import React from 'react';
import './SelectDeviceView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { ConnectDeviceData, LocalizePropType } from 'getaway-data-layer';
import TextAutocomplete, {
  AutocompleteOption,
} from '../../components/TextAutocomplete/TextAutocomplete';
import BrandButton from '../../components/BrandButton/BrandButton';

type props = {
  localize: LocalizePropType;
  devices: Array<ConnectDeviceData>;
  deviceNo?: string | null;
  inputValue?: string;
  handleChange: (event: any, newValue: AutocompleteOption | null) => void;
  handleInputChange: (event: any, newValue: string) => void;
  onPressContinue: () => void;
};

const SelectDeviceView = ({
  localize,
  devices,
  deviceNo,
  inputValue,
  handleChange,
  handleInputChange,
  onPressContinue,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('car.device.view.title')}
        description={localize('car.device.view.description')}
      />
      <div className="screen-body">
        <TextAutocomplete
          id="cloudboxx"
          placeHolder={localize('connect.item.cloudboxx.number')}
          value={deviceNo}
          inputValue={inputValue}
          data={devices?.map((device) => ({
            label: device?.serialNumber,
            value: device?.serialNumber,
          }))}
          handleInputChange={handleInputChange}
          handleChange={handleChange}
        />
        <BrandButton
          disabled={!deviceNo}
          title={localize('car.device.view.btn.txt')}
          onClick={onPressContinue}
        />
      </div>
    </div>
  );
};

export default SelectDeviceView;
