import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAdmin } from 'getaway-data-layer';

const LandingContainer = () => {
  const user = useSelector(selectAdmin);
  return user ? (
    <Redirect to={{ pathname: '/select' }} />
  ) : (
    <Redirect to={{ pathname: '/login' }} />
  );
};

export default LandingContainer;
