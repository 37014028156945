import { CLIENT_TYPE, Dispatch, CarStatusData } from '../../../../@types';
import {
  setNetworkActivity,
  setNetworkError,
  setNetworkSuccess,
} from '../../networkStatus';
import { connectClient } from '../../../clients/connectClient';
import { setNotification } from '../../ui/notifications';
import { APP } from '../../../../enums';
import { setSelectedConnectDeviceStatus } from './actions';

export const fetchDeviceStatus =
  (
    deviceId: string,
    successCallback: (data: CarStatusData) => void = () => {}
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.GET_DEVICE_STATUS));
    const { data, error } = await connectClient.fetchDeviceStatus(deviceId);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.GET_DEVICE_STATUS, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        dispatch(setSelectedConnectDeviceStatus(data));
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.GET_DEVICE_STATUS));
    }
  };

export const lockConnectDevice =
  (deviceId: string) => async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.LOCK_DEVICE));
    const { data, error } = await connectClient.lockDevice(deviceId);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.LOCK_DEVICE, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        dispatch(setSelectedConnectDeviceStatus(data));
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.LOCK_DEVICE));
    }
  };

export const unlockConnectDevice =
  (deviceId: string) => async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.UNLOCK_DEVICE));
    const { data, error } = await connectClient.unlockDevice(deviceId);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.UNLOCK_DEVICE, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        dispatch(setSelectedConnectDeviceStatus(data));
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.UNLOCK_DEVICE));
    }
  };
