import {
  CarStatusData,
  ConnectCarData,
  ConnectDeviceData,
  ConnectFuelCardData,
  ConnectInstallationData,
  Image,
  State,
} from '../@types';
import { FleetUserDataOutput } from '../@types';

export const selectAdminAuth = (store: State) =>
  store.connect.userData.basicAuth;

export const selectAdmin = (
  store: State
): FleetUserDataOutput | null | undefined => store.connect.userData.admin;

export const selectConnectCars = (store: State): Array<ConnectCarData> =>
  store.connect.appData.cars;

export const selectSelectedConnectCar = (
  store: State
): ConnectCarData | null | undefined => store.connect.ui.selectedCar;

export const selectSelectedConnectDevice = (
  store: State
): ConnectDeviceData | null | undefined => store.connect.ui.selectedDevice;

export const selectConnectDevices = (store: State): Array<ConnectDeviceData> =>
  store.connect.appData.devices;

export const selectCurrentInstallation = (
  store: State
): ConnectInstallationData | null => store.connect.appData.installation;

export const selectConnectFuelCards = (
  store: State
): Array<ConnectFuelCardData> => store.connect.appData.fuelCards;

export const selectConnectDeviceStatus = (store: State): CarStatusData | null =>
  store.connect.ui.selectedDeviceStatus;

export const selectVehicleRegistration = (store: State): Image | null =>
  store.connect.camera.vehicleRegistration;
