import { addHours, addDays, isBefore } from 'date-fns';

export const datesAreOnSameDay = (first: Date, second: Date | null) =>
  first &&
  second &&
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const dateFromObjectId = (id: String) => {
  if (id) {
    const timestamp = id.toString().substring(0, 8);
    return new Date(parseInt(timestamp, 16) * 1000);
  }

  return '';
};

export const isBefore24hrs = (date: Date) => {
  const now = new Date();
  return date && isBefore(date, addHours(now, 24));
};

export const isBeforeNextWeek = (date: Date) => {
  const now = new Date();
  return date && isBefore(date, addDays(now, 7));
};
