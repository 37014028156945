import React, { useEffect } from 'react';
import {
  findFuelCards,
  LocalizePropType,
  selectConnectFuelCards,
  selectCurrentInstallation,
  selectSelectedConnectCar,
  updateCarInstallation,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import FuelCardView from './FuelCardView';
import { navigateTo } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteOption } from '../../components/TextAutocomplete/TextAutocomplete';

type Props = {
  localize: LocalizePropType;
};

const FuelCardContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCar = useSelector(selectSelectedConnectCar);
  const fuelCards = useSelector(selectConnectFuelCards);
  const currentInstallation = useSelector(selectCurrentInstallation);

  const [cardNo, setCardNo] = React.useState<string | null>(null);
  const [inputValue, setInputValue] = React.useState('');

  const continueInstallation = () => {
    navigateTo(history, '/confirm');
  };

  const onPressContinue = () => {
    if (cardNo === currentInstallation?.fuelCard?.number) {
      continueInstallation();
    } else {
      const selectedFuelCard = fuelCards.find((card) => card.number === cardNo);
      dispatch(
        updateCarInstallation(
          selectedCar?.id!,
          { fuelCardId: selectedFuelCard?.id },
          () => {
            continueInstallation();
          }
        )
      );
    }
  };

  const handleChange = (event: any, newValue: AutocompleteOption | null) => {
    setCardNo(newValue?.value!);
  };

  const handleInputChange = (event: any, newValue: string) => {
    setInputValue(newValue);
  };

  useEffect(() => {
    dispatch(findFuelCards());
  }, []);

  useEffect(() => {
    if (currentInstallation?.fuelCard) {
      setCardNo(currentInstallation?.fuelCard?.number!);
    }
    if (!currentInstallation) {
      navigateTo(history, '/select');
    }
  }, [currentInstallation]);

  return (
    <FuelCardView
      localize={localize}
      fuelCards={fuelCards}
      cardNo={cardNo}
      inputValue={inputValue}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
      onPressContinue={onPressContinue}
    />
  );
};

export default withTextLocalizer(FuelCardContainer);
