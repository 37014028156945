import { BrandData } from '../../Types.api';

export type CommonState = {
  readonly appRated: boolean;
  readonly firstSteps: boolean;
  readonly supportContacted: boolean;
  readonly brandSettings?: BrandData | null;
  readonly dialog: any;
  readonly openedModal: any;
  readonly isIntercomUserRegistered: boolean;
};

enum CommonTypes {
  SET_APP_RATED = 'appData/common/SET_APP_RATED',
  SET_FIRST_STEPS = 'appData/common/SET_FIRST_STEPS',
  SET_SUPPORT_CONTACTED = 'appData/common/SET_SUPPORT_CONTACTED',
  SET_BRAND_SETTINGS = 'appData/common/SET_BRAND_SETTINGS',
  SET_DIALOG = 'appData/common/SET_DIALOG',
  CLEAR_DIALOG = 'appData/common/CLEAR_DIALOG',
  SET_OPENED_MODAL = 'appData/common/SET_OPENED_MODAL',
  CLEAR_OPENED_MODAL = 'appData/common/CLEAR_OPENED_MODAL',
  SET_IS_INTERCOM_USER_REGISTERED = 'appData/common/SET_IS_INTERCOM_USER_REGISTERED',
  RESET_STORE = 'RESET_STORE',
}

export type SetAppRated = {
  type: CommonTypes.SET_APP_RATED;
  payload: {
    appRated: boolean;
  };
};

export type SetFirstSteps = {
  type: CommonTypes.SET_FIRST_STEPS;
  payload: {
    firstSteps: boolean;
  };
};

export type SetSupportContacted = {
  type: CommonTypes.SET_SUPPORT_CONTACTED;
  payload: {
    supportContacted: boolean;
  };
};

export type SetIsIntercomUserRegistered = {
  type: CommonTypes.SET_IS_INTERCOM_USER_REGISTERED;
  payload: {
    isIntercomUserRegistered: boolean;
  };
};

export type SetBrandSettings = {
  type: CommonTypes.SET_BRAND_SETTINGS;
  payload: {
    brandSettings?: BrandData;
  };
};

export type SetOpenedModal = {
  type: CommonTypes.SET_OPENED_MODAL;
  payload: {
    modalName: string;
  };
};

export type SetDialog = {
  type: CommonTypes.SET_DIALOG;
  payload: {
    dialog: any;
  };
};

export type ClearOpenedModal = {
  type: CommonTypes.CLEAR_OPENED_MODAL;
};

export type ClearDialog = {
  type: CommonTypes.CLEAR_DIALOG;
};

export type CommonAction =
  | SetAppRated
  | SetFirstSteps
  | SetSupportContacted
  | SetBrandSettings
  | SetOpenedModal
  | ClearOpenedModal
  | SetDialog
  | SetIsIntercomUserRegistered
  | ClearDialog;

export default CommonTypes;
