import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import LandingPage from '../init/LandingContainer/LandingContainer';
import PrivateRoute from '../routes/PrivateRoute';
import LoginPage from '../ui/pages/Login/LoginContainer';
import ForgotPage from '../ui/pages/ForgetPassword/ForgotPasswordContainer';
import InstalledContainer from '../ui/pages/Installed/InstalledContainer';
import SelectCarContainer from '../ui/pages/Select/SelectCarContainer';
import RemovalContainer from '../ui/pages/Remove/RemovalContainer';
import CarDataContainer from '../ui/pages/CarData/CarDataContainer';
import SelectDeviceContainer from '../ui/pages/Device/SelectDeviceContainer';
import DataTestContainer from '../ui/pages/Test/DataTestContainer';
import DataConfirmContainer from '../ui/pages/Confirm/DataConfirmContainer';
import FuelCardContainer from '../ui/pages/Card/FuelCardContainer';
const NotFoundPage = React.lazy(
  () => import('../ui/pages/NotFound/NotFoundContainer')
);

const AppRoute = () => {
  return (
    <Router>
      <Switch>
        {/* AUTH */}
        <Route path="/login" component={LoginPage} />
        <Route path="/forgot" component={ForgotPage} />

        {/* Main process */}
        <PrivateRoute path="/select" component={SelectCarContainer} />
        <PrivateRoute path="/remove" component={RemovalContainer} />
        <PrivateRoute path="/car-data" component={CarDataContainer} />
        <PrivateRoute path="/device" component={SelectDeviceContainer} />
        <PrivateRoute path="/test" component={DataTestContainer} />
        <PrivateRoute path="/confirm" component={DataConfirmContainer} />
        <PrivateRoute path="/fuel-card" component={FuelCardContainer} />
        <PrivateRoute path="/installed" component={InstalledContainer} />
        <Route path="/" exact component={LandingPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default AppRoute;
