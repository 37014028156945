export default [
  {
    "ID": 868437929,
    "Tankstellennummer": 1056,
    "Name": "Agip Oberstdorf",
    "Address": "Sonthofener Strasse 16",
    "Address__1": "",
    "City": "Oberstdorf",
    "Postcode": 87561,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 83229626",
    "24 hour?": "FALSE",
    "Latitude": 47.41292,
    "Longitude": 10.27536,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.41292,10.27536",
    "IsOpen24Hours": false,
    "id": "47.41292,10.27536"
  },
  {
    "ID": 338525550,
    "Tankstellennummer": 1350,
    "Name": "Agip Klais",
    "Address": "Hauptstr. 18",
    "Address__1": "",
    "City": "Klais",
    "Postcode": 82493,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "08823/1617",
    "24 hour?": "FALSE",
    "Latitude": 47.48373,
    "Longitude": 11.23647,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.48373,11.23647",
    "IsOpen24Hours": false,
    "id": "47.48373,11.23647"
  },
  {
    "ID": 114504827,
    "Tankstellennummer": 1113,
    "Name": "Agip Garmisch-Partenk.",
    "Address": "Burgstr. 16",
    "Address__1": "",
    "City": "Garmisch-Partenk.",
    "Postcode": 82467,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 88219470",
    "24 hour?": "FALSE",
    "Latitude": 47.49893076,
    "Longitude": 11.08798154,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.49893076,11.08798154",
    "IsOpen24Hours": false,
    "id": "47.49893076,11.08798154"
  },
  {
    "ID": 1986,
    "Tankstellennummer": "0F888",
    "Name": "Aral Tankstelle Garmisch-Partenkirch, Hauptstr. 20",
    "Address": "Hauptstraße 20",
    "Address__1": "",
    "City": "Garmisch-Partenkirch",
    "Postcode": 82467,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 882152733,
    "24 hour?": "FALSE",
    "Latitude": 47.500453,
    "Longitude": 11.101443,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.500453,11.101443",
    "IsOpen24Hours": false,
    "id": "47.500453,11.101443"
  },
  {
    "ID": 1553592131,
    "Tankstellennummer": "0FN51",
    "Name": "SONTHOFEN",
    "Address": "Illerstraße 12",
    "Address__1": "",
    "City": "Sonthofen",
    "Postcode": 87527,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 83213322,
    "24 hour?": "FALSE",
    "Latitude": 47.523716,
    "Longitude": 10.262306,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.523716,10.262306",
    "IsOpen24Hours": false,
    "id": "47.523716,10.262306"
  },
  {
    "ID": 962131052,
    "Tankstellennummer": "0FO13",
    "Name": "LINDAU",
    "Address": "Robert-Bosch-Straße 40",
    "Address__1": "",
    "City": "Lindau",
    "Postcode": 88131,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 838278767,
    "24 hour?": "TRUE",
    "Latitude": 47.547694,
    "Longitude": 9.728079,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.547694,9.728079",
    "IsOpen24Hours": true,
    "id": "47.547694,9.728079"
  },
  {
    "ID": 1751260193,
    "Tankstellennummer": 1256,
    "Name": "Agip Oberau",
    "Address": "Ettaler Str. 3",
    "Address__1": "",
    "City": "Oberau",
    "Postcode": 82496,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 88249305",
    "24 hour?": "FALSE",
    "Latitude": 47.55964,
    "Longitude": 11.13368,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.55964,11.13368",
    "IsOpen24Hours": false,
    "id": "47.55964,11.13368"
  },
  {
    "ID": 2466,
    "Tankstellennummer": "0FC26",
    "Name": "Aral Tankstelle Rheinfelden, Friedrichstr.57",
    "Address": "Friedrichstraße 57",
    "Address__1": "",
    "City": "Rheinfelden",
    "Postcode": 79618,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76231286,
    "24 hour?": "TRUE",
    "Latitude": 47.562824,
    "Longitude": 7.792269,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.562824,7.792269",
    "IsOpen24Hours": true,
    "id": "47.562824,7.792269"
  },
  {
    "ID": 740435786,
    "Tankstellennummer": 1609,
    "Name": "Agip Immenstadt",
    "Address": "Lindauer Str. 17",
    "Address__1": "",
    "City": "Immenstadt",
    "Postcode": 87509,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 83231855",
    "24 hour?": "FALSE",
    "Latitude": 47.56686,
    "Longitude": 10.19303,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.56686,10.19303",
    "IsOpen24Hours": false,
    "id": "47.56686,10.19303"
  },
  {
    "ID": 1974,
    "Tankstellennummer": "0F880",
    "Name": "Aral Tankstelle Pfronten, Allgäuerstr. 39",
    "Address": "Allgäuerstraße 39",
    "Address__1": "",
    "City": "Pfronten",
    "Postcode": 87459,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8363296,
    "24 hour?": "FALSE",
    "Latitude": 47.584624,
    "Longitude": 10.559278,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.584624,10.559278",
    "IsOpen24Hours": false,
    "id": "47.584624,10.559278"
  },
  {
    "ID": 923800486,
    "Tankstellennummer": "0FP75",
    "Name": "SCHEIDEGG",
    "Address": "Bahnhofstraße 44",
    "Address__1": "",
    "City": "Scheidegg",
    "Postcode": 88175,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 838181717,
    "24 hour?": "FALSE",
    "Latitude": 47.584783,
    "Longitude": 9.854433,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.584783,9.854433",
    "IsOpen24Hours": false,
    "id": "47.584783,9.854433"
  },
  {
    "ID": 1636948387,
    "Tankstellennummer": "0FN53",
    "Name": "SCHÖNAU AM KÖNIGSSEE",
    "Address": "Seestraße 1",
    "Address__1": "",
    "City": "Schönau am Königssee",
    "Postcode": 83471,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 865295630,
    "24 hour?": "FALSE",
    "Latitude": 47.592761,
    "Longitude": 12.987441,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.592761,12.987441",
    "IsOpen24Hours": false,
    "id": "47.592761,12.987441"
  },
  {
    "ID": 1127845819,
    "Tankstellennummer": 1293,
    "Name": "Agip Lindenberg",
    "Address": "Pfaenderstr. 15 a",
    "Address__1": "",
    "City": "Lindenberg",
    "Postcode": 88161,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 83819298",
    "24 hour?": "FALSE",
    "Latitude": 47.59771,
    "Longitude": 9.877935,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.59771,9.877935",
    "IsOpen24Hours": false,
    "id": "47.59771,9.877935"
  },
  {
    "ID": 574451992,
    "Tankstellennummer": 1015,
    "Name": "Agip Oberammergau",
    "Address": "Bahnhofstrasse 6",
    "Address__1": "",
    "City": "Oberammergau",
    "Postcode": 82487,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 88229306",
    "24 hour?": "FALSE",
    "Latitude": 47.59880896,
    "Longitude": 11.05929735,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.59880896,11.05929735",
    "IsOpen24Hours": false,
    "id": "47.59880896,11.05929735"
  },
  {
    "ID": 2435,
    "Tankstellennummer": "0FC10",
    "Name": "Aral Tankstelle Lindenberg, Hauptstrasse 79",
    "Address": "Hauptstraße 79",
    "Address__1": "",
    "City": "Lindenberg",
    "Postcode": 88161,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8381927740,
    "24 hour?": "FALSE",
    "Latitude": 47.599593,
    "Longitude": 9.884206,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.599593,9.884206",
    "IsOpen24Hours": false,
    "id": "47.599593,9.884206"
  },
  {
    "ID": 10073,
    "Tankstellennummer": "0FM48",
    "Name": "Aral Autobahntankstelle Weil am Rhein, A 5",
    "Address": "A 5",
    "Address__1": "",
    "City": "Weil am Rhein",
    "Postcode": 79576,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 762116361980,
    "24 hour?": "TRUE",
    "Latitude": 47.600207,
    "Longitude": 7.603025,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.600207,7.603025",
    "IsOpen24Hours": true,
    "id": "47.600207,7.603025"
  },
  {
    "ID": 110561214,
    "Tankstellennummer": 1619,
    "Name": "Agip Immenstadt",
    "Address": "Zellers 6",
    "Address__1": "",
    "City": "Immenstadt",
    "Postcode": 87509,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 83797176",
    "24 hour?": "FALSE",
    "Latitude": 47.60201567,
    "Longitude": 10.25526052,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.60201567,10.25526052",
    "IsOpen24Hours": false,
    "id": "47.60201567,10.25526052"
  },
  {
    "ID": 2023,
    "Tankstellennummer": "0FG20",
    "Name": "Aral Autohof Kressbronn, Linderhof 8",
    "Address": "Linderhof 8",
    "Address__1": "",
    "City": "Kressbronn",
    "Postcode": 88079,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7543953347,
    "24 hour?": "TRUE",
    "Latitude": 47.607814,
    "Longitude": 9.586966,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.607814,9.586966",
    "IsOpen24Hours": true,
    "id": "47.607814,9.586966"
  },
  {
    "ID": 471832153,
    "Tankstellennummer": "TD041863",
    "Name": "KIEFERSFELDEN KAISERREICH-STR.",
    "Address": "KAISERREICH-STR. 1",
    "Address__1": "",
    "City": "KIEFERSFELDEN",
    "Postcode": 83088,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 80334959712,
    "24 hour?": "FALSE",
    "Latitude": 47.6186,
    "Longitude": 12.1986,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.6186,12.1986",
    "IsOpen24Hours": false,
    "id": "47.6186,12.1986"
  },
  {
    "ID": 482763873,
    "Tankstellennummer": 1614,
    "Name": "Agip Bischofswiesen - Strub",
    "Address": "Silbergstrasse 91 A/B 20",
    "Address__1": "",
    "City": "Bischofswiesen - Strub",
    "Postcode": 83489,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 86524757",
    "24 hour?": "FALSE",
    "Latitude": 47.62964687,
    "Longitude": 12.96003066,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.62964687,12.96003066",
    "IsOpen24Hours": false,
    "id": "47.62964687,12.96003066"
  },
  {
    "ID": 2446,
    "Tankstellennummer": "0FC19",
    "Name": "Aral Tankstelle Eimeldingen, Hauptstr.49",
    "Address": "Hauptstraße 49",
    "Address__1": "",
    "City": "Eimeldingen",
    "Postcode": 79591,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76216908035,
    "24 hour?": "FALSE",
    "Latitude": 47.633867,
    "Longitude": 7.594061,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.633867,7.594061",
    "IsOpen24Hours": false,
    "id": "47.633867,7.594061"
  },
  {
    "ID": 1377988473,
    "Tankstellennummer": 1258,
    "Name": "Agip Halblech",
    "Address": "Allguer Str. 7",
    "Address__1": "",
    "City": "Halblech",
    "Postcode": 87642,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 83689404",
    "24 hour?": "FALSE",
    "Latitude": 47.63806,
    "Longitude": 10.82567,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.63806,10.82567",
    "IsOpen24Hours": false,
    "id": "47.63806,10.82567"
  },
  {
    "ID": 327076029,
    "Tankstellennummer": 1349,
    "Name": "Agip Weitnau",
    "Address": "Zur Eisenschmiede 2",
    "Address__1": "",
    "City": "Weitnau/Hofen",
    "Postcode": 87480,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "08375/975773",
    "24 hour?": "TRUE",
    "Latitude": 47.65608,
    "Longitude": 10.08856,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.65608,10.08856",
    "IsOpen24Hours": true,
    "id": "47.65608,10.08856"
  },
  {
    "ID": 1888247873,
    "Tankstellennummer": 2240,
    "Name": "Agip Friedrichshafen",
    "Address": "Goethestr. 2",
    "Address__1": "",
    "City": "Friedrichshafen",
    "Postcode": 88046,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 75417313",
    "24 hour?": "FALSE",
    "Latitude": 47.66001,
    "Longitude": 9.48732,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.66001,9.48732",
    "IsOpen24Hours": false,
    "id": "47.66001,9.48732"
  },
  {
    "ID": 545349171,
    "Tankstellennummer": 1288,
    "Name": "Agip Tettnang",
    "Address": "Lindauer Str. 43",
    "Address__1": "",
    "City": "Tettnang",
    "Postcode": 88069,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 75427400",
    "24 hour?": "FALSE",
    "Latitude": 47.66662,
    "Longitude": 9.59375,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.66662,9.59375",
    "IsOpen24Hours": false,
    "id": "47.66662,9.59375"
  },
  {
    "ID": 1904933414,
    "Tankstellennummer": "TD040238",
    "Name": "NIEDERAUDORF ROSENHEIMER STR.",
    "Address": "ROSENHEIMER STR. 116",
    "Address__1": "",
    "City": "NIEDERAUDORF",
    "Postcode": 83080,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08033/1501",
    "24 hour?": "FALSE",
    "Latitude": 47.6675,
    "Longitude": 12.1691,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.6675,12.1691",
    "IsOpen24Hours": false,
    "id": "47.6675,12.1691"
  },
  {
    "ID": 2439,
    "Tankstellennummer": "0FC13",
    "Name": "Aral Tankstelle Tettnang, Hopfenstr.1",
    "Address": "Tettnanger Straße 150",
    "Address__1": "",
    "City": "Tettnang",
    "Postcode": 88069,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75424073599,
    "24 hour?": "FALSE",
    "Latitude": 47.668537,
    "Longitude": 9.56114,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.668537,9.56114",
    "IsOpen24Hours": false,
    "id": "47.668537,9.56114"
  },
  {
    "ID": 1195327339,
    "Tankstellennummer": 1669,
    "Name": "Agip Bischofwiesen",
    "Address": "Reichenhaller Strasse 20",
    "Address__1": "",
    "City": "Bischofswiesen",
    "Postcode": 83483,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "08652/985950",
    "24 hour?": "FALSE",
    "Latitude": 47.6693,
    "Longitude": 12.94402,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.6693,12.94402",
    "IsOpen24Hours": false,
    "id": "47.6693,12.94402"
  },
  {
    "ID": 854827975,
    "Tankstellennummer": 2040,
    "Name": "Agip Konstanz",
    "Address": "Reichenaustrasse 43 A",
    "Address__1": "",
    "City": "Konstanz",
    "Postcode": 78467,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 75316223",
    "24 hour?": "FALSE",
    "Latitude": 47.67176,
    "Longitude": 9.1665,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.67176,9.1665",
    "IsOpen24Hours": false,
    "id": "47.67176,9.1665"
  },
  {
    "ID": 1864576446,
    "Tankstellennummer": "0FU05",
    "Name": "KONSTANZ",
    "Address": "Opelstraße 1",
    "Address__1": "",
    "City": "Konstanz",
    "Postcode": 78467,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "07531/1272460",
    "24 hour?": "FALSE",
    "Latitude": 47.67609,
    "Longitude": 9.15313,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.67609,9.15313",
    "IsOpen24Hours": false,
    "id": "47.67609,9.15313"
  },
  {
    "ID": 1060303947,
    "Tankstellennummer": "0FM53",
    "Name": "KONSTANZ",
    "Address": "Carl-Benz-Straße 20-24",
    "Address__1": "",
    "City": "Konstanz",
    "Postcode": 78467,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7531694090,
    "24 hour?": "FALSE",
    "Latitude": 47.677586,
    "Longitude": 9.147051,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.677586,9.147051",
    "IsOpen24Hours": false,
    "id": "47.677586,9.147051"
  },
  {
    "ID": 1460,
    "Tankstellennummer": "0F525",
    "Name": "Aral Tankstelle Isny, Lindauer Str. 50",
    "Address": "Lindauer Straße 50",
    "Address__1": "",
    "City": "Isny",
    "Postcode": 88316,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75628117,
    "24 hour?": "TRUE",
    "Latitude": 47.6896,
    "Longitude": 10.032856,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.6896,10.032856",
    "IsOpen24Hours": true,
    "id": "47.6896,10.032856"
  },
  {
    "ID": 761711701,
    "Tankstellennummer": "0FO69",
    "Name": "MECKENBEUREN",
    "Address": "Hauptstraße 88",
    "Address__1": "",
    "City": "Meckenbeuren",
    "Postcode": 88074,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75424251,
    "24 hour?": "FALSE",
    "Latitude": 47.693377,
    "Longitude": 9.559551,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.693377,9.559551",
    "IsOpen24Hours": false,
    "id": "47.693377,9.559551"
  },
  {
    "ID": 1351645909,
    "Tankstellennummer": 2126,
    "Name": "Agip Meersburg",
    "Address": "Stettener Str. 51",
    "Address__1": "",
    "City": "Meersburg",
    "Postcode": 88709,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 75329618",
    "24 hour?": "FALSE",
    "Latitude": 47.69355407,
    "Longitude": 9.27939105,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.69355407,9.27939105",
    "IsOpen24Hours": false,
    "id": "47.69355407,9.27939105"
  },
  {
    "ID": 1994161192,
    "Tankstellennummer": 1601,
    "Name": "Agip Tegernsee",
    "Address": "Schwaighofstrasse 93",
    "Address__1": "",
    "City": "Tegernsee",
    "Postcode": 83684,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 80225140",
    "24 hour?": "FALSE",
    "Latitude": 47.69437,
    "Longitude": 11.77229,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.69437,11.77229",
    "IsOpen24Hours": false,
    "id": "47.69437,11.77229"
  },
  {
    "ID": 597614746,
    "Tankstellennummer": "0FO68",
    "Name": "AMTZELL",
    "Address": "Schomburger Straße 1",
    "Address__1": "",
    "City": "Amtzell",
    "Postcode": 88279,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75206126,
    "24 hour?": "FALSE",
    "Latitude": 47.695372,
    "Longitude": 9.758986,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.695372,9.758986",
    "IsOpen24Hours": false,
    "id": "47.695372,9.758986"
  },
  {
    "ID": 1404063400,
    "Tankstellennummer": "TD161240",
    "Name": "DURACH FUESSENER STR. 2A",
    "Address": "FUESSENER STR. 2A",
    "Address__1": "",
    "City": "DURACH",
    "Postcode": 87471,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0831-63279",
    "24 hour?": "FALSE",
    "Latitude": 47.6994,
    "Longitude": 10.3415,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.6994,10.3415",
    "IsOpen24Hours": false,
    "id": "47.6994,10.3415"
  },
  {
    "ID": 257137768,
    "Tankstellennummer": "0FO30",
    "Name": "KEMPTEN",
    "Address": "Oberstdorfer Straße 13",
    "Address__1": "",
    "City": "Kempten",
    "Postcode": 87435,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 831523530,
    "24 hour?": "FALSE",
    "Latitude": 47.70613,
    "Longitude": 10.311661,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.70613,10.311661",
    "IsOpen24Hours": false,
    "id": "47.70613,10.311661"
  },
  {
    "ID": 235821030,
    "Tankstellennummer": 1255,
    "Name": "Agip Bichl",
    "Address": "Kocheler Str. 11",
    "Address__1": "",
    "City": "Bichl",
    "Postcode": 83673,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 88571263",
    "24 hour?": "FALSE",
    "Latitude": 47.71488343,
    "Longitude": 11.41361774,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.71488343,11.41361774",
    "IsOpen24Hours": false,
    "id": "47.71488343,11.41361774"
  },
  {
    "ID": 20460001,
    "Tankstellennummer": "0FN36",
    "Name": "BAD REICHENHALL",
    "Address": "Berchtesgardener Straße 2",
    "Address__1": "",
    "City": "Bad Reichenhall",
    "Postcode": 83435,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 865167220,
    "24 hour?": "FALSE",
    "Latitude": 47.717601,
    "Longitude": 12.875201,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.717601,12.875201",
    "IsOpen24Hours": false,
    "id": "47.717601,12.875201"
  },
  {
    "ID": 2016,
    "Tankstellennummer": "0F914",
    "Name": "Aral Tankstelle Bad Wiessee, Münchener Str. 43",
    "Address": "Münchener Straße 43",
    "Address__1": "",
    "City": "Bad Wiessee",
    "Postcode": 83707,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8022857950,
    "24 hour?": "FALSE",
    "Latitude": 47.717837,
    "Longitude": 11.718955,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "47.717837,11.718955",
    "IsOpen24Hours": false,
    "id": "47.717837,11.718955"
  },
  {
    "ID": 847041464,
    "Tankstellennummer": 1217,
    "Name": "Agip Kempten",
    "Address": "Schumacherring 96",
    "Address__1": "",
    "City": "Kempten",
    "Postcode": 87437,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 83175510",
    "24 hour?": "TRUE",
    "Latitude": 47.73111,
    "Longitude": 10.32634,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.73111,10.32634",
    "IsOpen24Hours": true,
    "id": "47.73111,10.32634"
  },
  {
    "ID": 1951,
    "Tankstellennummer": "0F878",
    "Name": "Aral Tankstelle Kempten, Schumacherring 110",
    "Address": "Schumacherring 110",
    "Address__1": "",
    "City": "Kempten",
    "Postcode": 87437,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 83173262,
    "24 hour?": "TRUE",
    "Latitude": 47.733469,
    "Longitude": 10.325349,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.733469,10.325349",
    "IsOpen24Hours": true,
    "id": "47.733469,10.325349"
  },
  {
    "ID": 1941,
    "Tankstellennummer": "0F860",
    "Name": "Aral Tankstelle Bad Reichenhall, Loferer Straße 29",
    "Address": "Loferer Straße 29",
    "Address__1": "",
    "City": "Bad Reichenhall",
    "Postcode": 83435,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 86512325,
    "24 hour?": "TRUE",
    "Latitude": 47.733864,
    "Longitude": 12.877083,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.733864,12.877083",
    "IsOpen24Hours": true,
    "id": "47.733864,12.877083"
  },
  {
    "ID": 2082606842,
    "Tankstellennummer": 1654,
    "Name": "Agip Kiefersfelden",
    "Address": "Inntal-Ost / Autobahnrastst.2",
    "Address__1": "",
    "City": "Kiefersfelden",
    "Postcode": 83126,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 80333045",
    "24 hour?": "TRUE",
    "Latitude": 47.73387,
    "Longitude": 12.13147,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.73387,12.13147",
    "IsOpen24Hours": true,
    "id": "47.73387,12.13147"
  },
  {
    "ID": 1739665714,
    "Tankstellennummer": "TD182410",
    "Name": "BERMATINGEN SALEMER STR. 47",
    "Address": "SALEMER STR. 47",
    "Address__1": "",
    "City": "BERMATINGEN",
    "Postcode": 88697,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07544-741930",
    "24 hour?": "FALSE",
    "Latitude": 47.7358,
    "Longitude": 9.3414,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7358,9.3414",
    "IsOpen24Hours": false,
    "id": "47.7358,9.3414"
  },
  {
    "ID": 1718489141,
    "Tankstellennummer": "TD040360",
    "Name": "GOTTMADINGEN, HAUPTSTRASSE/",
    "Address": "HAUPTSTRASSE / KORNBLUMENWEG 43",
    "Address__1": "",
    "City": "GOTTMADINGEN",
    "Postcode": 78244,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07731-9072490",
    "24 hour?": "FALSE",
    "Latitude": 47.7366,
    "Longitude": 8.7881,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7366,8.7881",
    "IsOpen24Hours": false,
    "id": "47.7366,8.7881"
  },
  {
    "ID": 1328610741,
    "Tankstellennummer": "0FN97",
    "Name": "RADOLFZELL",
    "Address": "Eisenbahnstraße 8",
    "Address__1": "",
    "City": "Radolfzell",
    "Postcode": 78315,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7732988268,
    "24 hour?": "FALSE",
    "Latitude": 47.741594,
    "Longitude": 8.95478,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.741594,8.95478",
    "IsOpen24Hours": false,
    "id": "47.741594,8.95478"
  },
  {
    "ID": 514806764,
    "Tankstellennummer": 2613,
    "Name": "Agip Singen",
    "Address": "Rielasingerstr. 139",
    "Address__1": "",
    "City": "Singen",
    "Postcode": 78224,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 77312134",
    "24 hour?": "FALSE",
    "Latitude": 47.74207803,
    "Longitude": 8.84215889,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.74207803,8.84215889",
    "IsOpen24Hours": false,
    "id": "47.74207803,8.84215889"
  },
  {
    "ID": 942157161,
    "Tankstellennummer": "0FN38",
    "Name": "NUßDORF",
    "Address": "Brannenburger Straße 12",
    "Address__1": "",
    "City": "Nußdorf",
    "Postcode": 83131,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8034904618,
    "24 hour?": "FALSE",
    "Latitude": 47.743358,
    "Longitude": 12.154207,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.743358,12.154207",
    "IsOpen24Hours": false,
    "id": "47.743358,12.154207"
  },
  {
    "ID": 1356268088,
    "Tankstellennummer": 1009,
    "Name": "Agip Hausham",
    "Address": "Schlierseer Strasse 9",
    "Address__1": "",
    "City": "Hausham",
    "Postcode": 83734,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 80269327",
    "24 hour?": "FALSE",
    "Latitude": 47.74636,
    "Longitude": 11.84433,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.74636,11.84433",
    "IsOpen24Hours": false,
    "id": "47.74636,11.84433"
  },
  {
    "ID": 1086353884,
    "Tankstellennummer": 1070,
    "Name": "Agip Bad Toelz",
    "Address": "Lenggrieser Strasse 46",
    "Address__1": "",
    "City": "Bad Toelz",
    "Postcode": 83646,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 80418147",
    "24 hour?": "FALSE",
    "Latitude": 47.74650124,
    "Longitude": 11.566682,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.74650124,11.566682",
    "IsOpen24Hours": false,
    "id": "47.74650124,11.566682"
  },
  {
    "ID": 2097262933,
    "Tankstellennummer": 1605,
    "Name": "Agip Gmund",
    "Address": "Tegernseer Strasse 20",
    "Address__1": "",
    "City": "Gmund",
    "Postcode": 83703,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 80227526",
    "24 hour?": "FALSE",
    "Latitude": 47.74743942,
    "Longitude": 11.73804212,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.74743942,11.73804212",
    "IsOpen24Hours": false,
    "id": "47.74743942,11.73804212"
  },
  {
    "ID": 690270062,
    "Tankstellennummer": "TD036582",
    "Name": "BAD TOELZ LENGGRIESER STR. 87",
    "Address": "LENGGRIESER STR. 87",
    "Address__1": "",
    "City": "BAD TOELZ",
    "Postcode": 83646,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08041-8445",
    "24 hour?": "FALSE",
    "Latitude": 47.7479,
    "Longitude": 11.5654,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7479,11.5654",
    "IsOpen24Hours": false,
    "id": "47.7479,11.5654"
  },
  {
    "ID": 2467,
    "Tankstellennummer": "0FC27",
    "Name": "Aral Tankstelle Stühlingen, Bundesstr.314",
    "Address": "Bundesstraße 7",
    "Address__1": "",
    "City": "Stühlingen",
    "Postcode": 79780,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 77445211,
    "24 hour?": "FALSE",
    "Latitude": 47.751054,
    "Longitude": 8.45803,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.751054,8.45803",
    "IsOpen24Hours": false,
    "id": "47.751054,8.45803"
  },
  {
    "ID": 936508755,
    "Tankstellennummer": "0FQ59",
    "Name": "SINGEN",
    "Address": "Fittingstraße 99",
    "Address__1": "",
    "City": "Singen",
    "Postcode": 78224,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 77319269262,
    "24 hour?": "TRUE",
    "Latitude": 47.754134,
    "Longitude": 8.852683,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.754134,8.852683",
    "IsOpen24Hours": true,
    "id": "47.754134,8.852683"
  },
  {
    "ID": 1969,
    "Tankstellennummer": "0F006",
    "Name": "Aral Tankstelle Bad Tölz, Sachsenkamer Str. 23",
    "Address": "Sachsenkamer Straße 23",
    "Address__1": "",
    "City": "Bad Tölz",
    "Postcode": 83646,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 80415303,
    "24 hour?": "TRUE",
    "Latitude": 47.759593,
    "Longitude": 11.573566,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.759593,11.573566",
    "IsOpen24Hours": true,
    "id": "47.759593,11.573566"
  },
  {
    "ID": 52521033,
    "Tankstellennummer": 1610,
    "Name": "Agip Hausham",
    "Address": "Rathausstr. 1",
    "Address__1": "",
    "City": "Hausham",
    "Postcode": 83734,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 80269226",
    "24 hour?": "FALSE",
    "Latitude": 47.75969702,
    "Longitude": 11.82836075,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.75969702,11.82836075",
    "IsOpen24Hours": false,
    "id": "47.75969702,11.82836075"
  },
  {
    "ID": 1891655788,
    "Tankstellennummer": 1607,
    "Name": "Agip Ruhpolding",
    "Address": "Seehauser Str. 9",
    "Address__1": "",
    "City": "Ruhpolding",
    "Postcode": 83324,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 86631336",
    "24 hour?": "FALSE",
    "Latitude": 47.75992,
    "Longitude": 12.6502,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.75992,12.6502",
    "IsOpen24Hours": false,
    "id": "47.75992,12.6502"
  },
  {
    "ID": 669057218,
    "Tankstellennummer": 1606,
    "Name": "Agip Inzell",
    "Address": "Reichenhaller Str. 24",
    "Address__1": "",
    "City": "Inzell",
    "Postcode": 83334,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 86659293",
    "24 hour?": "FALSE",
    "Latitude": 47.76111933,
    "Longitude": 12.74836932,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.76111933,12.74836932",
    "IsOpen24Hours": false,
    "id": "47.76111933,12.74836932"
  },
  {
    "ID": 126888874,
    "Tankstellennummer": "TD040246",
    "Name": "SAMERBERG ESSBAUM 2",
    "Address": "ESSBAUM 2",
    "Address__1": "",
    "City": "SAMERBERG",
    "Postcode": 83122,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08032/8826",
    "24 hour?": "FALSE",
    "Latitude": 47.7643,
    "Longitude": 12.1973,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7643,12.1973",
    "IsOpen24Hours": false,
    "id": "47.7643,12.1973"
  },
  {
    "ID": 1078189183,
    "Tankstellennummer": "0FN32",
    "Name": "RUHPOLDING",
    "Address": "Hauptstraße 1",
    "Address__1": "",
    "City": "Ruhpolding",
    "Postcode": 83324,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8663419603,
    "24 hour?": "FALSE",
    "Latitude": 47.766101,
    "Longitude": 12.640801,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.766101,12.640801",
    "IsOpen24Hours": false,
    "id": "47.766101,12.640801"
  },
  {
    "ID": 1121991356,
    "Tankstellennummer": "0FX55",
    "Name": "PIDING",
    "Address": "A8",
    "Address__1": "",
    "City": "Piding",
    "Postcode": 83451,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 86512750,
    "24 hour?": "TRUE",
    "Latitude": 47.768054,
    "Longitude": 12.942177,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.768054,12.942177",
    "IsOpen24Hours": true,
    "id": "47.768054,12.942177"
  },
  {
    "ID": 370111671,
    "Tankstellennummer": 2036,
    "Name": "Agip Ueberlingen",
    "Address": "Lippertsreuter Strasse 13",
    "Address__1": "",
    "City": "Ueberlingen",
    "Postcode": 88662,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 75514590",
    "24 hour?": "FALSE",
    "Latitude": 47.7712163,
    "Longitude": 9.17326196,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7712163,9.17326196",
    "IsOpen24Hours": false,
    "id": "47.7712163,9.17326196"
  },
  {
    "ID": 2443,
    "Tankstellennummer": "0FC17",
    "Name": "Aral Tankstelle Überlingen, Lippertsreuterstr.33",
    "Address": "Lippertsreuterstraße33",
    "Address__1": "",
    "City": "Überlingen",
    "Postcode": 88662,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75514767,
    "24 hour?": "FALSE",
    "Latitude": 47.771758,
    "Longitude": 9.173977,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "47.771758,9.173977",
    "IsOpen24Hours": false,
    "id": "47.771758,9.173977"
  },
  {
    "ID": 162764393,
    "Tankstellennummer": "0FO71",
    "Name": "SCHLIER",
    "Address": "Scherzachstraße 4",
    "Address__1": "",
    "City": "Schlier",
    "Postcode": 88281,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7529912202,
    "24 hour?": "FALSE",
    "Latitude": 47.772072,
    "Longitude": 9.67064,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.772072,9.67064",
    "IsOpen24Hours": false,
    "id": "47.772072,9.67064"
  },
  {
    "ID": 1315828621,
    "Tankstellennummer": "0FO18",
    "Name": "MARKTOBERDORF-THALHOFEN",
    "Address": "Brückenstraße 6",
    "Address__1": "",
    "City": "Marktoberdorf-Thalho",
    "Postcode": 87616,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 83425352,
    "24 hour?": "FALSE",
    "Latitude": 47.773577,
    "Longitude": 10.603584,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.773577,10.603584",
    "IsOpen24Hours": false,
    "id": "47.773577,10.603584"
  },
  {
    "ID": 306812615,
    "Tankstellennummer": "0FO73",
    "Name": "VOGT",
    "Address": "Wolfegger Straße 2",
    "Address__1": "",
    "City": "Vogt",
    "Postcode": 88267,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7529912991,
    "24 hour?": "FALSE",
    "Latitude": 47.775632,
    "Longitude": 9.770919,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.775632,9.770919",
    "IsOpen24Hours": false,
    "id": "47.775632,9.770919"
  },
  {
    "ID": 1497,
    "Tankstellennummer": "0F553",
    "Name": "Aral Tankstelle Ravensburg, Jahnstr. 40",
    "Address": "Jahnstraße 40",
    "Address__1": "",
    "City": "Ravensburg",
    "Postcode": 88214,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7513529743,
    "24 hour?": "TRUE",
    "Latitude": 47.775666,
    "Longitude": 9.603938,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.775666,9.603938",
    "IsOpen24Hours": true,
    "id": "47.775666,9.603938"
  },
  {
    "ID": 1664111271,
    "Tankstellennummer": 1612,
    "Name": "Agip Peissenberg",
    "Address": "Schongauer Str. 22",
    "Address__1": "",
    "City": "Peissenberg",
    "Postcode": 82380,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 88032091",
    "24 hour?": "FALSE",
    "Latitude": 47.79315314,
    "Longitude": 11.06287882,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.79315314,11.06287882",
    "IsOpen24Hours": false,
    "id": "47.79315314,11.06287882"
  },
  {
    "ID": 1906,
    "Tankstellennummer": "0F837",
    "Name": "Aral Tankstelle Peiting, Schongauer Strasse 19",
    "Address": "Schongauer Straße 19",
    "Address__1": "",
    "City": "Peiting",
    "Postcode": 86971,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8861693041,
    "24 hour?": "FALSE",
    "Latitude": 47.798668,
    "Longitude": 10.921203,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.798668,10.921203",
    "IsOpen24Hours": false,
    "id": "47.798668,10.921203"
  },
  {
    "ID": 1967,
    "Tankstellennummer": "0F876",
    "Name": "Aral Tankstelle Altusried, Kemptener Str 43",
    "Address": "Kemptener Straße 43",
    "Address__1": "",
    "City": "Altusried",
    "Postcode": 87452,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8373935375,
    "24 hour?": "FALSE",
    "Latitude": 47.801887,
    "Longitude": 10.225011,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.801887,10.225011",
    "IsOpen24Hours": false,
    "id": "47.801887,10.225011"
  },
  {
    "ID": 568193409,
    "Tankstellennummer": "TD037838",
    "Name": "ROHRDORF BAB SAMERBERG/SUED A8",
    "Address": "BAB SAMERBERG/SUED A8",
    "Address__1": "",
    "City": "ROHRDORF",
    "Postcode": 83101,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08032-98930",
    "24 hour?": "FALSE",
    "Latitude": 47.802,
    "Longitude": 12.1754,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.802,12.1754",
    "IsOpen24Hours": false,
    "id": "47.802,12.1754"
  },
  {
    "ID": 571529544,
    "Tankstellennummer": "0FQ11",
    "Name": "ROHRDORF",
    "Address": "A8",
    "Address__1": "",
    "City": "Rohrdorf",
    "Postcode": 83101,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8032956910,
    "24 hour?": "TRUE",
    "Latitude": 47.802957,
    "Longitude": 12.178136,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.802957,12.178136",
    "IsOpen24Hours": true,
    "id": "47.802957,12.178136"
  },
  {
    "ID": 1927,
    "Tankstellennummer": "0F852",
    "Name": "Aral Tankstelle Raubling, Rosenheimer Strasse 83",
    "Address": "Rosenheimer Straße 83",
    "Address__1": "",
    "City": "Raubling",
    "Postcode": 83064,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 80352766,
    "24 hour?": "TRUE",
    "Latitude": 47.804791,
    "Longitude": 12.119877,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.804791,12.119877",
    "IsOpen24Hours": true,
    "id": "47.804791,12.119877"
  },
  {
    "ID": 1892,
    "Tankstellennummer": "0F831",
    "Name": "Aral Tankstelle Ainring, Franz Wisbacher Str.8",
    "Address": "An der B20 Nr.2",
    "Address__1": "",
    "City": "Ainring",
    "Postcode": 83404,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 86547725240,
    "24 hour?": "FALSE",
    "Latitude": 47.8055914,
    "Longitude": 12.9573657,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "47.8055914,12.9573657",
    "IsOpen24Hours": false,
    "id": "47.8055914,12.9573657"
  },
  {
    "ID": 557841856,
    "Tankstellennummer": "0FO29",
    "Name": "DIETMANNSRIED",
    "Address": "Heisinger Straße 25",
    "Address__1": "",
    "City": "Dietmannsried",
    "Postcode": 87463,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 83746710,
    "24 hour?": "FALSE",
    "Latitude": 47.806843,
    "Longitude": 10.296286,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.806843,10.296286",
    "IsOpen24Hours": false,
    "id": "47.806843,10.296286"
  },
  {
    "ID": 1503,
    "Tankstellennummer": "0F557",
    "Name": "Aral Tankstelle Müllheim, Werderstrasse 105",
    "Address": "Werderstraße 105",
    "Address__1": "",
    "City": "Müllheim",
    "Postcode": 79379,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76313882,
    "24 hour?": "TRUE",
    "Latitude": 47.809064,
    "Longitude": 7.639596,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.809064,7.639596",
    "IsOpen24Hours": true,
    "id": "47.809064,7.639596"
  },
  {
    "ID": 1229098092,
    "Tankstellennummer": "0FO74",
    "Name": "WEINGARTEN",
    "Address": "Schussenstraße 10",
    "Address__1": "",
    "City": "Weingarten",
    "Postcode": 88250,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75145623,
    "24 hour?": "FALSE",
    "Latitude": 47.81205,
    "Longitude": 9.64076,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.81205,9.64076",
    "IsOpen24Hours": false,
    "id": "47.81205,9.64076"
  },
  {
    "ID": 1691292132,
    "Tankstellennummer": 1675,
    "Name": "AGIP Warngau",
    "Address": "Reitham 51",
    "Address__1": "",
    "City": "Warngau",
    "Postcode": 83627,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 8021-324",
    "24 hour?": "FALSE",
    "Latitude": 47.81595,
    "Longitude": 11.71347,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.81595,11.71347",
    "IsOpen24Hours": false,
    "id": "47.81595,11.71347"
  },
  {
    "ID": 2462,
    "Tankstellennummer": "0FC24",
    "Name": "Aral Tankstelle Weingarten, Waldseer Str.64",
    "Address": "Waldseer Straße 64",
    "Address__1": "",
    "City": "Weingarten",
    "Postcode": 88250,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 751561310,
    "24 hour?": "TRUE",
    "Latitude": 47.817707,
    "Longitude": 9.642318,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.817707,9.642318",
    "IsOpen24Hours": true,
    "id": "47.817707,9.642318"
  },
  {
    "ID": 3100,
    "Tankstellennummer": "0FJ26",
    "Name": "Aral Tankstelle Bonndorf, Lenzkircher Str. 24",
    "Address": "Lenzkircher Straße 24",
    "Address__1": "",
    "City": "Bonndorf",
    "Postcode": 79848,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 770393780,
    "24 hour?": "FALSE",
    "Latitude": 47.818336,
    "Longitude": 8.330662,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.818336,8.330662",
    "IsOpen24Hours": false,
    "id": "47.818336,8.330662"
  },
  {
    "ID": 1220775207,
    "Tankstellennummer": 1268,
    "Name": "Agip Rohrdorf",
    "Address": "Rosenheimer Str. 44",
    "Address__1": "",
    "City": "Rohrdorf",
    "Postcode": 83101,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 80317113",
    "24 hour?": "FALSE",
    "Latitude": 47.82453501,
    "Longitude": 12.15353384,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.82453501,12.15353384",
    "IsOpen24Hours": false,
    "id": "47.82453501,12.15353384"
  },
  {
    "ID": 260530332,
    "Tankstellennummer": 1283,
    "Name": "Agip Siegsdorf",
    "Address": "Traunsteiner Strasse 19",
    "Address__1": "",
    "City": "Siegsdorf",
    "Postcode": 83313,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 86626682",
    "24 hour?": "FALSE",
    "Latitude": 47.82500051,
    "Longitude": 12.63813139,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.82500051,12.63813139",
    "IsOpen24Hours": false,
    "id": "47.82500051,12.63813139"
  },
  {
    "ID": 16592335,
    "Tankstellennummer": 1635,
    "Name": "Agip Grabenstaett",
    "Address": "Oberwinkl 4",
    "Address__1": "",
    "City": "Grabenstaett",
    "Postcode": 83355,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "08661/982510",
    "24 hour?": "TRUE",
    "Latitude": 47.82775,
    "Longitude": 12.52103,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.82775,12.52103",
    "IsOpen24Hours": true,
    "id": "47.82775,12.52103"
  },
  {
    "ID": 1019023015,
    "Tankstellennummer": "TD041830",
    "Name": "GRABENSTAETT OBERWINKL 4",
    "Address": "OBERWINKL 4",
    "Address__1": "",
    "City": "GRABENSTAETT",
    "Postcode": 83355,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 8661982510,
    "24 hour?": "FALSE",
    "Latitude": 47.828,
    "Longitude": 12.5211,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.828,12.5211",
    "IsOpen24Hours": false,
    "id": "47.828,12.5211"
  },
  {
    "ID": 2424,
    "Tankstellennummer": "0FC03",
    "Name": "Aral Tankstelle Todtnau, Schönauer Str. 22",
    "Address": "Schönauer Straße 22",
    "Address__1": "",
    "City": "Todtnau",
    "Postcode": 79674,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76717499973,
    "24 hour?": "FALSE",
    "Latitude": 47.828035,
    "Longitude": 7.945025,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.828035,7.945025",
    "IsOpen24Hours": false,
    "id": "47.828035,7.945025"
  },
  {
    "ID": 1941869041,
    "Tankstellennummer": "0FN33",
    "Name": "FREILASSING",
    "Address": "Sonnenfeld 2",
    "Address__1": "",
    "City": "Freilassing",
    "Postcode": 83395,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 86547778780,
    "24 hour?": "FALSE",
    "Latitude": 47.842901,
    "Longitude": 12.970901,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.842901,12.970901",
    "IsOpen24Hours": false,
    "id": "47.842901,12.970901"
  },
  {
    "ID": 1973,
    "Tankstellennummer": "0F879",
    "Name": "Aral Tankstelle Obergünzburg, Kemptener Str 23",
    "Address": "Kemptener Straße 23",
    "Address__1": "",
    "City": "Obergünzburg",
    "Postcode": 87634,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8372496,
    "24 hour?": "FALSE",
    "Latitude": 47.843043,
    "Longitude": 10.416526,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.843043,10.416526",
    "IsOpen24Hours": false,
    "id": "47.843043,10.416526"
  },
  {
    "ID": 530017656,
    "Tankstellennummer": 1028,
    "Name": "Agip Weilheim",
    "Address": "Puetrichstrasse 29",
    "Address__1": "",
    "City": "Weilheim",
    "Postcode": 82362,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 8812885",
    "24 hour?": "FALSE",
    "Latitude": 47.84385,
    "Longitude": 11.14761,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.84385,11.14761",
    "IsOpen24Hours": false,
    "id": "47.84385,11.14761"
  },
  {
    "ID": 926401258,
    "Tankstellennummer": 2023,
    "Name": "Agip Stockach",
    "Address": "Radolfzeller Strasse 35",
    "Address__1": "",
    "City": "Stockach",
    "Postcode": 78333,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 77712625",
    "24 hour?": "FALSE",
    "Latitude": 47.84504617,
    "Longitude": 8.99927077,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.84504617,8.99927077",
    "IsOpen24Hours": false,
    "id": "47.84504617,8.99927077"
  },
  {
    "ID": 2463,
    "Tankstellennummer": "0FC25",
    "Name": "Aral Autohof Blumberg, An der Bundesstr.27",
    "Address": "Nordwerk 10",
    "Address__1": "",
    "City": "Blumberg",
    "Postcode": 78176,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 77025783,
    "24 hour?": "TRUE",
    "Latitude": 47.846209,
    "Longitude": 8.554895,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.846209,8.554895",
    "IsOpen24Hours": true,
    "id": "47.846209,8.554895"
  },
  {
    "ID": 1904,
    "Tankstellennummer": "0F836",
    "Name": "Aral Tankstelle Rosenheim, Kufsteiner Str. 57",
    "Address": "Kufsteiner Straße 57",
    "Address__1": "",
    "City": "Rosenheim",
    "Postcode": 83022,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 803113256,
    "24 hour?": "TRUE",
    "Latitude": 47.848767,
    "Longitude": 12.127539,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.848767,12.127539",
    "IsOpen24Hours": true,
    "id": "47.848767,12.127539"
  },
  {
    "ID": 1953,
    "Tankstellennummer": "0F866",
    "Name": "Aral Tankstelle Kolbermoor, Rosenheimer Str.30 A",
    "Address": "Rosenheimer Straße 30 a",
    "Address__1": "",
    "City": "Kolbermoor",
    "Postcode": 83059,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 803191354,
    "24 hour?": "FALSE",
    "Latitude": 47.849876,
    "Longitude": 12.067706,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.849876,12.067706",
    "IsOpen24Hours": false,
    "id": "47.849876,12.067706"
  },
  {
    "ID": 1901,
    "Tankstellennummer": "0F835",
    "Name": "Aral Tankstelle Kolbermoor, Aiblinger Au 52 a",
    "Address": "Aiblinger Au 52 a",
    "Address__1": "",
    "City": "Kolbermoor",
    "Postcode": 83059,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 80614695,
    "24 hour?": "TRUE",
    "Latitude": 47.851146,
    "Longitude": 12.017452,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.851146,12.017452",
    "IsOpen24Hours": true,
    "id": "47.851146,12.017452"
  },
  {
    "ID": 1870860139,
    "Tankstellennummer": "TD180490",
    "Name": "ENGEN AACHER STR. 18",
    "Address": "AACHER STR. 18",
    "Address__1": "",
    "City": "ENGEN",
    "Postcode": 78234,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07733-9399413",
    "24 hour?": "FALSE",
    "Latitude": 47.8557,
    "Longitude": 8.7816,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.8557,8.7816",
    "IsOpen24Hours": false,
    "id": "47.8557,8.7816"
  },
  {
    "ID": 1231855676,
    "Tankstellennummer": 1092,
    "Name": "Agip Rosenheim",
    "Address": "Hubertusstrasse 9",
    "Address__1": "",
    "City": "Rosenheim",
    "Postcode": 83022,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 80313710",
    "24 hour?": "FALSE",
    "Latitude": 47.85597168,
    "Longitude": 12.11058043,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.85597168,12.11058043",
    "IsOpen24Hours": false,
    "id": "47.85597168,12.11058043"
  },
  {
    "ID": 1931,
    "Tankstellennummer": "0F853",
    "Name": "Aral Tankstelle Stephanskirchen, Salzburger Str.64",
    "Address": "Salzburger Straße 64",
    "Address__1": "",
    "City": "Stephanskirchen",
    "Postcode": 83071,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 803171081,
    "24 hour?": "FALSE",
    "Latitude": 47.860393,
    "Longitude": 12.15434,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.860393,12.15434",
    "IsOpen24Hours": false,
    "id": "47.860393,12.15434"
  },
  {
    "ID": 574472276,
    "Tankstellennummer": "0FM26",
    "Name": "WEYARN",
    "Address": "Münchener Straße 32",
    "Address__1": "",
    "City": "Weyarn",
    "Postcode": 83629,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8020904680,
    "24 hour?": "TRUE",
    "Latitude": 47.863983,
    "Longitude": 11.795796,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.863983,11.795796",
    "IsOpen24Hours": true,
    "id": "47.863983,11.795796"
  },
  {
    "ID": 1963,
    "Tankstellennummer": "0F872",
    "Name": "Aral Tankstelle Traunstein, Äussere Rosenheimerstr23",
    "Address": "Äussere Rosenheimerstr23",
    "Address__1": "",
    "City": "Traunstein",
    "Postcode": 83278,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8613132,
    "24 hour?": "FALSE",
    "Latitude": 47.86706,
    "Longitude": 12.633873,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.86706,12.633873",
    "IsOpen24Hours": false,
    "id": "47.86706,12.633873"
  },
  {
    "ID": 144450186,
    "Tankstellennummer": "0FN34",
    "Name": "TRAUNSTEIN",
    "Address": "Chiemseestraße 50",
    "Address__1": "",
    "City": "Traunstein",
    "Postcode": 83278,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 86112973,
    "24 hour?": "FALSE",
    "Latitude": 47.868101,
    "Longitude": 12.622201,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.868101,12.622201",
    "IsOpen24Hours": false,
    "id": "47.868101,12.622201"
  },
  {
    "ID": 930881694,
    "Tankstellennummer": 1668,
    "Name": "Agip Traunstein",
    "Address": "Salzburger Strasse 1 b",
    "Address__1": "",
    "City": "Traunstein",
    "Postcode": 83278,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "08611/2512",
    "24 hour?": "FALSE",
    "Latitude": 47.86902,
    "Longitude": 12.66856,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.86902,12.66856",
    "IsOpen24Hours": false,
    "id": "47.86902,12.66856"
  },
  {
    "ID": 669433359,
    "Tankstellennummer": 1086,
    "Name": "Agip Bad Aibling",
    "Address": "Muenchner Strasse 40",
    "Address__1": "",
    "City": "Bad Aibling",
    "Postcode": 83043,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 80618508",
    "24 hour?": "FALSE",
    "Latitude": 47.87268102,
    "Longitude": 11.98067547,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.87268102,11.98067547",
    "IsOpen24Hours": false,
    "id": "47.87268102,11.98067547"
  },
  {
    "ID": 1939,
    "Tankstellennummer": "0F858",
    "Name": "Aral Tankstelle Holzkirchen, Miesbacher Strasse 33",
    "Address": "Miesbacher Straße 33",
    "Address__1": "",
    "City": "Holzkirchen",
    "Postcode": 83607,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8024998184,
    "24 hour?": "TRUE",
    "Latitude": 47.875805,
    "Longitude": 11.710166,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.875805,11.710166",
    "IsOpen24Hours": true,
    "id": "47.875805,11.710166"
  },
  {
    "ID": 586477089,
    "Tankstellennummer": "0FX90",
    "Name": "BAD WALDSEE",
    "Address": "Egelseestraße 33",
    "Address__1": "",
    "City": "Bad Waldsee",
    "Postcode": 88339,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75244644900,
    "24 hour?": "TRUE",
    "Latitude": 47.87763,
    "Longitude": 9.70462,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.87763,9.70462",
    "IsOpen24Hours": true,
    "id": "47.87763,9.70462"
  },
  {
    "ID": 63524459,
    "Tankstellennummer": 1066,
    "Name": "Agip Kaufbeuren",
    "Address": "Kemptener Strasse 61",
    "Address__1": "",
    "City": "Kaufbeuren",
    "Postcode": 87600,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 83418281",
    "24 hour?": "FALSE",
    "Latitude": 47.8782752,
    "Longitude": 10.61169268,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.8782752,10.61169268",
    "IsOpen24Hours": false,
    "id": "47.8782752,10.61169268"
  },
  {
    "ID": 1999,
    "Tankstellennummer": "0F899",
    "Name": "Aral Tankstelle Geretsried, Elbestr. 23",
    "Address": "Elbestraße 23",
    "Address__1": "",
    "City": "Geretsried",
    "Postcode": 82538,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 817164255,
    "24 hour?": "FALSE",
    "Latitude": 47.880446,
    "Longitude": 11.460822,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "47.880446,11.460822",
    "IsOpen24Hours": false,
    "id": "47.880446,11.460822"
  },
  {
    "ID": 790229088,
    "Tankstellennummer": "TD041962",
    "Name": "KINSAU BAHNHOFSTR. 8 A",
    "Address": "BAHNHOFSTR. 8 A",
    "Address__1": "",
    "City": "KINSAU",
    "Postcode": 86981,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 8869228,
    "24 hour?": "FALSE",
    "Latitude": 47.8817,
    "Longitude": 10.8915,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.8817,10.8915",
    "IsOpen24Hours": false,
    "id": "47.8817,10.8915"
  },
  {
    "ID": 2020,
    "Tankstellennummer": "0F916",
    "Name": "Aral Autohof Bad Grönenbach, Hinter den Gärten",
    "Address": "Weißenbachstr. 2",
    "Address__1": "",
    "City": "Bad Grönenbach",
    "Postcode": 87730,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8334986108,
    "24 hour?": "TRUE",
    "Latitude": 47.883913,
    "Longitude": 10.248217,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.883913,10.248217",
    "IsOpen24Hours": true,
    "id": "47.883913,10.248217"
  },
  {
    "ID": 1936,
    "Tankstellennummer": "0F856",
    "Name": "Aral Tankstelle Kaufbeuren, Augsburger Str. 32",
    "Address": "Augsburger Straße 32",
    "Address__1": "",
    "City": "Kaufbeuren",
    "Postcode": 87600,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 83414859,
    "24 hour?": "FALSE",
    "Latitude": 47.88454,
    "Longitude": 10.636712,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.88454,10.636712",
    "IsOpen24Hours": false,
    "id": "47.88454,10.636712"
  },
  {
    "ID": 1853813540,
    "Tankstellennummer": 1617,
    "Name": "Agip Rimsting",
    "Address": "Bahnhofstrasse 37",
    "Address__1": "",
    "City": "Rimsting",
    "Postcode": 83253,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 80516109",
    "24 hour?": "FALSE",
    "Latitude": 47.89013217,
    "Longitude": 12.34439795,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.89013217,12.34439795",
    "IsOpen24Hours": false,
    "id": "47.89013217,12.34439795"
  },
  {
    "ID": 1304650327,
    "Tankstellennummer": "0FN39",
    "Name": "CHIEMING",
    "Address": "Hauptstraße 23",
    "Address__1": "",
    "City": "Chieming",
    "Postcode": 83339,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8664927124,
    "24 hour?": "FALSE",
    "Latitude": 47.890352,
    "Longitude": 12.5372,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.890352,12.5372",
    "IsOpen24Hours": false,
    "id": "47.890352,12.5372"
  },
  {
    "ID": 866247512,
    "Tankstellennummer": "TD040436",
    "Name": "FRIEDENWEILER RASTANLAGE 10/ B",
    "Address": "RASTANLAGE 10/ B31",
    "Address__1": "",
    "City": "FRIEDENWEILER",
    "Postcode": 79877,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 76546829903,
    "24 hour?": "FALSE",
    "Latitude": 47.8974,
    "Longitude": 8.2744,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.8974,8.2744",
    "IsOpen24Hours": false,
    "id": "47.8974,8.2744"
  },
  {
    "ID": 539996273,
    "Tankstellennummer": 2242,
    "Name": "Agip Bad Waldsee",
    "Address": "Kammermoosstr. 20",
    "Address__1": "",
    "City": "Bad Waldsee",
    "Postcode": 88339,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 75243111",
    "24 hour?": "FALSE",
    "Latitude": 47.90225967,
    "Longitude": 9.6982389,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.90225967,9.6982389",
    "IsOpen24Hours": false,
    "id": "47.90225967,9.6982389"
  },
  {
    "ID": 2425,
    "Tankstellennummer": "0FC04",
    "Name": "Aral Tankstelle Bad Wurzach, Leutkircher Str.21",
    "Address": "Leutkircher Straße 21",
    "Address__1": "",
    "City": "Bad Wurzach",
    "Postcode": 88410,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75642364,
    "24 hour?": "FALSE",
    "Latitude": 47.905077,
    "Longitude": 9.895639,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.905077,9.895639",
    "IsOpen24Hours": false,
    "id": "47.905077,9.895639"
  },
  {
    "ID": 704180898,
    "Tankstellennummer": 1604,
    "Name": "Agip Kaufbeuren",
    "Address": "Mindelheimer Str. 78",
    "Address__1": "",
    "City": "Kaufbeuren",
    "Postcode": 87600,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 83411222",
    "24 hour?": "FALSE",
    "Latitude": 47.90606284,
    "Longitude": 10.60847739,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.90606284,10.60847739",
    "IsOpen24Hours": false,
    "id": "47.90606284,10.60847739"
  },
  {
    "ID": 1963914394,
    "Tankstellennummer": "0FP89",
    "Name": "HARTHEIM-BREMGARTEN",
    "Address": "An der BAB 5",
    "Address__1": "",
    "City": "Hartheim-Bremgarten",
    "Postcode": 79258,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76339202116,
    "24 hour?": "TRUE",
    "Latitude": 47.908259,
    "Longitude": 7.592319,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.908259,7.592319",
    "IsOpen24Hours": true,
    "id": "47.908259,7.592319"
  },
  {
    "ID": 3163,
    "Tankstellennummer": "0FJ67",
    "Name": "Aral Tankstelle Wolfratshausen, Sauerlacher Str. 64",
    "Address": "Sauerlacher Straße 64",
    "Address__1": "",
    "City": "Wolfratshausen",
    "Postcode": 82515,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 817120793,
    "24 hour?": "FALSE",
    "Latitude": 47.913915,
    "Longitude": 11.432373,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "47.913915,11.432373",
    "IsOpen24Hours": false,
    "id": "47.913915,11.432373"
  },
  {
    "ID": 657535203,
    "Tankstellennummer": "0FQ55",
    "Name": "TITISEE-NEUSTADT",
    "Address": "Titiseestraße 26",
    "Address__1": "",
    "City": "Titisee-Neustadt",
    "Postcode": 79822,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76519361997,
    "24 hour?": "FALSE",
    "Latitude": 47.91818,
    "Longitude": 8.20693,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "47.91818,8.20693",
    "IsOpen24Hours": false,
    "id": "47.91818,8.20693"
  },
  {
    "ID": 2447,
    "Tankstellennummer": "0FC20",
    "Name": "Aral Tankstelle Titisee-Neustadt, Titiseestr.21",
    "Address": "Titiseestraße 21",
    "Address__1": "",
    "City": "Titisee-Neustadt",
    "Postcode": 79822,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "07651/9361864",
    "24 hour?": "FALSE",
    "Latitude": 47.919573,
    "Longitude": 8.205113,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "47.919573,8.205113",
    "IsOpen24Hours": false,
    "id": "47.919573,8.205113"
  },
  {
    "ID": 1008469057,
    "Tankstellennummer": "0FC15",
    "Name": "BAD KROZINGEN",
    "Address": "Freiburger Strasse 9",
    "Address__1": "",
    "City": "Bad Krozingen",
    "Postcode": 79189,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76333536,
    "24 hour?": "FALSE",
    "Latitude": 47.9199277,
    "Longitude": 7.7045613,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9199277,7.7045613",
    "IsOpen24Hours": false,
    "id": "47.9199277,7.7045613"
  },
  {
    "ID": 652169743,
    "Tankstellennummer": 2005,
    "Name": "Agip Bad Krozingen",
    "Address": "Freiburger Str. 3",
    "Address__1": "",
    "City": "Bad Krozingen",
    "Postcode": 79189,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 76339088",
    "24 hour?": "FALSE",
    "Latitude": 47.92133714,
    "Longitude": 7.70635386,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.92133714,7.70635386",
    "IsOpen24Hours": false,
    "id": "47.92133714,7.70635386"
  },
  {
    "ID": 2451,
    "Tankstellennummer": "0FC21",
    "Name": "Aral Tankstelle Pfullendorf, Überlinger Strasse 58",
    "Address": "Überlinger Straße 58",
    "Address__1": "",
    "City": "Pfullendorf",
    "Postcode": 88630,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75528625,
    "24 hour?": "FALSE",
    "Latitude": 47.927663,
    "Longitude": 9.243076,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "47.927663,9.243076",
    "IsOpen24Hours": false,
    "id": "47.927663,9.243076"
  },
  {
    "ID": 1914,
    "Tankstellennummer": "0F843",
    "Name": "Aral Tankstelle Waging, Salzburger Strasse 49",
    "Address": "Salzburger Straße 49",
    "Address__1": "",
    "City": "Waging",
    "Postcode": 83329,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 86814379,
    "24 hour?": "FALSE",
    "Latitude": 47.934114,
    "Longitude": 12.741812,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.934114,12.741812",
    "IsOpen24Hours": false,
    "id": "47.934114,12.741812"
  },
  {
    "ID": 675660443,
    "Tankstellennummer": "TD180680",
    "Name": "BAD KROZINGEN-HAUSEN TUNIBERGS",
    "Address": "TUNIBERGSTR. 2",
    "Address__1": "",
    "City": "BAD KROZINGEN-HAUSEN",
    "Postcode": 79189,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07633-14592",
    "24 hour?": "FALSE",
    "Latitude": 47.9537,
    "Longitude": 7.6698,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9537,7.6698",
    "IsOpen24Hours": false,
    "id": "47.9537,7.6698"
  },
  {
    "ID": 1812034338,
    "Tankstellennummer": 1659,
    "Name": "Agip Waging",
    "Address": "Hauptstr. 23",
    "Address__1": "",
    "City": "Waging",
    "Postcode": 83329,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "08681/4631",
    "24 hour?": "FALSE",
    "Latitude": 47.95683,
    "Longitude": 12.75798,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.95683,12.75798",
    "IsOpen24Hours": false,
    "id": "47.95683,12.75798"
  },
  {
    "ID": 3185,
    "Tankstellennummer": "0FJ78",
    "Name": "Aral Tankstelle Diessen, Lachnerstr. 56",
    "Address": "Lachener Straße 56",
    "Address__1": "",
    "City": "Dießen",
    "Postcode": 86911,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 88078776,
    "24 hour?": "FALSE",
    "Latitude": 47.957967,
    "Longitude": 11.102196,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.957967,11.102196",
    "IsOpen24Hours": false,
    "id": "47.957967,11.102196"
  },
  {
    "ID": 558948266,
    "Tankstellennummer": "0FN55",
    "Name": "TRAUNREUT",
    "Address": "Werner-von-Siemens-Straße 3",
    "Address__1": "",
    "City": "Traunreut",
    "Postcode": 83301,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 86692368,
    "24 hour?": "FALSE",
    "Latitude": 47.966321,
    "Longitude": 12.591563,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.966321,12.591563",
    "IsOpen24Hours": false,
    "id": "47.966321,12.591563"
  },
  {
    "ID": 2445,
    "Tankstellennummer": "0FC18",
    "Name": "Aral Tankstelle Tuttlingen, Bei der Pürschmark 4",
    "Address": "Bei der Pürschmark 4",
    "Address__1": "",
    "City": "Tuttlingen",
    "Postcode": 78532,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7461164574,
    "24 hour?": "FALSE",
    "Latitude": 47.967969,
    "Longitude": 8.787334,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "47.967969,8.787334",
    "IsOpen24Hours": false,
    "id": "47.967969,8.787334"
  },
  {
    "ID": 1899,
    "Tankstellennummer": "0F834",
    "Name": "Aral Tankstelle Memmingen, 34 Allgaeuer Str.",
    "Address": "Allgäuer Straße 34",
    "Address__1": "",
    "City": "Memmingen",
    "Postcode": 87700,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 833189251,
    "24 hour?": "FALSE",
    "Latitude": 47.972288,
    "Longitude": 10.182645,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.972288,10.182645",
    "IsOpen24Hours": false,
    "id": "47.972288,10.182645"
  },
  {
    "ID": 1885,
    "Tankstellennummer": "0F827",
    "Name": "Aral Tankstelle Sauerlach, Münchener Str.13",
    "Address": "Münchener Straße 13",
    "Address__1": "",
    "City": "Sauerlach",
    "Postcode": 82054,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 81049756,
    "24 hour?": "FALSE",
    "Latitude": 47.973675,
    "Longitude": 11.653654,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.973675,11.653654",
    "IsOpen24Hours": false,
    "id": "47.973675,11.653654"
  },
  {
    "ID": 2043178830,
    "Tankstellennummer": 2015,
    "Name": "Agip Freiburg",
    "Address": "Merzhauser Strasse 104",
    "Address__1": "",
    "City": "Freiburg",
    "Postcode": 79100,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 76140345",
    "24 hour?": "FALSE",
    "Latitude": 47.97979,
    "Longitude": 7.83187,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.97979,7.83187",
    "IsOpen24Hours": false,
    "id": "47.97979,7.83187"
  },
  {
    "ID": 2434,
    "Tankstellennummer": "0FC09",
    "Name": "Aral Tankstelle Freiburg, Freiburger Landstr. 23",
    "Address": "Freiburger Landstraße 23",
    "Address__1": "",
    "City": "Freiburg",
    "Postcode": 79112,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76644846,
    "24 hour?": "FALSE",
    "Latitude": 47.981943,
    "Longitude": 7.717552,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "47.981943,7.717552",
    "IsOpen24Hours": false,
    "id": "47.981943,7.717552"
  },
  {
    "ID": 2459,
    "Tankstellennummer": "0FC22",
    "Name": "Aral Tankstelle Freiburg, Schwarzwaldstr. 246",
    "Address": "Schwarzwaldstrasse 246",
    "Address__1": "",
    "City": "Freiburg",
    "Postcode": 79117,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76169077,
    "24 hour?": "FALSE",
    "Latitude": 47.987008,
    "Longitude": 7.895689,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.987008,7.895689",
    "IsOpen24Hours": false,
    "id": "47.987008,7.895689"
  },
  {
    "ID": 695206125,
    "Tankstellennummer": "TD000959",
    "Name": "FREIBURG BASLER STR. 57",
    "Address": "BASLER STR. 57",
    "Address__1": "",
    "City": "FREIBURG",
    "Postcode": 79100,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 761402902,
    "24 hour?": "FALSE",
    "Latitude": 47.9871,
    "Longitude": 7.8385,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9871,7.8385",
    "IsOpen24Hours": false,
    "id": "47.9871,7.8385"
  },
  {
    "ID": 2461,
    "Tankstellennummer": "0FC23",
    "Name": "Aral Tankstelle Freiburg, Leo-Wohleb-Str. 2",
    "Address": "Leo-Wohleb-Straße 2",
    "Address__1": "",
    "City": "Freiburg",
    "Postcode": 79098,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 761709731,
    "24 hour?": "TRUE",
    "Latitude": 47.990339,
    "Longitude": 7.857909,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.990339,7.857909",
    "IsOpen24Hours": true,
    "id": "47.990339,7.857909"
  },
  {
    "ID": 1505,
    "Tankstellennummer": "0FE91",
    "Name": "Aral Tankstelle Freiburg, Besanconallee 30",
    "Address": "Besanconallee 30",
    "Address__1": "",
    "City": "Freiburg",
    "Postcode": 79111,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76145366413,
    "24 hour?": "TRUE",
    "Latitude": 47.990662,
    "Longitude": 7.797461,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "47.990662,7.797461",
    "IsOpen24Hours": true,
    "id": "47.990662,7.797461"
  },
  {
    "ID": 1135471521,
    "Tankstellennummer": "0FN49",
    "Name": "MEMMINGEN",
    "Address": "Buxheimer Straße 113",
    "Address__1": "",
    "City": "Memmingen",
    "Postcode": 87700,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 833161722,
    "24 hour?": "FALSE",
    "Latitude": 47.991688,
    "Longitude": 10.160623,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.991688,10.160623",
    "IsOpen24Hours": false,
    "id": "47.991688,10.160623"
  },
  {
    "ID": 584301847,
    "Tankstellennummer": "0FX99",
    "Name": "PROJEKT",
    "Address": "Bötzinger Straße 19",
    "Address__1": "",
    "City": "Freiburg im Breisgau",
    "Postcode": 79111,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "0761/21720504",
    "24 hour?": "FALSE",
    "Latitude": 47.9918289,
    "Longitude": 7.7890291,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "47.9918289,7.7890291",
    "IsOpen24Hours": false,
    "id": "47.9918289,7.7890291"
  },
  {
    "ID": 66026872,
    "Tankstellennummer": "TD181090",
    "Name": "FREIBURG ESCHHOLZSTR. 73",
    "Address": "ESCHHOLZSTR. 73",
    "Address__1": "",
    "City": "FREIBURG",
    "Postcode": 79106,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0761-272031",
    "24 hour?": "FALSE",
    "Latitude": 47.9956,
    "Longitude": 7.8344,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9956,7.8344",
    "IsOpen24Hours": false,
    "id": "47.9956,7.8344"
  },
  {
    "ID": 250879303,
    "Tankstellennummer": 2253,
    "Name": "Agip Messkirch",
    "Address": "Mengener Str. 37",
    "Address__1": "",
    "City": "Messkirch",
    "Postcode": 88605,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 75759263",
    "24 hour?": "FALSE",
    "Latitude": 47.99572,
    "Longitude": 9.12102,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.99572,9.12102",
    "IsOpen24Hours": false,
    "id": "47.99572,9.12102"
  },
  {
    "ID": 1922,
    "Tankstellennummer": "0F848",
    "Name": "Aral Tankstelle Memmingen, Münchener Strasse 66",
    "Address": "Münchener Straße 66",
    "Address__1": "",
    "City": "Memmingen",
    "Postcode": 87700,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 83315724,
    "24 hour?": "FALSE",
    "Latitude": 47.996337,
    "Longitude": 10.199143,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "47.996337,10.199143",
    "IsOpen24Hours": false,
    "id": "47.996337,10.199143"
  },
  {
    "ID": 809438261,
    "Tankstellennummer": 1203,
    "Name": "Agip Obing",
    "Address": "Seeonerstrasse 1",
    "Address__1": "",
    "City": "Obing",
    "Postcode": 83119,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 86241450",
    "24 hour?": "FALSE",
    "Latitude": 47.9997755,
    "Longitude": 12.41016086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9997755,12.41016086",
    "IsOpen24Hours": false,
    "id": "47.9997755,12.41016086"
  },
  {
    "ID": 1424,
    "Tankstellennummer": "0F496",
    "Name": "Aral Tankstelle Bad Schussenried, Bahnhofstr.30",
    "Address": "Bahnhofstraße 30",
    "Address__1": "",
    "City": "Bad Schussenried",
    "Postcode": 88427,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75832413,
    "24 hour?": "FALSE",
    "Latitude": 48.000251,
    "Longitude": 9.660136,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.000251,9.660136",
    "IsOpen24Hours": false,
    "id": "48.000251,9.660136"
  },
  {
    "ID": 39843010,
    "Tankstellennummer": "TD041590",
    "Name": "STARNBERG MUENCHNER STR. 16",
    "Address": "MUENCHNER STR. 16",
    "Address__1": "",
    "City": "STARNBERG",
    "Postcode": 82319,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08151-16785",
    "24 hour?": "FALSE",
    "Latitude": 48.0014,
    "Longitude": 11.3497,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0014,11.3497",
    "IsOpen24Hours": false,
    "id": "48.0014,11.3497"
  },
  {
    "ID": 28851651,
    "Tankstellennummer": "0FO49",
    "Name": "HERRSCHING",
    "Address": "Seefelder Straße 28",
    "Address__1": "",
    "City": "Herrsching",
    "Postcode": 82211,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 81528861,
    "24 hour?": "FALSE",
    "Latitude": 48.002339,
    "Longitude": 11.181872,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.002339,11.181872",
    "IsOpen24Hours": false,
    "id": "48.002339,11.181872"
  },
  {
    "ID": 1016749180,
    "Tankstellennummer": "TD038000",
    "Name": "UNGERHAUSEN GUTENBERGSTRASSE 2",
    "Address": "GUTENBERGSTRASSE 2",
    "Address__1": "",
    "City": "UNGERHAUSEN",
    "Postcode": 87781,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08393-9439170",
    "24 hour?": "FALSE",
    "Latitude": 48.0183,
    "Longitude": 10.2736,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0183,10.2736",
    "IsOpen24Hours": false,
    "id": "48.0183,10.2736"
  },
  {
    "ID": 831394716,
    "Tankstellennummer": "0FS23",
    "Name": "79108 Freiburg Engesser Str.13.",
    "Address": "Engesserstraße 13",
    "Address__1": "",
    "City": "Freiburg im Breisgau",
    "Postcode": 79108,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76168002380,
    "24 hour?": "FALSE",
    "Latitude": 48.0186701,
    "Longitude": 7.842227,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.0186701,7.842227",
    "IsOpen24Hours": false,
    "id": "48.0186701,7.842227"
  },
  {
    "ID": 1395,
    "Tankstellennummer": "0F475",
    "Name": "Aral Tankstelle Bad Saulgau, Herbertinger Straße 1",
    "Address": "Herbertinger Straße 1",
    "Address__1": "",
    "City": "Bad Saulgau",
    "Postcode": 88348,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7581488636,
    "24 hour?": "FALSE",
    "Latitude": 48.01911,
    "Longitude": 9.49707,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.01911,9.49707",
    "IsOpen24Hours": false,
    "id": "48.01911,9.49707"
  },
  {
    "ID": 1912,
    "Tankstellennummer": "0F842",
    "Name": "Aral Tankstelle Höhenkirchen-Siegert, Münchener Strasse 10",
    "Address": "Münchner Straße 10",
    "Address__1": "",
    "City": "Höhenkirchen-Siegert",
    "Postcode": 85635,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 81028284,
    "24 hour?": "FALSE",
    "Latitude": 48.019726,
    "Longitude": 11.709872,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.019726,11.709872",
    "IsOpen24Hours": false,
    "id": "48.019726,11.709872"
  },
  {
    "ID": 777839545,
    "Tankstellennummer": "TD001248",
    "Name": "BAD DUERRHEIM SCHEFFELSTR. 13",
    "Address": "SCHEFFELSTR. 13",
    "Address__1": "",
    "City": "BAD DUERRHEIM",
    "Postcode": 78073,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07726-7545",
    "24 hour?": "FALSE",
    "Latitude": 48.0212,
    "Longitude": 8.5279,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0212,8.5279",
    "IsOpen24Hours": false,
    "id": "48.0212,8.5279"
  },
  {
    "ID": 211229992,
    "Tankstellennummer": 1196,
    "Name": "Agip Trostberg",
    "Address": "Bayerstrasse 4",
    "Address__1": "",
    "City": "Trostberg",
    "Postcode": 83308,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 86212419",
    "24 hour?": "FALSE",
    "Latitude": 48.0270707,
    "Longitude": 12.556434,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0270707,12.556434",
    "IsOpen24Hours": false,
    "id": "48.0270707,12.556434"
  },
  {
    "ID": 3191,
    "Tankstellennummer": "0FJ80",
    "Name": "Aral Tankstelle Utting, Schondorfer Str. 12",
    "Address": "Schondorfer Straße 12",
    "Address__1": "",
    "City": "Utting am Ammersee",
    "Postcode": 86919,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 88067267,
    "24 hour?": "FALSE",
    "Latitude": 48.027708,
    "Longitude": 11.085583,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.027708,11.085583",
    "IsOpen24Hours": false,
    "id": "48.027708,11.085583"
  },
  {
    "ID": 1839264663,
    "Tankstellennummer": "TD039354",
    "Name": "RIETHEIM-WEILHEIM LANGES GEWAN",
    "Address": "LANGES GEWAND 3",
    "Address__1": "",
    "City": "RIETHEIM-WEILHEIM",
    "Postcode": 78604,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07461-969725100",
    "24 hour?": "FALSE",
    "Latitude": 48.0303,
    "Longitude": 8.7757,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0303,8.7757",
    "IsOpen24Hours": false,
    "id": "48.0303,8.7757"
  },
  {
    "ID": 769339255,
    "Tankstellennummer": "TD000180",
    "Name": "FREIBURG GUNDELFINGER STR. 27",
    "Address": "GUNDELFINGER STR. 27",
    "Address__1": "",
    "City": "FREIBURG",
    "Postcode": 79108,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0761-552674",
    "24 hour?": "FALSE",
    "Latitude": 48.0303,
    "Longitude": 7.8628,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0303,7.8628",
    "IsOpen24Hours": false,
    "id": "48.0303,7.8628"
  },
  {
    "ID": 261227671,
    "Tankstellennummer": "TD038661",
    "Name": "TUNINGEN TROSSINGER STR. 41",
    "Address": "TROSSINGER STR. 41",
    "Address__1": "",
    "City": "TUNINGEN",
    "Postcode": 78609,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07464-529989-0",
    "24 hour?": "FALSE",
    "Latitude": 48.0347,
    "Longitude": 8.61,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0347,8.61",
    "IsOpen24Hours": false,
    "id": "48.0347,8.61"
  },
  {
    "ID": 582897294,
    "Tankstellennummer": 1676,
    "Name": "Agip Heimertingen",
    "Address": "Memminger Str. 37",
    "Address__1": "",
    "City": "Heimertingen",
    "Postcode": 87751,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 8335/989",
    "24 hour?": "FALSE",
    "Latitude": 48.03481,
    "Longitude": 10.15321,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.03481,10.15321",
    "IsOpen24Hours": false,
    "id": "48.03481,10.15321"
  },
  {
    "ID": 545069649,
    "Tankstellennummer": "TD001101",
    "Name": "BAD DUERRHEIM SCHWENNINGER STR",
    "Address": "SCHWENNINGER STR. 38",
    "Address__1": "",
    "City": "BAD DUERRHEIM",
    "Postcode": 78073,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07726-7618",
    "24 hour?": "FALSE",
    "Latitude": 48.035,
    "Longitude": 8.5335,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.035,8.5335",
    "IsOpen24Hours": false,
    "id": "48.035,8.5335"
  },
  {
    "ID": 47672731,
    "Tankstellennummer": "0FN52",
    "Name": "BUCHLOE",
    "Address": "Landsberger Straße 20",
    "Address__1": "",
    "City": "Buchloe",
    "Postcode": 86807,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8241911291,
    "24 hour?": "FALSE",
    "Latitude": 48.03594,
    "Longitude": 10.732483,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.03594,10.732483",
    "IsOpen24Hours": false,
    "id": "48.03594,10.732483"
  },
  {
    "ID": 154673482,
    "Tankstellennummer": "0FO19",
    "Name": "MINDELHEIM",
    "Address": "Nebelhornstraße 8",
    "Address__1": "",
    "City": "Mindelheim",
    "Postcode": 87719,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 826120100,
    "24 hour?": "FALSE",
    "Latitude": 48.036085,
    "Longitude": 10.501371,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.036085,10.501371",
    "IsOpen24Hours": false,
    "id": "48.036085,10.501371"
  },
  {
    "ID": 1917,
    "Tankstellennummer": "0F846",
    "Name": "Aral Tankstelle Landsberg, Schongauer Str. 7",
    "Address": "Schongauer Straße 7",
    "Address__1": "",
    "City": "Landsberg",
    "Postcode": 86899,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 81912727,
    "24 hour?": "TRUE",
    "Latitude": 48.03891,
    "Longitude": 10.864785,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.03891,10.864785",
    "IsOpen24Hours": true,
    "id": "48.03891,10.864785"
  },
  {
    "ID": 869351726,
    "Tankstellennummer": 2218,
    "Name": "Agip Berkheim",
    "Address": "Oberopfinger Str. 25",
    "Address__1": "",
    "City": "Berkheim",
    "Postcode": 88450,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 8395658",
    "24 hour?": "FALSE",
    "Latitude": 48.04266963,
    "Longitude": 10.08407617,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.04266963,10.08407617",
    "IsOpen24Hours": false,
    "id": "48.04266963,10.08407617"
  },
  {
    "ID": 1393651071,
    "Tankstellennummer": "TD041970",
    "Name": "TUERKHEIM MINDELHEIMER STR. 1",
    "Address": "MINDELHEIMER STR. 1",
    "Address__1": "",
    "City": "TUERKHEIM",
    "Postcode": 86842,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 8245904530,
    "24 hour?": "FALSE",
    "Latitude": 48.0431,
    "Longitude": 10.62,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0431,10.62",
    "IsOpen24Hours": false,
    "id": "48.0431,10.62"
  },
  {
    "ID": 3217,
    "Tankstellennummer": "0FJ96",
    "Name": "Aral Tankstelle Mengen, Messkircher Str. 31",
    "Address": "Messkircher Straße 31",
    "Address__1": "",
    "City": "Mengen",
    "Postcode": 88512,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75725670,
    "24 hour?": "FALSE",
    "Latitude": 48.044291,
    "Longitude": 9.322006,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.044291,9.322006",
    "IsOpen24Hours": false,
    "id": "48.044291,9.322006"
  },
  {
    "ID": 1038963447,
    "Tankstellennummer": 1193,
    "Name": "Agip Gruenwald",
    "Address": "Suedl.Muenchener Str. 24 A",
    "Address__1": "",
    "City": "Gruenwald",
    "Postcode": 82031,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89641387",
    "24 hour?": "FALSE",
    "Latitude": 48.04465439,
    "Longitude": 11.52815993,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.04465439,11.52815993",
    "IsOpen24Hours": false,
    "id": "48.04465439,11.52815993"
  },
  {
    "ID": 2428,
    "Tankstellennummer": "0FC05",
    "Name": "Aral Tankstelle Furtwangen, Bregstrasse  40",
    "Address": "Bregstraße  40a",
    "Address__1": "",
    "City": "Furtwangen",
    "Postcode": 78120,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 77234460,
    "24 hour?": "FALSE",
    "Latitude": 48.045353,
    "Longitude": 8.196713,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.045353,8.196713",
    "IsOpen24Hours": false,
    "id": "48.045353,8.196713"
  },
  {
    "ID": 330189723,
    "Tankstellennummer": "TD181200",
    "Name": "GUNDELFINGEN VOERSTETTER STR.",
    "Address": "VOERSTETTER STR. 40",
    "Address__1": "",
    "City": "GUNDELFINGEN",
    "Postcode": 79194,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0761-585928",
    "24 hour?": "FALSE",
    "Latitude": 48.047,
    "Longitude": 7.8635,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.047,7.8635",
    "IsOpen24Hours": false,
    "id": "48.047,7.8635"
  },
  {
    "ID": 3189,
    "Tankstellennummer": "0FJ79",
    "Name": "Aral Tankstelle Grafing, Muenchner Str. 37",
    "Address": "Münchener Straße 37",
    "Address__1": "",
    "City": "Grafing",
    "Postcode": 85567,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 80921822,
    "24 hour?": "FALSE",
    "Latitude": 48.049402,
    "Longitude": 11.965836,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.049402,11.965836",
    "IsOpen24Hours": false,
    "id": "48.049402,11.965836"
  },
  {
    "ID": 841745039,
    "Tankstellennummer": 1285,
    "Name": "Agip Taufkirchen",
    "Address": "Lindenring 5",
    "Address__1": "",
    "City": "Taufkirchen",
    "Postcode": 82024,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89612171",
    "24 hour?": "FALSE",
    "Latitude": 48.0521,
    "Longitude": 11.60575,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0521,11.60575",
    "IsOpen24Hours": false,
    "id": "48.0521,11.60575"
  },
  {
    "ID": 866054170,
    "Tankstellennummer": "TD000855",
    "Name": "GLOTTERTAL IN DEN ENGEMATTEN 2",
    "Address": "IN DEN ENGEMATTEN 2",
    "Address__1": "",
    "City": "GLOTTERTAL",
    "Postcode": 79286,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07684-909083",
    "24 hour?": "FALSE",
    "Latitude": 48.0545,
    "Longitude": 7.9227,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0545,7.9227",
    "IsOpen24Hours": false,
    "id": "48.0545,7.9227"
  },
  {
    "ID": 567745173,
    "Tankstellennummer": 1264,
    "Name": "Agip Landsberg",
    "Address": "Augsburger Str. 23",
    "Address__1": "",
    "City": "Landsberg",
    "Postcode": 86899,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 81912483",
    "24 hour?": "FALSE",
    "Latitude": 48.05769588,
    "Longitude": 10.86800049,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.05769588,10.86800049",
    "IsOpen24Hours": false,
    "id": "48.05769588,10.86800049"
  },
  {
    "ID": 2423,
    "Tankstellennummer": "0FC02",
    "Name": "Aral Tankstelle V.S.-Schwenningen, Neckarstr. 68",
    "Address": "Neckarstraße 68",
    "Address__1": "",
    "City": "V.S.-Schwenningen",
    "Postcode": 78056,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7720956960,
    "24 hour?": "TRUE",
    "Latitude": 48.057713,
    "Longitude": 8.535515,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.057713,8.535515",
    "IsOpen24Hours": true,
    "id": "48.057713,8.535515"
  },
  {
    "ID": 2437,
    "Tankstellennummer": "0FC12",
    "Name": "Aral Tankstelle V.S.-Villingen, Vöhrenbacher Str. 38",
    "Address": "Vöhrenbacherstraße 38",
    "Address__1": "",
    "City": "V.S.-Villingen",
    "Postcode": 78050,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 772153511,
    "24 hour?": "FALSE",
    "Latitude": 48.058018,
    "Longitude": 8.443937,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.058018,8.443937",
    "IsOpen24Hours": false,
    "id": "48.058018,8.443937"
  },
  {
    "ID": 774770919,
    "Tankstellennummer": "0FN57",
    "Name": "BAD BUCHAU",
    "Address": "Riedlinger Straße 67",
    "Address__1": "",
    "City": "Bad Buchau",
    "Postcode": 88422,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 758291015,
    "24 hour?": "FALSE",
    "Latitude": 48.059896,
    "Longitude": 9.594575,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.059896,9.594575",
    "IsOpen24Hours": false,
    "id": "48.059896,9.594575"
  },
  {
    "ID": 1883,
    "Tankstellennummer": "0F825",
    "Name": "Aral Tankstelle Wasserburg, Münchener Strasse 7",
    "Address": "Münchener Straße 7",
    "Address__1": "",
    "City": "Wasserburg",
    "Postcode": 83512,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 80719225808,
    "24 hour?": "FALSE",
    "Latitude": 48.061268,
    "Longitude": 12.209808,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.061268,12.209808",
    "IsOpen24Hours": false,
    "id": "48.061268,12.209808"
  },
  {
    "ID": 2421,
    "Tankstellennummer": "0FB99",
    "Name": "Aral Tankstelle Denzlingen, Bundesstr. 3",
    "Address": "Kronenstraße 42",
    "Address__1": "",
    "City": "Denzlingen",
    "Postcode": 79211,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76669132066,
    "24 hour?": "FALSE",
    "Latitude": 48.065614,
    "Longitude": 7.868078,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.065614,7.868078",
    "IsOpen24Hours": false,
    "id": "48.065614,7.868078"
  },
  {
    "ID": 1476,
    "Tankstellennummer": "0F538",
    "Name": "Aral Tankstelle Trossingen, Ernst-Haller-Strasse 62",
    "Address": "Ernst-Haller-Straße 62",
    "Address__1": "",
    "City": "Trossingen",
    "Postcode": 78647,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74256197,
    "24 hour?": "FALSE",
    "Latitude": 48.066772,
    "Longitude": 8.639078,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.066772,8.639078",
    "IsOpen24Hours": false,
    "id": "48.066772,8.639078"
  },
  {
    "ID": 1910,
    "Tankstellennummer": "0F841",
    "Name": "Aral Tankstelle Gauting, Münchener Str.42",
    "Address": "Münchener Straße 42",
    "Address__1": "",
    "City": "Gauting",
    "Postcode": 82131,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8996031470,
    "24 hour?": "FALSE",
    "Latitude": 48.067952,
    "Longitude": 11.387237,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.067952,11.387237",
    "IsOpen24Hours": false,
    "id": "48.067952,11.387237"
  },
  {
    "ID": 1570210198,
    "Tankstellennummer": "TD038174",
    "Name": "SIGMARINGENDORF BAHNHOFSTR. 4",
    "Address": "BAHNHOFSTR. 4 /B 33",
    "Address__1": "",
    "City": "SIGMARINGENDORF",
    "Postcode": 72517,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07571-62877",
    "24 hour?": "FALSE",
    "Latitude": 48.0686,
    "Longitude": 9.261,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0686,9.261",
    "IsOpen24Hours": false,
    "id": "48.0686,9.261"
  },
  {
    "ID": 8462239,
    "Tankstellennummer": 1186,
    "Name": "Agip Gauting",
    "Address": "Muenchner Strasse 54",
    "Address__1": "",
    "City": "Gauting",
    "Postcode": 82131,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89850512",
    "24 hour?": "FALSE",
    "Latitude": 48.0689585,
    "Longitude": 11.38861737,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0689585,11.38861737",
    "IsOpen24Hours": false,
    "id": "48.0689585,11.38861737"
  },
  {
    "ID": 744348792,
    "Tankstellennummer": "TD040956",
    "Name": "BOETZINGEN GOTTENHEIMER STR.16",
    "Address": "GOTTENHEIMER STR.16",
    "Address__1": "",
    "City": "BOETZINGEN",
    "Postcode": 79268,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 76639427420,
    "24 hour?": "FALSE",
    "Latitude": 48.0701,
    "Longitude": 7.7201,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0701,7.7201",
    "IsOpen24Hours": false,
    "id": "48.0701,7.7201"
  },
  {
    "ID": 1461790066,
    "Tankstellennummer": "0FT42",
    "Name": "OTTOBRUNN",
    "Address": "An der Westumgehung 1",
    "Address__1": "",
    "City": "Ottobrunn",
    "Postcode": 85521,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8962749631,
    "24 hour?": "FALSE",
    "Latitude": 48.072033,
    "Longitude": 11.652264,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.072033,11.652264",
    "IsOpen24Hours": false,
    "id": "48.072033,11.652264"
  },
  {
    "ID": 1021730457,
    "Tankstellennummer": 1174,
    "Name": "Agip Unterhaching",
    "Address": "Muenchner Str. 121A",
    "Address__1": "",
    "City": "Unterhaching",
    "Postcode": 82008,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89617338",
    "24 hour?": "FALSE",
    "Latitude": 48.07417955,
    "Longitude": 11.60450351,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.07417955,11.60450351",
    "IsOpen24Hours": false,
    "id": "48.07417955,11.60450351"
  },
  {
    "ID": 1385635415,
    "Tankstellennummer": 1188,
    "Name": "Agip Muenchen",
    "Address": "Herterichstrasse 111",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 81477,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89795346",
    "24 hour?": "FALSE",
    "Latitude": 48.07513,
    "Longitude": 11.51092,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.07513,11.51092",
    "IsOpen24Hours": false,
    "id": "48.07513,11.51092"
  },
  {
    "ID": 1449,
    "Tankstellennummer": "0F517",
    "Name": "Aral Tankstelle Sigmaringen, Messkircherstr.2a",
    "Address": "Messkircher Straße 2a",
    "Address__1": "",
    "City": "Sigmaringen",
    "Postcode": 72488,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 757151988,
    "24 hour?": "FALSE",
    "Latitude": 48.075634,
    "Longitude": 9.193497,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.075634,9.193497",
    "IsOpen24Hours": false,
    "id": "48.075634,9.193497"
  },
  {
    "ID": 1117723030,
    "Tankstellennummer": 1093,
    "Name": "Agip Muenchen",
    "Address": "Wolfratshauser Str. 232",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 81479,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89791228",
    "24 hour?": "TRUE",
    "Latitude": 48.07575912,
    "Longitude": 11.52380028,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.07575912,11.52380028",
    "IsOpen24Hours": true,
    "id": "48.07575912,11.52380028"
  },
  {
    "ID": 1395888325,
    "Tankstellennummer": "0FU18",
    "Name": "VILLINGEN-SCHWENNINGEN",
    "Address": "Drachenloch 3",
    "Address__1": "",
    "City": "Villingen-Schwenningen",
    "Postcode": 78052,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "07721/9959187",
    "24 hour?": "FALSE",
    "Latitude": 48.075969,
    "Longitude": 8.490755,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.075969,8.490755",
    "IsOpen24Hours": false,
    "id": "48.075969,8.490755"
  },
  {
    "ID": 825763989,
    "Tankstellennummer": "TD161340",
    "Name": "UNTERHACHING MUENCHNER STR. 92",
    "Address": "MUENCHNER STR. 92",
    "Address__1": "",
    "City": "UNTERHACHING",
    "Postcode": 82008,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "089-6114604",
    "24 hour?": "FALSE",
    "Latitude": 48.0778,
    "Longitude": 11.6011,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0778,11.6011",
    "IsOpen24Hours": false,
    "id": "48.0778,11.6011"
  },
  {
    "ID": 652656011,
    "Tankstellennummer": 1175,
    "Name": "Agip Unterhaching",
    "Address": "Albert-Schweitzer-Str. 68",
    "Address__1": "",
    "City": "Unterhaching",
    "Postcode": 82008,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89617016",
    "24 hour?": "FALSE",
    "Latitude": 48.08286446,
    "Longitude": 11.60232257,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.08286446,11.60232257",
    "IsOpen24Hours": false,
    "id": "48.08286446,11.60232257"
  },
  {
    "ID": 623487042,
    "Tankstellennummer": 1219,
    "Name": "Agip Biberach",
    "Address": "Waldseer Str. 107",
    "Address__1": "",
    "City": "Biberach",
    "Postcode": 88400,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 73512108",
    "24 hour?": "FALSE",
    "Latitude": 48.08592,
    "Longitude": 9.7959,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.08592,9.7959",
    "IsOpen24Hours": false,
    "id": "48.08592,9.7959"
  },
  {
    "ID": 1419,
    "Tankstellennummer": "0F492",
    "Name": "Aral Tankstelle Sigmaringen, Laizerstr.3",
    "Address": "Laizerstraße 3",
    "Address__1": "",
    "City": "Sigmaringen",
    "Postcode": 72488,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75713198,
    "24 hour?": "FALSE",
    "Latitude": 48.086857,
    "Longitude": 9.211907,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.086857,9.211907",
    "IsOpen24Hours": false,
    "id": "48.086857,9.211907"
  },
  {
    "ID": 1676041887,
    "Tankstellennummer": 1182,
    "Name": "Agip Kaufering",
    "Address": "Haidenbucher Strasse 2",
    "Address__1": "",
    "City": "Kaufering",
    "Postcode": 86916,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 81919662",
    "24 hour?": "FALSE",
    "Latitude": 48.08772898,
    "Longitude": 10.85619475,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.08772898,10.85619475",
    "IsOpen24Hours": false,
    "id": "48.08772898,10.85619475"
  },
  {
    "ID": 1330152439,
    "Tankstellennummer": "0FN35",
    "Name": "KIRCHWEIDACH",
    "Address": "Burghausener Straße 7",
    "Address__1": "",
    "City": "Kirchweidach",
    "Postcode": 84558,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8623240,
    "24 hour?": "FALSE",
    "Latitude": 48.087753,
    "Longitude": 12.645767,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.087753,12.645767",
    "IsOpen24Hours": false,
    "id": "48.087753,12.645767"
  },
  {
    "ID": 2036,
    "Tankstellennummer": "0FE96",
    "Name": "Aral Tankstelle Inning, Bruckerstr. 34 a",
    "Address": "Bruckerstraße 34 a",
    "Address__1": "",
    "City": "Inning",
    "Postcode": 82266,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8143997503,
    "24 hour?": "TRUE",
    "Latitude": 48.088772,
    "Longitude": 11.150987,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.088772,11.150987",
    "IsOpen24Hours": true,
    "id": "48.088772,11.150987"
  },
  {
    "ID": 791043082,
    "Tankstellennummer": 1076,
    "Name": "Agip Muenchen",
    "Address": "Neurieder Strasse 18",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 81475,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89751333",
    "24 hour?": "FALSE",
    "Latitude": 48.08914,
    "Longitude": 11.47439,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.08914,11.47439",
    "IsOpen24Hours": false,
    "id": "48.08914,11.47439"
  },
  {
    "ID": 204324894,
    "Tankstellennummer": "TD040295",
    "Name": "SIGMARINGEN MUEHLBERGSTR. 1",
    "Address": "1c MUEHLBERGSTR.",
    "Address__1": "",
    "City": "SIGMARINGEN",
    "Postcode": 72488,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07571-3200",
    "24 hour?": "FALSE",
    "Latitude": 48.0903,
    "Longitude": 9.2249,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0903,9.2249",
    "IsOpen24Hours": false,
    "id": "48.0903,9.2249"
  },
  {
    "ID": 2000,
    "Tankstellennummer": "0F900",
    "Name": "Aral Tankstelle Neuried, Forstenrieder Str. 26",
    "Address": "Forstenrieder Straße 26",
    "Address__1": "",
    "City": "Neuried",
    "Postcode": 82061,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8975070710,
    "24 hour?": "FALSE",
    "Latitude": 48.091406,
    "Longitude": 11.468299,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.091406,11.468299",
    "IsOpen24Hours": false,
    "id": "48.091406,11.468299"
  },
  {
    "ID": 3098,
    "Tankstellennummer": "0FJ24",
    "Name": "Aral Tankstelle Aldingen, Trossinger Str. 80",
    "Address": "Trossinger Straße 80",
    "Address__1": "",
    "City": "Aldingen",
    "Postcode": 78554,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74241717,
    "24 hour?": "FALSE",
    "Latitude": 48.091747,
    "Longitude": 8.690419,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.091747,8.690419",
    "IsOpen24Hours": false,
    "id": "48.091747,8.690419"
  },
  {
    "ID": 228479489,
    "Tankstellennummer": "TD041194",
    "Name": "BIBERACH RIEDLINGER STR. 52",
    "Address": "RIEDLINGER STR. 52",
    "Address__1": "",
    "City": "BIBERACH",
    "Postcode": 88400,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07351-10022",
    "24 hour?": "FALSE",
    "Latitude": 48.0957,
    "Longitude": 9.7795,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0957,9.7795",
    "IsOpen24Hours": false,
    "id": "48.0957,9.7795"
  },
  {
    "ID": 1964,
    "Tankstellennummer": "0F873",
    "Name": "Aral Tankstelle Vaterstetten, Wasserburger Landstr. 3",
    "Address": "Wasserburger Landstraße 3",
    "Address__1": "",
    "City": "Vaterstetten",
    "Postcode": 85591,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8106307446,
    "24 hour?": "TRUE",
    "Latitude": 48.096269,
    "Longitude": 11.771361,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.096269,11.771361",
    "IsOpen24Hours": true,
    "id": "48.096269,11.771361"
  },
  {
    "ID": 3179,
    "Tankstellennummer": "0FJ74",
    "Name": "Aral Tankstelle München, Gruenwalder Str. 175 C",
    "Address": "Grünwalder Straße 175 C",
    "Address__1": "",
    "City": "München",
    "Postcode": 81545,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8964270493,
    "24 hour?": "TRUE",
    "Latitude": 48.096381,
    "Longitude": 11.564114,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.096381,11.564114",
    "IsOpen24Hours": true,
    "id": "48.096381,11.564114"
  },
  {
    "ID": 3211,
    "Tankstellennummer": "0FJ94",
    "Name": "Aral Tankstelle Biberach, Rollinstr. 2",
    "Address": "Rollinstraße 2",
    "Address__1": "",
    "City": "Biberach",
    "Postcode": 88400,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 73519320,
    "24 hour?": "TRUE",
    "Latitude": 48.096917,
    "Longitude": 9.791901,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.096917,9.791901",
    "IsOpen24Hours": true,
    "id": "48.096917,9.791901"
  },
  {
    "ID": 1957,
    "Tankstellennummer": "0F868",
    "Name": "Aral Tankstelle München, Boschetsrieder Str. 127",
    "Address": "Boschetsrieder Straße 127",
    "Address__1": "",
    "City": "München",
    "Postcode": 81379,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89786153,
    "24 hour?": "FALSE",
    "Latitude": 48.098463,
    "Longitude": 11.518578,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.098463,11.518578",
    "IsOpen24Hours": false,
    "id": "48.098463,11.518578"
  },
  {
    "ID": 1776416006,
    "Tankstellennummer": "TD000859",
    "Name": "WALDKIRCH-KOLLNAU HAUPTSTR. 4",
    "Address": "HAUPTSTR. 4",
    "Address__1": "",
    "City": "WALDKIRCH-KOLLNAU",
    "Postcode": 79183,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07681-490424",
    "24 hour?": "FALSE",
    "Latitude": 48.1008,
    "Longitude": 7.9709,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1008,7.9709",
    "IsOpen24Hours": false,
    "id": "48.1008,7.9709"
  },
  {
    "ID": 2026471813,
    "Tankstellennummer": 1210,
    "Name": "Agip Planegg",
    "Address": "Bahnhofstr 1",
    "Address__1": "",
    "City": "Planegg",
    "Postcode": 82152,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89859415",
    "24 hour?": "FALSE",
    "Latitude": 48.10185854,
    "Longitude": 11.42627818,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.10185854,11.42627818",
    "IsOpen24Hours": false,
    "id": "48.10185854,11.42627818"
  },
  {
    "ID": 1961,
    "Tankstellennummer": "0F871",
    "Name": "Aral Tankstelle München, Tegernseer Landstr. 174",
    "Address": "Tegernseer Landstraße 174",
    "Address__1": "",
    "City": "München",
    "Postcode": 81539,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 896202810,
    "24 hour?": "TRUE",
    "Latitude": 48.107742,
    "Longitude": 11.579532,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.107742,11.579532",
    "IsOpen24Hours": true,
    "id": "48.107742,11.579532"
  },
  {
    "ID": 1977,
    "Tankstellennummer": "0F882",
    "Name": "Aral Tankstelle Haar, Münchener Strasse 16",
    "Address": "Münchener Straße 16",
    "Address__1": "",
    "City": "Haar",
    "Postcode": 85540,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89467300,
    "24 hour?": "TRUE",
    "Latitude": 48.108538,
    "Longitude": 11.722106,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.108538,11.722106",
    "IsOpen24Hours": true,
    "id": "48.108538,11.722106"
  },
  {
    "ID": 332283289,
    "Tankstellennummer": 1630,
    "Name": "Agip Muenchen",
    "Address": "Quiddestrae 31",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 81735,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89638498",
    "24 hour?": "FALSE",
    "Latitude": 48.10898,
    "Longitude": 11.64326,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.10898,11.64326",
    "IsOpen24Hours": false,
    "id": "48.10898,11.64326"
  },
  {
    "ID": 1664061845,
    "Tankstellennummer": 1058,
    "Name": "Agip Muenchen",
    "Address": "Boschetsrieder Str. 162",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 81379,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89748794",
    "24 hour?": "FALSE",
    "Latitude": 48.11107,
    "Longitude": 11.53658,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.11107,11.53658",
    "IsOpen24Hours": false,
    "id": "48.11107,11.53658"
  },
  {
    "ID": 1921,
    "Tankstellennummer": "0F847",
    "Name": "Aral Tankstelle München, Plinganserstr. 70",
    "Address": "Plinganser Str. 70",
    "Address__1": "",
    "City": "München",
    "Postcode": 81369,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89761566,
    "24 hour?": "TRUE",
    "Latitude": 48.112274,
    "Longitude": 11.539182,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.112274,11.539182",
    "IsOpen24Hours": true,
    "id": "48.112274,11.539182"
  },
  {
    "ID": 1547527036,
    "Tankstellennummer": 1079,
    "Name": "Agip Muenchen",
    "Address": "Innsbrucker Ring 149",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 81669,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89689199",
    "24 hour?": "TRUE",
    "Latitude": 48.11435095,
    "Longitude": 11.61760989,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.11435095,11.61760989",
    "IsOpen24Hours": true,
    "id": "48.11435095,11.61760989"
  },
  {
    "ID": 1840212386,
    "Tankstellennummer": "0FO16",
    "Name": "GRÄFELFING",
    "Address": "Pasinger Straße 50",
    "Address__1": "",
    "City": "Gräfelfing",
    "Postcode": 82166,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 898543576,
    "24 hour?": "FALSE",
    "Latitude": 48.118098,
    "Longitude": 11.442665,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.118098,11.442665",
    "IsOpen24Hours": false,
    "id": "48.118098,11.442665"
  },
  {
    "ID": 1960,
    "Tankstellennummer": "0F870",
    "Name": "Aral Tankstelle München, Heinrich-Wieland-Str. 2",
    "Address": "Heinrich-Wieland-Straße 2",
    "Address__1": "",
    "City": "München",
    "Postcode": 81735,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89401320,
    "24 hour?": "TRUE",
    "Latitude": 48.118328,
    "Longitude": 11.63108,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.118328,11.63108",
    "IsOpen24Hours": true,
    "id": "48.118328,11.63108"
  },
  {
    "ID": 3173,
    "Tankstellennummer": "0FJ72",
    "Name": "Aral Tankstelle München, Waldwiesenstr. 31",
    "Address": "Waldwiesenstraße 31",
    "Address__1": "",
    "City": "München",
    "Postcode": 81375,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8974029166,
    "24 hour?": "FALSE",
    "Latitude": 48.119009,
    "Longitude": 11.478235,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.119009,11.478235",
    "IsOpen24Hours": false,
    "id": "48.119009,11.478235"
  },
  {
    "ID": 1880,
    "Tankstellennummer": "0F824",
    "Name": "Aral Tankstelle München, Rosenheimer Str. 138",
    "Address": "Rosenheimer Straße 138",
    "Address__1": "",
    "City": "München",
    "Postcode": 81669,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89401258,
    "24 hour?": "TRUE",
    "Latitude": 48.119206,
    "Longitude": 11.607772,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.119206,11.607772",
    "IsOpen24Hours": true,
    "id": "48.119206,11.607772"
  },
  {
    "ID": 1926,
    "Tankstellennummer": "0F851",
    "Name": "Aral Tankstelle München, Wasserburger Landstr.51",
    "Address": "Wasserburger Landstr. 51",
    "Address__1": "",
    "City": "München",
    "Postcode": 81825,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89424843,
    "24 hour?": "TRUE",
    "Latitude": 48.119722,
    "Longitude": 11.677734,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.119722,11.677734",
    "IsOpen24Hours": true,
    "id": "48.119722,11.677734"
  },
  {
    "ID": 1843159577,
    "Tankstellennummer": "0FO20",
    "Name": "PFAFFENHAUSEN",
    "Address": "Mindelheimer Straße 19",
    "Address__1": "",
    "City": "Pfaffenhausen",
    "Postcode": 87772,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 82657332333,
    "24 hour?": "FALSE",
    "Latitude": 48.120366,
    "Longitude": 10.462015,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.120366,10.462015",
    "IsOpen24Hours": false,
    "id": "48.120366,10.462015"
  },
  {
    "ID": 1916,
    "Tankstellennummer": "0F845",
    "Name": "Aral Tankstelle München, Garmischer Str. 197",
    "Address": "Garmischer Straße 197",
    "Address__1": "",
    "City": "München",
    "Postcode": 81377,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 897608904,
    "24 hour?": "TRUE",
    "Latitude": 48.121346,
    "Longitude": 11.518817,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.121346,11.518817",
    "IsOpen24Hours": true,
    "id": "48.121346,11.518817"
  },
  {
    "ID": 1200401814,
    "Tankstellennummer": 1222,
    "Name": "Agip Muenchen",
    "Address": "Impler Str. 21",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 81371,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89725771",
    "24 hour?": "TRUE",
    "Latitude": 48.12237785,
    "Longitude": 11.54864215,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.12237785,11.54864215",
    "IsOpen24Hours": true,
    "id": "48.12237785,11.54864215"
  },
  {
    "ID": 1973302458,
    "Tankstellennummer": "0FU80",
    "Name": "DETTINGEN AN DER ILLER",
    "Address": "A7",
    "Address__1": "",
    "City": "Dettingen an der Iller",
    "Postcode": 88451,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7354576,
    "24 hour?": "TRUE",
    "Latitude": 48.123857,
    "Longitude": 10.112559,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.123857,10.112559",
    "IsOpen24Hours": true,
    "id": "48.123857,10.112559"
  },
  {
    "ID": 3170,
    "Tankstellennummer": "0FJ70",
    "Name": "Aral Tankstelle München, Kapuzinerstr. 46",
    "Address": "Kapuzinerstraße 46",
    "Address__1": "",
    "City": "München",
    "Postcode": 80469,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89761224,
    "24 hour?": "TRUE",
    "Latitude": 48.12389,
    "Longitude": 11.564831,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.12389,11.564831",
    "IsOpen24Hours": true,
    "id": "48.12389,11.564831"
  },
  {
    "ID": 1648111574,
    "Tankstellennummer": "TD039669",
    "Name": "ST. GEORGEN BUNDESSTRASSE 16 A",
    "Address": "BUNDESSTRASSE 16 A",
    "Address__1": "",
    "City": "ST. GEORGEN",
    "Postcode": 78112,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07724-9497544",
    "24 hour?": "FALSE",
    "Latitude": 48.1241,
    "Longitude": 8.3381,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1241,8.3381",
    "IsOpen24Hours": false,
    "id": "48.1241,8.3381"
  },
  {
    "ID": 51176688,
    "Tankstellennummer": "TD041103",
    "Name": "STETTEN FROHNSTETTER STR.9",
    "Address": "FROHNSTETTER STR.9",
    "Address__1": "",
    "City": "STETTEN AM KALTEN MARKT",
    "Postcode": 72510,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 75739544672,
    "24 hour?": "FALSE",
    "Latitude": 48.1259,
    "Longitude": 9.0784,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1259,9.0784",
    "IsOpen24Hours": false,
    "id": "48.1259,9.0784"
  },
  {
    "ID": 2014,
    "Tankstellennummer": "0F912",
    "Name": "Aral Tankstelle Garching, Binderfeld 2",
    "Address": "Binderfeld 2",
    "Address__1": "",
    "City": "Garching",
    "Postcode": 84518,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8634400,
    "24 hour?": "TRUE",
    "Latitude": 48.125935,
    "Longitude": 12.574169,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.125935,12.574169",
    "IsOpen24Hours": true,
    "id": "48.125935,12.574169"
  },
  {
    "ID": 1402198196,
    "Tankstellennummer": "TD000536",
    "Name": "ST. GEORGEN BUNDESSTRASSE 1D",
    "Address": "BUNDESSTRASSE 1D",
    "Address__1": "",
    "City": "ST. GEORGEN",
    "Postcode": 78112,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07724-1238",
    "24 hour?": "FALSE",
    "Latitude": 48.1267,
    "Longitude": 8.344,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1267,8.344",
    "IsOpen24Hours": false,
    "id": "48.1267,8.344"
  },
  {
    "ID": 3344,
    "Tankstellennummer": "0FK94",
    "Name": "Aral Tankstelle München, Garmischer Str. 138",
    "Address": "Garmischer Straße 138",
    "Address__1": "",
    "City": "München",
    "Postcode": 80686,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8957868298,
    "24 hour?": "TRUE",
    "Latitude": 48.12715,
    "Longitude": 11.522233,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.12715,11.522233",
    "IsOpen24Hours": true,
    "id": "48.12715,11.522233"
  },
  {
    "ID": 1074973868,
    "Tankstellennummer": "TD000847",
    "Name": "EMMENDINGEN BUNDESSTRASSE B3 N",
    "Address": "BUNDESSTRASSE B3 Nr. 9",
    "Address__1": "",
    "City": "EMMENDINGEN",
    "Postcode": 79312,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07641-53951",
    "24 hour?": "FALSE",
    "Latitude": 48.1296,
    "Longitude": 7.8251,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1296,7.8251",
    "IsOpen24Hours": false,
    "id": "48.1296,7.8251"
  },
  {
    "ID": 3175,
    "Tankstellennummer": "0FJ73",
    "Name": "Aral Tankstelle München, Berg-am-Laim-Str. 60",
    "Address": "Berg-am-Laim-Straße 60",
    "Address__1": "",
    "City": "München",
    "Postcode": 81673,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8945409628,
    "24 hour?": "TRUE",
    "Latitude": 48.130509,
    "Longitude": 11.617173,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.130509,11.617173",
    "IsOpen24Hours": true,
    "id": "48.130509,11.617173"
  },
  {
    "ID": 1284709675,
    "Tankstellennummer": 1290,
    "Name": "Agip Muenchen",
    "Address": "Josephspitalstr. 12",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 80331,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89558692",
    "24 hour?": "FALSE",
    "Latitude": 48.1365,
    "Longitude": 11.56593,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1365,11.56593",
    "IsOpen24Hours": false,
    "id": "48.1365,11.56593"
  },
  {
    "ID": 840701273,
    "Tankstellennummer": "TD041699",
    "Name": "GERMERING LANDSBERGER STR.",
    "Address": "LANDSBERGER STR. 2",
    "Address__1": "",
    "City": "GERMERING",
    "Postcode": 82110,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 89847250,
    "24 hour?": "FALSE",
    "Latitude": 48.1367,
    "Longitude": 11.3911,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1367,11.3911",
    "IsOpen24Hours": false,
    "id": "48.1367,11.3911"
  },
  {
    "ID": 529723453,
    "Tankstellennummer": "0FQ39",
    "Name": "MUNCHEN",
    "Address": "Otto-Perutz-Straße 1-3",
    "Address__1": "",
    "City": "München",
    "Postcode": 81829,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89460990620,
    "24 hour?": "FALSE",
    "Latitude": 48.1379328,
    "Longitude": 11.711851,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1379328,11.711851",
    "IsOpen24Hours": false,
    "id": "48.1379328,11.711851"
  },
  {
    "ID": 1966,
    "Tankstellennummer": "0F875",
    "Name": "Aral Tankstelle Alling, Gilchingerstr.31",
    "Address": "Gilchingerstraße 31",
    "Address__1": "",
    "City": "Alling",
    "Postcode": 82239,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 814171979,
    "24 hour?": "FALSE",
    "Latitude": 48.138099,
    "Longitude": 11.299389,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.138099,11.299389",
    "IsOpen24Hours": false,
    "id": "48.138099,11.299389"
  },
  {
    "ID": 232770117,
    "Tankstellennummer": "TD041897",
    "Name": "GERMERING AUGSBURGER STR. 13",
    "Address": "AUGSBURGER STR. 13",
    "Address__1": "",
    "City": "GERMERING",
    "Postcode": 82110,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 898414000,
    "24 hour?": "FALSE",
    "Latitude": 48.1396,
    "Longitude": 11.3601,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1396,11.3601",
    "IsOpen24Hours": false,
    "id": "48.1396,11.3601"
  },
  {
    "ID": 1896,
    "Tankstellennummer": "0F833",
    "Name": "Aral Tankstelle München, Riemer Str. 360",
    "Address": "Riemer Straße 360",
    "Address__1": "",
    "City": "München",
    "Postcode": 81829,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89907116,
    "24 hour?": "FALSE",
    "Latitude": 48.140631,
    "Longitude": 11.683916,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.140631,11.683916",
    "IsOpen24Hours": false,
    "id": "48.140631,11.683916"
  },
  {
    "ID": 1592753641,
    "Tankstellennummer": 1663,
    "Name": "Agip Germering",
    "Address": "Lise-Meitner-Strasse 2",
    "Address__1": "",
    "City": "Germering",
    "Postcode": 82110,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "089/89408492",
    "24 hour?": "FALSE",
    "Latitude": 48.14067,
    "Longitude": 11.36856,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.14067,11.36856",
    "IsOpen24Hours": false,
    "id": "48.14067,11.36856"
  },
  {
    "ID": 3101,
    "Tankstellennummer": "0FJ27",
    "Name": "Aral Tankstelle Endingen, Koenigschaffhauser Str. 2",
    "Address": "Koenigschaffhauser Straße",
    "Address__1": "",
    "City": "Endingen",
    "Postcode": 79346,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7642920880,
    "24 hour?": "FALSE",
    "Latitude": 48.143505,
    "Longitude": 7.695344,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.143505,7.695344",
    "IsOpen24Hours": false,
    "id": "48.143505,7.695344"
  },
  {
    "ID": 18968,
    "Tankstellennummer": "0FP30",
    "Name": "Tankstelle München, Landsberger Straße 423-425",
    "Address": "Landsberger Straße 423-425",
    "Address__1": "",
    "City": "München",
    "Postcode": 81241,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8982072936,
    "24 hour?": "FALSE",
    "Latitude": 48.14511,
    "Longitude": 11.478615,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.14511,11.478615",
    "IsOpen24Hours": false,
    "id": "48.14511,11.478615"
  },
  {
    "ID": 555991534,
    "Tankstellennummer": 1091,
    "Name": "Agip Muenchen",
    "Address": "Landsberger Strasse 447",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 81241,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89833399",
    "24 hour?": "FALSE",
    "Latitude": 48.14532666,
    "Longitude": 11.48121739,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.14532666,11.48121739",
    "IsOpen24Hours": false,
    "id": "48.14532666,11.48121739"
  },
  {
    "ID": 1979,
    "Tankstellennummer": "0F884",
    "Name": "Aral Tankstelle München, Richard-Strauss-Str. 70",
    "Address": "Richard Strauss-Straße 70",
    "Address__1": "",
    "City": "München",
    "Postcode": 81679,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89915079,
    "24 hour?": "TRUE",
    "Latitude": 48.145718,
    "Longitude": 11.616339,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.145718,11.616339",
    "IsOpen24Hours": true,
    "id": "48.145718,11.616339"
  },
  {
    "ID": 1020133751,
    "Tankstellennummer": "TD040048",
    "Name": "PARSDORF GRUBER STR. 14",
    "Address": "GRUBER STR. 14",
    "Address__1": "",
    "City": "PARSDORF",
    "Postcode": 85599,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 8932601090,
    "24 hour?": "FALSE",
    "Latitude": 48.1477,
    "Longitude": 11.7878,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1477,11.7878",
    "IsOpen24Hours": false,
    "id": "48.1477,11.7878"
  },
  {
    "ID": 1390,
    "Tankstellennummer": "0F471",
    "Name": "Aral Tankstelle Riedlingen, Neue Unlinger St. 4",
    "Address": "Neue Unlinger St. 4",
    "Address__1": "",
    "City": "Riedlingen",
    "Postcode": 88499,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 737110286,
    "24 hour?": "TRUE",
    "Latitude": 48.14829,
    "Longitude": 9.485131,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.14829,9.485131",
    "IsOpen24Hours": true,
    "id": "48.14829,9.485131"
  },
  {
    "ID": 276090496,
    "Tankstellennummer": 1657,
    "Name": "Agip Moorenweis",
    "Address": "Tuerkenfelder Strasse 2",
    "Address__1": "",
    "City": "Moorenweis",
    "Postcode": 82272,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": 81469979172,
    "24 hour?": "FALSE",
    "Latitude": 48.14925,
    "Longitude": 11.07333,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.14925,11.07333",
    "IsOpen24Hours": false,
    "id": "48.14925,11.07333"
  },
  {
    "ID": 1944937330,
    "Tankstellennummer": 1184,
    "Name": "Agip Maitenbeth-Thal",
    "Address": "An Der B 12",
    "Address__1": "",
    "City": "Maitenbeth-Thal",
    "Postcode": 83558,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 8076631",
    "24 hour?": "FALSE",
    "Latitude": 48.15275325,
    "Longitude": 12.08951466,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.15275325,12.08951466",
    "IsOpen24Hours": false,
    "id": "48.15275325,12.08951466"
  },
  {
    "ID": 407477743,
    "Tankstellennummer": "0FN76",
    "Name": "ROTTWEIL",
    "Address": "Tuttlinger Straße 38",
    "Address__1": "",
    "City": "Rottweil",
    "Postcode": 78628,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74121398,
    "24 hour?": "FALSE",
    "Latitude": 48.153309,
    "Longitude": 8.639625,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.153309,8.639625",
    "IsOpen24Hours": false,
    "id": "48.153309,8.639625"
  },
  {
    "ID": 320782562,
    "Tankstellennummer": 1201,
    "Name": "Agip Feldkirchen",
    "Address": "Rastanlage Vaterstetten-Ost",
    "Address__1": "",
    "City": "Feldkirchen",
    "Postcode": 85622,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 81067096",
    "24 hour?": "TRUE",
    "Latitude": 48.15384142,
    "Longitude": 11.75169259,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.15384142,11.75169259",
    "IsOpen24Hours": true,
    "id": "48.15384142,11.75169259"
  },
  {
    "ID": 12659571,
    "Tankstellennummer": "TD038505",
    "Name": "HOHENLINDEN JOSEF-NEUMEIER-STR",
    "Address": "JOSEF-NEUMEIER-STR. 1",
    "Address__1": "",
    "City": "HOHENLINDEN",
    "Postcode": 85664,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08124/444842",
    "24 hour?": "FALSE",
    "Latitude": 48.1557,
    "Longitude": 11.9866,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1557,11.9866",
    "IsOpen24Hours": false,
    "id": "48.1557,11.9866"
  },
  {
    "ID": 36610092,
    "Tankstellennummer": "0FP86",
    "Name": "Aral Tankstelle, Forchheimer Str. 15",
    "Address": "Forchheimer Str. 15",
    "Address__1": "",
    "City": "Riegel am Kaiserstuhl",
    "Postcode": 79359,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76429078674,
    "24 hour?": "FALSE",
    "Latitude": 48.156771,
    "Longitude": 7.744626,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.156771,7.744626",
    "IsOpen24Hours": false,
    "id": "48.156771,7.744626"
  },
  {
    "ID": 2104631135,
    "Tankstellennummer": "TD002105",
    "Name": "MUENCHEN LEONRODSTR. 48",
    "Address": "LEONRODSTR. 48",
    "Address__1": "",
    "City": "MUENCHEN",
    "Postcode": 80636,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "089-1292340",
    "24 hour?": "FALSE",
    "Latitude": 48.1575,
    "Longitude": 11.5439,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1575,11.5439",
    "IsOpen24Hours": false,
    "id": "48.1575,11.5439"
  },
  {
    "ID": 1976,
    "Tankstellennummer": "0F881",
    "Name": "Aral Tankstelle München, Freischützstr. 4",
    "Address": "Freischützstraße 4",
    "Address__1": "",
    "City": "München",
    "Postcode": 81927,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89935312,
    "24 hour?": "FALSE",
    "Latitude": 48.157569,
    "Longitude": 11.642239,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.157569,11.642239",
    "IsOpen24Hours": false,
    "id": "48.157569,11.642239"
  },
  {
    "ID": 3169,
    "Tankstellennummer": "0FJ69",
    "Name": "Aral Tankstelle München, Nymphenburger Str. 205",
    "Address": "Nymphenburger Straße 205",
    "Address__1": "",
    "City": "München",
    "Postcode": 80639,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89160379,
    "24 hour?": "FALSE",
    "Latitude": 48.157731,
    "Longitude": 11.528273,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.157731,11.528273",
    "IsOpen24Hours": false,
    "id": "48.157731,11.528273"
  },
  {
    "ID": 1131352368,
    "Tankstellennummer": "TD161090",
    "Name": "KLOSTERLECHFELD SCHWABMUENCHNE",
    "Address": "SCHWABMUENCHNER STR. 16",
    "Address__1": "",
    "City": "KLOSTERLECHFELD",
    "Postcode": 86836,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08232-8088112",
    "24 hour?": "FALSE",
    "Latitude": 48.159,
    "Longitude": 10.8282,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.159,10.8282",
    "IsOpen24Hours": false,
    "id": "48.159,10.8282"
  },
  {
    "ID": 279802593,
    "Tankstellennummer": "0FJ71",
    "Name": "Aral TankStelle München, Schwere-Reiter-Straße 26 B",
    "Address": "Schwere-Reiter-Straße 26b",
    "Address__1": "",
    "City": "München",
    "Postcode": 80797,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89188503,
    "24 hour?": "TRUE",
    "Latitude": 48.161003,
    "Longitude": 11.558504,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.161003,11.558504",
    "IsOpen24Hours": true,
    "id": "48.161003,11.558504"
  },
  {
    "ID": 1937,
    "Tankstellennummer": "0F857",
    "Name": "Aral Tankstelle München, Landshuter Allee 163",
    "Address": "Landshuter Allee 163",
    "Address__1": "",
    "City": "München",
    "Postcode": 80637,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89156225,
    "24 hour?": "TRUE",
    "Latitude": 48.163555,
    "Longitude": 11.537343,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.163555,11.537343",
    "IsOpen24Hours": true,
    "id": "48.163555,11.537343"
  },
  {
    "ID": 1385013086,
    "Tankstellennummer": 1151,
    "Name": "Agip Muenchen",
    "Address": "Verdistrasse 141",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 81247,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89811133",
    "24 hour?": "TRUE",
    "Latitude": 48.16447242,
    "Longitude": 11.45922867,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.16447242,11.45922867",
    "IsOpen24Hours": true,
    "id": "48.16447242,11.45922867"
  },
  {
    "ID": 236975352,
    "Tankstellennummer": 1291,
    "Name": "Agip Aschheim",
    "Address": "Eichendorffstr. 20",
    "Address__1": "",
    "City": "Aschheim",
    "Postcode": 85609,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89909375",
    "24 hour?": "TRUE",
    "Latitude": 48.16518378,
    "Longitude": 11.7205828,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.16518378,11.7205828",
    "IsOpen24Hours": true,
    "id": "48.16518378,11.7205828"
  },
  {
    "ID": 1040967925,
    "Tankstellennummer": "TD038497",
    "Name": "HAAG AM SCHACHENFELD 1",
    "Address": "AM SCHACHENFELD 1",
    "Address__1": "",
    "City": "HAAG",
    "Postcode": 83527,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08072/370722",
    "24 hour?": "FALSE",
    "Latitude": 48.1652,
    "Longitude": 12.1636,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1652,12.1636",
    "IsOpen24Hours": false,
    "id": "48.1652,12.1636"
  },
  {
    "ID": 2109965963,
    "Tankstellennummer": 1154,
    "Name": "Agip Muenchen",
    "Address": "Oberfoehringer Str. 178",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 81925,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89951498",
    "24 hour?": "FALSE",
    "Latitude": 48.16625326,
    "Longitude": 11.62366635,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.16625326,11.62366635",
    "IsOpen24Hours": false,
    "id": "48.16625326,11.62366635"
  },
  {
    "ID": 601857205,
    "Tankstellennummer": 1034,
    "Name": "Agip Burghausen",
    "Address": "Perger Strasse 2",
    "Address__1": "",
    "City": "Burghausen",
    "Postcode": 84489,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 86776516",
    "24 hour?": "FALSE",
    "Latitude": 48.16638159,
    "Longitude": 12.82779441,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.16638159,12.82779441",
    "IsOpen24Hours": false,
    "id": "48.16638159,12.82779441"
  },
  {
    "ID": 790717474,
    "Tankstellennummer": 2191,
    "Name": "Agip Elzach",
    "Address": "Freiburger Str. 17B",
    "Address__1": "",
    "City": "Elzach",
    "Postcode": 79215,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 76827766",
    "24 hour?": "FALSE",
    "Latitude": 48.16706655,
    "Longitude": 8.05931736,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.16706655,8.05931736",
    "IsOpen24Hours": false,
    "id": "48.16706655,8.05931736"
  },
  {
    "ID": 819486760,
    "Tankstellennummer": 1152,
    "Name": "Agip Muenchen",
    "Address": "Cosimastrasse 234",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 81927,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89951631",
    "24 hour?": "FALSE",
    "Latitude": 48.17227843,
    "Longitude": 11.6368684,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.17227843,11.6368684",
    "IsOpen24Hours": false,
    "id": "48.17227843,11.6368684"
  },
  {
    "ID": 397635691,
    "Tankstellennummer": "0FO21",
    "Name": "SCHWABMUNCHEN",
    "Address": "Kaufbeurer Straße 5",
    "Address__1": "",
    "City": "Schwabmünchen",
    "Postcode": 86830,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 82324773,
    "24 hour?": "FALSE",
    "Latitude": 48.175881,
    "Longitude": 10.757688,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.175881,10.757688",
    "IsOpen24Hours": false,
    "id": "48.175881,10.757688"
  },
  {
    "ID": 1978,
    "Tankstellennummer": "0F883",
    "Name": "Aral Tankstelle München, Georg-Brauchle-Ring 27",
    "Address": "Georg-Brauchle-Ring 27",
    "Address__1": "",
    "City": "München",
    "Postcode": 80992,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89155116,
    "24 hour?": "TRUE",
    "Latitude": 48.176104,
    "Longitude": 11.535616,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.176104,11.535616",
    "IsOpen24Hours": true,
    "id": "48.176104,11.535616"
  },
  {
    "ID": 2032166524,
    "Tankstellennummer": 1160,
    "Name": "Agip Fuerstenfeldbruck",
    "Address": "Muenchner Strasse 24/26",
    "Address__1": "",
    "City": "Fuerstenfeldbruck",
    "Postcode": 82256,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 81416200",
    "24 hour?": "FALSE",
    "Latitude": 48.17665,
    "Longitude": 11.26006,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.17665,11.26006",
    "IsOpen24Hours": false,
    "id": "48.17665,11.26006"
  },
  {
    "ID": 1948,
    "Tankstellennummer": "0F865",
    "Name": "Aral Tankstelle München, Schenkendorfstr. 15",
    "Address": "Schenkendorfstraße 15",
    "Address__1": "",
    "City": "München",
    "Postcode": 80807,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 89364016,
    "24 hour?": "TRUE",
    "Latitude": 48.177293,
    "Longitude": 11.589353,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.177293,11.589353",
    "IsOpen24Hours": true,
    "id": "48.177293,11.589353"
  },
  {
    "ID": 2010,
    "Tankstellennummer": "0FG39",
    "Name": "Aral Tankstelle Fürstenfeldbruck, Zadarstr. 11",
    "Address": "Zadarstrasse 11",
    "Address__1": "",
    "City": "Fürstenfeldbruck",
    "Postcode": 82256,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8141355400,
    "24 hour?": "TRUE",
    "Latitude": 48.177534,
    "Longitude": 11.227286,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.177534,11.227286",
    "IsOpen24Hours": true,
    "id": "48.177534,11.227286"
  },
  {
    "ID": 493628578,
    "Tankstellennummer": 1262,
    "Name": "Agip Muenchen",
    "Address": "Von-Kahr-Str. 62",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 80999,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89812118",
    "24 hour?": "FALSE",
    "Latitude": 48.17759,
    "Longitude": 11.46744,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.17759,11.46744",
    "IsOpen24Hours": false,
    "id": "48.17759,11.46744"
  },
  {
    "ID": 1567636086,
    "Tankstellennummer": "TD036699",
    "Name": "MUENCHEN PETUELRING 92",
    "Address": "PETUELRING 92",
    "Address__1": "",
    "City": "MUENCHEN",
    "Postcode": 80807,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "089-3595277",
    "24 hour?": "FALSE",
    "Latitude": 48.1782,
    "Longitude": 11.5721,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1782,11.5721",
    "IsOpen24Hours": false,
    "id": "48.1782,11.5721"
  },
  {
    "ID": 1908,
    "Tankstellennummer": "0F839",
    "Name": "Aral Tankstelle München, Schleißheimer Str. 257",
    "Address": "Schleißheimer Straße 257",
    "Address__1": "",
    "City": "München",
    "Postcode": 80809,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 893508370,
    "24 hour?": "FALSE",
    "Latitude": 48.178788,
    "Longitude": 11.567097,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.178788,11.567097",
    "IsOpen24Hours": false,
    "id": "48.178788,11.567097"
  },
  {
    "ID": 1887,
    "Tankstellennummer": "0F829",
    "Name": "Aral Tankstelle München, Lochhausener Str. 229",
    "Address": "Lochhausener Straße 229",
    "Address__1": "",
    "City": "München",
    "Postcode": 81249,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8989711640,
    "24 hour?": "TRUE",
    "Latitude": 48.178951,
    "Longitude": 11.405146,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.178951,11.405146",
    "IsOpen24Hours": true,
    "id": "48.178951,11.405146"
  },
  {
    "ID": 1892745296,
    "Tankstellennummer": "TD039404",
    "Name": "MUENCHEN LERCHENAUERSTR. 75",
    "Address": "LERCHENAUERSTR. 75",
    "Address__1": "",
    "City": "MUENCHEN",
    "Postcode": 80809,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "089-23540304",
    "24 hour?": "FALSE",
    "Latitude": 48.1823,
    "Longitude": 11.5539,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1823,11.5539",
    "IsOpen24Hours": false,
    "id": "48.1823,11.5539"
  },
  {
    "ID": 2429,
    "Tankstellennummer": "0FC06",
    "Name": "Aral Tankstelle Elzach, Schrahöfe 3",
    "Address": "Schrahöfe 3",
    "Address__1": "",
    "City": "Elzach",
    "Postcode": 79215,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76829209402,
    "24 hour?": "FALSE",
    "Latitude": 48.184721,
    "Longitude": 8.085626,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.184721,8.085626",
    "IsOpen24Hours": false,
    "id": "48.184721,8.085626"
  },
  {
    "ID": 745525582,
    "Tankstellennummer": "TD007708",
    "Name": "GROEBENZELL OLCHINGER STR. 82",
    "Address": "OLCHINGER STR. 82",
    "Address__1": "",
    "City": "GROEBENZELL",
    "Postcode": 82194,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08142-58627",
    "24 hour?": "FALSE",
    "Latitude": 48.1861,
    "Longitude": 11.3842,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1861,11.3842",
    "IsOpen24Hours": false,
    "id": "48.1861,11.3842"
  },
  {
    "ID": 500957282,
    "Tankstellennummer": "TD039362",
    "Name": "MUENCHEN MOOSACHER STR. 36",
    "Address": "MOOSACHER STR.36",
    "Address__1": "",
    "City": "MUENCHEN",
    "Postcode": 80809,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "089/35756644",
    "24 hour?": "FALSE",
    "Latitude": 48.1865,
    "Longitude": 11.556,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1865,11.556",
    "IsOpen24Hours": false,
    "id": "48.1865,11.556"
  },
  {
    "ID": 378609554,
    "Tankstellennummer": "TD162560",
    "Name": "FORSTERN HAUPTSTR. 40",
    "Address": "HAUPTSTR. 40",
    "Address__1": "",
    "City": "FORSTERN",
    "Postcode": 85659,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08124-527059",
    "24 hour?": "FALSE",
    "Latitude": 48.1866,
    "Longitude": 11.9753,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1866,11.9753",
    "IsOpen24Hours": false,
    "id": "48.1866,11.9753"
  },
  {
    "ID": 1294250419,
    "Tankstellennummer": "TD041954",
    "Name": "MUENCHEN TRIEBSTR. 11 E",
    "Address": "TRIEBSTR. 11 E",
    "Address__1": "",
    "City": "MUENCHEN",
    "Postcode": 80993,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 891415222,
    "24 hour?": "FALSE",
    "Latitude": 48.1876,
    "Longitude": 11.5309,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1876,11.5309",
    "IsOpen24Hours": false,
    "id": "48.1876,11.5309"
  },
  {
    "ID": 1134619344,
    "Tankstellennummer": "TD040014",
    "Name": "MARKT SCHWABEN POINGER STR. 2",
    "Address": "POINGER STR. 2",
    "Address__1": "",
    "City": "MARKT SCHWABEN",
    "Postcode": 85570,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08121-4767555",
    "24 hour?": "FALSE",
    "Latitude": 48.1903,
    "Longitude": 11.8485,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1903,11.8485",
    "IsOpen24Hours": false,
    "id": "48.1903,11.8485"
  },
  {
    "ID": 1289734778,
    "Tankstellennummer": "TD037887",
    "Name": "MUENCHEN DETMOLDSTR. 1",
    "Address": "DETMOLDSTR. 1",
    "Address__1": "",
    "City": "MUENCHEN",
    "Postcode": 80935,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "089-35043040",
    "24 hour?": "FALSE",
    "Latitude": 48.1923,
    "Longitude": 11.5643,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1923,11.5643",
    "IsOpen24Hours": false,
    "id": "48.1923,11.5643"
  },
  {
    "ID": 3286,
    "Tankstellennummer": "0FK48",
    "Name": "Aral Tankstelle Markt Schwaben, Erdinger Str.25",
    "Address": "Erdinger Straße 25",
    "Address__1": "",
    "City": "Markt Schwaben",
    "Postcode": 85570,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 81216010,
    "24 hour?": "FALSE",
    "Latitude": 48.193274,
    "Longitude": 11.872268,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.193274,11.872268",
    "IsOpen24Hours": false,
    "id": "48.193274,11.872268"
  },
  {
    "ID": 1909,
    "Tankstellennummer": "0F840",
    "Name": "Aral Tankstelle Schwabmünchen, Augsburger Str.66",
    "Address": "Augsburger Straße 66",
    "Address__1": "",
    "City": "Schwabmünchen",
    "Postcode": 86830,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 82324790,
    "24 hour?": "FALSE",
    "Latitude": 48.193657,
    "Longitude": 10.75461,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.193657,10.75461",
    "IsOpen24Hours": false,
    "id": "48.193657,10.75461"
  },
  {
    "ID": 157281495,
    "Tankstellennummer": "TD181140",
    "Name": "KENZINGEN OFFENBURGER STR. 22",
    "Address": "OFFENBURGER STR. 22",
    "Address__1": "",
    "City": "KENZINGEN",
    "Postcode": 79341,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07644-2557940",
    "24 hour?": "FALSE",
    "Latitude": 48.1956,
    "Longitude": 7.7739,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1956,7.7739",
    "IsOpen24Hours": false,
    "id": "48.1956,7.7739"
  },
  {
    "ID": 1824401234,
    "Tankstellennummer": "0FO17",
    "Name": "GRABEN",
    "Address": "Landsberger Straße 2",
    "Address__1": "",
    "City": "Graben",
    "Postcode": 86836,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 82322230,
    "24 hour?": "TRUE",
    "Latitude": 48.198674,
    "Longitude": 10.853949,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.198674,10.853949",
    "IsOpen24Hours": true,
    "id": "48.198674,10.853949"
  },
  {
    "ID": 52620424,
    "Tankstellennummer": "0FQ66",
    "Name": "OLCHING",
    "Address": "Johann-G.-Gutenbergstraße 1",
    "Address__1": "",
    "City": "Olching",
    "Postcode": 82140,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 81423060,
    "24 hour?": "FALSE",
    "Latitude": 48.19951,
    "Longitude": 11.335941,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.19951,11.335941",
    "IsOpen24Hours": false,
    "id": "48.19951,11.335941"
  },
  {
    "ID": 1995,
    "Tankstellennummer": "0F895",
    "Name": "Aral Tankstelle München, Ingolstädter Str. 164-166",
    "Address": "Ingolstädter Str. 164-166",
    "Address__1": "",
    "City": "München",
    "Postcode": 80939,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8931699843,
    "24 hour?": "TRUE",
    "Latitude": 48.201777,
    "Longitude": 11.586149,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.201777,11.586149",
    "IsOpen24Hours": true,
    "id": "48.201777,11.586149"
  },
  {
    "ID": 657481642,
    "Tankstellennummer": 1276,
    "Name": "Agip Muenchen",
    "Address": "Dachauer Strasse 469",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 80993,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89148397",
    "24 hour?": "TRUE",
    "Latitude": 48.20245671,
    "Longitude": 11.49069687,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.20245671,11.49069687",
    "IsOpen24Hours": true,
    "id": "48.20245671,11.49069687"
  },
  {
    "ID": 1451,
    "Tankstellennummer": "0F519",
    "Name": "Aral Tankstelle Albstadt, Berliner Str. 55",
    "Address": "Berliner Straße 55",
    "Address__1": "",
    "City": "Albstadt",
    "Postcode": 72458,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74312697,
    "24 hour?": "TRUE",
    "Latitude": 48.205603,
    "Longitude": 9.037156,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.205603,9.037156",
    "IsOpen24Hours": true,
    "id": "48.205603,9.037156"
  },
  {
    "ID": 210759775,
    "Tankstellennummer": "TD041004",
    "Name": "DIETINGEN NECKARBURG OST A81",
    "Address": "NECKARBURG OST A81",
    "Address__1": "",
    "City": "DIETINGEN",
    "Postcode": 78661,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0741 8894",
    "24 hour?": "FALSE",
    "Latitude": 48.2058,
    "Longitude": 8.6251,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2058,8.6251",
    "IsOpen24Hours": false,
    "id": "48.2058,8.6251"
  },
  {
    "ID": 573369755,
    "Tankstellennummer": 2606,
    "Name": "Agip Albstadt",
    "Address": "Berliner Str. 30",
    "Address__1": "",
    "City": "Albstadt",
    "Postcode": 72458,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 74315116",
    "24 hour?": "FALSE",
    "Latitude": 48.20617118,
    "Longitude": 9.03445253,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.20617118,9.03445253",
    "IsOpen24Hours": false,
    "id": "48.20617118,9.03445253"
  },
  {
    "ID": 1877,
    "Tankstellennummer": "0F823",
    "Name": "Aral Autobahntankstelle Neckarburg West",
    "Address": "A81",
    "Address__1": "",
    "City": "Dietingen",
    "Postcode": 78661,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7417453,
    "24 hour?": "TRUE",
    "Latitude": 48.206218,
    "Longitude": 8.622761,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.206218,8.622761",
    "IsOpen24Hours": true,
    "id": "48.206218,8.622761"
  },
  {
    "ID": 751815308,
    "Tankstellennummer": "TD040253",
    "Name": "WALDKRAIBURG KRAIBURGER STR. 1",
    "Address": "KRAIBURGER STR. 13",
    "Address__1": "",
    "City": "WALDKRAIBURG",
    "Postcode": 84478,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08631-378131",
    "24 hour?": "FALSE",
    "Latitude": 48.2076,
    "Longitude": 12.4179,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2076,12.4179",
    "IsOpen24Hours": false,
    "id": "48.2076,12.4179"
  },
  {
    "ID": 296686179,
    "Tankstellennummer": "0FO23",
    "Name": "DIETENHEIM",
    "Address": "Illertisser Straße 40",
    "Address__1": "",
    "City": "Dietenheim",
    "Postcode": 89165,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 73477593,
    "24 hour?": "FALSE",
    "Latitude": 48.210491,
    "Longitude": 10.077365,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.210491,10.077365",
    "IsOpen24Hours": false,
    "id": "48.210491,10.077365"
  },
  {
    "ID": 1410210981,
    "Tankstellennummer": "TD041889",
    "Name": "PASTETTEN AM ETZFELD 2",
    "Address": "AM ETZFELD 2",
    "Address__1": "",
    "City": "PASTETTEN",
    "Postcode": 85669,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 48.2109,
    "Longitude": 11.9542,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2109,11.9542",
    "IsOpen24Hours": false,
    "id": "48.2109,11.9542"
  },
  {
    "ID": 1990,
    "Tankstellennummer": "0F893",
    "Name": "Aral Tankstelle Waldkraiburg, Teplitzer Str. 24",
    "Address": "Teplitzer Straße 24",
    "Address__1": "",
    "City": "Waldkraiburg",
    "Postcode": 84478,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 86384185,
    "24 hour?": "TRUE",
    "Latitude": 48.211636,
    "Longitude": 12.417323,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.211636,12.417323",
    "IsOpen24Hours": true,
    "id": "48.211636,12.417323"
  },
  {
    "ID": 331391556,
    "Tankstellennummer": 1212,
    "Name": "Agip St Wolfgang",
    "Address": "Gewerbestrasse 3",
    "Address__1": "",
    "City": "St Wolfgang",
    "Postcode": 84427,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 80851208",
    "24 hour?": "FALSE",
    "Latitude": 48.2123539,
    "Longitude": 12.13412406,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2123539,12.13412406",
    "IsOpen24Hours": false,
    "id": "48.2123539,12.13412406"
  },
  {
    "ID": 1958,
    "Tankstellennummer": "0F869",
    "Name": "Aral Tankstelle München, Dülferstr. 3",
    "Address": "Dülferstraße 3",
    "Address__1": "",
    "City": "München",
    "Postcode": 80933,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8931207480,
    "24 hour?": "FALSE",
    "Latitude": 48.212649,
    "Longitude": 11.560139,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.212649,11.560139",
    "IsOpen24Hours": false,
    "id": "48.212649,11.560139"
  },
  {
    "ID": 2431,
    "Tankstellennummer": "0FC07",
    "Name": "Aral Tankstelle Hornberg, Hauptstr.34",
    "Address": "Hauptstraße 34",
    "Address__1": "",
    "City": "Hornberg",
    "Postcode": 78132,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7833363,
    "24 hour?": "FALSE",
    "Latitude": 48.21382,
    "Longitude": 8.227461,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.21382,8.227461",
    "IsOpen24Hours": false,
    "id": "48.21382,8.227461"
  },
  {
    "ID": 2432,
    "Tankstellennummer": "0FC08",
    "Name": "Aral Tankstelle Herbolzheim, Hauptstrasse 106",
    "Address": "Hauptstraße 108",
    "Address__1": "",
    "City": "Herbolzheim",
    "Postcode": 79336,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 76434230,
    "24 hour?": "FALSE",
    "Latitude": 48.216332,
    "Longitude": 7.779243,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.216332,7.779243",
    "IsOpen24Hours": false,
    "id": "48.216332,7.779243"
  },
  {
    "ID": 1007945437,
    "Tankstellennummer": "TD041186",
    "Name": "ILLERTISSEN LEITSCHAECKER 2",
    "Address": "LEITSCHAECKER 2",
    "Address__1": "",
    "City": "ILLERTISSEN",
    "Postcode": 89257,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07303/1579395",
    "24 hour?": "FALSE",
    "Latitude": 48.2198,
    "Longitude": 10.1275,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2198,10.1275",
    "IsOpen24Hours": false,
    "id": "48.2198,10.1275"
  },
  {
    "ID": 2093826076,
    "Tankstellennummer": 1176,
    "Name": "Agip Ismaning",
    "Address": "Muenchner Str. 57",
    "Address__1": "",
    "City": "Ismaning",
    "Postcode": 85737,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89969238",
    "24 hour?": "FALSE",
    "Latitude": 48.22335767,
    "Longitude": 11.66946611,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.22335767,11.66946611",
    "IsOpen24Hours": false,
    "id": "48.22335767,11.66946611"
  },
  {
    "ID": 1647410276,
    "Tankstellennummer": 1215,
    "Name": "Agip Altoetting",
    "Address": "Mueldorferstr. 67",
    "Address__1": "",
    "City": "Altoetting",
    "Postcode": 84503,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 86719698",
    "24 hour?": "FALSE",
    "Latitude": 48.22635,
    "Longitude": 12.6588,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.22635,12.6588",
    "IsOpen24Hours": false,
    "id": "48.22635,12.6588"
  },
  {
    "ID": 1886311252,
    "Tankstellennummer": "0FS60",
    "Name": "Aral Tankstelle Dotternhausen",
    "Address": "Dormettinger Straße 13",
    "Address__1": "",
    "City": "Dotternhausen",
    "Postcode": 72359,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 742791015,
    "24 hour?": "FALSE",
    "Latitude": 48.227396,
    "Longitude": 8.783317,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.227396,8.783317",
    "IsOpen24Hours": false,
    "id": "48.227396,8.783317"
  },
  {
    "ID": 3438,
    "Tankstellennummer": "0FL70",
    "Name": "Aral Tankstelle Schramberg, Heiligenbronner Str. 9",
    "Address": "Heiligenbronner Straße 10",
    "Address__1": "",
    "City": "Schramberg",
    "Postcode": 78713,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74229921710,
    "24 hour?": "FALSE",
    "Latitude": 48.228911,
    "Longitude": 8.421462,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.228911,8.421462",
    "IsOpen24Hours": false,
    "id": "48.228911,8.421462"
  },
  {
    "ID": 1502,
    "Tankstellennummer": "0FE86",
    "Name": "Aral Tankstelle Laupheim, Ulmer Str.91",
    "Address": "Ulmer Straße 91",
    "Address__1": "",
    "City": "Laupheim",
    "Postcode": 88471,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7392168623,
    "24 hour?": "TRUE",
    "Latitude": 48.237538,
    "Longitude": 9.882225,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.237538,9.882225",
    "IsOpen24Hours": true,
    "id": "48.237538,9.882225"
  },
  {
    "ID": 1572909823,
    "Tankstellennummer": "0FS10",
    "Name": "Aral Tankstelle NEUÖTTING",
    "Address": "SIMBACHER STRAßE 36",
    "Address__1": "",
    "City": "NEUÖTTING",
    "Postcode": 84524,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 86718839794,
    "24 hour?": "TRUE",
    "Latitude": 48.239864,
    "Longitude": 12.710528,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.239864,12.710528",
    "IsOpen24Hours": true,
    "id": "48.239864,12.710528"
  },
  {
    "ID": 1019643386,
    "Tankstellennummer": "0FT41",
    "Name": "MUHLDORF",
    "Address": "Innstraße 16",
    "Address__1": "",
    "City": "Mühldorf",
    "Postcode": 84453,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 86319866933,
    "24 hour?": "FALSE",
    "Latitude": 48.241777,
    "Longitude": 12.519261,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.241777,12.519261",
    "IsOpen24Hours": false,
    "id": "48.241777,12.519261"
  },
  {
    "ID": 1940,
    "Tankstellennummer": "0F859",
    "Name": "Aral Tankstelle Krumbach, Augsburger Str.42",
    "Address": "Augsburger Straße 42",
    "Address__1": "",
    "City": "Krumbach",
    "Postcode": 86381,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 82823059,
    "24 hour?": "FALSE",
    "Latitude": 48.243901,
    "Longitude": 10.372405,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.243901,10.372405",
    "IsOpen24Hours": false,
    "id": "48.243901,10.372405"
  },
  {
    "ID": 1634644836,
    "Tankstellennummer": "TD039065",
    "Name": "DACHAU MUENCHNER STR. 87",
    "Address": "MUENCHNER STR. 87",
    "Address__1": "",
    "City": "DACHAU",
    "Postcode": 85221,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08131-5150350",
    "24 hour?": "FALSE",
    "Latitude": 48.2452,
    "Longitude": 11.4488,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2452,11.4488",
    "IsOpen24Hours": false,
    "id": "48.2452,11.4488"
  },
  {
    "ID": 1649802885,
    "Tankstellennummer": "TD040220",
    "Name": "MUEHLDORF MUENCHENER STR. 70",
    "Address": "MUENCHENER STR. 70",
    "Address__1": "",
    "City": "MUEHLDORF",
    "Postcode": 84453,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08631/37810",
    "24 hour?": "FALSE",
    "Latitude": 48.2457,
    "Longitude": 12.5105,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2457,12.5105",
    "IsOpen24Hours": false,
    "id": "48.2457,12.5105"
  },
  {
    "ID": 1421,
    "Tankstellennummer": "0F494",
    "Name": "Aral Tankstelle Gammertingen, Sigmaringer Strasse 40",
    "Address": "Sigmaringer Straße 40",
    "Address__1": "",
    "City": "Gammertingen",
    "Postcode": 72501,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 75749350076,
    "24 hour?": "FALSE",
    "Latitude": 48.246159,
    "Longitude": 9.222872,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.246159,9.222872",
    "IsOpen24Hours": false,
    "id": "48.246159,9.222872"
  },
  {
    "ID": 2033,
    "Tankstellennummer": "0F924",
    "Name": "Aral Tankstelle Garching, Schleißheimer Str. 124",
    "Address": "Schleißheimer Straße 124",
    "Address__1": "",
    "City": "Garching",
    "Postcode": 85748,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8932666948,
    "24 hour?": "TRUE",
    "Latitude": 48.250399,
    "Longitude": 11.6067,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.250399,11.6067",
    "IsOpen24Hours": true,
    "id": "48.250399,11.6067"
  },
  {
    "ID": 397102749,
    "Tankstellennummer": "TD040212",
    "Name": "MUEHLDORF EGGLKOFENSTR. 2",
    "Address": "EGGLKOFENSTR. 2",
    "Address__1": "",
    "City": "MUEHLDORF",
    "Postcode": 84453,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08631-378121",
    "24 hour?": "FALSE",
    "Latitude": 48.2564,
    "Longitude": 12.5337,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2564,12.5337",
    "IsOpen24Hours": false,
    "id": "48.2564,12.5337"
  },
  {
    "ID": 2011,
    "Tankstellennummer": "0FG31",
    "Name": "Aral Tankstelle Dachau, Alte Römerstraße 50",
    "Address": "Alte Römerstraße 50",
    "Address__1": "",
    "City": "Dachau",
    "Postcode": 85221,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8131333352,
    "24 hour?": "TRUE",
    "Latitude": 48.259686,
    "Longitude": 11.469937,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.259686,11.469937",
    "IsOpen24Hours": true,
    "id": "48.259686,11.469937"
  },
  {
    "ID": 3158,
    "Tankstellennummer": "0FJ64",
    "Name": "Aral Tankstelle Simbach, Muenchner Str. 50",
    "Address": "Muenchner Straße 50",
    "Address__1": "",
    "City": "Simbach",
    "Postcode": 84359,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 85712006,
    "24 hour?": "FALSE",
    "Latitude": 48.262976,
    "Longitude": 13.016449,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.262976,13.016449",
    "IsOpen24Hours": false,
    "id": "48.262976,13.016449"
  },
  {
    "ID": 1475,
    "Tankstellennummer": "0F537",
    "Name": "Aral Tankstelle Balingen, Wilhelm-Kraut-Str.111",
    "Address": "Wilhelm-Kraut-Straße111",
    "Address__1": "",
    "City": "Balingen",
    "Postcode": 72336,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74334278,
    "24 hour?": "FALSE",
    "Latitude": 48.264203,
    "Longitude": 8.852485,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.264203,8.852485",
    "IsOpen24Hours": false,
    "id": "48.264203,8.852485"
  },
  {
    "ID": 812568422,
    "Tankstellennummer": "TD039248",
    "Name": "OBERSCHLEISSHEIM HICKLSTR. 2",
    "Address": "HICKLSTR. 2",
    "Address__1": "",
    "City": "OBERSCHLEISSHEIM",
    "Postcode": 85764,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "089-21894466",
    "24 hour?": "FALSE",
    "Latitude": 48.2652,
    "Longitude": 11.5932,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2652,11.5932",
    "IsOpen24Hours": false,
    "id": "48.2652,11.5932"
  },
  {
    "ID": 853957741,
    "Tankstellennummer": 1608,
    "Name": "Agip Winhoering",
    "Address": "Neuoettinger Str. 36",
    "Address__1": "",
    "City": "Winhoering",
    "Postcode": 84543,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 86718820",
    "24 hour?": "FALSE",
    "Latitude": 48.26572561,
    "Longitude": 12.65133925,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.26572561,12.65133925",
    "IsOpen24Hours": false,
    "id": "48.26572561,12.65133925"
  },
  {
    "ID": 1611428715,
    "Tankstellennummer": 1031,
    "Name": "Agip Dorfen",
    "Address": "Haager Strasse 41",
    "Address__1": "",
    "City": "Dorfen",
    "Postcode": 84405,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 8081589",
    "24 hour?": "FALSE",
    "Latitude": 48.26914338,
    "Longitude": 12.15474119,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.26914338,12.15474119",
    "IsOpen24Hours": false,
    "id": "48.26914338,12.15474119"
  },
  {
    "ID": 331284565,
    "Tankstellennummer": "TD038521",
    "Name": "ERHARTING GEWERBEPARK 14",
    "Address": "GEWERBEPARK 14",
    "Address__1": "",
    "City": "ERHARTING",
    "Postcode": 84513,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08631-9895512",
    "24 hour?": "FALSE",
    "Latitude": 48.2729,
    "Longitude": 12.5505,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2729,12.5505",
    "IsOpen24Hours": false,
    "id": "48.2729,12.5505"
  },
  {
    "ID": 2030,
    "Tankstellennummer": "0F925",
    "Name": "Aral Tankstelle Mering, Ohmstraße 1",
    "Address": "Ohmstraße 1",
    "Address__1": "",
    "City": "Mering",
    "Postcode": 86415,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8233781227,
    "24 hour?": "TRUE",
    "Latitude": 48.274136,
    "Longitude": 10.977898,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.274136,10.977898",
    "IsOpen24Hours": true,
    "id": "48.274136,10.977898"
  },
  {
    "ID": 1024236832,
    "Tankstellennummer": "0FT59",
    "Name": "Aral Ettenheim",
    "Address": "Nikola-Tesla-Straße 1",
    "Address__1": "",
    "City": "Ettenheim",
    "Postcode": 77955,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "07822/3005688",
    "24 hour?": "FALSE",
    "Latitude": 48.274796,
    "Longitude": 7.785564,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.274796,7.785564",
    "IsOpen24Hours": false,
    "id": "48.274796,7.785564"
  },
  {
    "ID": 63881261,
    "Tankstellennummer": "TD040311",
    "Name": "MAHLBERG ORSCHWEIER ALTE LANDS",
    "Address": "ALTE LANDSTR.23-29",
    "Address__1": "",
    "City": "MAHLBERG-ORSCHWEIER",
    "Postcode": 77972,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07822-4349649",
    "24 hour?": "FALSE",
    "Latitude": 48.2749,
    "Longitude": 7.787,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2749,7.787",
    "IsOpen24Hours": false,
    "id": "48.2749,7.787"
  },
  {
    "ID": 747438616,
    "Tankstellennummer": 2250,
    "Name": "Agip Mahlberg",
    "Address": "Nicola-Tesla-Strae",
    "Address__1": "",
    "City": "Mahlberg",
    "Postcode": 77972,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 78224405",
    "24 hour?": "TRUE",
    "Latitude": 48.27536233,
    "Longitude": 7.78465699,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.27536233,7.78465699",
    "IsOpen24Hours": true,
    "id": "48.27536233,7.78465699"
  },
  {
    "ID": 3103,
    "Tankstellennummer": "0FJ29",
    "Name": "Aral Tankstelle Haslach, Steinacher Str. 7",
    "Address": "Steinacher Straße 7",
    "Address__1": "",
    "City": "Haslach",
    "Postcode": 77716,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 78322404,
    "24 hour?": "FALSE",
    "Latitude": 48.277731,
    "Longitude": 8.084292,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.277731,8.084292",
    "IsOpen24Hours": false,
    "id": "48.277731,8.084292"
  },
  {
    "ID": 951640727,
    "Tankstellennummer": "0FO15",
    "Name": "BOBINGEN",
    "Address": "Schalmeistraße 2",
    "Address__1": "",
    "City": "Bobingen",
    "Postcode": 86399,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 82348771,
    "24 hour?": "FALSE",
    "Latitude": 48.279162,
    "Longitude": 10.840743,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.279162,10.840743",
    "IsOpen24Hours": false,
    "id": "48.279162,10.840743"
  },
  {
    "ID": 1474054946,
    "Tankstellennummer": 1296,
    "Name": "Agip Unterschleiheim",
    "Address": "Bezirksstrae 29",
    "Address__1": "",
    "City": "Unterschleiheim",
    "Postcode": 85716,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89310511",
    "24 hour?": "FALSE",
    "Latitude": 48.28175955,
    "Longitude": 11.575789,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.28175955,11.575789",
    "IsOpen24Hours": false,
    "id": "48.28175955,11.575789"
  },
  {
    "ID": 759489127,
    "Tankstellennummer": "TD182370",
    "Name": "HAUSACH HECHTSBERG 8",
    "Address": "HECHTSBERG 8",
    "Address__1": "",
    "City": "HAUSACH",
    "Postcode": 77756,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 78314149988,
    "24 hour?": "FALSE",
    "Latitude": 48.2819,
    "Longitude": 8.1372,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2819,8.1372",
    "IsOpen24Hours": false,
    "id": "48.2819,8.1372"
  },
  {
    "ID": 39578448,
    "Tankstellennummer": "TD182150",
    "Name": "GUTACH HAUSACHER STR. 13",
    "Address": "HAUSACHER STR. 13",
    "Address__1": "",
    "City": "GUTACH",
    "Postcode": 77793,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07831-969488",
    "24 hour?": "FALSE",
    "Latitude": 48.2833,
    "Longitude": 8.1939,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2833,8.1939",
    "IsOpen24Hours": false,
    "id": "48.2833,8.1939"
  },
  {
    "ID": 859797636,
    "Tankstellennummer": "TD041137",
    "Name": "OBERNDORF ROTTWEILER STR. 2",
    "Address": "ROTTWEILER STR. 2",
    "Address__1": "",
    "City": "OBERNDORF",
    "Postcode": 78727,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 74238758974,
    "24 hour?": "FALSE",
    "Latitude": 48.2861,
    "Longitude": 8.5781,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2861,8.5781",
    "IsOpen24Hours": false,
    "id": "48.2861,8.5781"
  },
  {
    "ID": 1013145004,
    "Tankstellennummer": 1680,
    "Name": "Agip Zeilarn",
    "Address": "Am Muehlbach 1",
    "Address__1": "",
    "City": "Zeilarn",
    "Postcode": 84367,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0)8572 9691",
    "24 hour?": "FALSE",
    "Latitude": 48.29275,
    "Longitude": 12.85236,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.29275,12.85236",
    "IsOpen24Hours": false,
    "id": "48.29275,12.85236"
  },
  {
    "ID": 510887639,
    "Tankstellennummer": 2194,
    "Name": "Agip Wolfach",
    "Address": "Bahnhofstr. 18",
    "Address__1": "",
    "City": "Wolfach",
    "Postcode": 77709,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 78344041",
    "24 hour?": "FALSE",
    "Latitude": 48.29414979,
    "Longitude": 8.22118453,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.29414979,8.22118453",
    "IsOpen24Hours": false,
    "id": "48.29414979,8.22118453"
  },
  {
    "ID": 1923,
    "Tankstellennummer": "0F849",
    "Name": "Aral Tankstelle Eching, Untere Hauptstrasse 11",
    "Address": "Untere Hauptstraße 11",
    "Address__1": "",
    "City": "Eching",
    "Postcode": 85386,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 893192811,
    "24 hour?": "TRUE",
    "Latitude": 48.298179,
    "Longitude": 11.625084,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.298179,11.625084",
    "IsOpen24Hours": true,
    "id": "48.298179,11.625084"
  },
  {
    "ID": 1379,
    "Tankstellennummer": "0F479",
    "Name": "Aral Tankstelle Oberndorf, Balinger Str.26",
    "Address": "Balinger Straße 26",
    "Address__1": "",
    "City": "Oberndorf",
    "Postcode": 78727,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74233558,
    "24 hour?": "FALSE",
    "Latitude": 48.300168,
    "Longitude": 8.611752,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.300168,8.611752",
    "IsOpen24Hours": false,
    "id": "48.300168,8.611752"
  },
  {
    "ID": 869248089,
    "Tankstellennummer": 1284,
    "Name": "Agip Erding",
    "Address": "Haager Strasse 34",
    "Address__1": "",
    "City": "Erding",
    "Postcode": 85435,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 81229026",
    "24 hour?": "FALSE",
    "Latitude": 48.30168997,
    "Longitude": 11.90837822,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.30168997,11.90837822",
    "IsOpen24Hours": false,
    "id": "48.30168997,11.90837822"
  },
  {
    "ID": 1866320839,
    "Tankstellennummer": "TD161650",
    "Name": "SCHWABHAUSEN AUGSBURGER STR. 4",
    "Address": "AUGSBURGER STR. 4",
    "Address__1": "",
    "City": "SCHWABHAUSEN",
    "Postcode": 85247,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08138-409",
    "24 hour?": "FALSE",
    "Latitude": 48.3034,
    "Longitude": 11.3552,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3034,11.3552",
    "IsOpen24Hours": false,
    "id": "48.3034,11.3552"
  },
  {
    "ID": 562187606,
    "Tankstellennummer": "TD000369",
    "Name": "MAHLBERG BAB A5/MAHLBERG-OST",
    "Address": "BAB A5/MAHLBERG-OST",
    "Address__1": "",
    "City": "MAHLBERG",
    "Postcode": 77972,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07825-849631",
    "24 hour?": "FALSE",
    "Latitude": 48.3066,
    "Longitude": 7.791,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3066,7.791",
    "IsOpen24Hours": false,
    "id": "48.3066,7.791"
  },
  {
    "ID": 1496,
    "Tankstellennummer": "0F552",
    "Name": "Aral Tankstelle Odelzhausen, Haupt-/Dieselstraße",
    "Address": "Rudolf-Diesel-Str. 2.",
    "Address__1": "",
    "City": "Odelzhausen",
    "Postcode": 85235,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8134555673,
    "24 hour?": "TRUE",
    "Latitude": 48.307089,
    "Longitude": 11.203779,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.307089,11.203779",
    "IsOpen24Hours": true,
    "id": "48.307089,11.203779"
  },
  {
    "ID": 1435,
    "Tankstellennummer": "0F503",
    "Name": "Aral Tankstelle Weißenhorn, Ulmer Str. 13",
    "Address": "Ulmer Straße 13",
    "Address__1": "",
    "City": "Weißenhorn",
    "Postcode": 89264,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 73092751,
    "24 hour?": "TRUE",
    "Latitude": 48.307509,
    "Longitude": 10.152489,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.307509,10.152489",
    "IsOpen24Hours": true,
    "id": "48.307509,10.152489"
  },
  {
    "ID": 351770850,
    "Tankstellennummer": 1665,
    "Name": "Agip Eching",
    "Address": "Dieselstrasse 16",
    "Address__1": "",
    "City": "Eching",
    "Postcode": 85386,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "08165/9087290",
    "24 hour?": "FALSE",
    "Latitude": 48.30859,
    "Longitude": 11.6412,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.30859,11.6412",
    "IsOpen24Hours": false,
    "id": "48.30859,11.6412"
  },
  {
    "ID": 320664362,
    "Tankstellennummer": 1655,
    "Name": "Agip Erding",
    "Address": "Am Kletthamer Feld 2",
    "Address__1": "",
    "City": "Erding",
    "Postcode": 85435,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 81222274",
    "24 hour?": "FALSE",
    "Latitude": 48.309203,
    "Longitude": 11.883366,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.309203,11.883366",
    "IsOpen24Hours": false,
    "id": "48.309203,11.883366"
  },
  {
    "ID": 2045,
    "Tankstellennummer": "0FG62",
    "Name": "Aral Tankstelle Augsburg, Inninger Str. 99",
    "Address": "Inninger Straße 99",
    "Address__1": "",
    "City": "Augsburg",
    "Postcode": 86179,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8216084769,
    "24 hour?": "TRUE",
    "Latitude": 48.309544,
    "Longitude": 10.890455,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.309544,10.890455",
    "IsOpen24Hours": true,
    "id": "48.309544,10.890455"
  },
  {
    "ID": 578676555,
    "Tankstellennummer": "0FO76",
    "Name": "WEIßENHORN",
    "Address": "Günzburger Straße 54",
    "Address__1": "",
    "City": "Weißenhorn",
    "Postcode": 89264,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7309969820,
    "24 hour?": "FALSE",
    "Latitude": 48.311829,
    "Longitude": 10.157852,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.311829,10.157852",
    "IsOpen24Hours": false,
    "id": "48.311829,10.157852"
  },
  {
    "ID": 1930,
    "Tankstellennummer": "0F854",
    "Name": "Aral Tankstelle Neufahrn, Grüneckerstr.6",
    "Address": "Grüneckerstraße 6",
    "Address__1": "",
    "City": "Neufahrn",
    "Postcode": 85375,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 81654610,
    "24 hour?": "TRUE",
    "Latitude": 48.313086,
    "Longitude": 11.665198,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.313086,11.665198",
    "IsOpen24Hours": true,
    "id": "48.313086,11.665198"
  },
  {
    "ID": 2001,
    "Tankstellennummer": "0F901",
    "Name": "Aral Tankstelle Erding, Sigwolfstraße 2",
    "Address": "Sigwolfstraße 2",
    "Address__1": "",
    "City": "Erding",
    "Postcode": 85435,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8122902786,
    "24 hour?": "TRUE",
    "Latitude": 48.31487,
    "Longitude": 11.892567,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.31487,11.892567",
    "IsOpen24Hours": true,
    "id": "48.31487,11.892567"
  },
  {
    "ID": 851666079,
    "Tankstellennummer": 2211,
    "Name": "Agip Seelbach",
    "Address": "Hauptstr. 57",
    "Address__1": "",
    "City": "Seelbach",
    "Postcode": 77960,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 78239663",
    "24 hour?": "FALSE",
    "Latitude": 48.31576109,
    "Longitude": 7.93853569,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.31576109,7.93853569",
    "IsOpen24Hours": false,
    "id": "48.31576109,7.93853569"
  },
  {
    "ID": 748605104,
    "Tankstellennummer": "TD038414",
    "Name": "NEUFAHRN MUENCHNERSTR. 11A",
    "Address": "MUENCHNERSTR. 11A",
    "Address__1": "",
    "City": "NEUFAHRN",
    "Postcode": 85375,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08165-66442",
    "24 hour?": "FALSE",
    "Latitude": 48.3184,
    "Longitude": 11.6915,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3184,11.6915",
    "IsOpen24Hours": false,
    "id": "48.3184,11.6915"
  },
  {
    "ID": 269547610,
    "Tankstellennummer": 1664,
    "Name": "Agip Augsburg",
    "Address": "Buergermeister-Schlosser-Str. 2",
    "Address__1": "",
    "City": "Augsburg",
    "Postcode": 86199,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "0821/99863460",
    "24 hour?": "FALSE",
    "Latitude": 48.31878,
    "Longitude": 10.86128,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.31878,10.86128",
    "IsOpen24Hours": false,
    "id": "48.31878,10.86128"
  },
  {
    "ID": 1441,
    "Tankstellennummer": "0F509",
    "Name": "Aral Tankstelle Senden, Kemptener Str. 61",
    "Address": "Kemptener Straße 61",
    "Address__1": "",
    "City": "Senden",
    "Postcode": 89250,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 73075217,
    "24 hour?": "TRUE",
    "Latitude": 48.320287,
    "Longitude": 10.049629,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.320287,10.049629",
    "IsOpen24Hours": true,
    "id": "48.320287,10.049629"
  },
  {
    "ID": 1915,
    "Tankstellennummer": "0F844",
    "Name": "Aral Tankstelle Augsburg, Haunstetter Str. 1391/2",
    "Address": "Haunstetter Straße 139 1/2",
    "Address__1": "",
    "City": "Augsburg",
    "Postcode": 86161,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 821573303,
    "24 hour?": "TRUE",
    "Latitude": 48.333732,
    "Longitude": 10.908119,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.333732,10.908119",
    "IsOpen24Hours": true,
    "id": "48.333732,10.908119"
  },
  {
    "ID": 2109924220,
    "Tankstellennummer": "TD182070",
    "Name": "BIBERACH BAHNHOFSTR. 8",
    "Address": "BAHNHOFSTR. 8",
    "Address__1": "",
    "City": "BIBERACH",
    "Postcode": 77781,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07835-3070",
    "24 hour?": "FALSE",
    "Latitude": 48.3387,
    "Longitude": 8.0304,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3387,8.0304",
    "IsOpen24Hours": false,
    "id": "48.3387,8.0304"
  },
  {
    "ID": 3099,
    "Tankstellennummer": "0FJ25",
    "Name": "Aral Tankstelle Biberach, Hauptstr. 41",
    "Address": "Hauptstraße 41",
    "Address__1": "",
    "City": "Biberach",
    "Postcode": 77781,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 78353925,
    "24 hour?": "FALSE",
    "Latitude": 48.34032,
    "Longitude": 8.024665,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.34032,8.024665",
    "IsOpen24Hours": false,
    "id": "48.34032,8.024665"
  },
  {
    "ID": 2422,
    "Tankstellennummer": "0FC01",
    "Name": "Aral Tankstelle Lahr, Freiburger Str.11",
    "Address": "Freiburger Straße 11",
    "Address__1": "",
    "City": "Lahr",
    "Postcode": 77933,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 782142263,
    "24 hour?": "FALSE",
    "Latitude": 48.340505,
    "Longitude": 7.841934,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.340505,7.841934",
    "IsOpen24Hours": false,
    "id": "48.340505,7.841934"
  },
  {
    "ID": 316509830,
    "Tankstellennummer": "TD035550",
    "Name": "LAHR BISMARCKSTR. 83",
    "Address": "BISMARCKSTR. 83",
    "Address__1": "",
    "City": "LAHR",
    "Postcode": 77933,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07821-983400",
    "24 hour?": "FALSE",
    "Latitude": 48.341,
    "Longitude": 7.8797,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.341,7.8797",
    "IsOpen24Hours": false,
    "id": "48.341,7.8797"
  },
  {
    "ID": 52531226,
    "Tankstellennummer": 2136,
    "Name": "Agip Lahr",
    "Address": "Freiburger Strasse 9",
    "Address__1": "",
    "City": "Lahr",
    "Postcode": 77933,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 78219818",
    "24 hour?": "TRUE",
    "Latitude": 48.34151034,
    "Longitude": 7.84149881,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.34151034,7.84149881",
    "IsOpen24Hours": true,
    "id": "48.34151034,7.84149881"
  },
  {
    "ID": 2436,
    "Tankstellennummer": "0FC11",
    "Name": "Aral Tankstelle Lahr, Turmstr.33",
    "Address": "Turmstraße 33",
    "Address__1": "",
    "City": "Lahr",
    "Postcode": 77933,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 782125474,
    "24 hour?": "TRUE",
    "Latitude": 48.341746,
    "Longitude": 7.876973,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.341746,7.876973",
    "IsOpen24Hours": true,
    "id": "48.341746,7.876973"
  },
  {
    "ID": 660267778,
    "Tankstellennummer": "TD035980",
    "Name": "VOEHRINGEN AUTOHOF EYTHSTRASSE",
    "Address": "AUTOHOF EYTHSTRASSE 16 A",
    "Address__1": "",
    "City": "VOEHRINGEN",
    "Postcode": 72189,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07454-96040",
    "24 hour?": "FALSE",
    "Latitude": 48.3434,
    "Longitude": 8.6631,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3434,8.6631",
    "IsOpen24Hours": false,
    "id": "48.3434,8.6631"
  },
  {
    "ID": 108855562,
    "Tankstellennummer": "TD007591",
    "Name": "FREISING ACHERINGER HAUPTSTR.",
    "Address": "ACHERINGER HAUPTSTR. 20",
    "Address__1": "",
    "City": "FREISING",
    "Postcode": 85354,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08165-64290",
    "24 hour?": "FALSE",
    "Latitude": 48.3444,
    "Longitude": 11.7092,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3444,11.7092",
    "IsOpen24Hours": false,
    "id": "48.3444,11.7092"
  },
  {
    "ID": 28519536,
    "Tankstellennummer": "0FO31",
    "Name": "AUGSBURG",
    "Address": "Edisonstraße 14 a",
    "Address__1": "",
    "City": "Augsburg",
    "Postcode": 86199,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8215998707,
    "24 hour?": "TRUE",
    "Latitude": 48.344737,
    "Longitude": 10.885279,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.344737,10.885279",
    "IsOpen24Hours": true,
    "id": "48.344737,10.885279"
  },
  {
    "ID": 13882289,
    "Tankstellennummer": "0FO32",
    "Name": "AUGSBURG",
    "Address": "Haunstetter Straße 75",
    "Address__1": "",
    "City": "Augsburg",
    "Postcode": 86161,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 82132856518,
    "24 hour?": "FALSE",
    "Latitude": 48.346277,
    "Longitude": 10.905344,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.346277,10.905344",
    "IsOpen24Hours": false,
    "id": "48.346277,10.905344"
  },
  {
    "ID": 1483,
    "Tankstellennummer": "0F544",
    "Name": "Aral Tankstelle Hechingen, An der Bundesstraße 27",
    "Address": "Holger-Crafoord-Straße 1",
    "Address__1": "",
    "City": "Hechingen",
    "Postcode": 72379,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74714950,
    "24 hour?": "FALSE",
    "Latitude": 48.352099,
    "Longitude": 8.975689,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.352099,8.975689",
    "IsOpen24Hours": false,
    "id": "48.352099,8.975689"
  },
  {
    "ID": 1576410278,
    "Tankstellennummer": 1227,
    "Name": "Agip Muenchen",
    "Address": "Flughafen Nordallee 45",
    "Address__1": "",
    "City": "Muenchen",
    "Postcode": 85356,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 89970518",
    "24 hour?": "TRUE",
    "Latitude": 48.35395434,
    "Longitude": 11.7493756,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.35395434,11.7493756",
    "IsOpen24Hours": true,
    "id": "48.35395434,11.7493756"
  },
  {
    "ID": 1556672603,
    "Tankstellennummer": 1611,
    "Name": "Agip Markt Indersdorf",
    "Address": "Maroldstrasse 64",
    "Address__1": "",
    "City": "Markt Indersdorf",
    "Postcode": 85229,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 81366479",
    "24 hour?": "FALSE",
    "Latitude": 48.35492,
    "Longitude": 11.38783,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.35492,11.38783",
    "IsOpen24Hours": false,
    "id": "48.35492,11.38783"
  },
  {
    "ID": 650252144,
    "Tankstellennummer": "0FN75",
    "Name": "AUGSBURG",
    "Address": "Friedberger Straße 47 1/4",
    "Address__1": "",
    "City": "Augsburg",
    "Postcode": 86161,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 821551677,
    "24 hour?": "TRUE",
    "Latitude": 48.355628,
    "Longitude": 10.919002,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.355628,10.919002",
    "IsOpen24Hours": true,
    "id": "48.355628,10.919002"
  },
  {
    "ID": 147330985,
    "Tankstellennummer": 2008,
    "Name": "Agip Hechingen",
    "Address": "Hofgartenstrasse 10",
    "Address__1": "",
    "City": "Hechingen",
    "Postcode": 72379,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 74715315",
    "24 hour?": "TRUE",
    "Latitude": 48.35692051,
    "Longitude": 8.96465209,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.35692051,8.96465209",
    "IsOpen24Hours": true,
    "id": "48.35692051,8.96465209"
  },
  {
    "ID": 1486,
    "Tankstellennummer": "0F546",
    "Name": "Aral Tankstelle Ulm, Raiffeisenstr. 1",
    "Address": "Raiffeisenstraße 1",
    "Address__1": "",
    "City": "Ulm",
    "Postcode": 89079,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 73144488,
    "24 hour?": "TRUE",
    "Latitude": 48.358242,
    "Longitude": 9.970291,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.358242,9.970291",
    "IsOpen24Hours": true,
    "id": "48.358242,9.970291"
  },
  {
    "ID": 1563353063,
    "Tankstellennummer": "0FO33",
    "Name": "PFAFFENHOFEN",
    "Address": "Lindenstraße 1",
    "Address__1": "",
    "City": "Pfaffenhofen",
    "Postcode": 89284,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7302919086,
    "24 hour?": "FALSE",
    "Latitude": 48.358701,
    "Longitude": 10.160148,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.358701,10.160148",
    "IsOpen24Hours": false,
    "id": "48.358701,10.160148"
  },
  {
    "ID": 1747497078,
    "Tankstellennummer": 1279,
    "Name": "Agip Neumarkt/St.Veit",
    "Address": "Bahnhofstrasse 47",
    "Address__1": "",
    "City": "Neumarkt/St.Veit",
    "Postcode": 84494,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 86397098",
    "24 hour?": "FALSE",
    "Latitude": 48.35931661,
    "Longitude": 12.50180251,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.35931661,12.50180251",
    "IsOpen24Hours": false,
    "id": "48.35931661,12.50180251"
  },
  {
    "ID": 1323132980,
    "Tankstellennummer": 212051,
    "Name": "Ulm",
    "Address": "Nicolaus Otto Strasse 27",
    "Address__1": "",
    "City": "ULM",
    "Postcode": 89079,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 48.36,
    "Longitude": 9.929805,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.36,9.929805",
    "IsOpen24Hours": false,
    "id": "48.36,9.929805"
  },
  {
    "ID": 3104,
    "Tankstellennummer": "0FJ30",
    "Name": "Aral Tankstelle Burladingen - Melchingen, Talheimer Str. 21",
    "Address": "Talheimer Straße 21",
    "Address__1": "",
    "City": "Burladingen - Melchi",
    "Postcode": 72393,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71261583,
    "24 hour?": "FALSE",
    "Latitude": 48.361061,
    "Longitude": 9.145284,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.361061,9.145284",
    "IsOpen24Hours": false,
    "id": "48.361061,9.145284"
  },
  {
    "ID": 3183,
    "Tankstellennummer": "0FJ77",
    "Name": "Aral Tankstelle Friedberg, Aichacher Str. 26",
    "Address": "Aichacher Straße 26",
    "Address__1": "",
    "City": "Friedberg",
    "Postcode": 86316,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 821601885,
    "24 hour?": "TRUE",
    "Latitude": 48.361405,
    "Longitude": 10.98779,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.361405,10.98779",
    "IsOpen24Hours": true,
    "id": "48.361405,10.98779"
  },
  {
    "ID": 1394,
    "Tankstellennummer": "0F474",
    "Name": "Aral Tankstelle Sulz, Stuttgarter Str.30",
    "Address": "Stuttgarter Straße 30",
    "Address__1": "",
    "City": "Sulz",
    "Postcode": 72172,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74549767037,
    "24 hour?": "FALSE",
    "Latitude": 48.367181,
    "Longitude": 8.636595,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.367181,8.636595",
    "IsOpen24Hours": false,
    "id": "48.367181,8.636595"
  },
  {
    "ID": 3159,
    "Tankstellennummer": "0FJ65",
    "Name": "Aral Tankstelle Velden, Landshuter Str. 16",
    "Address": "Landshuter Straße 16",
    "Address__1": "",
    "City": "Velden",
    "Postcode": 84149,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8742919288,
    "24 hour?": "FALSE",
    "Latitude": 48.368626,
    "Longitude": 12.251242,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.368626,12.251242",
    "IsOpen24Hours": false,
    "id": "48.368626,12.251242"
  },
  {
    "ID": 3102,
    "Tankstellennummer": "0FJ28",
    "Name": "Aral Tankstelle Friesenheim, Adlerstrasse 29",
    "Address": "Adlerstraße 29",
    "Address__1": "",
    "City": "Friesenheim",
    "Postcode": 77948,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7821997535,
    "24 hour?": "FALSE",
    "Latitude": 48.370685,
    "Longitude": 7.872607,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.370685,7.872607",
    "IsOpen24Hours": false,
    "id": "48.370685,7.872607"
  },
  {
    "ID": 1663901527,
    "Tankstellennummer": "TD161460",
    "Name": "AUGSBURG LEONHARDSBERG 17",
    "Address": "LEONHARDSBERG 17",
    "Address__1": "",
    "City": "AUGSBURG",
    "Postcode": 86150,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0821-514662",
    "24 hour?": "FALSE",
    "Latitude": 48.3707,
    "Longitude": 10.9004,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3707,10.9004",
    "IsOpen24Hours": false,
    "id": "48.3707,10.9004"
  },
  {
    "ID": 1427,
    "Tankstellennummer": "0F498",
    "Name": "Aral Tankstelle Schelklingen, Ehinger Str.11",
    "Address": "Ehinger Straße 11",
    "Address__1": "",
    "City": "Schelklingen",
    "Postcode": 89601,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 73942346,
    "24 hour?": "FALSE",
    "Latitude": 48.370829,
    "Longitude": 9.732629,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.370829,9.732629",
    "IsOpen24Hours": false,
    "id": "48.370829,9.732629"
  },
  {
    "ID": 2038295895,
    "Tankstellennummer": "0FN56",
    "Name": "ICHENHAUSEN",
    "Address": "Günzburger Straße 55",
    "Address__1": "",
    "City": "Ichenhausen",
    "Postcode": 89335,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 82231281,
    "24 hour?": "FALSE",
    "Latitude": 48.374001,
    "Longitude": 10.311301,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.374001,10.311301",
    "IsOpen24Hours": false,
    "id": "48.374001,10.311301"
  },
  {
    "ID": 1968,
    "Tankstellennummer": "0F877",
    "Name": "Aral Tankstelle Augsburg, Stätzlinger Str. 86",
    "Address": "Stätzlinger Straße 86",
    "Address__1": "",
    "City": "Augsburg",
    "Postcode": 86165,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 821713575,
    "24 hour?": "FALSE",
    "Latitude": 48.378201,
    "Longitude": 10.937471,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.378201,10.937471",
    "IsOpen24Hours": false,
    "id": "48.378201,10.937471"
  },
  {
    "ID": 658291738,
    "Tankstellennummer": "0FS86",
    "Name": "ENGSTINGEN",
    "Address": "Trochtelfinger Straße 38",
    "Address__1": "",
    "City": "Engstingen",
    "Postcode": 72829,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71299362420,
    "24 hour?": "FALSE",
    "Latitude": 48.3832717,
    "Longitude": 9.2827525,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3832717,9.2827525",
    "IsOpen24Hours": false,
    "id": "48.3832717,9.2827525"
  },
  {
    "ID": 2042,
    "Tankstellennummer": "0FG67",
    "Name": "Aral Tankstelle Augsburg, Kobelweg 64 1/4",
    "Address": "Kobelweg 64 1/4",
    "Address__1": "",
    "City": "Augsburg",
    "Postcode": 86156,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8212401234,
    "24 hour?": "FALSE",
    "Latitude": 48.385634,
    "Longitude": 10.851188,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.385634,10.851188",
    "IsOpen24Hours": false,
    "id": "48.385634,10.851188"
  },
  {
    "ID": 235026474,
    "Tankstellennummer": 2201,
    "Name": "Agip Engstingen",
    "Address": "Bernlocher Str. 17",
    "Address__1": "",
    "City": "Engstingen",
    "Postcode": 72829,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71293318",
    "24 hour?": "FALSE",
    "Latitude": 48.38574624,
    "Longitude": 9.30056324,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.38574624,9.30056324",
    "IsOpen24Hours": false,
    "id": "48.38574624,9.30056324"
  },
  {
    "ID": 3319,
    "Tankstellennummer": "0FK74",
    "Name": "Aral Tankstelle Neu-Ulm, Otto-Renner-Str. 1",
    "Address": "Otto-Renner-Straße 1",
    "Address__1": "",
    "City": "Neu-Ulm",
    "Postcode": 89231,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7317253429,
    "24 hour?": "TRUE",
    "Latitude": 48.386472,
    "Longitude": 10.035743,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.386472,10.035743",
    "IsOpen24Hours": true,
    "id": "48.386472,10.035743"
  },
  {
    "ID": 1447,
    "Tankstellennummer": "0F515",
    "Name": "Aral Tankstelle Bodelshausen, Bahnhofstr.84",
    "Address": "Bahnhofstraße 84",
    "Address__1": "",
    "City": "Bodelshausen",
    "Postcode": 72411,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74717511,
    "24 hour?": "FALSE",
    "Latitude": 48.387321,
    "Longitude": 8.979032,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.387321,8.979032",
    "IsOpen24Hours": false,
    "id": "48.387321,8.979032"
  },
  {
    "ID": 3218,
    "Tankstellennummer": "0FJ97",
    "Name": "Aral Tankstelle Ulm, Illerstr. 2",
    "Address": "Illerstraße 2",
    "Address__1": "",
    "City": "Ulm",
    "Postcode": 89077,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 73130306,
    "24 hour?": "TRUE",
    "Latitude": 48.389088,
    "Longitude": 9.978195,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.389088,9.978195",
    "IsOpen24Hours": true,
    "id": "48.389088,9.978195"
  },
  {
    "ID": 1210319242,
    "Tankstellennummer": "TD036566",
    "Name": "AUGSBURG DONAUWOERTHER STR. 10",
    "Address": "DONAUWOERTHER STR. 102",
    "Address__1": "",
    "City": "AUGSBURG",
    "Postcode": 86154,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0821-414291",
    "24 hour?": "FALSE",
    "Latitude": 48.3891,
    "Longitude": 10.8816,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3891,10.8816",
    "IsOpen24Hours": false,
    "id": "48.3891,10.8816"
  },
  {
    "ID": 854327619,
    "Tankstellennummer": 1165,
    "Name": "Agip Augsburg",
    "Address": "Holzweg 21",
    "Address__1": "",
    "City": "Augsburg",
    "Postcode": 86156,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 82146136",
    "24 hour?": "FALSE",
    "Latitude": 48.38996,
    "Longitude": 10.86653,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.38996,10.86653",
    "IsOpen24Hours": false,
    "id": "48.38996,10.86653"
  },
  {
    "ID": 2008,
    "Tankstellennummer": "0F908",
    "Name": "Aral Autohof Dasing, Laimeringer Str. 2",
    "Address": "Laimeringer Straße 2",
    "Address__1": "",
    "City": "Dasing",
    "Postcode": 86453,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8205969083,
    "24 hour?": "TRUE",
    "Latitude": 48.389962,
    "Longitude": 11.063402,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.389962,11.063402",
    "IsOpen24Hours": true,
    "id": "48.389962,11.063402"
  },
  {
    "ID": 2026,
    "Tankstellennummer": "0F921",
    "Name": "Aral Tankstelle Augsburg, Aindlinger Str. 11",
    "Address": "Aindlinger Strasse 11",
    "Address__1": "",
    "City": "Augsburg",
    "Postcode": 86167,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8217472450,
    "24 hour?": "TRUE",
    "Latitude": 48.392692,
    "Longitude": 10.933418,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.392692,10.933418",
    "IsOpen24Hours": true,
    "id": "48.392692,10.933418"
  },
  {
    "ID": 844173611,
    "Tankstellennummer": "TD040196",
    "Name": "EGGENFELDEN TIEFSTADT 10",
    "Address": "TIEFSTADT 10",
    "Address__1": "",
    "City": "EGGENFELDEN",
    "Postcode": 84307,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08721/508143",
    "24 hour?": "FALSE",
    "Latitude": 48.3958,
    "Longitude": 12.7661,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3958,12.7661",
    "IsOpen24Hours": false,
    "id": "48.3958,12.7661"
  },
  {
    "ID": 1981,
    "Tankstellennummer": "0F885",
    "Name": "Aral Tankstelle Freising, Isarstr. 3",
    "Address": "Isarstraße 3",
    "Address__1": "",
    "City": "Freising",
    "Postcode": 85356,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 816183048,
    "24 hour?": "FALSE",
    "Latitude": 48.397459,
    "Longitude": 11.75929,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.397459,11.75929",
    "IsOpen24Hours": false,
    "id": "48.397459,11.75929"
  },
  {
    "ID": 2032295381,
    "Tankstellennummer": "TD041988",
    "Name": "AUGSBURG GABLINGER WEG 83",
    "Address": "GABLINGER WEG 83",
    "Address__1": "",
    "City": "AUGSBURG",
    "Postcode": 86154,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0821-45309480",
    "24 hour?": "FALSE",
    "Latitude": 48.3989,
    "Longitude": 10.8687,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3989,10.8687",
    "IsOpen24Hours": false,
    "id": "48.3989,10.8687"
  },
  {
    "ID": 1481,
    "Tankstellennummer": "0F542",
    "Name": "Aral Tankstelle Gengenbach, Berghauptener Str. 19-23",
    "Address": "Berghauptener Str. 21",
    "Address__1": "",
    "City": "Gengenbach",
    "Postcode": 77723,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7803922517,
    "24 hour?": "TRUE",
    "Latitude": 48.400053,
    "Longitude": 8.007424,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.400053,8.007424",
    "IsOpen24Hours": true,
    "id": "48.400053,8.007424"
  },
  {
    "ID": 1397,
    "Tankstellennummer": "0F477",
    "Name": "Aral Tankstelle Neu-Ulm, Leipheimer Str. 34",
    "Address": "Leipheimer Straße 34",
    "Address__1": "",
    "City": "Neu-Ulm",
    "Postcode": 89233,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 731711295,
    "24 hour?": "FALSE",
    "Latitude": 48.404406,
    "Longitude": 10.03394,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.404406,10.03394",
    "IsOpen24Hours": false,
    "id": "48.404406,10.03394"
  },
  {
    "ID": 499850325,
    "Tankstellennummer": "TD002121",
    "Name": "NEU-ULM LEIPHEIMER STR. 60",
    "Address": "LEIPHEIMER STR. 60",
    "Address__1": "",
    "City": "NEU-ULM",
    "Postcode": 89233,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0731-714728",
    "24 hour?": "FALSE",
    "Latitude": 48.4056,
    "Longitude": 10.0381,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4056,10.0381",
    "IsOpen24Hours": false,
    "id": "48.4056,10.0381"
  },
  {
    "ID": 3219,
    "Tankstellennummer": "0FJ98",
    "Name": "Aral Tankstelle Ulm, Karlstr. 77",
    "Address": "Karlstraße 77",
    "Address__1": "",
    "City": "Ulm",
    "Postcode": 89073,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 73123019,
    "24 hour?": "FALSE",
    "Latitude": 48.406403,
    "Longitude": 9.996263,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.406403,9.996263",
    "IsOpen24Hours": false,
    "id": "48.406403,9.996263"
  },
  {
    "ID": 14657,
    "Tankstellennummer": "0FO34",
    "Name": "Aral Tankstelle Jettingen-Scheppach OT Jettingen, Hauptstraße 263",
    "Address": "Hauptstraße 263",
    "Address__1": "",
    "City": "Jettingen-Scheppach",
    "Postcode": 89343,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8225959090,
    "24 hour?": "FALSE",
    "Latitude": 48.409452,
    "Longitude": 10.444741,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.409452,10.444741",
    "IsOpen24Hours": false,
    "id": "48.409452,10.444741"
  },
  {
    "ID": 1371622312,
    "Tankstellennummer": "TD041376",
    "Name": "AUGSBURG BAB A8 - AUGSBURG OST",
    "Address": "BAB A8 - AUGSBURG OST",
    "Address__1": "",
    "City": "AUGSBURG",
    "Postcode": 86169,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 82148044019,
    "24 hour?": "FALSE",
    "Latitude": 48.4121,
    "Longitude": 10.9128,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4121,10.9128",
    "IsOpen24Hours": false,
    "id": "48.4121,10.9128"
  },
  {
    "ID": 977,
    "Tankstellennummer": "0F165",
    "Name": "Aral Tankstelle Hohberg, Bundesstrasse 3",
    "Address": "Bundesstraße 3",
    "Address__1": "",
    "City": "Hohberg",
    "Postcode": 77749,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7808911057,
    "24 hour?": "FALSE",
    "Latitude": 48.415245,
    "Longitude": 7.886977,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.415245,7.886977",
    "IsOpen24Hours": false,
    "id": "48.415245,7.886977"
  },
  {
    "ID": 148682214,
    "Tankstellennummer": 2619,
    "Name": "Agip Muensingen",
    "Address": "Robert-Bosch-Str. 2-4",
    "Address__1": "",
    "City": "Muensingen",
    "Postcode": 72525,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 73815013",
    "24 hour?": "TRUE",
    "Latitude": 48.41539,
    "Longitude": 9.47401,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.41539,9.47401",
    "IsOpen24Hours": true,
    "id": "48.41539,9.47401"
  },
  {
    "ID": 668614971,
    "Tankstellennummer": "TD040352",
    "Name": "NERSINGEN AN DER LEIBI 1",
    "Address": "AN DER LEIBI 1",
    "Address__1": "",
    "City": "NERSINGEN",
    "Postcode": 89278,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07308-9240280",
    "24 hour?": "FALSE",
    "Latitude": 48.4164,
    "Longitude": 10.101,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4164,10.101",
    "IsOpen24Hours": false,
    "id": "48.4164,10.101"
  },
  {
    "ID": 1907,
    "Tankstellennummer": "0F838",
    "Name": "Aral Tankstelle Adelsried, Augsburger Str. 25",
    "Address": "Augsburger Straße 25",
    "Address__1": "",
    "City": "Adelsried",
    "Postcode": 86477,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 82948607430,
    "24 hour?": "FALSE",
    "Latitude": 48.423658,
    "Longitude": 10.720173,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.423658,10.720173",
    "IsOpen24Hours": false,
    "id": "48.423658,10.720173"
  },
  {
    "ID": 807431226,
    "Tankstellennummer": "0FX56",
    "Name": "GERSTHOFEN-EDENBERGEN",
    "Address": "A8",
    "Address__1": "",
    "City": "Gersthofen-Edenberge",
    "Postcode": 86368,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 82148685416,
    "24 hour?": "TRUE",
    "Latitude": 48.4249832,
    "Longitude": 10.808122,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.4249832,10.808122",
    "IsOpen24Hours": true,
    "id": "48.4249832,10.808122"
  },
  {
    "ID": 559751344,
    "Tankstellennummer": "TD162640",
    "Name": "BURGAU AUGSBURGER STR. 29",
    "Address": "AUGSBURGER STR. 29",
    "Address__1": "",
    "City": "BURGAU",
    "Postcode": 89331,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08222-410209",
    "24 hour?": "FALSE",
    "Latitude": 48.4256,
    "Longitude": 10.4139,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4256,10.4139",
    "IsOpen24Hours": false,
    "id": "48.4256,10.4139"
  },
  {
    "ID": 550244221,
    "Tankstellennummer": "TD035642",
    "Name": "GUENZBURG AUTOHOF WILHELM-MAYB",
    "Address": "AUTOHOF WILHELM-MAYBACH-STR. 3",
    "Address__1": "",
    "City": "GUENZBURG",
    "Postcode": 89312,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08221-2044013",
    "24 hour?": "FALSE",
    "Latitude": 48.4276,
    "Longitude": 10.2882,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4276,10.2882",
    "IsOpen24Hours": false,
    "id": "48.4276,10.2882"
  },
  {
    "ID": 2034,
    "Tankstellennummer": "0FG30",
    "Name": "Aral Tankstelle Allershausen, Schroßlacher Str. 2",
    "Address": "Schroßlacher Straße 2",
    "Address__1": "",
    "City": "Allershausen",
    "Postcode": 85391,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8166993709,
    "24 hour?": "TRUE",
    "Latitude": 48.428426,
    "Longitude": 11.585441,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.428426,11.585441",
    "IsOpen24Hours": true,
    "id": "48.428426,11.585441"
  },
  {
    "ID": 1568671447,
    "Tankstellennummer": "0FS59",
    "Name": "TANKHOF CHRISTOPH SCHMID GMBH",
    "Address": "Junginger Straße 11",
    "Address__1": "",
    "City": "Ulm",
    "Postcode": 89081,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7319609671,
    "24 hour?": "FALSE",
    "Latitude": 48.436443,
    "Longitude": 9.970989,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.436443,9.970989",
    "IsOpen24Hours": false,
    "id": "48.436443,9.970989"
  },
  {
    "ID": 67026262,
    "Tankstellennummer": 1298,
    "Name": "Agip Gersthofen",
    "Address": "Mercedesring 2",
    "Address__1": "",
    "City": "Gersthofen",
    "Postcode": 86368,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 82146094",
    "24 hour?": "TRUE",
    "Latitude": 48.43679,
    "Longitude": 10.86898,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.43679,10.86898",
    "IsOpen24Hours": true,
    "id": "48.43679,10.86898"
  },
  {
    "ID": 551378358,
    "Tankstellennummer": 1139,
    "Name": "Agip Gangkofen",
    "Address": "Frontenhausener Str. 40",
    "Address__1": "",
    "City": "Gangkofen",
    "Postcode": 84140,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 8722481",
    "24 hour?": "FALSE",
    "Latitude": 48.43850067,
    "Longitude": 12.56236309,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.43850067,12.56236309",
    "IsOpen24Hours": false,
    "id": "48.43850067,12.56236309"
  },
  {
    "ID": 371176100,
    "Tankstellennummer": "TD041871",
    "Name": "LEIPHEIM AN DER A 8",
    "Address": "AN DER A 8",
    "Address__1": "",
    "City": "LEIPHEIM",
    "Postcode": 89340,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 82212780280,
    "24 hour?": "FALSE",
    "Latitude": 48.4389,
    "Longitude": 10.2129,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4389,10.2129",
    "IsOpen24Hours": false,
    "id": "48.4389,10.2129"
  },
  {
    "ID": 1212792816,
    "Tankstellennummer": "0FU37",
    "Name": "GERSTHOFEN",
    "Address": "Henleinstraße 35",
    "Address__1": "",
    "City": "Gersthofen",
    "Postcode": 86368,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 82142092000,
    "24 hour?": "FALSE",
    "Latitude": 48.442223,
    "Longitude": 10.874033,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.442223,10.874033",
    "IsOpen24Hours": false,
    "id": "48.442223,10.874033"
  },
  {
    "ID": 766568345,
    "Tankstellennummer": "0FN54",
    "Name": "LEIPHEIM",
    "Address": "Ulmer Straße 13",
    "Address__1": "",
    "City": "Leipheim",
    "Postcode": 89340,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 822171065,
    "24 hour?": "FALSE",
    "Latitude": 48.445883,
    "Longitude": 10.21254,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.445883,10.21254",
    "IsOpen24Hours": false,
    "id": "48.445883,10.21254"
  },
  {
    "ID": 277650271,
    "Tankstellennummer": 1666,
    "Name": "Agip Bad Birnbach",
    "Address": "Landstr. 3",
    "Address__1": "",
    "City": "Bad Birnbach",
    "Postcode": 84364,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "08563/825",
    "24 hour?": "FALSE",
    "Latitude": 48.44749,
    "Longitude": 13.04008,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.44749,13.04008",
    "IsOpen24Hours": false,
    "id": "48.44749,13.04008"
  },
  {
    "ID": 1965,
    "Tankstellennummer": "0F874",
    "Name": "Aral Tankstelle Vilsbiburg, Landshuter Str.9",
    "Address": "Landshuter Straße 9",
    "Address__1": "",
    "City": "Vilsbiburg",
    "Postcode": 84137,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 874196336513,
    "24 hour?": "FALSE",
    "Latitude": 48.448663,
    "Longitude": 12.351033,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.448663,12.351033",
    "IsOpen24Hours": false,
    "id": "48.448663,12.351033"
  },
  {
    "ID": 1035002204,
    "Tankstellennummer": "TD181470",
    "Name": "NEURIED DUNDENHEIMER STR. 31",
    "Address": "DUNDENHEIMER STR. 31",
    "Address__1": "",
    "City": "NEURIED",
    "Postcode": 77743,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07807-887",
    "24 hour?": "FALSE",
    "Latitude": 48.4509,
    "Longitude": 7.8164,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4509,7.8164",
    "IsOpen24Hours": false,
    "id": "48.4509,7.8164"
  },
  {
    "ID": 167586576,
    "Tankstellennummer": "0FU51",
    "Name": "HORB AM NECKAR",
    "Address": "BILDECHINGER STEIGE 64",
    "Address__1": "",
    "City": "HORB AM NECKAR",
    "Postcode": 72160,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74515506927,
    "24 hour?": "TRUE",
    "Latitude": 48.455575,
    "Longitude": 8.699015,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.455575,8.699015",
    "IsOpen24Hours": true,
    "id": "48.455575,8.699015"
  },
  {
    "ID": 1823098870,
    "Tankstellennummer": "TD040774",
    "Name": "GUENZBURG SCHLACHTHAUSSTR. 52",
    "Address": "SCHLACHTHAUSSTR. 52",
    "Address__1": "",
    "City": "GUENZBURG",
    "Postcode": 89312,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08221-2039255",
    "24 hour?": "FALSE",
    "Latitude": 48.4586,
    "Longitude": 10.2767,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4586,10.2767",
    "IsOpen24Hours": false,
    "id": "48.4586,10.2767"
  },
  {
    "ID": 2004,
    "Tankstellennummer": "0F904",
    "Name": "Aral Tankstelle Moosburg, Landshuter Str. 42",
    "Address": "Landshuter Straße 42",
    "Address__1": "",
    "City": "Moosburg",
    "Postcode": 85368,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8761729846,
    "24 hour?": "TRUE",
    "Latitude": 48.463953,
    "Longitude": 11.939814,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.463953,11.939814",
    "IsOpen24Hours": true,
    "id": "48.463953,11.939814"
  },
  {
    "ID": 1943,
    "Tankstellennummer": "0F861",
    "Name": "Aral Tankstelle Langweid, Donauwörther Str.15",
    "Address": "Donauwörther Straße 15",
    "Address__1": "",
    "City": "Langweid",
    "Postcode": 86462,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 821492625,
    "24 hour?": "TRUE",
    "Latitude": 48.465298,
    "Longitude": 10.866053,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.465298,10.866053",
    "IsOpen24Hours": true,
    "id": "48.465298,10.866053"
  },
  {
    "ID": 1584205909,
    "Tankstellennummer": "TD007443",
    "Name": "SCHUTTERWALD SCHUTTERSTR. 3",
    "Address": "SCHUTTERSTR. 3",
    "Address__1": "",
    "City": "SCHUTTERWALD",
    "Postcode": 77746,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0781-96560",
    "24 hour?": "FALSE",
    "Latitude": 48.4657,
    "Longitude": 7.8799,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4657,7.8799",
    "IsOpen24Hours": false,
    "id": "48.4657,7.8799"
  },
  {
    "ID": 1283846287,
    "Tankstellennummer": "TD039966",
    "Name": "OFFENBURG MAX-PLANCK-STR. 1A",
    "Address": "MAX-PLANCK-STR. 1A",
    "Address__1": "",
    "City": "OFFENBURG",
    "Postcode": 77656,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0781-20396585",
    "24 hour?": "FALSE",
    "Latitude": 48.4725,
    "Longitude": 7.9273,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4725,7.9273",
    "IsOpen24Hours": false,
    "id": "48.4725,7.9273"
  },
  {
    "ID": 28043,
    "Tankstellennummer": "0FP31",
    "Name": "Aral Tankstelle Pfullingen, Zeilstr. 27",
    "Address": "Zeilstraße 27",
    "Address__1": "",
    "City": "Pfullingen",
    "Postcode": 72793,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7121312580,
    "24 hour?": "TRUE",
    "Latitude": 48.47326,
    "Longitude": 9.233511,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.47326,9.233511",
    "IsOpen24Hours": true,
    "id": "48.47326,9.233511"
  },
  {
    "ID": 285452911,
    "Tankstellennummer": 2612,
    "Name": "Agip Rottenburg",
    "Address": "Tuebinger Str. 26",
    "Address__1": "",
    "City": "Rottenburg",
    "Postcode": 72108,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 74729475",
    "24 hour?": "TRUE",
    "Latitude": 48.47332,
    "Longitude": 8.93871,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.47332,8.93871",
    "IsOpen24Hours": true,
    "id": "48.47332,8.93871"
  },
  {
    "ID": 1830748528,
    "Tankstellennummer": "TD190860",
    "Name": "OFFENBURG RAMMERSWEIER STR. 18",
    "Address": "RAMMERSWEIER STR. 18",
    "Address__1": "",
    "City": "OFFENBURG",
    "Postcode": 77654,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0781-36528",
    "24 hour?": "FALSE",
    "Latitude": 48.475,
    "Longitude": 7.9473,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.475,7.9473",
    "IsOpen24Hours": false,
    "id": "48.475,7.9473"
  },
  {
    "ID": 297676107,
    "Tankstellennummer": "0FO27",
    "Name": "OFFINGEN",
    "Address": "Günzburger Straße 23",
    "Address__1": "",
    "City": "Offingen",
    "Postcode": 89362,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8224967490,
    "24 hour?": "FALSE",
    "Latitude": 48.478521,
    "Longitude": 10.363052,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.478521,10.363052",
    "IsOpen24Hours": false,
    "id": "48.478521,10.363052"
  },
  {
    "ID": 2025,
    "Tankstellennummer": "0F920",
    "Name": "Aral Autohof Wang, Gewerbepark Spörer Au 1",
    "Address": "Gewerbepark Spörer Au 1",
    "Address__1": "",
    "City": "Wang bei Moosburg",
    "Postcode": 85368,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8709263168,
    "24 hour?": "TRUE",
    "Latitude": 48.482743,
    "Longitude": 12.000579,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.482743,12.000579",
    "IsOpen24Hours": true,
    "id": "48.482743,12.000579"
  },
  {
    "ID": 1472,
    "Tankstellennummer": "0F535",
    "Name": "Aral Tankstelle Reutlingen, Albstr. 82",
    "Address": "Albstraße 82",
    "Address__1": "",
    "City": "Reutlingen",
    "Postcode": 72764,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7121270312,
    "24 hour?": "TRUE",
    "Latitude": 48.485059,
    "Longitude": 9.2225,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.485059,9.2225",
    "IsOpen24Hours": true,
    "id": "48.485059,9.2225"
  },
  {
    "ID": 1128253174,
    "Tankstellennummer": 2110,
    "Name": "Agip Reutlingen",
    "Address": "Gustav-Schwab-Strasse 41",
    "Address__1": "",
    "City": "Reutlingen",
    "Postcode": 72762,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71212392",
    "24 hour?": "FALSE",
    "Latitude": 48.48938,
    "Longitude": 9.19247,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.48938,9.19247",
    "IsOpen24Hours": false,
    "id": "48.48938,9.19247"
  },
  {
    "ID": 1479,
    "Tankstellennummer": "0F541",
    "Name": "Aral Tankstelle Offenburg, Bundesstr. 19A",
    "Address": "Bundesstraße 19A",
    "Address__1": "",
    "City": "Offenburg",
    "Postcode": 77652,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 78125793,
    "24 hour?": "FALSE",
    "Latitude": 48.490497,
    "Longitude": 7.950969,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.490497,7.950969",
    "IsOpen24Hours": false,
    "id": "48.490497,7.950969"
  },
  {
    "ID": 1414,
    "Tankstellennummer": "0F488",
    "Name": "Aral Tankstelle Rottenburg, Gosbertstr.24",
    "Address": "Gosbertstraße 24",
    "Address__1": "",
    "City": "Rottenburg",
    "Postcode": 72108,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74579599080,
    "24 hour?": "FALSE",
    "Latitude": 48.495669,
    "Longitude": 8.813009,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.495669,8.813009",
    "IsOpen24Hours": false,
    "id": "48.495669,8.813009"
  },
  {
    "ID": 1661509298,
    "Tankstellennummer": 2120,
    "Name": "Agip Reutlingen",
    "Address": "In Laisen 14",
    "Address__1": "",
    "City": "Reutlingen",
    "Postcode": 72766,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71214902",
    "24 hour?": "TRUE",
    "Latitude": 48.50396,
    "Longitude": 9.22034,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.50396,9.22034",
    "IsOpen24Hours": true,
    "id": "48.50396,9.22034"
  },
  {
    "ID": 932236919,
    "Tankstellennummer": "TD001628",
    "Name": "REUTLINGEN STORLACHSTR. 2",
    "Address": "STORLACHSTR. 2",
    "Address__1": "",
    "City": "REUTLINGEN",
    "Postcode": 72760,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07121-334290",
    "24 hour?": "FALSE",
    "Latitude": 48.504,
    "Longitude": 9.2124,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.504,9.2124",
    "IsOpen24Hours": false,
    "id": "48.504,9.2124"
  },
  {
    "ID": 1501,
    "Tankstellennummer": "0F556",
    "Name": "Aral Tankstelle Rottenburg/Ergenzing, Mercedesstraße/B 28 a",
    "Address": "Mercedesstraße 51",
    "Address__1": "",
    "City": "Rottenburg/Ergenzing",
    "Postcode": 72108,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7457931293,
    "24 hour?": "TRUE",
    "Latitude": 48.505305,
    "Longitude": 8.826744,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.505305,8.826744",
    "IsOpen24Hours": true,
    "id": "48.505305,8.826744"
  },
  {
    "ID": 1613911412,
    "Tankstellennummer": "0FV15",
    "Name": "BONDORF",
    "Address": "Am Römerfeld 1",
    "Address__1": "",
    "City": "Bondorf",
    "Postcode": 71149,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74575959090,
    "24 hour?": "TRUE",
    "Latitude": 48.50676,
    "Longitude": 8.83585,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.50676,8.83585",
    "IsOpen24Hours": true,
    "id": "48.50676,8.83585"
  },
  {
    "ID": 216063994,
    "Tankstellennummer": 1656,
    "Name": "Agip Schweitenkirchen",
    "Address": "Oberthann 9",
    "Address__1": "",
    "City": "Schweitenkirchen",
    "Postcode": 85301,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 8444333",
    "24 hour?": "FALSE",
    "Latitude": 48.50747,
    "Longitude": 11.58537,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.50747,11.58537",
    "IsOpen24Hours": false,
    "id": "48.50747,11.58537"
  },
  {
    "ID": 976,
    "Tankstellennummer": "0F164",
    "Name": "Aral Tankstelle Reutlingen, Rommelsbacher Straße 140",
    "Address": "Rommelsbacher Straße 140",
    "Address__1": "",
    "City": "Reutlingen",
    "Postcode": 72760,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7121380665,
    "24 hour?": "TRUE",
    "Latitude": 48.511714,
    "Longitude": 9.204224,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.511714,9.204224",
    "IsOpen24Hours": true,
    "id": "48.511714,9.204224"
  },
  {
    "ID": 1384,
    "Tankstellennummer": "0F465",
    "Name": "Aral Tankstelle Tübingen, Reutlinger Str. 72",
    "Address": "Reutlinger Straße 72",
    "Address__1": "",
    "City": "Tübingen",
    "Postcode": 72072,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 707131941,
    "24 hour?": "TRUE",
    "Latitude": 48.512952,
    "Longitude": 9.073625,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.512952,9.073625",
    "IsOpen24Hours": true,
    "id": "48.512952,9.073625"
  },
  {
    "ID": 529488880,
    "Tankstellennummer": 2100,
    "Name": "Agip Reutlingen-Sondelf.",
    "Address": "Reutlinger Strasse 68",
    "Address__1": "",
    "City": "Reutlingen-Sondelf.",
    "Postcode": 72766,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71214918",
    "24 hour?": "FALSE",
    "Latitude": 48.51504,
    "Longitude": 9.22598,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.51504,9.22598",
    "IsOpen24Hours": false,
    "id": "48.51504,9.22598"
  },
  {
    "ID": 1498,
    "Tankstellennummer": "0F554",
    "Name": "Aral Autohof Merklingen, Nellinger Straße",
    "Address": "Nellinger Straße 24",
    "Address__1": "",
    "City": "Merklingen",
    "Postcode": 89188,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 73379233029,
    "24 hour?": "TRUE",
    "Latitude": 48.516101,
    "Longitude": 9.75969,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.516101,9.75969",
    "IsOpen24Hours": true,
    "id": "48.516101,9.75969"
  },
  {
    "ID": 1892523718,
    "Tankstellennummer": 1540,
    "Name": "Agip Bruckberg",
    "Address": "Bahnhofstrae 15",
    "Address__1": "",
    "City": "Bruckberg",
    "Postcode": 84079,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 87659204",
    "24 hour?": "FALSE",
    "Latitude": 48.52009841,
    "Longitude": 11.99503696,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.52009841,11.99503696",
    "IsOpen24Hours": false,
    "id": "48.52009841,11.99503696"
  },
  {
    "ID": 143274935,
    "Tankstellennummer": "0FQ08",
    "Name": "PFAFFENHOFEN",
    "Address": "Äußere Moosburger Str. 1",
    "Address__1": "",
    "City": "Pfaffenhofen",
    "Postcode": 85276,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8441499190,
    "24 hour?": "FALSE",
    "Latitude": 48.522353,
    "Longitude": 11.543634,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.522353,11.543634",
    "IsOpen24Hours": false,
    "id": "48.522353,11.543634"
  },
  {
    "ID": 1319105694,
    "Tankstellennummer": "TD006072",
    "Name": "TUEBINGEN RHEINLANDSTR. 28-30",
    "Address": "RHEINLANDSTR. 28-30",
    "Address__1": "",
    "City": "TUEBINGEN",
    "Postcode": 72070,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07071-45366",
    "24 hour?": "FALSE",
    "Latitude": 48.5235,
    "Longitude": 9.0393,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.5235,9.0393",
    "IsOpen24Hours": false,
    "id": "48.5235,9.0393"
  },
  {
    "ID": 1991,
    "Tankstellennummer": "0F894",
    "Name": "Aral Tankstelle Fürstenzell, Passauer Str. 39",
    "Address": "Passauer Straße 39",
    "Address__1": "",
    "City": "Fürstenzell",
    "Postcode": 94081,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 85021313,
    "24 hour?": "FALSE",
    "Latitude": 48.525187,
    "Longitude": 13.327044,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.525187,13.327044",
    "IsOpen24Hours": false,
    "id": "48.525187,13.327044"
  },
  {
    "ID": 95474174,
    "Tankstellennummer": 1159,
    "Name": "Agip Landshut",
    "Address": "Wittstrasse 15",
    "Address__1": "",
    "City": "Landshut",
    "Postcode": 84036,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 48.52888,
    "Longitude": 12.14103,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.52888,12.14103",
    "IsOpen24Hours": false,
    "id": "48.52888,12.14103"
  },
  {
    "ID": 916348449,
    "Tankstellennummer": "TD040477",
    "Name": "NIEDERSTOTZINGEN",
    "Address": "BAHNHOFSTR. 22",
    "Address__1": "",
    "City": "NIEDERSTOTZINGEN",
    "Postcode": 89168,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07325/919070",
    "24 hour?": "FALSE",
    "Latitude": 48.5361,
    "Longitude": 10.237,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.5361,10.237",
    "IsOpen24Hours": false,
    "id": "48.5361,10.237"
  },
  {
    "ID": 2094183061,
    "Tankstellennummer": 1541,
    "Name": "Agip Landshut",
    "Address": "Luitpoldstrae 55",
    "Address__1": "",
    "City": "Landshut",
    "Postcode": 84034,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 87161836",
    "24 hour?": "FALSE",
    "Latitude": 48.54211133,
    "Longitude": 12.13959734,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.54211133,12.13959734",
    "IsOpen24Hours": false,
    "id": "48.54211133,12.13959734"
  },
  {
    "ID": 769462581,
    "Tankstellennummer": "TD180400",
    "Name": "METZINGEN NUERTINGER STR. 64",
    "Address": "NUERTINGER STR. 64",
    "Address__1": "",
    "City": "METZINGEN",
    "Postcode": 72555,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07123-200830",
    "24 hour?": "FALSE",
    "Latitude": 48.5437,
    "Longitude": 9.2902,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.5437,9.2902",
    "IsOpen24Hours": false,
    "id": "48.5437,9.2902"
  },
  {
    "ID": 253891210,
    "Tankstellennummer": 1269,
    "Name": "Agip Frontenhausen",
    "Address": "Gangkofener Str. 73",
    "Address__1": "",
    "City": "Frontenhausen",
    "Postcode": 84160,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 87321349",
    "24 hour?": "FALSE",
    "Latitude": 48.54516395,
    "Longitude": 12.5284392,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.54516395,12.5284392",
    "IsOpen24Hours": false,
    "id": "48.54516395,12.5284392"
  },
  {
    "ID": 1933,
    "Tankstellennummer": "0F855",
    "Name": "Aral Tankstelle Landshut, Niedermayerstr. 54",
    "Address": "Niedermayerstraße 54",
    "Address__1": "",
    "City": "Landshut",
    "Postcode": 84036,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 87152916,
    "24 hour?": "TRUE",
    "Latitude": 48.546207,
    "Longitude": 12.181083,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.546207,12.181083",
    "IsOpen24Hours": true,
    "id": "48.546207,12.181083"
  },
  {
    "ID": 860747105,
    "Tankstellennummer": "TD039461",
    "Name": "LANDSHUT OBERNDORFER STR. 23A",
    "Address": "OBERNDORFER STR. 23a",
    "Address__1": "",
    "City": "LANDSHUT",
    "Postcode": 84032,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0871-40471787",
    "24 hour?": "FALSE",
    "Latitude": 48.548,
    "Longitude": 12.1318,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.548,12.1318",
    "IsOpen24Hours": false,
    "id": "48.548,12.1318"
  },
  {
    "ID": 1843,
    "Tankstellennummer": "0F803",
    "Name": "Aral Tankstelle Nagold, Haiterbacher Strasse",
    "Address": "Haiterbacher Straße 24-26",
    "Address__1": "",
    "City": "Nagold",
    "Postcode": 72202,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 74523562,
    "24 hour?": "TRUE",
    "Latitude": 48.548875,
    "Longitude": 8.726787,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.548875,8.726787",
    "IsOpen24Hours": true,
    "id": "48.548875,8.726787"
  },
  {
    "ID": 1310622020,
    "Tankstellennummer": 1658,
    "Name": "Agip Schrobenhausen",
    "Address": "Gerolsbacher Str. 9",
    "Address__1": "",
    "City": "Schrobenhausen",
    "Postcode": 86529,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 82528877",
    "24 hour?": "FALSE",
    "Latitude": 48.55546,
    "Longitude": 11.26806,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.55546,11.26806",
    "IsOpen24Hours": false,
    "id": "48.55546,11.26806"
  },
  {
    "ID": 1945,
    "Tankstellennummer": "0F863",
    "Name": "Aral Autobahntankstelle Geisenhausen, In der Holledau (A 9)",
    "Address": "A9",
    "Address__1": "",
    "City": "Geisenhausen",
    "Postcode": 85301,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8441801573,
    "24 hour?": "TRUE",
    "Latitude": 48.55655,
    "Longitude": 11.587913,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.55655,11.587913",
    "IsOpen24Hours": true,
    "id": "48.55655,11.587913"
  },
  {
    "ID": 3153,
    "Tankstellennummer": "0FJ61",
    "Name": "Aral Tankstelle Passau, Neuburger Str. 141",
    "Address": "Neuburger Straße 141",
    "Address__1": "",
    "City": "Passau",
    "Postcode": 94036,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 85153174,
    "24 hour?": "FALSE",
    "Latitude": 48.558993,
    "Longitude": 13.421607,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.558993,13.421607",
    "IsOpen24Hours": false,
    "id": "48.558993,13.421607"
  },
  {
    "ID": 1483531223,
    "Tankstellennummer": "0FG35",
    "Name": "APPENWEIER",
    "Address": "A5",
    "Address__1": "",
    "City": "Appenweier",
    "Postcode": 77767,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 78052949,
    "24 hour?": "TRUE",
    "Latitude": 48.561717,
    "Longitude": 7.956888,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.561717,7.956888",
    "IsOpen24Hours": true,
    "id": "48.561717,7.956888"
  },
  {
    "ID": 52022622,
    "Tankstellennummer": 1615,
    "Name": "Agip Passau",
    "Address": "Neuburger Str. 104",
    "Address__1": "",
    "City": "Passau",
    "Postcode": 94036,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 85158991",
    "24 hour?": "FALSE",
    "Latitude": 48.5637827,
    "Longitude": 13.42923552,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.5637827,13.42923552",
    "IsOpen24Hours": false,
    "id": "48.5637827,13.42923552"
  },
  {
    "ID": 1872,
    "Tankstellennummer": "0F819",
    "Name": "Aral Autobahntankstelle Appenweier, Renchtal West (A 5)",
    "Address": "A5",
    "Address__1": "",
    "City": "Appenweier",
    "Postcode": 77767,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 78053402,
    "24 hour?": "TRUE",
    "Latitude": 48.563791,
    "Longitude": 7.956917,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.563791,7.956917",
    "IsOpen24Hours": true,
    "id": "48.563791,7.956917"
  },
  {
    "ID": 1617448619,
    "Tankstellennummer": 1277,
    "Name": "Agip Thierhaupten",
    "Address": "Meitinger Str. 16-18",
    "Address__1": "",
    "City": "Thierhaupten",
    "Postcode": 86672,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 82712426",
    "24 hour?": "FALSE",
    "Latitude": 48.56677,
    "Longitude": 10.89826,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.56677,10.89826",
    "IsOpen24Hours": false,
    "id": "48.56677,10.89826"
  },
  {
    "ID": 929919700,
    "Tankstellennummer": 2267,
    "Name": "Agip Grafenberg",
    "Address": "Nuertinger Strasse 30",
    "Address__1": "",
    "City": "Grafenberg",
    "Postcode": 72661,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "07123/939610",
    "24 hour?": "TRUE",
    "Latitude": 48.5668,
    "Longitude": 9.30846,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.5668,9.30846",
    "IsOpen24Hours": true,
    "id": "48.5668,9.30846"
  },
  {
    "ID": 234896777,
    "Tankstellennummer": 1096,
    "Name": "Agip Passau",
    "Address": "Spitalhofstrasse 84",
    "Address__1": "",
    "City": "Passau",
    "Postcode": 94032,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 85170166",
    "24 hour?": "FALSE",
    "Latitude": 48.57020316,
    "Longitude": 13.42698438,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.57020316,13.42698438",
    "IsOpen24Hours": false,
    "id": "48.57020316,13.42698438"
  },
  {
    "ID": 28168781,
    "Tankstellennummer": "TD019372",
    "Name": "KEHL FRIEDHOFSTR. 7",
    "Address": "FRIEDHOFSTR. 7",
    "Address__1": "",
    "City": "KEHL",
    "Postcode": 77694,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07851-640",
    "24 hour?": "FALSE",
    "Latitude": 48.5709,
    "Longitude": 7.8204,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.5709,7.8204",
    "IsOpen24Hours": false,
    "id": "48.5709,7.8204"
  },
  {
    "ID": 2043814678,
    "Tankstellennummer": "0FQ67",
    "Name": "LAUINGEN AN DER DONAU",
    "Address": "Werner-v.-Siemens-Str. 31",
    "Address__1": "",
    "City": "Lauingen an der Donau",
    "Postcode": 89415,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 90727017866,
    "24 hour?": "TRUE",
    "Latitude": 48.575483,
    "Longitude": 10.45461,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.575483,10.45461",
    "IsOpen24Hours": true,
    "id": "48.575483,10.45461"
  },
  {
    "ID": 967,
    "Tankstellennummer": "0F156",
    "Name": "Aral Tankstelle Kehl, Straßburger Str. 36",
    "Address": "Straßburger Straße 34a",
    "Address__1": "",
    "City": "Kehl",
    "Postcode": 77694,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7851958877,
    "24 hour?": "TRUE",
    "Latitude": 48.575546,
    "Longitude": 7.81747,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.575546,7.81747",
    "IsOpen24Hours": true,
    "id": "48.575546,7.81747"
  },
  {
    "ID": 1868,
    "Tankstellennummer": "0F818",
    "Name": "Aral Tankstelle Kehl, Strassburger Str.11",
    "Address": "Strassburger Straße 11a",
    "Address__1": "",
    "City": "Kehl",
    "Postcode": 77694,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7851958081,
    "24 hour?": "TRUE",
    "Latitude": 48.576097,
    "Longitude": 7.818007,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.576097,7.818007",
    "IsOpen24Hours": true,
    "id": "48.576097,7.818007"
  },
  {
    "ID": 1379350259,
    "Tankstellennummer": 1620,
    "Name": "Agip Dillingen",
    "Address": "Grosse Allee 17",
    "Address__1": "",
    "City": "Dillingen",
    "Postcode": 89407,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 90718062",
    "24 hour?": "TRUE",
    "Latitude": 48.58051,
    "Longitude": 10.49173,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.58051,10.49173",
    "IsOpen24Hours": true,
    "id": "48.58051,10.49173"
  },
  {
    "ID": 3197,
    "Tankstellennummer": "0FJ85",
    "Name": "Aral Tankstelle Bad Ditzenbach, Bahnhofstr. 4-8",
    "Address": "Bahnhofstraße 4-8",
    "Address__1": "",
    "City": "Bad Ditzenbach",
    "Postcode": 73342,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 73355304,
    "24 hour?": "FALSE",
    "Latitude": 48.581652,
    "Longitude": 9.684212,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.581652,9.684212",
    "IsOpen24Hours": false,
    "id": "48.581652,9.684212"
  },
  {
    "ID": 3200,
    "Tankstellennummer": "0FJ87",
    "Name": "Aral Tankstelle Frickenhausen, Theodor Heuss Str. 20",
    "Address": "Theodor Heuss Straße 20",
    "Address__1": "",
    "City": "Frickenhausen",
    "Postcode": 72636,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 70252555,
    "24 hour?": "FALSE",
    "Latitude": 48.582309,
    "Longitude": 9.368684,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.582309,9.368684",
    "IsOpen24Hours": false,
    "id": "48.582309,9.368684"
  },
  {
    "ID": 210794111,
    "Tankstellennummer": "0FY35",
    "Name": "GIENGEN",
    "Address": "A7",
    "Address__1": "",
    "City": "Giengen",
    "Postcode": 89537,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 732496150,
    "24 hour?": "TRUE",
    "Latitude": 48.5829,
    "Longitude": 10.1771,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.5829,10.1771",
    "IsOpen24Hours": true,
    "id": "48.5829,10.1771"
  },
  {
    "ID": 2037,
    "Tankstellennummer": "0FE77",
    "Name": "Aral Autobahntankstelle Passau, Donautal Ost (A 3)",
    "Address": "Reichsgrafenstraße 35/A3",
    "Address__1": "",
    "City": "Passau",
    "Postcode": 94036,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 85175688831,
    "24 hour?": "TRUE",
    "Latitude": 48.587579,
    "Longitude": 13.365938,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.587579,13.365938",
    "IsOpen24Hours": true,
    "id": "48.587579,13.365938"
  },
  {
    "ID": 1213313631,
    "Tankstellennummer": "TD041111",
    "Name": "ALDERSBACH RITTER-TUSCHL-STR.",
    "Address": "RITTER-TUSCHL-STR. 39",
    "Address__1": "",
    "City": "ALDERSBACH",
    "Postcode": 94501,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 8543916298,
    "24 hour?": "FALSE",
    "Latitude": 48.5903,
    "Longitude": 13.094,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.5903,13.094",
    "IsOpen24Hours": false,
    "id": "48.5903,13.094"
  },
  {
    "ID": 592564307,
    "Tankstellennummer": "TD036434",
    "Name": "DEGGINGEN KOENIGSTR. 52",
    "Address": "KOENIGSTR. 52",
    "Address__1": "",
    "City": "DEGGINGEN",
    "Postcode": 73326,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07334-5408",
    "24 hour?": "FALSE",
    "Latitude": 48.5968,
    "Longitude": 9.7159,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.5968,9.7159",
    "IsOpen24Hours": false,
    "id": "48.5968,9.7159"
  },
  {
    "ID": 3471,
    "Tankstellennummer": "0FL90",
    "Name": "Aral Tankstelle Hermaringen, Friedrichstr. 23",
    "Address": "Friedrichstraße 23",
    "Address__1": "",
    "City": "Hermaringen",
    "Postcode": 89568,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7322919572,
    "24 hour?": "FALSE",
    "Latitude": 48.597607,
    "Longitude": 10.256253,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.597607,10.256253",
    "IsOpen24Hours": false,
    "id": "48.597607,10.256253"
  },
  {
    "ID": 232318871,
    "Tankstellennummer": 1621,
    "Name": "Agip Wolnzach",
    "Address": "Preysingstr. 58",
    "Address__1": "",
    "City": "Wolnzach",
    "Postcode": 85283,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 84429119",
    "24 hour?": "FALSE",
    "Latitude": 48.59798814,
    "Longitude": 11.6300023,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.59798814,11.6300023",
    "IsOpen24Hours": false,
    "id": "48.59798814,11.6300023"
  },
  {
    "ID": 762367900,
    "Tankstellennummer": "TD039883",
    "Name": "NORDENDORF B2",
    "Address": "B2",
    "Address__1": "",
    "City": "NORDENDORF",
    "Postcode": 86695,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08273/9969887",
    "24 hour?": "FALSE",
    "Latitude": 48.5984,
    "Longitude": 10.8405,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.5984,10.8405",
    "IsOpen24Hours": false,
    "id": "48.5984,10.8405"
  },
  {
    "ID": 415493301,
    "Tankstellennummer": "TD007997",
    "Name": "KEHL-BODERSWEIER RASTATTERSTRA",
    "Address": "RASTATTERSTRASSE 5",
    "Address__1": "",
    "City": "KEHL-BODERSWEIER",
    "Postcode": 77694,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07853-356",
    "24 hour?": "FALSE",
    "Latitude": 48.5995,
    "Longitude": 7.8683,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.5995,7.8683",
    "IsOpen24Hours": false,
    "id": "48.5995,7.8683"
  },
  {
    "ID": 1461,
    "Tankstellennummer": "0F526",
    "Name": "Aral Tankstelle Herrenberg, Stuttgarter Str.43",
    "Address": "Stuttgarter Straße43",
    "Address__1": "",
    "City": "Herrenberg",
    "Postcode": 71083,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 703224366,
    "24 hour?": "TRUE",
    "Latitude": 48.600553,
    "Longitude": 8.873095,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.600553,8.873095",
    "IsOpen24Hours": true,
    "id": "48.600553,8.873095"
  },
  {
    "ID": 1381904996,
    "Tankstellennummer": "TD180760",
    "Name": "ACHERN OFFENBURGER STR. 36",
    "Address": "OFFENBURGER STR. 36",
    "Address__1": "",
    "City": "ACHERN",
    "Postcode": 77855,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07841-21364",
    "24 hour?": "FALSE",
    "Latitude": 48.6054,
    "Longitude": 8.0324,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6054,8.0324",
    "IsOpen24Hours": false,
    "id": "48.6054,8.0324"
  },
  {
    "ID": 1440,
    "Tankstellennummer": "0F507",
    "Name": "Aral Tankstelle Geislingen, Schillerstraße 34",
    "Address": "Schillerstraße 34",
    "Address__1": "",
    "City": "Geislingen",
    "Postcode": 73312,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 733142173,
    "24 hour?": "TRUE",
    "Latitude": 48.609757,
    "Longitude": 9.842613,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.609757,9.842613",
    "IsOpen24Hours": true,
    "id": "48.609757,9.842613"
  },
  {
    "ID": 1499,
    "Tankstellennummer": "0F555",
    "Name": "Aral Tankstelle Nürtingen, Robert-Bosch-Str. 9-11",
    "Address": "Robert-Bosch-Straße 9-11",
    "Address__1": "",
    "City": "Nürtingen",
    "Postcode": 72622,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 70229417838,
    "24 hour?": "FALSE",
    "Latitude": 48.615639,
    "Longitude": 9.331526,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.615639,9.331526",
    "IsOpen24Hours": false,
    "id": "48.615639,9.331526"
  },
  {
    "ID": 2489,
    "Tankstellennummer": "0FC35",
    "Name": "Aral Tankstelle Salzweg, Passauer Str. 18",
    "Address": "Passauer Straße 18",
    "Address__1": "",
    "City": "Salzweg",
    "Postcode": 94121,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 85142693,
    "24 hour?": "FALSE",
    "Latitude": 48.618297,
    "Longitude": 13.483034,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.618297,13.483034",
    "IsOpen24Hours": false,
    "id": "48.618297,13.483034"
  },
  {
    "ID": 2035,
    "Tankstellennummer": "0FE95",
    "Name": "Aral Tankstelle Tiefenbach, Am Jackinger Berg",
    "Address": "Am Jackinger Berg",
    "Address__1": "",
    "City": "Tiefenbach",
    "Postcode": 94113,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8509937774,
    "24 hour?": "TRUE",
    "Latitude": 48.620407,
    "Longitude": 13.416174,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.620407,13.416174",
    "IsOpen24Hours": true,
    "id": "48.620407,13.416174"
  },
  {
    "ID": 1924105170,
    "Tankstellennummer": "TD038653",
    "Name": "NUFRINGEN ROHRAUER STR.38",
    "Address": "ROHRAUER STR.38",
    "Address__1": "",
    "City": "NUFRINGEN",
    "Postcode": 71154,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 7032958055,
    "24 hour?": "FALSE",
    "Latitude": 48.6208,
    "Longitude": 8.8946,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6208,8.8946",
    "IsOpen24Hours": false,
    "id": "48.6208,8.8946"
  },
  {
    "ID": 473946768,
    "Tankstellennummer": 2028,
    "Name": "Agip Giengen",
    "Address": "Heidenheimer Strasse 47",
    "Address__1": "",
    "City": "Giengen",
    "Postcode": 89537,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 73229195",
    "24 hour?": "FALSE",
    "Latitude": 48.62368,
    "Longitude": 10.24219,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.62368,10.24219",
    "IsOpen24Hours": false,
    "id": "48.62368,10.24219"
  },
  {
    "ID": 272013596,
    "Tankstellennummer": "TD182360",
    "Name": "GIENGEN HEIDENHEIMER STR. 19",
    "Address": "HEIDENHEIMER STR. 19",
    "Address__1": "",
    "City": "GIENGEN",
    "Postcode": 89537,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07322-7513",
    "24 hour?": "FALSE",
    "Latitude": 48.6238,
    "Longitude": 10.2413,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6238,10.2413",
    "IsOpen24Hours": false,
    "id": "48.6238,10.2413"
  },
  {
    "ID": 1186851119,
    "Tankstellennummer": "0FO66",
    "Name": "WÖRTH A. D. ISAR",
    "Address": "Siemensstraße 2",
    "Address__1": "",
    "City": "Wörth a. d. Isar",
    "Postcode": 84109,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8702949351,
    "24 hour?": "TRUE",
    "Latitude": 48.625107,
    "Longitude": 12.322628,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.625107,12.322628",
    "IsOpen24Hours": true,
    "id": "48.625107,12.322628"
  },
  {
    "ID": 227724445,
    "Tankstellennummer": "TD160670",
    "Name": "MAINBURG-SANDELZHSN AEUSS. LAN",
    "Address": "AEUSS. LANDSHUTER STR. 38",
    "Address__1": "",
    "City": "MAINBURG-SANDELZHSN",
    "Postcode": 84048,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08751-1658",
    "24 hour?": "FALSE",
    "Latitude": 48.6269,
    "Longitude": 11.7934,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6269,11.7934",
    "IsOpen24Hours": false,
    "id": "48.6269,11.7934"
  },
  {
    "ID": 573340971,
    "Tankstellennummer": 1078,
    "Name": "Agip Vilshofen",
    "Address": "Ortenburger Strasse 40",
    "Address__1": "",
    "City": "Vilshofen",
    "Postcode": 94474,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 85418595",
    "24 hour?": "FALSE",
    "Latitude": 48.6269912,
    "Longitude": 13.189829,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6269912,13.189829",
    "IsOpen24Hours": false,
    "id": "48.6269912,13.189829"
  },
  {
    "ID": 1504,
    "Tankstellennummer": "0FG63",
    "Name": "Aral Tankstelle Herbrechtingen, Zeppelinweg 2",
    "Address": "Zeppelinweg 2",
    "Address__1": "",
    "City": "Herbrechtingen",
    "Postcode": 89542,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 73249833735,
    "24 hour?": "TRUE",
    "Latitude": 48.627676,
    "Longitude": 10.18718,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.627676,10.18718",
    "IsOpen24Hours": true,
    "id": "48.627676,10.18718"
  },
  {
    "ID": 1866,
    "Tankstellennummer": "0F816",
    "Name": "Aral Tankstelle Achern, Fautenbacher Strasse 22",
    "Address": "Fautenbacher Straße 22",
    "Address__1": "",
    "City": "Achern",
    "Postcode": 77855,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 784124099,
    "24 hour?": "FALSE",
    "Latitude": 48.628514,
    "Longitude": 8.063374,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.628514,8.063374",
    "IsOpen24Hours": false,
    "id": "48.628514,8.063374"
  },
  {
    "ID": 2096845714,
    "Tankstellennummer": "0FX95",
    "Name": "AICHTAL",
    "Address": "Bertha-Benz-Straße 1",
    "Address__1": "",
    "City": "Aichtal",
    "Postcode": 72631,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "TRUE",
    "Latitude": 48.62972,
    "Longitude": 9.23594,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.62972,9.23594",
    "IsOpen24Hours": true,
    "id": "48.62972,9.23594"
  },
  {
    "ID": 1655688356,
    "Tankstellennummer": "TD190090",
    "Name": "SASBACH HAUPTSTR. 25",
    "Address": "HAUPTSTR. 25",
    "Address__1": "",
    "City": "SASBACH",
    "Postcode": 77880,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07841-664138",
    "24 hour?": "FALSE",
    "Latitude": 48.6355,
    "Longitude": 8.0881,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6355,8.0881",
    "IsOpen24Hours": false,
    "id": "48.6355,8.0881"
  },
  {
    "ID": 1997,
    "Tankstellennummer": "0F897",
    "Name": "Aral Tankstelle Mainburg, Freisinger Str. 21",
    "Address": "Freisinger Straße 21",
    "Address__1": "",
    "City": "Mainburg",
    "Postcode": 84048,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 87515838,
    "24 hour?": "FALSE",
    "Latitude": 48.635765,
    "Longitude": 11.779198,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.635765,11.779198",
    "IsOpen24Hours": false,
    "id": "48.635765,11.779198"
  },
  {
    "ID": 2487,
    "Tankstellennummer": "0FC34",
    "Name": "Aral Tankstelle Dingolfing, Bahnhof Str.18",
    "Address": "Bahnhofstraße 18",
    "Address__1": "",
    "City": "Dingolfing",
    "Postcode": 84130,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 873173537,
    "24 hour?": "TRUE",
    "Latitude": 48.636243,
    "Longitude": 12.495942,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.636243,12.495942",
    "IsOpen24Hours": true,
    "id": "48.636243,12.495942"
  },
  {
    "ID": 244637377,
    "Tankstellennummer": "TD182090",
    "Name": "WILDBERG CALWER STR. 304",
    "Address": "CALWER STR. 304",
    "Address__1": "",
    "City": "WILDBERG",
    "Postcode": 72218,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07054-365",
    "24 hour?": "FALSE",
    "Latitude": 48.6367,
    "Longitude": 8.7468,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6367,8.7468",
    "IsOpen24Hours": false,
    "id": "48.6367,8.7468"
  },
  {
    "ID": 1914730795,
    "Tankstellennummer": 2108,
    "Name": "Agip Kuchen",
    "Address": "Hauptstrasse 38",
    "Address__1": "",
    "City": "Kuchen",
    "Postcode": 73329,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 73318681",
    "24 hour?": "FALSE",
    "Latitude": 48.63763,
    "Longitude": 9.79569,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.63763,9.79569",
    "IsOpen24Hours": false,
    "id": "48.63763,9.79569"
  },
  {
    "ID": 745841216,
    "Tankstellennummer": "TD033175",
    "Name": "GAERTRINGEN BOEBLINGER STR. 6",
    "Address": "BOEBLINGER STR. 6",
    "Address__1": "",
    "City": "GAERTRINGEN",
    "Postcode": 71116,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07034-285065",
    "24 hour?": "FALSE",
    "Latitude": 48.6389,
    "Longitude": 8.9098,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6389,8.9098",
    "IsOpen24Hours": false,
    "id": "48.6389,8.9098"
  },
  {
    "ID": 139402941,
    "Tankstellennummer": "TD039370",
    "Name": "DINGOLFING ALEMANNENSTRASSE 1",
    "Address": "ALEMANNENSTRASSE 1",
    "Address__1": "",
    "City": "DINGOLFING",
    "Postcode": 84130,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 87313099830,
    "24 hour?": "FALSE",
    "Latitude": 48.6411,
    "Longitude": 12.4633,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6411,12.4633",
    "IsOpen24Hours": false,
    "id": "48.6411,12.4633"
  },
  {
    "ID": 3449,
    "Tankstellennummer": "0FL76",
    "Name": "Aral Tankstelle Kirchheim, Kirchheimer Str. 97",
    "Address": "Kirchheimer Straße 97",
    "Address__1": "",
    "City": "Kirchheim",
    "Postcode": 73230,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 702181775,
    "24 hour?": "FALSE",
    "Latitude": 48.641695,
    "Longitude": 9.476037,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.641695,9.476037",
    "IsOpen24Hours": false,
    "id": "48.641695,9.476037"
  },
  {
    "ID": 782217106,
    "Tankstellennummer": "TD038406",
    "Name": "KIRCHHEIM/TECK IM HAG 2-4",
    "Address": "IM HAG 2-4",
    "Address__1": "",
    "City": "KIRCHHEIM/TECK",
    "Postcode": 73230,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07021-863650",
    "24 hour?": "FALSE",
    "Latitude": 48.6422,
    "Longitude": 9.4337,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6422,9.4337",
    "IsOpen24Hours": false,
    "id": "48.6422,9.4337"
  },
  {
    "ID": 773366042,
    "Tankstellennummer": 1281,
    "Name": "Agip Reichertshofen/Wind.",
    "Address": "Obere Hauptstr. 5",
    "Address__1": "",
    "City": "Reichertshofen/Wind.",
    "Postcode": 85084,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 84533306",
    "24 hour?": "TRUE",
    "Latitude": 48.6451472,
    "Longitude": 11.51395257,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.6451472,11.51395257",
    "IsOpen24Hours": true,
    "id": "48.6451472,11.51395257"
  },
  {
    "ID": 3339,
    "Tankstellennummer": "0FK90",
    "Name": "Aral Tankstelle Holzgerlingen, Boeblinger Str. 69",
    "Address": "Boeblinger Straße 69",
    "Address__1": "",
    "City": "Holzgerlingen",
    "Postcode": 71088,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7031605288,
    "24 hour?": "FALSE",
    "Latitude": 48.645636,
    "Longitude": 9.009776,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.645636,9.009776",
    "IsOpen24Hours": false,
    "id": "48.645636,9.009776"
  },
  {
    "ID": 1477,
    "Tankstellennummer": "0F539",
    "Name": "Aral Tankstelle Wolfschlugen, Nürtinger Str.49",
    "Address": "Nürtinger Straße 49",
    "Address__1": "",
    "City": "Wolfschlugen",
    "Postcode": 72649,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7022953580,
    "24 hour?": "FALSE",
    "Latitude": 48.651158,
    "Longitude": 9.295594,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.651158,9.295594",
    "IsOpen24Hours": false,
    "id": "48.651158,9.295594"
  },
  {
    "ID": 551293305,
    "Tankstellennummer": "TD000746",
    "Name": "FILDERSTADT-HARTH HARTH.HAUPTS",
    "Address": "HARTH.HAUPTSTR. 50-52",
    "Address__1": "",
    "City": "FILDERSTADT-HARTH",
    "Postcode": 70794,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07158-982131",
    "24 hour?": "FALSE",
    "Latitude": 48.6516,
    "Longitude": 9.2463,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6516,9.2463",
    "IsOpen24Hours": false,
    "id": "48.6516,9.2463"
  },
  {
    "ID": 1985,
    "Tankstellennummer": "0F887",
    "Name": "Aral Tankstelle Hauzenberg, Josef-Greschniok-Str. 1",
    "Address": "Josef-Greschniok-Straße 1",
    "Address__1": "",
    "City": "Hauzenberg",
    "Postcode": 94051,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 85861342,
    "24 hour?": "FALSE",
    "Latitude": 48.654014,
    "Longitude": 13.620601,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.654014,13.620601",
    "IsOpen24Hours": false,
    "id": "48.654014,13.620601"
  },
  {
    "ID": 485536402,
    "Tankstellennummer": 2609,
    "Name": "Agip Kirchheim",
    "Address": "Stuttgarter Str. 144",
    "Address__1": "",
    "City": "Kirchheim",
    "Postcode": 73230,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 70213054",
    "24 hour?": "FALSE",
    "Latitude": 48.65565,
    "Longitude": 9.42765,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.65565,9.42765",
    "IsOpen24Hours": false,
    "id": "48.65565,9.42765"
  },
  {
    "ID": 1443,
    "Tankstellennummer": "0F511",
    "Name": "Aral Tankstelle Filderstadt, Hohenheimer Str.49",
    "Address": "Hohenheimer Straße 49",
    "Address__1": "",
    "City": "Filderstadt",
    "Postcode": 70794,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71188241521,
    "24 hour?": "FALSE",
    "Latitude": 48.659377,
    "Longitude": 9.197765,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.659377,9.197765",
    "IsOpen24Hours": false,
    "id": "48.659377,9.197765"
  },
  {
    "ID": 1090688,
    "Tankstellennummer": 2153,
    "Name": "Agip Steinenbronn",
    "Address": "Umgehungsstrasse 65",
    "Address__1": "",
    "City": "Steinenbronn",
    "Postcode": 71144,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71572750",
    "24 hour?": "FALSE",
    "Latitude": 48.66626,
    "Longitude": 9.12409,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.66626,9.12409",
    "IsOpen24Hours": false,
    "id": "48.66626,9.12409"
  },
  {
    "ID": 2089644248,
    "Tankstellennummer": 2616,
    "Name": "Agip Boeblingen",
    "Address": "Schoenbuchstr. 40",
    "Address__1": "",
    "City": "Boeblingen",
    "Postcode": 71032,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 70312797",
    "24 hour?": "FALSE",
    "Latitude": 48.67316,
    "Longitude": 9.01353,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.67316,9.01353",
    "IsOpen24Hours": false,
    "id": "48.67316,9.01353"
  },
  {
    "ID": 1867,
    "Tankstellennummer": "0F817",
    "Name": "Aral Tankstelle Ottersweier, Hauptstrasse 114",
    "Address": "Hauptstraße 114",
    "Address__1": "",
    "City": "Ottersweier",
    "Postcode": 77833,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72232818707,
    "24 hour?": "FALSE",
    "Latitude": 48.67359,
    "Longitude": 8.120672,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.67359,8.120672",
    "IsOpen24Hours": false,
    "id": "48.67359,8.120672"
  },
  {
    "ID": 429065111,
    "Tankstellennummer": "TD001552",
    "Name": "KOENGEN BAHNHOFSTR. 23",
    "Address": "BAHNHOFSTR. 23",
    "Address__1": "",
    "City": "KOENGEN",
    "Postcode": 73257,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07024-84995",
    "24 hour?": "FALSE",
    "Latitude": 48.6775,
    "Longitude": 9.3671,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6775,9.3671",
    "IsOpen24Hours": false,
    "id": "48.6775,9.3671"
  },
  {
    "ID": 1124573054,
    "Tankstellennummer": 1016,
    "Name": "Agip Tapfheim",
    "Address": "Dillinger Strasse 34",
    "Address__1": "",
    "City": "Tapfheim",
    "Postcode": 86660,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 90709217",
    "24 hour?": "FALSE",
    "Latitude": 48.67982245,
    "Longitude": 10.69982659,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.67982245,10.69982659",
    "IsOpen24Hours": false,
    "id": "48.67982245,10.69982659"
  },
  {
    "ID": 1289421740,
    "Tankstellennummer": "TD182380",
    "Name": "RHEINAU-FREISTETT AM RHEINUEBE",
    "Address": "AM RHEINUEBERGANG",
    "Address__1": "",
    "City": "RHEINAU-FREISTETT",
    "Postcode": 77866,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07844-991122/24",
    "24 hour?": "FALSE",
    "Latitude": 48.6805,
    "Longitude": 7.9222,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6805,7.9222",
    "IsOpen24Hours": false,
    "id": "48.6805,7.9222"
  },
  {
    "ID": 922488725,
    "Tankstellennummer": 2034,
    "Name": "Agip Goeppingen",
    "Address": "Schlater Strasse 39",
    "Address__1": "",
    "City": "Goeppingen",
    "Postcode": 73037,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71618118",
    "24 hour?": "FALSE",
    "Latitude": 48.68181,
    "Longitude": 9.68773,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.68181,9.68773",
    "IsOpen24Hours": false,
    "id": "48.68181,9.68773"
  },
  {
    "ID": 1485,
    "Tankstellennummer": "0F545",
    "Name": "Aral Tankstelle Böblingen, Herrenberger Str. 50",
    "Address": "Herrenberger Straße 50",
    "Address__1": "",
    "City": "Böblingen",
    "Postcode": 71034,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7031225353,
    "24 hour?": "TRUE",
    "Latitude": 48.683029,
    "Longitude": 9.001526,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.683029,9.001526",
    "IsOpen24Hours": true,
    "id": "48.683029,9.001526"
  },
  {
    "ID": 1301476890,
    "Tankstellennummer": "TD040170",
    "Name": "FILDERSTADT PLIENINGER STR. 51",
    "Address": "PLIENINGER STR. 51",
    "Address__1": "",
    "City": "FILDERSTADT",
    "Postcode": 70794,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0711-65504188",
    "24 hour?": "FALSE",
    "Latitude": 48.6841,
    "Longitude": 9.2186,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6841,9.2186",
    "IsOpen24Hours": false,
    "id": "48.6841,9.2186"
  },
  {
    "ID": 2480,
    "Tankstellennummer": "0FE68",
    "Name": "Aral Tankstelle Ergoldsbach, Landshuter Strasse 20",
    "Address": "Landshuter Straße 20",
    "Address__1": "",
    "City": "Ergoldsbach",
    "Postcode": 84061,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 87711345,
    "24 hour?": "TRUE",
    "Latitude": 48.68472,
    "Longitude": 12.206087,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.68472,12.206087",
    "IsOpen24Hours": true,
    "id": "48.68472,12.206087"
  },
  {
    "ID": 1448,
    "Tankstellennummer": "0F516",
    "Name": "Aral Tankstelle Donzdorf, Hauptstrasse 18",
    "Address": "Hauptstraße 18",
    "Address__1": "",
    "City": "Donzdorf",
    "Postcode": 73072,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 716229869,
    "24 hour?": "FALSE",
    "Latitude": 48.685977,
    "Longitude": 9.803848,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.685977,9.803848",
    "IsOpen24Hours": false,
    "id": "48.685977,9.803848"
  },
  {
    "ID": 1614724690,
    "Tankstellennummer": 1090,
    "Name": "Agip Rain am Lech",
    "Address": "Muenchner Strasse 28",
    "Address__1": "",
    "City": "Rain am Lech",
    "Postcode": 86641,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "09090 / 9594877",
    "24 hour?": "FALSE",
    "Latitude": 48.68683,
    "Longitude": 10.91974,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.68683,10.91974",
    "IsOpen24Hours": false,
    "id": "48.68683,10.91974"
  },
  {
    "ID": 929954620,
    "Tankstellennummer": "TD039446",
    "Name": "NATTHEIM MEMMINGER HAU 1",
    "Address": "MEMMINGER HAU 1",
    "Address__1": "",
    "City": "NATTHEIM",
    "Postcode": 89564,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 73217478314,
    "24 hour?": "FALSE",
    "Latitude": 48.6894,
    "Longitude": 10.2133,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6894,10.2133",
    "IsOpen24Hours": false,
    "id": "48.6894,10.2133"
  },
  {
    "ID": 1488,
    "Tankstellennummer": "0F547",
    "Name": "Aral Tankstelle Heidenheim, Nördlinger Str. 72",
    "Address": "Nördlinger Straße 72",
    "Address__1": "",
    "City": "Heidenheim",
    "Postcode": 89520,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 732125375,
    "24 hour?": "TRUE",
    "Latitude": 48.690636,
    "Longitude": 10.165861,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.690636,10.165861",
    "IsOpen24Hours": true,
    "id": "48.690636,10.165861"
  },
  {
    "ID": 2007,
    "Tankstellennummer": "0F907",
    "Name": "Aral Tankstelle Neuhausen, Plieninger Str. 35",
    "Address": "Plieninger Straße 35",
    "Address__1": "",
    "City": "Neuhausen",
    "Postcode": 73765,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71589857104,
    "24 hour?": "TRUE",
    "Latitude": 48.691251,
    "Longitude": 9.265736,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.691251,9.265736",
    "IsOpen24Hours": true,
    "id": "48.691251,9.265736"
  },
  {
    "ID": 61824071,
    "Tankstellennummer": "TD041442",
    "Name": "DENKENDORF HOHER RAIN 2",
    "Address": "HOHER RAIN 2",
    "Address__1": "",
    "City": "DENKENDORF",
    "Postcode": 73770,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 71193445340,
    "24 hour?": "FALSE",
    "Latitude": 48.6929,
    "Longitude": 9.3049,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6929,9.3049",
    "IsOpen24Hours": false,
    "id": "48.6929,9.3049"
  },
  {
    "ID": 1580084582,
    "Tankstellennummer": "0FY16",
    "Name": "PILSTING",
    "Address": "Hietzinger Wiesen 4",
    "Address__1": "",
    "City": "Pilsting",
    "Postcode": 94431,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 99539807970,
    "24 hour?": "TRUE",
    "Latitude": 48.6929,
    "Longitude": 12.67337,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.6929,12.67337",
    "IsOpen24Hours": true,
    "id": "48.6929,12.67337"
  },
  {
    "ID": 2006,
    "Tankstellennummer": "0F906",
    "Name": "Aral Tankstelle Eislingen, Stuttgarter Straße 119",
    "Address": "Stuttgarter Straße 119",
    "Address__1": "",
    "City": "Eislingen",
    "Postcode": 73054,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7161990220,
    "24 hour?": "TRUE",
    "Latitude": 48.696419,
    "Longitude": 9.690237,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.696419,9.690237",
    "IsOpen24Hours": true,
    "id": "48.696419,9.690237"
  },
  {
    "ID": 1468,
    "Tankstellennummer": "0F531",
    "Name": "Aral Tankstelle Leinfelden-Echterdin, Hauptstr.159",
    "Address": "Hauptstraße 159",
    "Address__1": "",
    "City": "Leinfelden-Echterdin",
    "Postcode": 70771,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 711793779,
    "24 hour?": "TRUE",
    "Latitude": 48.696706,
    "Longitude": 9.166695,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.696706,9.166695",
    "IsOpen24Hours": true,
    "id": "48.696706,9.166695"
  },
  {
    "ID": 41520412,
    "Tankstellennummer": "0FG21",
    "Name": "Aral TankStelle Göppingen, Jahnstraße 88",
    "Address": "Jahnstraße 88",
    "Address__1": "",
    "City": "Göppingen",
    "Postcode": 73037,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 716173548,
    "24 hour?": "TRUE",
    "Latitude": 48.696904,
    "Longitude": 9.659792,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.696904,9.659792",
    "IsOpen24Hours": true,
    "id": "48.696904,9.659792"
  },
  {
    "ID": 1808,
    "Tankstellennummer": "0F779",
    "Name": "Aral Tankstelle Bühl, Hauptstr. 129",
    "Address": "Hauptstraße 129",
    "Address__1": "",
    "City": "Bühl",
    "Postcode": 77815,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72234364,
    "24 hour?": "FALSE",
    "Latitude": 48.7021,
    "Longitude": 8.139317,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.7021,8.139317",
    "IsOpen24Hours": false,
    "id": "48.7021,8.139317"
  },
  {
    "ID": 1382,
    "Tankstellennummer": "0F463",
    "Name": "Aral Tankstelle Göppingen, Hohenstaufenstr. 5",
    "Address": "Hohenstaufenstraße 5",
    "Address__1": "",
    "City": "Göppingen",
    "Postcode": 73033,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 716175454,
    "24 hour?": "TRUE",
    "Latitude": 48.702837,
    "Longitude": 9.661701,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.702837,9.661701",
    "IsOpen24Hours": true,
    "id": "48.702837,9.661701"
  },
  {
    "ID": 117863404,
    "Tankstellennummer": 1297,
    "Name": "Agip Ingolstadt-Zuchering",
    "Address": "Am Hochfeldweg 2",
    "Address__1": "",
    "City": "Ingolstadt-Zuchering",
    "Postcode": 85051,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 84509286",
    "24 hour?": "TRUE",
    "Latitude": 48.7033131,
    "Longitude": 11.41864491,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.7033131,11.41864491",
    "IsOpen24Hours": true,
    "id": "48.7033131,11.41864491"
  },
  {
    "ID": 1897981600,
    "Tankstellennummer": 2617,
    "Name": "Agip Sindelfingen",
    "Address": "H.-M.-Schleyer Str. 1",
    "Address__1": "",
    "City": "Sindelfingen",
    "Postcode": 71063,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 70318165",
    "24 hour?": "TRUE",
    "Latitude": 48.70349621,
    "Longitude": 9.00171422,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.70349621,9.00171422",
    "IsOpen24Hours": true,
    "id": "48.70349621,9.00171422"
  },
  {
    "ID": 757949354,
    "Tankstellennummer": "TD000360",
    "Name": "LEINFELDEN MEISENWEG 8",
    "Address": "MEISENWEG 8",
    "Address__1": "",
    "City": "LEINFELDEN",
    "Postcode": 70771,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0711-756429",
    "24 hour?": "FALSE",
    "Latitude": 48.7055,
    "Longitude": 9.144,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.7055,9.144",
    "IsOpen24Hours": false,
    "id": "48.7055,9.144"
  },
  {
    "ID": 1450,
    "Tankstellennummer": "0F518",
    "Name": "Aral Tankstelle Sindelfingen, Mahdentalstr. 86",
    "Address": "Mahdentalstraße 86",
    "Address__1": "",
    "City": "Sindelfingen",
    "Postcode": 71065,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7031811966,
    "24 hour?": "TRUE",
    "Latitude": 48.706791,
    "Longitude": 9.027986,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.706791,9.027986",
    "IsOpen24Hours": true,
    "id": "48.706791,9.027986"
  },
  {
    "ID": 1500,
    "Tankstellennummer": "0FE71",
    "Name": "Aral Tankstelle Denkendorf, Marie-Curie-Str. 2",
    "Address": "Marie-Curie-Straße 2",
    "Address__1": "",
    "City": "Denkendorf",
    "Postcode": 73770,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7113482625,
    "24 hour?": "TRUE",
    "Latitude": 48.707259,
    "Longitude": 9.305527,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.707259,9.305527",
    "IsOpen24Hours": true,
    "id": "48.707259,9.305527"
  },
  {
    "ID": 1018769218,
    "Tankstellennummer": "0FN84",
    "Name": "UHINGEN",
    "Address": "Ulmer Straße 39",
    "Address__1": "",
    "City": "Uhingen",
    "Postcode": 73066,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71619838726,
    "24 hour?": "FALSE",
    "Latitude": 48.707301,
    "Longitude": 9.589791,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.707301,9.589791",
    "IsOpen24Hours": false,
    "id": "48.707301,9.589791"
  },
  {
    "ID": 17778,
    "Tankstellennummer": "0FP23",
    "Name": "Aral Tankstelle Göppingen, Stuttgarter Straße 80",
    "Address": "Stuttgarter Straße 80",
    "Address__1": "",
    "City": "Göppingen",
    "Postcode": 73033,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7161922495,
    "24 hour?": "TRUE",
    "Latitude": 48.708973,
    "Longitude": 9.633905,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.708973,9.633905",
    "IsOpen24Hours": true,
    "id": "48.708973,9.633905"
  },
  {
    "ID": 547093038,
    "Tankstellennummer": "TD039800",
    "Name": "DONAUWOERTH AUGSBURGER STRASSE",
    "Address": "AUGSBURGER STRASSE 16",
    "Address__1": "",
    "City": "DONAUWOERTH",
    "Postcode": 86609,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0906-4494",
    "24 hour?": "FALSE",
    "Latitude": 48.7108,
    "Longitude": 10.7878,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.7108,10.7878",
    "IsOpen24Hours": false,
    "id": "48.7108,10.7878"
  },
  {
    "ID": 19268934,
    "Tankstellennummer": 2109,
    "Name": "Agip Calw",
    "Address": "Stuttgarter Strasse 92",
    "Address__1": "",
    "City": "Calw",
    "Postcode": 75365,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 70513027",
    "24 hour?": "FALSE",
    "Latitude": 48.71118239,
    "Longitude": 8.75631913,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.71118239,8.75631913",
    "IsOpen24Hours": false,
    "id": "48.71118239,8.75631913"
  },
  {
    "ID": 1486998683,
    "Tankstellennummer": "TD180180",
    "Name": "GOEPPINGEN LORCHER STR. 67",
    "Address": "LORCHER STR. 67",
    "Address__1": "",
    "City": "GOEPPINGEN",
    "Postcode": 73033,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07161-25770",
    "24 hour?": "FALSE",
    "Latitude": 48.7114,
    "Longitude": 9.6489,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.7114,9.6489",
    "IsOpen24Hours": false,
    "id": "48.7114,9.6489"
  },
  {
    "ID": 1630510436,
    "Tankstellennummer": "0FN46",
    "Name": "EBERSBACH AN DER FILS",
    "Address": "Stuttgarter Straße 85",
    "Address__1": "",
    "City": "Ebersbach a. d. Fils",
    "Postcode": 73061,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7163532228,
    "24 hour?": "FALSE",
    "Latitude": 48.713698,
    "Longitude": 9.513178,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.713698,9.513178",
    "IsOpen24Hours": false,
    "id": "48.713698,9.513178"
  },
  {
    "ID": 1095693227,
    "Tankstellennummer": "TD001727",
    "Name": "GRAFENAU DAETZINGER STR. 36",
    "Address": "DAETZINGER STR. 36",
    "Address__1": "",
    "City": "GRAFENAU",
    "Postcode": 71120,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07033-43950",
    "24 hour?": "FALSE",
    "Latitude": 48.7149,
    "Longitude": 8.9006,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.7149,8.9006",
    "IsOpen24Hours": false,
    "id": "48.7149,8.9006"
  },
  {
    "ID": 1670188459,
    "Tankstellennummer": 1651,
    "Name": "Agip Elsendorf",
    "Address": "Gewerbegeb. Langweid a.d. B301",
    "Address__1": "",
    "City": "Elsendorf",
    "Postcode": 84094,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 87539674",
    "24 hour?": "TRUE",
    "Latitude": 48.71580033,
    "Longitude": 11.8022279,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.71580033,11.8022279",
    "IsOpen24Hours": true,
    "id": "48.71580033,11.8022279"
  },
  {
    "ID": 220315189,
    "Tankstellennummer": 1650,
    "Name": "Agip Donauwoerth",
    "Address": "Buergermeister-Hefele-Strasse 1",
    "Address__1": "",
    "City": "Donauwoerth",
    "Postcode": 86609,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 90670556",
    "24 hour?": "TRUE",
    "Latitude": 48.71945,
    "Longitude": 10.77674,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.71945,10.77674",
    "IsOpen24Hours": true,
    "id": "48.71945,10.77674"
  },
  {
    "ID": 919780170,
    "Tankstellennummer": 1618,
    "Name": "Agip Neuburg / Donau",
    "Address": "Augsburger Strasse 133",
    "Address__1": "",
    "City": "Neuburg / Donau",
    "Postcode": 86633,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 84311329",
    "24 hour?": "FALSE",
    "Latitude": 48.71954295,
    "Longitude": 11.17740875,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.71954295,11.17740875",
    "IsOpen24Hours": false,
    "id": "48.71954295,11.17740875"
  },
  {
    "ID": 302164822,
    "Tankstellennummer": 2025,
    "Name": "Agip Stuttgart",
    "Address": "Welfenstrasse 32",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70599,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71145348",
    "24 hour?": "FALSE",
    "Latitude": 48.71958,
    "Longitude": 9.20812,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.71958,9.20812",
    "IsOpen24Hours": false,
    "id": "48.71958,9.20812"
  },
  {
    "ID": 2021,
    "Tankstellennummer": "0F917",
    "Name": "Aral Tankstelle Neuburg, Am Südpark 2",
    "Address": "Am Südpark 2",
    "Address__1": "",
    "City": "Neuburg",
    "Postcode": 86633,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8431430346,
    "24 hour?": "TRUE",
    "Latitude": 48.720188,
    "Longitude": 11.172039,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.720188,11.172039",
    "IsOpen24Hours": true,
    "id": "48.720188,11.172039"
  },
  {
    "ID": 3205,
    "Tankstellennummer": "0FJ90",
    "Name": "Aral Tankstelle Stuttgart, Plieninger Str. 96",
    "Address": "Plieninger Straße 96",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70567,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7117285215,
    "24 hour?": "TRUE",
    "Latitude": 48.723202,
    "Longitude": 9.159887,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.723202,9.159887",
    "IsOpen24Hours": true,
    "id": "48.723202,9.159887"
  },
  {
    "ID": 1438,
    "Tankstellennummer": "0F506",
    "Name": "Aral Tankstelle Altbach, Esslinger Str. 43",
    "Address": "Esslinger Straße 43",
    "Address__1": "",
    "City": "Altbach",
    "Postcode": 73776,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 715327370,
    "24 hour?": "FALSE",
    "Latitude": 48.723648,
    "Longitude": 9.377717,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.723648,9.377717",
    "IsOpen24Hours": false,
    "id": "48.723648,9.377717"
  },
  {
    "ID": 3207,
    "Tankstellennummer": "0FJ92",
    "Name": "Aral Tankstelle Stuttgart, Robert-Koch-Str. 64",
    "Address": "Robert-Koch-Straße 64",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70563,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7119019518,
    "24 hour?": "FALSE",
    "Latitude": 48.724877,
    "Longitude": 9.10656,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.724877,9.10656",
    "IsOpen24Hours": false,
    "id": "48.724877,9.10656"
  },
  {
    "ID": 28549,
    "Tankstellennummer": "0FP53",
    "Name": "BP ALTHENGSTETT",
    "Address": "Gottlieb-Braun-Straße 33 / B 295",
    "Address__1": "",
    "City": "Althengstett",
    "Postcode": 75382,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7051922290,
    "24 hour?": "TRUE",
    "Latitude": 48.726751,
    "Longitude": 8.786109,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.726751,8.786109",
    "IsOpen24Hours": true,
    "id": "48.726751,8.786109"
  },
  {
    "ID": 1668276370,
    "Tankstellennummer": "0FN42",
    "Name": "BADEN-BADEN",
    "Address": "Poststraße 40",
    "Address__1": "",
    "City": "Baden-Baden",
    "Postcode": 76534,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 722396420,
    "24 hour?": "FALSE",
    "Latitude": 48.729295,
    "Longitude": 8.156018,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.729295,8.156018",
    "IsOpen24Hours": false,
    "id": "48.729295,8.156018"
  },
  {
    "ID": 1474,
    "Tankstellennummer": "0F536",
    "Name": "Aral Tankstelle Stuttgart, Hauptstr. 156",
    "Address": "Hauptstr. 156",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70563,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 711731748,
    "24 hour?": "FALSE",
    "Latitude": 48.730937,
    "Longitude": 9.095362,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.730937,9.095362",
    "IsOpen24Hours": false,
    "id": "48.730937,9.095362"
  },
  {
    "ID": 1775289837,
    "Tankstellennummer": "TD181280",
    "Name": "STUTTGART SEEROSENSTR. 48",
    "Address": "SEEROSENSTR. 48",
    "Address__1": "",
    "City": "STUTTGART",
    "Postcode": 70563,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0711-67201840",
    "24 hour?": "FALSE",
    "Latitude": 48.7337,
    "Longitude": 9.1069,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.7337,9.1069",
    "IsOpen24Hours": false,
    "id": "48.7337,9.1069"
  },
  {
    "ID": 1830,
    "Tankstellennummer": "0F793",
    "Name": "Aral Tankstelle Oberreichenbach, Wildbader Str.55",
    "Address": "Wildbader Straße 55",
    "Address__1": "",
    "City": "Oberreichenbach",
    "Postcode": 75394,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7051968683,
    "24 hour?": "FALSE",
    "Latitude": 48.734192,
    "Longitude": 8.662784,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.734192,8.662784",
    "IsOpen24Hours": false,
    "id": "48.734192,8.662784"
  },
  {
    "ID": 1027203139,
    "Tankstellennummer": "0FN82",
    "Name": "MAGSTADT",
    "Address": "Hohberger Straße 19",
    "Address__1": "",
    "City": "Magstadt",
    "Postcode": 71106,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 715994740,
    "24 hour?": "FALSE",
    "Latitude": 48.738601,
    "Longitude": 8.966261,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.738601,8.966261",
    "IsOpen24Hours": false,
    "id": "48.738601,8.966261"
  },
  {
    "ID": 1318191965,
    "Tankstellennummer": "TD181430",
    "Name": "ESSLINGEN URBANSTR. 65",
    "Address": "URBANSTR. 65",
    "Address__1": "",
    "City": "ESSLINGEN",
    "Postcode": 73728,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0711-9319777",
    "24 hour?": "FALSE",
    "Latitude": 48.7396,
    "Longitude": 9.3197,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.7396,9.3197",
    "IsOpen24Hours": false,
    "id": "48.7396,9.3197"
  },
  {
    "ID": 1432,
    "Tankstellennummer": "0F501",
    "Name": "Aral Tankstelle Stuttgart, Kirchheimer Str. 108",
    "Address": "Kirchheimer Straße 108",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70619,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7114799609,
    "24 hour?": "FALSE",
    "Latitude": 48.741936,
    "Longitude": 9.217338,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.741936,9.217338",
    "IsOpen24Hours": false,
    "id": "48.741936,9.217338"
  },
  {
    "ID": 2015,
    "Tankstellennummer": "0F913",
    "Name": "Aral Autobahntankstelle Sindelfingen, Sindelfinger Wald Süd (A 8)",
    "Address": "A8",
    "Address__1": "",
    "City": "Sindelfingen",
    "Postcode": 71067,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 703170810,
    "24 hour?": "TRUE",
    "Latitude": 48.742578,
    "Longitude": 9.034182,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.742578,9.034182",
    "IsOpen24Hours": true,
    "id": "48.742578,9.034182"
  },
  {
    "ID": 252592704,
    "Tankstellennummer": 2272,
    "Name": "Agip Magstadt",
    "Address": "Aichern 3",
    "Address__1": "",
    "City": "Magstadt",
    "Postcode": 71106,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 7159/939",
    "24 hour?": "FALSE",
    "Latitude": 48.74274,
    "Longitude": 8.96295,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.74274,8.96295",
    "IsOpen24Hours": false,
    "id": "48.74274,8.96295"
  },
  {
    "ID": 1288899532,
    "Tankstellennummer": 1211,
    "Name": "Agip Neureichenau",
    "Address": "Dreisesselstr. 39",
    "Address__1": "",
    "City": "Neureichenau",
    "Postcode": 94089,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 85839607",
    "24 hour?": "FALSE",
    "Latitude": 48.74444361,
    "Longitude": 13.74979969,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.74444361,13.74979969",
    "IsOpen24Hours": false,
    "id": "48.74444361,13.74979969"
  },
  {
    "ID": 3474,
    "Tankstellennummer": "0FL93",
    "Name": "Aral Tankstelle Magstadt, Blumenstr. 39",
    "Address": "Blumenstraße 39",
    "Address__1": "",
    "City": "Magstadt",
    "Postcode": 71106,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7159949619,
    "24 hour?": "FALSE",
    "Latitude": 48.744829,
    "Longitude": 8.971171,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.744829,8.971171",
    "IsOpen24Hours": false,
    "id": "48.744829,8.971171"
  },
  {
    "ID": 1553979026,
    "Tankstellennummer": 1216,
    "Name": "Agip Ingolstadt",
    "Address": "Manchinger Str. 115",
    "Address__1": "",
    "City": "Ingolstadt",
    "Postcode": 85053,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 84162883",
    "24 hour?": "FALSE",
    "Latitude": 48.74492,
    "Longitude": 11.47045,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.74492,11.47045",
    "IsOpen24Hours": false,
    "id": "48.74492,11.47045"
  },
  {
    "ID": 1763,
    "Tankstellennummer": "0F776",
    "Name": "Aral Tankstelle Baden-Baden, Maximilianstr. 45",
    "Address": "Maximilianstraße 45",
    "Address__1": "",
    "City": "Baden-Baden",
    "Postcode": 76534,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72217438,
    "24 hour?": "FALSE",
    "Latitude": 48.749844,
    "Longitude": 8.254592,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.749844,8.254592",
    "IsOpen24Hours": false,
    "id": "48.749844,8.254592"
  },
  {
    "ID": 1922453253,
    "Tankstellennummer": 2103,
    "Name": "Agip Esslingen",
    "Address": "Obertuerkheimer Str. 21",
    "Address__1": "",
    "City": "Esslingen",
    "Postcode": 73733,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71191833",
    "24 hour?": "FALSE",
    "Latitude": 48.75094,
    "Longitude": 9.2756,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.75094,9.2756",
    "IsOpen24Hours": false,
    "id": "48.75094,9.2756"
  },
  {
    "ID": 813325966,
    "Tankstellennummer": "TD038398",
    "Name": "INGOLSTADT MANCHINGERSTR. 84",
    "Address": "MANCHINGER STR. 84",
    "Address__1": "",
    "City": "INGOLSTADT",
    "Postcode": 85053,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0841/99389815",
    "24 hour?": "FALSE",
    "Latitude": 48.753,
    "Longitude": 11.4518,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.753,11.4518",
    "IsOpen24Hours": false,
    "id": "48.753,11.4518"
  },
  {
    "ID": 1925,
    "Tankstellennummer": "0F850",
    "Name": "Aral Tankstelle Ingolstadt, Manchinger Str. 76",
    "Address": "Manchinger Straße 76",
    "Address__1": "",
    "City": "Ingolstadt",
    "Postcode": 85053,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 84168555,
    "24 hour?": "TRUE",
    "Latitude": 48.753933,
    "Longitude": 11.449887,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.753933,11.449887",
    "IsOpen24Hours": true,
    "id": "48.753933,11.449887"
  },
  {
    "ID": 2018,
    "Tankstellennummer": "0F915",
    "Name": "Aral Tankstelle Neresheim, Nördlinger Str. 18-22",
    "Address": "Nördlinger Straße 18-22",
    "Address__1": "",
    "City": "Neresheim",
    "Postcode": 73450,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 73269644533,
    "24 hour?": "FALSE",
    "Latitude": 48.754295,
    "Longitude": 10.336607,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.754295,10.336607",
    "IsOpen24Hours": false,
    "id": "48.754295,10.336607"
  },
  {
    "ID": 1770102966,
    "Tankstellennummer": "TD039784",
    "Name": "NERESHEIM HEIDENHEIMER STR. 17",
    "Address": "HEIDENHEIMER STR. 17",
    "Address__1": "",
    "City": "NERESHEIM",
    "Postcode": 73450,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 7326389,
    "24 hour?": "FALSE",
    "Latitude": 48.755,
    "Longitude": 10.3283,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.755,10.3283",
    "IsOpen24Hours": false,
    "id": "48.755,10.3283"
  },
  {
    "ID": 284348439,
    "Tankstellennummer": "0FP60",
    "Name": "WEIL DER STADT",
    "Address": "Siemensstraße 9",
    "Address__1": "",
    "City": "Weil der Stadt",
    "Postcode": 71263,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 70336005,
    "24 hour?": "FALSE",
    "Latitude": 48.756123,
    "Longitude": 8.867436,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.756123,8.867436",
    "IsOpen24Hours": false,
    "id": "48.756123,8.867436"
  },
  {
    "ID": 978,
    "Tankstellennummer": "0F166",
    "Name": "Aral Tankstelle Bad Wildbad, Calmbacher Str. 53",
    "Address": "Calmbacher Straße 53",
    "Address__1": "",
    "City": "Bad Wildbad",
    "Postcode": 75323,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7081380973,
    "24 hour?": "FALSE",
    "Latitude": 48.758847,
    "Longitude": 8.551376,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.758847,8.551376",
    "IsOpen24Hours": false,
    "id": "48.758847,8.551376"
  },
  {
    "ID": 644948071,
    "Tankstellennummer": "0FN81",
    "Name": "ESSLINGEN-WÄLDENBRON",
    "Address": "Stettener Straße 112",
    "Address__1": "",
    "City": "Esslingen-Wäldenbron",
    "Postcode": 73732,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7119385399,
    "24 hour?": "FALSE",
    "Latitude": 48.759401,
    "Longitude": 9.317381,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.759401,9.317381",
    "IsOpen24Hours": false,
    "id": "48.759401,9.317381"
  },
  {
    "ID": 574595749,
    "Tankstellennummer": 1542,
    "Name": "Agip Ingolstadt",
    "Address": "Muenchner Strasse 284",
    "Address__1": "",
    "City": "Ingolstadt",
    "Postcode": 85051,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 84112945",
    "24 hour?": "FALSE",
    "Latitude": 48.76215,
    "Longitude": 11.42539,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.76215,11.42539",
    "IsOpen24Hours": false,
    "id": "48.76215,11.42539"
  },
  {
    "ID": 2032239918,
    "Tankstellennummer": "TD000564",
    "Name": "SINZHEIM BERGSTR.1",
    "Address": "BERGSTR.1",
    "Address__1": "",
    "City": "SINZHEIM",
    "Postcode": 76547,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07221-8433",
    "24 hour?": "FALSE",
    "Latitude": 48.7644,
    "Longitude": 8.1695,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.7644,8.1695",
    "IsOpen24Hours": false,
    "id": "48.7644,8.1695"
  },
  {
    "ID": 1071523439,
    "Tankstellennummer": 2604,
    "Name": "Agip Stuttgart",
    "Address": "Hedelfinger Str. 73",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70327,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71142246",
    "24 hour?": "FALSE",
    "Latitude": 48.76608,
    "Longitude": 9.24985,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.76608,9.24985",
    "IsOpen24Hours": false,
    "id": "48.76608,9.24985"
  },
  {
    "ID": 932647530,
    "Tankstellennummer": 1603,
    "Name": "Agip Ingolstadt",
    "Address": "Regensburger Str. 65",
    "Address__1": "",
    "City": "Ingolstadt",
    "Postcode": 85055,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 84158198",
    "24 hour?": "FALSE",
    "Latitude": 48.76782,
    "Longitude": 11.44751,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.76782,11.44751",
    "IsOpen24Hours": false,
    "id": "48.76782,11.44751"
  },
  {
    "ID": 1408,
    "Tankstellennummer": "0F484",
    "Name": "Aral Tankstelle Renningen, Leonberger Str.76",
    "Address": "Leonberger Straße 76",
    "Address__1": "",
    "City": "Renningen",
    "Postcode": 71272,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7159800881,
    "24 hour?": "FALSE",
    "Latitude": 48.767975,
    "Longitude": 8.944295,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.767975,8.944295",
    "IsOpen24Hours": false,
    "id": "48.767975,8.944295"
  },
  {
    "ID": 3258,
    "Tankstellennummer": "0FK31",
    "Name": "Aral Tankstelle Vohburg, Regensburger Str. 30",
    "Address": "Regensburger Straße 30",
    "Address__1": "",
    "City": "Vohburg",
    "Postcode": 85088,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 845792960,
    "24 hour?": "FALSE",
    "Latitude": 48.768766,
    "Longitude": 11.623462,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.768766,11.623462",
    "IsOpen24Hours": false,
    "id": "48.768766,11.623462"
  },
  {
    "ID": 17777,
    "Tankstellennummer": "0FP18",
    "Name": "Aral Tankstelle Stuttgart, Hedelfinger Straße 25a",
    "Address": "Hedelfinger Straße 25 A",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70327,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7114077791,
    "24 hour?": "TRUE",
    "Latitude": 48.769526,
    "Longitude": 9.248199,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.769526,9.248199",
    "IsOpen24Hours": true,
    "id": "48.769526,9.248199"
  },
  {
    "ID": 1850,
    "Tankstellennummer": "0F807",
    "Name": "Aral Tankstelle Baden-Baden, Lange Str. 122",
    "Address": "Lange Straße 122",
    "Address__1": "",
    "City": "Baden-Baden",
    "Postcode": 76530,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 722125331,
    "24 hour?": "TRUE",
    "Latitude": 48.770545,
    "Longitude": 8.228437,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.770545,8.228437",
    "IsOpen24Hours": true,
    "id": "48.770545,8.228437"
  },
  {
    "ID": 673322684,
    "Tankstellennummer": 2615,
    "Name": "Agip Bad Wildbad-Calmbach",
    "Address": "Kleinenztalstrasse 39",
    "Address__1": "",
    "City": "Bad Wildbad-Calmbach",
    "Postcode": 75323,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 70817554",
    "24 hour?": "FALSE",
    "Latitude": 48.77125543,
    "Longitude": 8.58378518,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.77125543,8.58378518",
    "IsOpen24Hours": false,
    "id": "48.77125543,8.58378518"
  },
  {
    "ID": 3306,
    "Tankstellennummer": "0FK64",
    "Name": "Aral Tankstelle Ingolstadt, Schollstrasse 2",
    "Address": "Schollstraße 2",
    "Address__1": "",
    "City": "Ingolstadt",
    "Postcode": 85055,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8412294,
    "24 hour?": "TRUE",
    "Latitude": 48.775251,
    "Longitude": 11.459352,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.775251,11.459352",
    "IsOpen24Hours": true,
    "id": "48.775251,11.459352"
  },
  {
    "ID": 1495,
    "Tankstellennummer": "0F551",
    "Name": "Aral Tankstelle Oberkochen, Heidenheimer Str.110-116",
    "Address": "Heidenheimer Straße 116",
    "Address__1": "",
    "City": "Oberkochen",
    "Postcode": 73447,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7364921880,
    "24 hour?": "FALSE",
    "Latitude": 48.775676,
    "Longitude": 10.098891,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.775676,10.098891",
    "IsOpen24Hours": false,
    "id": "48.775676,10.098891"
  },
  {
    "ID": 584117770,
    "Tankstellennummer": 1214,
    "Name": "Agip Ingolstadt",
    "Address": "Roemerstr. 50",
    "Address__1": "",
    "City": "Ingolstadt",
    "Postcode": 85055,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 84195653",
    "24 hour?": "TRUE",
    "Latitude": 48.77641,
    "Longitude": 11.45548,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.77641,11.45548",
    "IsOpen24Hours": true,
    "id": "48.77641,11.45548"
  },
  {
    "ID": 1127070857,
    "Tankstellennummer": "TD001644",
    "Name": "STUTTGART ULMER STR. 206",
    "Address": "ULMER STR. 206",
    "Address__1": "",
    "City": "STUTTGART",
    "Postcode": 70188,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0711-423388",
    "24 hour?": "FALSE",
    "Latitude": 48.7794,
    "Longitude": 9.2307,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.7794,9.2307",
    "IsOpen24Hours": false,
    "id": "48.7794,9.2307"
  },
  {
    "ID": 3150,
    "Tankstellennummer": "0FJ59",
    "Name": "Aral Tankstelle Hengersberg, Deggendorfer Str. 48",
    "Address": "Deggendorfer Straße 48",
    "Address__1": "",
    "City": "Hengersberg",
    "Postcode": 94491,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 990193470,
    "24 hour?": "FALSE",
    "Latitude": 48.780168,
    "Longitude": 13.05283,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.780168,13.05283",
    "IsOpen24Hours": false,
    "id": "48.780168,13.05283"
  },
  {
    "ID": 808440728,
    "Tankstellennummer": 2076,
    "Name": "Agip Stuttgart",
    "Address": "Kriegsbergstrasse 55 A",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70174,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71129502",
    "24 hour?": "TRUE",
    "Latitude": 48.78229,
    "Longitude": 9.17149,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.78229,9.17149",
    "IsOpen24Hours": true,
    "id": "48.78229,9.17149"
  },
  {
    "ID": 1029812592,
    "Tankstellennummer": "TD036731",
    "Name": "PLATTLING DEGGENDORFER STRASSE",
    "Address": "DEGGENDORFER STRASSE 61",
    "Address__1": "",
    "City": "PLATTLING",
    "Postcode": 94447,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09931-3696",
    "24 hour?": "FALSE",
    "Latitude": 48.7828,
    "Longitude": 12.8804,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.7828,12.8804",
    "IsOpen24Hours": false,
    "id": "48.7828,12.8804"
  },
  {
    "ID": 871543749,
    "Tankstellennummer": 1280,
    "Name": "Agip Neustadt/Donau",
    "Address": "Raffineriestr. 119",
    "Address__1": "",
    "City": "Neustadt/Donau",
    "Postcode": 93333,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 94457502",
    "24 hour?": "TRUE",
    "Latitude": 48.78366927,
    "Longitude": 11.76958312,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.78366927,11.76958312",
    "IsOpen24Hours": true,
    "id": "48.78366927,11.76958312"
  },
  {
    "ID": 1824685358,
    "Tankstellennummer": "TD008011",
    "Name": "BADEN-BADEN OOSER BAHNHOFSTR.",
    "Address": "OOSER BAHNHOFSTR. 53",
    "Address__1": "",
    "City": "BADEN-BADEN",
    "Postcode": 76532,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07221-394559",
    "24 hour?": "FALSE",
    "Latitude": 48.786,
    "Longitude": 8.1971,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.786,8.1971",
    "IsOpen24Hours": false,
    "id": "48.786,8.1971"
  },
  {
    "ID": 864084542,
    "Tankstellennummer": 2611,
    "Name": "Agip Schwaebisch-Gmuend",
    "Address": "Eutighofer Str. 124",
    "Address__1": "",
    "City": "Schwaebisch-Gmuend",
    "Postcode": 73525,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71713082",
    "24 hour?": "FALSE",
    "Latitude": 48.78604,
    "Longitude": 9.76823,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.78604,9.76823",
    "IsOpen24Hours": false,
    "id": "48.78604,9.76823"
  },
  {
    "ID": 747294120,
    "Tankstellennummer": "TD039230",
    "Name": "SCHWAEBISCH-GMUEND LORCHER STR",
    "Address": "LORCHER STR. 199",
    "Address__1": "",
    "City": "SCHWAEBISCH-GMUEND",
    "Postcode": 73529,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07171-9998895",
    "24 hour?": "FALSE",
    "Latitude": 48.7886,
    "Longitude": 9.7634,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.7886,9.7634",
    "IsOpen24Hours": false,
    "id": "48.7886,9.7634"
  },
  {
    "ID": 3194,
    "Tankstellennummer": "0FJ82",
    "Name": "Aral Tankstelle Stuttgart, Cannstatter Str. 46",
    "Address": "Cannstatter Straße 46",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70190,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7112625648,
    "24 hour?": "TRUE",
    "Latitude": 48.789219,
    "Longitude": 9.192324,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.789219,9.192324",
    "IsOpen24Hours": true,
    "id": "48.789219,9.192324"
  },
  {
    "ID": 578839668,
    "Tankstellennummer": 2602,
    "Name": "Agip Gerlingen",
    "Address": "Stuttgarter Strasse 24",
    "Address__1": "",
    "City": "Gerlingen",
    "Postcode": 70839,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71562530",
    "24 hour?": "FALSE",
    "Latitude": 48.78995,
    "Longitude": 9.04618,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.78995,9.04618",
    "IsOpen24Hours": false,
    "id": "48.78995,9.04618"
  },
  {
    "ID": 1597371188,
    "Tankstellennummer": 2281,
    "Name": "Agip Stuttgart",
    "Address": "Augsburger Strasse 231",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70327,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "0711/32770830",
    "24 hour?": "FALSE",
    "Latitude": 48.79124,
    "Longitude": 9.24389,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.79124,9.24389",
    "IsOpen24Hours": false,
    "id": "48.79124,9.24389"
  },
  {
    "ID": 1815,
    "Tankstellennummer": "0F785",
    "Name": "Aral Tankstelle Hügelsheim, Hauptstr. 1",
    "Address": "Hauptstraße 1",
    "Address__1": "",
    "City": "Hügelsheim",
    "Postcode": 76549,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7229181765,
    "24 hour?": "FALSE",
    "Latitude": 48.799102,
    "Longitude": 8.115871,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.799102,8.115871",
    "IsOpen24Hours": false,
    "id": "48.799102,8.115871"
  },
  {
    "ID": 848435798,
    "Tankstellennummer": 2144,
    "Name": "Agip Plüderhausen",
    "Address": "Jakob-Schuele-Str. 52",
    "Address__1": "",
    "City": "Pluederhausen",
    "Postcode": 73655,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "07181/82667",
    "24 hour?": "FALSE",
    "Latitude": 48.80059,
    "Longitude": 9.59286,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.80059,9.59286",
    "IsOpen24Hours": false,
    "id": "48.80059,9.59286"
  },
  {
    "ID": 1436,
    "Tankstellennummer": "0F504",
    "Name": "Aral Tankstelle Gerlingen, Weilimdorfer Strasse 25",
    "Address": "Weilimdorfer Straße 25",
    "Address__1": "",
    "City": "Gerlingen",
    "Postcode": 70839,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 715621349,
    "24 hour?": "FALSE",
    "Latitude": 48.800904,
    "Longitude": 9.06681,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.800904,9.06681",
    "IsOpen24Hours": false,
    "id": "48.800904,9.06681"
  },
  {
    "ID": 1415,
    "Tankstellennummer": "0F489",
    "Name": "Aral Tankstelle Plüderhausen, Jacob-Schüle-Str. 60",
    "Address": "Jakob-Schüle-Straße 60",
    "Address__1": "",
    "City": "Plüderhausen",
    "Postcode": 73655,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71819940325,
    "24 hour?": "FALSE",
    "Latitude": 48.801836,
    "Longitude": 9.591705,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.801836,9.591705",
    "IsOpen24Hours": false,
    "id": "48.801836,9.591705"
  },
  {
    "ID": 3196,
    "Tankstellennummer": "0FJ84",
    "Name": "Aral Tankstelle Schwäbisch Gmünd, Remsstr. 10",
    "Address": "Remsstraße 10",
    "Address__1": "",
    "City": "Schwäbisch Gmünd",
    "Postcode": 73525,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 717136288,
    "24 hour?": "TRUE",
    "Latitude": 48.802795,
    "Longitude": 9.797567,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.802795,9.797567",
    "IsOpen24Hours": true,
    "id": "48.802795,9.797567"
  },
  {
    "ID": 1385,
    "Tankstellennummer": "0F466",
    "Name": "Aral Tankstelle Stuttgart, Waiblinger Str. 23-25",
    "Address": "Waiblinger Straße 23-25",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70372,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7119561039,
    "24 hour?": "TRUE",
    "Latitude": 48.804345,
    "Longitude": 9.220273,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.804345,9.220273",
    "IsOpen24Hours": true,
    "id": "48.804345,9.220273"
  },
  {
    "ID": 941724631,
    "Tankstellennummer": 2254,
    "Name": "Agip Service Station",
    "Address": "Heilbronner Str. 390",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70469,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "",
    "24 hour?": "TRUE",
    "Latitude": 48.80442,
    "Longitude": 9.18345,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.80442,9.18345",
    "IsOpen24Hours": true,
    "id": "48.80442,9.18345"
  },
  {
    "ID": 1423,
    "Tankstellennummer": "0F495",
    "Name": "Aral Tankstelle Winterbach, Ritterstr.14",
    "Address": "Ritterstraße14",
    "Address__1": "",
    "City": "Winterbach",
    "Postcode": 73650,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 718141154,
    "24 hour?": "FALSE",
    "Latitude": 48.804879,
    "Longitude": 9.478821,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.804879,9.478821",
    "IsOpen24Hours": false,
    "id": "48.804879,9.478821"
  },
  {
    "ID": 1442,
    "Tankstellennummer": "0F510",
    "Name": "Aral Tankstelle Weinstadt, Stuttgarter Str. 67",
    "Address": "Stuttgarter Straße 67",
    "Address__1": "",
    "City": "Weinstadt",
    "Postcode": 71384,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 715165446,
    "24 hour?": "FALSE",
    "Latitude": 48.805327,
    "Longitude": 9.381961,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.805327,9.381961",
    "IsOpen24Hours": false,
    "id": "48.805327,9.381961"
  },
  {
    "ID": 334252757,
    "Tankstellennummer": 2143,
    "Name": "Agip Fellbach",
    "Address": "Rommelshauser Strasse 30",
    "Address__1": "",
    "City": "Fellbach",
    "Postcode": 70734,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71158171",
    "24 hour?": "FALSE",
    "Latitude": 48.80568569,
    "Longitude": 9.3051507,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.80568569,9.3051507",
    "IsOpen24Hours": false,
    "id": "48.80568569,9.3051507"
  },
  {
    "ID": 1444,
    "Tankstellennummer": "0F512",
    "Name": "Aral Tankstelle Schorndorf, Stuttgarter Strasse 36",
    "Address": "Stuttgarter Straße 36",
    "Address__1": "",
    "City": "Schorndorf",
    "Postcode": 73614,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7181979888,
    "24 hour?": "TRUE",
    "Latitude": 48.805699,
    "Longitude": 9.515002,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.805699,9.515002",
    "IsOpen24Hours": true,
    "id": "48.805699,9.515002"
  },
  {
    "ID": 527425147,
    "Tankstellennummer": "TD036491",
    "Name": "SCHORNDORF STUTTGARTER STR. 62",
    "Address": "STUTTGARTER STR. 62",
    "Address__1": "",
    "City": "SCHORNDORF",
    "Postcode": 73614,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07181-75965",
    "24 hour?": "FALSE",
    "Latitude": 48.8057,
    "Longitude": 9.508,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8057,9.508",
    "IsOpen24Hours": false,
    "id": "48.8057,9.508"
  },
  {
    "ID": 824288631,
    "Tankstellennummer": "TD036525",
    "Name": "STUTTGART NUERNBERGER STR. 9",
    "Address": "NUERNBERGER STR. 9",
    "Address__1": "",
    "City": "STUTTGART",
    "Postcode": 70374,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0711-522550",
    "24 hour?": "FALSE",
    "Latitude": 48.8061,
    "Longitude": 9.2324,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8061,9.2324",
    "IsOpen24Hours": false,
    "id": "48.8061,9.2324"
  },
  {
    "ID": 2005,
    "Tankstellennummer": "0F905",
    "Name": "Aral Tankstelle Stuttgart, Pragstraße 138 A",
    "Address": "Pragstraße 138 A",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70376,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7118560500,
    "24 hour?": "TRUE",
    "Latitude": 48.807538,
    "Longitude": 9.194154,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.807538,9.194154",
    "IsOpen24Hours": true,
    "id": "48.807538,9.194154"
  },
  {
    "ID": 1814,
    "Tankstellennummer": "0F784",
    "Name": "Aral Tankstelle Höfen, Hindenburgstr.  75",
    "Address": "Hindenburgstraße  75",
    "Address__1": "",
    "City": "Höfen",
    "Postcode": 75339,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 70815286,
    "24 hour?": "FALSE",
    "Latitude": 48.808028,
    "Longitude": 8.58397,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.808028,8.58397",
    "IsOpen24Hours": false,
    "id": "48.808028,8.58397"
  },
  {
    "ID": 536843198,
    "Tankstellennummer": 2252,
    "Name": "Agip Kernen",
    "Address": "Karlstr. 60",
    "Address__1": "",
    "City": "Kernen",
    "Postcode": 71394,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 7151-206",
    "24 hour?": "FALSE",
    "Latitude": 48.8087,
    "Longitude": 9.32529,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8087,9.32529",
    "IsOpen24Hours": false,
    "id": "48.8087,9.32529"
  },
  {
    "ID": 3322,
    "Tankstellennummer": "0FK77",
    "Name": "Aral Tankstelle Urbach, Schorndorfer Str. 49",
    "Address": "Schorndorfer Straße 49",
    "Address__1": "",
    "City": "Urbach",
    "Postcode": 73660,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7181981808,
    "24 hour?": "TRUE",
    "Latitude": 48.809126,
    "Longitude": 9.57053,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.809126,9.57053",
    "IsOpen24Hours": true,
    "id": "48.809126,9.57053"
  },
  {
    "ID": 1126601080,
    "Tankstellennummer": 2605,
    "Name": "Agip Stuttgart",
    "Address": "Nuernberger Strasse 18",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70374,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71152745",
    "24 hour?": "FALSE",
    "Latitude": 48.80984,
    "Longitude": 9.25133,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.80984,9.25133",
    "IsOpen24Hours": false,
    "id": "48.80984,9.25133"
  },
  {
    "ID": 3206,
    "Tankstellennummer": "0FJ91",
    "Name": "Aral Tankstelle Stuttgart, Wiener Str. 129",
    "Address": "Wiener Straße 129",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70469,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 711852430,
    "24 hour?": "TRUE",
    "Latitude": 48.810096,
    "Longitude": 9.154195,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.810096,9.154195",
    "IsOpen24Hours": true,
    "id": "48.810096,9.154195"
  },
  {
    "ID": 1036058671,
    "Tankstellennummer": "TD133600",
    "Name": "GAGGENAU MURGTALSTR. 89",
    "Address": "MURGTALSTR.89",
    "Address__1": "",
    "City": "GAGGENAU",
    "Postcode": 76571,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07225-5603",
    "24 hour?": "FALSE",
    "Latitude": 48.8103,
    "Longitude": 8.3101,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8103,8.3101",
    "IsOpen24Hours": false,
    "id": "48.8103,8.3101"
  },
  {
    "ID": 11341644,
    "Tankstellennummer": "TD040071",
    "Name": "KOESCHING RUPPERTSWIES 6",
    "Address": "RUPPERTSWIES 6",
    "Address__1": "",
    "City": "KOESCHING",
    "Postcode": 85092,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "08456-9698530",
    "24 hour?": "FALSE",
    "Latitude": 48.8111,
    "Longitude": 11.4791,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8111,11.4791",
    "IsOpen24Hours": false,
    "id": "48.8111,11.4791"
  },
  {
    "ID": 131521976,
    "Tankstellennummer": "0FM66",
    "Name": "BADEN-BADEN",
    "Address": "Sandweierer Straße 45",
    "Address__1": "",
    "City": "Baden-Baden",
    "Postcode": 76532,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7221809171,
    "24 hour?": "FALSE",
    "Latitude": 48.811201,
    "Longitude": 8.195391,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.811201,8.195391",
    "IsOpen24Hours": false,
    "id": "48.811201,8.195391"
  },
  {
    "ID": 2013,
    "Tankstellennummer": "0F911",
    "Name": "Aral Tankstelle Stuttgart, Heilbronner Str. 289",
    "Address": "Heilbronner Straße 289",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70469,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7118560056,
    "24 hour?": "TRUE",
    "Latitude": 48.812611,
    "Longitude": 9.179996,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.812611,9.179996",
    "IsOpen24Hours": true,
    "id": "48.812611,9.179996"
  },
  {
    "ID": 1437,
    "Tankstellennummer": "0F505",
    "Name": "Aral Tankstelle Stuttgart, Wiener Str. 39",
    "Address": "Wiener Straße 39",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70469,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7111353230,
    "24 hour?": "TRUE",
    "Latitude": 48.813231,
    "Longitude": 9.165155,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.813231,9.165155",
    "IsOpen24Hours": true,
    "id": "48.813231,9.165155"
  },
  {
    "ID": 543108826,
    "Tankstellennummer": "TD041202",
    "Name": "WEINSTADT SCHORNDORFER STR. 10",
    "Address": "SCHORNDORFER STR. 10",
    "Address__1": "",
    "City": "WEINSTADT",
    "Postcode": 71384,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 715161243,
    "24 hour?": "FALSE",
    "Latitude": 48.8141,
    "Longitude": 9.3691,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8141,9.3691",
    "IsOpen24Hours": false,
    "id": "48.8141,9.3691"
  },
  {
    "ID": 1395109555,
    "Tankstellennummer": 2127,
    "Name": "Agip Schorndorf",
    "Address": "Welzheimer Strasse 22",
    "Address__1": "",
    "City": "Schorndorf",
    "Postcode": 73614,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71819797",
    "24 hour?": "FALSE",
    "Latitude": 48.81425,
    "Longitude": 9.52972,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.81425,9.52972",
    "IsOpen24Hours": false,
    "id": "48.81425,9.52972"
  },
  {
    "ID": 3195,
    "Tankstellennummer": "0FJ83",
    "Name": "Aral Tankstelle Schwäbisch Gmünd, Deinbacher Str. 5",
    "Address": "Deinbacher Straße 5",
    "Address__1": "",
    "City": "Schwäbisch Gmünd",
    "Postcode": 73527,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 717174516,
    "24 hour?": "FALSE",
    "Latitude": 48.816456,
    "Longitude": 9.778075,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.816456,9.778075",
    "IsOpen24Hours": false,
    "id": "48.816456,9.778075"
  },
  {
    "ID": 28418,
    "Tankstellennummer": "0FP40",
    "Name": "Aral Tankstelle Pförring, Max-Pollin-Strasse 2",
    "Address": "Max-Pollin-Strasse 2",
    "Address__1": "",
    "City": "Pförring",
    "Postcode": 85104,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8403927825,
    "24 hour?": "FALSE",
    "Latitude": 48.816767,
    "Longitude": 11.693572,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.816767,11.693572",
    "IsOpen24Hours": false,
    "id": "48.816767,11.693572"
  },
  {
    "ID": 244378973,
    "Tankstellennummer": "0FN79",
    "Name": "STUTTGART",
    "Address": "Schmidener Straße 255",
    "Address__1": "",
    "City": "Stuttgart",
    "Postcode": 70374,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 15229888629,
    "24 hour?": "FALSE",
    "Latitude": 48.819759,
    "Longitude": 9.243494,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.819759,9.243494",
    "IsOpen24Hours": false,
    "id": "48.819759,9.243494"
  },
  {
    "ID": 1836714827,
    "Tankstellennummer": "TD182310",
    "Name": "FELLBACH OHMSTR. 25",
    "Address": "OHMSTR. 25",
    "Address__1": "",
    "City": "FELLBACH",
    "Postcode": 70736,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0711-54041707",
    "24 hour?": "FALSE",
    "Latitude": 48.8223,
    "Longitude": 9.285,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8223,9.285",
    "IsOpen24Hours": false,
    "id": "48.8223,9.285"
  },
  {
    "ID": 1429,
    "Tankstellennummer": "0F017",
    "Name": "Aral Tankstelle Ditzingen, Siemensstr. 21",
    "Address": "Siemensstraße 21",
    "Address__1": "",
    "City": "Ditzingen",
    "Postcode": 71254,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71566437,
    "24 hour?": "TRUE",
    "Latitude": 48.822902,
    "Longitude": 9.069422,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.822902,9.069422",
    "IsOpen24Hours": true,
    "id": "48.822902,9.069422"
  },
  {
    "ID": 3202,
    "Tankstellennummer": "0FJ89",
    "Name": "Aral Tankstelle Mögglingen, Hauptstr. 2",
    "Address": "Hauptstraße 2",
    "Address__1": "",
    "City": "Mögglingen",
    "Postcode": 73563,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7174316,
    "24 hour?": "FALSE",
    "Latitude": 48.823385,
    "Longitude": 9.9578,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.823385,9.9578",
    "IsOpen24Hours": false,
    "id": "48.823385,9.9578"
  },
  {
    "ID": 3312,
    "Tankstellennummer": "0FK70",
    "Name": "Aral Tankstelle Waiblingen, Alte Bundesstrasse 25",
    "Address": "Alte Bundesstraße 25",
    "Address__1": "",
    "City": "Waiblingen",
    "Postcode": 71332,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7151905014,
    "24 hour?": "FALSE",
    "Latitude": 48.824863,
    "Longitude": 9.31977,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.824863,9.31977",
    "IsOpen24Hours": false,
    "id": "48.824863,9.31977"
  },
  {
    "ID": 1439,
    "Tankstellennummer": "0F508",
    "Name": "Aral Tankstelle Schorndorf, Wieslauftalstrasse 72",
    "Address": "Wieslauftalstraße 72",
    "Address__1": "",
    "City": "Schorndorf",
    "Postcode": 73614,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71815423,
    "24 hour?": "FALSE",
    "Latitude": 48.826994,
    "Longitude": 9.549957,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.826994,9.549957",
    "IsOpen24Hours": false,
    "id": "48.826994,9.549957"
  },
  {
    "ID": 2120236667,
    "Tankstellennummer": "TD132990",
    "Name": "TIEFENBRONN WIMSHEIMER STR. 21",
    "Address": "WIMSHEIMER STR. 21",
    "Address__1": "",
    "City": "TIEFENBRONN",
    "Postcode": 75233,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07234-95150",
    "24 hour?": "FALSE",
    "Latitude": 48.8286,
    "Longitude": 8.8012,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8286,8.8012",
    "IsOpen24Hours": false,
    "id": "48.8286,8.8012"
  },
  {
    "ID": 1464,
    "Tankstellennummer": "0F528",
    "Name": "Aral Tankstelle Aalen, Stuttgarter Str. 97A",
    "Address": "Stuttgarter Straße 97A",
    "Address__1": "",
    "City": "Aalen",
    "Postcode": 73430,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 736164525,
    "24 hour?": "TRUE",
    "Latitude": 48.832023,
    "Longitude": 10.087991,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.832023,10.087991",
    "IsOpen24Hours": true,
    "id": "48.832023,10.087991"
  },
  {
    "ID": 3242,
    "Tankstellennummer": "0FK19",
    "Name": "Aral Tankstelle Deggendorf, Angermuehle 8",
    "Address": "Angermuehle 8",
    "Address__1": "",
    "City": "Deggendorf",
    "Postcode": 94469,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 99138300112,
    "24 hour?": "FALSE",
    "Latitude": 48.835113,
    "Longitude": 12.958976,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.835113,12.958976",
    "IsOpen24Hours": false,
    "id": "48.835113,12.958976"
  },
  {
    "ID": 1946,
    "Tankstellennummer": "0F864",
    "Name": "Aral Autobahntankstelle Hepberg, Köschinger Forst Ost (A 9)",
    "Address": "A9",
    "Address__1": "",
    "City": "Hepberg",
    "Postcode": 85120,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 84051336,
    "24 hour?": "TRUE",
    "Latitude": 48.836148,
    "Longitude": 11.471164,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.836148,11.471164",
    "IsOpen24Hours": true,
    "id": "48.836148,11.471164"
  },
  {
    "ID": 1114583388,
    "Tankstellennummer": "0FN58",
    "Name": "MONHEIM",
    "Address": "Donauwörther Straße 63",
    "Address__1": "",
    "City": "Monheim",
    "Postcode": 86653,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 90911863,
    "24 hour?": "FALSE",
    "Latitude": 48.836726,
    "Longitude": 10.845195,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.836726,10.845195",
    "IsOpen24Hours": false,
    "id": "48.836726,10.845195"
  },
  {
    "ID": 596846174,
    "Tankstellennummer": 2137,
    "Name": "Agip Rastatt",
    "Address": "Donaustrasse 2",
    "Address__1": "",
    "City": "Rastatt",
    "Postcode": 76437,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 72223402",
    "24 hour?": "FALSE",
    "Latitude": 48.8387,
    "Longitude": 8.2014,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8387,8.2014",
    "IsOpen24Hours": false,
    "id": "48.8387,8.2014"
  },
  {
    "ID": 298808171,
    "Tankstellennummer": "0FP85",
    "Name": "Aral TankStelle Schierling, Dieselstraße 16",
    "Address": "Dieselstraße 16",
    "Address__1": "",
    "City": "Schierling",
    "Postcode": 84069,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 94519489202,
    "24 hour?": "TRUE",
    "Latitude": 48.83899,
    "Longitude": 12.119167,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.83899,12.119167",
    "IsOpen24Hours": true,
    "id": "48.83899,12.119167"
  },
  {
    "ID": 1325901562,
    "Tankstellennummer": 1161,
    "Name": "Agip Deggendorf",
    "Address": "Detter Strasse 1A",
    "Address__1": "",
    "City": "Deggendorf",
    "Postcode": 94469,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 99138308",
    "24 hour?": "FALSE",
    "Latitude": 48.84126,
    "Longitude": 12.95684,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.84126,12.95684",
    "IsOpen24Hours": false,
    "id": "48.84126,12.95684"
  },
  {
    "ID": 1071074901,
    "Tankstellennummer": 2309,
    "Name": "Agip Alfdorf",
    "Address": "Hauptstr.",
    "Address__1": "",
    "City": "Alfdorf",
    "Postcode": 73553,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71729399",
    "24 hour?": "FALSE",
    "Latitude": 48.84252,
    "Longitude": 9.708996,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.84252,9.708996",
    "IsOpen24Hours": false,
    "id": "48.84252,9.708996"
  },
  {
    "ID": 2121489894,
    "Tankstellennummer": 3365,
    "Name": "Agip Mindelstetten",
    "Address": "Ingolstaedter Str. 1",
    "Address__1": "",
    "City": "Mindelstetten",
    "Postcode": 93349,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 84049148",
    "24 hour?": "FALSE",
    "Latitude": 48.85047097,
    "Longitude": 11.64196287,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.85047097,11.64196287",
    "IsOpen24Hours": false,
    "id": "48.85047097,11.64196287"
  },
  {
    "ID": 1998,
    "Tankstellennummer": "0F898",
    "Name": "Aral Tankstelle Korntal-Münchingen, Heinrich-Lanz-Str. 1",
    "Address": "Heinrich-Lanz-Straße 1",
    "Address__1": "",
    "City": "Korntal-Münchingen",
    "Postcode": 70825,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7150959920,
    "24 hour?": "TRUE",
    "Latitude": 48.850588,
    "Longitude": 9.105471,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.850588,9.105471",
    "IsOpen24Hours": true,
    "id": "48.850588,9.105471"
  },
  {
    "ID": 747776904,
    "Tankstellennummer": "TD181480",
    "Name": "WAIBLINGEN NECKARSTR. 47",
    "Address": "NECKARSTR. 47",
    "Address__1": "",
    "City": "WAIBLINGEN",
    "Postcode": 71334,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07151-52837",
    "24 hour?": "FALSE",
    "Latitude": 48.8529,
    "Longitude": 9.297,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8529,9.297",
    "IsOpen24Hours": false,
    "id": "48.8529,9.297"
  },
  {
    "ID": 22690747,
    "Tankstellennummer": "TD000862",
    "Name": "NEUENBUERG MARXZELLER STR. 90",
    "Address": "MARXZELLER STR. 90",
    "Address__1": "",
    "City": "NEUENBUERG",
    "Postcode": 75305,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07082-413024",
    "24 hour?": "FALSE",
    "Latitude": 48.8529,
    "Longitude": 8.5821,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8529,8.5821",
    "IsOpen24Hours": false,
    "id": "48.8529,8.5821"
  },
  {
    "ID": 1899568540,
    "Tankstellennummer": "0FN99",
    "Name": "KORNWESTHEIM",
    "Address": "Rosensteinstraße 1",
    "Address__1": "",
    "City": "Kornwestheim",
    "Postcode": 70806,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 715422060,
    "24 hour?": "FALSE",
    "Latitude": 48.856301,
    "Longitude": 9.185561,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.856301,9.185561",
    "IsOpen24Hours": false,
    "id": "48.856301,9.185561"
  },
  {
    "ID": 1024236574,
    "Tankstellennummer": 1170,
    "Name": "Agip Grafenau",
    "Address": "Rosenauer Strasse 6",
    "Address__1": "",
    "City": "Grafenau",
    "Postcode": 94481,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 85523488",
    "24 hour?": "FALSE",
    "Latitude": 48.85963818,
    "Longitude": 13.39841438,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.85963818,13.39841438",
    "IsOpen24Hours": false,
    "id": "48.85963818,13.39841438"
  },
  {
    "ID": 329006413,
    "Tankstellennummer": "TD039776",
    "Name": "NOERDLINGEN NUERNBERGER STR. 6",
    "Address": "NUERNBERGER STR. 61",
    "Address__1": "",
    "City": "NOERDLINGEN",
    "Postcode": 86720,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09081-6011",
    "24 hour?": "FALSE",
    "Latitude": 48.8608,
    "Longitude": 10.5022,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8608,10.5022",
    "IsOpen24Hours": false,
    "id": "48.8608,10.5022"
  },
  {
    "ID": 1406020696,
    "Tankstellennummer": "TD000864",
    "Name": "RASTATT UNTERE WIESEN 2",
    "Address": "UNTERE WIESEN 2",
    "Address__1": "",
    "City": "RASTATT",
    "Postcode": 76437,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07222-830839",
    "24 hour?": "FALSE",
    "Latitude": 48.8613,
    "Longitude": 8.2397,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8613,8.2397",
    "IsOpen24Hours": false,
    "id": "48.8613,8.2397"
  },
  {
    "ID": 582952282,
    "Tankstellennummer": "0FN45",
    "Name": "PFORZHEIM",
    "Address": "Amselstraße 50",
    "Address__1": "",
    "City": "Pforzheim",
    "Postcode": 75180,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 723171240,
    "24 hour?": "FALSE",
    "Latitude": 48.86179,
    "Longitude": 8.661948,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.86179,8.661948",
    "IsOpen24Hours": false,
    "id": "48.86179,8.661948"
  },
  {
    "ID": 500379791,
    "Tankstellennummer": 1672,
    "Name": "Agip Noerdlingen",
    "Address": "Carl-Heuchel-Strasse",
    "Address__1": "",
    "City": "Noerdlingen",
    "Postcode": 86720,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 48.86287,
    "Longitude": 10.51788,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.86287,10.51788",
    "IsOpen24Hours": false,
    "id": "48.86287,10.51788"
  },
  {
    "ID": 58712393,
    "Tankstellennummer": "TD000758",
    "Name": "RASTATT KARLSRUHER STR. 16",
    "Address": "KARLSRUHER STR. 16",
    "Address__1": "",
    "City": "RASTATT",
    "Postcode": 76437,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07222-150160",
    "24 hour?": "FALSE",
    "Latitude": 48.8638,
    "Longitude": 8.2179,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8638,8.2179",
    "IsOpen24Hours": false,
    "id": "48.8638,8.2179"
  },
  {
    "ID": 3458,
    "Tankstellennummer": "0FL81",
    "Name": "Aral Tankstelle Marxzell, Albtalstrasse 18",
    "Address": "Albtalstraße 18",
    "Address__1": "",
    "City": "Marxzell",
    "Postcode": 76359,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72486939,
    "24 hour?": "FALSE",
    "Latitude": 48.863818,
    "Longitude": 8.447864,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.863818,8.447864",
    "IsOpen24Hours": false,
    "id": "48.863818,8.447864"
  },
  {
    "ID": 32907194,
    "Tankstellennummer": "0FT80",
    "Name": "HEMMINGEN",
    "Address": "Freiherr-von-Varnbüler Str. 1",
    "Address__1": "",
    "City": "Hemmingen",
    "Postcode": 71282,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71509185010,
    "24 hour?": "FALSE",
    "Latitude": 48.864815,
    "Longitude": 9.039389,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.864815,9.039389",
    "IsOpen24Hours": false,
    "id": "48.864815,9.039389"
  },
  {
    "ID": 1877958359,
    "Tankstellennummer": 2032,
    "Name": "Agip Rastatt",
    "Address": "Richard-Wagner-Ring 26",
    "Address__1": "",
    "City": "Rastatt",
    "Postcode": 76437,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 72222281",
    "24 hour?": "FALSE",
    "Latitude": 48.86691,
    "Longitude": 8.20811,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.86691,8.20811",
    "IsOpen24Hours": false,
    "id": "48.86691,8.20811"
  },
  {
    "ID": 1289099881,
    "Tankstellennummer": 2072,
    "Name": "Agip Aalen",
    "Address": "Abtsgmuender Strasse 20",
    "Address__1": "",
    "City": "Aalen",
    "Postcode": 73433,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 73617409",
    "24 hour?": "FALSE",
    "Latitude": 48.86931,
    "Longitude": 10.10666,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.86931,10.10666",
    "IsOpen24Hours": false,
    "id": "48.86931,10.10666"
  },
  {
    "ID": 596053573,
    "Tankstellennummer": 2246,
    "Name": "Agip Welzheim",
    "Address": "Friedrich-Bauer-Str. 31",
    "Address__1": "",
    "City": "Welzheim",
    "Postcode": 73642,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71829293",
    "24 hour?": "FALSE",
    "Latitude": 48.87023863,
    "Longitude": 9.62364836,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.87023863,9.62364836",
    "IsOpen24Hours": false,
    "id": "48.87023863,9.62364836"
  },
  {
    "ID": 797008224,
    "Tankstellennummer": 2024,
    "Name": "Agip Pforzheim",
    "Address": "Hirsauer Strasse 216",
    "Address__1": "",
    "City": "Pforzheim",
    "Postcode": 75180,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 72317400",
    "24 hour?": "FALSE",
    "Latitude": 48.87152,
    "Longitude": 8.67859,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.87152,8.67859",
    "IsOpen24Hours": false,
    "id": "48.87152,8.67859"
  },
  {
    "ID": 383258211,
    "Tankstellennummer": "TD191450",
    "Name": "RASTATT PLITTERSDORFER STR. 46",
    "Address": "PLITTERSDORFER STR. 46A",
    "Address__1": "",
    "City": "RASTATT",
    "Postcode": 76437,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07222-966375",
    "24 hour?": "FALSE",
    "Latitude": 48.8727,
    "Longitude": 8.1886,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8727,8.1886",
    "IsOpen24Hours": false,
    "id": "48.8727,8.1886"
  },
  {
    "ID": 839798351,
    "Tankstellennummer": "0FP67",
    "Name": "STRAUBING",
    "Address": "Aiterhofener Straße 215",
    "Address__1": "",
    "City": "Straubing",
    "Postcode": 94315,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 94211862815,
    "24 hour?": "TRUE",
    "Latitude": 48.872891,
    "Longitude": 12.631675,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.872891,12.631675",
    "IsOpen24Hours": true,
    "id": "48.872891,12.631675"
  },
  {
    "ID": 1126015296,
    "Tankstellennummer": 2055,
    "Name": "Agip Winnenden",
    "Address": "Waiblinger Strasse 67",
    "Address__1": "",
    "City": "Winnenden",
    "Postcode": 71364,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71951740",
    "24 hour?": "FALSE",
    "Latitude": 48.8732,
    "Longitude": 9.38895,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8732,9.38895",
    "IsOpen24Hours": false,
    "id": "48.8732,9.38895"
  },
  {
    "ID": 20254309,
    "Tankstellennummer": "TD040410",
    "Name": "SCHWAIKHEIM, KORNWEG 1",
    "Address": "KORNWEG 1 / L1140",
    "Address__1": "",
    "City": "SCHWAIKHEIM",
    "Postcode": 71409,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07195-135491",
    "24 hour?": "FALSE",
    "Latitude": 48.8749,
    "Longitude": 9.3433,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8749,9.3433",
    "IsOpen24Hours": false,
    "id": "48.8749,9.3433"
  },
  {
    "ID": 209608016,
    "Tankstellennummer": "TD036475",
    "Name": "REMSECK ALDINGER STR. 8",
    "Address": "ALDINGER STR. 8",
    "Address__1": "",
    "City": "REMSECK",
    "Postcode": 71686,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07146-810047",
    "24 hour?": "FALSE",
    "Latitude": 48.8753,
    "Longitude": 9.2706,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8753,9.2706",
    "IsOpen24Hours": false,
    "id": "48.8753,9.2706"
  },
  {
    "ID": 159200102,
    "Tankstellennummer": "0FO22",
    "Name": "AALEN-FACHSENFELD",
    "Address": "Wasseralfinger Straße 66",
    "Address__1": "",
    "City": "Aalen-Fachsenfeld",
    "Postcode": 73434,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7366922998,
    "24 hour?": "FALSE",
    "Latitude": 48.878487,
    "Longitude": 10.049996,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.878487,10.049996",
    "IsOpen24Hours": false,
    "id": "48.878487,10.049996"
  },
  {
    "ID": 291123594,
    "Tankstellennummer": 2310,
    "Name": "Agip Rudersberg",
    "Address": "Heilbronner Str. 75",
    "Address__1": "",
    "City": "Rudersberg",
    "Postcode": 73635,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71833022",
    "24 hour?": "FALSE",
    "Latitude": 48.87864,
    "Longitude": 9.53008,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.87864,9.53008",
    "IsOpen24Hours": false,
    "id": "48.87864,9.53008"
  },
  {
    "ID": 1713242450,
    "Tankstellennummer": "0FP82",
    "Name": "KARLSBAD-ITTERSBACH",
    "Address": "Im Stöckmädle 29",
    "Address__1": "",
    "City": "Karlsbad-Ittersbach",
    "Postcode": 76307,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72489267093,
    "24 hour?": "FALSE",
    "Latitude": 48.88042,
    "Longitude": 8.510005,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.88042,8.510005",
    "IsOpen24Hours": false,
    "id": "48.88042,8.510005"
  },
  {
    "ID": 860866219,
    "Tankstellennummer": "0FN83",
    "Name": "SCHWIEBERDINGEN",
    "Address": "Robert-Bosch-Straße 10",
    "Address__1": "",
    "City": "Schwieberdingen",
    "Postcode": 71701,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 715033403,
    "24 hour?": "FALSE",
    "Latitude": 48.881901,
    "Longitude": 9.079281,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.881901,9.079281",
    "IsOpen24Hours": false,
    "id": "48.881901,9.079281"
  },
  {
    "ID": 952344916,
    "Tankstellennummer": 3027,
    "Name": "Agip Eichstaett",
    "Address": "Ingolstaedter Str. 44 A",
    "Address__1": "",
    "City": "Eichstaett",
    "Postcode": 85072,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 84212865",
    "24 hour?": "TRUE",
    "Latitude": 48.88202,
    "Longitude": 11.19653,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.88202,11.19653",
    "IsOpen24Hours": true,
    "id": "48.88202,11.19653"
  },
  {
    "ID": 1551867853,
    "Tankstellennummer": "TD040717",
    "Name": "WINNENDEN B14/MARBACHER STR.",
    "Address": "B14/MARBACHER STR.",
    "Address__1": "",
    "City": "WINNENDEN",
    "Postcode": 71364,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "071955-988995",
    "24 hour?": "FALSE",
    "Latitude": 48.8824,
    "Longitude": 9.3867,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8824,9.3867",
    "IsOpen24Hours": false,
    "id": "48.8824,9.3867"
  },
  {
    "ID": 1618969529,
    "Tankstellennummer": 1111,
    "Name": "Agip Straubing",
    "Address": "Heerstr. 106",
    "Address__1": "",
    "City": "Straubing",
    "Postcode": 94315,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 94212114",
    "24 hour?": "FALSE",
    "Latitude": 48.88282,
    "Longitude": 12.5857,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.88282,12.5857",
    "IsOpen24Hours": false,
    "id": "48.88282,12.5857"
  },
  {
    "ID": 825599104,
    "Tankstellennummer": "TD035444",
    "Name": "WESTHAUSEN IN DER WAAGE 1",
    "Address": "IN DER WAAGE 1",
    "Address__1": "",
    "City": "WESTHAUSEN",
    "Postcode": 73463,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07363-9547097",
    "24 hour?": "FALSE",
    "Latitude": 48.8831,
    "Longitude": 10.175,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8831,10.175",
    "IsOpen24Hours": false,
    "id": "48.8831,10.175"
  },
  {
    "ID": 1818034351,
    "Tankstellennummer": "TD039792",
    "Name": "WALLERSTEIN NOERDLINGER STR. 1",
    "Address": "NOERDLINGER STR. 11",
    "Address__1": "",
    "City": "WALLERSTEIN",
    "Postcode": 86757,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09081-7087",
    "24 hour?": "FALSE",
    "Latitude": 48.8836,
    "Longitude": 10.4761,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8836,10.4761",
    "IsOpen24Hours": false,
    "id": "48.8836,10.4761"
  },
  {
    "ID": 1544800644,
    "Tankstellennummer": "TD191340",
    "Name": "PFORZHEIM CALWER STR. 99",
    "Address": "CALWER STR. 99",
    "Address__1": "",
    "City": "PFORZHEIM",
    "Postcode": 75175,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07231-459459",
    "24 hour?": "FALSE",
    "Latitude": 48.8838,
    "Longitude": 8.6992,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8838,8.6992",
    "IsOpen24Hours": false,
    "id": "48.8838,8.6992"
  },
  {
    "ID": 808447400,
    "Tankstellennummer": 1616,
    "Name": "Agip Straubing",
    "Address": "Ittlinger Str. 226",
    "Address__1": "",
    "City": "Straubing",
    "Postcode": 94315,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 94216113",
    "24 hour?": "FALSE",
    "Latitude": 48.88413,
    "Longitude": 12.59316,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.88413,12.59316",
    "IsOpen24Hours": false,
    "id": "48.88413,12.59316"
  },
  {
    "ID": 521273235,
    "Tankstellennummer": "TD000489",
    "Name": "PFORZHEIM WURMBERGER STR. 8",
    "Address": "WURMBERGER STR. 8",
    "Address__1": "",
    "City": "PFORZHEIM",
    "Postcode": 75175,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07231-61228",
    "24 hour?": "FALSE",
    "Latitude": 48.8846,
    "Longitude": 8.714,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8846,8.714",
    "IsOpen24Hours": false,
    "id": "48.8846,8.714"
  },
  {
    "ID": 864220225,
    "Tankstellennummer": "TD001891",
    "Name": "PFORZHEIM BRESLAUER STR. 1",
    "Address": "BRESLAUER STR. 1",
    "Address__1": "",
    "City": "PFORZHEIM",
    "Postcode": 75181,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07231-63949",
    "24 hour?": "FALSE",
    "Latitude": 48.8848,
    "Longitude": 8.7273,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8848,8.7273",
    "IsOpen24Hours": false,
    "id": "48.8848,8.7273"
  },
  {
    "ID": 1913967359,
    "Tankstellennummer": "TD041095",
    "Name": "BIETIGHEIM MUGGENSTURMER LANDS",
    "Address": "2 MUGGENSTUMER LANDSTR.",
    "Address__1": "",
    "City": "BIETIGHEIM",
    "Postcode": 76467,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07222-150050",
    "24 hour?": "FALSE",
    "Latitude": 48.8849,
    "Longitude": 8.2692,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8849,8.2692",
    "IsOpen24Hours": false,
    "id": "48.8849,8.2692"
  },
  {
    "ID": 226421440,
    "Tankstellennummer": 1108,
    "Name": "Agip Straubing",
    "Address": "Chamer Strasse 48",
    "Address__1": "",
    "City": "Straubing",
    "Postcode": 94315,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 94211236",
    "24 hour?": "FALSE",
    "Latitude": 48.88767,
    "Longitude": 12.574,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.88767,12.574",
    "IsOpen24Hours": false,
    "id": "48.88767,12.574"
  },
  {
    "ID": 1446,
    "Tankstellennummer": "0F514",
    "Name": "Aral Tankstelle Möglingen, Ludwigsburger Strasse 20",
    "Address": "Ludwigsburger Straße 20",
    "Address__1": "",
    "City": "Möglingen",
    "Postcode": 71696,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7141484326,
    "24 hour?": "FALSE",
    "Latitude": 48.887779,
    "Longitude": 9.128691,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.887779,9.128691",
    "IsOpen24Hours": false,
    "id": "48.887779,9.128691"
  },
  {
    "ID": 2012,
    "Tankstellennummer": "0F910",
    "Name": "Aral Tankstelle Ludwigsburg, Friedrichstraße 160",
    "Address": "Friedrichstraße 160",
    "Address__1": "",
    "City": "Ludwigsburg",
    "Postcode": 71638,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7141890454,
    "24 hour?": "TRUE",
    "Latitude": 48.888661,
    "Longitude": 9.21355,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.888661,9.21355",
    "IsOpen24Hours": true,
    "id": "48.888661,9.21355"
  },
  {
    "ID": 1833,
    "Tankstellennummer": "0F795",
    "Name": "Aral Tankstelle Pforzheim, Kaiser-Friedrich-Str. 3",
    "Address": "Kaiser-Friedrich-Straße 3",
    "Address__1": "",
    "City": "Pforzheim",
    "Postcode": 75172,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7231927913,
    "24 hour?": "TRUE",
    "Latitude": 48.889079,
    "Longitude": 8.692441,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.889079,8.692441",
    "IsOpen24Hours": true,
    "id": "48.889079,8.692441"
  },
  {
    "ID": 113274190,
    "Tankstellennummer": "0FO26",
    "Name": "REMSECK",
    "Address": "Neckaraue 2",
    "Address__1": "",
    "City": "Remseck",
    "Postcode": 71686,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 714643532,
    "24 hour?": "FALSE",
    "Latitude": 48.890196,
    "Longitude": 9.271213,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.890196,9.271213",
    "IsOpen24Hours": false,
    "id": "48.890196,9.271213"
  },
  {
    "ID": 1428,
    "Tankstellennummer": "0F499",
    "Name": "Aral Tankstelle Ludwigsburg, Schwieberdinger Str. 70",
    "Address": "Schwieberdinger Straße 70",
    "Address__1": "",
    "City": "Ludwigsburg",
    "Postcode": 71636,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7141462453,
    "24 hour?": "TRUE",
    "Latitude": 48.890533,
    "Longitude": 9.173835,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.890533,9.173835",
    "IsOpen24Hours": true,
    "id": "48.890533,9.173835"
  },
  {
    "ID": 852254964,
    "Tankstellennummer": 2067,
    "Name": "Agip Ludwigsburg",
    "Address": "Friesenstrasse 38",
    "Address__1": "",
    "City": "Ludwigsburg",
    "Postcode": 71640,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71412955",
    "24 hour?": "FALSE",
    "Latitude": 48.89322,
    "Longitude": 9.2233,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.89322,9.2233",
    "IsOpen24Hours": false,
    "id": "48.89322,9.2233"
  },
  {
    "ID": 397597791,
    "Tankstellennummer": "0FO67",
    "Name": "NIEDERWINKLING",
    "Address": "Industriestraße 2",
    "Address__1": "",
    "City": "Niederwinkling",
    "Postcode": 94559,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9962912020,
    "24 hour?": "TRUE",
    "Latitude": 48.893933,
    "Longitude": 12.800573,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.893933,12.800573",
    "IsOpen24Hours": true,
    "id": "48.893933,12.800573"
  },
  {
    "ID": 1606020748,
    "Tankstellennummer": "0FO08",
    "Name": "ETTLINGEN OT SCHÖLLBRONN",
    "Address": "Moosbronner Straße 37",
    "Address__1": "",
    "City": "Ettlingen OT Schöllb",
    "Postcode": 76275,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72431855041,
    "24 hour?": "FALSE",
    "Latitude": 48.89499,
    "Longitude": 8.420843,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.89499,8.420843",
    "IsOpen24Hours": false,
    "id": "48.89499,8.420843"
  },
  {
    "ID": 496918192,
    "Tankstellennummer": "TD036459",
    "Name": "LUDWIGSBURG MARTIN-LUTHER-STR.",
    "Address": "MARTIN-LUTHER-STR. 11",
    "Address__1": "",
    "City": "LUDWIGSBURG",
    "Postcode": 71636,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07141-923171",
    "24 hour?": "FALSE",
    "Latitude": 48.8961,
    "Longitude": 9.18,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8961,9.18",
    "IsOpen24Hours": false,
    "id": "48.8961,9.18"
  },
  {
    "ID": 204401804,
    "Tankstellennummer": "TD041756",
    "Name": "PFORZHEIM EUTINGER STR. 85",
    "Address": "EUTINGER STR.85",
    "Address__1": "",
    "City": "PFORZHEIM",
    "Postcode": 75175,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07231-955235",
    "24 hour?": "FALSE",
    "Latitude": 48.8985,
    "Longitude": 8.725,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.8985,8.725",
    "IsOpen24Hours": false,
    "id": "48.8985,8.725"
  },
  {
    "ID": 322824342,
    "Tankstellennummer": 2141,
    "Name": "Agip Bietigheim",
    "Address": "Badenstr. 6",
    "Address__1": "",
    "City": "Bietigheim",
    "Postcode": 76467,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 72459381",
    "24 hour?": "FALSE",
    "Latitude": 48.90025177,
    "Longitude": 8.24904179,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.90025177,8.24904179",
    "IsOpen24Hours": false,
    "id": "48.90025177,8.24904179"
  },
  {
    "ID": 853478212,
    "Tankstellennummer": "TD192060",
    "Name": "MALSCH DURMERSHEIMER STR. 14A",
    "Address": "DURMERSHEIMER STR. 14a",
    "Address__1": "",
    "City": "MALSCH",
    "Postcode": 76316,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07246-8484",
    "24 hour?": "FALSE",
    "Latitude": 48.902,
    "Longitude": 8.3108,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.902,8.3108",
    "IsOpen24Hours": false,
    "id": "48.902,8.3108"
  },
  {
    "ID": 1490,
    "Tankstellennummer": "0F548",
    "Name": "Aral Tankstelle Pforzheim, Karlsruher Str. 26A",
    "Address": "Karlsruher Straße 26A",
    "Address__1": "",
    "City": "Pforzheim",
    "Postcode": 75179,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7231106050,
    "24 hour?": "TRUE",
    "Latitude": 48.902054,
    "Longitude": 8.661044,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.902054,8.661044",
    "IsOpen24Hours": true,
    "id": "48.902054,8.661044"
  },
  {
    "ID": 1445,
    "Tankstellennummer": "0F513",
    "Name": "Aral Tankstelle Markgröningen, Bahnhofstrasse 51",
    "Address": "Bahnhofstraße 51",
    "Address__1": "",
    "City": "Markgröningen",
    "Postcode": 71706,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71454642,
    "24 hour?": "FALSE",
    "Latitude": 48.905016,
    "Longitude": 9.091017,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.905016,9.091017",
    "IsOpen24Hours": false,
    "id": "48.905016,9.091017"
  },
  {
    "ID": 426336917,
    "Tankstellennummer": 1286,
    "Name": "Agip Bogen",
    "Address": "Bahnhofstrasse 10A",
    "Address__1": "",
    "City": "Bogen",
    "Postcode": 94327,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 94221864",
    "24 hour?": "FALSE",
    "Latitude": 48.90949,
    "Longitude": 12.69103,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.90949,12.69103",
    "IsOpen24Hours": false,
    "id": "48.90949,12.69103"
  },
  {
    "ID": 1875939467,
    "Tankstellennummer": 2238,
    "Name": "Agip Pforzheim",
    "Address": "Karlsruher Str. 85",
    "Address__1": "",
    "City": "Pforzheim",
    "Postcode": 75179,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 72313282",
    "24 hour?": "TRUE",
    "Latitude": 48.90988,
    "Longitude": 8.64727,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.90988,8.64727",
    "IsOpen24Hours": true,
    "id": "48.90988,8.64727"
  },
  {
    "ID": 1879,
    "Tankstellennummer": "0F001",
    "Name": "Aral Tankstelle Pforzheim, Lochäckerstr. / B 294",
    "Address": "Lochäckerstraße / B 294",
    "Address__1": "",
    "City": "Pforzheim",
    "Postcode": 75177,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72315660680,
    "24 hour?": "TRUE",
    "Latitude": 48.910001,
    "Longitude": 8.718911,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.910001,8.718911",
    "IsOpen24Hours": true,
    "id": "48.910001,8.718911"
  },
  {
    "ID": 31200468,
    "Tankstellennummer": 1679,
    "Name": "Agip Bogen",
    "Address": "Gewerbegebiet Baerndorf 4",
    "Address__1": "",
    "City": "Bogen",
    "Postcode": 94327,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0)944228099",
    "24 hour?": "FALSE",
    "Latitude": 48.91135,
    "Longitude": 12.68916,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.91135,12.68916",
    "IsOpen24Hours": false,
    "id": "48.91135,12.68916"
  },
  {
    "ID": 1825,
    "Tankstellennummer": "0F791",
    "Name": "Aral Tankstelle Karlsbad, Ettlinger-Str.35",
    "Address": "Ettlinger-Straße35",
    "Address__1": "",
    "City": "Karlsbad",
    "Postcode": 76307,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72029421913,
    "24 hour?": "FALSE",
    "Latitude": 48.917312,
    "Longitude": 8.499941,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.917312,8.499941",
    "IsOpen24Hours": false,
    "id": "48.917312,8.499941"
  },
  {
    "ID": 1841,
    "Tankstellennummer": "0F802",
    "Name": "Aral Tankstelle Vaihingen, Vaihinger Eck/An der B 10",
    "Address": "Stuttgarter Straße 139",
    "Address__1": "",
    "City": "Vaihingen",
    "Postcode": 71665,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 704217154,
    "24 hour?": "TRUE",
    "Latitude": 48.922426,
    "Longitude": 8.974054,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.922426,8.974054",
    "IsOpen24Hours": true,
    "id": "48.922426,8.974054"
  },
  {
    "ID": 2335,
    "Tankstellennummer": "0FB45",
    "Name": "Aral Tankstelle Denkendorf, Haupt Str.8",
    "Address": "Hauptstraße 8",
    "Address__1": "",
    "City": "Denkendorf",
    "Postcode": 85095,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 84669519584,
    "24 hour?": "FALSE",
    "Latitude": 48.929023,
    "Longitude": 11.459235,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.929023,11.459235",
    "IsOpen24Hours": false,
    "id": "48.929023,11.459235"
  },
  {
    "ID": 1610507412,
    "Tankstellennummer": 2043,
    "Name": "Agip Backnang",
    "Address": "Stuttgarter Strasse 144",
    "Address__1": "",
    "City": "Backnang",
    "Postcode": 71522,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71916267",
    "24 hour?": "FALSE",
    "Latitude": 48.93528,
    "Longitude": 9.43749,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.93528,9.43749",
    "IsOpen24Hours": false,
    "id": "48.93528,9.43749"
  },
  {
    "ID": 3192,
    "Tankstellennummer": "0FJ81",
    "Name": "Aral Tankstelle Backnang, Stuttgarter Str. 141",
    "Address": "Stuttgarter Straße 141",
    "Address__1": "",
    "City": "Backnang",
    "Postcode": 71522,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 719163813,
    "24 hour?": "FALSE",
    "Latitude": 48.936069,
    "Longitude": 9.437789,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.936069,9.437789",
    "IsOpen24Hours": false,
    "id": "48.936069,9.437789"
  },
  {
    "ID": 1809,
    "Tankstellennummer": "0F780",
    "Name": "Aral Tankstelle Durmersheim, Hauptstr.166 A",
    "Address": "Hauptstraße 166 A",
    "Address__1": "",
    "City": "Durmersheim",
    "Postcode": 76448,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72457956,
    "24 hour?": "FALSE",
    "Latitude": 48.936479,
    "Longitude": 8.27235,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.936479,8.27235",
    "IsOpen24Hours": false,
    "id": "48.936479,8.27235"
  },
  {
    "ID": 1454,
    "Tankstellennummer": "0F522",
    "Name": "Aral Tankstelle Erdmannhausen, Marbacher Str.21",
    "Address": "Marbacher Straße 21",
    "Address__1": "",
    "City": "Erdmannhausen",
    "Postcode": 71729,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71446651,
    "24 hour?": "FALSE",
    "Latitude": 48.941889,
    "Longitude": 9.291132,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.941889,9.291132",
    "IsOpen24Hours": false,
    "id": "48.941889,9.291132"
  },
  {
    "ID": 1737998767,
    "Tankstellennummer": "0FN44",
    "Name": "MUHLACKER",
    "Address": "Stuttgarter Straße 54",
    "Address__1": "",
    "City": "Mühlacker",
    "Postcode": 75417,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 70413100,
    "24 hour?": "FALSE",
    "Latitude": 48.946531,
    "Longitude": 8.846422,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.946531,8.846422",
    "IsOpen24Hours": false,
    "id": "48.946531,8.846422"
  },
  {
    "ID": 1918759819,
    "Tankstellennummer": "TD036723",
    "Name": "OETTINGEN AM SAUERECK 2",
    "Address": "AM SAUERECK 2",
    "Address__1": "",
    "City": "OETTINGEN",
    "Postcode": 86732,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09082-1444",
    "24 hour?": "FALSE",
    "Latitude": 48.947,
    "Longitude": 10.597,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.947,10.597",
    "IsOpen24Hours": false,
    "id": "48.947,10.597"
  },
  {
    "ID": 2479,
    "Tankstellennummer": "0FC32",
    "Name": "Aral Tankstelle Kirchroth, Bernauer Str. 20",
    "Address": "Bernauer Straße 20",
    "Address__1": "",
    "City": "Kirchroth",
    "Postcode": 94356,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9428226,
    "24 hour?": "FALSE",
    "Latitude": 48.947363,
    "Longitude": 12.545309,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.947363,12.545309",
    "IsOpen24Hours": false,
    "id": "48.947363,12.545309"
  },
  {
    "ID": 1827,
    "Tankstellennummer": "0F792",
    "Name": "Aral Tankstelle Mühlacker, Pforzheimer Str. 55",
    "Address": "Pforzheimer Straße 55",
    "Address__1": "",
    "City": "Mühlacker",
    "Postcode": 75417,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 70414840,
    "24 hour?": "FALSE",
    "Latitude": 48.948279,
    "Longitude": 8.834584,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.948279,8.834584",
    "IsOpen24Hours": false,
    "id": "48.948279,8.834584"
  },
  {
    "ID": 407229540,
    "Tankstellennummer": "TD192710",
    "Name": "EISINGEN ALTE STEINER STR. 43",
    "Address": "ALTE STEINER STR. 43",
    "Address__1": "",
    "City": "EISINGEN",
    "Postcode": 75239,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07232-8994",
    "24 hour?": "FALSE",
    "Latitude": 48.9494,
    "Longitude": 8.6648,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.9494,8.6648",
    "IsOpen24Hours": false,
    "id": "48.9494,8.6648"
  },
  {
    "ID": 1812,
    "Tankstellennummer": "0F782",
    "Name": "Aral Tankstelle Karlsruhe, Wiesenstr. 30",
    "Address": "Wiesenstraße 30",
    "Address__1": "",
    "City": "Karlsruhe",
    "Postcode": 76228,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 721450101,
    "24 hour?": "FALSE",
    "Latitude": 48.952746,
    "Longitude": 8.470857,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.952746,8.470857",
    "IsOpen24Hours": false,
    "id": "48.952746,8.470857"
  },
  {
    "ID": 1839,
    "Tankstellennummer": "0F800",
    "Name": "Aral Tankstelle Remchingen, Bahnhofstr.2",
    "Address": "Bahnhofstraße2",
    "Address__1": "",
    "City": "Remchingen",
    "Postcode": 75196,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 723271202,
    "24 hour?": "TRUE",
    "Latitude": 48.952996,
    "Longitude": 8.570688,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.952996,8.570688",
    "IsOpen24Hours": true,
    "id": "48.952996,8.570688"
  },
  {
    "ID": 805045694,
    "Tankstellennummer": 2150,
    "Name": "Agip Illingen",
    "Address": "Illinger Eck B 10/B 35",
    "Address__1": "",
    "City": "Illingen",
    "Postcode": 75428,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 70422050",
    "24 hour?": "FALSE",
    "Latitude": 48.95328663,
    "Longitude": 8.91265141,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.95328663,8.91265141",
    "IsOpen24Hours": false,
    "id": "48.95328663,8.91265141"
  },
  {
    "ID": 204469330,
    "Tankstellennummer": "0FM12",
    "Name": "MURR",
    "Address": "Im langen Feld 17",
    "Address__1": "",
    "City": "Murr",
    "Postcode": 71711,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 714429235,
    "24 hour?": "TRUE",
    "Latitude": 48.957129,
    "Longitude": 9.243526,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.957129,9.243526",
    "IsOpen24Hours": true,
    "id": "48.957129,9.243526"
  },
  {
    "ID": 3054,
    "Tankstellennummer": "0FI89",
    "Name": "Aral Tankstelle Ettlingen, Karlsruher Str. 50a",
    "Address": "Karlsruher Straße 50a",
    "Address__1": "",
    "City": "Ettlingen",
    "Postcode": 76275,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 724312372,
    "24 hour?": "TRUE",
    "Latitude": 48.957374,
    "Longitude": 8.406625,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.957374,8.406625",
    "IsOpen24Hours": true,
    "id": "48.957374,8.406625"
  },
  {
    "ID": 936428703,
    "Tankstellennummer": 2133,
    "Name": "Agip Sachsenheim",
    "Address": "Ludwigsburger Str. 29-33",
    "Address__1": "",
    "City": "Sachsenheim",
    "Postcode": 74343,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71477621",
    "24 hour?": "FALSE",
    "Latitude": 48.95836,
    "Longitude": 9.07123,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.95836,9.07123",
    "IsOpen24Hours": false,
    "id": "48.95836,9.07123"
  },
  {
    "ID": 1088842954,
    "Tankstellennummer": 2039,
    "Name": "Agip Bietigheim-Bissingen",
    "Address": "Grossingersheimer Strasse",
    "Address__1": "",
    "City": "Bietigheim-Bissingen",
    "Postcode": 74321,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71425343",
    "24 hour?": "FALSE",
    "Latitude": 48.95915307,
    "Longitude": 9.13343474,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.95915307,9.13343474",
    "IsOpen24Hours": false,
    "id": "48.95915307,9.13343474"
  },
  {
    "ID": 1009967193,
    "Tankstellennummer": "0FS61",
    "Name": "Aral Tankstelle Backnang",
    "Address": "Manfred-von-Ardenne-Allee 2",
    "Address__1": "",
    "City": "Backnang",
    "Postcode": 71522,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71913455060,
    "24 hour?": "FALSE",
    "Latitude": 48.959211,
    "Longitude": 9.423126,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.959211,9.423126",
    "IsOpen24Hours": false,
    "id": "48.959211,9.423126"
  },
  {
    "ID": 1430,
    "Tankstellennummer": "0F500",
    "Name": "Aral Tankstelle Pleidelsheim, Marbacher Str. 36",
    "Address": "Marbacher Straße 36",
    "Address__1": "",
    "City": "Pleidelsheim",
    "Postcode": 74385,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7144281777,
    "24 hour?": "TRUE",
    "Latitude": 48.95922,
    "Longitude": 9.210152,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.95922,9.210152",
    "IsOpen24Hours": true,
    "id": "48.95922,9.210152"
  },
  {
    "ID": 1405,
    "Tankstellennummer": "0F482",
    "Name": "Aral Tankstelle Sachsenheim, Ludwigsburger Str.15",
    "Address": "Ludwigsburger Straße 15",
    "Address__1": "",
    "City": "Sachsenheim",
    "Postcode": 74343,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71476360,
    "24 hour?": "FALSE",
    "Latitude": 48.959629,
    "Longitude": 9.068952,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.959629,9.068952",
    "IsOpen24Hours": false,
    "id": "48.959629,9.068952"
  },
  {
    "ID": 1824956384,
    "Tankstellennummer": "TD040931",
    "Name": "PENTLING REGENSBURGER STR.",
    "Address": "REGENSBURGER STR. 20A",
    "Address__1": "",
    "City": "PENTLING",
    "Postcode": 93080,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09405-9680979",
    "24 hour?": "FALSE",
    "Latitude": 48.9603,
    "Longitude": 12.048,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.9603,12.048",
    "IsOpen24Hours": false,
    "id": "48.9603,12.048"
  },
  {
    "ID": 1659338619,
    "Tankstellennummer": 2614,
    "Name": "Agip Backnang",
    "Address": "Sulzbacher Str. 192/1",
    "Address__1": "",
    "City": "Backnang",
    "Postcode": 71522,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71919543",
    "24 hour?": "FALSE",
    "Latitude": 48.96056,
    "Longitude": 9.43078,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.96056,9.43078",
    "IsOpen24Hours": false,
    "id": "48.96056,9.43078"
  },
  {
    "ID": 1466,
    "Tankstellennummer": "0F530",
    "Name": "Aral Tankstelle Bietigheim-Bissingen, Löchgauer Str. 77-79",
    "Address": "Löchgauer Straße 77-79",
    "Address__1": "",
    "City": "Bietigheim-Bissingen",
    "Postcode": 74321,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7142987935,
    "24 hour?": "FALSE",
    "Latitude": 48.96211,
    "Longitude": 9.121924,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.96211,9.121924",
    "IsOpen24Hours": false,
    "id": "48.96211,9.121924"
  },
  {
    "ID": 3156,
    "Tankstellennummer": "0FJ62",
    "Name": "Aral Tankstelle Regen, Ruselstr. 37",
    "Address": "Ruselstraße 37",
    "Address__1": "",
    "City": "Regen",
    "Postcode": 94209,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 99212431,
    "24 hour?": "FALSE",
    "Latitude": 48.966408,
    "Longitude": 13.118874,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.966408,13.118874",
    "IsOpen24Hours": false,
    "id": "48.966408,13.118874"
  },
  {
    "ID": 1823,
    "Tankstellennummer": "0F790",
    "Name": "Aral Tankstelle Königsbach-Stein, Ankerstrasse 64",
    "Address": "Ankerstraße 64",
    "Address__1": "",
    "City": "Königsbach-Stein",
    "Postcode": 75203,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72325595,
    "24 hour?": "FALSE",
    "Latitude": 48.966665,
    "Longitude": 8.603625,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.966665,8.603625",
    "IsOpen24Hours": false,
    "id": "48.966665,8.603625"
  },
  {
    "ID": 130115708,
    "Tankstellennummer": 1229,
    "Name": "Agip Obertraubling",
    "Address": "Schwindgraben 2",
    "Address__1": "",
    "City": "Obertraubling",
    "Postcode": 93083,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 94015102",
    "24 hour?": "FALSE",
    "Latitude": 48.97125,
    "Longitude": 12.17505,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.97125,12.17505",
    "IsOpen24Hours": false,
    "id": "48.97125,12.17505"
  },
  {
    "ID": 410946968,
    "Tankstellennummer": 1136,
    "Name": "Agip Regen",
    "Address": "Zwieseler Strasse 44",
    "Address__1": "",
    "City": "Regen",
    "Postcode": 94209,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 99211852",
    "24 hour?": "FALSE",
    "Latitude": 48.97188331,
    "Longitude": 13.13808647,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.97188331,13.13808647",
    "IsOpen24Hours": false,
    "id": "48.97188331,13.13808647"
  },
  {
    "ID": 1066783165,
    "Tankstellennummer": "TD006619",
    "Name": "ASPACH DAIMLER STR. 1",
    "Address": "DAIMLER STR. 1",
    "Address__1": "",
    "City": "ASPACH",
    "Postcode": 71546,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07191-3678024",
    "24 hour?": "FALSE",
    "Latitude": 48.9719,
    "Longitude": 9.3871,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.9719,9.3871",
    "IsOpen24Hours": false,
    "id": "48.9719,9.3871"
  },
  {
    "ID": 3293,
    "Tankstellennummer": "0FK54",
    "Name": "Aral Tankstelle Gaildorf, Schoenberger Str. 7-16",
    "Address": "Schönberger Straße 7-16",
    "Address__1": "",
    "City": "Gaildorf",
    "Postcode": 74405,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 79716005,
    "24 hour?": "FALSE",
    "Latitude": 48.979346,
    "Longitude": 9.77933,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.979346,9.77933",
    "IsOpen24Hours": false,
    "id": "48.979346,9.77933"
  },
  {
    "ID": 54763711,
    "Tankstellennummer": "TD040113",
    "Name": "KARLSRUHE ERLACHSEEWEG 10",
    "Address": "ERLACHSEEWEG 10",
    "Address__1": "",
    "City": "KARLSRUHE",
    "Postcode": 76227,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0721-56867276",
    "24 hour?": "FALSE",
    "Latitude": 48.9859,
    "Longitude": 8.4476,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.9859,8.4476",
    "IsOpen24Hours": false,
    "id": "48.9859,8.4476"
  },
  {
    "ID": 1993,
    "Tankstellennummer": "0F892",
    "Name": "Aral Tankstelle Neutraubling, Gleiwitzer Str. 8",
    "Address": "Gleiwitzer Straße 8",
    "Address__1": "",
    "City": "Neutraubling",
    "Postcode": 93073,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 940179060,
    "24 hour?": "TRUE",
    "Latitude": 48.988226,
    "Longitude": 12.189362,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "48.988226,12.189362",
    "IsOpen24Hours": true,
    "id": "48.988226,12.189362"
  },
  {
    "ID": 1822,
    "Tankstellennummer": "0F789",
    "Name": "Aral Tankstelle Karlsruhe, Ebertstr. 32",
    "Address": "Ebertstraße 32",
    "Address__1": "",
    "City": "Karlsruhe",
    "Postcode": 76137,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 721817516,
    "24 hour?": "FALSE",
    "Latitude": 48.995142,
    "Longitude": 8.389539,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.995142,8.389539",
    "IsOpen24Hours": false,
    "id": "48.995142,8.389539"
  },
  {
    "ID": 1046621217,
    "Tankstellennummer": "TD039255",
    "Name": "BARBING FRAUNHOFER STR. 3",
    "Address": "FRAUNHOFER STR.3",
    "Address__1": "",
    "City": "BARBING",
    "Postcode": 93092,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09401-5278911",
    "24 hour?": "FALSE",
    "Latitude": 48.9957,
    "Longitude": 12.2352,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.9957,12.2352",
    "IsOpen24Hours": false,
    "id": "48.9957,12.2352"
  },
  {
    "ID": 841561669,
    "Tankstellennummer": 2027,
    "Name": "Agip Karlsruhe",
    "Address": "Killisfeldstrasse 44",
    "Address__1": "",
    "City": "Karlsruhe",
    "Postcode": 76227,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 72144175",
    "24 hour?": "FALSE",
    "Latitude": 48.99646,
    "Longitude": 8.45472,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.99646,8.45472",
    "IsOpen24Hours": false,
    "id": "48.99646,8.45472"
  },
  {
    "ID": 2009,
    "Tankstellennummer": "0F909",
    "Name": "Aral Tankstelle Kinding, Enkeringer Str. 9",
    "Address": "Enkeringer Straße 7",
    "Address__1": "",
    "City": "Kinding",
    "Postcode": 85125,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8467805892,
    "24 hour?": "FALSE",
    "Latitude": 48.998172,
    "Longitude": 11.377887,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "48.998172,11.377887",
    "IsOpen24Hours": false,
    "id": "48.998172,11.377887"
  },
  {
    "ID": 701382918,
    "Tankstellennummer": 3001,
    "Name": "Agip Regensburg",
    "Address": "Unterislinger Weg 13",
    "Address__1": "",
    "City": "Regensburg",
    "Postcode": 93053,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 94172181",
    "24 hour?": "FALSE",
    "Latitude": 48.99972,
    "Longitude": 12.11096,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.99972,12.11096",
    "IsOpen24Hours": false,
    "id": "48.99972,12.11096"
  },
  {
    "ID": 2022,
    "Tankstellennummer": "0F918",
    "Name": "Aral Autohof Regensburg, Max-Planck-Str. 8",
    "Address": "Max-Planck-Straße 8",
    "Address__1": "",
    "City": "Regensburg",
    "Postcode": 93055,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9417995752,
    "24 hour?": "TRUE",
    "Latitude": 49.000164,
    "Longitude": 12.148321,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.000164,12.148321",
    "IsOpen24Hours": true,
    "id": "49.000164,12.148321"
  },
  {
    "ID": 1114706216,
    "Tankstellennummer": "0FQ70",
    "Name": "WÖRTH AN DER DONAU",
    "Address": "Gewerbepark B 3",
    "Address__1": "",
    "City": "Wörth an der Donau",
    "Postcode": 93086,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 94829080210,
    "24 hour?": "TRUE",
    "Latitude": 49.00064,
    "Longitude": 12.364157,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.00064,12.364157",
    "IsOpen24Hours": true,
    "id": "49.00064,12.364157"
  },
  {
    "ID": 1989,
    "Tankstellennummer": "0F891",
    "Name": "Aral Tankstelle Zwiesel, Bärnzeller Str. 5",
    "Address": "Bärnzeller Straße 5",
    "Address__1": "",
    "City": "Zwiesel",
    "Postcode": 94227,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9922802644,
    "24 hour?": "TRUE",
    "Latitude": 49.001061,
    "Longitude": 13.220335,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.001061,13.220335",
    "IsOpen24Hours": true,
    "id": "49.001061,13.220335"
  },
  {
    "ID": 2469,
    "Tankstellennummer": "0FC28",
    "Name": "Aral Tankstelle Regensburg, Augsburger Str. 32",
    "Address": "Augsburger Straße 32",
    "Address__1": "",
    "City": "Regensburg",
    "Postcode": 93051,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 94196564,
    "24 hour?": "FALSE",
    "Latitude": 49.003172,
    "Longitude": 12.081405,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.003172,12.081405",
    "IsOpen24Hours": false,
    "id": "49.003172,12.081405"
  },
  {
    "ID": 647941401,
    "Tankstellennummer": "TD006775",
    "Name": "LOECHGAU ERLIGHEIMER STR. 20",
    "Address": "ERLIGHEIMER STR. 20",
    "Address__1": "",
    "City": "LOECHGAU",
    "Postcode": 74369,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07143-88302-0",
    "24 hour?": "FALSE",
    "Latitude": 49.0034,
    "Longitude": 9.1044,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.0034,9.1044",
    "IsOpen24Hours": false,
    "id": "49.0034,9.1044"
  },
  {
    "ID": 376221111,
    "Tankstellennummer": "TD040857",
    "Name": "KARLSRUHE DURLACHER ALLEE 102",
    "Address": "DURLACHER ALLEE 102",
    "Address__1": "",
    "City": "KARLSRUHE",
    "Postcode": 76137,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0721 20440908",
    "24 hour?": "FALSE",
    "Latitude": 49.0047,
    "Longitude": 8.4387,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.0047,8.4387",
    "IsOpen24Hours": false,
    "id": "49.0047,8.4387"
  },
  {
    "ID": 1386,
    "Tankstellennummer": "0F467",
    "Name": "Aral Tankstelle Sulzbach, An der B 14/Sulzbach.-Eck",
    "Address": "An der B 14/Sulzbach.-Eck",
    "Address__1": "",
    "City": "Sulzbach",
    "Postcode": 71560,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71936104,
    "24 hour?": "FALSE",
    "Latitude": 49.005061,
    "Longitude": 9.501942,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.005061,9.501942",
    "IsOpen24Hours": false,
    "id": "49.005061,9.501942"
  },
  {
    "ID": 1818,
    "Tankstellennummer": "0F012",
    "Name": "Aral Tankstelle Karlsruhe, Durlacher Allee 46",
    "Address": "Durlacher Allee 46",
    "Address__1": "",
    "City": "Karlsruhe",
    "Postcode": 76131,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 721696022,
    "24 hour?": "FALSE",
    "Latitude": 49.007631,
    "Longitude": 8.42285,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.007631,8.42285",
    "IsOpen24Hours": false,
    "id": "49.007631,8.42285"
  },
  {
    "ID": 1820,
    "Tankstellennummer": "0F787",
    "Name": "Aral Tankstelle Karlsruhe, Amalienstr. 55/59",
    "Address": "Amalienstraße 55/59",
    "Address__1": "",
    "City": "Karlsruhe",
    "Postcode": 76133,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72126224,
    "24 hour?": "FALSE",
    "Latitude": 49.009215,
    "Longitude": 8.390873,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.009215,8.390873",
    "IsOpen24Hours": false,
    "id": "49.009215,8.390873"
  },
  {
    "ID": 3340,
    "Tankstellennummer": "0FK91",
    "Name": "Aral Tankstelle Regensburg, Kirchmeierstr. 20",
    "Address": "Kirchmeierstraße 20",
    "Address__1": "",
    "City": "Regensburg",
    "Postcode": 93051,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9413998303,
    "24 hour?": "TRUE",
    "Latitude": 49.011024,
    "Longitude": 12.071235,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.011024,12.071235",
    "IsOpen24Hours": true,
    "id": "49.011024,12.071235"
  },
  {
    "ID": 224462212,
    "Tankstellennummer": "TD039412",
    "Name": "REGENSBURG OSTERHOFENER STR. 1",
    "Address": "OSTERHOFENER STR. 1",
    "Address__1": "",
    "City": "REGENSBURG",
    "Postcode": 93055,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0941 59874567",
    "24 hour?": "FALSE",
    "Latitude": 49.0132,
    "Longitude": 12.1397,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.0132,12.1397",
    "IsOpen24Hours": false,
    "id": "49.0132,12.1397"
  },
  {
    "ID": 3060,
    "Tankstellennummer": "0FI93",
    "Name": "Aral Tankstelle Karlsruhe, Haid-und-Neu-Str. 60",
    "Address": "Haid-und-Neu-Straße 60",
    "Address__1": "",
    "City": "Karlsruhe",
    "Postcode": 76131,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 721615570,
    "24 hour?": "TRUE",
    "Latitude": 49.015208,
    "Longitude": 8.435365,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.015208,8.435365",
    "IsOpen24Hours": true,
    "id": "49.015208,8.435365"
  },
  {
    "ID": 1027202,
    "Tankstellennummer": 3161,
    "Name": "Agip Regensburg",
    "Address": "Vilsecker Str. 2",
    "Address__1": "",
    "City": "Regensburg",
    "Postcode": 93057,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "0941/46705847",
    "24 hour?": "TRUE",
    "Latitude": 49.01643,
    "Longitude": 12.10176,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.01643,12.10176",
    "IsOpen24Hours": true,
    "id": "49.01643,12.10176"
  },
  {
    "ID": 960326758,
    "Tankstellennummer": 2130,
    "Name": "Agip Karlsruhe",
    "Address": "Neureuter Str. 5",
    "Address__1": "",
    "City": "Karlsruhe",
    "Postcode": 76185,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 72156801",
    "24 hour?": "TRUE",
    "Latitude": 49.01733,
    "Longitude": 8.35405,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.01733,8.35405",
    "IsOpen24Hours": true,
    "id": "49.01733,8.35405"
  },
  {
    "ID": 1506,
    "Tankstellennummer": "0FG29",
    "Name": "Aral Tankstelle Karlsruhe, An der Tagweide 2",
    "Address": "An der Tagweide 2",
    "Address__1": "",
    "City": "Karlsruhe",
    "Postcode": 76139,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 721616522,
    "24 hour?": "FALSE",
    "Latitude": 49.020278,
    "Longitude": 8.459429,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.020278,8.459429",
    "IsOpen24Hours": false,
    "id": "49.020278,8.459429"
  },
  {
    "ID": 1355597094,
    "Tankstellennummer": "TD036764",
    "Name": "WEISSENBURG AUGSBURGER STR. 61",
    "Address": "AUGSBURGER STR. 61",
    "Address__1": "",
    "City": "WEISSENBURG",
    "Postcode": 91781,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09141-922908",
    "24 hour?": "FALSE",
    "Latitude": 49.0214,
    "Longitude": 10.9659,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.0214,10.9659",
    "IsOpen24Hours": false,
    "id": "49.0214,10.9659"
  },
  {
    "ID": 1813,
    "Tankstellennummer": "0F783",
    "Name": "Aral Tankstelle Hagenbach, Friedenstr. 2a",
    "Address": "Friedenstraße 2a",
    "Address__1": "",
    "City": "Hagenbach",
    "Postcode": 76767,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72734518,
    "24 hour?": "FALSE",
    "Latitude": 49.022048,
    "Longitude": 8.251543,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.022048,8.251543",
    "IsOpen24Hours": false,
    "id": "49.022048,8.251543"
  },
  {
    "ID": 2485,
    "Tankstellennummer": "0FC33",
    "Name": "Aral Tankstelle Regensburg, Puricellistr. 3",
    "Address": "Puricellistraße 3",
    "Address__1": "",
    "City": "Regensburg",
    "Postcode": 93049,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 94123371,
    "24 hour?": "TRUE",
    "Latitude": 49.022498,
    "Longitude": 12.062694,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.022498,12.062694",
    "IsOpen24Hours": true,
    "id": "49.022498,12.062694"
  },
  {
    "ID": 1334906744,
    "Tankstellennummer": 3366,
    "Name": "Agip Regensburg",
    "Address": "Donaustaufer Str. 316",
    "Address__1": "",
    "City": "Regensburg",
    "Postcode": 93055,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 94145318",
    "24 hour?": "FALSE",
    "Latitude": 49.02503,
    "Longitude": 12.14775,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.02503,12.14775",
    "IsOpen24Hours": false,
    "id": "49.02503,12.14775"
  },
  {
    "ID": 568217723,
    "Tankstellennummer": "TD000961",
    "Name": "KARLSRUHE KARLSRUHER STR. 82",
    "Address": "KARLSRUHER STR. 82",
    "Address__1": "",
    "City": "KARLSRUHE",
    "Postcode": 76139,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0721-681250",
    "24 hour?": "FALSE",
    "Latitude": 49.0253,
    "Longitude": 8.4489,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.0253,8.4489",
    "IsOpen24Hours": false,
    "id": "49.0253,8.4489"
  },
  {
    "ID": 854023624,
    "Tankstellennummer": 3080,
    "Name": "Agip Regensburg",
    "Address": "Schweinfurter Strasse 1",
    "Address__1": "",
    "City": "Regensburg",
    "Postcode": 93059,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 94184667",
    "24 hour?": "FALSE",
    "Latitude": 49.02885,
    "Longitude": 12.08525,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.02885,12.08525",
    "IsOpen24Hours": false,
    "id": "49.02885,12.08525"
  },
  {
    "ID": 53044525,
    "Tankstellennummer": 610370,
    "Name": "WESTFALEN, KARLSRUHE",
    "Address": "Siemensallee 77",
    "Address__1": "",
    "City": "KARLSRUHE",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 49.0293,
    "Longitude": 8.347,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.0293,8.347",
    "IsOpen24Hours": false,
    "id": "49.0293,8.347"
  },
  {
    "ID": 1765,
    "Tankstellennummer": "0F778",
    "Name": "Aral Tankstelle Bretten, Pforzheimer Str.69",
    "Address": "Pforzheimer Straße69",
    "Address__1": "",
    "City": "Bretten",
    "Postcode": 75015,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72527327,
    "24 hour?": "TRUE",
    "Latitude": 49.032837,
    "Longitude": 8.706649,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.032837,8.706649",
    "IsOpen24Hours": true,
    "id": "49.032837,8.706649"
  },
  {
    "ID": 2491,
    "Tankstellennummer": "0FC37",
    "Name": "Aral Tankstelle Dietfurt, Hauptstr. 61",
    "Address": "Hauptstraße 61",
    "Address__1": "",
    "City": "Dietfurt",
    "Postcode": 92345,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 8464602953,
    "24 hour?": "FALSE",
    "Latitude": 49.033131,
    "Longitude": 11.592952,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.033131,11.592952",
    "IsOpen24Hours": false,
    "id": "49.033131,11.592952"
  },
  {
    "ID": 818082244,
    "Tankstellennummer": 3127,
    "Name": "Agip Weissenburg",
    "Address": "Nuernberger Strasse 51",
    "Address__1": "",
    "City": "Weissenburg",
    "Postcode": 91781,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91418737",
    "24 hour?": "FALSE",
    "Latitude": 49.03406047,
    "Longitude": 10.97185819,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.03406047,10.97185819",
    "IsOpen24Hours": false,
    "id": "49.03406047,10.97185819"
  },
  {
    "ID": 1988,
    "Tankstellennummer": "0F890",
    "Name": "Aral Tankstelle Beilstein, Oberstenfelder Straße 24",
    "Address": "Oberstenfelder Straße 24",
    "Address__1": "",
    "City": "Beilstein",
    "Postcode": 71717,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 70625670,
    "24 hour?": "FALSE",
    "Latitude": 49.034816,
    "Longitude": 9.316273,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.034816,9.316273",
    "IsOpen24Hours": false,
    "id": "49.034816,9.316273"
  },
  {
    "ID": 1834,
    "Tankstellennummer": "0F796",
    "Name": "Aral Tankstelle Bretten, Alexanderplatz",
    "Address": "Alexanderplatz",
    "Address__1": "",
    "City": "Bretten",
    "Postcode": 75015,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7252958220,
    "24 hour?": "TRUE",
    "Latitude": 49.037842,
    "Longitude": 8.687745,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.037842,8.687745",
    "IsOpen24Hours": true,
    "id": "49.037842,8.687745"
  },
  {
    "ID": 970,
    "Tankstellennummer": "0FE97",
    "Name": "Aral Tankstelle Karlsruhe, Linkenheimer Landstr. 125",
    "Address": "Linkenheimer Landstr. 125",
    "Address__1": "",
    "City": "Karlsruhe",
    "Postcode": 76149,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7214539429,
    "24 hour?": "TRUE",
    "Latitude": 49.038118,
    "Longitude": 8.393598,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.038118,8.393598",
    "IsOpen24Hours": true,
    "id": "49.038118,8.393598"
  },
  {
    "ID": 1468908407,
    "Tankstellennummer": "TD001842",
    "Name": "WOERTH PFORTZER STR. 1",
    "Address": "PFORTZER STR. 1",
    "Address__1": "",
    "City": "WOERTH",
    "Postcode": 76744,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07271-41041",
    "24 hour?": "FALSE",
    "Latitude": 49.0383,
    "Longitude": 8.2893,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.0383,8.2893",
    "IsOpen24Hours": false,
    "id": "49.0383,8.2893"
  },
  {
    "ID": 2322,
    "Tankstellennummer": "0FB33",
    "Name": "Aral Tankstelle Weißenburg, Nürnberger Str. 44",
    "Address": "Nürnberger Straße 44",
    "Address__1": "",
    "City": "Weißenburg",
    "Postcode": 91781,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 914182835,
    "24 hour?": "TRUE",
    "Latitude": 49.038826,
    "Longitude": 10.970908,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.038826,10.970908",
    "IsOpen24Hours": true,
    "id": "49.038826,10.970908"
  },
  {
    "ID": 150772747,
    "Tankstellennummer": 3043,
    "Name": "Agip Regensburg",
    "Address": "Lappersdorfer Strasse 110",
    "Address__1": "",
    "City": "Regensburg",
    "Postcode": 93059,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 94182903",
    "24 hour?": "TRUE",
    "Latitude": 49.04051,
    "Longitude": 12.09464,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.04051,12.09464",
    "IsOpen24Hours": true,
    "id": "49.04051,12.09464"
  },
  {
    "ID": 813710953,
    "Tankstellennummer": "TD181490",
    "Name": "ILSFELD BAB- WUNNENSTEIN OST/A",
    "Address": "BAB- WUNNENSTEIN OST/A 81",
    "Address__1": "",
    "City": "ILSFELD",
    "Postcode": 74360,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07062-4170",
    "24 hour?": "FALSE",
    "Latitude": 49.043,
    "Longitude": 9.2625,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.043,9.2625",
    "IsOpen24Hours": false,
    "id": "49.043,9.2625"
  },
  {
    "ID": 126565461,
    "Tankstellennummer": "TD001909",
    "Name": "WOERTH BAHNHOFSTR. 45",
    "Address": "BAHNHOFSTR. 45",
    "Address__1": "",
    "City": "WOERTH",
    "Postcode": 76744,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07271-7006",
    "24 hour?": "FALSE",
    "Latitude": 49.0464,
    "Longitude": 8.2732,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.0464,8.2732",
    "IsOpen24Hours": false,
    "id": "49.0464,8.2732"
  },
  {
    "ID": 1636039927,
    "Tankstellennummer": "TD001784",
    "Name": "ILSFELD BAB-WUNNENSTEIN-WEST/A",
    "Address": "BAB-WUNNENSTEIN-WEST/A 81",
    "Address__1": "",
    "City": "ILSFELD",
    "Postcode": 74360,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07062-267330",
    "24 hour?": "FALSE",
    "Latitude": 49.0468,
    "Longitude": 9.2656,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.0468,9.2656",
    "IsOpen24Hours": false,
    "id": "49.0468,9.2656"
  },
  {
    "ID": 926442432,
    "Tankstellennummer": "TD000444",
    "Name": "KARLSRUHE GRABENER STR. 11",
    "Address": "GRABENER STR. 11",
    "Address__1": "",
    "City": "KARLSRUHE",
    "Postcode": 76149,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0721-705389",
    "24 hour?": "FALSE",
    "Latitude": 49.0565,
    "Longitude": 8.3835,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.0565,8.3835",
    "IsOpen24Hours": false,
    "id": "49.0565,8.3835"
  },
  {
    "ID": 2002,
    "Tankstellennummer": "0F902",
    "Name": "Aral Autohof Ilsfeld, Hauptstr. 60",
    "Address": "Hauptstraße 60",
    "Address__1": "",
    "City": "Ilsfeld",
    "Postcode": 74360,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 706295220,
    "24 hour?": "TRUE",
    "Latitude": 49.058882,
    "Longitude": 9.271201,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.058882,9.271201",
    "IsOpen24Hours": true,
    "id": "49.058882,9.271201"
  },
  {
    "ID": 1453,
    "Tankstellennummer": "0F521",
    "Name": "Aral Tankstelle Güglingen, Heilbronner Strasse 70",
    "Address": "Heilbronner Straße 70",
    "Address__1": "",
    "City": "Güglingen",
    "Postcode": 74363,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71358420,
    "24 hour?": "FALSE",
    "Latitude": 49.066403,
    "Longitude": 9.011304,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.066403,9.011304",
    "IsOpen24Hours": false,
    "id": "49.066403,9.011304"
  },
  {
    "ID": 1409,
    "Tankstellennummer": "0F485",
    "Name": "Aral Tankstelle Lauffen, Stuttgarter Str.75",
    "Address": "Stuttgarter Straße 75",
    "Address__1": "",
    "City": "Lauffen",
    "Postcode": 74348,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71336000,
    "24 hour?": "TRUE",
    "Latitude": 49.070317,
    "Longitude": 9.158054,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.070317,9.158054",
    "IsOpen24Hours": true,
    "id": "49.070317,9.158054"
  },
  {
    "ID": 3201,
    "Tankstellennummer": "0FJ88",
    "Name": "Aral Tankstelle Mainhardt, Heilbronner Str. 2",
    "Address": "Heilbronner Straße 2",
    "Address__1": "",
    "City": "Mainhardt",
    "Postcode": 74535,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 79031081,
    "24 hour?": "FALSE",
    "Latitude": 49.076872,
    "Longitude": 9.563253,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.076872,9.563253",
    "IsOpen24Hours": false,
    "id": "49.076872,9.563253"
  },
  {
    "ID": 1452,
    "Tankstellennummer": "0F520",
    "Name": "Aral Tankstelle Lauffen, Kiesstrasse 4-6",
    "Address": "Kiesstraße 4-6",
    "Address__1": "",
    "City": "Lauffen",
    "Postcode": 74348,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71337261,
    "24 hour?": "FALSE",
    "Latitude": 49.077072,
    "Longitude": 9.152936,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.077072,9.152936",
    "IsOpen24Hours": false,
    "id": "49.077072,9.152936"
  },
  {
    "ID": 653328610,
    "Tankstellennummer": 1166,
    "Name": "Agip Viechtach",
    "Address": "Schmidstrasse 2",
    "Address__1": "",
    "City": "Viechtach",
    "Postcode": 94234,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 99429042",
    "24 hour?": "FALSE",
    "Latitude": 49.07863,
    "Longitude": 12.87956,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.07863,12.87956",
    "IsOpen24Hours": false,
    "id": "49.07863,12.87956"
  },
  {
    "ID": 1838,
    "Tankstellennummer": "0F799",
    "Name": "Aral Tankstelle Bruchsal, Weingartener Str.99",
    "Address": "Weingartener Straße 99",
    "Address__1": "",
    "City": "Bruchsal",
    "Postcode": 76646,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7257911412,
    "24 hour?": "FALSE",
    "Latitude": 49.080138,
    "Longitude": 8.549252,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.080138,8.549252",
    "IsOpen24Hours": false,
    "id": "49.080138,8.549252"
  },
  {
    "ID": 1817,
    "Tankstellennummer": "0F786",
    "Name": "Aral Tankstelle Kandel, Rheinstr. 128",
    "Address": "Rheinstraße 128",
    "Address__1": "",
    "City": "Kandel",
    "Postcode": 76870,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 727595670,
    "24 hour?": "TRUE",
    "Latitude": 49.085265,
    "Longitude": 8.210204,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.085265,8.210204",
    "IsOpen24Hours": true,
    "id": "49.085265,8.210204"
  },
  {
    "ID": 987,
    "Tankstellennummer": "0F172",
    "Name": "Aral Tankstelle Untergruppenbach, Autozubringer Süd",
    "Address": "Talgraben 1",
    "Address__1": "",
    "City": "Untergruppenbach",
    "Postcode": 74199,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 713170810,
    "24 hour?": "TRUE",
    "Latitude": 49.086424,
    "Longitude": 9.275176,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.086424,9.275176",
    "IsOpen24Hours": true,
    "id": "49.086424,9.275176"
  },
  {
    "ID": 1388,
    "Tankstellennummer": "0F469",
    "Name": "Aral Tankstelle Untergruppenbach, Entenstr.18 / Nord",
    "Address": "Entenstraße 18",
    "Address__1": "",
    "City": "Untergruppenbach",
    "Postcode": 74199,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7131976124,
    "24 hour?": "FALSE",
    "Latitude": 49.086725,
    "Longitude": 9.27556,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.086725,9.27556",
    "IsOpen24Hours": false,
    "id": "49.086725,9.27556"
  },
  {
    "ID": 867430505,
    "Tankstellennummer": "0F532",
    "Name": "WUSTENROT",
    "Address": "An der Bundesstraße 5",
    "Address__1": "",
    "City": "Wüstenrot",
    "Postcode": 71543,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 794591910,
    "24 hour?": "FALSE",
    "Latitude": 49.090451,
    "Longitude": 9.490995,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.090451,9.490995",
    "IsOpen24Hours": false,
    "id": "49.090451,9.490995"
  },
  {
    "ID": 662565268,
    "Tankstellennummer": "TD040337",
    "Name": "BRUNN AN DER A 3",
    "Address": "AN DER A 3",
    "Address__1": "",
    "City": "BRUNN",
    "Postcode": 93164,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09498/9077889",
    "24 hour?": "FALSE",
    "Latitude": 49.091,
    "Longitude": 11.8885,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.091,11.8885",
    "IsOpen24Hours": false,
    "id": "49.091,11.8885"
  },
  {
    "ID": 1402,
    "Tankstellennummer": "0F481",
    "Name": "Aral Tankstelle Schwäbisch Hall, Herdweg 52",
    "Address": "Herdweg 52",
    "Address__1": "",
    "City": "Schwäbisch Hall",
    "Postcode": 74523,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 79072277,
    "24 hour?": "FALSE",
    "Latitude": 49.093279,
    "Longitude": 9.838456,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.093279,9.838456",
    "IsOpen24Hours": false,
    "id": "49.093279,9.838456"
  },
  {
    "ID": 59509658,
    "Tankstellennummer": "TD000803",
    "Name": "SCHWAEBISCH HALL EGERLAENDER W",
    "Address": "EGERLAENDER WEG 2",
    "Address__1": "",
    "City": "SCHWAEBISCH HALL",
    "Postcode": 74523,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0791-52138",
    "24 hour?": "FALSE",
    "Latitude": 49.1065,
    "Longitude": 9.7188,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1065,9.7188",
    "IsOpen24Hours": false,
    "id": "49.1065,9.7188"
  },
  {
    "ID": 1837,
    "Tankstellennummer": "0F798",
    "Name": "Aral Tankstelle Sulzfeld, Hauptstrasse",
    "Address": "Hauptstraße",
    "Address__1": "",
    "City": "Sulzfeld",
    "Postcode": 75056,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7269919130,
    "24 hour?": "FALSE",
    "Latitude": 49.109529,
    "Longitude": 8.855131,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.109529,8.855131",
    "IsOpen24Hours": false,
    "id": "49.109529,8.855131"
  },
  {
    "ID": 751713892,
    "Tankstellennummer": "TD040345",
    "Name": "GUNZENHAUSEN OETTINGER STR. 6C",
    "Address": "OETTINGER STR. 6c",
    "Address__1": "",
    "City": "GUNZENHAUSEN",
    "Postcode": 91710,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 9831673720,
    "24 hour?": "FALSE",
    "Latitude": 49.114,
    "Longitude": 10.7478,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.114,10.7478",
    "IsOpen24Hours": false,
    "id": "49.114,10.7478"
  },
  {
    "ID": 315721151,
    "Tankstellennummer": "TD000428",
    "Name": "KLEINBLITTERSDORF KONRAD-ADENA",
    "Address": "KONRAD-ADENAUER-STR. 29",
    "Address__1": "",
    "City": "KLEINBLITTERSDORF",
    "Postcode": 66271,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06805-4173",
    "24 hour?": "FALSE",
    "Latitude": 49.1179,
    "Longitude": 7.0629,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1179,7.0629",
    "IsOpen24Hours": false,
    "id": "49.1179,7.0629"
  },
  {
    "ID": 7199907,
    "Tankstellennummer": "TD036509",
    "Name": "SCHWAEBISCH HALL JOHANNITER ST",
    "Address": "JOHANNITER STR. 44",
    "Address__1": "",
    "City": "SCHWAEBISCH HALL",
    "Postcode": 74523,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0791-6521",
    "24 hour?": "FALSE",
    "Latitude": 49.119,
    "Longitude": 9.7346,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.119,9.7346",
    "IsOpen24Hours": false,
    "id": "49.119,9.7346"
  },
  {
    "ID": 1453264250,
    "Tankstellennummer": "TD001537",
    "Name": "HEILBRONN KOLPINGSTR. 32",
    "Address": "KOLPINGSTR.32",
    "Address__1": "",
    "City": "HEILBRONN",
    "Postcode": 74081,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07131-255313",
    "24 hour?": "FALSE",
    "Latitude": 49.1228,
    "Longitude": 9.1959,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1228,9.1959",
    "IsOpen24Hours": false,
    "id": "49.1228,9.1959"
  },
  {
    "ID": 294660789,
    "Tankstellennummer": "TD001834",
    "Name": "LINKENHEIM-HOCHSTETTEN KARLSRU",
    "Address": "KARLSRUHER STR. 61A",
    "Address__1": "",
    "City": "LINKENHEIM-HOCHSTETTEN",
    "Postcode": 76351,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07247-4271",
    "24 hour?": "FALSE",
    "Latitude": 49.1253,
    "Longitude": 8.409,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1253,8.409",
    "IsOpen24Hours": false,
    "id": "49.1253,8.409"
  },
  {
    "ID": 1660935208,
    "Tankstellennummer": 3611,
    "Name": "Agip Gunzenhausen",
    "Address": "Nuernberger Str. 109",
    "Address__1": "",
    "City": "Gunzenhausen",
    "Postcode": 91710,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 98312504",
    "24 hour?": "TRUE",
    "Latitude": 49.12719767,
    "Longitude": 10.76824883,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.12719767,10.76824883",
    "IsOpen24Hours": true,
    "id": "49.12719767,10.76824883"
  },
  {
    "ID": 1810,
    "Tankstellennummer": "0F781",
    "Name": "Aral Tankstelle Eppingen, Brettener Str. 68",
    "Address": "Brettener Straße 68",
    "Address__1": "",
    "City": "Eppingen",
    "Postcode": 75031,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72622073777,
    "24 hour?": "FALSE",
    "Latitude": 49.134961,
    "Longitude": 8.901309,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.134961,8.901309",
    "IsOpen24Hours": false,
    "id": "49.134961,8.901309"
  },
  {
    "ID": 502095507,
    "Tankstellennummer": 2266,
    "Name": "Agip Linkenheim-Hochstetten",
    "Address": "Roemeraecker 5",
    "Address__1": "",
    "City": "Linkenheim-Hochstetten",
    "Postcode": 76351,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "07247/9544915",
    "24 hour?": "FALSE",
    "Latitude": 49.13768241,
    "Longitude": 8.42713194,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.13768241,8.42713194",
    "IsOpen24Hours": false,
    "id": "49.13768241,8.42713194"
  },
  {
    "ID": 979,
    "Tankstellennummer": "0F167",
    "Name": "Aral Tankstelle Crailsheim, Alter Postweg 1",
    "Address": "Alter Postweg 1",
    "Address__1": "",
    "City": "Crailsheim",
    "Postcode": 74564,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7951294260,
    "24 hour?": "TRUE",
    "Latitude": 49.138681,
    "Longitude": 10.062769,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.138681,10.062769",
    "IsOpen24Hours": true,
    "id": "49.138681,10.062769"
  },
  {
    "ID": 740126980,
    "Tankstellennummer": "TD000227",
    "Name": "MANDELBACHTAL GRENZLANDSTRASSE",
    "Address": "GRENZLANDSTRASSE 25",
    "Address__1": "",
    "City": "MANDELBACHTAL",
    "Postcode": 66399,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 68049146720,
    "24 hour?": "FALSE",
    "Latitude": 49.1396,
    "Longitude": 7.1302,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1396,7.1302",
    "IsOpen24Hours": false,
    "id": "49.1396,7.1302"
  },
  {
    "ID": 3198,
    "Tankstellennummer": "0FJ86",
    "Name": "Aral Tankstelle Heilbronn, Oststr. 72",
    "Address": "Oststraße 72",
    "Address__1": "",
    "City": "Heilbronn",
    "Postcode": 74072,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7131177475,
    "24 hour?": "TRUE",
    "Latitude": 49.140013,
    "Longitude": 9.229914,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.140013,9.229914",
    "IsOpen24Hours": true,
    "id": "49.140013,9.229914"
  },
  {
    "ID": 1894990020,
    "Tankstellennummer": 2135,
    "Name": "Agip Crailsheim",
    "Address": "Blaufelder Strasse",
    "Address__1": "",
    "City": "Crailsheim",
    "Postcode": 74564,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 79515922",
    "24 hour?": "FALSE",
    "Latitude": 49.14064308,
    "Longitude": 10.0733849,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.14064308,10.0733849",
    "IsOpen24Hours": false,
    "id": "49.14064308,10.0733849"
  },
  {
    "ID": 310306080,
    "Tankstellennummer": 2010,
    "Name": "Agip Crailsheim-Rossfeld",
    "Address": "Haller Str. 210",
    "Address__1": "",
    "City": "Crailsheim-Rossfeld",
    "Postcode": 74564,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 79512297",
    "24 hour?": "FALSE",
    "Latitude": 49.14136,
    "Longitude": 10.06094,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.14136,10.06094",
    "IsOpen24Hours": false,
    "id": "49.14136,10.06094"
  },
  {
    "ID": 1383,
    "Tankstellennummer": "0F464",
    "Name": "Aral Tankstelle Heilbronn, Wilhelm-Leuschner-Str. 14",
    "Address": "Wilhelm-Leuschner-Str. 14",
    "Address__1": "",
    "City": "Heilbronn",
    "Postcode": 74080,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 713142113,
    "24 hour?": "TRUE",
    "Latitude": 49.141443,
    "Longitude": 9.196302,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.141443,9.196302",
    "IsOpen24Hours": true,
    "id": "49.141443,9.196302"
  },
  {
    "ID": 864029355,
    "Tankstellennummer": 2074,
    "Name": "Agip Heilbronn",
    "Address": "Gymnasiumstrasse 71",
    "Address__1": "",
    "City": "Heilbronn",
    "Postcode": 74072,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71318049",
    "24 hour?": "FALSE",
    "Latitude": 49.14189,
    "Longitude": 9.22569,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.14189,9.22569",
    "IsOpen24Hours": false,
    "id": "49.14189,9.22569"
  },
  {
    "ID": 1076691340,
    "Tankstellennummer": 2269,
    "Name": "Agip Leingarten",
    "Address": "Leibnizstr. 24",
    "Address__1": "",
    "City": "Leingarten",
    "Postcode": 74211,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "07131-3994325",
    "24 hour?": "FALSE",
    "Latitude": 49.14383,
    "Longitude": 9.12661,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.14383,9.12661",
    "IsOpen24Hours": false,
    "id": "49.14383,9.12661"
  },
  {
    "ID": 1398,
    "Tankstellennummer": "0F478",
    "Name": "Aral Tankstelle Ellhofen, Haller Strasse 18",
    "Address": "Haller Straße 18",
    "Address__1": "",
    "City": "Ellhofen",
    "Postcode": 74248,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7134916854,
    "24 hour?": "FALSE",
    "Latitude": 49.148811,
    "Longitude": 9.321914,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.148811,9.321914",
    "IsOpen24Hours": false,
    "id": "49.148811,9.321914"
  },
  {
    "ID": 477683238,
    "Tankstellennummer": "TD019331",
    "Name": "FORST HAMBRUECKER STR. 59",
    "Address": "HAMBRUECKER STR.59",
    "Address__1": "",
    "City": "FORST",
    "Postcode": 76694,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07251-12278",
    "24 hour?": "FALSE",
    "Latitude": 49.1527,
    "Longitude": 8.5771,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1527,8.5771",
    "IsOpen24Hours": false,
    "id": "49.1527,8.5771"
  },
  {
    "ID": 1107446122,
    "Tankstellennummer": 3057,
    "Name": "Agip Traitsching",
    "Address": "Bachstr. 1",
    "Address__1": "",
    "City": "Traitsching",
    "Postcode": 93455,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 9974395",
    "24 hour?": "FALSE",
    "Latitude": 49.15276,
    "Longitude": 12.64594,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.15276,12.64594",
    "IsOpen24Hours": false,
    "id": "49.15276,12.64594"
  },
  {
    "ID": 1361966550,
    "Tankstellennummer": "TD036442",
    "Name": "HEILBRONN SAARBRUECKENER STR.",
    "Address": "SAARBRUECKENER STR. 41",
    "Address__1": "",
    "City": "HEILBRONN",
    "Postcode": 74078,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07131-42829",
    "24 hour?": "FALSE",
    "Latitude": 49.1552,
    "Longitude": 9.1764,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1552,9.1764",
    "IsOpen24Hours": false,
    "id": "49.1552,9.1764"
  },
  {
    "ID": 555918491,
    "Tankstellennummer": "TD001669",
    "Name": "UBSTADT BRUCHSALER STR. 15",
    "Address": "BRUCHSALER STR. 15",
    "Address__1": "",
    "City": "UBSTADT",
    "Postcode": 76698,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07251-967011",
    "24 hour?": "FALSE",
    "Latitude": 49.1557,
    "Longitude": 8.6268,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1557,8.6268",
    "IsOpen24Hours": false,
    "id": "49.1557,8.6268"
  },
  {
    "ID": 1426,
    "Tankstellennummer": "0F497",
    "Name": "Aral Tankstelle Heilbronn, Neckarsulmer Str. 91",
    "Address": "Neckarsulmer Straße 91",
    "Address__1": "",
    "City": "Heilbronn",
    "Postcode": 74076,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7131953061,
    "24 hour?": "TRUE",
    "Latitude": 49.156403,
    "Longitude": 9.224244,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.156403,9.224244",
    "IsOpen24Hours": true,
    "id": "49.156403,9.224244"
  },
  {
    "ID": 17728,
    "Tankstellennummer": "0FP21",
    "Name": "Aral Tankstelle Röttenbach, Zeppelinstraße 2",
    "Address": "Zeppelinstraße 2",
    "Address__1": "",
    "City": "Röttenbach",
    "Postcode": 91187,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 91726859440,
    "24 hour?": "TRUE",
    "Latitude": 49.157415,
    "Longitude": 11.041153,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.157415,11.041153",
    "IsOpen24Hours": true,
    "id": "49.157415,11.041153"
  },
  {
    "ID": 829181953,
    "Tankstellennummer": "TD035592",
    "Name": "HILPOLTSTEIN AUTOHOF AN DER AU",
    "Address": "AUTOHOF AN DER AUTOBAHN K4",
    "Address__1": "",
    "City": "HILPOLTSTEIN",
    "Postcode": 91161,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09174-976666",
    "24 hour?": "FALSE",
    "Latitude": 49.1609,
    "Longitude": 11.2622,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1609,11.2622",
    "IsOpen24Hours": false,
    "id": "49.1609,11.2622"
  },
  {
    "ID": 917658413,
    "Tankstellennummer": "TD040808",
    "Name": "PARSBERG VELBURGER STR. 1",
    "Address": "VELBURGER STR. 1",
    "Address__1": "",
    "City": "PARSBERG",
    "Postcode": 92331,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 9492954590,
    "24 hour?": "FALSE",
    "Latitude": 49.1625,
    "Longitude": 11.7161,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1625,11.7161",
    "IsOpen24Hours": false,
    "id": "49.1625,11.7161"
  },
  {
    "ID": 1815665382,
    "Tankstellennummer": 2099,
    "Name": "Agip Ubstadt-Weiher",
    "Address": "Stettfelder Str. 56",
    "Address__1": "",
    "City": "Ubstadt-Weiher",
    "Postcode": 76698,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 72519616",
    "24 hour?": "FALSE",
    "Latitude": 49.16381104,
    "Longitude": 8.63457943,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.16381104,8.63457943",
    "IsOpen24Hours": false,
    "id": "49.16381104,8.63457943"
  },
  {
    "ID": 2139292758,
    "Tankstellennummer": 2073,
    "Name": "Agip Graben-Neudorf",
    "Address": "Mannheimer Strasse 1A",
    "Address__1": "",
    "City": "Graben-Neudorf",
    "Postcode": 76676,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 72557255",
    "24 hour?": "TRUE",
    "Latitude": 49.16917858,
    "Longitude": 8.49272693,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.16917858,8.49272693",
    "IsOpen24Hours": true,
    "id": "49.16917858,8.49272693"
  },
  {
    "ID": 107933830,
    "Tankstellennummer": "TD038828",
    "Name": "WOLPERTSHAUSEN SUESSWIESENSTR.",
    "Address": "SUESSWIESENSTR. 2",
    "Address__1": "",
    "City": "WOLPERTSHAUSEN",
    "Postcode": 74549,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07904-9435899",
    "24 hour?": "FALSE",
    "Latitude": 49.1706,
    "Longitude": 9.8597,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1706,9.8597",
    "IsOpen24Hours": false,
    "id": "49.1706,9.8597"
  },
  {
    "ID": 2024,
    "Tankstellennummer": "0F919",
    "Name": "Aral Tankstelle Kötzting, Arnbrucker Str. 1 a",
    "Address": "Arnbrucker Straße 1 a",
    "Address__1": "",
    "City": "Bad Kötzting",
    "Postcode": 93444,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9941904901,
    "24 hour?": "TRUE",
    "Latitude": 49.17126,
    "Longitude": 12.863188,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.17126,12.863188",
    "IsOpen24Hours": true,
    "id": "49.17126,12.863188"
  },
  {
    "ID": 2112816714,
    "Tankstellennummer": "0FX98",
    "Name": "SCHNELLDORF",
    "Address": "Rudolf-Diesel-Straße 1",
    "Address__1": "",
    "City": "Schnelldorf",
    "Postcode": 91625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 79509262810,
    "24 hour?": "TRUE",
    "Latitude": 49.17156,
    "Longitude": 10.24098,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.17156,10.24098",
    "IsOpen24Hours": true,
    "id": "49.17156,10.24098"
  },
  {
    "ID": 1657887930,
    "Tankstellennummer": "TD042069",
    "Name": "SATTELDORF MARCO-POLO-STR.",
    "Address": "MARCO-POLO-STR. 1",
    "Address__1": "",
    "City": "SATTELDORF",
    "Postcode": 74589,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 795196900,
    "24 hour?": "FALSE",
    "Latitude": 49.1818,
    "Longitude": 10.0694,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1818,10.0694",
    "IsOpen24Hours": false,
    "id": "49.1818,10.0694"
  },
  {
    "ID": 919735608,
    "Tankstellennummer": "TD190620",
    "Name": "PIRMASENS BLOCKSBERGSTR. 55",
    "Address": "BLOCKSBERGSTR. 55",
    "Address__1": "",
    "City": "PIRMASENS",
    "Postcode": 66955,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06331-75557",
    "24 hour?": "FALSE",
    "Latitude": 49.1919,
    "Longitude": 7.5997,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1919,7.5997",
    "IsOpen24Hours": false,
    "id": "49.1919,7.5997"
  },
  {
    "ID": 855890828,
    "Tankstellennummer": "0FR11",
    "Name": "LANDAU",
    "Address": "Carl-Bosch Straße 1A",
    "Address__1": "",
    "City": "Landau",
    "Postcode": 76829,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63415590250,
    "24 hour?": "TRUE",
    "Latitude": 49.192556,
    "Longitude": 8.142516,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.192556,8.142516",
    "IsOpen24Hours": true,
    "id": "49.192556,8.142516"
  },
  {
    "ID": 1831,
    "Tankstellennummer": "0F794",
    "Name": "Aral Tankstelle Offenbach, Landauer Str. 11",
    "Address": "Landauer Straße 11",
    "Address__1": "",
    "City": "Offenbach",
    "Postcode": 76877,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63486473,
    "24 hour?": "FALSE",
    "Latitude": 49.194376,
    "Longitude": 8.187527,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.194376,8.187527",
    "IsOpen24Hours": false,
    "id": "49.194376,8.187527"
  },
  {
    "ID": 3437,
    "Tankstellennummer": "0FL69",
    "Name": "Aral Tankstelle Saarbrücken, Grossblittersd.Str. 285",
    "Address": "Großblittersdorfer Straße 285",
    "Address__1": "",
    "City": "Saarbrücken",
    "Postcode": 66119,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 681872270,
    "24 hour?": "TRUE",
    "Latitude": 49.19524,
    "Longitude": 7.02359,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.19524,7.02359",
    "IsOpen24Hours": true,
    "id": "49.19524,7.02359"
  },
  {
    "ID": 1764,
    "Tankstellennummer": "0F777",
    "Name": "Aral Tankstelle Bellheim, Zeiskamer Str. 63",
    "Address": "Zeiskamer Straße 63",
    "Address__1": "",
    "City": "Bellheim",
    "Postcode": 76756,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 727293110,
    "24 hour?": "FALSE",
    "Latitude": 49.195579,
    "Longitude": 8.277498,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.195579,8.277498",
    "IsOpen24Hours": false,
    "id": "49.195579,8.277498"
  },
  {
    "ID": 3285,
    "Tankstellennummer": "0FK47",
    "Name": "Aral Tankstelle Arrach, Regentalstr. 10",
    "Address": "Regentalstraße 10",
    "Address__1": "",
    "City": "Arrach",
    "Postcode": 93474,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 99433657,
    "24 hour?": "FALSE",
    "Latitude": 49.196611,
    "Longitude": 13.008035,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.196611,13.008035",
    "IsOpen24Hours": false,
    "id": "49.196611,13.008035"
  },
  {
    "ID": 912510480,
    "Tankstellennummer": "TD000450",
    "Name": "ITTLINGEN REIHENER STR. 24",
    "Address": "REIHENER STR. 24",
    "Address__1": "",
    "City": "ITTLINGEN",
    "Postcode": 74930,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07266-911017",
    "24 hour?": "FALSE",
    "Latitude": 49.1968,
    "Longitude": 8.9359,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1968,8.9359",
    "IsOpen24Hours": false,
    "id": "49.1968,8.9359"
  },
  {
    "ID": 1223500345,
    "Tankstellennummer": "TD002519",
    "Name": "SAARBRUECKEN GROSSBLITTERSDORF",
    "Address": "GROSSBLITTERSDORFER STR. 252",
    "Address__1": "",
    "City": "SAARBRUECKEN",
    "Postcode": 66119,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0681-871467",
    "24 hour?": "FALSE",
    "Latitude": 49.1975,
    "Longitude": 7.0194,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.1975,7.0194",
    "IsOpen24Hours": false,
    "id": "49.1975,7.0194"
  },
  {
    "ID": 2332,
    "Tankstellennummer": "0FB42",
    "Name": "Aral Tankstelle Merkendorf, Gunzenhausener Str. 9",
    "Address": "Gunzenhausener Straße 9",
    "Address__1": "",
    "City": "Merkendorf",
    "Postcode": 91732,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 982665520,
    "24 hour?": "TRUE",
    "Latitude": 49.198463,
    "Longitude": 10.70191,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.198463,10.70191",
    "IsOpen24Hours": true,
    "id": "49.198463,10.70191"
  },
  {
    "ID": 1389,
    "Tankstellennummer": "0F470",
    "Name": "Aral Tankstelle Neckarsulm, Neuenstädter Str. 95",
    "Address": "Neuenstädter Straße 93",
    "Address__1": "",
    "City": "Neckarsulm",
    "Postcode": 74172,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 713217709,
    "24 hour?": "TRUE",
    "Latitude": 49.198907,
    "Longitude": 9.232369,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.198907,9.232369",
    "IsOpen24Hours": true,
    "id": "49.198907,9.232369"
  },
  {
    "ID": 2475,
    "Tankstellennummer": "0FC31",
    "Name": "Aral Tankstelle Roding, Chamer Strasse 52",
    "Address": "Chamer Straße 52",
    "Address__1": "",
    "City": "Roding",
    "Postcode": 93426,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 94612109,
    "24 hour?": "FALSE",
    "Latitude": 49.199259,
    "Longitude": 12.524314,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.199259,12.524314",
    "IsOpen24Hours": false,
    "id": "49.199259,12.524314"
  },
  {
    "ID": 1493,
    "Tankstellennummer": "0F549",
    "Name": "Aral Tankstelle Neckarsulm, Neuenstädter Str. 96",
    "Address": "Neuenstädter Straße 96",
    "Address__1": "",
    "City": "Neckarsulm",
    "Postcode": 74172,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7132980222,
    "24 hour?": "TRUE",
    "Latitude": 49.199271,
    "Longitude": 9.23295,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.199271,9.23295",
    "IsOpen24Hours": true,
    "id": "49.199271,9.23295"
  },
  {
    "ID": 1392,
    "Tankstellennummer": "0F473",
    "Name": "Aral Tankstelle Öhringen, Heilbronner Str. 17",
    "Address": "Heilbronner Straße 17",
    "Address__1": "",
    "City": "Öhringen",
    "Postcode": 74613,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 794134945,
    "24 hour?": "TRUE",
    "Latitude": 49.202223,
    "Longitude": 9.495108,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.202223,9.495108",
    "IsOpen24Hours": true,
    "id": "49.202223,9.495108"
  },
  {
    "ID": 1029902437,
    "Tankstellennummer": "TD000410",
    "Name": "RUPPERTSWEILER STAENDENHOF 1 B",
    "Address": "STAENDENHOF 1, B10",
    "Address__1": "",
    "City": "RUPPERTSWEILER",
    "Postcode": 66957,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06395-6280",
    "24 hour?": "FALSE",
    "Latitude": 49.2041,
    "Longitude": 7.6961,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2041,7.6961",
    "IsOpen24Hours": false,
    "id": "49.2041,7.6961"
  },
  {
    "ID": 3142,
    "Tankstellennummer": "0FJ56",
    "Name": "Aral Tankstelle Roding, Schwandorfer Str. 10",
    "Address": "Schwandorfer Straße 10",
    "Address__1": "",
    "City": "Roding",
    "Postcode": 93426,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 94611233,
    "24 hour?": "FALSE",
    "Latitude": 49.205821,
    "Longitude": 12.486366,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.205821,12.486366",
    "IsOpen24Hours": false,
    "id": "49.205821,12.486366"
  },
  {
    "ID": 973,
    "Tankstellennummer": "0F161",
    "Name": "Aral Tankstelle Saarbrücken, Neumühler Weg",
    "Address": "Neumühler Weg 40",
    "Address__1": "",
    "City": "Saarbrücken",
    "Postcode": 66130,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 681875544,
    "24 hour?": "TRUE",
    "Latitude": 49.207392,
    "Longitude": 7.021482,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.207392,7.021482",
    "IsOpen24Hours": true,
    "id": "49.207392,7.021482"
  },
  {
    "ID": 226623111,
    "Tankstellennummer": "TD006056",
    "Name": "SAARBRUECKEN METZER STR.123",
    "Address": "METZER STR. 123",
    "Address__1": "",
    "City": "SAARBRUECKEN",
    "Postcode": 66117,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0681-5846632",
    "24 hour?": "FALSE",
    "Latitude": 49.2095,
    "Longitude": 6.9637,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2095,6.9637",
    "IsOpen24Hours": false,
    "id": "49.2095,6.9637"
  },
  {
    "ID": 268220335,
    "Tankstellennummer": "0FT81",
    "Name": "BAD RAPPENAU",
    "Address": "Wilhelm-Hauff-Straße 43",
    "Address__1": "",
    "City": "Bad Rappenau",
    "Postcode": 74906,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7066915353,
    "24 hour?": "TRUE",
    "Latitude": 49.210829,
    "Longitude": 9.070105,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.210829,9.070105",
    "IsOpen24Hours": true,
    "id": "49.210829,9.070105"
  },
  {
    "ID": 645012517,
    "Tankstellennummer": "TD038125",
    "Name": "BAD RAPPENAU BUCHAECKERRING 40",
    "Address": "BUCHAECKERRING 40",
    "Address__1": "",
    "City": "BAD RAPPENAU",
    "Postcode": 74906,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07066-9158824",
    "24 hour?": "FALSE",
    "Latitude": 49.2123,
    "Longitude": 9.0773,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2123,9.0773",
    "IsOpen24Hours": false,
    "id": "49.2123,9.0773"
  },
  {
    "ID": 1781,
    "Tankstellennummer": "0F759",
    "Name": "Aral Tankstelle Blieskastel-Aßweiler, Saar-Pfalz-Straße",
    "Address": "Saar-Pfalz-Straße",
    "Address__1": "",
    "City": "Blieskastel-Aßweiler",
    "Postcode": 66440,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68038433,
    "24 hour?": "FALSE",
    "Latitude": 49.213016,
    "Longitude": 7.181737,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.213016,7.181737",
    "IsOpen24Hours": false,
    "id": "49.213016,7.181737"
  },
  {
    "ID": 1729,
    "Tankstellennummer": "0F720",
    "Name": "Aral Tankstelle Pirmasens, Zweibrücker Str. 130",
    "Address": "Zweibrücker Straße 130",
    "Address__1": "",
    "City": "Pirmasens",
    "Postcode": 66954,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 633165954,
    "24 hour?": "TRUE",
    "Latitude": 49.213939,
    "Longitude": 7.597454,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.213939,7.597454",
    "IsOpen24Hours": true,
    "id": "49.213939,7.597454"
  },
  {
    "ID": 647901513,
    "Tankstellennummer": "TD170350",
    "Name": "FEUCHTWANGEN DORFGUETINGEN 43",
    "Address": "DORFGUETINGEN 43",
    "Address__1": "",
    "City": "FEUCHTWANGEN",
    "Postcode": 91555,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09852-768",
    "24 hour?": "FALSE",
    "Latitude": 49.2163,
    "Longitude": 10.3002,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2163,10.3002",
    "IsOpen24Hours": false,
    "id": "49.2163,10.3002"
  },
  {
    "ID": 871820521,
    "Tankstellennummer": 4305,
    "Name": "Agip Saarbruecken",
    "Address": "Metzer Str. 114",
    "Address__1": "",
    "City": "Saarbruecken",
    "Postcode": 66117,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 68154240",
    "24 hour?": "FALSE",
    "Latitude": 49.217653,
    "Longitude": 6.968336,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.217653,6.968336",
    "IsOpen24Hours": false,
    "id": "49.217653,6.968336"
  },
  {
    "ID": 549543194,
    "Tankstellennummer": 2092,
    "Name": "Agip Bad Friedrichshall",
    "Address": "Heilbronner Strasse 31",
    "Address__1": "",
    "City": "Bad Friedrichshall",
    "Postcode": 74177,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 71362371",
    "24 hour?": "FALSE",
    "Latitude": 49.21915,
    "Longitude": 9.21513,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.21915,9.21513",
    "IsOpen24Hours": false,
    "id": "49.21915,9.21513"
  },
  {
    "ID": 1860,
    "Tankstellennummer": "0F813",
    "Name": "Aral Tankstelle Saarbrücken, Feldmannstr. 69-73",
    "Address": "Feldmannstraße 69-73",
    "Address__1": "",
    "City": "Saarbrücken",
    "Postcode": 66119,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68156485,
    "24 hour?": "FALSE",
    "Latitude": 49.223519,
    "Longitude": 6.993105,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.223519,6.993105",
    "IsOpen24Hours": false,
    "id": "49.223519,6.993105"
  },
  {
    "ID": 254775692,
    "Tankstellennummer": 3105,
    "Name": "Agip Cham",
    "Address": "Further Strasse 8",
    "Address__1": "",
    "City": "Cham",
    "Postcode": 93413,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 99719270",
    "24 hour?": "FALSE",
    "Latitude": 49.22373,
    "Longitude": 12.67087,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.22373,12.67087",
    "IsOpen24Hours": false,
    "id": "49.22373,12.67087"
  },
  {
    "ID": 3093,
    "Tankstellennummer": "0FJ19",
    "Name": "Aral Tankstelle Saarbrücken, Mainzer Str. 273",
    "Address": "Mainzer Straße 273",
    "Address__1": "",
    "City": "Saarbrücken",
    "Postcode": 66121,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68165358,
    "24 hour?": "TRUE",
    "Latitude": 49.2255,
    "Longitude": 7.029655,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.2255,7.029655",
    "IsOpen24Hours": true,
    "id": "49.2255,7.029655"
  },
  {
    "ID": 1305530904,
    "Tankstellennummer": "TD000849",
    "Name": "GERMERSHEIM HAFENSTR. 2A",
    "Address": "HAFENSTR. 2A",
    "Address__1": "",
    "City": "GERMERSHEIM",
    "Postcode": 76726,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07274-778269",
    "24 hour?": "FALSE",
    "Latitude": 49.2279,
    "Longitude": 8.3718,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2279,8.3718",
    "IsOpen24Hours": false,
    "id": "49.2279,8.3718"
  },
  {
    "ID": 3435,
    "Tankstellennummer": "0FL68",
    "Name": "Aral Tankstelle Saarbrücken, Mainzer Str. 95",
    "Address": "Mainzer Straße 97",
    "Address__1": "",
    "City": "Saarbrücken",
    "Postcode": 66121,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68161887,
    "24 hour?": "TRUE",
    "Latitude": 49.22948,
    "Longitude": 7.007818,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.22948,7.007818",
    "IsOpen24Hours": true,
    "id": "49.22948,7.007818"
  },
  {
    "ID": 321705612,
    "Tankstellennummer": "TD038935",
    "Name": "SAARBRUECKEN DEUTSCHHERRNSTR.",
    "Address": "DEUTSCHHERRNSTR. 34-40",
    "Address__1": "",
    "City": "SAARBRUECKEN",
    "Postcode": 66117,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0681-59590984",
    "24 hour?": "FALSE",
    "Latitude": 49.2317,
    "Longitude": 6.9795,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2317,6.9795",
    "IsOpen24Hours": false,
    "id": "49.2317,6.9795"
  },
  {
    "ID": 1933231537,
    "Tankstellennummer": "0FP77",
    "Name": "ZWEIBRUCKEN",
    "Address": "Bitscher Straße 20a",
    "Address__1": "",
    "City": "Zweibrücken",
    "Postcode": 66482,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6332470736,
    "24 hour?": "FALSE",
    "Latitude": 49.235524,
    "Longitude": 7.361955,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.235524,7.361955",
    "IsOpen24Hours": false,
    "id": "49.235524,7.361955"
  },
  {
    "ID": 315060127,
    "Tankstellennummer": "0FP16",
    "Name": "BLIESKASTEL",
    "Address": "Bliesaue 3",
    "Address__1": "",
    "City": "Blieskastel",
    "Postcode": 66440,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68429219763,
    "24 hour?": "FALSE",
    "Latitude": 49.235657,
    "Longitude": 7.269783,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.235657,7.269783",
    "IsOpen24Hours": false,
    "id": "49.235657,7.269783"
  },
  {
    "ID": 562121748,
    "Tankstellennummer": "TD000974",
    "Name": "SAARBRUECKEN BURBACHER STR. 50",
    "Address": "BURBACHER STR. 50",
    "Address__1": "",
    "City": "SAARBRUECKEN",
    "Postcode": 66117,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0681-703332",
    "24 hour?": "FALSE",
    "Latitude": 49.2364,
    "Longitude": 6.9426,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2364,6.9426",
    "IsOpen24Hours": false,
    "id": "49.2364,6.9426"
  },
  {
    "ID": 1396,
    "Tankstellennummer": "0F476",
    "Name": "Aral Tankstelle Neuenstadt, Kochendorfer Str.2",
    "Address": "Kochendorfer Straße 2",
    "Address__1": "",
    "City": "Neuenstadt",
    "Postcode": 74196,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 71391382,
    "24 hour?": "FALSE",
    "Latitude": 49.236569,
    "Longitude": 9.324672,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.236569,9.324672",
    "IsOpen24Hours": false,
    "id": "49.236569,9.324672"
  },
  {
    "ID": 1943882865,
    "Tankstellennummer": "TD002493",
    "Name": "SAARBRUECKEN MARTIN-LUTHER-STR",
    "Address": "MARTIN-LUTHER-STR. 21",
    "Address__1": "",
    "City": "SAARBRUECKEN",
    "Postcode": 66111,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0681-398444",
    "24 hour?": "FALSE",
    "Latitude": 49.2376,
    "Longitude": 7.0048,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2376,7.0048",
    "IsOpen24Hours": false,
    "id": "49.2376,7.0048"
  },
  {
    "ID": 1800,
    "Tankstellennummer": "0F773",
    "Name": "Aral Tankstelle Saarbrücken, St. Johanner Str. 111",
    "Address": "St. Johanner Straße 105",
    "Address__1": "",
    "City": "Saarbrücken",
    "Postcode": 66115,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68143798,
    "24 hour?": "TRUE",
    "Latitude": 49.239022,
    "Longitude": 6.976791,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.239022,6.976791",
    "IsOpen24Hours": true,
    "id": "49.239022,6.976791"
  },
  {
    "ID": 1102711700,
    "Tankstellennummer": "TD002188",
    "Name": "SAARBRUECKEN DUDWEILER STR. 11",
    "Address": "DUDWEILER STR. 111",
    "Address__1": "",
    "City": "SAARBRUECKEN",
    "Postcode": 66111,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0681-34507",
    "24 hour?": "FALSE",
    "Latitude": 49.241,
    "Longitude": 7.0015,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.241,7.0015",
    "IsOpen24Hours": false,
    "id": "49.241,7.0015"
  },
  {
    "ID": 1552600688,
    "Tankstellennummer": "TD037903",
    "Name": "AURACH BAB A6/FRANKENHOEHE SUE",
    "Address": "BAB A6/FRANKENHOEHE SUED",
    "Address__1": "",
    "City": "AURACH",
    "Postcode": 91589,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09804-919150",
    "24 hour?": "FALSE",
    "Latitude": 49.2415,
    "Longitude": 10.3522,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2415,10.3522",
    "IsOpen24Hours": false,
    "id": "49.2415,10.3522"
  },
  {
    "ID": 306135777,
    "Tankstellennummer": "TD041939",
    "Name": "BURGOBERBACH IM BIRKFELD 8",
    "Address": "IM BIRKFELD 8",
    "Address__1": "",
    "City": "BURGOBERBACH",
    "Postcode": 91595,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 98054269946,
    "24 hour?": "FALSE",
    "Latitude": 49.2423,
    "Longitude": 10.6061,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2423,10.6061",
    "IsOpen24Hours": false,
    "id": "49.2423,10.6061"
  },
  {
    "ID": 683905151,
    "Tankstellennummer": 3158,
    "Name": "Agip Aurach",
    "Address": "Frankenhoehe Nord",
    "Address__1": "",
    "City": "Aurach",
    "Postcode": 91589,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 98049191",
    "24 hour?": "TRUE",
    "Latitude": 49.24234,
    "Longitude": 10.35762,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.24234,10.35762",
    "IsOpen24Hours": true,
    "id": "49.24234,10.35762"
  },
  {
    "ID": 285611873,
    "Tankstellennummer": "0FQ65",
    "Name": "VÖLKLINGEN-WEHRDEN",
    "Address": "Hallerstraße 1",
    "Address__1": "",
    "City": "Völklingen-Wehrden",
    "Postcode": 66333,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 689829375,
    "24 hour?": "TRUE",
    "Latitude": 49.243826,
    "Longitude": 6.83978,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.243826,6.83978",
    "IsOpen24Hours": true,
    "id": "49.243826,6.83978"
  },
  {
    "ID": 305155963,
    "Tankstellennummer": 3040,
    "Name": "Agip Windsbach",
    "Address": "Ansbacher Strasse 34",
    "Address__1": "",
    "City": "Windsbach",
    "Postcode": 91575,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 9871246",
    "24 hour?": "FALSE",
    "Latitude": 49.24591429,
    "Longitude": 10.82309287,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.24591429,10.82309287",
    "IsOpen24Hours": false,
    "id": "49.24591429,10.82309287"
  },
  {
    "ID": 701358196,
    "Tankstellennummer": 3142,
    "Name": "Agip Roth",
    "Address": "Allersberger Str. 81",
    "Address__1": "",
    "City": "Roth",
    "Postcode": 91154,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91716264",
    "24 hour?": "FALSE",
    "Latitude": 49.24674,
    "Longitude": 11.10639,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.24674,11.10639",
    "IsOpen24Hours": false,
    "id": "49.24674,11.10639"
  },
  {
    "ID": 1401,
    "Tankstellennummer": "0F480",
    "Name": "Aral Tankstelle Rot am See, Crailsheimer Str. 9",
    "Address": "Crailsheimer Straße 9",
    "Address__1": "",
    "City": "Rot am See",
    "Postcode": 74585,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 79552294,
    "24 hour?": "FALSE",
    "Latitude": 49.24707,
    "Longitude": 10.028508,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.24707,10.028508",
    "IsOpen24Hours": false,
    "id": "49.24707,10.028508"
  },
  {
    "ID": 1797,
    "Tankstellennummer": "0F771",
    "Name": "Aral Tankstelle Völklingen, Karolinger Strasse 5",
    "Address": "Karolingerstraße 5",
    "Address__1": "",
    "City": "Völklingen",
    "Postcode": 66333,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 689823327,
    "24 hour?": "TRUE",
    "Latitude": 49.247547,
    "Longitude": 6.859246,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.247547,6.859246",
    "IsOpen24Hours": true,
    "id": "49.247547,6.859246"
  },
  {
    "ID": 1469,
    "Tankstellennummer": "0F533",
    "Name": "Aral Tankstelle Gerabronn, Haller Str.5",
    "Address": "Haller Straße 5",
    "Address__1": "",
    "City": "Gerabronn",
    "Postcode": 74582,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 79525059,
    "24 hour?": "FALSE",
    "Latitude": 49.247807,
    "Longitude": 9.920046,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.247807,9.920046",
    "IsOpen24Hours": false,
    "id": "49.247807,9.920046"
  },
  {
    "ID": 3429,
    "Tankstellennummer": "0FL64",
    "Name": "Aral Tankstelle Mühlhausen, Hauptstr. 166",
    "Address": "Hauptstraße 166",
    "Address__1": "",
    "City": "Mühlhausen",
    "Postcode": 69242,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 622262546,
    "24 hour?": "FALSE",
    "Latitude": 49.248049,
    "Longitude": 8.735546,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.248049,8.735546",
    "IsOpen24Hours": false,
    "id": "49.248049,8.735546"
  },
  {
    "ID": 382672560,
    "Tankstellennummer": "TD002550",
    "Name": "CONTWIG PIRMASENSER STR. 93",
    "Address": "PIRMASENSER STR. 93",
    "Address__1": "",
    "City": "CONTWIG",
    "Postcode": 66497,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06332-5833",
    "24 hour?": "FALSE",
    "Latitude": 49.2484,
    "Longitude": 7.4431,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2484,7.4431",
    "IsOpen24Hours": false,
    "id": "49.2484,7.4431"
  },
  {
    "ID": 3096,
    "Tankstellennummer": "0FJ22",
    "Name": "Aral Tankstelle Überherrn, Hauptstrasse 2 C",
    "Address": "Hauptstraße 2 C",
    "Address__1": "",
    "City": "Überherrn",
    "Postcode": 66802,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 683692570,
    "24 hour?": "FALSE",
    "Latitude": 49.248609,
    "Longitude": 6.697189,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.248609,6.697189",
    "IsOpen24Hours": false,
    "id": "49.248609,6.697189"
  },
  {
    "ID": 1792,
    "Tankstellennummer": "0F767",
    "Name": "Aral Tankstelle Völklingen, Str.d.13.Januar 197",
    "Address": "Straße des 13. Januar 197",
    "Address__1": "",
    "City": "Völklingen",
    "Postcode": 66333,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 689881668,
    "24 hour?": "FALSE",
    "Latitude": 49.249077,
    "Longitude": 6.898475,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.249077,6.898475",
    "IsOpen24Hours": false,
    "id": "49.249077,6.898475"
  },
  {
    "ID": 1875,
    "Tankstellennummer": "0F821",
    "Name": "Aral Tankstelle Zweibrücken, Saarlandstr.2",
    "Address": "Saarlandstraße 2",
    "Address__1": "",
    "City": "Zweibrücken",
    "Postcode": 66482,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6332472572,
    "24 hour?": "TRUE",
    "Latitude": 49.249568,
    "Longitude": 7.370192,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.249568,7.370192",
    "IsOpen24Hours": true,
    "id": "49.249568,7.370192"
  },
  {
    "ID": 3139,
    "Tankstellennummer": "0FJ55",
    "Name": "Aral Tankstelle Roth, Allersberger Str. 33 A",
    "Address": "Allersberger Straße 33",
    "Address__1": "",
    "City": "Roth",
    "Postcode": 91154,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 91712530,
    "24 hour?": "FALSE",
    "Latitude": 49.249778,
    "Longitude": 11.095588,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.249778,11.095588",
    "IsOpen24Hours": false,
    "id": "49.249778,11.095588"
  },
  {
    "ID": 938935153,
    "Tankstellennummer": 3143,
    "Name": "Agip Roth",
    "Address": "Nuernberger Str. 45",
    "Address__1": "",
    "City": "Roth",
    "Postcode": 91154,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91716362",
    "24 hour?": "FALSE",
    "Latitude": 49.24995,
    "Longitude": 11.08998,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.24995,11.08998",
    "IsOpen24Hours": false,
    "id": "49.24995,11.08998"
  },
  {
    "ID": 1829718840,
    "Tankstellennummer": "0FT48",
    "Name": "OBERHAUSEN-RHEINHAUS",
    "Address": "Hoeber-u.Mandelbaumstr. 1",
    "Address__1": "",
    "City": "Oberhausen-Rheinhaus",
    "Postcode": 68794,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 72542031920,
    "24 hour?": "TRUE",
    "Latitude": 49.250455,
    "Longitude": 8.500838,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.250455,8.500838",
    "IsOpen24Hours": true,
    "id": "49.250455,8.500838"
  },
  {
    "ID": 64707487,
    "Tankstellennummer": "TD038729",
    "Name": "AURACH ANSBACHER STR. 42",
    "Address": "ANSBACHER STR. 42",
    "Address__1": "",
    "City": "AURACH",
    "Postcode": 91589,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09804-9391922",
    "24 hour?": "FALSE",
    "Latitude": 49.2523,
    "Longitude": 10.4345,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2523,10.4345",
    "IsOpen24Hours": false,
    "id": "49.2523,10.4345"
  },
  {
    "ID": 2028,
    "Tankstellennummer": "0F923",
    "Name": "Aral Autohof Herrieden, Am Eichelberg 2",
    "Address": "Am Eichelberg 2",
    "Address__1": "",
    "City": "Herrieden",
    "Postcode": 91567,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9825929790,
    "24 hour?": "TRUE",
    "Latitude": 49.258752,
    "Longitude": 10.501871,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.258752,10.501871",
    "IsOpen24Hours": true,
    "id": "49.258752,10.501871"
  },
  {
    "ID": 302242886,
    "Tankstellennummer": "TD000538",
    "Name": "ST. INGBERT ENSHEIMER STRASSE",
    "Address": "ENSHEIMER STRASSE 162",
    "Address__1": "",
    "City": "ST. INGBERT",
    "Postcode": 66386,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06894-6626",
    "24 hour?": "FALSE",
    "Latitude": 49.2623,
    "Longitude": 7.1031,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2623,7.1031",
    "IsOpen24Hours": false,
    "id": "49.2623,7.1031"
  },
  {
    "ID": 1861,
    "Tankstellennummer": "0F814",
    "Name": "Aral Tankstelle St. Ingbert, Ensheimerstr.152",
    "Address": "Ensheimer Straße 152",
    "Address__1": "",
    "City": "St. Ingbert",
    "Postcode": 66386,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68946874,
    "24 hour?": "FALSE",
    "Latitude": 49.263135,
    "Longitude": 7.102876,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.263135,7.102876",
    "IsOpen24Hours": false,
    "id": "49.263135,7.102876"
  },
  {
    "ID": 407167077,
    "Tankstellennummer": "TD190180",
    "Name": "EDESHEIM STAATSSTR. 47A",
    "Address": "STAATSSTR. 47A",
    "Address__1": "",
    "City": "EDESHEIM",
    "Postcode": 67483,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06323-2876",
    "24 hour?": "FALSE",
    "Latitude": 49.2658,
    "Longitude": 8.1336,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2658,8.1336",
    "IsOpen24Hours": false,
    "id": "49.2658,8.1336"
  },
  {
    "ID": 28101501,
    "Tankstellennummer": "TD124210",
    "Name": "SAARBRUECKEN SULZBACHTALSTR. 1",
    "Address": "SULZBACHTALSTR. 19",
    "Address__1": "",
    "City": "SAARBRUECKEN",
    "Postcode": 66125,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0681-3908027",
    "24 hour?": "FALSE",
    "Latitude": 49.2671,
    "Longitude": 7.0112,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2671,7.0112",
    "IsOpen24Hours": false,
    "id": "49.2671,7.0112"
  },
  {
    "ID": 1611588676,
    "Tankstellennummer": 2161,
    "Name": "Agip Saarbruecken(Dudw.)",
    "Address": "Scheidter Strasse 260-262",
    "Address__1": "",
    "City": "Saarbruecken(Dudw.)",
    "Postcode": 66125,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 68977336",
    "24 hour?": "FALSE",
    "Latitude": 49.26802,
    "Longitude": 7.04973,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.26802,7.04973",
    "IsOpen24Hours": false,
    "id": "49.26802,7.04973"
  },
  {
    "ID": 921242044,
    "Tankstellennummer": "0FT56",
    "Name": "SCHWEGENHEIM",
    "Address": "Im breiten Pfuhl 5",
    "Address__1": "",
    "City": "Schwegenheim",
    "Postcode": 67365,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63445074933,
    "24 hour?": "TRUE",
    "Latitude": 49.271938,
    "Longitude": 8.338524,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.271938,8.338524",
    "IsOpen24Hours": true,
    "id": "49.271938,8.338524"
  },
  {
    "ID": 333200777,
    "Tankstellennummer": "TD124240",
    "Name": "BOUS SAARBRUECKER STR. 67",
    "Address": "SAARBRUECKER STR. 67",
    "Address__1": "",
    "City": "BOUS",
    "Postcode": 66359,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06834-3630",
    "24 hour?": "FALSE",
    "Latitude": 49.2746,
    "Longitude": 6.7967,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2746,6.7967",
    "IsOpen24Hours": false,
    "id": "49.2746,6.7967"
  },
  {
    "ID": 3095,
    "Tankstellennummer": "0FJ21",
    "Name": "Aral Tankstelle St Ingbert, Saarbrueckerstr. 6 A",
    "Address": "Saarbrücker Straße 6 a",
    "Address__1": "",
    "City": "St. Ingbert",
    "Postcode": 66386,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68944468,
    "24 hour?": "TRUE",
    "Latitude": 49.275611,
    "Longitude": 7.110575,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.275611,7.110575",
    "IsOpen24Hours": true,
    "id": "49.275611,7.110575"
  },
  {
    "ID": 182611090,
    "Tankstellennummer": 3356,
    "Name": "Agip Lichtenau",
    "Address": "Fuchsgrabenweg 1",
    "Address__1": "",
    "City": "Lichtenau",
    "Postcode": 91586,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 9827/928",
    "24 hour?": "TRUE",
    "Latitude": 49.28013,
    "Longitude": 10.712788,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.28013,10.712788",
    "IsOpen24Hours": true,
    "id": "49.28013,10.712788"
  },
  {
    "ID": 3137,
    "Tankstellennummer": "0FJ52",
    "Name": "Aral Tankstelle Lichtenau, Ansbacher Str. 34",
    "Address": "Ansbacher Straße 34",
    "Address__1": "",
    "City": "Lichtenau",
    "Postcode": 91586,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9827250,
    "24 hour?": "FALSE",
    "Latitude": 49.281171,
    "Longitude": 10.679745,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.281171,10.679745",
    "IsOpen24Hours": false,
    "id": "49.281171,10.679745"
  },
  {
    "ID": 1391,
    "Tankstellennummer": "0F472",
    "Name": "Aral Tankstelle Künzelsau, Mergentheimer Str.2/1",
    "Address": "Mergentheimer Straße 2/1",
    "Address__1": "",
    "City": "Künzelsau",
    "Postcode": 74653,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 79402221,
    "24 hour?": "FALSE",
    "Latitude": 49.281938,
    "Longitude": 9.691381,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.281938,9.691381",
    "IsOpen24Hours": false,
    "id": "49.281938,9.691381"
  },
  {
    "ID": 794828030,
    "Tankstellennummer": "TD002337",
    "Name": "WIESLOCH HAUPTSTR. 180",
    "Address": "HAUPTSTR. 180",
    "Address__1": "",
    "City": "WIESLOCH",
    "Postcode": 69168,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06222-50637",
    "24 hour?": "FALSE",
    "Latitude": 49.2835,
    "Longitude": 8.6863,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2835,8.6863",
    "IsOpen24Hours": false,
    "id": "49.2835,8.6863"
  },
  {
    "ID": 3116,
    "Tankstellennummer": "0FJ37",
    "Name": "Aral Tankstelle Künzelsau, Mergentheimer Str. 126",
    "Address": "Mergentheimer Straße 126",
    "Address__1": "",
    "City": "Künzelsau",
    "Postcode": 74653,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 7940910821,
    "24 hour?": "FALSE",
    "Latitude": 49.284447,
    "Longitude": 9.684491,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.284447,9.684491",
    "IsOpen24Hours": false,
    "id": "49.284447,9.684491"
  },
  {
    "ID": 2376,
    "Tankstellennummer": "0FB73",
    "Name": "Aral Tankstelle Neumarkt, Amberger Str.18",
    "Address": "Amberger Straße 18",
    "Address__1": "",
    "City": "Neumarkt",
    "Postcode": 92318,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 918143441,
    "24 hour?": "TRUE",
    "Latitude": 49.285191,
    "Longitude": 11.465289,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.285191,11.465289",
    "IsOpen24Hours": true,
    "id": "49.285191,11.465289"
  },
  {
    "ID": 1739,
    "Tankstellennummer": "0F726",
    "Name": "Aral Tankstelle Waldfischbach-Burgal, Hauptstr.71",
    "Address": "Hauptstraße 71",
    "Address__1": "",
    "City": "Waldfischbach-Burgal",
    "Postcode": 67714,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6333274825,
    "24 hour?": "FALSE",
    "Latitude": 49.286531,
    "Longitude": 7.64962,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.286531,7.64962",
    "IsOpen24Hours": false,
    "id": "49.286531,7.64962"
  },
  {
    "ID": 1864,
    "Tankstellennummer": "0F815",
    "Name": "Aral Tankstelle Wiesloch, Eichelweg 1",
    "Address": "Neues Sträßel 4",
    "Address__1": "",
    "City": "Wiesloch",
    "Postcode": 69168,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62221038,
    "24 hour?": "FALSE",
    "Latitude": 49.28953,
    "Longitude": 8.678427,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.28953,8.678427",
    "IsOpen24Hours": false,
    "id": "49.28953,8.678427"
  },
  {
    "ID": 1856,
    "Tankstellennummer": "0F810",
    "Name": "Aral Tankstelle Kirkel, Kaiserstr.1 E",
    "Address": "Kaiserstraße 1 E",
    "Address__1": "",
    "City": "Kirkel",
    "Postcode": 66459,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6849551,
    "24 hour?": "FALSE",
    "Latitude": 49.289744,
    "Longitude": 7.238459,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.289744,7.238459",
    "IsOpen24Hours": false,
    "id": "49.289744,7.238459"
  },
  {
    "ID": 372245733,
    "Tankstellennummer": 2244,
    "Name": "Agip Wiesloch",
    "Address": "Schwetzinger Str. 125",
    "Address__1": "",
    "City": "Wiesloch",
    "Postcode": 69168,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62225276",
    "24 hour?": "FALSE",
    "Latitude": 49.29148,
    "Longitude": 8.68342,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.29148,8.68342",
    "IsOpen24Hours": false,
    "id": "49.29148,8.68342"
  },
  {
    "ID": 2093622112,
    "Tankstellennummer": "TD007377",
    "Name": "PUETTLINGEN KOELLERTALSTR. 141",
    "Address": "KOELLERTALSTR. 141",
    "Address__1": "",
    "City": "PUETTLINGEN",
    "Postcode": 66346,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06806-440332",
    "24 hour?": "FALSE",
    "Latitude": 49.2939,
    "Longitude": 6.8921,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2939,6.8921",
    "IsOpen24Hours": false,
    "id": "49.2939,6.8921"
  },
  {
    "ID": 862646082,
    "Tankstellennummer": "TD002279",
    "Name": "NEULUSSHEIM ALTLUSSHEIMER STR.",
    "Address": "ALTLUSSHEIMER STR. 54",
    "Address__1": "",
    "City": "NEULUSSHEIM",
    "Postcode": 68809,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06205-31735",
    "24 hour?": "FALSE",
    "Latitude": 49.2944,
    "Longitude": 8.5169,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2944,8.5169",
    "IsOpen24Hours": false,
    "id": "49.2944,8.5169"
  },
  {
    "ID": 1504912746,
    "Tankstellennummer": "0FN24",
    "Name": "RIEGELSBERG",
    "Address": "Hixberger Straße 28 a",
    "Address__1": "",
    "City": "Riegelsberg",
    "Postcode": 66292,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68063060416,
    "24 hour?": "FALSE",
    "Latitude": 49.295276,
    "Longitude": 6.934298,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.295276,6.934298",
    "IsOpen24Hours": false,
    "id": "49.295276,6.934298"
  },
  {
    "ID": 1750311760,
    "Tankstellennummer": "TD005967",
    "Name": "NECKARBISCHOFSHEIM WAIBSTAEDTE",
    "Address": "WAIBSTAEDTER STR. 35",
    "Address__1": "",
    "City": "NECKARBISCHOFSHEIM",
    "Postcode": 74924,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07263-969900",
    "24 hour?": "FALSE",
    "Latitude": 49.2958,
    "Longitude": 8.9544,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2958,8.9544",
    "IsOpen24Hours": false,
    "id": "49.2958,8.9544"
  },
  {
    "ID": 306040300,
    "Tankstellennummer": "TD041145",
    "Name": "BLAUFELDEN IM RIEDLE 1",
    "Address": "IM RIEDLE 1",
    "Address__1": "",
    "City": "BLAUFELDEN",
    "Postcode": 74572,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "07953-6249980",
    "24 hour?": "FALSE",
    "Latitude": 49.2959,
    "Longitude": 9.9787,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2959,9.9787",
    "IsOpen24Hours": false,
    "id": "49.2959,9.9787"
  },
  {
    "ID": 260869867,
    "Tankstellennummer": "0FN30",
    "Name": "RIEGELSBERG",
    "Address": "Saarbrücker Straße 7 c",
    "Address__1": "",
    "City": "Riegelsberg",
    "Postcode": 66292,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 680644748,
    "24 hour?": "FALSE",
    "Latitude": 49.29754,
    "Longitude": 6.94797,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.29754,6.94797",
    "IsOpen24Hours": false,
    "id": "49.29754,6.94797"
  },
  {
    "ID": 990,
    "Tankstellennummer": "0F176",
    "Name": "Aral Tankstelle Ansbach, Matthias-Öchsler- Str. 2",
    "Address": "Matthias-Öchsler- Str. 2",
    "Address__1": "",
    "City": "Ansbach",
    "Postcode": 91522,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9819775633,
    "24 hour?": "TRUE",
    "Latitude": 49.299054,
    "Longitude": 10.586178,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.299054,10.586178",
    "IsOpen24Hours": true,
    "id": "49.299054,10.586178"
  },
  {
    "ID": 381751654,
    "Tankstellennummer": "TD002287",
    "Name": "ROEMERBERG GERMERSHEIMER STR.",
    "Address": "GERMERSHEIMER STR. 2A",
    "Address__1": "",
    "City": "ROEMERBERG",
    "Postcode": 67354,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06232-82636",
    "24 hour?": "FALSE",
    "Latitude": 49.2993,
    "Longitude": 8.4106,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2993,8.4106",
    "IsOpen24Hours": false,
    "id": "49.2993,8.4106"
  },
  {
    "ID": 1401163978,
    "Tankstellennummer": "TD124080",
    "Name": "SAARLOUIS BAB A 620 NORDSEITE",
    "Address": "BAB A 620 NORDSEITE",
    "Address__1": "",
    "City": "SAARLOUIS",
    "Postcode": 66740,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06831-1585",
    "24 hour?": "FALSE",
    "Latitude": 49.2998,
    "Longitude": 6.7494,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2998,6.7494",
    "IsOpen24Hours": false,
    "id": "49.2998,6.7494"
  },
  {
    "ID": 597507693,
    "Tankstellennummer": "TD124100",
    "Name": "SAARLOUIS BAB A 620 SUEDSEITE",
    "Address": "BAB A 620 SUEDSEITE",
    "Address__1": "",
    "City": "SAARLOUIS",
    "Postcode": 66740,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06831-2060",
    "24 hour?": "FALSE",
    "Latitude": 49.2998,
    "Longitude": 6.7484,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.2998,6.7484",
    "IsOpen24Hours": false,
    "id": "49.2998,6.7484"
  },
  {
    "ID": 2084705357,
    "Tankstellennummer": "TD006718",
    "Name": "WALLDORF BAHNHOFSTR. 35",
    "Address": "33 BAHNHOFSTR.",
    "Address__1": "",
    "City": "WALLDORF",
    "Postcode": 69190,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 6227890051,
    "24 hour?": "FALSE",
    "Latitude": 49.3005,
    "Longitude": 8.645,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.3005,8.645",
    "IsOpen24Hours": false,
    "id": "49.3005,8.645"
  },
  {
    "ID": 2106470438,
    "Tankstellennummer": 4777,
    "Name": "Agip Querischied",
    "Address": "Talstr. 37",
    "Address__1": "",
    "City": "Quierschied",
    "Postcode": 66287,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 6897-841",
    "24 hour?": "FALSE",
    "Latitude": 49.3038,
    "Longitude": 7.02652,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.3038,7.02652",
    "IsOpen24Hours": false,
    "id": "49.3038,7.02652"
  },
  {
    "ID": 3094,
    "Tankstellennummer": "0FJ20",
    "Name": "Aral Tankstelle Saarlouis, Metzer Str. 50",
    "Address": "Metzer Straße 44",
    "Address__1": "",
    "City": "Saarlouis",
    "Postcode": 66740,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 683140414,
    "24 hour?": "TRUE",
    "Latitude": 49.305459,
    "Longitude": 6.74008,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.305459,6.74008",
    "IsOpen24Hours": true,
    "id": "49.305459,6.74008"
  },
  {
    "ID": 1384270501,
    "Tankstellennummer": "TD002329",
    "Name": "WIESLOCH SCHATTHAEUSER STR. 44",
    "Address": "SCHATTHAEUSER STR. 44",
    "Address__1": "",
    "City": "WIESLOCH",
    "Postcode": 69168,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06222-772652",
    "24 hour?": "FALSE",
    "Latitude": 49.306,
    "Longitude": 8.7389,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.306,8.7389",
    "IsOpen24Hours": false,
    "id": "49.306,8.7389"
  },
  {
    "ID": 559998382,
    "Tankstellennummer": "0FN74",
    "Name": "MAIKAMMER",
    "Address": "Bahnhofstraße 73",
    "Address__1": "",
    "City": "Maikammer",
    "Postcode": 67487,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63215222,
    "24 hour?": "FALSE",
    "Latitude": 49.306461,
    "Longitude": 8.142164,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.306461,8.142164",
    "IsOpen24Hours": false,
    "id": "49.306461,8.142164"
  },
  {
    "ID": 1783,
    "Tankstellennummer": "0F760",
    "Name": "Aral Tankstelle Sulzbach, Grülingstrasse",
    "Address": "Grülingstraße",
    "Address__1": "",
    "City": "Sulzbach",
    "Postcode": 66280,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68972307,
    "24 hour?": "FALSE",
    "Latitude": 49.306551,
    "Longitude": 7.056207,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.306551,7.056207",
    "IsOpen24Hours": false,
    "id": "49.306551,7.056207"
  },
  {
    "ID": 3076,
    "Tankstellennummer": "0FJ07",
    "Name": "Aral Tankstelle Speyer, Landauer Str. 65",
    "Address": "Landauer Straße 65",
    "Address__1": "",
    "City": "Speyer",
    "Postcode": 67346,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 623275427,
    "24 hour?": "TRUE",
    "Latitude": 49.308935,
    "Longitude": 8.423943,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.308935,8.423943",
    "IsOpen24Hours": true,
    "id": "49.308935,8.423943"
  },
  {
    "ID": 968,
    "Tankstellennummer": "0F157",
    "Name": "Aral Tankstelle Speyer, Industriestraße 5 a",
    "Address": "Industriestraße 5 a",
    "Address__1": "",
    "City": "Speyer",
    "Postcode": 67346,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 623261143,
    "24 hour?": "TRUE",
    "Latitude": 49.309731,
    "Longitude": 8.444535,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.309731,8.444535",
    "IsOpen24Hours": true,
    "id": "49.309731,8.444535"
  },
  {
    "ID": 2029,
    "Tankstellennummer": "0FE67",
    "Name": "Aral Autohof Kammerstein, An der Autobahn 1",
    "Address": "An der Autobahn 1",
    "Address__1": "",
    "City": "Kammerstein",
    "Postcode": 91126,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9122879822,
    "24 hour?": "TRUE",
    "Latitude": 49.31136,
    "Longitude": 11.002243,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.31136,11.002243",
    "IsOpen24Hours": true,
    "id": "49.31136,11.002243"
  },
  {
    "ID": 656202391,
    "Tankstellennummer": "TD002295",
    "Name": "SPEYER LINDENSTR. 18-20",
    "Address": "LINDENSTR. 20",
    "Address__1": "",
    "City": "SPEYER",
    "Postcode": 67346,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06232-75440",
    "24 hour?": "FALSE",
    "Latitude": 49.3127,
    "Longitude": 8.4375,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.3127,8.4375",
    "IsOpen24Hours": false,
    "id": "49.3127,8.4375"
  },
  {
    "ID": 3056,
    "Tankstellennummer": "0FI91",
    "Name": "Aral Autobahntankstelle Hockenheim, Hockenheimring Ost (A 6)",
    "Address": "A 6",
    "Address__1": "",
    "City": "Hockenheim",
    "Postcode": 68766,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 620513513,
    "24 hour?": "TRUE",
    "Latitude": 49.315262,
    "Longitude": 8.57763,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.315262,8.57763",
    "IsOpen24Hours": true,
    "id": "49.315262,8.57763"
  },
  {
    "ID": 496789662,
    "Tankstellennummer": 2247,
    "Name": "Agip Widdern",
    "Address": "Jagsttal-Ost",
    "Address__1": "",
    "City": "Widdern",
    "Postcode": 74259,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62985646",
    "24 hour?": "TRUE",
    "Latitude": 49.315915,
    "Longitude": 9.419485,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.315915,9.419485",
    "IsOpen24Hours": true,
    "id": "49.315915,9.419485"
  },
  {
    "ID": 576504645,
    "Tankstellennummer": "TD191230",
    "Name": "FRIEDRICHSTHAL SAARBRUECKER ST",
    "Address": "SAARBRUECKER STR. 1b",
    "Address__1": "",
    "City": "FRIEDRICHSTHAL",
    "Postcode": 66299,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06897-89213",
    "24 hour?": "FALSE",
    "Latitude": 49.317,
    "Longitude": 7.0877,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.317,7.0877",
    "IsOpen24Hours": false,
    "id": "49.317,7.0877"
  },
  {
    "ID": 3081,
    "Tankstellennummer": "0FJ11",
    "Name": "Aral Tankstelle Spiesen-Elversberg, Lindenstr. 1",
    "Address": "Lindenstraße 14",
    "Address__1": "",
    "City": "Spiesen-Elversberg",
    "Postcode": 66583,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6821790981,
    "24 hour?": "TRUE",
    "Latitude": 49.317689,
    "Longitude": 7.122482,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.317689,7.122482",
    "IsOpen24Hours": true,
    "id": "49.317689,7.122482"
  },
  {
    "ID": 1799,
    "Tankstellennummer": "0F772",
    "Name": "Aral Tankstelle Spiesen-Elversberg, Lindenstrasse 7",
    "Address": "Lindenstraße 7",
    "Address__1": "",
    "City": "Spiesen-Elversberg",
    "Postcode": 66583,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 682171436,
    "24 hour?": "FALSE",
    "Latitude": 49.3178,
    "Longitude": 7.12184,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.3178,7.12184",
    "IsOpen24Hours": false,
    "id": "49.3178,7.12184"
  },
  {
    "ID": 3086,
    "Tankstellennummer": "0FJ15",
    "Name": "Aral Tankstelle Homburg, Saarbruecker Str. 100",
    "Address": "Saarbruecker Straße 100",
    "Address__1": "",
    "City": "Homburg",
    "Postcode": 66424,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6841120865,
    "24 hour?": "TRUE",
    "Latitude": 49.318678,
    "Longitude": 7.327816,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.318678,7.327816",
    "IsOpen24Hours": true,
    "id": "49.318678,7.327816"
  },
  {
    "ID": 3071,
    "Tankstellennummer": "0FJ02",
    "Name": "Aral Tankstelle Meckesheim, Zuzenhaeuser Str. 2",
    "Address": "Zuzenhaeuser Straße 2",
    "Address__1": "",
    "City": "Meckesheim",
    "Postcode": 74909,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6226990562,
    "24 hour?": "FALSE",
    "Latitude": 49.319861,
    "Longitude": 8.814378,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.319861,8.814378",
    "IsOpen24Hours": false,
    "id": "49.319861,8.814378"
  },
  {
    "ID": 1880225696,
    "Tankstellennummer": 2251,
    "Name": "Agip Betriebstankstelle",
    "Address": "Am Hockheimring, Fahrerlager 3",
    "Address__1": "",
    "City": "Hockenheim",
    "Postcode": 68766,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "06205/950173",
    "24 hour?": "FALSE",
    "Latitude": 49.32117,
    "Longitude": 8.56822,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.32117,8.56822",
    "IsOpen24Hours": false,
    "id": "49.32117,8.56822"
  },
  {
    "ID": 1328754853,
    "Tankstellennummer": "0FP55",
    "Name": "Aral TankStelle Möckmühl, Züttlinger Straße 5",
    "Address": "Züttlinger Straße 5",
    "Address__1": "",
    "City": "Möckmühl",
    "Postcode": 74219,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62982509,
    "24 hour?": "FALSE",
    "Latitude": 49.32193,
    "Longitude": 9.363825,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.32193,9.363825",
    "IsOpen24Hours": false,
    "id": "49.32193,9.363825"
  },
  {
    "ID": 1795,
    "Tankstellennummer": "0F769",
    "Name": "Aral Tankstelle Saarlouis, Bahnhofstr.20",
    "Address": "Bahnhofstraße 22",
    "Address__1": "",
    "City": "Saarlouis",
    "Postcode": 66740,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 683181203,
    "24 hour?": "TRUE",
    "Latitude": 49.325216,
    "Longitude": 6.757746,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.325216,6.757746",
    "IsOpen24Hours": true,
    "id": "49.325216,6.757746"
  },
  {
    "ID": 1728,
    "Tankstellennummer": "0F719",
    "Name": "Aral Tankstelle Speyer, Bahnhofstr. 116",
    "Address": "Bahnhofstraße 116",
    "Address__1": "",
    "City": "Speyer",
    "Postcode": 67346,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 623276218,
    "24 hour?": "TRUE",
    "Latitude": 49.32727,
    "Longitude": 8.429358,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.32727,8.429358",
    "IsOpen24Hours": true,
    "id": "49.32727,8.429358"
  },
  {
    "ID": 1786,
    "Tankstellennummer": "0F763",
    "Name": "Aral Tankstelle Quierschied, Glashüttenstrasse 4",
    "Address": "Glashüttenstraße 4",
    "Address__1": "",
    "City": "Quierschied",
    "Postcode": 66287,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 689761902,
    "24 hour?": "FALSE",
    "Latitude": 49.329715,
    "Longitude": 7.056714,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.329715,7.056714",
    "IsOpen24Hours": false,
    "id": "49.329715,7.056714"
  },
  {
    "ID": 998,
    "Tankstellennummer": "0F183",
    "Name": "Aral Tankstelle Hockenheim, Mannheimer Str. 1",
    "Address": "Mannheimer Straße 1",
    "Address__1": "",
    "City": "Hockenheim",
    "Postcode": 68766,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6205289123,
    "24 hour?": "TRUE",
    "Latitude": 49.330458,
    "Longitude": 8.536052,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.330458,8.536052",
    "IsOpen24Hours": true,
    "id": "49.330458,8.536052"
  },
  {
    "ID": 2320,
    "Tankstellennummer": "0F011",
    "Name": "Aral Tankstelle Heilsbronn, Ansbacher Strasse 35",
    "Address": "Ansbacher Straße 35",
    "Address__1": "",
    "City": "Heilsbronn",
    "Postcode": 91560,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9872955750,
    "24 hour?": "FALSE",
    "Latitude": 49.334026,
    "Longitude": 10.782558,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.334026,10.782558",
    "IsOpen24Hours": false,
    "id": "49.334026,10.782558"
  },
  {
    "ID": 966,
    "Tankstellennummer": "0F155",
    "Name": "Aral Tankstelle Mosbach, An der B 27/B292",
    "Address": "An der B 27/B292",
    "Address__1": "",
    "City": "Mosbach",
    "Postcode": 74821,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 626191199,
    "24 hour?": "TRUE",
    "Latitude": 49.337732,
    "Longitude": 9.116638,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.337732,9.116638",
    "IsOpen24Hours": true,
    "id": "49.337732,9.116638"
  },
  {
    "ID": 595662251,
    "Tankstellennummer": "TD006676",
    "Name": "SPEYER WORMSER LANDSTR. 255",
    "Address": "WORMSER LANDSTR. 255",
    "Address__1": "",
    "City": "SPEYER",
    "Postcode": 67346,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06232-34051",
    "24 hour?": "FALSE",
    "Latitude": 49.3379,
    "Longitude": 8.4297,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.3379,8.4297",
    "IsOpen24Hours": false,
    "id": "49.3379,8.4297"
  },
  {
    "ID": 992,
    "Tankstellennummer": "0F178",
    "Name": "Aral Tankstelle Heusweiler, Trierer Str. 44-46",
    "Address": "Trierer Straße 44-46",
    "Address__1": "",
    "City": "Heusweiler",
    "Postcode": 66265,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6806609512,
    "24 hour?": "FALSE",
    "Latitude": 49.341224,
    "Longitude": 6.933607,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.341224,6.933607",
    "IsOpen24Hours": false,
    "id": "49.341224,6.933607"
  },
  {
    "ID": 1047466757,
    "Tankstellennummer": "TD002642",
    "Name": "QUIERSCHIED HAUPTSTR. 258",
    "Address": "HAUPTSTR. 258",
    "Address__1": "",
    "City": "QUIERSCHIED",
    "Postcode": 66287,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06825-5306",
    "24 hour?": "FALSE",
    "Latitude": 49.3417,
    "Longitude": 7.0206,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.3417,7.0206",
    "IsOpen24Hours": false,
    "id": "49.3417,7.0206"
  },
  {
    "ID": 1718681955,
    "Tankstellennummer": "TD000748",
    "Name": "HOMBURG-BRUCHHOF KAISERSLAUTER",
    "Address": "KAISERSLAUTERER STR.37",
    "Address__1": "",
    "City": "HOMBURG-BRUCHHOF",
    "Postcode": 66424,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06841-64264",
    "24 hour?": "FALSE",
    "Latitude": 49.343,
    "Longitude": 7.3586,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.343,7.3586",
    "IsOpen24Hours": false,
    "id": "49.343,7.3586"
  },
  {
    "ID": 1332502896,
    "Tankstellennummer": 2113,
    "Name": "Agip Mosbach",
    "Address": "Heidelberger Strasse 49",
    "Address__1": "",
    "City": "Mosbach",
    "Postcode": 74821,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62617767",
    "24 hour?": "FALSE",
    "Latitude": 49.34331178,
    "Longitude": 9.10529611,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.34331178,9.10529611",
    "IsOpen24Hours": false,
    "id": "49.34331178,9.10529611"
  },
  {
    "ID": 1182394551,
    "Tankstellennummer": "TD039115",
    "Name": "MOSBACH-NECKARELZ HEIDELBERGER",
    "Address": "HEIDELBERGER STR. 27",
    "Address__1": "",
    "City": "MOSBACH-NECKARELZ",
    "Postcode": 74821,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06261-6752532",
    "24 hour?": "FALSE",
    "Latitude": 49.3435,
    "Longitude": 9.1055,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.3435,9.1055",
    "IsOpen24Hours": false,
    "id": "49.3435,9.1055"
  },
  {
    "ID": 959,
    "Tankstellennummer": "0F149",
    "Name": "Aral Tankstelle Neunkirchen, Königstr. 32",
    "Address": "Königstraße 32",
    "Address__1": "",
    "City": "Neunkirchen",
    "Postcode": 66538,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6821140910,
    "24 hour?": "TRUE",
    "Latitude": 49.344607,
    "Longitude": 7.174258,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.344607,7.174258",
    "IsOpen24Hours": true,
    "id": "49.344607,7.174258"
  },
  {
    "ID": 1070841718,
    "Tankstellennummer": 3103,
    "Name": "Agip Wendelstein",
    "Address": "Rangaustr. 4A",
    "Address__1": "",
    "City": "Wendelstein",
    "Postcode": 90530,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91298929",
    "24 hour?": "FALSE",
    "Latitude": 49.34587,
    "Longitude": 11.11118,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.34587,11.11118",
    "IsOpen24Hours": false,
    "id": "49.34587,11.11118"
  },
  {
    "ID": 1773,
    "Tankstellennummer": "0F751",
    "Name": "Aral Tankstelle Sandhausen, Heidelberger Strasse 9",
    "Address": "Heidelberger Straße 9",
    "Address__1": "",
    "City": "Sandhausen",
    "Postcode": 69207,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62243159,
    "24 hour?": "FALSE",
    "Latitude": 49.345916,
    "Longitude": 8.660079,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.345916,8.660079",
    "IsOpen24Hours": false,
    "id": "49.345916,8.660079"
  },
  {
    "ID": 309252559,
    "Tankstellennummer": "TD036707",
    "Name": "NEUNBURG V.W. AMBERGER STRASSE",
    "Address": "AMBERGER STRASSE 37",
    "Address__1": "",
    "City": "NEUNBURG V.W.",
    "Postcode": 92431,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09672-3493",
    "24 hour?": "FALSE",
    "Latitude": 49.3461,
    "Longitude": 12.3736,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.3461,12.3736",
    "IsOpen24Hours": false,
    "id": "49.3461,12.3736"
  },
  {
    "ID": 756848474,
    "Tankstellennummer": "TD190830",
    "Name": "BEXBACH KLEINOTTWEILER STR. 30",
    "Address": "KLEINOTTWEILER STR.30",
    "Address__1": "",
    "City": "BEXBACH",
    "Postcode": 66450,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06826-2415",
    "24 hour?": "FALSE",
    "Latitude": 49.3487,
    "Longitude": 7.2642,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.3487,7.2642",
    "IsOpen24Hours": false,
    "id": "49.3487,7.2642"
  },
  {
    "ID": 1778,
    "Tankstellennummer": "0F756",
    "Name": "Aral Tankstelle Neustadt, Branchweilerhofstr.",
    "Address": "Branchweilerhofstraße 104",
    "Address__1": "",
    "City": "Neustadt",
    "Postcode": 67433,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 632112320,
    "24 hour?": "FALSE",
    "Latitude": 49.35071,
    "Longitude": 8.163982,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.35071,8.163982",
    "IsOpen24Hours": false,
    "id": "49.35071,8.163982"
  },
  {
    "ID": 3089,
    "Tankstellennummer": "0FJ18",
    "Name": "Aral Tankstelle Merchweiler, Hauptstr. 243",
    "Address": "Hauptstraße 243",
    "Address__1": "",
    "City": "Merchweiler",
    "Postcode": 66589,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68255037,
    "24 hour?": "FALSE",
    "Latitude": 49.351048,
    "Longitude": 7.050832,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.351048,7.050832",
    "IsOpen24Hours": false,
    "id": "49.351048,7.050832"
  },
  {
    "ID": 1405343525,
    "Tankstellennummer": 2174,
    "Name": "Agip Schiffweiler",
    "Address": "Kreisstrasse",
    "Address__1": "",
    "City": "Schiffweiler",
    "Postcode": 66578,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 68219637",
    "24 hour?": "TRUE",
    "Latitude": 49.35391,
    "Longitude": 7.13962,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.35391,7.13962",
    "IsOpen24Hours": true,
    "id": "49.35391,7.13962"
  },
  {
    "ID": 3088,
    "Tankstellennummer": "0FJ17",
    "Name": "Aral Tankstelle Schiffweiler, Kreisstr. 26",
    "Address": "Kreisstraße 26",
    "Address__1": "",
    "City": "Schiffweiler",
    "Postcode": 66578,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6821963739,
    "24 hour?": "TRUE",
    "Latitude": 49.355593,
    "Longitude": 7.129624,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.355593,7.129624",
    "IsOpen24Hours": true,
    "id": "49.355593,7.129624"
  },
  {
    "ID": 3082,
    "Tankstellennummer": "0FJ12",
    "Name": "Aral Tankstelle Dillingen, Merziger Str. 106",
    "Address": "Merziger Straße 106",
    "Address__1": "",
    "City": "Dillingen",
    "Postcode": 66763,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 683171417,
    "24 hour?": "TRUE",
    "Latitude": 49.356689,
    "Longitude": 6.720603,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.356689,6.720603",
    "IsOpen24Hours": true,
    "id": "49.356689,6.720603"
  },
  {
    "ID": 327877460,
    "Tankstellennummer": "TD002220",
    "Name": "KETSCH HOCKENHEIMER STR. 108",
    "Address": "HOCKENHEIMER STR. 108",
    "Address__1": "",
    "City": "KETSCH",
    "Postcode": 68775,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06202-692190",
    "24 hour?": "FALSE",
    "Latitude": 49.3604,
    "Longitude": 8.5269,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.3604,8.5269",
    "IsOpen24Hours": false,
    "id": "49.3604,8.5269"
  },
  {
    "ID": 1787,
    "Tankstellennummer": "0F764",
    "Name": "Aral Tankstelle Rehlingen-Siersburg, Wallerfanger Str. 69",
    "Address": "Wallerfanger Straße 69",
    "Address__1": "",
    "City": "Rehlingen-Siersburg",
    "Postcode": 66780,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68357591,
    "24 hour?": "TRUE",
    "Latitude": 49.365029,
    "Longitude": 6.687149,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.365029,6.687149",
    "IsOpen24Hours": true,
    "id": "49.365029,6.687149"
  },
  {
    "ID": 845013060,
    "Tankstellennummer": "TD006866",
    "Name": "SCHWARZACH HAUPTSTR. 72",
    "Address": "HAUPTSTR. 72",
    "Address__1": "",
    "City": "SCHWARZACH",
    "Postcode": 74869,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06262-6294",
    "24 hour?": "FALSE",
    "Latitude": 49.3666,
    "Longitude": 8.9815,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.3666,8.9815",
    "IsOpen24Hours": false,
    "id": "49.3666,8.9815"
  },
  {
    "ID": 470261560,
    "Tankstellennummer": 3041,
    "Name": "Agip Nuernberg",
    "Address": "Vorjurastrasse 180",
    "Address__1": "",
    "City": "Nuernberg",
    "Postcode": 90453,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91163787",
    "24 hour?": "FALSE",
    "Latitude": 49.36863,
    "Longitude": 11.04559,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.36863,11.04559",
    "IsOpen24Hours": false,
    "id": "49.36863,11.04559"
  },
  {
    "ID": 1876,
    "Tankstellennummer": "0F822",
    "Name": "Aral Tankstelle Heidelberg, Am Götzenberg 13",
    "Address": "Am Götzenberg 13",
    "Address__1": "",
    "City": "Heidelberg",
    "Postcode": 69126,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6221383813,
    "24 hour?": "FALSE",
    "Latitude": 49.370401,
    "Longitude": 8.701226,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.370401,8.701226",
    "IsOpen24Hours": false,
    "id": "49.370401,8.701226"
  },
  {
    "ID": 1545629173,
    "Tankstellennummer": 3113,
    "Name": "Agip Nuernberg",
    "Address": "Radmeisterstrasse 1",
    "Address__1": "",
    "City": "Nuernberg",
    "Postcode": 90455,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91188318",
    "24 hour?": "FALSE",
    "Latitude": 49.37093,
    "Longitude": 11.07774,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.37093,11.07774",
    "IsOpen24Hours": false,
    "id": "49.37093,11.07774"
  },
  {
    "ID": 1936080451,
    "Tankstellennummer": "0FM61",
    "Name": "WALDMOHR",
    "Address": "A6",
    "Address__1": "",
    "City": "Waldmohr",
    "Postcode": 66914,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63737514,
    "24 hour?": "TRUE",
    "Latitude": 49.372808,
    "Longitude": 7.37541,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.372808,7.37541",
    "IsOpen24Hours": true,
    "id": "49.372808,7.37541"
  },
  {
    "ID": 2324,
    "Tankstellennummer": "0FB35",
    "Name": "Aral Tankstelle Rothenburg, Ansbacher Str. 38",
    "Address": "Ansbacher Straße 38",
    "Address__1": "",
    "City": "Rothenburg",
    "Postcode": 91541,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 98616052,
    "24 hour?": "TRUE",
    "Latitude": 49.375102,
    "Longitude": 10.190799,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.375102,10.190799",
    "IsOpen24Hours": true,
    "id": "49.375102,10.190799"
  },
  {
    "ID": 1000,
    "Tankstellennummer": "0FE98",
    "Name": "Aral Tankstelle Schwetzingen, Hockenheimer Landstr. 4",
    "Address": "Hockenheimer Landstraße 4",
    "Address__1": "",
    "City": "Schwetzingen",
    "Postcode": 68723,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6202128091,
    "24 hour?": "TRUE",
    "Latitude": 49.377107,
    "Longitude": 8.560663,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.377107,8.560663",
    "IsOpen24Hours": true,
    "id": "49.377107,8.560663"
  },
  {
    "ID": 688021736,
    "Tankstellennummer": 4093,
    "Name": "Agip Schifferstadt",
    "Address": "Salierstrasse 62",
    "Address__1": "",
    "City": "Schifferstadt",
    "Postcode": 67105,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62359886",
    "24 hour?": "FALSE",
    "Latitude": 49.38307,
    "Longitude": 8.38841,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.38307,8.38841",
    "IsOpen24Hours": false,
    "id": "49.38307,8.38841"
  },
  {
    "ID": 982,
    "Tankstellennummer": "0F168",
    "Name": "Aral Tankstelle Illingen-Uchtelfange, Saarbrücker Str. 175",
    "Address": "Saarbrücker Straße 175",
    "Address__1": "",
    "City": "Illingen-Uchtelfangen",
    "Postcode": 66557,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 682543054,
    "24 hour?": "FALSE",
    "Latitude": 49.383264,
    "Longitude": 7.011543,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.383264,7.011543",
    "IsOpen24Hours": false,
    "id": "49.383264,7.011543"
  },
  {
    "ID": 2382,
    "Tankstellennummer": "0FB78",
    "Name": "Aral Tankstelle Altdorf, Nürnberger Str.25",
    "Address": "Nürnberger Straße 25",
    "Address__1": "",
    "City": "Altdorf",
    "Postcode": 90518,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 91871515,
    "24 hour?": "TRUE",
    "Latitude": 49.383969,
    "Longitude": 11.34805,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.383969,11.34805",
    "IsOpen24Hours": true,
    "id": "49.383969,11.34805"
  },
  {
    "ID": 3128,
    "Tankstellennummer": "0FJ45",
    "Name": "Aral Tankstelle Altdorf, Nuernberger Str. 21-23",
    "Address": "Nuernberger Straße 23",
    "Address__1": "",
    "City": "Altdorf",
    "Postcode": 90518,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 918790180,
    "24 hour?": "FALSE",
    "Latitude": 49.384428,
    "Longitude": 11.348847,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.384428,11.348847",
    "IsOpen24Hours": false,
    "id": "49.384428,11.348847"
  },
  {
    "ID": 538551622,
    "Tankstellennummer": 2016,
    "Name": "Agip Heidelberg",
    "Address": "Schwetzinger Strasse 138",
    "Address__1": "",
    "City": "Heidelberg",
    "Postcode": 69124,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62217855",
    "24 hour?": "TRUE",
    "Latitude": 49.38494,
    "Longitude": 8.67295,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.38494,8.67295",
    "IsOpen24Hours": true,
    "id": "49.38494,8.67295"
  },
  {
    "ID": 2470,
    "Tankstellennummer": "0FC29",
    "Name": "Aral Tankstelle Schwarzenfeld, Amberger Str. 31",
    "Address": "Amberger Straße 31",
    "Address__1": "",
    "City": "Schwarzenfeld",
    "Postcode": 92521,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9435501210,
    "24 hour?": "FALSE",
    "Latitude": 49.388114,
    "Longitude": 12.134007,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.388114,12.134007",
    "IsOpen24Hours": false,
    "id": "49.388114,12.134007"
  },
  {
    "ID": 1617456655,
    "Tankstellennummer": "TD001529",
    "Name": "ELZTAL HAUPTSTR. 26",
    "Address": "HAUPTSTR. 26",
    "Address__1": "",
    "City": "ELZTAL",
    "Postcode": 74834,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06261-893159",
    "24 hour?": "FALSE",
    "Latitude": 49.3886,
    "Longitude": 9.1932,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.3886,9.1932",
    "IsOpen24Hours": false,
    "id": "49.3886,9.1932"
  },
  {
    "ID": 1873,
    "Tankstellennummer": "0F820",
    "Name": "Aral Tankstelle Bruchmühlbach-Miesau, Spies-Str.2-4",
    "Address": "Spießstraße 2",
    "Address__1": "",
    "City": "Bruchmühlbach-Miesau",
    "Postcode": 66892,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6372993290,
    "24 hour?": "TRUE",
    "Latitude": 49.392177,
    "Longitude": 7.437914,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.392177,7.437914",
    "IsOpen24Hours": true,
    "id": "49.392177,7.437914"
  },
  {
    "ID": 1064680612,
    "Tankstellennummer": "0FV30",
    "Name": "NECKARGEMUND",
    "Address": "Bahnhofstraße 79-81",
    "Address__1": "",
    "City": "Neckargemünd",
    "Postcode": 69151,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62239725144,
    "24 hour?": "FALSE",
    "Latitude": 49.392458,
    "Longitude": 8.779826,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.392458,8.779826",
    "IsOpen24Hours": false,
    "id": "49.392458,8.779826"
  },
  {
    "ID": 1926249377,
    "Tankstellennummer": 2021,
    "Name": "Agip Neckargemuend",
    "Address": "Neckarsteinacher Str. 21",
    "Address__1": "",
    "City": "Neckargemuend",
    "Postcode": 69151,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62232715",
    "24 hour?": "FALSE",
    "Latitude": 49.39578334,
    "Longitude": 8.80907417,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.39578334,8.80907417",
    "IsOpen24Hours": false,
    "id": "49.39578334,8.80907417"
  },
  {
    "ID": 3473,
    "Tankstellennummer": "0FL92",
    "Name": "Aral Tankstelle Eppelheim, Schwetzinger Str. 4",
    "Address": "Schwetzinger Straße 4",
    "Address__1": "",
    "City": "Eppelheim",
    "Postcode": 69214,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6221763291,
    "24 hour?": "FALSE",
    "Latitude": 49.39913,
    "Longitude": 8.624707,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.39913,8.624707",
    "IsOpen24Hours": false,
    "id": "49.39913,8.624707"
  },
  {
    "ID": 2416,
    "Tankstellennummer": "0FB95",
    "Name": "Aral Tankstelle Nürnberg, Georg-Ledebour-Str. 4",
    "Address": "Georg-Ledebour-Straße 4",
    "Address__1": "",
    "City": "Nürnberg",
    "Postcode": 90473,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 91137856383,
    "24 hour?": "TRUE",
    "Latitude": 49.401334,
    "Longitude": 11.150818,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.401334,11.150818",
    "IsOpen24Hours": true,
    "id": "49.401334,11.150818"
  },
  {
    "ID": 3138,
    "Tankstellennummer": "0FJ53",
    "Name": "Aral Tankstelle Nürnberg, Muenchener Str. 401",
    "Address": "Münchener Straße 401",
    "Address__1": "",
    "City": "Nürnberg",
    "Postcode": 90471,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 911806266,
    "24 hour?": "TRUE",
    "Latitude": 49.401853,
    "Longitude": 11.115467,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.401853,11.115467",
    "IsOpen24Hours": true,
    "id": "49.401853,11.115467"
  },
  {
    "ID": 778931762,
    "Tankstellennummer": "0FJ54",
    "Name": "Aral TankStelle Nürnberg, Münchener Straße 400",
    "Address": "Münchener Straße 400",
    "Address__1": "",
    "City": "Nürnberg",
    "Postcode": 90471,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 911805503,
    "24 hour?": "TRUE",
    "Latitude": 49.402082,
    "Longitude": 11.114833,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.402082,11.114833",
    "IsOpen24Hours": true,
    "id": "49.402082,11.114833"
  },
  {
    "ID": 3424,
    "Tankstellennummer": "0FL59",
    "Name": "Aral Tankstelle Lebach, Saarbruecker Str. 51",
    "Address": "Saarbruecker Straße 51",
    "Address__1": "",
    "City": "Lebach",
    "Postcode": 66822,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68813253,
    "24 hour?": "TRUE",
    "Latitude": 49.402546,
    "Longitude": 6.916348,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.402546,6.916348",
    "IsOpen24Hours": true,
    "id": "49.402546,6.916348"
  },
  {
    "ID": 593837908,
    "Tankstellennummer": "TD191710",
    "Name": "HEIDELBERG RINGSTR. 12",
    "Address": "RINGSTR. 12",
    "Address__1": "",
    "City": "HEIDELBERG",
    "Postcode": 69115,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06221-23773",
    "24 hour?": "FALSE",
    "Latitude": 49.4043,
    "Longitude": 8.6826,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4043,8.6826",
    "IsOpen24Hours": false,
    "id": "49.4043,8.6826"
  },
  {
    "ID": 583199809,
    "Tankstellennummer": "TD038844",
    "Name": "HEIDELBERG CZERNYRING 1",
    "Address": "CZERNYRING 1",
    "Address__1": "",
    "City": "HEIDELBERG",
    "Postcode": 69115,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06221-29888",
    "24 hour?": "FALSE",
    "Latitude": 49.4074,
    "Longitude": 8.6723,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4074,8.6723",
    "IsOpen24Hours": false,
    "id": "49.4074,8.6723"
  },
  {
    "ID": 1733,
    "Tankstellennummer": "0F722",
    "Name": "Aral Tankstelle Heidelberg, Bergheimer Str. 90",
    "Address": "Bergheimer Straße 90",
    "Address__1": "",
    "City": "Heidelberg",
    "Postcode": 69115,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6221163048,
    "24 hour?": "TRUE",
    "Latitude": 49.408024,
    "Longitude": 8.67828,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.408024,8.67828",
    "IsOpen24Hours": true,
    "id": "49.408024,8.67828"
  },
  {
    "ID": 1794,
    "Tankstellennummer": "0F768",
    "Name": "Aral Tankstelle Beckingen, Haustadter-Tal-Str.110",
    "Address": "Haustadter-Tal-Straße 110",
    "Address__1": "",
    "City": "Beckingen",
    "Postcode": 66701,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68357569,
    "24 hour?": "FALSE",
    "Latitude": 49.409617,
    "Longitude": 6.717799,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.409617,6.717799",
    "IsOpen24Hours": false,
    "id": "49.409617,6.717799"
  },
  {
    "ID": 680572042,
    "Tankstellennummer": "TD039008",
    "Name": "DANNSTADT BAT 61 / WESTSEITE",
    "Address": "BAB WESTSEITE / A 61",
    "Address__1": "",
    "City": "DANNSTADT-SCHAUERNHEIM",
    "Postcode": 67125,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06231-2542",
    "24 hour?": "FALSE",
    "Latitude": 49.4103,
    "Longitude": 8.34,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4103,8.34",
    "IsOpen24Hours": false,
    "id": "49.4103,8.34"
  },
  {
    "ID": 828452193,
    "Tankstellennummer": "TD171350",
    "Name": "ALFELD AUTOHOF VOGELHERD 4",
    "Address": "AUTOHOF VOGELHERD 4",
    "Address__1": "",
    "City": "ALFELD",
    "Postcode": 91236,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09157-95170",
    "24 hour?": "FALSE",
    "Latitude": 49.4104,
    "Longitude": 11.5448,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4104,11.5448",
    "IsOpen24Hours": false,
    "id": "49.4104,11.5448"
  },
  {
    "ID": 1121353574,
    "Tankstellennummer": 3017,
    "Name": "Agip Nuernberg",
    "Address": "Loewenberger Strasse 135",
    "Address__1": "",
    "City": "Nuernberg",
    "Postcode": 90475,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91183475",
    "24 hour?": "FALSE",
    "Latitude": 49.41112,
    "Longitude": 11.17117,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.41112,11.17117",
    "IsOpen24Hours": false,
    "id": "49.41112,11.17117"
  },
  {
    "ID": 646770605,
    "Tankstellennummer": "TD007385",
    "Name": "LEBACH HEERESSTR. 27",
    "Address": "HEERESSTR. 27",
    "Address__1": "",
    "City": "LEBACH",
    "Postcode": 66822,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06881-52861",
    "24 hour?": "FALSE",
    "Latitude": 49.4126,
    "Longitude": 6.8941,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4126,6.8941",
    "IsOpen24Hours": false,
    "id": "49.4126,6.8941"
  },
  {
    "ID": 1757,
    "Tankstellennummer": "0F742",
    "Name": "Aral Tankstelle Landstuhl, Kaiserstr.110",
    "Address": "Kaiserstraße 110",
    "Address__1": "",
    "City": "Landstuhl",
    "Postcode": 66849,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 637112115,
    "24 hour?": "FALSE",
    "Latitude": 49.414005,
    "Longitude": 7.582463,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.414005,7.582463",
    "IsOpen24Hours": false,
    "id": "49.414005,7.582463"
  },
  {
    "ID": 3085,
    "Tankstellennummer": "0FJ14",
    "Name": "Aral Tankstelle Schmelz, Primsweiler Str. 9",
    "Address": "Primsweiler Straße 9",
    "Address__1": "",
    "City": "Schmelz",
    "Postcode": 66839,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68877474,
    "24 hour?": "FALSE",
    "Latitude": 49.418601,
    "Longitude": 6.845031,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.418601,6.845031",
    "IsOpen24Hours": false,
    "id": "49.418601,6.845031"
  },
  {
    "ID": 373047468,
    "Tankstellennummer": 3060,
    "Name": "Agip Nuernberg",
    "Address": "Ansbacher Strasse 102",
    "Address__1": "",
    "City": "Nuernberg",
    "Postcode": 90449,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91167656",
    "24 hour?": "FALSE",
    "Latitude": 49.42069,
    "Longitude": 11.03272,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.42069,11.03272",
    "IsOpen24Hours": false,
    "id": "49.42069,11.03272"
  },
  {
    "ID": 3084,
    "Tankstellennummer": "0FJ13",
    "Name": "Aral Tankstelle Kaiserslautern, Im Erfenbacher Tal 15",
    "Address": "Im Erfenbacher Tal 15",
    "Address__1": "",
    "City": "Kaiserslautern",
    "Postcode": 67661,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6313503528,
    "24 hour?": "FALSE",
    "Latitude": 49.421475,
    "Longitude": 7.703652,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.421475,7.703652",
    "IsOpen24Hours": false,
    "id": "49.421475,7.703652"
  },
  {
    "ID": 1842147766,
    "Tankstellennummer": "TD000757",
    "Name": "MANNHEIM EDINGER RIEDWEG 8-12",
    "Address": "EDINGER RIEDWEG 8-12",
    "Address__1": "",
    "City": "MANNHEIM",
    "Postcode": 68219,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0621-8019209",
    "24 hour?": "FALSE",
    "Latitude": 49.4218,
    "Longitude": 8.534,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4218,8.534",
    "IsOpen24Hours": false,
    "id": "49.4218,8.534"
  },
  {
    "ID": 1753,
    "Tankstellennummer": "0F738",
    "Name": "Aral Tankstelle Limburgerhof, Speyerer-Straße 105 a",
    "Address": "Speyerer-Straße 105 a",
    "Address__1": "",
    "City": "Limburgerhof",
    "Postcode": 67117,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 623667805,
    "24 hour?": "FALSE",
    "Latitude": 49.421961,
    "Longitude": 8.394073,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.421961,8.394073",
    "IsOpen24Hours": false,
    "id": "49.421961,8.394073"
  },
  {
    "ID": 1362238728,
    "Tankstellennummer": "0FV99",
    "Name": "ILLSCHWANG",
    "Address": "A6",
    "Address__1": "",
    "City": "Illschwang",
    "Postcode": 92278,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9666951680,
    "24 hour?": "TRUE",
    "Latitude": 49.4237,
    "Longitude": 11.68635,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.4237,11.68635",
    "IsOpen24Hours": true,
    "id": "49.4237,11.68635"
  },
  {
    "ID": 601879300,
    "Tankstellennummer": "TD002568",
    "Name": "OTTWEILER DOERRENBACHER STR. 2",
    "Address": "DOERRENBACHER STR. 22",
    "Address__1": "",
    "City": "OTTWEILER",
    "Postcode": 66564,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06858-332",
    "24 hour?": "FALSE",
    "Latitude": 49.424,
    "Longitude": 7.2361,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.424,7.2361",
    "IsOpen24Hours": false,
    "id": "49.424,7.2361"
  },
  {
    "ID": 1402851976,
    "Tankstellennummer": "TD000863",
    "Name": "HEIDELBERG GRENZHOEFER WEG 35",
    "Address": "GRENZHOEFER WEG 35",
    "Address__1": "",
    "City": "HEIDELBERG",
    "Postcode": 69123,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06221-705929",
    "24 hour?": "FALSE",
    "Latitude": 49.4241,
    "Longitude": 8.6385,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4241,8.6385",
    "IsOpen24Hours": false,
    "id": "49.4241,8.6385"
  },
  {
    "ID": 1928540247,
    "Tankstellennummer": "TD041418",
    "Name": "OSTERBURKEN MERCHINGER STR.1",
    "Address": "MERCHINGER STR.1",
    "Address__1": "",
    "City": "OSTERBURKEN",
    "Postcode": 74706,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 629164210,
    "24 hour?": "FALSE",
    "Latitude": 49.4279,
    "Longitude": 9.4271,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4279,9.4271",
    "IsOpen24Hours": false,
    "id": "49.4279,9.4271"
  },
  {
    "ID": 2393,
    "Tankstellennummer": "0F015",
    "Name": "Aral Tankstelle Nürnberg, Schweinauer Hauptstr. 92",
    "Address": "Schweinauer Hauptstr. 92",
    "Address__1": "",
    "City": "Nürnberg",
    "Postcode": 90441,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9116266537,
    "24 hour?": "TRUE",
    "Latitude": 49.429339,
    "Longitude": 11.043851,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.429339,11.043851",
    "IsOpen24Hours": true,
    "id": "49.429339,11.043851"
  },
  {
    "ID": 3057,
    "Tankstellennummer": "0FI92",
    "Name": "Aral Tankstelle Heidelberg, Mannheimer Str. 300-302",
    "Address": "Mannheimer Straße 300-302",
    "Address__1": "",
    "City": "Heidelberg",
    "Postcode": 69123,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6221831614,
    "24 hour?": "FALSE",
    "Latitude": 49.429585,
    "Longitude": 8.645405,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.429585,8.645405",
    "IsOpen24Hours": false,
    "id": "49.429585,8.645405"
  },
  {
    "ID": 2394,
    "Tankstellennummer": "0F014",
    "Name": "Aral Tankstelle Nürnberg, Ulmenstr. 31",
    "Address": "Ulmenstraße 31",
    "Address__1": "",
    "City": "Nürnberg",
    "Postcode": 90443,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 911414155,
    "24 hour?": "TRUE",
    "Latitude": 49.429776,
    "Longitude": 11.070766,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.429776,11.070766",
    "IsOpen24Hours": true,
    "id": "49.429776,11.070766"
  },
  {
    "ID": 1749,
    "Tankstellennummer": "0F735",
    "Name": "Aral Tankstelle Schönau, Neckarsteinacher Str.",
    "Address": "Neckarsteinacher Str. 42",
    "Address__1": "",
    "City": "Schönau",
    "Postcode": 69250,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6228913878,
    "24 hour?": "FALSE",
    "Latitude": 49.429992,
    "Longitude": 8.812239,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.429992,8.812239",
    "IsOpen24Hours": false,
    "id": "49.429992,8.812239"
  },
  {
    "ID": 3052,
    "Tankstellennummer": "0FI87",
    "Name": "Aral Tankstelle Dannstadt-Schauernheim, Am Rathausplatz 22",
    "Address": "Am Rathausplatz 22",
    "Address__1": "",
    "City": "Dannstadt-Schauernheim",
    "Postcode": 67125,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62319396510,
    "24 hour?": "FALSE",
    "Latitude": 49.431867,
    "Longitude": 8.309247,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.431867,8.309247",
    "IsOpen24Hours": false,
    "id": "49.431867,8.309247"
  },
  {
    "ID": 503101077,
    "Tankstellennummer": 4149,
    "Name": "Agip Kaiserslautern",
    "Address": "Kantstrasse 64",
    "Address__1": "",
    "City": "Kaiserslautern",
    "Postcode": 67663,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 63131152",
    "24 hour?": "FALSE",
    "Latitude": 49.43216,
    "Longitude": 7.78105,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.43216,7.78105",
    "IsOpen24Hours": false,
    "id": "49.43216,7.78105"
  },
  {
    "ID": 35818742,
    "Tankstellennummer": 4232,
    "Name": "Agip Kaiserslautern",
    "Address": "Brandenburger Strasse 1",
    "Address__1": "",
    "City": "Kaiserslautern",
    "Postcode": 67663,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 63130395",
    "24 hour?": "TRUE",
    "Latitude": 49.43350156,
    "Longitude": 7.7502241,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.43350156,7.7502241",
    "IsOpen24Hours": true,
    "id": "49.43350156,7.7502241"
  },
  {
    "ID": 1762,
    "Tankstellennummer": "0F745",
    "Name": "Aral Tankstelle Kaiserslautern, Trippstadter Str. 69",
    "Address": "Trippstadter Straße 69",
    "Address__1": "",
    "City": "Kaiserslautern",
    "Postcode": 67663,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63128680,
    "24 hour?": "TRUE",
    "Latitude": 49.433753,
    "Longitude": 7.757465,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.433753,7.757465",
    "IsOpen24Hours": true,
    "id": "49.433753,7.757465"
  },
  {
    "ID": 1019175072,
    "Tankstellennummer": "0FN48",
    "Name": "ALTRIP",
    "Address": "Bezirksstraße 16",
    "Address__1": "",
    "City": "Altrip",
    "Postcode": 67122,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62362685,
    "24 hour?": "FALSE",
    "Latitude": 49.434966,
    "Longitude": 8.484531,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.434966,8.484531",
    "IsOpen24Hours": false,
    "id": "49.434966,8.484531"
  },
  {
    "ID": 1049418602,
    "Tankstellennummer": "TD000860",
    "Name": "KAISERSLAUTERN KOENIGSTR. 131",
    "Address": "KOENIGSTR. 131",
    "Address__1": "",
    "City": "KAISERSLAUTERN",
    "Postcode": 67655,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0631-3115316",
    "24 hour?": "FALSE",
    "Latitude": 49.4351,
    "Longitude": 7.7537,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4351,7.7537",
    "IsOpen24Hours": false,
    "id": "49.4351,7.7537"
  },
  {
    "ID": 268452325,
    "Tankstellennummer": 3003,
    "Name": "Agip Oberasbach",
    "Address": "Rothenburger Strasse 32",
    "Address__1": "",
    "City": "Oberasbach",
    "Postcode": 90522,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91169221",
    "24 hour?": "FALSE",
    "Latitude": 49.43594,
    "Longitude": 10.97738,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.43594,10.97738",
    "IsOpen24Hours": false,
    "id": "49.43594,10.97738"
  },
  {
    "ID": 2401,
    "Tankstellennummer": "0FB87",
    "Name": "Aral Tankstelle Nürnberg, Wodanstr. 27",
    "Address": "Wodanstraße 27",
    "Address__1": "",
    "City": "Nürnberg",
    "Postcode": 90461,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 911460780,
    "24 hour?": "TRUE",
    "Latitude": 49.437191,
    "Longitude": 11.094202,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.437191,11.094202",
    "IsOpen24Hours": true,
    "id": "49.437191,11.094202"
  },
  {
    "ID": 1495097951,
    "Tankstellennummer": 3098,
    "Name": "Agip Nuernberg",
    "Address": "Wallensteinstrasse 90",
    "Address__1": "",
    "City": "Nuernberg",
    "Postcode": 90431,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91161422",
    "24 hour?": "FALSE",
    "Latitude": 49.4375,
    "Longitude": 11.0314,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4375,11.0314",
    "IsOpen24Hours": false,
    "id": "49.4375,11.0314"
  },
  {
    "ID": 2331,
    "Tankstellennummer": "0FB41",
    "Name": "Aral Tankstelle Amberg, Regensburger Str.70 a",
    "Address": "Regensburger Straße70 a",
    "Address__1": "",
    "City": "Amberg",
    "Postcode": 92224,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 962183744,
    "24 hour?": "TRUE",
    "Latitude": 49.437984,
    "Longitude": 11.878823,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.437984,11.878823",
    "IsOpen24Hours": true,
    "id": "49.437984,11.878823"
  },
  {
    "ID": 1756,
    "Tankstellennummer": "0F741",
    "Name": "Aral Tankstelle Mannheim, Relaisstr. 1-3",
    "Address": "Relaisstraße 1-3",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68219,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621891606,
    "24 hour?": "TRUE",
    "Latitude": 49.438393,
    "Longitude": 8.523771,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.438393,8.523771",
    "IsOpen24Hours": true,
    "id": "49.438393,8.523771"
  },
  {
    "ID": 745602378,
    "Tankstellennummer": 3069,
    "Name": "Agip Amberg",
    "Address": "Regensburger Strasse 68/A",
    "Address__1": "",
    "City": "Amberg",
    "Postcode": 92224,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 96217814",
    "24 hour?": "FALSE",
    "Latitude": 49.43973,
    "Longitude": 11.8771,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.43973,11.8771",
    "IsOpen24Hours": false,
    "id": "49.43973,11.8771"
  },
  {
    "ID": 2363,
    "Tankstellennummer": "0FB65",
    "Name": "Aral Tankstelle Amberg, Nürnberger Str.2",
    "Address": "Nürnberger Straße2",
    "Address__1": "",
    "City": "Amberg",
    "Postcode": 92224,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9621320040,
    "24 hour?": "TRUE",
    "Latitude": 49.444489,
    "Longitude": 11.840918,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.444489,11.840918",
    "IsOpen24Hours": true,
    "id": "49.444489,11.840918"
  },
  {
    "ID": 1917876534,
    "Tankstellennummer": "0FV02",
    "Name": "STEINSFELD",
    "Address": "Baukreativstraße 7",
    "Address__1": "",
    "City": "Steinsfeld-Endsee",
    "Postcode": 91628,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "TRUE",
    "Latitude": 49.44466,
    "Longitude": 10.250931,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.44466,10.250931",
    "IsOpen24Hours": true,
    "id": "49.44466,10.250931"
  },
  {
    "ID": 3457,
    "Tankstellennummer": "0FL80",
    "Name": "Aral Tankstelle Edingen-Neckarhausen, Trautenfeldstr. 38",
    "Address": "Trautenfeldstraße 38",
    "Address__1": "",
    "City": "Edingen-Neckarhausen",
    "Postcode": 68535,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621471739,
    "24 hour?": "FALSE",
    "Latitude": 49.445287,
    "Longitude": 8.58033,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.445287,8.58033",
    "IsOpen24Hours": false,
    "id": "49.445287,8.58033"
  },
  {
    "ID": 673338222,
    "Tankstellennummer": 3102,
    "Name": "Agip Amberg",
    "Address": "Nuernberger Strasse 2A",
    "Address__1": "",
    "City": "Amberg",
    "Postcode": 92224,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 96212247",
    "24 hour?": "FALSE",
    "Latitude": 49.44568314,
    "Longitude": 11.83479183,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.44568314,11.83479183",
    "IsOpen24Hours": false,
    "id": "49.44568314,11.83479183"
  },
  {
    "ID": 653675692,
    "Tankstellennummer": 3146,
    "Name": "Agip Nuernberg",
    "Address": "Witschelstr. 91",
    "Address__1": "",
    "City": "Nuernberg",
    "Postcode": 90431,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91194062",
    "24 hour?": "TRUE",
    "Latitude": 49.44781,
    "Longitude": 11.03898,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.44781,11.03898",
    "IsOpen24Hours": true,
    "id": "49.44781,11.03898"
  },
  {
    "ID": 906437985,
    "Tankstellennummer": 3029,
    "Name": "Agip Zirndorf",
    "Address": "Fuerther Strasse 51",
    "Address__1": "",
    "City": "Zirndorf",
    "Postcode": 90513,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91160448",
    "24 hour?": "FALSE",
    "Latitude": 49.44864858,
    "Longitude": 10.96262984,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.44864858,10.96262984",
    "IsOpen24Hours": false,
    "id": "49.44864858,10.96262984"
  },
  {
    "ID": 650345162,
    "Tankstellennummer": 3014,
    "Name": "Agip Fuerth",
    "Address": "Hoefener Strasse 44",
    "Address__1": "",
    "City": "Fuerth",
    "Postcode": 90763,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91170571",
    "24 hour?": "FALSE",
    "Latitude": 49.448965,
    "Longitude": 11.00043,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.448965,11.00043",
    "IsOpen24Hours": false,
    "id": "49.448965,11.00043"
  },
  {
    "ID": 489989040,
    "Tankstellennummer": "TD035949",
    "Name": "LUDWIGSHAFEN HAUPTSTR. 257",
    "Address": "HAUPTSTR. 257",
    "Address__1": "",
    "City": "LUDWIGSHAFEN",
    "Postcode": 67065,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0621-542476",
    "24 hour?": "FALSE",
    "Latitude": 49.4502,
    "Longitude": 8.4235,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4502,8.4235",
    "IsOpen24Hours": false,
    "id": "49.4502,8.4235"
  },
  {
    "ID": 676348367,
    "Tankstellennummer": "TD000440",
    "Name": "LUDWIGSHAFEN-MAUDACH BERGSTR.",
    "Address": "34 BERGSTR.",
    "Address__1": "",
    "City": "LUDWIGSHAFEN-MAUDACH",
    "Postcode": 67067,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0621-553424",
    "24 hour?": "FALSE",
    "Latitude": 49.4534,
    "Longitude": 8.3755,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4534,8.3755",
    "IsOpen24Hours": false,
    "id": "49.4534,8.3755"
  },
  {
    "ID": 969,
    "Tankstellennummer": "0F158",
    "Name": "Aral Tankstelle Kaiserslautern, Mainzer Straße 117",
    "Address": "Mainzer Straße 117",
    "Address__1": "",
    "City": "Kaiserslautern",
    "Postcode": 67657,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63143688,
    "24 hour?": "TRUE",
    "Latitude": 49.455755,
    "Longitude": 7.795879,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.455755,7.795879",
    "IsOpen24Hours": true,
    "id": "49.455755,7.795879"
  },
  {
    "ID": 1784,
    "Tankstellennummer": "0F761",
    "Name": "Aral Tankstelle Marpingen, Alsweiler Str. 50",
    "Address": "Alsweiler Straße 50",
    "Address__1": "",
    "City": "Marpingen",
    "Postcode": 66646,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68535107,
    "24 hour?": "FALSE",
    "Latitude": 49.455796,
    "Longitude": 7.06074,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.455796,7.06074",
    "IsOpen24Hours": false,
    "id": "49.455796,7.06074"
  },
  {
    "ID": 813236520,
    "Tankstellennummer": "TD036715",
    "Name": "NUERNBERG OSTENDSTRASSE 143",
    "Address": "OSTENDSTRASSE 143",
    "Address__1": "",
    "City": "NUERNBERG",
    "Postcode": 90482,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0911-5430061",
    "24 hour?": "FALSE",
    "Latitude": 49.457,
    "Longitude": 11.1215,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.457,11.1215",
    "IsOpen24Hours": false,
    "id": "49.457,11.1215"
  },
  {
    "ID": 537004133,
    "Tankstellennummer": 3609,
    "Name": "Agip Amberg",
    "Address": "Bayreuther Str. 46",
    "Address__1": "",
    "City": "Amberg",
    "Postcode": 92224,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 96216202",
    "24 hour?": "TRUE",
    "Latitude": 49.45707,
    "Longitude": 11.84874,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.45707,11.84874",
    "IsOpen24Hours": true,
    "id": "49.45707,11.84874"
  },
  {
    "ID": 826096773,
    "Tankstellennummer": "0FG11",
    "Name": "Aral TankStelle Ludwigshafen, Wegelnburgstraße 27",
    "Address": "Wegelnburgstraße 27",
    "Address__1": "",
    "City": "Ludwigshafen",
    "Postcode": 67065,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62157240520,
    "24 hour?": "TRUE",
    "Latitude": 49.457106,
    "Longitude": 8.431821,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.457106,8.431821",
    "IsOpen24Hours": true,
    "id": "49.457106,8.431821"
  },
  {
    "ID": 1837093036,
    "Tankstellennummer": "TD035329",
    "Name": "KAISERSLAUTERN MAINZER STR. 14",
    "Address": "MAINZER STR. 140",
    "Address__1": "",
    "City": "KAISERSLAUTERN",
    "Postcode": 67657,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0631-40226",
    "24 hour?": "FALSE",
    "Latitude": 49.4573,
    "Longitude": 7.8015,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4573,7.8015",
    "IsOpen24Hours": false,
    "id": "49.4573,7.8015"
  },
  {
    "ID": 742280853,
    "Tankstellennummer": 3167,
    "Name": "Agip Leinburg",
    "Address": "Gersdorfer Hauptstr. 1",
    "Address__1": "",
    "City": "Leinburg",
    "Postcode": 91227,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "09120/565",
    "24 hour?": "FALSE",
    "Latitude": 49.45836,
    "Longitude": 11.34692,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.45836,11.34692",
    "IsOpen24Hours": false,
    "id": "49.45836,11.34692"
  },
  {
    "ID": 3072,
    "Tankstellennummer": "0FJ03",
    "Name": "Aral Tankstelle Mannheim, Steubenstr. 48",
    "Address": "Steubenstraße 48",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68163,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6218322965,
    "24 hour?": "FALSE",
    "Latitude": 49.460739,
    "Longitude": 8.478127,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.460739,8.478127",
    "IsOpen24Hours": false,
    "id": "49.460739,8.478127"
  },
  {
    "ID": 1620127313,
    "Tankstellennummer": "TD000749",
    "Name": "MANNHEIM SCHWABENSTR. 41",
    "Address": "SCHWABENSTR. 41",
    "Address__1": "",
    "City": "MANNHEIM",
    "Postcode": 68239,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0621-472576",
    "24 hour?": "FALSE",
    "Latitude": 49.4608,
    "Longitude": 8.5697,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4608,8.5697",
    "IsOpen24Hours": false,
    "id": "49.4608,8.5697"
  },
  {
    "ID": 1826446776,
    "Tankstellennummer": 4163,
    "Name": "Agip Bad Duerkheim",
    "Address": "Weinstrasse Nord 57",
    "Address__1": "",
    "City": "Bad Duerkheim",
    "Postcode": 67098,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 63226601",
    "24 hour?": "FALSE",
    "Latitude": 49.46098,
    "Longitude": 8.16238,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.46098,8.16238",
    "IsOpen24Hours": false,
    "id": "49.46098,8.16238"
  },
  {
    "ID": 1785,
    "Tankstellennummer": "0F762",
    "Name": "Aral Tankstelle Schmelz, Trierer-Str.90",
    "Address": "Trierer Straße 90",
    "Address__1": "",
    "City": "Schmelz",
    "Postcode": 66839,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 688788393,
    "24 hour?": "FALSE",
    "Latitude": 49.463,
    "Longitude": 6.851665,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.463,6.851665",
    "IsOpen24Hours": false,
    "id": "49.463,6.851665"
  },
  {
    "ID": 3053,
    "Tankstellennummer": "0FI88",
    "Name": "Aral Tankstelle Bad Dürkheim, Mannheimer Str. 112",
    "Address": "Mannheimer Straße 112",
    "Address__1": "",
    "City": "Bad Dürkheim",
    "Postcode": 67098,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6322943317,
    "24 hour?": "FALSE",
    "Latitude": 49.463229,
    "Longitude": 8.186616,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.463229,8.186616",
    "IsOpen24Hours": false,
    "id": "49.463229,8.186616"
  },
  {
    "ID": 1343820670,
    "Tankstellennummer": "TD039164",
    "Name": "LUDWIGSHAFEN MUNDENHEIMER STR.",
    "Address": "MUNDENHEIMER STR. 74",
    "Address__1": "",
    "City": "LUDWIGSHAFEN",
    "Postcode": 67061,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0621-57240080",
    "24 hour?": "FALSE",
    "Latitude": 49.4638,
    "Longitude": 8.4365,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4638,8.4365",
    "IsOpen24Hours": false,
    "id": "49.4638,8.4365"
  },
  {
    "ID": 2341,
    "Tankstellennummer": "0FB49",
    "Name": "Aral Tankstelle Fürth, Herrnstr. 27",
    "Address": "Herrnstraße 27",
    "Address__1": "",
    "City": "Fürth",
    "Postcode": 90763,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 911775990,
    "24 hour?": "TRUE",
    "Latitude": 49.464158,
    "Longitude": 10.987908,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.464158,10.987908",
    "IsOpen24Hours": true,
    "id": "49.464158,10.987908"
  },
  {
    "ID": 2321,
    "Tankstellennummer": "0FB32",
    "Name": "Aral Tankstelle Nürnberg, Äuss.Sulzbacher Str.117",
    "Address": "Äußere Sulzbacher Straße 117",
    "Address__1": "",
    "City": "Nürnberg",
    "Postcode": 90491,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9115979448,
    "24 hour?": "TRUE",
    "Latitude": 49.464248,
    "Longitude": 11.117947,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.464248,11.117947",
    "IsOpen24Hours": true,
    "id": "49.464248,11.117947"
  },
  {
    "ID": 937419169,
    "Tankstellennummer": "TD170680",
    "Name": "NUERNBERG LAUFAMHOLZSTR. 146",
    "Address": "LAUFAMHOLZSTR. 146",
    "Address__1": "",
    "City": "NUERNBERG",
    "Postcode": 90482,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0911-501968",
    "24 hour?": "FALSE",
    "Latitude": 49.4652,
    "Longitude": 11.1526,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4652,11.1526",
    "IsOpen24Hours": false,
    "id": "49.4652,11.1526"
  },
  {
    "ID": 2316,
    "Tankstellennummer": "0FB29",
    "Name": "Aral Tankstelle Fürth, Nürnberger Str. 131",
    "Address": "Nürnberger Straße 131",
    "Address__1": "",
    "City": "Fürth",
    "Postcode": 90762,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 911706607,
    "24 hour?": "FALSE",
    "Latitude": 49.466534,
    "Longitude": 11.010082,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.466534,11.010082",
    "IsOpen24Hours": false,
    "id": "49.466534,11.010082"
  },
  {
    "ID": 3135,
    "Tankstellennummer": "0FJ50",
    "Name": "Aral Tankstelle Fürth, Nuernberger Str. 126",
    "Address": "Nuernberger Straße 126",
    "Address__1": "",
    "City": "Fürth",
    "Postcode": 90762,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 911706709,
    "24 hour?": "TRUE",
    "Latitude": 49.467563,
    "Longitude": 11.005729,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.467563,11.005729",
    "IsOpen24Hours": true,
    "id": "49.467563,11.005729"
  },
  {
    "ID": 1665032795,
    "Tankstellennummer": 3607,
    "Name": "Agip Nuernberg",
    "Address": "Laufamholz Strasse 294",
    "Address__1": "",
    "City": "Nuernberg",
    "Postcode": 90482,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91150128",
    "24 hour?": "FALSE",
    "Latitude": 49.46895,
    "Longitude": 11.16705,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.46895,11.16705",
    "IsOpen24Hours": false,
    "id": "49.46895,11.16705"
  },
  {
    "ID": 1788,
    "Tankstellennummer": "0F765",
    "Name": "Aral Tankstelle St. Wendel, Mommstr.",
    "Address": "Mommstraße 1",
    "Address__1": "",
    "City": "St. Wendel",
    "Postcode": 66606,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 68512753,
    "24 hour?": "FALSE",
    "Latitude": 49.469793,
    "Longitude": 7.167429,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.469793,7.167429",
    "IsOpen24Hours": false,
    "id": "49.469793,7.167429"
  },
  {
    "ID": 2397,
    "Tankstellennummer": "0FB85",
    "Name": "Aral Tankstelle Schwaig, Röthenbacher Strasse 4",
    "Address": "Röthenbacher Straße 4",
    "Address__1": "",
    "City": "Schwaig",
    "Postcode": 90571,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 911500169,
    "24 hour?": "TRUE",
    "Latitude": 49.472108,
    "Longitude": 11.201648,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.472108,11.201648",
    "IsOpen24Hours": true,
    "id": "49.472108,11.201648"
  },
  {
    "ID": 3250,
    "Tankstellennummer": "0FK24",
    "Name": "Aral Tankstelle Nürnberg, Erlanger Str. 40",
    "Address": "Erlanger Straße 40",
    "Address__1": "",
    "City": "Nürnberg",
    "Postcode": 90425,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 911341045,
    "24 hour?": "TRUE",
    "Latitude": 49.473685,
    "Longitude": 11.064863,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.473685,11.064863",
    "IsOpen24Hours": true,
    "id": "49.473685,11.064863"
  },
  {
    "ID": 2082703895,
    "Tankstellennummer": 2044,
    "Name": "Agip Mannheim",
    "Address": "Gottl.-Daimler-Str. 44",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68165,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62144855",
    "24 hour?": "FALSE",
    "Latitude": 49.47373378,
    "Longitude": 8.49856516,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.47373378,8.49856516",
    "IsOpen24Hours": false,
    "id": "49.47373378,8.49856516"
  },
  {
    "ID": 2357,
    "Tankstellennummer": "0FB60",
    "Name": "Aral Tankstelle Nürnberg, Äuss.Bayreuther Str.137",
    "Address": "Äuss. Bayreuther Str. 137",
    "Address__1": "",
    "City": "Nürnberg",
    "Postcode": 90411,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 911511470,
    "24 hour?": "TRUE",
    "Latitude": 49.474647,
    "Longitude": 11.103074,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.474647,11.103074",
    "IsOpen24Hours": true,
    "id": "49.474647,11.103074"
  },
  {
    "ID": 3075,
    "Tankstellennummer": "0FJ06",
    "Name": "Aral Tankstelle Schriesheim, Roemerstr. 1",
    "Address": "Römerstraße 1",
    "Address__1": "",
    "City": "Schriesheim",
    "Postcode": 69198,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 620361422,
    "24 hour?": "FALSE",
    "Latitude": 49.474939,
    "Longitude": 8.658518,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.474939,8.658518",
    "IsOpen24Hours": false,
    "id": "49.474939,8.658518"
  },
  {
    "ID": 1744,
    "Tankstellennummer": "0F732",
    "Name": "Aral Tankstelle Mannheim, Möhlstr. 1",
    "Address": "Möhlstraße 1",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68165,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621407238,
    "24 hour?": "TRUE",
    "Latitude": 49.47571,
    "Longitude": 8.487958,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.47571,8.487958",
    "IsOpen24Hours": true,
    "id": "49.47571,8.487958"
  },
  {
    "ID": 1743,
    "Tankstellennummer": "0F731",
    "Name": "Aral Tankstelle Weilerbach, Hauptstr.77 a",
    "Address": "Hauptstraße 77a",
    "Address__1": "",
    "City": "Weilerbach",
    "Postcode": 67685,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63741285,
    "24 hour?": "FALSE",
    "Latitude": 49.477554,
    "Longitude": 7.637979,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.477554,7.637979",
    "IsOpen24Hours": false,
    "id": "49.477554,7.637979"
  },
  {
    "ID": 138424912,
    "Tankstellennummer": "TD007450",
    "Name": "OTTERBACH OBERE LAUTER STR. 37",
    "Address": "OBERE LAUTER STR. 37",
    "Address__1": "",
    "City": "OTTERBACH",
    "Postcode": 67731,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06301-8258",
    "24 hour?": "FALSE",
    "Latitude": 49.4788,
    "Longitude": 7.7355,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4788,7.7355",
    "IsOpen24Hours": false,
    "id": "49.4788,7.7355"
  },
  {
    "ID": 1021231222,
    "Tankstellennummer": 3162,
    "Name": "Agip Roethenbach",
    "Address": "Am Gewerbepark 6",
    "Address__1": "",
    "City": "Roethenbach",
    "Postcode": 90552,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "0911/54099439",
    "24 hour?": "FALSE",
    "Latitude": 49.47912,
    "Longitude": 11.22447,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.47912,11.22447",
    "IsOpen24Hours": false,
    "id": "49.47912,11.22447"
  },
  {
    "ID": 1451632249,
    "Tankstellennummer": 4092,
    "Name": "Agip Birkenheide",
    "Address": "Mannheimer Str. 2",
    "Address__1": "",
    "City": "Birkenheide",
    "Postcode": 67134,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62373301",
    "24 hour?": "FALSE",
    "Latitude": 49.47983,
    "Longitude": 8.26447,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.47983,8.26447",
    "IsOpen24Hours": false,
    "id": "49.47983,8.26447"
  },
  {
    "ID": 3068,
    "Tankstellennummer": "0FI99",
    "Name": "Aral Tankstelle Ludwigshafen, Heinigstr. 69",
    "Address": "Heinigstraße 69",
    "Address__1": "",
    "City": "Ludwigshafen",
    "Postcode": 67059,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621514933,
    "24 hour?": "TRUE",
    "Latitude": 49.481501,
    "Longitude": 8.439371,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.481501,8.439371",
    "IsOpen24Hours": true,
    "id": "49.481501,8.439371"
  },
  {
    "ID": 1775,
    "Tankstellennummer": "0F753",
    "Name": "Aral Tankstelle Otterbach, Lauterstrasse 1",
    "Address": "Lauterstraße 1",
    "Address__1": "",
    "City": "Otterbach",
    "Postcode": 67731,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63019877,
    "24 hour?": "FALSE",
    "Latitude": 49.482837,
    "Longitude": 7.730862,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.482837,7.730862",
    "IsOpen24Hours": false,
    "id": "49.482837,7.730862"
  },
  {
    "ID": 375459366,
    "Tankstellennummer": "TD037861",
    "Name": "WILHERMSDORF NUERNBERGER STR.",
    "Address": "NUERNBERGER STR. 1A",
    "Address__1": "",
    "City": "WILHERMSDORF",
    "Postcode": 91452,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09102-993950",
    "24 hour?": "FALSE",
    "Latitude": 49.4834,
    "Longitude": 10.7255,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4834,10.7255",
    "IsOpen24Hours": false,
    "id": "49.4834,10.7255"
  },
  {
    "ID": 1767,
    "Tankstellennummer": "0F747",
    "Name": "Aral Tankstelle Enkenbach-Alsenborn, Hochspeyerer-Strasse 53",
    "Address": "Hochspeyerer-Straße 53",
    "Address__1": "",
    "City": "Enkenbach-Alsenborn",
    "Postcode": 67677,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63034364,
    "24 hour?": "FALSE",
    "Latitude": 49.483675,
    "Longitude": 7.900407,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.483675,7.900407",
    "IsOpen24Hours": false,
    "id": "49.483675,7.900407"
  },
  {
    "ID": 563818285,
    "Tankstellennummer": "TD037937",
    "Name": "OHRENBACH BAB WEST",
    "Address": "BAB WESTSEITE",
    "Address__1": "",
    "City": "OHRENBACH",
    "Postcode": 91620,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09865-986113",
    "24 hour?": "FALSE",
    "Latitude": 49.4852,
    "Longitude": 10.2104,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4852,10.2104",
    "IsOpen24Hours": false,
    "id": "49.4852,10.2104"
  },
  {
    "ID": 2355,
    "Tankstellennummer": "0FB58",
    "Name": "Aral Tankstelle Fürth, Erlanger Str. 98",
    "Address": "Erlanger Straße 98",
    "Address__1": "",
    "City": "Fürth",
    "Postcode": 90765,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9117906217,
    "24 hour?": "FALSE",
    "Latitude": 49.485776,
    "Longitude": 10.996485,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.485776,10.996485",
    "IsOpen24Hours": false,
    "id": "49.485776,10.996485"
  },
  {
    "ID": 857919568,
    "Tankstellennummer": "0FU84",
    "Name": "OHRENBACH",
    "Address": "A7",
    "Address__1": "",
    "City": "Ohrenbach",
    "Postcode": 91620,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 986597770,
    "24 hour?": "TRUE",
    "Latitude": 49.48603,
    "Longitude": 10.211815,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.48603,10.211815",
    "IsOpen24Hours": true,
    "id": "49.48603,10.211815"
  },
  {
    "ID": 757383550,
    "Tankstellennummer": "TD041574",
    "Name": "THOLEY-HASBORN THEELTALSTRASSE",
    "Address": "THEELTALSTRASSE 50",
    "Address__1": "",
    "City": "THOLEY-HASBORN",
    "Postcode": 66636,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 68538540045,
    "24 hour?": "FALSE",
    "Latitude": 49.4877,
    "Longitude": 6.9846,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4877,6.9846",
    "IsOpen24Hours": false,
    "id": "49.4877,6.9846"
  },
  {
    "ID": 848780657,
    "Tankstellennummer": 3019,
    "Name": "Agip Rueckersdorf",
    "Address": "Hauptstrasse 5-7",
    "Address__1": "",
    "City": "Rueckersdorf",
    "Postcode": 90607,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91157938",
    "24 hour?": "FALSE",
    "Latitude": 49.49203,
    "Longitude": 11.23676,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.49203,11.23676",
    "IsOpen24Hours": false,
    "id": "49.49203,11.23676"
  },
  {
    "ID": 962,
    "Tankstellennummer": "0F152",
    "Name": "Aral Tankstelle Ludwigshafen, Prälat-Caire-Str. 14",
    "Address": "Prälat-Caire-Straße 14",
    "Address__1": "",
    "City": "Ludwigshafen",
    "Postcode": 67071,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621680551,
    "24 hour?": "TRUE",
    "Latitude": 49.492061,
    "Longitude": 8.383311,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.492061,8.383311",
    "IsOpen24Hours": true,
    "id": "49.492061,8.383311"
  },
  {
    "ID": 1137765225,
    "Tankstellennummer": "TD037648",
    "Name": "ST. WENDEL ST. WENDELER STR. 3",
    "Address": "ST. WENDELER STR. 33",
    "Address__1": "",
    "City": "ST. WENDEL",
    "Postcode": 66606,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06854-8654",
    "24 hour?": "FALSE",
    "Latitude": 49.4933,
    "Longitude": 7.1144,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4933,7.1144",
    "IsOpen24Hours": false,
    "id": "49.4933,7.1144"
  },
  {
    "ID": 3066,
    "Tankstellennummer": "0FI97",
    "Name": "Aral Tankstelle Mannheim, Hafenstr. 19-21",
    "Address": "Hafenstraße 19-21",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68159,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62124207,
    "24 hour?": "TRUE",
    "Latitude": 49.494056,
    "Longitude": 8.457063,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.494056,8.457063",
    "IsOpen24Hours": true,
    "id": "49.494056,8.457063"
  },
  {
    "ID": 1796,
    "Tankstellennummer": "0F770",
    "Name": "Aral Tankstelle Mettlach, von-Boch-Liebig-Str. 21",
    "Address": "von-Boch-Liebig-Straße 21",
    "Address__1": "",
    "City": "Mettlach",
    "Postcode": 66693,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 686493532,
    "24 hour?": "FALSE",
    "Latitude": 49.495053,
    "Longitude": 6.586036,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.495053,6.586036",
    "IsOpen24Hours": false,
    "id": "49.495053,6.586036"
  },
  {
    "ID": 3070,
    "Tankstellennummer": "0FJ01",
    "Name": "Aral Tankstelle Mannheim, Friedrich-Ebert-Str. 12-1",
    "Address": "Friedrich-Ebert-Straße 12",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68167,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621332213,
    "24 hour?": "TRUE",
    "Latitude": 49.496583,
    "Longitude": 8.487706,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.496583,8.487706",
    "IsOpen24Hours": true,
    "id": "49.496583,8.487706"
  },
  {
    "ID": 596775178,
    "Tankstellennummer": "TD000969",
    "Name": "MANNHEIM FR.-EBERT-STR. 14-16",
    "Address": "FR.-EBERT-STR. 14-16",
    "Address__1": "",
    "City": "MANNHEIM",
    "Postcode": 68167,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0621-333545",
    "24 hour?": "FALSE",
    "Latitude": 49.4972,
    "Longitude": 8.4881,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4972,8.4881",
    "IsOpen24Hours": false,
    "id": "49.4972,8.4881"
  },
  {
    "ID": 1771,
    "Tankstellennummer": "0F749",
    "Name": "Aral Tankstelle Otterberg, Hauptstr.10",
    "Address": "Hauptstraße 10",
    "Address__1": "",
    "City": "Otterberg",
    "Postcode": 67697,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63019778,
    "24 hour?": "FALSE",
    "Latitude": 49.499206,
    "Longitude": 7.772772,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.499206,7.772772",
    "IsOpen24Hours": false,
    "id": "49.499206,7.772772"
  },
  {
    "ID": 245452203,
    "Tankstellennummer": "TD170130",
    "Name": "PFREIMD WERNBERGER STR. 34",
    "Address": "WERNBERGER STR. 34",
    "Address__1": "",
    "City": "PFREIMD",
    "Postcode": 92536,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09606-91277",
    "24 hour?": "FALSE",
    "Latitude": 49.4994,
    "Longitude": 12.1846,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.4994,12.1846",
    "IsOpen24Hours": false,
    "id": "49.4994,12.1846"
  },
  {
    "ID": 1777,
    "Tankstellennummer": "0F755",
    "Name": "Aral Tankstelle Mannheim, Neustadter Str. 47",
    "Address": "Neustadter Straße 47",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68309,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621735151,
    "24 hour?": "FALSE",
    "Latitude": 49.499635,
    "Longitude": 8.506895,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.499635,8.506895",
    "IsOpen24Hours": false,
    "id": "49.499635,8.506895"
  },
  {
    "ID": 964,
    "Tankstellennummer": "0F153",
    "Name": "Aral Tankstelle Ludwigshafen, Brunckstr. 100",
    "Address": "Brunckstraße 100",
    "Address__1": "",
    "City": "Ludwigshafen",
    "Postcode": 67063,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621696456,
    "24 hour?": "TRUE",
    "Latitude": 49.503806,
    "Longitude": 8.4126,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.503806,8.4126",
    "IsOpen24Hours": true,
    "id": "49.503806,8.4126"
  },
  {
    "ID": 1016021354,
    "Tankstellennummer": 3140,
    "Name": "Agip Sulzbach-Rosenberg",
    "Address": "Bayreuther Str. 26",
    "Address__1": "",
    "City": "Sulzbach-Rosenberg",
    "Postcode": 92237,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 96615313",
    "24 hour?": "FALSE",
    "Latitude": 49.50422,
    "Longitude": 11.74321,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.50422,11.74321",
    "IsOpen24Hours": false,
    "id": "49.50422,11.74321"
  },
  {
    "ID": 1855,
    "Tankstellennummer": "0F809",
    "Name": "Aral Tankstelle Katzweiler, Hauptstr. 73",
    "Address": "Hauptstraße 73",
    "Address__1": "",
    "City": "Katzweiler",
    "Postcode": 67734,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63019140,
    "24 hour?": "FALSE",
    "Latitude": 49.504651,
    "Longitude": 7.692398,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.504651,7.692398",
    "IsOpen24Hours": false,
    "id": "49.504651,7.692398"
  },
  {
    "ID": 1564292339,
    "Tankstellennummer": "0FN27",
    "Name": "LAMBSHEIM",
    "Address": "Breslauer Straße 2",
    "Address__1": "",
    "City": "Lambsheim",
    "Postcode": 67245,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6233579820,
    "24 hour?": "FALSE",
    "Latitude": 49.504823,
    "Longitude": 8.291397,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.504823,8.291397",
    "IsOpen24Hours": false,
    "id": "49.504823,8.291397"
  },
  {
    "ID": 398479673,
    "Tankstellennummer": 2142,
    "Name": "Agip Mannheim",
    "Address": "Untermuehlaustr. 105-107",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68169,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62131650",
    "24 hour?": "FALSE",
    "Latitude": 49.50557,
    "Longitude": 8.46313,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.50557,8.46313",
    "IsOpen24Hours": false,
    "id": "49.50557,8.46313"
  },
  {
    "ID": 3297,
    "Tankstellennummer": "0FK57",
    "Name": "Aral Tankstelle Mannheim, Untermuehlaustr. 50",
    "Address": "Untermühlaustraße 50",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68169,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621316970,
    "24 hour?": "TRUE",
    "Latitude": 49.506178,
    "Longitude": 8.463967,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.506178,8.463967",
    "IsOpen24Hours": true,
    "id": "49.506178,8.463967"
  },
  {
    "ID": 1934882214,
    "Tankstellennummer": "TD038380",
    "Name": "HIRSCHBERG GOLDBECKSTR. 1",
    "Address": "GOLDBECKSTR. 1",
    "Address__1": "",
    "City": "HIRSCHBERG",
    "Postcode": 69493,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06201-876431",
    "24 hour?": "FALSE",
    "Latitude": 49.5074,
    "Longitude": 8.6369,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5074,8.6369",
    "IsOpen24Hours": false,
    "id": "49.5074,8.6369"
  },
  {
    "ID": 971721381,
    "Tankstellennummer": "TD200328",
    "Name": "HIRSCHBERG GOLDBECKSTR. 1",
    "Address": "GOLDBECKSTR. 1",
    "Address__1": "",
    "City": "HIRSCHBERG",
    "Postcode": 69493,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 49.5077,
    "Longitude": 8.6373,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5077,8.6373",
    "IsOpen24Hours": false,
    "id": "49.5077,8.6373"
  },
  {
    "ID": 1611424315,
    "Tankstellennummer": 2149,
    "Name": "Agip Mannheim",
    "Address": "Magdeburger Strasse",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68309,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62170620",
    "24 hour?": "FALSE",
    "Latitude": 49.51046,
    "Longitude": 8.55189,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.51046,8.55189",
    "IsOpen24Hours": false,
    "id": "49.51046,8.55189"
  },
  {
    "ID": 1746072563,
    "Tankstellennummer": "TD000223",
    "Name": "HIRSCHBERG LANDSTRASSE 7",
    "Address": "LANDSTRASSE 7",
    "Address__1": "",
    "City": "HIRSCHBERG",
    "Postcode": 69493,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06201-51522",
    "24 hour?": "FALSE",
    "Latitude": 49.5109,
    "Longitude": 8.6556,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5109,8.6556",
    "IsOpen24Hours": false,
    "id": "49.5109,8.6556"
  },
  {
    "ID": 963,
    "Tankstellennummer": "0F019",
    "Name": "Aral Tankstelle Losheim am See, Haagstraße",
    "Address": "Haagstraße",
    "Address__1": "",
    "City": "Losheim am See",
    "Postcode": 66679,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 687291044,
    "24 hour?": "TRUE",
    "Latitude": 49.511373,
    "Longitude": 6.753374,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.511373,6.753374",
    "IsOpen24Hours": true,
    "id": "49.511373,6.753374"
  },
  {
    "ID": 1858,
    "Tankstellennummer": "0F811",
    "Name": "Aral Tankstelle Mannheim, Brandenburger Str. 13-15",
    "Address": "Brandenburger Str. 13-15",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68309,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621706602,
    "24 hour?": "TRUE",
    "Latitude": 49.512068,
    "Longitude": 8.529542,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.512068,8.529542",
    "IsOpen24Hours": true,
    "id": "49.512068,8.529542"
  },
  {
    "ID": 1647508622,
    "Tankstellennummer": "TD000879",
    "Name": "MANNHEIM HAFENBAHNSTR 74",
    "Address": "HAFENBAHNSTR 74",
    "Address__1": "",
    "City": "MANNHEIM",
    "Postcode": 68305,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0621-7621810",
    "24 hour?": "FALSE",
    "Latitude": 49.5133,
    "Longitude": 8.4843,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5133,8.4843",
    "IsOpen24Hours": false,
    "id": "49.5133,8.4843"
  },
  {
    "ID": 1409282186,
    "Tankstellennummer": "0FU30",
    "Name": "KONKEN",
    "Address": "Schellweilerstraße 1",
    "Address__1": "",
    "City": "Konken",
    "Postcode": 66871,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63849933155,
    "24 hour?": "TRUE",
    "Latitude": 49.51378,
    "Longitude": 7.37208,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.51378,7.37208",
    "IsOpen24Hours": true,
    "id": "49.51378,7.37208"
  },
  {
    "ID": 861790653,
    "Tankstellennummer": "0FN43",
    "Name": "BUCHEN",
    "Address": "Bödigheimer Straße 24",
    "Address__1": "",
    "City": "Buchen",
    "Postcode": 74722,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 628152150,
    "24 hour?": "FALSE",
    "Latitude": 49.515287,
    "Longitude": 9.321544,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.515287,9.321544",
    "IsOpen24Hours": false,
    "id": "49.515287,9.321544"
  },
  {
    "ID": 1060020205,
    "Tankstellennummer": "0FM39",
    "Name": "Aral TankStelle Mannheim, Alter Postweg 4-6",
    "Address": "Alter Postweg 4-6",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68309,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6217163939,
    "24 hour?": "TRUE",
    "Latitude": 49.515964,
    "Longitude": 8.520571,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.515964,8.520571",
    "IsOpen24Hours": true,
    "id": "49.515964,8.520571"
  },
  {
    "ID": 871874399,
    "Tankstellennummer": 3144,
    "Name": "Agip Lauf",
    "Address": "Hersbrucker Str. 64",
    "Address__1": "",
    "City": "Lauf",
    "Postcode": 91207,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91238446",
    "24 hour?": "TRUE",
    "Latitude": 49.51597,
    "Longitude": 11.29061,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.51597,11.29061",
    "IsOpen24Hours": true,
    "id": "49.51597,11.29061"
  },
  {
    "ID": 1746090896,
    "Tankstellennummer": 3091,
    "Name": "Agip Fuerth",
    "Address": "Vacher Strasse 417",
    "Address__1": "",
    "City": "Fuerth",
    "Postcode": 90768,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91176138",
    "24 hour?": "FALSE",
    "Latitude": 49.52106,
    "Longitude": 10.96406,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.52106,10.96406",
    "IsOpen24Hours": false,
    "id": "49.52106,10.96406"
  },
  {
    "ID": 1855844456,
    "Tankstellennummer": "TD000460",
    "Name": "FREISEN-OBERKIRCHEN HAUPERSWEI",
    "Address": "HAUPERSWEILER STR. 1",
    "Address__1": "",
    "City": "FREISEN-OBERKIRCHEN",
    "Postcode": 66629,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06855-7100",
    "24 hour?": "FALSE",
    "Latitude": 49.525,
    "Longitude": 7.263,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.525,7.263",
    "IsOpen24Hours": false,
    "id": "49.525,7.263"
  },
  {
    "ID": 3055,
    "Tankstellennummer": "0FI90",
    "Name": "Aral Tankstelle Frankenthal, Mahlastr. 37",
    "Address": "Mahlastraße 37",
    "Address__1": "",
    "City": "Frankenthal",
    "Postcode": 67227,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6233349208,
    "24 hour?": "TRUE",
    "Latitude": 49.52605,
    "Longitude": 8.354932,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.52605,8.354932",
    "IsOpen24Hours": true,
    "id": "49.52605,8.354932"
  },
  {
    "ID": 3328,
    "Tankstellennummer": "0FK82",
    "Name": "Aral Tankstelle Mannheim, Waldstrasse 48-56",
    "Address": "Waldstraße 48-56",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68305,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621751361,
    "24 hour?": "FALSE",
    "Latitude": 49.528229,
    "Longitude": 8.489984,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.528229,8.489984",
    "IsOpen24Hours": false,
    "id": "49.528229,8.489984"
  },
  {
    "ID": 3118,
    "Tankstellennummer": "0FJ39",
    "Name": "Aral Tankstelle Mudau, Bahnhofstrasse 7",
    "Address": "Bahnhofstraße 7",
    "Address__1": "",
    "City": "Mudau",
    "Postcode": 69427,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 628495025,
    "24 hour?": "FALSE",
    "Latitude": 49.529972,
    "Longitude": 9.204745,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.529972,9.204745",
    "IsOpen24Hours": false,
    "id": "49.529972,9.204745"
  },
  {
    "ID": 474209997,
    "Tankstellennummer": "TD191330",
    "Name": "HETTENLEIDELHEIM WATTENHEIMER",
    "Address": "WATTENHEIMER STR. 28",
    "Address__1": "",
    "City": "HETTENLEIDELHEIM",
    "Postcode": 67310,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06322-9799176",
    "24 hour?": "FALSE",
    "Latitude": 49.5316,
    "Longitude": 8.0661,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5316,8.0661",
    "IsOpen24Hours": false,
    "id": "49.5316,8.0661"
  },
  {
    "ID": 3067,
    "Tankstellennummer": "0FI98",
    "Name": "Aral Tankstelle Ludwigshafen, Oppauer Str. 125",
    "Address": "Oppauer Straße 125",
    "Address__1": "",
    "City": "Ludwigshafen",
    "Postcode": 67069,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621661459,
    "24 hour?": "TRUE",
    "Latitude": 49.534019,
    "Longitude": 8.388857,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.534019,8.388857",
    "IsOpen24Hours": true,
    "id": "49.534019,8.388857"
  },
  {
    "ID": 1789,
    "Tankstellennummer": "0F766",
    "Name": "Aral Tankstelle Wadern, Poststr.33",
    "Address": "Poststraße 33",
    "Address__1": "",
    "City": "Wadern",
    "Postcode": 66687,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6871902924,
    "24 hour?": "FALSE",
    "Latitude": 49.535001,
    "Longitude": 6.895406,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.535001,6.895406",
    "IsOpen24Hours": false,
    "id": "49.535001,6.895406"
  },
  {
    "ID": 1672433222,
    "Tankstellennummer": "TD191420",
    "Name": "FRANKENTHAL FOLTZRING 38-40",
    "Address": "FOLTZRING 38-40",
    "Address__1": "",
    "City": "FRANKENTHAL",
    "Postcode": 67227,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06233-66197",
    "24 hour?": "FALSE",
    "Latitude": 49.5378,
    "Longitude": 8.359,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5378,8.359",
    "IsOpen24Hours": false,
    "id": "49.5378,8.359"
  },
  {
    "ID": 2493,
    "Tankstellennummer": "0FC38",
    "Name": "Aral Tankstelle Wernberg-Köblitz, Nürnbergerstr.35",
    "Address": "Nürnbergerstraße 35",
    "Address__1": "",
    "City": "Wernberg-Köblitz",
    "Postcode": 92533,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 96042272,
    "24 hour?": "FALSE",
    "Latitude": 49.537968,
    "Longitude": 12.156044,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.537968,12.156044",
    "IsOpen24Hours": false,
    "id": "49.537968,12.156044"
  },
  {
    "ID": 1902038668,
    "Tankstellennummer": "0FV07",
    "Name": "KUSEL",
    "Address": "Glanstraße 44",
    "Address__1": "",
    "City": "Kusel",
    "Postcode": 66869,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "06381/429715",
    "24 hour?": "FALSE",
    "Latitude": 49.53976,
    "Longitude": 7.413175,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.53976,7.413175",
    "IsOpen24Hours": false,
    "id": "49.53976,7.413175"
  },
  {
    "ID": 417747570,
    "Tankstellennummer": "TD041426",
    "Name": "LAUDA-KOENIGSHOFEN DEUBACHER S",
    "Address": "DEUBACHER STR.1",
    "Address__1": "",
    "City": "LAUDA-KOENIGSHOFEN",
    "Postcode": 97922,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 934365440,
    "24 hour?": "FALSE",
    "Latitude": 49.5413,
    "Longitude": 9.7366,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5413,9.7366",
    "IsOpen24Hours": false,
    "id": "49.5413,9.7366"
  },
  {
    "ID": 1606022057,
    "Tankstellennummer": "0FG12",
    "Name": "Aral TankStelle Viernheim, BAB 659",
    "Address": "BAB 659",
    "Address__1": "",
    "City": "Viernheim",
    "Postcode": 68519,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6204919183,
    "24 hour?": "TRUE",
    "Latitude": 49.542497,
    "Longitude": 8.608754,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.542497,8.608754",
    "IsOpen24Hours": true,
    "id": "49.542497,8.608754"
  },
  {
    "ID": 46017452,
    "Tankstellennummer": "TD000603",
    "Name": "VIERNHEIM WORMSER STR. 2",
    "Address": "WORMSER STR. 2",
    "Address__1": "",
    "City": "VIERNHEIM",
    "Postcode": 68519,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06204-76161",
    "24 hour?": "FALSE",
    "Latitude": 49.5435,
    "Longitude": 8.5743,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5435,8.5743",
    "IsOpen24Hours": false,
    "id": "49.5435,8.5743"
  },
  {
    "ID": 691523960,
    "Tankstellennummer": "TD000442",
    "Name": "HESSHEIM FRANKENTALER STR. 23",
    "Address": "FRANKENTALER STR. 23",
    "Address__1": "",
    "City": "HESSHEIM",
    "Postcode": 67258,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06233-371600",
    "24 hour?": "FALSE",
    "Latitude": 49.5441,
    "Longitude": 8.3122,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5441,8.3122",
    "IsOpen24Hours": false,
    "id": "49.5441,8.3122"
  },
  {
    "ID": 745735046,
    "Tankstellennummer": "TD038448",
    "Name": "ERLANGEN SEBASTIANSTR. 28",
    "Address": "SEBASTIANSTR. 28",
    "Address__1": "",
    "City": "ERLANGEN",
    "Postcode": 91058,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09131/602151",
    "24 hour?": "FALSE",
    "Latitude": 49.5452,
    "Longitude": 11.0299,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5452,11.0299",
    "IsOpen24Hours": false,
    "id": "49.5452,11.0299"
  },
  {
    "ID": 3125,
    "Tankstellennummer": "0FJ42",
    "Name": "Aral Tankstelle Uffenheim, Wuerzburger Str. 34",
    "Address": "Wuerzburger Straße 34",
    "Address__1": "",
    "City": "Uffenheim",
    "Postcode": 97215,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9842415,
    "24 hour?": "FALSE",
    "Latitude": 49.546688,
    "Longitude": 10.227066,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.546688,10.227066",
    "IsOpen24Hours": false,
    "id": "49.546688,10.227066"
  },
  {
    "ID": 916470923,
    "Tankstellennummer": "TD124060",
    "Name": "GRUENSTADT LEININGER STR. 39-4",
    "Address": "LEININGER STR. 39-41",
    "Address__1": "",
    "City": "GRUENSTADT",
    "Postcode": 67269,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06359-92214",
    "24 hour?": "FALSE",
    "Latitude": 49.5469,
    "Longitude": 8.1593,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5469,8.1593",
    "IsOpen24Hours": false,
    "id": "49.5469,8.1593"
  },
  {
    "ID": 304219834,
    "Tankstellennummer": "TD133370",
    "Name": "WEINHEIM BERGSTR. 13-15",
    "Address": "BERGSTR. 13-15",
    "Address__1": "",
    "City": "WEINHEIM",
    "Postcode": 69469,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06201-4888320",
    "24 hour?": "FALSE",
    "Latitude": 49.5472,
    "Longitude": 8.6639,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5472,8.6639",
    "IsOpen24Hours": false,
    "id": "49.5472,8.6639"
  },
  {
    "ID": 3078,
    "Tankstellennummer": "0FJ08",
    "Name": "Aral Tankstelle Weinheim, Mannheimerstr. 77",
    "Address": "Mannheimerstraße 77",
    "Address__1": "",
    "City": "Weinheim",
    "Postcode": 69469,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 620114295,
    "24 hour?": "TRUE",
    "Latitude": 49.549426,
    "Longitude": 8.654739,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.549426,8.654739",
    "IsOpen24Hours": true,
    "id": "49.549426,8.654739"
  },
  {
    "ID": 1761,
    "Tankstellennummer": "0F744",
    "Name": "Aral Tankstelle Mannheim, Frankenthaler Str.86",
    "Address": "Frankenthaler Straße 86",
    "Address__1": "",
    "City": "Mannheim",
    "Postcode": 68307,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 621771755,
    "24 hour?": "TRUE",
    "Latitude": 49.550115,
    "Longitude": 8.449075,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.550115,8.449075",
    "IsOpen24Hours": true,
    "id": "49.550115,8.449075"
  },
  {
    "ID": 1399663196,
    "Tankstellennummer": "TD040659",
    "Name": "FRANKENTHAL INDUSTRIESTR.22",
    "Address": "INDUSTRIESTR.22",
    "Address__1": "",
    "City": "FRANKENTHAL",
    "Postcode": 67227,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06233-7370237",
    "24 hour?": "FALSE",
    "Latitude": 49.5514,
    "Longitude": 8.3478,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5514,8.3478",
    "IsOpen24Hours": false,
    "id": "49.5514,8.3478"
  },
  {
    "ID": 1366101323,
    "Tankstellennummer": "TD035477",
    "Name": "KIRCHHEIM AUTOHOF ROSENGARTENW",
    "Address": "AUTOHOF ROSENGARTENWEG 1",
    "Address__1": "",
    "City": "KIRCHHEIM",
    "Postcode": 67281,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06359-9692132",
    "24 hour?": "FALSE",
    "Latitude": 49.5542,
    "Longitude": 8.1856,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5542,8.1856",
    "IsOpen24Hours": false,
    "id": "49.5542,8.1856"
  },
  {
    "ID": 1852,
    "Tankstellennummer": "0F808",
    "Name": "Aral Tankstelle Freisen, Baumholderstr.47-51",
    "Address": "Baumholderstraße 47-51",
    "Address__1": "",
    "City": "Freisen",
    "Postcode": 66629,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6855920017,
    "24 hour?": "TRUE",
    "Latitude": 49.554605,
    "Longitude": 7.256143,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.554605,7.256143",
    "IsOpen24Hours": true,
    "id": "49.554605,7.256143"
  },
  {
    "ID": 1754,
    "Tankstellennummer": "0F739",
    "Name": "Aral Tankstelle Grünstadt, Kirchheimer Str.51-53",
    "Address": "Kirchheimer Straße 51-53",
    "Address__1": "",
    "City": "Grünstadt",
    "Postcode": 67269,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63593194,
    "24 hour?": "FALSE",
    "Latitude": 49.559289,
    "Longitude": 8.170279,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.559289,8.170279",
    "IsOpen24Hours": false,
    "id": "49.559289,8.170279"
  },
  {
    "ID": 330710526,
    "Tankstellennummer": "0FI85",
    "Name": "Aral TankStelle Birkenau, Hauptstraße 46-48",
    "Address": "Hauptstraße 46-48",
    "Address__1": "",
    "City": "Birkenau",
    "Postcode": 69488,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 620131714,
    "24 hour?": "FALSE",
    "Latitude": 49.561659,
    "Longitude": 8.699699,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.561659,8.699699",
    "IsOpen24Hours": false,
    "id": "49.561659,8.699699"
  },
  {
    "ID": 136316053,
    "Tankstellennummer": "TD002162",
    "Name": "EISENBERG ROSENTHALER STR. 2",
    "Address": "ROSENTHALER STR. 2",
    "Address__1": "",
    "City": "EISENBERG",
    "Postcode": 67304,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06351-5164",
    "24 hour?": "FALSE",
    "Latitude": 49.5642,
    "Longitude": 8.0623,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5642,8.0623",
    "IsOpen24Hours": false,
    "id": "49.5642,8.0623"
  },
  {
    "ID": 141362362,
    "Tankstellennummer": "TD003327",
    "Name": "WINNWEILER JAKOBSTR. 43",
    "Address": "JAKOBSTR. 43",
    "Address__1": "",
    "City": "WINNWEILER",
    "Postcode": 67722,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06302-2187",
    "24 hour?": "FALSE",
    "Latitude": 49.5648,
    "Longitude": 7.8567,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5648,7.8567",
    "IsOpen24Hours": false,
    "id": "49.5648,7.8567"
  },
  {
    "ID": 3112,
    "Tankstellennummer": "0FJ34",
    "Name": "Aral Tankstelle Herzogenaurach, Erlanger Str. 41",
    "Address": "Erlanger Straße 41",
    "Address__1": "",
    "City": "Herzogenaurach",
    "Postcode": 91074,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9132797981,
    "24 hour?": "FALSE",
    "Latitude": 49.566982,
    "Longitude": 10.892825,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.566982,10.892825",
    "IsOpen24Hours": false,
    "id": "49.566982,10.892825"
  },
  {
    "ID": 2019,
    "Tankstellennummer": "0FG36",
    "Name": "Aral Autohof Schnaittach, Schwarzleite 2",
    "Address": "Schwarzleite 2",
    "Address__1": "",
    "City": "Schnaittach",
    "Postcode": 91220,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 91539229892,
    "24 hour?": "TRUE",
    "Latitude": 49.567291,
    "Longitude": 11.327495,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.567291,11.327495",
    "IsOpen24Hours": true,
    "id": "49.567291,11.327495"
  },
  {
    "ID": 1741,
    "Tankstellennummer": "0F728",
    "Name": "Aral Tankstelle Wald-Michelbach, Ludwigstr.21-25",
    "Address": "Ludwigstraße 21-25",
    "Address__1": "",
    "City": "Wald-Michelbach",
    "Postcode": 69483,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6207941718,
    "24 hour?": "FALSE",
    "Latitude": 49.573554,
    "Longitude": 8.823318,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.573554,8.823318",
    "IsOpen24Hours": false,
    "id": "49.573554,8.823318"
  },
  {
    "ID": 2366,
    "Tankstellennummer": "0FB84",
    "Name": "Aral Tankstelle Erlangen, Äuß. Brucker Str. 171",
    "Address": "Äuß. Brucker Straße 171",
    "Address__1": "",
    "City": "Erlangen",
    "Postcode": 91058,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9131640702,
    "24 hour?": "TRUE",
    "Latitude": 49.577007,
    "Longitude": 10.986522,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.577007,10.986522",
    "IsOpen24Hours": true,
    "id": "49.577007,10.986522"
  },
  {
    "ID": 1187845109,
    "Tankstellennummer": "TD039024",
    "Name": "ECKENTAL DR.-OTTO-LEICH-STR.",
    "Address": "DR. OTTO-LEICH STR. / B2",
    "Address__1": "",
    "City": "ECKENTAL",
    "Postcode": 90542,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09126-2971320",
    "24 hour?": "FALSE",
    "Latitude": 49.5803,
    "Longitude": 11.1982,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5803,11.1982",
    "IsOpen24Hours": false,
    "id": "49.5803,11.1982"
  },
  {
    "ID": 510061471,
    "Tankstellennummer": "0FM88",
    "Name": "ERLANGEN",
    "Address": "A3",
    "Address__1": "",
    "City": "Erlangen",
    "Postcode": 91056,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9131758670,
    "24 hour?": "TRUE",
    "Latitude": 49.580301,
    "Longitude": 10.930001,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.580301,10.930001",
    "IsOpen24Hours": true,
    "id": "49.580301,10.930001"
  },
  {
    "ID": 315761785,
    "Tankstellennummer": 2280,
    "Name": "Agip Wallduern",
    "Address": "Wuerzburger Str.9",
    "Address__1": "",
    "City": "Wallduern",
    "Postcode": 74731,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": 62829289646,
    "24 hour?": "FALSE",
    "Latitude": 49.58159,
    "Longitude": 9.3745,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.58159,9.3745",
    "IsOpen24Hours": false,
    "id": "49.58159,9.3745"
  },
  {
    "ID": 908491459,
    "Tankstellennummer": 3063,
    "Name": "Agip Neustad a.d. Aisch",
    "Address": "Riedfelder Ortsstrasse 2A",
    "Address__1": "",
    "City": "Neustadt a. d. Aisch",
    "Postcode": 91413,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91612336",
    "24 hour?": "FALSE",
    "Latitude": 49.58219894,
    "Longitude": 10.59729519,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.58219894,10.59729519",
    "IsOpen24Hours": false,
    "id": "49.58219894,10.59729519"
  },
  {
    "ID": 2373,
    "Tankstellennummer": "0FB70",
    "Name": "Aral Tankstelle Neustadt, Bamberger Str.74",
    "Address": "Bamberger Straße 74",
    "Address__1": "",
    "City": "Neustadt a. d. Aisch",
    "Postcode": 91413,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9161874225,
    "24 hour?": "FALSE",
    "Latitude": 49.584064,
    "Longitude": 10.616671,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.584064,10.616671",
    "IsOpen24Hours": false,
    "id": "49.584064,10.616671"
  },
  {
    "ID": 3133,
    "Tankstellennummer": "0FJ48",
    "Name": "Aral Tankstelle Eckental, Forther Hauptstr. 2",
    "Address": "Forther Hauptstraße 2",
    "Address__1": "",
    "City": "Eckental",
    "Postcode": 90542,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9126259515,
    "24 hour?": "FALSE",
    "Latitude": 49.589256,
    "Longitude": 11.217015,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.589256,11.217015",
    "IsOpen24Hours": false,
    "id": "49.589256,11.217015"
  },
  {
    "ID": 253990561,
    "Tankstellennummer": 4051,
    "Name": "Agip Lampertheim",
    "Address": "Roemerstrasse 76-78",
    "Address__1": "",
    "City": "Lampertheim",
    "Postcode": 68623,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62069109",
    "24 hour?": "FALSE",
    "Latitude": 49.5924,
    "Longitude": 8.472,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5924,8.472",
    "IsOpen24Hours": false,
    "id": "49.5924,8.472"
  },
  {
    "ID": 2417,
    "Tankstellennummer": "0FB96",
    "Name": "Aral Tankstelle Erlangen, Am Europakanal 26",
    "Address": "Am Europakanal 26",
    "Address__1": "",
    "City": "Erlangen",
    "Postcode": 91056,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 913147599,
    "24 hour?": "TRUE",
    "Latitude": 49.59296,
    "Longitude": 10.97005,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.59296,10.97005",
    "IsOpen24Hours": true,
    "id": "49.59296,10.97005"
  },
  {
    "ID": 846056900,
    "Tankstellennummer": 2014,
    "Name": "Agip Hemsbach",
    "Address": "Huettenfelder Str. 46",
    "Address__1": "",
    "City": "Hemsbach",
    "Postcode": 69502,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62017144",
    "24 hour?": "TRUE",
    "Latitude": 49.59301623,
    "Longitude": 8.63971061,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.59301623,8.63971061",
    "IsOpen24Hours": true,
    "id": "49.59301623,8.63971061"
  },
  {
    "ID": 1674824526,
    "Tankstellennummer": "0FN25",
    "Name": "STANDENBUHL",
    "Address": "Kaiserstraße 11",
    "Address__1": "",
    "City": "Standenbühl",
    "Postcode": 67816,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6357989117,
    "24 hour?": "FALSE",
    "Latitude": 49.594053,
    "Longitude": 7.985273,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.594053,7.985273",
    "IsOpen24Hours": false,
    "id": "49.594053,7.985273"
  },
  {
    "ID": 2365,
    "Tankstellennummer": "0FB66",
    "Name": "Aral Tankstelle Erlangen, Drausnickstr. 60",
    "Address": "Drausnickstraße 60",
    "Address__1": "",
    "City": "Erlangen",
    "Postcode": 91052,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 913152370,
    "24 hour?": "TRUE",
    "Latitude": 49.595761,
    "Longitude": 11.031701,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.595761,11.031701",
    "IsOpen24Hours": true,
    "id": "49.595761,11.031701"
  },
  {
    "ID": 2342,
    "Tankstellennummer": "0FB50",
    "Name": "Aral Tankstelle Uttenreuth, Erlanger Strasse 33",
    "Address": "Erlanger Straße 33",
    "Address__1": "",
    "City": "Uttenreuth",
    "Postcode": 91080,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 913157088,
    "24 hour?": "FALSE",
    "Latitude": 49.596263,
    "Longitude": 11.068797,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.596263,11.068797",
    "IsOpen24Hours": false,
    "id": "49.596263,11.068797"
  },
  {
    "ID": 2103492273,
    "Tankstellennummer": "TD040576",
    "Name": "MOERLENBACH WEINHEIMER STR. 34",
    "Address": "WEINHEIMER STR. 34",
    "Address__1": "",
    "City": "MOERLENBACH",
    "Postcode": 69509,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 62093305,
    "24 hour?": "FALSE",
    "Latitude": 49.5966,
    "Longitude": 8.7317,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.5966,8.7317",
    "IsOpen24Hours": false,
    "id": "49.5966,8.7317"
  },
  {
    "ID": 296733799,
    "Tankstellennummer": "TD036608",
    "Name": "DIESPECK BAMBERGER STR. 59/61",
    "Address": "BAMBERGER STR. 59/61",
    "Address__1": "",
    "City": "DIESPECK",
    "Postcode": 91456,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09161-885858",
    "24 hour?": "FALSE",
    "Latitude": 49.6008,
    "Longitude": 10.6257,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.6008,10.6257",
    "IsOpen24Hours": false,
    "id": "49.6008,10.6257"
  },
  {
    "ID": 309137165,
    "Tankstellennummer": "TD003301",
    "Name": "LAMPERTHEIM BUERSTAEDTER STR.",
    "Address": "BUERSTAEDTER STR. 78",
    "Address__1": "",
    "City": "LAMPERTHEIM",
    "Postcode": 68623,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06206-2132",
    "24 hour?": "FALSE",
    "Latitude": 49.6037,
    "Longitude": 8.4678,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.6037,8.4678",
    "IsOpen24Hours": false,
    "id": "49.6037,8.4678"
  },
  {
    "ID": 1941904930,
    "Tankstellennummer": "0FP17",
    "Name": "Aral TankStelle Neunkirchen am Brand, Kleinsendelbacher Str. 12",
    "Address": "Kleinsendelbacher Str. 12",
    "Address__1": "",
    "City": "Neunkirchen am Brand",
    "Postcode": 91077,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9134906906,
    "24 hour?": "FALSE",
    "Latitude": 49.604142,
    "Longitude": 11.138034,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.604142,11.138034",
    "IsOpen24Hours": false,
    "id": "49.604142,11.138034"
  },
  {
    "ID": 3051,
    "Tankstellennummer": "0FI86",
    "Name": "Aral Tankstelle Bockenheim, Weinstr. 90",
    "Address": "Weinstraße 90",
    "Address__1": "",
    "City": "Bockenheim",
    "Postcode": 67278,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63599615906,
    "24 hour?": "FALSE",
    "Latitude": 49.608469,
    "Longitude": 8.181643,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.608469,8.181643",
    "IsOpen24Hours": false,
    "id": "49.608469,8.181643"
  },
  {
    "ID": 3291,
    "Tankstellennummer": "0FK52",
    "Name": "Aral Tankstelle Baumholder, In der Schwaerzgrub 20",
    "Address": "In der Schwärzgrub 20",
    "Address__1": "",
    "City": "Baumholder",
    "Postcode": 55774,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 67837701,
    "24 hour?": "FALSE",
    "Latitude": 49.60933,
    "Longitude": 7.328758,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.60933,7.328758",
    "IsOpen24Hours": false,
    "id": "49.60933,7.328758"
  },
  {
    "ID": 847,
    "Tankstellennummer": "0F069",
    "Name": "Aral Tankstelle Birkenfeld, Steinautal/ B 41",
    "Address": "Im Steinautal 2",
    "Address__1": "",
    "City": "Ellweiler",
    "Postcode": 55765,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 67822453,
    "24 hour?": "FALSE",
    "Latitude": 49.617227,
    "Longitude": 7.158907,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.617227,7.158907",
    "IsOpen24Hours": false,
    "id": "49.617227,7.158907"
  },
  {
    "ID": 1105107119,
    "Tankstellennummer": "0FQ98",
    "Name": "GRUNSFELD",
    "Address": "A81",
    "Address__1": "",
    "City": "Grünsfeld",
    "Postcode": 97947,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 934692170,
    "24 hour?": "TRUE",
    "Latitude": 49.62291,
    "Longitude": 9.72944,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.62291,9.72944",
    "IsOpen24Hours": true,
    "id": "49.62291,9.72944"
  },
  {
    "ID": 3247,
    "Tankstellennummer": "0FK22",
    "Name": "Aral Tankstelle Erlangen, Weisendorfer Str. 16",
    "Address": "Weisendorfer Straße 16",
    "Address__1": "",
    "City": "Erlangen",
    "Postcode": 91056,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9135729895,
    "24 hour?": "FALSE",
    "Latitude": 49.625682,
    "Longitude": 10.940643,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.625682,10.940643",
    "IsOpen24Hours": false,
    "id": "49.625682,10.940643"
  },
  {
    "ID": 2048815528,
    "Tankstellennummer": 4306,
    "Name": "Agip Marnheim",
    "Address": "Hauptstr. 1a",
    "Address__1": "",
    "City": "Marnheim",
    "Postcode": 67297,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 63527048",
    "24 hour?": "TRUE",
    "Latitude": 49.62936,
    "Longitude": 8.03572,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.62936,8.03572",
    "IsOpen24Hours": true,
    "id": "49.62936,8.03572"
  },
  {
    "ID": 1780,
    "Tankstellennummer": "0F758",
    "Name": "Aral Tankstelle Rimbach, Staatsstrasse 101",
    "Address": "Staatsstraße 101",
    "Address__1": "",
    "City": "Rimbach",
    "Postcode": 64668,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62536484,
    "24 hour?": "FALSE",
    "Latitude": 49.630154,
    "Longitude": 8.75995,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.630154,8.75995",
    "IsOpen24Hours": false,
    "id": "49.630154,8.75995"
  },
  {
    "ID": 413938982,
    "Tankstellennummer": "TD041434",
    "Name": "TAUBERBISCHOFSHEIM",
    "Address": "WERTHEIMER STR. 50",
    "Address__1": "",
    "City": "TAUBERBISCHOFSHEIM",
    "Postcode": 97941,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 934195164,
    "24 hour?": "FALSE",
    "Latitude": 49.6302,
    "Longitude": 9.6661,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.6302,9.6661",
    "IsOpen24Hours": false,
    "id": "49.6302,9.6661"
  },
  {
    "ID": 3079,
    "Tankstellennummer": "0FJ09",
    "Name": "Aral Tankstelle Worms, Alzeyer Str. 58",
    "Address": "Alzeyer Straße 58",
    "Address__1": "",
    "City": "Worms",
    "Postcode": 67549,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 624158314,
    "24 hour?": "FALSE",
    "Latitude": 49.631911,
    "Longitude": 8.347308,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.631911,8.347308",
    "IsOpen24Hours": false,
    "id": "49.631911,8.347308"
  },
  {
    "ID": 660736864,
    "Tankstellennummer": "0FN28",
    "Name": "WORMS",
    "Address": "Alzeyer Straße 71",
    "Address__1": "",
    "City": "Worms",
    "Postcode": 67549,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6241209415,
    "24 hour?": "FALSE",
    "Latitude": 49.632292,
    "Longitude": 8.341961,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.632292,8.341961",
    "IsOpen24Hours": false,
    "id": "49.632292,8.341961"
  },
  {
    "ID": 763600069,
    "Tankstellennummer": "TD040790",
    "Name": "GERHARDSHOFEN DACHSBACHER STR.",
    "Address": "DACHSBACHER STR. 1",
    "Address__1": "",
    "City": "GERHARDSHOFEN",
    "Postcode": 91466,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 9163997290,
    "24 hour?": "FALSE",
    "Latitude": 49.6323,
    "Longitude": 10.6927,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.6323,10.6927",
    "IsOpen24Hours": false,
    "id": "49.6323,10.6927"
  },
  {
    "ID": 1606393168,
    "Tankstellennummer": "0FN31",
    "Name": "WORMS-PFEDDERSHEIM",
    "Address": "Odenwaldstraße 7",
    "Address__1": "",
    "City": "Worms-Pfeddersheim",
    "Postcode": 67551,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6247905314,
    "24 hour?": "FALSE",
    "Latitude": 49.634336,
    "Longitude": 8.280931,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.634336,8.280931",
    "IsOpen24Hours": false,
    "id": "49.634336,8.280931"
  },
  {
    "ID": 3433,
    "Tankstellennummer": "0FL67",
    "Name": "Aral Tankstelle Rockenhausen, Kreuzwiese 1",
    "Address": "Kreuzwiese 1",
    "Address__1": "",
    "City": "Rockenhausen",
    "Postcode": 67806,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63619946820,
    "24 hour?": "FALSE",
    "Latitude": 49.642302,
    "Longitude": 7.826909,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.642302,7.826909",
    "IsOpen24Hours": false,
    "id": "49.642302,7.826909"
  },
  {
    "ID": 2109848701,
    "Tankstellennummer": 4552,
    "Name": "Agip Lorsch",
    "Address": "Industriestr. 9",
    "Address__1": "",
    "City": "Lorsch",
    "Postcode": 64653,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 6251/572",
    "24 hour?": "FALSE",
    "Latitude": 49.64247,
    "Longitude": 8.57126,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.64247,8.57126",
    "IsOpen24Hours": false,
    "id": "49.64247,8.57126"
  },
  {
    "ID": 767240775,
    "Tankstellennummer": "TD038968",
    "Name": "LORSCH BAB OST A 67",
    "Address": "BAB OST A 67",
    "Address__1": "",
    "City": "LORSCH",
    "Postcode": 64653,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06251-175180",
    "24 hour?": "FALSE",
    "Latitude": 49.6426,
    "Longitude": 8.5534,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.6426,8.5534",
    "IsOpen24Hours": false,
    "id": "49.6426,8.5534"
  },
  {
    "ID": 983,
    "Tankstellennummer": "0F169",
    "Name": "Aral Tankstelle Heppenheim, Lorscher Str. 53",
    "Address": "Lorscher Straße 53",
    "Address__1": "",
    "City": "Heppenheim",
    "Postcode": 64646,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62527969160,
    "24 hour?": "TRUE",
    "Latitude": 49.643989,
    "Longitude": 8.625536,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.643989,8.625536",
    "IsOpen24Hours": true,
    "id": "49.643989,8.625536"
  },
  {
    "ID": 1735,
    "Tankstellennummer": "0F723",
    "Name": "Aral Tankstelle Bürstadt, Nibelungenstr.197",
    "Address": "Nibelungenstraße 197",
    "Address__1": "",
    "City": "Bürstadt",
    "Postcode": 68642,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6206963564,
    "24 hour?": "TRUE",
    "Latitude": 49.645725,
    "Longitude": 8.473277,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.645725,8.473277",
    "IsOpen24Hours": true,
    "id": "49.645725,8.473277"
  },
  {
    "ID": 1774,
    "Tankstellennummer": "0F752",
    "Name": "Aral Tankstelle Worms, Gaustr. 154",
    "Address": "Gaustraße 156",
    "Address__1": "",
    "City": "Worms",
    "Postcode": 67549,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 624152310,
    "24 hour?": "FALSE",
    "Latitude": 49.646155,
    "Longitude": 8.349439,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.646155,8.349439",
    "IsOpen24Hours": false,
    "id": "49.646155,8.349439"
  },
  {
    "ID": 4153994,
    "Tankstellennummer": 2170,
    "Name": "Agip Birkenfeld",
    "Address": "Friedrich-August-Str. 22",
    "Address__1": "",
    "City": "Birkenfeld",
    "Postcode": 55765,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 67825529",
    "24 hour?": "FALSE",
    "Latitude": 49.64907,
    "Longitude": 7.16119,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.64907,7.16119",
    "IsOpen24Hours": false,
    "id": "49.64907,7.16119"
  },
  {
    "ID": 300857038,
    "Tankstellennummer": 3622,
    "Name": "Agip Markt Bibart",
    "Address": "Wuerzburger Str. 23",
    "Address__1": "",
    "City": "Markt Bibart",
    "Postcode": 91477,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 9162/825",
    "24 hour?": "FALSE",
    "Latitude": 49.64924,
    "Longitude": 10.42396,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.64924,10.42396",
    "IsOpen24Hours": false,
    "id": "49.64924,10.42396"
  },
  {
    "ID": 1738,
    "Tankstellennummer": "0F725",
    "Name": "Aral Tankstelle Lauterecken, Saarbrücker Str. 56",
    "Address": "Saarbrücker Straße 56",
    "Address__1": "",
    "City": "Lauterecken",
    "Postcode": 67742,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63824030325,
    "24 hour?": "FALSE",
    "Latitude": 49.650055,
    "Longitude": 7.58423,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.650055,7.58423",
    "IsOpen24Hours": false,
    "id": "49.650055,7.58423"
  },
  {
    "ID": 3080,
    "Tankstellennummer": "0FJ10",
    "Name": "Aral Tankstelle Birkenfeld, Hochwaldstr. 2",
    "Address": "Hochwaldstraße 2",
    "Address__1": "",
    "City": "Birkenfeld",
    "Postcode": 55765,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 678299390,
    "24 hour?": "FALSE",
    "Latitude": 49.650758,
    "Longitude": 7.157486,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.650758,7.157486",
    "IsOpen24Hours": false,
    "id": "49.650758,7.157486"
  },
  {
    "ID": 1772,
    "Tankstellennummer": "0F750",
    "Name": "Aral Tankstelle Erbach, Neckarstr. 40",
    "Address": "Neckarstraße 40",
    "Address__1": "",
    "City": "Erbach",
    "Postcode": 64711,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60623565,
    "24 hour?": "FALSE",
    "Latitude": 49.652422,
    "Longitude": 8.995297,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.652422,8.995297",
    "IsOpen24Hours": false,
    "id": "49.652422,8.995297"
  },
  {
    "ID": 2038763737,
    "Tankstellennummer": "TD133570",
    "Name": "LORSCH NIBELUNGENSTR. 129",
    "Address": "NIBELUNGENSTR. 129",
    "Address__1": "",
    "City": "LORSCH",
    "Postcode": 64653,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06251-51222",
    "24 hour?": "FALSE",
    "Latitude": 49.6532,
    "Longitude": 8.5575,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.6532,8.5575",
    "IsOpen24Hours": false,
    "id": "49.6532,8.5575"
  },
  {
    "ID": 971,
    "Tankstellennummer": "0F159",
    "Name": "Aral Autohof Worms, Mainzer Straße/B 9",
    "Address": "Mainzer Straße 140",
    "Address__1": "",
    "City": "Worms",
    "Postcode": 67547,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6241490921,
    "24 hour?": "TRUE",
    "Latitude": 49.654737,
    "Longitude": 8.359395,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.654737,8.359395",
    "IsOpen24Hours": true,
    "id": "49.654737,8.359395"
  },
  {
    "ID": 929,
    "Tankstellennummer": "0F126",
    "Name": "Aral Tankstelle Hermeskeil, Trierer Str. 97",
    "Address": "Trierer Straße 97",
    "Address__1": "",
    "City": "Hermeskeil",
    "Postcode": 54411,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6503404,
    "24 hour?": "FALSE",
    "Latitude": 49.657723,
    "Longitude": 6.933154,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.657723,6.933154",
    "IsOpen24Hours": false,
    "id": "49.657723,6.933154"
  },
  {
    "ID": 1759,
    "Tankstellennummer": "0F743",
    "Name": "Aral Tankstelle Kirchheimbolanden, Bischheimer Str. 9",
    "Address": "Bischheimer Straße 9",
    "Address__1": "",
    "City": "Kirchheimbolanden",
    "Postcode": 67292,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 63525016,
    "24 hour?": "TRUE",
    "Latitude": 49.668573,
    "Longitude": 8.02008,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.668573,8.02008",
    "IsOpen24Hours": true,
    "id": "49.668573,8.02008"
  },
  {
    "ID": 1065753408,
    "Tankstellennummer": 3095,
    "Name": "Agip Ochsenfurt",
    "Address": "Wuerzburger Strasse 37",
    "Address__1": "",
    "City": "Ochsenfurt",
    "Postcode": 97199,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 93313125",
    "24 hour?": "FALSE",
    "Latitude": 49.66867228,
    "Longitude": 10.06488621,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.66867228,10.06488621",
    "IsOpen24Hours": false,
    "id": "49.66867228,10.06488621"
  },
  {
    "ID": 2374,
    "Tankstellennummer": "0FB71",
    "Name": "Aral Tankstelle Marktbreit, Mainstraße",
    "Address": "Mainstraße",
    "Address__1": "",
    "City": "Marktbreit",
    "Postcode": 97340,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 93321303,
    "24 hour?": "FALSE",
    "Latitude": 49.669033,
    "Longitude": 10.145292,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.669033,10.145292",
    "IsOpen24Hours": false,
    "id": "49.669033,10.145292"
  },
  {
    "ID": 984,
    "Tankstellennummer": "0F170",
    "Name": "Aral Autohof Bensheim, Amperestraße 1",
    "Address": "Amperestraße 1",
    "Address__1": "",
    "City": "Bensheim",
    "Postcode": 64625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6251581734,
    "24 hour?": "TRUE",
    "Latitude": 49.67291,
    "Longitude": 8.597638,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.67291,8.597638",
    "IsOpen24Hours": true,
    "id": "49.67291,8.597638"
  },
  {
    "ID": 1776,
    "Tankstellennummer": "0F754",
    "Name": "Aral Tankstelle Bensheim, Wormser-Str.53",
    "Address": "Wormser Straße 53",
    "Address__1": "",
    "City": "Bensheim",
    "Postcode": 64625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62512416,
    "24 hour?": "TRUE",
    "Latitude": 49.676285,
    "Longitude": 8.611732,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.676285,8.611732",
    "IsOpen24Hours": true,
    "id": "49.676285,8.611732"
  },
  {
    "ID": 938935477,
    "Tankstellennummer": 4001,
    "Name": "Agip Bensheim",
    "Address": "Darmstaedter Strasse 136",
    "Address__1": "",
    "City": "Bensheim",
    "Postcode": 64625,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62519837",
    "24 hour?": "FALSE",
    "Latitude": 49.68325206,
    "Longitude": 8.62170914,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.68325206,8.62170914",
    "IsOpen24Hours": false,
    "id": "49.68325206,8.62170914"
  },
  {
    "ID": 1017,
    "Tankstellennummer": "0FG41",
    "Name": "Aral Autobahntankstelle Bensheim, Bergstraße Ost (A 5)",
    "Address": "A5",
    "Address__1": "",
    "City": "Bensheim",
    "Postcode": 64625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 625165100,
    "24 hour?": "TRUE",
    "Latitude": 49.68928,
    "Longitude": 8.603341,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.68928,8.603341",
    "IsOpen24Hours": true,
    "id": "49.68928,8.603341"
  },
  {
    "ID": 427828888,
    "Tankstellennummer": "0FO94",
    "Name": "REINSFELD",
    "Address": "A 1",
    "Address__1": "",
    "City": "Reinsfeld",
    "Postcode": 54421,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 65861011,
    "24 hour?": "TRUE",
    "Latitude": 49.689705,
    "Longitude": 6.89999,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.689705,6.89999",
    "IsOpen24Hours": true,
    "id": "49.689705,6.89999"
  },
  {
    "ID": 237035118,
    "Tankstellennummer": "TD130580",
    "Name": "REICHENBERG/ALBERTSHAUSEN AN D",
    "Address": "AN DER BUNDESSTR. 19",
    "Address__1": "",
    "City": "REICHENBERG/ALBERTSHAUSEN",
    "Postcode": 97234,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09334-355",
    "24 hour?": "FALSE",
    "Latitude": 49.692,
    "Longitude": 9.9419,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.692,9.9419",
    "IsOpen24Hours": false,
    "id": "49.692,9.9419"
  },
  {
    "ID": 1635838533,
    "Tankstellennummer": "TD039263",
    "Name": "BIBLIS DARMSTAEDTER STR. 143",
    "Address": "DARMSTAEDTER STR. 143",
    "Address__1": "",
    "City": "BIBLIS",
    "Postcode": 68647,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06245-2007479",
    "24 hour?": "FALSE",
    "Latitude": 49.695,
    "Longitude": 8.4653,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.695,8.4653",
    "IsOpen24Hours": false,
    "id": "49.695,8.4653"
  },
  {
    "ID": 2358,
    "Tankstellennummer": "0FB61",
    "Name": "Aral Tankstelle Höchstadt, Fürther Strasse 1",
    "Address": "Fürther Straße 1",
    "Address__1": "",
    "City": "Höchstadt",
    "Postcode": 91315,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 91938286,
    "24 hour?": "TRUE",
    "Latitude": 49.69798,
    "Longitude": 10.803122,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.69798,10.803122",
    "IsOpen24Hours": true,
    "id": "49.69798,10.803122"
  },
  {
    "ID": 806909553,
    "Tankstellennummer": "0FQ69",
    "Name": "FORCHHEIM",
    "Address": "Am halben Weg 2",
    "Address__1": "",
    "City": "Forchheim",
    "Postcode": 91301,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 91916219620,
    "24 hour?": "TRUE",
    "Latitude": 49.698383,
    "Longitude": 11.0649749,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.698383,11.0649749",
    "IsOpen24Hours": true,
    "id": "49.698383,11.0649749"
  },
  {
    "ID": 1551928756,
    "Tankstellennummer": "0FM68",
    "Name": "GREMSDORF",
    "Address": "Gewerbepark 2",
    "Address__1": "",
    "City": "Gremsdorf",
    "Postcode": 91350,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9193504495,
    "24 hour?": "TRUE",
    "Latitude": 49.698492,
    "Longitude": 10.854734,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.698492,10.854734",
    "IsOpen24Hours": true,
    "id": "49.698492,10.854734"
  },
  {
    "ID": 2031,
    "Tankstellennummer": "0F926",
    "Name": "Aral Tankstelle Adelsdorf, Hochstr.12",
    "Address": "Hochstraße12",
    "Address__1": "",
    "City": "Adelsdorf",
    "Postcode": 91325,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9195925772,
    "24 hour?": "FALSE",
    "Latitude": 49.703501,
    "Longitude": 10.881012,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.703501,10.881012",
    "IsOpen24Hours": false,
    "id": "49.703501,10.881012"
  },
  {
    "ID": 2410,
    "Tankstellennummer": "0FB91",
    "Name": "Aral Tankstelle Miltenberg, Mainzer Str.42",
    "Address": "Mainzer Straße 42",
    "Address__1": "",
    "City": "Miltenberg",
    "Postcode": 63897,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 93712466,
    "24 hour?": "FALSE",
    "Latitude": 49.704222,
    "Longitude": 9.236841,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.704222,9.236841",
    "IsOpen24Hours": false,
    "id": "49.704222,9.236841"
  },
  {
    "ID": 1549818976,
    "Tankstellennummer": "TD039297",
    "Name": "MILTENBERG EICHENBUEHLER STR.",
    "Address": "EICHENBUEHLER STR. 52",
    "Address__1": "",
    "City": "MILTENBERG",
    "Postcode": 63897,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09371-3885",
    "24 hour?": "FALSE",
    "Latitude": 49.7044,
    "Longitude": 9.2673,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7044,9.2673",
    "IsOpen24Hours": false,
    "id": "49.7044,9.2673"
  },
  {
    "ID": 1737,
    "Tankstellennummer": "0F724",
    "Name": "Aral Tankstelle Osthofen, Rheinstr. 47-49",
    "Address": "Rheinstraße 47",
    "Address__1": "",
    "City": "Osthofen",
    "Postcode": 67574,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62425028777,
    "24 hour?": "FALSE",
    "Latitude": 49.704644,
    "Longitude": 8.333475,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.704644,8.333475",
    "IsOpen24Hours": false,
    "id": "49.704644,8.333475"
  },
  {
    "ID": 577384769,
    "Tankstellennummer": "TD000477",
    "Name": "OSTHOFEN RHEINSTR. 38",
    "Address": "RHEINSTR. 38",
    "Address__1": "",
    "City": "OSTHOFEN",
    "Postcode": 67574,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06242-6258",
    "24 hour?": "FALSE",
    "Latitude": 49.7047,
    "Longitude": 8.3318,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7047,8.3318",
    "IsOpen24Hours": false,
    "id": "49.7047,8.3318"
  },
  {
    "ID": 552927,
    "Tankstellennummer": 3615,
    "Name": "Agip Forchheim",
    "Address": "Hafenstrasse 7",
    "Address__1": "",
    "City": "Forchheim",
    "Postcode": 91301,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "09191/9762849",
    "24 hour?": "FALSE",
    "Latitude": 49.70558,
    "Longitude": 11.05819,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.70558,11.05819",
    "IsOpen24Hours": false,
    "id": "49.70558,11.05819"
  },
  {
    "ID": 3097,
    "Tankstellennummer": "0FJ23",
    "Name": "Aral Tankstelle Idar-Oberstein, Hauptstr. 242",
    "Address": "Hauptstraße 242",
    "Address__1": "",
    "City": "Idar-Oberstein",
    "Postcode": 55743,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 678125120,
    "24 hour?": "TRUE",
    "Latitude": 49.709512,
    "Longitude": 7.315356,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.709512,7.315356",
    "IsOpen24Hours": true,
    "id": "49.709512,7.315356"
  },
  {
    "ID": 1127265416,
    "Tankstellennummer": "TD130920",
    "Name": "REICHELSHEIM HEIDELBERGER STR.",
    "Address": "HEIDELBERGER STR.71",
    "Address__1": "",
    "City": "REICHELSHEIM",
    "Postcode": 64385,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06164-913955",
    "24 hour?": "FALSE",
    "Latitude": 49.7102,
    "Longitude": 8.8335,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7102,8.8335",
    "IsOpen24Hours": false,
    "id": "49.7102,8.8335"
  },
  {
    "ID": 1026459422,
    "Tankstellennummer": "TD041541",
    "Name": "KONZ BRUNOSTRASSE 34",
    "Address": "BRUNOSTRASSE 34",
    "Address__1": "",
    "City": "KONZ",
    "Postcode": 54329,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 6501607546,
    "24 hour?": "FALSE",
    "Latitude": 49.7106,
    "Longitude": 6.5911,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7106,6.5911",
    "IsOpen24Hours": false,
    "id": "49.7106,6.5911"
  },
  {
    "ID": 1020853319,
    "Tankstellennummer": 3610,
    "Name": "Agip Forchheim",
    "Address": "Bayreuther Str. 22",
    "Address__1": "",
    "City": "Forchheim",
    "Postcode": 91301,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 91917277",
    "24 hour?": "FALSE",
    "Latitude": 49.71588107,
    "Longitude": 11.07725804,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.71588107,11.07725804",
    "IsOpen24Hours": false,
    "id": "49.71588107,11.07725804"
  },
  {
    "ID": 2336,
    "Tankstellennummer": "0FB46",
    "Name": "Aral Tankstelle Forchheim, Reuther Str.58",
    "Address": "Reuther Straße 58",
    "Address__1": "",
    "City": "Forchheim",
    "Postcode": 91301,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 919194241,
    "24 hour?": "FALSE",
    "Latitude": 49.717754,
    "Longitude": 11.104472,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.717754,11.104472",
    "IsOpen24Hours": false,
    "id": "49.717754,11.104472"
  },
  {
    "ID": 3061,
    "Tankstellennummer": "0FI94",
    "Name": "Aral Tankstelle Reichelsheim, Siegfriedstr. 64",
    "Address": "Siegfriedstraße 64",
    "Address__1": "",
    "City": "Reichelsheim",
    "Postcode": 64385,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61641214,
    "24 hour?": "FALSE",
    "Latitude": 49.722496,
    "Longitude": 8.868194,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.722496,8.868194",
    "IsOpen24Hours": false,
    "id": "49.722496,8.868194"
  },
  {
    "ID": 753937799,
    "Tankstellennummer": 2162,
    "Name": "Agip Idar-Oberstein",
    "Address": "Heidensteilstrasse 8",
    "Address__1": "",
    "City": "Idar-Oberstein",
    "Postcode": 55743,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 67814313",
    "24 hour?": "FALSE",
    "Latitude": 49.72719,
    "Longitude": 7.31222,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.72719,7.31222",
    "IsOpen24Hours": false,
    "id": "49.72719,7.31222"
  },
  {
    "ID": 1593837650,
    "Tankstellennummer": "TD130910",
    "Name": "ZWINGENBERG DARMSTAEDTER STR.4",
    "Address": "DARMSTAEDTER STR.44 - 46",
    "Address__1": "",
    "City": "ZWINGENBERG",
    "Postcode": 64673,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06251-71746",
    "24 hour?": "FALSE",
    "Latitude": 49.7293,
    "Longitude": 8.6096,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7293,8.6096",
    "IsOpen24Hours": false,
    "id": "49.7293,8.6096"
  },
  {
    "ID": 1859,
    "Tankstellennummer": "0F812",
    "Name": "Aral Tankstelle Obermoschel, Richard-Müller-Str.7 A",
    "Address": "Richard-Müller-Straße 7 a",
    "Address__1": "",
    "City": "Obermoschel",
    "Postcode": 67823,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6362993170,
    "24 hour?": "FALSE",
    "Latitude": 49.729474,
    "Longitude": 7.776979,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.729474,7.776979",
    "IsOpen24Hours": false,
    "id": "49.729474,7.776979"
  },
  {
    "ID": 1025221512,
    "Tankstellennummer": "0FY23",
    "Name": "BAT ALSBACH",
    "Address": "A 5",
    "Address__1": "",
    "City": "Alsbach-Hähnlein",
    "Postcode": 64665,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 62573922,
    "24 hour?": "TRUE",
    "Latitude": 49.733063,
    "Longitude": 8.591964,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.733063,8.591964",
    "IsOpen24Hours": true,
    "id": "49.733063,8.591964"
  },
  {
    "ID": 3134,
    "Tankstellennummer": "0FJ49",
    "Name": "Aral Tankstelle Forchheim, Bamberger Str. 91",
    "Address": "Bamberger Straße 91",
    "Address__1": "",
    "City": "Forchheim",
    "Postcode": 91301,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9191727212,
    "24 hour?": "TRUE",
    "Latitude": 49.7339,
    "Longitude": 11.056936,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.7339,11.056936",
    "IsOpen24Hours": true,
    "id": "49.7339,11.056936"
  },
  {
    "ID": 3326,
    "Tankstellennummer": "0FK80",
    "Name": "Aral Tankstelle Idar-Oberstein, Fischbacher Str. 1",
    "Address": "Fischbacher Straße 1",
    "Address__1": "",
    "City": "Idar-Oberstein",
    "Postcode": 55743,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 678499800,
    "24 hour?": "TRUE",
    "Latitude": 49.738978,
    "Longitude": 7.408038,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.738978,7.408038",
    "IsOpen24Hours": true,
    "id": "49.738978,7.408038"
  },
  {
    "ID": 58011811,
    "Tankstellennummer": "TD039578",
    "Name": "TRIER-TARFORST KOHLENSTR. 51",
    "Address": "KOHLENSTR. 51",
    "Address__1": "",
    "City": "TRIER-TARFORST",
    "Postcode": 54296,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0651-9990133",
    "24 hour?": "FALSE",
    "Latitude": 49.7451,
    "Longitude": 6.6916,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7451,6.6916",
    "IsOpen24Hours": false,
    "id": "49.7451,6.6916"
  },
  {
    "ID": 56465170,
    "Tankstellennummer": "TD041533",
    "Name": "TRIER EUREN EUREN STRASSE 92",
    "Address": "EUREN STRASSE 92",
    "Address__1": "",
    "City": "TRIER EUREN",
    "Postcode": 54294,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 6519982926,
    "24 hour?": "FALSE",
    "Latitude": 49.7451,
    "Longitude": 6.6143,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7451,6.6143",
    "IsOpen24Hours": false,
    "id": "49.7451,6.6143"
  },
  {
    "ID": 2380,
    "Tankstellennummer": "0FB76",
    "Name": "Aral Autohof Schlüsselfeld, Attelsdorf Ost",
    "Address": "Attelsdorf 19",
    "Address__1": "",
    "City": "Schlüsselfeld",
    "Postcode": 96132,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9552444,
    "24 hour?": "TRUE",
    "Latitude": 49.745809,
    "Longitude": 10.63429,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.745809,10.63429",
    "IsOpen24Hours": true,
    "id": "49.745809,10.63429"
  },
  {
    "ID": 1060163739,
    "Tankstellennummer": "TD041384",
    "Name": "PEGNITZ, BAB A9 PEGNITZ WEST",
    "Address": "BAB A9 PEGNITZ WEST",
    "Address__1": "",
    "City": "PEGNITZ",
    "Postcode": 91257,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 92414944539,
    "24 hour?": "FALSE",
    "Latitude": 49.7496,
    "Longitude": 11.5107,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7496,11.5107",
    "IsOpen24Hours": false,
    "id": "49.7496,11.5107"
  },
  {
    "ID": 2327,
    "Tankstellennummer": "0FB38",
    "Name": "Aral Tankstelle Pegnitz, Nürnberger Str.39",
    "Address": "Nürnberger Str. 39",
    "Address__1": "",
    "City": "Pegnitz",
    "Postcode": 91257,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 92412713,
    "24 hour?": "FALSE",
    "Latitude": 49.749701,
    "Longitude": 11.539701,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.749701,11.539701",
    "IsOpen24Hours": false,
    "id": "49.749701,11.539701"
  },
  {
    "ID": 1740,
    "Tankstellennummer": "0F727",
    "Name": "Aral Tankstelle Alzey, Schafhäuser Str.2-6",
    "Address": "Schaffhäuser Straße 2-6",
    "Address__1": "",
    "City": "Alzey",
    "Postcode": 55232,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 673142299,
    "24 hour?": "TRUE",
    "Latitude": 49.750778,
    "Longitude": 8.119211,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.750778,8.119211",
    "IsOpen24Hours": true,
    "id": "49.750778,8.119211"
  },
  {
    "ID": 756672669,
    "Tankstellennummer": 4054,
    "Name": "Agip Seeheim-Jugenheim",
    "Address": "Zwingenberger Strasse 25",
    "Address__1": "",
    "City": "Seeheim-Jugenheim",
    "Postcode": 64342,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62579036",
    "24 hour?": "FALSE",
    "Latitude": 49.751854,
    "Longitude": 8.631091,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.751854,8.631091",
    "IsOpen24Hours": false,
    "id": "49.751854,8.631091"
  },
  {
    "ID": 2046,
    "Tankstellennummer": "0FG38",
    "Name": "Aral Autobahntankstelle Würzburg, A 3 (Süd)",
    "Address": "A3",
    "Address__1": "",
    "City": "Würzburg",
    "Postcode": 97084,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 93165907,
    "24 hour?": "TRUE",
    "Latitude": 49.752156,
    "Longitude": 9.962798,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.752156,9.962798",
    "IsOpen24Hours": true,
    "id": "49.752156,9.962798"
  },
  {
    "ID": 2371,
    "Tankstellennummer": "0FB68",
    "Name": "Aral Autobahntankstelle Würzburg, A 3 (Nord)",
    "Address": "A3 /Am Katzenberg",
    "Address__1": "",
    "City": "Würzburg",
    "Postcode": 97084,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9316158090,
    "24 hour?": "TRUE",
    "Latitude": 49.754668,
    "Longitude": 9.961424,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.754668,9.961424",
    "IsOpen24Hours": true,
    "id": "49.754668,9.961424"
  },
  {
    "ID": 891,
    "Tankstellennummer": "0F099",
    "Name": "Aral Tankstelle Trier, Ostallee 22",
    "Address": "Ostallee 22",
    "Address__1": "",
    "City": "Trier",
    "Postcode": 54290,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 65174942,
    "24 hour?": "TRUE",
    "Latitude": 49.754705,
    "Longitude": 6.647396,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.754705,6.647396",
    "IsOpen24Hours": true,
    "id": "49.754705,6.647396"
  },
  {
    "ID": 1673335197,
    "Tankstellennummer": "TD040378",
    "Name": "BIEBELRIED WUERZBURGER STR.",
    "Address": "WUERZBURGER STR. 55",
    "Address__1": "",
    "City": "BIEBELRIED",
    "Postcode": 97318,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09302-6498750",
    "24 hour?": "FALSE",
    "Latitude": 49.7668,
    "Longitude": 10.0758,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7668,10.0758",
    "IsOpen24Hours": false,
    "id": "49.7668,10.0758"
  },
  {
    "ID": 59742648,
    "Tankstellennummer": "TD042036",
    "Name": "GEISELWIND SCHEINFELDER STR. 1",
    "Address": "SCHEINFELDER STR. 15",
    "Address__1": "",
    "City": "GEISELWIND",
    "Postcode": 96160,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 49.768,
    "Longitude": 10.4714,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.768,10.4714",
    "IsOpen24Hours": false,
    "id": "49.768,10.4714"
  },
  {
    "ID": 3269,
    "Tankstellennummer": "0FK39",
    "Name": "Aral Tankstelle Würzburg, Mergentheimer Str. 31",
    "Address": "Mergentheimer Straße 31",
    "Address__1": "",
    "City": "Würzburg",
    "Postcode": 97084,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 931611213,
    "24 hour?": "TRUE",
    "Latitude": 49.768126,
    "Longitude": 9.940464,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.768126,9.940464",
    "IsOpen24Hours": true,
    "id": "49.768126,9.940464"
  },
  {
    "ID": 585047462,
    "Tankstellennummer": "TD036749",
    "Name": "POTTENSTEIN PEGNITZER STRASSE",
    "Address": "PEGNITZER STRASSE 31",
    "Address__1": "",
    "City": "POTTENSTEIN",
    "Postcode": 91278,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09243-312",
    "24 hour?": "FALSE",
    "Latitude": 49.7685,
    "Longitude": 11.4098,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7685,11.4098",
    "IsOpen24Hours": false,
    "id": "49.7685,11.4098"
  },
  {
    "ID": 1620192070,
    "Tankstellennummer": "TD039040",
    "Name": "GEISELWIND SCHEINFELDER STR. 1",
    "Address": "SCHEINFELDER STR. 15",
    "Address__1": "",
    "City": "GEISELWIND",
    "Postcode": 96160,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09556-180",
    "24 hour?": "FALSE",
    "Latitude": 49.7694,
    "Longitude": 10.4704,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7694,10.4704",
    "IsOpen24Hours": false,
    "id": "49.7694,10.4704"
  },
  {
    "ID": 754725998,
    "Tankstellennummer": 4065,
    "Name": "Agip Seeheim-Jugenheim",
    "Address": "Heidelberger Strasse 35",
    "Address__1": "",
    "City": "Seeheim-Jugenheim",
    "Postcode": 64342,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 62579629",
    "24 hour?": "FALSE",
    "Latitude": 49.77138,
    "Longitude": 8.64598,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.77138,8.64598",
    "IsOpen24Hours": false,
    "id": "49.77138,8.64598"
  },
  {
    "ID": 2068970892,
    "Tankstellennummer": "TD040832",
    "Name": "WERTHEIM ALMOSENBERG",
    "Address": "ALMOSENBERG 4",
    "Address__1": "",
    "City": "WERTHEIM",
    "Postcode": 97877,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09342-3029802",
    "24 hour?": "FALSE",
    "Latitude": 49.7716,
    "Longitude": 9.5875,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7716,9.5875",
    "IsOpen24Hours": false,
    "id": "49.7716,9.5875"
  },
  {
    "ID": 711130194,
    "Tankstellennummer": "TD041517",
    "Name": "TRIER ZURMAIENERSTRASSE 155- 1",
    "Address": "ZURMAIENERSTRASSE 155-157",
    "Address__1": "",
    "City": "TRIER",
    "Postcode": 54292,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 6514368353,
    "24 hour?": "FALSE",
    "Latitude": 49.7717,
    "Longitude": 6.6579,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7717,6.6579",
    "IsOpen24Hours": false,
    "id": "49.7717,6.6579"
  },
  {
    "ID": 1022561047,
    "Tankstellennummer": "0FT88",
    "Name": "Aral Tankstelle Ebermannstadt, Forchheimer Straße 44",
    "Address": "Forchheimer Straße 44",
    "Address__1": "",
    "City": "Ebermannstadt",
    "Postcode": 91320,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9194737016,
    "24 hour?": "FALSE",
    "Latitude": 49.776901,
    "Longitude": 11.177896,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.776901,11.177896",
    "IsOpen24Hours": false,
    "id": "49.776901,11.177896"
  },
  {
    "ID": 1892635040,
    "Tankstellennummer": "TD036772",
    "Name": "WUERZBURG KANTSTR. 25",
    "Address": "KANTSTR. 25",
    "Address__1": "",
    "City": "WUERZBURG",
    "Postcode": 97074,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0931-81701",
    "24 hour?": "FALSE",
    "Latitude": 49.778,
    "Longitude": 9.9427,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.778,9.9427",
    "IsOpen24Hours": false,
    "id": "49.778,9.9427"
  },
  {
    "ID": 994,
    "Tankstellennummer": "0F180",
    "Name": "Aral Autohof Dettelbach, Mainfrankenpark 24",
    "Address": "Mainfrankenpark 24",
    "Address__1": "",
    "City": "Dettelbach",
    "Postcode": 97337,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9302932100,
    "24 hour?": "TRUE",
    "Latitude": 49.778483,
    "Longitude": 10.066275,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.778483,10.066275",
    "IsOpen24Hours": true,
    "id": "49.778483,10.066275"
  },
  {
    "ID": 930,
    "Tankstellennummer": "0F127",
    "Name": "Aral Tankstelle Trier, Ruwerer Str. 35",
    "Address": "Ruwerer Straße 35",
    "Address__1": "",
    "City": "Trier",
    "Postcode": 54292,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 65152397,
    "24 hour?": "FALSE",
    "Latitude": 49.780284,
    "Longitude": 6.70244,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.780284,6.70244",
    "IsOpen24Hours": false,
    "id": "49.780284,6.70244"
  },
  {
    "ID": 646048142,
    "Tankstellennummer": 3155,
    "Name": "Agip Haidt",
    "Address": "An der A 3",
    "Address__1": "",
    "City": "Haidt",
    "Postcode": 97355,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 9325200",
    "24 hour?": "TRUE",
    "Latitude": 49.78054801,
    "Longitude": 10.24479844,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.78054801,10.24479844",
    "IsOpen24Hours": true,
    "id": "49.78054801,10.24479844"
  },
  {
    "ID": 1480,
    "Tankstellennummer": "0F540",
    "Name": "Aral Tankstelle Wertheim, Bestenheider Landstr. 70",
    "Address": "Bestenheider Landstraße 7",
    "Address__1": "",
    "City": "Wertheim",
    "Postcode": 97877,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 93429347282,
    "24 hour?": "FALSE",
    "Latitude": 49.781713,
    "Longitude": 9.504071,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.781713,9.504071",
    "IsOpen24Hours": false,
    "id": "49.781713,9.504071"
  },
  {
    "ID": 872403050,
    "Tankstellennummer": "TD041764",
    "Name": "WOERTH AM MAIN HATTSTEINSTRASS",
    "Address": "HATTSTEINSTRASSE 2",
    "Address__1": "",
    "City": "WOERTH AM MAIN",
    "Postcode": 63939,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 93727063484,
    "24 hour?": "FALSE",
    "Latitude": 49.7835,
    "Longitude": 9.17,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7835,9.17",
    "IsOpen24Hours": false,
    "id": "49.7835,9.17"
  },
  {
    "ID": 845,
    "Tankstellennummer": "0F067",
    "Name": "Aral Tankstelle Bad Sobernheim, Monzinger Str. 102-104",
    "Address": "Monzinger Straße 102-104",
    "Address__1": "",
    "City": "Bad Sobernheim",
    "Postcode": 55566,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 67514069,
    "24 hour?": "FALSE",
    "Latitude": 49.787675,
    "Longitude": 7.639332,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.787675,7.639332",
    "IsOpen24Hours": false,
    "id": "49.787675,7.639332"
  },
  {
    "ID": 754940540,
    "Tankstellennummer": 3604,
    "Name": "Agip Hoechberg",
    "Address": "Leistenstrasse 2",
    "Address__1": "",
    "City": "Hoechberg",
    "Postcode": 97204,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 93149266",
    "24 hour?": "FALSE",
    "Latitude": 49.78842072,
    "Longitude": 9.89876343,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.78842072,9.89876343",
    "IsOpen24Hours": false,
    "id": "49.78842072,9.89876343"
  },
  {
    "ID": 2381,
    "Tankstellennummer": "0FB77",
    "Name": "Aral Tankstelle Schwarzach, Kitzinger Str.38",
    "Address": "Kitzinger Straße38",
    "Address__1": "",
    "City": "Schwarzach",
    "Postcode": 97359,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 932499809,
    "24 hour?": "FALSE",
    "Latitude": 49.789618,
    "Longitude": 10.215601,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.789618,10.215601",
    "IsOpen24Hours": false,
    "id": "49.789618,10.215601"
  },
  {
    "ID": 3031,
    "Tankstellennummer": "0FI70",
    "Name": "Aral Tankstelle Gross-Bieberau, Lichtenberger Str. 66",
    "Address": "Lichtenberger Straße 66",
    "Address__1": "",
    "City": "Groß-Bieberau",
    "Postcode": 64401,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6162931427,
    "24 hour?": "FALSE",
    "Latitude": 49.79173,
    "Longitude": 8.821302,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.79173,8.821302",
    "IsOpen24Hours": false,
    "id": "49.79173,8.821302"
  },
  {
    "ID": 2318,
    "Tankstellennummer": "0FB30",
    "Name": "Aral Tankstelle Würzburg, Wörthstr. 1A",
    "Address": "Wörthstraße 1A",
    "Address__1": "",
    "City": "Würzburg",
    "Postcode": 97082,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 93142468,
    "24 hour?": "FALSE",
    "Latitude": 49.793041,
    "Longitude": 9.916193,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.793041,9.916193",
    "IsOpen24Hours": false,
    "id": "49.793041,9.916193"
  },
  {
    "ID": 551320982,
    "Tankstellennummer": 3355,
    "Name": "Agip Buttenheim",
    "Address": "Im Gewerbepark",
    "Address__1": "",
    "City": "Buttenheim",
    "Postcode": 96155,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 95454455",
    "24 hour?": "TRUE",
    "Latitude": 49.79857,
    "Longitude": 11.02748,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.79857,11.02748",
    "IsOpen24Hours": true,
    "id": "49.79857,11.02748"
  },
  {
    "ID": 128060236,
    "Tankstellennummer": "TD003343",
    "Name": "HOCHSTETTEN BINGER LANDSTR. 25",
    "Address": "BINGER LANDSTR. 25",
    "Address__1": "",
    "City": "HOCHSTETTEN",
    "Postcode": 55606,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06752-2066",
    "24 hour?": "FALSE",
    "Latitude": 49.7992,
    "Longitude": 7.5094,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.7992,7.5094",
    "IsOpen24Hours": false,
    "id": "49.7992,7.5094"
  },
  {
    "ID": 2387,
    "Tankstellennummer": "0FB82",
    "Name": "Aral Tankstelle Dettelbach, Bamberger Str. 23",
    "Address": "Bamberger Straße 23",
    "Address__1": "",
    "City": "Dettelbach",
    "Postcode": 97337,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 93241468,
    "24 hour?": "FALSE",
    "Latitude": 49.801515,
    "Longitude": 10.165631,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.801515,10.165631",
    "IsOpen24Hours": false,
    "id": "49.801515,10.165631"
  },
  {
    "ID": 1584082793,
    "Tankstellennummer": "TD040022",
    "Name": "PFUNGSTADT ESCHOLLBRUECKER STR",
    "Address": "ESCHOLLBRUECKER STR.47",
    "Address__1": "",
    "City": "PFUNGSTADT",
    "Postcode": 64319,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 61579390703,
    "24 hour?": "FALSE",
    "Latitude": 49.8042,
    "Longitude": 8.5836,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8042,8.5836",
    "IsOpen24Hours": false,
    "id": "49.8042,8.5836"
  },
  {
    "ID": 704460470,
    "Tankstellennummer": 4077,
    "Name": "Agip Pfungstadt",
    "Address": "Eberstaedter Strasse 115",
    "Address__1": "",
    "City": "Pfungstadt",
    "Postcode": 64319,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61578656",
    "24 hour?": "FALSE",
    "Latitude": 49.80436,
    "Longitude": 8.60201,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.80436,8.60201",
    "IsOpen24Hours": false,
    "id": "49.80436,8.60201"
  },
  {
    "ID": 466512400,
    "Tankstellennummer": "TD041608",
    "Name": "TRIER- EHRANG SERVAISSTRASSE 1",
    "Address": "SERVAISSTRASSE 1",
    "Address__1": "",
    "City": "TRIER- EHRANG",
    "Postcode": 54293,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 6519680914,
    "24 hour?": "FALSE",
    "Latitude": 49.8064,
    "Longitude": 6.6854,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8064,6.6854",
    "IsOpen24Hours": false,
    "id": "49.8064,6.6854"
  },
  {
    "ID": 1228779374,
    "Tankstellennummer": "0FU65",
    "Name": "GROß-BIEBERAU",
    "Address": "Bahnhofstraße 80",
    "Address__1": "",
    "City": "Groß-Bieberau",
    "Postcode": 64401,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6162940610,
    "24 hour?": "FALSE",
    "Latitude": 49.807314,
    "Longitude": 8.82522,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.807314,8.82522",
    "IsOpen24Hours": false,
    "id": "49.807314,8.82522"
  },
  {
    "ID": 965641178,
    "Tankstellennummer": "0F805",
    "Name": "Aral TankStelle Pfungstadt, Darmstädter Straße 2",
    "Address": "Darmstädter Straße 2",
    "Address__1": "",
    "City": "Pfungstadt",
    "Postcode": 64319,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61579896791,
    "24 hour?": "FALSE",
    "Latitude": 49.807423,
    "Longitude": 8.564181,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.807423,8.564181",
    "IsOpen24Hours": false,
    "id": "49.807423,8.564181"
  },
  {
    "ID": 1127161378,
    "Tankstellennummer": "0FS75",
    "Name": "OLGA ACKERMANN",
    "Address": "ALBERT-EINSTEIN-STR. 1 a",
    "Address__1": "",
    "City": "WÜRZBURG",
    "Postcode": 97080,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 93140409720,
    "24 hour?": "TRUE",
    "Latitude": 49.808427,
    "Longitude": 9.886323,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.808427,9.886323",
    "IsOpen24Hours": true,
    "id": "49.808427,9.886323"
  },
  {
    "ID": 1244597,
    "Tankstellennummer": "0FY31",
    "Name": "BAT PFUNGSTADT OST",
    "Address": "A 67",
    "Address__1": "",
    "City": "Pfungstadt",
    "Postcode": 64319,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61573201,
    "24 hour?": "TRUE",
    "Latitude": 49.8107009,
    "Longitude": 8.5776706,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.8107009,8.5776706",
    "IsOpen24Hours": true,
    "id": "49.8107009,8.5776706"
  },
  {
    "ID": 961377653,
    "Tankstellennummer": "0FU85",
    "Name": "PFUNGSTADT",
    "Address": "A67",
    "Address__1": "",
    "City": "Pfungstadt",
    "Postcode": 64319,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 615785531,
    "24 hour?": "TRUE",
    "Latitude": 49.813422,
    "Longitude": 8.578055,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.813422,8.578055",
    "IsOpen24Hours": true,
    "id": "49.813422,8.578055"
  },
  {
    "ID": 2133237740,
    "Tankstellennummer": "TD036863",
    "Name": "BREUBERG-SANDBACH HOECHSTER ST",
    "Address": "HOECHSTER STR. 33",
    "Address__1": "",
    "City": "BREUBERG-SANDBACH",
    "Postcode": 64747,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06163-3359",
    "24 hour?": "FALSE",
    "Latitude": 49.8151,
    "Longitude": 9.0116,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8151,9.0116",
    "IsOpen24Hours": false,
    "id": "49.8151,9.0116"
  },
  {
    "ID": 887,
    "Tankstellennummer": "0F097",
    "Name": "Aral Tankstelle Morbach, Hunsrueckhöhenstrasse",
    "Address": "Hunsrückhöhenstraße",
    "Address__1": "",
    "City": "Morbach",
    "Postcode": 54497,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 65333195,
    "24 hour?": "FALSE",
    "Latitude": 49.816904,
    "Longitude": 7.12788,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.816904,7.12788",
    "IsOpen24Hours": false,
    "id": "49.816904,7.12788"
  },
  {
    "ID": 1805,
    "Tankstellennummer": "0F774",
    "Name": "Aral Tankstelle Wöllstein, Höllerstr.16",
    "Address": "Höllerstraße 16",
    "Address__1": "",
    "City": "Wöllstein",
    "Postcode": 55597,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6703960203,
    "24 hour?": "FALSE",
    "Latitude": 49.816959,
    "Longitude": 7.960743,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.816959,7.960743",
    "IsOpen24Hours": false,
    "id": "49.816959,7.960743"
  },
  {
    "ID": 1625297263,
    "Tankstellennummer": "0FQ21",
    "Name": "WINDISCHESCHENBACH",
    "Address": "A93",
    "Address__1": "",
    "City": "Windischeschenbach",
    "Postcode": 92670,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 96819199770,
    "24 hour?": "TRUE",
    "Latitude": 49.816969,
    "Longitude": 12.1706662,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.816969,12.1706662",
    "IsOpen24Hours": true,
    "id": "49.816969,12.1706662"
  },
  {
    "ID": 848,
    "Tankstellennummer": "0F070",
    "Name": "Aral Tankstelle Waldböckelheim, Kreuznacher Str. / B 41",
    "Address": "Kreuznacher Straße/B 41",
    "Address__1": "",
    "City": "Waldböckelheim",
    "Postcode": 55596,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 67586273,
    "24 hour?": "FALSE",
    "Latitude": 49.819016,
    "Longitude": 7.721366,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.819016,7.721366",
    "IsOpen24Hours": false,
    "id": "49.819016,7.721366"
  },
  {
    "ID": 3018,
    "Tankstellennummer": "0FI63",
    "Name": "Aral Tankstelle Darmstadt, Reuterallee 51",
    "Address": "Reuterallee 51",
    "Address__1": "",
    "City": "Darmstadt",
    "Postcode": 64297,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 615152527,
    "24 hour?": "FALSE",
    "Latitude": 49.821703,
    "Longitude": 8.641593,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.821703,8.641593",
    "IsOpen24Hours": false,
    "id": "49.821703,8.641593"
  },
  {
    "ID": 205475478,
    "Tankstellennummer": 3165,
    "Name": "Agip Marktheidenfeld",
    "Address": "Triefensteiner Str. 1",
    "Address__1": "",
    "City": "Marktheidenfeld",
    "Postcode": 97828,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "09391/8101857",
    "24 hour?": "FALSE",
    "Latitude": 49.82291,
    "Longitude": 9.56295,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.82291,9.56295",
    "IsOpen24Hours": false,
    "id": "49.82291,9.56295"
  },
  {
    "ID": 931,
    "Tankstellennummer": "0F128",
    "Name": "Aral Tankstelle Schweich, Oberstiftstr 42a",
    "Address": "Oberstiftstr 41 A",
    "Address__1": "",
    "City": "Schweich",
    "Postcode": 54338,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 65022225,
    "24 hour?": "FALSE",
    "Latitude": 49.826963,
    "Longitude": 6.753925,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.826963,6.753925",
    "IsOpen24Hours": false,
    "id": "49.826963,6.753925"
  },
  {
    "ID": 1618079671,
    "Tankstellennummer": "TD041558",
    "Name": "WELSCHBILLIG WINDMUEHLE / B51",
    "Address": "WINDMUEHLE B51",
    "Address__1": "",
    "City": "WELSCHBILLIG",
    "Postcode": 54298,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 650699044,
    "24 hour?": "FALSE",
    "Latitude": 49.8283,
    "Longitude": 6.5643,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8283,6.5643",
    "IsOpen24Hours": false,
    "id": "49.8283,6.5643"
  },
  {
    "ID": 3041,
    "Tankstellennummer": "0FI78",
    "Name": "Aral Tankstelle Mühltal, Odenwaldstr. 13",
    "Address": "Odenwaldstraße 13",
    "Address__1": "",
    "City": "Mühltal",
    "Postcode": 64367,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 615114269,
    "24 hour?": "FALSE",
    "Latitude": 49.829868,
    "Longitude": 8.706072,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.829868,8.706072",
    "IsOpen24Hours": false,
    "id": "49.829868,8.706072"
  },
  {
    "ID": 496018176,
    "Tankstellennummer": "TD133480",
    "Name": "RIEDSTADT-GODDELAU ROEMERSTR.",
    "Address": "ROEMERSTR. 1",
    "Address__1": "",
    "City": "RIEDSTADT-GODDELAU",
    "Postcode": 64560,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06158-184056",
    "24 hour?": "FALSE",
    "Latitude": 49.8347,
    "Longitude": 8.4841,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8347,8.4841",
    "IsOpen24Hours": false,
    "id": "49.8347,8.4841"
  },
  {
    "ID": 494301452,
    "Tankstellennummer": "0FN26",
    "Name": "GAU-BICKELHEIM",
    "Address": "Wöllsteiner Straße 11",
    "Address__1": "",
    "City": "Gau-Bickelheim",
    "Postcode": 55599,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6701961510,
    "24 hour?": "FALSE",
    "Latitude": 49.834917,
    "Longitude": 8.015878,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.834917,8.015878",
    "IsOpen24Hours": false,
    "id": "49.834917,8.015878"
  },
  {
    "ID": 2330,
    "Tankstellennummer": "0FB40",
    "Name": "Aral Tankstelle Erbendorf, Tirschenreuther Str.20",
    "Address": "Tirschenreuther Straße 20",
    "Address__1": "",
    "City": "Erbendorf",
    "Postcode": 92681,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 96821733,
    "24 hour?": "FALSE",
    "Latitude": 49.835548,
    "Longitude": 12.054758,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.835548,12.054758",
    "IsOpen24Hours": false,
    "id": "49.835548,12.054758"
  },
  {
    "ID": 644938615,
    "Tankstellennummer": "TD003236",
    "Name": "BAD KREUZNACH ALZEYER STR. 73",
    "Address": "ALZEYER STR. 73",
    "Address__1": "",
    "City": "BAD KREUZNACH",
    "Postcode": 55543,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0671-68373",
    "24 hour?": "FALSE",
    "Latitude": 49.8366,
    "Longitude": 7.8772,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8366,7.8772",
    "IsOpen24Hours": false,
    "id": "49.8366,7.8772"
  },
  {
    "ID": 854003625,
    "Tankstellennummer": "TD038257",
    "Name": "WOERRSTADT",
    "Address": "4 SOPHIE-OPEL STR.",
    "Address__1": "",
    "City": "WOERRSTADT",
    "Postcode": 55286,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 67322775117,
    "24 hour?": "FALSE",
    "Latitude": 49.8398,
    "Longitude": 8.1423,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8398,8.1423",
    "IsOpen24Hours": false,
    "id": "49.8398,8.1423"
  },
  {
    "ID": 1890386655,
    "Tankstellennummer": "TD040816",
    "Name": "KUERNACH UNTERER KELLERMANN 1",
    "Address": "UNTERER KELLERMANN 1",
    "Address__1": "",
    "City": "KUERNACH",
    "Postcode": 97273,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 93679889070,
    "24 hour?": "FALSE",
    "Latitude": 49.84,
    "Longitude": 10.0183,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.84,10.0183",
    "IsOpen24Hours": false,
    "id": "49.84,10.0183"
  },
  {
    "ID": 846,
    "Tankstellennummer": "0F068",
    "Name": "Aral Tankstelle Bad Kreuznach, Bosenheimer Str. 45",
    "Address": "Bosenheimer Straße 45",
    "Address__1": "",
    "City": "Bad Kreuznach",
    "Postcode": 55543,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6718962600,
    "24 hour?": "TRUE",
    "Latitude": 49.841989,
    "Longitude": 7.867241,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.841989,7.867241",
    "IsOpen24Hours": true,
    "id": "49.841989,7.867241"
  },
  {
    "ID": 2098078559,
    "Tankstellennummer": "0FT96",
    "Name": "OPPENHEIM",
    "Address": "Am Wattengraben 2",
    "Address__1": "",
    "City": "Oppenheim",
    "Postcode": 55276,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61333869197,
    "24 hour?": "TRUE",
    "Latitude": 49.845664,
    "Longitude": 8.360125,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.845664,8.360125",
    "IsOpen24Hours": true,
    "id": "49.845664,8.360125"
  },
  {
    "ID": 2403,
    "Tankstellennummer": "0FB88",
    "Name": "Aral Tankstelle Obernburg, Römerstrasse 98",
    "Address": "Römerstraße 98",
    "Address__1": "",
    "City": "Obernburg",
    "Postcode": 63785,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 602272033,
    "24 hour?": "FALSE",
    "Latitude": 49.846627,
    "Longitude": 9.151591,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.846627,9.151591",
    "IsOpen24Hours": false,
    "id": "49.846627,9.151591"
  },
  {
    "ID": 991,
    "Tankstellennummer": "0F177",
    "Name": "Aral Tankstelle Bad Kreuznach, Schwabenheimer Weg 135",
    "Address": "Schwabenheimer Weg 135",
    "Address__1": "",
    "City": "Bad Kreuznach",
    "Postcode": 55543,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6718965105,
    "24 hour?": "TRUE",
    "Latitude": 49.851806,
    "Longitude": 7.892767,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.851806,7.892767",
    "IsOpen24Hours": true,
    "id": "49.851806,7.892767"
  },
  {
    "ID": 1299784629,
    "Tankstellennummer": "0FN41",
    "Name": "WÖRRSTADT",
    "Address": "Ober-Saulheimer Straße 17",
    "Address__1": "",
    "City": "Wörrstadt",
    "Postcode": 55286,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 67321403,
    "24 hour?": "FALSE",
    "Latitude": 49.852648,
    "Longitude": 8.123193,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.852648,8.123193",
    "IsOpen24Hours": false,
    "id": "49.852648,8.123193"
  },
  {
    "ID": 1779,
    "Tankstellennummer": "0F757",
    "Name": "Aral Tankstelle Darmstadt, Rüdesheimer Str. 114",
    "Address": "Rüdesheimer Straße 114",
    "Address__1": "",
    "City": "Darmstadt",
    "Postcode": 64295,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 615163545,
    "24 hour?": "TRUE",
    "Latitude": 49.854019,
    "Longitude": 8.641989,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.854019,8.641989",
    "IsOpen24Hours": true,
    "id": "49.854019,8.641989"
  },
  {
    "ID": 2044562062,
    "Tankstellennummer": 4561,
    "Name": "Agip Riedstadt-Wolfskehlen",
    "Address": "Griesheimer Str. 31",
    "Address__1": "",
    "City": "Riedstadt-Wolfskehlen",
    "Postcode": 64560,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61589752",
    "24 hour?": "FALSE",
    "Latitude": 49.85529487,
    "Longitude": 8.50622223,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.85529487,8.50622223",
    "IsOpen24Hours": false,
    "id": "49.85529487,8.50622223"
  },
  {
    "ID": 1010915382,
    "Tankstellennummer": "0FT77",
    "Name": "ELSENFELD",
    "Address": "Im Höning",
    "Address__1": "",
    "City": "Elsenfeld",
    "Postcode": 63820,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60225062265,
    "24 hour?": "FALSE",
    "Latitude": 49.856072,
    "Longitude": 9.167702,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.856072,9.167702",
    "IsOpen24Hours": false,
    "id": "49.856072,9.167702"
  },
  {
    "ID": 1766,
    "Tankstellennummer": "0F746",
    "Name": "Aral Tankstelle Darmstadt, Niederramstädter Str.170",
    "Address": "Niederramstädter Str. 170",
    "Address__1": "",
    "City": "Darmstadt",
    "Postcode": 64285,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 615148696,
    "24 hour?": "FALSE",
    "Latitude": 49.856413,
    "Longitude": 8.669227,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.856413,8.669227",
    "IsOpen24Hours": false,
    "id": "49.856413,8.669227"
  },
  {
    "ID": 3126,
    "Tankstellennummer": "0FJ43",
    "Name": "Aral Tankstelle Volkach, Sommeracher Str. 35",
    "Address": "Sommeracher Straße 35",
    "Address__1": "",
    "City": "Volkach",
    "Postcode": 97332,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9381809616,
    "24 hour?": "FALSE",
    "Latitude": 49.860187,
    "Longitude": 10.227348,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.860187,10.227348",
    "IsOpen24Hours": false,
    "id": "49.860187,10.227348"
  },
  {
    "ID": 2411,
    "Tankstellennummer": "0FB92",
    "Name": "Aral Tankstelle Dammbach, Wintersbacher Str.7",
    "Address": "Wintersbacher Straße 7",
    "Address__1": "",
    "City": "Dammbach",
    "Postcode": 63874,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60921539,
    "24 hour?": "FALSE",
    "Latitude": 49.860261,
    "Longitude": 9.291362,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.860261,9.291362",
    "IsOpen24Hours": false,
    "id": "49.860261,9.291362"
  },
  {
    "ID": 1015156806,
    "Tankstellennummer": "TD003384",
    "Name": "OPPENHEIM HAFENSTR. 41",
    "Address": "HAFENSTR. 41",
    "Address__1": "",
    "City": "OPPENHEIM",
    "Postcode": 55276,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06133-9427",
    "24 hour?": "FALSE",
    "Latitude": 49.8606,
    "Longitude": 8.3568,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8606,8.3568",
    "IsOpen24Hours": false,
    "id": "49.8606,8.3568"
  },
  {
    "ID": 395398259,
    "Tankstellennummer": "TD000945",
    "Name": "DARMSTADT HEIDELBERGER STR. 55",
    "Address": "HEIDELBERGER STR. 55",
    "Address__1": "",
    "City": "DARMSTADT",
    "Postcode": 64285,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06151-316459",
    "24 hour?": "FALSE",
    "Latitude": 49.8614,
    "Longitude": 8.6468,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8614,8.6468",
    "IsOpen24Hours": false,
    "id": "49.8614,8.6468"
  },
  {
    "ID": 1039695905,
    "Tankstellennummer": 3083,
    "Name": "Agip Stegaurach",
    "Address": "Bamberger Strasse 39",
    "Address__1": "",
    "City": "Stegaurach",
    "Postcode": 96135,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 95129333",
    "24 hour?": "FALSE",
    "Latitude": 49.86295938,
    "Longitude": 10.84646514,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.86295938,10.84646514",
    "IsOpen24Hours": false,
    "id": "49.86295938,10.84646514"
  },
  {
    "ID": 1565602169,
    "Tankstellennummer": "TD035691",
    "Name": "NIERSTEIN BOSCHSTR. 2",
    "Address": "BOSCHSTR. 2",
    "Address__1": "",
    "City": "NIERSTEIN",
    "Postcode": 55283,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06133-491802",
    "24 hour?": "FALSE",
    "Latitude": 49.8633,
    "Longitude": 8.333,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8633,8.333",
    "IsOpen24Hours": false,
    "id": "49.8633,8.333"
  },
  {
    "ID": 1037248113,
    "Tankstellennummer": "TD005983",
    "Name": "GROSS-UMSTADT MOERSWEG 40",
    "Address": "MOERSWEG 40",
    "Address__1": "",
    "City": "GROSS-UMSTADT",
    "Postcode": 64823,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06078-75712",
    "24 hour?": "FALSE",
    "Latitude": 49.8655,
    "Longitude": 8.9306,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8655,8.9306",
    "IsOpen24Hours": false,
    "id": "49.8655,8.9306"
  },
  {
    "ID": 844,
    "Tankstellennummer": "0F066",
    "Name": "Aral Tankstelle Roxheim, Hauptstrasse 1",
    "Address": "Hauptstraße 1",
    "Address__1": "",
    "City": "Roxheim",
    "Postcode": 55595,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 67120354475,
    "24 hour?": "FALSE",
    "Latitude": 49.867574,
    "Longitude": 7.820235,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.867574,7.820235",
    "IsOpen24Hours": false,
    "id": "49.867574,7.820235"
  },
  {
    "ID": 3019,
    "Tankstellennummer": "0FI64",
    "Name": "Aral Tankstelle Darmstadt, Heinrichstr. 241",
    "Address": "Heinrichstraße 241",
    "Address__1": "",
    "City": "Darmstadt",
    "Postcode": 64287,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 615147843,
    "24 hour?": "FALSE",
    "Latitude": 49.867749,
    "Longitude": 8.681954,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.867749,8.681954",
    "IsOpen24Hours": false,
    "id": "49.867749,8.681954"
  },
  {
    "ID": 3017,
    "Tankstellennummer": "0FI62",
    "Name": "Aral Tankstelle Darmstadt, Neckarstr. 19",
    "Address": "Neckarstraße 19",
    "Address__1": "",
    "City": "Darmstadt",
    "Postcode": 64283,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6151315733,
    "24 hour?": "TRUE",
    "Latitude": 49.86853,
    "Longitude": 8.645511,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.86853,8.645511",
    "IsOpen24Hours": true,
    "id": "49.86853,8.645511"
  },
  {
    "ID": 1022367398,
    "Tankstellennummer": 3163,
    "Name": "Agip Speichersdorf",
    "Address": "Laibacher Weg 1",
    "Address__1": "",
    "City": "Speichersdorf",
    "Postcode": 95469,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "09275/6059362",
    "24 hour?": "FALSE",
    "Latitude": 49.87047,
    "Longitude": 11.77287,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.87047,11.77287",
    "IsOpen24Hours": false,
    "id": "49.87047,11.77287"
  },
  {
    "ID": 1730,
    "Tankstellennummer": "0F721",
    "Name": "Aral Tankstelle Darmstadt, Rheinstr. 69",
    "Address": "Rheinstraße 69",
    "Address__1": "",
    "City": "Darmstadt",
    "Postcode": 64295,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6151891309,
    "24 hour?": "TRUE",
    "Latitude": 49.871594,
    "Longitude": 8.639391,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.871594,8.639391",
    "IsOpen24Hours": true,
    "id": "49.871594,8.639391"
  },
  {
    "ID": 3032,
    "Tankstellennummer": "0FI71",
    "Name": "Aral Tankstelle Gross-Zimmern, Darmstaedter Str. 49A",
    "Address": "Darmstaedter Straße 49A",
    "Address__1": "",
    "City": "Groß-Zimmern",
    "Postcode": 64846,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6071951360,
    "24 hour?": "FALSE",
    "Latitude": 49.873131,
    "Longitude": 8.820814,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.873131,8.820814",
    "IsOpen24Hours": false,
    "id": "49.873131,8.820814"
  },
  {
    "ID": 2323,
    "Tankstellennummer": "0FB34",
    "Name": "Aral Tankstelle Bamberg, Würzburger Str. 76",
    "Address": "Würzburger Straße 76",
    "Address__1": "",
    "City": "Bamberg",
    "Postcode": 96049,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 95153020,
    "24 hour?": "FALSE",
    "Latitude": 49.877672,
    "Longitude": 10.884029,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.877672,10.884029",
    "IsOpen24Hours": false,
    "id": "49.877672,10.884029"
  },
  {
    "ID": 2946,
    "Tankstellennummer": "0FI06",
    "Name": "Aral Tankstelle Hetzerath, Wittlicher Str. 1",
    "Address": "Wittlicher Straße 1",
    "Address__1": "",
    "City": "Hetzerath",
    "Postcode": 54523,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6508918825,
    "24 hour?": "FALSE",
    "Latitude": 49.878967,
    "Longitude": 6.814709,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.878967,6.814709",
    "IsOpen24Hours": false,
    "id": "49.878967,6.814709"
  },
  {
    "ID": 3110,
    "Tankstellennummer": "0FJ32",
    "Name": "Aral Tankstelle Bamberg, Am Luitpoldhain 1 A",
    "Address": "Am Luitpoldhain 1 A",
    "Address__1": "",
    "City": "Bamberg",
    "Postcode": 96050,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 951130725,
    "24 hour?": "TRUE",
    "Latitude": 49.886665,
    "Longitude": 10.906391,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.886665,10.906391",
    "IsOpen24Hours": true,
    "id": "49.886665,10.906391"
  },
  {
    "ID": 818757544,
    "Tankstellennummer": "TD002774",
    "Name": "DARMSTADT SIEMENSSTR. 2",
    "Address": "SIEMENSSTR. 2",
    "Address__1": "",
    "City": "DARMSTADT",
    "Postcode": 64289,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06151-4929866",
    "24 hour?": "FALSE",
    "Latitude": 49.8961,
    "Longitude": 8.6806,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8961,8.6806",
    "IsOpen24Hours": false,
    "id": "49.8961,8.6806"
  },
  {
    "ID": 550714564,
    "Tankstellennummer": "0FU88",
    "Name": "WEIBERSBRUNN",
    "Address": "A3",
    "Address__1": "",
    "City": "Weibersbrunn",
    "Postcode": 63879,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "TRUE",
    "Latitude": 49.896365,
    "Longitude": 9.395583,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.896365,9.395583",
    "IsOpen24Hours": true,
    "id": "49.896365,9.395583"
  },
  {
    "ID": 2386,
    "Tankstellennummer": "0FB81",
    "Name": "Aral Tankstelle Bergtheim, Würzburger Str.1u.3",
    "Address": "Würzburger Straße 1u.3",
    "Address__1": "",
    "City": "Bergtheim",
    "Postcode": 97241,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9367522,
    "24 hour?": "FALSE",
    "Latitude": 49.897438,
    "Longitude": 10.067211,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.897438,10.067211",
    "IsOpen24Hours": false,
    "id": "49.897438,10.067211"
  },
  {
    "ID": 955,
    "Tankstellennummer": "0F146",
    "Name": "Aral Autobahntankstelle Weibersbrunn, Spessart Süd (A 3)",
    "Address": "A3",
    "Address__1": "",
    "City": "Weibersbrunn",
    "Postcode": 63879,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60949889336,
    "24 hour?": "TRUE",
    "Latitude": 49.89745,
    "Longitude": 9.392536,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.89745,9.392536",
    "IsOpen24Hours": true,
    "id": "49.89745,9.392536"
  },
  {
    "ID": 1847,
    "Tankstellennummer": "0F804",
    "Name": "Aral Tankstelle Dieburg, Altstadt 37",
    "Address": "Groß-Umstädter Straße 1",
    "Address__1": "",
    "City": "Dieburg",
    "Postcode": 64807,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6071499697,
    "24 hour?": "FALSE",
    "Latitude": 49.8991,
    "Longitude": 8.846725,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.8991,8.846725",
    "IsOpen24Hours": false,
    "id": "49.8991,8.846725"
  },
  {
    "ID": 1404899981,
    "Tankstellennummer": "0FM92",
    "Name": "LANGENLONSHEIM",
    "Address": "Naheweinstraße 182",
    "Address__1": "",
    "City": "Langenlonsheim",
    "Postcode": 55450,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6704961595,
    "24 hour?": "FALSE",
    "Latitude": 49.901101,
    "Longitude": 7.899361,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.901101,7.899361",
    "IsOpen24Hours": false,
    "id": "49.901101,7.899361"
  },
  {
    "ID": 1769,
    "Tankstellennummer": "0F748",
    "Name": "Aral Tankstelle Weiterstadt, Darmstädter Str.122",
    "Address": "Darmstädter Straße122",
    "Address__1": "",
    "City": "Weiterstadt",
    "Postcode": 64331,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61502190,
    "24 hour?": "FALSE",
    "Latitude": 49.90225,
    "Longitude": 8.594787,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.90225,8.594787",
    "IsOpen24Hours": false,
    "id": "49.90225,8.594787"
  },
  {
    "ID": 863169097,
    "Tankstellennummer": "TD171360",
    "Name": "BAMBERG ZOLLNERSTR. 2",
    "Address": "ZOLLNERSTR. 2",
    "Address__1": "",
    "City": "BAMBERG",
    "Postcode": 96052,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 95118329691,
    "24 hour?": "FALSE",
    "Latitude": 49.9025,
    "Longitude": 10.8997,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9025,10.8997",
    "IsOpen24Hours": false,
    "id": "49.9025,10.8997"
  },
  {
    "ID": 2319,
    "Tankstellennummer": "0FB31",
    "Name": "Aral Tankstelle Bamberg, Memmelsdorfer Str. 80",
    "Address": "Memmelsdorfer Straße 80",
    "Address__1": "",
    "City": "Bamberg",
    "Postcode": 96052,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 95131562,
    "24 hour?": "TRUE",
    "Latitude": 49.905601,
    "Longitude": 10.896485,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.905601,10.896485",
    "IsOpen24Hours": true,
    "id": "49.905601,10.896485"
  },
  {
    "ID": 12501,
    "Tankstellennummer": "0FL53",
    "Name": "Aral Tankstelle Büttelborn, Mainzer Str. 16 a",
    "Address": "Mainzer Straße 116a",
    "Address__1": "",
    "City": "Büttelborn",
    "Postcode": 64572,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61529879262,
    "24 hour?": "TRUE",
    "Latitude": 49.906551,
    "Longitude": 8.503033,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.906551,8.503033",
    "IsOpen24Hours": true,
    "id": "49.906551,8.503033"
  },
  {
    "ID": 65504620,
    "Tankstellennummer": "0FS21",
    "Name": "NIEDER-OLM",
    "Address": "Pariser Straße 140",
    "Address__1": "",
    "City": "Nieder-Olm",
    "Postcode": 55268,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61369538615,
    "24 hour?": "FALSE",
    "Latitude": 49.907236,
    "Longitude": 8.19893,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.907236,8.19893",
    "IsOpen24Hours": false,
    "id": "49.907236,8.19893"
  },
  {
    "ID": 2027,
    "Tankstellennummer": "0F922",
    "Name": "Aral Tankstelle Bamberg, Rodezstr. 2 a",
    "Address": "Rodezstraße 2 a",
    "Address__1": "",
    "City": "Bamberg",
    "Postcode": 96052,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9513090155,
    "24 hour?": "TRUE",
    "Latitude": 49.914137,
    "Longitude": 10.908761,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.914137,10.908761",
    "IsOpen24Hours": true,
    "id": "49.914137,10.908761"
  },
  {
    "ID": 28417,
    "Tankstellennummer": "0FP39",
    "Name": "Aral Tankstelle Niedernberg, Am Knückel 1",
    "Address": "Am Knückel 1",
    "Address__1": "",
    "City": "Niedernberg",
    "Postcode": 63843,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60283078950,
    "24 hour?": "FALSE",
    "Latitude": 49.914553,
    "Longitude": 9.121742,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.914553,9.121742",
    "IsOpen24Hours": false,
    "id": "49.914553,9.121742"
  },
  {
    "ID": 1748,
    "Tankstellennummer": "0F733",
    "Name": "Aral Tankstelle Darmstadt, Frankfurter Landstr. 257",
    "Address": "Frankfurter Landstr. 257",
    "Address__1": "",
    "City": "Darmstadt",
    "Postcode": 64291,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61511015810,
    "24 hour?": "FALSE",
    "Latitude": 49.918192,
    "Longitude": 8.654759,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.918192,8.654759",
    "IsOpen24Hours": false,
    "id": "49.918192,8.654759"
  },
  {
    "ID": 896,
    "Tankstellennummer": "0F104",
    "Name": "Aral Tankstelle Groß-Gerau, Oppenheimer Str.7",
    "Address": "Oppenheimer Str. 7",
    "Address__1": "",
    "City": "Groß-Gerau",
    "Postcode": 64521,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61527854,
    "24 hour?": "TRUE",
    "Latitude": 49.921675,
    "Longitude": 8.472126,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.921675,8.472126",
    "IsOpen24Hours": true,
    "id": "49.921675,8.472126"
  },
  {
    "ID": 280675191,
    "Tankstellennummer": "TD035683",
    "Name": "MAINZ-EBERSHEIM AM KESSELTAL 4",
    "Address": "AM KESSELTAL 4",
    "Address__1": "",
    "City": "MAINZ-EBERSHEIM",
    "Postcode": 55129,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06136-954195",
    "24 hour?": "FALSE",
    "Latitude": 49.9224,
    "Longitude": 8.2658,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9224,8.2658",
    "IsOpen24Hours": false,
    "id": "49.9224,8.2658"
  },
  {
    "ID": 849208736,
    "Tankstellennummer": "TD035709",
    "Name": "WALDLAUBERSHEIM AUTOHOF AN DER",
    "Address": "AUTOHOF AN DER BAB 61",
    "Address__1": "",
    "City": "WALDLAUBERSHEIM",
    "Postcode": 55444,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06707 242984863",
    "24 hour?": "FALSE",
    "Latitude": 49.9242,
    "Longitude": 7.8247,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9242,7.8247",
    "IsOpen24Hours": false,
    "id": "49.9242,7.8247"
  },
  {
    "ID": 1676219315,
    "Tankstellennummer": "0FQ83",
    "Name": "BINGEN",
    "Address": "Gustav-Stresemann-Straße",
    "Address__1": "",
    "City": "Bingen",
    "Postcode": 55411,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 67219628623,
    "24 hour?": "TRUE",
    "Latitude": 49.925073,
    "Longitude": 7.93675,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.925073,7.93675",
    "IsOpen24Hours": true,
    "id": "49.925073,7.93675"
  },
  {
    "ID": 2412,
    "Tankstellennummer": "0FB93",
    "Name": "Aral Tankstelle Großostheim, Aschaffenburger Str.33",
    "Address": "Aschaffenburger Straße33",
    "Address__1": "",
    "City": "Großostheim",
    "Postcode": 63762,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60269779501,
    "24 hour?": "FALSE",
    "Latitude": 49.926198,
    "Longitude": 9.079335,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.926198,9.079335",
    "IsOpen24Hours": false,
    "id": "49.926198,9.079335"
  },
  {
    "ID": 687889847,
    "Tankstellennummer": "0FR72",
    "Name": "BUCHENBEUREN",
    "Address": "Im Schiffels 1",
    "Address__1": "",
    "City": "Büchenbeuren",
    "Postcode": 55491,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 65435005438,
    "24 hour?": "FALSE",
    "Latitude": 49.92726,
    "Longitude": 7.279584,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.92726,7.279584",
    "IsOpen24Hours": false,
    "id": "49.92726,7.279584"
  },
  {
    "ID": 859803637,
    "Tankstellennummer": 4053,
    "Name": "Agip Muenster",
    "Address": "Frankfurter Strasse 50",
    "Address__1": "",
    "City": "Muenster",
    "Postcode": 64839,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 60713054",
    "24 hour?": "FALSE",
    "Latitude": 49.92920876,
    "Longitude": 8.85750393,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.92920876,8.85750393",
    "IsOpen24Hours": false,
    "id": "49.92920876,8.85750393"
  },
  {
    "ID": 851,
    "Tankstellennummer": "0F073",
    "Name": "Aral Tankstelle Trebur, Vordere Oderstrasse 2",
    "Address": "Vordere Oderstraße 2",
    "Address__1": "",
    "City": "Trebur",
    "Postcode": 65468,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61473139,
    "24 hour?": "FALSE",
    "Latitude": 49.93035,
    "Longitude": 8.406678,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.93035,8.406678",
    "IsOpen24Hours": false,
    "id": "49.93035,8.406678"
  },
  {
    "ID": 243929392,
    "Tankstellennummer": "TD170290",
    "Name": "MEMMELSDORF BAMBERGER STR. 6",
    "Address": "BAMBERGER STR. 6",
    "Address__1": "",
    "City": "MEMMELSDORF",
    "Postcode": 96117,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0951-44291",
    "24 hour?": "FALSE",
    "Latitude": 49.9314,
    "Longitude": 10.9509,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9314,10.9509",
    "IsOpen24Hours": false,
    "id": "49.9314,10.9509"
  },
  {
    "ID": 2003,
    "Tankstellennummer": "0F903",
    "Name": "Aral Autohof Mitterteich, Gottlieb-Daimler-Str. 4",
    "Address": "Gottlieb-Daimler-Straße 4",
    "Address__1": "",
    "City": "Mitterteich",
    "Postcode": 95666,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 96334000029,
    "24 hour?": "TRUE",
    "Latitude": 49.93671,
    "Longitude": 12.227068,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.93671,12.227068",
    "IsOpen24Hours": true,
    "id": "49.93671,12.227068"
  },
  {
    "ID": 1660702427,
    "Tankstellennummer": 3068,
    "Name": "Agip Bayreuth",
    "Address": "Bamberger Strasse 30",
    "Address__1": "",
    "City": "Bayreuth",
    "Postcode": 95445,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 92162678",
    "24 hour?": "FALSE",
    "Latitude": 49.93694,
    "Longitude": 11.55421,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.93694,11.55421",
    "IsOpen24Hours": false,
    "id": "49.93694,11.55421"
  },
  {
    "ID": 1114443372,
    "Tankstellennummer": "TD132960",
    "Name": "NAUHEIM DARMSTAEDTER LANDSTR 2",
    "Address": "DARMSTAEDTER LANDSTR 2",
    "Address__1": "",
    "City": "NAUHEIM",
    "Postcode": 64569,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06152-64953",
    "24 hour?": "FALSE",
    "Latitude": 49.9391,
    "Longitude": 8.4489,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9391,8.4489",
    "IsOpen24Hours": false,
    "id": "49.9391,8.4489"
  },
  {
    "ID": 318937147,
    "Tankstellennummer": "0FY36",
    "Name": "WEITERSTADT",
    "Address": "A5",
    "Address__1": "",
    "City": "Weiterstadt",
    "Postcode": 64331,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 615050060,
    "24 hour?": "TRUE",
    "Latitude": 49.943385,
    "Longitude": 8.610928,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.943385,8.610928",
    "IsOpen24Hours": true,
    "id": "49.943385,8.610928"
  },
  {
    "ID": 359785455,
    "Tankstellennummer": "0FY22",
    "Name": "WEITERSTADT",
    "Address": "A5",
    "Address__1": "",
    "City": "Weiterstadt",
    "Postcode": 64331,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6150134933,
    "24 hour?": "TRUE",
    "Latitude": 49.9455299,
    "Longitude": 8.6109209,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.9455299,8.6109209",
    "IsOpen24Hours": true,
    "id": "49.9455299,8.6109209"
  },
  {
    "ID": 339697722,
    "Tankstellennummer": "0FY33",
    "Name": "STROMBERG, BAT HUNSRUCK WEST",
    "Address": "A61",
    "Address__1": "",
    "City": "Stromberg",
    "Postcode": 55442,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 67243327,
    "24 hour?": "TRUE",
    "Latitude": 49.946038,
    "Longitude": 7.793038,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.946038,7.793038",
    "IsOpen24Hours": true,
    "id": "49.946038,7.793038"
  },
  {
    "ID": 1029546618,
    "Tankstellennummer": "TD041582",
    "Name": "HERFORST LINDENSTRASSE 25",
    "Address": "LINDENSTRASSE 25",
    "Address__1": "",
    "City": "HERFORST",
    "Postcode": 54662,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 6562930304,
    "24 hour?": "FALSE",
    "Latitude": 49.9463,
    "Longitude": 6.6905,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9463,6.6905",
    "IsOpen24Hours": false,
    "id": "49.9463,6.6905"
  },
  {
    "ID": 237945200,
    "Tankstellennummer": "0FU82",
    "Name": "HAUSEN BEI WURZBURG",
    "Address": "A7",
    "Address__1": "",
    "City": "Hausen bei Würzburg",
    "Postcode": 97262,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 93639966968,
    "24 hour?": "TRUE",
    "Latitude": 49.946846,
    "Longitude": 10.021016,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.946846,10.021016",
    "IsOpen24Hours": true,
    "id": "49.946846,10.021016"
  },
  {
    "ID": 854,
    "Tankstellennummer": "0F076",
    "Name": "Aral Tankstelle Bingen, Hitchinstr. 44",
    "Address": "Hitchinstraße 44",
    "Address__1": "",
    "City": "Bingen",
    "Postcode": 55411,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 672144743,
    "24 hour?": "TRUE",
    "Latitude": 49.948458,
    "Longitude": 7.903118,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.948458,7.903118",
    "IsOpen24Hours": true,
    "id": "49.948458,7.903118"
  },
  {
    "ID": 1855061585,
    "Tankstellennummer": "TD124230",
    "Name": "BINGEN SCHULTH.-KOLLEI-STR. 23",
    "Address": "SCHULTH.-KOLLEI-STR. 23",
    "Address__1": "",
    "City": "BINGEN",
    "Postcode": 55411,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06721-44713",
    "24 hour?": "FALSE",
    "Latitude": 49.9501,
    "Longitude": 7.9032,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9501,7.9032",
    "IsOpen24Hours": false,
    "id": "49.9501,7.9032"
  },
  {
    "ID": 226861969,
    "Tankstellennummer": "TD002808",
    "Name": "EPPERTSHAUSEN OBERROEDERSTR.29",
    "Address": "OBERROEDERSTR.29",
    "Address__1": "",
    "City": "EPPERTSHAUSEN",
    "Postcode": 64859,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06071-31359",
    "24 hour?": "FALSE",
    "Latitude": 49.9503,
    "Longitude": 8.8404,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9503,8.8404",
    "IsOpen24Hours": false,
    "id": "49.9503,8.8404"
  },
  {
    "ID": 3333,
    "Tankstellennummer": "0FK85",
    "Name": "Aral Tankstelle Kirchberg, Kappeler Str. 50",
    "Address": "Kappeler Straße 50",
    "Address__1": "",
    "City": "Kirchberg",
    "Postcode": 55481,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6763960706,
    "24 hour?": "FALSE",
    "Latitude": 49.950477,
    "Longitude": 7.404095,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.950477,7.404095",
    "IsOpen24Hours": false,
    "id": "49.950477,7.404095"
  },
  {
    "ID": 205327346,
    "Tankstellennummer": 3164,
    "Name": "Agip Bayreuth",
    "Address": "Bernecker Str.52",
    "Address__1": "",
    "City": "Bayreuth",
    "Postcode": 95448,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "0921/78519303",
    "24 hour?": "TRUE",
    "Latitude": 49.9547,
    "Longitude": 11.60095,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.9547,11.60095",
    "IsOpen24Hours": true,
    "id": "49.9547,11.60095"
  },
  {
    "ID": 1987,
    "Tankstellennummer": "0F889",
    "Name": "Aral Tankstelle Bayreuth, Bernecker Str. 51",
    "Address": "Bernecker Straße 49",
    "Address__1": "",
    "City": "Bayreuth",
    "Postcode": 95448,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 92113046,
    "24 hour?": "TRUE",
    "Latitude": 49.954797,
    "Longitude": 11.600094,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.954797,11.600094",
    "IsOpen24Hours": true,
    "id": "49.954797,11.600094"
  },
  {
    "ID": 2356,
    "Tankstellennummer": "0FB59",
    "Name": "Aral Tankstelle Karlstadt, Würzburger Str. 3-5",
    "Address": "Würzburger Straße 5b",
    "Address__1": "",
    "City": "Karlstadt",
    "Postcode": 97753,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 93532489,
    "24 hour?": "TRUE",
    "Latitude": 49.955634,
    "Longitude": 9.772477,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.955634,9.772477",
    "IsOpen24Hours": true,
    "id": "49.955634,9.772477"
  },
  {
    "ID": 972,
    "Tankstellennummer": "0F160",
    "Name": "Aral Tankstelle Bitburg, Saarstr. 58 a",
    "Address": "Saarstraße 58 a",
    "Address__1": "",
    "City": "Bitburg",
    "Postcode": 54634,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6561940247,
    "24 hour?": "TRUE",
    "Latitude": 49.955921,
    "Longitude": 6.525196,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.955921,6.525196",
    "IsOpen24Hours": true,
    "id": "49.955921,6.525196"
  },
  {
    "ID": 1746,
    "Tankstellennummer": "0F729",
    "Name": "Aral Tankstelle Babenhausen, Aschaffenburger Str.3",
    "Address": "Aschaffenburger Straße 3",
    "Address__1": "",
    "City": "Babenhausen",
    "Postcode": 64832,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 607362912,
    "24 hour?": "FALSE",
    "Latitude": 49.957428,
    "Longitude": 8.958051,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.957428,8.958051",
    "IsOpen24Hours": false,
    "id": "49.957428,8.958051"
  },
  {
    "ID": 851455769,
    "Tankstellennummer": 4039,
    "Name": "Agip Mainz-Lerchenberg",
    "Address": "Hindemithstr. 4 a",
    "Address__1": "",
    "City": "Mainz-Lerchenberg",
    "Postcode": 55127,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61317152",
    "24 hour?": "FALSE",
    "Latitude": 49.95925,
    "Longitude": 8.20013,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.95925,8.20013",
    "IsOpen24Hours": false,
    "id": "49.95925,8.20013"
  },
  {
    "ID": 800936386,
    "Tankstellennummer": "TD041475",
    "Name": "STOCKSTADT TAUNUSSTR. 1",
    "Address": "TAUNUSSTR. 1",
    "Address__1": "",
    "City": "STOCKSTADT",
    "Postcode": 63811,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 60279795656,
    "24 hour?": "FALSE",
    "Latitude": 49.9609,
    "Longitude": 9.0807,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9609,9.0807",
    "IsOpen24Hours": false,
    "id": "49.9609,9.0807"
  },
  {
    "ID": 3468,
    "Tankstellennummer": "0FL88",
    "Name": "Aral Tankstelle Aschaffenburg, Wuerzburger Str. 190",
    "Address": "Würzburger Straße 190",
    "Address__1": "",
    "City": "Aschaffenburg",
    "Postcode": 63743,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60218629290,
    "24 hour?": "FALSE",
    "Latitude": 49.961464,
    "Longitude": 9.177972,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.961464,9.177972",
    "IsOpen24Hours": false,
    "id": "49.961464,9.177972"
  },
  {
    "ID": 888373233,
    "Tankstellennummer": 4602,
    "Name": "Agip Aschaffenburg",
    "Address": "Pappelweg 10 und 12",
    "Address__1": "",
    "City": "Aschaffenburg",
    "Postcode": 63741,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 6021/584",
    "24 hour?": "FALSE",
    "Latitude": 49.961556,
    "Longitude": 9.126808,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.961556,9.126808",
    "IsOpen24Hours": false,
    "id": "49.961556,9.126808"
  },
  {
    "ID": 2119361429,
    "Tankstellennummer": "TD038018",
    "Name": "MAINZ DEKAN-LAIST-STR. 56",
    "Address": "DEKAN-LAIST-STR. 56",
    "Address__1": "",
    "City": "MAINZ",
    "Postcode": 55129,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06131-9728871",
    "24 hour?": "FALSE",
    "Latitude": 49.9638,
    "Longitude": 8.2535,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9638,8.2535",
    "IsOpen24Hours": false,
    "id": "49.9638,8.2535"
  },
  {
    "ID": 948,
    "Tankstellennummer": "0F141",
    "Name": "Aral Tankstelle Gau-Algesheim, An der B 41",
    "Address": "Rheinstraße 18",
    "Address__1": "",
    "City": "Gau-Algesheim",
    "Postcode": 55435,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 67252340,
    "24 hour?": "FALSE",
    "Latitude": 49.964873,
    "Longitude": 8.017713,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.964873,8.017713",
    "IsOpen24Hours": false,
    "id": "49.964873,8.017713"
  },
  {
    "ID": 3345,
    "Tankstellennummer": "0FM09",
    "Name": "Aral Tankstelle Stockstadt, Obernburger Str. 127-135",
    "Address": "Obernburger Straße127-135",
    "Address__1": "",
    "City": "Stockstadt",
    "Postcode": 63811,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6027401675,
    "24 hour?": "FALSE",
    "Latitude": 49.965195,
    "Longitude": 9.075168,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.965195,9.075168",
    "IsOpen24Hours": false,
    "id": "49.965195,9.075168"
  },
  {
    "ID": 845021017,
    "Tankstellennummer": "TD040758",
    "Name": "BABENHAUSEN BOUXWILLER STR.1",
    "Address": "BOUXWILLER STRASSE 1",
    "Address__1": "",
    "City": "BABENHAUSEN",
    "Postcode": 64832,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06073-723376",
    "24 hour?": "FALSE",
    "Latitude": 49.9698,
    "Longitude": 8.9493,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9698,8.9493",
    "IsOpen24Hours": false,
    "id": "49.9698,8.9493"
  },
  {
    "ID": 2390,
    "Tankstellennummer": "0FB80",
    "Name": "Aral Tankstelle Aschaffenburg, Würzburger Str. 59",
    "Address": "Würzburger Straße 59",
    "Address__1": "",
    "City": "Aschaffenburg",
    "Postcode": 63743,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6021930563,
    "24 hour?": "TRUE",
    "Latitude": 49.969923,
    "Longitude": 9.163068,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.969923,9.163068",
    "IsOpen24Hours": true,
    "id": "49.969923,9.163068"
  },
  {
    "ID": 48763761,
    "Tankstellennummer": "TD170820",
    "Name": "SCHESSLITZ BAMBERGER STR. 22",
    "Address": "BAMBERGER STR. 22",
    "Address__1": "",
    "City": "SCHESSLITZ",
    "Postcode": 96110,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09542-201",
    "24 hour?": "FALSE",
    "Latitude": 49.9706,
    "Longitude": 11.0253,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9706,11.0253",
    "IsOpen24Hours": false,
    "id": "49.9706,11.0253"
  },
  {
    "ID": 2344,
    "Tankstellennummer": "0FB52",
    "Name": "Aral Tankstelle Eltmann, Bamberger Str. 17",
    "Address": "Bamberger Straße 17",
    "Address__1": "",
    "City": "Eltmann",
    "Postcode": 97483,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9522271,
    "24 hour?": "FALSE",
    "Latitude": 49.971124,
    "Longitude": 10.670604,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.971124,10.670604",
    "IsOpen24Hours": false,
    "id": "49.971124,10.670604"
  },
  {
    "ID": 539033431,
    "Tankstellennummer": "TD013294",
    "Name": "MOERFELDEN-WALLDORF RUESSELSHE",
    "Address": "RUESSELSHEIMER STR. 60",
    "Address__1": "",
    "City": "MOERFELDEN-WALLDORF",
    "Postcode": 64546,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06105-21054",
    "24 hour?": "FALSE",
    "Latitude": 49.973,
    "Longitude": 8.5532,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.973,8.5532",
    "IsOpen24Hours": false,
    "id": "49.973,8.5532"
  },
  {
    "ID": 2936,
    "Tankstellennummer": "0FH96",
    "Name": "Aral Tankstelle Bitburg, Am Markt 2",
    "Address": "Am Markt 2",
    "Address__1": "",
    "City": "Bitburg",
    "Postcode": 54634,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 65619464660,
    "24 hour?": "FALSE",
    "Latitude": 49.973065,
    "Longitude": 6.521433,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.973065,6.521433",
    "IsOpen24Hours": false,
    "id": "49.973065,6.521433"
  },
  {
    "ID": 842459068,
    "Tankstellennummer": 4132,
    "Name": "Agip Roedermark-Urberrach",
    "Address": "Konrad-Adenauer-Str. 71",
    "Address__1": "",
    "City": "Roedermark-Urberrach",
    "Postcode": 63322,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 60748618",
    "24 hour?": "FALSE",
    "Latitude": 49.97438586,
    "Longitude": 8.78935107,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.97438586,8.78935107",
    "IsOpen24Hours": false,
    "id": "49.97438586,8.78935107"
  },
  {
    "ID": 501406935,
    "Tankstellennummer": 4601,
    "Name": "Agip Aschaffenburg",
    "Address": "Platanenallee 11",
    "Address__1": "",
    "City": "Aschaffenburg",
    "Postcode": 63739,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 60214532",
    "24 hour?": "FALSE",
    "Latitude": 49.97689,
    "Longitude": 9.15258,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.97689,9.15258",
    "IsOpen24Hours": false,
    "id": "49.97689,9.15258"
  },
  {
    "ID": 746126985,
    "Tankstellennummer": 4123,
    "Name": "Agip Roedermark-Oberroden",
    "Address": "Dieburger Strasse 117",
    "Address__1": "",
    "City": "Roedermark-Oberroden",
    "Postcode": 63322,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 60748816",
    "24 hour?": "FALSE",
    "Latitude": 49.97824548,
    "Longitude": 8.76175,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.97824548,8.76175",
    "IsOpen24Hours": false,
    "id": "49.97824548,8.76175"
  },
  {
    "ID": 10227581,
    "Tankstellennummer": 3159,
    "Name": "Agip Arnstein-Heugrumbach",
    "Address": "Julius-Echter-Str. 26",
    "Address__1": "",
    "City": "Arnstein-Heugrumbach",
    "Postcode": 97450,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 93631212",
    "24 hour?": "FALSE",
    "Latitude": 49.97848,
    "Longitude": 9.95374,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.97848,9.95374",
    "IsOpen24Hours": false,
    "id": "49.97848,9.95374"
  },
  {
    "ID": 2104291727,
    "Tankstellennummer": "TD041525",
    "Name": "BITBURG KOELNER STR. 18",
    "Address": "KOELNER STR. 18",
    "Address__1": "",
    "City": "BITBURG",
    "Postcode": 54634,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 65612395,
    "24 hour?": "FALSE",
    "Latitude": 49.9788,
    "Longitude": 6.5259,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9788,6.5259",
    "IsOpen24Hours": false,
    "id": "49.9788,6.5259"
  },
  {
    "ID": 989,
    "Tankstellennummer": "0F175",
    "Name": "Aral Tankstelle Ingelheim, Konrad-Adenauer-Str. 15",
    "Address": "Konrad-Adenauer-Straße 14",
    "Address__1": "",
    "City": "Ingelheim",
    "Postcode": 55218,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6132431937,
    "24 hour?": "FALSE",
    "Latitude": 49.97907,
    "Longitude": 8.05402,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.97907,8.05402",
    "IsOpen24Hours": false,
    "id": "49.97907,8.05402"
  },
  {
    "ID": 3335,
    "Tankstellennummer": "0FK87",
    "Name": "Aral Tankstelle Mörfelden, Industriestr. 1",
    "Address": "Industriestraße 1",
    "Address__1": "",
    "City": "Mörfelden",
    "Postcode": 64546,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6105921766,
    "24 hour?": "TRUE",
    "Latitude": 49.979076,
    "Longitude": 8.581539,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.979076,8.581539",
    "IsOpen24Hours": true,
    "id": "49.979076,8.581539"
  },
  {
    "ID": 559743337,
    "Tankstellennummer": "TD000874",
    "Name": "SCHESSLITZ AM STEINERNEN KREUZ",
    "Address": "AM STEINERNEN KREUZ 1-3",
    "Address__1": "",
    "City": "SCHESSLITZ",
    "Postcode": 96110,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0954-2942020",
    "24 hour?": "FALSE",
    "Latitude": 49.9801,
    "Longitude": 11.0435,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9801,11.0435",
    "IsOpen24Hours": false,
    "id": "49.9801,11.0435"
  },
  {
    "ID": 313736174,
    "Tankstellennummer": "0FS49",
    "Name": "DREIEICH-OFFENTHAL",
    "Address": "In der Quelle 74",
    "Address__1": "",
    "City": "Dreieich-Offenthal",
    "Postcode": 63303,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60748035221,
    "24 hour?": "TRUE",
    "Latitude": 49.980222,
    "Longitude": 8.73653,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.980222,8.73653",
    "IsOpen24Hours": true,
    "id": "49.980222,8.73653"
  },
  {
    "ID": 3109,
    "Tankstellennummer": "0FJ31",
    "Name": "Aral Tankstelle Aschaffenburg, Hanauer Str. 64",
    "Address": "Hanauer Straße 64",
    "Address__1": "",
    "City": "Aschaffenburg",
    "Postcode": 63739,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 602127874,
    "24 hour?": "TRUE",
    "Latitude": 49.980452,
    "Longitude": 9.132444,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.980452,9.132444",
    "IsOpen24Hours": true,
    "id": "49.980452,9.132444"
  },
  {
    "ID": 2005912365,
    "Tankstellennummer": "TD039826",
    "Name": "WALDASCHAFF AM HEERBACH 1",
    "Address": "AM HEERBACH 1",
    "Address__1": "",
    "City": "WALDASCHAFF",
    "Postcode": 63857,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06095-3373",
    "24 hour?": "FALSE",
    "Latitude": 49.9809,
    "Longitude": 9.2754,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9809,9.2754",
    "IsOpen24Hours": false,
    "id": "49.9809,9.2754"
  },
  {
    "ID": 890,
    "Tankstellennummer": "0F098",
    "Name": "Aral Tankstelle Wittlich, Kurfürstenstraße 69",
    "Address": "Kurfürstenstraße 69",
    "Address__1": "",
    "City": "Wittlich",
    "Postcode": 54516,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 657120841,
    "24 hour?": "TRUE",
    "Latitude": 49.98228,
    "Longitude": 6.900637,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.98228,6.900637",
    "IsOpen24Hours": true,
    "id": "49.98228,6.900637"
  },
  {
    "ID": 3063,
    "Tankstellennummer": "0FI96",
    "Name": "Aral Tankstelle Mainz, Hechtsheimer Str. 4",
    "Address": "Hechtsheimer Straße 4",
    "Address__1": "",
    "City": "Mainz",
    "Postcode": 55131,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6131985789,
    "24 hour?": "FALSE",
    "Latitude": 49.98279,
    "Longitude": 8.280189,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.98279,8.280189",
    "IsOpen24Hours": false,
    "id": "49.98279,8.280189"
  },
  {
    "ID": 598625169,
    "Tankstellennummer": "TD003053",
    "Name": "MAINZ-FINTHEN FLUGPLATZSTR. 26",
    "Address": "FLUGPLATZSTR. 26",
    "Address__1": "",
    "City": "MAINZ-FINTHEN",
    "Postcode": 55126,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06131-40805",
    "24 hour?": "FALSE",
    "Latitude": 49.9832,
    "Longitude": 8.1708,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9832,8.1708",
    "IsOpen24Hours": false,
    "id": "49.9832,8.1708"
  },
  {
    "ID": 680670315,
    "Tankstellennummer": "TD133450",
    "Name": "MOERFELDEN-WALLDORF INDUSTRIES",
    "Address": "INDUSTRIESTR. 19",
    "Address__1": "",
    "City": "MOERFELDEN-WALLDORF",
    "Postcode": 64546,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06105-21069",
    "24 hour?": "FALSE",
    "Latitude": 49.9836,
    "Longitude": 8.5793,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9836,8.5793",
    "IsOpen24Hours": false,
    "id": "49.9836,8.5793"
  },
  {
    "ID": 2100163508,
    "Tankstellennummer": "TD002733",
    "Name": "ASCHAFFENBURG SCHILLERSTR. 80",
    "Address": "SCHILLERSTR. 80",
    "Address__1": "",
    "City": "ASCHAFFENBURG",
    "Postcode": 63741,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06021-423279",
    "24 hour?": "FALSE",
    "Latitude": 49.9858,
    "Longitude": 9.1352,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9858,9.1352",
    "IsOpen24Hours": false,
    "id": "49.9858,9.1352"
  },
  {
    "ID": 918,
    "Tankstellennummer": "0F117",
    "Name": "Aral Tankstelle Rüsselsheim, Friedrich-Ebert-Str. 54",
    "Address": "Friedrich-Ebert-Straße54",
    "Address__1": "",
    "City": "Rüsselsheim",
    "Postcode": 65428,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 614215334,
    "24 hour?": "FALSE",
    "Latitude": 49.986844,
    "Longitude": 8.416374,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.986844,8.416374",
    "IsOpen24Hours": false,
    "id": "49.986844,8.416374"
  },
  {
    "ID": 741192761,
    "Tankstellennummer": "0FV18",
    "Name": "GINSHEIM-GUSTAVSBURG",
    "Address": "Am Flurgraben 1-11",
    "Address__1": "",
    "City": "Ginsheim-Gustavsburg",
    "Postcode": 65462,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61345678996,
    "24 hour?": "FALSE",
    "Latitude": 49.987533,
    "Longitude": 8.327749,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.987533,8.327749",
    "IsOpen24Hours": false,
    "id": "49.987533,8.327749"
  },
  {
    "ID": 835,
    "Tankstellennummer": "0F060",
    "Name": "Aral Tankstelle Mainz, Pariser Str. 2",
    "Address": "Pariser Straße 2",
    "Address__1": "",
    "City": "Mainz",
    "Postcode": 55131,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6131573110,
    "24 hour?": "TRUE",
    "Latitude": 49.98786,
    "Longitude": 8.266955,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.98786,8.266955",
    "IsOpen24Hours": true,
    "id": "49.98786,8.266955"
  },
  {
    "ID": 1108173177,
    "Tankstellennummer": "TD037960",
    "Name": "WITTLICH FRIEDRICHSTR. 58",
    "Address": "FRIEDRICHSTR. 58",
    "Address__1": "",
    "City": "WITTLICH",
    "Postcode": 54516,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06571-1472359",
    "24 hour?": "FALSE",
    "Latitude": 49.9882,
    "Longitude": 6.9034,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9882,6.9034",
    "IsOpen24Hours": false,
    "id": "49.9882,6.9034"
  },
  {
    "ID": 909,
    "Tankstellennummer": "0F020",
    "Name": "Aral Tankstelle Geisenheim, Chauvignystr. 15",
    "Address": "Chauvignystraße 15",
    "Address__1": "",
    "City": "Geisenheim",
    "Postcode": 65366,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6722995520,
    "24 hour?": "FALSE",
    "Latitude": 49.988261,
    "Longitude": 7.977877,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.988261,7.977877",
    "IsOpen24Hours": false,
    "id": "49.988261,7.977877"
  },
  {
    "ID": 1474167560,
    "Tankstellennummer": "TD002535",
    "Name": "WITTLICH FRIEDRICHSTR. 41",
    "Address": "FRIEDRICHSTR. 41",
    "Address__1": "",
    "City": "WITTLICH",
    "Postcode": 54516,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06571-7277",
    "24 hour?": "FALSE",
    "Latitude": 49.9883,
    "Longitude": 6.8991,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9883,6.8991",
    "IsOpen24Hours": false,
    "id": "49.9883,6.8991"
  },
  {
    "ID": 1458117544,
    "Tankstellennummer": "TD131200",
    "Name": "RUESSELSHEIM HASSLOCHER STR. 1",
    "Address": "HASSLOCHER STR. 149",
    "Address__1": "",
    "City": "RUESSELSHEIM",
    "Postcode": 65428,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06142-162670",
    "24 hour?": "FALSE",
    "Latitude": 49.9887,
    "Longitude": 8.426,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9887,8.426",
    "IsOpen24Hours": false,
    "id": "49.9887,8.426"
  },
  {
    "ID": 3037,
    "Tankstellennummer": "0FI74",
    "Name": "Aral Tankstelle Langen, Frankfurter Str. 19",
    "Address": "Frankfurter Straße 19",
    "Address__1": "",
    "City": "Langen",
    "Postcode": 63225,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 610321524,
    "24 hour?": "FALSE",
    "Latitude": 49.991924,
    "Longitude": 8.680595,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.991924,8.680595",
    "IsOpen24Hours": false,
    "id": "49.991924,8.680595"
  },
  {
    "ID": 289465519,
    "Tankstellennummer": "TD131270",
    "Name": "LANGEN BERLINER ALLEE 12",
    "Address": "BERLINER ALLEE 12",
    "Address__1": "",
    "City": "LANGEN",
    "Postcode": 63225,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06103-71010",
    "24 hour?": "FALSE",
    "Latitude": 49.9923,
    "Longitude": 8.6495,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9923,8.6495",
    "IsOpen24Hours": false,
    "id": "49.9923,8.6495"
  },
  {
    "ID": 986,
    "Tankstellennummer": "0F173",
    "Name": "Aral Autohof Rüsselsheim, Mainzer Str. 95",
    "Address": "Mainzer Straße 95",
    "Address__1": "",
    "City": "Rüsselsheim",
    "Postcode": 65428,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6142910915,
    "24 hour?": "TRUE",
    "Latitude": 49.993163,
    "Longitude": 8.38713,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "49.993163,8.38713",
    "IsOpen24Hours": true,
    "id": "49.993163,8.38713"
  },
  {
    "ID": 2409,
    "Tankstellennummer": "0FB90",
    "Name": "Aral Tankstelle Kleinostheim, Aschaffenburger Str. 86",
    "Address": "Aschaffenburger Straße 86",
    "Address__1": "",
    "City": "Kleinostheim",
    "Postcode": 63801,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60279026,
    "24 hour?": "FALSE",
    "Latitude": 49.994607,
    "Longitude": 9.06971,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.994607,9.06971",
    "IsOpen24Hours": false,
    "id": "49.994607,9.06971"
  },
  {
    "ID": 960,
    "Tankstellennummer": "0F150",
    "Name": "Aral Autohof Rheinböllen, Bahnhofstraße",
    "Address": "Bahnhofstraße 53",
    "Address__1": "",
    "City": "Rheinböllen",
    "Postcode": 55494,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 67643000,
    "24 hour?": "TRUE",
    "Latitude": 49.996164,
    "Longitude": 7.68382,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "49.996164,7.68382",
    "IsOpen24Hours": true,
    "id": "49.996164,7.68382"
  },
  {
    "ID": 564810548,
    "Tankstellennummer": "TD038455",
    "Name": "MAINZ BINGERSTR. 27",
    "Address": "BINGERSTR. 27",
    "Address__1": "",
    "City": "MAINZ",
    "Postcode": 55131,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06131-1432888",
    "24 hour?": "FALSE",
    "Latitude": 49.9977,
    "Longitude": 8.2567,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9977,8.2567",
    "IsOpen24Hours": false,
    "id": "49.9977,8.2567"
  },
  {
    "ID": 1012001353,
    "Tankstellennummer": "TD038737",
    "Name": "MAINZ SAARSTR. 6",
    "Address": "SAARSTR. 6",
    "Address__1": "",
    "City": "MAINZ",
    "Postcode": 55122,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06131-381434",
    "24 hour?": "FALSE",
    "Latitude": 49.9977,
    "Longitude": 8.2536,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9977,8.2536",
    "IsOpen24Hours": false,
    "id": "49.9977,8.2536"
  },
  {
    "ID": 2088915352,
    "Tankstellennummer": "0FR40",
    "Name": "LANGEN",
    "Address": "Amperestraße 3",
    "Address__1": "",
    "City": "Langen",
    "Postcode": 63225,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61038037198,
    "24 hour?": "FALSE",
    "Latitude": 49.99819,
    "Longitude": 8.651626,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "49.99819,8.651626",
    "IsOpen24Hours": false,
    "id": "49.99819,8.651626"
  },
  {
    "ID": 101608978,
    "Tankstellennummer": "TD002899",
    "Name": "RODGAU HAINBURGSTRASSE 51",
    "Address": "HAINBURGSTR. 51",
    "Address__1": "",
    "City": "RODGAU",
    "Postcode": 63110,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06106-771247",
    "24 hour?": "FALSE",
    "Latitude": 49.9995,
    "Longitude": 8.8811,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "49.9995,8.8811",
    "IsOpen24Hours": false,
    "id": "49.9995,8.8811"
  },
  {
    "ID": 279731663,
    "Tankstellennummer": 4020,
    "Name": "Agip Dreieich-Goetzenhain",
    "Address": "Bleiswijker Strasse 200",
    "Address__1": "",
    "City": "Dreieich-Goetzenhain",
    "Postcode": 63303,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "06103/9951125",
    "24 hour?": "FALSE",
    "Latitude": 50.00019,
    "Longitude": 8.73126,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.00019,8.73126",
    "IsOpen24Hours": false,
    "id": "50.00019,8.73126"
  },
  {
    "ID": 817520387,
    "Tankstellennummer": "TD003340",
    "Name": "WERNECK AUTOHOF AM ESCHENBACH",
    "Address": "AUTOHOF AM ESCHENBACH 1",
    "Address__1": "",
    "City": "WERNECK",
    "Postcode": 97440,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09722-94620",
    "24 hour?": "FALSE",
    "Latitude": 50.0033,
    "Longitude": 10.118,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0033,10.118",
    "IsOpen24Hours": false,
    "id": "50.0033,10.118"
  },
  {
    "ID": 2352,
    "Tankstellennummer": "0FB57",
    "Name": "Aral Tankstelle Schwebheim, Schweinfurter Str.78",
    "Address": "Schweinfurter Straße  78",
    "Address__1": "",
    "City": "Schwebheim",
    "Postcode": 97525,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 97231569,
    "24 hour?": "FALSE",
    "Latitude": 50.004266,
    "Longitude": 10.242368,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.004266,10.242368",
    "IsOpen24Hours": false,
    "id": "50.004266,10.242368"
  },
  {
    "ID": 641141461,
    "Tankstellennummer": 4040,
    "Name": "Agip Mainz",
    "Address": "Schnellstrasse West",
    "Address__1": "",
    "City": "Mainz",
    "Postcode": 55130,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61318648",
    "24 hour?": "FALSE",
    "Latitude": 50.004372,
    "Longitude": 8.262588,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.004372,8.262588",
    "IsOpen24Hours": false,
    "id": "50.004372,8.262588"
  },
  {
    "ID": 1276742253,
    "Tankstellennummer": 4041,
    "Name": "Agip Mainz",
    "Address": "Schnellstrasse Ost",
    "Address__1": "",
    "City": "Mainz",
    "Postcode": 55130,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61316982",
    "24 hour?": "FALSE",
    "Latitude": 50.004372,
    "Longitude": 8.262588,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.004372,8.262588",
    "IsOpen24Hours": false,
    "id": "50.004372,8.262588"
  },
  {
    "ID": 925,
    "Tankstellennummer": "0F122",
    "Name": "Aral Tankstelle Mainz-Kostheim, Hochheimer Str. 25",
    "Address": "Hochheimer Straße 25",
    "Address__1": "",
    "City": "Mainz-Kostheim",
    "Postcode": 55246,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 613463241,
    "24 hour?": "TRUE",
    "Latitude": 50.007951,
    "Longitude": 8.292079,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.007951,8.292079",
    "IsOpen24Hours": true,
    "id": "50.007951,8.292079"
  },
  {
    "ID": 1574101078,
    "Tankstellennummer": "TD130140",
    "Name": "DREIEICH DARMSTAEDTER STR. 92",
    "Address": "DARMSTAEDTER STR. 92",
    "Address__1": "",
    "City": "DREIEICH",
    "Postcode": 63303,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06103-697050",
    "24 hour?": "FALSE",
    "Latitude": 50.0088,
    "Longitude": 8.6894,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0088,8.6894",
    "IsOpen24Hours": false,
    "id": "50.0088,8.6894"
  },
  {
    "ID": 919,
    "Tankstellennummer": "0F118",
    "Name": "Aral Tankstelle Dreieich, Darmstaedter Str. 43",
    "Address": "Darmstädter Straße 43",
    "Address__1": "",
    "City": "Dreieich",
    "Postcode": 63303,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 610365671,
    "24 hour?": "FALSE",
    "Latitude": 50.013304,
    "Longitude": 8.692331,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.013304,8.692331",
    "IsOpen24Hours": false,
    "id": "50.013304,8.692331"
  },
  {
    "ID": 988,
    "Tankstellennummer": "0F174",
    "Name": "Aral Tankstelle Dietzenbach, Elisabeth-Selbert-Str. 4",
    "Address": "Elisabeth-Selbert-Straße",
    "Address__1": "",
    "City": "Dietzenbach",
    "Postcode": 63128,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60748511300,
    "24 hour?": "TRUE",
    "Latitude": 50.01343,
    "Longitude": 8.79411,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.01343,8.79411",
    "IsOpen24Hours": true,
    "id": "50.01343,8.79411"
  },
  {
    "ID": 2399,
    "Tankstellennummer": "0FB86",
    "Name": "Aral Tankstelle Tröstau, Fichtelgebirgsstr.2",
    "Address": "Fichtelgebirgsstraße 2",
    "Address__1": "",
    "City": "Tröstau",
    "Postcode": 95709,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 92326193,
    "24 hour?": "FALSE",
    "Latitude": 50.015959,
    "Longitude": 11.958745,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.015959,11.958745",
    "IsOpen24Hours": false,
    "id": "50.015959,11.958745"
  },
  {
    "ID": 2969,
    "Tankstellennummer": "0FI22",
    "Name": "Aral Tankstelle Zell, Brandenburg 4",
    "Address": "Brandenburg 1",
    "Address__1": "",
    "City": "Zell",
    "Postcode": 56856,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6542960225,
    "24 hour?": "FALSE",
    "Latitude": 50.017049,
    "Longitude": 7.180465,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.017049,7.180465",
    "IsOpen24Hours": false,
    "id": "50.017049,7.180465"
  },
  {
    "ID": 841844097,
    "Tankstellennummer": "TD038463",
    "Name": "DREIEICH EISENBAHNSTR. 210",
    "Address": "EISENBAHNSTR. 210",
    "Address__1": "",
    "City": "DREIEICH",
    "Postcode": 63303,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06103-697906",
    "24 hour?": "FALSE",
    "Latitude": 50.0191,
    "Longitude": 8.6727,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0191,8.6727",
    "IsOpen24Hours": false,
    "id": "50.0191,8.6727"
  },
  {
    "ID": 997,
    "Tankstellennummer": "0F182",
    "Name": "Aral Autohof Schweinfurt, Straßburgstr. 1",
    "Address": "Straßburgstraße 1",
    "Address__1": "",
    "City": "Schweinfurt",
    "Postcode": 97424,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9721608526,
    "24 hour?": "TRUE",
    "Latitude": 50.021655,
    "Longitude": 10.216618,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.021655,10.216618",
    "IsOpen24Hours": true,
    "id": "50.021655,10.216618"
  },
  {
    "ID": 837,
    "Tankstellennummer": "0F061",
    "Name": "Aral Tankstelle Eltville, Erbacher Str. 2",
    "Address": "Erbacher Straße 2",
    "Address__1": "",
    "City": "Eltville",
    "Postcode": 65343,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6123899352,
    "24 hour?": "FALSE",
    "Latitude": 50.024137,
    "Longitude": 8.116059,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.024137,8.116059",
    "IsOpen24Hours": false,
    "id": "50.024137,8.116059"
  },
  {
    "ID": 3123,
    "Tankstellennummer": "0FJ41",
    "Name": "Aral Tankstelle Schweinfurt, Würzburger Str. 44",
    "Address": "Würzburger Straße 44",
    "Address__1": "",
    "City": "Schweinfurt",
    "Postcode": 97424,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 972181466,
    "24 hour?": "FALSE",
    "Latitude": 50.024444,
    "Longitude": 10.196102,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.024444,10.196102",
    "IsOpen24Hours": false,
    "id": "50.024444,10.196102"
  },
  {
    "ID": 993,
    "Tankstellennummer": "0F179",
    "Name": "Aral Tankstelle Mainz, Rheinallee 207",
    "Address": "Rheinallee 207",
    "Address__1": "",
    "City": "Mainz",
    "Postcode": 55120,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6131687011,
    "24 hour?": "TRUE",
    "Latitude": 50.027154,
    "Longitude": 8.222996,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.027154,8.222996",
    "IsOpen24Hours": true,
    "id": "50.027154,8.222996"
  },
  {
    "ID": 570174523,
    "Tankstellennummer": 3636,
    "Name": "Agip Hassfurt",
    "Address": "Godelstatt 3",
    "Address__1": "",
    "City": "Hassfurt",
    "Postcode": 97437,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "09521/9525563",
    "24 hour?": "FALSE",
    "Latitude": 50.03161,
    "Longitude": 10.54097,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.03161,10.54097",
    "IsOpen24Hours": false,
    "id": "50.03161,10.54097"
  },
  {
    "ID": 3045,
    "Tankstellennummer": "0FI81",
    "Name": "Aral Tankstelle Dreieich, Kurt-Schumacher-Ring 138",
    "Address": "Kurt-Schumacher-Ring 138",
    "Address__1": "",
    "City": "Dreieich",
    "Postcode": 63303,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6103373437,
    "24 hour?": "FALSE",
    "Latitude": 50.034562,
    "Longitude": 8.694491,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.034562,8.694491",
    "IsOpen24Hours": false,
    "id": "50.034562,8.694491"
  },
  {
    "ID": 577778086,
    "Tankstellennummer": "TD002790",
    "Name": "KARLSTEIN HANAUER LANDSTR.114",
    "Address": "HANAUER LANDSTR.114",
    "Address__1": "",
    "City": "KARLSTEIN",
    "Postcode": 63791,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06188-990599/98",
    "24 hour?": "FALSE",
    "Latitude": 50.037,
    "Longitude": 9.0361,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.037,9.0361",
    "IsOpen24Hours": false,
    "id": "50.037,9.0361"
  },
  {
    "ID": 830934679,
    "Tankstellennummer": "0FE99",
    "Name": "Aral TankStelle Neudrossenfeld, An der Autobahn 1",
    "Address": "An der Autobahn 1",
    "Address__1": "",
    "City": "Neudrossenfeld",
    "Postcode": 95512,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9203688996,
    "24 hour?": "TRUE",
    "Latitude": 50.037347,
    "Longitude": 11.490633,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.037347,11.490633",
    "IsOpen24Hours": true,
    "id": "50.037347,11.490633"
  },
  {
    "ID": 2375,
    "Tankstellennummer": "0FB72",
    "Name": "Aral Tankstelle Bad Berneck, Maintalstrasse 159",
    "Address": "Maintalstraße 159",
    "Address__1": "",
    "City": "Bad Berneck",
    "Postcode": 95460,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9273502042,
    "24 hour?": "FALSE",
    "Latitude": 50.037669,
    "Longitude": 11.677459,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.037669,11.677459",
    "IsOpen24Hours": false,
    "id": "50.037669,11.677459"
  },
  {
    "ID": 281334089,
    "Tankstellennummer": "0FP66",
    "Name": "SELIGENSTADT",
    "Address": "Frankfurter Straße 102 A",
    "Address__1": "",
    "City": "Seligenstadt",
    "Postcode": 63500,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61828206288,
    "24 hour?": "FALSE",
    "Latitude": 50.045104,
    "Longitude": 8.961201,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.045104,8.961201",
    "IsOpen24Hours": false,
    "id": "50.045104,8.961201"
  },
  {
    "ID": 1059179975,
    "Tankstellennummer": "TD041566",
    "Name": "WIESBADEN AEPPELALLEE 39",
    "Address": "AEPPELALLEE 39",
    "Address__1": "",
    "City": "WIESBADEN",
    "Postcode": 65203,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 6116010537,
    "24 hour?": "FALSE",
    "Latitude": 50.0477,
    "Longitude": 8.2312,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0477,8.2312",
    "IsOpen24Hours": false,
    "id": "50.0477,8.2312"
  },
  {
    "ID": 3485,
    "Tankstellennummer": "0FL98",
    "Name": "Aral Tankstelle Flörsheim, Hofheimer Str. 24",
    "Address": "Hofheimer Straße 24",
    "Address__1": "",
    "City": "Flörsheim",
    "Postcode": 65439,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 614531103,
    "24 hour?": "FALSE",
    "Latitude": 50.047755,
    "Longitude": 8.434482,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.047755,8.434482",
    "IsOpen24Hours": false,
    "id": "50.047755,8.434482"
  },
  {
    "ID": 3039,
    "Tankstellennummer": "0FI76",
    "Name": "Aral Tankstelle Neu-Isenburg, Friedhofstr. 11",
    "Address": "Friedhofstraße 11",
    "Address__1": "",
    "City": "Neu-Isenburg",
    "Postcode": 63263,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 610239307,
    "24 hour?": "FALSE",
    "Latitude": 50.047976,
    "Longitude": 8.697337,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.047976,8.697337",
    "IsOpen24Hours": false,
    "id": "50.047976,8.697337"
  },
  {
    "ID": 1935809627,
    "Tankstellennummer": 3141,
    "Name": "Agip Schweinfurt",
    "Address": "Niederwerrner Str. 4  1/3",
    "Address__1": "",
    "City": "Schweinfurt",
    "Postcode": 97421,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 97211865",
    "24 hour?": "FALSE",
    "Latitude": 50.0484,
    "Longitude": 10.22893,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0484,10.22893",
    "IsOpen24Hours": false,
    "id": "50.0484,10.22893"
  },
  {
    "ID": 900,
    "Tankstellennummer": "0F106",
    "Name": "Aral Tankstelle Wiesbaden, Biebricher Allee 186",
    "Address": "Biebricher Allee 186",
    "Address__1": "",
    "City": "Wiesbaden",
    "Postcode": 65203,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 611694289,
    "24 hour?": "FALSE",
    "Latitude": 50.048535,
    "Longitude": 8.241274,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.048535,8.241274",
    "IsOpen24Hours": false,
    "id": "50.048535,8.241274"
  },
  {
    "ID": 240180360,
    "Tankstellennummer": "TD039941",
    "Name": "SCHWEINFURT MAINBERGER STR. 22",
    "Address": "MAINBERGER STR. 22",
    "Address__1": "",
    "City": "SCHWEINFURT",
    "Postcode": 97422,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09721-3709616",
    "24 hour?": "FALSE",
    "Latitude": 50.0488,
    "Longitude": 10.2455,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0488,10.2455",
    "IsOpen24Hours": false,
    "id": "50.0488,10.2455"
  },
  {
    "ID": 953,
    "Tankstellennummer": "0F144",
    "Name": "Aral Autobahntankstelle Wiesbaden, Erbenheim-Süd (A 66)",
    "Address": "A 66 Erbenheim-Süd",
    "Address__1": "",
    "City": "Wiesbaden",
    "Postcode": 65205,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 611700710,
    "24 hour?": "FALSE",
    "Latitude": 50.054256,
    "Longitude": 8.311488,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.054256,8.311488",
    "IsOpen24Hours": false,
    "id": "50.054256,8.311488"
  },
  {
    "ID": 2413,
    "Tankstellennummer": "0FB94",
    "Name": "Aral Tankstelle Mömbris, Kahlgrundstr.137",
    "Address": "Kahlgrundstraße 137",
    "Address__1": "",
    "City": "Mömbris",
    "Postcode": 63776,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60291654,
    "24 hour?": "FALSE",
    "Latitude": 50.054511,
    "Longitude": 9.194303,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.054511,9.194303",
    "IsOpen24Hours": false,
    "id": "50.054511,9.194303"
  },
  {
    "ID": 1996,
    "Tankstellennummer": "0F896",
    "Name": "Aral Autohof Himmelkron, Hofer Str. 2 / B 303",
    "Address": "Hofer Straße 2 / B 303",
    "Address__1": "",
    "City": "Himmelkron",
    "Postcode": 95502,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 927357337,
    "24 hour?": "TRUE",
    "Latitude": 50.054613,
    "Longitude": 11.622785,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.054613,11.622785",
    "IsOpen24Hours": true,
    "id": "50.054613,11.622785"
  },
  {
    "ID": 817,
    "Tankstellennummer": "0F048",
    "Name": "Aral Autobahntankstelle Wiesbaden, A 66 Erbenheim-Nord",
    "Address": "A 66 Erbenheim-Nord",
    "Address__1": "",
    "City": "Wiesbaden",
    "Postcode": 65205,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 611718300,
    "24 hour?": "TRUE",
    "Latitude": 50.055113,
    "Longitude": 8.311917,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.055113,8.311917",
    "IsOpen24Hours": true,
    "id": "50.055113,8.311917"
  },
  {
    "ID": 902,
    "Tankstellennummer": "0F107",
    "Name": "Aral Tankstelle Eltville, Schlangenbader Str. 8",
    "Address": "Schlangenbader Straße 8",
    "Address__1": "",
    "City": "Eltville",
    "Postcode": 65344,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 612371407,
    "24 hour?": "TRUE",
    "Latitude": 50.05636,
    "Longitude": 8.119202,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.05636,8.119202",
    "IsOpen24Hours": true,
    "id": "50.05636,8.119202"
  },
  {
    "ID": 1603131561,
    "Tankstellennummer": 4105,
    "Name": "Agip Heusenstamm",
    "Address": "Frankfurter Strasse 110",
    "Address__1": "",
    "City": "Heusenstamm",
    "Postcode": 63150,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61046182",
    "24 hour?": "FALSE",
    "Latitude": 50.05859,
    "Longitude": 8.79407,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.05859,8.79407",
    "IsOpen24Hours": false,
    "id": "50.05859,8.79407"
  },
  {
    "ID": 910,
    "Tankstellennummer": "0F113",
    "Name": "Aral Tankstelle Heusenstamm, Isenburger Str. 20",
    "Address": "Isenburger Straße 20",
    "Address__1": "",
    "City": "Heusenstamm",
    "Postcode": 63150,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61045123,
    "24 hour?": "FALSE",
    "Latitude": 50.058698,
    "Longitude": 8.788944,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.058698,8.788944",
    "IsOpen24Hours": false,
    "id": "50.058698,8.788944"
  },
  {
    "ID": 1376854855,
    "Tankstellennummer": "TD003137",
    "Name": "WIESBADEN ERICH-OLLENHAUER-STR",
    "Address": "ERICH-OLLENHAUER-STR.147",
    "Address__1": "",
    "City": "WIESBADEN",
    "Postcode": 65187,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0611-843707",
    "24 hour?": "FALSE",
    "Latitude": 50.0598,
    "Longitude": 8.2218,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0598,8.2218",
    "IsOpen24Hours": false,
    "id": "50.0598,8.2218"
  },
  {
    "ID": 922,
    "Tankstellennummer": "0F121",
    "Name": "Aral Tankstelle Wiesbaden, Mainzer Str. 103",
    "Address": "Mainzer Straße 103",
    "Address__1": "",
    "City": "Wiesbaden",
    "Postcode": 65189,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61174478,
    "24 hour?": "TRUE",
    "Latitude": 50.06217,
    "Longitude": 8.252317,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.06217,8.252317",
    "IsOpen24Hours": true,
    "id": "50.06217,8.252317"
  },
  {
    "ID": 1490870079,
    "Tankstellennummer": "0FR23",
    "Name": "Aral Tankstelle Oberthausen",
    "Address": "IM BIRKENGRUND",
    "Address__1": "",
    "City": "OBERTSHAUSEN",
    "Postcode": 63179,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "06104/4052680",
    "24 hour?": "TRUE",
    "Latitude": 50.062576,
    "Longitude": 8.8297,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.062576,8.8297",
    "IsOpen24Hours": true,
    "id": "50.062576,8.8297"
  },
  {
    "ID": 815538456,
    "Tankstellennummer": "0FU15",
    "Name": "EUERBACH",
    "Address": "Oberwerrner Weg 10",
    "Address__1": "",
    "City": "Euerbach",
    "Postcode": 97502,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "09726/9052785",
    "24 hour?": "TRUE",
    "Latitude": 50.062846,
    "Longitude": 10.144527,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.062846,10.144527",
    "IsOpen24Hours": true,
    "id": "50.062846,10.144527"
  },
  {
    "ID": 1473260816,
    "Tankstellennummer": "TD035535",
    "Name": "KELSTERBACH ROSSERTSTR. 2",
    "Address": "ROSSERTSTR. 2",
    "Address__1": "",
    "City": "KELSTERBACH",
    "Postcode": 65451,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06107-3013911",
    "24 hour?": "FALSE",
    "Latitude": 50.066,
    "Longitude": 8.5373,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.066,8.5373",
    "IsOpen24Hours": false,
    "id": "50.066,8.5373"
  },
  {
    "ID": 920,
    "Tankstellennummer": "0F119",
    "Name": "Aral Tankstelle Wiesbaden, Berliner Str. 68",
    "Address": "Berliner Straße 68",
    "Address__1": "",
    "City": "Wiesbaden",
    "Postcode": 65189,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 611701141,
    "24 hour?": "TRUE",
    "Latitude": 50.066237,
    "Longitude": 8.26902,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.066237,8.26902",
    "IsOpen24Hours": true,
    "id": "50.066237,8.26902"
  },
  {
    "ID": 3074,
    "Tankstellennummer": "0FJ05",
    "Name": "Aral Tankstelle Kastellaun, Zellerstr. 34",
    "Address": "Zellerstraße 34",
    "Address__1": "",
    "City": "Kastellaun",
    "Postcode": 56288,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 67625599,
    "24 hour?": "FALSE",
    "Latitude": 50.068868,
    "Longitude": 7.439397,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.068868,7.439397",
    "IsOpen24Hours": false,
    "id": "50.068868,7.439397"
  },
  {
    "ID": 873,
    "Tankstellennummer": "0F089",
    "Name": "Aral Tankstelle Rodgau, B 448",
    "Address": "B 448",
    "Address__1": "",
    "City": "Rodgau",
    "Postcode": 63110,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61044657,
    "24 hour?": "TRUE",
    "Latitude": 50.069198,
    "Longitude": 8.877869,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.069198,8.877869",
    "IsOpen24Hours": true,
    "id": "50.069198,8.877869"
  },
  {
    "ID": 492093593,
    "Tankstellennummer": "TD000683",
    "Name": "NIEDEROEFFLINGEN BAB EIFEL-OST",
    "Address": "BAB EIFEL-OST / A1",
    "Address__1": "",
    "City": "NIEDEROEFFLINGEN",
    "Postcode": 54533,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06574-200",
    "24 hour?": "FALSE",
    "Latitude": 50.0692,
    "Longitude": 6.8819,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0692,6.8819",
    "IsOpen24Hours": false,
    "id": "50.0692,6.8819"
  },
  {
    "ID": 3047,
    "Tankstellennummer": "0FI83",
    "Name": "Aral Tankstelle Wiesbaden, Bahnhofsplatz",
    "Address": "Bahnhofsplatz",
    "Address__1": "",
    "City": "Wiesbaden",
    "Postcode": 65189,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 611719188,
    "24 hour?": "TRUE",
    "Latitude": 50.071471,
    "Longitude": 8.245563,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.071471,8.245563",
    "IsOpen24Hours": true,
    "id": "50.071471,8.245563"
  },
  {
    "ID": 3114,
    "Tankstellennummer": "0FJ35",
    "Name": "Aral Tankstelle Kahl, Forststr. 3 - 5",
    "Address": "Forststraße 3 - 5",
    "Address__1": "",
    "City": "Kahl",
    "Postcode": 63796,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6188900819,
    "24 hour?": "FALSE",
    "Latitude": 50.075266,
    "Longitude": 9.001623,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.075266,9.001623",
    "IsOpen24Hours": false,
    "id": "50.075266,9.001623"
  },
  {
    "ID": 1366113702,
    "Tankstellennummer": "TD019463",
    "Name": "WIESBADEN DOTZHEIMER STR. 184",
    "Address": "DOTZHEIMER STR. 184",
    "Address__1": "",
    "City": "WIESBADEN",
    "Postcode": 65197,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0611-420626",
    "24 hour?": "FALSE",
    "Latitude": 50.0761,
    "Longitude": 8.2086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0761,8.2086",
    "IsOpen24Hours": false,
    "id": "50.0761,8.2086"
  },
  {
    "ID": 830,
    "Tankstellennummer": "0F056",
    "Name": "Aral Tankstelle Wiesbaden, Dotzheimer Str. 93",
    "Address": "Dotzheimer Straße 93",
    "Address__1": "",
    "City": "Wiesbaden",
    "Postcode": 65197,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61147027,
    "24 hour?": "TRUE",
    "Latitude": 50.077702,
    "Longitude": 8.221285,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.077702,8.221285",
    "IsOpen24Hours": true,
    "id": "50.077702,8.221285"
  },
  {
    "ID": 926,
    "Tankstellennummer": "0F123",
    "Name": "Aral Tankstelle Frankfurt, Lyoner Str. 70",
    "Address": "Lyoner Straße 70",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60528,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 696661885,
    "24 hour?": "FALSE",
    "Latitude": 50.0781,
    "Longitude": 8.633934,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0781,8.633934",
    "IsOpen24Hours": false,
    "id": "50.0781,8.633934"
  },
  {
    "ID": 812,
    "Tankstellennummer": "0F044",
    "Name": "Aral Tankstelle Frankfurt, Kennedyallee 280",
    "Address": "Forsthausstraße 50",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60528,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 69671026,
    "24 hour?": "TRUE",
    "Latitude": 50.080015,
    "Longitude": 8.655258,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.080015,8.655258",
    "IsOpen24Hours": true,
    "id": "50.080015,8.655258"
  },
  {
    "ID": 867,
    "Tankstellennummer": "0F085",
    "Name": "Aral Tankstelle Großkrotzenburg, Lindenstr. 33",
    "Address": "Lindenstraße 33",
    "Address__1": "",
    "City": "Großkrotzenburg",
    "Postcode": 63538,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6186318,
    "24 hour?": "FALSE",
    "Latitude": 50.081866,
    "Longitude": 8.986247,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.081866,8.986247",
    "IsOpen24Hours": false,
    "id": "50.081866,8.986247"
  },
  {
    "ID": 2077330934,
    "Tankstellennummer": "TD041798",
    "Name": "MARKTSCHORGAST AM CHRISTOPHSBU",
    "Address": "AM CHRISTOPHSBUEHL 1",
    "Address__1": "",
    "City": "MARKTSCHORGAST",
    "Postcode": 95509,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 92273329885,
    "24 hour?": "FALSE",
    "Latitude": 50.0819,
    "Longitude": 11.6645,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0819,11.6645",
    "IsOpen24Hours": false,
    "id": "50.0819,11.6645"
  },
  {
    "ID": 1039397264,
    "Tankstellennummer": "TD000732",
    "Name": "WIESBADEN KLARENTHALER STR.44",
    "Address": "KLARENTHALER STR.44",
    "Address__1": "",
    "City": "WIESBADEN",
    "Postcode": 65197,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0611-440787",
    "24 hour?": "FALSE",
    "Latitude": 50.0821,
    "Longitude": 8.2164,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0821,8.2164",
    "IsOpen24Hours": false,
    "id": "50.0821,8.2164"
  },
  {
    "ID": 856855869,
    "Tankstellennummer": 4230,
    "Name": "Agip Frankfurt",
    "Address": "Industriepark Hoechst",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 65926,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 69300928",
    "24 hour?": "TRUE",
    "Latitude": 50.08232,
    "Longitude": 8.54354,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.08232,8.54354",
    "IsOpen24Hours": true,
    "id": "50.08232,8.54354"
  },
  {
    "ID": 921,
    "Tankstellennummer": "0F120",
    "Name": "Aral Tankstelle Wiesbaden, Bierstadter Str. 51",
    "Address": "Bierstadter Straße 51",
    "Address__1": "",
    "City": "Wiesbaden",
    "Postcode": 65189,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 611565373,
    "24 hour?": "TRUE",
    "Latitude": 50.083342,
    "Longitude": 8.262308,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.083342,8.262308",
    "IsOpen24Hours": true,
    "id": "50.083342,8.262308"
  },
  {
    "ID": 804121615,
    "Tankstellennummer": "TD002816",
    "Name": "FRANKFURT GOLDSTEINSTR.221",
    "Address": "GOLDSTEINSTR.221",
    "Address__1": "",
    "City": "FRANKFURT",
    "Postcode": 60528,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "069-6661285",
    "24 hour?": "FALSE",
    "Latitude": 50.0842,
    "Longitude": 8.6247,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0842,8.6247",
    "IsOpen24Hours": false,
    "id": "50.0842,8.6247"
  },
  {
    "ID": 856,
    "Tankstellennummer": "0F077",
    "Name": "Aral Tankstelle Offenbach, Waldstr. 215",
    "Address": "Waldstraße 215",
    "Address__1": "",
    "City": "Offenbach",
    "Postcode": 63071,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6983071423,
    "24 hour?": "TRUE",
    "Latitude": 50.08688,
    "Longitude": 8.770483,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.08688,8.770483",
    "IsOpen24Hours": true,
    "id": "50.08688,8.770483"
  },
  {
    "ID": 834,
    "Tankstellennummer": "0F005",
    "Name": "Aral Tankstelle Hofheim, Zeilsheimer Str. 62",
    "Address": "Zeilsheimer Straße 62",
    "Address__1": "",
    "City": "Hofheim",
    "Postcode": 65719,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61926791,
    "24 hour?": "TRUE",
    "Latitude": 50.087986,
    "Longitude": 8.458349,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.087986,8.458349",
    "IsOpen24Hours": true,
    "id": "50.087986,8.458349"
  },
  {
    "ID": 59883434,
    "Tankstellennummer": "TD036905",
    "Name": "HOFHEIM ZEILSHEIMER STR. 37-39",
    "Address": "ZEILSHEIMER STR. 37-39",
    "Address__1": "",
    "City": "HOFHEIM",
    "Postcode": 65719,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06192-6776",
    "24 hour?": "FALSE",
    "Latitude": 50.0885,
    "Longitude": 8.4544,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0885,8.4544",
    "IsOpen24Hours": false,
    "id": "50.0885,8.4544"
  },
  {
    "ID": 276491021,
    "Tankstellennummer": "TD006817",
    "Name": "KRIFTEL FRANKFURTER STR. 92",
    "Address": "FRANKFURTER STR. 92",
    "Address__1": "",
    "City": "KRIFTEL",
    "Postcode": 65830,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06192-46296",
    "24 hour?": "FALSE",
    "Latitude": 50.0887,
    "Longitude": 8.4776,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0887,8.4776",
    "IsOpen24Hours": false,
    "id": "50.0887,8.4776"
  },
  {
    "ID": 863,
    "Tankstellennummer": "0F079",
    "Name": "Aral Tankstelle Obertshausen, Lämmerspieler Str. 50",
    "Address": "Lämmerspieler Straße 50",
    "Address__1": "",
    "City": "Obertshausen",
    "Postcode": 63179,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6104407280,
    "24 hour?": "FALSE",
    "Latitude": 50.08872,
    "Longitude": 8.86373,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.08872,8.86373",
    "IsOpen24Hours": false,
    "id": "50.08872,8.86373"
  },
  {
    "ID": 832,
    "Tankstellennummer": "0F058",
    "Name": "Aral Tankstelle Frankfurt, DarmstaedterLandstr. 304",
    "Address": "Darmstädter Landstr. 304",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60598,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 696809040,
    "24 hour?": "TRUE",
    "Latitude": 50.089701,
    "Longitude": 8.690747,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.089701,8.690747",
    "IsOpen24Hours": true,
    "id": "50.089701,8.690747"
  },
  {
    "ID": 2345,
    "Tankstellennummer": "0FB54",
    "Name": "Aral Tankstelle Ebern, Klein-Nürnberg 24",
    "Address": "Klein-Nürnberg 24",
    "Address__1": "",
    "City": "Ebern",
    "Postcode": 96106,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 95315560,
    "24 hour?": "FALSE",
    "Latitude": 50.090523,
    "Longitude": 10.795552,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.090523,10.795552",
    "IsOpen24Hours": false,
    "id": "50.090523,10.795552"
  },
  {
    "ID": 1895232562,
    "Tankstellennummer": "TD131210",
    "Name": "OFFENBACH ASCHAFFENBURGER STR",
    "Address": "ASCHAFFENBURGER STR 96",
    "Address__1": "",
    "City": "OFFENBACH",
    "Postcode": 63073,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "069-891179",
    "24 hour?": "FALSE",
    "Latitude": 50.0918,
    "Longitude": 8.801,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0918,8.801",
    "IsOpen24Hours": false,
    "id": "50.0918,8.801"
  },
  {
    "ID": 1932646238,
    "Tankstellennummer": "0FV76",
    "Name": "GEFREES",
    "Address": "Buehlweg 1",
    "Address__1": "",
    "City": "Gefrees",
    "Postcode": 95482,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 92549616926,
    "24 hour?": "FALSE",
    "Latitude": 50.094051,
    "Longitude": 11.731639,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.094051,11.731639",
    "IsOpen24Hours": false,
    "id": "50.094051,11.731639"
  },
  {
    "ID": 223662729,
    "Tankstellennummer": "TD133000",
    "Name": "ALZENAU KAELBERAUER STR. 12",
    "Address": "KAELBERAUER STR. 12",
    "Address__1": "",
    "City": "ALZENAU",
    "Postcode": 63755,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06023-1829",
    "24 hour?": "FALSE",
    "Latitude": 50.0988,
    "Longitude": 9.1,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.0988,9.1",
    "IsOpen24Hours": false,
    "id": "50.0988,9.1"
  },
  {
    "ID": 831,
    "Tankstellennummer": "0F057",
    "Name": "Aral Tankstelle Offenbach, Bieberer Str. 157",
    "Address": "Bieberer Straße 157",
    "Address__1": "",
    "City": "Offenbach",
    "Postcode": 63071,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6985703117,
    "24 hour?": "TRUE",
    "Latitude": 50.098988,
    "Longitude": 8.785816,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.098988,8.785816",
    "IsOpen24Hours": true,
    "id": "50.098988,8.785816"
  },
  {
    "ID": 2086862663,
    "Tankstellennummer": "0FY26",
    "Name": "BAT MEDENBACH OST",
    "Address": "A 3",
    "Address__1": "",
    "City": "Wiesbaden",
    "Postcode": 65207,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6122507985,
    "24 hour?": "TRUE",
    "Latitude": 50.099604,
    "Longitude": 8.3552116,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.099604,8.3552116",
    "IsOpen24Hours": true,
    "id": "50.099604,8.3552116"
  },
  {
    "ID": 833,
    "Tankstellennummer": "0F059",
    "Name": "Aral Tankstelle Frankfurt, Mainzer Landstr. 545",
    "Address": "Mainzer Landstraße 545",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 65933,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 69394954,
    "24 hour?": "TRUE",
    "Latitude": 50.100187,
    "Longitude": 8.603721,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.100187,8.603721",
    "IsOpen24Hours": true,
    "id": "50.100187,8.603721"
  },
  {
    "ID": 3417,
    "Tankstellennummer": "0FL55",
    "Name": "BP Tankstelle Frankfurt, Siemensstr. 37",
    "Address": "Siemensstraße 37",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60594,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 69622953,
    "24 hour?": "TRUE",
    "Latitude": 50.10236,
    "Longitude": 8.695524,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.10236,8.695524",
    "IsOpen24Hours": true,
    "id": "50.10236,8.695524"
  },
  {
    "ID": 2389,
    "Tankstellennummer": "0FB79",
    "Name": "Aral Tankstelle Bad Staffelstein, Lichtenfelser Str.38",
    "Address": "Lichtenfelser Straße 38",
    "Address__1": "",
    "City": "Bad Staffelstein",
    "Postcode": 96231,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 95735700,
    "24 hour?": "FALSE",
    "Latitude": 50.103878,
    "Longitude": 11.005794,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.103878,11.005794",
    "IsOpen24Hours": false,
    "id": "50.103878,11.005794"
  },
  {
    "ID": 58000296,
    "Tankstellennummer": "TD002840",
    "Name": "HANAU VOSSWALDESTR. 1",
    "Address": "VOSSWALDESTR. 1",
    "Address__1": "",
    "City": "HANAU",
    "Postcode": 63457,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06181-5206287",
    "24 hour?": "FALSE",
    "Latitude": 50.1083,
    "Longitude": 8.9689,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1083,8.9689",
    "IsOpen24Hours": false,
    "id": "50.1083,8.9689"
  },
  {
    "ID": 553447259,
    "Tankstellennummer": 4554,
    "Name": "Agip Hofheim-Lorsbach",
    "Address": "Hofheimer Str. 61",
    "Address__1": "",
    "City": "Hofheim-Lorsbach",
    "Postcode": 65719,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61929019",
    "24 hour?": "FALSE",
    "Latitude": 50.11231159,
    "Longitude": 8.42627387,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.11231159,8.42627387",
    "IsOpen24Hours": false,
    "id": "50.11231159,8.42627387"
  },
  {
    "ID": 3023,
    "Tankstellennummer": "0FI65",
    "Name": "Aral Tankstelle Frankfurt, Hanauer Landstr. 34-40",
    "Address": "Hanauer Landstraße 34-40",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60314,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 69491523,
    "24 hour?": "FALSE",
    "Latitude": 50.112922,
    "Longitude": 8.699732,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.112922,8.699732",
    "IsOpen24Hours": false,
    "id": "50.112922,8.699732"
  },
  {
    "ID": 649191745,
    "Tankstellennummer": "TD000861",
    "Name": "HANAU RODGAUSTRASSE 6",
    "Address": "RODGAUSTRASSE 6",
    "Address__1": "",
    "City": "HANAU",
    "Postcode": 63457,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06181-399572",
    "24 hour?": "FALSE",
    "Latitude": 50.1167,
    "Longitude": 8.9289,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1167,8.9289",
    "IsOpen24Hours": false,
    "id": "50.1167,8.9289"
  },
  {
    "ID": 850,
    "Tankstellennummer": "0F072",
    "Name": "Aral Autobahntankstelle Frankfurt, A 66, Südseite",
    "Address": "Aral an der A 66, Südseit",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 65929,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6930850810,
    "24 hour?": "TRUE",
    "Latitude": 50.118079,
    "Longitude": 8.541727,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.118079,8.541727",
    "IsOpen24Hours": true,
    "id": "50.118079,8.541727"
  },
  {
    "ID": 849,
    "Tankstellennummer": "0F071",
    "Name": "Aral Autobahntankstelle Frankfurt, A 66, Nordseite",
    "Address": "Aral an der A 66, Nordsei",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 65929,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6930852981,
    "24 hour?": "TRUE",
    "Latitude": 50.119608,
    "Longitude": 8.543903,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.119608,8.543903",
    "IsOpen24Hours": true,
    "id": "50.119608,8.543903"
  },
  {
    "ID": 1864401899,
    "Tankstellennummer": 4138,
    "Name": "Agip Muehlheim",
    "Address": "Friedensstrasse 108",
    "Address__1": "",
    "City": "Muehlheim",
    "Postcode": 63165,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61087280",
    "24 hour?": "TRUE",
    "Latitude": 50.1199,
    "Longitude": 8.82474,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.1199,8.82474",
    "IsOpen24Hours": true,
    "id": "50.1199,8.82474"
  },
  {
    "ID": 865796348,
    "Tankstellennummer": "0FT37",
    "Name": "FRANKFURT",
    "Address": "Carl-Benz-Str. 10",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60386,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6940809098,
    "24 hour?": "TRUE",
    "Latitude": 50.121764,
    "Longitude": 8.749124,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.121764,8.749124",
    "IsOpen24Hours": true,
    "id": "50.121764,8.749124"
  },
  {
    "ID": 3026,
    "Tankstellennummer": "0FI67",
    "Name": "Aral Tankstelle Frankfurt, Grueneburgweg 67",
    "Address": "Grueneburgweg 67",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60323,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 69723810,
    "24 hour?": "FALSE",
    "Latitude": 50.12197,
    "Longitude": 8.669096,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.12197,8.669096",
    "IsOpen24Hours": false,
    "id": "50.12197,8.669096"
  },
  {
    "ID": 874,
    "Tankstellennummer": "0F090",
    "Name": "Aral Tankstelle Frankfurt, Ratsweg 12",
    "Address": "Ratsweg 12",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60386,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 69413968,
    "24 hour?": "TRUE",
    "Latitude": 50.123524,
    "Longitude": 8.723396,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.123524,8.723396",
    "IsOpen24Hours": true,
    "id": "50.123524,8.723396"
  },
  {
    "ID": 3274,
    "Tankstellennummer": "0FK43",
    "Name": "Aral Tankstelle Altenkunstadt, Weismainer Str. 58",
    "Address": "Weismainer Straße 58",
    "Address__1": "",
    "City": "Altenkunstadt",
    "Postcode": 96264,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 95721627,
    "24 hour?": "FALSE",
    "Latitude": 50.127996,
    "Longitude": 11.239743,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.127996,11.239743",
    "IsOpen24Hours": false,
    "id": "50.127996,11.239743"
  },
  {
    "ID": 479171789,
    "Tankstellennummer": "TD000426",
    "Name": "HANAU LEIPZIGER STR. 52",
    "Address": "52 LEIPZIGER STR.",
    "Address__1": "",
    "City": "HANAU",
    "Postcode": 63450,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06181-31938",
    "24 hour?": "FALSE",
    "Latitude": 50.1284,
    "Longitude": 8.9332,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1284,8.9332",
    "IsOpen24Hours": false,
    "id": "50.1284,8.9332"
  },
  {
    "ID": 907,
    "Tankstellennummer": "0F112",
    "Name": "Aral Tankstelle Frankfurt, Am Industriehof 11",
    "Address": "Am Industriehof 11",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60487,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 69776451,
    "24 hour?": "TRUE",
    "Latitude": 50.129113,
    "Longitude": 8.624005,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.129113,8.624005",
    "IsOpen24Hours": true,
    "id": "50.129113,8.624005"
  },
  {
    "ID": 947,
    "Tankstellennummer": "0F140",
    "Name": "Aral Tankstelle Hanau, Friedrich-Ebert-Anlage 7",
    "Address": "Friedrich-Ebert-Anlage 7",
    "Address__1": "",
    "City": "Hanau",
    "Postcode": 63450,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61819698409,
    "24 hour?": "TRUE",
    "Latitude": 50.129699,
    "Longitude": 8.921511,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.129699,8.921511",
    "IsOpen24Hours": true,
    "id": "50.129699,8.921511"
  },
  {
    "ID": 864,
    "Tankstellennummer": "0F081",
    "Name": "Aral Tankstelle Maintal, Kennedy-/ Berliner Str.",
    "Address": "Kennedy-/Berliner Straße",
    "Address__1": "",
    "City": "Maintal",
    "Postcode": 63477,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6181441748,
    "24 hour?": "TRUE",
    "Latitude": 50.132162,
    "Longitude": 8.850056,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.132162,8.850056",
    "IsOpen24Hours": true,
    "id": "50.132162,8.850056"
  },
  {
    "ID": 828,
    "Tankstellennummer": "0F055",
    "Name": "Aral Tankstelle Wiesbaden, Kirchhohl 15",
    "Address": "Kirchhohl 15",
    "Address__1": "",
    "City": "Wiesbaden",
    "Postcode": 65207,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 612761428,
    "24 hour?": "FALSE",
    "Latitude": 50.132818,
    "Longitude": 8.2971,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.132818,8.2971",
    "IsOpen24Hours": false,
    "id": "50.132818,8.2971"
  },
  {
    "ID": 950,
    "Tankstellennummer": "0F142",
    "Name": "Aral Tankstelle Frankfurt, Friedberger Landstr. 300",
    "Address": "Friedberger Landstr. 300",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60389,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 699551070,
    "24 hour?": "TRUE",
    "Latitude": 50.133384,
    "Longitude": 8.696017,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.133384,8.696017",
    "IsOpen24Hours": true,
    "id": "50.133384,8.696017"
  },
  {
    "ID": 2014092617,
    "Tankstellennummer": 4098,
    "Name": "Agip Frankfurt",
    "Address": "Dortelweiler Strasse 49",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60389,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 69461333",
    "24 hour?": "FALSE",
    "Latitude": 50.13377,
    "Longitude": 8.70642,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.13377,8.70642",
    "IsOpen24Hours": false,
    "id": "50.13377,8.70642"
  },
  {
    "ID": 651315777,
    "Tankstellennummer": "TD130780",
    "Name": "EPPSTEIN LORSBACHER STR.14",
    "Address": "LORSBACHER STR.14",
    "Address__1": "",
    "City": "EPPSTEIN",
    "Postcode": 65817,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06198-349178",
    "24 hour?": "FALSE",
    "Latitude": 50.1353,
    "Longitude": 8.4004,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1353,8.4004",
    "IsOpen24Hours": false,
    "id": "50.1353,8.4004"
  },
  {
    "ID": 830493346,
    "Tankstellennummer": 3601,
    "Name": "Agip Burgkunstadt",
    "Address": "In der Au 1",
    "Address__1": "",
    "City": "Burgkunstadt",
    "Postcode": 96224,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 95721544",
    "24 hour?": "TRUE",
    "Latitude": 50.13719718,
    "Longitude": 11.25089166,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.13719718,11.25089166",
    "IsOpen24Hours": true,
    "id": "50.13719718,11.25089166"
  },
  {
    "ID": 1353649298,
    "Tankstellennummer": "TD003087",
    "Name": "SULZBACH HAUPTSTR. 103",
    "Address": "HAUPTSTR. 103",
    "Address__1": "",
    "City": "SULZBACH",
    "Postcode": 65843,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06196-71144",
    "24 hour?": "FALSE",
    "Latitude": 50.1378,
    "Longitude": 8.5192,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1378,8.5192",
    "IsOpen24Hours": false,
    "id": "50.1378,8.5192"
  },
  {
    "ID": 757280404,
    "Tankstellennummer": "0FM60",
    "Name": "BAD SODEN",
    "Address": "Königsteiner Straße 23",
    "Address__1": "",
    "City": "Bad Soden",
    "Postcode": 65812,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6196671777,
    "24 hour?": "FALSE",
    "Latitude": 50.14024,
    "Longitude": 8.505094,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.14024,8.505094",
    "IsOpen24Hours": false,
    "id": "50.14024,8.505094"
  },
  {
    "ID": 499063300,
    "Tankstellennummer": "0FM74",
    "Name": "Aral TankStelle Frankfurt, Friedberger Landstraße 325",
    "Address": "Friedberger Landstr. 325",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60389,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6913025453,
    "24 hour?": "TRUE",
    "Latitude": 50.140379,
    "Longitude": 8.698532,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.140379,8.698532",
    "IsOpen24Hours": true,
    "id": "50.140379,8.698532"
  },
  {
    "ID": 868,
    "Tankstellennummer": "0F086",
    "Name": "Aral Tankstelle Frankfurt, Ludwig-Landmann-Str. 90",
    "Address": "Ludwig-Landmann-Straße 90",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60488,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 69764309,
    "24 hour?": "FALSE",
    "Latitude": 50.141447,
    "Longitude": 8.609893,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.141447,8.609893",
    "IsOpen24Hours": false,
    "id": "50.141447,8.609893"
  },
  {
    "ID": 1879305546,
    "Tankstellennummer": "TD000173",
    "Name": "FRANKFURT VILBELER LANDSTR. 96",
    "Address": "VILBELER LANDSTR. 96",
    "Address__1": "",
    "City": "FRANKFURT",
    "Postcode": 60388,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06109-32340",
    "24 hour?": "FALSE",
    "Latitude": 50.1443,
    "Longitude": 8.753,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1443,8.753",
    "IsOpen24Hours": false,
    "id": "50.1443,8.753"
  },
  {
    "ID": 872,
    "Tankstellennummer": "0F088",
    "Name": "Aral Tankstelle Kelkheim, KelkheimerStr. 43",
    "Address": "Kelkheimer Str 43",
    "Address__1": "",
    "City": "Kelkheim",
    "Postcode": 65779,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 619561301,
    "24 hour?": "FALSE",
    "Latitude": 50.145994,
    "Longitude": 8.429585,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.145994,8.429585",
    "IsOpen24Hours": false,
    "id": "50.145994,8.429585"
  },
  {
    "ID": 58602450,
    "Tankstellennummer": 4167,
    "Name": "Agip Hanau",
    "Address": "Bruchkoebeler Landstrasse 53",
    "Address__1": "",
    "City": "Hanau",
    "Postcode": 63452,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61818408",
    "24 hour?": "FALSE",
    "Latitude": 50.146175,
    "Longitude": 8.90929,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.146175,8.90929",
    "IsOpen24Hours": false,
    "id": "50.146175,8.90929"
  },
  {
    "ID": 1184023437,
    "Tankstellennummer": "TD130860",
    "Name": "FREIGERICHT HANAUERSTR. 61",
    "Address": "HANAUERSTR. 61",
    "Address__1": "",
    "City": "FREIGERICHT",
    "Postcode": 63579,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06055-915217",
    "24 hour?": "FALSE",
    "Latitude": 50.1471,
    "Longitude": 9.1118,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1471,9.1118",
    "IsOpen24Hours": false,
    "id": "50.1471,9.1118"
  },
  {
    "ID": 1352331341,
    "Tankstellennummer": "TD000169",
    "Name": "FRANKFURT HEERSTR. 78",
    "Address": "HEERSTR. 78",
    "Address__1": "",
    "City": "FRANKFURT",
    "Postcode": 60488,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "069-762965",
    "24 hour?": "FALSE",
    "Latitude": 50.1471,
    "Longitude": 8.6121,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1471,8.6121",
    "IsOpen24Hours": false,
    "id": "50.1471,8.6121"
  },
  {
    "ID": 272376479,
    "Tankstellennummer": 4027,
    "Name": "Agip Rodenbach",
    "Address": "Gelnhaeuser Str. 1-3",
    "Address__1": "",
    "City": "Rodenbach",
    "Postcode": 63517,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61845050",
    "24 hour?": "FALSE",
    "Latitude": 50.14729573,
    "Longitude": 9.0250372,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.14729573,9.0250372",
    "IsOpen24Hours": false,
    "id": "50.14729573,9.0250372"
  },
  {
    "ID": 1011,
    "Tankstellennummer": "0FG27",
    "Name": "Aral Tankstelle Hanau, Maintaler Str. 20",
    "Address": "Maintaler Straße 20",
    "Address__1": "",
    "City": "Hanau",
    "Postcode": 63452,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61819839891,
    "24 hour?": "TRUE",
    "Latitude": 50.147583,
    "Longitude": 8.901271,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.147583,8.901271",
    "IsOpen24Hours": true,
    "id": "50.147583,8.901271"
  },
  {
    "ID": 515985744,
    "Tankstellennummer": "TD000170",
    "Name": "FRANKFURT KURHESSENSTR. 111",
    "Address": "KURHESSENSTR. 111",
    "Address__1": "",
    "City": "FRANKFURT",
    "Postcode": 60431,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "069-512131",
    "24 hour?": "FALSE",
    "Latitude": 50.148,
    "Longitude": 8.6565,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.148,8.6565",
    "IsOpen24Hours": false,
    "id": "50.148,8.6565"
  },
  {
    "ID": 507490698,
    "Tankstellennummer": 4036,
    "Name": "Agip Maintal",
    "Address": "Am Kreuzstein 55",
    "Address__1": "",
    "City": "Maintal",
    "Postcode": 63477,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61096163",
    "24 hour?": "FALSE",
    "Latitude": 50.14811,
    "Longitude": 8.80783,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.14811,8.80783",
    "IsOpen24Hours": false,
    "id": "50.14811,8.80783"
  },
  {
    "ID": 2334,
    "Tankstellennummer": "0FB44",
    "Name": "Aral Tankstelle Lichtenfels, Coburger Str.64",
    "Address": "Coburger Straße 64",
    "Address__1": "",
    "City": "Lichtenfels",
    "Postcode": 96215,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 95712633,
    "24 hour?": "FALSE",
    "Latitude": 50.151047,
    "Longitude": 11.05926,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.151047,11.05926",
    "IsOpen24Hours": false,
    "id": "50.151047,11.05926"
  },
  {
    "ID": 813,
    "Tankstellennummer": "0F045",
    "Name": "Aral Tankstelle Frankfurt, Eschersh. Landstr. 523",
    "Address": "Eschersh. Landstr.  523",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60431,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 69525947,
    "24 hour?": "FALSE",
    "Latitude": 50.151655,
    "Longitude": 8.662366,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.151655,8.662366",
    "IsOpen24Hours": false,
    "id": "50.151655,8.662366"
  },
  {
    "ID": 3111,
    "Tankstellennummer": "0FJ33",
    "Name": "Aral Tankstelle Euerdorf, An der Saale 1a",
    "Address": "An der Saale 1a",
    "Address__1": "",
    "City": "Euerdorf",
    "Postcode": 97717,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 97047580,
    "24 hour?": "FALSE",
    "Latitude": 50.151924,
    "Longitude": 10.026564,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.151924,10.026564",
    "IsOpen24Hours": false,
    "id": "50.151924,10.026564"
  },
  {
    "ID": 1816314540,
    "Tankstellennummer": "TD003111",
    "Name": "TAUNUSSTEIN AARSTR. 212",
    "Address": "AARSTR. 212",
    "Address__1": "",
    "City": "TAUNUSSTEIN",
    "Postcode": 65232,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06128-982542",
    "24 hour?": "FALSE",
    "Latitude": 50.1528,
    "Longitude": 8.1805,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1528,8.1805",
    "IsOpen24Hours": false,
    "id": "50.1528,8.1805"
  },
  {
    "ID": 1994581466,
    "Tankstellennummer": 4043,
    "Name": "Agip Frankfurt",
    "Address": "Vilbeler Landstrasse 248",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60388,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61092760",
    "24 hour?": "FALSE",
    "Latitude": 50.15693,
    "Longitude": 8.74517,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.15693,8.74517",
    "IsOpen24Hours": false,
    "id": "50.15693,8.74517"
  },
  {
    "ID": 1120706869,
    "Tankstellennummer": 3621,
    "Name": "Agip Marktzeuln",
    "Address": "Hauptstr. 40",
    "Address__1": "",
    "City": "Marktzeuln",
    "Postcode": 96275,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "09574/505",
    "24 hour?": "FALSE",
    "Latitude": 50.16035,
    "Longitude": 11.18467,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.16035,11.18467",
    "IsOpen24Hours": false,
    "id": "50.16035,11.18467"
  },
  {
    "ID": 1856782170,
    "Tankstellennummer": "TD133510",
    "Name": "NIEDERNHAUSEN IDSTEINER STR. 5",
    "Address": "IDSTEINER STR. 50",
    "Address__1": "",
    "City": "NIEDERNHAUSEN",
    "Postcode": 65527,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06127-903620",
    "24 hour?": "FALSE",
    "Latitude": 50.1643,
    "Longitude": 8.3126,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1643,8.3126",
    "IsOpen24Hours": false,
    "id": "50.1643,8.3126"
  },
  {
    "ID": 497945767,
    "Tankstellennummer": "TD131290",
    "Name": "BAD VILBEL ALTE FRANKFURTER ST",
    "Address": "ALTE FRANKFURTER STR. 92",
    "Address__1": "",
    "City": "BAD VILBEL",
    "Postcode": 61118,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06101-85770",
    "24 hour?": "FALSE",
    "Latitude": 50.165,
    "Longitude": 8.7243,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.165,8.7243",
    "IsOpen24Hours": false,
    "id": "50.165,8.7243"
  },
  {
    "ID": 3038,
    "Tankstellennummer": "0FI75",
    "Name": "Aral Tankstelle Taunusstein, Limburger Str. 34",
    "Address": "Limburger Straße 34",
    "Address__1": "",
    "City": "Taunusstein",
    "Postcode": 65232,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 612872462,
    "24 hour?": "FALSE",
    "Latitude": 50.170395,
    "Longitude": 8.211591,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.170395,8.211591",
    "IsOpen24Hours": false,
    "id": "50.170395,8.211591"
  },
  {
    "ID": 2938,
    "Tankstellennummer": "0FH97",
    "Name": "Aral Tankstelle Büchel, Hauptstr. 26",
    "Address": "Hauptstraße 26",
    "Address__1": "",
    "City": "Büchel",
    "Postcode": 56823,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2678801,
    "24 hour?": "FALSE",
    "Latitude": 50.173923,
    "Longitude": 7.082313,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.173923,7.082313",
    "IsOpen24Hours": false,
    "id": "50.173923,7.082313"
  },
  {
    "ID": 823,
    "Tankstellennummer": "0F052",
    "Name": "Aral Tankstelle Kronberg, Frankfurter Str. 52-54",
    "Address": "Frankfurter Straße 52",
    "Address__1": "",
    "City": "Kronberg",
    "Postcode": 61476,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 617379124,
    "24 hour?": "FALSE",
    "Latitude": 50.173923,
    "Longitude": 8.523387,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.173923,8.523387",
    "IsOpen24Hours": false,
    "id": "50.173923,8.523387"
  },
  {
    "ID": 815,
    "Tankstellennummer": "0F047",
    "Name": "Aral Tankstelle Langenselbold, Gelnhaeuser Str. 17",
    "Address": "Gelnhäuser Straße 17",
    "Address__1": "",
    "City": "Langenselbold",
    "Postcode": 63505,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61842103,
    "24 hour?": "FALSE",
    "Latitude": 50.175696,
    "Longitude": 9.046026,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.175696,9.046026",
    "IsOpen24Hours": false,
    "id": "50.175696,9.046026"
  },
  {
    "ID": 827,
    "Tankstellennummer": "0F054",
    "Name": "Aral Tankstelle Bruchköbel, Hauptstr. 10",
    "Address": "Hauptstraße 10",
    "Address__1": "",
    "City": "Bruchköbel",
    "Postcode": 63486,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 618177724,
    "24 hour?": "FALSE",
    "Latitude": 50.178068,
    "Longitude": 8.914258,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.178068,8.914258",
    "IsOpen24Hours": false,
    "id": "50.178068,8.914258"
  },
  {
    "ID": 995,
    "Tankstellennummer": "0FG15",
    "Name": "Aral Tankstelle Königstein, Mammolshainer Weg",
    "Address": "Mammolshainer Weg",
    "Address__1": "",
    "City": "Königstein",
    "Postcode": 61462,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6174209242,
    "24 hour?": "FALSE",
    "Latitude": 50.179242,
    "Longitude": 8.475964,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.179242,8.475964",
    "IsOpen24Hours": false,
    "id": "50.179242,8.475964"
  },
  {
    "ID": 1230113872,
    "Tankstellennummer": "TD133540",
    "Name": "BRUCHKOEBEL ROEMERSTR.16",
    "Address": "ROEMERSTR.16",
    "Address__1": "",
    "City": "BRUCHKOEBEL",
    "Postcode": 63486,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06181-576095",
    "24 hour?": "FALSE",
    "Latitude": 50.1794,
    "Longitude": 8.9092,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1794,8.9092",
    "IsOpen24Hours": false,
    "id": "50.1794,8.9092"
  },
  {
    "ID": 3043,
    "Tankstellennummer": "0FI79",
    "Name": "Aral Tankstelle Kronberg, Sodener Str. 29",
    "Address": "Sodener Straße 29",
    "Address__1": "",
    "City": "Kronberg",
    "Postcode": 61476,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 617364545,
    "24 hour?": "TRUE",
    "Latitude": 50.179907,
    "Longitude": 8.538894,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.179907,8.538894",
    "IsOpen24Hours": true,
    "id": "50.179907,8.538894"
  },
  {
    "ID": 821,
    "Tankstellennummer": "0F049",
    "Name": "Aral Tankstelle Oberursel, Frankfurter Landstr. 200",
    "Address": "Frankfurter Landstr. 200",
    "Address__1": "",
    "City": "Oberursel",
    "Postcode": 61440,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 617173993,
    "24 hour?": "TRUE",
    "Latitude": 50.180441,
    "Longitude": 8.605376,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.180441,8.605376",
    "IsOpen24Hours": true,
    "id": "50.180441,8.605376"
  },
  {
    "ID": 275586926,
    "Tankstellennummer": "TD038042",
    "Name": "ERLENSEE AUTOHOF NEUBERG",
    "Address": "ROENTGENSTR. 1",
    "Address__1": "",
    "City": "ERLENSEE",
    "Postcode": 63526,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06183-8007810",
    "24 hour?": "FALSE",
    "Latitude": 50.1824,
    "Longitude": 9.007,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1824,9.007",
    "IsOpen24Hours": false,
    "id": "50.1824,9.007"
  },
  {
    "ID": 482435883,
    "Tankstellennummer": 4026,
    "Name": "Agip Gelnhausen",
    "Address": "Hanauer Landstrasse 68",
    "Address__1": "",
    "City": "Gelnhausen",
    "Postcode": 63571,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 60516866",
    "24 hour?": "FALSE",
    "Latitude": 50.18244476,
    "Longitude": 9.13645147,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.18244476,9.13645147",
    "IsOpen24Hours": false,
    "id": "50.18244476,9.13645147"
  },
  {
    "ID": 2347,
    "Tankstellennummer": "0FB53",
    "Name": "Aral Tankstelle Stadtlauringen, Schweinfurter Str.36",
    "Address": "Schweinfurter Straße36",
    "Address__1": "",
    "City": "Stadtlauringen",
    "Postcode": 97488,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9724545,
    "24 hour?": "FALSE",
    "Latitude": 50.183213,
    "Longitude": 10.355032,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.183213,10.355032",
    "IsOpen24Hours": false,
    "id": "50.183213,10.355032"
  },
  {
    "ID": 3025,
    "Tankstellennummer": "0FI66",
    "Name": "Aral Tankstelle Frankfurt, Oberer Kalbacher Weg 35",
    "Address": "Oberer Kalbacher Weg 35",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 60437,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6995059127,
    "24 hour?": "FALSE",
    "Latitude": 50.183502,
    "Longitude": 8.659846,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.183502,8.659846",
    "IsOpen24Hours": false,
    "id": "50.183502,8.659846"
  },
  {
    "ID": 2949,
    "Tankstellennummer": "0FI09",
    "Name": "Aral Tankstelle Treis-Karden, Moselstr. 6",
    "Address": "Moselstraße 6",
    "Address__1": "",
    "City": "Treis-Karden",
    "Postcode": 56253,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26722682,
    "24 hour?": "FALSE",
    "Latitude": 50.185471,
    "Longitude": 7.305253,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.185471,7.305253",
    "IsOpen24Hours": false,
    "id": "50.185471,7.305253"
  },
  {
    "ID": 852,
    "Tankstellennummer": "0F074",
    "Name": "Aral Tankstelle Gelnhausen, Gelnhäuser Str. 1",
    "Address": "Gelnhäuser Straße 1",
    "Address__1": "",
    "City": "Gelnhausen",
    "Postcode": 63571,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 605168801,
    "24 hour?": "FALSE",
    "Latitude": 50.186713,
    "Longitude": 9.162186,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.186713,9.162186",
    "IsOpen24Hours": false,
    "id": "50.186713,9.162186"
  },
  {
    "ID": 1014,
    "Tankstellennummer": "0FE89",
    "Name": "Aral Tankstelle Bad Vilbel, Friedberger Str. 89",
    "Address": "Friedberger Straße 89",
    "Address__1": "",
    "City": "Bad Vilbel",
    "Postcode": 61118,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6101307498,
    "24 hour?": "TRUE",
    "Latitude": 50.190224,
    "Longitude": 8.742365,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.190224,8.742365",
    "IsOpen24Hours": true,
    "id": "50.190224,8.742365"
  },
  {
    "ID": 2362,
    "Tankstellennummer": "0FB64",
    "Name": "Aral Tankstelle Münchberg, Stammbacher Str. 45",
    "Address": "Stammbacher Straße 45",
    "Address__1": "",
    "City": "Münchberg",
    "Postcode": 95213,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 925180710,
    "24 hour?": "FALSE",
    "Latitude": 50.190262,
    "Longitude": 11.7784,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.190262,11.7784",
    "IsOpen24Hours": false,
    "id": "50.190262,11.7784"
  },
  {
    "ID": 839758003,
    "Tankstellennummer": "0FP70",
    "Name": "KUPS",
    "Address": "Bamberger Straße 27",
    "Address__1": "",
    "City": "Küps",
    "Postcode": 96328,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 926480269,
    "24 hour?": "FALSE",
    "Latitude": 50.191616,
    "Longitude": 11.268119,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.191616,11.268119",
    "IsOpen24Hours": false,
    "id": "50.191616,11.268119"
  },
  {
    "ID": 212791611,
    "Tankstellennummer": "TD036871",
    "Name": "FRANKFURT AN DER BORNHOHL 56",
    "Address": "AN DER BORNHOHL 56",
    "Address__1": "",
    "City": "FRANKFURT",
    "Postcode": 60437,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "069-5071584",
    "24 hour?": "FALSE",
    "Latitude": 50.1964,
    "Longitude": 8.6669,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.1964,8.6669",
    "IsOpen24Hours": false,
    "id": "50.1964,8.6669"
  },
  {
    "ID": 842857093,
    "Tankstellennummer": "TD042051",
    "Name": "MUENCHBERG AUGUST-HORCH-STR. 1",
    "Address": "AUGUST-HORCH-STR. 12",
    "Address__1": "",
    "City": "MUENCHBERG",
    "Postcode": 95213,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 92517109,
    "24 hour?": "FALSE",
    "Latitude": 50.2028,
    "Longitude": 11.7779,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.2028,11.7779",
    "IsOpen24Hours": false,
    "id": "50.2028,11.7779"
  },
  {
    "ID": 2939,
    "Tankstellennummer": "0FH98",
    "Name": "Aral Tankstelle Daun, Bonner Str. 16",
    "Address": "Bonner Straße 16",
    "Address__1": "",
    "City": "Daun",
    "Postcode": 54550,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6592985318,
    "24 hour?": "FALSE",
    "Latitude": 50.203225,
    "Longitude": 6.826713,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.203225,6.826713",
    "IsOpen24Hours": false,
    "id": "50.203225,6.826713"
  },
  {
    "ID": 1407242227,
    "Tankstellennummer": "TD130480",
    "Name": "GELNHAUSEN HOCHSTR. 23",
    "Address": "HOCHSTR. 23",
    "Address__1": "",
    "City": "GELNHAUSEN",
    "Postcode": 63571,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06051-3323",
    "24 hour?": "FALSE",
    "Latitude": 50.2071,
    "Longitude": 9.1557,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.2071,9.1557",
    "IsOpen24Hours": false,
    "id": "50.2071,9.1557"
  },
  {
    "ID": 906,
    "Tankstellennummer": "0F109",
    "Name": "Aral Autobahntankstelle Bad Homburg, Taunus-Schnellweg-Sueds.",
    "Address": "Taunus-Schnellweg-Südseit",
    "Address__1": "",
    "City": "Bad Homburg",
    "Postcode": 61352,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6172690835,
    "24 hour?": "TRUE",
    "Latitude": 50.208427,
    "Longitude": 8.615339,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.208427,8.615339",
    "IsOpen24Hours": true,
    "id": "50.208427,8.615339"
  },
  {
    "ID": 905,
    "Tankstellennummer": "0F108",
    "Name": "Aral Autobahntankstelle Bad Homburg, Taunus-Schnellweg-Nords.",
    "Address": "Taunus-Schnellweg-Nordsei",
    "Address__1": "",
    "City": "Bad Homburg",
    "Postcode": 61352,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 617221266,
    "24 hour?": "TRUE",
    "Latitude": 50.20872,
    "Longitude": 8.616669,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.20872,8.616669",
    "IsOpen24Hours": true,
    "id": "50.20872,8.616669"
  },
  {
    "ID": 3115,
    "Tankstellennummer": "0FJ36",
    "Name": "Aral Tankstelle Küps, Kanzleistr. 1",
    "Address": "Kanzleistraße 1",
    "Address__1": "",
    "City": "Küps",
    "Postcode": 96328,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 92649959073,
    "24 hour?": "TRUE",
    "Latitude": 50.210724,
    "Longitude": 11.290923,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.210724,11.290923",
    "IsOpen24Hours": true,
    "id": "50.210724,11.290923"
  },
  {
    "ID": 1724730474,
    "Tankstellennummer": 610378,
    "Name": "WESTFALEN, ULMEN",
    "Address": "Ritter-Heinrich-Str. 2",
    "Address__1": "",
    "City": "Ulmen",
    "Postcode": 56766,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02676/910661",
    "24 hour?": "FALSE",
    "Latitude": 50.2138,
    "Longitude": 6.97349,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.2138,6.97349",
    "IsOpen24Hours": false,
    "id": "50.2138,6.97349"
  },
  {
    "ID": 309444429,
    "Tankstellennummer": "TD035626",
    "Name": "IDSTEIN WIESBADENER STR. 77",
    "Address": "WIESBADENER STR. 77",
    "Address__1": "",
    "City": "IDSTEIN",
    "Postcode": 65510,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06126-953888",
    "24 hour?": "FALSE",
    "Latitude": 50.2168,
    "Longitude": 8.254,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.2168,8.254",
    "IsOpen24Hours": false,
    "id": "50.2168,8.254"
  },
  {
    "ID": 842,
    "Tankstellennummer": "0F065",
    "Name": "Aral Tankstelle Bad Homburg, Urseler Str. 38",
    "Address": "Urseler Straße 38",
    "Address__1": "",
    "City": "Bad Homburg",
    "Postcode": 61348,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6172301300,
    "24 hour?": "FALSE",
    "Latitude": 50.221659,
    "Longitude": 8.60708,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.221659,8.60708",
    "IsOpen24Hours": false,
    "id": "50.221659,8.60708"
  },
  {
    "ID": 814,
    "Tankstellennummer": "0F046",
    "Name": "Aral Tankstelle Bad Homburg, Louisenstr. 146",
    "Address": "Louisenstraße 146",
    "Address__1": "",
    "City": "Bad Homburg",
    "Postcode": 61348,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6172450201,
    "24 hour?": "FALSE",
    "Latitude": 50.221734,
    "Longitude": 8.628591,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.221734,8.628591",
    "IsOpen24Hours": false,
    "id": "50.221734,8.628591"
  },
  {
    "ID": 2032,
    "Tankstellennummer": "0F927",
    "Name": "Aral Tankstelle Grub am Forst, Gruber Str. 22",
    "Address": "Gruber Straße 22",
    "Address__1": "",
    "City": "Grub am Forst",
    "Postcode": 96271,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 95609816715,
    "24 hour?": "TRUE",
    "Latitude": 50.222629,
    "Longitude": 11.042767,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.222629,11.042767",
    "IsOpen24Hours": true,
    "id": "50.222629,11.042767"
  },
  {
    "ID": 2943,
    "Tankstellennummer": "0FI03",
    "Name": "Aral Tankstelle Gerolstein, An der Bundesstr. 410",
    "Address": "Gerolsteiner Straße 47",
    "Address__1": "",
    "City": "Pelm",
    "Postcode": 54570,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 65913700,
    "24 hour?": "FALSE",
    "Latitude": 50.224596,
    "Longitude": 6.678783,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.224596,6.678783",
    "IsOpen24Hours": false,
    "id": "50.224596,6.678783"
  },
  {
    "ID": 1016,
    "Tankstellennummer": "0FE90",
    "Name": "Aral Tankstelle Bad Homburg, Kleine Brückenstr. 34",
    "Address": "Kleine Brückenstraße 34",
    "Address__1": "",
    "City": "Bad Homburg",
    "Postcode": 61352,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6172185476,
    "24 hour?": "FALSE",
    "Latitude": 50.225309,
    "Longitude": 8.67737,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.225309,8.67737",
    "IsOpen24Hours": false,
    "id": "50.225309,8.67737"
  },
  {
    "ID": 903,
    "Tankstellennummer": "0F110",
    "Name": "Aral Tankstelle Bad Orb, Frankfurter Str.",
    "Address": "Frankfurter Straße",
    "Address__1": "",
    "City": "Bad Orb",
    "Postcode": 63619,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6052900313,
    "24 hour?": "FALSE",
    "Latitude": 50.229155,
    "Longitude": 9.343829,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.229155,9.343829",
    "IsOpen24Hours": false,
    "id": "50.229155,9.343829"
  },
  {
    "ID": 540224661,
    "Tankstellennummer": "TD041772",
    "Name": "KARBEN HOMBURGER STR. 50",
    "Address": "HOMBURGER STR. 50",
    "Address__1": "",
    "City": "KARBEN",
    "Postcode": 61184,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 50.2309,
    "Longitude": 8.7704,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.2309,8.7704",
    "IsOpen24Hours": false,
    "id": "50.2309,8.7704"
  },
  {
    "ID": 858,
    "Tankstellennummer": "0F078",
    "Name": "Aral Tankstelle Karben, Homburger Str. 66",
    "Address": "Homburger Straße 66",
    "Address__1": "",
    "City": "Karben",
    "Postcode": 61184,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60393472,
    "24 hour?": "FALSE",
    "Latitude": 50.23178,
    "Longitude": 8.766445,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.23178,8.766445",
    "IsOpen24Hours": false,
    "id": "50.23178,8.766445"
  },
  {
    "ID": 822,
    "Tankstellennummer": "0F050",
    "Name": "Aral Tankstelle Nidderau, Konrad-Adenauer-Allee 1-3",
    "Address": "Konrad-Adenauer-Allee 1-3",
    "Address__1": "",
    "City": "Nidderau",
    "Postcode": 61130,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 618722133,
    "24 hour?": "TRUE",
    "Latitude": 50.233936,
    "Longitude": 8.863251,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.233936,8.863251",
    "IsOpen24Hours": true,
    "id": "50.233936,8.863251"
  },
  {
    "ID": 3337,
    "Tankstellennummer": "0FK89",
    "Name": "Aral Tankstelle Boppard, Koblenzer Str. 237",
    "Address": "Koblenzer Straße 237",
    "Address__1": "",
    "City": "Boppard",
    "Postcode": 56154,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 67422447,
    "24 hour?": "FALSE",
    "Latitude": 50.23674,
    "Longitude": 7.577054,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.23674,7.577054",
    "IsOpen24Hours": false,
    "id": "50.23674,7.577054"
  },
  {
    "ID": 3035,
    "Tankstellennummer": "0FI73",
    "Name": "Aral Tankstelle Bad Homburg, Saalburgstr. 114",
    "Address": "Saalburgstraße 114",
    "Address__1": "",
    "City": "Bad Homburg",
    "Postcode": 61350,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 617237205,
    "24 hour?": "TRUE",
    "Latitude": 50.236995,
    "Longitude": 8.593014,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.236995,8.593014",
    "IsOpen24Hours": true,
    "id": "50.236995,8.593014"
  },
  {
    "ID": 3455,
    "Tankstellennummer": "0FL79",
    "Name": "Aral Tankstelle Löf, Moselstr. 1",
    "Address": "Moselstraße 1",
    "Address__1": "",
    "City": "Löf",
    "Postcode": 56332,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2605952325,
    "24 hour?": "FALSE",
    "Latitude": 50.239589,
    "Longitude": 7.441824,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.239589,7.441824",
    "IsOpen24Hours": false,
    "id": "50.239589,7.441824"
  },
  {
    "ID": 1729705031,
    "Tankstellennummer": "TD170740",
    "Name": "KRONACH KREUZBERGSTR. 52",
    "Address": "KREUZBERGSTR. 52",
    "Address__1": "",
    "City": "KRONACH",
    "Postcode": 96317,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09261-2202",
    "24 hour?": "FALSE",
    "Latitude": 50.2397,
    "Longitude": 11.3408,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.2397,11.3408",
    "IsOpen24Hours": false,
    "id": "50.2397,11.3408"
  },
  {
    "ID": 675814556,
    "Tankstellennummer": "0FP02",
    "Name": "HAMMERSBACH",
    "Address": "Am Lachbach 1",
    "Address__1": "",
    "City": "Hammersbach",
    "Postcode": 63546,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6185893027,
    "24 hour?": "TRUE",
    "Latitude": 50.243066,
    "Longitude": 8.997957,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.243066,8.997957",
    "IsOpen24Hours": true,
    "id": "50.243066,8.997957"
  },
  {
    "ID": 2359,
    "Tankstellennummer": "0FB62",
    "Name": "Aral Tankstelle Coburg, Ernst-Faber-Str. 19",
    "Address": "Ernst-Faber-Straße 19",
    "Address__1": "",
    "City": "Coburg",
    "Postcode": 96450,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 956110345,
    "24 hour?": "TRUE",
    "Latitude": 50.24811,
    "Longitude": 10.965753,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.24811,10.965753",
    "IsOpen24Hours": true,
    "id": "50.24811,10.965753"
  },
  {
    "ID": 3117,
    "Tankstellennummer": "0FJ38",
    "Name": "Aral Tankstelle Mitwitz, Kronacher Str. 40",
    "Address": "Kronacher Straße 40",
    "Address__1": "",
    "City": "Mitwitz",
    "Postcode": 96268,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9266254,
    "24 hour?": "FALSE",
    "Latitude": 50.24973,
    "Longitude": 11.21463,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.24973,11.21463",
    "IsOpen24Hours": false,
    "id": "50.24973,11.21463"
  },
  {
    "ID": 2333,
    "Tankstellennummer": "0FB43",
    "Name": "Aral Tankstelle Münnerstadt, Meininger Str.1",
    "Address": "Meininger Straße 1",
    "Address__1": "",
    "City": "Münnerstadt",
    "Postcode": 97702,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 97333130,
    "24 hour?": "FALSE",
    "Latitude": 50.251738,
    "Longitude": 10.195887,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.251738,10.195887",
    "IsOpen24Hours": false,
    "id": "50.251738,10.195887"
  },
  {
    "ID": 862,
    "Tankstellennummer": "0F083",
    "Name": "Aral Tankstelle Friedrichsdorf, Höhenstraße 1",
    "Address": "Höhenstraße 1",
    "Address__1": "",
    "City": "Friedrichsdorf",
    "Postcode": 61381,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 61722663645,
    "24 hour?": "FALSE",
    "Latitude": 50.252922,
    "Longitude": 8.635035,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.252922,8.635035",
    "IsOpen24Hours": false,
    "id": "50.252922,8.635035"
  },
  {
    "ID": 643703591,
    "Tankstellennummer": "TD122740",
    "Name": "UERSFELD HAUPTSTR.23",
    "Address": "HAUPTSTR.23",
    "Address__1": "",
    "City": "UERSFELD",
    "Postcode": 56767,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02657-247",
    "24 hour?": "FALSE",
    "Latitude": 50.2558,
    "Longitude": 7.0136,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.2558,7.0136",
    "IsOpen24Hours": false,
    "id": "50.2558,7.0136"
  },
  {
    "ID": 3321,
    "Tankstellennummer": "0FK76",
    "Name": "Aral Tankstelle Burkardroth, Forstmeisterstrasse 53",
    "Address": "Forstmeisterstraße 53",
    "Address__1": "",
    "City": "Burkardroth",
    "Postcode": 97705,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 97349293,
    "24 hour?": "FALSE",
    "Latitude": 50.263128,
    "Longitude": 9.994378,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.263128,9.994378",
    "IsOpen24Hours": false,
    "id": "50.263128,9.994378"
  },
  {
    "ID": 254029187,
    "Tankstellennummer": "0FY21",
    "Name": "KEHRIG",
    "Address": "A48",
    "Address__1": "",
    "City": "Kehrig",
    "Postcode": 56729,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2651900522,
    "24 hour?": "TRUE",
    "Latitude": 50.2675517,
    "Longitude": 7.2266494,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.2675517,7.2266494",
    "IsOpen24Hours": true,
    "id": "50.2675517,7.2266494"
  },
  {
    "ID": 1928129164,
    "Tankstellennummer": 3108,
    "Name": "Agip Coburg",
    "Address": "Callenberger Strasse 38",
    "Address__1": "",
    "City": "Coburg",
    "Postcode": 96450,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 95616956",
    "24 hour?": "FALSE",
    "Latitude": 50.26845,
    "Longitude": 10.95439,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.26845,10.95439",
    "IsOpen24Hours": false,
    "id": "50.26845,10.95439"
  },
  {
    "ID": 1993092458,
    "Tankstellennummer": "TD123790",
    "Name": "OLZHEIM AN DER B 51",
    "Address": "AN DER B 51",
    "Address__1": "",
    "City": "OLZHEIM",
    "Postcode": 54597,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06552-99110",
    "24 hour?": "FALSE",
    "Latitude": 50.2688,
    "Longitude": 6.4559,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.2688,6.4559",
    "IsOpen24Hours": false,
    "id": "50.2688,6.4559"
  },
  {
    "ID": 2369,
    "Tankstellennummer": "0FB67",
    "Name": "Aral Tankstelle Coburg, Rodacher Str. 69",
    "Address": "Rodacher Straße 69",
    "Address__1": "",
    "City": "Coburg",
    "Postcode": 96450,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 956166036,
    "24 hour?": "TRUE",
    "Latitude": 50.274048,
    "Longitude": 10.957376,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.274048,10.957376",
    "IsOpen24Hours": true,
    "id": "50.274048,10.957376"
  },
  {
    "ID": 1919970951,
    "Tankstellennummer": 4050,
    "Name": "Agip Friedrichsdorf-Koepp",
    "Address": "Friedberger Strasse 10",
    "Address__1": "",
    "City": "Friedrichsdorf-Koepp",
    "Postcode": 61381,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 61757067",
    "24 hour?": "FALSE",
    "Latitude": 50.27863,
    "Longitude": 8.65529,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.27863,8.65529",
    "IsOpen24Hours": false,
    "id": "50.27863,8.65529"
  },
  {
    "ID": 946,
    "Tankstellennummer": "0F139",
    "Name": "Aral Tankstelle Altenstadt, An der Bundesstr. 521",
    "Address": "An der Bundesstraße 521",
    "Address__1": "",
    "City": "Altenstadt",
    "Postcode": 63674,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60479858950,
    "24 hour?": "FALSE",
    "Latitude": 50.284888,
    "Longitude": 8.94086,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.284888,8.94086",
    "IsOpen24Hours": false,
    "id": "50.284888,8.94086"
  },
  {
    "ID": 556728651,
    "Tankstellennummer": "TD041467",
    "Name": "SCHMITTEN WEILRODER STR. 1",
    "Address": "WEILRODER STR. 1",
    "Address__1": "",
    "City": "SCHMITTEN",
    "Postcode": 61389,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 60842377,
    "24 hour?": "FALSE",
    "Latitude": 50.2893,
    "Longitude": 8.4612,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.2893,8.4612",
    "IsOpen24Hours": false,
    "id": "50.2893,8.4612"
  },
  {
    "ID": 824,
    "Tankstellennummer": "0F053",
    "Name": "Aral Tankstelle Neu-Anspach, Saalburgstr. 30",
    "Address": "Saalburgstraße 30",
    "Address__1": "",
    "City": "Neu-Anspach",
    "Postcode": 61267,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60819657590,
    "24 hour?": "FALSE",
    "Latitude": 50.28981,
    "Longitude": 8.51229,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.28981,8.51229",
    "IsOpen24Hours": false,
    "id": "50.28981,8.51229"
  },
  {
    "ID": 1044376773,
    "Tankstellennummer": "0FU08",
    "Name": "ROSBACH",
    "Address": "Carl-Benz-Straße 1",
    "Address__1": "",
    "City": "Rosbach vor der Höhe",
    "Postcode": 61191,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60038269661,
    "24 hour?": "FALSE",
    "Latitude": 50.293452,
    "Longitude": 8.693699,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.293452,8.693699",
    "IsOpen24Hours": false,
    "id": "50.293452,8.693699"
  },
  {
    "ID": 1359945433,
    "Tankstellennummer": 3042,
    "Name": "Agip Roedental",
    "Address": "Oeslauer Strasse 13",
    "Address__1": "",
    "City": "Roedental",
    "Postcode": 96472,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 95638252",
    "24 hour?": "FALSE",
    "Latitude": 50.29485,
    "Longitude": 11.04786,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.29485,11.04786",
    "IsOpen24Hours": false,
    "id": "50.29485,11.04786"
  },
  {
    "ID": 64146096,
    "Tankstellennummer": "TD003012",
    "Name": "BAD CAMBERG BAHNHOFSTR. 31 A",
    "Address": "BAHNHOFSTR. 31 a",
    "Address__1": "",
    "City": "BAD CAMBERG",
    "Postcode": 65520,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06434-8253",
    "24 hour?": "FALSE",
    "Latitude": 50.2967,
    "Longitude": 8.2601,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.2967,8.2601",
    "IsOpen24Hours": false,
    "id": "50.2967,8.2601"
  },
  {
    "ID": 2361,
    "Tankstellennummer": "0FB63",
    "Name": "Aral Tankstelle Bad Königshofen, Bamberger Str.4",
    "Address": "Bamberger Straße 4",
    "Address__1": "",
    "City": "Bad Königshofen",
    "Postcode": 97631,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 97616451,
    "24 hour?": "FALSE",
    "Latitude": 50.298095,
    "Longitude": 10.468471,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.298095,10.468471",
    "IsOpen24Hours": false,
    "id": "50.298095,10.468471"
  },
  {
    "ID": 840,
    "Tankstellennummer": "0F063",
    "Name": "Aral Autobahntankstelle Bad Camberg Ost",
    "Address": "A3",
    "Address__1": "",
    "City": "Bad Camberg",
    "Postcode": 65520,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "06434/9085738",
    "24 hour?": "TRUE",
    "Latitude": 50.30041,
    "Longitude": 8.236824,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.30041,8.236824",
    "IsOpen24Hours": true,
    "id": "50.30041,8.236824"
  },
  {
    "ID": 501096738,
    "Tankstellennummer": "TD150760",
    "Name": "LAHNSTEIN GUTENBERGSTR. 7",
    "Address": "GUTENBERGSTR. 7",
    "Address__1": "",
    "City": "LAHNSTEIN",
    "Postcode": 56112,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0152-53581813",
    "24 hour?": "FALSE",
    "Latitude": 50.3027,
    "Longitude": 7.6051,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3027,7.6051",
    "IsOpen24Hours": false,
    "id": "50.3027,7.6051"
  },
  {
    "ID": 294391208,
    "Tankstellennummer": 3148,
    "Name": "Agip Hof",
    "Address": "Wunsiedler Str. 11",
    "Address__1": "",
    "City": "Hof",
    "Postcode": 95032,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 92819701",
    "24 hour?": "TRUE",
    "Latitude": 50.30473879,
    "Longitude": 11.91968644,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.30473879,11.91968644",
    "IsOpen24Hours": true,
    "id": "50.30473879,11.91968644"
  },
  {
    "ID": 1113891053,
    "Tankstellennummer": "TD003061",
    "Name": "ROSBACH TAUNUSSTR. 1",
    "Address": "TAUNUSSTR. 1",
    "Address__1": "",
    "City": "ROSBACH",
    "Postcode": 61191,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06003-1203",
    "24 hour?": "FALSE",
    "Latitude": 50.307,
    "Longitude": 8.6972,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.307,8.6972",
    "IsOpen24Hours": false,
    "id": "50.307,8.6972"
  },
  {
    "ID": 807429434,
    "Tankstellennummer": 3092,
    "Name": "Agip Bad Brueckenau",
    "Address": "Kissinger Strasse 35",
    "Address__1": "",
    "City": "Bad Brueckenau",
    "Postcode": 97769,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 97412020",
    "24 hour?": "FALSE",
    "Latitude": 50.31187411,
    "Longitude": 9.79383164,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.31187411,9.79383164",
    "IsOpen24Hours": false,
    "id": "50.31187411,9.79383164"
  },
  {
    "ID": 940,
    "Tankstellennummer": "0F133",
    "Name": "Aral Tankstelle Kobern-Gondorf, Moselstrasse",
    "Address": "Moselstraße / B 416",
    "Address__1": "",
    "City": "Kobern-Gondorf",
    "Postcode": 56330,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26074065,
    "24 hour?": "FALSE",
    "Latitude": 50.314255,
    "Longitude": 7.462393,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.314255,7.462393",
    "IsOpen24Hours": false,
    "id": "50.314255,7.462393"
  },
  {
    "ID": 2338,
    "Tankstellennummer": "0FB48",
    "Name": "Aral Tankstelle Saal, Bahnhofstrasse 6",
    "Address": "Bahnhofstraße 8",
    "Address__1": "",
    "City": "Saal",
    "Postcode": 97633,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 97629305130,
    "24 hour?": "FALSE",
    "Latitude": 50.317464,
    "Longitude": 10.352696,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.317464,10.352696",
    "IsOpen24Hours": false,
    "id": "50.317464,10.352696"
  },
  {
    "ID": 2372,
    "Tankstellennummer": "0FB69",
    "Name": "Aral Tankstelle Hof, Fabrikzeile 5",
    "Address": "Fabrikzeile 5",
    "Address__1": "",
    "City": "Hof",
    "Postcode": 95028,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 92818402310,
    "24 hour?": "FALSE",
    "Latitude": 50.319373,
    "Longitude": 11.921987,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.319373,11.921987",
    "IsOpen24Hours": false,
    "id": "50.319373,11.921987"
  },
  {
    "ID": 1351602089,
    "Tankstellennummer": "TD039305",
    "Name": "SELBITZ OT SELLANGER STEGENWAL",
    "Address": "STEGENWALDHAUSER STR. 1",
    "Address__1": "",
    "City": "SELBITZ OT SELLANGER",
    "Postcode": 95152,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 9280953550,
    "24 hour?": "FALSE",
    "Latitude": 50.3243,
    "Longitude": 11.7845,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3243,11.7845",
    "IsOpen24Hours": false,
    "id": "50.3243,11.7845"
  },
  {
    "ID": 941,
    "Tankstellennummer": "0F134",
    "Name": "Aral Tankstelle Mayen, Kelberger Str. 63",
    "Address": "Kelberger Straße 68",
    "Address__1": "",
    "City": "Mayen",
    "Postcode": 56727,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26513438,
    "24 hour?": "FALSE",
    "Latitude": 50.325299,
    "Longitude": 7.213625,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.325299,7.213625",
    "IsOpen24Hours": false,
    "id": "50.325299,7.213625"
  },
  {
    "ID": 1021489051,
    "Tankstellennummer": 5207,
    "Name": "Agip Adorf",
    "Address": "Oelsnitzer Str.",
    "Address__1": "",
    "City": "Adorf",
    "Postcode": 8626,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 37423229",
    "24 hour?": "FALSE",
    "Latitude": 50.3264961,
    "Longitude": 12.25652487,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3264961,12.25652487",
    "IsOpen24Hours": false,
    "id": "50.3264961,12.25652487"
  },
  {
    "ID": 1746972826,
    "Tankstellennummer": 3079,
    "Name": "Agip Neustadt",
    "Address": "Am Moos 24",
    "Address__1": "",
    "City": "Neustadt",
    "Postcode": 96465,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 95686114",
    "24 hour?": "FALSE",
    "Latitude": 50.3322337,
    "Longitude": 11.11406417,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3322337,11.11406417",
    "IsOpen24Hours": false,
    "id": "50.3322337,11.11406417"
  },
  {
    "ID": 2979,
    "Tankstellennummer": "0FI31",
    "Name": "Aral Tankstelle Koblenz, Roemerstr. 221",
    "Address": "Römerstraße 221",
    "Address__1": "",
    "City": "Koblenz",
    "Postcode": 56075,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26132520,
    "24 hour?": "TRUE",
    "Latitude": 50.334287,
    "Longitude": 7.582917,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.334287,7.582917",
    "IsOpen24Hours": true,
    "id": "50.334287,7.582917"
  },
  {
    "ID": 1839341886,
    "Tankstellennummer": "TD130230",
    "Name": "USINGEN WEILBURGER STR. 30/33",
    "Address": "WEILBURGER STR. 30/33",
    "Address__1": "",
    "City": "USINGEN",
    "Postcode": 61250,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06081-2680",
    "24 hour?": "FALSE",
    "Latitude": 50.3401,
    "Longitude": 8.5349,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3401,8.5349",
    "IsOpen24Hours": false,
    "id": "50.3401,8.5349"
  },
  {
    "ID": 667086252,
    "Tankstellennummer": "TD170070",
    "Name": "BAD RODACH COBURGER STR. 42",
    "Address": "COBURGER STR. 42",
    "Address__1": "",
    "City": "BAD RODACH",
    "Postcode": 96476,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "09564-4833",
    "24 hour?": "FALSE",
    "Latitude": 50.3409,
    "Longitude": 10.784,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3409,10.784",
    "IsOpen24Hours": false,
    "id": "50.3409,10.784"
  },
  {
    "ID": 2589,
    "Tankstellennummer": "0FD22",
    "Name": "Aral Tankstelle Sonneberg, Neustadter Straße 137",
    "Address": "Neustadter Straße 137",
    "Address__1": "",
    "City": "Sonneberg",
    "Postcode": 96515,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3675802960,
    "24 hour?": "FALSE",
    "Latitude": 50.349146,
    "Longitude": 11.152811,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.349146,11.152811",
    "IsOpen24Hours": false,
    "id": "50.349146,11.152811"
  },
  {
    "ID": 2963,
    "Tankstellennummer": "0FI19",
    "Name": "Aral Tankstelle Ochtendung, Koblenzer Str. 26",
    "Address": "Koblenzer Straße 36",
    "Address__1": "",
    "City": "Ochtendung",
    "Postcode": 56299,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26259592411,
    "24 hour?": "FALSE",
    "Latitude": 50.350525,
    "Longitude": 7.39445,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.350525,7.39445",
    "IsOpen24Hours": false,
    "id": "50.350525,7.39445"
  },
  {
    "ID": 2968,
    "Tankstellennummer": "0FI21",
    "Name": "Aral Tankstelle Stadtkyll, Auelstr. 27-29",
    "Address": "Auelstraße 27-29",
    "Address__1": "",
    "City": "Stadtkyll",
    "Postcode": 54589,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6597961106,
    "24 hour?": "FALSE",
    "Latitude": 50.353611,
    "Longitude": 6.524125,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.353611,6.524125",
    "IsOpen24Hours": false,
    "id": "50.353611,6.524125"
  },
  {
    "ID": 818685981,
    "Tankstellennummer": "0FQ17",
    "Name": "OBER-MÖRLEN",
    "Address": "A5",
    "Address__1": "",
    "City": "Ober-Mörlen",
    "Postcode": 61239,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60027915,
    "24 hour?": "TRUE",
    "Latitude": 50.35566,
    "Longitude": 8.69456,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.35566,8.69456",
    "IsOpen24Hours": true,
    "id": "50.35566,8.69456"
  },
  {
    "ID": 777912340,
    "Tankstellennummer": "TD024638",
    "Name": "SONNEBERG KOEPPELSDORFER STR.",
    "Address": "KOEPPELSDORFER STR. 69",
    "Address__1": "",
    "City": "SONNEBERG",
    "Postcode": 96515,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03675-427258",
    "24 hour?": "FALSE",
    "Latitude": 50.3562,
    "Longitude": 11.18,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3562,11.18",
    "IsOpen24Hours": false,
    "id": "50.3562,11.18"
  },
  {
    "ID": 2935,
    "Tankstellennummer": "0FH95",
    "Name": "Aral Tankstelle Barweiler, An der Bundesstr. 258",
    "Address": "An der Bundesstraße 258",
    "Address__1": "",
    "City": "Barweiler",
    "Postcode": 53534,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2691930070,
    "24 hour?": "FALSE",
    "Latitude": 50.357459,
    "Longitude": 6.857173,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.357459,6.857173",
    "IsOpen24Hours": false,
    "id": "50.357459,6.857173"
  },
  {
    "ID": 3040,
    "Tankstellennummer": "0FI77",
    "Name": "Aral Tankstelle Brechen, Limburger Str. 15-23",
    "Address": "Limburger Straße 15-19",
    "Address__1": "",
    "City": "Brechen",
    "Postcode": 65611,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6438836974,
    "24 hour?": "FALSE",
    "Latitude": 50.361537,
    "Longitude": 8.170562,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.361537,8.170562",
    "IsOpen24Hours": false,
    "id": "50.361537,8.170562"
  },
  {
    "ID": 861822872,
    "Tankstellennummer": "TD000962",
    "Name": "KOBLENZ SCHLACHTHOFSTR. 88",
    "Address": "SCHLACHTHOFSTR. 88",
    "Address__1": "",
    "City": "KOBLENZ",
    "Postcode": 56073,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0261-43111",
    "24 hour?": "FALSE",
    "Latitude": 50.3625,
    "Longitude": 7.5716,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3625,7.5716",
    "IsOpen24Hours": false,
    "id": "50.3625,7.5716"
  },
  {
    "ID": 924,
    "Tankstellennummer": "0F138",
    "Name": "Aral Tankstelle Koblenz, Schlachthofstr. 79",
    "Address": "Schlachthofstraße 79",
    "Address__1": "",
    "City": "Koblenz",
    "Postcode": 56073,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26142582,
    "24 hour?": "TRUE",
    "Latitude": 50.362888,
    "Longitude": 7.574478,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.362888,7.574478",
    "IsOpen24Hours": true,
    "id": "50.362888,7.574478"
  },
  {
    "ID": 651095067,
    "Tankstellennummer": "TD023242",
    "Name": "SONNEBERG BETTELHECKER STR. 16",
    "Address": "BETTELHECKER STR. 160",
    "Address__1": "",
    "City": "SONNEBERG",
    "Postcode": 96515,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03675-702932",
    "24 hour?": "FALSE",
    "Latitude": 50.3657,
    "Longitude": 11.1433,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3657,11.1433",
    "IsOpen24Hours": false,
    "id": "50.3657,11.1433"
  },
  {
    "ID": 100712227,
    "Tankstellennummer": 610371,
    "Name": "WESTFALEN, KOBLENZ",
    "Address": "Pfarrer-Kraus-Str. 154",
    "Address__1": "",
    "City": "KOBLENZ",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 50.3697,
    "Longitude": 7.6598,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3697,7.6598",
    "IsOpen24Hours": false,
    "id": "50.3697,7.6598"
  },
  {
    "ID": 1389186651,
    "Tankstellennummer": "TD007195",
    "Name": "LIMBURG-LINTER MAINZER STR. 4",
    "Address": "MAINZER STR. 4",
    "Address__1": "",
    "City": "LIMBURG-LINTER",
    "Postcode": 65550,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06431-47600",
    "24 hour?": "FALSE",
    "Latitude": 50.3709,
    "Longitude": 8.0847,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3709,8.0847",
    "IsOpen24Hours": false,
    "id": "50.3709,8.0847"
  },
  {
    "ID": 3313,
    "Tankstellennummer": "0FK71",
    "Name": "Aral Tankstelle Diez, Nikolaus-Otto-Strasse 7-9",
    "Address": "Nikolaus-Otto-Straße 7-9",
    "Address__1": "",
    "City": "Diez",
    "Postcode": 65582,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6432910915,
    "24 hour?": "FALSE",
    "Latitude": 50.372392,
    "Longitude": 8.036092,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.372392,8.036092",
    "IsOpen24Hours": false,
    "id": "50.372392,8.036092"
  },
  {
    "ID": 932,
    "Tankstellennummer": "0F008",
    "Name": "Aral Tankstelle Adenau, Trierer Str. 1",
    "Address": "Trierer Straße 1",
    "Address__1": "",
    "City": "Adenau",
    "Postcode": 53518,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2691433,
    "24 hour?": "FALSE",
    "Latitude": 50.377937,
    "Longitude": 6.949287,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.377937,6.949287",
    "IsOpen24Hours": false,
    "id": "50.377937,6.949287"
  },
  {
    "ID": 923,
    "Tankstellennummer": "0F137",
    "Name": "Aral Tankstelle Koblenz, Andernacher Str. 207",
    "Address": "Andernacher Straße 207",
    "Address__1": "",
    "City": "Koblenz",
    "Postcode": 56070,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26183720,
    "24 hour?": "TRUE",
    "Latitude": 50.378368,
    "Longitude": 7.584987,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.378368,7.584987",
    "IsOpen24Hours": true,
    "id": "50.378368,7.584987"
  },
  {
    "ID": 1648153676,
    "Tankstellennummer": "TD039651",
    "Name": "BAD NAUHEIM FRANKFURTER STR. 2",
    "Address": "FRANKFURTER STR. 245",
    "Address__1": "",
    "City": "BAD NAUHEIM",
    "Postcode": 61231,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0176-36325455",
    "24 hour?": "FALSE",
    "Latitude": 50.3811,
    "Longitude": 8.728,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3811,8.728",
    "IsOpen24Hours": false,
    "id": "50.3811,8.728"
  },
  {
    "ID": 861,
    "Tankstellennummer": "0F082",
    "Name": "Aral Tankstelle Echzell, Hauptstr. 27",
    "Address": "Hauptstraße 27",
    "Address__1": "",
    "City": "Echzell",
    "Postcode": 61209,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6008424,
    "24 hour?": "FALSE",
    "Latitude": 50.383314,
    "Longitude": 8.882463,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.383314,8.882463",
    "IsOpen24Hours": false,
    "id": "50.383314,8.882463"
  },
  {
    "ID": 913,
    "Tankstellennummer": "0F115",
    "Name": "Aral Tankstelle Limburg, Frankfurter Str. 55",
    "Address": "Frankfurter Straße 55",
    "Address__1": "",
    "City": "Limburg",
    "Postcode": 65549,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6431408687,
    "24 hour?": "TRUE",
    "Latitude": 50.383734,
    "Longitude": 8.070257,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.383734,8.070257",
    "IsOpen24Hours": true,
    "id": "50.383734,8.070257"
  },
  {
    "ID": 302964876,
    "Tankstellennummer": "0FX61",
    "Name": "LIMBURG",
    "Address": "A3",
    "Address__1": "",
    "City": "Limburg",
    "Postcode": 65549,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 643171026,
    "24 hour?": "TRUE",
    "Latitude": 50.3853509,
    "Longitude": 8.080848,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.3853509,8.080848",
    "IsOpen24Hours": true,
    "id": "50.3853509,8.080848"
  },
  {
    "ID": 2019475528,
    "Tankstellennummer": "0FU53",
    "Name": "SCHLUCHTERN",
    "Address": "A66",
    "Address__1": "",
    "City": "Schlüchtern",
    "Postcode": 36381,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 66619164695,
    "24 hour?": "TRUE",
    "Latitude": 50.387552,
    "Longitude": 9.526144,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.387552,9.526144",
    "IsOpen24Hours": true,
    "id": "50.387552,9.526144"
  },
  {
    "ID": 1949813,
    "Tankstellennummer": "0FQ09",
    "Name": "KOBLENZ",
    "Address": "August-Horch-Straße 1-3",
    "Address__1": "",
    "City": "Koblenz",
    "Postcode": 56076,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26198859626,
    "24 hour?": "TRUE",
    "Latitude": 50.389276,
    "Longitude": 7.566403,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.389276,7.566403",
    "IsOpen24Hours": true,
    "id": "50.389276,7.566403"
  },
  {
    "ID": 804208475,
    "Tankstellennummer": 4140,
    "Name": "Agip Muelheim-Kaerlich",
    "Address": "Clemensstrasse 12",
    "Address__1": "",
    "City": "Muelheim-Kaerlich",
    "Postcode": 56218,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 26301817",
    "24 hour?": "FALSE",
    "Latitude": 50.39013,
    "Longitude": 7.48685,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.39013,7.48685",
    "IsOpen24Hours": false,
    "id": "50.39013,7.48685"
  },
  {
    "ID": 1536005831,
    "Tankstellennummer": "TD041400",
    "Name": "VALLENDAR RHEINSTR. 31",
    "Address": "RHEINSTR. 31",
    "Address__1": "",
    "City": "VALLENDAR",
    "Postcode": 56179,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0261-96371544",
    "24 hour?": "FALSE",
    "Latitude": 50.3932,
    "Longitude": 7.6168,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.3932,7.6168",
    "IsOpen24Hours": false,
    "id": "50.3932,7.6168"
  },
  {
    "ID": 31887991,
    "Tankstellennummer": 5030,
    "Name": "Agip Eisfeld (Guest)",
    "Address": "An der B 4",
    "Address__1": "",
    "City": "Eisfeld (Guest)",
    "Postcode": 98673,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 36863092",
    "24 hour?": "TRUE",
    "Latitude": 50.39444221,
    "Longitude": 10.92150216,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.39444221,10.92150216",
    "IsOpen24Hours": true,
    "id": "50.39444221,10.92150216"
  },
  {
    "ID": 512549125,
    "Tankstellennummer": "TD026625",
    "Name": "SCHOENECK KLINGENTHALER STR.3",
    "Address": "KLINGENTHALER STR. 3",
    "Address__1": "",
    "City": "SCHOENECK",
    "Postcode": 8261,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037464-82595",
    "24 hour?": "FALSE",
    "Latitude": 50.395,
    "Longitude": 12.3406,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.395,12.3406",
    "IsOpen24Hours": false,
    "id": "50.395,12.3406"
  },
  {
    "ID": 863124558,
    "Tankstellennummer": "0FO90",
    "Name": "MULHEIM KÄRLICH",
    "Address": "In der Pützgewann 3 b",
    "Address__1": "",
    "City": "Mülheim Kärlich",
    "Postcode": 56218,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26309669283,
    "24 hour?": "TRUE",
    "Latitude": 50.396488,
    "Longitude": 7.512766,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.396488,7.512766",
    "IsOpen24Hours": true,
    "id": "50.396488,7.512766"
  },
  {
    "ID": 1013,
    "Tankstellennummer": "0FE88",
    "Name": "Aral Tankstelle Wölfersheim, Seestr. 34",
    "Address": "Seestraße 34",
    "Address__1": "",
    "City": "Wölfersheim",
    "Postcode": 61200,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60365204,
    "24 hour?": "TRUE",
    "Latitude": 50.402727,
    "Longitude": 8.820052,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.402727,8.820052",
    "IsOpen24Hours": true,
    "id": "50.402727,8.820052"
  },
  {
    "ID": 477486144,
    "Tankstellennummer": "0FY17",
    "Name": "B-RUDOLPHSTEIN, BAT FRANKENWAL",
    "Address": "A9",
    "Address__1": "",
    "City": "Berg-Rudolphstein",
    "Postcode": 95180,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9293940910,
    "24 hour?": "TRUE",
    "Latitude": 50.404203,
    "Longitude": 11.773599,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.404203,11.773599",
    "IsOpen24Hours": true,
    "id": "50.404203,11.773599"
  },
  {
    "ID": 1012,
    "Tankstellennummer": "0FE87",
    "Name": "Aral Tankstelle Nidda, Raun 112",
    "Address": "Raun 112",
    "Address__1": "",
    "City": "Nidda",
    "Postcode": 63667,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60432456,
    "24 hour?": "FALSE",
    "Latitude": 50.40858,
    "Longitude": 9.013224,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.40858,9.013224",
    "IsOpen24Hours": false,
    "id": "50.40858,9.013224"
  },
  {
    "ID": 199140162,
    "Tankstellennummer": "0FY37",
    "Name": "KALBACH",
    "Address": "A7",
    "Address__1": "",
    "City": "Kalbach",
    "Postcode": 36148,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9742275,
    "24 hour?": "TRUE",
    "Latitude": 50.410318,
    "Longitude": 9.732433,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.410318,9.732433",
    "IsOpen24Hours": true,
    "id": "50.410318,9.732433"
  },
  {
    "ID": 1705,
    "Tankstellennummer": "0F702",
    "Name": "Aral Autobahntankstelle Kalbach, Uttringhausen West (A 7)",
    "Address": "A7",
    "Address__1": "",
    "City": "Kalbach",
    "Postcode": 36148,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 9742204,
    "24 hour?": "TRUE",
    "Latitude": 50.419785,
    "Longitude": 9.735476,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.419785,9.735476",
    "IsOpen24Hours": true,
    "id": "50.419785,9.735476"
  },
  {
    "ID": 2034225643,
    "Tankstellennummer": "TD000724",
    "Name": "NIDDA HOHERODSKOPFSTR. 2",
    "Address": "HOHERODSKOPFSTR. 2",
    "Address__1": "",
    "City": "NIDDA",
    "Postcode": 63667,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06043-7031",
    "24 hour?": "FALSE",
    "Latitude": 50.4201,
    "Longitude": 9.0091,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4201,9.0091",
    "IsOpen24Hours": false,
    "id": "50.4201,9.0091"
  },
  {
    "ID": 1051595493,
    "Tankstellennummer": "TD041640",
    "Name": "MELLRICHSTADT A71, MELLRICHSTA",
    "Address": "A71 RASTANLAGE MELLRICHSTAEDTER HOE",
    "Address__1": "HE OST",
    "City": "MELLRICHSTADT",
    "Postcode": 97638,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 97767090343,
    "24 hour?": "FALSE",
    "Latitude": 50.4214,
    "Longitude": 10.349,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4214,10.349",
    "IsOpen24Hours": false,
    "id": "50.4214,10.349"
  },
  {
    "ID": 243602621,
    "Tankstellennummer": "TD032680",
    "Name": "STEINACH SONNEBERGER STR. 86",
    "Address": "SONNEBERGER STR. 86",
    "Address__1": "",
    "City": "STEINACH",
    "Postcode": 96523,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036762-31911",
    "24 hour?": "FALSE",
    "Latitude": 50.4223,
    "Longitude": 11.1661,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4223,11.1661",
    "IsOpen24Hours": false,
    "id": "50.4223,11.1661"
  },
  {
    "ID": 1937891312,
    "Tankstellennummer": "TD120310",
    "Name": "ANDERNACH AKTIENSTR. 88",
    "Address": "AKTIENSTR. 88",
    "Address__1": "",
    "City": "ANDERNACH",
    "Postcode": 56626,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02632-44457",
    "24 hour?": "FALSE",
    "Latitude": 50.4236,
    "Longitude": 7.4059,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4236,7.4059",
    "IsOpen24Hours": false,
    "id": "50.4236,7.4059"
  },
  {
    "ID": 831385845,
    "Tankstellennummer": "0FN96",
    "Name": "MELLRICHSTADT",
    "Address": "A 71",
    "Address__1": "",
    "City": "Mellrichstadt",
    "Postcode": 97638,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 97767094943,
    "24 hour?": "TRUE",
    "Latitude": 50.423602,
    "Longitude": 10.351844,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.423602,10.351844",
    "IsOpen24Hours": true,
    "id": "50.423602,10.351844"
  },
  {
    "ID": 2326,
    "Tankstellennummer": "0FB37",
    "Name": "Aral Tankstelle Mellrichstadt, Oberstreuer Str. 13",
    "Address": "Oberstreuer Straße 13",
    "Address__1": "",
    "City": "Mellrichstadt",
    "Postcode": 97638,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 977681501680,
    "24 hour?": "FALSE",
    "Latitude": 50.424482,
    "Longitude": 10.297077,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.424482,10.297077",
    "IsOpen24Hours": false,
    "id": "50.424482,10.297077"
  },
  {
    "ID": 1624740412,
    "Tankstellennummer": "TD150280",
    "Name": "NEUWIED NEUWIEDER STR. 19",
    "Address": "NEUWIEDER STR. 19 b",
    "Address__1": "",
    "City": "NEUWIED",
    "Postcode": 56566,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02622-903328",
    "24 hour?": "FALSE",
    "Latitude": 50.4247,
    "Longitude": 7.542,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4247,7.542",
    "IsOpen24Hours": false,
    "id": "50.4247,7.542"
  },
  {
    "ID": 2559,
    "Tankstellennummer": "0FC95",
    "Name": "Aral Tankstelle Oelsnitz, Plauensche Str.65",
    "Address": "Plauensche Straße 65",
    "Address__1": "",
    "City": "Oelsnitz",
    "Postcode": 8606,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3742122180,
    "24 hour?": "FALSE",
    "Latitude": 50.426245,
    "Longitude": 12.176679,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.426245,12.176679",
    "IsOpen24Hours": false,
    "id": "50.426245,12.176679"
  },
  {
    "ID": 2971,
    "Tankstellennummer": "0FI24",
    "Name": "Aral Tankstelle Bendorf, Hauptstr. 170",
    "Address": "Hauptstraße 170",
    "Address__1": "",
    "City": "Bendorf",
    "Postcode": 56170,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2622923413,
    "24 hour?": "FALSE",
    "Latitude": 50.426868,
    "Longitude": 7.571692,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.426868,7.571692",
    "IsOpen24Hours": false,
    "id": "50.426868,7.571692"
  },
  {
    "ID": 3027,
    "Tankstellennummer": "0FI68",
    "Name": "Aral Tankstelle Gedern, Lauterbacher Str. 68",
    "Address": "Lauterbacher Straße 68",
    "Address__1": "",
    "City": "Gedern",
    "Postcode": 63688,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 60451369,
    "24 hour?": "FALSE",
    "Latitude": 50.429139,
    "Longitude": 9.196706,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.429139,9.196706",
    "IsOpen24Hours": false,
    "id": "50.429139,9.196706"
  },
  {
    "ID": 885,
    "Tankstellennummer": "0F095",
    "Name": "Aral Tankstelle Montabaur, Koblenzer Str. 25a",
    "Address": "Koblenzer Straße 25 a",
    "Address__1": "",
    "City": "Montabaur",
    "Postcode": 56410,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2602970181,
    "24 hour?": "FALSE",
    "Latitude": 50.429346,
    "Longitude": 7.822906,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.429346,7.822906",
    "IsOpen24Hours": false,
    "id": "50.429346,7.822906"
  },
  {
    "ID": 60648806,
    "Tankstellennummer": 5045,
    "Name": "Agip Hirschberg",
    "Address": "An Der A 9",
    "Address__1": "",
    "City": "Hirschberg",
    "Postcode": 7927,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 36644260",
    "24 hour?": "TRUE",
    "Latitude": 50.43021873,
    "Longitude": 11.7988143,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.43021873,11.7988143",
    "IsOpen24Hours": true,
    "id": "50.43021873,11.7988143"
  },
  {
    "ID": 578703325,
    "Tankstellennummer": "TD041392",
    "Name": "NEUWIED LANGENDORFER STR. 52",
    "Address": "LANGENDORFER STR. 52",
    "Address__1": "",
    "City": "NEUWIED",
    "Postcode": 56564,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02631-9549451",
    "24 hour?": "FALSE",
    "Latitude": 50.434,
    "Longitude": 7.4551,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.434,7.4551",
    "IsOpen24Hours": false,
    "id": "50.434,7.4551"
  },
  {
    "ID": 1568476202,
    "Tankstellennummer": "TD040691",
    "Name": "WEILMUENSTER WEILSTRASSE 112",
    "Address": "WEILSTRASSE 112",
    "Address__1": "",
    "City": "WEILMUENSTER",
    "Postcode": 35789,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06472-8313603",
    "24 hour?": "FALSE",
    "Latitude": 50.4341,
    "Longitude": 8.3745,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4341,8.3745",
    "IsOpen24Hours": false,
    "id": "50.4341,8.3745"
  },
  {
    "ID": 493828714,
    "Tankstellennummer": "TD023937",
    "Name": "GEFELL HOFER STR. 38",
    "Address": "HOFER STR. 38",
    "Address__1": "",
    "City": "GEFELL",
    "Postcode": 7926,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036649-82258",
    "24 hour?": "FALSE",
    "Latitude": 50.4352,
    "Longitude": 11.8556,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4352,11.8556",
    "IsOpen24Hours": false,
    "id": "50.4352,11.8556"
  },
  {
    "ID": 1363292339,
    "Tankstellennummer": "TD130420",
    "Name": "BUTZBACH GRIEDELER STR.72",
    "Address": "GRIEDELER STR.72",
    "Address__1": "",
    "City": "BUTZBACH",
    "Postcode": 35510,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06033-60763",
    "24 hour?": "FALSE",
    "Latitude": 50.4365,
    "Longitude": 8.6815,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4365,8.6815",
    "IsOpen24Hours": false,
    "id": "50.4365,8.6815"
  },
  {
    "ID": 893,
    "Tankstellennummer": "0F101",
    "Name": "Aral Tankstelle Butzbach, Griedeler Str. 70",
    "Address": "Griedeler Straße 70",
    "Address__1": "",
    "City": "Butzbach",
    "Postcode": 35510,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6033970722,
    "24 hour?": "TRUE",
    "Latitude": 50.43661,
    "Longitude": 8.681257,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.43661,8.681257",
    "IsOpen24Hours": true,
    "id": "50.43661,8.681257"
  },
  {
    "ID": 1669148214,
    "Tankstellennummer": "TD000743",
    "Name": "ANDERNACH KOBLENZER STR. 53-55",
    "Address": "KOBLENZER STR. 53-55",
    "Address__1": "",
    "City": "ANDERNACH",
    "Postcode": 56626,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02632-493988",
    "24 hour?": "FALSE",
    "Latitude": 50.4371,
    "Longitude": 7.4163,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4371,7.4163",
    "IsOpen24Hours": false,
    "id": "50.4371,7.4163"
  },
  {
    "ID": 59460732,
    "Tankstellennummer": "TD150190",
    "Name": "NIEDERZISSEN BAB-BROHLTAL-OST/",
    "Address": "BAB-BROHLTAL-OST/A 61",
    "Address__1": "",
    "City": "NIEDERZISSEN",
    "Postcode": 56651,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02636-9419460",
    "24 hour?": "FALSE",
    "Latitude": 50.4398,
    "Longitude": 7.2261,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4398,7.2261",
    "IsOpen24Hours": false,
    "id": "50.4398,7.2261"
  },
  {
    "ID": 3323,
    "Tankstellennummer": "0FK78",
    "Name": "Aral Tankstelle Montabaur, Alleestr. 18",
    "Address": "Alleestraße 18",
    "Address__1": "",
    "City": "Montabaur",
    "Postcode": 56410,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2602917369,
    "24 hour?": "TRUE",
    "Latitude": 50.44011,
    "Longitude": 7.830385,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.44011,7.830385",
    "IsOpen24Hours": true,
    "id": "50.44011,7.830385"
  },
  {
    "ID": 649838763,
    "Tankstellennummer": "TD131890",
    "Name": "NIEDERZISSEN BAB BROHLTAL WEST",
    "Address": "BAB BROHLTAL WEST/A 61",
    "Address__1": "",
    "City": "NIEDERZISSEN",
    "Postcode": 56651,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02636-970077",
    "24 hour?": "FALSE",
    "Latitude": 50.4418,
    "Longitude": 7.2247,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4418,7.2247",
    "IsOpen24Hours": false,
    "id": "50.4418,7.2247"
  },
  {
    "ID": 884,
    "Tankstellennummer": "0F094",
    "Name": "Aral Tankstelle Höhr-Grenzhausen, Am Autobahnzubringer",
    "Address": "Am Autobahnzubringer/West",
    "Address__1": "",
    "City": "Höhr-Grenzhausen",
    "Postcode": 56203,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26242696,
    "24 hour?": "TRUE",
    "Latitude": 50.441919,
    "Longitude": 7.666416,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.441919,7.666416",
    "IsOpen24Hours": true,
    "id": "50.441919,7.666416"
  },
  {
    "ID": 1625033151,
    "Tankstellennummer": 4091,
    "Name": "Agip Neuwied",
    "Address": "Engersgaustrasse 67",
    "Address__1": "",
    "City": "Neuwied",
    "Postcode": 56566,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 26228322",
    "24 hour?": "FALSE",
    "Latitude": 50.4421,
    "Longitude": 7.54527,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4421,7.54527",
    "IsOpen24Hours": false,
    "id": "50.4421,7.54527"
  },
  {
    "ID": 165099288,
    "Tankstellennummer": "TD150200",
    "Name": "HOEHR-GRENZHAUSEN LINDENSTR. 4",
    "Address": "LINDENSTR. 40",
    "Address__1": "",
    "City": "HOEHR-GRENZHAUSEN",
    "Postcode": 56203,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02624-5077",
    "24 hour?": "FALSE",
    "Latitude": 50.4425,
    "Longitude": 7.6526,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4425,7.6526",
    "IsOpen24Hours": false,
    "id": "50.4425,7.6526"
  },
  {
    "ID": 943,
    "Tankstellennummer": "0F135",
    "Name": "Aral Tankstelle Neuwied, Berggärtenstr.",
    "Address": "Berggärtenstraße",
    "Address__1": "",
    "City": "Neuwied",
    "Postcode": 56564,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 263131991,
    "24 hour?": "TRUE",
    "Latitude": 50.443075,
    "Longitude": 7.469469,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.443075,7.469469",
    "IsOpen24Hours": true,
    "id": "50.443075,7.469469"
  },
  {
    "ID": 210824809,
    "Tankstellennummer": "TD038307",
    "Name": "NEUWIED RUDOLF-DIESEL-STR. 3-5",
    "Address": "RUDOLF-DIESEL-STR. 3-5",
    "Address__1": "",
    "City": "NEUWIED",
    "Postcode": 56566,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02631-9427150",
    "24 hour?": "FALSE",
    "Latitude": 50.4434,
    "Longitude": 7.4988,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4434,7.4988",
    "IsOpen24Hours": false,
    "id": "50.4434,7.4988"
  },
  {
    "ID": 1866261571,
    "Tankstellennummer": "TD000853",
    "Name": "HEILIGENROTH INDUSTRIESTRASSE",
    "Address": "INDUSTRIESTRASSE 26",
    "Address__1": "",
    "City": "HEILIGENROTH",
    "Postcode": 56412,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02602-1342860",
    "24 hour?": "FALSE",
    "Latitude": 50.4495,
    "Longitude": 7.8525,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4495,7.8525",
    "IsOpen24Hours": false,
    "id": "50.4495,7.8525"
  },
  {
    "ID": 2139652162,
    "Tankstellennummer": "0FG19",
    "Name": "BLANKENHEIM",
    "Address": "Mülheimer Heide 1",
    "Address__1": "",
    "City": "Blankenheim",
    "Postcode": 53945,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2449919251,
    "24 hour?": "TRUE",
    "Latitude": 50.449959,
    "Longitude": 6.667896,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.449959,6.667896",
    "IsOpen24Hours": true,
    "id": "50.449959,6.667896"
  },
  {
    "ID": 542784903,
    "Tankstellennummer": "TD035675",
    "Name": "HEILIGENROTH INDUSTRIESTRASSE",
    "Address": "INDUSTRIESTRASSE 2",
    "Address__1": "",
    "City": "HEILIGENROTH",
    "Postcode": 56412,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02602-8387954",
    "24 hour?": "FALSE",
    "Latitude": 50.4515,
    "Longitude": 7.8485,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4515,7.8485",
    "IsOpen24Hours": false,
    "id": "50.4515,7.8485"
  },
  {
    "ID": 3011,
    "Tankstellennummer": "0FI57",
    "Name": "Aral Tankstelle Neuhof, Lindenplatz 1",
    "Address": "Lindenplatz 1",
    "Address__1": "",
    "City": "Neuhof",
    "Postcode": 36119,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 66552241,
    "24 hour?": "FALSE",
    "Latitude": 50.454747,
    "Longitude": 9.614162,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.454747,9.614162",
    "IsOpen24Hours": false,
    "id": "50.454747,9.614162"
  },
  {
    "ID": 333393931,
    "Tankstellennummer": "TD150160",
    "Name": "BURGBROHL BROHLTALSTR. 21",
    "Address": "BROHLTALSTR. 21",
    "Address__1": "",
    "City": "BURGBROHL",
    "Postcode": 56659,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02636-929196",
    "24 hour?": "FALSE",
    "Latitude": 50.4576,
    "Longitude": 7.2827,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4576,7.2827",
    "IsOpen24Hours": false,
    "id": "50.4576,7.2827"
  },
  {
    "ID": 935,
    "Tankstellennummer": "0F131",
    "Name": "Aral Tankstelle Niederzissen, Brohltalstraße 71",
    "Address": "Brohltalstraße 71",
    "Address__1": "",
    "City": "Niederzissen",
    "Postcode": 56651,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26366272,
    "24 hour?": "FALSE",
    "Latitude": 50.460181,
    "Longitude": 7.223001,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.460181,7.223001",
    "IsOpen24Hours": false,
    "id": "50.460181,7.223001"
  },
  {
    "ID": 65170070,
    "Tankstellennummer": "TD150340",
    "Name": "RANSBACH-BAUMBACH RHEINSTR. 99",
    "Address": "RHEINSTR. 99",
    "Address__1": "",
    "City": "RANSBACH-BAUMBACH",
    "Postcode": 56235,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02623-923636",
    "24 hour?": "FALSE",
    "Latitude": 50.4636,
    "Longitude": 7.7262,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4636,7.7262",
    "IsOpen24Hours": false,
    "id": "50.4636,7.7262"
  },
  {
    "ID": 1344992598,
    "Tankstellennummer": "0FY20",
    "Name": "PLAUEN",
    "Address": "A 72",
    "Address__1": "",
    "City": "Plauen",
    "Postcode": 8527,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 374141580,
    "24 hour?": "TRUE",
    "Latitude": 50.4673088,
    "Longitude": 12.1852238,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.4673088,12.1852238",
    "IsOpen24Hours": true,
    "id": "50.4673088,12.1852238"
  },
  {
    "ID": 859,
    "Tankstellennummer": "0F080",
    "Name": "Aral Autohof Beselich, An Der B 49 Südseite",
    "Address": "An der B 49 Südseite",
    "Address__1": "",
    "City": "Beselich",
    "Postcode": 65614,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6484396,
    "24 hour?": "TRUE",
    "Latitude": 50.467915,
    "Longitude": 8.137499,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.467915,8.137499",
    "IsOpen24Hours": true,
    "id": "50.467915,8.137499"
  },
  {
    "ID": 36254967,
    "Tankstellennummer": "TD150730",
    "Name": "WIRGES BAHNHOFSTR. 84",
    "Address": "BAHNHOFSTR. 84",
    "Address__1": "",
    "City": "WIRGES",
    "Postcode": 56422,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02602-69032",
    "24 hour?": "FALSE",
    "Latitude": 50.4688,
    "Longitude": 7.7881,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4688,7.7881",
    "IsOpen24Hours": false,
    "id": "50.4688,7.7881"
  },
  {
    "ID": 9976,
    "Tankstellennummer": "0FM43",
    "Name": "Aral Tankstelle Beselich, B 49/Nordseite",
    "Address": "B 49/Nordseite",
    "Address__1": "",
    "City": "Beselich",
    "Postcode": 65614,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6484890071,
    "24 hour?": "TRUE",
    "Latitude": 50.46912,
    "Longitude": 8.137242,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.46912,8.137242",
    "IsOpen24Hours": true,
    "id": "50.46912,8.137242"
  },
  {
    "ID": 660472732,
    "Tankstellennummer": "TD000786",
    "Name": "KALL AHRSTR. 24",
    "Address": "AHRSTR. 24",
    "Address__1": "",
    "City": "KALL",
    "Postcode": 53925,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02447-95050",
    "24 hour?": "FALSE",
    "Latitude": 50.4708,
    "Longitude": 6.5309,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4708,6.5309",
    "IsOpen24Hours": false,
    "id": "50.4708,6.5309"
  },
  {
    "ID": 2643,
    "Tankstellennummer": "0FD67",
    "Name": "Aral Tankstelle Falkenstein, Plauensche Str. 70",
    "Address": "Plauensche Straße 70",
    "Address__1": "",
    "City": "Falkenstein",
    "Postcode": 8223,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 374571566,
    "24 hour?": "FALSE",
    "Latitude": 50.474174,
    "Longitude": 12.354368,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.474174,12.354368",
    "IsOpen24Hours": false,
    "id": "50.474174,12.354368"
  },
  {
    "ID": 758356308,
    "Tankstellennummer": "TD150330",
    "Name": "WIRGES CHR.-HEIBEL-STR. 41",
    "Address": "CHR.-HEIBEL-STR. 41",
    "Address__1": "",
    "City": "WIRGES",
    "Postcode": 56422,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02602-680830",
    "24 hour?": "FALSE",
    "Latitude": 50.4749,
    "Longitude": 7.7929,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4749,7.7929",
    "IsOpen24Hours": false,
    "id": "50.4749,7.7929"
  },
  {
    "ID": 2082662004,
    "Tankstellennummer": 4019,
    "Name": "Agip Hadamar",
    "Address": "Siegener Strasse",
    "Address__1": "",
    "City": "Hadamar",
    "Postcode": 65589,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 64332630",
    "24 hour?": "FALSE",
    "Latitude": 50.47772,
    "Longitude": 8.05952,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.47772,8.05952",
    "IsOpen24Hours": false,
    "id": "50.47772,8.05952"
  },
  {
    "ID": 651836946,
    "Tankstellennummer": "TD035485",
    "Name": "MOGENDORF AUTOHOF REIMERSHECK",
    "Address": "AUTOHOF REIMERSHECK 3",
    "Address__1": "",
    "City": "MOGENDORF",
    "Postcode": 56424,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02623-9262132",
    "24 hour?": "FALSE",
    "Latitude": 50.4828,
    "Longitude": 7.7491,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4828,7.7491",
    "IsOpen24Hours": false,
    "id": "50.4828,7.7491"
  },
  {
    "ID": 557693374,
    "Tankstellennummer": 5204,
    "Name": "Agip Plauen",
    "Address": "Oelsnitzer Str. 10-12",
    "Address__1": "",
    "City": "Plauen",
    "Postcode": 8527,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 37412248",
    "24 hour?": "TRUE",
    "Latitude": 50.48498767,
    "Longitude": 12.13900274,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.48498767,12.13900274",
    "IsOpen24Hours": true,
    "id": "50.48498767,12.13900274"
  },
  {
    "ID": 1215376045,
    "Tankstellennummer": "0FU72",
    "Name": "AHRBRUCK",
    "Address": "Hauptstraße 49",
    "Address__1": "",
    "City": "Ahrbrück",
    "Postcode": 53506,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "02643/9048899",
    "24 hour?": "FALSE",
    "Latitude": 50.485544,
    "Longitude": 6.973878,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.485544,6.973878",
    "IsOpen24Hours": false,
    "id": "50.485544,6.973878"
  },
  {
    "ID": 1725927911,
    "Tankstellennummer": "0FT92",
    "Name": "BROHL-LUTZING",
    "Address": "An der B9",
    "Address__1": "",
    "City": "Brohl-Lützing",
    "Postcode": 56656,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26334729318,
    "24 hour?": "FALSE",
    "Latitude": 50.486287,
    "Longitude": 7.32906,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.486287,7.32906",
    "IsOpen24Hours": false,
    "id": "50.486287,7.32906"
  },
  {
    "ID": 1633694617,
    "Tankstellennummer": "TD038158",
    "Name": "EICHENZELL RHOENHOF 5",
    "Address": "RHOENHOF 5",
    "Address__1": "",
    "City": "EICHENZELL",
    "Postcode": 36124,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06659-915450",
    "24 hour?": "FALSE",
    "Latitude": 50.4884,
    "Longitude": 9.708,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4884,9.708",
    "IsOpen24Hours": false,
    "id": "50.4884,9.708"
  },
  {
    "ID": 2985,
    "Tankstellennummer": "0FI37",
    "Name": "Aral Tankstelle Mogendorf, Rheinstr. 35",
    "Address": "Rheinstraße 35",
    "Address__1": "",
    "City": "Mogendorf",
    "Postcode": 56424,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26235662,
    "24 hour?": "FALSE",
    "Latitude": 50.488943,
    "Longitude": 7.75606,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.488943,7.75606",
    "IsOpen24Hours": false,
    "id": "50.488943,7.75606"
  },
  {
    "ID": 2641,
    "Tankstellennummer": "0FD65",
    "Name": "Aral Tankstelle Plauen, Trockentalstr. 64",
    "Address": "Trockentalstraße 64",
    "Address__1": "",
    "City": "Plauen",
    "Postcode": 8527,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3741137066,
    "24 hour?": "FALSE",
    "Latitude": 50.49016,
    "Longitude": 12.130948,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.49016,12.130948",
    "IsOpen24Hours": false,
    "id": "50.49016,12.130948"
  },
  {
    "ID": 870358118,
    "Tankstellennummer": 5209,
    "Name": "Agip Auerbach",
    "Address": "Goeltzschtalstr. 75",
    "Address__1": "",
    "City": "Auerbach",
    "Postcode": 8209,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 37441711",
    "24 hour?": "TRUE",
    "Latitude": 50.49522921,
    "Longitude": 12.39653702,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.49522921,12.39653702",
    "IsOpen24Hours": true,
    "id": "50.49522921,12.39653702"
  },
  {
    "ID": 505598815,
    "Tankstellennummer": "TD000852",
    "Name": "ANHAUSEN NEUWIEDER STR. 5",
    "Address": "NEUWIEDER STR. 5",
    "Address__1": "",
    "City": "ANHAUSEN",
    "Postcode": 56584,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02639-961593",
    "24 hour?": "FALSE",
    "Latitude": 50.4965,
    "Longitude": 7.5527,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.4965,7.5527",
    "IsOpen24Hours": false,
    "id": "50.4965,7.5527"
  },
  {
    "ID": 2546,
    "Tankstellennummer": "0FC87",
    "Name": "Aral Tankstelle Plauen, Hammerstr. 65",
    "Address": "Hammerstraße 65",
    "Address__1": "",
    "City": "Plauen",
    "Postcode": 8523,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3741223173,
    "24 hour?": "TRUE",
    "Latitude": 50.499787,
    "Longitude": 12.147388,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.499787,12.147388",
    "IsOpen24Hours": true,
    "id": "50.499787,12.147388"
  },
  {
    "ID": 426632026,
    "Tankstellennummer": "0FN64",
    "Name": "EIBENSTOCK",
    "Address": "Schneeberger Straße 30",
    "Address__1": "",
    "City": "Eibenstock",
    "Postcode": 8309,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 377523145,
    "24 hour?": "FALSE",
    "Latitude": 50.501301,
    "Longitude": 12.603101,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.501301,12.603101",
    "IsOpen24Hours": false,
    "id": "50.501301,12.603101"
  },
  {
    "ID": 859429258,
    "Tankstellennummer": "TD150720",
    "Name": "HERSCHBACH HAUPTSTR. 5",
    "Address": "HAUPTSTR. 5",
    "Address__1": "",
    "City": "HERSCHBACH",
    "Postcode": 56414,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06435-4070013",
    "24 hour?": "FALSE",
    "Latitude": 50.5034,
    "Longitude": 7.9279,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5034,7.9279",
    "IsOpen24Hours": false,
    "id": "50.5034,7.9279"
  },
  {
    "ID": 1047671696,
    "Tankstellennummer": "TD024711",
    "Name": "SCHLEUSINGEN OT WALDAU HAUPTST",
    "Address": "HAUPTSTRASSE 121",
    "Address__1": "",
    "City": "SCHLEUSINGEN OT WALDAU",
    "Postcode": 98553,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036878-61521",
    "24 hour?": "FALSE",
    "Latitude": 50.5044,
    "Longitude": 10.8337,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5044,10.8337",
    "IsOpen24Hours": false,
    "id": "50.5044,10.8337"
  },
  {
    "ID": 841,
    "Tankstellennummer": "0F064",
    "Name": "Aral Tankstelle Waldbrunn, Steinbacher Str. 1",
    "Address": "Steinbacher Straße1",
    "Address__1": "",
    "City": "Waldbrunn",
    "Postcode": 65620,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6436602648,
    "24 hour?": "FALSE",
    "Latitude": 50.505561,
    "Longitude": 8.092233,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.505561,8.092233",
    "IsOpen24Hours": false,
    "id": "50.505561,8.092233"
  },
  {
    "ID": 680382046,
    "Tankstellennummer": "TD033860",
    "Name": "PLAUEN FRIEDRICH-ECKARDT-STRAS",
    "Address": "FRIEDRICH-ECKARDT-STRASSE 2",
    "Address__1": "",
    "City": "PLAUEN",
    "Postcode": 8529,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03741-442003",
    "24 hour?": "FALSE",
    "Latitude": 50.508,
    "Longitude": 12.1585,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.508,12.1585",
    "IsOpen24Hours": false,
    "id": "50.508,12.1585"
  },
  {
    "ID": 1007722872,
    "Tankstellennummer": 4170,
    "Name": "Agip Braunfels/Lahn",
    "Address": "Kaiser-Friedrich-Str. 1a",
    "Address__1": "",
    "City": "Braunfels/Lahn",
    "Postcode": 35619,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "06442/9320047",
    "24 hour?": "FALSE",
    "Latitude": 50.51772,
    "Longitude": 8.39122,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.51772,8.39122",
    "IsOpen24Hours": false,
    "id": "50.51772,8.39122"
  },
  {
    "ID": 3033,
    "Tankstellennummer": "0FI72",
    "Name": "Aral Tankstelle Hüttenberg, Frankfurter Str. 12",
    "Address": "Frankfurter Straße 12",
    "Address__1": "",
    "City": "Hüttenberg",
    "Postcode": 35625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 644174273,
    "24 hour?": "FALSE",
    "Latitude": 50.521868,
    "Longitude": 8.572381,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.521868,8.572381",
    "IsOpen24Hours": false,
    "id": "50.521868,8.572381"
  },
  {
    "ID": 1646,
    "Tankstellennummer": "0F663",
    "Name": "Aral Tankstelle Lich, Giessener Str.29",
    "Address": "Giessener Straße 29",
    "Address__1": "",
    "City": "Lich",
    "Postcode": 35423,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6404950220,
    "24 hour?": "TRUE",
    "Latitude": 50.524535,
    "Longitude": 8.817428,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.524535,8.817428",
    "IsOpen24Hours": true,
    "id": "50.524535,8.817428"
  },
  {
    "ID": 1903951243,
    "Tankstellennummer": "TD024588",
    "Name": "LICHTE SAALFELDER STR. 90",
    "Address": "SAALFELDER STR. 90",
    "Address__1": "",
    "City": "NEUHAUS AM RENNWEG OT LICHTE",
    "Postcode": 98724,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036701-60425",
    "24 hour?": "FALSE",
    "Latitude": 50.5253,
    "Longitude": 11.2237,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5253,11.2237",
    "IsOpen24Hours": false,
    "id": "50.5253,11.2237"
  },
  {
    "ID": 1021075300,
    "Tankstellennummer": "TD024521",
    "Name": "GRAEFENTHAL PROBSTZELLAER STR.",
    "Address": "PROBSTZELLAER STR. 15",
    "Address__1": "",
    "City": "GRAEFENTHAL",
    "Postcode": 98743,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036703-80739",
    "24 hour?": "FALSE",
    "Latitude": 50.5273,
    "Longitude": 11.3127,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5273,11.3127",
    "IsOpen24Hours": false,
    "id": "50.5273,11.3127"
  },
  {
    "ID": 221749946,
    "Tankstellennummer": 4780,
    "Name": "Agip Selters",
    "Address": "Rheinstr. 1a",
    "Address__1": "",
    "City": "Selters (Westerwald)",
    "Postcode": 56242,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "02626/5208",
    "24 hour?": "FALSE",
    "Latitude": 50.52831,
    "Longitude": 7.75815,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.52831,7.75815",
    "IsOpen24Hours": false,
    "id": "50.52831,7.75815"
  },
  {
    "ID": 2947,
    "Tankstellennummer": "0FI07",
    "Name": "Aral Tankstelle Monschau, Hauptstr. 144",
    "Address": "Hauptstraße 144",
    "Address__1": "",
    "City": "Monschau",
    "Postcode": 52156,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24723401,
    "24 hour?": "FALSE",
    "Latitude": 50.529719,
    "Longitude": 6.262684,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.529719,6.262684",
    "IsOpen24Hours": false,
    "id": "50.529719,6.262684"
  },
  {
    "ID": 2622,
    "Tankstellennummer": "0FD47",
    "Name": "Aral Tankstelle Schwarzenberg, Schwarzenberger Str. 4",
    "Address": "Schwarzenberger Straße 4",
    "Address__1": "",
    "City": "Schwarzenberg",
    "Postcode": 8340,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 377486350,
    "24 hour?": "TRUE",
    "Latitude": 50.531156,
    "Longitude": 12.811705,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.531156,12.811705",
    "IsOpen24Hours": true,
    "id": "50.531156,12.811705"
  },
  {
    "ID": 1349575004,
    "Tankstellennummer": "TD040485",
    "Name": "MENGERSKIRCHEN, ERLENWIESE 21",
    "Address": "ERLENWIESE 21",
    "Address__1": "",
    "City": "MENGERSKIRCHEN",
    "Postcode": 35794,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06476-411250",
    "24 hour?": "FALSE",
    "Latitude": 50.5317,
    "Longitude": 8.1433,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5317,8.1433",
    "IsOpen24Hours": false,
    "id": "50.5317,8.1433"
  },
  {
    "ID": 1601221097,
    "Tankstellennummer": "TD003038",
    "Name": "LINDEN FRANKFURTER STR. 2",
    "Address": "FRANKFURTER STR.2",
    "Address__1": "",
    "City": "LINDEN",
    "Postcode": 35440,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06403-2522",
    "24 hour?": "FALSE",
    "Latitude": 50.5325,
    "Longitude": 8.65,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5325,8.65",
    "IsOpen24Hours": false,
    "id": "50.5325,8.65"
  },
  {
    "ID": 1676731674,
    "Tankstellennummer": "TD026823",
    "Name": "RODEWISCH LENGENFELDER STR. 4",
    "Address": "LENGENFELDER STR. 4 A",
    "Address__1": "",
    "City": "RODEWISCH",
    "Postcode": 8228,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03744-32200",
    "24 hour?": "FALSE",
    "Latitude": 50.5331,
    "Longitude": 12.4038,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5331,12.4038",
    "IsOpen24Hours": false,
    "id": "50.5331,12.4038"
  },
  {
    "ID": 845947286,
    "Tankstellennummer": "0FO97",
    "Name": "SELTERS",
    "Address": "Schützstraße 2",
    "Address__1": "",
    "City": "Selters",
    "Postcode": 56242,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2626921605,
    "24 hour?": "FALSE",
    "Latitude": 50.537626,
    "Longitude": 7.75951,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.537626,7.75951",
    "IsOpen24Hours": false,
    "id": "50.537626,7.75951"
  },
  {
    "ID": 846060734,
    "Tankstellennummer": 4166,
    "Name": "Agip Linden",
    "Address": "Robert-Bosch-Strasse 14",
    "Address__1": "",
    "City": "Linden",
    "Postcode": 35440,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 64037202",
    "24 hour?": "FALSE",
    "Latitude": 50.53763,
    "Longitude": 8.65385,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.53763,8.65385",
    "IsOpen24Hours": false,
    "id": "50.53763,8.65385"
  },
  {
    "ID": 559009534,
    "Tankstellennummer": "TD025163",
    "Name": "TREUEN INNERE HERLASGRUENER ST",
    "Address": "INNERE HERLASGRUENER STR. 17",
    "Address__1": "",
    "City": "TREUEN",
    "Postcode": 8233,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037468-2639",
    "24 hour?": "FALSE",
    "Latitude": 50.5442,
    "Longitude": 12.2992,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5442,12.2992",
    "IsOpen24Hours": false,
    "id": "50.5442,12.2992"
  },
  {
    "ID": 928,
    "Tankstellennummer": "0F125",
    "Name": "Aral Tankstelle Bad Neuenahr-Ahrweil, Sebastianstr. 158",
    "Address": "Sebastianstraße 158",
    "Address__1": "",
    "City": "Bad Neuenahr-Ahrweil",
    "Postcode": 53474,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 264135033,
    "24 hour?": "FALSE",
    "Latitude": 50.545523,
    "Longitude": 7.112139,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.545523,7.112139",
    "IsOpen24Hours": false,
    "id": "50.545523,7.112139"
  },
  {
    "ID": 1124078343,
    "Tankstellennummer": 4146,
    "Name": "Agip Fulda",
    "Address": "Pacelli-Allee 1 A",
    "Address__1": "",
    "City": "Fulda",
    "Postcode": 36043,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 66163985",
    "24 hour?": "FALSE",
    "Latitude": 50.54708,
    "Longitude": 9.70289,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.54708,9.70289",
    "IsOpen24Hours": false,
    "id": "50.54708,9.70289"
  },
  {
    "ID": 3318,
    "Tankstellennummer": "0FK73",
    "Name": "Aral Tankstelle Dierdorf, Neuwieder Str. 38-40",
    "Address": "Neuwieder Straße 38-40",
    "Address__1": "",
    "City": "Dierdorf",
    "Postcode": 56269,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26892038,
    "24 hour?": "TRUE",
    "Latitude": 50.54755,
    "Longitude": 7.647419,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.54755,7.647419",
    "IsOpen24Hours": true,
    "id": "50.54755,7.647419"
  },
  {
    "ID": 597934984,
    "Tankstellennummer": "0FU79",
    "Name": "DERNBACH",
    "Address": "A3",
    "Address__1": "",
    "City": "Dernbach",
    "Postcode": 56307,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2689979680,
    "24 hour?": "TRUE",
    "Latitude": 50.547978,
    "Longitude": 7.58523,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.547978,7.58523",
    "IsOpen24Hours": true,
    "id": "50.547978,7.58523"
  },
  {
    "ID": 1696,
    "Tankstellennummer": "0F695",
    "Name": "Aral Tankstelle Leun, Brückenstrasse 1 A",
    "Address": "Brückenstraße 2",
    "Address__1": "",
    "City": "Leun",
    "Postcode": 35638,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 64738434,
    "24 hour?": "FALSE",
    "Latitude": 50.548085,
    "Longitude": 8.358471,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.548085,8.358471",
    "IsOpen24Hours": false,
    "id": "50.548085,8.358471"
  },
  {
    "ID": 2961,
    "Tankstellennummer": "0FI17",
    "Name": "Aral Tankstelle Bad Neuenahr-Ahr., Heerstr. 51",
    "Address": "Heerstraße 51",
    "Address__1": "",
    "City": "Bad Neuenahr-Ahr.",
    "Postcode": 53474,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26411594,
    "24 hour?": "FALSE",
    "Latitude": 50.548185,
    "Longitude": 7.13891,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.548185,7.13891",
    "IsOpen24Hours": false,
    "id": "50.548185,7.13891"
  },
  {
    "ID": 25220283,
    "Tankstellennummer": "TD001016",
    "Name": "SINZIG KOELNER STR. 17 A",
    "Address": "KOELNER STR. 17 A",
    "Address__1": "",
    "City": "SINZIG",
    "Postcode": 53489,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02642-409200",
    "24 hour?": "FALSE",
    "Latitude": 50.5484,
    "Longitude": 7.245,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5484,7.245",
    "IsOpen24Hours": false,
    "id": "50.5484,7.245"
  },
  {
    "ID": 814988189,
    "Tankstellennummer": 610363,
    "Name": "WESTFALEN, BAD NEUENAHR",
    "Address": "Heerstr. 166",
    "Address__1": "",
    "City": "Bad Neuenahr",
    "Postcode": 53474,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02641/204594",
    "24 hour?": "FALSE",
    "Latitude": 50.5493,
    "Longitude": 7.1308,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5493,7.1308",
    "IsOpen24Hours": false,
    "id": "50.5493,7.1308"
  },
  {
    "ID": 3046,
    "Tankstellennummer": "0FI82",
    "Name": "Aral Tankstelle Wetzlar, Bergstr. 4-6",
    "Address": "Bergstraße 4",
    "Address__1": "",
    "City": "Wetzlar",
    "Postcode": 35578,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 644122440,
    "24 hour?": "TRUE",
    "Latitude": 50.550764,
    "Longitude": 8.502649,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.550764,8.502649",
    "IsOpen24Hours": true,
    "id": "50.550764,8.502649"
  },
  {
    "ID": 975,
    "Tankstellennummer": "0F163",
    "Name": "Aral Tankstelle Petersberg, Petersberger Str. 101",
    "Address": "Petersberger Straße 101",
    "Address__1": "",
    "City": "Petersberg",
    "Postcode": 36100,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 66165535,
    "24 hour?": "FALSE",
    "Latitude": 50.552548,
    "Longitude": 9.701944,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.552548,9.701944",
    "IsOpen24Hours": false,
    "id": "50.552548,9.701944"
  },
  {
    "ID": 1669,
    "Tankstellennummer": "0F676",
    "Name": "Aral Tankstelle Wetzlar, Karl-Kellner-Ring 2",
    "Address": "Karl-Kellner-Ring 2",
    "Address__1": "",
    "City": "Wetzlar",
    "Postcode": 35576,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6441921390,
    "24 hour?": "FALSE",
    "Latitude": 50.553514,
    "Longitude": 8.498047,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.553514,8.498047",
    "IsOpen24Hours": false,
    "id": "50.553514,8.498047"
  },
  {
    "ID": 2122381920,
    "Tankstellennummer": "TD039172",
    "Name": "SOLMS MITTELBIEL 2",
    "Address": "MITTELBIEL 2",
    "Address__1": "",
    "City": "SOLMS",
    "Postcode": 35606,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06441-9521852",
    "24 hour?": "FALSE",
    "Latitude": 50.5544,
    "Longitude": 8.4124,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5544,8.4124",
    "IsOpen24Hours": false,
    "id": "50.5544,8.4124"
  },
  {
    "ID": 846282426,
    "Tankstellennummer": "TD019356",
    "Name": "HERBSTEIN HESSENSTR. 79",
    "Address": "HESSENSTR. 79",
    "Address__1": "",
    "City": "HERBSTEIN",
    "Postcode": 36358,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06643-1347",
    "24 hour?": "FALSE",
    "Latitude": 50.5565,
    "Longitude": 9.3504,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5565,9.3504",
    "IsOpen24Hours": false,
    "id": "50.5565,9.3504"
  },
  {
    "ID": 2998,
    "Tankstellennummer": "0FI49",
    "Name": "Aral Tankstelle Westerburg, Willmenroder Str. 32",
    "Address": "Willmenroder Straße 32",
    "Address__1": "",
    "City": "Westerburg",
    "Postcode": 56457,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26634844,
    "24 hour?": "FALSE",
    "Latitude": 50.556685,
    "Longitude": 7.973047,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.556685,7.973047",
    "IsOpen24Hours": false,
    "id": "50.556685,7.973047"
  },
  {
    "ID": 1588775753,
    "Tankstellennummer": "TD033464",
    "Name": "MEININGEN WERRASTR.",
    "Address": "STEINWEG 26",
    "Address__1": "",
    "City": "MEININGEN",
    "Postcode": 98617,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03693-476431",
    "24 hour?": "FALSE",
    "Latitude": 50.5595,
    "Longitude": 10.4131,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5595,10.4131",
    "IsOpen24Hours": false,
    "id": "50.5595,10.4131"
  },
  {
    "ID": 1661,
    "Tankstellennummer": "0F671",
    "Name": "Aral Tankstelle Fulda, Schlitzer Str. 105",
    "Address": "Schlitzer Straße 105",
    "Address__1": "",
    "City": "Fulda",
    "Postcode": 36039,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 66151681,
    "24 hour?": "FALSE",
    "Latitude": 50.565528,
    "Longitude": 9.657295,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.565528,9.657295",
    "IsOpen24Hours": false,
    "id": "50.565528,9.657295"
  },
  {
    "ID": 767025193,
    "Tankstellennummer": "0FO93",
    "Name": "WILLROTH",
    "Address": "Raiffeisenstraße 17",
    "Address__1": "",
    "City": "Willroth",
    "Postcode": 56594,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2687929100,
    "24 hour?": "TRUE",
    "Latitude": 50.565968,
    "Longitude": 7.526535,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.565968,7.526535",
    "IsOpen24Hours": true,
    "id": "50.565968,7.526535"
  },
  {
    "ID": 509085403,
    "Tankstellennummer": "TD041277",
    "Name": "WETZLAR HERMANNSTEINERSTR.30",
    "Address": "HERMANNSTEINERSTR. 30",
    "Address__1": "",
    "City": "WETZLAR",
    "Postcode": 35576,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06441-36333",
    "24 hour?": "FALSE",
    "Latitude": 50.5683,
    "Longitude": 8.4997,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5683,8.4997",
    "IsOpen24Hours": false,
    "id": "50.5683,8.4997"
  },
  {
    "ID": 1362994057,
    "Tankstellennummer": "TD000950",
    "Name": "LINZ ASBACHER STR. 55",
    "Address": "ASBACHER STR. 55",
    "Address__1": "",
    "City": "LINZ",
    "Postcode": 53545,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02644-2749",
    "24 hour?": "FALSE",
    "Latitude": 50.5697,
    "Longitude": 7.2869,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5697,7.2869",
    "IsOpen24Hours": false,
    "id": "50.5697,7.2869"
  },
  {
    "ID": 2073,
    "Tankstellennummer": "0F949",
    "Name": "Aral Tankstelle Schleiden, Kölner Str. 13",
    "Address": "Kölner Straße 13",
    "Address__1": "",
    "City": "Schleiden",
    "Postcode": 53937,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24442395,
    "24 hour?": "FALSE",
    "Latitude": 50.570695,
    "Longitude": 6.50612,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.570695,6.50612",
    "IsOpen24Hours": false,
    "id": "50.570695,6.50612"
  },
  {
    "ID": 519905270,
    "Tankstellennummer": "TD039990",
    "Name": "REMAGEN SINZIGER STR. 39",
    "Address": "SINZIGER STR. 39",
    "Address__1": "",
    "City": "REMAGEN",
    "Postcode": 53424,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02642-901700",
    "24 hour?": "FALSE",
    "Latitude": 50.5708,
    "Longitude": 7.2383,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5708,7.2383",
    "IsOpen24Hours": false,
    "id": "50.5708,7.2383"
  },
  {
    "ID": 308589979,
    "Tankstellennummer": "TD037556",
    "Name": "REMAGEN SINZIGER STR. 15",
    "Address": "SINZIGER STR. 15",
    "Address__1": "",
    "City": "REMAGEN",
    "Postcode": 53424,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02642-22045",
    "24 hour?": "FALSE",
    "Latitude": 50.5729,
    "Longitude": 7.2353,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5729,7.2353",
    "IsOpen24Hours": false,
    "id": "50.5729,7.2353"
  },
  {
    "ID": 961,
    "Tankstellennummer": "0F151",
    "Name": "Aral Tankstelle Gießen, Schiffenberger Weg 68",
    "Address": "Schiffenberger Weg 68",
    "Address__1": "",
    "City": "Gießen",
    "Postcode": 35394,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 64176428,
    "24 hour?": "TRUE",
    "Latitude": 50.574159,
    "Longitude": 8.684971,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.574159,8.684971",
    "IsOpen24Hours": true,
    "id": "50.574159,8.684971"
  },
  {
    "ID": 1765300112,
    "Tankstellennummer": 5119,
    "Name": "Agip Lengenfeld",
    "Address": "Zwickauer Strasse 16",
    "Address__1": "",
    "City": "Lengenfeld",
    "Postcode": 8485,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 37606378",
    "24 hour?": "TRUE",
    "Latitude": 50.57422777,
    "Longitude": 12.37429416,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.57422777,12.37429416",
    "IsOpen24Hours": true,
    "id": "50.57422777,12.37429416"
  },
  {
    "ID": 2996,
    "Tankstellennummer": "0FI47",
    "Name": "Aral Tankstelle Steinen, Hohe Str. 1",
    "Address": "Hohe Straße 1",
    "Address__1": "",
    "City": "Steinen",
    "Postcode": 56244,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2666632,
    "24 hour?": "FALSE",
    "Latitude": 50.574775,
    "Longitude": 7.809739,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.574775,7.809739",
    "IsOpen24Hours": false,
    "id": "50.574775,7.809739"
  },
  {
    "ID": 3028,
    "Tankstellennummer": "0FI69",
    "Name": "Aral Tankstelle Lahnau, Waldgirmeser Str. 6",
    "Address": "Waldgirmeser Straße 6",
    "Address__1": "",
    "City": "Lahnau",
    "Postcode": 35633,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 644196470,
    "24 hour?": "FALSE",
    "Latitude": 50.578194,
    "Longitude": 8.564324,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.578194,8.564324",
    "IsOpen24Hours": false,
    "id": "50.578194,8.564324"
  },
  {
    "ID": 2642,
    "Tankstellennummer": "0FD66",
    "Name": "Aral Tankstelle Aue, Bockauer Talstr. 20",
    "Address": "Bockauer Talstraße 20",
    "Address__1": "",
    "City": "Aue",
    "Postcode": 8280,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 377120368,
    "24 hour?": "FALSE",
    "Latitude": 50.579574,
    "Longitude": 12.692009,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.579574,12.692009",
    "IsOpen24Hours": false,
    "id": "50.579574,12.692009"
  },
  {
    "ID": 937312723,
    "Tankstellennummer": "0FV98",
    "Name": "FULDA",
    "Address": "Michelsrombacher Straße 6",
    "Address__1": "",
    "City": "Fulda",
    "Postcode": 36039,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 66196211314,
    "24 hour?": "TRUE",
    "Latitude": 50.5811827,
    "Longitude": 9.7059969,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.5811827,9.7059969",
    "IsOpen24Hours": true,
    "id": "50.5811827,9.7059969"
  },
  {
    "ID": 966576102,
    "Tankstellennummer": "0FP72",
    "Name": "Aral TankStelle Großenlüder, An der Aspe 1",
    "Address": "An der Aspe 1",
    "Address__1": "",
    "City": "Großenlüder",
    "Postcode": 36137,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 66487128,
    "24 hour?": "FALSE",
    "Latitude": 50.582054,
    "Longitude": 9.5420412,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.582054,9.5420412",
    "IsOpen24Hours": false,
    "id": "50.582054,9.5420412"
  },
  {
    "ID": 1048445089,
    "Tankstellennummer": "TD035840",
    "Name": "HERPF WALLDORFER STRASSE 59",
    "Address": "WALLDORFER STRASSE 59",
    "Address__1": "",
    "City": "HERPF",
    "Postcode": 98617,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036943-63204",
    "24 hour?": "FALSE",
    "Latitude": 50.5835,
    "Longitude": 10.339,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5835,10.339",
    "IsOpen24Hours": false,
    "id": "50.5835,10.339"
  },
  {
    "ID": 881,
    "Tankstellennummer": "0F093",
    "Name": "Aral Tankstelle Langenhahn, Koblenzer Str. 15",
    "Address": "Koblenzer Straße 15",
    "Address__1": "",
    "City": "Langenhahn",
    "Postcode": 56459,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2663969011,
    "24 hour?": "FALSE",
    "Latitude": 50.583839,
    "Longitude": 7.918299,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.583839,7.918299",
    "IsOpen24Hours": false,
    "id": "50.583839,7.918299"
  },
  {
    "ID": 1648001290,
    "Tankstellennummer": "TD040683",
    "Name": "HEUCHELHEIM GIESSENER STR.120",
    "Address": "GIESSENER STR.120",
    "Address__1": "",
    "City": "HEUCHELHEIM",
    "Postcode": 35452,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0641-63624",
    "24 hour?": "FALSE",
    "Latitude": 50.5863,
    "Longitude": 8.6414,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5863,8.6414",
    "IsOpen24Hours": false,
    "id": "50.5863,8.6414"
  },
  {
    "ID": 1717684641,
    "Tankstellennummer": "0FN23",
    "Name": "PAUSA",
    "Address": "Neunkirchener Straße 2",
    "Address__1": "",
    "City": "Pausa",
    "Postcode": 7952,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 374327739,
    "24 hour?": "FALSE",
    "Latitude": 50.587923,
    "Longitude": 11.998185,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.587923,11.998185",
    "IsOpen24Hours": false,
    "id": "50.587923,11.998185"
  },
  {
    "ID": 318803749,
    "Tankstellennummer": 5257,
    "Name": "Agip Grossbreitenbach",
    "Address": "Ilmenauer Str. 7 g",
    "Address__1": "",
    "City": "Grossbreitenbach",
    "Postcode": 98701,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "036781/41077",
    "24 hour?": "FALSE",
    "Latitude": 50.58807,
    "Longitude": 10.99362,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.58807,10.99362",
    "IsOpen24Hours": false,
    "id": "50.58807,10.99362"
  },
  {
    "ID": 1224154775,
    "Tankstellennummer": "TD023226",
    "Name": "MEININGEN DOLMARSTR. 38",
    "Address": "DOLMARSTR.38",
    "Address__1": "",
    "City": "MEININGEN",
    "Postcode": 98617,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03693-506135",
    "24 hour?": "FALSE",
    "Latitude": 50.5881,
    "Longitude": 10.4229,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5881,10.4229",
    "IsOpen24Hours": false,
    "id": "50.5881,10.4229"
  },
  {
    "ID": 832520082,
    "Tankstellennummer": "TD037697",
    "Name": "SUHL SCHUETZENSTR. 1A",
    "Address": "SCHUETZENSTR. 1A",
    "Address__1": "",
    "City": "SUHL",
    "Postcode": 98527,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03681-807271",
    "24 hour?": "FALSE",
    "Latitude": 50.5881,
    "Longitude": 10.7192,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5881,10.7192",
    "IsOpen24Hours": false,
    "id": "50.5881,10.7192"
  },
  {
    "ID": 2077,
    "Tankstellennummer": "0F953",
    "Name": "Aral Tankstelle Mechernich, Heerstr. 88",
    "Address": "Heerstraße 88",
    "Address__1": "",
    "City": "Mechernich",
    "Postcode": 53894,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 244331365,
    "24 hour?": "FALSE",
    "Latitude": 50.588352,
    "Longitude": 6.647133,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.588352,6.647133",
    "IsOpen24Hours": false,
    "id": "50.588352,6.647133"
  },
  {
    "ID": 650848883,
    "Tankstellennummer": "TD025247",
    "Name": "ANNABERG-BUCHHOLZ DRESDNER STR",
    "Address": "DRESDNER STR. 5",
    "Address__1": "",
    "City": "ANNABERG-BUCHHOLZ",
    "Postcode": 9456,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03733-53417",
    "24 hour?": "FALSE",
    "Latitude": 50.5903,
    "Longitude": 13.0135,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5903,13.0135",
    "IsOpen24Hours": false,
    "id": "50.5903,13.0135"
  },
  {
    "ID": 2645,
    "Tankstellennummer": "0FD69",
    "Name": "Aral Tankstelle Annaberg-Buchholz, Chemnitzer Str. 34",
    "Address": "Chemnitzer Straße 34",
    "Address__1": "",
    "City": "Annaberg-Buchholz",
    "Postcode": 9456,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 373351015,
    "24 hour?": "TRUE",
    "Latitude": 50.591124,
    "Longitude": 13.011043,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.591124,13.011043",
    "IsOpen24Hours": true,
    "id": "50.591124,13.011043"
  },
  {
    "ID": 1356264128,
    "Tankstellennummer": "0FU38",
    "Name": "BUSECK",
    "Address": "Ganseburg 7",
    "Address__1": "",
    "City": "Buseck",
    "Postcode": 35418,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 64085034120,
    "24 hour?": "FALSE",
    "Latitude": 50.591518,
    "Longitude": 8.781615,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.591518,8.781615",
    "IsOpen24Hours": false,
    "id": "50.591518,8.781615"
  },
  {
    "ID": 207789436,
    "Tankstellennummer": "0FO92",
    "Name": "PUDERBACH",
    "Address": "Auf der Held 2",
    "Address__1": "",
    "City": "Puderbach",
    "Postcode": 56305,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2684978878,
    "24 hour?": "FALSE",
    "Latitude": 50.593321,
    "Longitude": 7.60749,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.593321,7.60749",
    "IsOpen24Hours": false,
    "id": "50.593321,7.60749"
  },
  {
    "ID": 43044528,
    "Tankstellennummer": "TD035881",
    "Name": "REISKIRCHEN AN DER B 49",
    "Address": "AN DER B 49",
    "Address__1": "",
    "City": "REISKIRCHEN",
    "Postcode": 35447,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 6408549756,
    "24 hour?": "FALSE",
    "Latitude": 50.5961,
    "Longitude": 8.8175,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5961,8.8175",
    "IsOpen24Hours": false,
    "id": "50.5961,8.8175"
  },
  {
    "ID": 323960468,
    "Tankstellennummer": "TD039321",
    "Name": "AUE LOESSNITZER STR.",
    "Address": "LOESSNITZER STR. 87",
    "Address__1": "",
    "City": "AUE",
    "Postcode": 8280,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03771-2765355",
    "24 hour?": "FALSE",
    "Latitude": 50.5965,
    "Longitude": 12.7072,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.5965,12.7072",
    "IsOpen24Hours": false,
    "id": "50.5965,12.7072"
  },
  {
    "ID": 945,
    "Tankstellennummer": "0F136",
    "Name": "Aral Autobahntankstelle Neustadt, Fernthal West (A 3)",
    "Address": "A3",
    "Address__1": "",
    "City": "Neustadt",
    "Postcode": 53577,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26833502,
    "24 hour?": "TRUE",
    "Latitude": 50.599924,
    "Longitude": 7.432348,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.599924,7.432348",
    "IsOpen24Hours": true,
    "id": "50.599924,7.432348"
  },
  {
    "ID": 331626631,
    "Tankstellennummer": "0FP83",
    "Name": "UNKEL",
    "Address": "Bundesstraße 42",
    "Address__1": "",
    "City": "Unkel",
    "Postcode": 53572,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22249879024,
    "24 hour?": "TRUE",
    "Latitude": 50.601943,
    "Longitude": 7.221923,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.601943,7.221923",
    "IsOpen24Hours": true,
    "id": "50.601943,7.221923"
  },
  {
    "ID": 776627403,
    "Tankstellennummer": "TD038190",
    "Name": "HEINSDORFERGRUND GEWERBERING 9",
    "Address": "GEWERBERING 9",
    "Address__1": "",
    "City": "HEINSDORFERGRUND",
    "Postcode": 8468,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03765/717373",
    "24 hour?": "FALSE",
    "Latitude": 50.6022,
    "Longitude": 12.3201,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6022,12.3201",
    "IsOpen24Hours": false,
    "id": "50.6022,12.3201"
  },
  {
    "ID": 894,
    "Tankstellennummer": "0F102",
    "Name": "Aral Tankstelle Rennerod, Hauptstr.9",
    "Address": "Hauptstraße 9",
    "Address__1": "",
    "City": "Rennerod",
    "Postcode": 56477,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2664999560,
    "24 hour?": "FALSE",
    "Latitude": 50.603163,
    "Longitude": 8.064023,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.603163,8.064023",
    "IsOpen24Hours": false,
    "id": "50.603163,8.064023"
  },
  {
    "ID": 1903000971,
    "Tankstellennummer": "TD024786",
    "Name": "SUHL-MAEBENDORF HAUPTSTR. 2A",
    "Address": "HAUPTSTR. 2A",
    "Address__1": "",
    "City": "SUHL-MAEBENDORF",
    "Postcode": 98529,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03681-722414",
    "24 hour?": "FALSE",
    "Latitude": 50.6044,
    "Longitude": 10.631,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6044,10.631",
    "IsOpen24Hours": false,
    "id": "50.6044,10.631"
  },
  {
    "ID": 1674,
    "Tankstellennummer": "0F680",
    "Name": "Aral Tankstelle Gießen, Marburger Str. 229",
    "Address": "Marburger Straße 229",
    "Address__1": "",
    "City": "Gießen",
    "Postcode": 35396,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6419502133,
    "24 hour?": "FALSE",
    "Latitude": 50.606453,
    "Longitude": 8.695725,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.606453,8.695725",
    "IsOpen24Hours": false,
    "id": "50.606453,8.695725"
  },
  {
    "ID": 1619794705,
    "Tankstellennummer": 4701,
    "Name": "Agip Ehringshausen",
    "Address": "Koelschhaeuser Strasse 25",
    "Address__1": "",
    "City": "Ehringshausen",
    "Postcode": 35630,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 64438112",
    "24 hour?": "FALSE",
    "Latitude": 50.60745713,
    "Longitude": 8.38310627,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.60745713,8.38310627",
    "IsOpen24Hours": false,
    "id": "50.60745713,8.38310627"
  },
  {
    "ID": 308019311,
    "Tankstellennummer": 610587,
    "Name": "WESTFALEN, SIMMERATH",
    "Address": "In den Bremen 48",
    "Address__1": "",
    "City": "Simmerath",
    "Postcode": 52152,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "TRUE",
    "Latitude": 50.6075,
    "Longitude": 6.31229,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.6075,6.31229",
    "IsOpen24Hours": true,
    "id": "50.6075,6.31229"
  },
  {
    "ID": 3324,
    "Tankstellennummer": "0FK79",
    "Name": "Aral Tankstelle Wettenberg, Am Augarten 1",
    "Address": "Am Augarten 1",
    "Address__1": "",
    "City": "Wettenberg",
    "Postcode": 35435,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6419805045,
    "24 hour?": "TRUE",
    "Latitude": 50.611391,
    "Longitude": 8.648171,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.611391,8.648171",
    "IsOpen24Hours": true,
    "id": "50.611391,8.648171"
  },
  {
    "ID": 1013810480,
    "Tankstellennummer": 610498,
    "Name": "WESTFALEN, WACHTBERG",
    "Address": "Töpferstr. 2",
    "Address__1": "",
    "City": "Wachtberg-Adendorf",
    "Postcode": 53343,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02225/703740",
    "24 hour?": "FALSE",
    "Latitude": 50.6129,
    "Longitude": 7.05351,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6129,7.05351",
    "IsOpen24Hours": false,
    "id": "50.6129,7.05351"
  },
  {
    "ID": 22184676,
    "Tankstellennummer": "0FM31",
    "Name": "MUCKE",
    "Address": "Giessener Straße 10",
    "Address__1": "",
    "City": "Mücke",
    "Postcode": 35325,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6400951223,
    "24 hour?": "FALSE",
    "Latitude": 50.613042,
    "Longitude": 9.027333,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.613042,9.027333",
    "IsOpen24Hours": false,
    "id": "50.613042,9.027333"
  },
  {
    "ID": 509158821,
    "Tankstellennummer": "0FM36",
    "Name": "KAULSDORF",
    "Address": "Zur Oschütz 1",
    "Address__1": "",
    "City": "Kaulsdorf",
    "Postcode": 7338,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3673332630,
    "24 hour?": "FALSE",
    "Latitude": 50.617237,
    "Longitude": 11.42804,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.617237,11.42804",
    "IsOpen24Hours": false,
    "id": "50.617237,11.42804"
  },
  {
    "ID": 2957,
    "Tankstellennummer": "0FI15",
    "Name": "Aral Tankstelle Mechernich, Im Wingert 43",
    "Address": "Wingert 43",
    "Address__1": "",
    "City": "Mechernich",
    "Postcode": 53894,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2443911405,
    "24 hour?": "FALSE",
    "Latitude": 50.618108,
    "Longitude": 6.651867,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.618108,6.651867",
    "IsOpen24Hours": false,
    "id": "50.618108,6.651867"
  },
  {
    "ID": 860852066,
    "Tankstellennummer": "0FM40",
    "Name": "DRIEDORF",
    "Address": "Limburger Straße 12",
    "Address__1": "",
    "City": "Driedorf",
    "Postcode": 35759,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27757224,
    "24 hour?": "FALSE",
    "Latitude": 50.61818,
    "Longitude": 8.192196,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.61818,8.192196",
    "IsOpen24Hours": false,
    "id": "50.61818,8.192196"
  },
  {
    "ID": 163210207,
    "Tankstellennummer": "0FP64",
    "Name": "Aral TankStelle Mechernich, Monzenbend 8",
    "Address": "Monzenbend 8",
    "Address__1": "",
    "City": "Mechernich",
    "Postcode": 53894,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2443310120,
    "24 hour?": "FALSE",
    "Latitude": 50.618682,
    "Longitude": 6.651707,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.618682,6.651707",
    "IsOpen24Hours": false,
    "id": "50.618682,6.651707"
  },
  {
    "ID": 2974,
    "Tankstellennummer": "0FI27",
    "Name": "Aral Tankstelle Höhn, Bundesstr. 255",
    "Address": "Am Großen Garten 7a / B 2",
    "Address__1": "",
    "City": "Höhn",
    "Postcode": 56462,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2661949598,
    "24 hour?": "FALSE",
    "Latitude": 50.619165,
    "Longitude": 7.990604,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.619165,7.990604",
    "IsOpen24Hours": false,
    "id": "50.619165,7.990604"
  },
  {
    "ID": 936,
    "Tankstellennummer": "0F132",
    "Name": "Aral Tankstelle Neustadt, Wiedtalstr.23",
    "Address": "Wiedtalstraße 24",
    "Address__1": "",
    "City": "Neustadt (Wied)",
    "Postcode": 53577,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 268331350,
    "24 hour?": "FALSE",
    "Latitude": 50.620807,
    "Longitude": 7.427844,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.620807,7.427844",
    "IsOpen24Hours": false,
    "id": "50.620807,7.427844"
  },
  {
    "ID": 1023197071,
    "Tankstellennummer": "0FY32",
    "Name": "GRUNBERG",
    "Address": "A 5",
    "Address__1": "",
    "City": "Grünberg",
    "Postcode": 35305,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 640191450,
    "24 hour?": "FALSE",
    "Latitude": 50.622389,
    "Longitude": 8.895367,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.622389,8.895367",
    "IsOpen24Hours": false,
    "id": "50.622389,8.895367"
  },
  {
    "ID": 954,
    "Tankstellennummer": "0F145",
    "Name": "Aral Autobahntankstelle Grünberg, Reinhardshain Nord (A 5)",
    "Address": "A5",
    "Address__1": "",
    "City": "Grünberg",
    "Postcode": 35305,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 640191370,
    "24 hour?": "TRUE",
    "Latitude": 50.623664,
    "Longitude": 8.895533,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.623664,8.895533",
    "IsOpen24Hours": true,
    "id": "50.623664,8.895533"
  },
  {
    "ID": 2046225373,
    "Tankstellennummer": 610377,
    "Name": "WESTFALEN, RHEINBACH",
    "Address": "Meckenheimer Str. 8",
    "Address__1": "",
    "City": "Rheinbach",
    "Postcode": 53359,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02226/915850",
    "24 hour?": "FALSE",
    "Latitude": 50.625,
    "Longitude": 6.96066,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.625,6.96066",
    "IsOpen24Hours": false,
    "id": "50.625,6.96066"
  },
  {
    "ID": 2959,
    "Tankstellennummer": "0FI16",
    "Name": "Aral Tankstelle Meckenheim, Bonner Str. 6",
    "Address": "Bonner Straße 6",
    "Address__1": "",
    "City": "Meckenheim",
    "Postcode": 53340,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22255013,
    "24 hour?": "FALSE",
    "Latitude": 50.626234,
    "Longitude": 7.020345,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.626234,7.020345",
    "IsOpen24Hours": false,
    "id": "50.626234,7.020345"
  },
  {
    "ID": 1277237319,
    "Tankstellennummer": "TD150860",
    "Name": "NISTERTAL BRUECKENSTR. 17",
    "Address": "BRUECKENSTR. 17",
    "Address__1": "",
    "City": "NISTERTAL",
    "Postcode": 57647,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02661-949086",
    "24 hour?": "FALSE",
    "Latitude": 50.6289,
    "Longitude": 7.8953,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6289,7.8953",
    "IsOpen24Hours": false,
    "id": "50.6289,7.8953"
  },
  {
    "ID": 2060,
    "Tankstellennummer": "0F938",
    "Name": "Aral Tankstelle Euskirchen, Geierstr.",
    "Address": "Geierstraße 55",
    "Address__1": "",
    "City": "Euskirchen",
    "Postcode": 53881,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22556284,
    "24 hour?": "FALSE",
    "Latitude": 50.629393,
    "Longitude": 6.845624,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.629393,6.845624",
    "IsOpen24Hours": false,
    "id": "50.629393,6.845624"
  },
  {
    "ID": 2547,
    "Tankstellennummer": "0FC88",
    "Name": "Aral Tankstelle Lößnitz, Chemnitzer Str. 8",
    "Address": "Chemnitzer Straße 8a",
    "Address__1": "",
    "City": "Lößnitz",
    "Postcode": 8294,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 377135357,
    "24 hour?": "FALSE",
    "Latitude": 50.631517,
    "Longitude": 12.736795,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.631517,12.736795",
    "IsOpen24Hours": false,
    "id": "50.631517,12.736795"
  },
  {
    "ID": 2978,
    "Tankstellennummer": "0FI30",
    "Name": "Aral Tankstelle Höchstenbach, Koblenzer Str. 3",
    "Address": "Koblenzer Straße 3",
    "Address__1": "",
    "City": "Höchstenbach",
    "Postcode": 57629,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2680442,
    "24 hour?": "FALSE",
    "Latitude": 50.633737,
    "Longitude": 7.74607,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.633737,7.74607",
    "IsOpen24Hours": false,
    "id": "50.633737,7.74607"
  },
  {
    "ID": 943845093,
    "Tankstellennummer": "TD025502",
    "Name": "ZWOENITZ HARTENSTEINER STR. 11",
    "Address": "HARTENSTEINER STR. 11",
    "Address__1": "",
    "City": "ZWOENITZ",
    "Postcode": 8297,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037754-5536",
    "24 hour?": "FALSE",
    "Latitude": 50.6344,
    "Longitude": 12.794,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6344,12.794",
    "IsOpen24Hours": false,
    "id": "50.6344,12.794"
  },
  {
    "ID": 2945,
    "Tankstellennummer": "0FI05",
    "Name": "Aral Tankstelle Heimbach, Am Bahnhof 1",
    "Address": "Hengebachstr. 1",
    "Address__1": "",
    "City": "Heimbach",
    "Postcode": 52396,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2446444,
    "24 hour?": "FALSE",
    "Latitude": 50.635387,
    "Longitude": 6.474189,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.635387,6.474189",
    "IsOpen24Hours": false,
    "id": "50.635387,6.474189"
  },
  {
    "ID": 2640,
    "Tankstellennummer": "0FD64",
    "Name": "Aral Tankstelle Reichenbach, Zwickauer Str. 240",
    "Address": "Zwickauer Straße 240",
    "Address__1": "",
    "City": "Reichenbach",
    "Postcode": 8468,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 376512016,
    "24 hour?": "FALSE",
    "Latitude": 50.636226,
    "Longitude": 12.323708,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.636226,12.323708",
    "IsOpen24Hours": false,
    "id": "50.636226,12.323708"
  },
  {
    "ID": 2544,
    "Tankstellennummer": "0FC85",
    "Name": "Aral Tankstelle Suhl, Gothaer Str./An der B 247",
    "Address": "Gothaer Straße 158",
    "Address__1": "",
    "City": "Suhl",
    "Postcode": 98528,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3681461595,
    "24 hour?": "TRUE",
    "Latitude": 50.636489,
    "Longitude": 10.697485,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.636489,10.697485",
    "IsOpen24Hours": true,
    "id": "50.636489,10.697485"
  },
  {
    "ID": 2071,
    "Tankstellennummer": "0F947",
    "Name": "Aral Tankstelle Bad Honnef, Rottbitzer Str 32",
    "Address": "Rottbitzer Straße 32",
    "Address__1": "",
    "City": "Bad Honnef",
    "Postcode": 53604,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 222480045,
    "24 hour?": "FALSE",
    "Latitude": 50.646697,
    "Longitude": 7.314059,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.646697,7.314059",
    "IsOpen24Hours": false,
    "id": "50.646697,7.314059"
  },
  {
    "ID": 825408586,
    "Tankstellennummer": "TD000729",
    "Name": "ROETGEN BUNDESSTR.HAUS NR. 7",
    "Address": "BUNDESSTR.HAUS NR. 7",
    "Address__1": "",
    "City": "ROETGEN",
    "Postcode": 52159,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02471-2510",
    "24 hour?": "FALSE",
    "Latitude": 50.6469,
    "Longitude": 6.1853,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6469,6.1853",
    "IsOpen24Hours": false,
    "id": "50.6469,6.1853"
  },
  {
    "ID": 756895610,
    "Tankstellennummer": 5051,
    "Name": "Agip Greiz",
    "Address": "Plauensche Str. 2",
    "Address__1": "",
    "City": "Greiz",
    "Postcode": 7973,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 36616352",
    "24 hour?": "TRUE",
    "Latitude": 50.64720623,
    "Longitude": 12.19469413,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.64720623,12.19469413",
    "IsOpen24Hours": true,
    "id": "50.64720623,12.19469413"
  },
  {
    "ID": 1586294792,
    "Tankstellennummer": "0FP88",
    "Name": "Aral TankStelle Bad Honnef, Gewerbegebiet Am Dachsberg",
    "Address": "Gewerbegebiet Am Dachsberg 8",
    "Address__1": "",
    "City": "Bad Honnef",
    "Postcode": 53604,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22249897088,
    "24 hour?": "TRUE",
    "Latitude": 50.647984,
    "Longitude": 7.334606,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.647984,7.334606",
    "IsOpen24Hours": true,
    "id": "50.647984,7.334606"
  },
  {
    "ID": 1590151220,
    "Tankstellennummer": "0FO83",
    "Name": "BAD MARIENBERG",
    "Address": "Langenbacher Straße 18",
    "Address__1": "",
    "City": "Bad Marienberg",
    "Postcode": 56470,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2661931012,
    "24 hour?": "FALSE",
    "Latitude": 50.648029,
    "Longitude": 7.953069,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.648029,7.953069",
    "IsOpen24Hours": false,
    "id": "50.648029,7.953069"
  },
  {
    "ID": 1093897573,
    "Tankstellennummer": "0FO85",
    "Name": "FLAMMERSFELD",
    "Address": "Rheinstraße 50",
    "Address__1": "",
    "City": "Flammersfeld",
    "Postcode": 57632,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 26851508,
    "24 hour?": "FALSE",
    "Latitude": 50.648258,
    "Longitude": 7.527139,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.648258,7.527139",
    "IsOpen24Hours": false,
    "id": "50.648258,7.527139"
  },
  {
    "ID": 586173641,
    "Tankstellennummer": 5115,
    "Name": "Agip Saalfeld",
    "Address": "Kulmbacher Str. 71",
    "Address__1": "",
    "City": "Saalfeld",
    "Postcode": 7318,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 36715205",
    "24 hour?": "TRUE",
    "Latitude": 50.64889306,
    "Longitude": 11.37350954,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.64889306,11.37350954",
    "IsOpen24Hours": true,
    "id": "50.64889306,11.37350954"
  },
  {
    "ID": 581279929,
    "Tankstellennummer": "TD034447",
    "Name": "MARIENBERG AEUSS.WOLKENSTEINER",
    "Address": "AEUSS.WOLKENSTEINER STR. 3",
    "Address__1": "",
    "City": "MARIENBERG",
    "Postcode": 9496,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03735-22485",
    "24 hour?": "FALSE",
    "Latitude": 50.65,
    "Longitude": 13.1564,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.65,13.1564",
    "IsOpen24Hours": false,
    "id": "50.65,13.1564"
  },
  {
    "ID": 756813618,
    "Tankstellennummer": 610367,
    "Name": "WESTFALEN, EUSKIRCHEN",
    "Address": "Roitzheimer Str. 117",
    "Address__1": "",
    "City": "Euskirchen",
    "Postcode": 53879,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02251/861724",
    "24 hour?": "FALSE",
    "Latitude": 50.6527,
    "Longitude": 6.80004,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6527,6.80004",
    "IsOpen24Hours": false,
    "id": "50.6527,6.80004"
  },
  {
    "ID": 766398823,
    "Tankstellennummer": "TD037671",
    "Name": "ZELLA-MEHLIS TALSTR. 60",
    "Address": "TALSTR. 60",
    "Address__1": "",
    "City": "ZELLA-MEHLIS",
    "Postcode": 98544,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03682-486011",
    "24 hour?": "FALSE",
    "Latitude": 50.6542,
    "Longitude": 10.6605,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6542,10.6605",
    "IsOpen24Hours": false,
    "id": "50.6542,10.6605"
  },
  {
    "ID": 1846422088,
    "Tankstellennummer": "0FY48",
    "Name": "PROJEKT",
    "Address": "Am Räschen 4",
    "Address__1": "",
    "City": "Wasungen",
    "Postcode": 98634,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 50.654413,
    "Longitude": 10.377674,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.654413,10.377674",
    "IsOpen24Hours": false,
    "id": "50.654413,10.377674"
  },
  {
    "ID": 3132192,
    "Tankstellennummer": 4704,
    "Name": "Agip Staufenberg",
    "Address": "Siemensstrasse 1",
    "Address__1": "",
    "City": "Staufenberg",
    "Postcode": 35460,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 64037785",
    "24 hour?": "TRUE",
    "Latitude": 50.65500224,
    "Longitude": 8.7327106,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.65500224,8.7327106",
    "IsOpen24Hours": true,
    "id": "50.65500224,8.7327106"
  },
  {
    "ID": 1056365815,
    "Tankstellennummer": "TD023135",
    "Name": "GREIZ ZEULENRODAER STR. 31",
    "Address": "ZEULENRODAER STR. 31",
    "Address__1": "",
    "City": "GREIZ",
    "Postcode": 7973,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03661-2591",
    "24 hour?": "FALSE",
    "Latitude": 50.6555,
    "Longitude": 12.1757,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6555,12.1757",
    "IsOpen24Hours": false,
    "id": "50.6555,12.1757"
  },
  {
    "ID": 2766,
    "Tankstellennummer": "0FE58",
    "Name": "Aral Tankstelle Zeulenroda, Schopperstr. 83",
    "Address": "Schopperstraße 83",
    "Address__1": "",
    "City": "Zeulenroda",
    "Postcode": 7937,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3662882710,
    "24 hour?": "FALSE",
    "Latitude": 50.655651,
    "Longitude": 11.993255,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.655651,11.993255",
    "IsOpen24Hours": false,
    "id": "50.655651,11.993255"
  },
  {
    "ID": 832461926,
    "Tankstellennummer": "TD123070",
    "Name": "EUSKIRCHEN KOMMERNER STR. 147",
    "Address": "KOMMERNER STR. 147",
    "Address__1": "",
    "City": "EUSKIRCHEN",
    "Postcode": 53879,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02251-929103",
    "24 hour?": "FALSE",
    "Latitude": 50.6566,
    "Longitude": 6.7681,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6566,6.7681",
    "IsOpen24Hours": false,
    "id": "50.6566,6.7681"
  },
  {
    "ID": 949210716,
    "Tankstellennummer": "TD024109",
    "Name": "SAALFELD RUDOLSTAEDTER STR. 13",
    "Address": "RUDOLSTAEDTER STR. 13",
    "Address__1": "",
    "City": "SAALFELD",
    "Postcode": 7318,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03671-2264",
    "24 hour?": "FALSE",
    "Latitude": 50.6574,
    "Longitude": 11.3474,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6574,11.3474",
    "IsOpen24Hours": false,
    "id": "50.6574,11.3474"
  },
  {
    "ID": 946119845,
    "Tankstellennummer": "TD026294",
    "Name": "OLBERNHAU BLUMENAUER STR.9",
    "Address": "BLUMENAUER STR.9",
    "Address__1": "",
    "City": "OLBERNHAU",
    "Postcode": 9526,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037360-74280",
    "24 hour?": "FALSE",
    "Latitude": 50.6616,
    "Longitude": 13.3303,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6616,13.3303",
    "IsOpen24Hours": false,
    "id": "50.6616,13.3303"
  },
  {
    "ID": 2942,
    "Tankstellennummer": "0FI02",
    "Name": "Aral Tankstelle Euskirchen, Koelner Str. 177-181",
    "Address": "Kölner Straße 177",
    "Address__1": "",
    "City": "Euskirchen",
    "Postcode": 53879,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22512190,
    "24 hour?": "TRUE",
    "Latitude": 50.66227,
    "Longitude": 6.805835,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.66227,6.805835",
    "IsOpen24Hours": true,
    "id": "50.66227,6.805835"
  },
  {
    "ID": 927,
    "Tankstellennummer": "0F124",
    "Name": "Aral Tankstelle Hof, An Der Bundesstr. 414",
    "Address": "Talstraße 12 / B414",
    "Address__1": "",
    "City": "Hof",
    "Postcode": 56472,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 266164375,
    "24 hour?": "FALSE",
    "Latitude": 50.664441,
    "Longitude": 8.015791,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.664441,8.015791",
    "IsOpen24Hours": false,
    "id": "50.664441,8.015791"
  },
  {
    "ID": 825109570,
    "Tankstellennummer": "0FO88",
    "Name": "HACHENBURG",
    "Address": "Saynstraße 44",
    "Address__1": "",
    "City": "Hachenburg",
    "Postcode": 57627,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2662939200,
    "24 hour?": "FALSE",
    "Latitude": 50.667175,
    "Longitude": 7.808867,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.667175,7.808867",
    "IsOpen24Hours": false,
    "id": "50.667175,7.808867"
  },
  {
    "ID": 1683,
    "Tankstellennummer": "0F687",
    "Name": "Aral Tankstelle Hünfeld, Fuldaer Str. 27",
    "Address": "Fuldaer Straße 27",
    "Address__1": "",
    "City": "Hünfeld",
    "Postcode": 36088,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 66522705,
    "24 hour?": "FALSE",
    "Latitude": 50.667382,
    "Longitude": 9.762146,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.667382,9.762146",
    "IsOpen24Hours": false,
    "id": "50.667382,9.762146"
  },
  {
    "ID": 1226345918,
    "Tankstellennummer": 4703,
    "Name": "Agip Muecke/Bernsfeld",
    "Address": "Homberger Str. 24",
    "Address__1": "",
    "City": "Muecke/Bernsfeld",
    "Postcode": 35325,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 66348856",
    "24 hour?": "FALSE",
    "Latitude": 50.66766,
    "Longitude": 8.99412,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.66766,8.99412",
    "IsOpen24Hours": false,
    "id": "50.66766,8.99412"
  },
  {
    "ID": 1359,
    "Tankstellennummer": "0F441",
    "Name": "Aral Tankstelle Euskirchen, Frauenberger Str. 168",
    "Address": "Frauenberger Straße 168",
    "Address__1": "",
    "City": "Euskirchen",
    "Postcode": 53879,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2251782560,
    "24 hour?": "FALSE",
    "Latitude": 50.67013,
    "Longitude": 6.772548,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.67013,6.772548",
    "IsOpen24Hours": false,
    "id": "50.67013,6.772548"
  },
  {
    "ID": 1580080614,
    "Tankstellennummer": "0FN73",
    "Name": "WILKAU-HAßLAU",
    "Address": "Schneeberger Straße 83 a",
    "Address__1": "",
    "City": "Wilkau-Haßlau",
    "Postcode": 8112,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 375661265,
    "24 hour?": "FALSE",
    "Latitude": 50.673066,
    "Longitude": 12.528272,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.673066,12.528272",
    "IsOpen24Hours": false,
    "id": "50.673066,12.528272"
  },
  {
    "ID": 1658842743,
    "Tankstellennummer": "TD000028",
    "Name": "BONN-BAD GODESBERG KOBLENZER S",
    "Address": "KOBLENZER STR. 195",
    "Address__1": "",
    "City": "BONN-BAD GODESBERG",
    "Postcode": 53177,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0228-332515",
    "24 hour?": "FALSE",
    "Latitude": 50.6764,
    "Longitude": 7.1705,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6764,7.1705",
    "IsOpen24Hours": false,
    "id": "50.6764,7.1705"
  },
  {
    "ID": 1347,
    "Tankstellennummer": "0F432",
    "Name": "Aral Tankstelle Bonn, Koblenzer Str. 171",
    "Address": "Koblenzer Straße 171",
    "Address__1": "",
    "City": "Bonn",
    "Postcode": 53177,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 228333097,
    "24 hour?": "FALSE",
    "Latitude": 50.677611,
    "Longitude": 7.1685,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.677611,7.1685",
    "IsOpen24Hours": false,
    "id": "50.677611,7.1685"
  },
  {
    "ID": 1679,
    "Tankstellennummer": "0F684",
    "Name": "Aral Tankstelle Herborn, Westerwaldstrasse 26",
    "Address": "Westerwaldstraße 26",
    "Address__1": "",
    "City": "Herborn",
    "Postcode": 35745,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 277282520,
    "24 hour?": "TRUE",
    "Latitude": 50.678465,
    "Longitude": 8.30135,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.678465,8.30135",
    "IsOpen24Hours": true,
    "id": "50.678465,8.30135"
  },
  {
    "ID": 2125157263,
    "Tankstellennummer": "TD033266",
    "Name": "ILMENAU GRENZHAMMER 4",
    "Address": "GRENZHAMMER 4",
    "Address__1": "",
    "City": "ILMENAU",
    "Postcode": 98693,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03677-842787",
    "24 hour?": "FALSE",
    "Latitude": 50.6787,
    "Longitude": 10.936,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6787,10.936",
    "IsOpen24Hours": false,
    "id": "50.6787,10.936"
  },
  {
    "ID": 1305481329,
    "Tankstellennummer": "TD038687",
    "Name": "WILDENFELS ARNO-SCHMIDT-STR. 2",
    "Address": "ARNO-SCHMIDT-STR. 28 A",
    "Address__1": "",
    "City": "WILDENFELS",
    "Postcode": 8134,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037603-550711",
    "24 hour?": "FALSE",
    "Latitude": 50.6806,
    "Longitude": 12.5927,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6806,12.5927",
    "IsOpen24Hours": false,
    "id": "50.6806,12.5927"
  },
  {
    "ID": 2076,
    "Tankstellennummer": "0F951",
    "Name": "Aral Tankstelle Königswinter, Königswinterer Str.273",
    "Address": "Königswinterer Straße 273",
    "Address__1": "",
    "City": "Königswinter",
    "Postcode": 53639,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2223900971,
    "24 hour?": "FALSE",
    "Latitude": 50.680975,
    "Longitude": 7.272625,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.680975,7.272625",
    "IsOpen24Hours": false,
    "id": "50.680975,7.272625"
  },
  {
    "ID": 399007918,
    "Tankstellennummer": 610499,
    "Name": "WESTFALEN, BONN",
    "Address": "Reichsstr. 3-5",
    "Address__1": "",
    "City": "Bonn",
    "Postcode": 53125,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0228/96691376",
    "24 hour?": "FALSE",
    "Latitude": 50.6832,
    "Longitude": 7.07568,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6832,7.07568",
    "IsOpen24Hours": false,
    "id": "50.6832,7.07568"
  },
  {
    "ID": 1835261333,
    "Tankstellennummer": "0FO82",
    "Name": "ALTENKIRCHEN",
    "Address": "Kölner Straße 35",
    "Address__1": "",
    "City": "Altenkirchen",
    "Postcode": 57610,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2681987630,
    "24 hour?": "TRUE",
    "Latitude": 50.686808,
    "Longitude": 7.634016,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.686808,7.634016",
    "IsOpen24Hours": true,
    "id": "50.686808,7.634016"
  },
  {
    "ID": 527006392,
    "Tankstellennummer": "TD150150",
    "Name": "BUCHHOLZ HAUPTSTR. 64",
    "Address": "HAUPTSTR. 64",
    "Address__1": "",
    "City": "BUCHHOLZ",
    "Postcode": 53567,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02683-7206",
    "24 hour?": "FALSE",
    "Latitude": 50.6881,
    "Longitude": 7.3948,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6881,7.3948",
    "IsOpen24Hours": false,
    "id": "50.6881,7.3948"
  },
  {
    "ID": 583014591,
    "Tankstellennummer": 5041,
    "Name": "Agip Ilmenau",
    "Address": "Buecheloher Str. B 87",
    "Address__1": "",
    "City": "Ilmenau",
    "Postcode": 98693,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 36772049",
    "24 hour?": "TRUE",
    "Latitude": 50.69034343,
    "Longitude": 10.94027344,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.69034343,10.94027344",
    "IsOpen24Hours": true,
    "id": "50.69034343,10.94027344"
  },
  {
    "ID": 1028968861,
    "Tankstellennummer": "TD023275",
    "Name": "ILMENAU ERFURTER STR. 60",
    "Address": "ERFURTER STR. 60",
    "Address__1": "",
    "City": "ILMENAU",
    "Postcode": 98693,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03677-840890",
    "24 hour?": "FALSE",
    "Latitude": 50.6913,
    "Longitude": 10.905,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6913,10.905",
    "IsOpen24Hours": false,
    "id": "50.6913,10.905"
  },
  {
    "ID": 2150,
    "Tankstellennummer": "0FA09",
    "Name": "Aral Tankstelle Bonn, Friesdorfer Str. 195",
    "Address": "Friesdorfer Straße 195",
    "Address__1": "",
    "City": "Bonn",
    "Postcode": 53175,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 228310151,
    "24 hour?": "FALSE",
    "Latitude": 50.691947,
    "Longitude": 7.136983,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.691947,7.136983",
    "IsOpen24Hours": false,
    "id": "50.691947,7.136983"
  },
  {
    "ID": 2962,
    "Tankstellennummer": "0FI18",
    "Name": "Aral Tankstelle Nideggen, Im Altwerk 27",
    "Address": "Im Altwerk 27",
    "Address__1": "",
    "City": "Nideggen",
    "Postcode": 52385,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24271309,
    "24 hour?": "FALSE",
    "Latitude": 50.692228,
    "Longitude": 6.483995,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.692228,6.483995",
    "IsOpen24Hours": false,
    "id": "50.692228,6.483995"
  },
  {
    "ID": 1637,
    "Tankstellennummer": "0F661",
    "Name": "Aral Tankstelle Herborn-Burg, Burger Hauptstr. 10-12",
    "Address": "Burger Hauptstraße 10-12",
    "Address__1": "",
    "City": "Herborn-Burg",
    "Postcode": 35745,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27722675,
    "24 hour?": "FALSE",
    "Latitude": 50.693525,
    "Longitude": 8.302755,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.693525,8.302755",
    "IsOpen24Hours": false,
    "id": "50.693525,8.302755"
  },
  {
    "ID": 291632368,
    "Tankstellennummer": "TD006908",
    "Name": "BONN UBIERSTR. 8",
    "Address": "UBIERSTR. 8",
    "Address__1": "",
    "City": "BONN",
    "Postcode": 53173,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0228-363298",
    "24 hour?": "FALSE",
    "Latitude": 50.6945,
    "Longitude": 7.1618,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6945,7.1618",
    "IsOpen24Hours": false,
    "id": "50.6945,7.1618"
  },
  {
    "ID": 744272251,
    "Tankstellennummer": "TD026500",
    "Name": "ZWICKAU INN.ZWICKAUER STR.29",
    "Address": "INN.ZWICKAUER STR.29",
    "Address__1": "",
    "City": "ZWICKAU",
    "Postcode": 8062,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0375-787047",
    "24 hour?": "FALSE",
    "Latitude": 50.6947,
    "Longitude": 12.4792,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6947,12.4792",
    "IsOpen24Hours": false,
    "id": "50.6947,12.4792"
  },
  {
    "ID": 936607565,
    "Tankstellennummer": 4203,
    "Name": "Agip Bonn",
    "Address": "Godesberger Allee 63-65",
    "Address__1": "",
    "City": "Bonn",
    "Postcode": 53175,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 22837403",
    "24 hour?": "TRUE",
    "Latitude": 50.69702,
    "Longitude": 7.14142,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.69702,7.14142",
    "IsOpen24Hours": true,
    "id": "50.69702,7.14142"
  },
  {
    "ID": 1366306429,
    "Tankstellennummer": "TD036954",
    "Name": "MITTENAAR AN DER B 255",
    "Address": "AN DER B 255",
    "Address__1": "",
    "City": "MITTENAAR",
    "Postcode": 35756,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02778-913110",
    "24 hour?": "FALSE",
    "Latitude": 50.6983,
    "Longitude": 8.4089,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6983,8.4089",
    "IsOpen24Hours": false,
    "id": "50.6983,8.4089"
  },
  {
    "ID": 481891481,
    "Tankstellennummer": "0FM50",
    "Name": "KÖNIGSWINTER",
    "Address": "Königswinterer Straße 106",
    "Address__1": "",
    "City": "Königswinter",
    "Postcode": 53639,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2244876519,
    "24 hour?": "TRUE",
    "Latitude": 50.698908,
    "Longitude": 7.277707,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.698908,7.277707",
    "IsOpen24Hours": true,
    "id": "50.698908,7.277707"
  },
  {
    "ID": 581018785,
    "Tankstellennummer": "TD034454",
    "Name": "GELENAU FRITZ-REUTER-STRASSE 2",
    "Address": "FRITZ-REUTER-STRASSE 21",
    "Address__1": "",
    "City": "GELENAU",
    "Postcode": 9423,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037297-5055",
    "24 hour?": "FALSE",
    "Latitude": 50.6996,
    "Longitude": 12.9397,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6996,12.9397",
    "IsOpen24Hours": false,
    "id": "50.6996,12.9397"
  },
  {
    "ID": 1460836746,
    "Tankstellennummer": "TD041335",
    "Name": "STOLLBERG BAHNHOFSTR. 30",
    "Address": "BAHNHOFSTR. 30",
    "Address__1": "",
    "City": "STOLLBERG",
    "Postcode": 9366,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 37296884265,
    "24 hour?": "FALSE",
    "Latitude": 50.6997,
    "Longitude": 12.7669,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.6997,12.7669",
    "IsOpen24Hours": false,
    "id": "50.6997,12.7669"
  },
  {
    "ID": 1673,
    "Tankstellennummer": "0F679",
    "Name": "Aral Tankstelle Herborn, Marburger Str.15",
    "Address": "Marburger Str. 15",
    "Address__1": "",
    "City": "Herborn",
    "Postcode": 35745,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 277261240,
    "24 hour?": "FALSE",
    "Latitude": 50.700498,
    "Longitude": 8.337595,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.700498,8.337595",
    "IsOpen24Hours": false,
    "id": "50.700498,8.337595"
  },
  {
    "ID": 596627535,
    "Tankstellennummer": "TD023069",
    "Name": "POESSNECK NEUSTAEDTER STR. 162",
    "Address": "NEUSTAEDTER STR. 162",
    "Address__1": "",
    "City": "POESSNECK",
    "Postcode": 7381,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03647-425370",
    "24 hour?": "FALSE",
    "Latitude": 50.701,
    "Longitude": 11.6142,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.701,11.6142",
    "IsOpen24Hours": false,
    "id": "50.701,11.6142"
  },
  {
    "ID": 2944,
    "Tankstellennummer": "0FI04",
    "Name": "Aral Tankstelle Bonn, Godesberger Allee 131",
    "Address": "Godesberger Allee 131",
    "Address__1": "",
    "City": "Bonn",
    "Postcode": 53175,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 228376016,
    "24 hour?": "TRUE",
    "Latitude": 50.701407,
    "Longitude": 7.136586,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.701407,7.136586",
    "IsOpen24Hours": true,
    "id": "50.701407,7.136586"
  },
  {
    "ID": 2709,
    "Tankstellennummer": "0FE23",
    "Name": "Aral Tankstelle Zwickau, Wildenfelser Str. 15",
    "Address": "Wildenfelser Straße 15",
    "Address__1": "",
    "City": "Zwickau",
    "Postcode": 8056,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 375287020,
    "24 hour?": "FALSE",
    "Latitude": 50.702093,
    "Longitude": 12.508305,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.702093,12.508305",
    "IsOpen24Hours": false,
    "id": "50.702093,12.508305"
  },
  {
    "ID": 914206218,
    "Tankstellennummer": "0FQ47",
    "Name": "KÖNIGSWINTER",
    "Address": "Im Mühlenbruch 2",
    "Address__1": "",
    "City": "Königswinter",
    "Postcode": 53639,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22237009181,
    "24 hour?": "TRUE",
    "Latitude": 50.70256,
    "Longitude": 7.177107,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.70256,7.177107",
    "IsOpen24Hours": true,
    "id": "50.70256,7.177107"
  },
  {
    "ID": 56360858,
    "Tankstellennummer": "TD000079",
    "Name": "BONN-DOTTENDORF KESSENICHER ST",
    "Address": "KESSENICHER STR. 212",
    "Address__1": "",
    "City": "BONN-DOTTENDORF",
    "Postcode": 53129,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0228-231116",
    "24 hour?": "FALSE",
    "Latitude": 50.7028,
    "Longitude": 7.1161,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7028,7.1161",
    "IsOpen24Hours": false,
    "id": "50.7028,7.1161"
  },
  {
    "ID": 17760,
    "Tankstellennummer": "0FP24",
    "Name": "Aral Tankstelle Zülpich, Römerallee 78",
    "Address": "Römerallee 78",
    "Address__1": "",
    "City": "Zülpich",
    "Postcode": 53909,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22528306140,
    "24 hour?": "TRUE",
    "Latitude": 50.703644,
    "Longitude": 6.666699,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.703644,6.666699",
    "IsOpen24Hours": true,
    "id": "50.703644,6.666699"
  },
  {
    "ID": 2677,
    "Tankstellennummer": "0FD98",
    "Name": "Aral Tankstelle Zwickau, Planitzer Str. 3-5",
    "Address": "Planitzer Straße 3-5",
    "Address__1": "",
    "City": "Zwickau",
    "Postcode": 8056,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 375282580,
    "24 hour?": "TRUE",
    "Latitude": 50.704439,
    "Longitude": 12.493827,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.704439,12.493827",
    "IsOpen24Hours": true,
    "id": "50.704439,12.493827"
  },
  {
    "ID": 1032050175,
    "Tankstellennummer": "TD023234",
    "Name": "OBERHOF POSTSTR. 12",
    "Address": "POSTSTR. 12",
    "Address__1": "",
    "City": "OBERHOF",
    "Postcode": 98559,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036842-22598",
    "24 hour?": "FALSE",
    "Latitude": 50.705,
    "Longitude": 10.7319,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.705,10.7319",
    "IsOpen24Hours": false,
    "id": "50.705,10.7319"
  },
  {
    "ID": 2051,
    "Tankstellennummer": "0F932",
    "Name": "Aral Tankstelle Aachen, Schleidener Str. 152",
    "Address": "Schleidener Straße 152",
    "Address__1": "",
    "City": "Aachen",
    "Postcode": 52076,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24081460290,
    "24 hour?": "FALSE",
    "Latitude": 50.706352,
    "Longitude": 6.181828,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.706352,6.181828",
    "IsOpen24Hours": false,
    "id": "50.706352,6.181828"
  },
  {
    "ID": 2092585347,
    "Tankstellennummer": "TD025452",
    "Name": "ZWICKAU REICHENBACHER STR. 115",
    "Address": "REICHENBACHER STR. 115",
    "Address__1": "",
    "City": "ZWICKAU",
    "Postcode": 8056,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0375-216851",
    "24 hour?": "FALSE",
    "Latitude": 50.7084,
    "Longitude": 12.4648,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7084,12.4648",
    "IsOpen24Hours": false,
    "id": "50.7084,12.4648"
  },
  {
    "ID": 2107478759,
    "Tankstellennummer": "TD026807",
    "Name": "OELSNITZ/ERZGEB.. OBERWUERSCHN",
    "Address": "OBERWUERSCHNITZER STR. 8/10",
    "Address__1": "",
    "City": "OELSNITZ/ERZGEB..",
    "Postcode": 9376,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037296-93472",
    "24 hour?": "FALSE",
    "Latitude": 50.7087,
    "Longitude": 12.7351,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7087,12.7351",
    "IsOpen24Hours": false,
    "id": "50.7087,12.7351"
  },
  {
    "ID": 1117777028,
    "Tankstellennummer": "TD026302",
    "Name": "POCKAU-LENGEFELD FLOEHATALSTR.",
    "Address": "FLOEHATALSTR. 2A",
    "Address__1": "",
    "City": "POCKAU-LENGEFELD",
    "Postcode": 9509,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037367-9659",
    "24 hour?": "FALSE",
    "Latitude": 50.7087,
    "Longitude": 13.2198,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7087,13.2198",
    "IsOpen24Hours": false,
    "id": "50.7087,13.2198"
  },
  {
    "ID": 1009115789,
    "Tankstellennummer": "TD040634",
    "Name": "BONN JULIUS-LEBER-STR. 2C",
    "Address": "JULIUS-LEBER-STR. 2c",
    "Address__1": "",
    "City": "BONN",
    "Postcode": 53123,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0228-96285704",
    "24 hour?": "FALSE",
    "Latitude": 50.7093,
    "Longitude": 7.0546,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7093,7.0546",
    "IsOpen24Hours": false,
    "id": "50.7093,7.0546"
  },
  {
    "ID": 2649,
    "Tankstellennummer": "0FD72",
    "Name": "Aral Tankstelle Thalheim, B 180",
    "Address": "Chemnitzer Straße 47 d",
    "Address__1": "",
    "City": "Thalheim",
    "Postcode": 9380,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 372138992,
    "24 hour?": "FALSE",
    "Latitude": 50.710053,
    "Longitude": 12.865768,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.710053,12.865768",
    "IsOpen24Hours": false,
    "id": "50.710053,12.865768"
  },
  {
    "ID": 2004795463,
    "Tankstellennummer": "TD037440",
    "Name": "BONN KONRAD-ADENAUER-DAMM 200",
    "Address": "KONRAD-ADENAUER-DAMM 200",
    "Address__1": "",
    "City": "BONN",
    "Postcode": 53123,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0228-644806",
    "24 hour?": "FALSE",
    "Latitude": 50.7122,
    "Longitude": 7.0406,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7122,7.0406",
    "IsOpen24Hours": false,
    "id": "50.7122,7.0406"
  },
  {
    "ID": 707805786,
    "Tankstellennummer": "0FY28",
    "Name": "BAT GROßENMOOR WEST",
    "Address": "A7",
    "Address__1": "",
    "City": "Burghaun",
    "Postcode": 36151,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 665396010,
    "24 hour?": "TRUE",
    "Latitude": 50.712895,
    "Longitude": 9.662873,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.712895,9.662873",
    "IsOpen24Hours": true,
    "id": "50.712895,9.662873"
  },
  {
    "ID": 574571611,
    "Tankstellennummer": "0FQ05",
    "Name": "BURGHAUN",
    "Address": "A7 - Ost",
    "Address__1": "",
    "City": "Burghaun",
    "Postcode": 36151,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6653292,
    "24 hour?": "TRUE",
    "Latitude": 50.712969,
    "Longitude": 9.662551,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.712969,9.662551",
    "IsOpen24Hours": true,
    "id": "50.712969,9.662551"
  },
  {
    "ID": 2099,
    "Tankstellennummer": "0F973",
    "Name": "Aral Tankstelle Bonn, Im Wingert 20",
    "Address": "Im Wingert 20",
    "Address__1": "",
    "City": "Bonn",
    "Postcode": 53115,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 228212348,
    "24 hour?": "FALSE",
    "Latitude": 50.718098,
    "Longitude": 7.091064,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.718098,7.091064",
    "IsOpen24Hours": false,
    "id": "50.718098,7.091064"
  },
  {
    "ID": 2535,
    "Tankstellennummer": "0FC74",
    "Name": "Aral Tankstelle Zwickau, Äußere Dresdner Str.",
    "Address": "Äußere Dresdner Straße 23A",
    "Address__1": "",
    "City": "Zwickau",
    "Postcode": 8066,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 375477462,
    "24 hour?": "FALSE",
    "Latitude": 50.718671,
    "Longitude": 12.515612,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.718671,12.515612",
    "IsOpen24Hours": false,
    "id": "50.718671,12.515612"
  },
  {
    "ID": 2940,
    "Tankstellennummer": "0FH99",
    "Name": "Aral Tankstelle Bonn, Rochusstr. 46",
    "Address": "Rochusstraße 46",
    "Address__1": "",
    "City": "Bonn",
    "Postcode": 53123,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 228624902,
    "24 hour?": "TRUE",
    "Latitude": 50.719192,
    "Longitude": 7.05678,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.719192,7.05678",
    "IsOpen24Hours": true,
    "id": "50.719192,7.05678"
  },
  {
    "ID": 1288007801,
    "Tankstellennummer": "0FP65",
    "Name": "Aral TankStelle Schmalkalden, Asbacher Straße 17 b",
    "Address": "Asbacher Straße 17 b",
    "Address__1": "",
    "City": "Schmalkalden",
    "Postcode": 98574,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 36834650680,
    "24 hour?": "FALSE",
    "Latitude": 50.719344,
    "Longitude": 10.473489,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.719344,10.473489",
    "IsOpen24Hours": false,
    "id": "50.719344,10.473489"
  },
  {
    "ID": 3394,
    "Tankstellennummer": "0FL34",
    "Name": "Aral Tankstelle Zwickau, Marienthaler Str. 2",
    "Address": "Marienthaler Straße 2",
    "Address__1": "",
    "City": "Zwickau",
    "Postcode": 8060,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 375523928,
    "24 hour?": "TRUE",
    "Latitude": 50.720628,
    "Longitude": 12.473056,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.720628,12.473056",
    "IsOpen24Hours": true,
    "id": "50.720628,12.473056"
  },
  {
    "ID": 1935809650,
    "Tankstellennummer": "TD035857",
    "Name": "GEISA BORSCHER STRASSE 21",
    "Address": "BORSCHER STRASSE 21",
    "Address__1": "",
    "City": "GEISA",
    "Postcode": 36419,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036967-76511",
    "24 hour?": "FALSE",
    "Latitude": 50.7225,
    "Longitude": 9.9585,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7225,9.9585",
    "IsOpen24Hours": false,
    "id": "50.7225,9.9585"
  },
  {
    "ID": 759992224,
    "Tankstellennummer": "0FN72",
    "Name": "WERDAU",
    "Address": "Greizer Straße 11",
    "Address__1": "",
    "City": "Werdau",
    "Postcode": 8412,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 37615209,
    "24 hour?": "FALSE",
    "Latitude": 50.723501,
    "Longitude": 12.374233,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.723501,12.374233",
    "IsOpen24Hours": false,
    "id": "50.723501,12.374233"
  },
  {
    "ID": 2137,
    "Tankstellennummer": "0FA16",
    "Name": "Aral Tankstelle Bonn, Königswinterer Str. 409",
    "Address": "Königswinterer Straße 409",
    "Address__1": "",
    "City": "Bonn",
    "Postcode": 53227,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 228442219,
    "24 hour?": "FALSE",
    "Latitude": 50.724429,
    "Longitude": 7.152956,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.724429,7.152956",
    "IsOpen24Hours": false,
    "id": "50.724429,7.152956"
  },
  {
    "ID": 2742,
    "Tankstellennummer": "0FE48",
    "Name": "Aral Autohof Niederdorf, Neue Schichtstr. 16",
    "Address": "Neue Schichtstraße 16",
    "Address__1": "",
    "City": "Niederdorf",
    "Postcode": 9366,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3729692441,
    "24 hour?": "TRUE",
    "Latitude": 50.724486,
    "Longitude": 12.778826,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.724486,12.778826",
    "IsOpen24Hours": true,
    "id": "50.724486,12.778826"
  },
  {
    "ID": 1140252356,
    "Tankstellennummer": "0FX86",
    "Name": "GERABERG",
    "Address": "A 71",
    "Address__1": "",
    "City": "Geraberg",
    "Postcode": 98716,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 36776894953,
    "24 hour?": "TRUE",
    "Latitude": 50.725311,
    "Longitude": 10.845811,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.725311,10.845811",
    "IsOpen24Hours": true,
    "id": "50.725311,10.845811"
  },
  {
    "ID": 594950657,
    "Tankstellennummer": "0FM35",
    "Name": "RUDOLSTADT",
    "Address": "Jenaische Straße 103c",
    "Address__1": "",
    "City": "Rudolstadt",
    "Postcode": 7407,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3672424471,
    "24 hour?": "FALSE",
    "Latitude": 50.727196,
    "Longitude": 11.364309,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.727196,11.364309",
    "IsOpen24Hours": false,
    "id": "50.727196,11.364309"
  },
  {
    "ID": 965214095,
    "Tankstellennummer": "TD038471",
    "Name": "BURKHARDTSDORF OBERE HAUPTSTR.",
    "Address": "OBERE HAUPTSTRASSE 44",
    "Address__1": "",
    "City": "BURKHARDTSDORF",
    "Postcode": 9235,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03721/268372",
    "24 hour?": "FALSE",
    "Latitude": 50.73,
    "Longitude": 12.9103,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.73,12.9103",
    "IsOpen24Hours": false,
    "id": "50.73,12.9103"
  },
  {
    "ID": 1672,
    "Tankstellennummer": "0F678",
    "Name": "Aral Tankstelle Homberg, Marburger Str.28",
    "Address": "Marburger Straße 28",
    "Address__1": "",
    "City": "Homberg",
    "Postcode": 35315,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6633363,
    "24 hour?": "FALSE",
    "Latitude": 50.730889,
    "Longitude": 8.99466,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.730889,8.99466",
    "IsOpen24Hours": false,
    "id": "50.730889,8.99466"
  },
  {
    "ID": 1280503174,
    "Tankstellennummer": "TD124140",
    "Name": "ALSFELD AUTOHOF PFEFFERHOEHE 1",
    "Address": "AUTOHOF PFEFFERHOEHE 18",
    "Address__1": "",
    "City": "ALSFELD",
    "Postcode": 36304,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06631-800850",
    "24 hour?": "FALSE",
    "Latitude": 50.7334,
    "Longitude": 9.2415,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7334,9.2415",
    "IsOpen24Hours": false,
    "id": "50.7334,9.2415"
  },
  {
    "ID": 43902891,
    "Tankstellennummer": "0FO84",
    "Name": "ELKENROTH",
    "Address": "Nordstraße 2",
    "Address__1": "",
    "City": "Elkenroth",
    "Postcode": 57578,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27479129748,
    "24 hour?": "FALSE",
    "Latitude": 50.73569,
    "Longitude": 7.877913,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.73569,7.877913",
    "IsOpen24Hours": false,
    "id": "50.73569,7.877913"
  },
  {
    "ID": 468885141,
    "Tankstellennummer": 4171,
    "Name": "Agip Alsfeld",
    "Address": "An der Hessenhalle 7",
    "Address__1": "",
    "City": "Alsfeld",
    "Postcode": 36304,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "06631/8026112",
    "24 hour?": "TRUE",
    "Latitude": 50.73834,
    "Longitude": 9.25037,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.73834,9.25037",
    "IsOpen24Hours": true,
    "id": "50.73834,9.25037"
  },
  {
    "ID": 2575,
    "Tankstellennummer": "0FD11",
    "Name": "Aral Tankstelle Neustadt, Triptiser Str. 36",
    "Address": "Triptiser Straße 36",
    "Address__1": "",
    "City": "Neustadt",
    "Postcode": 7806,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3648122249,
    "24 hour?": "TRUE",
    "Latitude": 50.738785,
    "Longitude": 11.768153,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.738785,11.768153",
    "IsOpen24Hours": true,
    "id": "50.738785,11.768153"
  },
  {
    "ID": 215081543,
    "Tankstellennummer": "TD150660",
    "Name": "HENNEF WESTERWALDSTR. 37",
    "Address": "WESTERWALDSTR. 37",
    "Address__1": "",
    "City": "HENNEF",
    "Postcode": 53773,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02248-2588",
    "24 hour?": "FALSE",
    "Latitude": 50.7404,
    "Longitude": 7.357,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7404,7.357",
    "IsOpen24Hours": false,
    "id": "50.7404,7.357"
  },
  {
    "ID": 200176352,
    "Tankstellennummer": 4172,
    "Name": "Agip Haiger",
    "Address": "Hohleichenrain 8",
    "Address__1": "",
    "City": "Haiger",
    "Postcode": 35708,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 2773/747",
    "24 hour?": "FALSE",
    "Latitude": 50.74165,
    "Longitude": 8.22374,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.74165,8.22374",
    "IsOpen24Hours": false,
    "id": "50.74165,8.22374"
  },
  {
    "ID": 2674,
    "Tankstellennummer": "0FD95",
    "Name": "Aral Tankstelle Zwickau, Leipziger Str. 208",
    "Address": "Leipziger Straße 208",
    "Address__1": "",
    "City": "Zwickau",
    "Postcode": 8058,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 375282684,
    "24 hour?": "TRUE",
    "Latitude": 50.742686,
    "Longitude": 12.488169,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.742686,12.488169",
    "IsOpen24Hours": true,
    "id": "50.742686,12.488169"
  },
  {
    "ID": 2064,
    "Tankstellennummer": "0F941",
    "Name": "Aral Tankstelle Bonn, Potsdamer Platz 2",
    "Address": "Potsdamer Platz 2",
    "Address__1": "",
    "City": "Bonn",
    "Postcode": 53119,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2287668731,
    "24 hour?": "TRUE",
    "Latitude": 50.743065,
    "Longitude": 7.078459,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.743065,7.078459",
    "IsOpen24Hours": true,
    "id": "50.743065,7.078459"
  },
  {
    "ID": 2069,
    "Tankstellennummer": "0F945",
    "Name": "Aral Tankstelle Bonn, Römerstr. 102-110",
    "Address": "Römerstraße 102-110",
    "Address__1": "",
    "City": "Bonn",
    "Postcode": 53111,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 228636321,
    "24 hour?": "FALSE",
    "Latitude": 50.746575,
    "Longitude": 7.099609,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.746575,7.099609",
    "IsOpen24Hours": false,
    "id": "50.746575,7.099609"
  },
  {
    "ID": 1449010108,
    "Tankstellennummer": "TD033316",
    "Name": "LUGAU HOHENSTEINER STR.44",
    "Address": "HOHENSTEINER STR.44",
    "Address__1": "",
    "City": "LUGAU",
    "Postcode": 9385,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037295-41978",
    "24 hour?": "FALSE",
    "Latitude": 50.7467,
    "Longitude": 12.7419,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7467,12.7419",
    "IsOpen24Hours": false,
    "id": "50.7467,12.7419"
  },
  {
    "ID": 2081,
    "Tankstellennummer": "0F957",
    "Name": "Aral Tankstelle Bonn, Landsberger Str. 2",
    "Address": "Landsberger Straße 2",
    "Address__1": "",
    "City": "Bonn",
    "Postcode": 53119,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 228661806,
    "24 hour?": "FALSE",
    "Latitude": 50.747492,
    "Longitude": 7.067335,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.747492,7.067335",
    "IsOpen24Hours": false,
    "id": "50.747492,7.067335"
  },
  {
    "ID": 242290229,
    "Tankstellennummer": "TD121320",
    "Name": "KREUZAU DUERENER STR. 38",
    "Address": "DUERENER STR. 38",
    "Address__1": "",
    "City": "KREUZAU",
    "Postcode": 52372,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02422-503234",
    "24 hour?": "FALSE",
    "Latitude": 50.7488,
    "Longitude": 6.491,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7488,6.491",
    "IsOpen24Hours": false,
    "id": "50.7488,6.491"
  },
  {
    "ID": 574090552,
    "Tankstellennummer": "0FV79",
    "Name": "WEILERSWIST",
    "Address": "Bonner Straße 70",
    "Address__1": "",
    "City": "Weilerswist",
    "Postcode": 53919,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22549690747,
    "24 hour?": "FALSE",
    "Latitude": 50.74921,
    "Longitude": 6.85018,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.74921,6.85018",
    "IsOpen24Hours": false,
    "id": "50.74921,6.85018"
  },
  {
    "ID": 752707402,
    "Tankstellennummer": "TD041483",
    "Name": "DILLENBURG KASSELER STR. 30",
    "Address": "KASSELER STR. 30",
    "Address__1": "",
    "City": "DILLENBURG",
    "Postcode": 35683,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02771-812042",
    "24 hour?": "FALSE",
    "Latitude": 50.7496,
    "Longitude": 8.277,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7496,8.277",
    "IsOpen24Hours": false,
    "id": "50.7496,8.277"
  },
  {
    "ID": 1687,
    "Tankstellennummer": "0F691",
    "Name": "Aral Tankstelle Dillenburg, Kasseler Str. 32",
    "Address": "Kasseler Straße 32",
    "Address__1": "",
    "City": "Dillenburg",
    "Postcode": 35683,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2771812117,
    "24 hour?": "FALSE",
    "Latitude": 50.749956,
    "Longitude": 8.276847,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.749956,8.276847",
    "IsOpen24Hours": false,
    "id": "50.749956,8.276847"
  },
  {
    "ID": 500294232,
    "Tankstellennummer": "0FO96",
    "Name": "BURBACH",
    "Address": "Carl Benz Straße 11",
    "Address__1": "",
    "City": "Burbach",
    "Postcode": 57299,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27366891,
    "24 hour?": "FALSE",
    "Latitude": 50.7504,
    "Longitude": 8.100061,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7504,8.100061",
    "IsOpen24Hours": false,
    "id": "50.7504,8.100061"
  },
  {
    "ID": 2068,
    "Tankstellennummer": "0F944",
    "Name": "Aral Tankstelle Aachen, Trierer Str. 705",
    "Address": "Trierer Straße 703",
    "Address__1": "",
    "City": "Aachen",
    "Postcode": 52078,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 241522087,
    "24 hour?": "FALSE",
    "Latitude": 50.75198,
    "Longitude": 6.15932,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.75198,6.15932",
    "IsOpen24Hours": false,
    "id": "50.75198,6.15932"
  },
  {
    "ID": 1228772234,
    "Tankstellennummer": 5220,
    "Name": "Agip Lichtenstein",
    "Address": "Aeuss.Zwickauer Str. 16-20",
    "Address__1": "",
    "City": "Lichtenstein",
    "Postcode": 9350,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 37204213",
    "24 hour?": "FALSE",
    "Latitude": 50.75273644,
    "Longitude": 12.62110193,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.75273644,12.62110193",
    "IsOpen24Hours": false,
    "id": "50.75273644,12.62110193"
  },
  {
    "ID": 1682,
    "Tankstellennummer": "0F686",
    "Name": "Aral Tankstelle Gladenbach, Petersburg  6",
    "Address": "Petersburg  8",
    "Address__1": "",
    "City": "Gladenbach",
    "Postcode": 35075,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 64621086,
    "24 hour?": "FALSE",
    "Latitude": 50.753283,
    "Longitude": 8.546959,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.753283,8.546959",
    "IsOpen24Hours": false,
    "id": "50.753283,8.546959"
  },
  {
    "ID": 508478852,
    "Tankstellennummer": "TD039214",
    "Name": "BORNHEIM BONNER STR. 71",
    "Address": "BONNER STR. 71",
    "Address__1": "",
    "City": "BORNHEIM",
    "Postcode": 53332,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02222-9890200",
    "24 hour?": "FALSE",
    "Latitude": 50.7563,
    "Longitude": 7.0086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7563,7.0086",
    "IsOpen24Hours": false,
    "id": "50.7563,7.0086"
  },
  {
    "ID": 1446472500,
    "Tankstellennummer": 4705,
    "Name": "Agip Weimar/Nieder-Weimar",
    "Address": "Herborner Str. 34",
    "Address__1": "",
    "City": "Weimar/Nieder-Weimar",
    "Postcode": 35096,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 64217615",
    "24 hour?": "FALSE",
    "Latitude": 50.76000475,
    "Longitude": 8.73340648,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.76000475,8.73340648",
    "IsOpen24Hours": false,
    "id": "50.76000475,8.73340648"
  },
  {
    "ID": 1026311084,
    "Tankstellennummer": 610274,
    "Name": "WESTFALEN, BORNHEIM",
    "Address": "Alexander-Bell-Str. 1",
    "Address__1": "",
    "City": "Bornheim",
    "Postcode": 53332,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02222/6480965",
    "24 hour?": "TRUE",
    "Latitude": 50.761,
    "Longitude": 7.02631,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.761,7.02631",
    "IsOpen24Hours": true,
    "id": "50.761,7.02631"
  },
  {
    "ID": 28490,
    "Tankstellennummer": "0FP44",
    "Name": "Aral Tankstelle Stolberg, Gressenicher Str. 85",
    "Address": "Gressenicher Straße 85",
    "Address__1": "",
    "City": "Stolberg",
    "Postcode": 52224,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2402764757,
    "24 hour?": "FALSE",
    "Latitude": 50.761343,
    "Longitude": 6.282833,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.761343,6.282833",
    "IsOpen24Hours": false,
    "id": "50.761343,6.282833"
  },
  {
    "ID": 936075068,
    "Tankstellennummer": "TD039206",
    "Name": "BORNHEIM AM HELLENKREUZ 1",
    "Address": "AM HELLENKREUZ 1",
    "Address__1": "",
    "City": "BORNHEIM",
    "Postcode": 53332,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02222-9891379",
    "24 hour?": "FALSE",
    "Latitude": 50.7624,
    "Longitude": 6.9758,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7624,6.9758",
    "IsOpen24Hours": false,
    "id": "50.7624,6.9758"
  },
  {
    "ID": 2085,
    "Tankstellennummer": "0F960",
    "Name": "Aral Tankstelle Aachen, Friedrich-Ebert-Allee 97",
    "Address": "Friedrich-Ebert-Allee 97",
    "Address__1": "",
    "City": "Aachen",
    "Postcode": 52066,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24166204,
    "24 hour?": "FALSE",
    "Latitude": 50.7627,
    "Longitude": 6.096954,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.7627,6.096954",
    "IsOpen24Hours": false,
    "id": "50.7627,6.096954"
  },
  {
    "ID": 398339664,
    "Tankstellennummer": "TD000810",
    "Name": "STOLBERG PRAEMIENSTR. 30",
    "Address": "PRAEMIENSTR. 30",
    "Address__1": "",
    "City": "STOLBERG",
    "Postcode": 52223,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02402-24678",
    "24 hour?": "FALSE",
    "Latitude": 50.7645,
    "Longitude": 6.2172,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7645,6.2172",
    "IsOpen24Hours": false,
    "id": "50.7645,6.2172"
  },
  {
    "ID": 844831421,
    "Tankstellennummer": "TD023648",
    "Name": "STADTILM ILMENAUER STR. 2",
    "Address": "ILMENAUER STR.2",
    "Address__1": "",
    "City": "STADTILM",
    "Postcode": 99326,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03629-3172",
    "24 hour?": "FALSE",
    "Latitude": 50.7677,
    "Longitude": 11.0648,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7677,11.0648",
    "IsOpen24Hours": false,
    "id": "50.7677,11.0648"
  },
  {
    "ID": 590509748,
    "Tankstellennummer": 4702,
    "Name": "Agip Gladenbach",
    "Address": "Marburger Strasse 30",
    "Address__1": "",
    "City": "Gladenbach",
    "Postcode": 35075,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 64629116",
    "24 hour?": "FALSE",
    "Latitude": 50.77166,
    "Longitude": 8.60434,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.77166,8.60434",
    "IsOpen24Hours": false,
    "id": "50.77166,8.60434"
  },
  {
    "ID": 2065,
    "Tankstellennummer": "0F942",
    "Name": "Aral Tankstelle Eitorf, Bahnhofstr.43",
    "Address": "Bahnhofstr. 43",
    "Address__1": "",
    "City": "Eitorf",
    "Postcode": 53783,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22433133,
    "24 hour?": "FALSE",
    "Latitude": 50.773706,
    "Longitude": 7.444756,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.773706,7.444756",
    "IsOpen24Hours": false,
    "id": "50.773706,7.444756"
  },
  {
    "ID": 2157,
    "Tankstellennummer": "0FA14",
    "Name": "Aral Tankstelle Sankt Augustin, Bonner Str. 134",
    "Address": "Bonner Straße 134",
    "Address__1": "",
    "City": "Sankt Augustin",
    "Postcode": 53757,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2241203231,
    "24 hour?": "FALSE",
    "Latitude": 50.778405,
    "Longitude": 7.190881,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.778405,7.190881",
    "IsOpen24Hours": false,
    "id": "50.778405,7.190881"
  },
  {
    "ID": 2934,
    "Tankstellennummer": "0FH94",
    "Name": "Aral Tankstelle Aachen, Joseph-von-Görres Str. 53",
    "Address": "Joseph-von-Görres Str. 53",
    "Address__1": "",
    "City": "Aachen",
    "Postcode": 52068,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 241502672,
    "24 hour?": "TRUE",
    "Latitude": 50.779193,
    "Longitude": 6.109724,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.779193,6.109724",
    "IsOpen24Hours": true,
    "id": "50.779193,6.109724"
  },
  {
    "ID": 1914916804,
    "Tankstellennummer": 610276,
    "Name": "WESTFALEN, DUEREN",
    "Address": "Nikolaus-Otto-Str. 1",
    "Address__1": "",
    "City": "Düren",
    "Postcode": 52351,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02421/4953057",
    "24 hour?": "FALSE",
    "Latitude": 50.7794,
    "Longitude": 6.5065,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7794,6.5065",
    "IsOpen24Hours": false,
    "id": "50.7794,6.5065"
  },
  {
    "ID": 1380,
    "Tankstellennummer": "0F461",
    "Name": "Aral Tankstelle Sankt Augustin, Frankfurter Str. 78",
    "Address": "Frankfurter Straße 78",
    "Address__1": "",
    "City": "Sankt Augustin",
    "Postcode": 53757,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2241590763,
    "24 hour?": "FALSE",
    "Latitude": 50.783231,
    "Longitude": 7.235983,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.783231,7.235983",
    "IsOpen24Hours": false,
    "id": "50.783231,7.235983"
  },
  {
    "ID": 2933,
    "Tankstellennummer": "0FH93",
    "Name": "Aral Tankstelle Aachen, Roermonder Str. 33",
    "Address": "Roermonder Straße 33",
    "Address__1": "",
    "City": "Aachen",
    "Postcode": 52072,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 241872088,
    "24 hour?": "TRUE",
    "Latitude": 50.783904,
    "Longitude": 6.074591,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.783904,6.074591",
    "IsOpen24Hours": true,
    "id": "50.783904,6.074591"
  },
  {
    "ID": 858857706,
    "Tankstellennummer": 4775,
    "Name": "Agip Wissen / Sieg",
    "Address": "Morsbacher Str. 13",
    "Address__1": "",
    "City": "Wissen / Sieg",
    "Postcode": 57537,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "02742/2876",
    "24 hour?": "FALSE",
    "Latitude": 50.78691,
    "Longitude": 7.72913,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.78691,7.72913",
    "IsOpen24Hours": false,
    "id": "50.78691,7.72913"
  },
  {
    "ID": 2993,
    "Tankstellennummer": "0FI45",
    "Name": "Aral Tankstelle Siegburg, Bonner Strasse 32-34",
    "Address": "Bonner Straße 32-34",
    "Address__1": "",
    "City": "Siegburg",
    "Postcode": 53721,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 224162409,
    "24 hour?": "TRUE",
    "Latitude": 50.791351,
    "Longitude": 7.204606,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.791351,7.204606",
    "IsOpen24Hours": true,
    "id": "50.791351,7.204606"
  },
  {
    "ID": 1550644622,
    "Tankstellennummer": "0FM42",
    "Name": "Aral TankStelle Marburg, B 3 / Am Krekel 3",
    "Address": "B 3 / Am Krekel 3",
    "Address__1": "",
    "City": "Marburg",
    "Postcode": 35039,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6421165036,
    "24 hour?": "TRUE",
    "Latitude": 50.793705,
    "Longitude": 8.76076,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.793705,8.76076",
    "IsOpen24Hours": true,
    "id": "50.793705,8.76076"
  },
  {
    "ID": 1566813798,
    "Tankstellennummer": "TD120580",
    "Name": "AACHEN ROERMONDER STR. 315",
    "Address": "ROERMONDER STR. 315",
    "Address__1": "",
    "City": "AACHEN",
    "Postcode": 52072,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0241-12773",
    "24 hour?": "FALSE",
    "Latitude": 50.7961,
    "Longitude": 6.0636,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7961,6.0636",
    "IsOpen24Hours": false,
    "id": "50.7961,6.0636"
  },
  {
    "ID": 1077334055,
    "Tankstellennummer": "TD025486",
    "Name": "CHEMNITZ STOLLBERGER STRASSE 1",
    "Address": "STOLLBERGER STRASSE 178",
    "Address__1": "",
    "City": "CHEMNITZ",
    "Postcode": 9122,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0371-2824083",
    "24 hour?": "FALSE",
    "Latitude": 50.7962,
    "Longitude": 12.8811,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.7962,12.8811",
    "IsOpen24Hours": false,
    "id": "50.7962,12.8811"
  },
  {
    "ID": 28477,
    "Tankstellennummer": "0FP43",
    "Name": "Aral Tankstelle Eschweiler, Stolberger Str. 64",
    "Address": "Stolberger Straße 64",
    "Address__1": "",
    "City": "Eschweiler",
    "Postcode": 52249,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2403504360,
    "24 hour?": "FALSE",
    "Latitude": 50.799015,
    "Longitude": 6.236251,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.799015,6.236251",
    "IsOpen24Hours": false,
    "id": "50.799015,6.236251"
  },
  {
    "ID": 1632,
    "Tankstellennummer": "0F658",
    "Name": "Aral Tankstelle Marburg, Schwanallee  56",
    "Address": "Schwanallee 56",
    "Address__1": "",
    "City": "Marburg",
    "Postcode": 35037,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6421167089,
    "24 hour?": "TRUE",
    "Latitude": 50.799101,
    "Longitude": 8.762429,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.799101,8.762429",
    "IsOpen24Hours": true,
    "id": "50.799101,8.762429"
  },
  {
    "ID": 1720,
    "Tankstellennummer": "0F713",
    "Name": "Aral Tankstelle Marburg, Großseelheimer Str. 93",
    "Address": "Großseelheimer Straße 93",
    "Address__1": "",
    "City": "Marburg",
    "Postcode": 35039,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 642124595,
    "24 hour?": "TRUE",
    "Latitude": 50.799606,
    "Longitude": 8.788718,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.799606,8.788718",
    "IsOpen24Hours": true,
    "id": "50.799606,8.788718"
  },
  {
    "ID": 1006812260,
    "Tankstellennummer": 610361,
    "Name": "WESTFALEN, AACHEN",
    "Address": "Strangenhäuschen 10",
    "Address__1": "",
    "City": "Aachen",
    "Postcode": 52070,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0241/ 9551551",
    "24 hour?": "FALSE",
    "Latitude": 50.8018,
    "Longitude": 6.11078,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8018,6.11078",
    "IsOpen24Hours": false,
    "id": "50.8018,6.11078"
  },
  {
    "ID": 736346286,
    "Tankstellennummer": "TD041848",
    "Name": "LEDERHOSE A9 / B175",
    "Address": "An der Autobahn 1",
    "Address__1": "",
    "City": "LEDERHOSE",
    "Postcode": 7589,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 50.803,
    "Longitude": 11.8939,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.803,11.8939",
    "IsOpen24Hours": false,
    "id": "50.803,11.8939"
  },
  {
    "ID": 870322940,
    "Tankstellennummer": "TD032904",
    "Name": "HOHENSTEIN-ERNSTTHAL DRESDNER",
    "Address": "DRESDNER STR. 106",
    "Address__1": "",
    "City": "HOHENSTEIN-ERNSTTHAL",
    "Postcode": 9337,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03723-411166",
    "24 hour?": "FALSE",
    "Latitude": 50.8047,
    "Longitude": 12.7267,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8047,12.7267",
    "IsOpen24Hours": false,
    "id": "50.8047,12.7267"
  },
  {
    "ID": 757912597,
    "Tankstellennummer": "TD025742",
    "Name": "CRIMMITSCHAU WERDAUER STR. 35",
    "Address": "WERDAUER STR. 35",
    "Address__1": "",
    "City": "CRIMMITSCHAU",
    "Postcode": 8451,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03762-6093",
    "24 hour?": "FALSE",
    "Latitude": 50.8082,
    "Longitude": 12.3885,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8082,12.3885",
    "IsOpen24Hours": false,
    "id": "50.8082,12.3885"
  },
  {
    "ID": 912361603,
    "Tankstellennummer": "TD024430",
    "Name": "BAD SALZUNGEN AUGUST-BEBEL-STR",
    "Address": "AUGUST-BEBEL-STR. 98",
    "Address__1": "",
    "City": "BAD SALZUNGEN",
    "Postcode": 36433,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03695-622489",
    "24 hour?": "FALSE",
    "Latitude": 50.8083,
    "Longitude": 10.2584,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8083,10.2584",
    "IsOpen24Hours": false,
    "id": "50.8083,10.2584"
  },
  {
    "ID": 2639,
    "Tankstellennummer": "0FD63",
    "Name": "Aral Tankstelle Kahla, Am Heerweg 33",
    "Address": "Am Heerweg 33",
    "Address__1": "",
    "City": "Kahla",
    "Postcode": 7768,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3642424005,
    "24 hour?": "FALSE",
    "Latitude": 50.809309,
    "Longitude": 11.577449,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.809309,11.577449",
    "IsOpen24Hours": false,
    "id": "50.809309,11.577449"
  },
  {
    "ID": 2070,
    "Tankstellennummer": "0F946",
    "Name": "Aral Tankstelle Würselen, Hauptstr.  384",
    "Address": "Hauptstraße 384",
    "Address__1": "",
    "City": "Würselen",
    "Postcode": 52146,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 240592738,
    "24 hour?": "TRUE",
    "Latitude": 50.809385,
    "Longitude": 6.144159,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.809385,6.144159",
    "IsOpen24Hours": true,
    "id": "50.809385,6.144159"
  },
  {
    "ID": 680456053,
    "Tankstellennummer": "0FO48",
    "Name": "NÖRVENICH",
    "Address": "Bahnhofstraße 57",
    "Address__1": "",
    "City": "Nörvenich",
    "Postcode": 52388,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2426901818,
    "24 hour?": "FALSE",
    "Latitude": 50.809733,
    "Longitude": 6.636635,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.809733,6.636635",
    "IsOpen24Hours": false,
    "id": "50.809733,6.636635"
  },
  {
    "ID": 2158,
    "Tankstellennummer": "0FA15",
    "Name": "Aral Tankstelle Troisdorf, Moselstr. 2",
    "Address": "Moselstraße 2",
    "Address__1": "",
    "City": "Troisdorf",
    "Postcode": 53842,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2241809276,
    "24 hour?": "FALSE",
    "Latitude": 50.810069,
    "Longitude": 7.157805,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.810069,7.157805",
    "IsOpen24Hours": false,
    "id": "50.810069,7.157805"
  },
  {
    "ID": 1938743163,
    "Tankstellennummer": "TD025346",
    "Name": "CHEMNITZ ZSCHOPAUER STR. 319",
    "Address": "ZSCHOPAUER STR. 319",
    "Address__1": "",
    "City": "CHEMNITZ",
    "Postcode": 9127,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0371-7253452",
    "24 hour?": "FALSE",
    "Latitude": 50.8102,
    "Longitude": 12.9651,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8102,12.9651",
    "IsOpen24Hours": false,
    "id": "50.8102,12.9651"
  },
  {
    "ID": 1009647346,
    "Tankstellennummer": "0FM55",
    "Name": "ERFTSTADT",
    "Address": "Carl-Schurz-Straße 160",
    "Address__1": "",
    "City": "Erftstadt",
    "Postcode": 50374,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 223545711,
    "24 hour?": "FALSE",
    "Latitude": 50.810398,
    "Longitude": 6.808512,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.810398,6.808512",
    "IsOpen24Hours": false,
    "id": "50.810398,6.808512"
  },
  {
    "ID": 932320085,
    "Tankstellennummer": "0FO89",
    "Name": "KIRCHEN",
    "Address": "Jungenthaler Straße 75",
    "Address__1": "",
    "City": "Kirchen",
    "Postcode": 57548,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27417278,
    "24 hour?": "FALSE",
    "Latitude": 50.810948,
    "Longitude": 7.864885,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.810948,7.864885",
    "IsOpen24Hours": false,
    "id": "50.810948,7.864885"
  },
  {
    "ID": 427090267,
    "Tankstellennummer": "TD124250",
    "Name": "DUEREN NEUE JUELICHER STR. 45",
    "Address": "NEUE JUELICHER STR. 45",
    "Address__1": "",
    "City": "DUEREN",
    "Postcode": 52353,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02421-941417",
    "24 hour?": "FALSE",
    "Latitude": 50.8123,
    "Longitude": 6.4745,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8123,6.4745",
    "IsOpen24Hours": false,
    "id": "50.8123,6.4745"
  },
  {
    "ID": 260100349,
    "Tankstellennummer": "TD019323",
    "Name": "DUEREN SCHOELLERSTR. 155",
    "Address": "SCHOELLERSTR. 155",
    "Address__1": "",
    "City": "DUEREN",
    "Postcode": 52351,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02421-14252",
    "24 hour?": "FALSE",
    "Latitude": 50.8132,
    "Longitude": 6.4884,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8132,6.4884",
    "IsOpen24Hours": false,
    "id": "50.8132,6.4884"
  },
  {
    "ID": 2555,
    "Tankstellennummer": "0FC93",
    "Name": "Aral Tankstelle Leimbach, Salzunger Str.",
    "Address": "Salzunger Straße 27",
    "Address__1": "",
    "City": "Leimbach",
    "Postcode": 36433,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3695628240,
    "24 hour?": "TRUE",
    "Latitude": 50.813589,
    "Longitude": 10.20417,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.813589,10.20417",
    "IsOpen24Hours": true,
    "id": "50.813589,10.20417"
  },
  {
    "ID": 769654416,
    "Tankstellennummer": 610366,
    "Name": "WESTFALEN, DUEREN",
    "Address": "Kölner Landstr. 421",
    "Address__1": "",
    "City": "Düren",
    "Postcode": 52351,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02421/391751",
    "24 hour?": "FALSE",
    "Latitude": 50.8137,
    "Longitude": 6.51702,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8137,6.51702",
    "IsOpen24Hours": false,
    "id": "50.8137,6.51702"
  },
  {
    "ID": 2595,
    "Tankstellennummer": "0FD26",
    "Name": "Aral Tankstelle Chemnitz, Zschopauer Str. 234",
    "Address": "Zschopauer Straße 234",
    "Address__1": "",
    "City": "Chemnitz",
    "Postcode": 9126,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 37133471632,
    "24 hour?": "TRUE",
    "Latitude": 50.814158,
    "Longitude": 12.954828,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.814158,12.954828",
    "IsOpen24Hours": true,
    "id": "50.814158,12.954828"
  },
  {
    "ID": 2571,
    "Tankstellennummer": "0FD06",
    "Name": "Aral Tankstelle Chemnitz, Annaberger Str. 94",
    "Address": "Annaberger Straße 94",
    "Address__1": "",
    "City": "Chemnitz",
    "Postcode": 9120,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 1724102820,
    "24 hour?": "FALSE",
    "Latitude": 50.814593,
    "Longitude": 12.91301,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.814593,12.91301",
    "IsOpen24Hours": false,
    "id": "50.814593,12.91301"
  },
  {
    "ID": 22012116,
    "Tankstellennummer": "TD037705",
    "Name": "WILNSDORF AUTOHOF ELKERSBERG 2",
    "Address": "AUTOHOF ELKERSBERG 2",
    "Address__1": "",
    "City": "WILNSDORF",
    "Postcode": 57234,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02739-40339-0",
    "24 hour?": "FALSE",
    "Latitude": 50.8147,
    "Longitude": 8.0967,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8147,8.0967",
    "IsOpen24Hours": false,
    "id": "50.8147,8.0967"
  },
  {
    "ID": 2740,
    "Tankstellennummer": "0FE46",
    "Name": "Aral Tankstelle Ohrdruf, Suhler Str. 5 b",
    "Address": "Suhler Straße 5 b",
    "Address__1": "",
    "City": "Ohrdruf",
    "Postcode": 99885,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3624313753,
    "24 hour?": "FALSE",
    "Latitude": 50.815165,
    "Longitude": 10.729685,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.815165,10.729685",
    "IsOpen24Hours": false,
    "id": "50.815165,10.729685"
  },
  {
    "ID": 3385,
    "Tankstellennummer": "0FL26",
    "Name": "Aral Tankstelle Chemnitz, Neefestr. 145",
    "Address": "Neefestraße 145",
    "Address__1": "",
    "City": "Chemnitz",
    "Postcode": 9116,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3712806239,
    "24 hour?": "FALSE",
    "Latitude": 50.81645,
    "Longitude": 12.88299,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.81645,12.88299",
    "IsOpen24Hours": false,
    "id": "50.81645,12.88299"
  },
  {
    "ID": 2596,
    "Tankstellennummer": "0FD27",
    "Name": "Aral Tankstelle Ohrdruf, Suhler Str. 5 a",
    "Address": "Suhler Straße 5 a",
    "Address__1": "",
    "City": "Ohrdruf",
    "Postcode": 99885,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3624312755,
    "24 hour?": "TRUE",
    "Latitude": 50.81657,
    "Longitude": 10.729292,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.81657,10.729292",
    "IsOpen24Hours": true,
    "id": "50.81657,10.729292"
  },
  {
    "ID": 2053,
    "Tankstellennummer": "0F934",
    "Name": "Aral Tankstelle Langerwehe, Hauptstr. 275",
    "Address": "Hauptstraße 275",
    "Address__1": "",
    "City": "Langerwehe",
    "Postcode": 52379,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24231745,
    "24 hour?": "FALSE",
    "Latitude": 50.816628,
    "Longitude": 6.371937,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.816628,6.371937",
    "IsOpen24Hours": false,
    "id": "50.816628,6.371937"
  },
  {
    "ID": 1719,
    "Tankstellennummer": "0F712",
    "Name": "Aral Tankstelle Marburg, Krummbogen 4",
    "Address": "Krummbogen 4",
    "Address__1": "",
    "City": "Marburg",
    "Postcode": 35039,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 642161348,
    "24 hour?": "TRUE",
    "Latitude": 50.817594,
    "Longitude": 8.774307,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.817594,8.774307",
    "IsOpen24Hours": true,
    "id": "50.817594,8.774307"
  },
  {
    "ID": 1224,
    "Tankstellennummer": "0F332",
    "Name": "Aral Tankstelle Wilnsdorf, Hagener Str.19",
    "Address": "Hagener Straße 19",
    "Address__1": "",
    "City": "Wilnsdorf",
    "Postcode": 57234,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 273987330,
    "24 hour?": "FALSE",
    "Latitude": 50.817705,
    "Longitude": 8.106176,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.817705,8.106176",
    "IsOpen24Hours": false,
    "id": "50.817705,8.106176"
  },
  {
    "ID": 478425849,
    "Tankstellennummer": "TD025494",
    "Name": "AUGUSTUSBURG UNTERE SCHLOSSSTR",
    "Address": "UNTERE SCHLOSSSTRASSE 37",
    "Address__1": "",
    "City": "AUGUSTUSBURG",
    "Postcode": 9573,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037291-6567",
    "24 hour?": "FALSE",
    "Latitude": 50.8183,
    "Longitude": 13.1009,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8183,13.1009",
    "IsOpen24Hours": false,
    "id": "50.8183,13.1009"
  },
  {
    "ID": 813485433,
    "Tankstellennummer": "TD001115",
    "Name": "ESCHWEILER DUERENER STR. 168-1",
    "Address": "DUERENER STR. 168-172",
    "Address__1": "",
    "City": "ESCHWEILER",
    "Postcode": 52249,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02403-4600",
    "24 hour?": "FALSE",
    "Latitude": 50.8189,
    "Longitude": 6.2821,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8189,6.2821",
    "IsOpen24Hours": false,
    "id": "50.8189,6.2821"
  },
  {
    "ID": 1362,
    "Tankstellennummer": "0F447",
    "Name": "Aral Tankstelle Troisdorf, Mülheimer Str. 15",
    "Address": "Mülheimer Straße 15",
    "Address__1": "",
    "City": "Troisdorf",
    "Postcode": 53840,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2241809505,
    "24 hour?": "TRUE",
    "Latitude": 50.819062,
    "Longitude": 7.142635,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.819062,7.142635",
    "IsOpen24Hours": true,
    "id": "50.819062,7.142635"
  },
  {
    "ID": 2534,
    "Tankstellennummer": "0FC73",
    "Name": "Aral Tankstelle Crimmitschau, Glauchauer Landstr. 70",
    "Address": "Glauchauer Landstraße 70",
    "Address__1": "",
    "City": "Crimmitschau",
    "Postcode": 8451,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 37625374,
    "24 hour?": "FALSE",
    "Latitude": 50.819565,
    "Longitude": 12.405028,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.819565,12.405028",
    "IsOpen24Hours": false,
    "id": "50.819565,12.405028"
  },
  {
    "ID": 513854280,
    "Tankstellennummer": "TD000931",
    "Name": "ESCHWEILER DUERENER STR. 308",
    "Address": "DUERENER STR. 308",
    "Address__1": "",
    "City": "ESCHWEILER",
    "Postcode": 52249,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02403-21572",
    "24 hour?": "FALSE",
    "Latitude": 50.8197,
    "Longitude": 6.2921,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8197,6.2921",
    "IsOpen24Hours": false,
    "id": "50.8197,6.2921"
  },
  {
    "ID": 308322750,
    "Tankstellennummer": "TD000385",
    "Name": "MERZENICH VALDERSWEG 99",
    "Address": "VALDERSWEG 99",
    "Address__1": "",
    "City": "MERZENICH",
    "Postcode": 52399,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02421-393988",
    "24 hour?": "FALSE",
    "Latitude": 50.8198,
    "Longitude": 6.533,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8198,6.533",
    "IsOpen24Hours": false,
    "id": "50.8198,6.533"
  },
  {
    "ID": 2047,
    "Tankstellennummer": "0F928",
    "Name": "Aral Tankstelle Brühl, Pingsdorfer Str.106",
    "Address": "Pingsdorfer Straße 106",
    "Address__1": "",
    "City": "Brühl",
    "Postcode": 50321,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2232941130,
    "24 hour?": "FALSE",
    "Latitude": 50.820612,
    "Longitude": 6.895321,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.820612,6.895321",
    "IsOpen24Hours": false,
    "id": "50.820612,6.895321"
  },
  {
    "ID": 1871045872,
    "Tankstellennummer": "TD000638",
    "Name": "DUEREN ZOLLHAUSSTR. 10",
    "Address": "ZOLLHAUSSTR. 10",
    "Address__1": "",
    "City": "DUEREN",
    "Postcode": 52353,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02421-81150",
    "24 hour?": "FALSE",
    "Latitude": 50.8222,
    "Longitude": 6.4614,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8222,6.4614",
    "IsOpen24Hours": false,
    "id": "50.8222,6.4614"
  },
  {
    "ID": 58659258,
    "Tankstellennummer": "TD111630",
    "Name": "HAUNECK HUENFELDER STR. 4",
    "Address": "HUENFELDER STR. 4",
    "Address__1": "",
    "City": "HAUNECK",
    "Postcode": 36282,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06621-75264",
    "24 hour?": "FALSE",
    "Latitude": 50.8242,
    "Longitude": 9.7325,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8242,9.7325",
    "IsOpen24Hours": false,
    "id": "50.8242,9.7325"
  },
  {
    "ID": 929091748,
    "Tankstellennummer": "TD000312",
    "Name": "KIRCHHAIN NIEDERRHEINISCHE STR",
    "Address": "NIEDERRHEINISCHE STR. 39",
    "Address__1": "",
    "City": "KIRCHHAIN",
    "Postcode": 35274,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06422-1775",
    "24 hour?": "FALSE",
    "Latitude": 50.8243,
    "Longitude": 8.9286,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8243,8.9286",
    "IsOpen24Hours": false,
    "id": "50.8243,8.9286"
  },
  {
    "ID": 3329,
    "Tankstellennummer": "0FK83",
    "Name": "Aral Tankstelle Eschweiler, Rue de Wattrelos 11",
    "Address": "Rue de Wattrelos 11",
    "Address__1": "",
    "City": "Eschweiler",
    "Postcode": 52249,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2403830005,
    "24 hour?": "TRUE",
    "Latitude": 50.82532,
    "Longitude": 6.247152,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.82532,6.247152",
    "IsOpen24Hours": true,
    "id": "50.82532,6.247152"
  },
  {
    "ID": 1468907663,
    "Tankstellennummer": "TD035782",
    "Name": "BAD SALZUNGEN AN DER B 62",
    "Address": "An der B 62 Nr. 2",
    "Address__1": "",
    "City": "BAD SALZUNGEN",
    "Postcode": 36469,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03695-82010",
    "24 hour?": "FALSE",
    "Latitude": 50.8258,
    "Longitude": 10.1601,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8258,10.1601",
    "IsOpen24Hours": false,
    "id": "50.8258,10.1601"
  },
  {
    "ID": 2080,
    "Tankstellennummer": "0F956",
    "Name": "Aral Tankstelle Würselen, Krefelder Str.17",
    "Address": "Krefelder Straße 17",
    "Address__1": "",
    "City": "Würselen",
    "Postcode": 52146,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 240582575,
    "24 hour?": "TRUE",
    "Latitude": 50.826754,
    "Longitude": 6.128096,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.826754,6.128096",
    "IsOpen24Hours": true,
    "id": "50.826754,6.128096"
  },
  {
    "ID": 1875825276,
    "Tankstellennummer": 4738,
    "Name": "Agip Vacha",
    "Address": "Badelacher Weg 10",
    "Address__1": "",
    "City": "Vacha",
    "Postcode": 36404,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 36962246",
    "24 hour?": "FALSE",
    "Latitude": 50.82847,
    "Longitude": 10.03043,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.82847,10.03043",
    "IsOpen24Hours": false,
    "id": "50.82847,10.03043"
  },
  {
    "ID": 915423866,
    "Tankstellennummer": 4164,
    "Name": "Agip Stadtallendorf",
    "Address": "Bahnhofstr./Am Lohpfad 1",
    "Address__1": "",
    "City": "Stadtallendorf",
    "Postcode": 35260,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 64289212",
    "24 hour?": "FALSE",
    "Latitude": 50.82895,
    "Longitude": 9.02061,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.82895,9.02061",
    "IsOpen24Hours": false,
    "id": "50.82895,9.02061"
  },
  {
    "ID": 1348,
    "Tankstellennummer": "0F433",
    "Name": "Aral Tankstelle Herzogenrath, Roermonder Str. 25-29",
    "Address": "Roermonder Straße 25-29",
    "Address__1": "",
    "City": "Herzogenrath",
    "Postcode": 52134,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 240718113,
    "24 hour?": "FALSE",
    "Latitude": 50.829493,
    "Longitude": 6.078054,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.829493,6.078054",
    "IsOpen24Hours": false,
    "id": "50.829493,6.078054"
  },
  {
    "ID": 3292,
    "Tankstellennummer": "0FK53",
    "Name": "Aral Tankstelle Wesseling, Bruehler Strasse 160",
    "Address": "Brühler Straße 160",
    "Address__1": "",
    "City": "Wesseling",
    "Postcode": 50389,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2232942668,
    "24 hour?": "TRUE",
    "Latitude": 50.830895,
    "Longitude": 6.956559,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.830895,6.956559",
    "IsOpen24Hours": true,
    "id": "50.830895,6.956559"
  },
  {
    "ID": 499336187,
    "Tankstellennummer": 4734,
    "Name": "Agip Kirchheim",
    "Address": "Industriestr. 7a",
    "Address__1": "",
    "City": "Kirchheim",
    "Postcode": 36275,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 6625632",
    "24 hour?": "TRUE",
    "Latitude": 50.83196,
    "Longitude": 9.57097,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.83196,9.57097",
    "IsOpen24Hours": true,
    "id": "50.83196,9.57097"
  },
  {
    "ID": 2186,
    "Tankstellennummer": "0FF02",
    "Name": "Aral Tankstelle Düren, Nordstr. / B 56",
    "Address": "Nordstraße 176",
    "Address__1": "",
    "City": "Düren",
    "Postcode": 52353,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2421204542,
    "24 hour?": "TRUE",
    "Latitude": 50.832429,
    "Longitude": 6.458606,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.832429,6.458606",
    "IsOpen24Hours": true,
    "id": "50.832429,6.458606"
  },
  {
    "ID": 41013834,
    "Tankstellennummer": "TD026021",
    "Name": "CHEMNITZ LIMBACHER STR. 218-22",
    "Address": "LIMBACHER STR. 218-220",
    "Address__1": "",
    "City": "CHEMNITZ",
    "Postcode": 9116,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0371-364046",
    "24 hour?": "FALSE",
    "Latitude": 50.8329,
    "Longitude": 12.8734,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8329,12.8734",
    "IsOpen24Hours": false,
    "id": "50.8329,12.8734"
  },
  {
    "ID": 1971334679,
    "Tankstellennummer": "0FU76",
    "Name": "KIRCHHEIM",
    "Address": "Industriestraße 7a",
    "Address__1": "",
    "City": "Kirchheim",
    "Postcode": 36275,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6625632,
    "24 hour?": "FALSE",
    "Latitude": 50.8333193,
    "Longitude": 9.5703069,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8333193,9.5703069",
    "IsOpen24Hours": false,
    "id": "50.8333193,9.5703069"
  },
  {
    "ID": 105002059,
    "Tankstellennummer": "0FV04",
    "Name": "STADTALLENDORF",
    "Address": "Daimlerstraße 2",
    "Address__1": "",
    "City": "Stadtallendorf",
    "Postcode": 35260,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 64284483606,
    "24 hour?": "TRUE",
    "Latitude": 50.835262,
    "Longitude": 9.038366,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.835262,9.038366",
    "IsOpen24Hours": true,
    "id": "50.835262,9.038366"
  },
  {
    "ID": 1668,
    "Tankstellennummer": "0F675",
    "Name": "Aral Tankstelle Bad Hersfeld, Alsfelder Strasse 12",
    "Address": "Alsfelder Straße 12",
    "Address__1": "",
    "City": "Bad Hersfeld",
    "Postcode": 36251,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 662172294,
    "24 hour?": "FALSE",
    "Latitude": 50.836174,
    "Longitude": 9.668664,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.836174,9.668664",
    "IsOpen24Hours": false,
    "id": "50.836174,9.668664"
  },
  {
    "ID": 1028994119,
    "Tankstellennummer": "TD025981",
    "Name": "CHEMNITZ LIMBACHER STR. 58",
    "Address": "LIMBACHER STR. 58",
    "Address__1": "",
    "City": "CHEMNITZ",
    "Postcode": 9113,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0371-3363873",
    "24 hour?": "FALSE",
    "Latitude": 50.838,
    "Longitude": 12.897,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.838,12.897",
    "IsOpen24Hours": false,
    "id": "50.838,12.897"
  },
  {
    "ID": 1103872863,
    "Tankstellennummer": "TD023358",
    "Name": "ARNSTADT FRIEDRICHSTR. 1",
    "Address": "FRIEDRICHSTR.1",
    "Address__1": "",
    "City": "ARNSTADT",
    "Postcode": 99310,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03628-76218",
    "24 hour?": "FALSE",
    "Latitude": 50.8399,
    "Longitude": 10.9559,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8399,10.9559",
    "IsOpen24Hours": false,
    "id": "50.8399,10.9559"
  },
  {
    "ID": 1663431537,
    "Tankstellennummer": "TD007096",
    "Name": "GLAUCHAU WALDENBURGER STR. 115",
    "Address": "WALDENBURGER STR. 115",
    "Address__1": "",
    "City": "GLAUCHAU",
    "Postcode": 8371,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03763-15022",
    "24 hour?": "FALSE",
    "Latitude": 50.8402,
    "Longitude": 12.5425,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8402,12.5425",
    "IsOpen24Hours": false,
    "id": "50.8402,12.5425"
  },
  {
    "ID": 1109889173,
    "Tankstellennummer": "TD022913",
    "Name": "ARNSTADT ICHTERSHAEUSER STR. 9",
    "Address": "ICHTERSHAEUSER STR. 9",
    "Address__1": "",
    "City": "ARNSTADT",
    "Postcode": 99310,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03628-640862",
    "24 hour?": "FALSE",
    "Latitude": 50.8415,
    "Longitude": 10.9509,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8415,10.9509",
    "IsOpen24Hours": false,
    "id": "50.8415,10.9509"
  },
  {
    "ID": 1894926512,
    "Tankstellennummer": 5090,
    "Name": "Agip Chemnitz",
    "Address": "Dresdner Str. 84",
    "Address__1": "",
    "City": "Chemnitz",
    "Postcode": 9130,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 37140450",
    "24 hour?": "TRUE",
    "Latitude": 50.84159176,
    "Longitude": 12.93563266,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.84159176,12.93563266",
    "IsOpen24Hours": true,
    "id": "50.84159176,12.93563266"
  },
  {
    "ID": 2551,
    "Tankstellennummer": "0FD07",
    "Name": "Aral Tankstelle Gera, Heeresbergstr.9",
    "Address": "Heeresbergstraße 9",
    "Address__1": "",
    "City": "Gera",
    "Postcode": 7549,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 36533022,
    "24 hour?": "TRUE",
    "Latitude": 50.842231,
    "Longitude": 12.076788,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.842231,12.076788",
    "IsOpen24Hours": true,
    "id": "50.842231,12.076788"
  },
  {
    "ID": 10012,
    "Tankstellennummer": "0FM03",
    "Name": "Aral Tankstelle Brühl, Kölnstr. 272",
    "Address": "Kölnstraße 272",
    "Address__1": "",
    "City": "Brühl",
    "Postcode": 50321,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2232411019,
    "24 hour?": "TRUE",
    "Latitude": 50.845304,
    "Longitude": 6.915569,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.845304,6.915569",
    "IsOpen24Hours": true,
    "id": "50.845304,6.915569"
  },
  {
    "ID": 1686,
    "Tankstellennummer": "0F690",
    "Name": "Aral Tankstelle Cölbe, Kasseler Str.21",
    "Address": "Kasseler Straße 21",
    "Address__1": "",
    "City": "Cölbe",
    "Postcode": 35091,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 642182645,
    "24 hour?": "FALSE",
    "Latitude": 50.847007,
    "Longitude": 8.776021,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.847007,8.776021",
    "IsOpen24Hours": false,
    "id": "50.847007,8.776021"
  },
  {
    "ID": 2990,
    "Tankstellennummer": "0FI42",
    "Name": "Aral Tankstelle Ruppichteroth, Broelstr. 29",
    "Address": "Broelstraße 29",
    "Address__1": "",
    "City": "Ruppichteroth",
    "Postcode": 53809,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2295920120,
    "24 hour?": "FALSE",
    "Latitude": 50.847741,
    "Longitude": 7.500641,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.847741,7.500641",
    "IsOpen24Hours": false,
    "id": "50.847741,7.500641"
  },
  {
    "ID": 1893958233,
    "Tankstellennummer": "TD033837",
    "Name": "GERA HEERESBERGSTRASSE 3",
    "Address": "HEERESBERGSTRASSE 3",
    "Address__1": "",
    "City": "GERA",
    "Postcode": 7549,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0365-7106803",
    "24 hour?": "FALSE",
    "Latitude": 50.8483,
    "Longitude": 12.0762,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8483,12.0762",
    "IsOpen24Hours": false,
    "id": "50.8483,12.0762"
  },
  {
    "ID": 112128792,
    "Tankstellennummer": "TD000679",
    "Name": "HERZOGENRATH VOCCARTSTR. 80",
    "Address": "VOCCARTSTR. 80",
    "Address__1": "",
    "City": "HERZOGENRATH",
    "Postcode": 52134,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02406-2480",
    "24 hour?": "FALSE",
    "Latitude": 50.8505,
    "Longitude": 6.0764,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8505,6.0764",
    "IsOpen24Hours": false,
    "id": "50.8505,6.0764"
  },
  {
    "ID": 3013,
    "Tankstellennummer": "0FI59",
    "Name": "Aral Tankstelle Neustadt, Marburger Str. 42",
    "Address": "Marburger Straße 42",
    "Address__1": "",
    "City": "Neustadt",
    "Postcode": 35279,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6692919662,
    "24 hour?": "FALSE",
    "Latitude": 50.851163,
    "Longitude": 9.107086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.851163,9.107086",
    "IsOpen24Hours": false,
    "id": "50.851163,9.107086"
  },
  {
    "ID": 1553766583,
    "Tankstellennummer": "TD038877",
    "Name": "LIMBACH-OBERFROHNA",
    "Address": "HOHENSTEINER STR. 72",
    "Address__1": "",
    "City": "LIMBACH-OBERFROHNA",
    "Postcode": 9212,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03722-5050857",
    "24 hour?": "FALSE",
    "Latitude": 50.8516,
    "Longitude": 12.7748,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8516,12.7748",
    "IsOpen24Hours": false,
    "id": "50.8516,12.7748"
  },
  {
    "ID": 2708,
    "Tankstellennummer": "0FE22",
    "Name": "Aral Tankstelle Chemnitz, Blankenauer Str. 70",
    "Address": "Blankenauer Straße 70",
    "Address__1": "",
    "City": "Chemnitz",
    "Postcode": 9113,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 371449293,
    "24 hour?": "TRUE",
    "Latitude": 50.856329,
    "Longitude": 12.92948,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.856329,12.92948",
    "IsOpen24Hours": true,
    "id": "50.856329,12.92948"
  },
  {
    "ID": 1017357535,
    "Tankstellennummer": "TD038117",
    "Name": "SCHMOELLN BAT ALTENBURGER LAND",
    "Address": "BAB 4 / ALTENBURGER LAND-SUED",
    "Address__1": "",
    "City": "SCHMOELLN OT WEISSBACH",
    "Postcode": 4626,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034491/554813",
    "24 hour?": "FALSE",
    "Latitude": 50.8567,
    "Longitude": 12.3135,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8567,12.3135",
    "IsOpen24Hours": false,
    "id": "50.8567,12.3135"
  },
  {
    "ID": 3389,
    "Tankstellennummer": "0FL30",
    "Name": "Aral Tankstelle Arnstadt, Ichtershaeuser Str. 84",
    "Address": "Ichtershaeuser Straße 84",
    "Address__1": "",
    "City": "Arnstadt",
    "Postcode": 99310,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 362878340,
    "24 hour?": "FALSE",
    "Latitude": 50.856769,
    "Longitude": 10.958926,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.856769,10.958926",
    "IsOpen24Hours": false,
    "id": "50.856769,10.958926"
  },
  {
    "ID": 2631,
    "Tankstellennummer": "0FD56",
    "Name": "Aral Tankstelle Chemnitz, Leipziger Str. 206",
    "Address": "Leipziger Straße 206",
    "Address__1": "",
    "City": "Chemnitz",
    "Postcode": 9114,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 371372415,
    "24 hour?": "TRUE",
    "Latitude": 50.856868,
    "Longitude": 12.875218,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.856868,12.875218",
    "IsOpen24Hours": true,
    "id": "50.856868,12.875218"
  },
  {
    "ID": 1646232812,
    "Tankstellennummer": "TD000270",
    "Name": "ALSDORF-HOENGEN AACHENER STR.",
    "Address": "AACHENER STR. 51",
    "Address__1": "",
    "City": "ALSDORF-HOENGEN",
    "Postcode": 52477,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02404-61353",
    "24 hour?": "FALSE",
    "Latitude": 50.857,
    "Longitude": 6.2022,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.857,6.2022",
    "IsOpen24Hours": false,
    "id": "50.857,6.2022"
  },
  {
    "ID": 1565774703,
    "Tankstellennummer": "0FM56",
    "Name": "HURTH",
    "Address": "A 1",
    "Address__1": "",
    "City": "Hürth",
    "Postcode": 50354,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22371335,
    "24 hour?": "TRUE",
    "Latitude": 50.857101,
    "Longitude": 6.806151,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.857101,6.806151",
    "IsOpen24Hours": true,
    "id": "50.857101,6.806151"
  },
  {
    "ID": 1026785769,
    "Tankstellennummer": "TD035865",
    "Name": "BLANKENHAIN WALDECKER STRASSE",
    "Address": "WALDECKER STRASSE 11",
    "Address__1": "",
    "City": "BLANKENHAIN",
    "Postcode": 99444,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036459-41308",
    "24 hour?": "FALSE",
    "Latitude": 50.8578,
    "Longitude": 11.3502,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8578,11.3502",
    "IsOpen24Hours": false,
    "id": "50.8578,11.3502"
  },
  {
    "ID": 644504096,
    "Tankstellennummer": "TD025254",
    "Name": "BRAND-ERBISDORF LANGENAUER STR",
    "Address": "LANGENAUER STR. 1",
    "Address__1": "",
    "City": "BRAND-ERBISDORF",
    "Postcode": 9618,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037322-2271",
    "24 hour?": "FALSE",
    "Latitude": 50.8579,
    "Longitude": 13.319,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8579,13.319",
    "IsOpen24Hours": false,
    "id": "50.8579,13.319"
  },
  {
    "ID": 3369,
    "Tankstellennummer": "0FL12",
    "Name": "Aral Tankstelle Chemnitz, Leipziger Str. 257",
    "Address": "Leipziger Straße 257",
    "Address__1": "",
    "City": "Chemnitz",
    "Postcode": 9114,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3713301680,
    "24 hour?": "TRUE",
    "Latitude": 50.858133,
    "Longitude": 12.869132,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.858133,12.869132",
    "IsOpen24Hours": true,
    "id": "50.858133,12.869132"
  },
  {
    "ID": 774171815,
    "Tankstellennummer": "TD036855",
    "Name": "BAD HERSFELD CARL-BENZ-STR. 3",
    "Address": "CARL-BENZ-STR. 3",
    "Address__1": "",
    "City": "BAD HERSFELD",
    "Postcode": 36251,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06621-919744",
    "24 hour?": "FALSE",
    "Latitude": 50.8582,
    "Longitude": 9.7227,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8582,9.7227",
    "IsOpen24Hours": false,
    "id": "50.8582,9.7227"
  },
  {
    "ID": 2031258534,
    "Tankstellennummer": "TD000614",
    "Name": "KLINGENBERG DRESDNER STR. 1A",
    "Address": "DRESDNER STR. 1a",
    "Address__1": "",
    "City": "KLINGENBERG OT PRETZSCHENDORF",
    "Postcode": 1774,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035058-41252",
    "24 hour?": "FALSE",
    "Latitude": 50.8619,
    "Longitude": 13.5368,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8619,13.5368",
    "IsOpen24Hours": false,
    "id": "50.8619,13.5368"
  },
  {
    "ID": 833554841,
    "Tankstellennummer": "0FO95",
    "Name": "NETPHEN",
    "Address": "Wetzlarer Straße 32",
    "Address__1": "",
    "City": "Netphen",
    "Postcode": 57250,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27372185959,
    "24 hour?": "FALSE",
    "Latitude": 50.862037,
    "Longitude": 8.200172,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.862037,8.200172",
    "IsOpen24Hours": false,
    "id": "50.862037,8.200172"
  },
  {
    "ID": 1649268826,
    "Tankstellennummer": "0FO91",
    "Name": "NIEDERFISCHBACH",
    "Address": "Konrad-Adenauer-Straße 192",
    "Address__1": "",
    "City": "Niederfischbach",
    "Postcode": 57572,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2734571767,
    "24 hour?": "FALSE",
    "Latitude": 50.862115,
    "Longitude": 7.885619,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.862115,7.885619",
    "IsOpen24Hours": false,
    "id": "50.862115,7.885619"
  },
  {
    "ID": 864037051,
    "Tankstellennummer": "0FP13",
    "Name": "BAD HERSFELD",
    "Address": "An der Haune 1 a",
    "Address__1": "",
    "City": "Bad Hersfeld",
    "Postcode": 36251,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 66217940447,
    "24 hour?": "TRUE",
    "Latitude": 50.862171,
    "Longitude": 9.722813,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.862171,9.722813",
    "IsOpen24Hours": true,
    "id": "50.862171,9.722813"
  },
  {
    "ID": 865285119,
    "Tankstellennummer": "TD025379",
    "Name": "OEDERAN FREIBERGER STR. 72",
    "Address": "FREIBERGER STR. 72",
    "Address__1": "",
    "City": "OEDERAN",
    "Postcode": 9569,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037292-60273",
    "24 hour?": "FALSE",
    "Latitude": 50.8649,
    "Longitude": 13.1789,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8649,13.1789",
    "IsOpen24Hours": false,
    "id": "50.8649,13.1789"
  },
  {
    "ID": 30627165,
    "Tankstellennummer": 610374,
    "Name": "WESTFALEN, NIEDERZIER",
    "Address": "Römerstr. 17",
    "Address__1": "",
    "City": "Niederzier",
    "Postcode": 52382,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02428/3000",
    "24 hour?": "FALSE",
    "Latitude": 50.866,
    "Longitude": 6.43456,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.866,6.43456",
    "IsOpen24Hours": false,
    "id": "50.866,6.43456"
  },
  {
    "ID": 2092,
    "Tankstellennummer": "0F967",
    "Name": "Aral Tankstelle Niederzier, Römerstr.30",
    "Address": "Römerstraße 30",
    "Address__1": "",
    "City": "Niederzier",
    "Postcode": 52382,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24283021,
    "24 hour?": "TRUE",
    "Latitude": 50.866646,
    "Longitude": 6.434919,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.866646,6.434919",
    "IsOpen24Hours": true,
    "id": "50.866646,6.434919"
  },
  {
    "ID": 2987,
    "Tankstellennummer": "0FI39",
    "Name": "Aral Tankstelle Morsbach, Waldbroelerstr. 61",
    "Address": "Waldbrölerstraße 61",
    "Address__1": "",
    "City": "Morsbach",
    "Postcode": 51597,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22948197,
    "24 hour?": "FALSE",
    "Latitude": 50.868476,
    "Longitude": 7.726046,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.868476,7.726046",
    "IsOpen24Hours": false,
    "id": "50.868476,7.726046"
  },
  {
    "ID": 740170552,
    "Tankstellennummer": "0FP71",
    "Name": "RONNEBURG",
    "Address": "An der B 7",
    "Address__1": "",
    "City": "Ronneburg",
    "Postcode": 7580,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 36602519387,
    "24 hour?": "TRUE",
    "Latitude": 50.868725,
    "Longitude": 12.216167,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.868725,12.216167",
    "IsOpen24Hours": true,
    "id": "50.868725,12.216167"
  },
  {
    "ID": 842878249,
    "Tankstellennummer": 4218,
    "Name": "Agip Lohmar",
    "Address": "Peisel",
    "Address__1": "",
    "City": "Lohmar",
    "Postcode": 53797,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 22469126",
    "24 hour?": "FALSE",
    "Latitude": 50.86883,
    "Longitude": 7.25244,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.86883,7.25244",
    "IsOpen24Hours": false,
    "id": "50.86883,7.25244"
  },
  {
    "ID": 2087,
    "Tankstellennummer": "0F962",
    "Name": "Aral Tankstelle Köln, Kölnstr. 1",
    "Address": "Kölnstraße 1",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50999,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2236962590,
    "24 hour?": "TRUE",
    "Latitude": 50.869795,
    "Longitude": 7.005583,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.869795,7.005583",
    "IsOpen24Hours": true,
    "id": "50.869795,7.005583"
  },
  {
    "ID": 62916004,
    "Tankstellennummer": 610009,
    "Name": "WESTFALEN, KERPEN - T?nich",
    "Address": "Heisenbergstr. 2a",
    "Address__1": "",
    "City": "Kerpen",
    "Postcode": 50169,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02237/657731",
    "24 hour?": "TRUE",
    "Latitude": 50.8699,
    "Longitude": 6.75738,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.8699,6.75738",
    "IsOpen24Hours": true,
    "id": "50.8699,6.75738"
  },
  {
    "ID": 1237,
    "Tankstellennummer": "0F344",
    "Name": "Aral Tankstelle Siegen, Koblenzer Str. 82",
    "Address": "Koblenzer Straße 82",
    "Address__1": "",
    "City": "Siegen",
    "Postcode": 57072,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 271331480,
    "24 hour?": "TRUE",
    "Latitude": 50.870854,
    "Longitude": 8.014519,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.870854,8.014519",
    "IsOpen24Hours": true,
    "id": "50.870854,8.014519"
  },
  {
    "ID": 3012,
    "Tankstellennummer": "0FI58",
    "Name": "Aral Tankstelle Neukirchen, Niederrheinische Str. 27",
    "Address": "Niederrheinische Str. 27",
    "Address__1": "",
    "City": "Neukirchen",
    "Postcode": 34626,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 66947878,
    "24 hour?": "FALSE",
    "Latitude": 50.871994,
    "Longitude": 9.337172,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.871994,9.337172",
    "IsOpen24Hours": false,
    "id": "50.871994,9.337172"
  },
  {
    "ID": 1095628240,
    "Tankstellennummer": "TD123980",
    "Name": "BAD HERSFELD HOMBERGER STR. 5",
    "Address": "HOMBERGER STR. 5",
    "Address__1": "",
    "City": "BAD HERSFELD",
    "Postcode": 36251,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06621-917756",
    "24 hour?": "FALSE",
    "Latitude": 50.8742,
    "Longitude": 9.7051,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8742,9.7051",
    "IsOpen24Hours": false,
    "id": "50.8742,9.7051"
  },
  {
    "ID": 319997301,
    "Tankstellennummer": "0FQ01",
    "Name": "ALSDORF",
    "Address": "Luisenstraße 77",
    "Address__1": "",
    "City": "Alsdorf",
    "Postcode": 52477,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24046777544,
    "24 hour?": "TRUE",
    "Latitude": 50.874326,
    "Longitude": 6.169732,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.874326,6.169732",
    "IsOpen24Hours": true,
    "id": "50.874326,6.169732"
  },
  {
    "ID": 282840511,
    "Tankstellennummer": "TD000966",
    "Name": "KOELN RONDORF KAPELLENSTR. 29",
    "Address": "KAPELLENSTR. 29",
    "Address__1": "",
    "City": "KOELN RONDORF",
    "Postcode": 50997,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02233-23640",
    "24 hour?": "FALSE",
    "Latitude": 50.8745,
    "Longitude": 6.9498,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8745,6.9498",
    "IsOpen24Hours": false,
    "id": "50.8745,6.9498"
  },
  {
    "ID": 1195,
    "Tankstellennummer": "0F309",
    "Name": "Aral Tankstelle Siegen, Marienborner Str. 173",
    "Address": "Marienborner Straße 173",
    "Address__1": "",
    "City": "Siegen",
    "Postcode": 57074,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27162061,
    "24 hour?": "FALSE",
    "Latitude": 50.876316,
    "Longitude": 8.037726,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.876316,8.037726",
    "IsOpen24Hours": false,
    "id": "50.876316,8.037726"
  },
  {
    "ID": 327332228,
    "Tankstellennummer": "TD124090",
    "Name": "SIEGEN MARIENBORNER STR. 204",
    "Address": "MARIENBORNER STR.204",
    "Address__1": "",
    "City": "SIEGEN",
    "Postcode": 57074,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0271-62105",
    "24 hour?": "FALSE",
    "Latitude": 50.8764,
    "Longitude": 8.0396,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8764,8.0396",
    "IsOpen24Hours": false,
    "id": "50.8764,8.0396"
  },
  {
    "ID": 381845780,
    "Tankstellennummer": "TD042101",
    "Name": "FREUDENBERG, SIEGERLAND OST /A",
    "Address": "SIEGERLAND OST /A45",
    "Address__1": "",
    "City": "FREUDENBERG",
    "Postcode": 57258,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02734-5471",
    "24 hour?": "FALSE",
    "Latitude": 50.8769,
    "Longitude": 7.9482,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8769,7.9482",
    "IsOpen24Hours": false,
    "id": "50.8769,7.9482"
  },
  {
    "ID": 2542,
    "Tankstellennummer": "0FC84",
    "Name": "Aral Tankstelle Jena-Lobeda, Stadtrodaer Str. 102 / B",
    "Address": "Stadtrodaer Straße 102",
    "Address__1": "",
    "City": "Jena",
    "Postcode": 7747,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3641334142,
    "24 hour?": "TRUE",
    "Latitude": 50.877387,
    "Longitude": 11.621981,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.877387,11.621981",
    "IsOpen24Hours": true,
    "id": "50.877387,11.621981"
  },
  {
    "ID": 765272334,
    "Tankstellennummer": "TD025361",
    "Name": "HARTMANNSDORF CHEMNITZER STR.",
    "Address": "CHEMNITZER STR. 38 B",
    "Address__1": "",
    "City": "HARTMANNSDORF",
    "Postcode": 9232,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03722-96464",
    "24 hour?": "FALSE",
    "Latitude": 50.8785,
    "Longitude": 12.8218,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8785,12.8218",
    "IsOpen24Hours": false,
    "id": "50.8785,12.8218"
  },
  {
    "ID": 965,
    "Tankstellennummer": "0F154",
    "Name": "Aral Tankstelle Siegen, Sandstr. 126",
    "Address": "Sandstraße 126",
    "Address__1": "",
    "City": "Siegen",
    "Postcode": 57072,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27124660,
    "24 hour?": "FALSE",
    "Latitude": 50.880257,
    "Longitude": 8.026544,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.880257,8.026544",
    "IsOpen24Hours": false,
    "id": "50.880257,8.026544"
  },
  {
    "ID": 529932422,
    "Tankstellennummer": "TD041343",
    "Name": "KERPEN SINDORFER STR. 65",
    "Address": "SINDORFER STR. 65",
    "Address__1": "",
    "City": "KERPEN",
    "Postcode": 50171,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 22375639966,
    "24 hour?": "FALSE",
    "Latitude": 50.8804,
    "Longitude": 6.6943,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8804,6.6943",
    "IsOpen24Hours": false,
    "id": "50.8804,6.6943"
  },
  {
    "ID": 1325,
    "Tankstellennummer": "0F418",
    "Name": "Aral Autobahntankstelle Freudenberg, Siegerland West (A 45)",
    "Address": "A45",
    "Address__1": "",
    "City": "Freudenberg",
    "Postcode": 57258,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2734495780,
    "24 hour?": "TRUE",
    "Latitude": 50.881733,
    "Longitude": 7.9341,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.881733,7.9341",
    "IsOpen24Hours": true,
    "id": "50.881733,7.9341"
  },
  {
    "ID": 408215460,
    "Tankstellennummer": "TD039982",
    "Name": "KOELN NORDALLEE 2",
    "Address": "NORDALLEE 2",
    "Address__1": "",
    "City": "KOELN",
    "Postcode": 51147,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02203-591642",
    "24 hour?": "FALSE",
    "Latitude": 50.8818,
    "Longitude": 7.1131,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8818,7.1131",
    "IsOpen24Hours": false,
    "id": "50.8818,7.1131"
  },
  {
    "ID": 1630317692,
    "Tankstellennummer": 610010,
    "Name": "WESTFALEN, KERPEN II",
    "Address": "Sindorfer Str. 52",
    "Address__1": "",
    "City": "Kerpen",
    "Postcode": 50171,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02237/9285641",
    "24 hour?": "FALSE",
    "Latitude": 50.882426,
    "Longitude": 6.694047,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.882426,6.694047",
    "IsOpen24Hours": false,
    "id": "50.882426,6.694047"
  },
  {
    "ID": 1945279688,
    "Tankstellennummer": 4736,
    "Name": "Agip Friedewald",
    "Address": "Herfaer Str. 4",
    "Address__1": "",
    "City": "Friedewald",
    "Postcode": 36289,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 66749191",
    "24 hour?": "FALSE",
    "Latitude": 50.8839,
    "Longitude": 9.866,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8839,9.866",
    "IsOpen24Hours": false,
    "id": "50.8839,9.866"
  },
  {
    "ID": 155497104,
    "Tankstellennummer": "TD000926",
    "Name": "ROESRATH O.-V.-NELL-BREUNING 4",
    "Address": "O.-v.-NELL-BREUNING 4",
    "Address__1": "",
    "City": "ROESRATH",
    "Postcode": 51503,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02205-899802",
    "24 hour?": "FALSE",
    "Latitude": 50.8844,
    "Longitude": 7.1864,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8844,7.1864",
    "IsOpen24Hours": false,
    "id": "50.8844,7.1864"
  },
  {
    "ID": 389490549,
    "Tankstellennummer": 4742,
    "Name": "Agip Heringen",
    "Address": "Woelfershaeuser Strasse 49",
    "Address__1": "",
    "City": "Heringen",
    "Postcode": 36266,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 66241703",
    "24 hour?": "FALSE",
    "Latitude": 50.884966,
    "Longitude": 9.987643,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.884966,9.987643",
    "IsOpen24Hours": false,
    "id": "50.884966,9.987643"
  },
  {
    "ID": 2765,
    "Tankstellennummer": "0FE57",
    "Name": "Aral Tankstelle Dippoldiswalde, Altenberger Str. 40 a",
    "Address": "Alte Altenberger Str. 40A",
    "Address__1": "",
    "City": "Dippoldiswalde",
    "Postcode": 1744,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3504612652,
    "24 hour?": "TRUE",
    "Latitude": 50.886876,
    "Longitude": 13.670973,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.886876,13.670973",
    "IsOpen24Hours": true,
    "id": "50.886876,13.670973"
  },
  {
    "ID": 1943069397,
    "Tankstellennummer": "TD000850",
    "Name": "HERZOGENRATH GEILENKIRCHENER S",
    "Address": "GEILENKIRCHENER STR 414",
    "Address__1": "",
    "City": "HERZOGENRATH",
    "Postcode": 52134,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02406-669141",
    "24 hour?": "FALSE",
    "Latitude": 50.8869,
    "Longitude": 6.1209,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8869,6.1209",
    "IsOpen24Hours": false,
    "id": "50.8869,6.1209"
  },
  {
    "ID": 1988309865,
    "Tankstellennummer": 4741,
    "Name": "Agip Philippstal",
    "Address": "Eisenacher Str. 15",
    "Address__1": "",
    "City": "Philippstal",
    "Postcode": 36266,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 6620425",
    "24 hour?": "FALSE",
    "Latitude": 50.887795,
    "Longitude": 9.922655,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.887795,9.922655",
    "IsOpen24Hours": false,
    "id": "50.887795,9.922655"
  },
  {
    "ID": 316928661,
    "Tankstellennummer": "0FR07",
    "Name": "HERMSDORF",
    "Address": "Kraftsdorfer Straße 2",
    "Address__1": "",
    "City": "Hermsdorf",
    "Postcode": 7629,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3660154012,
    "24 hour?": "TRUE",
    "Latitude": 50.889295,
    "Longitude": 11.871518,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.889295,11.871518",
    "IsOpen24Hours": true,
    "id": "50.889295,11.871518"
  },
  {
    "ID": 1650,
    "Tankstellennummer": "0F666",
    "Name": "Aral Tankstelle Breidenbach, Hauptstrasse 85",
    "Address": "Hauptstraße 85",
    "Address__1": "",
    "City": "Breidenbach",
    "Postcode": 35236,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 6465913004,
    "24 hour?": "FALSE",
    "Latitude": 50.890115,
    "Longitude": 8.45321,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.890115,8.45321",
    "IsOpen24Hours": false,
    "id": "50.890115,8.45321"
  },
  {
    "ID": 1398861761,
    "Tankstellennummer": "0FO98",
    "Name": "WALDBRÖL",
    "Address": "Friedrich-Engels-Straße 2",
    "Address__1": "",
    "City": "Waldbröl",
    "Postcode": 51545,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22912244,
    "24 hour?": "TRUE",
    "Latitude": 50.89057,
    "Longitude": 7.647413,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.89057,7.647413",
    "IsOpen24Hours": true,
    "id": "50.89057,7.647413"
  },
  {
    "ID": 2155,
    "Tankstellennummer": "0FA13",
    "Name": "Aral Tankstelle Köln, Bergerstr. 108",
    "Address": "Bergerstraße 108",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51145,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 220332882,
    "24 hour?": "FALSE",
    "Latitude": 50.890974,
    "Longitude": 7.06785,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.890974,7.06785",
    "IsOpen24Hours": false,
    "id": "50.890974,7.06785"
  },
  {
    "ID": 745217677,
    "Tankstellennummer": "TD032631",
    "Name": "GERA LEIBNIZSTR.",
    "Address": "LEIBNIZSTR. 74",
    "Address__1": "",
    "City": "GERA",
    "Postcode": 7548,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 36520519740,
    "24 hour?": "FALSE",
    "Latitude": 50.8911,
    "Longitude": 12.0686,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8911,12.0686",
    "IsOpen24Hours": false,
    "id": "50.8911,12.0686"
  },
  {
    "ID": 2063,
    "Tankstellennummer": "0F940",
    "Name": "Aral Autohof Köln, Am Verteilerkreis Westseite",
    "Address": "Am Verteilerkreis Westsei",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50968,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221381718,
    "24 hour?": "TRUE",
    "Latitude": 50.892089,
    "Longitude": 6.966327,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.892089,6.966327",
    "IsOpen24Hours": true,
    "id": "50.892089,6.966327"
  },
  {
    "ID": 298610841,
    "Tankstellennummer": 610362,
    "Name": "WESTFALEN, ALDENHOVEN",
    "Address": "Am alten Bahnhof",
    "Address__1": "",
    "City": "Aldenhoven",
    "Postcode": 52457,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02464/1828",
    "24 hour?": "FALSE",
    "Latitude": 50.8921,
    "Longitude": 6.27447,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8921,6.27447",
    "IsOpen24Hours": false,
    "id": "50.8921,6.27447"
  },
  {
    "ID": 2752,
    "Tankstellennummer": "0FG13",
    "Name": "Aral Autobahntankstelle Auerswalde, Auerswalde Süd (A 4)",
    "Address": "A4",
    "Address__1": "",
    "City": "Oberlichtenau",
    "Postcode": 9244,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3720883523,
    "24 hour?": "TRUE",
    "Latitude": 50.893469,
    "Longitude": 12.94599,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.893469,12.94599",
    "IsOpen24Hours": true,
    "id": "50.893469,12.94599"
  },
  {
    "ID": 3359,
    "Tankstellennummer": "0FL04",
    "Name": "Aral Tankstelle Zittau, Theodor-Koerner-Allee 2",
    "Address": "Theodor-Koerner-Allee 2",
    "Address__1": "",
    "City": "Zittau",
    "Postcode": 2763,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3583510275,
    "24 hour?": "TRUE",
    "Latitude": 50.893853,
    "Longitude": 14.80181,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.893853,14.80181",
    "IsOpen24Hours": true,
    "id": "50.893853,14.80181"
  },
  {
    "ID": 585092109,
    "Tankstellennummer": "TD024406",
    "Name": "SCHMOELLN RONNEBURGER STR. 108",
    "Address": "RONNEBURGER STR. 108",
    "Address__1": "",
    "City": "SCHMOELLN",
    "Postcode": 4626,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034491-82726",
    "24 hour?": "FALSE",
    "Latitude": 50.8943,
    "Longitude": 12.3387,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.8943,12.3387",
    "IsOpen24Hours": false,
    "id": "50.8943,12.3387"
  },
  {
    "ID": 2056,
    "Tankstellennummer": "0F936",
    "Name": "Aral Tankstelle Hürth, Luxemburger Str. 58",
    "Address": "Luxemburger Straße 58",
    "Address__1": "",
    "City": "Hürth",
    "Postcode": 50354,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 223365260,
    "24 hour?": "TRUE",
    "Latitude": 50.894856,
    "Longitude": 6.905027,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.894856,6.905027",
    "IsOpen24Hours": true,
    "id": "50.894856,6.905027"
  },
  {
    "ID": 2098,
    "Tankstellennummer": "0F972",
    "Name": "Aral Tankstelle Kerpen, Kerpener Str.  185",
    "Address": "Kerpener Straße  185",
    "Address__1": "",
    "City": "Kerpen",
    "Postcode": 50170,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 227351765,
    "24 hour?": "TRUE",
    "Latitude": 50.897337,
    "Longitude": 6.683757,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.897337,6.683757",
    "IsOpen24Hours": true,
    "id": "50.897337,6.683757"
  },
  {
    "ID": 1033846520,
    "Tankstellennummer": "TD024307",
    "Name": "JENA GOESCHWITZER STR. 1",
    "Address": "GOESCHWITZER STR. 1",
    "Address__1": "",
    "City": "JENA",
    "Postcode": 7745,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03641-215080",
    "24 hour?": "FALSE",
    "Latitude": 50.898,
    "Longitude": 11.5934,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.898,11.5934",
    "IsOpen24Hours": false,
    "id": "50.898,11.5934"
  },
  {
    "ID": 1370632364,
    "Tankstellennummer": "0FN70",
    "Name": "MUHLAU",
    "Address": "Lindenstraße 2",
    "Address__1": "",
    "City": "Mühlau",
    "Postcode": 9241,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 37226157,
    "24 hour?": "FALSE",
    "Latitude": 50.898567,
    "Longitude": 12.774261,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.898567,12.774261",
    "IsOpen24Hours": false,
    "id": "50.898567,12.774261"
  },
  {
    "ID": 1232,
    "Tankstellennummer": "0F339",
    "Name": "Aral Tankstelle Siegen, Siegstr. 43",
    "Address": "Siegstraße 43",
    "Address__1": "",
    "City": "Siegen",
    "Postcode": 57076,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27173043,
    "24 hour?": "FALSE",
    "Latitude": 50.89991,
    "Longitude": 8.034391,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.89991,8.034391",
    "IsOpen24Hours": false,
    "id": "50.89991,8.034391"
  },
  {
    "ID": 645067108,
    "Tankstellennummer": "TD000869",
    "Name": "KOELN BONNER STR. 417-425",
    "Address": "BONNER STR. 417-425",
    "Address__1": "",
    "City": "KOELN",
    "Postcode": 50968,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0221-372137",
    "24 hour?": "FALSE",
    "Latitude": 50.9009,
    "Longitude": 6.9657,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9009,6.9657",
    "IsOpen24Hours": false,
    "id": "50.9009,6.9657"
  },
  {
    "ID": 1375,
    "Tankstellennummer": "0F459",
    "Name": "Aral Tankstelle Wetter, Marburger Str. 61",
    "Address": "Marburger Straße 61",
    "Address__1": "",
    "City": "Wetter",
    "Postcode": 35083,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 642392110,
    "24 hour?": "FALSE",
    "Latitude": 50.900984,
    "Longitude": 8.736279,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.900984,8.736279",
    "IsOpen24Hours": false,
    "id": "50.900984,8.736279"
  },
  {
    "ID": 1371,
    "Tankstellennummer": "0F456",
    "Name": "Aral Tankstelle Freudenberg, Siegener Str. 449",
    "Address": "Siegener Straße 449",
    "Address__1": "",
    "City": "Freudenberg - Linden",
    "Postcode": 57258,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27347192,
    "24 hour?": "FALSE",
    "Latitude": 50.901309,
    "Longitude": 7.92667,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.901309,7.92667",
    "IsOpen24Hours": false,
    "id": "50.901309,7.92667"
  },
  {
    "ID": 1314002415,
    "Tankstellennummer": "TD022921",
    "Name": "WALTERSHAUSEN GOTHAER STR. 9",
    "Address": "GOTHAER STR.9",
    "Address__1": "",
    "City": "WALTERSHAUSEN",
    "Postcode": 99880,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03622-68444",
    "24 hour?": "FALSE",
    "Latitude": 50.9018,
    "Longitude": 10.5665,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9018,10.5665",
    "IsOpen24Hours": false,
    "id": "50.9018,10.5665"
  },
  {
    "ID": 2685,
    "Tankstellennummer": "0FE05",
    "Name": "Aral Tankstelle Freiberg, Brander Str. 55",
    "Address": "Brander Straße 55",
    "Address__1": "",
    "City": "Freiberg",
    "Postcode": 9599,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3731767710,
    "24 hour?": "FALSE",
    "Latitude": 50.903164,
    "Longitude": 13.337929,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.903164,13.337929",
    "IsOpen24Hours": false,
    "id": "50.903164,13.337929"
  },
  {
    "ID": 3296,
    "Tankstellennummer": "0FK56",
    "Name": "Aral Tankstelle Köln, Kölner Str. 255",
    "Address": "Kölner Straße 255",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51149,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 220317951,
    "24 hour?": "TRUE",
    "Latitude": 50.903592,
    "Longitude": 7.020729,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.903592,7.020729",
    "IsOpen24Hours": true,
    "id": "50.903592,7.020729"
  },
  {
    "ID": 246554706,
    "Tankstellennummer": "TD023309",
    "Name": "BAD BERKA WEIMARISCHE STR. 31",
    "Address": "WEIMARISCHE STR. 31",
    "Address__1": "",
    "City": "BAD BERKA",
    "Postcode": 99438,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036458-42107",
    "24 hour?": "FALSE",
    "Latitude": 50.9038,
    "Longitude": 11.2789,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9038,11.2789",
    "IsOpen24Hours": false,
    "id": "50.9038,11.2789"
  },
  {
    "ID": 2552,
    "Tankstellennummer": "0FD08",
    "Name": "Aral Tankstelle Bad Berka, Weimarische Str. 26",
    "Address": "Weimarische Straße 34",
    "Address__1": "",
    "City": "Bad Berka",
    "Postcode": 99438,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3645841155,
    "24 hour?": "FALSE",
    "Latitude": 50.904459,
    "Longitude": 11.278268,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.904459,11.278268",
    "IsOpen24Hours": false,
    "id": "50.904459,11.278268"
  },
  {
    "ID": 1562241654,
    "Tankstellennummer": "TD024265",
    "Name": "HERMSDORF EISENBERGER STR. 89",
    "Address": "EISENBERGER STR. 89",
    "Address__1": "",
    "City": "HERMSDORF",
    "Postcode": 7629,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036601-82688",
    "24 hour?": "FALSE",
    "Latitude": 50.9047,
    "Longitude": 11.8573,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9047,11.8573",
    "IsOpen24Hours": false,
    "id": "50.9047,11.8573"
  },
  {
    "ID": 2988,
    "Tankstellennummer": "0FI40",
    "Name": "Aral Tankstelle Much, Marienfelder Str. 4",
    "Address": "Marienfelder Straße 2",
    "Address__1": "",
    "City": "Much",
    "Postcode": 53804,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22455580,
    "24 hour?": "FALSE",
    "Latitude": 50.90519,
    "Longitude": 7.411584,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.90519,7.411584",
    "IsOpen24Hours": false,
    "id": "50.90519,7.411584"
  },
  {
    "ID": 2170,
    "Tankstellennummer": "0FA25",
    "Name": "Aral Tankstelle Köln, Zollstockgürtel 39",
    "Address": "Zollstockgürtel 39",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50969,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221364366,
    "24 hour?": "TRUE",
    "Latitude": 50.905784,
    "Longitude": 6.937863,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.905784,6.937863",
    "IsOpen24Hours": true,
    "id": "50.905784,6.937863"
  },
  {
    "ID": 3388,
    "Tankstellennummer": "0FL29",
    "Name": "Aral Tankstelle Jena, Stadtrodaer Str. 11",
    "Address": "Stadtrodaer Straße 11",
    "Address__1": "",
    "City": "Jena",
    "Postcode": 7749,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3641394893,
    "24 hour?": "TRUE",
    "Latitude": 50.906034,
    "Longitude": 11.589875,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.906034,11.589875",
    "IsOpen24Hours": true,
    "id": "50.906034,11.589875"
  },
  {
    "ID": 48116668,
    "Tankstellennummer": "TD025411",
    "Name": "FREIBERG KLEINSCHIRMAER STR. 1",
    "Address": "KLEINSCHIRMAER STR. 1",
    "Address__1": "",
    "City": "FREIBERG",
    "Postcode": 9599,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03731-76126",
    "24 hour?": "FALSE",
    "Latitude": 50.9062,
    "Longitude": 13.312,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9062,13.312",
    "IsOpen24Hours": false,
    "id": "50.9062,13.312"
  },
  {
    "ID": 2144482023,
    "Tankstellennummer": "TD033761",
    "Name": "FRANKENBERG AEUSSERE CHEMNITZE",
    "Address": "AEUSSERE CHEMNITZER STR. 61",
    "Address__1": "",
    "City": "FRANKENBERG",
    "Postcode": 9669,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037206-81070",
    "24 hour?": "FALSE",
    "Latitude": 50.9064,
    "Longitude": 13.0218,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9064,13.0218",
    "IsOpen24Hours": false,
    "id": "50.9064,13.0218"
  },
  {
    "ID": 330313107,
    "Tankstellennummer": "0FL07",
    "Name": "Aral TankStelle Gera, Siemensstraße 57",
    "Address": "Siemensstraße 57",
    "Address__1": "",
    "City": "Gera",
    "Postcode": 7546,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 365415045,
    "24 hour?": "TRUE",
    "Latitude": 50.907794,
    "Longitude": 12.06653,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.907794,12.06653",
    "IsOpen24Hours": true,
    "id": "50.907794,12.06653"
  },
  {
    "ID": 1029578108,
    "Tankstellennummer": "0FO55",
    "Name": "FRECHEN",
    "Address": "Kölner Straße 36-40",
    "Address__1": "",
    "City": "Frechen",
    "Postcode": 50226,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 223414646,
    "24 hour?": "TRUE",
    "Latitude": 50.910591,
    "Longitude": 6.817882,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.910591,6.817882",
    "IsOpen24Hours": true,
    "id": "50.910591,6.817882"
  },
  {
    "ID": 2948,
    "Tankstellennummer": "0FI08",
    "Name": "Aral Tankstelle Kerpen-Sindorf, Erftstrasse 127",
    "Address": "Erftstraße 127",
    "Address__1": "",
    "City": "Kerpen-Sindorf",
    "Postcode": 50170,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2273570436,
    "24 hour?": "TRUE",
    "Latitude": 50.911672,
    "Longitude": 6.683405,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.911672,6.683405",
    "IsOpen24Hours": true,
    "id": "50.911672,6.683405"
  },
  {
    "ID": 2710,
    "Tankstellennummer": "0FE24",
    "Name": "Aral Autohof Gera, Siemensstr. 58",
    "Address": "Siemensstraße 58",
    "Address__1": "",
    "City": "Gera",
    "Postcode": 7546,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 36577349500,
    "24 hour?": "TRUE",
    "Latitude": 50.912259,
    "Longitude": 12.064824,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.912259,12.064824",
    "IsOpen24Hours": true,
    "id": "50.912259,12.064824"
  },
  {
    "ID": 1012047632,
    "Tankstellennummer": "TD040287",
    "Name": "SIEGEN WENSCHTSTR. 7",
    "Address": "WENSCHTSTR. 7",
    "Address__1": "",
    "City": "SIEGEN",
    "Postcode": 57078,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0271-49939325",
    "24 hour?": "FALSE",
    "Latitude": 50.9125,
    "Longitude": 8.0074,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9125,8.0074",
    "IsOpen24Hours": false,
    "id": "50.9125,8.0074"
  },
  {
    "ID": 2104455743,
    "Tankstellennummer": "TD000935",
    "Name": "KOELN SIEGBURGER STR.490-492",
    "Address": "SIEGBURGER STR.490-492",
    "Address__1": "",
    "City": "KOELN",
    "Postcode": 51105,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0221-834992",
    "24 hour?": "FALSE",
    "Latitude": 50.9132,
    "Longitude": 6.9976,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9132,6.9976",
    "IsOpen24Hours": false,
    "id": "50.9132,6.9976"
  },
  {
    "ID": 1366,
    "Tankstellennummer": "0F451",
    "Name": "Aral Tankstelle Köln, Alter Deutzer Postweg 102",
    "Address": "Alter Deutzer Postweg 102",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51149,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2203371622,
    "24 hour?": "TRUE",
    "Latitude": 50.918621,
    "Longitude": 7.049332,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.918621,7.049332",
    "IsOpen24Hours": true,
    "id": "50.918621,7.049332"
  },
  {
    "ID": 1054622067,
    "Tankstellennummer": 610497,
    "Name": "WESTFALEN, KOELN",
    "Address": "Rolshoverstr. 420",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51105,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0221/8301240",
    "24 hour?": "FALSE",
    "Latitude": 50.9205,
    "Longitude": 6.99859,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9205,6.99859",
    "IsOpen24Hours": false,
    "id": "50.9205,6.99859"
  },
  {
    "ID": 1112656980,
    "Tankstellennummer": "TD035816",
    "Name": "LAUCHA GEWERBESTR. 4",
    "Address": "GEWERBESTR. 4",
    "Address__1": "",
    "City": "LAUCHA",
    "Postcode": 99880,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03622-440211",
    "24 hour?": "FALSE",
    "Latitude": 50.9225,
    "Longitude": 10.5522,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9225,10.5522",
    "IsOpen24Hours": false,
    "id": "50.9225,10.5522"
  },
  {
    "ID": 2169,
    "Tankstellennummer": "0FA24",
    "Name": "Aral Tankstelle Köln, Siegburger Str. 116",
    "Address": "Siegburger Straße 116A",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50679,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221812107,
    "24 hour?": "TRUE",
    "Latitude": 50.922994,
    "Longitude": 6.980695,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.922994,6.980695",
    "IsOpen24Hours": true,
    "id": "50.922994,6.980695"
  },
  {
    "ID": 33645740,
    "Tankstellennummer": "0FS20",
    "Name": "KÖLN",
    "Address": "Dürener Straße 407",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50858,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22117092660,
    "24 hour?": "FALSE",
    "Latitude": 50.923318,
    "Longitude": 6.869527,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.923318,6.869527",
    "IsOpen24Hours": false,
    "id": "50.923318,6.869527"
  },
  {
    "ID": 2772,
    "Tankstellennummer": "0FE64",
    "Name": "Aral Tankstelle Rathmannsdorf, Elbstr. 8",
    "Address": "Elbstraße 8",
    "Address__1": "",
    "City": "Rathmannsdorf",
    "Postcode": 1814,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3502243088,
    "24 hour?": "FALSE",
    "Latitude": 50.923519,
    "Longitude": 14.134711,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.923519,14.134711",
    "IsOpen24Hours": false,
    "id": "50.923519,14.134711"
  },
  {
    "ID": 2083,
    "Tankstellennummer": "0F959",
    "Name": "Aral Tankstelle Köln, Rösrather Str. 521B",
    "Address": "Rösrather Straße 521B/ Lü",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51107,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221868077,
    "24 hour?": "FALSE",
    "Latitude": 50.923607,
    "Longitude": 7.079232,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.923607,7.079232",
    "IsOpen24Hours": false,
    "id": "50.923607,7.079232"
  },
  {
    "ID": 1358,
    "Tankstellennummer": "0F444",
    "Name": "Aral Tankstelle Kerpen, Hauptstr. 2",
    "Address": "Hauptstraße 2",
    "Address__1": "",
    "City": "Kerpen",
    "Postcode": 50169,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22731002,
    "24 hour?": "TRUE",
    "Latitude": 50.92425,
    "Longitude": 6.705449,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.92425,6.705449",
    "IsOpen24Hours": true,
    "id": "50.92425,6.705449"
  },
  {
    "ID": 1066856355,
    "Tankstellennummer": 610372,
    "Name": "WESTFALEN, KOELN",
    "Address": "Dürener Str. 388-392",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50935,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0221/ 9433723",
    "24 hour?": "FALSE",
    "Latitude": 50.9251,
    "Longitude": 6.89525,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9251,6.89525",
    "IsOpen24Hours": false,
    "id": "50.9251,6.89525"
  },
  {
    "ID": 231116143,
    "Tankstellennummer": 610369,
    "Name": "WESTFALEN, JUELICH",
    "Address": "An der Leimkaul 1",
    "Address__1": "",
    "City": "Jülich",
    "Postcode": 52428,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0172/7422760",
    "24 hour?": "TRUE",
    "Latitude": 50.9268,
    "Longitude": 6.37195,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.9268,6.37195",
    "IsOpen24Hours": true,
    "id": "50.9268,6.37195"
  },
  {
    "ID": 2168,
    "Tankstellennummer": "0FA23",
    "Name": "Aral Tankstelle Köln, Rolshover Str. 183",
    "Address": "Rolshover Straße 183",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51105,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221836527,
    "24 hour?": "TRUE",
    "Latitude": 50.927277,
    "Longitude": 7.000214,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.927277,7.000214",
    "IsOpen24Hours": true,
    "id": "50.927277,7.000214"
  },
  {
    "ID": 2048,
    "Tankstellennummer": "0F929",
    "Name": "Aral Tankstelle Jülich, Neusser Str.45",
    "Address": "Neusser Straße 45",
    "Address__1": "",
    "City": "Jülich",
    "Postcode": 52428,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24612180,
    "24 hour?": "TRUE",
    "Latitude": 50.928046,
    "Longitude": 6.365275,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.928046,6.365275",
    "IsOpen24Hours": true,
    "id": "50.928046,6.365275"
  },
  {
    "ID": 658407855,
    "Tankstellennummer": 610376,
    "Name": "WESTFALEN, REICHSHOF",
    "Address": "Am Dreieck 16",
    "Address__1": "",
    "City": "REICHSHOF",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 50.9281,
    "Longitude": 7.64401,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9281,7.64401",
    "IsOpen24Hours": false,
    "id": "50.9281,7.64401"
  },
  {
    "ID": 2967,
    "Tankstellennummer": "0FI20",
    "Name": "Aral Tankstelle Übach-Palenberg, Heerlenerstrasse 145",
    "Address": "Heerlener Straße 145",
    "Address__1": "",
    "City": "Übach-Palenberg",
    "Postcode": 52531,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2451909327,
    "24 hour?": "FALSE",
    "Latitude": 50.928336,
    "Longitude": 6.067247,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.928336,6.067247",
    "IsOpen24Hours": false,
    "id": "50.928336,6.067247"
  },
  {
    "ID": 2094030200,
    "Tankstellennummer": "TD121040",
    "Name": "FRECHEN BAB SUEDSEITE / A 4",
    "Address": "BAB SUEDSEITE / A 4",
    "Address__1": "",
    "City": "FRECHEN",
    "Postcode": 50226,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02234-52164",
    "24 hour?": "FALSE",
    "Latitude": 50.9286,
    "Longitude": 6.7757,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9286,6.7757",
    "IsOpen24Hours": false,
    "id": "50.9286,6.7757"
  },
  {
    "ID": 2564,
    "Tankstellennummer": "0FC99",
    "Name": "Aral Tankstelle Freiberg, Leipziger Str. 43",
    "Address": "Leipziger  Straße 43",
    "Address__1": "",
    "City": "Freiberg",
    "Postcode": 9599,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 373133699,
    "24 hour?": "FALSE",
    "Latitude": 50.92884,
    "Longitude": 13.327974,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.92884,13.327974",
    "IsOpen24Hours": false,
    "id": "50.92884,13.327974"
  },
  {
    "ID": 594329874,
    "Tankstellennummer": "0FM82",
    "Name": "FRECHEN",
    "Address": "A 4",
    "Address__1": "",
    "City": "Frechen",
    "Postcode": 50226,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 223463900,
    "24 hour?": "TRUE",
    "Latitude": 50.929101,
    "Longitude": 6.775411,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.929101,6.775411",
    "IsOpen24Hours": true,
    "id": "50.929101,6.775411"
  },
  {
    "ID": 974,
    "Tankstellennummer": "0F162",
    "Name": "Aral Tankstelle Bad Laasphe, Bahnhofstraße 72",
    "Address": "Bahnhofstraße 72",
    "Address__1": "",
    "City": "Bad Laasphe",
    "Postcode": 57334,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2752200416,
    "24 hour?": "FALSE",
    "Latitude": 50.930953,
    "Longitude": 8.42897,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.930953,8.42897",
    "IsOpen24Hours": false,
    "id": "50.930953,8.42897"
  },
  {
    "ID": 775885933,
    "Tankstellennummer": "TD000965",
    "Name": "KOELN HOLZMARKT 49",
    "Address": "HOLZMARKT 49",
    "Address__1": "",
    "City": "KOELN",
    "Postcode": 50676,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0221-218144",
    "24 hour?": "FALSE",
    "Latitude": 50.9313,
    "Longitude": 6.9624,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9313,6.9624",
    "IsOpen24Hours": false,
    "id": "50.9313,6.9624"
  },
  {
    "ID": 1117447054,
    "Tankstellennummer": "TD023119",
    "Name": "JENA AUGUST-BEBEL-STR. 31",
    "Address": "AUGUST-BEBEL-STR. 31",
    "Address__1": "",
    "City": "JENA",
    "Postcode": 7743,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03641-820879",
    "24 hour?": "FALSE",
    "Latitude": 50.932,
    "Longitude": 11.5722,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.932,11.5722",
    "IsOpen24Hours": false,
    "id": "50.932,11.5722"
  },
  {
    "ID": 3357,
    "Tankstellennummer": "0FL02",
    "Name": "Aral Tankstelle Gotha, Leinastr. 80",
    "Address": "Leinastraße 80",
    "Address__1": "",
    "City": "Gotha",
    "Postcode": 99867,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3621702636,
    "24 hour?": "FALSE",
    "Latitude": 50.93412,
    "Longitude": 10.676027,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.93412,10.676027",
    "IsOpen24Hours": false,
    "id": "50.93412,10.676027"
  },
  {
    "ID": 2713,
    "Tankstellennummer": "0FE28",
    "Name": "Aral Autohof Mellingen, Aral-Allee",
    "Address": "Aralallee 1",
    "Address__1": "",
    "City": "Mellingen",
    "Postcode": 99441,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3645380630,
    "24 hour?": "TRUE",
    "Latitude": 50.93467,
    "Longitude": 11.389635,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.93467,11.389635",
    "IsOpen24Hours": true,
    "id": "50.93467,11.389635"
  },
  {
    "ID": 3480,
    "Tankstellennummer": "0FL96",
    "Name": "Aral Tankstelle Köln, Aachener Str. 203-209",
    "Address": "Aachener Straße 203-209",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50931,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2219402524,
    "24 hour?": "FALSE",
    "Latitude": 50.936368,
    "Longitude": 6.922341,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.936368,6.922341",
    "IsOpen24Hours": false,
    "id": "50.936368,6.922341"
  },
  {
    "ID": 2541,
    "Tankstellennummer": "0FC83",
    "Name": "Aral Tankstelle Gotha, Ohrdrufer Str.",
    "Address": "Ohrdrufer Straße 2a",
    "Address__1": "",
    "City": "Gotha",
    "Postcode": 99867,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3621709631,
    "24 hour?": "TRUE",
    "Latitude": 50.936511,
    "Longitude": 10.716086,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.936511,10.716086",
    "IsOpen24Hours": true,
    "id": "50.936511,10.716086"
  },
  {
    "ID": 2052,
    "Tankstellennummer": "0F933",
    "Name": "Aral Tankstelle Köln, Aachener Str. 1100",
    "Address": "Aachener Straße 1100",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50858,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2234943601,
    "24 hour?": "TRUE",
    "Latitude": 50.93839,
    "Longitude": 6.848411,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.93839,6.848411",
    "IsOpen24Hours": true,
    "id": "50.93839,6.848411"
  },
  {
    "ID": 57803883,
    "Tankstellennummer": 610373,
    "Name": "WESTFALEN, KOELN",
    "Address": "Olpener Str. 27",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51103,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 50.9403,
    "Longitude": 7.01887,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9403,7.01887",
    "IsOpen24Hours": false,
    "id": "50.9403,7.01887"
  },
  {
    "ID": 2999,
    "Tankstellennummer": "0FI50",
    "Name": "Aral Tankstelle Reichshof, Siegener Str. 19",
    "Address": "Siegener Straße 19",
    "Address__1": "",
    "City": "Reichshof",
    "Postcode": 51580,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2297359,
    "24 hour?": "FALSE",
    "Latitude": 50.940817,
    "Longitude": 7.744051,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.940817,7.744051",
    "IsOpen24Hours": false,
    "id": "50.940817,7.744051"
  },
  {
    "ID": 31386209,
    "Tankstellennummer": "TD022863",
    "Name": "GOTHA PUSCHKINALLEE 3A",
    "Address": "PUSCHKINALLEE 3 - 5",
    "Address__1": "",
    "City": "GOTHA",
    "Postcode": 99867,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03621-853772",
    "24 hour?": "FALSE",
    "Latitude": 50.9424,
    "Longitude": 10.7007,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9424,10.7007",
    "IsOpen24Hours": false,
    "id": "50.9424,10.7007"
  },
  {
    "ID": 2523,
    "Tankstellennummer": "0FC64",
    "Name": "Aral Tankstelle Jena, Camburger Str. 64",
    "Address": "Camburger Straße 64",
    "Address__1": "",
    "City": "Jena",
    "Postcode": 7743,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3641424000,
    "24 hour?": "TRUE",
    "Latitude": 50.942558,
    "Longitude": 11.597068,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.942558,11.597068",
    "IsOpen24Hours": true,
    "id": "50.942558,11.597068"
  },
  {
    "ID": 2954,
    "Tankstellennummer": "0FI13",
    "Name": "Aral Tankstelle Köln, Olpener Str. 498",
    "Address": "Olpener Straße 498",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51109,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221895143,
    "24 hour?": "FALSE",
    "Latitude": 50.942745,
    "Longitude": 7.050981,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.942745,7.050981",
    "IsOpen24Hours": false,
    "id": "50.942745,7.050981"
  },
  {
    "ID": 2164,
    "Tankstellennummer": "0FA19",
    "Name": "Aral Tankstelle Köln, Frankfurter Str. 390",
    "Address": "Frankfurter Straße 390",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51103,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221871436,
    "24 hour?": "TRUE",
    "Latitude": 50.942747,
    "Longitude": 7.027255,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.942747,7.027255",
    "IsOpen24Hours": true,
    "id": "50.942747,7.027255"
  },
  {
    "ID": 1296691412,
    "Tankstellennummer": "0FO47",
    "Name": "KÖLN",
    "Address": "Maarweg 126",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50825,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221541909,
    "24 hour?": "FALSE",
    "Latitude": 50.943089,
    "Longitude": 6.898796,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.943089,6.898796",
    "IsOpen24Hours": false,
    "id": "50.943089,6.898796"
  },
  {
    "ID": 529704800,
    "Tankstellennummer": "TD040923",
    "Name": "WIEHL BREMIGSWIESE 2",
    "Address": "BREMIGSWIESE 2",
    "Address__1": "",
    "City": "WIEHL",
    "Postcode": 51674,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02262-7084871",
    "24 hour?": "FALSE",
    "Latitude": 50.9441,
    "Longitude": 7.5738,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9441,7.5738",
    "IsOpen24Hours": false,
    "id": "50.9441,7.5738"
  },
  {
    "ID": 1200427882,
    "Tankstellennummer": "TD000325",
    "Name": "KOELN OLPENER STR. 690",
    "Address": "OLPENER STR. 690",
    "Address__1": "",
    "City": "KOELN",
    "Postcode": 51109,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0221-6908711",
    "24 hour?": "FALSE",
    "Latitude": 50.945,
    "Longitude": 7.0626,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.945,7.0626",
    "IsOpen24Hours": false,
    "id": "50.945,7.0626"
  },
  {
    "ID": 164828537,
    "Tankstellennummer": "TD006932",
    "Name": "GOTHA WEIMARER STR. 57/59",
    "Address": "WEIMARER STR. 57/59",
    "Address__1": "",
    "City": "GOTHA",
    "Postcode": 99867,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03621-892098",
    "24 hour?": "FALSE",
    "Latitude": 50.9456,
    "Longitude": 10.7331,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9456,10.7331",
    "IsOpen24Hours": false,
    "id": "50.9456,10.7331"
  },
  {
    "ID": 1376,
    "Tankstellennummer": "0FG18",
    "Name": "Aral Tankstelle Köln, Widdersdorfer Strasse 427",
    "Address": "Widdersdorfer Straße 427-",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50933,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2215894077,
    "24 hour?": "TRUE",
    "Latitude": 50.946877,
    "Longitude": 6.8805,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.946877,6.8805",
    "IsOpen24Hours": true,
    "id": "50.946877,6.8805"
  },
  {
    "ID": 2166,
    "Tankstellennummer": "0FA21",
    "Name": "Aral Tankstelle Köln, Olpener Str. 1026",
    "Address": "Olpener Straße 1026",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51109,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221840873,
    "24 hour?": "FALSE",
    "Latitude": 50.946881,
    "Longitude": 7.085226,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.946881,7.085226",
    "IsOpen24Hours": false,
    "id": "50.946881,7.085226"
  },
  {
    "ID": 1400819105,
    "Tankstellennummer": "TD000992",
    "Name": "OVERATH OLPER STR.41",
    "Address": "OLPER STR.41",
    "Address__1": "",
    "City": "OVERATH",
    "Postcode": 51491,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02204-73484",
    "24 hour?": "FALSE",
    "Latitude": 50.9469,
    "Longitude": 7.2177,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9469,7.2177",
    "IsOpen24Hours": false,
    "id": "50.9469,7.2177"
  },
  {
    "ID": 578998257,
    "Tankstellennummer": "TD022954",
    "Name": "EICHELBORN BAB SUEDSEITE / A4",
    "Address": "BAB SUEDSEITE / A4",
    "Address__1": "",
    "City": "GRAMMETAL, OT EICHELBORN",
    "Postcode": 99428,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 36209432950,
    "24 hour?": "FALSE",
    "Latitude": 50.9482,
    "Longitude": 11.1939,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9482,11.1939",
    "IsOpen24Hours": false,
    "id": "50.9482,11.1939"
  },
  {
    "ID": 2115,
    "Tankstellennummer": "0F987",
    "Name": "Aral Tankstelle Wiehl, Hauptstr.2",
    "Address": "Hauptstraße2",
    "Address__1": "",
    "City": "Wiehl",
    "Postcode": 51674,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22627074607,
    "24 hour?": "FALSE",
    "Latitude": 50.94922,
    "Longitude": 7.545073,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.94922,7.545073",
    "IsOpen24Hours": false,
    "id": "50.94922,7.545073"
  },
  {
    "ID": 1934808401,
    "Tankstellennummer": "TD023796",
    "Name": "ERFURT AM URBICHER KREUZ 34",
    "Address": "AM URBICHER KREUZ 34",
    "Address__1": "",
    "City": "ERFURT",
    "Postcode": 99099,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0361-4210828",
    "24 hour?": "FALSE",
    "Latitude": 50.9496,
    "Longitude": 11.0942,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9496,11.0942",
    "IsOpen24Hours": false,
    "id": "50.9496,11.0942"
  },
  {
    "ID": 579003021,
    "Tankstellennummer": "TD034702",
    "Name": "EICHELBORN BAB NORDSEITE / A4",
    "Address": "BAB NORDSEITE / A4",
    "Address__1": "",
    "City": "GRAMMETAL, OT EICHELBORN",
    "Postcode": 99428,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036209-40228",
    "24 hour?": "FALSE",
    "Latitude": 50.95,
    "Longitude": 11.1974,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.95,11.1974",
    "IsOpen24Hours": false,
    "id": "50.95,11.1974"
  },
  {
    "ID": 2049,
    "Tankstellennummer": "0F930",
    "Name": "Aral Tankstelle Bergheim, Aachener Str. 25",
    "Address": "Aachener Straße 25",
    "Address__1": "",
    "City": "Bergheim",
    "Postcode": 50126,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 227141568,
    "24 hour?": "FALSE",
    "Latitude": 50.950449,
    "Longitude": 6.625508,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.950449,6.625508",
    "IsOpen24Hours": false,
    "id": "50.950449,6.625508"
  },
  {
    "ID": 2956,
    "Tankstellennummer": "0FI14",
    "Name": "Aral Tankstelle Köln, Frankfurter Str. 198",
    "Address": "Frankfurter Straße 198",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51065,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221693137,
    "24 hour?": "TRUE",
    "Latitude": 50.950485,
    "Longitude": 7.016565,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.950485,7.016565",
    "IsOpen24Hours": true,
    "id": "50.950485,7.016565"
  },
  {
    "ID": 2167,
    "Tankstellennummer": "0FA22",
    "Name": "Aral Tankstelle Köln, Riehler Str. 12-14",
    "Address": "Riehler Straße 12-14",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50668,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2219726040,
    "24 hour?": "TRUE",
    "Latitude": 50.952379,
    "Longitude": 6.961644,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.952379,6.961644",
    "IsOpen24Hours": true,
    "id": "50.952379,6.961644"
  },
  {
    "ID": 2502,
    "Tankstellennummer": "0FC46",
    "Name": "Aral Tankstelle Pirna, Zehistaer Str. 1",
    "Address": "Zehistaer Straße 1",
    "Address__1": "",
    "City": "Pirna",
    "Postcode": 1796,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3501769123,
    "24 hour?": "FALSE",
    "Latitude": 50.953159,
    "Longitude": 13.938441,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.953159,13.938441",
    "IsOpen24Hours": false,
    "id": "50.953159,13.938441"
  },
  {
    "ID": 1864478800,
    "Tankstellennummer": "TD035790",
    "Name": "WILDECK-OBERSUHL ZUM DOENGES 2",
    "Address": "ZUM DOENGES 2",
    "Address__1": "",
    "City": "WILDECK-OBERSUHL",
    "Postcode": 36208,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06626-92100",
    "24 hour?": "FALSE",
    "Latitude": 50.9551,
    "Longitude": 10.0224,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9551,10.0224",
    "IsOpen24Hours": false,
    "id": "50.9551,10.0224"
  },
  {
    "ID": 2184,
    "Tankstellennummer": "0FA33",
    "Name": "Aral Tankstelle Pulheim, Sachsstr. 2",
    "Address": "Sachsstraße 2",
    "Address__1": "",
    "City": "Pulheim",
    "Postcode": 50259,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2234698914,
    "24 hour?": "TRUE",
    "Latitude": 50.957461,
    "Longitude": 6.801279,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.957461,6.801279",
    "IsOpen24Hours": true,
    "id": "50.957461,6.801279"
  },
  {
    "ID": 3376,
    "Tankstellennummer": "0FL18",
    "Name": "Aral Tankstelle Pirna, Krietzschwitzer Straße",
    "Address": "Krietzschwitzer Straße 7",
    "Address__1": "",
    "City": "Pirna",
    "Postcode": 1796,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3501761642,
    "24 hour?": "TRUE",
    "Latitude": 50.957801,
    "Longitude": 13.949456,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.957801,13.949456",
    "IsOpen24Hours": true,
    "id": "50.957801,13.949456"
  },
  {
    "ID": 2660,
    "Tankstellennummer": "0FD79",
    "Name": "Aral Tankstelle Erfurt, Kranichfelder Str. 2",
    "Address": "Kranichfelder Straße 2",
    "Address__1": "",
    "City": "Erfurt",
    "Postcode": 99097,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3613465366,
    "24 hour?": "TRUE",
    "Latitude": 50.958448,
    "Longitude": 11.053105,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.958448,11.053105",
    "IsOpen24Hours": true,
    "id": "50.958448,11.053105"
  },
  {
    "ID": 1050537658,
    "Tankstellennummer": "0FO58",
    "Name": "KÖLN",
    "Address": "Äußere-Kanal-Straße 90",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50827,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221551000,
    "24 hour?": "TRUE",
    "Latitude": 50.958536,
    "Longitude": 6.905212,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.958536,6.905212",
    "IsOpen24Hours": true,
    "id": "50.958536,6.905212"
  },
  {
    "ID": 2601,
    "Tankstellennummer": "0FD31",
    "Name": "Aral Tankstelle Weimar, Berkaer Str. 71",
    "Address": "Berkaer Straße 71",
    "Address__1": "",
    "City": "Weimar",
    "Postcode": 99425,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3643905891,
    "24 hour?": "TRUE",
    "Latitude": 50.960726,
    "Longitude": 11.316404,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.960726,11.316404",
    "IsOpen24Hours": true,
    "id": "50.960726,11.316404"
  },
  {
    "ID": 1214,
    "Tankstellennummer": "0F026",
    "Name": "Aral Tankstelle Kreuztal, Hagener Str.10",
    "Address": "Hagener Straße 10",
    "Address__1": "",
    "City": "Kreuztal",
    "Postcode": 57223,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2732765532,
    "24 hour?": "TRUE",
    "Latitude": 50.960927,
    "Longitude": 7.988881,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.960927,7.988881",
    "IsOpen24Hours": true,
    "id": "50.960927,7.988881"
  },
  {
    "ID": 2067,
    "Tankstellennummer": "0F943",
    "Name": "Aral Tankstelle Bergisch Gladbach, Kölner Str. 74",
    "Address": "Kölner Straße 74",
    "Address__1": "",
    "City": "Bergisch Gladbach",
    "Postcode": 51429,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 220453818,
    "24 hour?": "FALSE",
    "Latitude": 50.962031,
    "Longitude": 7.1483,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.962031,7.1483",
    "IsOpen24Hours": false,
    "id": "50.962031,7.1483"
  },
  {
    "ID": 472981164,
    "Tankstellennummer": "TD000984",
    "Name": "OVERATH LINDLARER STR.143",
    "Address": "LINDLARER STR.143",
    "Address__1": "",
    "City": "OVERATH",
    "Postcode": 51491,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02204-74445",
    "24 hour?": "FALSE",
    "Latitude": 50.9627,
    "Longitude": 7.2444,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9627,7.2444",
    "IsOpen24Hours": false,
    "id": "50.9627,7.2444"
  },
  {
    "ID": 309416719,
    "Tankstellennummer": "TD022855",
    "Name": "ERFURT ARNSTAEDTER STR. 36",
    "Address": "ARNSTAEDTER STR. 36",
    "Address__1": "",
    "City": "ERFURT",
    "Postcode": 99096,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0361-3460738",
    "24 hour?": "FALSE",
    "Latitude": 50.9629,
    "Longitude": 11.0334,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9629,11.0334",
    "IsOpen24Hours": false,
    "id": "50.9629,11.0334"
  },
  {
    "ID": 152304932,
    "Tankstellennummer": "0FU19",
    "Name": "ERFURT",
    "Address": "Gothaer Straße 40",
    "Address__1": "",
    "City": "Erfurt",
    "Postcode": 99094,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 36165319607,
    "24 hour?": "FALSE",
    "Latitude": 50.964982,
    "Longitude": 10.99912,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.964982,10.99912",
    "IsOpen24Hours": false,
    "id": "50.964982,10.99912"
  },
  {
    "ID": 2055,
    "Tankstellennummer": "0F935",
    "Name": "Aral Tankstelle Köln, Berg.Gladbach.Str.417-419",
    "Address": "Berg.-Gladbacher Str. 417",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51067,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221632555,
    "24 hour?": "TRUE",
    "Latitude": 50.967794,
    "Longitude": 7.033921,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.967794,7.033921",
    "IsOpen24Hours": true,
    "id": "50.967794,7.033921"
  },
  {
    "ID": 1032958131,
    "Tankstellennummer": "TD024950",
    "Name": "HAINICHEN FRANKENBERGER STR. 5",
    "Address": "FRANKENBERGER STR. 56",
    "Address__1": "",
    "City": "HAINICHEN",
    "Postcode": 9661,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "037207-2588",
    "24 hour?": "FALSE",
    "Latitude": 50.968,
    "Longitude": 13.1051,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.968,13.1051",
    "IsOpen24Hours": false,
    "id": "50.968,13.1051"
  },
  {
    "ID": 3365,
    "Tankstellennummer": "0FL09",
    "Name": "Aral Tankstelle Erfurt, Rudolstaedter Str. 58",
    "Address": "Rudolstaedter Straße 58",
    "Address__1": "",
    "City": "Erfurt",
    "Postcode": 99099,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3614211782,
    "24 hour?": "FALSE",
    "Latitude": 50.969068,
    "Longitude": 11.06126,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.969068,11.06126",
    "IsOpen24Hours": false,
    "id": "50.969068,11.06126"
  },
  {
    "ID": 494468940,
    "Tankstellennummer": "0F970",
    "Name": "Aral TankStelle Köln, Escher Straße 273",
    "Address": "Escher Straße 273",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50739,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221171612,
    "24 hour?": "TRUE",
    "Latitude": 50.969443,
    "Longitude": 6.927176,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.969443,6.927176",
    "IsOpen24Hours": true,
    "id": "50.969443,6.927176"
  },
  {
    "ID": 1895023889,
    "Tankstellennummer": 5106,
    "Name": "Agip Eisenberg",
    "Address": "Jenaer Str. 71",
    "Address__1": "",
    "City": "Eisenberg",
    "Postcode": 7607,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 36691561",
    "24 hour?": "TRUE",
    "Latitude": 50.96984449,
    "Longitude": 11.85835613,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.96984449,11.85835613",
    "IsOpen24Hours": true,
    "id": "50.96984449,11.85835613"
  },
  {
    "ID": 2600,
    "Tankstellennummer": "0FD30",
    "Name": "Aral Tankstelle Eisenberg, Jenaer Str. 13",
    "Address": "Jenaer Straße 13",
    "Address__1": "",
    "City": "Eisenberg",
    "Postcode": 7607,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3669152900,
    "24 hour?": "TRUE",
    "Latitude": 50.970106,
    "Longitude": 11.890035,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.970106,11.890035",
    "IsOpen24Hours": true,
    "id": "50.970106,11.890035"
  },
  {
    "ID": 2161,
    "Tankstellennummer": "0FA17",
    "Name": "Aral Tankstelle Geilenkirchen, Heinsberger Str.77",
    "Address": "Heinsberger Straße 77",
    "Address__1": "",
    "City": "Geilenkirchen",
    "Postcode": 52511,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24519149696,
    "24 hour?": "TRUE",
    "Latitude": 50.970737,
    "Longitude": 6.119667,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.970737,6.119667",
    "IsOpen24Hours": true,
    "id": "50.970737,6.119667"
  },
  {
    "ID": 1466887425,
    "Tankstellennummer": "TD033498",
    "Name": "ERFURT BINDERSLEBENER LANDSTR.",
    "Address": "BINDERSLEBENER LANDSTR. 98",
    "Address__1": "",
    "City": "ERFURT",
    "Postcode": 99092,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0361-2229393",
    "24 hour?": "FALSE",
    "Latitude": 50.9724,
    "Longitude": 10.9728,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9724,10.9728",
    "IsOpen24Hours": false,
    "id": "50.9724,10.9728"
  },
  {
    "ID": 1063397229,
    "Tankstellennummer": "0FO57",
    "Name": "KÖLN",
    "Address": "Berliner Straße 111",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51063,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22196426619,
    "24 hour?": "FALSE",
    "Latitude": 50.973797,
    "Longitude": 7.012554,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.973797,7.012554",
    "IsOpen24Hours": false,
    "id": "50.973797,7.012554"
  },
  {
    "ID": 2953,
    "Tankstellennummer": "0FI11",
    "Name": "Aral Tankstelle Köln, B.- Gladbacher Str. 781",
    "Address": "Berg.-Gladbacher Str. 781",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51069,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221681487,
    "24 hour?": "TRUE",
    "Latitude": 50.974559,
    "Longitude": 7.057847,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.974559,7.057847",
    "IsOpen24Hours": true,
    "id": "50.974559,7.057847"
  },
  {
    "ID": 2089,
    "Tankstellennummer": "0F964",
    "Name": "Aral Tankstelle Köln, Amsterdamer Str. 194",
    "Address": "Amsterdamer Straße 194",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50735,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2217127826,
    "24 hour?": "TRUE",
    "Latitude": 50.974747,
    "Longitude": 6.970322,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.974747,6.970322",
    "IsOpen24Hours": true,
    "id": "50.974747,6.970322"
  },
  {
    "ID": 787369693,
    "Tankstellennummer": 5256,
    "Name": "Agip Umpferstedt",
    "Address": "Apoldaerstr. 2",
    "Address__1": "",
    "City": "Umpferstedt",
    "Postcode": 99441,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 36435105",
    "24 hour?": "FALSE",
    "Latitude": 50.9751,
    "Longitude": 11.41396,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9751,11.41396",
    "IsOpen24Hours": false,
    "id": "50.9751,11.41396"
  },
  {
    "ID": 665656950,
    "Tankstellennummer": "TD124220",
    "Name": "BERGISCH GLADBACH BENSBERGER S",
    "Address": "BENSBERGER STR. 240",
    "Address__1": "",
    "City": "BERGISCH GLADBACH",
    "Postcode": 51469,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02202-34120",
    "24 hour?": "FALSE",
    "Latitude": 50.9753,
    "Longitude": 7.1403,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9753,7.1403",
    "IsOpen24Hours": false,
    "id": "50.9753,7.1403"
  },
  {
    "ID": 2955,
    "Tankstellennummer": "0FI12",
    "Name": "Aral Tankstelle Köln- Mülheim, Muelheimer Zubringer 140",
    "Address": "Mülheimer Zubringer 140/S",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51063,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2219641905,
    "24 hour?": "TRUE",
    "Latitude": 50.977127,
    "Longitude": 7.007043,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.977127,7.007043",
    "IsOpen24Hours": true,
    "id": "50.977127,7.007043"
  },
  {
    "ID": 310101802,
    "Tankstellennummer": "TD034553",
    "Name": "MITTWEIDA CHEMNITZER STR. 28F",
    "Address": "CHEMNITZER STR. 28f",
    "Address__1": "",
    "City": "MITTWEIDA",
    "Postcode": 9648,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03727-611392",
    "24 hour?": "FALSE",
    "Latitude": 50.9774,
    "Longitude": 12.9813,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9774,12.9813",
    "IsOpen24Hours": false,
    "id": "50.9774,12.9813"
  },
  {
    "ID": 253202312,
    "Tankstellennummer": "TD023366",
    "Name": "EISENACH KASSELER STR. 9",
    "Address": "KASSELER STR. 9",
    "Address__1": "",
    "City": "EISENACH",
    "Postcode": 99817,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03691-203496",
    "24 hour?": "FALSE",
    "Latitude": 50.9778,
    "Longitude": 10.3047,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9778,10.3047",
    "IsOpen24Hours": false,
    "id": "50.9778,10.3047"
  },
  {
    "ID": 350427349,
    "Tankstellennummer": "0FV74",
    "Name": "EISENACH",
    "Address": "Clemensstraße 19",
    "Address__1": "",
    "City": "Eisenach",
    "Postcode": 99817,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 36918819798,
    "24 hour?": "TRUE",
    "Latitude": 50.97799,
    "Longitude": 10.33136,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.97799,10.33136",
    "IsOpen24Hours": true,
    "id": "50.97799,10.33136"
  },
  {
    "ID": 2133629869,
    "Tankstellennummer": 610500,
    "Name": "WESTFALEN, KOELN",
    "Address": "Spenrather Weg 1",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50829,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0221/17099940",
    "24 hour?": "TRUE",
    "Latitude": 50.9799,
    "Longitude": 6.84652,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.9799,6.84652",
    "IsOpen24Hours": true,
    "id": "50.9799,6.84652"
  },
  {
    "ID": 752074938,
    "Tankstellennummer": "0FO59",
    "Name": "KÖLN",
    "Address": "Boltensternstraße 350",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50735,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2219775353,
    "24 hour?": "TRUE",
    "Latitude": 50.980505,
    "Longitude": 6.969038,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.980505,6.969038",
    "IsOpen24Hours": true,
    "id": "50.980505,6.969038"
  },
  {
    "ID": 3403,
    "Tankstellennummer": "0FL44",
    "Name": "Aral Tankstelle Eisenach, Kasseler Str. 50",
    "Address": "Kasseler Straße 50",
    "Address__1": "",
    "City": "Eisenach",
    "Postcode": 99817,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 369177024,
    "24 hour?": "FALSE",
    "Latitude": 50.981121,
    "Longitude": 10.302172,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.981121,10.302172",
    "IsOpen24Hours": false,
    "id": "50.981121,10.302172"
  },
  {
    "ID": 36686258,
    "Tankstellennummer": "TD008029",
    "Name": "EISENACH LANGENSALZAER STR. 77",
    "Address": "LANGENSALZAER STR. 77",
    "Address__1": "",
    "City": "EISENACH",
    "Postcode": 99817,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03691-7879477",
    "24 hour?": "FALSE",
    "Latitude": 50.9813,
    "Longitude": 10.3468,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9813,10.3468",
    "IsOpen24Hours": false,
    "id": "50.9813,10.3468"
  },
  {
    "ID": 2533,
    "Tankstellennummer": "0FC71",
    "Name": "Aral Tankstelle Altenburg, Münsaer Str. 65/B 180",
    "Address": "Münsaer Straße 65  /B 180",
    "Address__1": "",
    "City": "Altenburg",
    "Postcode": 4600,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3447579249,
    "24 hour?": "TRUE",
    "Latitude": 50.983298,
    "Longitude": 12.456116,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.983298,12.456116",
    "IsOpen24Hours": true,
    "id": "50.983298,12.456116"
  },
  {
    "ID": 1126965549,
    "Tankstellennummer": "TD120820",
    "Name": "GUMMERSBACH DIERINGHAUSER STR.",
    "Address": "DIERINGHAUSER STR.17",
    "Address__1": "",
    "City": "GUMMERSBACH",
    "Postcode": 51645,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02261-969572",
    "24 hour?": "FALSE",
    "Latitude": 50.9841,
    "Longitude": 7.5406,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9841,7.5406",
    "IsOpen24Hours": false,
    "id": "50.9841,7.5406"
  },
  {
    "ID": 1386896649,
    "Tankstellennummer": "TD022988",
    "Name": "ERFURT SCHLACHTHOFSTR. 14",
    "Address": "SCHLACHTHOFSTR. 14",
    "Address__1": "",
    "City": "ERFURT",
    "Postcode": 99085,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0361-5623517",
    "24 hour?": "FALSE",
    "Latitude": 50.9862,
    "Longitude": 11.0382,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9862,11.0382",
    "IsOpen24Hours": false,
    "id": "50.9862,11.0382"
  },
  {
    "ID": 2177,
    "Tankstellennummer": "0FA30",
    "Name": "Aral Tankstelle Bergheim, Dormagener Str. 18 - 20",
    "Address": "Dormagener Straße 20",
    "Address__1": "",
    "City": "Bergheim",
    "Postcode": 50129,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22716785851,
    "24 hour?": "FALSE",
    "Latitude": 50.986501,
    "Longitude": 6.671675,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.986501,6.671675",
    "IsOpen24Hours": false,
    "id": "50.986501,6.671675"
  },
  {
    "ID": 1370,
    "Tankstellennummer": "0F455",
    "Name": "Aral Tankstelle Hilchenbach, Wittgensteiner Str.8 u. 1",
    "Address": "Wittgensteiner Straße 8 -",
    "Address__1": "",
    "City": "Hilchenbach",
    "Postcode": 57271,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27332686,
    "24 hour?": "FALSE",
    "Latitude": 50.986885,
    "Longitude": 8.102047,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.986885,8.102047",
    "IsOpen24Hours": false,
    "id": "50.986885,8.102047"
  },
  {
    "ID": 49033888,
    "Tankstellennummer": "TD033373",
    "Name": "HEIDENAU DRESDNER STR. 103 A",
    "Address": "DRESDNER STR. 103 A",
    "Address__1": "",
    "City": "HEIDENAU",
    "Postcode": 1809,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03529-512353",
    "24 hour?": "FALSE",
    "Latitude": 50.9869,
    "Longitude": 13.8582,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9869,13.8582",
    "IsOpen24Hours": false,
    "id": "50.9869,13.8582"
  },
  {
    "ID": 474915203,
    "Tankstellennummer": "TD005819",
    "Name": "KREUZTAL HAGENER STR. 237",
    "Address": "HAGENER STR. 239",
    "Address__1": "",
    "City": "KREUZTAL",
    "Postcode": 57223,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02732-5531848",
    "24 hour?": "FALSE",
    "Latitude": 50.9881,
    "Longitude": 7.9593,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9881,7.9593",
    "IsOpen24Hours": false,
    "id": "50.9881,7.9593"
  },
  {
    "ID": 2151,
    "Tankstellennummer": "0FA10",
    "Name": "Aral Tankstelle Bergisch Gladbach, Dellbrücker Str. 2-4",
    "Address": "Dellbrücker Straße 2-4",
    "Address__1": "",
    "City": "Bergisch Gladbach",
    "Postcode": 51469,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 220251865,
    "24 hour?": "FALSE",
    "Latitude": 50.988156,
    "Longitude": 7.087447,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.988156,7.087447",
    "IsOpen24Hours": false,
    "id": "50.988156,7.087447"
  },
  {
    "ID": 3418,
    "Tankstellennummer": "0FL56",
    "Name": "Aral Tankstelle Gangelt, Bundesstr. 150",
    "Address": "Bundesstraße 150",
    "Address__1": "",
    "City": "Gangelt",
    "Postcode": 52538,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24547458,
    "24 hour?": "FALSE",
    "Latitude": 50.988632,
    "Longitude": 6.033085,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.988632,6.033085",
    "IsOpen24Hours": false,
    "id": "50.988632,6.033085"
  },
  {
    "ID": 293400255,
    "Tankstellennummer": "0FO56",
    "Name": "KÖLN",
    "Address": "Düsseldorfer Straße 491",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 51061,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 221664829,
    "24 hour?": "TRUE",
    "Latitude": 50.989542,
    "Longitude": 6.998822,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "50.989542,6.998822",
    "IsOpen24Hours": true,
    "id": "50.989542,6.998822"
  },
  {
    "ID": 1631694669,
    "Tankstellennummer": "TD035774",
    "Name": "ROTENBURG-LISPENHAUSEN NUERNBE",
    "Address": "NUERNBERGER STRASSE 67",
    "Address__1": "",
    "City": "ROTENBURG-LISPENHAUSEN",
    "Postcode": 36199,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "06623-919323",
    "24 hour?": "FALSE",
    "Latitude": 50.9907,
    "Longitude": 9.7758,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9907,9.7758",
    "IsOpen24Hours": false,
    "id": "50.9907,9.7758"
  },
  {
    "ID": 1242,
    "Tankstellennummer": "0F348",
    "Name": "Aral Tankstelle Erndtebrück, Marburger Str.25",
    "Address": "Marburger Straße 25",
    "Address__1": "",
    "City": "Erndtebrück",
    "Postcode": 57339,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27532085,
    "24 hour?": "FALSE",
    "Latitude": 50.991046,
    "Longitude": 8.258931,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.991046,8.258931",
    "IsOpen24Hours": false,
    "id": "50.991046,8.258931"
  },
  {
    "ID": 2165,
    "Tankstellennummer": "0FA20",
    "Name": "Aral Tankstelle Köln, Neusser Str. 856",
    "Address": "Neusser Straße 856",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50737,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2219742284,
    "24 hour?": "TRUE",
    "Latitude": 50.993198,
    "Longitude": 6.94057,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.993198,6.94057",
    "IsOpen24Hours": true,
    "id": "50.993198,6.94057"
  },
  {
    "ID": 3361,
    "Tankstellennummer": "0FL05",
    "Name": "Aral Tankstelle Mittweida, Sonnenstr. 1",
    "Address": "Sonnenstraße 1",
    "Address__1": "",
    "City": "Mittweida",
    "Postcode": 9648,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 372790602,
    "24 hour?": "TRUE",
    "Latitude": 50.993401,
    "Longitude": 12.965552,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.993401,12.965552",
    "IsOpen24Hours": true,
    "id": "50.993401,12.965552"
  },
  {
    "ID": 2507,
    "Tankstellennummer": "0FC51",
    "Name": "Aral Tankstelle Eisenach, An der Karlskuppe 1",
    "Address": "An der Karlskuppe 1",
    "Address__1": "",
    "City": "Eisenach",
    "Postcode": 99817,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3691818095,
    "24 hour?": "FALSE",
    "Latitude": 50.994186,
    "Longitude": 10.292966,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.994186,10.292966",
    "IsOpen24Hours": false,
    "id": "50.994186,10.292966"
  },
  {
    "ID": 1009112139,
    "Tankstellennummer": "TD000942",
    "Name": "BERGISCH GLADBACH PAFFRATHER S",
    "Address": "PAFFRATHER STR. 215",
    "Address__1": "",
    "City": "BERGISCH GLADBACH",
    "Postcode": 51469,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02202-920265",
    "24 hour?": "FALSE",
    "Latitude": 50.9953,
    "Longitude": 7.1098,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9953,7.1098",
    "IsOpen24Hours": false,
    "id": "50.9953,7.1098"
  },
  {
    "ID": 2088,
    "Tankstellennummer": "0F963",
    "Name": "Aral Tankstelle Pulheim, Venloer Str. 39",
    "Address": "Venloer Straße 39",
    "Address__1": "",
    "City": "Pulheim",
    "Postcode": 50259,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 223854747,
    "24 hour?": "TRUE",
    "Latitude": 50.995372,
    "Longitude": 6.810329,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "50.995372,6.810329",
    "IsOpen24Hours": true,
    "id": "50.995372,6.810329"
  },
  {
    "ID": 600687475,
    "Tankstellennummer": "TD032854",
    "Name": "FREITAL POISENTALSTR. 18",
    "Address": "POISENTALSTR. 18",
    "Address__1": "",
    "City": "FREITAL",
    "Postcode": 1705,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-6491861",
    "24 hour?": "FALSE",
    "Latitude": 50.9961,
    "Longitude": 13.6521,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.9961,13.6521",
    "IsOpen24Hours": false,
    "id": "50.9961,13.6521"
  },
  {
    "ID": 2655,
    "Tankstellennummer": "0FD76",
    "Name": "Aral Tankstelle Ebersbach, Rumburger Str. 42",
    "Address": "Rumburger Straße 38",
    "Address__1": "",
    "City": "Ebersbach",
    "Postcode": 2730,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3586300091,
    "24 hour?": "FALSE",
    "Latitude": 50.997968,
    "Longitude": 14.61652,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "50.997968,14.61652",
    "IsOpen24Hours": false,
    "id": "50.997968,14.61652"
  },
  {
    "ID": 2095,
    "Tankstellennummer": "0F969",
    "Name": "Aral Tankstelle Köln, Longericher Str. 543",
    "Address": "Longericher Straße 543",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50739,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2215992601,
    "24 hour?": "FALSE",
    "Latitude": 50.998909,
    "Longitude": 6.912042,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "50.998909,6.912042",
    "IsOpen24Hours": false,
    "id": "50.998909,6.912042"
  },
  {
    "ID": 2075,
    "Tankstellennummer": "0F950",
    "Name": "Aral Tankstelle Bergisch Gladbach, Odenthaler Str. 205",
    "Address": "Odenthaler Straße 205",
    "Address__1": "",
    "City": "Bergisch Gladbach",
    "Postcode": 51467,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 220235627,
    "24 hour?": "FALSE",
    "Latitude": 51.000198,
    "Longitude": 7.131204,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.000198,7.131204",
    "IsOpen24Hours": false,
    "id": "51.000198,7.131204"
  },
  {
    "ID": 2711,
    "Tankstellennummer": "0FE25",
    "Name": "Aral Tankstelle Freital, Dresdner Str. 164-166",
    "Address": "Dresdner Straße 164",
    "Address__1": "",
    "City": "Freital",
    "Postcode": 1705,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3514601502,
    "24 hour?": "TRUE",
    "Latitude": 51.001763,
    "Longitude": 13.651485,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.001763,13.651485",
    "IsOpen24Hours": true,
    "id": "51.001763,13.651485"
  },
  {
    "ID": 2124219022,
    "Tankstellennummer": "TD037663",
    "Name": "ERFURT AUGSBURGER STRASSE 2",
    "Address": "AUGSBURGER STRASSE 2",
    "Address__1": "",
    "City": "ERFURT",
    "Postcode": 99091,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0361-26232699",
    "24 hour?": "FALSE",
    "Latitude": 51.0022,
    "Longitude": 11.0026,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0022,11.0026",
    "IsOpen24Hours": false,
    "id": "51.0022,11.0026"
  },
  {
    "ID": 258928846,
    "Tankstellennummer": "0FF58",
    "Name": "GUMMERSBACH",
    "Address": "Am alten Bahnhof 1",
    "Address__1": "",
    "City": "Gummersbach",
    "Postcode": 51645,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2261789440,
    "24 hour?": "TRUE",
    "Latitude": 51.003345,
    "Longitude": 7.575883,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.003345,7.575883",
    "IsOpen24Hours": true,
    "id": "51.003345,7.575883"
  },
  {
    "ID": 3289,
    "Tankstellennummer": "0FK50",
    "Name": "Aral Tankstelle Köln, Donatusstr. 20",
    "Address": "Donatusstraße 20",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50767,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2219591032,
    "24 hour?": "FALSE",
    "Latitude": 51.003404,
    "Longitude": 6.875087,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.003404,6.875087",
    "IsOpen24Hours": false,
    "id": "51.003404,6.875087"
  },
  {
    "ID": 1714965599,
    "Tankstellennummer": 610375,
    "Name": "WESTFALEN, GUMMERSBACH",
    "Address": "Kölner Str. 188",
    "Address__1": "",
    "City": "Gummersbach",
    "Postcode": 51645,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02261/51945",
    "24 hour?": "FALSE",
    "Latitude": 51.0042,
    "Longitude": 7.59573,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0042,7.59573",
    "IsOpen24Hours": false,
    "id": "51.0042,7.59573"
  },
  {
    "ID": 538153495,
    "Tankstellennummer": 4168,
    "Name": "Agip Knuellwald",
    "Address": "Schilfwiese 1",
    "Address__1": "",
    "City": "Knuellwald",
    "Postcode": 34593,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "05681/9399398",
    "24 hour?": "TRUE",
    "Latitude": 51.00435,
    "Longitude": 9.4799,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.00435,9.4799",
    "IsOpen24Hours": true,
    "id": "51.00435,9.4799"
  },
  {
    "ID": 2187,
    "Tankstellennummer": "0FE80",
    "Name": "Aral Autobahntankstelle Bedburg, Bedburger Land Ost (A 61)",
    "Address": "A 61 Ost",
    "Address__1": "",
    "City": "Bedburg",
    "Postcode": 50181,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2272904330,
    "24 hour?": "TRUE",
    "Latitude": 51.004863,
    "Longitude": 6.522144,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.004863,6.522144",
    "IsOpen24Hours": true,
    "id": "51.004863,6.522144"
  },
  {
    "ID": 1943200638,
    "Tankstellennummer": "0FP91",
    "Name": "BEDBURG",
    "Address": "A 61 West",
    "Address__1": "",
    "City": "Bedburg",
    "Postcode": 50181,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2272407650,
    "24 hour?": "TRUE",
    "Latitude": 51.005141,
    "Longitude": 6.52227,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.005141,6.52227",
    "IsOpen24Hours": true,
    "id": "51.005141,6.52227"
  },
  {
    "ID": 2683,
    "Tankstellennummer": "0FE04",
    "Name": "Aral Tankstelle Dresden, Straße des 17. Juni - Nummer 9",
    "Address": "Straße des 17. Juni 9",
    "Address__1": "",
    "City": "Dresden",
    "Postcode": 1257,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3512015155,
    "24 hour?": "FALSE",
    "Latitude": 51.006626,
    "Longitude": 13.819407,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.006626,13.819407",
    "IsOpen24Hours": false,
    "id": "51.006626,13.819407"
  },
  {
    "ID": 1649,
    "Tankstellennummer": "0F665",
    "Name": "Aral Tankstelle Knüllwald, Hauptstrasse 32",
    "Address": "Hauptstraße 32",
    "Address__1": "",
    "City": "Knüllwald",
    "Postcode": 34593,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56812872,
    "24 hour?": "TRUE",
    "Latitude": 51.006637,
    "Longitude": 9.472994,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.006637,9.472994",
    "IsOpen24Hours": true,
    "id": "51.006637,9.472994"
  },
  {
    "ID": 1395537147,
    "Tankstellennummer": "0FM38",
    "Name": "Aral TankStelle Erfurt, Salinenstraße 56",
    "Address": "Salinenstraße 56",
    "Address__1": "",
    "City": "Erfurt",
    "Postcode": 99085,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 36126232610,
    "24 hour?": "FALSE",
    "Latitude": 51.00684,
    "Longitude": 11.045409,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.00684,11.045409",
    "IsOpen24Hours": false,
    "id": "51.00684,11.045409"
  },
  {
    "ID": 1556892436,
    "Tankstellennummer": "TD032763",
    "Name": "DRESDEN LANGER WEG 17",
    "Address": "LANGER WEG 17",
    "Address__1": "",
    "City": "DRESDEN",
    "Postcode": 1257,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-2753023",
    "24 hour?": "FALSE",
    "Latitude": 51.0069,
    "Longitude": 13.81,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0069,13.81",
    "IsOpen24Hours": false,
    "id": "51.0069,13.81"
  },
  {
    "ID": 1053777681,
    "Tankstellennummer": "TD034579",
    "Name": "ALTENBURG LEIPZIGER STR. 87",
    "Address": "LEIPZIGER STR. 87",
    "Address__1": "",
    "City": "ALTENBURG",
    "Postcode": 4600,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03447-836804",
    "24 hour?": "FALSE",
    "Latitude": 51.008,
    "Longitude": 12.4533,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.008,12.4533",
    "IsOpen24Hours": false,
    "id": "51.008,12.4533"
  },
  {
    "ID": 1586691004,
    "Tankstellennummer": "TD033811",
    "Name": "ERFURT MITTELHAEUSER STR. 69",
    "Address": "MITTELHAEUSER STR. 69",
    "Address__1": "",
    "City": "ERFURT",
    "Postcode": 99089,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0361-7920622",
    "24 hour?": "FALSE",
    "Latitude": 51.0096,
    "Longitude": 11.018,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0096,11.018",
    "IsOpen24Hours": false,
    "id": "51.0096,11.018"
  },
  {
    "ID": 1066812030,
    "Tankstellennummer": 5078,
    "Name": "Agip Altenburg",
    "Address": "Leipziger Str./B 93",
    "Address__1": "",
    "City": "Altenburg",
    "Postcode": 4600,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 34478343",
    "24 hour?": "TRUE",
    "Latitude": 51.0110859,
    "Longitude": 12.4541242,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.0110859,12.4541242",
    "IsOpen24Hours": true,
    "id": "51.0110859,12.4541242"
  },
  {
    "ID": 2767,
    "Tankstellennummer": "0FE59",
    "Name": "Aral Tankstelle Erfurt, Demminer Str. 7",
    "Address": "Demminer Straße 7",
    "Address__1": "",
    "City": "Erfurt",
    "Postcode": 99091,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3617914006,
    "24 hour?": "TRUE",
    "Latitude": 51.013167,
    "Longitude": 10.987543,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.013167,10.987543",
    "IsOpen24Hours": true,
    "id": "51.013167,10.987543"
  },
  {
    "ID": 641642654,
    "Tankstellennummer": 610364,
    "Name": "WESTFALEN, BERGNEUSTADT",
    "Address": "Kölner Str. 124",
    "Address__1": "",
    "City": "Bergneustadt",
    "Postcode": 51702,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02261/43783",
    "24 hour?": "FALSE",
    "Latitude": 51.0171,
    "Longitude": 7.63931,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0171,7.63931",
    "IsOpen24Hours": false,
    "id": "51.0171,7.63931"
  },
  {
    "ID": 2984,
    "Tankstellennummer": "0FI36",
    "Name": "Aral Tankstelle Lindlar, Koelner Str. 23",
    "Address": "Koelner Straße 23",
    "Address__1": "",
    "City": "Lindlar",
    "Postcode": 51789,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22666075,
    "24 hour?": "FALSE",
    "Latitude": 51.017627,
    "Longitude": 7.372177,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.017627,7.372177",
    "IsOpen24Hours": false,
    "id": "51.017627,7.372177"
  },
  {
    "ID": 1340,
    "Tankstellennummer": "0F427",
    "Name": "Aral Tankstelle Köln, Athener Ring 1",
    "Address": "Athener Ring 1",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50765,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2217089582,
    "24 hour?": "TRUE",
    "Latitude": 51.019199,
    "Longitude": 6.896581,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.019199,6.896581",
    "IsOpen24Hours": true,
    "id": "51.019199,6.896581"
  },
  {
    "ID": 2723,
    "Tankstellennummer": "0FE37",
    "Name": "Aral Tankstelle Dresden, Bergstr. 121/ Ecke Südhöhe",
    "Address": "Bergstr. 121",
    "Address__1": "",
    "City": "Dresden",
    "Postcode": 1217,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3514016150,
    "24 hour?": "TRUE",
    "Latitude": 51.019833,
    "Longitude": 13.730003,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.019833,13.730003",
    "IsOpen24Hours": true,
    "id": "51.019833,13.730003"
  },
  {
    "ID": 282077561,
    "Tankstellennummer": "0FN62",
    "Name": "DRESDEN",
    "Address": "Österreicher Straße 64",
    "Address__1": "",
    "City": "Dresden",
    "Postcode": 1279,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3512514937,
    "24 hour?": "FALSE",
    "Latitude": 51.021001,
    "Longitude": 13.840501,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.021001,13.840501",
    "IsOpen24Hours": false,
    "id": "51.021001,13.840501"
  },
  {
    "ID": 1700,
    "Tankstellennummer": "0F699",
    "Name": "Aral Tankstelle Allendorf, Edertalstraße 60",
    "Address": "Edertalstraße 60",
    "Address__1": "",
    "City": "Allendorf",
    "Postcode": 35108,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 64529110251,
    "24 hour?": "FALSE",
    "Latitude": 51.021558,
    "Longitude": 8.663096,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.021558,8.663096",
    "IsOpen24Hours": false,
    "id": "51.021558,8.663096"
  },
  {
    "ID": 2972,
    "Tankstellennummer": "0FI25",
    "Name": "Aral Tankstelle Bergneustadt, Koelner Str. 290",
    "Address": "Kölner Straße 290",
    "Address__1": "",
    "City": "Bergneustadt",
    "Postcode": 51702,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 1753325167,
    "24 hour?": "FALSE",
    "Latitude": 51.021679,
    "Longitude": 7.658899,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.021679,7.658899",
    "IsOpen24Hours": false,
    "id": "51.021679,7.658899"
  },
  {
    "ID": 2980,
    "Tankstellennummer": "0FI32",
    "Name": "Aral Tankstelle Leverkusen, Willy-Brandt-Ring 20",
    "Address": "Willy-Brandt-Ring 20",
    "Address__1": "",
    "City": "Leverkusen",
    "Postcode": 51373,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 214830450,
    "24 hour?": "TRUE",
    "Latitude": 51.02299,
    "Longitude": 7.003646,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.02299,7.003646",
    "IsOpen24Hours": true,
    "id": "51.02299,7.003646"
  },
  {
    "ID": 745192695,
    "Tankstellennummer": "TD006783",
    "Name": "LINDLAR ALTENLINDE 1",
    "Address": "ALTENLINDE 1",
    "Address__1": "",
    "City": "LINDLAR",
    "Postcode": 51789,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02266-5556",
    "24 hour?": "FALSE",
    "Latitude": 51.0235,
    "Longitude": 7.3705,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0235,7.3705",
    "IsOpen24Hours": false,
    "id": "51.0235,7.3705"
  },
  {
    "ID": 528964571,
    "Tankstellennummer": 610368,
    "Name": "WESTFALEN, GUMMERSBACH",
    "Address": "Brückenstr. 85",
    "Address__1": "",
    "City": "Gummersbach",
    "Postcode": 51643,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02261/919355",
    "24 hour?": "FALSE",
    "Latitude": 51.0236,
    "Longitude": 7.55463,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0236,7.55463",
    "IsOpen24Hours": false,
    "id": "51.0236,7.55463"
  },
  {
    "ID": 2120,
    "Tankstellennummer": "0F988",
    "Name": "Aral Tankstelle Gummersbach, Brückenstr. 83",
    "Address": "Brückenstraße 83",
    "Address__1": "",
    "City": "Gummersbach",
    "Postcode": 51643,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 226165147,
    "24 hour?": "FALSE",
    "Latitude": 51.023703,
    "Longitude": 7.555455,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.023703,7.555455",
    "IsOpen24Hours": false,
    "id": "51.023703,7.555455"
  },
  {
    "ID": 2029010825,
    "Tankstellennummer": "TD023531",
    "Name": "APOLDA ERFURTER STR. 34",
    "Address": "ERFURTER STR.34",
    "Address__1": "",
    "City": "APOLDA",
    "Postcode": 99510,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03644-553256",
    "24 hour?": "FALSE",
    "Latitude": 51.0241,
    "Longitude": 11.4973,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0241,11.4973",
    "IsOpen24Hours": false,
    "id": "51.0241,11.4973"
  },
  {
    "ID": 1149,
    "Tankstellennummer": "0F324",
    "Name": "Aral Tankstelle Olpe, Bruchstr.35",
    "Address": "Bruchstraße 35",
    "Address__1": "",
    "City": "Olpe",
    "Postcode": 57462,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27612212,
    "24 hour?": "FALSE",
    "Latitude": 51.024264,
    "Longitude": 7.844693,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.024264,7.844693",
    "IsOpen24Hours": false,
    "id": "51.024264,7.844693"
  },
  {
    "ID": 2097,
    "Tankstellennummer": "0F971",
    "Name": "Aral Tankstelle Leverkusen, Willy-Brandt-R. 101",
    "Address": "Willy-Brandt-Ring  101",
    "Address__1": "",
    "City": "Leverkusen",
    "Postcode": 51375,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21453024,
    "24 hour?": "FALSE",
    "Latitude": 51.027338,
    "Longitude": 7.042083,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.027338,7.042083",
    "IsOpen24Hours": false,
    "id": "51.027338,7.042083"
  },
  {
    "ID": 2976,
    "Tankstellennummer": "0FI29",
    "Name": "Aral Tankstelle Drolshagen, Hagener Str. 67",
    "Address": "Hagener Straße 67",
    "Address__1": "",
    "City": "Drolshagen",
    "Postcode": 57489,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2761979408,
    "24 hour?": "FALSE",
    "Latitude": 51.027881,
    "Longitude": 7.77242,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.027881,7.77242",
    "IsOpen24Hours": false,
    "id": "51.027881,7.77242"
  },
  {
    "ID": 412037609,
    "Tankstellennummer": "TD021386",
    "Name": "NEUSTADT W.-KAULISCH-STR.49",
    "Address": "W.-KAULISCH-STR.49",
    "Address__1": "",
    "City": "NEUSTADT",
    "Postcode": 1844,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03596-604238",
    "24 hour?": "FALSE",
    "Latitude": 51.0288,
    "Longitude": 14.2086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0288,14.2086",
    "IsOpen24Hours": false,
    "id": "51.0288,14.2086"
  },
  {
    "ID": 513606804,
    "Tankstellennummer": "TD021337",
    "Name": "DRESDEN BODENBACHER STR. 64",
    "Address": "BODENBACHER STR. 64",
    "Address__1": "",
    "City": "DRESDEN",
    "Postcode": 1277,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-2561098",
    "24 hour?": "FALSE",
    "Latitude": 51.0294,
    "Longitude": 13.7907,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0294,13.7907",
    "IsOpen24Hours": false,
    "id": "51.0294,13.7907"
  },
  {
    "ID": 1053020769,
    "Tankstellennummer": "TD110600",
    "Name": "OLPE MARTINSTR.61",
    "Address": "MARTINSTR.61",
    "Address__1": "",
    "City": "OLPE",
    "Postcode": 57462,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02761-836593",
    "24 hour?": "FALSE",
    "Latitude": 51.0296,
    "Longitude": 7.854,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0296,7.854",
    "IsOpen24Hours": false,
    "id": "51.0296,7.854"
  },
  {
    "ID": 2074,
    "Tankstellennummer": "0F952",
    "Name": "Aral Tankstelle Odenthal, Altenberger Dom-Str. 2",
    "Address": "Altenberger Dom-Straße 2",
    "Address__1": "",
    "City": "Odenthal",
    "Postcode": 51519,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 220271845,
    "24 hour?": "FALSE",
    "Latitude": 51.031389,
    "Longitude": 7.111378,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.031389,7.111378",
    "IsOpen24Hours": false,
    "id": "51.031389,7.111378"
  },
  {
    "ID": 744644259,
    "Tankstellennummer": "TD021634",
    "Name": "DRESDEN WUERZBURGER STR. 26",
    "Address": "WUERZBURGER STR. 26",
    "Address__1": "",
    "City": "DRESDEN",
    "Postcode": 1187,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-4717777",
    "24 hour?": "FALSE",
    "Latitude": 51.0322,
    "Longitude": 13.7107,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0322,13.7107",
    "IsOpen24Hours": false,
    "id": "51.0322,13.7107"
  },
  {
    "ID": 1373,
    "Tankstellennummer": "0FE92",
    "Name": "Aral Tankstelle Köln, Oskar-Schindler-Str. 1",
    "Address": "Oskar-Schindler-Straße 1",
    "Address__1": "",
    "City": "Köln",
    "Postcode": 50769,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22170995092,
    "24 hour?": "TRUE",
    "Latitude": 51.032967,
    "Longitude": 6.919921,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.032967,6.919921",
    "IsOpen24Hours": true,
    "id": "51.032967,6.919921"
  },
  {
    "ID": 549663627,
    "Tankstellennummer": 5129,
    "Name": "Agip Dresden",
    "Address": "Enderstrasse 92 e",
    "Address__1": "",
    "City": "Dresden",
    "Postcode": 1277,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "0351 / 25020192",
    "24 hour?": "FALSE",
    "Latitude": 51.03351,
    "Longitude": 13.80604,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.03351,13.80604",
    "IsOpen24Hours": false,
    "id": "51.03351,13.80604"
  },
  {
    "ID": 1587667798,
    "Tankstellennummer": "TD000737",
    "Name": "ROMMERSKIRCHEN VENLOER STR. 46",
    "Address": "VENLOER STR. 46",
    "Address__1": "",
    "City": "ROMMERSKIRCHEN",
    "Postcode": 41569,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02183-9530",
    "24 hour?": "FALSE",
    "Latitude": 51.0344,
    "Longitude": 6.6877,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0344,6.6877",
    "IsOpen24Hours": false,
    "id": "51.0344,6.6877"
  },
  {
    "ID": 155133363,
    "Tankstellennummer": "TD150700",
    "Name": "OLPE WESTFAELISCHE STR.92",
    "Address": "WESTFAELISCHE STR.92",
    "Address__1": "",
    "City": "OLPE",
    "Postcode": 57462,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02761-601361",
    "24 hour?": "FALSE",
    "Latitude": 51.0355,
    "Longitude": 7.8555,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0355,7.8555",
    "IsOpen24Hours": false,
    "id": "51.0355,7.8555"
  },
  {
    "ID": 1314983412,
    "Tankstellennummer": "0FY39",
    "Name": "KNULLWALD, BAT HASSELBERG OST",
    "Address": "A7",
    "Address__1": "",
    "City": "Knüllwald",
    "Postcode": 34593,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56859220109,
    "24 hour?": "TRUE",
    "Latitude": 51.03567,
    "Longitude": 9.48933,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.03567,9.48933",
    "IsOpen24Hours": true,
    "id": "51.03567,9.48933"
  },
  {
    "ID": 221568643,
    "Tankstellennummer": "TD021329",
    "Name": "DRESDEN WIENER STR. 39",
    "Address": "WIENER STR. 39",
    "Address__1": "",
    "City": "DRESDEN",
    "Postcode": 1069,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-46927235",
    "24 hour?": "FALSE",
    "Latitude": 51.0361,
    "Longitude": 13.7485,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0361,13.7485",
    "IsOpen24Hours": false,
    "id": "51.0361,13.7485"
  },
  {
    "ID": 2131,
    "Tankstellennummer": "0F995",
    "Name": "Aral Tankstelle Rommerskirchen, Venloer Str.143",
    "Address": "Venloer Straße 143",
    "Address__1": "",
    "City": "Rommerskirchen",
    "Postcode": 41569,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21837079,
    "24 hour?": "TRUE",
    "Latitude": 51.036558,
    "Longitude": 6.677627,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.036558,6.677627",
    "IsOpen24Hours": true,
    "id": "51.036558,6.677627"
  },
  {
    "ID": 1821410934,
    "Tankstellennummer": "0FM08",
    "Name": "KNULLWALD",
    "Address": "A 7",
    "Address__1": "",
    "City": "Knüllwald",
    "Postcode": 34593,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5685922290,
    "24 hour?": "TRUE",
    "Latitude": 51.036835,
    "Longitude": 9.48776,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.036835,9.48776",
    "IsOpen24Hours": true,
    "id": "51.036835,9.48776"
  },
  {
    "ID": 1204612687,
    "Tankstellennummer": "TD035089",
    "Name": "DRESDEN KESSELSDORFER STR. 214",
    "Address": "KESSELSDORFER STR. 214",
    "Address__1": "",
    "City": "DRESDEN",
    "Postcode": 1169,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-4126824",
    "24 hour?": "FALSE",
    "Latitude": 51.0398,
    "Longitude": 13.6571,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0398,13.6571",
    "IsOpen24Hours": false,
    "id": "51.0398,13.6571"
  },
  {
    "ID": 3362,
    "Tankstellennummer": "0FL06",
    "Name": "Aral Tankstelle Apolda, Buttstaedter Str.",
    "Address": "Buttstaedter Straße 50",
    "Address__1": "",
    "City": "Apolda",
    "Postcode": 99510,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3644555516,
    "24 hour?": "TRUE",
    "Latitude": 51.040082,
    "Longitude": 11.511237,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.040082,11.511237",
    "IsOpen24Hours": true,
    "id": "51.040082,11.511237"
  },
  {
    "ID": 2035351633,
    "Tankstellennummer": "TD033886",
    "Name": "OSTERFELD AUTOHOF IM HEIDEGRUN",
    "Address": "IM HEIDEGRUND SUED 1",
    "Address__1": "",
    "City": "OSTERFELD",
    "Postcode": 6724,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034422-31018",
    "24 hour?": "FALSE",
    "Latitude": 51.0402,
    "Longitude": 11.9385,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0402,11.9385",
    "IsOpen24Hours": false,
    "id": "51.0402,11.9385"
  },
  {
    "ID": 474260839,
    "Tankstellennummer": "TD000846",
    "Name": "LEVERKUSEN BERLINER STR. 25",
    "Address": "BERLINER STR. 25",
    "Address__1": "",
    "City": "LEVERKUSEN",
    "Postcode": 51377,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02148-909415",
    "24 hour?": "FALSE",
    "Latitude": 51.0431,
    "Longitude": 7.0723,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0431,7.0723",
    "IsOpen24Hours": false,
    "id": "51.0431,7.0723"
  },
  {
    "ID": 536815881,
    "Tankstellennummer": "TD034462",
    "Name": "ZEITZ KLEEFELDPLATZ 1A",
    "Address": "KLEEFELDPLATZ 1a",
    "Address__1": "",
    "City": "ZEITZ",
    "Postcode": 6712,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03441-766130",
    "24 hour?": "FALSE",
    "Latitude": 51.0444,
    "Longitude": 12.1441,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0444,12.1441",
    "IsOpen24Hours": false,
    "id": "51.0444,12.1441"
  },
  {
    "ID": 1937907811,
    "Tankstellennummer": "TD023754",
    "Name": "ERFURT-STOTTERNHEIM ERFURTER L",
    "Address": "ERFURTER LANDSTRASSE 49",
    "Address__1": "",
    "City": "ERFURT-STOTTERNHEIM",
    "Postcode": 99095,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036204-52515",
    "24 hour?": "FALSE",
    "Latitude": 51.0462,
    "Longitude": 11.0421,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0462,11.0421",
    "IsOpen24Hours": false,
    "id": "51.0462,11.0421"
  },
  {
    "ID": 2991,
    "Tankstellennummer": "0FI43",
    "Name": "Aral Tankstelle Leverkusen, Steinbuecheler Str. 48",
    "Address": "Steinbuecheler Straße 48",
    "Address__1": "",
    "City": "Leverkusen",
    "Postcode": 51377,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2148505923,
    "24 hour?": "FALSE",
    "Latitude": 51.047945,
    "Longitude": 7.060423,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.047945,7.060423",
    "IsOpen24Hours": false,
    "id": "51.047945,7.060423"
  },
  {
    "ID": 322227843,
    "Tankstellennummer": "TD035766",
    "Name": "ELXLEBEN OSTERLANGE 1",
    "Address": "OSTERLANGE 1",
    "Address__1": "",
    "City": "ELXLEBEN",
    "Postcode": 99189,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036201-86263",
    "24 hour?": "FALSE",
    "Latitude": 51.048,
    "Longitude": 10.9421,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.048,10.9421",
    "IsOpen24Hours": false,
    "id": "51.048,10.9421"
  },
  {
    "ID": 1688,
    "Tankstellennummer": "0F692",
    "Name": "Aral Tankstelle Borken, Westrandstrasse 1",
    "Address": "Westrandstraße 1",
    "Address__1": "",
    "City": "Borken",
    "Postcode": 34582,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56822433,
    "24 hour?": "FALSE",
    "Latitude": 51.04911,
    "Longitude": 9.27302,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.04911,9.27302",
    "IsOpen24Hours": false,
    "id": "51.04911,9.27302"
  },
  {
    "ID": 1065485306,
    "Tankstellennummer": "TD021568",
    "Name": "DRESDEN HUEBLERSTR. 36",
    "Address": "HUEBLERSTR. 36",
    "Address__1": "",
    "City": "DRESDEN",
    "Postcode": 1309,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-40425070",
    "24 hour?": "FALSE",
    "Latitude": 51.0492,
    "Longitude": 13.7932,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0492,13.7932",
    "IsOpen24Hours": false,
    "id": "51.0492,13.7932"
  },
  {
    "ID": 1387282274,
    "Tankstellennummer": "TD025650",
    "Name": "FROHBURG PENIGER STR.40",
    "Address": "PENIGER STR.40",
    "Address__1": "",
    "City": "FROHBURG",
    "Postcode": 4654,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034348-51177",
    "24 hour?": "FALSE",
    "Latitude": 51.0497,
    "Longitude": 12.5563,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0497,12.5563",
    "IsOpen24Hours": false,
    "id": "51.0497,12.5563"
  },
  {
    "ID": 2583,
    "Tankstellennummer": "0FD18",
    "Name": "Aral Tankstelle Creuzburg, Bahnhofstr.",
    "Address": "Bahnhofstraße",
    "Address__1": "",
    "City": "Creuzburg",
    "Postcode": 99831,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3692698488,
    "24 hour?": "FALSE",
    "Latitude": 51.050454,
    "Longitude": 10.226457,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.050454,10.226457",
    "IsOpen24Hours": false,
    "id": "51.050454,10.226457"
  },
  {
    "ID": 54382485,
    "Tankstellennummer": "TD034439",
    "Name": "GEITHAIN PENIGER STR. 1B",
    "Address": "PENIGER STR. 1B",
    "Address__1": "",
    "City": "GEITHAIN",
    "Postcode": 4643,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034341-43567",
    "24 hour?": "FALSE",
    "Latitude": 51.0514,
    "Longitude": 12.7069,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0514,12.7069",
    "IsOpen24Hours": false,
    "id": "51.0514,12.7069"
  },
  {
    "ID": 1374,
    "Tankstellennummer": "0F458",
    "Name": "Aral Tankstelle Hückelhoven, Roermonder Str. 18",
    "Address": "Roermonder Straße 18",
    "Address__1": "",
    "City": "Hückelhoven",
    "Postcode": 41836,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2433938733,
    "24 hour?": "TRUE",
    "Latitude": 51.052715,
    "Longitude": 6.217374,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.052715,6.217374",
    "IsOpen24Hours": true,
    "id": "51.052715,6.217374"
  },
  {
    "ID": 1100098142,
    "Tankstellennummer": "TD032870",
    "Name": "WILSDRUFF UMGEHUNGSSTR. 18",
    "Address": "UMGEHUNGSSTR. 18",
    "Address__1": "",
    "City": "WILSDRUFF",
    "Postcode": 1723,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035204-61992",
    "24 hour?": "FALSE",
    "Latitude": 51.055,
    "Longitude": 13.5384,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.055,13.5384",
    "IsOpen24Hours": false,
    "id": "51.055,13.5384"
  },
  {
    "ID": 2981,
    "Tankstellennummer": "0FI33",
    "Name": "Aral Tankstelle Leverkusen, Solinger Str. 67",
    "Address": "Solinger Straße 67",
    "Address__1": "",
    "City": "Leverkusen",
    "Postcode": 51371,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21421349,
    "24 hour?": "TRUE",
    "Latitude": 51.056073,
    "Longitude": 6.953897,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.056073,6.953897",
    "IsOpen24Hours": true,
    "id": "51.056073,6.953897"
  },
  {
    "ID": 1186,
    "Tankstellennummer": "0F303",
    "Name": "Aral Tankstelle Bad Berleburg, Astenbergstr. 2",
    "Address": "Astenbergstraße 2",
    "Address__1": "",
    "City": "Bad Berleburg",
    "Postcode": 57319,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2751892558,
    "24 hour?": "FALSE",
    "Latitude": 51.056654,
    "Longitude": 8.392903,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.056654,8.392903",
    "IsOpen24Hours": false,
    "id": "51.056654,8.392903"
  },
  {
    "ID": 933909364,
    "Tankstellennummer": "TD021600",
    "Name": "DRESDEN GRUNDSTR. 99",
    "Address": "GRUNDSTR. 99",
    "Address__1": "",
    "City": "DRESDEN",
    "Postcode": 1326,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-2683294",
    "24 hour?": "FALSE",
    "Latitude": 51.0581,
    "Longitude": 13.8243,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0581,13.8243",
    "IsOpen24Hours": false,
    "id": "51.0581,13.8243"
  },
  {
    "ID": 1708,
    "Tankstellennummer": "0F704",
    "Name": "Aral Tankstelle Frankenberg, Röddenauer Str. 31",
    "Address": "Röddenauer Straße 31",
    "Address__1": "",
    "City": "Frankenberg",
    "Postcode": 35066,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 64516152,
    "24 hour?": "TRUE",
    "Latitude": 51.058242,
    "Longitude": 8.78391,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.058242,8.78391",
    "IsOpen24Hours": true,
    "id": "51.058242,8.78391"
  },
  {
    "ID": 546783145,
    "Tankstellennummer": "0FN63",
    "Name": "DRESDEN OT WEIßIG",
    "Address": "Bautzner Landstraße 246",
    "Address__1": "",
    "City": "Dresden OT Weißig",
    "Postcode": 1328,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3512690772,
    "24 hour?": "FALSE",
    "Latitude": 51.060353,
    "Longitude": 13.874702,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.060353,13.874702",
    "IsOpen24Hours": false,
    "id": "51.060353,13.874702"
  },
  {
    "ID": 2763,
    "Tankstellennummer": "0FE56",
    "Name": "Aral Autobahntankstelle Wilsdruff, Dresdner Tor Süd (A 4)",
    "Address": "Dresdner Tor Süd / A 4",
    "Address__1": "",
    "City": "Wilsdruff",
    "Postcode": 1723,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 352049430,
    "24 hour?": "TRUE",
    "Latitude": 51.061136,
    "Longitude": 13.568827,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.061136,13.568827",
    "IsOpen24Hours": true,
    "id": "51.061136,13.568827"
  },
  {
    "ID": 1835417260,
    "Tankstellennummer": "0FN21",
    "Name": "ROCHLITZ",
    "Address": "Colditzer Straße 7 c",
    "Address__1": "",
    "City": "Rochlitz",
    "Postcode": 9306,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 373741351,
    "24 hour?": "FALSE",
    "Latitude": 51.061148,
    "Longitude": 12.786807,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.061148,12.786807",
    "IsOpen24Hours": false,
    "id": "51.061148,12.786807"
  },
  {
    "ID": 480333440,
    "Tankstellennummer": "TD033019",
    "Name": "DRESDEN HAMBURGER STR.44",
    "Address": "HAMBURGER STR.44",
    "Address__1": "",
    "City": "DRESDEN",
    "Postcode": 1067,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-4942558",
    "24 hour?": "FALSE",
    "Latitude": 51.0613,
    "Longitude": 13.6938,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0613,13.6938",
    "IsOpen24Hours": false,
    "id": "51.0613,13.6938"
  },
  {
    "ID": 1769933942,
    "Tankstellennummer": "TD021360",
    "Name": "NOSSEN FABRIKSTR. 17",
    "Address": "FABRIKSTR. 17",
    "Address__1": "",
    "City": "NOSSEN",
    "Postcode": 1683,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035242-68259",
    "24 hour?": "FALSE",
    "Latitude": 51.0625,
    "Longitude": 13.2912,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0625,13.2912",
    "IsOpen24Hours": false,
    "id": "51.0625,13.2912"
  },
  {
    "ID": 2762,
    "Tankstellennummer": "0FE55",
    "Name": "Aral Autobahntankstelle Wilsdruff, Dresdner Tor Nord (A 4)",
    "Address": "Dresdner Tor Nord / A 4",
    "Address__1": "",
    "City": "Wilsdruff",
    "Postcode": 1723,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 352049420,
    "24 hour?": "TRUE",
    "Latitude": 51.062505,
    "Longitude": 13.575056,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.062505,13.575056",
    "IsOpen24Hours": true,
    "id": "51.062505,13.575056"
  },
  {
    "ID": 2689,
    "Tankstellennummer": "0FE08",
    "Name": "Aral Tankstelle Dresden, Hamburger Str.88 c",
    "Address": "Hamburger Straße 88 c",
    "Address__1": "",
    "City": "Dresden",
    "Postcode": 1157,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3514226969,
    "24 hour?": "TRUE",
    "Latitude": 51.062807,
    "Longitude": 13.679735,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.062807,13.679735",
    "IsOpen24Hours": true,
    "id": "51.062807,13.679735"
  },
  {
    "ID": 1656,
    "Tankstellennummer": "0F669",
    "Name": "Aral Tankstelle Frankenberg, Ederstrasse 18",
    "Address": "Ederstraße 18",
    "Address__1": "",
    "City": "Frankenberg",
    "Postcode": 35066,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 64514412,
    "24 hour?": "FALSE",
    "Latitude": 51.064271,
    "Longitude": 8.8046,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.064271,8.8046",
    "IsOpen24Hours": false,
    "id": "51.064271,8.8046"
  },
  {
    "ID": 280806226,
    "Tankstellennummer": "TD020917",
    "Name": "DRESDEN BAUTZNER STR. 72",
    "Address": "BAUTZNER STR. 72",
    "Address__1": "",
    "City": "DRESDEN",
    "Postcode": 1099,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-8049180",
    "24 hour?": "FALSE",
    "Latitude": 51.0644,
    "Longitude": 13.7636,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0644,13.7636",
    "IsOpen24Hours": false,
    "id": "51.0644,13.7636"
  },
  {
    "ID": 1044325376,
    "Tankstellennummer": "TD000933",
    "Name": "HEINSBERG BORSIGSTRASSE 42",
    "Address": "BORSIGSTRASSE 42",
    "Address__1": "",
    "City": "HEINSBERG",
    "Postcode": 52525,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02452-101007",
    "24 hour?": "FALSE",
    "Latitude": 51.0658,
    "Longitude": 6.1131,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0658,6.1131",
    "IsOpen24Hours": false,
    "id": "51.0658,6.1131"
  },
  {
    "ID": 747392046,
    "Tankstellennummer": "TD121500",
    "Name": "HEINSBERG LIECKER STR. 62",
    "Address": "LIECKER STR. 62",
    "Address__1": "",
    "City": "HEINSBERG",
    "Postcode": 52525,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02452-155476",
    "24 hour?": "FALSE",
    "Latitude": 51.0664,
    "Longitude": 6.088,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0664,6.088",
    "IsOpen24Hours": false,
    "id": "51.0664,6.088"
  },
  {
    "ID": 2090,
    "Tankstellennummer": "0F965",
    "Name": "Aral Tankstelle Leverkusen, Bonner Str.  2",
    "Address": "Bonner Straße 2",
    "Address__1": "",
    "City": "Leverkusen",
    "Postcode": 51379,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2171715830,
    "24 hour?": "TRUE",
    "Latitude": 51.066892,
    "Longitude": 6.991081,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.066892,6.991081",
    "IsOpen24Hours": true,
    "id": "51.066892,6.991081"
  },
  {
    "ID": 594414962,
    "Tankstellennummer": "TD039156",
    "Name": "LEVERKUSEN POMMERNSTR. 16",
    "Address": "POMMERNSTR. 16",
    "Address__1": "",
    "City": "LEVERKUSEN",
    "Postcode": 51379,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02171-7370168",
    "24 hour?": "FALSE",
    "Latitude": 51.0681,
    "Longitude": 7.0168,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0681,7.0168",
    "IsOpen24Hours": false,
    "id": "51.0681,7.0168"
  },
  {
    "ID": 535815748,
    "Tankstellennummer": "TD000059",
    "Name": "HUECKELHOVEN HEERSTR.60",
    "Address": "HEERSTR.60",
    "Address__1": "",
    "City": "HUECKELHOVEN",
    "Postcode": 41836,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02433-5515",
    "24 hour?": "FALSE",
    "Latitude": 51.0687,
    "Longitude": 6.1855,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0687,6.1855",
    "IsOpen24Hours": false,
    "id": "51.0687,6.1855"
  },
  {
    "ID": 2699,
    "Tankstellennummer": "0FE15",
    "Name": "Aral Tankstelle Dresden, Werftstr. 13",
    "Address": "Werftstraße 13",
    "Address__1": "",
    "City": "Dresden",
    "Postcode": 1139,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3518496290,
    "24 hour?": "TRUE",
    "Latitude": 51.068993,
    "Longitude": 13.689975,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.068993,13.689975",
    "IsOpen24Hours": true,
    "id": "51.068993,13.689975"
  },
  {
    "ID": 2657,
    "Tankstellennummer": "0FD77",
    "Name": "Aral Tankstelle Zeitz, Weißenfelser Str. 98",
    "Address": "Weißenfelser Straße 98",
    "Address__1": "",
    "City": "Zeitz",
    "Postcode": 6712,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3441214095,
    "24 hour?": "FALSE",
    "Latitude": 51.069362,
    "Longitude": 12.121917,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.069362,12.121917",
    "IsOpen24Hours": false,
    "id": "51.069362,12.121917"
  },
  {
    "ID": 2607,
    "Tankstellennummer": "0FD37",
    "Name": "Aral Tankstelle Dresden, Fischhausstr. 15",
    "Address": "Fischhausstraße 15",
    "Address__1": "",
    "City": "Dresden",
    "Postcode": 1099,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3518043050,
    "24 hour?": "TRUE",
    "Latitude": 51.07046,
    "Longitude": 13.788781,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.07046,13.788781",
    "IsOpen24Hours": true,
    "id": "51.07046,13.788781"
  },
  {
    "ID": 53551076,
    "Tankstellennummer": "TD007120",
    "Name": "ROSSWEIN VOGELSTANGE 17",
    "Address": "VOGELSTANGE 17",
    "Address__1": "",
    "City": "ROSSWEIN",
    "Postcode": 4741,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034322-43775",
    "24 hour?": "FALSE",
    "Latitude": 51.0722,
    "Longitude": 13.1846,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0722,13.1846",
    "IsOpen24Hours": false,
    "id": "51.0722,13.1846"
  },
  {
    "ID": 2973,
    "Tankstellennummer": "0FI26",
    "Name": "Aral Tankstelle Leverkusen, Burscheider Str. 154",
    "Address": "Burscheider Straße 154",
    "Address__1": "",
    "City": "Leverkusen",
    "Postcode": 51381,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2171734035,
    "24 hour?": "FALSE",
    "Latitude": 51.079371,
    "Longitude": 7.03726,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.079371,7.03726",
    "IsOpen24Hours": false,
    "id": "51.079371,7.03726"
  },
  {
    "ID": 226467928,
    "Tankstellennummer": "TD037762",
    "Name": "DRESDEN KOENIGSBRUECKER STR. 1",
    "Address": "KOENIGSBRUECKER STR. 104",
    "Address__1": "",
    "City": "DRESDEN",
    "Postcode": 1099,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-8106054",
    "24 hour?": "FALSE",
    "Latitude": 51.0831,
    "Longitude": 13.7616,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0831,13.7616",
    "IsOpen24Hours": false,
    "id": "51.0831,13.7616"
  },
  {
    "ID": 2975,
    "Tankstellennummer": "0FI28",
    "Name": "Aral Tankstelle Burscheid, Hoehestr. 51",
    "Address": "Hoehestraße 51",
    "Address__1": "",
    "City": "Burscheid",
    "Postcode": 51399,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2174789535,
    "24 hour?": "TRUE",
    "Latitude": 51.084205,
    "Longitude": 7.127536,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.084205,7.127536",
    "IsOpen24Hours": true,
    "id": "51.084205,7.127536"
  },
  {
    "ID": 768847477,
    "Tankstellennummer": 610256,
    "Name": "WESTFALEN, WABERN",
    "Address": "Hauptstr. 1a",
    "Address__1": "",
    "City": "Wabern",
    "Postcode": 34590,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05683/8756",
    "24 hour?": "TRUE",
    "Latitude": 51.0843,
    "Longitude": 9.374,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.0843,9.374",
    "IsOpen24Hours": true,
    "id": "51.0843,9.374"
  },
  {
    "ID": 2083696573,
    "Tankstellennummer": "TD035063",
    "Name": "DRESDEN DRESDNER STR. 49",
    "Address": "DRESDNER STR. 49",
    "Address__1": "",
    "City": "DRESDEN",
    "Postcode": 1156,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-4540000",
    "24 hour?": "FALSE",
    "Latitude": 51.0871,
    "Longitude": 13.6404,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0871,13.6404",
    "IsOpen24Hours": false,
    "id": "51.0871,13.6404"
  },
  {
    "ID": 3384,
    "Tankstellennummer": "0FL25",
    "Name": "Aral Tankstelle Dresden, Kötzschenbroder Str. 188",
    "Address": "Kötzschenbroder Str. 188",
    "Address__1": "",
    "City": "Dresden",
    "Postcode": 1139,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3518301510,
    "24 hour?": "FALSE",
    "Latitude": 51.090615,
    "Longitude": 13.675574,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.090615,13.675574",
    "IsOpen24Hours": false,
    "id": "51.090615,13.675574"
  },
  {
    "ID": 813767859,
    "Tankstellennummer": "0FO60",
    "Name": "WERMELSKIRCHEN",
    "Address": "Altenberger Straße 93",
    "Address__1": "",
    "City": "Wermelskirchen",
    "Postcode": 42929,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21934691,
    "24 hour?": "FALSE",
    "Latitude": 51.091155,
    "Longitude": 7.186871,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.091155,7.186871",
    "IsOpen24Hours": false,
    "id": "51.091155,7.186871"
  },
  {
    "ID": 2986,
    "Tankstellennummer": "0FI38",
    "Name": "Aral Tankstelle Monheim, Gartzenweg 58",
    "Address": "Gartzenweg 58",
    "Address__1": "",
    "City": "Monheim",
    "Postcode": 40789,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 217356997,
    "24 hour?": "FALSE",
    "Latitude": 51.091676,
    "Longitude": 6.897396,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.091676,6.897396",
    "IsOpen24Hours": false,
    "id": "51.091676,6.897396"
  },
  {
    "ID": 1940050558,
    "Tankstellennummer": "0FN61",
    "Name": "DRESDEN",
    "Address": "Kötzschenbroder Str. 193",
    "Address__1": "",
    "City": "Dresden",
    "Postcode": 1139,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3518371511,
    "24 hour?": "FALSE",
    "Latitude": 51.092601,
    "Longitude": 13.670501,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.092601,13.670501",
    "IsOpen24Hours": false,
    "id": "51.092601,13.670501"
  },
  {
    "ID": 2091,
    "Tankstellennummer": "0F966",
    "Name": "Aral Tankstelle Burscheid, Dünweg 55",
    "Address": "Dünweg 55",
    "Address__1": "",
    "City": "Burscheid",
    "Postcode": 51399,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2174786368,
    "24 hour?": "FALSE",
    "Latitude": 51.093802,
    "Longitude": 7.145046,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.093802,7.145046",
    "IsOpen24Hours": false,
    "id": "51.093802,7.145046"
  },
  {
    "ID": 2174,
    "Tankstellennummer": "0FA28",
    "Name": "Aral Tankstelle Heinsberg, Roermonder Str. 41",
    "Address": "Roermonder Straße 41",
    "Address__1": "",
    "City": "Heinsberg",
    "Postcode": 52525,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24527601,
    "24 hour?": "FALSE",
    "Latitude": 51.094157,
    "Longitude": 6.08197,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.094157,6.08197",
    "IsOpen24Hours": false,
    "id": "51.094157,6.08197"
  },
  {
    "ID": 3354,
    "Tankstellennummer": "0FK98",
    "Name": "Aral Tankstelle Dresden, Radeburger Str./Hellerhof",
    "Address": "Radeburger Straße/Hellerhof",
    "Address__1": "",
    "City": "Dresden",
    "Postcode": 1129,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3518498840,
    "24 hour?": "TRUE",
    "Latitude": 51.094532,
    "Longitude": 13.736135,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.094532,13.736135",
    "IsOpen24Hours": true,
    "id": "51.094532,13.736135"
  },
  {
    "ID": 119709845,
    "Tankstellennummer": 610365,
    "Name": "WESTFALEN, DORMAGEN",
    "Address": "Lübecker Str. 1",
    "Address__1": "",
    "City": "Dormagen",
    "Postcode": 41540,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02133/219019",
    "24 hour?": "FALSE",
    "Latitude": 51.0949,
    "Longitude": 6.81309,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.0949,6.81309",
    "IsOpen24Hours": false,
    "id": "51.0949,6.81309"
  },
  {
    "ID": 2108,
    "Tankstellennummer": "0F980",
    "Name": "Aral Tankstelle Erkelenz, Lauerstr. 12",
    "Address": "Lauerstraße 12",
    "Address__1": "",
    "City": "Erkelenz",
    "Postcode": 41812,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24326252,
    "24 hour?": "FALSE",
    "Latitude": 51.096317,
    "Longitude": 6.215489,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.096317,6.215489",
    "IsOpen24Hours": false,
    "id": "51.096317,6.215489"
  },
  {
    "ID": 2771,
    "Tankstellennummer": "0FE63",
    "Name": "Aral Tankstelle Kirschau, Bautzener Str. 22",
    "Address": "Bautzener Straße 78",
    "Address__1": "",
    "City": "Schirgiswalde-Kirsch",
    "Postcode": 2681,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3592380130,
    "24 hour?": "FALSE",
    "Latitude": 51.096552,
    "Longitude": 14.430656,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.096552,14.430656",
    "IsOpen24Hours": false,
    "id": "51.096552,14.430656"
  },
  {
    "ID": 1226,
    "Tankstellennummer": "0F333",
    "Name": "Aral Tankstelle Lennestadt, Hundemstr.75",
    "Address": "Hundemstraße 75",
    "Address__1": "",
    "City": "Lennestadt",
    "Postcode": 57368,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27236200,
    "24 hour?": "FALSE",
    "Latitude": 51.101167,
    "Longitude": 8.070988,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.101167,8.070988",
    "IsOpen24Hours": false,
    "id": "51.101167,8.070988"
  },
  {
    "ID": 3015,
    "Tankstellennummer": "0FI61",
    "Name": "Aral Tankstelle Wabern, Landgrafenstr. 5",
    "Address": "Landgrafenstraße 5",
    "Address__1": "",
    "City": "Wabern",
    "Postcode": 34590,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5683930315,
    "24 hour?": "FALSE",
    "Latitude": 51.102165,
    "Longitude": 9.349691,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.102165,9.349691",
    "IsOpen24Hours": false,
    "id": "51.102165,9.349691"
  },
  {
    "ID": 506267035,
    "Tankstellennummer": "TD034926",
    "Name": "LOEBAU WEISSENBERGER STR. 10",
    "Address": "WEISSENBERGER STR. 10",
    "Address__1": "",
    "City": "LOEBAU",
    "Postcode": 2708,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03585-860090",
    "24 hour?": "FALSE",
    "Latitude": 51.1033,
    "Longitude": 14.6702,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1033,14.6702",
    "IsOpen24Hours": false,
    "id": "51.1033,14.6702"
  },
  {
    "ID": 2701,
    "Tankstellennummer": "0FE17",
    "Name": "Aral Tankstelle Bad Langensalza, Tonnaer Str. 30 b",
    "Address": "Tonnaer Straße 30 b",
    "Address__1": "",
    "City": "Bad Langensalza",
    "Postcode": 99947,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3603815726,
    "24 hour?": "TRUE",
    "Latitude": 51.104,
    "Longitude": 10.663811,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.104,10.663811",
    "IsOpen24Hours": true,
    "id": "51.104,10.663811"
  },
  {
    "ID": 1672405315,
    "Tankstellennummer": "TD033431",
    "Name": "HARTHA TOEPELSTR. / AN DER B17",
    "Address": "TOEPELSTR.",
    "Address__1": "",
    "City": "HARTHA",
    "Postcode": 4746,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034328-60424",
    "24 hour?": "FALSE",
    "Latitude": 51.1043,
    "Longitude": 12.9623,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1043,12.9623",
    "IsOpen24Hours": false,
    "id": "51.1043,12.9623"
  },
  {
    "ID": 759408118,
    "Tankstellennummer": "TD032672",
    "Name": "BAD LANGENSALZA TONNAER STR. 2",
    "Address": "TONNAER STR. 28",
    "Address__1": "",
    "City": "BAD LANGENSALZA",
    "Postcode": 99947,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03603-842355",
    "24 hour?": "FALSE",
    "Latitude": 51.1043,
    "Longitude": 10.6644,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1043,10.6644",
    "IsOpen24Hours": false,
    "id": "51.1043,10.6644"
  },
  {
    "ID": 1668126951,
    "Tankstellennummer": 5005,
    "Name": "Agip Loebau",
    "Address": "Aeussere Bautzener Str. 38",
    "Address__1": "",
    "City": "Loebau",
    "Postcode": 2708,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 35858606",
    "24 hour?": "TRUE",
    "Latitude": 51.10569485,
    "Longitude": 14.65293466,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.10569485,14.65293466",
    "IsOpen24Hours": true,
    "id": "51.10569485,14.65293466"
  },
  {
    "ID": 2109,
    "Tankstellennummer": "0F981",
    "Name": "Aral Tankstelle Wassenberg, Gladbacher Str.21",
    "Address": "Gladbacher Straße 21",
    "Address__1": "",
    "City": "Wassenberg",
    "Postcode": 41849,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 243220725,
    "24 hour?": "FALSE",
    "Latitude": 51.107243,
    "Longitude": 6.178586,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.107243,6.178586",
    "IsOpen24Hours": false,
    "id": "51.107243,6.178586"
  },
  {
    "ID": 1338,
    "Tankstellennummer": "0F426",
    "Name": "Aral Tankstelle Dormagen, Krefelder Str. 101",
    "Address": "Krefelder Straße 101",
    "Address__1": "",
    "City": "Dormagen",
    "Postcode": 41539,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21333310,
    "24 hour?": "TRUE",
    "Latitude": 51.10837,
    "Longitude": 6.825619,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.10837,6.825619",
    "IsOpen24Hours": true,
    "id": "51.10837,6.825619"
  },
  {
    "ID": 1861413776,
    "Tankstellennummer": "0FU99",
    "Name": "BAD LANGENSALZA",
    "Address": "Sondershäuser Straße 2",
    "Address__1": "",
    "City": "Bad Langensalza",
    "Postcode": 99947,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 36038953177,
    "24 hour?": "FALSE",
    "Latitude": 51.113495,
    "Longitude": 10.659204,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.113495,10.659204",
    "IsOpen24Hours": false,
    "id": "51.113495,10.659204"
  },
  {
    "ID": 2145,
    "Tankstellennummer": "0FA04",
    "Name": "Aral Tankstelle Jüchen, Bedburdycker Straße",
    "Address": "Schulstraße 83",
    "Address__1": "",
    "City": "Jüchen",
    "Postcode": 41363,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21817069775,
    "24 hour?": "FALSE",
    "Latitude": 51.114059,
    "Longitude": 6.550157,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.114059,6.550157",
    "IsOpen24Hours": false,
    "id": "51.114059,6.550157"
  },
  {
    "ID": 2613,
    "Tankstellennummer": "0FD40",
    "Name": "Aral Tankstelle Borna, B 93",
    "Address": "Am Wilhelmschacht 17",
    "Address__1": "",
    "City": "Borna",
    "Postcode": 4552,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3433208803,
    "24 hour?": "TRUE",
    "Latitude": 51.116192,
    "Longitude": 12.485927,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.116192,12.485927",
    "IsOpen24Hours": true,
    "id": "51.116192,12.485927"
  },
  {
    "ID": 3014,
    "Tankstellennummer": "0FI60",
    "Name": "Aral Tankstelle Spangenberg, Berliner Str. 2",
    "Address": "Berliner Straße 2",
    "Address__1": "",
    "City": "Spangenberg",
    "Postcode": 34286,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56636310,
    "24 hour?": "FALSE",
    "Latitude": 51.116439,
    "Longitude": 9.657353,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.116439,9.657353",
    "IsOpen24Hours": false,
    "id": "51.116439,9.657353"
  },
  {
    "ID": 3001,
    "Tankstellennummer": "0FI52",
    "Name": "Aral Tankstelle Leichlingen, Hauptstr. 89",
    "Address": "Hauptstraße 89",
    "Address__1": "",
    "City": "Leichlingen",
    "Postcode": 42799,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 217438058,
    "24 hour?": "FALSE",
    "Latitude": 51.118133,
    "Longitude": 7.12508,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.118133,7.12508",
    "IsOpen24Hours": false,
    "id": "51.118133,7.12508"
  },
  {
    "ID": 1112532097,
    "Tankstellennummer": "TD005777",
    "Name": "ATTENDORN KOELNER STR. 86",
    "Address": "KOELNER STR. 86",
    "Address__1": "",
    "City": "ATTENDORN",
    "Postcode": 57439,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02722-3235",
    "24 hour?": "FALSE",
    "Latitude": 51.1187,
    "Longitude": 7.8933,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1187,7.8933",
    "IsOpen24Hours": false,
    "id": "51.1187,7.8933"
  },
  {
    "ID": 2124,
    "Tankstellennummer": "0F990",
    "Name": "Aral Tankstelle Langenfeld, Hardt 76",
    "Address": "Hardt 76",
    "Address__1": "",
    "City": "Langenfeld",
    "Postcode": 40764,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2173907790,
    "24 hour?": "FALSE",
    "Latitude": 51.119474,
    "Longitude": 6.97357,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.119474,6.97357",
    "IsOpen24Hours": false,
    "id": "51.119474,6.97357"
  },
  {
    "ID": 2597,
    "Tankstellennummer": "0FD28",
    "Name": "Aral Tankstelle Döbeln, Dresdener Str.",
    "Address": "Dresdener Straße 30 t",
    "Address__1": "",
    "City": "Döbeln",
    "Postcode": 4720,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3431702392,
    "24 hour?": "TRUE",
    "Latitude": 51.120479,
    "Longitude": 13.149826,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.120479,13.149826",
    "IsOpen24Hours": true,
    "id": "51.120479,13.149826"
  },
  {
    "ID": 1989083414,
    "Tankstellennummer": 5117,
    "Name": "Agip Dresden",
    "Address": "Flughafenstrasse 81",
    "Address__1": "",
    "City": "Dresden",
    "Postcode": 1109,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 35189031",
    "24 hour?": "TRUE",
    "Latitude": 51.12055389,
    "Longitude": 13.76287394,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.12055389,13.76287394",
    "IsOpen24Hours": true,
    "id": "51.12055389,13.76287394"
  },
  {
    "ID": 2107891584,
    "Tankstellennummer": "TD007252",
    "Name": "DOEBELN DRESDNER STR. 30 O",
    "Address": "DRESDNER STR. 30 o",
    "Address__1": "",
    "City": "DOEBELN",
    "Postcode": 4720,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03431-574700",
    "24 hour?": "FALSE",
    "Latitude": 51.1208,
    "Longitude": 13.1507,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1208,13.1507",
    "IsOpen24Hours": false,
    "id": "51.1208,13.1507"
  },
  {
    "ID": 1085111774,
    "Tankstellennummer": "0F655",
    "Name": "Aral TankStelle Fritzlar, Gießener Straße 60",
    "Address": "Gießener Straße 60",
    "Address__1": "",
    "City": "Fritzlar",
    "Postcode": 34560,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5622930418,
    "24 hour?": "FALSE",
    "Latitude": 51.120846,
    "Longitude": 9.277641,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.120846,9.277641",
    "IsOpen24Hours": false,
    "id": "51.120846,9.277641"
  },
  {
    "ID": 1917845630,
    "Tankstellennummer": "TD124180",
    "Name": "GREVENBROICH JAEGERHOF 10",
    "Address": "JAEGERHOF 10",
    "Address__1": "",
    "City": "GREVENBROICH",
    "Postcode": 41516,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02182-885034",
    "24 hour?": "FALSE",
    "Latitude": 51.1218,
    "Longitude": 6.6667,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1218,6.6667",
    "IsOpen24Hours": false,
    "id": "51.1218,6.6667"
  },
  {
    "ID": 3000,
    "Tankstellennummer": "0FI51",
    "Name": "Aral Tankstelle Wipperfürth, Leiersmühle 5",
    "Address": "Leiersmühle 5",
    "Address__1": "",
    "City": "Wipperfürth",
    "Postcode": 51688,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 22671847,
    "24 hour?": "FALSE",
    "Latitude": 51.121847,
    "Longitude": 7.410305,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.121847,7.410305",
    "IsOpen24Hours": false,
    "id": "51.121847,7.410305"
  },
  {
    "ID": 2970,
    "Tankstellennummer": "0FI23",
    "Name": "Aral Tankstelle Attendorn, Am Wassertor 17",
    "Address": "Am Wassertor 17",
    "Address__1": "",
    "City": "Attendorn",
    "Postcode": 57439,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 27224323,
    "24 hour?": "TRUE",
    "Latitude": 51.12274,
    "Longitude": 7.907254,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.12274,7.907254",
    "IsOpen24Hours": true,
    "id": "51.12274,7.907254"
  },
  {
    "ID": 1993217674,
    "Tankstellennummer": "TD111670",
    "Name": "MELSUNGEN NUERNBERGER STR. 16",
    "Address": "NUERNBERGER STR. 16",
    "Address__1": "",
    "City": "MELSUNGEN",
    "Postcode": 34212,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05661-921977",
    "24 hour?": "FALSE",
    "Latitude": 51.1234,
    "Longitude": 9.5414,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1234,9.5414",
    "IsOpen24Hours": false,
    "id": "51.1234,9.5414"
  },
  {
    "ID": 579801800,
    "Tankstellennummer": "TD038950",
    "Name": "DORMAGEN NIEVENHEIM WEST A 57",
    "Address": "NIEVENHEIM WEST A57",
    "Address__1": "",
    "City": "DORMAGEN",
    "Postcode": 41542,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02133-70550",
    "24 hour?": "FALSE",
    "Latitude": 51.1234,
    "Longitude": 6.7959,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1234,6.7959",
    "IsOpen24Hours": false,
    "id": "51.1234,6.7959"
  },
  {
    "ID": 1229,
    "Tankstellennummer": "0F336",
    "Name": "Aral Tankstelle Kierspe, Kölner Str.154",
    "Address": "Kölner Straße 154",
    "Address__1": "",
    "City": "Kierspe",
    "Postcode": 58566,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23592057,
    "24 hour?": "FALSE",
    "Latitude": 51.124574,
    "Longitude": 7.60335,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.124574,7.60335",
    "IsOpen24Hours": false,
    "id": "51.124574,7.60335"
  },
  {
    "ID": 232771448,
    "Tankstellennummer": "TD035808",
    "Name": "SCHOENSTEDT AN DER B 247",
    "Address": "AN DER B 247",
    "Address__1": "",
    "City": "SCHOENSTEDT",
    "Postcode": 99947,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036022-91919",
    "24 hour?": "FALSE",
    "Latitude": 51.1248,
    "Longitude": 10.5816,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1248,10.5816",
    "IsOpen24Hours": false,
    "id": "51.1248,10.5816"
  },
  {
    "ID": 2105,
    "Tankstellennummer": "0F977",
    "Name": "Aral Tankstelle Mönchengladbach, Kölner Str. 107",
    "Address": "Kölner Straße 107",
    "Address__1": "",
    "City": "Mönchengladbach",
    "Postcode": 41199,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2166601018,
    "24 hour?": "FALSE",
    "Latitude": 51.127934,
    "Longitude": 6.453592,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.127934,6.453592",
    "IsOpen24Hours": false,
    "id": "51.127934,6.453592"
  },
  {
    "ID": 1677,
    "Tankstellennummer": "0F682",
    "Name": "Aral Tankstelle Melsungen, Fritzlarer Str.61",
    "Address": "Fritzlarer Straße 61",
    "Address__1": "",
    "City": "Melsungen",
    "Postcode": 34212,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5661920715,
    "24 hour?": "FALSE",
    "Latitude": 51.128955,
    "Longitude": 9.537404,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.128955,9.537404",
    "IsOpen24Hours": false,
    "id": "51.128955,9.537404"
  },
  {
    "ID": 378351531,
    "Tankstellennummer": "TD000806",
    "Name": "GREVENBROICH BAB VIERWINDEN-NO",
    "Address": "BAB VIERWINDEN-NORD / A46",
    "Address__1": "",
    "City": "GREVENBROICH",
    "Postcode": 41516,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02182-1468",
    "24 hour?": "FALSE",
    "Latitude": 51.13,
    "Longitude": 6.6041,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.13,6.6041",
    "IsOpen24Hours": false,
    "id": "51.13,6.6041"
  },
  {
    "ID": 2603,
    "Tankstellennummer": "0FD33",
    "Name": "Aral Tankstelle Radeberg, Badstr. 69",
    "Address": "Badstraße 69",
    "Address__1": "",
    "City": "Radeberg",
    "Postcode": 1454,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3528442320,
    "24 hour?": "TRUE",
    "Latitude": 51.130991,
    "Longitude": 13.916689,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.130991,13.916689",
    "IsOpen24Hours": true,
    "id": "51.130991,13.916689"
  },
  {
    "ID": 2092965121,
    "Tankstellennummer": "TD024927",
    "Name": "BORNA LEIPZIGER STR.",
    "Address": "LEIPZIGER STRASSE 2F",
    "Address__1": "",
    "City": "BORNA",
    "Postcode": 4552,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03433-208626",
    "24 hour?": "FALSE",
    "Latitude": 51.1323,
    "Longitude": 12.5057,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1323,12.5057",
    "IsOpen24Hours": false,
    "id": "51.1323,12.5057"
  },
  {
    "ID": 2615,
    "Tankstellennummer": "0FD42",
    "Name": "Aral Tankstelle Coswig, Weinböhlaer Str. 46",
    "Address": "Weinböhlaer Straße 46",
    "Address__1": "",
    "City": "Coswig",
    "Postcode": 1640,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 352373629,
    "24 hour?": "TRUE",
    "Latitude": 51.135477,
    "Longitude": 13.576879,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.135477,13.576879",
    "IsOpen24Hours": true,
    "id": "51.135477,13.576879"
  },
  {
    "ID": 572720418,
    "Tankstellennummer": 610381,
    "Name": "WESTFALEN, FRITZLAR",
    "Address": "Kasseler Str. 38",
    "Address__1": "",
    "City": "Fritzlar",
    "Postcode": 34560,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05622/4232",
    "24 hour?": "TRUE",
    "Latitude": 51.1357,
    "Longitude": 9.2725,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.1357,9.2725",
    "IsOpen24Hours": true,
    "id": "51.1357,9.2725"
  },
  {
    "ID": 1082326263,
    "Tankstellennummer": "TD020867",
    "Name": "BISCHOFSWERDA BAUTZENER STR. 1",
    "Address": "BAUTZENER STR. 121",
    "Address__1": "",
    "City": "BISCHOFSWERDA",
    "Postcode": 1877,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03594-703427",
    "24 hour?": "FALSE",
    "Latitude": 51.1366,
    "Longitude": 14.1918,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1366,14.1918",
    "IsOpen24Hours": false,
    "id": "51.1366,14.1918"
  },
  {
    "ID": 771730487,
    "Tankstellennummer": 5062,
    "Name": "Agip Colditz",
    "Address": "Flurteil Eule 2",
    "Address__1": "",
    "City": "Colditz",
    "Postcode": 4680,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 34381431",
    "24 hour?": "FALSE",
    "Latitude": 51.13726944,
    "Longitude": 12.79151064,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.13726944,12.79151064",
    "IsOpen24Hours": false,
    "id": "51.13726944,12.79151064"
  },
  {
    "ID": 3287,
    "Tankstellennummer": "0FK49",
    "Name": "Aral Tankstelle Düsseldorf, Frankfurter Str. 323",
    "Address": "Frankfurter Straße 323",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40595,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2117053523,
    "24 hour?": "TRUE",
    "Latitude": 51.138005,
    "Longitude": 6.904963,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.138005,6.904963",
    "IsOpen24Hours": true,
    "id": "51.138005,6.904963"
  },
  {
    "ID": 2112,
    "Tankstellennummer": "0F983",
    "Name": "Aral Tankstelle Wegberg, Beecker Str.79",
    "Address": "Beecker Straße 79",
    "Address__1": "",
    "City": "Wegberg",
    "Postcode": 41844,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 24343371,
    "24 hour?": "FALSE",
    "Latitude": 51.138192,
    "Longitude": 6.287354,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.138192,6.287354",
    "IsOpen24Hours": false,
    "id": "51.138192,6.287354"
  },
  {
    "ID": 866008842,
    "Tankstellennummer": "TD031385",
    "Name": "BAD KOESEN AN DER B87",
    "Address": "AN DER B87",
    "Address__1": "",
    "City": "BAD KOESEN",
    "Postcode": 6628,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034463-26808",
    "24 hour?": "FALSE",
    "Latitude": 51.1389,
    "Longitude": 11.7302,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1389,11.7302",
    "IsOpen24Hours": false,
    "id": "51.1389,11.7302"
  },
  {
    "ID": 1757278843,
    "Tankstellennummer": 610407,
    "Name": "WESTFALEN, FRITZLAR",
    "Address": "Brautäcker 25",
    "Address__1": "",
    "City": "Fritzlar",
    "Postcode": 34560,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05622/9195652",
    "24 hour?": "TRUE",
    "Latitude": 51.14,
    "Longitude": 9.27479,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.14,9.27479",
    "IsOpen24Hours": true,
    "id": "51.14,9.27479"
  },
  {
    "ID": 1671361344,
    "Tankstellennummer": "TD033068",
    "Name": "DRESDEN-WEIXDORF KOENIGSBRUECK",
    "Address": "KOENIGSBRUECKER LANDSTR. 265",
    "Address__1": "",
    "City": "DRESDEN-WEIXDORF",
    "Postcode": 1108,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0351-8802301",
    "24 hour?": "FALSE",
    "Latitude": 51.1404,
    "Longitude": 13.7976,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1404,13.7976",
    "IsOpen24Hours": false,
    "id": "51.1404,13.7976"
  },
  {
    "ID": 584985619,
    "Tankstellennummer": "TD025643",
    "Name": "BAD LAUSICK REICHERSDORFER STR",
    "Address": "REICHERSDORFER STR. 9",
    "Address__1": "",
    "City": "BAD LAUSICK",
    "Postcode": 4651,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034345-22864",
    "24 hour?": "FALSE",
    "Latitude": 51.1407,
    "Longitude": 12.6501,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1407,12.6501",
    "IsOpen24Hours": false,
    "id": "51.1407,12.6501"
  },
  {
    "ID": 580448409,
    "Tankstellennummer": "0FO86",
    "Name": "LENNESTADT-GREVENBRUCK",
    "Address": "Kölner Straße 112",
    "Address__1": "",
    "City": "Lennestadt-Grevenbrü",
    "Postcode": 57368,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2721718258,
    "24 hour?": "FALSE",
    "Latitude": 51.142929,
    "Longitude": 8.017814,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.142929,8.017814",
    "IsOpen24Hours": false,
    "id": "51.142929,8.017814"
  },
  {
    "ID": 667685199,
    "Tankstellennummer": 610249,
    "Name": "WESTFALEN, EDERTAL",
    "Address": "Edertalstr. 6",
    "Address__1": "",
    "City": "Edertal",
    "Postcode": 34549,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05621/4336",
    "24 hour?": "FALSE",
    "Latitude": 51.1435,
    "Longitude": 9.17621,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1435,9.17621",
    "IsOpen24Hours": false,
    "id": "51.1435,9.17621"
  },
  {
    "ID": 1283649659,
    "Tankstellennummer": "TD021063",
    "Name": "GOERLITZ BAHNHOFSTR. 68-72",
    "Address": "BAHNHOFSTR. 68-72",
    "Address__1": "",
    "City": "GOERLITZ",
    "Postcode": 2826,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03581-409306",
    "24 hour?": "FALSE",
    "Latitude": 51.1461,
    "Longitude": 14.9839,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1461,14.9839",
    "IsOpen24Hours": false,
    "id": "51.1461,14.9839"
  },
  {
    "ID": 1014875577,
    "Tankstellennummer": "0FT03",
    "Name": "WERMELSKIRCHEN",
    "Address": "Neuenhöhe 20",
    "Address__1": "",
    "City": "Wermelskirchen",
    "Postcode": 42929,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2196731850,
    "24 hour?": "TRUE",
    "Latitude": 51.146157,
    "Longitude": 7.236031,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.146157,7.236031",
    "IsOpen24Hours": true,
    "id": "51.146157,7.236031"
  },
  {
    "ID": 2647,
    "Tankstellennummer": "0FD71",
    "Name": "Aral Tankstelle Görlitz, Reichenbacher Str. 89",
    "Address": "Reichenbacher Straße 89",
    "Address__1": "",
    "City": "Görlitz",
    "Postcode": 2827,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 358178180,
    "24 hour?": "FALSE",
    "Latitude": 51.147488,
    "Longitude": 14.941448,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.147488,14.941448",
    "IsOpen24Hours": false,
    "id": "51.147488,14.941448"
  },
  {
    "ID": 2624,
    "Tankstellennummer": "0FD49",
    "Name": "Aral Tankstelle Naumburg, Kösener Str.",
    "Address": "Kösener Straße",
    "Address__1": "",
    "City": "Naumburg",
    "Postcode": 6618,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3445778420,
    "24 hour?": "TRUE",
    "Latitude": 51.150973,
    "Longitude": 11.783996,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.150973,11.783996",
    "IsOpen24Hours": true,
    "id": "51.150973,11.783996"
  },
  {
    "ID": 2591,
    "Tankstellennummer": "0FD25",
    "Name": "Aral Tankstelle Groitzsch, Altenburger Str. 79",
    "Address": "Altenburger Straße 79",
    "Address__1": "",
    "City": "Groitzsch",
    "Postcode": 4539,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3429642265,
    "24 hour?": "FALSE",
    "Latitude": 51.151018,
    "Longitude": 12.286598,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.151018,12.286598",
    "IsOpen24Hours": false,
    "id": "51.151018,12.286598"
  },
  {
    "ID": 918497956,
    "Tankstellennummer": "0FN65",
    "Name": "GROßRÖHRSDORF",
    "Address": "Pulsnitzer Straße 31",
    "Address__1": "",
    "City": "Großröhrsdorf",
    "Postcode": 1900,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3595231554,
    "24 hour?": "FALSE",
    "Latitude": 51.151355,
    "Longitude": 14.020287,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.151355,14.020287",
    "IsOpen24Hours": false,
    "id": "51.151355,14.020287"
  },
  {
    "ID": 1389507523,
    "Tankstellennummer": "TD022103",
    "Name": "RAMMENAU HAUPTSTR. 36",
    "Address": "HAUPTSTR. 36",
    "Address__1": "",
    "City": "RAMMENAU",
    "Postcode": 1877,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03594-713535",
    "24 hour?": "FALSE",
    "Latitude": 51.1526,
    "Longitude": 14.1314,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1526,14.1314",
    "IsOpen24Hours": false,
    "id": "51.1526,14.1314"
  },
  {
    "ID": 1381769054,
    "Tankstellennummer": "TD034603",
    "Name": "GROSSWEITZSCHEN AM HEITEREN BL",
    "Address": "HEITERER BLICK 2",
    "Address__1": "",
    "City": "GROSSWEITZSCHEN",
    "Postcode": 4720,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03431-729730",
    "24 hour?": "FALSE",
    "Latitude": 51.1539,
    "Longitude": 13.1197,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1539,13.1197",
    "IsOpen24Hours": false,
    "id": "51.1539,13.1197"
  },
  {
    "ID": 2111,
    "Tankstellennummer": "0F982",
    "Name": "Aral Tankstelle Mönchengladbach, Liedberger Str. 25",
    "Address": "Liedberger Straße 25",
    "Address__1": "",
    "City": "Mönchengladbach",
    "Postcode": 41238,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 216682959,
    "24 hour?": "TRUE",
    "Latitude": 51.156362,
    "Longitude": 6.507381,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.156362,6.507381",
    "IsOpen24Hours": true,
    "id": "51.156362,6.507381"
  },
  {
    "ID": 2919,
    "Tankstellennummer": "0FH84",
    "Name": "Aral Tankstelle Neuss, Nievenheimer Str. 6",
    "Address": "Nievenheimer Straße 6",
    "Address__1": "",
    "City": "Neuss",
    "Postcode": 41469,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21372257,
    "24 hour?": "FALSE",
    "Latitude": 51.157718,
    "Longitude": 6.731179,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.157718,6.731179",
    "IsOpen24Hours": false,
    "id": "51.157718,6.731179"
  },
  {
    "ID": 1869874057,
    "Tankstellennummer": "TD000430",
    "Name": "HILDEN RICHRATHER STR. 116",
    "Address": "RICHRATHER STR. 116",
    "Address__1": "",
    "City": "HILDEN",
    "Postcode": 40723,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02103-64460",
    "24 hour?": "FALSE",
    "Latitude": 51.1583,
    "Longitude": 6.9386,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1583,6.9386",
    "IsOpen24Hours": false,
    "id": "51.1583,6.9386"
  },
  {
    "ID": 2089656970,
    "Tankstellennummer": "TD005892",
    "Name": "SCHMALLENBERG BAHNHOFSTR. 37",
    "Address": "BAHNHOFSTR. 37",
    "Address__1": "",
    "City": "SCHMALLENBERG",
    "Postcode": 57392,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02972-6279",
    "24 hour?": "FALSE",
    "Latitude": 51.1583,
    "Longitude": 8.2915,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1583,8.2915",
    "IsOpen24Hours": false,
    "id": "51.1583,8.2915"
  },
  {
    "ID": 1979927622,
    "Tankstellennummer": 900530,
    "Name": "WESTFALEN, BAT REMSCHEID-Ostseite",
    "Address": "BAT-Remscheid/Ostseite",
    "Address__1": "",
    "City": "Remscheid",
    "Postcode": 42859,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02191/89082720",
    "24 hour?": "TRUE",
    "Latitude": 51.1586,
    "Longitude": 7.22794,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.1586,7.22794",
    "IsOpen24Hours": true,
    "id": "51.1586,7.22794"
  },
  {
    "ID": 691834804,
    "Tankstellennummer": 610618,
    "Name": "WESTFALEN, SOLINGEN",
    "Address": "Kamper Str. 11",
    "Address__1": "",
    "City": "Solingen",
    "Postcode": 42699,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0212/335026",
    "24 hour?": "FALSE",
    "Latitude": 51.1586,
    "Longitude": 7.00536,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1586,7.00536",
    "IsOpen24Hours": false,
    "id": "51.1586,7.00536"
  },
  {
    "ID": 2149,
    "Tankstellennummer": "0FA08",
    "Name": "Aral Autobahntankstelle Solingen, Ohligser Heide West (A 3)",
    "Address": "A3",
    "Address__1": "",
    "City": "Solingen",
    "Postcode": 42697,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21274210,
    "24 hour?": "TRUE",
    "Latitude": 51.158961,
    "Longitude": 6.961062,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.158961,6.961062",
    "IsOpen24Hours": true,
    "id": "51.158961,6.961062"
  },
  {
    "ID": 865946393,
    "Tankstellennummer": "TD000133",
    "Name": "MOENCHENGLADBACH DAHLENER STR.",
    "Address": "DAHLENER STR.520",
    "Address__1": "",
    "City": "MOENCHENGLADBACH",
    "Postcode": 41239,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02166-31582",
    "24 hour?": "FALSE",
    "Latitude": 51.1595,
    "Longitude": 6.4153,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1595,6.4153",
    "IsOpen24Hours": false,
    "id": "51.1595,6.4153"
  },
  {
    "ID": 2725,
    "Tankstellennummer": "0FE39",
    "Name": "Aral Tankstelle Sömmerda, Weißenseerstr. 68",
    "Address": "Weißenseer Straße 68",
    "Address__1": "",
    "City": "Sömmerda",
    "Postcode": 99610,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3634612704,
    "24 hour?": "FALSE",
    "Latitude": 51.163151,
    "Longitude": 11.106833,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.163151,11.106833",
    "IsOpen24Hours": false,
    "id": "51.163151,11.106833"
  },
  {
    "ID": 2578,
    "Tankstellennummer": "0FD14",
    "Name": "Aral Tankstelle Görlitz, Nieskyer Str. 9",
    "Address": "Nieskyer Straße 9",
    "Address__1": "",
    "City": "Görlitz",
    "Postcode": 2828,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3581316410,
    "24 hour?": "TRUE",
    "Latitude": 51.16347,
    "Longitude": 14.972685,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.16347,14.972685",
    "IsOpen24Hours": true,
    "id": "51.16347,14.972685"
  },
  {
    "ID": 782953642,
    "Tankstellennummer": "TD000422",
    "Name": "HILDEN DUESSELDORFER STR. 66",
    "Address": "DUESSELDORFER STR. 66",
    "Address__1": "",
    "City": "HILDEN",
    "Postcode": 40721,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02103-55579",
    "24 hour?": "FALSE",
    "Latitude": 51.1647,
    "Longitude": 6.9171,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1647,6.9171",
    "IsOpen24Hours": false,
    "id": "51.1647,6.9171"
  },
  {
    "ID": 3407,
    "Tankstellennummer": "0FL47",
    "Name": "Aral Tankstelle Meissen, Grossenhainer Strasse 41",
    "Address": "Großenhainer Straße 41",
    "Address__1": "",
    "City": "Meißen",
    "Postcode": 1662,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3521739910,
    "24 hour?": "TRUE",
    "Latitude": 51.165799,
    "Longitude": 13.486726,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.165799,13.486726",
    "IsOpen24Hours": true,
    "id": "51.165799,13.486726"
  },
  {
    "ID": 2120245117,
    "Tankstellennummer": "TD037747",
    "Name": "KORSCHENBROICH DIESELSTR.",
    "Address": "DIESELSTR. 2",
    "Address__1": "",
    "City": "KORSCHENBROICH",
    "Postcode": 41352,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02182-5272",
    "24 hour?": "FALSE",
    "Latitude": 51.168,
    "Longitude": 6.5908,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.168,6.5908",
    "IsOpen24Hours": false,
    "id": "51.168,6.5908"
  },
  {
    "ID": 567754222,
    "Tankstellennummer": "0FS56",
    "Name": "FINNENTROP",
    "Address": "Bamenohler Straße 113",
    "Address__1": "",
    "City": "Finnentrop",
    "Postcode": 57413,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 272150879,
    "24 hour?": "FALSE",
    "Latitude": 51.168472,
    "Longitude": 7.977023,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.168472,7.977023",
    "IsOpen24Hours": false,
    "id": "51.168472,7.977023"
  },
  {
    "ID": 1450246270,
    "Tankstellennummer": "TD000821",
    "Name": "DUESSELDORF FORSTSTRASSE 15",
    "Address": "FORSTSTRASSE 15",
    "Address__1": "",
    "City": "DUESSELDORF",
    "Postcode": 40597,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0211-7100357",
    "24 hour?": "FALSE",
    "Latitude": 51.1689,
    "Longitude": 6.875,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1689,6.875",
    "IsOpen24Hours": false,
    "id": "51.1689,6.875"
  },
  {
    "ID": 474869365,
    "Tankstellennummer": "0FM73",
    "Name": "Aral TankStelle Düsseldorf, Münchener Straße 300 / Itterstr. 147",
    "Address": "Münchener Straße 300 / It",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40589,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21170089550,
    "24 hour?": "TRUE",
    "Latitude": 51.169051,
    "Longitude": 6.822734,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.169051,6.822734",
    "IsOpen24Hours": true,
    "id": "51.169051,6.822734"
  },
  {
    "ID": 2760,
    "Tankstellennummer": "0FE53",
    "Name": "Aral Tankstelle Meißen, Hochuferstr. 11",
    "Address": "Hochuferstraße 11",
    "Address__1": "",
    "City": "Meißen",
    "Postcode": 1662,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3521453101,
    "24 hour?": "TRUE",
    "Latitude": 51.169111,
    "Longitude": 13.471323,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.169111,13.471323",
    "IsOpen24Hours": true,
    "id": "51.169111,13.471323"
  },
  {
    "ID": 1369,
    "Tankstellennummer": "0F454",
    "Name": "Aral Tankstelle Düsseldorf, Nürnberger Str. 33",
    "Address": "Nürnberger Straße 33",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40599,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2119991725,
    "24 hour?": "FALSE",
    "Latitude": 51.170425,
    "Longitude": 6.859408,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.170425,6.859408",
    "IsOpen24Hours": false,
    "id": "51.170425,6.859408"
  },
  {
    "ID": 664735599,
    "Tankstellennummer": "TD000566",
    "Name": "SOLINGEN OSTSTR. 5",
    "Address": "OSTSTR. 5",
    "Address__1": "",
    "City": "SOLINGEN",
    "Postcode": 42651,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0212-202894",
    "24 hour?": "FALSE",
    "Latitude": 51.1719,
    "Longitude": 7.0895,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1719,7.0895",
    "IsOpen24Hours": false,
    "id": "51.1719,7.0895"
  },
  {
    "ID": 2144,
    "Tankstellennummer": "0FA03",
    "Name": "Aral Tankstelle Neuss, Norfer Str. 54",
    "Address": "Norfer Straße 54",
    "Address__1": "",
    "City": "Neuss",
    "Postcode": 41468,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2131364947,
    "24 hour?": "FALSE",
    "Latitude": 51.174276,
    "Longitude": 6.737947,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.174276,6.737947",
    "IsOpen24Hours": false,
    "id": "51.174276,6.737947"
  },
  {
    "ID": 1588531367,
    "Tankstellennummer": "TD040865",
    "Name": "OTTENDORF-OKRILLA",
    "Address": "ZUR KUHBRUECKE 1",
    "Address__1": "",
    "City": "OTTENDORF-OKRILLA",
    "Postcode": 1458,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03520/5189791",
    "24 hour?": "FALSE",
    "Latitude": 51.1747,
    "Longitude": 13.854,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1747,13.854",
    "IsOpen24Hours": false,
    "id": "51.1747,13.854"
  },
  {
    "ID": 758371226,
    "Tankstellennummer": "TD007112",
    "Name": "OTTENDORF-OKRILLA DRESDNER STR",
    "Address": "DRESDNER STR.53",
    "Address__1": "",
    "City": "OTTENDORF-OKRILLA",
    "Postcode": 1458,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035205-54211",
    "24 hour?": "FALSE",
    "Latitude": 51.1749,
    "Longitude": 13.8252,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1749,13.8252",
    "IsOpen24Hours": false,
    "id": "51.1749,13.8252"
  },
  {
    "ID": 17758,
    "Tankstellennummer": "0FP04",
    "Name": "Aral Tankstelle Gudensberg, Fritzlaer Straße 39",
    "Address": "Fritzlarer Straße 39",
    "Address__1": "",
    "City": "Gudensberg",
    "Postcode": 34281,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56032302,
    "24 hour?": "FALSE",
    "Latitude": 51.17605,
    "Longitude": 9.356393,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.17605,9.356393",
    "IsOpen24Hours": false,
    "id": "51.17605,9.356393"
  },
  {
    "ID": 2911,
    "Tankstellennummer": "0FH78",
    "Name": "Aral Tankstelle Hilden, Gerresheimer Str. 122",
    "Address": "Gerresheimer Straße 122",
    "Address__1": "",
    "City": "Hilden",
    "Postcode": 40721,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2103390049,
    "24 hour?": "FALSE",
    "Latitude": 51.176566,
    "Longitude": 6.92753,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.176566,6.92753",
    "IsOpen24Hours": false,
    "id": "51.176566,6.92753"
  },
  {
    "ID": 1365,
    "Tankstellennummer": "0F450",
    "Name": "Aral Tankstelle Remscheid, Neuenkamper Str. 2-4",
    "Address": "Neuenkamper Straße 2-4",
    "Address__1": "",
    "City": "Remscheid",
    "Postcode": 42855,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2191387558,
    "24 hour?": "TRUE",
    "Latitude": 51.17704,
    "Longitude": 7.211503,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.17704,7.211503",
    "IsOpen24Hours": true,
    "id": "51.17704,7.211503"
  },
  {
    "ID": 2715,
    "Tankstellennummer": "0FE29",
    "Name": "Aral Autohof Zorbau, Hallesche Str. 41/Schwabe",
    "Address": "Hallesche Straße 41/Schwa",
    "Address__1": "",
    "City": "Lützen / OT Zorbau",
    "Postcode": 6686,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3444192234,
    "24 hour?": "TRUE",
    "Latitude": 51.177707,
    "Longitude": 12.010184,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.177707,12.010184",
    "IsOpen24Hours": true,
    "id": "51.177707,12.010184"
  },
  {
    "ID": 2665,
    "Tankstellennummer": "0FD84",
    "Name": "Aral Tankstelle Bautzen, Löbauer Str. 65",
    "Address": "Löbauer Straße 65",
    "Address__1": "",
    "City": "Bautzen",
    "Postcode": 2625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3591211102,
    "24 hour?": "FALSE",
    "Latitude": 51.17863,
    "Longitude": 14.45308,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.17863,14.45308",
    "IsOpen24Hours": false,
    "id": "51.17863,14.45308"
  },
  {
    "ID": 1777900381,
    "Tankstellennummer": 5011,
    "Name": "Agip Bautzen",
    "Address": "Loebauer Str. 151",
    "Address__1": "",
    "City": "Bautzen",
    "Postcode": 2625,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 35912419",
    "24 hour?": "TRUE",
    "Latitude": 51.179105,
    "Longitude": 14.45051,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.179105,14.45051",
    "IsOpen24Hours": true,
    "id": "51.179105,14.45051"
  },
  {
    "ID": 3358,
    "Tankstellennummer": "0FL03",
    "Name": "Aral Tankstelle Bautzen, Dresdener Str. 49C",
    "Address": "Dresdener Straße 49C",
    "Address__1": "",
    "City": "Bautzen",
    "Postcode": 2625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3591301677,
    "24 hour?": "TRUE",
    "Latitude": 51.179229,
    "Longitude": 14.408396,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.179229,14.408396",
    "IsOpen24Hours": true,
    "id": "51.179229,14.408396"
  },
  {
    "ID": 1647058745,
    "Tankstellennummer": "TD020842",
    "Name": "BAUTZEN ROSENSTR. 3",
    "Address": "ROSENSTR. 3",
    "Address__1": "",
    "City": "BAUTZEN",
    "Postcode": 2625,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03591-47000",
    "24 hour?": "FALSE",
    "Latitude": 51.1805,
    "Longitude": 14.4301,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1805,14.4301",
    "IsOpen24Hours": false,
    "id": "51.1805,14.4301"
  },
  {
    "ID": 760091859,
    "Tankstellennummer": "TD143170",
    "Name": "REMSCHEID SCHUETTENDELLE 27",
    "Address": "SCHUETTENDELLE 27",
    "Address__1": "",
    "City": "REMSCHEID",
    "Postcode": 42857,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02191-2099115",
    "24 hour?": "FALSE",
    "Latitude": 51.181,
    "Longitude": 7.1741,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.181,7.1741",
    "IsOpen24Hours": false,
    "id": "51.181,7.1741"
  },
  {
    "ID": 2918,
    "Tankstellennummer": "0FH83",
    "Name": "Aral Tankstelle Neuss, Bergheimer Str. 252",
    "Address": "Bergheimer Straße 252",
    "Address__1": "",
    "City": "Neuss",
    "Postcode": 41464,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 213141655,
    "24 hour?": "TRUE",
    "Latitude": 51.18164,
    "Longitude": 6.688258,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.18164,6.688258",
    "IsOpen24Hours": true,
    "id": "51.18164,6.688258"
  },
  {
    "ID": 846286927,
    "Tankstellennummer": "TD000759",
    "Name": "MOENCHENGLADBACH BAHNSTR. 174",
    "Address": "BAHNSTR. 174",
    "Address__1": "",
    "City": "MOENCHENGLADBACH",
    "Postcode": 41069,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02161-592993",
    "24 hour?": "FALSE",
    "Latitude": 51.1818,
    "Longitude": 6.4118,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1818,6.4118",
    "IsOpen24Hours": false,
    "id": "51.1818,6.4118"
  },
  {
    "ID": 846282638,
    "Tankstellennummer": "TD006684",
    "Name": "HALVER FRANKFURTER STR. 85",
    "Address": "FRANKFURTER STR. 85",
    "Address__1": "",
    "City": "HALVER",
    "Postcode": 58553,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02353-3322",
    "24 hour?": "FALSE",
    "Latitude": 51.1844,
    "Longitude": 7.5096,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1844,7.5096",
    "IsOpen24Hours": false,
    "id": "51.1844,7.5096"
  },
  {
    "ID": 521609997,
    "Tankstellennummer": "TD007989",
    "Name": "MOENCHENGLADBACH AACHENER STR.",
    "Address": "AACHENER STR. 341",
    "Address__1": "",
    "City": "MOENCHENGLADBACH",
    "Postcode": 41069,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02161-832461",
    "24 hour?": "FALSE",
    "Latitude": 51.185,
    "Longitude": 6.4141,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.185,6.4141",
    "IsOpen24Hours": false,
    "id": "51.185,6.4141"
  },
  {
    "ID": 2995,
    "Tankstellennummer": "0FI46",
    "Name": "Aral Tankstelle Solingen, Schlagbaumer Str. 66",
    "Address": "Schlagbaumer Straße 66",
    "Address__1": "",
    "City": "Solingen",
    "Postcode": 42653,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2122541192,
    "24 hour?": "TRUE",
    "Latitude": 51.185083,
    "Longitude": 7.079738,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.185083,7.079738",
    "IsOpen24Hours": true,
    "id": "51.185083,7.079738"
  },
  {
    "ID": 1220,
    "Tankstellennummer": "0F329",
    "Name": "Aral Tankstelle Schmallenberg, Wehrscheid 7",
    "Address": "Wehrscheid 7",
    "Address__1": "",
    "City": "Schmallenberg",
    "Postcode": 57392,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 297496660,
    "24 hour?": "FALSE",
    "Latitude": 51.185118,
    "Longitude": 8.313642,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.185118,8.313642",
    "IsOpen24Hours": false,
    "id": "51.185118,8.313642"
  },
  {
    "ID": 3378,
    "Tankstellennummer": "0FL20",
    "Name": "Aral Tankstelle Weissenfels, Nikolaus-Otto-Str. 1",
    "Address": "Nikolaus-Otto-Straße 1",
    "Address__1": "",
    "City": "Weißenfels",
    "Postcode": 6667,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3443302222,
    "24 hour?": "TRUE",
    "Latitude": 51.18824,
    "Longitude": 11.989125,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.18824,11.989125",
    "IsOpen24Hours": true,
    "id": "51.18824,11.989125"
  },
  {
    "ID": 762526929,
    "Tankstellennummer": "TD037481",
    "Name": "HAAN DUESSELDORFER STR. 28",
    "Address": "DUESSELDORFER STR. 28",
    "Address__1": "",
    "City": "HAAN",
    "Postcode": 42781,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02129-959770",
    "24 hour?": "FALSE",
    "Latitude": 51.1883,
    "Longitude": 6.9949,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1883,6.9949",
    "IsOpen24Hours": false,
    "id": "51.1883,6.9949"
  },
  {
    "ID": 2570,
    "Tankstellennummer": "0FD05",
    "Name": "Aral Tankstelle Bautzen, Kreckwitzer Str. 2",
    "Address": "Kreckwitzer Straße 2",
    "Address__1": "",
    "City": "Bautzen",
    "Postcode": 2625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3591211090,
    "24 hour?": "TRUE",
    "Latitude": 51.190178,
    "Longitude": 14.451673,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.190178,14.451673",
    "IsOpen24Hours": true,
    "id": "51.190178,14.451673"
  },
  {
    "ID": 765595707,
    "Tankstellennummer": "TD124310",
    "Name": "REMSCHEID HASTENERSTR. 33",
    "Address": "HASTENERSTR. 33",
    "Address__1": "",
    "City": "REMSCHEID",
    "Postcode": 42855,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02191-81588",
    "24 hour?": "FALSE",
    "Latitude": 51.1915,
    "Longitude": 7.1708,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1915,7.1708",
    "IsOpen24Hours": false,
    "id": "51.1915,7.1708"
  },
  {
    "ID": 943931224,
    "Tankstellennummer": "0FM37",
    "Name": "Aral TankStelle Mönchengladbach, Monschauer Straße 33",
    "Address": "Monschauer Straße 33",
    "Address__1": "",
    "City": "Mönchengladbach",
    "Postcode": 41068,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21614770840,
    "24 hour?": "TRUE",
    "Latitude": 51.19284,
    "Longitude": 6.409079,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.19284,6.409079",
    "IsOpen24Hours": true,
    "id": "51.19284,6.409079"
  },
  {
    "ID": 471978037,
    "Tankstellennummer": "0FX62",
    "Name": "BAUTZEN",
    "Address": "A4",
    "Address__1": "",
    "City": "Bautzen",
    "Postcode": 2625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3593780308,
    "24 hour?": "TRUE",
    "Latitude": 51.1931848,
    "Longitude": 14.4185958,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.1931848,14.4185958",
    "IsOpen24Hours": true,
    "id": "51.1931848,14.4185958"
  },
  {
    "ID": 2142,
    "Tankstellennummer": "0FA01",
    "Name": "Aral Tankstelle Haan, Hochdahler Str.130",
    "Address": "Hochdahler Straße 130",
    "Address__1": "",
    "City": "Haan",
    "Postcode": 42781,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2129342673,
    "24 hour?": "TRUE",
    "Latitude": 51.193776,
    "Longitude": 6.98796,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.193776,6.98796",
    "IsOpen24Hours": true,
    "id": "51.193776,6.98796"
  },
  {
    "ID": 2103,
    "Tankstellennummer": "0F975",
    "Name": "Aral Tankstelle Mönchengladbach, Waldnieler Str. 60",
    "Address": "Waldnieler Straße 60",
    "Address__1": "",
    "City": "Mönchengladbach",
    "Postcode": 41068,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2161837907,
    "24 hour?": "TRUE",
    "Latitude": 51.194829,
    "Longitude": 6.419637,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.194829,6.419637",
    "IsOpen24Hours": true,
    "id": "51.194829,6.419637"
  },
  {
    "ID": 926289751,
    "Tankstellennummer": 5009,
    "Name": "Agip Espenhain",
    "Address": "Am Bahnhof 1-10",
    "Address__1": "",
    "City": "Espenhain",
    "Postcode": 4579,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 34206721",
    "24 hour?": "TRUE",
    "Latitude": 51.19516092,
    "Longitude": 12.46017865,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.19516092,12.46017865",
    "IsOpen24Hours": true,
    "id": "51.19516092,12.46017865"
  },
  {
    "ID": 124472738,
    "Tankstellennummer": "TD022293",
    "Name": "LOMMATZSCH MEISSNER STR.41",
    "Address": "MEISSNER STR.41",
    "Address__1": "",
    "City": "LOMMATZSCH",
    "Postcode": 1623,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035241-58631",
    "24 hour?": "FALSE",
    "Latitude": 51.1969,
    "Longitude": 13.3142,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1969,13.3142",
    "IsOpen24Hours": false,
    "id": "51.1969,13.3142"
  },
  {
    "ID": 1622,
    "Tankstellennummer": "0FG33",
    "Name": "Aral Autohof Hessisch Lichtenau, Leipziger Str. 201",
    "Address": "Leipziger Straße 201",
    "Address__1": "",
    "City": "Hessisch Lichtenau",
    "Postcode": 37235,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5602914088,
    "24 hour?": "TRUE",
    "Latitude": 51.198117,
    "Longitude": 9.74601,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.198117,9.74601",
    "IsOpen24Hours": true,
    "id": "51.198117,9.74601"
  },
  {
    "ID": 1841300354,
    "Tankstellennummer": "TD035717",
    "Name": "WEISSENFELS NAUMBURGER STRASSE",
    "Address": "NAUMBURGER STRASSE 26",
    "Address__1": "",
    "City": "WEISSENFELS",
    "Postcode": 6667,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03443-334566",
    "24 hour?": "FALSE",
    "Latitude": 51.1983,
    "Longitude": 11.9639,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.1983,11.9639",
    "IsOpen24Hours": false,
    "id": "51.1983,11.9639"
  },
  {
    "ID": 2529,
    "Tankstellennummer": "0FC69",
    "Name": "Aral Tankstelle Mühlhausen, Langensalzaer Landstr. 51",
    "Address": "Langensalzaer Landstraße",
    "Address__1": "",
    "City": "Mühlhausen",
    "Postcode": 99974,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3601440450,
    "24 hour?": "TRUE",
    "Latitude": 51.198577,
    "Longitude": 10.478381,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.198577,10.478381",
    "IsOpen24Hours": true,
    "id": "51.198577,10.478381"
  },
  {
    "ID": 569297659,
    "Tankstellennummer": "0FY18",
    "Name": "BAUTZEN",
    "Address": "A4",
    "Address__1": "",
    "City": "Bautzen",
    "Postcode": 2625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3593788198,
    "24 hour?": "TRUE",
    "Latitude": 51.198629,
    "Longitude": 14.328277,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.198629,14.328277",
    "IsOpen24Hours": true,
    "id": "51.198629,14.328277"
  },
  {
    "ID": 3005,
    "Tankstellennummer": "0FI54",
    "Name": "Aral Tankstelle Hessisch Lichtenau, Leipziger Str. 126",
    "Address": "Leipziger Straße 126",
    "Address__1": "",
    "City": "Hessisch Lichtenau",
    "Postcode": 37235,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56022462,
    "24 hour?": "FALSE",
    "Latitude": 51.199149,
    "Longitude": 9.726851,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.199149,9.726851",
    "IsOpen24Hours": false,
    "id": "51.199149,9.726851"
  },
  {
    "ID": 2182,
    "Tankstellennummer": "0FG26",
    "Name": "Aral Tankstelle Solingen, Wuppertaler Str.110Alte Z",
    "Address": "Wuppertaler Straße 110",
    "Address__1": "",
    "City": "Solingen",
    "Postcode": 42653,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2122571905,
    "24 hour?": "TRUE",
    "Latitude": 51.19932,
    "Longitude": 7.075124,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.19932,7.075124",
    "IsOpen24Hours": true,
    "id": "51.19932,7.075124"
  },
  {
    "ID": 1097714963,
    "Tankstellennummer": "TD000380",
    "Name": "DUESSELDORF SUEDRING 60",
    "Address": "SUEDRING 60",
    "Address__1": "",
    "City": "DUESSELDORF",
    "Postcode": 40223,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0211-151643",
    "24 hour?": "FALSE",
    "Latitude": 51.2001,
    "Longitude": 6.768,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2001,6.768",
    "IsOpen24Hours": false,
    "id": "51.2001,6.768"
  },
  {
    "ID": 215940737,
    "Tankstellennummer": 5022,
    "Name": "Agip Pulsnitz",
    "Address": "Kamenzer Strasse 34",
    "Address__1": "",
    "City": "Pulsnitz",
    "Postcode": 1896,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 35955442",
    "24 hour?": "FALSE",
    "Latitude": 51.20022925,
    "Longitude": 14.02258282,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.20022925,14.02258282",
    "IsOpen24Hours": false,
    "id": "51.20022925,14.02258282"
  },
  {
    "ID": 1351,
    "Tankstellennummer": "0F436",
    "Name": "Aral Tankstelle Winterberg, Am Hagenblech 60",
    "Address": "Am Hagenblech 60",
    "Address__1": "",
    "City": "Winterberg",
    "Postcode": 59955,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 298192600,
    "24 hour?": "TRUE",
    "Latitude": 51.200444,
    "Longitude": 8.530926,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.200444,8.530926",
    "IsOpen24Hours": true,
    "id": "51.200444,8.530926"
  },
  {
    "ID": 2175,
    "Tankstellennummer": "0FA29",
    "Name": "Aral Tankstelle Düsseldorf, Südring 115",
    "Address": "Südring 115",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40221,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 211392503,
    "24 hour?": "TRUE",
    "Latitude": 51.201012,
    "Longitude": 6.763259,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.201012,6.763259",
    "IsOpen24Hours": true,
    "id": "51.201012,6.763259"
  },
  {
    "ID": 2113,
    "Tankstellennummer": "0F984",
    "Name": "Aral Tankstelle Mönchengladbach, Vorster Str. 384",
    "Address": "Vorster Straße 384",
    "Address__1": "",
    "City": "Mönchengladbach",
    "Postcode": 41169,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2161559241,
    "24 hour?": "FALSE",
    "Latitude": 51.201265,
    "Longitude": 6.34863,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.201265,6.34863",
    "IsOpen24Hours": false,
    "id": "51.201265,6.34863"
  },
  {
    "ID": 849472747,
    "Tankstellennummer": "0F996",
    "Name": "Aral TankStelle Düsseldorf, Gerresheimer Landstraße 67",
    "Address": "Gerresheimer Landstr. 67",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40627,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 211201996,
    "24 hour?": "TRUE",
    "Latitude": 51.20161,
    "Longitude": 6.904797,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.20161,6.904797",
    "IsOpen24Hours": true,
    "id": "51.20161,6.904797"
  },
  {
    "ID": 515915957,
    "Tankstellennummer": 610617,
    "Name": "WESTFALEN, RADEVORMWALD",
    "Address": "Elberfelder Str. 148",
    "Address__1": "",
    "City": "Radevormwald",
    "Postcode": 42477,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02195/7284",
    "24 hour?": "FALSE",
    "Latitude": 51.2022,
    "Longitude": 7.32606,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2022,7.32606",
    "IsOpen24Hours": false,
    "id": "51.2022,7.32606"
  },
  {
    "ID": 2173,
    "Tankstellennummer": "0FA27",
    "Name": "Aral Tankstelle Wuppertal, Berghauser Str. 10",
    "Address": "Berghauser Straße 10",
    "Address__1": "",
    "City": "Wuppertal",
    "Postcode": 42349,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2022471654,
    "24 hour?": "FALSE",
    "Latitude": 51.202695,
    "Longitude": 7.131114,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.202695,7.131114",
    "IsOpen24Hours": false,
    "id": "51.202695,7.131114"
  },
  {
    "ID": 17883,
    "Tankstellennummer": "0FP36",
    "Name": "Aral Tankstelle Guxhagen, Grifter Straße 2",
    "Address": "Grifter Straße 2",
    "Address__1": "",
    "City": "Guxhagen",
    "Postcode": 34302,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5665800070,
    "24 hour?": "TRUE",
    "Latitude": 51.20305,
    "Longitude": 9.46859,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.20305,9.46859",
    "IsOpen24Hours": true,
    "id": "51.20305,9.46859"
  },
  {
    "ID": 2118,
    "Tankstellennummer": "0F986",
    "Name": "Aral Tankstelle Mönchengladbach, Marienburger Str. 3",
    "Address": "Marienburger Straße 3",
    "Address__1": "",
    "City": "Mönchengladbach",
    "Postcode": 41063,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 216186849,
    "24 hour?": "FALSE",
    "Latitude": 51.203104,
    "Longitude": 6.418979,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.203104,6.418979",
    "IsOpen24Hours": false,
    "id": "51.203104,6.418979"
  },
  {
    "ID": 2153,
    "Tankstellennummer": "0FA12",
    "Name": "Aral Tankstelle Mönchengladbach, Volksbadstr. 1",
    "Address": "Volksbadstraße 1",
    "Address__1": "",
    "City": "Mönchengladbach",
    "Postcode": 41065,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2161601788,
    "24 hour?": "FALSE",
    "Latitude": 51.203676,
    "Longitude": 6.474836,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.203676,6.474836",
    "IsOpen24Hours": false,
    "id": "51.203676,6.474836"
  },
  {
    "ID": 2912,
    "Tankstellennummer": "0FH79",
    "Name": "Aral Tankstelle Erkrath, Haaner Str. 77",
    "Address": "Haaner Straße 77",
    "Address__1": "",
    "City": "Erkrath",
    "Postcode": 40699,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2104948379,
    "24 hour?": "FALSE",
    "Latitude": 51.204407,
    "Longitude": 6.97023,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.204407,6.97023",
    "IsOpen24Hours": false,
    "id": "51.204407,6.97023"
  },
  {
    "ID": 843005141,
    "Tankstellennummer": "TD037986",
    "Name": "HAAN LANDSTRASSE 64",
    "Address": "LANDSTRASSE 64",
    "Address__1": "",
    "City": "HAAN",
    "Postcode": 42781,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02129-3456929",
    "24 hour?": "FALSE",
    "Latitude": 51.2049,
    "Longitude": 7.0415,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2049,7.0415",
    "IsOpen24Hours": false,
    "id": "51.2049,7.0415"
  },
  {
    "ID": 1218,
    "Tankstellennummer": "0F328",
    "Name": "Aral Tankstelle Lüdenscheid, Talstr. 161",
    "Address": "Talstraße 161",
    "Address__1": "",
    "City": "Lüdenscheid",
    "Postcode": 58515,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 235171177,
    "24 hour?": "TRUE",
    "Latitude": 51.205544,
    "Longitude": 7.582653,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.205544,7.582653",
    "IsOpen24Hours": true,
    "id": "51.205544,7.582653"
  },
  {
    "ID": 2906,
    "Tankstellennummer": "0FH72",
    "Name": "Aral Tankstelle Neuss, Engelbertstr.",
    "Address": "Engelbertstraße",
    "Address__1": "",
    "City": "Neuss",
    "Postcode": 41462,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2131561102,
    "24 hour?": "FALSE",
    "Latitude": 51.207104,
    "Longitude": 6.671111,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.207104,6.671111",
    "IsOpen24Hours": false,
    "id": "51.207104,6.671111"
  },
  {
    "ID": 1851110758,
    "Tankstellennummer": "TD120390",
    "Name": "REMSCHEID KREUZBERGSTR.",
    "Address": "KREUZBERGSTR. 65",
    "Address__1": "",
    "City": "REMSCHEID",
    "Postcode": 42899,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02191-462832",
    "24 hour?": "FALSE",
    "Latitude": 51.2086,
    "Longitude": 7.2314,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2086,7.2314",
    "IsOpen24Hours": false,
    "id": "51.2086,7.2314"
  },
  {
    "ID": 1836261039,
    "Tankstellennummer": "TD000842",
    "Name": "SCHWALMTAL UNGERATH 301",
    "Address": "UNGERATH 301",
    "Address__1": "",
    "City": "SCHWALMTAL",
    "Postcode": 41366,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02163-943140",
    "24 hour?": "FALSE",
    "Latitude": 51.2086,
    "Longitude": 6.2808,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2086,6.2808",
    "IsOpen24Hours": false,
    "id": "51.2086,6.2808"
  },
  {
    "ID": 3262,
    "Tankstellennummer": "0FK33",
    "Name": "Aral Tankstelle Düsseldorf, Koelner Str. 356",
    "Address": "Kölner Straße 356",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40227,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21199614011,
    "24 hour?": "TRUE",
    "Latitude": 51.209536,
    "Longitude": 6.810053,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.209536,6.810053",
    "IsOpen24Hours": true,
    "id": "51.209536,6.810053"
  },
  {
    "ID": 2107,
    "Tankstellennummer": "0F979",
    "Name": "Aral Tankstelle Niederkrüchten, Roermonder Str. 2",
    "Address": "Roermonder Straße 2",
    "Address__1": "",
    "City": "Niederkrüchten",
    "Postcode": 41372,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 216382397,
    "24 hour?": "FALSE",
    "Latitude": 51.210776,
    "Longitude": 6.143233,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.210776,6.143233",
    "IsOpen24Hours": false,
    "id": "51.210776,6.143233"
  },
  {
    "ID": 2106,
    "Tankstellennummer": "0F978",
    "Name": "Aral Tankstelle Mönchengladbach, Eickener Str. 357",
    "Address": "Eickener Straße 357",
    "Address__1": "",
    "City": "Mönchengladbach",
    "Postcode": 41063,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2161184615,
    "24 hour?": "TRUE",
    "Latitude": 51.212324,
    "Longitude": 6.448193,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.212324,6.448193",
    "IsOpen24Hours": true,
    "id": "51.212324,6.448193"
  },
  {
    "ID": 2141,
    "Tankstellennummer": "0F999",
    "Name": "Aral Tankstelle Düsseldorf, In den Kötten 9",
    "Address": "In den Kötten 9",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40627,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 211279993,
    "24 hour?": "TRUE",
    "Latitude": 51.21241,
    "Longitude": 6.857821,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.21241,6.857821",
    "IsOpen24Hours": true,
    "id": "51.21241,6.857821"
  },
  {
    "ID": 2761,
    "Tankstellennummer": "0FE54",
    "Name": "Aral Tankstelle Radeburg, Radeberger Str. 18",
    "Address": "Radeberger Straße 18",
    "Address__1": "",
    "City": "Radeburg",
    "Postcode": 1471,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 352082321,
    "24 hour?": "TRUE",
    "Latitude": 51.214191,
    "Longitude": 13.73058,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.214191,13.73058",
    "IsOpen24Hours": true,
    "id": "51.214191,13.73058"
  },
  {
    "ID": 778255434,
    "Tankstellennummer": "TD000955",
    "Name": "DUESSELDORF VENNHAUSER ALLEE 2",
    "Address": "VENNHAUSER ALLEE 266",
    "Address__1": "",
    "City": "DUESSELDORF",
    "Postcode": 40627,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0211-279666",
    "24 hour?": "FALSE",
    "Latitude": 51.2144,
    "Longitude": 6.8649,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2144,6.8649",
    "IsOpen24Hours": false,
    "id": "51.2144,6.8649"
  },
  {
    "ID": 829145455,
    "Tankstellennummer": "TD000735",
    "Name": "ERKRATH-HOCHDAHL SCHIMMELBUSCH",
    "Address": "SCHIMMELBUSCHSTR. 27",
    "Address__1": "",
    "City": "ERKRATH-HOCHDAHL",
    "Postcode": 40699,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02104-39604",
    "24 hour?": "FALSE",
    "Latitude": 51.2155,
    "Longitude": 6.9499,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2155,6.9499",
    "IsOpen24Hours": false,
    "id": "51.2155,6.9499"
  },
  {
    "ID": 850017897,
    "Tankstellennummer": "TD033878",
    "Name": "MUEHLHAUSEN WENDEWEHRSTR. 129",
    "Address": "WENDEWEHRSTR. 129",
    "Address__1": "",
    "City": "MUEHLHAUSEN",
    "Postcode": 99974,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03601-815176",
    "24 hour?": "FALSE",
    "Latitude": 51.2162,
    "Longitude": 10.4634,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2162,10.4634",
    "IsOpen24Hours": false,
    "id": "51.2162,10.4634"
  },
  {
    "ID": 2117,
    "Tankstellennummer": "0F985",
    "Name": "Aral Tankstelle Mönchengladbach, Krefelder Str. 472",
    "Address": "Krefelder Straße 472",
    "Address__1": "",
    "City": "Mönchengladbach",
    "Postcode": 41066,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2161602426,
    "24 hour?": "TRUE",
    "Latitude": 51.21829,
    "Longitude": 6.477363,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.21829,6.477363",
    "IsOpen24Hours": true,
    "id": "51.21829,6.477363"
  },
  {
    "ID": 2140,
    "Tankstellennummer": "0F998",
    "Name": "Aral Tankstelle Kaarst, Kaarster Straße 1",
    "Address": "Kaarster Straße 1",
    "Address__1": "",
    "City": "Kaarst",
    "Postcode": 41564,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2131605076,
    "24 hour?": "FALSE",
    "Latitude": 51.218434,
    "Longitude": 6.605988,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.218434,6.605988",
    "IsOpen24Hours": false,
    "id": "51.218434,6.605988"
  },
  {
    "ID": 2992,
    "Tankstellennummer": "0FI44",
    "Name": "Aral Tankstelle Remscheid, Barmer Str. 70",
    "Address": "Barmer Straße 70",
    "Address__1": "",
    "City": "Remscheid",
    "Postcode": 42899,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2191953342,
    "24 hour?": "FALSE",
    "Latitude": 51.219449,
    "Longitude": 7.241142,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.219449,7.241142",
    "IsOpen24Hours": false,
    "id": "51.219449,7.241142"
  },
  {
    "ID": 1564844367,
    "Tankstellennummer": "TD030486",
    "Name": "FREYBURG MERSEBURGER STR.31 B",
    "Address": "MERSEBURGER STR.31 b",
    "Address__1": "",
    "City": "FREYBURG",
    "Postcode": 6632,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034464-28271",
    "24 hour?": "FALSE",
    "Latitude": 51.2207,
    "Longitude": 11.7728,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2207,11.7728",
    "IsOpen24Hours": false,
    "id": "51.2207,11.7728"
  },
  {
    "ID": 568283562,
    "Tankstellennummer": "TD037820",
    "Name": "DUESSELDORF HOEHERWEG 202",
    "Address": "HOEHERWEG 202",
    "Address__1": "",
    "City": "DUESSELDORF",
    "Postcode": 40233,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0211-5072244",
    "24 hour?": "FALSE",
    "Latitude": 51.2221,
    "Longitude": 6.8203,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2221,6.8203",
    "IsOpen24Hours": false,
    "id": "51.2221,6.8203"
  },
  {
    "ID": 58584933,
    "Tankstellennummer": "0FN93",
    "Name": "GREUßEN",
    "Address": "Lindenstraße 60",
    "Address__1": "",
    "City": "Greußen",
    "Postcode": 99718,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3636701255,
    "24 hour?": "FALSE",
    "Latitude": 51.222967,
    "Longitude": 10.947146,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.222967,10.947146",
    "IsOpen24Hours": false,
    "id": "51.222967,10.947146"
  },
  {
    "ID": 958,
    "Tankstellennummer": "0F148",
    "Name": "Aral Autobahntankstelle Edermünde, Holzhausen Ost (A 49)",
    "Address": "BAB A 49 -Ostseite",
    "Address__1": "",
    "City": "Edermünde",
    "Postcode": 34295,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56656454,
    "24 hour?": "FALSE",
    "Latitude": 51.223041,
    "Longitude": 9.420125,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.223041,9.420125",
    "IsOpen24Hours": false,
    "id": "51.223041,9.420125"
  },
  {
    "ID": 1697,
    "Tankstellennummer": "0F696",
    "Name": "Aral Autobahntankstelle Edermünde, Holzhausen West (A 49)",
    "Address": "BAB 49/Westseite",
    "Address__1": "",
    "City": "Edermünde",
    "Postcode": 34295,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56656388,
    "24 hour?": "TRUE",
    "Latitude": 51.223765,
    "Longitude": 9.419553,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.223765,9.419553",
    "IsOpen24Hours": true,
    "id": "51.223765,9.419553"
  },
  {
    "ID": 742648956,
    "Tankstellennummer": "TD122220",
    "Name": "ERKRATH KIRCHSTR. 23",
    "Address": "KIRCHSTR. 23",
    "Address__1": "",
    "City": "ERKRATH",
    "Postcode": 40699,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0211-9003030",
    "24 hour?": "FALSE",
    "Latitude": 51.2238,
    "Longitude": 6.9165,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2238,6.9165",
    "IsOpen24Hours": false,
    "id": "51.2238,6.9165"
  },
  {
    "ID": 2741,
    "Tankstellennummer": "0FE47",
    "Name": "Aral Autohof Kodersdorf, An der B115/ BAB 4",
    "Address": "SIEDLERWEG 1",
    "Address__1": "",
    "City": "Kodersdorf",
    "Postcode": 2923,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3582562337,
    "24 hour?": "TRUE",
    "Latitude": 51.225674,
    "Longitude": 14.902096,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.225674,14.902096",
    "IsOpen24Hours": true,
    "id": "51.225674,14.902096"
  },
  {
    "ID": 841889401,
    "Tankstellennummer": "TD007930",
    "Name": "GRIMMA BAHNHOFSTR. 15",
    "Address": "BAHNHOFSTR. 15",
    "Address__1": "",
    "City": "GRIMMA",
    "Postcode": 4668,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03437-916889",
    "24 hour?": "FALSE",
    "Latitude": 51.2284,
    "Longitude": 12.7159,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2284,12.7159",
    "IsOpen24Hours": false,
    "id": "51.2284,12.7159"
  },
  {
    "ID": 1326,
    "Tankstellennummer": "0F002",
    "Name": "Aral Tankstelle Lüdenscheid, Werdohler Landstr. 4",
    "Address": "Werdohler Landstraße 4",
    "Address__1": "",
    "City": "Lüdenscheid",
    "Postcode": 58511,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23514329875,
    "24 hour?": "TRUE",
    "Latitude": 51.22859,
    "Longitude": 7.65142,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.22859,7.65142",
    "IsOpen24Hours": true,
    "id": "51.22859,7.65142"
  },
  {
    "ID": 2134,
    "Tankstellennummer": "0FE79",
    "Name": "Aral Tankstelle Wuppertal, Cronenberger Str. 388",
    "Address": "Cronenberger Straße 388",
    "Address__1": "",
    "City": "Wuppertal",
    "Postcode": 42349,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2024087581,
    "24 hour?": "TRUE",
    "Latitude": 51.22901,
    "Longitude": 7.149388,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.22901,7.149388",
    "IsOpen24Hours": true,
    "id": "51.22901,7.149388"
  },
  {
    "ID": 326895173,
    "Tankstellennummer": "0FT75",
    "Name": "LUDENSCHEID",
    "Address": "Heedfelder Straße 92",
    "Address__1": "",
    "City": "Lüdenscheid",
    "Postcode": 58509,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23516913,
    "24 hour?": "FALSE",
    "Latitude": 51.229366,
    "Longitude": 7.611857,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.229366,7.611857",
    "IsOpen24Hours": false,
    "id": "51.229366,7.611857"
  },
  {
    "ID": 433005961,
    "Tankstellennummer": "TD124260",
    "Name": "DUESSELDORF DREHERSTR. 171A",
    "Address": "DREHERSTR. 171A",
    "Address__1": "",
    "City": "DUESSELDORF",
    "Postcode": 40625,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0211-234560",
    "24 hour?": "FALSE",
    "Latitude": 51.2298,
    "Longitude": 6.8477,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2298,6.8477",
    "IsOpen24Hours": false,
    "id": "51.2298,6.8477"
  },
  {
    "ID": 2129,
    "Tankstellennummer": "0F994",
    "Name": "Aral Tankstelle Wuppertal, Westring 83",
    "Address": "Westring 83",
    "Address__1": "",
    "City": "Wuppertal",
    "Postcode": 42329,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 202781423,
    "24 hour?": "TRUE",
    "Latitude": 51.229879,
    "Longitude": 7.074273,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.229879,7.074273",
    "IsOpen24Hours": true,
    "id": "51.229879,7.074273"
  },
  {
    "ID": 2598,
    "Tankstellennummer": "0FD29",
    "Name": "Aral Tankstelle Grimma, Leipziger Str. 93",
    "Address": "Leipziger Straße 95",
    "Address__1": "",
    "City": "Grimma",
    "Postcode": 4668,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3437917940,
    "24 hour?": "FALSE",
    "Latitude": 51.231318,
    "Longitude": 12.703964,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.231318,12.703964",
    "IsOpen24Hours": false,
    "id": "51.231318,12.703964"
  },
  {
    "ID": 1266,
    "Tankstellennummer": "0F368",
    "Name": "Aral Tankstelle Schwalmtal, Hauptstr. 48",
    "Address": "Hauptstraße 48",
    "Address__1": "",
    "City": "Schwalmtal",
    "Postcode": 41366,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 216320442,
    "24 hour?": "FALSE",
    "Latitude": 51.232708,
    "Longitude": 6.240123,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.232708,6.240123",
    "IsOpen24Hours": false,
    "id": "51.232708,6.240123"
  },
  {
    "ID": 2909,
    "Tankstellennummer": "0FH75",
    "Name": "Aral Tankstelle Düsseldorf, Pariser Str. 82",
    "Address": "Pariser Straße 82",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40549,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 211501372,
    "24 hour?": "TRUE",
    "Latitude": 51.233334,
    "Longitude": 6.727858,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.233334,6.727858",
    "IsOpen24Hours": true,
    "id": "51.233334,6.727858"
  },
  {
    "ID": 2126,
    "Tankstellennummer": "0F992",
    "Name": "Aral Tankstelle Wuppertal, Linde 19",
    "Address": "Linde 19",
    "Address__1": "",
    "City": "Wuppertal",
    "Postcode": 42287,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 202464793,
    "24 hour?": "FALSE",
    "Latitude": 51.23368,
    "Longitude": 7.23626,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.23368,7.23626",
    "IsOpen24Hours": false,
    "id": "51.23368,7.23626"
  },
  {
    "ID": 2769,
    "Tankstellennummer": "0FE61",
    "Name": "Aral Tankstelle Grimma, Straße des Friedens",
    "Address": "Straße des Friedens",
    "Address__1": "",
    "City": "Grimma",
    "Postcode": 4668,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3437910258,
    "24 hour?": "FALSE",
    "Latitude": 51.237715,
    "Longitude": 12.72329,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.237715,12.72329",
    "IsOpen24Hours": false,
    "id": "51.237715,12.72329"
  },
  {
    "ID": 1261,
    "Tankstellennummer": "0F363",
    "Name": "Aral Tankstelle Brüggen, Klosterstr.1",
    "Address": "Klosterstraße 1",
    "Address__1": "",
    "City": "Brüggen",
    "Postcode": 41379,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21635534,
    "24 hour?": "FALSE",
    "Latitude": 51.237852,
    "Longitude": 6.189237,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.237852,6.189237",
    "IsOpen24Hours": false,
    "id": "51.237852,6.189237"
  },
  {
    "ID": 3450,
    "Tankstellennummer": "0FL77",
    "Name": "Aral Tankstelle Düsseldorf, Luetticher Str. 100",
    "Address": "Luetticher Straße 100",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40547,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 211552322,
    "24 hour?": "TRUE",
    "Latitude": 51.2398491,
    "Longitude": 6.7440739,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.2398491,6.7440739",
    "IsOpen24Hours": true,
    "id": "51.2398491,6.7440739"
  },
  {
    "ID": 423501168,
    "Tankstellennummer": "0FH76",
    "Name": "Aral TankStelle Düsseldorf, Luetticher Straße 108",
    "Address": "Luetticher Straße 108",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40547,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 211552422,
    "24 hour?": "TRUE",
    "Latitude": 51.24112,
    "Longitude": 6.743643,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.24112,6.743643",
    "IsOpen24Hours": true,
    "id": "51.24112,6.743643"
  },
  {
    "ID": 2125,
    "Tankstellennummer": "0F991",
    "Name": "Aral Tankstelle Düsseldorf, Ahnfeldstr. 60",
    "Address": "Ahnfeldstraße 60",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40239,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 211627610,
    "24 hour?": "FALSE",
    "Latitude": 51.241591,
    "Longitude": 6.797076,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.241591,6.797076",
    "IsOpen24Hours": false,
    "id": "51.241591,6.797076"
  },
  {
    "ID": 864820597,
    "Tankstellennummer": "TD037523",
    "Name": "SCHALKSMUEHLE VOLMESTR. 49",
    "Address": "VOLMESTR. 49",
    "Address__1": "",
    "City": "SCHALKSMUEHLE",
    "Postcode": 58579,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02355-1404",
    "24 hour?": "FALSE",
    "Latitude": 51.2453,
    "Longitude": 7.524,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2453,7.524",
    "IsOpen24Hours": false,
    "id": "51.2453,7.524"
  },
  {
    "ID": 1653,
    "Tankstellennummer": "0F667",
    "Name": "Aral Tankstelle Waldeck-Sachsenhause, Korbacher Str. 24",
    "Address": "Korbacher Straße 24",
    "Address__1": "",
    "City": "Waldeck-Sachsenhause",
    "Postcode": 34513,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56349942479,
    "24 hour?": "FALSE",
    "Latitude": 51.246795,
    "Longitude": 9.001947,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.246795,9.001947",
    "IsOpen24Hours": false,
    "id": "51.246795,9.001947"
  },
  {
    "ID": 2908,
    "Tankstellennummer": "0FH74",
    "Name": "Aral Tankstelle Düsseldorf, Brehmstr. 90",
    "Address": "Brehmstraße 90",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40239,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 211627301,
    "24 hour?": "FALSE",
    "Latitude": 51.247919,
    "Longitude": 6.801548,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.247919,6.801548",
    "IsOpen24Hours": false,
    "id": "51.247919,6.801548"
  },
  {
    "ID": 585050963,
    "Tankstellennummer": "TD000851",
    "Name": "MEERBUSCH DUESSELDORFER STR.23",
    "Address": "DUESSELDORFER STR.23",
    "Address__1": "",
    "City": "MEERBUSCH",
    "Postcode": 40667,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02132-130792",
    "24 hour?": "FALSE",
    "Latitude": 51.2497,
    "Longitude": 6.6907,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2497,6.6907",
    "IsOpen24Hours": false,
    "id": "51.2497,6.6907"
  },
  {
    "ID": 1217,
    "Tankstellennummer": "0F327",
    "Name": "Aral Tankstelle Eslohe, Mindener Str. 12",
    "Address": "Mindener Straße 14",
    "Address__1": "",
    "City": "Eslohe",
    "Postcode": 59889,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2973571,
    "24 hour?": "FALSE",
    "Latitude": 51.250439,
    "Longitude": 8.202831,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.250439,8.202831",
    "IsOpen24Hours": false,
    "id": "51.250439,8.202831"
  },
  {
    "ID": 2100473439,
    "Tankstellennummer": 610004,
    "Name": "WESTFALEN, WUPPERTAL",
    "Address": "Friedrich-Ebert-Str. 129",
    "Address__1": "",
    "City": "Wuppertal",
    "Postcode": 42117,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0202/2835761",
    "24 hour?": "TRUE",
    "Latitude": 51.2511,
    "Longitude": 7.12846,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.2511,7.12846",
    "IsOpen24Hours": true,
    "id": "51.2511,7.12846"
  },
  {
    "ID": 683511425,
    "Tankstellennummer": "0FN68",
    "Name": "LUTZEN",
    "Address": "Göteborger Straße 12 a",
    "Address__1": "",
    "City": "Lützen",
    "Postcode": 6686,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3444420030,
    "24 hour?": "TRUE",
    "Latitude": 51.251734,
    "Longitude": 12.137886,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.251734,12.137886",
    "IsOpen24Hours": true,
    "id": "51.251734,12.137886"
  },
  {
    "ID": 1110630195,
    "Tankstellennummer": "TD000624",
    "Name": "GROSSDUBRAU ERNST-THAELMANN-ST",
    "Address": "ERNST-THAELMANN-STR. 25a",
    "Address__1": "",
    "City": "GROSSDUBRAU",
    "Postcode": 2694,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035934-8977",
    "24 hour?": "FALSE",
    "Latitude": 51.2522,
    "Longitude": 14.4625,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2522,14.4625",
    "IsOpen24Hours": false,
    "id": "51.2522,14.4625"
  },
  {
    "ID": 1273,
    "Tankstellennummer": "0F374",
    "Name": "Aral Tankstelle Viersen, Viersener Str. 142",
    "Address": "Viersener Straße 142",
    "Address__1": "",
    "City": "Viersen",
    "Postcode": 41751,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 216242285,
    "24 hour?": "TRUE",
    "Latitude": 51.254489,
    "Longitude": 6.347575,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.254489,6.347575",
    "IsOpen24Hours": true,
    "id": "51.254489,6.347575"
  },
  {
    "ID": 1180847143,
    "Tankstellennummer": "TD110570",
    "Name": "WERDOHL UETTERLINGSER STR.39",
    "Address": "UETTERLINGSER STR.39",
    "Address__1": "",
    "City": "WERDOHL",
    "Postcode": 58791,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02392-180326",
    "24 hour?": "FALSE",
    "Latitude": 51.2545,
    "Longitude": 7.7459,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2545,7.7459",
    "IsOpen24Hours": false,
    "id": "51.2545,7.7459"
  },
  {
    "ID": 2103257731,
    "Tankstellennummer": "TD005827",
    "Name": "LUEDENSCHEID ALTENAER STR. 244",
    "Address": "ALTENAER STR. 244",
    "Address__1": "",
    "City": "LUEDENSCHEID",
    "Postcode": 58513,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02351-50021",
    "24 hour?": "FALSE",
    "Latitude": 51.2545,
    "Longitude": 7.6352,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2545,7.6352",
    "IsOpen24Hours": false,
    "id": "51.2545,7.6352"
  },
  {
    "ID": 1201,
    "Tankstellennummer": "0F315",
    "Name": "Aral Tankstelle Schalksmühle, Heedfelder Str.14",
    "Address": "Heedfelder Straße14",
    "Address__1": "",
    "City": "Schalksmühle",
    "Postcode": 58579,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23516759180,
    "24 hour?": "FALSE",
    "Latitude": 51.256003,
    "Longitude": 7.583629,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.256003,7.583629",
    "IsOpen24Hours": false,
    "id": "51.256003,7.583629"
  },
  {
    "ID": 1678,
    "Tankstellennummer": "0F683",
    "Name": "Aral Tankstelle Baunatal, Langenbergstrasse 9",
    "Address": "Langenbergstraße 9",
    "Address__1": "",
    "City": "Baunatal",
    "Postcode": 34225,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 561492256,
    "24 hour?": "FALSE",
    "Latitude": 51.25609,
    "Longitude": 9.413223,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.25609,9.413223",
    "IsOpen24Hours": false,
    "id": "51.25609,9.413223"
  },
  {
    "ID": 2739,
    "Tankstellennummer": "0FE45",
    "Name": "Aral Autohof Grimma, Hengstbergstraße 11",
    "Address": "Hengstbergstraße 11",
    "Address__1": "",
    "City": "Grimma",
    "Postcode": 4668,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 34379407130,
    "24 hour?": "TRUE",
    "Latitude": 51.256147,
    "Longitude": 12.725392,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.256147,12.725392",
    "IsOpen24Hours": true,
    "id": "51.256147,12.725392"
  },
  {
    "ID": 765141829,
    "Tankstellennummer": "TD123890",
    "Name": "WILLICH HAUPTSTR.149",
    "Address": "HAUPTSTR.149",
    "Address__1": "",
    "City": "WILLICH",
    "Postcode": 47877,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02156-60714",
    "24 hour?": "FALSE",
    "Latitude": 51.2571,
    "Longitude": 6.4869,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2571,6.4869",
    "IsOpen24Hours": false,
    "id": "51.2571,6.4869"
  },
  {
    "ID": 522365870,
    "Tankstellennummer": 610651,
    "Name": "WESTFALEN, WERDOHL",
    "Address": "Lennestr. 17",
    "Address__1": "",
    "City": "Werdohl",
    "Postcode": 58791,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02392/3030",
    "24 hour?": "FALSE",
    "Latitude": 51.2571,
    "Longitude": 7.75746,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2571,7.75746",
    "IsOpen24Hours": false,
    "id": "51.2571,7.75746"
  },
  {
    "ID": 1198,
    "Tankstellennummer": "0F312",
    "Name": "Aral Tankstelle Breckerfeld, Frankfurter Str. 20",
    "Address": "Frankfurter Straße 22",
    "Address__1": "",
    "City": "Breckerfeld",
    "Postcode": 58339,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23384519894,
    "24 hour?": "FALSE",
    "Latitude": 51.2573,
    "Longitude": 7.46924,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2573,7.46924",
    "IsOpen24Hours": false,
    "id": "51.2573,7.46924"
  },
  {
    "ID": 12351,
    "Tankstellennummer": "0FC72",
    "Name": "Aral Tankstelle Königsbrück, Dresdner Str. 36",
    "Address": "Dresdner Straße 36",
    "Address__1": "",
    "City": "Königsbrück",
    "Postcode": 1936,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3579536372,
    "24 hour?": "TRUE",
    "Latitude": 51.257701,
    "Longitude": 13.891425,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.257701,13.891425",
    "IsOpen24Hours": true,
    "id": "51.257701,13.891425"
  },
  {
    "ID": 648166295,
    "Tankstellennummer": "TD111660",
    "Name": "GROSSALMERODE KASSELER STR. 49",
    "Address": "KASSELER STR. 49-51",
    "Address__1": "",
    "City": "GROSSALMERODE",
    "Postcode": 37247,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05604-6386",
    "24 hour?": "FALSE",
    "Latitude": 51.2583,
    "Longitude": 9.7782,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2583,9.7782",
    "IsOpen24Hours": false,
    "id": "51.2583,9.7782"
  },
  {
    "ID": 1648144980,
    "Tankstellennummer": 610285,
    "Name": "WESTFALEN, BAUNATAL",
    "Address": "Knallhütter Str. 43",
    "Address__1": "",
    "City": "Baunatal",
    "Postcode": 34225,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0561/492201",
    "24 hour?": "TRUE",
    "Latitude": 51.2583,
    "Longitude": 9.44833,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.2583,9.44833",
    "IsOpen24Hours": true,
    "id": "51.2583,9.44833"
  },
  {
    "ID": 51418571,
    "Tankstellennummer": "TD000978",
    "Name": "WUPPERTAL HOFKAMP 47",
    "Address": "HOFKAMP 45",
    "Address__1": "",
    "City": "WUPPERTAL",
    "Postcode": 42103,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0202-70516555",
    "24 hour?": "FALSE",
    "Latitude": 51.2591,
    "Longitude": 7.1511,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2591,7.1511",
    "IsOpen24Hours": false,
    "id": "51.2591,7.1511"
  },
  {
    "ID": 2180,
    "Tankstellennummer": "0FA31",
    "Name": "Aral Tankstelle Düsseldorf, Bergische Landstr. 605",
    "Address": "Bergische Landstraße 605",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40629,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2112913810,
    "24 hour?": "TRUE",
    "Latitude": 51.260509,
    "Longitude": 6.908724,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.260509,6.908724",
    "IsOpen24Hours": true,
    "id": "51.260509,6.908724"
  },
  {
    "ID": 1298,
    "Tankstellennummer": "0F398",
    "Name": "Aral Tankstelle Viersen, Brabanter Str. 85",
    "Address": "Brabanter Straße 85",
    "Address__1": "",
    "City": "Viersen",
    "Postcode": 41751,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 216251369,
    "24 hour?": "FALSE",
    "Latitude": 51.260919,
    "Longitude": 6.334866,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.260919,6.334866",
    "IsOpen24Hours": false,
    "id": "51.260919,6.334866"
  },
  {
    "ID": 1062274371,
    "Tankstellennummer": "TD021394",
    "Name": "KAMENZ NEBELSCHUETZER STR.4",
    "Address": "NEBELSCHUETZER STR.4",
    "Address__1": "",
    "City": "KAMENZ",
    "Postcode": 1917,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03578/309435",
    "24 hour?": "FALSE",
    "Latitude": 51.2633,
    "Longitude": 14.1164,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2633,14.1164",
    "IsOpen24Hours": false,
    "id": "51.2633,14.1164"
  },
  {
    "ID": 758327181,
    "Tankstellennummer": "0F989",
    "Name": "Aral TankStelle Wuppertal, Friedrich-Engels-Allee 237",
    "Address": "Friedr.-Engels-Allee 237",
    "Address__1": "",
    "City": "Wuppertal",
    "Postcode": 42285,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 20281457,
    "24 hour?": "TRUE",
    "Latitude": 51.263429,
    "Longitude": 7.177795,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.263429,7.177795",
    "IsOpen24Hours": true,
    "id": "51.263429,7.177795"
  },
  {
    "ID": 2907,
    "Tankstellennummer": "0FH73",
    "Name": "Aral Tankstelle Düsseldorf, Freiligrathplatz 32",
    "Address": "Freiligrathplatz 32",
    "Address__1": "",
    "City": "Düsseldorf",
    "Postcode": 40474,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2114350080,
    "24 hour?": "TRUE",
    "Latitude": 51.265086,
    "Longitude": 6.753424,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.265086,6.753424",
    "IsOpen24Hours": true,
    "id": "51.265086,6.753424"
  },
  {
    "ID": 1852904297,
    "Tankstellennummer": "TD000232",
    "Name": "WILLICH PARKSTR. 16",
    "Address": "PARKSTR. 16",
    "Address__1": "",
    "City": "WILLICH",
    "Postcode": 47877,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02154-205770",
    "24 hour?": "FALSE",
    "Latitude": 51.2667,
    "Longitude": 6.5514,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2667,6.5514",
    "IsOpen24Hours": false,
    "id": "51.2667,6.5514"
  },
  {
    "ID": 1689,
    "Tankstellennummer": "0F693",
    "Name": "Aral Tankstelle Lohfelden, Kasseler Strasse 53",
    "Address": "Kasseler Straße 53",
    "Address__1": "",
    "City": "Lohfelden",
    "Postcode": 34253,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 560891807,
    "24 hour?": "FALSE",
    "Latitude": 51.268222,
    "Longitude": 9.561636,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.268222,9.561636",
    "IsOpen24Hours": false,
    "id": "51.268222,9.561636"
  },
  {
    "ID": 1548767619,
    "Tankstellennummer": "TD035931",
    "Name": "WEISSENFELS BAEUMCHEN 15",
    "Address": "BAEUMCHEN 15",
    "Address__1": "",
    "City": "WEISSENFELS",
    "Postcode": 6667,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03443-3383245",
    "24 hour?": "FALSE",
    "Latitude": 51.2687,
    "Longitude": 11.9783,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2687,11.9783",
    "IsOpen24Hours": false,
    "id": "51.2687,11.9783"
  },
  {
    "ID": 2931,
    "Tankstellennummer": "0FH91",
    "Name": "Aral Tankstelle Wuppertal, Nevigeser Str. 37",
    "Address": "Nevigeser Straße 31",
    "Address__1": "",
    "City": "Wuppertal",
    "Postcode": 42113,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2022761355,
    "24 hour?": "TRUE",
    "Latitude": 51.268726,
    "Longitude": 7.126851,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.268726,7.126851",
    "IsOpen24Hours": true,
    "id": "51.268726,7.126851"
  },
  {
    "ID": 397291449,
    "Tankstellennummer": "TD000765",
    "Name": "DUESSELDORF THEODORSTR. 282",
    "Address": "THEODORSTR. 282",
    "Address__1": "",
    "City": "DUESSELDORF",
    "Postcode": 40472,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0211-6549816",
    "24 hour?": "FALSE",
    "Latitude": 51.2719,
    "Longitude": 6.8164,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2719,6.8164",
    "IsOpen24Hours": false,
    "id": "51.2719,6.8164"
  },
  {
    "ID": 841859216,
    "Tankstellennummer": "0FN66",
    "Name": "LEIPZIG",
    "Address": "Dieskaustraße 483 a",
    "Address__1": "",
    "City": "Leipzig",
    "Postcode": 4249,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3414240640,
    "24 hour?": "FALSE",
    "Latitude": 51.272811,
    "Longitude": 12.308377,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.272811,12.308377",
    "IsOpen24Hours": false,
    "id": "51.272811,12.308377"
  },
  {
    "ID": 2923,
    "Tankstellennummer": "0FH86",
    "Name": "Aral Tankstelle Willich, Krefelder Str. 136",
    "Address": "Krefelder Straße 136",
    "Address__1": "",
    "City": "Willich",
    "Postcode": 47877,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21543045,
    "24 hour?": "FALSE",
    "Latitude": 51.273348,
    "Longitude": 6.555031,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.273348,6.555031",
    "IsOpen24Hours": false,
    "id": "51.273348,6.555031"
  },
  {
    "ID": 2928,
    "Tankstellennummer": "0FH89",
    "Name": "Aral Tankstelle Wuppertal, Langerfelder Str. 121",
    "Address": "Langerfelder Straße 121",
    "Address__1": "",
    "City": "Wuppertal",
    "Postcode": 42389,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2022602549,
    "24 hour?": "FALSE",
    "Latitude": 51.274135,
    "Longitude": 7.238901,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.274135,7.238901",
    "IsOpen24Hours": false,
    "id": "51.274135,7.238901"
  },
  {
    "ID": 2929,
    "Tankstellennummer": "0FH90",
    "Name": "Aral Tankstelle Wuppertal, Uellendahler Str. 264",
    "Address": "Uellendahler Straße 264",
    "Address__1": "",
    "City": "Wuppertal",
    "Postcode": 42109,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 202750411,
    "24 hour?": "TRUE",
    "Latitude": 51.276099,
    "Longitude": 7.155494,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.276099,7.155494",
    "IsOpen24Hours": true,
    "id": "51.276099,7.155494"
  },
  {
    "ID": 2932,
    "Tankstellennummer": "0FH92",
    "Name": "Aral Tankstelle Wuppertal, Carnaper Str. 70",
    "Address": "Carnaper Straße 70",
    "Address__1": "",
    "City": "Wuppertal",
    "Postcode": 42283,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 202507341,
    "24 hour?": "TRUE",
    "Latitude": 51.276312,
    "Longitude": 7.18987,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.276312,7.18987",
    "IsOpen24Hours": true,
    "id": "51.276312,7.18987"
  },
  {
    "ID": 547164443,
    "Tankstellennummer": "0FX59",
    "Name": "LOHFELDEN",
    "Address": "A7",
    "Address__1": "",
    "City": "Lohfelden",
    "Postcode": 34253,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56158580000,
    "24 hour?": "TRUE",
    "Latitude": 51.2774049,
    "Longitude": 9.5318486,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.2774049,9.5318486",
    "IsOpen24Hours": true,
    "id": "51.2774049,9.5318486"
  },
  {
    "ID": 417517392,
    "Tankstellennummer": "TD000655",
    "Name": "WUPPERTAL WESTKOTTER STR. 65",
    "Address": "WESTKOTTER STR. 65",
    "Address__1": "",
    "City": "WUPPERTAL",
    "Postcode": 42277,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0202-505167",
    "24 hour?": "FALSE",
    "Latitude": 51.2789,
    "Longitude": 7.2042,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2789,7.2042",
    "IsOpen24Hours": false,
    "id": "51.2789,7.2042"
  },
  {
    "ID": 2610,
    "Tankstellennummer": "0FD39",
    "Name": "Aral Tankstelle Niesky, An der Hochstraße 16",
    "Address": "An der Hochstraße 16",
    "Address__1": "",
    "City": "Niesky",
    "Postcode": 2906,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3588206921,
    "24 hour?": "FALSE",
    "Latitude": 51.280037,
    "Longitude": 14.818502,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.280037,14.818502",
    "IsOpen24Hours": false,
    "id": "51.280037,14.818502"
  },
  {
    "ID": 1211,
    "Tankstellennummer": "0F322",
    "Name": "Aral Tankstelle Schwelm, Barmer Str. 44",
    "Address": "Barmer Straße 44",
    "Address__1": "",
    "City": "Schwelm",
    "Postcode": 58332,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23364089979,
    "24 hour?": "FALSE",
    "Latitude": 51.280605,
    "Longitude": 7.278696,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.280605,7.278696",
    "IsOpen24Hours": false,
    "id": "51.280605,7.278696"
  },
  {
    "ID": 1268,
    "Tankstellennummer": "0F369",
    "Name": "Aral Tankstelle Willich, Schottelstr.55",
    "Address": "Schottelstraße55",
    "Address__1": "",
    "City": "Willich",
    "Postcode": 47877,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 215691870,
    "24 hour?": "FALSE",
    "Latitude": 51.280864,
    "Longitude": 6.467419,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.280864,6.467419",
    "IsOpen24Hours": false,
    "id": "51.280864,6.467419"
  },
  {
    "ID": 535810846,
    "Tankstellennummer": "TD000242",
    "Name": "KORBACH AROLSER LANDSTR. 39",
    "Address": "AROLSER LANDSTR. 39",
    "Address__1": "",
    "City": "KORBACH",
    "Postcode": 34497,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05631-62226",
    "24 hour?": "FALSE",
    "Latitude": 51.2819,
    "Longitude": 8.8768,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2819,8.8768",
    "IsOpen24Hours": false,
    "id": "51.2819,8.8768"
  },
  {
    "ID": 330156933,
    "Tankstellennummer": "TD038752",
    "Name": "WUPPERTAL JESINGHAUSER STR.110",
    "Address": "JESINGHAUSER STR. 110",
    "Address__1": "",
    "City": "WUPPERTAL",
    "Postcode": 42389,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0202-70516555",
    "24 hour?": "FALSE",
    "Latitude": 51.2828,
    "Longitude": 7.2561,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2828,7.2561",
    "IsOpen24Hours": false,
    "id": "51.2828,7.2561"
  },
  {
    "ID": 244240304,
    "Tankstellennummer": "TD030601",
    "Name": "BRAUNSBEDRA MUECHELNER STR. 04",
    "Address": "MUECHELNER STR. 04",
    "Address__1": "",
    "City": "BRAUNSBEDRA",
    "Postcode": 6242,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034633-22213",
    "24 hour?": "FALSE",
    "Latitude": 51.2837,
    "Longitude": 11.8752,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2837,11.8752",
    "IsOpen24Hours": false,
    "id": "51.2837,11.8752"
  },
  {
    "ID": 1676,
    "Tankstellennummer": "0F681",
    "Name": "Aral Tankstelle Kaufungen, Leipziger Strasse 266",
    "Address": "Leipziger Straße 266",
    "Address__1": "",
    "City": "Kaufungen",
    "Postcode": 34260,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56052524,
    "24 hour?": "FALSE",
    "Latitude": 51.284221,
    "Longitude": 9.602628,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.284221,9.602628",
    "IsOpen24Hours": false,
    "id": "51.284221,9.602628"
  },
  {
    "ID": 1349,
    "Tankstellennummer": "0F434",
    "Name": "Aral Tankstelle Wuppertal, Märkische Str. 29",
    "Address": "Märkische Straße 29",
    "Address__1": "",
    "City": "Wuppertal",
    "Postcode": 42281,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 202520277,
    "24 hour?": "TRUE",
    "Latitude": 51.284764,
    "Longitude": 7.208179,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.284764,7.208179",
    "IsOpen24Hours": true,
    "id": "51.284764,7.208179"
  },
  {
    "ID": 1196,
    "Tankstellennummer": "0F310",
    "Name": "Aral Tankstelle Sundern, Allendorfer Str.41",
    "Address": "Allendorfer Straße 41",
    "Address__1": "",
    "City": "Sundern",
    "Postcode": 59846,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2393321,
    "24 hour?": "FALSE",
    "Latitude": 51.284878,
    "Longitude": 7.954361,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.284878,7.954361",
    "IsOpen24Hours": false,
    "id": "51.284878,7.954361"
  },
  {
    "ID": 863973404,
    "Tankstellennummer": "TD032805",
    "Name": "LEIPZIG BORNAISCHE STR. 227",
    "Address": "BORNAISCHE STR. 227",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4279,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0341-3389322",
    "24 hour?": "FALSE",
    "Latitude": 51.2849,
    "Longitude": 12.3946,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2849,12.3946",
    "IsOpen24Hours": false,
    "id": "51.2849,12.3946"
  },
  {
    "ID": 3283,
    "Tankstellennummer": "0FK46",
    "Name": "Aral Tankstelle Wülfrath, Wilhelmstr. 24-30",
    "Address": "Wilhelmstraße 24",
    "Address__1": "",
    "City": "Wülfrath",
    "Postcode": 42489,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 205874220,
    "24 hour?": "TRUE",
    "Latitude": 51.285329,
    "Longitude": 7.047494,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.285329,7.047494",
    "IsOpen24Hours": true,
    "id": "51.285329,7.047494"
  },
  {
    "ID": 3375,
    "Tankstellennummer": "0FL17",
    "Name": "Aral Tankstelle Markkleeberg, Koburger Strasse 52",
    "Address": "Koburger Straße 52",
    "Address__1": "",
    "City": "Markkleeberg",
    "Postcode": 4416,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3413582448,
    "24 hour?": "TRUE",
    "Latitude": 51.286089,
    "Longitude": 12.366098,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.286089,12.366098",
    "IsOpen24Hours": true,
    "id": "51.286089,12.366098"
  },
  {
    "ID": 1394006731,
    "Tankstellennummer": "TD038059",
    "Name": "LOHFELDEN AM FIESELER WERK 7",
    "Address": "AM FIESELER WERK 7",
    "Address__1": "",
    "City": "LOHFELDEN",
    "Postcode": 34253,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0561-9513161",
    "24 hour?": "FALSE",
    "Latitude": 51.2867,
    "Longitude": 9.5293,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2867,9.5293",
    "IsOpen24Hours": false,
    "id": "51.2867,9.5293"
  },
  {
    "ID": 508695214,
    "Tankstellennummer": "TD021352",
    "Name": "NIESKY GOERLITZER STR.36",
    "Address": "GOERLITZER STR.36",
    "Address__1": "",
    "City": "NIESKY",
    "Postcode": 2906,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03588-205610",
    "24 hour?": "FALSE",
    "Latitude": 51.2876,
    "Longitude": 14.8199,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2876,14.8199",
    "IsOpen24Hours": false,
    "id": "51.2876,14.8199"
  },
  {
    "ID": 332357309,
    "Tankstellennummer": 610649,
    "Name": "WESTFALEN, SCHWELM",
    "Address": "Hauptstr. 134",
    "Address__1": "",
    "City": "Schwelm",
    "Postcode": 58332,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02336/6616",
    "24 hour?": "FALSE",
    "Latitude": 51.2879,
    "Longitude": 7.30058,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2879,7.30058",
    "IsOpen24Hours": false,
    "id": "51.2879,7.30058"
  },
  {
    "ID": 529002436,
    "Tankstellennummer": "TD036921",
    "Name": "KASSEL FRANKFURTER STRASSE 241",
    "Address": "FRANKFURTER STRASSE 241",
    "Address__1": "",
    "City": "KASSEL",
    "Postcode": 34134,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0561-42156",
    "24 hour?": "FALSE",
    "Latitude": 51.2886,
    "Longitude": 9.4718,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2886,9.4718",
    "IsOpen24Hours": false,
    "id": "51.2886,9.4718"
  },
  {
    "ID": 2662,
    "Tankstellennummer": "0FD81",
    "Name": "Aral Tankstelle Braunsbedra, Merseburger Str.",
    "Address": "Merseburger Straße 166",
    "Address__1": "",
    "City": "Braunsbedra",
    "Postcode": 6242,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3463321881,
    "24 hour?": "TRUE",
    "Latitude": 51.288618,
    "Longitude": 11.904674,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.288618,11.904674",
    "IsOpen24Hours": true,
    "id": "51.288618,11.904674"
  },
  {
    "ID": 2143,
    "Tankstellennummer": "0FA02",
    "Name": "Aral Tankstelle Ratingen, Volkardeyer Str. 54",
    "Address": "Volkardeyer Straße 54",
    "Address__1": "",
    "City": "Ratingen",
    "Postcode": 40878,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 210243980,
    "24 hour?": "TRUE",
    "Latitude": 51.288663,
    "Longitude": 6.827508,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.288663,6.827508",
    "IsOpen24Hours": true,
    "id": "51.288663,6.827508"
  },
  {
    "ID": 1088155309,
    "Tankstellennummer": "TD038323",
    "Name": "KREFELD NIROSTASTRASSE 10",
    "Address": "NIROSTASTR. 10",
    "Address__1": "",
    "City": "KREFELD",
    "Postcode": 47807,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02151-9372354",
    "24 hour?": "FALSE",
    "Latitude": 51.289,
    "Longitude": 6.554,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.289,6.554",
    "IsOpen24Hours": false,
    "id": "51.289,6.554"
  },
  {
    "ID": 482221183,
    "Tankstellennummer": "TD021220",
    "Name": "RIESA LEIPZIGER STR. 12",
    "Address": "LEIPZIGER STR. 12",
    "Address__1": "",
    "City": "RIESA",
    "Postcode": 1589,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03525-731747",
    "24 hour?": "FALSE",
    "Latitude": 51.2901,
    "Longitude": 13.2906,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2901,13.2906",
    "IsOpen24Hours": false,
    "id": "51.2901,13.2906"
  },
  {
    "ID": 19890547,
    "Tankstellennummer": "0FV91",
    "Name": "RATINGEN HOHENSTEIN",
    "Address": "Broichhofstrasse 8 (A52)",
    "Address__1": "",
    "City": "Ratingen",
    "Postcode": 40880,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21029422656,
    "24 hour?": "TRUE",
    "Latitude": 51.290548,
    "Longitude": 6.8044,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.290548,6.8044",
    "IsOpen24Hours": true,
    "id": "51.290548,6.8044"
  },
  {
    "ID": 1114591503,
    "Tankstellennummer": "TD032938",
    "Name": "LEIPZIG PRAGER STRASSE 420",
    "Address": "PRAGER STRASSE 420",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4288,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034297-42240",
    "24 hour?": "FALSE",
    "Latitude": 51.2908,
    "Longitude": 12.4447,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2908,12.4447",
    "IsOpen24Hours": false,
    "id": "51.2908,12.4447"
  },
  {
    "ID": 2669,
    "Tankstellennummer": "0FD88",
    "Name": "Aral Tankstelle Bad Dürrenberg, Leipziger Str. 63 a",
    "Address": "Leipziger Straße 63 a",
    "Address__1": "",
    "City": "Bad Dürrenberg",
    "Postcode": 6231,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 346282328,
    "24 hour?": "FALSE",
    "Latitude": 51.290899,
    "Longitude": 12.076298,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.290899,12.076298",
    "IsOpen24Hours": false,
    "id": "51.290899,12.076298"
  },
  {
    "ID": 324269146,
    "Tankstellennummer": "TD007567",
    "Name": "OSCHATZ DRESDENER STR. 95",
    "Address": "DRESDENER STR. 95",
    "Address__1": "",
    "City": "OSCHATZ",
    "Postcode": 4758,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03435-929730",
    "24 hour?": "FALSE",
    "Latitude": 51.291,
    "Longitude": 13.1332,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.291,13.1332",
    "IsOpen24Hours": false,
    "id": "51.291,13.1332"
  },
  {
    "ID": 1625,
    "Tankstellennummer": "0F651",
    "Name": "Aral Tankstelle Kassel, Leuschnerstr. 81",
    "Address": "Leuschner Straße 81",
    "Address__1": "",
    "City": "Kassel",
    "Postcode": 34134,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 561402572,
    "24 hour?": "FALSE",
    "Latitude": 51.29127,
    "Longitude": 9.45569,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.29127,9.45569",
    "IsOpen24Hours": false,
    "id": "51.29127,9.45569"
  },
  {
    "ID": 1322,
    "Tankstellennummer": "0F415",
    "Name": "Aral Tankstelle Schwelm, Talstraße 10",
    "Address": "Talstraße 10",
    "Address__1": "",
    "City": "Schwelm",
    "Postcode": 58332,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23363645,
    "24 hour?": "TRUE",
    "Latitude": 51.292955,
    "Longitude": 7.28626,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.292955,7.28626",
    "IsOpen24Hours": true,
    "id": "51.292955,7.28626"
  },
  {
    "ID": 1636,
    "Tankstellennummer": "0F660",
    "Name": "Aral Tankstelle Willingen, Briloner Str.47",
    "Address": "Briloner Straße 47",
    "Address__1": "",
    "City": "Willingen",
    "Postcode": 34508,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56326397,
    "24 hour?": "FALSE",
    "Latitude": 51.293936,
    "Longitude": 8.604794,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.293936,8.604794",
    "IsOpen24Hours": false,
    "id": "51.293936,8.604794"
  },
  {
    "ID": 1660,
    "Tankstellennummer": "0F670",
    "Name": "Aral Tankstelle Kassel, Nürnberger Str. 106",
    "Address": "Nürnberger Straße 104",
    "Address__1": "",
    "City": "Kassel",
    "Postcode": 34123,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56159182,
    "24 hour?": "TRUE",
    "Latitude": 51.294464,
    "Longitude": 9.511655,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.294464,9.511655",
    "IsOpen24Hours": true,
    "id": "51.294464,9.511655"
  },
  {
    "ID": 24969344,
    "Tankstellennummer": "TD021246",
    "Name": "THIENDORF KAMENZER STR. 27",
    "Address": "KAMENZER STR. 27",
    "Address__1": "",
    "City": "THIENDORF",
    "Postcode": 1561,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035248-81325",
    "24 hour?": "FALSE",
    "Latitude": 51.2945,
    "Longitude": 13.7362,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2945,13.7362",
    "IsOpen24Hours": false,
    "id": "51.2945,13.7362"
  },
  {
    "ID": 231939745,
    "Tankstellennummer": "TD123760",
    "Name": "NETTETAL DUELKENER STR. 8",
    "Address": "DUELKENER STR. 8",
    "Address__1": "",
    "City": "NETTETAL",
    "Postcode": 41334,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02153-7971",
    "24 hour?": "FALSE",
    "Latitude": 51.2946,
    "Longitude": 6.2574,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2946,6.2574",
    "IsOpen24Hours": false,
    "id": "51.2946,6.2574"
  },
  {
    "ID": 1040170379,
    "Tankstellennummer": 5014,
    "Name": "Agip Grossenhain",
    "Address": "Radeburger Str. 150",
    "Address__1": "",
    "City": "Grossenhain",
    "Postcode": 1558,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 35225023",
    "24 hour?": "TRUE",
    "Latitude": 51.295135,
    "Longitude": 13.543415,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.295135,13.543415",
    "IsOpen24Hours": true,
    "id": "51.295135,13.543415"
  },
  {
    "ID": 1865278288,
    "Tankstellennummer": "0FM14",
    "Name": "Aral TankStelle Schönfeld, Königsbrücker Straße 25",
    "Address": "Königsbrücker Straße 25",
    "Address__1": "",
    "City": "Schönfeld",
    "Postcode": 1561,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3524888447,
    "24 hour?": "TRUE",
    "Latitude": 51.295416,
    "Longitude": 13.730064,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.295416,13.730064",
    "IsOpen24Hours": true,
    "id": "51.295416,13.730064"
  },
  {
    "ID": 1923068154,
    "Tankstellennummer": "TD000739",
    "Name": "RATINGEN HOMBERGER STR. 21",
    "Address": "HOMBERGER STR.21",
    "Address__1": "",
    "City": "RATINGEN",
    "Postcode": 40882,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02102-843167",
    "24 hour?": "FALSE",
    "Latitude": 51.296,
    "Longitude": 6.8668,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.296,6.8668",
    "IsOpen24Hours": false,
    "id": "51.296,6.8668"
  },
  {
    "ID": 1357,
    "Tankstellennummer": "0F443",
    "Name": "Aral Tankstelle Ennepetal, Neustr. 19",
    "Address": "Neustraße 19",
    "Address__1": "",
    "City": "Ennepetal",
    "Postcode": 58256,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 233388816,
    "24 hour?": "TRUE",
    "Latitude": 51.29687,
    "Longitude": 7.360476,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.29687,7.360476",
    "IsOpen24Hours": true,
    "id": "51.29687,7.360476"
  },
  {
    "ID": 2759,
    "Tankstellennummer": "0FE52",
    "Name": "Aral Tankstelle Großenhain, Elsterwerdaer Str. 19 a",
    "Address": "Elsterwerdaer Straße 19 a",
    "Address__1": "",
    "City": "Großenhain",
    "Postcode": 1558,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 352238555,
    "24 hour?": "FALSE",
    "Latitude": 51.298017,
    "Longitude": 13.533796,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.298017,13.533796",
    "IsOpen24Hours": false,
    "id": "51.298017,13.533796"
  },
  {
    "ID": 593738896,
    "Tankstellennummer": "TD035824",
    "Name": "AN DER SCHMUECKE ZUR THUERINGE",
    "Address": "ZUR THUERINGER PFORTE 10",
    "Address__1": "",
    "City": "AN DER SCHMUECKE",
    "Postcode": 6577,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034673-779718",
    "24 hour?": "FALSE",
    "Latitude": 51.2997,
    "Longitude": 11.2021,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.2997,11.2021",
    "IsOpen24Hours": false,
    "id": "51.2997,11.2021"
  },
  {
    "ID": 3257,
    "Tankstellennummer": "0FK30",
    "Name": "Aral Tankstelle Altena, Bahnhofstrasse 32",
    "Address": "Bahnhofstraße 32",
    "Address__1": "",
    "City": "Altena",
    "Postcode": 58762,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 235225355,
    "24 hour?": "TRUE",
    "Latitude": 51.302346,
    "Longitude": 7.667049,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.302346,7.667049",
    "IsOpen24Hours": true,
    "id": "51.302346,7.667049"
  },
  {
    "ID": 1716,
    "Tankstellennummer": "0F710",
    "Name": "Aral Tankstelle Kassel, Frankfurter Straße 90",
    "Address": "Frankfurter Straße 90",
    "Address__1": "",
    "City": "Kassel",
    "Postcode": 34121,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56123104,
    "24 hour?": "TRUE",
    "Latitude": 51.303697,
    "Longitude": 9.483775,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.303697,9.483775",
    "IsOpen24Hours": true,
    "id": "51.303697,9.483775"
  },
  {
    "ID": 2774,
    "Tankstellennummer": "0FE66",
    "Name": "Aral Tankstelle Oschatz, An der B 6",
    "Address": "Leipziger Straße 31",
    "Address__1": "",
    "City": "Oschatz",
    "Postcode": 4758,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3435926583,
    "24 hour?": "TRUE",
    "Latitude": 51.304055,
    "Longitude": 13.093789,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.304055,13.093789",
    "IsOpen24Hours": true,
    "id": "51.304055,13.093789"
  },
  {
    "ID": 746308399,
    "Tankstellennummer": "TD040618",
    "Name": "RIESA, KLOETZER STR. 12A",
    "Address": "KLOETZER STR. 12a",
    "Address__1": "",
    "City": "RIESA",
    "Postcode": 1587,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03525/7770310",
    "24 hour?": "FALSE",
    "Latitude": 51.3054,
    "Longitude": 13.2976,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3054,13.2976",
    "IsOpen24Hours": false,
    "id": "51.3054,13.2976"
  },
  {
    "ID": 2146,
    "Tankstellennummer": "0FA05",
    "Name": "Aral Tankstelle Velbert, Elberfelder Str. 99",
    "Address": "Elberfelder Straße 99",
    "Address__1": "",
    "City": "Velbert",
    "Postcode": 42553,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2053923666,
    "24 hour?": "FALSE",
    "Latitude": 51.307914,
    "Longitude": 7.08761,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.307914,7.08761",
    "IsOpen24Hours": false,
    "id": "51.307914,7.08761"
  },
  {
    "ID": 1887831005,
    "Tankstellennummer": 4740,
    "Name": "Agip Kassel",
    "Address": "Sandershaeuser Str. 43",
    "Address__1": "",
    "City": "Kassel",
    "Postcode": 34123,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 56157995",
    "24 hour?": "TRUE",
    "Latitude": 51.30889,
    "Longitude": 9.51857,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.30889,9.51857",
    "IsOpen24Hours": true,
    "id": "51.30889,9.51857"
  },
  {
    "ID": 827307335,
    "Tankstellennummer": "TD034611",
    "Name": "LEIPZIG ARNO-NITZSCHE STRASSE",
    "Address": "ARNO-NITZSCHE STRASSE 32",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4277,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0341-3018122",
    "24 hour?": "FALSE",
    "Latitude": 51.3089,
    "Longitude": 12.3895,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3089,12.3895",
    "IsOpen24Hours": false,
    "id": "51.3089,12.3895"
  },
  {
    "ID": 1647,
    "Tankstellennummer": "0F664",
    "Name": "Aral Tankstelle Niestetal, Niestetalstr. 10",
    "Address": "Niestetalstraße 10",
    "Address__1": "",
    "City": "Niestetal",
    "Postcode": 34266,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5619209547,
    "24 hour?": "FALSE",
    "Latitude": 51.309105,
    "Longitude": 9.570691,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.309105,9.570691",
    "IsOpen24Hours": false,
    "id": "51.309105,9.570691"
  },
  {
    "ID": 1684,
    "Tankstellennummer": "0F688",
    "Name": "Aral Tankstelle Kassel, Kohlenstraße 60",
    "Address": "Kohlenstraße 60",
    "Address__1": "",
    "City": "Kassel",
    "Postcode": 34121,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56124343,
    "24 hour?": "FALSE",
    "Latitude": 51.309211,
    "Longitude": 9.462022,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.309211,9.462022",
    "IsOpen24Hours": false,
    "id": "51.309211,9.462022"
  },
  {
    "ID": 2185,
    "Tankstellennummer": "0FE83",
    "Name": "Aral Tankstelle Nettetal-Lobberich, Kempener Str. 83",
    "Address": "Kempener Straße 83",
    "Address__1": "",
    "City": "Nettetal",
    "Postcode": 41334,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21532387,
    "24 hour?": "TRUE",
    "Latitude": 51.310413,
    "Longitude": 6.291814,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.310413,6.291814",
    "IsOpen24Hours": true,
    "id": "51.310413,6.291814"
  },
  {
    "ID": 1256,
    "Tankstellennummer": "0F359",
    "Name": "Aral Tankstelle Nettetal, Kölner Str. 70",
    "Address": "Kölner Straße 70",
    "Address__1": "",
    "City": "Nettetal",
    "Postcode": 41334,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21576169,
    "24 hour?": "FALSE",
    "Latitude": 51.310836,
    "Longitude": 6.204854,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.310836,6.204854",
    "IsOpen24Hours": false,
    "id": "51.310836,6.204854"
  },
  {
    "ID": 210186545,
    "Tankstellennummer": "TD036939",
    "Name": "KASSEL LEIPZIGER STRASSE 22",
    "Address": "LEIPZIGER STRASSE 22",
    "Address__1": "",
    "City": "KASSEL",
    "Postcode": 34125,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0561-572144",
    "24 hour?": "FALSE",
    "Latitude": 51.3113,
    "Longitude": 9.5111,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3113,9.5111",
    "IsOpen24Hours": false,
    "id": "51.3113,9.5111"
  },
  {
    "ID": 2035285855,
    "Tankstellennummer": "TD032706",
    "Name": "LEIPZIG PRAGER STRASSE 179-181",
    "Address": "PRAGER STRASSE 179",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4299,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0341-8629060",
    "24 hour?": "FALSE",
    "Latitude": 51.313,
    "Longitude": 12.4149,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.313,12.4149",
    "IsOpen24Hours": false,
    "id": "51.313,12.4149"
  },
  {
    "ID": 1037820180,
    "Tankstellennummer": "TD032946",
    "Name": "RIESA LAUCHHAMMERSTR. 12",
    "Address": "LAUCHHAMMERSTR. 12",
    "Address__1": "",
    "City": "RIESA",
    "Postcode": 1591,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03525-733388",
    "24 hour?": "FALSE",
    "Latitude": 51.3133,
    "Longitude": 13.2893,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3133,13.2893",
    "IsOpen24Hours": false,
    "id": "51.3133,13.2893"
  },
  {
    "ID": 913959353,
    "Tankstellennummer": "TD000346",
    "Name": "NETTETAL WERNER-JAEGER-STR. 13",
    "Address": "WERNER-JAEGER-STR. 13",
    "Address__1": "",
    "City": "NETTETAL",
    "Postcode": 41334,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02153-3254",
    "24 hour?": "FALSE",
    "Latitude": 51.3134,
    "Longitude": 6.2739,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3134,6.2739",
    "IsOpen24Hours": false,
    "id": "51.3134,6.2739"
  },
  {
    "ID": 1353,
    "Tankstellennummer": "0F438",
    "Name": "Aral Tankstelle Krefeld, Kölner Str. 316",
    "Address": "Kölner Straße 316",
    "Address__1": "",
    "City": "Krefeld",
    "Postcode": 47807,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2151315131,
    "24 hour?": "FALSE",
    "Latitude": 51.313477,
    "Longitude": 6.579583,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.313477,6.579583",
    "IsOpen24Hours": false,
    "id": "51.313477,6.579583"
  },
  {
    "ID": 2770,
    "Tankstellennummer": "0FE62",
    "Name": "Aral Tankstelle Leipzig, An der Tabaksmühle",
    "Address": "An der Tabaksmühle",
    "Address__1": "",
    "City": "Leipzig",
    "Postcode": 4277,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 341868480,
    "24 hour?": "TRUE",
    "Latitude": 51.313577,
    "Longitude": 12.404612,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.313577,12.404612",
    "IsOpen24Hours": true,
    "id": "51.313577,12.404612"
  },
  {
    "ID": 2915,
    "Tankstellennummer": "0FH81",
    "Name": "Aral Tankstelle Krefeld, Gladbacher Str. 313-331",
    "Address": "Gladbacher Straße 313-331",
    "Address__1": "",
    "City": "Krefeld",
    "Postcode": 47805,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2151399027,
    "24 hour?": "TRUE",
    "Latitude": 51.314466,
    "Longitude": 6.557782,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.314466,6.557782",
    "IsOpen24Hours": true,
    "id": "51.314466,6.557782"
  },
  {
    "ID": 2898,
    "Tankstellennummer": "0FH64",
    "Name": "Aral Tankstelle Balve, Neuenrader Str. 2",
    "Address": "Neuenrader Straße 2",
    "Address__1": "",
    "City": "Balve",
    "Postcode": 58802,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2375910443,
    "24 hour?": "FALSE",
    "Latitude": 51.314659,
    "Longitude": 7.875074,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.314659,7.875074",
    "IsOpen24Hours": false,
    "id": "51.314659,7.875074"
  },
  {
    "ID": 1827667639,
    "Tankstellennummer": 610634,
    "Name": "WESTFALEN, GEVELSBERG",
    "Address": "Wittener Str. 19",
    "Address__1": "",
    "City": "Gevelsberg",
    "Postcode": 58285,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02332/2867",
    "24 hour?": "FALSE",
    "Latitude": 51.3217,
    "Longitude": 7.33794,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3217,7.33794",
    "IsOpen24Hours": false,
    "id": "51.3217,7.33794"
  },
  {
    "ID": 2152,
    "Tankstellennummer": "0FA11",
    "Name": "Aral Tankstelle Heiligenhaus, Ratinger Str.19",
    "Address": "Ratinger Straße 19",
    "Address__1": "",
    "City": "Heiligenhaus",
    "Postcode": 42579,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 205693030,
    "24 hour?": "TRUE",
    "Latitude": 51.321982,
    "Longitude": 6.955689,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.321982,6.955689",
    "IsOpen24Hours": true,
    "id": "51.321982,6.955689"
  },
  {
    "ID": 1361,
    "Tankstellennummer": "0F446",
    "Name": "Aral Tankstelle Krefeld, Untergath 210",
    "Address": "Untergath 210",
    "Address__1": "",
    "City": "Krefeld",
    "Postcode": 47805,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2151557780,
    "24 hour?": "TRUE",
    "Latitude": 51.3221928,
    "Longitude": 6.5914068,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.3221928,6.5914068",
    "IsOpen24Hours": true,
    "id": "51.3221928,6.5914068"
  },
  {
    "ID": 1653300282,
    "Tankstellennummer": "TD038489",
    "Name": "LEIPZIG ZSCHOCHERSCHE STR. 84",
    "Address": "ZSCHOCHERSCHE STR. 84",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4229,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0341-4803837",
    "24 hour?": "FALSE",
    "Latitude": 51.3227,
    "Longitude": 12.3319,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3227,12.3319",
    "IsOpen24Hours": false,
    "id": "51.3227,12.3319"
  },
  {
    "ID": 1723,
    "Tankstellennummer": "0F715",
    "Name": "Aral Tankstelle Wolfhagen, Ippinghäuser Str. 10",
    "Address": "Ippinghäuser Straße 10",
    "Address__1": "",
    "City": "Wolfhagen",
    "Postcode": 34466,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56929876100,
    "24 hour?": "FALSE",
    "Latitude": 51.32347,
    "Longitude": 9.173734,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.32347,9.173734",
    "IsOpen24Hours": false,
    "id": "51.32347,9.173734"
  },
  {
    "ID": 3007,
    "Tankstellennummer": "0FI55",
    "Name": "Aral Tankstelle Kassel, Fuldatalstr. 1",
    "Address": "Fuldatalstraße 1",
    "Address__1": "",
    "City": "Kassel",
    "Postcode": 34125,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 561873851,
    "24 hour?": "TRUE",
    "Latitude": 51.323519,
    "Longitude": 9.513459,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.323519,9.513459",
    "IsOpen24Hours": true,
    "id": "51.323519,9.513459"
  },
  {
    "ID": 1236,
    "Tankstellennummer": "0F343",
    "Name": "Aral Tankstelle Sundern, Westenfelder Str.20",
    "Address": "Westenfelder Straße 20",
    "Address__1": "",
    "City": "Sundern",
    "Postcode": 59846,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2933976818,
    "24 hour?": "FALSE",
    "Latitude": 51.325678,
    "Longitude": 8.038365,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.325678,8.038365",
    "IsOpen24Hours": false,
    "id": "51.325678,8.038365"
  },
  {
    "ID": 1552415828,
    "Tankstellennummer": 610359,
    "Name": "WESTFALEN, TOENISVORST",
    "Address": "Düsseldorfer Str. 2",
    "Address__1": "",
    "City": "Tönisvorst",
    "Postcode": 47918,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02151/9316678",
    "24 hour?": "TRUE",
    "Latitude": 51.3271,
    "Longitude": 6.47041,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.3271,6.47041",
    "IsOpen24Hours": true,
    "id": "51.3271,6.47041"
  },
  {
    "ID": 1109446744,
    "Tankstellennummer": "0FR90",
    "Name": "WAURICH OHG",
    "Address": "An der Kleinbahn 5",
    "Address__1": "",
    "City": "Nettetal",
    "Postcode": 41334,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21578958775,
    "24 hour?": "TRUE",
    "Latitude": 51.328647,
    "Longitude": 6.196333,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.328647,6.196333",
    "IsOpen24Hours": true,
    "id": "51.328647,6.196333"
  },
  {
    "ID": 1560809780,
    "Tankstellennummer": 610354,
    "Name": "WESTFALEN, KREFELD",
    "Address": "Neue Ritter Str. 41",
    "Address__1": "",
    "City": "Krefeld",
    "Postcode": 47805,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02151/318103",
    "24 hour?": "FALSE",
    "Latitude": 51.3291,
    "Longitude": 6.58718,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3291,6.58718",
    "IsOpen24Hours": false,
    "id": "51.3291,6.58718"
  },
  {
    "ID": 563929075,
    "Tankstellennummer": "0FN60",
    "Name": "BRANDIS",
    "Address": "Beuchaer Straße 73",
    "Address__1": "",
    "City": "Brandis",
    "Postcode": 4821,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3429274221,
    "24 hour?": "FALSE",
    "Latitude": 51.329914,
    "Longitude": 12.592826,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.329914,12.592826",
    "IsOpen24Hours": false,
    "id": "51.329914,12.592826"
  },
  {
    "ID": 559795625,
    "Tankstellennummer": "TD036947",
    "Name": "KASSEL WOLFHAGER STRASSE 276",
    "Address": "WOLFHAGER STRASSE 276",
    "Address__1": "",
    "City": "KASSEL",
    "Postcode": 34128,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0561-882422",
    "24 hour?": "FALSE",
    "Latitude": 51.3314,
    "Longitude": 9.4557,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3314,9.4557",
    "IsOpen24Hours": false,
    "id": "51.3314,9.4557"
  },
  {
    "ID": 2921,
    "Tankstellennummer": "0FH85",
    "Name": "Aral Tankstelle Velbert, Heidestr. 116-120",
    "Address": "Heidestraße 116-120",
    "Address__1": "",
    "City": "Velbert",
    "Postcode": 42549,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2051255786,
    "24 hour?": "TRUE",
    "Latitude": 51.331862,
    "Longitude": 7.038549,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.331862,7.038549",
    "IsOpen24Hours": true,
    "id": "51.331862,7.038549"
  },
  {
    "ID": 1717,
    "Tankstellennummer": "0F711",
    "Name": "Aral Tankstelle Kassel, Holländische Str. 142",
    "Address": "Holländische Straße 142",
    "Address__1": "",
    "City": "Kassel",
    "Postcode": 34127,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5619893813,
    "24 hour?": "FALSE",
    "Latitude": 51.333741,
    "Longitude": 9.495671,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.333741,9.495671",
    "IsOpen24Hours": false,
    "id": "51.333741,9.495671"
  },
  {
    "ID": 1227,
    "Tankstellennummer": "0F334",
    "Name": "Aral Tankstelle Nachrodt-Wiblingwerd, Altenaer Str. 29",
    "Address": "Altenaer Straße 29",
    "Address__1": "",
    "City": "Nachrodt-Wiblingwerde",
    "Postcode": 58769,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23523515,
    "24 hour?": "FALSE",
    "Latitude": 51.33389,
    "Longitude": 7.649726,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.33389,7.649726",
    "IsOpen24Hours": false,
    "id": "51.33389,7.649726"
  },
  {
    "ID": 1553609055,
    "Tankstellennummer": "TD120480",
    "Name": "VELBERT FRIEDRICH-EBERT-STR. 1",
    "Address": "FRIEDRICH-EBERT-STR. 12-14",
    "Address__1": "",
    "City": "VELBERT",
    "Postcode": 42549,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02051-255409",
    "24 hour?": "FALSE",
    "Latitude": 51.3348,
    "Longitude": 7.0489,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3348,7.0489",
    "IsOpen24Hours": false,
    "id": "51.3348,7.0489"
  },
  {
    "ID": 839909832,
    "Tankstellennummer": "TD024869",
    "Name": "LEIPZIG LUETZNER STR.7B",
    "Address": "LUETZNER STR.7B",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4177,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0341-4800930",
    "24 hour?": "FALSE",
    "Latitude": 51.3353,
    "Longitude": 12.3378,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3353,12.3378",
    "IsOpen24Hours": false,
    "id": "51.3353,12.3378"
  },
  {
    "ID": 2664,
    "Tankstellennummer": "0FD83",
    "Name": "Aral Tankstelle Leipzig, Merseburger Str. 109-113",
    "Address": "Merseburger Straße 111",
    "Address__1": "",
    "City": "Leipzig",
    "Postcode": 4177,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3414801898,
    "24 hour?": "TRUE",
    "Latitude": 51.338168,
    "Longitude": 12.325599,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.338168,12.325599",
    "IsOpen24Hours": true,
    "id": "51.338168,12.325599"
  },
  {
    "ID": 2916,
    "Tankstellennummer": "0FH82",
    "Name": "Aral Tankstelle Ratingen, Am Krummenweg 4",
    "Address": "Am Krummenweg 9",
    "Address__1": "",
    "City": "Ratingen",
    "Postcode": 40885,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 210218051,
    "24 hour?": "FALSE",
    "Latitude": 51.338451,
    "Longitude": 6.869088,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.338451,6.869088",
    "IsOpen24Hours": false,
    "id": "51.338451,6.869088"
  },
  {
    "ID": 505879771,
    "Tankstellennummer": "TD007484",
    "Name": "LEIPZIG SCHOMBURGKSTR. 8",
    "Address": "SCHOMBURGKSTR. 8",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4179,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0341-4419128",
    "24 hour?": "FALSE",
    "Latitude": 51.3399,
    "Longitude": 12.2993,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3399,12.2993",
    "IsOpen24Hours": false,
    "id": "51.3399,12.2993"
  },
  {
    "ID": 2768,
    "Tankstellennummer": "0FE60",
    "Name": "Aral Tankstelle Leipzig, Marschnerstr. 50",
    "Address": "Marschnerstraße 50",
    "Address__1": "",
    "City": "Leipzig",
    "Postcode": 4109,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3419808809,
    "24 hour?": "TRUE",
    "Latitude": 51.340281,
    "Longitude": 12.354341,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.340281,12.354341",
    "IsOpen24Hours": true,
    "id": "51.340281,12.354341"
  },
  {
    "ID": 3479,
    "Tankstellennummer": "0FL95",
    "Name": "Aral Tankstelle Sprockhövel, Wittener Str. 68",
    "Address": "Wittener Straße 68",
    "Address__1": "",
    "City": "Sprockhövel",
    "Postcode": 45549,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23392796,
    "24 hour?": "TRUE",
    "Latitude": 51.340392,
    "Longitude": 7.289735,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.340392,7.289735",
    "IsOpen24Hours": true,
    "id": "51.340392,7.289735"
  },
  {
    "ID": 2722,
    "Tankstellennummer": "0FE36",
    "Name": "Aral Tankstelle Zeithain, Lichtenseer Str.  (B169)",
    "Address": "Lichtenseer Str.  18",
    "Address__1": "",
    "City": "Zeithain",
    "Postcode": 1619,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3525761326,
    "24 hour?": "TRUE",
    "Latitude": 51.340408,
    "Longitude": 13.347722,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.340408,13.347722",
    "IsOpen24Hours": true,
    "id": "51.340408,13.347722"
  },
  {
    "ID": 252667544,
    "Tankstellennummer": 5103,
    "Name": "Agip Leipzig",
    "Address": "Paunsdorfer Str. 70",
    "Address__1": "",
    "City": "Leipzig",
    "Postcode": 4316,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 34165151",
    "24 hour?": "TRUE",
    "Latitude": 51.34236,
    "Longitude": 12.44519,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.34236,12.44519",
    "IsOpen24Hours": true,
    "id": "51.34236,12.44519"
  },
  {
    "ID": 2517,
    "Tankstellennummer": "0FC58",
    "Name": "Aral Tankstelle Merseburg, Thomas-Müntzer-Ring",
    "Address": "Thomas-Müntzer-Strasse 2",
    "Address__1": "",
    "City": "Merseburg",
    "Postcode": 6217,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3461525570,
    "24 hour?": "TRUE",
    "Latitude": 51.342787,
    "Longitude": 11.9892,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.342787,11.9892",
    "IsOpen24Hours": true,
    "id": "51.342787,11.9892"
  },
  {
    "ID": 410246834,
    "Tankstellennummer": "TD035410",
    "Name": "LEIPZIG LUDWIG-ERHARD-STR. 36",
    "Address": "LUDWIG-ERHARD-STR. 36",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4103,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0341-6810411",
    "24 hour?": "FALSE",
    "Latitude": 51.3432,
    "Longitude": 12.3955,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3432,12.3955",
    "IsOpen24Hours": false,
    "id": "51.3432,12.3955"
  },
  {
    "ID": 544205019,
    "Tankstellennummer": "TD041947",
    "Name": "KREFELD BRUCHFELD 94",
    "Address": "BRUCHFELD 94",
    "Address__1": "",
    "City": "KREFELD",
    "Postcode": 47809,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02151 3256971",
    "24 hour?": "FALSE",
    "Latitude": 51.3444,
    "Longitude": 6.6283,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3444,6.6283",
    "IsOpen24Hours": false,
    "id": "51.3444,6.6283"
  },
  {
    "ID": 524137359,
    "Tankstellennummer": "TD030619",
    "Name": "MERSEBURG THOMAS-MUENTZER-STR.",
    "Address": "THOMAS-MUENTZER-STR. 5",
    "Address__1": "",
    "City": "MERSEBURG",
    "Postcode": 6217,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03461-503727",
    "24 hour?": "FALSE",
    "Latitude": 51.3449,
    "Longitude": 11.988,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3449,11.988",
    "IsOpen24Hours": false,
    "id": "51.3449,11.988"
  },
  {
    "ID": 495813294,
    "Tankstellennummer": "TD032573",
    "Name": "LEIPZIG RIESAER STR. 74",
    "Address": "RIESAER STR. 74",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4328,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0341-2519182",
    "24 hour?": "FALSE",
    "Latitude": 51.3456,
    "Longitude": 12.4612,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3456,12.4612",
    "IsOpen24Hours": false,
    "id": "51.3456,12.4612"
  },
  {
    "ID": 685974992,
    "Tankstellennummer": "TD000601",
    "Name": "VELBERT DONNERSTR. 20",
    "Address": "DONNERSTR. 20",
    "Address__1": "",
    "City": "VELBERT",
    "Postcode": 42555,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02052-3308",
    "24 hour?": "FALSE",
    "Latitude": 51.3465,
    "Longitude": 7.1255,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3465,7.1255",
    "IsOpen24Hours": false,
    "id": "51.3465,7.1255"
  },
  {
    "ID": 2900,
    "Tankstellennummer": "0FH66",
    "Name": "Aral Tankstelle Meschede, Briloner Str. 44",
    "Address": "Briloner Straße 44",
    "Address__1": "",
    "City": "Meschede",
    "Postcode": 59872,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 29151412,
    "24 hour?": "TRUE",
    "Latitude": 51.346872,
    "Longitude": 8.296652,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.346872,8.296652",
    "IsOpen24Hours": true,
    "id": "51.346872,8.296652"
  },
  {
    "ID": 1233,
    "Tankstellennummer": "0F340",
    "Name": "Aral Tankstelle Hagen, Eilper Straße 40",
    "Address": "Eilper Straße 40",
    "Address__1": "",
    "City": "Hagen",
    "Postcode": 58091,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 233177020,
    "24 hour?": "TRUE",
    "Latitude": 51.346934,
    "Longitude": 7.49486,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.346934,7.49486",
    "IsOpen24Hours": true,
    "id": "51.346934,7.49486"
  },
  {
    "ID": 1715,
    "Tankstellennummer": "0F709",
    "Name": "Aral Tankstelle Fuldatal, Veckerhagener Str.19",
    "Address": "Veckerhagener Straße19",
    "Address__1": "",
    "City": "Fuldatal",
    "Postcode": 34233,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 561811447,
    "24 hour?": "FALSE",
    "Latitude": 51.347365,
    "Longitude": 9.523922,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.347365,9.523922",
    "IsOpen24Hours": false,
    "id": "51.347365,9.523922"
  },
  {
    "ID": 3387,
    "Tankstellennummer": "0FL28",
    "Name": "Aral Tankstelle Günthersdorf, Merseburger Str.",
    "Address": "Nordpark 3",
    "Address__1": "",
    "City": "Leuna OT Kötschlitz",
    "Postcode": 6237,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3463820984,
    "24 hour?": "TRUE",
    "Latitude": 51.347609,
    "Longitude": 12.175212,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.347609,12.175212",
    "IsOpen24Hours": true,
    "id": "51.347609,12.175212"
  },
  {
    "ID": 1258,
    "Tankstellennummer": "0F360",
    "Name": "Aral Tankstelle Krefeld, Hülser Str. 299",
    "Address": "Hülser Straße 299",
    "Address__1": "",
    "City": "Krefeld",
    "Postcode": 47803,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2151753235,
    "24 hour?": "TRUE",
    "Latitude": 51.347965,
    "Longitude": 6.543426,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.347965,6.543426",
    "IsOpen24Hours": true,
    "id": "51.347965,6.543426"
  },
  {
    "ID": 2661,
    "Tankstellennummer": "0FD80",
    "Name": "Aral Tankstelle Leipzig, Ludwig-Hupfeld-Str. 15",
    "Address": "Ludwig-Hupfeld-Straße 15",
    "Address__1": "",
    "City": "Leipzig",
    "Postcode": 4178,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3414418938,
    "24 hour?": "FALSE",
    "Latitude": 51.348533,
    "Longitude": 12.301473,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.348533,12.301473",
    "IsOpen24Hours": false,
    "id": "51.348533,12.301473"
  },
  {
    "ID": 210816938,
    "Tankstellennummer": 5130,
    "Name": "Agip Merseburg",
    "Address": "Thomas Muentzer Str. 74",
    "Address__1": "",
    "City": "Merseburg",
    "Postcode": 6217,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": 34618219700,
    "24 hour?": "TRUE",
    "Latitude": 51.34864,
    "Longitude": 11.98288,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.34864,11.98288",
    "IsOpen24Hours": true,
    "id": "51.34864,11.98288"
  },
  {
    "ID": 2691,
    "Tankstellennummer": "0FE10",
    "Name": "Aral Tankstelle Leipzig, Permoserstr.32",
    "Address": "Permoserstraße 32a",
    "Address__1": "",
    "City": "Leipzig",
    "Postcode": 4318,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3412323185,
    "24 hour?": "FALSE",
    "Latitude": 51.350964,
    "Longitude": 12.434185,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.350964,12.434185",
    "IsOpen24Hours": false,
    "id": "51.350964,12.434185"
  },
  {
    "ID": 1234,
    "Tankstellennummer": "0F341",
    "Name": "Aral Tankstelle Meschede, Warsteiner Str.46",
    "Address": "Warsteiner Straße 46",
    "Address__1": "",
    "City": "Meschede",
    "Postcode": 59872,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2912306,
    "24 hour?": "FALSE",
    "Latitude": 51.352973,
    "Longitude": 8.281412,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.352973,8.281412",
    "IsOpen24Hours": false,
    "id": "51.352973,8.281412"
  },
  {
    "ID": 1318,
    "Tankstellennummer": "0F412",
    "Name": "Aral Tankstelle Krefeld, Wüstrathstr. 6",
    "Address": "Wüstrathstraße 6",
    "Address__1": "",
    "City": "Krefeld",
    "Postcode": 47829,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 215146632,
    "24 hour?": "FALSE",
    "Latitude": 51.353437,
    "Longitude": 6.64274,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.353437,6.64274",
    "IsOpen24Hours": false,
    "id": "51.353437,6.64274"
  },
  {
    "ID": 599847333,
    "Tankstellennummer": "TD035964",
    "Name": "LEIPZIG RACKWITZER STRASSE 26",
    "Address": "RACKWITZER STRASSE 26",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4347,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0341-2345861",
    "24 hour?": "FALSE",
    "Latitude": 51.3544,
    "Longitude": 12.395,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3544,12.395",
    "IsOpen24Hours": false,
    "id": "51.3544,12.395"
  },
  {
    "ID": 1330,
    "Tankstellennummer": "0F422",
    "Name": "Aral Tankstelle Hagen, Hohenlimburger Str. 191",
    "Address": "Hohenlimburger Straße 191",
    "Address__1": "",
    "City": "Hagen",
    "Postcode": 58119,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 233445186,
    "24 hour?": "TRUE",
    "Latitude": 51.355959,
    "Longitude": 7.556166,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.355959,7.556166",
    "IsOpen24Hours": true,
    "id": "51.355959,7.556166"
  },
  {
    "ID": 910106195,
    "Tankstellennummer": "0FX83",
    "Name": "ZIERENBERG",
    "Address": "A44",
    "Address__1": "",
    "City": "Zierenberg",
    "Postcode": 34289,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56068124,
    "24 hour?": "TRUE",
    "Latitude": 51.3568504,
    "Longitude": 9.2789292,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.3568504,9.2789292",
    "IsOpen24Hours": true,
    "id": "51.3568504,9.2789292"
  },
  {
    "ID": 1037221911,
    "Tankstellennummer": "TD000132",
    "Name": "DUISBURG UERDINGER STR. 130",
    "Address": "UERDINGER STR. 130",
    "Address__1": "",
    "City": "DUISBURG",
    "Postcode": 47259,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0203-781709",
    "24 hour?": "FALSE",
    "Latitude": 51.3587,
    "Longitude": 6.6875,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3587,6.6875",
    "IsOpen24Hours": false,
    "id": "51.3587,6.6875"
  },
  {
    "ID": 493895577,
    "Tankstellennummer": "0FN69",
    "Name": "MACHERN",
    "Address": "Gartenallee 2",
    "Address__1": "",
    "City": "Machern",
    "Postcode": 4827,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 34292869790,
    "24 hour?": "FALSE",
    "Latitude": 51.359901,
    "Longitude": 12.616976,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.359901,12.616976",
    "IsOpen24Hours": false,
    "id": "51.359901,12.616976"
  },
  {
    "ID": 1096632966,
    "Tankstellennummer": "TD143190",
    "Name": "WETTER VOGELSANGER STR.37",
    "Address": "VOGELSANGER STR.37",
    "Address__1": "",
    "City": "WETTER",
    "Postcode": 58300,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02335-60371",
    "24 hour?": "FALSE",
    "Latitude": 51.3608,
    "Longitude": 7.376,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3608,7.376",
    "IsOpen24Hours": false,
    "id": "51.3608,7.376"
  },
  {
    "ID": 1213,
    "Tankstellennummer": "0F326",
    "Name": "Aral Tankstelle Hagen, Eppenhauser Str. 134",
    "Address": "Eppenhauser Straße 134",
    "Address__1": "",
    "City": "Hagen",
    "Postcode": 58093,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23317878512,
    "24 hour?": "FALSE",
    "Latitude": 51.36096,
    "Longitude": 7.506292,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.36096,7.506292",
    "IsOpen24Hours": false,
    "id": "51.36096,7.506292"
  },
  {
    "ID": 2104,
    "Tankstellennummer": "0F976",
    "Name": "Aral Tankstelle Essen, Ringstraße 30",
    "Address": "Ringstraße 30",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45219,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 20545132,
    "24 hour?": "TRUE",
    "Latitude": 51.361393,
    "Longitude": 6.943623,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.361393,6.943623",
    "IsOpen24Hours": true,
    "id": "51.361393,6.943623"
  },
  {
    "ID": 1864273090,
    "Tankstellennummer": 5047,
    "Name": "Agip Bennewitz",
    "Address": "Leipziger Str. 51",
    "Address__1": "",
    "City": "Bennewitz",
    "Postcode": 4828,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 34258109",
    "24 hour?": "TRUE",
    "Latitude": 51.362415,
    "Longitude": 12.69706,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.362415,12.69706",
    "IsOpen24Hours": true,
    "id": "51.362415,12.69706"
  },
  {
    "ID": 1875049902,
    "Tankstellennummer": 610345,
    "Name": "WESTFALEN, DUISBURG",
    "Address": "Düsseldorfer Landstr. 343",
    "Address__1": "",
    "City": "Duisburg",
    "Postcode": 47259,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0203/60859961",
    "24 hour?": "FALSE",
    "Latitude": 51.3633,
    "Longitude": 6.75019,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3633,6.75019",
    "IsOpen24Hours": false,
    "id": "51.3633,6.75019"
  },
  {
    "ID": 942671288,
    "Tankstellennummer": "TD000398",
    "Name": "MUELHEIM KOELNER STR. 451",
    "Address": "KOELNER STR. 451",
    "Address__1": "",
    "City": "MUELHEIM",
    "Postcode": 45481,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0208-480146",
    "24 hour?": "FALSE",
    "Latitude": 51.3635,
    "Longitude": 6.8633,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3635,6.8633",
    "IsOpen24Hours": false,
    "id": "51.3635,6.8633"
  },
  {
    "ID": 2107711758,
    "Tankstellennummer": "TD035212",
    "Name": "ARTERN SANGERHAEUSER STR.",
    "Address": "SANGERHAEUSER STR. 35",
    "Address__1": "",
    "City": "ARTERN",
    "Postcode": 6556,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03466-740568",
    "24 hour?": "FALSE",
    "Latitude": 51.3641,
    "Longitude": 11.287,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3641,11.287",
    "IsOpen24Hours": false,
    "id": "51.3641,11.287"
  },
  {
    "ID": 534747478,
    "Tankstellennummer": "0FT74",
    "Name": "HAGEN",
    "Address": "Elseyer Straße 43",
    "Address__1": "",
    "City": "Hagen",
    "Postcode": 58119,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 233451418,
    "24 hour?": "FALSE",
    "Latitude": 51.364132,
    "Longitude": 7.55335,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.364132,7.55335",
    "IsOpen24Hours": false,
    "id": "51.364132,7.55335"
  },
  {
    "ID": 1345,
    "Tankstellennummer": "0F430",
    "Name": "Aral Tankstelle Kempen, Hülser Str.45",
    "Address": "Hülser Straße 45",
    "Address__1": "",
    "City": "Kempen",
    "Postcode": 47906,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 21523439,
    "24 hour?": "FALSE",
    "Latitude": 51.364553,
    "Longitude": 6.430604,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.364553,6.430604",
    "IsOpen24Hours": false,
    "id": "51.364553,6.430604"
  },
  {
    "ID": 1212,
    "Tankstellennummer": "0F325",
    "Name": "Aral Tankstelle Meschede, Bahnhofstr. 78",
    "Address": "Bahnhofstraße 78",
    "Address__1": "",
    "City": "Meschede",
    "Postcode": 59872,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 290397120,
    "24 hour?": "FALSE",
    "Latitude": 51.365244,
    "Longitude": 8.172538,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.365244,8.172538",
    "IsOpen24Hours": false,
    "id": "51.365244,8.172538"
  },
  {
    "ID": 1035913821,
    "Tankstellennummer": "TD007187",
    "Name": "LEIPZIG LINDENTHALER STR. 61",
    "Address": "LINDENTHALER STR.61",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4155,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0341-5832105",
    "24 hour?": "FALSE",
    "Latitude": 51.366,
    "Longitude": 12.3661,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.366,12.3661",
    "IsOpen24Hours": false,
    "id": "51.366,12.3661"
  },
  {
    "ID": 1319,
    "Tankstellennummer": "0F413",
    "Name": "Aral Tankstelle Sprockhövel, Bochumer Str.4",
    "Address": "Bochumer Straße 4",
    "Address__1": "",
    "City": "Sprockhövel",
    "Postcode": 45549,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 232474188,
    "24 hour?": "FALSE",
    "Latitude": 51.36684,
    "Longitude": 7.252376,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.36684,7.252376",
    "IsOpen24Hours": false,
    "id": "51.36684,7.252376"
  },
  {
    "ID": 1550990993,
    "Tankstellennummer": "TD037499",
    "Name": "HAGEN AM SPORTPARK 32",
    "Address": "AM SPORTPARK 32",
    "Address__1": "",
    "City": "HAGEN",
    "Postcode": 58097,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02331-81616",
    "24 hour?": "FALSE",
    "Latitude": 51.369,
    "Longitude": 7.4752,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.369,7.4752",
    "IsOpen24Hours": false,
    "id": "51.369,7.4752"
  },
  {
    "ID": 2103386165,
    "Tankstellennummer": "TD120350",
    "Name": "DUISBURG GROSSENBAUMER ALLEE 7",
    "Address": "GROSSENBAUMER ALLEE 75",
    "Address__1": "",
    "City": "DUISBURG",
    "Postcode": 47269,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0203-761567",
    "24 hour?": "FALSE",
    "Latitude": 51.3695,
    "Longitude": 6.7791,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3695,6.7791",
    "IsOpen24Hours": false,
    "id": "51.3695,6.7791"
  },
  {
    "ID": 930107029,
    "Tankstellennummer": "TD037465",
    "Name": "DUISBURG MUENDELHEIMER STR. 10",
    "Address": "MUENDELHEIMER STR. 106",
    "Address__1": "",
    "City": "DUISBURG",
    "Postcode": 47259,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0203-781405",
    "24 hour?": "FALSE",
    "Latitude": 51.3699,
    "Longitude": 6.7399,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3699,6.7399",
    "IsOpen24Hours": false,
    "id": "51.3699,6.7399"
  },
  {
    "ID": 330965335,
    "Tankstellennummer": "TD037788",
    "Name": "STAUFENBERG AUTOHOF BAB 7",
    "Address": "AUTOHOF BAB 7 / AS LUTTERBERG",
    "Address__1": "",
    "City": "STAUFENBERG",
    "Postcode": 34355,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05543-302710",
    "24 hour?": "FALSE",
    "Latitude": 51.3707,
    "Longitude": 9.6333,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3707,9.6333",
    "IsOpen24Hours": false,
    "id": "51.3707,9.6333"
  },
  {
    "ID": 2037253030,
    "Tankstellennummer": "TD123920",
    "Name": "WETTER GRUNDSCHOETTELER STR 58",
    "Address": "GRUNDSCHOETTELER STR 58",
    "Address__1": "",
    "City": "WETTER",
    "Postcode": 58300,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02335-69161",
    "24 hour?": "FALSE",
    "Latitude": 51.3712,
    "Longitude": 7.3697,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3712,7.3697",
    "IsOpen24Hours": false,
    "id": "51.3712,7.3697"
  },
  {
    "ID": 1849613974,
    "Tankstellennummer": "TD030775",
    "Name": "MERSEBURG QUERFURTER STR. 4A",
    "Address": "QUERFURTER STR. 4A",
    "Address__1": "",
    "City": "MERSEBURG",
    "Postcode": 6217,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03461-204531",
    "24 hour?": "FALSE",
    "Latitude": 51.3718,
    "Longitude": 11.9829,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3718,11.9829",
    "IsOpen24Hours": false,
    "id": "51.3718,11.9829"
  },
  {
    "ID": 3366,
    "Tankstellennummer": "0FL10",
    "Name": "Aral Tankstelle Taucha, Leipziger Str.",
    "Address": "Leipziger Str. 129",
    "Address__1": "",
    "City": "Taucha",
    "Postcode": 4425,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3429834595,
    "24 hour?": "TRUE",
    "Latitude": 51.37212,
    "Longitude": 12.474988,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.37212,12.474988",
    "IsOpen24Hours": true,
    "id": "51.37212,12.474988"
  },
  {
    "ID": 1322921319,
    "Tankstellennummer": "TD034140",
    "Name": "HEILIGENSTADT DINGELSTAEDTER S",
    "Address": "DINGELSTAEDTER STRASSE 33",
    "Address__1": "",
    "City": "HEILIGENSTADT",
    "Postcode": 37308,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03606-608552",
    "24 hour?": "FALSE",
    "Latitude": 51.3753,
    "Longitude": 10.1484,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3753,10.1484",
    "IsOpen24Hours": false,
    "id": "51.3753,10.1484"
  },
  {
    "ID": 13887008,
    "Tankstellennummer": "TD034637",
    "Name": "LEIPZIG MAX-LIEBERMANN-STRASSE",
    "Address": "MAX-LIEBERMANN-STRASSE 11",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4159,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0341-9010412",
    "24 hour?": "FALSE",
    "Latitude": 51.3754,
    "Longitude": 12.3491,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3754,12.3491",
    "IsOpen24Hours": false,
    "id": "51.3754,12.3491"
  },
  {
    "ID": 525467826,
    "Tankstellennummer": "TD035105",
    "Name": "ORTRAND ELSTERWERDAER STR. 35",
    "Address": "ELSTERWERDAER STR. 35",
    "Address__1": "",
    "City": "ORTRAND",
    "Postcode": 1990,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035755-53819",
    "24 hour?": "FALSE",
    "Latitude": 51.3756,
    "Longitude": 13.7496,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3756,13.7496",
    "IsOpen24Hours": false,
    "id": "51.3756,13.7496"
  },
  {
    "ID": 2707,
    "Tankstellennummer": "0FE21",
    "Name": "Aral Tankstelle Leipzig, Max-Liebermann-Straße 101",
    "Address": "Max-Liebermann-Straße 101",
    "Address__1": "",
    "City": "Leipzig",
    "Postcode": 4157,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3419121670,
    "24 hour?": "TRUE",
    "Latitude": 51.376073,
    "Longitude": 12.37766,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.376073,12.37766",
    "IsOpen24Hours": true,
    "id": "51.376073,12.37766"
  },
  {
    "ID": 2545,
    "Tankstellennummer": "0FC86",
    "Name": "Aral Tankstelle Leipzig, Essener Str. 31",
    "Address": "Essener Straße 31",
    "Address__1": "",
    "City": "Leipzig",
    "Postcode": 4357,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3416022765,
    "24 hour?": "FALSE",
    "Latitude": 51.376576,
    "Longitude": 12.399026,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.376576,12.399026",
    "IsOpen24Hours": false,
    "id": "51.376576,12.399026"
  },
  {
    "ID": 1885281858,
    "Tankstellennummer": "TD034942",
    "Name": "BERNSDORF ERNST-THAELMANN-STR.",
    "Address": "ERNST-THAELMANN-STR. 1",
    "Address__1": "",
    "City": "BERNSDORF",
    "Postcode": 2994,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035723-20110",
    "24 hour?": "FALSE",
    "Latitude": 51.3769,
    "Longitude": 14.0714,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3769,14.0714",
    "IsOpen24Hours": false,
    "id": "51.3769,14.0714"
  },
  {
    "ID": 1130390436,
    "Tankstellennummer": 610636,
    "Name": "WESTFALEN, HAGEN",
    "Address": "Becheltestr. 56",
    "Address__1": "",
    "City": "Hagen",
    "Postcode": 58089,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02331/13108",
    "24 hour?": "FALSE",
    "Latitude": 51.3778,
    "Longitude": 7.45236,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3778,7.45236",
    "IsOpen24Hours": false,
    "id": "51.3778,7.45236"
  },
  {
    "ID": 1652678722,
    "Tankstellennummer": "0FM47",
    "Name": "ISERLOHN",
    "Address": "Schlesische Straße 75",
    "Address__1": "",
    "City": "Iserlohn",
    "Postcode": 58636,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 237162222,
    "24 hour?": "TRUE",
    "Latitude": 51.377995,
    "Longitude": 7.728409,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.377995,7.728409",
    "IsOpen24Hours": true,
    "id": "51.377995,7.728409"
  },
  {
    "ID": 490418796,
    "Tankstellennummer": "0FY49",
    "Name": "HEMER",
    "Address": "Hauptstraße 72",
    "Address__1": "",
    "City": "Hemer",
    "Postcode": 58675,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23724365,
    "24 hour?": "FALSE",
    "Latitude": 51.378773,
    "Longitude": 7.764271,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.378773,7.764271",
    "IsOpen24Hours": false,
    "id": "51.378773,7.764271"
  },
  {
    "ID": 3409,
    "Tankstellennummer": "0FL49",
    "Name": "Aral Tankstelle Leipzig, Landsberger Strasse 116",
    "Address": "Landsberger Straße 116",
    "Address__1": "",
    "City": "Leipzig",
    "Postcode": 4157,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3419129891,
    "24 hour?": "FALSE",
    "Latitude": 51.379495,
    "Longitude": 12.358635,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.379495,12.358635",
    "IsOpen24Hours": false,
    "id": "51.379495,12.358635"
  },
  {
    "ID": 1329,
    "Tankstellennummer": "0F421",
    "Name": "Aral Tankstelle Iserlohn, Dortmunder Str. 110",
    "Address": "Dortmunder Straße 110",
    "Address__1": "",
    "City": "Iserlohn",
    "Postcode": 58638,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 237127435,
    "24 hour?": "TRUE",
    "Latitude": 51.380569,
    "Longitude": 7.68189,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.380569,7.68189",
    "IsOpen24Hours": true,
    "id": "51.380569,7.68189"
  },
  {
    "ID": 1631,
    "Tankstellennummer": "0F657",
    "Name": "Aral Tankstelle Bad Arolsen, Korbacherstr. 2",
    "Address": "Korbacherstraße 2",
    "Address__1": "",
    "City": "Bad Arolsen",
    "Postcode": 34454,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56913584,
    "24 hour?": "TRUE",
    "Latitude": 51.381158,
    "Longitude": 8.998941,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.381158,8.998941",
    "IsOpen24Hours": true,
    "id": "51.381158,8.998941"
  },
  {
    "ID": 511610999,
    "Tankstellennummer": "0FN71",
    "Name": "TAUCHA",
    "Address": "Portitzer Straße 71",
    "Address__1": "",
    "City": "Taucha",
    "Postcode": 4425,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3429863646,
    "24 hour?": "FALSE",
    "Latitude": 51.382079,
    "Longitude": 12.477612,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.382079,12.477612",
    "IsOpen24Hours": false,
    "id": "51.382079,12.477612"
  },
  {
    "ID": 2081635720,
    "Tankstellennummer": "0FT72",
    "Name": "STRAELEN",
    "Address": "Heronger Feld  9",
    "Address__1": "",
    "City": "Straelen",
    "Postcode": 47638,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 28395682873,
    "24 hour?": "TRUE",
    "Latitude": 51.382563,
    "Longitude": 6.245313,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.382563,6.245313",
    "IsOpen24Hours": true,
    "id": "51.382563,6.245313"
  },
  {
    "ID": 1272,
    "Tankstellennummer": "0F373",
    "Name": "Aral Tankstelle Krefeld, Klever Str. 165",
    "Address": "Klever Straße 165",
    "Address__1": "",
    "City": "Krefeld",
    "Postcode": 47839,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2151730295,
    "24 hour?": "FALSE",
    "Latitude": 51.382843,
    "Longitude": 6.504978,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.382843,6.504978",
    "IsOpen24Hours": false,
    "id": "51.382843,6.504978"
  },
  {
    "ID": 487346551,
    "Tankstellennummer": 5025,
    "Name": "Agip Leipzig",
    "Address": "Hallesche Str. 182",
    "Address__1": "",
    "City": "Leipzig",
    "Postcode": 4159,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 34146157",
    "24 hour?": "TRUE",
    "Latitude": 51.38467257,
    "Longitude": 12.27306411,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.38467257,12.27306411",
    "IsOpen24Hours": true,
    "id": "51.38467257,12.27306411"
  },
  {
    "ID": 2675,
    "Tankstellennummer": "0FD96",
    "Name": "Aral Autohof Leipzig, Maximilianallee 70",
    "Address": "Maximilianallee 70",
    "Address__1": "",
    "City": "Leipzig",
    "Postcode": 4129,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3419129096,
    "24 hour?": "TRUE",
    "Latitude": 51.3858,
    "Longitude": 12.389745,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.3858,12.389745",
    "IsOpen24Hours": true,
    "id": "51.3858,12.389745"
  },
  {
    "ID": 2897,
    "Tankstellennummer": "0FH63",
    "Name": "Aral Tankstelle Hagen, Herdecker Str. 14",
    "Address": "Herdecker Straße 14",
    "Address__1": "",
    "City": "Hagen",
    "Postcode": 58089,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2331932809,
    "24 hour?": "TRUE",
    "Latitude": 51.385832,
    "Longitude": 7.447328,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.385832,7.447328",
    "IsOpen24Hours": true,
    "id": "51.385832,7.447328"
  },
  {
    "ID": 1205723461,
    "Tankstellennummer": "TD000927",
    "Name": "HAGEN WESTSTR. 10",
    "Address": "WESTSTR. 10",
    "Address__1": "",
    "City": "HAGEN",
    "Postcode": 58089,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02331-3483454",
    "24 hour?": "FALSE",
    "Latitude": 51.3859,
    "Longitude": 7.4374,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3859,7.4374",
    "IsOpen24Hours": false,
    "id": "51.3859,7.4374"
  },
  {
    "ID": 1243,
    "Tankstellennummer": "0F349",
    "Name": "Aral Tankstelle Hagen, Hagener Str. 121",
    "Address": "Hagener Straße 121",
    "Address__1": "",
    "City": "Hagen",
    "Postcode": 58099,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 233161999,
    "24 hour?": "TRUE",
    "Latitude": 51.386465,
    "Longitude": 7.477907,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.386465,7.477907",
    "IsOpen24Hours": true,
    "id": "51.386465,7.477907"
  },
  {
    "ID": 1305,
    "Tankstellennummer": "0F402",
    "Name": "Aral Tankstelle Essen, Heidhauser Straße 9",
    "Address": "Heidhauser Straße 1",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45239,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 201404066,
    "24 hour?": "TRUE",
    "Latitude": 51.388102,
    "Longitude": 7.0226,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.388102,7.0226",
    "IsOpen24Hours": true,
    "id": "51.388102,7.0226"
  },
  {
    "ID": 824573040,
    "Tankstellennummer": "TD035238",
    "Name": "ARNSBERG IM NEYL 30",
    "Address": "IM NEYL 30",
    "Address__1": "",
    "City": "ARNSBERG",
    "Postcode": 59823,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02937-6473",
    "24 hour?": "FALSE",
    "Latitude": 51.3906,
    "Longitude": 8.1303,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3906,8.1303",
    "IsOpen24Hours": false,
    "id": "51.3906,8.1303"
  },
  {
    "ID": 110182073,
    "Tankstellennummer": "0FM46",
    "Name": "HEMER",
    "Address": "Märkische Straße 16",
    "Address__1": "",
    "City": "Hemer",
    "Postcode": 58675,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 237210628,
    "24 hour?": "TRUE",
    "Latitude": 51.393113,
    "Longitude": 7.758487,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.393113,7.758487",
    "IsOpen24Hours": true,
    "id": "51.393113,7.758487"
  },
  {
    "ID": 508491119,
    "Tankstellennummer": "0FN67",
    "Name": "LEIPZIG / LINDENTHAL",
    "Address": "Zum Apelstein 1",
    "Address__1": "",
    "City": "Leipzig / Lindenthal",
    "Postcode": 4158,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3414618761,
    "24 hour?": "FALSE",
    "Latitude": 51.393551,
    "Longitude": 12.341964,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.393551,12.341964",
    "IsOpen24Hours": false,
    "id": "51.393551,12.341964"
  },
  {
    "ID": 1252870737,
    "Tankstellennummer": 212052,
    "Name": "Duisburg",
    "Address": "Marseiller Str. 11",
    "Address__1": "",
    "City": "DUISBURG",
    "Postcode": 47229,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.39526,
    "Longitude": 6.72394,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.39526,6.72394",
    "IsOpen24Hours": false,
    "id": "51.39526,6.72394"
  },
  {
    "ID": 24241015,
    "Tankstellennummer": "TD019414",
    "Name": "DUISBURG DUESSELDORFER STR.51",
    "Address": "DUESSELDORFER STR.51",
    "Address__1": "",
    "City": "DUISBURG",
    "Postcode": 47239,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02151-406010",
    "24 hour?": "FALSE",
    "Latitude": 51.3964,
    "Longitude": 6.6578,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3964,6.6578",
    "IsOpen24Hours": false,
    "id": "51.3964,6.6578"
  },
  {
    "ID": 44884250,
    "Tankstellennummer": "TD005702",
    "Name": "WETTER OSTERFELDSTR. 60",
    "Address": "OSTERFELDSTR. 60",
    "Address__1": "",
    "City": "WETTER",
    "Postcode": 58300,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02335-70130",
    "24 hour?": "FALSE",
    "Latitude": 51.3968,
    "Longitude": 7.3519,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3968,7.3519",
    "IsOpen24Hours": false,
    "id": "51.3968,7.3519"
  },
  {
    "ID": 1316,
    "Tankstellennummer": "0F411",
    "Name": "Aral Tankstelle Essen, Kupferdreher Str. 63-67",
    "Address": "Kupferdreher Straße 61",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45257,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 201482034,
    "24 hour?": "FALSE",
    "Latitude": 51.396836,
    "Longitude": 7.085488,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.396836,7.085488",
    "IsOpen24Hours": false,
    "id": "51.396836,7.085488"
  },
  {
    "ID": 591387781,
    "Tankstellennummer": "TD019349",
    "Name": "HAGEN-BOELE SCHWERTER STR. 161",
    "Address": "SCHWERTER STR. 161",
    "Address__1": "",
    "City": "HAGEN-BOELE",
    "Postcode": 58099,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02331-65197",
    "24 hour?": "FALSE",
    "Latitude": 51.3989,
    "Longitude": 7.4733,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.3989,7.4733",
    "IsOpen24Hours": false,
    "id": "51.3989,7.4733"
  },
  {
    "ID": 291512989,
    "Tankstellennummer": "0FG40",
    "Name": "Aral TankStelle Schkeuditz, Bierweg 6",
    "Address": "Bierweg 6",
    "Address__1": "",
    "City": "Schkeuditz",
    "Postcode": 4435,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3420465724,
    "24 hour?": "TRUE",
    "Latitude": 51.399467,
    "Longitude": 12.190646,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.399467,12.190646",
    "IsOpen24Hours": true,
    "id": "51.399467,12.190646"
  },
  {
    "ID": 869402667,
    "Tankstellennummer": "0FT73",
    "Name": "ISERLOHN",
    "Address": "Baarstraße 162",
    "Address__1": "",
    "City": "Iserlohn",
    "Postcode": 58636,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 237142200,
    "24 hour?": "FALSE",
    "Latitude": 51.400465,
    "Longitude": 7.697738,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.400465,7.697738",
    "IsOpen24Hours": false,
    "id": "51.400465,7.697738"
  },
  {
    "ID": 31379906,
    "Tankstellennummer": "TD040840",
    "Name": "SCHKEUDITZ, AN DER AUTOBAHN 1",
    "Address": "AN DER AUTOBAHN 1",
    "Address__1": "",
    "City": "SCHKEUDITZ",
    "Postcode": 4435,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034204-989920",
    "24 hour?": "FALSE",
    "Latitude": 51.4019,
    "Longitude": 12.1813,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4019,12.1813",
    "IsOpen24Hours": false,
    "id": "51.4019,12.1813"
  },
  {
    "ID": 680219253,
    "Tankstellennummer": "0FV23",
    "Name": "KIRCHWORBIS",
    "Address": "A38, Kirchworbiser Wiesen 2",
    "Address__1": "",
    "City": "Kirchworbis",
    "Postcode": 37339,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.402786,
    "Longitude": 10.414647,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.402786,10.414647",
    "IsOpen24Hours": false,
    "id": "51.402786,10.414647"
  },
  {
    "ID": 1701,
    "Tankstellennummer": "0F700",
    "Name": "Aral Tankstelle Brilon, Möhnestr.22",
    "Address": "Möhnestraße 22",
    "Address__1": "",
    "City": "Brilon",
    "Postcode": 59929,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 29618601,
    "24 hour?": "TRUE",
    "Latitude": 51.40322,
    "Longitude": 8.571145,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.40322,8.571145",
    "IsOpen24Hours": true,
    "id": "51.40322,8.571145"
  },
  {
    "ID": 1299,
    "Tankstellennummer": "0F399",
    "Name": "Aral Tankstelle Duisburg, Friedrich-Ebert-Str. 117",
    "Address": "Friedrich-Ebert-Straße 117",
    "Address__1": "",
    "City": "Duisburg",
    "Postcode": 47226,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2065313826,
    "24 hour?": "TRUE",
    "Latitude": 51.404989,
    "Longitude": 6.711944,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.404989,6.711944",
    "IsOpen24Hours": true,
    "id": "51.404989,6.711944"
  },
  {
    "ID": 1277,
    "Tankstellennummer": "0F378",
    "Name": "Aral Tankstelle Witten, Im Hammertal 1",
    "Address": "Im Hammertal 1",
    "Address__1": "",
    "City": "Witten",
    "Postcode": 58456,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 232430592,
    "24 hour?": "TRUE",
    "Latitude": 51.405451,
    "Longitude": 7.25213,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.405451,7.25213",
    "IsOpen24Hours": true,
    "id": "51.405451,7.25213"
  },
  {
    "ID": 567406511,
    "Tankstellennummer": "TD005124",
    "Name": "HATTINGEN BURGALTENDORFER STR.",
    "Address": "BURGALTENDORFER STR.3",
    "Address__1": "",
    "City": "HATTINGEN",
    "Postcode": 45529,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02324-40756",
    "24 hour?": "FALSE",
    "Latitude": 51.4056,
    "Longitude": 7.1392,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4056,7.1392",
    "IsOpen24Hours": false,
    "id": "51.4056,7.1392"
  },
  {
    "ID": 590460247,
    "Tankstellennummer": "TD038711",
    "Name": "HAGEN WANDHOFENER STR. 9",
    "Address": "WANDHOFENER STR. 9",
    "Address__1": "",
    "City": "HAGEN",
    "Postcode": 58099,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02331-9235959",
    "24 hour?": "FALSE",
    "Latitude": 51.4066,
    "Longitude": 7.4781,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4066,7.4781",
    "IsOpen24Hours": false,
    "id": "51.4066,7.4781"
  },
  {
    "ID": 1274,
    "Tankstellennummer": "0F375",
    "Name": "Aral Tankstelle Wachtendonk, Wankumer Str. 18",
    "Address": "Wankumer Straße 18",
    "Address__1": "",
    "City": "Wachtendonk",
    "Postcode": 47669,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 28369726510,
    "24 hour?": "FALSE",
    "Latitude": 51.406856,
    "Longitude": 6.327047,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.406856,6.327047",
    "IsOpen24Hours": false,
    "id": "51.406856,6.327047"
  },
  {
    "ID": 3430,
    "Tankstellennummer": "0FL65",
    "Name": "Aral Tankstelle Mülheim, Grossenbaumer Str. 67",
    "Address": "Grossenbaumer Straße 67",
    "Address__1": "",
    "City": "Mülheim",
    "Postcode": 45481,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 208423854,
    "24 hour?": "FALSE",
    "Latitude": 51.409512,
    "Longitude": 6.853948,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.409512,6.853948",
    "IsOpen24Hours": false,
    "id": "51.409512,6.853948"
  },
  {
    "ID": 273928838,
    "Tankstellennummer": 610379,
    "Name": "WESTFALEN, DUISBURG",
    "Address": "Wanheimer Str. 225",
    "Address__1": "",
    "City": "Duisburg",
    "Postcode": 47053,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0203/6084119",
    "24 hour?": "TRUE",
    "Latitude": 51.4099,
    "Longitude": 6.75268,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.4099,6.75268",
    "IsOpen24Hours": true,
    "id": "51.4099,6.75268"
  },
  {
    "ID": 484667105,
    "Tankstellennummer": 5105,
    "Name": "Agip Leipzig-Podelwitz",
    "Address": "Kossaerstr./Maximilianallee",
    "Address__1": "",
    "City": "Leipzig-Podelwitz",
    "Postcode": 4356,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 34152172",
    "24 hour?": "TRUE",
    "Latitude": 51.4104,
    "Longitude": 12.39345,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.4104,12.39345",
    "IsOpen24Hours": true,
    "id": "51.4104,12.39345"
  },
  {
    "ID": 1323,
    "Tankstellennummer": "0F416",
    "Name": "Aral Tankstelle Arnsberg, Hüstener Str. 44",
    "Address": "Hüstener Straße 44",
    "Address__1": "",
    "City": "Arnsberg",
    "Postcode": 59821,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 293177251,
    "24 hour?": "TRUE",
    "Latitude": 51.410496,
    "Longitude": 8.054933,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.410496,8.054933",
    "IsOpen24Hours": true,
    "id": "51.410496,8.054933"
  },
  {
    "ID": 1043452,
    "Tankstellennummer": "TD007898",
    "Name": "HATTINGEN AUF DEM HAIDCHEN 45",
    "Address": "AUF DEM HAIDCHEN 45",
    "Address__1": "",
    "City": "HATTINGEN",
    "Postcode": 45527,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02324-60778",
    "24 hour?": "FALSE",
    "Latitude": 51.4111,
    "Longitude": 7.1995,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4111,7.1995",
    "IsOpen24Hours": false,
    "id": "51.4111,7.1995"
  },
  {
    "ID": 2093764779,
    "Tankstellennummer": 610637,
    "Name": "WESTFALEN, HAGEN",
    "Address": "Ruhrtalstr. 5",
    "Address__1": "",
    "City": "Hagen",
    "Postcode": 58099,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02304/67001",
    "24 hour?": "FALSE",
    "Latitude": 51.412,
    "Longitude": 7.51293,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.412,7.51293",
    "IsOpen24Hours": false,
    "id": "51.412,7.51293"
  },
  {
    "ID": 2509,
    "Tankstellennummer": "0FC53",
    "Name": "Aral Tankstelle Worbis, Breitenbacher Str.",
    "Address": "Industriestraße 1",
    "Address__1": "",
    "City": "Worbis",
    "Postcode": 37339,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 36074633214,
    "24 hour?": "TRUE",
    "Latitude": 51.413226,
    "Longitude": 10.357825,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.413226,10.357825",
    "IsOpen24Hours": true,
    "id": "51.413226,10.357825"
  },
  {
    "ID": 295562325,
    "Tankstellennummer": "TD150450",
    "Name": "LEIPZIG AUTOHOF A14 POSTSTRASS",
    "Address": "AUTOHOF A14 POSTSTRASSE 3",
    "Address__1": "",
    "City": "LEIPZIG",
    "Postcode": 4158,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0341-4616662",
    "24 hour?": "FALSE",
    "Latitude": 51.4136,
    "Longitude": 12.3082,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4136,12.3082",
    "IsOpen24Hours": false,
    "id": "51.4136,12.3082"
  },
  {
    "ID": 1054812998,
    "Tankstellennummer": "TD124270",
    "Name": "DUISBURG HEERSTR. 270",
    "Address": "HEERSTR.270",
    "Address__1": "",
    "City": "DUISBURG",
    "Postcode": 47053,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0203-63355",
    "24 hour?": "FALSE",
    "Latitude": 51.4151,
    "Longitude": 6.7626,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4151,6.7626",
    "IsOpen24Hours": false,
    "id": "51.4151,6.7626"
  },
  {
    "ID": 1230,
    "Tankstellennummer": "0F337",
    "Name": "Aral Tankstelle Herdecke, Herdecker Bach 37 A",
    "Address": "Herdecker Bach 37 A",
    "Address__1": "",
    "City": "Herdecke",
    "Postcode": 58313,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23302878,
    "24 hour?": "TRUE",
    "Latitude": 51.41551,
    "Longitude": 7.432201,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.41551,7.432201",
    "IsOpen24Hours": true,
    "id": "51.41551,7.432201"
  },
  {
    "ID": 906218597,
    "Tankstellennummer": "TD000970",
    "Name": "MUELHEIM ZEPPELINSTR. 77",
    "Address": "ZEPPELINSTR. 77",
    "Address__1": "",
    "City": "MUELHEIM",
    "Postcode": 45470,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0208-374085",
    "24 hour?": "FALSE",
    "Latitude": 51.4168,
    "Longitude": 6.9054,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4168,6.9054",
    "IsOpen24Hours": false,
    "id": "51.4168,6.9054"
  },
  {
    "ID": 1264,
    "Tankstellennummer": "0F366",
    "Name": "Aral Tankstelle Mülheim, Röntgenstraße 4",
    "Address": "Röntgenstraße 4",
    "Address__1": "",
    "City": "Mülheim",
    "Postcode": 45470,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 208374546,
    "24 hour?": "FALSE",
    "Latitude": 51.41748,
    "Longitude": 6.895428,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.41748,6.895428",
    "IsOpen24Hours": false,
    "id": "51.41748,6.895428"
  },
  {
    "ID": 1665,
    "Tankstellennummer": "0F673",
    "Name": "Aral Tankstelle Hann. Münden, Hedemündener Strasse 10",
    "Address": "Hedemündener Straße 10",
    "Address__1": "",
    "City": "Hann. Münden",
    "Postcode": 34346,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 55411859,
    "24 hour?": "TRUE",
    "Latitude": 51.41784,
    "Longitude": 9.667729,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.41784,9.667729",
    "IsOpen24Hours": true,
    "id": "51.41784,9.667729"
  },
  {
    "ID": 1276,
    "Tankstellennummer": "0F377",
    "Name": "Aral Tankstelle Witten, Wittener Str. 71",
    "Address": "Wittener Straße 71",
    "Address__1": "",
    "City": "Witten",
    "Postcode": 58456,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2302760298,
    "24 hour?": "FALSE",
    "Latitude": 51.41916,
    "Longitude": 7.275161,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.41916,7.275161",
    "IsOpen24Hours": false,
    "id": "51.41916,7.275161"
  },
  {
    "ID": 853414291,
    "Tankstellennummer": "TD000956",
    "Name": "DUISBURG KOLONIESTR. 141",
    "Address": "KOLONIESTR. 141",
    "Address__1": "",
    "City": "DUISBURG",
    "Postcode": 47057,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0203-352727",
    "24 hour?": "FALSE",
    "Latitude": 51.4198,
    "Longitude": 6.7866,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4198,6.7866",
    "IsOpen24Hours": false,
    "id": "51.4198,6.7866"
  },
  {
    "ID": 51170068,
    "Tankstellennummer": "TD007963",
    "Name": "ESSEN-UEBERRUHR UEBERRUHRSTR.",
    "Address": "UEBERRUHRSTR. 335",
    "Address__1": "",
    "City": "ESSEN-UEBERRUHR",
    "Postcode": 45277,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0201-54502640",
    "24 hour?": "FALSE",
    "Latitude": 51.4217,
    "Longitude": 7.0888,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4217,7.0888",
    "IsOpen24Hours": false,
    "id": "51.4217,7.0888"
  },
  {
    "ID": 1366970036,
    "Tankstellennummer": "TD000958",
    "Name": "ESSEN ALFREDSTR. 248",
    "Address": "ALFREDSTR. 248",
    "Address__1": "",
    "City": "ESSEN",
    "Postcode": 45133,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0201-410115",
    "24 hour?": "FALSE",
    "Latitude": 51.4219,
    "Longitude": 6.9985,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4219,6.9985",
    "IsOpen24Hours": false,
    "id": "51.4219,6.9985"
  },
  {
    "ID": 2136124578,
    "Tankstellennummer": "TD039719",
    "Name": "ESSEN FRANKENSTR. 241",
    "Address": "FRANKENSTR. 241",
    "Address__1": "",
    "City": "ESSEN",
    "Postcode": 45134,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0201-50776097",
    "24 hour?": "FALSE",
    "Latitude": 51.422,
    "Longitude": 7.0284,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.422,7.0284",
    "IsOpen24Hours": false,
    "id": "51.422,7.0284"
  },
  {
    "ID": 1205,
    "Tankstellennummer": "0F317",
    "Name": "Aral Tankstelle Arnsberg, Heinrich-Lübke-Str. 66",
    "Address": "Heinrich-Lübke-Straße 66",
    "Address__1": "",
    "City": "Arnsberg",
    "Postcode": 59759,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 293231576,
    "24 hour?": "TRUE",
    "Latitude": 51.423572,
    "Longitude": 7.989389,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.423572,7.989389",
    "IsOpen24Hours": true,
    "id": "51.423572,7.989389"
  },
  {
    "ID": 504482950,
    "Tankstellennummer": 4744,
    "Name": "Agip Immenhausen",
    "Address": "Hohenkirchnerstr. 9",
    "Address__1": "",
    "City": "Immenhausen",
    "Postcode": 34376,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 56732573",
    "24 hour?": "FALSE",
    "Latitude": 51.42436,
    "Longitude": 9.48025,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.42436,9.48025",
    "IsOpen24Hours": false,
    "id": "51.42436,9.48025"
  },
  {
    "ID": 2574,
    "Tankstellennummer": "0FD10",
    "Name": "Aral Tankstelle Hoyerswerda, Kamenzer Bogen 16",
    "Address": "Kamenzer Bogen 16",
    "Address__1": "",
    "City": "Hoyerswerda",
    "Postcode": 2977,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3571406537,
    "24 hour?": "TRUE",
    "Latitude": 51.424453,
    "Longitude": 14.225092,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.424453,14.225092",
    "IsOpen24Hours": true,
    "id": "51.424453,14.225092"
  },
  {
    "ID": 566377350,
    "Tankstellennummer": "TD143120",
    "Name": "ISERLOHN KALTHOFER FELD 1",
    "Address": "KALTHOFER FELD 1",
    "Address__1": "",
    "City": "ISERLOHN",
    "Postcode": 58640,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02371-44346",
    "24 hour?": "FALSE",
    "Latitude": 51.426,
    "Longitude": 7.6756,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.426,7.6756",
    "IsOpen24Hours": false,
    "id": "51.426,7.6756"
  },
  {
    "ID": 1324183984,
    "Tankstellennummer": 610346,
    "Name": "WESTFALEN, DUISBURG",
    "Address": "Sternbuschweg 103a",
    "Address__1": "",
    "City": "Duisburg",
    "Postcode": 47057,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0203/372339",
    "24 hour?": "FALSE",
    "Latitude": 51.4267,
    "Longitude": 6.79122,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4267,6.79122",
    "IsOpen24Hours": false,
    "id": "51.4267,6.79122"
  },
  {
    "ID": 1293,
    "Tankstellennummer": "0F393",
    "Name": "Aral Tankstelle Bochum, Kemnader Str.328",
    "Address": "Kemnader Straße328",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44797,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 234791759,
    "24 hour?": "FALSE",
    "Latitude": 51.428253,
    "Longitude": 7.24378,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.428253,7.24378",
    "IsOpen24Hours": false,
    "id": "51.428253,7.24378"
  },
  {
    "ID": 2106481536,
    "Tankstellennummer": "TD004994",
    "Name": "BOCHUM KEMNADER STR. 315",
    "Address": "KEMNADER STR. 315",
    "Address__1": "",
    "City": "BOCHUM",
    "Postcode": 44797,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0234-791008",
    "24 hour?": "FALSE",
    "Latitude": 51.4285,
    "Longitude": 7.2425,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4285,7.2425",
    "IsOpen24Hours": false,
    "id": "51.4285,7.2425"
  },
  {
    "ID": 2875,
    "Tankstellennummer": "0FH43",
    "Name": "Aral Tankstelle Essen, Alfredstr. 156-160",
    "Address": "Alfredstraße 156-160",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45131,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 201411545,
    "24 hour?": "TRUE",
    "Latitude": 51.428574,
    "Longitude": 7.000317,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.428574,7.000317",
    "IsOpen24Hours": true,
    "id": "51.428574,7.000317"
  },
  {
    "ID": 1821841369,
    "Tankstellennummer": 5151,
    "Name": "Agip Nohra",
    "Address": "Am Huenstein 65",
    "Address__1": "",
    "City": "Nohra",
    "Postcode": 99735,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 36334538",
    "24 hour?": "TRUE",
    "Latitude": 51.42875,
    "Longitude": 10.71415,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.42875,10.71415",
    "IsOpen24Hours": true,
    "id": "51.42875,10.71415"
  },
  {
    "ID": 521027422,
    "Tankstellennummer": "0FX54",
    "Name": "ALLSTEDT",
    "Address": "A 38",
    "Address__1": "",
    "City": "Allstedt",
    "Postcode": 6542,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3465267690,
    "24 hour?": "TRUE",
    "Latitude": 51.4293125,
    "Longitude": 11.3938494,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.4293125,11.3938494",
    "IsOpen24Hours": true,
    "id": "51.4293125,11.3938494"
  },
  {
    "ID": 39838391,
    "Tankstellennummer": "0F361",
    "Name": "Aral TankStelle Mülheim, Essener Straße 225",
    "Address": "Essener Straße 225",
    "Address__1": "",
    "City": "Mülheim",
    "Postcode": 45472,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 208431410,
    "24 hour?": "TRUE",
    "Latitude": 51.430686,
    "Longitude": 6.923581,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.430686,6.923581",
    "IsOpen24Hours": true,
    "id": "51.430686,6.923581"
  },
  {
    "ID": 1248,
    "Tankstellennummer": "0F352",
    "Name": "Aral Tankstelle Essen, Alfredstraße 121",
    "Address": "Alfredstraße 121",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45131,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 201772604,
    "24 hour?": "TRUE",
    "Latitude": 51.432069,
    "Longitude": 7.001987,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.432069,7.001987",
    "IsOpen24Hours": true,
    "id": "51.432069,7.001987"
  },
  {
    "ID": 1285,
    "Tankstellennummer": "0F385",
    "Name": "Aral Tankstelle Essen, Rellinghauser Str. 302",
    "Address": "Rellinghauser Straße 312",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45136,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 201250191,
    "24 hour?": "TRUE",
    "Latitude": 51.434878,
    "Longitude": 7.033927,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.434878,7.033927",
    "IsOpen24Hours": true,
    "id": "51.434878,7.033927"
  },
  {
    "ID": 758315934,
    "Tankstellennummer": 610357,
    "Name": "WESTFALEN, MUELHEIM",
    "Address": "Bruchstr. 18-21",
    "Address__1": "",
    "City": "Mülheim",
    "Postcode": 45468,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0208/471311",
    "24 hour?": "TRUE",
    "Latitude": 51.4357,
    "Longitude": 6.89093,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.4357,6.89093",
    "IsOpen24Hours": true,
    "id": "51.4357,6.89093"
  },
  {
    "ID": 1221,
    "Tankstellennummer": "0F330",
    "Name": "Aral Tankstelle Menden, Bräukerweg 122",
    "Address": "Bräukerweg 122 b",
    "Address__1": "",
    "City": "Menden",
    "Postcode": 58708,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 237360775,
    "24 hour?": "FALSE",
    "Latitude": 51.437425,
    "Longitude": 7.761429,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.437425,7.761429",
    "IsOpen24Hours": false,
    "id": "51.437425,7.761429"
  },
  {
    "ID": 1364,
    "Tankstellennummer": "0F449",
    "Name": "Aral Tankstelle Duisburg, Kardinal-Galen-Str. 106",
    "Address": "Kardinal-Galen-Straße 106",
    "Address__1": "",
    "City": "Duisburg",
    "Postcode": 47058,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 203331435,
    "24 hour?": "TRUE",
    "Latitude": 51.437432,
    "Longitude": 6.776241,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.437432,6.776241",
    "IsOpen24Hours": true,
    "id": "51.437432,6.776241"
  },
  {
    "ID": 1185,
    "Tankstellennummer": "0F302",
    "Name": "Aral Tankstelle Witten, Sprockhöveler Str. 147",
    "Address": "Sprockhöveler Straße 147",
    "Address__1": "",
    "City": "Witten",
    "Postcode": 58455,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2302274691,
    "24 hour?": "FALSE",
    "Latitude": 51.437722,
    "Longitude": 7.32128,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.437722,7.32128",
    "IsOpen24Hours": false,
    "id": "51.437722,7.32128"
  },
  {
    "ID": 1546567843,
    "Tankstellennummer": "TD123630",
    "Name": "DUISBURG AUTOHOF - AM SCHLUETE",
    "Address": "AUTOHOF - AM SCHLUETERSHOF 27",
    "Address__1": "",
    "City": "DUISBURG",
    "Postcode": 47059,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0203-314086",
    "24 hour?": "FALSE",
    "Latitude": 51.4379,
    "Longitude": 6.7388,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4379,6.7388",
    "IsOpen24Hours": false,
    "id": "51.4379,6.7388"
  },
  {
    "ID": 560804000,
    "Tankstellennummer": "TD033506",
    "Name": "HALLE MERSEBURGER STR. 359",
    "Address": "MERSEBURGER STR. 359",
    "Address__1": "",
    "City": "HALLE",
    "Postcode": 6132,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0345-7809911",
    "24 hour?": "FALSE",
    "Latitude": 51.4383,
    "Longitude": 11.9848,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4383,11.9848",
    "IsOpen24Hours": false,
    "id": "51.4383,11.9848"
  },
  {
    "ID": 761879630,
    "Tankstellennummer": 610355,
    "Name": "WESTFALEN, MOERS",
    "Address": "Asberger Str. 188",
    "Address__1": "",
    "City": "Moers",
    "Postcode": 47441,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02841/51223",
    "24 hour?": "FALSE",
    "Latitude": 51.4387,
    "Longitude": 6.6576,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4387,6.6576",
    "IsOpen24Hours": false,
    "id": "51.4387,6.6576"
  },
  {
    "ID": 294449259,
    "Tankstellennummer": "TD004986",
    "Name": "BOCHUM KEMNADER-STR. 14",
    "Address": "KEMNADER-STR. 14",
    "Address__1": "",
    "City": "BOCHUM",
    "Postcode": 44795,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0234-472286",
    "24 hour?": "FALSE",
    "Latitude": 51.4399,
    "Longitude": 7.2143,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4399,7.2143",
    "IsOpen24Hours": false,
    "id": "51.4399,7.2143"
  },
  {
    "ID": 3370,
    "Tankstellennummer": "0FL13",
    "Name": "Aral Tankstelle Halle, Merseburger Str. 355",
    "Address": "Merseburger Straße 355",
    "Address__1": "",
    "City": "Halle",
    "Postcode": 6132,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3457758492,
    "24 hour?": "TRUE",
    "Latitude": 51.440122,
    "Longitude": 11.984517,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.440122,11.984517",
    "IsOpen24Hours": true,
    "id": "51.440122,11.984517"
  },
  {
    "ID": 1262,
    "Tankstellennummer": "0F364",
    "Name": "Aral Tankstelle Kerken, Umgehungsstr.1",
    "Address": "Umgehungsstraße 1",
    "Address__1": "",
    "City": "Kerken",
    "Postcode": 47647,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 28337594,
    "24 hour?": "TRUE",
    "Latitude": 51.44262,
    "Longitude": 6.414314,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.44262,6.414314",
    "IsOpen24Hours": true,
    "id": "51.44262,6.414314"
  },
  {
    "ID": 1197,
    "Tankstellennummer": "0F311",
    "Name": "Aral Tankstelle Bochum, Hattinger Str. 451",
    "Address": "Hattinger Straße 451",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44795,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 234471650,
    "24 hour?": "FALSE",
    "Latitude": 51.443369,
    "Longitude": 7.194065,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.443369,7.194065",
    "IsOpen24Hours": false,
    "id": "51.443369,7.194065"
  },
  {
    "ID": 1306,
    "Tankstellennummer": "0F403",
    "Name": "Aral Tankstelle Moers, Römerstr. 454",
    "Address": "Römerstraße 454",
    "Address__1": "",
    "City": "Moers",
    "Postcode": 47441,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 284151105,
    "24 hour?": "TRUE",
    "Latitude": 51.443892,
    "Longitude": 6.661056,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.443892,6.661056",
    "IsOpen24Hours": true,
    "id": "51.443892,6.661056"
  },
  {
    "ID": 332450681,
    "Tankstellennummer": "TD000026",
    "Name": "DUISBURG KIRCHSTR. 1",
    "Address": "KIRCHSTR. 1",
    "Address__1": "",
    "City": "DUISBURG",
    "Postcode": 47198,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02066-34083",
    "24 hour?": "FALSE",
    "Latitude": 51.4439,
    "Longitude": 6.676,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4439,6.676",
    "IsOpen24Hours": false,
    "id": "51.4439,6.676"
  },
  {
    "ID": 693226619,
    "Tankstellennummer": "TD005694",
    "Name": "SCHWERTE SCHUETZENSTR.18",
    "Address": "SCHUETZENSTR.18",
    "Address__1": "",
    "City": "SCHWERTE",
    "Postcode": 58239,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02304-13152",
    "24 hour?": "FALSE",
    "Latitude": 51.4444,
    "Longitude": 7.5771,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4444,7.5771",
    "IsOpen24Hours": false,
    "id": "51.4444,7.5771"
  },
  {
    "ID": 2878,
    "Tankstellennummer": "0FH46",
    "Name": "Aral Tankstelle Mülheim, Aktienstr. 226",
    "Address": "Aktienstraße 226",
    "Address__1": "",
    "City": "Mülheim",
    "Postcode": 45473,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2089901013,
    "24 hour?": "FALSE",
    "Latitude": 51.444463,
    "Longitude": 6.905406,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.444463,6.905406",
    "IsOpen24Hours": false,
    "id": "51.444463,6.905406"
  },
  {
    "ID": 1070564470,
    "Tankstellennummer": 5092,
    "Name": "Agip Halle",
    "Address": "Freyburger Str. 17",
    "Address__1": "",
    "City": "Halle",
    "Postcode": 6132,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 34577030",
    "24 hour?": "TRUE",
    "Latitude": 51.44469,
    "Longitude": 11.96994,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.44469,11.96994",
    "IsOpen24Hours": true,
    "id": "51.44469,11.96994"
  },
  {
    "ID": 1304227169,
    "Tankstellennummer": "TD000840",
    "Name": "MUELHEIM MELLINGHOFER STR.110",
    "Address": "MELLINGHOFER STR.110",
    "Address__1": "",
    "City": "MUELHEIM",
    "Postcode": 45473,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0208-995570",
    "24 hour?": "FALSE",
    "Latitude": 51.4453,
    "Longitude": 6.8844,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4453,6.8844",
    "IsOpen24Hours": false,
    "id": "51.4453,6.8844"
  },
  {
    "ID": 1254,
    "Tankstellennummer": "0F355",
    "Name": "Aral Tankstelle Essen, Holsterhauser Straße 2",
    "Address": "Holsterhauser Straße 2",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45147,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 201777426,
    "24 hour?": "TRUE",
    "Latitude": 51.446254,
    "Longitude": 7.000578,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.446254,7.000578",
    "IsOpen24Hours": true,
    "id": "51.446254,7.000578"
  },
  {
    "ID": 1288,
    "Tankstellennummer": "0F388",
    "Name": "Aral Tankstelle Neukirchen-Vluyn, Niederrheinallee 48",
    "Address": "Niederrheinallee 48",
    "Address__1": "",
    "City": "Neukirchen-Vluyn",
    "Postcode": 47506,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 28454465,
    "24 hour?": "TRUE",
    "Latitude": 51.446697,
    "Longitude": 6.576852,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.446697,6.576852",
    "IsOpen24Hours": true,
    "id": "51.446697,6.576852"
  },
  {
    "ID": 1253,
    "Tankstellennummer": "0F357",
    "Name": "Aral Tankstelle Essen, Steeler Straße 281",
    "Address": "Steeler Straße 281",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45138,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 201287957,
    "24 hour?": "TRUE",
    "Latitude": 51.4472,
    "Longitude": 7.04587,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.4472,7.04587",
    "IsOpen24Hours": true,
    "id": "51.4472,7.04587"
  },
  {
    "ID": 667045505,
    "Tankstellennummer": "TD022848",
    "Name": "BLEICHERODE NORDHAUESER STR. 9",
    "Address": "NORDHAEUSER STR.9",
    "Address__1": "",
    "City": "BLEICHERODE",
    "Postcode": 99752,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036338-43505",
    "24 hour?": "FALSE",
    "Latitude": 51.4474,
    "Longitude": 10.5841,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4474,10.5841",
    "IsOpen24Hours": false,
    "id": "51.4474,10.5841"
  },
  {
    "ID": 1729298329,
    "Tankstellennummer": "0FU29",
    "Name": "MULHEIM AN DER RUHR",
    "Address": "Steinkampstraße 50",
    "Address__1": "",
    "City": "Mülheim an der Ruhr",
    "Postcode": 45476,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 20837749841,
    "24 hour?": "TRUE",
    "Latitude": 51.447488,
    "Longitude": 6.845953,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.447488,6.845953",
    "IsOpen24Hours": true,
    "id": "51.447488,6.845953"
  },
  {
    "ID": 1400779489,
    "Tankstellennummer": "TD005652",
    "Name": "MENDEN WERLER STR. 60",
    "Address": "WERLER STR. 60",
    "Address__1": "",
    "City": "MENDEN",
    "Postcode": 58706,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02373-3544",
    "24 hour?": "FALSE",
    "Latitude": 51.4478,
    "Longitude": 7.7866,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4478,7.7866",
    "IsOpen24Hours": false,
    "id": "51.4478,7.7866"
  },
  {
    "ID": 2883,
    "Tankstellennummer": "0FH51",
    "Name": "Aral Tankstelle Witten, Sprockhoeveler Str. 1",
    "Address": "Sprockhoeveler Straße 1",
    "Address__1": "",
    "City": "Witten",
    "Postcode": 58455,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 230255410,
    "24 hour?": "TRUE",
    "Latitude": 51.450427,
    "Longitude": 7.3256,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.450427,7.3256",
    "IsOpen24Hours": true,
    "id": "51.450427,7.3256"
  },
  {
    "ID": 601731125,
    "Tankstellennummer": 610356,
    "Name": "WESTFALEN, MOERS",
    "Address": "Hülsdonker Str. 117",
    "Address__1": "",
    "City": "Moers",
    "Postcode": 47441,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02841/26448",
    "24 hour?": "FALSE",
    "Latitude": 51.451,
    "Longitude": 6.60871,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.451,6.60871",
    "IsOpen24Hours": false,
    "id": "51.451,6.60871"
  },
  {
    "ID": 1263,
    "Tankstellennummer": "0F365",
    "Name": "Aral Tankstelle Moers, Krefelder Str. 10",
    "Address": "Krefelder Straße 10",
    "Address__1": "",
    "City": "Moers",
    "Postcode": 47441,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 284129441,
    "24 hour?": "FALSE",
    "Latitude": 51.452067,
    "Longitude": 6.620324,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.452067,6.620324",
    "IsOpen24Hours": false,
    "id": "51.452067,6.620324"
  },
  {
    "ID": 3416,
    "Tankstellennummer": "0FL54",
    "Name": "Aral Tankstelle Essen, Berliner Str. 170",
    "Address": "Berliner Straße 170",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45144,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2018761280,
    "24 hour?": "FALSE",
    "Latitude": 51.452452,
    "Longitude": 6.972793,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.452452,6.972793",
    "IsOpen24Hours": false,
    "id": "51.452452,6.972793"
  },
  {
    "ID": 1228,
    "Tankstellennummer": "0F335",
    "Name": "Aral Tankstelle Schwerte, Hörder Str.89",
    "Address": "Hörder Straße 89",
    "Address__1": "",
    "City": "Schwerte",
    "Postcode": 58239,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 230412930,
    "24 hour?": "TRUE",
    "Latitude": 51.45275,
    "Longitude": 7.560858,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.45275,7.560858",
    "IsOpen24Hours": true,
    "id": "51.45275,7.560858"
  },
  {
    "ID": 1356,
    "Tankstellennummer": "0F442",
    "Name": "Aral Tankstelle Duisburg, Hafenstr. 43 A",
    "Address": "Hafenstraße 43 A",
    "Address__1": "",
    "City": "Duisburg",
    "Postcode": 47119,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 203873020,
    "24 hour?": "TRUE",
    "Latitude": 51.453208,
    "Longitude": 6.740333,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.453208,6.740333",
    "IsOpen24Hours": true,
    "id": "51.453208,6.740333"
  },
  {
    "ID": 845967423,
    "Tankstellennummer": 610624,
    "Name": "WESTFALEN, ARNSBERG",
    "Address": "Werler Str. 8",
    "Address__1": "",
    "City": "Arnsberg",
    "Postcode": 59755,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02932/83399",
    "24 hour?": "TRUE",
    "Latitude": 51.4551,
    "Longitude": 7.96144,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.4551,7.96144",
    "IsOpen24Hours": true,
    "id": "51.4551,7.96144"
  },
  {
    "ID": 1635,
    "Tankstellennummer": "0F659",
    "Name": "Aral Tankstelle Marsberg, Bredelarerstr. 50",
    "Address": "Bredelarer Straße 50",
    "Address__1": "",
    "City": "Marsberg",
    "Postcode": 34431,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 29928040,
    "24 hour?": "FALSE",
    "Latitude": 51.455481,
    "Longitude": 8.840655,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.455481,8.840655",
    "IsOpen24Hours": false,
    "id": "51.455481,8.840655"
  },
  {
    "ID": 741523281,
    "Tankstellennummer": "TD112580",
    "Name": "BOCHUM MARKSTR. 119",
    "Address": "MARKSTR. 119",
    "Address__1": "",
    "City": "BOCHUM",
    "Postcode": 44803,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0234-38619",
    "24 hour?": "FALSE",
    "Latitude": 51.4555,
    "Longitude": 7.2503,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4555,7.2503",
    "IsOpen24Hours": false,
    "id": "51.4555,7.2503"
  },
  {
    "ID": 655265629,
    "Tankstellennummer": 610342,
    "Name": "WESTFALEN, BOCHUM",
    "Address": "Elsa-Brändström-Str. 137",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44869,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02327/72225",
    "24 hour?": "FALSE",
    "Latitude": 51.4563,
    "Longitude": 7.17608,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4563,7.17608",
    "IsOpen24Hours": false,
    "id": "51.4563,7.17608"
  },
  {
    "ID": 1235,
    "Tankstellennummer": "0F342",
    "Name": "Aral Tankstelle Arnsberg, Möhneufer 2",
    "Address": "Möhneufer 2",
    "Address__1": "",
    "City": "Arnsberg",
    "Postcode": 59755,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 293222223,
    "24 hour?": "TRUE",
    "Latitude": 51.456361,
    "Longitude": 7.961709,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.456361,7.961709",
    "IsOpen24Hours": true,
    "id": "51.456361,7.961709"
  },
  {
    "ID": 2663,
    "Tankstellennummer": "0FD82",
    "Name": "Aral Tankstelle Sangerhausen, Erfurter Straße 35",
    "Address": "Erfurter Straße 35",
    "Address__1": "",
    "City": "Sangerhausen",
    "Postcode": 6526,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3464517900,
    "24 hour?": "TRUE",
    "Latitude": 51.456513,
    "Longitude": 11.300216,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.456513,11.300216",
    "IsOpen24Hours": true,
    "id": "51.456513,11.300216"
  },
  {
    "ID": 1055386234,
    "Tankstellennummer": 610620,
    "Name": "WESTFALEN, DUISBURG",
    "Address": "Rheindeichstr. 20",
    "Address__1": "",
    "City": "Duisburg",
    "Postcode": 47198,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02066/55488",
    "24 hour?": "TRUE",
    "Latitude": 51.4572,
    "Longitude": 6.71349,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.4572,6.71349",
    "IsOpen24Hours": true,
    "id": "51.4572,6.71349"
  },
  {
    "ID": 221368702,
    "Tankstellennummer": "TD021444",
    "Name": "EILENBURG ROEDGENER STR. 3",
    "Address": "ROEDGENER STR.3",
    "Address__1": "",
    "City": "EILENBURG",
    "Postcode": 4838,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03423-602911",
    "24 hour?": "FALSE",
    "Latitude": 51.4581,
    "Longitude": 12.6146,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4581,12.6146",
    "IsOpen24Hours": false,
    "id": "51.4581,12.6146"
  },
  {
    "ID": 1282,
    "Tankstellennummer": "0F383",
    "Name": "Aral Tankstelle Bochum, Königsallee 142",
    "Address": "Königsallee 142",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44789,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 234337046,
    "24 hour?": "TRUE",
    "Latitude": 51.461155,
    "Longitude": 7.220608,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.461155,7.220608",
    "IsOpen24Hours": true,
    "id": "51.461155,7.220608"
  },
  {
    "ID": 1290,
    "Tankstellennummer": "0F390",
    "Name": "Aral Tankstelle Essen, Krayer Straße 188",
    "Address": "Krayer Straße 188",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45307,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 201590516,
    "24 hour?": "TRUE",
    "Latitude": 51.462059,
    "Longitude": 7.078826,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.462059,7.078826",
    "IsOpen24Hours": true,
    "id": "51.462059,7.078826"
  },
  {
    "ID": 578647200,
    "Tankstellennummer": 610621,
    "Name": "WESTFALEN, BOCHUM",
    "Address": "Westfälische Str. 21",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44869,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02327/957046",
    "24 hour?": "FALSE",
    "Latitude": 51.4621,
    "Longitude": 7.1545,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4621,7.1545",
    "IsOpen24Hours": false,
    "id": "51.4621,7.1545"
  },
  {
    "ID": 1271,
    "Tankstellennummer": "0F372",
    "Name": "Aral Tankstelle Essen, Frintroper Straße 66-68",
    "Address": "Frintroper Straße 66-68",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45359,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 201672659,
    "24 hour?": "FALSE",
    "Latitude": 51.462675,
    "Longitude": 6.941557,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.462675,6.941557",
    "IsOpen24Hours": false,
    "id": "51.462675,6.941557"
  },
  {
    "ID": 308089614,
    "Tankstellennummer": "TD034660",
    "Name": "ROSSLA AN DER B 80",
    "Address": "NORDRING 4",
    "Address__1": "",
    "City": "SUEDHARZ OT ROSSLA",
    "Postcode": 6536,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034651-2548",
    "24 hour?": "FALSE",
    "Latitude": 51.4631,
    "Longitude": 11.0557,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4631,11.0557",
    "IsOpen24Hours": false,
    "id": "51.4631,11.0557"
  },
  {
    "ID": 680467537,
    "Tankstellennummer": "TD120680",
    "Name": "DUISBURG BGM.-PUETZ-STRASSE 8",
    "Address": "BGM.-PUETZ-STRASSE 8",
    "Address__1": "",
    "City": "DUISBURG",
    "Postcode": 47137,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0203-446384",
    "24 hour?": "FALSE",
    "Latitude": 51.4635,
    "Longitude": 6.7735,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4635,6.7735",
    "IsOpen24Hours": false,
    "id": "51.4635,6.7735"
  },
  {
    "ID": 292373436,
    "Tankstellennummer": "TD000076",
    "Name": "BOCHUM-LANGENDREER HAUPTSTR. 2",
    "Address": "HAUPTSTR. 279",
    "Address__1": "",
    "City": "BOCHUM-LANGENDREER",
    "Postcode": 44892,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0234-286628",
    "24 hour?": "FALSE",
    "Latitude": 51.4647,
    "Longitude": 7.3212,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4647,7.3212",
    "IsOpen24Hours": false,
    "id": "51.4647,7.3212"
  },
  {
    "ID": 333652795,
    "Tankstellennummer": "TD028605",
    "Name": "ELSTERWERDA SIEDLUNGSSTR.19",
    "Address": "SIEDLUNGSSTR.19",
    "Address__1": "",
    "City": "ELSTERWERDA",
    "Postcode": 4910,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03533-2060",
    "24 hour?": "FALSE",
    "Latitude": 51.4647,
    "Longitude": 13.5303,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4647,13.5303",
    "IsOpen24Hours": false,
    "id": "51.4647,13.5303"
  },
  {
    "ID": 1363,
    "Tankstellennummer": "0F448",
    "Name": "Aral Tankstelle Bochum, Berliner Str. 96",
    "Address": "Berliner Straße 96",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44867,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2327939497,
    "24 hour?": "TRUE",
    "Latitude": 51.465261,
    "Longitude": 7.131056,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.465261,7.131056",
    "IsOpen24Hours": true,
    "id": "51.465261,7.131056"
  },
  {
    "ID": 864144987,
    "Tankstellennummer": "TD028829",
    "Name": "SCHWARZHEIDE SCHIPKAUER STR.20",
    "Address": "SCHIPKAUER STR.20",
    "Address__1": "",
    "City": "SCHWARZHEIDE",
    "Postcode": 1987,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035752-50893",
    "24 hour?": "FALSE",
    "Latitude": 51.4665,
    "Longitude": 13.8682,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4665,13.8682",
    "IsOpen24Hours": false,
    "id": "51.4665,13.8682"
  },
  {
    "ID": 1309,
    "Tankstellennummer": "0F405",
    "Name": "Aral Tankstelle Oberhausen, Mülheimer Str. 278-284",
    "Address": "Mülheimer Straße 278-284",
    "Address__1": "",
    "City": "Oberhausen",
    "Postcode": 46045,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2088830797,
    "24 hour?": "TRUE",
    "Latitude": 51.466988,
    "Longitude": 6.864604,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.466988,6.864604",
    "IsOpen24Hours": true,
    "id": "51.466988,6.864604"
  },
  {
    "ID": 2408,
    "Tankstellennummer": "0FC80",
    "Name": "Aral Tankstelle Elsterwerda, B 169",
    "Address": "B 169",
    "Address__1": "",
    "City": "Elsterwerda",
    "Postcode": 4910,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 35334281,
    "24 hour?": "FALSE",
    "Latitude": 51.46718,
    "Longitude": 13.537833,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.46718,13.537833",
    "IsOpen24Hours": false,
    "id": "51.46718,13.537833"
  },
  {
    "ID": 1244,
    "Tankstellennummer": "0F350",
    "Name": "Aral Autobahntankstelle Schwerte, Lichtendorfer Str. 136",
    "Address": "Lichtendorfer Straße 136",
    "Address__1": "",
    "City": "Schwerte",
    "Postcode": 58239,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 230440366,
    "24 hour?": "TRUE",
    "Latitude": 51.467338,
    "Longitude": 7.595074,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.467338,7.595074",
    "IsOpen24Hours": true,
    "id": "51.467338,7.595074"
  },
  {
    "ID": 1239,
    "Tankstellennummer": "0F346",
    "Name": "Aral Tankstelle Dortmund, Hagener Str. 156",
    "Address": "Hagener Straße 156",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44225,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 231731257,
    "24 hour?": "TRUE",
    "Latitude": 51.467394,
    "Longitude": 7.460269,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.467394,7.460269",
    "IsOpen24Hours": true,
    "id": "51.467394,7.460269"
  },
  {
    "ID": 1372,
    "Tankstellennummer": "0F457",
    "Name": "Aral Tankstelle Essen, Ernestinenstr. 157",
    "Address": "Ernestinenstraße 157",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45141,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2012018242,
    "24 hour?": "TRUE",
    "Latitude": 51.467783,
    "Longitude": 7.045453,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.467783,7.045453",
    "IsOpen24Hours": true,
    "id": "51.467783,7.045453"
  },
  {
    "ID": 207611177,
    "Tankstellennummer": 610654,
    "Name": "WESTFALEN, BOCHUM",
    "Address": "Alte Wittener Str. 40",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44803,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0234/ 33385927",
    "24 hour?": "TRUE",
    "Latitude": 51.4681,
    "Longitude": 7.27106,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.4681,7.27106",
    "IsOpen24Hours": true,
    "id": "51.4681,7.27106"
  },
  {
    "ID": 158511179,
    "Tankstellennummer": "TD000947",
    "Name": "DORTMUND PREINSTR. 126",
    "Address": "PREINSTR. 126",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": 44265,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0231-7246591",
    "24 hour?": "FALSE",
    "Latitude": 51.4682,
    "Longitude": 7.4927,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4682,7.4927",
    "IsOpen24Hours": false,
    "id": "51.4682,7.4927"
  },
  {
    "ID": 1231,
    "Tankstellennummer": "0F338",
    "Name": "Aral Tankstelle Menden, Fröndenberger Str. 291",
    "Address": "Fröndenberger Straße 291",
    "Address__1": "",
    "City": "Menden",
    "Postcode": 58708,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2373390839,
    "24 hour?": "FALSE",
    "Latitude": 51.468478,
    "Longitude": 7.768807,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.468478,7.768807",
    "IsOpen24Hours": false,
    "id": "51.468478,7.768807"
  },
  {
    "ID": 2492,
    "Tankstellennummer": "0FC39",
    "Name": "Aral Tankstelle Lauchhammer, Berliner Str. 1",
    "Address": "Berliner Straße 1",
    "Address__1": "",
    "City": "Lauchhammer",
    "Postcode": 1979,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 35747258,
    "24 hour?": "FALSE",
    "Latitude": 51.468566,
    "Longitude": 13.739294,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.468566,13.739294",
    "IsOpen24Hours": false,
    "id": "51.468566,13.739294"
  },
  {
    "ID": 1345474735,
    "Tankstellennummer": 610630,
    "Name": "WESTFALEN, DORTMUND",
    "Address": "Benninghofer Str. 265",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44267,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": 2516950,
    "24 hour?": "FALSE",
    "Latitude": 51.4687,
    "Longitude": 7.51574,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4687,7.51574",
    "IsOpen24Hours": false,
    "id": "51.4687,7.51574"
  },
  {
    "ID": 652045435,
    "Tankstellennummer": 900687,
    "Name": "WESTFALEN, BAT LICHTENDORF NORD DORTMUND",
    "Address": "Roemerstr. 28",
    "Address__1": "",
    "City": "BAT LICHTENDORF NORD DORTMUND",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.4688,
    "Longitude": 7.59318,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4688,7.59318",
    "IsOpen24Hours": false,
    "id": "51.4688,7.59318"
  },
  {
    "ID": 276712616,
    "Tankstellennummer": "TD111940",
    "Name": "BOCHUM UNTERSTRASSE 100",
    "Address": "UNTERSTRASSE 100",
    "Address__1": "",
    "City": "BOCHUM",
    "Postcode": 44892,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0234-9272073",
    "24 hour?": "FALSE",
    "Latitude": 51.4699,
    "Longitude": 7.3069,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4699,7.3069",
    "IsOpen24Hours": false,
    "id": "51.4699,7.3069"
  },
  {
    "ID": 738291819,
    "Tankstellennummer": 5150,
    "Name": "Agip Teistungen",
    "Address": "Bergstrae 39",
    "Address__1": "",
    "City": "Teistungen",
    "Postcode": 37339,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 36071872",
    "24 hour?": "FALSE",
    "Latitude": 51.46998,
    "Longitude": 10.26971,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.46998,10.26971",
    "IsOpen24Hours": false,
    "id": "51.46998,10.26971"
  },
  {
    "ID": 1332,
    "Tankstellennummer": "0F423",
    "Name": "Aral Tankstelle Oberhausen, Bebelstr. 69A",
    "Address": "Bebelstraße 69 a",
    "Address__1": "",
    "City": "Oberhausen",
    "Postcode": 46049,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 20825434,
    "24 hour?": "FALSE",
    "Latitude": 51.471525,
    "Longitude": 6.83761,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.471525,6.83761",
    "IsOpen24Hours": false,
    "id": "51.471525,6.83761"
  },
  {
    "ID": 2562,
    "Tankstellennummer": "0FC97",
    "Name": "Aral Tankstelle Halle, Eislebener Chaussee 200",
    "Address": "Eislebener Chaussee 200",
    "Address__1": "",
    "City": "Halle",
    "Postcode": 6126,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3456903263,
    "24 hour?": "TRUE",
    "Latitude": 51.471557,
    "Longitude": 11.904981,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.471557,11.904981",
    "IsOpen24Hours": true,
    "id": "51.471557,11.904981"
  },
  {
    "ID": 1575804090,
    "Tankstellennummer": "0FQ91",
    "Name": "DORTMUND",
    "Address": "Zeche Crone 2",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44265,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23142788770,
    "24 hour?": "TRUE",
    "Latitude": 51.47233,
    "Longitude": 7.485391,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.47233,7.485391",
    "IsOpen24Hours": true,
    "id": "51.47233,7.485391"
  },
  {
    "ID": 1335,
    "Tankstellennummer": "0F425",
    "Name": "Aral Tankstelle Oberhausen, Mülheimer Str. 155",
    "Address": "Mülheimer Straße 155",
    "Address__1": "",
    "City": "Oberhausen",
    "Postcode": 46045,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 20821691,
    "24 hour?": "TRUE",
    "Latitude": 51.472761,
    "Longitude": 6.863527,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.472761,6.863527",
    "IsOpen24Hours": true,
    "id": "51.472761,6.863527"
  },
  {
    "ID": 483621597,
    "Tankstellennummer": "TD030460",
    "Name": "HALLE PLATZ DREI LILIEN 5",
    "Address": "PLATZ DREI LILIEN 5",
    "Address__1": "",
    "City": "HALLE",
    "Postcode": 6124,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0345-6902885",
    "24 hour?": "FALSE",
    "Latitude": 51.4736,
    "Longitude": 11.9239,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4736,11.9239",
    "IsOpen24Hours": false,
    "id": "51.4736,11.9239"
  },
  {
    "ID": 1723857708,
    "Tankstellennummer": "TD111130",
    "Name": "FROENDENBERG WESTICKER STR. 58",
    "Address": "WESTICKER STR. 58",
    "Address__1": "",
    "City": "FROENDENBERG",
    "Postcode": 58730,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02373-76259",
    "24 hour?": "FALSE",
    "Latitude": 51.4753,
    "Longitude": 7.7887,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4753,7.7887",
    "IsOpen24Hours": false,
    "id": "51.4753,7.7887"
  },
  {
    "ID": 2745,
    "Tankstellennummer": "0FE50",
    "Name": "Aral Tankstelle Dölbau, Geltestr.",
    "Address": "Geltestraße 1",
    "Address__1": "",
    "City": "Dölbau",
    "Postcode": 6184,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3460252411,
    "24 hour?": "TRUE",
    "Latitude": 51.476333,
    "Longitude": 12.086264,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.476333,12.086264",
    "IsOpen24Hours": true,
    "id": "51.476333,12.086264"
  },
  {
    "ID": 2499,
    "Tankstellennummer": "0F024",
    "Name": "Aral Tankstelle Nordhausen, Helmestraße 109",
    "Address": "Helmestraße 109",
    "Address__1": "",
    "City": "Nordhausen",
    "Postcode": 99734,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3631602241,
    "24 hour?": "TRUE",
    "Latitude": 51.477266,
    "Longitude": 10.808441,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.477266,10.808441",
    "IsOpen24Hours": true,
    "id": "51.477266,10.808441"
  },
  {
    "ID": 1572798340,
    "Tankstellennummer": "TD005173",
    "Name": "OBERHAUSEN RUHRORTER STR. 65",
    "Address": "RUHRORTER STR. 65",
    "Address__1": "",
    "City": "OBERHAUSEN",
    "Postcode": 46049,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0208-807951",
    "24 hour?": "FALSE",
    "Latitude": 51.4774,
    "Longitude": 6.83,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4774,6.83",
    "IsOpen24Hours": false,
    "id": "51.4774,6.83"
  },
  {
    "ID": 203850263,
    "Tankstellennummer": "TD019315",
    "Name": "DORTMUND HAGENER STR. 18",
    "Address": "HAGENER STR. 18",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": 44225,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0231-719593",
    "24 hour?": "FALSE",
    "Latitude": 51.4774,
    "Longitude": 7.4591,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4774,7.4591",
    "IsOpen24Hours": false,
    "id": "51.4774,7.4591"
  },
  {
    "ID": 223932841,
    "Tankstellennummer": "0F460",
    "Name": "Aral TankStelle Bochum, Wittener Straße 66",
    "Address": "Wittener Straße 66",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44789,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 234335915,
    "24 hour?": "TRUE",
    "Latitude": 51.477485,
    "Longitude": 7.227767,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.477485,7.227767",
    "IsOpen24Hours": true,
    "id": "51.477485,7.227767"
  },
  {
    "ID": 587145437,
    "Tankstellennummer": "TD000308",
    "Name": "DORTMUND LICHTENDORFER STR. 15",
    "Address": "LICHTENDORFER STR.152",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": 44289,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0231-400363",
    "24 hour?": "FALSE",
    "Latitude": 51.4781,
    "Longitude": 7.5978,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4781,7.5978",
    "IsOpen24Hours": false,
    "id": "51.4781,7.5978"
  },
  {
    "ID": 584912194,
    "Tankstellennummer": "TD110530",
    "Name": "BOCHUM BERLINER STR. 37",
    "Address": "BERLINER STR. 37",
    "Address__1": "",
    "City": "BOCHUM",
    "Postcode": 44866,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02327-31229",
    "24 hour?": "FALSE",
    "Latitude": 51.4783,
    "Longitude": 7.1267,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4783,7.1267",
    "IsOpen24Hours": false,
    "id": "51.4783,7.1267"
  },
  {
    "ID": 1054739211,
    "Tankstellennummer": "TD032953",
    "Name": "HALLE DELITZSCHER STR. 36",
    "Address": "DELITZSCHER STR. 36",
    "Address__1": "",
    "City": "HALLE",
    "Postcode": 6112,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0345-5606281",
    "24 hour?": "FALSE",
    "Latitude": 51.4785,
    "Longitude": 11.996,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4785,11.996",
    "IsOpen24Hours": false,
    "id": "51.4785,11.996"
  },
  {
    "ID": 38882652,
    "Tankstellennummer": "TD150370",
    "Name": "TEUTSCHENTHAL OT LANGENBOGEN",
    "Address": "KOECHSTEDTER WEG 8A",
    "Address__1": "",
    "City": "TEUTSCHENTHAL OT LANGENBOGEN",
    "Postcode": 6179,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034601-23164",
    "24 hour?": "FALSE",
    "Latitude": 51.4793,
    "Longitude": 11.7842,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4793,11.7842",
    "IsOpen24Hours": false,
    "id": "51.4793,11.7842"
  },
  {
    "ID": 1287,
    "Tankstellennummer": "0F387",
    "Name": "Aral Tankstelle Bochum, Alleestr. 79",
    "Address": "Alleestraße 79",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44793,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23466401,
    "24 hour?": "TRUE",
    "Latitude": 51.479328,
    "Longitude": 7.20471,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.479328,7.20471",
    "IsOpen24Hours": true,
    "id": "51.479328,7.20471"
  },
  {
    "ID": 2882,
    "Tankstellennummer": "0FH50",
    "Name": "Aral Tankstelle Bochum, Bochumer Str. 6",
    "Address": "Bochumer Straße 6",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44866,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 232788617,
    "24 hour?": "FALSE",
    "Latitude": 51.479443,
    "Longitude": 7.14988,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.479443,7.14988",
    "IsOpen24Hours": false,
    "id": "51.479443,7.14988"
  },
  {
    "ID": 1870401092,
    "Tankstellennummer": 610347,
    "Name": "WESTFALEN, DUISBURG",
    "Address": "Moehlenkampstr. 9",
    "Address__1": "",
    "City": "DUISBURG",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.4802,
    "Longitude": 6.74379,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4802,6.74379",
    "IsOpen24Hours": false,
    "id": "51.4802,6.74379"
  },
  {
    "ID": 3382,
    "Tankstellennummer": "0FL23",
    "Name": "Aral Tankstelle Sangerhausen, Riestedter Str.",
    "Address": "Riestedter Straße 55",
    "Address__1": "",
    "City": "Sangerhausen",
    "Postcode": 6526,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3464572843,
    "24 hour?": "TRUE",
    "Latitude": 51.480374,
    "Longitude": 11.317881,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.480374,11.317881",
    "IsOpen24Hours": true,
    "id": "51.480374,11.317881"
  },
  {
    "ID": 428072040,
    "Tankstellennummer": "0FU46",
    "Name": "BOCHUM",
    "Address": "HAUPTSTRAßE 100",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44894,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23441747848,
    "24 hour?": "TRUE",
    "Latitude": 51.480763,
    "Longitude": 7.32395,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.480763,7.32395",
    "IsOpen24Hours": true,
    "id": "51.480763,7.32395"
  },
  {
    "ID": 921520694,
    "Tankstellennummer": "0FR98",
    "Name": "Aral Tankstelle Hofgeismar",
    "Address": "GREBENSTEINER STRAßE",
    "Address__1": "",
    "City": "HOFGEISMAR",
    "Postcode": 34369,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 56715070246,
    "24 hour?": "TRUE",
    "Latitude": 51.481432,
    "Longitude": 9.38993,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.481432,9.38993",
    "IsOpen24Hours": true,
    "id": "51.481432,9.38993"
  },
  {
    "ID": 2890,
    "Tankstellennummer": "0FH57",
    "Name": "Aral Tankstelle Dortmund, Stockumer Str. 168",
    "Address": "Stockumer Straße 168",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44225,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 231711234,
    "24 hour?": "TRUE",
    "Latitude": 51.481626,
    "Longitude": 7.438967,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.481626,7.438967",
    "IsOpen24Hours": true,
    "id": "51.481626,7.438967"
  },
  {
    "ID": 1483867324,
    "Tankstellennummer": "TD030528",
    "Name": "HALLE AN DER MAGISTRALE 2",
    "Address": "AN DER MAGISTRALE 2",
    "Address__1": "",
    "City": "HALLE",
    "Postcode": 6124,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0345-8044362",
    "24 hour?": "FALSE",
    "Latitude": 51.4817,
    "Longitude": 11.9453,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4817,11.9453",
    "IsOpen24Hours": false,
    "id": "51.4817,11.9453"
  },
  {
    "ID": 650128716,
    "Tankstellennummer": "TD000949",
    "Name": "DORTMUND STOCKUMER STR. 200",
    "Address": "STOCKUMER STR. 200",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": 44225,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0231-711950",
    "24 hour?": "FALSE",
    "Latitude": 51.4818,
    "Longitude": 7.4363,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4818,7.4363",
    "IsOpen24Hours": false,
    "id": "51.4818,7.4363"
  },
  {
    "ID": 2181,
    "Tankstellennummer": "0FA32",
    "Name": "Aral Tankstelle Essen, Bottroper Str. 298 a",
    "Address": "Bottroper Straße 298 a",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45356,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2016143248,
    "24 hour?": "FALSE",
    "Latitude": 51.484366,
    "Longitude": 6.975781,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.484366,6.975781",
    "IsOpen24Hours": false,
    "id": "51.484366,6.975781"
  },
  {
    "ID": 507469275,
    "Tankstellennummer": 610348,
    "Name": "WESTFALEN, ESSEN",
    "Address": "Bottroper Str. 331",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45356,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0201/665811",
    "24 hour?": "TRUE",
    "Latitude": 51.4849,
    "Longitude": 6.97206,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.4849,6.97206",
    "IsOpen24Hours": true,
    "id": "51.4849,6.97206"
  },
  {
    "ID": 1278481084,
    "Tankstellennummer": "TD030759",
    "Name": "SANGERHAUSEN KUPFERHUETTE 7",
    "Address": "KUPFERHUETTE 7",
    "Address__1": "",
    "City": "SANGERHAUSEN",
    "Postcode": 6526,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03464-572685",
    "24 hour?": "FALSE",
    "Latitude": 51.486,
    "Longitude": 11.3086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.486,11.3086",
    "IsOpen24Hours": false,
    "id": "51.486,11.3086"
  },
  {
    "ID": 2109554051,
    "Tankstellennummer": "0FO78",
    "Name": "Aral TankStelle Essen, Gladbecker Str. 398 A",
    "Address": "Gladbecker Str. 398 A",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45326,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 20143644416,
    "24 hour?": "TRUE",
    "Latitude": 51.486159,
    "Longitude": 6.999299,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.486159,6.999299",
    "IsOpen24Hours": true,
    "id": "51.486159,6.999299"
  },
  {
    "ID": 1148489878,
    "Tankstellennummer": "TD042002",
    "Name": "WERTHER AM HELMEBERG 3",
    "Address": "AM HELMEBERG 3",
    "Address__1": "",
    "City": "WERTHER",
    "Postcode": 99735,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 36319999169,
    "24 hour?": "FALSE",
    "Latitude": 51.4871,
    "Longitude": 10.7424,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4871,10.7424",
    "IsOpen24Hours": false,
    "id": "51.4871,10.7424"
  },
  {
    "ID": 1020306339,
    "Tankstellennummer": 610652,
    "Name": "WESTFALEN, MOERS",
    "Address": "Rheinberger Str. 306",
    "Address__1": "",
    "City": "Moers",
    "Postcode": 47445,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02841/41252",
    "24 hour?": "TRUE",
    "Latitude": 51.4874,
    "Longitude": 6.63315,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.4874,6.63315",
    "IsOpen24Hours": true,
    "id": "51.4874,6.63315"
  },
  {
    "ID": 3410,
    "Tankstellennummer": "0FL50",
    "Name": "Aral Tankstelle Halle, Volkmannstrasse 19",
    "Address": "Volkmannstraße 19",
    "Address__1": "",
    "City": "Halle",
    "Postcode": 6112,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3455126523,
    "24 hour?": "TRUE",
    "Latitude": 51.487914,
    "Longitude": 11.984189,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.487914,11.984189",
    "IsOpen24Hours": true,
    "id": "51.487914,11.984189"
  },
  {
    "ID": 1944011284,
    "Tankstellennummer": "TD042077",
    "Name": "DIEMELSTADT WREXER TEICH 1",
    "Address": "WREXER TEICH 1",
    "Address__1": "",
    "City": "DIEMELSTADT",
    "Postcode": 34474,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05694-1688",
    "24 hour?": "FALSE",
    "Latitude": 51.4886,
    "Longitude": 9.0072,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4886,9.0072",
    "IsOpen24Hours": false,
    "id": "51.4886,9.0072"
  },
  {
    "ID": 2871,
    "Tankstellennummer": "0FH41",
    "Name": "Aral Tankstelle Duisburg, Duisburger Str. 105",
    "Address": "Duisburger Straße 121",
    "Address__1": "",
    "City": "Duisburg",
    "Postcode": 47166,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2039948050,
    "24 hour?": "TRUE",
    "Latitude": 51.489325,
    "Longitude": 6.784194,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.489325,6.784194",
    "IsOpen24Hours": true,
    "id": "51.489325,6.784194"
  },
  {
    "ID": 1269,
    "Tankstellennummer": "0F370",
    "Name": "Aral Tankstelle Bochum, Dorstener Str.  84",
    "Address": "Dorstener Straße  84",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44809,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23495544430,
    "24 hour?": "TRUE",
    "Latitude": 51.489437,
    "Longitude": 7.208033,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.489437,7.208033",
    "IsOpen24Hours": true,
    "id": "51.489437,7.208033"
  },
  {
    "ID": 1284,
    "Tankstellennummer": "0F384",
    "Name": "Aral Tankstelle Bochum, Castroper Str. 190",
    "Address": "Castroper Straße 190",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44791,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 234591182,
    "24 hour?": "TRUE",
    "Latitude": 51.490716,
    "Longitude": 7.241519,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.490716,7.241519",
    "IsOpen24Hours": true,
    "id": "51.490716,7.241519"
  },
  {
    "ID": 8474767,
    "Tankstellennummer": "TD004440",
    "Name": "WARBURG PADERBORNER TOR 164",
    "Address": "PADERBORNER TOR 164",
    "Address__1": "",
    "City": "WARBURG",
    "Postcode": 34414,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05641-8530",
    "24 hour?": "FALSE",
    "Latitude": 51.4922,
    "Longitude": 9.1309,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4922,9.1309",
    "IsOpen24Hours": false,
    "id": "51.4922,9.1309"
  },
  {
    "ID": 1349270625,
    "Tankstellennummer": "0FK28",
    "Name": "Aral TankStelle Essen, Altenessener Straße 288",
    "Address": "Altenessener Straße 288",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45326,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2018378311,
    "24 hour?": "TRUE",
    "Latitude": 51.492365,
    "Longitude": 7.009831,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.492365,7.009831",
    "IsOpen24Hours": true,
    "id": "51.492365,7.009831"
  },
  {
    "ID": 502267970,
    "Tankstellennummer": 610653,
    "Name": "WESTFALEN, BOCHUM",
    "Address": "Werner Hellweg 526",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44894,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0234/262238",
    "24 hour?": "FALSE",
    "Latitude": 51.4924,
    "Longitude": 7.31072,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4924,7.31072",
    "IsOpen24Hours": false,
    "id": "51.4924,7.31072"
  },
  {
    "ID": 2548,
    "Tankstellennummer": "0FC89",
    "Name": "Aral Tankstelle Nordhausen, Hallesche Str. 101",
    "Address": "Hallesche Straße 101",
    "Address__1": "",
    "City": "Nordhausen",
    "Postcode": 99734,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3631603191,
    "24 hour?": "TRUE",
    "Latitude": 51.493673,
    "Longitude": 10.816218,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.493673,10.816218",
    "IsOpen24Hours": true,
    "id": "51.493673,10.816218"
  },
  {
    "ID": 931216400,
    "Tankstellennummer": "TD000867",
    "Name": "DORTMUND AM REMBERG 94",
    "Address": "AM REMBERG 94",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": 44269,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0231-42786733",
    "24 hour?": "FALSE",
    "Latitude": 51.4937,
    "Longitude": 7.5206,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4937,7.5206",
    "IsOpen24Hours": false,
    "id": "51.4937,7.5206"
  },
  {
    "ID": 1584366956,
    "Tankstellennummer": "0FQ85",
    "Name": "Aral Tankstelle Bochum",
    "Address": "Lütgendortmunder Hellweg 200/a.d A4",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44892,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23432589808,
    "24 hour?": "TRUE",
    "Latitude": 51.494024,
    "Longitude": 7.339435,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.494024,7.339435",
    "IsOpen24Hours": true,
    "id": "51.494024,7.339435"
  },
  {
    "ID": 784420773,
    "Tankstellennummer": "TD022871",
    "Name": "NORDHAUSEN PULVERHAUSWEG",
    "Address": "PULVERHAUSWEG 1",
    "Address__1": "",
    "City": "NORDHAUSEN",
    "Postcode": 99734,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03631-601162",
    "24 hour?": "FALSE",
    "Latitude": 51.4944,
    "Longitude": 10.8147,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4944,10.8147",
    "IsOpen24Hours": false,
    "id": "51.4944,10.8147"
  },
  {
    "ID": 1164,
    "Tankstellennummer": "0F292",
    "Name": "Aral Tankstelle Warburg, Paderborner Tor 180/B 7",
    "Address": "Paderborner Tor 180/B 7",
    "Address__1": "",
    "City": "Warburg",
    "Postcode": 34414,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5641743838,
    "24 hour?": "TRUE",
    "Latitude": 51.494593,
    "Longitude": 9.126022,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.494593,9.126022",
    "IsOpen24Hours": true,
    "id": "51.494593,9.126022"
  },
  {
    "ID": 1544056608,
    "Tankstellennummer": "TD000725",
    "Name": "DUISBURG-NEUMUEHL K.-ADENAUER-",
    "Address": "K.-ADENAUER-RING 35",
    "Address__1": "",
    "City": "DUISBURG-NEUMUEHL",
    "Postcode": 47167,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0203-587548",
    "24 hour?": "FALSE",
    "Latitude": 51.4951,
    "Longitude": 6.8106,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4951,6.8106",
    "IsOpen24Hours": false,
    "id": "51.4951,6.8106"
  },
  {
    "ID": 1545855655,
    "Tankstellennummer": 5109,
    "Name": "Agip Nordhausen",
    "Address": "Freiherr-vom-Stein-Str. 30",
    "Address__1": "",
    "City": "Nordhausen",
    "Postcode": 99734,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 36319029",
    "24 hour?": "TRUE",
    "Latitude": 51.49546,
    "Longitude": 10.77823,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.49546,10.77823",
    "IsOpen24Hours": true,
    "id": "51.49546,10.77823"
  },
  {
    "ID": 1292,
    "Tankstellennummer": "0F392",
    "Name": "Aral Tankstelle Gelsenkirchen, Bochumer Str. 177",
    "Address": "Bochumer Straße 177",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45886,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 209204182,
    "24 hour?": "FALSE",
    "Latitude": 51.496242,
    "Longitude": 7.114437,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.496242,7.114437",
    "IsOpen24Hours": false,
    "id": "51.496242,7.114437"
  },
  {
    "ID": 1190,
    "Tankstellennummer": "0F323",
    "Name": "Aral Autobahntankstelle Dortmund, Im Westefeld 35",
    "Address": "Im I. Westfeld 35",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44388,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 231631156,
    "24 hour?": "TRUE",
    "Latitude": 51.497046,
    "Longitude": 7.332839,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.497046,7.332839",
    "IsOpen24Hours": true,
    "id": "51.497046,7.332839"
  },
  {
    "ID": 3391,
    "Tankstellennummer": "0FL31",
    "Name": "Aral Tankstelle Halle, Dessauer Platz 4",
    "Address": "Dessauer Platz 4",
    "Address__1": "",
    "City": "Halle",
    "Postcode": 6118,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3452909012,
    "24 hour?": "TRUE",
    "Latitude": 51.497411,
    "Longitude": 11.98195,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.497411,11.98195",
    "IsOpen24Hours": true,
    "id": "51.497411,11.98195"
  },
  {
    "ID": 961101056,
    "Tankstellennummer": "0FH80",
    "Name": "Aral TankStelle Kamp-Lintfort, Moerser Straße 470-472",
    "Address": "Moerser Straße 470-472",
    "Address__1": "",
    "City": "Kamp-Lintfort",
    "Postcode": 47475,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 284293567,
    "24 hour?": "TRUE",
    "Latitude": 51.499149,
    "Longitude": 6.568116,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.499149,6.568116",
    "IsOpen24Hours": true,
    "id": "51.499149,6.568116"
  },
  {
    "ID": 1838459999,
    "Tankstellennummer": "TD019299",
    "Name": "BOCHUM-HARPEN HARPENER HELLWEG",
    "Address": "HARPENER HELLWEG 88",
    "Address__1": "",
    "City": "BOCHUM-HARPEN",
    "Postcode": 44805,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0234-231498",
    "24 hour?": "FALSE",
    "Latitude": 51.4993,
    "Longitude": 7.2764,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4993,7.2764",
    "IsOpen24Hours": false,
    "id": "51.4993,7.2764"
  },
  {
    "ID": 515870230,
    "Tankstellennummer": "0FH59",
    "Name": "Aral TankStelle Dortmund, Rheinlanddamm 103",
    "Address": "Rheinlanddamm 103",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44139,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2319128872,
    "24 hour?": "TRUE",
    "Latitude": 51.499353,
    "Longitude": 7.47172,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.499353,7.47172",
    "IsOpen24Hours": true,
    "id": "51.499353,7.47172"
  },
  {
    "ID": 208514768,
    "Tankstellennummer": "TD142370",
    "Name": "DIEMELSTADT BAB BIGGENKOPF SUE",
    "Address": "BAB BIGGENKOPF SUED",
    "Address__1": "",
    "City": "DIEMELSTADT",
    "Postcode": 34474,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05642-98100",
    "24 hour?": "FALSE",
    "Latitude": 51.4995,
    "Longitude": 8.9834,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.4995,8.9834",
    "IsOpen24Hours": false,
    "id": "51.4995,8.9834"
  },
  {
    "ID": 1199,
    "Tankstellennummer": "0F313",
    "Name": "Aral Tankstelle Dortmund, Lindemannstr. 65",
    "Address": "Lindemannstraße 65",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44137,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2319003692,
    "24 hour?": "TRUE",
    "Latitude": 51.501486,
    "Longitude": 7.451744,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.501486,7.451744",
    "IsOpen24Hours": true,
    "id": "51.501486,7.451744"
  },
  {
    "ID": 1354,
    "Tankstellennummer": "0F439",
    "Name": "Aral Tankstelle Bochum, Herner Str. 256",
    "Address": "Herner Straße 256",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44809,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2349530941,
    "24 hour?": "TRUE",
    "Latitude": 51.501622,
    "Longitude": 7.2127,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.501622,7.2127",
    "IsOpen24Hours": true,
    "id": "51.501622,7.2127"
  },
  {
    "ID": 1367,
    "Tankstellennummer": "0F452",
    "Name": "Aral Tankstelle Bochum, Castroper Hellweg 111",
    "Address": "Castroper Hellweg 111",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44805,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2349508090,
    "24 hour?": "TRUE",
    "Latitude": 51.501691,
    "Longitude": 7.258643,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.501691,7.258643",
    "IsOpen24Hours": true,
    "id": "51.501691,7.258643"
  },
  {
    "ID": 2678,
    "Tankstellennummer": "0FD99",
    "Name": "Aral Tankstelle Halle, Rosenfelder Straße 13",
    "Address": "Rosenfelder Straße 13",
    "Address__1": "",
    "City": "Halle",
    "Postcode": 6116,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3455606492,
    "24 hour?": "TRUE",
    "Latitude": 51.502248,
    "Longitude": 12.033682,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.502248,12.033682",
    "IsOpen24Hours": true,
    "id": "51.502248,12.033682"
  },
  {
    "ID": 835851385,
    "Tankstellennummer": 610343,
    "Name": "WESTFALEN, BOCHUM",
    "Address": "Herner Str. 269",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44809,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0234/ 9041583",
    "24 hour?": "TRUE",
    "Latitude": 51.5025,
    "Longitude": 7.21228,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.5025,7.21228",
    "IsOpen24Hours": true,
    "id": "51.5025,7.21228"
  },
  {
    "ID": 2879,
    "Tankstellennummer": "0FH47",
    "Name": "Aral Tankstelle Oberhausen, Bottroper Str. 211",
    "Address": "Bottroper Straße 211",
    "Address__1": "",
    "City": "Oberhausen",
    "Postcode": 46117,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 208890648,
    "24 hour?": "FALSE",
    "Latitude": 51.502726,
    "Longitude": 6.892161,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.502726,6.892161",
    "IsOpen24Hours": false,
    "id": "51.502726,6.892161"
  },
  {
    "ID": 2888,
    "Tankstellennummer": "0FH55",
    "Name": "Aral Tankstelle Ense, Werler Str. 56",
    "Address": "Werler Straße 56",
    "Address__1": "",
    "City": "Ense",
    "Postcode": 59469,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 29389795557,
    "24 hour?": "FALSE",
    "Latitude": 51.50381,
    "Longitude": 7.969485,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.50381,7.969485",
    "IsOpen24Hours": false,
    "id": "51.50381,7.969485"
  },
  {
    "ID": 1193,
    "Tankstellennummer": "0F307",
    "Name": "Aral Tankstelle Dortmund, Westfalendamm 166",
    "Address": "Westfalendamm 166",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44141,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2319776560,
    "24 hour?": "TRUE",
    "Latitude": 51.504057,
    "Longitude": 7.498477,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.504057,7.498477",
    "IsOpen24Hours": true,
    "id": "51.504057,7.498477"
  },
  {
    "ID": 52487364,
    "Tankstellennummer": "TD111190",
    "Name": "DORTMUND WESTFALENDAMM 272",
    "Address": "WESTFALENDAMM 272",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": 44141,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0231-596931",
    "24 hour?": "FALSE",
    "Latitude": 51.5041,
    "Longitude": 7.5108,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5041,7.5108",
    "IsOpen24Hours": false,
    "id": "51.5041,7.5108"
  },
  {
    "ID": 1614605499,
    "Tankstellennummer": "TD000316",
    "Name": "DUISBURG HAMBORN KAISER-FRIEDR",
    "Address": "KAISER-FRIEDRICH-STR. 71",
    "Address__1": "",
    "City": "DUISBURG HAMBORN",
    "Postcode": 47169,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0203-401774",
    "24 hour?": "FALSE",
    "Latitude": 51.506,
    "Longitude": 6.7636,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.506,6.7636",
    "IsOpen24Hours": false,
    "id": "51.506,6.7636"
  },
  {
    "ID": 1225555158,
    "Tankstellennummer": 610183,
    "Name": "WESTFALEN, DORTMUND",
    "Address": "Emschertalstr. 131",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.5061,
    "Longitude": 7.58915,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5061,7.58915",
    "IsOpen24Hours": false,
    "id": "51.5061,7.58915"
  },
  {
    "ID": 1209,
    "Tankstellennummer": "0F320",
    "Name": "Aral Tankstelle Dortmund, Martener Str. 276",
    "Address": "Martener Straße 276",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44379,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 231613466,
    "24 hour?": "FALSE",
    "Latitude": 51.508762,
    "Longitude": 7.388477,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.508762,7.388477",
    "IsOpen24Hours": false,
    "id": "51.508762,7.388477"
  },
  {
    "ID": 2891,
    "Tankstellennummer": "0FH58",
    "Name": "Aral Tankstelle Dortmund, Heiliger Weg / Löwenstr.",
    "Address": "Heiliger Weg / Löwenstr.",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44135,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 231524830,
    "24 hour?": "TRUE",
    "Latitude": 51.509497,
    "Longitude": 7.473947,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.509497,7.473947",
    "IsOpen24Hours": true,
    "id": "51.509497,7.473947"
  },
  {
    "ID": 2880,
    "Tankstellennummer": "0FH48",
    "Name": "Aral Tankstelle Oberhausen, Vestische Str. 157",
    "Address": "Vestische Straße 157",
    "Address__1": "",
    "City": "Oberhausen",
    "Postcode": 46117,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 20898975792,
    "24 hour?": "FALSE",
    "Latitude": 51.509498,
    "Longitude": 6.865255,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.509498,6.865255",
    "IsOpen24Hours": false,
    "id": "51.509498,6.865255"
  },
  {
    "ID": 310292626,
    "Tankstellennummer": "TD033159",
    "Name": "SENFTENBERG USEDOMER STR.",
    "Address": "USEDOMER STR. 1",
    "Address__1": "",
    "City": "SENFTENBERG",
    "Postcode": 1968,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03573-61085",
    "24 hour?": "FALSE",
    "Latitude": 51.51,
    "Longitude": 13.9818,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.51,13.9818",
    "IsOpen24Hours": false,
    "id": "51.51,13.9818"
  },
  {
    "ID": 248512249,
    "Tankstellennummer": "TD030643",
    "Name": "EISLEBEN HALLESCHE STR. 151",
    "Address": "HALLESCHE STR. 151",
    "Address__1": "",
    "City": "EISLEBEN",
    "Postcode": 6295,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03475-716165",
    "24 hour?": "FALSE",
    "Latitude": 51.5121,
    "Longitude": 11.571,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5121,11.571",
    "IsOpen24Hours": false,
    "id": "51.5121,11.571"
  },
  {
    "ID": 1399796952,
    "Tankstellennummer": "TD110850",
    "Name": "GELSENKIRCHEN HEINRICHPLATZ 3",
    "Address": "HEINRICHPLATZ 3",
    "Address__1": "",
    "City": "GELSENKIRCHEN",
    "Postcode": 45888,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0209-1659850",
    "24 hour?": "FALSE",
    "Latitude": 51.5123,
    "Longitude": 7.1111,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5123,7.1111",
    "IsOpen24Hours": false,
    "id": "51.5123,7.1111"
  },
  {
    "ID": 12352,
    "Tankstellennummer": "0FH40",
    "Name": "Aral Tankstelle Bochum, Herner Str. 403",
    "Address": "Herner Straße 403",
    "Address__1": "",
    "City": "Bochum",
    "Postcode": 44807,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 234532070,
    "24 hour?": "TRUE",
    "Latitude": 51.512546,
    "Longitude": 7.210151,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.512546,7.210151",
    "IsOpen24Hours": true,
    "id": "51.512546,7.210151"
  },
  {
    "ID": 781385283,
    "Tankstellennummer": "TD019398",
    "Name": "OBERHAUSEN BIEFANGSTR. 92",
    "Address": "BIEFANGSTR. 92",
    "Address__1": "",
    "City": "OBERHAUSEN",
    "Postcode": 46149,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0208-669830",
    "24 hour?": "FALSE",
    "Latitude": 51.5136,
    "Longitude": 6.8274,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5136,6.8274",
    "IsOpen24Hours": false,
    "id": "51.5136,6.8274"
  },
  {
    "ID": 2872,
    "Tankstellennummer": "0FH42",
    "Name": "Aral Tankstelle Duisburg, Weseler Str. 261",
    "Address": "Weseler Straße 261",
    "Address__1": "",
    "City": "Duisburg",
    "Postcode": 47169,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 203990888,
    "24 hour?": "TRUE",
    "Latitude": 51.513927,
    "Longitude": 6.741863,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.513927,6.741863",
    "IsOpen24Hours": true,
    "id": "51.513927,6.741863"
  },
  {
    "ID": 869055679,
    "Tankstellennummer": 610353,
    "Name": "WESTFALEN, HOLZWICKEDE",
    "Address": "Zur alten Kolonie 1",
    "Address__1": "",
    "City": "Holzwickede",
    "Postcode": 59439,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02301/3437",
    "24 hour?": "FALSE",
    "Latitude": 51.5148,
    "Longitude": 7.62053,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5148,7.62053",
    "IsOpen24Hours": false,
    "id": "51.5148,7.62053"
  },
  {
    "ID": 1319049907,
    "Tankstellennummer": "TD004978",
    "Name": "BOCHUM FRAUENLOBSTR.112",
    "Address": "FRAUENLOBSTR.112",
    "Address__1": "",
    "City": "BOCHUM",
    "Postcode": 44805,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0234-850992",
    "24 hour?": "FALSE",
    "Latitude": 51.5153,
    "Longitude": 7.2589,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5153,7.2589",
    "IsOpen24Hours": false,
    "id": "51.5153,7.2589"
  },
  {
    "ID": 1685,
    "Tankstellennummer": "0F689",
    "Name": "Aral Tankstelle Duderstadt, Herzberger Str. 1",
    "Address": "Herzberger Straße 1",
    "Address__1": "",
    "City": "Duderstadt",
    "Postcode": 37115,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 55272838,
    "24 hour?": "FALSE",
    "Latitude": 51.515854,
    "Longitude": 10.26076,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.515854,10.26076",
    "IsOpen24Hours": false,
    "id": "51.515854,10.26076"
  },
  {
    "ID": 3281,
    "Tankstellennummer": "0FK45",
    "Name": "Aral Tankstelle Duderstadt, Charlottenburger Str. 9",
    "Address": "Charlottenburger Straße 9",
    "Address__1": "",
    "City": "Duderstadt",
    "Postcode": 37115,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5527941535,
    "24 hour?": "FALSE",
    "Latitude": 51.516367,
    "Longitude": 10.242981,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.516367,10.242981",
    "IsOpen24Hours": false,
    "id": "51.516367,10.242981"
  },
  {
    "ID": 1317,
    "Tankstellennummer": "0F410",
    "Name": "Aral Tankstelle Oberhausen, Teutoburger Str. 1-3",
    "Address": "Teutoburger Straße 1-3",
    "Address__1": "",
    "City": "Oberhausen",
    "Postcode": 46145,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 208667060,
    "24 hour?": "TRUE",
    "Latitude": 51.516623,
    "Longitude": 6.857246,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.516623,6.857246",
    "IsOpen24Hours": true,
    "id": "51.516623,6.857246"
  },
  {
    "ID": 3478,
    "Tankstellennummer": "0FM10",
    "Name": "Aral Tankstelle Essen, Karnaper Str. 44",
    "Address": "Karnaper Straße 44",
    "Address__1": "",
    "City": "Essen",
    "Postcode": 45329,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2014366875,
    "24 hour?": "TRUE",
    "Latitude": 51.51737,
    "Longitude": 7.007558,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.51737,7.007558",
    "IsOpen24Hours": true,
    "id": "51.51737,7.007558"
  },
  {
    "ID": 313520874,
    "Tankstellennummer": "TD034538",
    "Name": "HALLE TROTHAER STRASSE 40",
    "Address": "TROTHAER STRASSE 40",
    "Address__1": "",
    "City": "HALLE",
    "Postcode": 6118,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0345-5234122",
    "24 hour?": "FALSE",
    "Latitude": 51.5192,
    "Longitude": 11.955,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5192,11.955",
    "IsOpen24Hours": false,
    "id": "51.5192,11.955"
  },
  {
    "ID": 3253,
    "Tankstellennummer": "0FK27",
    "Name": "Aral Tankstelle Gelsenkirchen, Overwegstr. 72",
    "Address": "Gewerkenstraße 63",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45881,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2094082034,
    "24 hour?": "TRUE",
    "Latitude": 51.519656,
    "Longitude": 7.081889,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.519656,7.081889",
    "IsOpen24Hours": true,
    "id": "51.519656,7.081889"
  },
  {
    "ID": 3341,
    "Tankstellennummer": "0FK92",
    "Name": "Aral Tankstelle Dortmund, Baerenbruch 135",
    "Address": "Baerenbruch 135",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44379,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2316102302,
    "24 hour?": "TRUE",
    "Latitude": 51.519744,
    "Longitude": 7.372563,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.519744,7.372563",
    "IsOpen24Hours": true,
    "id": "51.519744,7.372563"
  },
  {
    "ID": 372008017,
    "Tankstellennummer": 610622,
    "Name": "WESTFALEN, DORTMUND",
    "Address": "Bornstr. 31-33",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44145,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0231/812233",
    "24 hour?": "FALSE",
    "Latitude": 51.5198,
    "Longitude": 7.47013,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5198,7.47013",
    "IsOpen24Hours": false,
    "id": "51.5198,7.47013"
  },
  {
    "ID": 1343,
    "Tankstellennummer": "0F428",
    "Name": "Aral Tankstelle Bottrop, Friedrich-Ebert-Str. 113",
    "Address": "Friedrich-Ebert-Str. 113",
    "Address__1": "",
    "City": "Bottrop",
    "Postcode": 46236,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 204122389,
    "24 hour?": "TRUE",
    "Latitude": 51.519913,
    "Longitude": 6.931138,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.519913,6.931138",
    "IsOpen24Hours": true,
    "id": "51.519913,6.931138"
  },
  {
    "ID": 1711,
    "Tankstellennummer": "0F706",
    "Name": "Aral Tankstelle Göttingen, Reinhäuser Landstr. 108",
    "Address": "Reinhäuser Landstraße 108",
    "Address__1": "",
    "City": "Göttingen",
    "Postcode": 37083,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 55131705,
    "24 hour?": "FALSE",
    "Latitude": 51.520218,
    "Longitude": 9.940411,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.520218,9.940411",
    "IsOpen24Hours": false,
    "id": "51.520218,9.940411"
  },
  {
    "ID": 644599049,
    "Tankstellennummer": "TD000766",
    "Name": "GELSENKIRCHEN GROTHUSSTR. 4",
    "Address": "GROTHUSSTR. 4",
    "Address__1": "",
    "City": "GELSENKIRCHEN",
    "Postcode": 45881,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0209-498552",
    "24 hour?": "FALSE",
    "Latitude": 51.5203,
    "Longitude": 7.0788,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5203,7.0788",
    "IsOpen24Hours": false,
    "id": "51.5203,7.0788"
  },
  {
    "ID": 1194,
    "Tankstellennummer": "0F308",
    "Name": "Aral Tankstelle Dortmund, Wambeler Hellweg 129",
    "Address": "Wambeler Hellweg 129",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44143,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 231597276,
    "24 hour?": "FALSE",
    "Latitude": 51.520784,
    "Longitude": 7.523512,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.520784,7.523512",
    "IsOpen24Hours": false,
    "id": "51.520784,7.523512"
  },
  {
    "ID": 1346,
    "Tankstellennummer": "0F431",
    "Name": "Aral Tankstelle Oberhausen, Siegesstr. 140",
    "Address": "Siegesstraße 140",
    "Address__1": "",
    "City": "Oberhausen",
    "Postcode": 46147,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 208688849,
    "24 hour?": "FALSE",
    "Latitude": 51.521149,
    "Longitude": 6.787809,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.521149,6.787809",
    "IsOpen24Hours": false,
    "id": "51.521149,6.787809"
  },
  {
    "ID": 2646,
    "Tankstellennummer": "0FD70",
    "Name": "Aral Tankstelle Halle, Magdeburger Chaussee 2",
    "Address": "Magdeburger Chaussee 2",
    "Address__1": "",
    "City": "Halle",
    "Postcode": 6118,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3455210836,
    "24 hour?": "TRUE",
    "Latitude": 51.521878,
    "Longitude": 11.953743,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.521878,11.953743",
    "IsOpen24Hours": true,
    "id": "51.521878,11.953743"
  },
  {
    "ID": 1302,
    "Tankstellennummer": "0F401",
    "Name": "Aral Tankstelle Bottrop, Ostring 201",
    "Address": "Ostring 201",
    "Address__1": "",
    "City": "Bottrop",
    "Postcode": 46238,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 204163364,
    "24 hour?": "FALSE",
    "Latitude": 51.521896,
    "Longitude": 6.952606,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.521896,6.952606",
    "IsOpen24Hours": false,
    "id": "51.521896,6.952606"
  },
  {
    "ID": 651637069,
    "Tankstellennummer": 5007,
    "Name": "Agip Weisswasser",
    "Address": "Halbendorfer Weg 9",
    "Address__1": "",
    "City": "Weisswasser",
    "Postcode": 2943,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 35762053",
    "24 hour?": "FALSE",
    "Latitude": 51.52204305,
    "Longitude": 14.60600898,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.52204305,14.60600898",
    "IsOpen24Hours": false,
    "id": "51.52204305,14.60600898"
  },
  {
    "ID": 746823776,
    "Tankstellennummer": "TD038851",
    "Name": "BOTTROP PROSPERSTR. 440",
    "Address": "PROSPERSTR. 440",
    "Address__1": "",
    "City": "BOTTROP",
    "Postcode": 46238,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02041-7763253",
    "24 hour?": "FALSE",
    "Latitude": 51.5222,
    "Longitude": 6.9844,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5222,6.9844",
    "IsOpen24Hours": false,
    "id": "51.5222,6.9844"
  },
  {
    "ID": 769597286,
    "Tankstellennummer": "TD005140",
    "Name": "HERNE EICKELER BRUCH 143",
    "Address": "EICKELER BRUCH 143",
    "Address__1": "",
    "City": "HERNE",
    "Postcode": 44652,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02325-33436",
    "24 hour?": "FALSE",
    "Latitude": 51.5222,
    "Longitude": 7.1599,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5222,7.1599",
    "IsOpen24Hours": false,
    "id": "51.5222,7.1599"
  },
  {
    "ID": 1238,
    "Tankstellennummer": "0F345",
    "Name": "Aral Tankstelle Dortmund, Brackeler Hellweg 160",
    "Address": "Brackeler Hellweg 160",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44309,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 231257429,
    "24 hour?": "FALSE",
    "Latitude": 51.52399,
    "Longitude": 7.551552,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.52399,7.551552",
    "IsOpen24Hours": false,
    "id": "51.52399,7.551552"
  },
  {
    "ID": 1890532409,
    "Tankstellennummer": "TD124360",
    "Name": "DORTMUND HANNOEVERSCHE STR.52A",
    "Address": "HANNOEVERSCHE STR.52A",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": 44143,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0231-5315877",
    "24 hour?": "FALSE",
    "Latitude": 51.5245,
    "Longitude": 7.511,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5245,7.511",
    "IsOpen24Hours": false,
    "id": "51.5245,7.511"
  },
  {
    "ID": 773212777,
    "Tankstellennummer": 212053,
    "Name": "Dortmund",
    "Address": "Hannoversche Str. 107",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": 44143,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.52475,
    "Longitude": 7.51404,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.52475,7.51404",
    "IsOpen24Hours": false,
    "id": "51.52475,7.51404"
  },
  {
    "ID": 1814854600,
    "Tankstellennummer": "TD037655",
    "Name": "EISLEBEN KASSELER STRASSE 49",
    "Address": "KASSELER STRASSE 49",
    "Address__1": "",
    "City": "EISLEBEN",
    "Postcode": 6295,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03475-603810",
    "24 hour?": "FALSE",
    "Latitude": 51.5249,
    "Longitude": 11.5278,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5249,11.5278",
    "IsOpen24Hours": false,
    "id": "51.5249,11.5278"
  },
  {
    "ID": 848301151,
    "Tankstellennummer": "TD110090",
    "Name": "UNNA ISERLOHNER STR.45",
    "Address": "ISERLOHNER STR.45",
    "Address__1": "",
    "City": "UNNA",
    "Postcode": 59423,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02303-80000",
    "24 hour?": "FALSE",
    "Latitude": 51.5268,
    "Longitude": 7.6949,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5268,7.6949",
    "IsOpen24Hours": false,
    "id": "51.5268,7.6949"
  },
  {
    "ID": 576848210,
    "Tankstellennummer": 610633,
    "Name": "WESTFALEN, GELSENKIRCHEN",
    "Address": "Zum Bauverein 44",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45899,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0171/38151327",
    "24 hour?": "TRUE",
    "Latitude": 51.5269,
    "Longitude": 7.01836,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.5269,7.01836",
    "IsOpen24Hours": true,
    "id": "51.5269,7.01836"
  },
  {
    "ID": 1908591932,
    "Tankstellennummer": "TD123870",
    "Name": "DUISBURG DR.-WILH.-ROELEN-STR",
    "Address": "DR.-WILH.-ROELEN-STR 357 a",
    "Address__1": "",
    "City": "DUISBURG",
    "Postcode": 47179,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0203-493735",
    "24 hour?": "FALSE",
    "Latitude": 51.5272,
    "Longitude": 6.7359,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5272,6.7359",
    "IsOpen24Hours": false,
    "id": "51.5272,6.7359"
  },
  {
    "ID": 2033315549,
    "Tankstellennummer": "TD124170",
    "Name": "HERNE DORSTENER STR. 203",
    "Address": "DORSTENER STR. 203",
    "Address__1": "",
    "City": "HERNE",
    "Postcode": 44652,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02325-910170",
    "24 hour?": "FALSE",
    "Latitude": 51.5274,
    "Longitude": 7.1875,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5274,7.1875",
    "IsOpen24Hours": false,
    "id": "51.5274,7.1875"
  },
  {
    "ID": 2522,
    "Tankstellennummer": "0FC63",
    "Name": "Aral Tankstelle Senftenberg, Laugkfeld 30",
    "Address": "Laugkfeld 30",
    "Address__1": "",
    "City": "Senftenberg",
    "Postcode": 1968,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3573794952,
    "24 hour?": "FALSE",
    "Latitude": 51.527646,
    "Longitude": 14.011302,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.527646,14.011302",
    "IsOpen24Hours": false,
    "id": "51.527646,14.011302"
  },
  {
    "ID": 512984414,
    "Tankstellennummer": "TD110110",
    "Name": "DORTMUND ASSELNER HELLWEG 75",
    "Address": "ASSELNER HELLWEG 75",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": 44319,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0231-818218",
    "24 hour?": "FALSE",
    "Latitude": 51.5277,
    "Longitude": 7.5795,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5277,7.5795",
    "IsOpen24Hours": false,
    "id": "51.5277,7.5795"
  },
  {
    "ID": 1390231216,
    "Tankstellennummer": "TD111850",
    "Name": "DORTMUND FLUGHAFENSTR.71",
    "Address": "FLUGHAFENSTR.71",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": 44309,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0231-5331677",
    "24 hour?": "FALSE",
    "Latitude": 51.528,
    "Longitude": 7.546,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.528,7.546",
    "IsOpen24Hours": false,
    "id": "51.528,7.546"
  },
  {
    "ID": 3273,
    "Tankstellennummer": "0FK42",
    "Name": "Aral Tankstelle Oberhausen, Bahnstr. 131",
    "Address": "Bahnstraße 131",
    "Address__1": "",
    "City": "Oberhausen",
    "Postcode": 46147,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 208680549,
    "24 hour?": "FALSE",
    "Latitude": 51.529583,
    "Longitude": 6.797949,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.529583,6.797949",
    "IsOpen24Hours": false,
    "id": "51.529583,6.797949"
  },
  {
    "ID": 1297,
    "Tankstellennummer": "0F397",
    "Name": "Aral Tankstelle Bottrop, Hans-Sachs-Str. 64",
    "Address": "Hans-Sachs-Straße 64",
    "Address__1": "",
    "City": "Bottrop",
    "Postcode": 46236,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 20416505,
    "24 hour?": "TRUE",
    "Latitude": 51.530323,
    "Longitude": 6.924594,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.530323,6.924594",
    "IsOpen24Hours": true,
    "id": "51.530323,6.924594"
  },
  {
    "ID": 549798847,
    "Tankstellennummer": "0FV27",
    "Name": "DORTMUND",
    "Address": "Schäferstrasse 51",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44147,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23118983645,
    "24 hour?": "FALSE",
    "Latitude": 51.531412,
    "Longitude": 7.440737,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.531412,7.440737",
    "IsOpen24Hours": false,
    "id": "51.531412,7.440737"
  },
  {
    "ID": 838672444,
    "Tankstellennummer": 610549,
    "Name": "WESTFALEN, HERNE",
    "Address": "Holsterhauser Str. 214",
    "Address__1": "",
    "City": "Herne",
    "Postcode": 44625,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02325/9760714",
    "24 hour?": "TRUE",
    "Latitude": 51.5316,
    "Longitude": 7.20546,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.5316,7.20546",
    "IsOpen24Hours": true,
    "id": "51.5316,7.20546"
  },
  {
    "ID": 505847451,
    "Tankstellennummer": "TD037549",
    "Name": "UNNA FELDSTR. 7",
    "Address": "FELDSTR. 7",
    "Address__1": "",
    "City": "UNNA",
    "Postcode": 59423,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02303-15031",
    "24 hour?": "FALSE",
    "Latitude": 51.5318,
    "Longitude": 7.6715,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5318,7.6715",
    "IsOpen24Hours": false,
    "id": "51.5318,7.6715"
  },
  {
    "ID": 1673661865,
    "Tankstellennummer": "TD007542",
    "Name": "DELITZSCH DUEBENER STR. 133",
    "Address": "DUEBENER STR. 133",
    "Address__1": "",
    "City": "DELITZSCH",
    "Postcode": 4509,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034202/969969",
    "24 hour?": "FALSE",
    "Latitude": 51.5319,
    "Longitude": 12.3573,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5319,12.3573",
    "IsOpen24Hours": false,
    "id": "51.5319,12.3573"
  },
  {
    "ID": 2304,
    "Tankstellennummer": "0FE72",
    "Name": "Aral Tankstelle Unna, Bundesstr. 1",
    "Address": "Bundesstraße 1",
    "Address__1": "",
    "City": "Unna",
    "Postcode": 59423,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2303772435,
    "24 hour?": "TRUE",
    "Latitude": 51.532106,
    "Longitude": 7.701102,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.532106,7.701102",
    "IsOpen24Hours": true,
    "id": "51.532106,7.701102"
  },
  {
    "ID": 3309,
    "Tankstellennummer": "0FK66",
    "Name": "Aral Tankstelle Gelsenkirchen, Kurt-Schumacher-Str. 155",
    "Address": "Kurt-Schumacher-Straße155",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45881,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2099443052,
    "24 hour?": "TRUE",
    "Latitude": 51.532884,
    "Longitude": 7.077537,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.532884,7.077537",
    "IsOpen24Hours": true,
    "id": "51.532884,7.077537"
  },
  {
    "ID": 1308,
    "Tankstellennummer": "0F404",
    "Name": "Aral Tankstelle Herne, Holsterhauser Str. 301",
    "Address": "Holsterhauser Straße 301",
    "Address__1": "",
    "City": "Herne",
    "Postcode": 44625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 232354548,
    "24 hour?": "TRUE",
    "Latitude": 51.533059,
    "Longitude": 7.214134,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.533059,7.214134",
    "IsOpen24Hours": true,
    "id": "51.533059,7.214134"
  },
  {
    "ID": 1270,
    "Tankstellennummer": "0F371",
    "Name": "Aral Tankstelle Gelsenkirchen, Bismarckstr. 280",
    "Address": "Bismarckstraße 280",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45889,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 20985906,
    "24 hour?": "TRUE",
    "Latitude": 51.533143,
    "Longitude": 7.110759,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.533143,7.110759",
    "IsOpen24Hours": true,
    "id": "51.533143,7.110759"
  },
  {
    "ID": 1286,
    "Tankstellennummer": "0F386",
    "Name": "Aral Tankstelle Herne, Hölkeskampring 109",
    "Address": "Hölkeskampring 109",
    "Address__1": "",
    "City": "Herne",
    "Postcode": 44625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 232340037,
    "24 hour?": "FALSE",
    "Latitude": 51.533312,
    "Longitude": 7.234871,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.533312,7.234871",
    "IsOpen24Hours": false,
    "id": "51.533312,7.234871"
  },
  {
    "ID": 204356277,
    "Tankstellennummer": "0FP90",
    "Name": "WERL",
    "Address": "A44",
    "Address__1": "",
    "City": "Werl",
    "Postcode": 59457,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 29225299,
    "24 hour?": "TRUE",
    "Latitude": 51.533429,
    "Longitude": 7.863355,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.533429,7.863355",
    "IsOpen24Hours": true,
    "id": "51.533429,7.863355"
  },
  {
    "ID": 224326044,
    "Tankstellennummer": "TD038869",
    "Name": "RHEINBERG MINKELDONK 1.",
    "Address": "MINKELDONK 1",
    "Address__1": "",
    "City": "RHEINBERG",
    "Postcode": 47495,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02843-9596456",
    "24 hour?": "FALSE",
    "Latitude": 51.534,
    "Longitude": 6.5809,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.534,6.5809",
    "IsOpen24Hours": false,
    "id": "51.534,6.5809"
  },
  {
    "ID": 1379094727,
    "Tankstellennummer": 610707,
    "Name": "WESTFALEN, BOTTROP",
    "Address": "Wilhelm-Tenhagen-Str. 1",
    "Address__1": "",
    "City": "Bottrop",
    "Postcode": 46240,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02041/700865",
    "24 hour?": "TRUE",
    "Latitude": 51.5357,
    "Longitude": 6.97877,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.5357,6.97877",
    "IsOpen24Hours": true,
    "id": "51.5357,6.97877"
  },
  {
    "ID": 1047736734,
    "Tankstellennummer": "TD005579",
    "Name": "DORTMUND BORNSTR.266",
    "Address": "BORNSTR.266",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": 44145,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0231-818218",
    "24 hour?": "FALSE",
    "Latitude": 51.5357,
    "Longitude": 7.4745,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5357,7.4745",
    "IsOpen24Hours": false,
    "id": "51.5357,7.4745"
  },
  {
    "ID": 594464156,
    "Tankstellennummer": "TD122070",
    "Name": "HERNE KARLSTR. 44",
    "Address": "KARLSTR. 44",
    "Address__1": "",
    "City": "HERNE",
    "Postcode": 44649,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02325-977166",
    "24 hour?": "FALSE",
    "Latitude": 51.5371,
    "Longitude": 7.1492,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5371,7.1492",
    "IsOpen24Hours": false,
    "id": "51.5371,7.1492"
  },
  {
    "ID": 770721200,
    "Tankstellennummer": 610542,
    "Name": "WESTFALEN, DORTMUND",
    "Address": "Dollersweg 103",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44319,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0231/211796",
    "24 hour?": "FALSE",
    "Latitude": 51.5376,
    "Longitude": 7.62727,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5376,7.62727",
    "IsOpen24Hours": false,
    "id": "51.5376,7.62727"
  },
  {
    "ID": 1278,
    "Tankstellennummer": "0F379",
    "Name": "Aral Tankstelle Issum, Weseler Str. 90",
    "Address": "Weseler Straße 90",
    "Address__1": "",
    "City": "Issum",
    "Postcode": 47661,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 28353864,
    "24 hour?": "FALSE",
    "Latitude": 51.540248,
    "Longitude": 6.437934,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.540248,6.437934",
    "IsOpen24Hours": false,
    "id": "51.540248,6.437934"
  },
  {
    "ID": 2905,
    "Tankstellennummer": "0FH71",
    "Name": "Aral Tankstelle Unna, Hansastr. 145",
    "Address": "Hansastraße 145",
    "Address__1": "",
    "City": "Unna",
    "Postcode": 59427,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2303591025,
    "24 hour?": "FALSE",
    "Latitude": 51.54028,
    "Longitude": 7.655711,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.54028,7.655711",
    "IsOpen24Hours": false,
    "id": "51.54028,7.655711"
  },
  {
    "ID": 1191,
    "Tankstellennummer": "0F306",
    "Name": "Aral Tankstelle Castrop-Rauxel, Wittener Straße 95 A",
    "Address": "Wittener Straße 95",
    "Address__1": "",
    "City": "Castrop-Rauxel",
    "Postcode": 44575,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 230523098,
    "24 hour?": "FALSE",
    "Latitude": 51.543495,
    "Longitude": 7.317006,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.543495,7.317006",
    "IsOpen24Hours": false,
    "id": "51.543495,7.317006"
  },
  {
    "ID": 1712,
    "Tankstellennummer": "0F707",
    "Name": "Aral Tankstelle Göttingen, Weender Landstr. 62A",
    "Address": "Weender Landstraße 62A",
    "Address__1": "",
    "City": "Göttingen",
    "Postcode": 37075,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 55131444,
    "24 hour?": "TRUE",
    "Latitude": 51.544402,
    "Longitude": 9.932306,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.544402,9.932306",
    "IsOpen24Hours": true,
    "id": "51.544402,9.932306"
  },
  {
    "ID": 564997527,
    "Tankstellennummer": "TD038067",
    "Name": "LANDSBERG AM AUTOHOF 1",
    "Address": "AM AUTOHOF 1",
    "Address__1": "",
    "City": "LANDSBERG",
    "Postcode": 6188,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034604/24690",
    "24 hour?": "FALSE",
    "Latitude": 51.5448,
    "Longitude": 12.0143,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5448,12.0143",
    "IsOpen24Hours": false,
    "id": "51.5448,12.0143"
  },
  {
    "ID": 208798888,
    "Tankstellennummer": 610647,
    "Name": "WESTFALEN, OBERHAUSEN",
    "Address": "Buchenweg 107",
    "Address__1": "",
    "City": "Oberhausen",
    "Postcode": 46147,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0208/672808",
    "24 hour?": "FALSE",
    "Latitude": 51.5455,
    "Longitude": 6.82915,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5455,6.82915",
    "IsOpen24Hours": false,
    "id": "51.5455,6.82915"
  },
  {
    "ID": 1333,
    "Tankstellennummer": "0F424",
    "Name": "Aral Tankstelle Gladbeck, Horster Str. 370",
    "Address": "Horster Straße 370",
    "Address__1": "",
    "City": "Gladbeck",
    "Postcode": 45968,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 204334859,
    "24 hour?": "FALSE",
    "Latitude": 51.546558,
    "Longitude": 7.012883,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.546558,7.012883",
    "IsOpen24Hours": false,
    "id": "51.546558,7.012883"
  },
  {
    "ID": 3320,
    "Tankstellennummer": "0FK75",
    "Name": "Aral Tankstelle Oberhausen, Hoehenweg 23-25",
    "Address": "Hoehenweg 23-25",
    "Address__1": "",
    "City": "Oberhausen",
    "Postcode": 46147,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 208627088,
    "24 hour?": "TRUE",
    "Latitude": 51.548381,
    "Longitude": 6.851153,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.548381,6.851153",
    "IsOpen24Hours": true,
    "id": "51.548381,6.851153"
  },
  {
    "ID": 293595840,
    "Tankstellennummer": "TD033993",
    "Name": "BUEREN FUERSTENBERGER STR. 1-3",
    "Address": "FUERSTENBERGER STR. 1-3",
    "Address__1": "",
    "City": "BUEREN",
    "Postcode": 33142,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02951-2902",
    "24 hour?": "FALSE",
    "Latitude": 51.5484,
    "Longitude": 8.5638,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5484,8.5638",
    "IsOpen24Hours": false,
    "id": "51.5484,8.5638"
  },
  {
    "ID": 1639,
    "Tankstellennummer": "0F662",
    "Name": "Aral Tankstelle Göttingen, Königsallee 245",
    "Address": "Königsallee 245",
    "Address__1": "",
    "City": "Göttingen",
    "Postcode": 37079,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 55162445,
    "24 hour?": "TRUE",
    "Latitude": 51.548755,
    "Longitude": 9.909477,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.548755,9.909477",
    "IsOpen24Hours": true,
    "id": "51.548755,9.909477"
  },
  {
    "ID": 1328,
    "Tankstellennummer": "0F420",
    "Name": "Aral Tankstelle Dortmund, Flughafenstr. 425",
    "Address": "Flughafenstraße 425",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44328,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 231235201,
    "24 hour?": "FALSE",
    "Latitude": 51.549128,
    "Longitude": 7.538585,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.549128,7.538585",
    "IsOpen24Hours": false,
    "id": "51.549128,7.538585"
  },
  {
    "ID": 948813441,
    "Tankstellennummer": "TD000113",
    "Name": "DINSLAKEN WILLY-BRANDT-STR. 20",
    "Address": "WILLY-BRANDT-STR. 206",
    "Address__1": "",
    "City": "DINSLAKEN",
    "Postcode": 46535,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02064-732480",
    "24 hour?": "FALSE",
    "Latitude": 51.5496,
    "Longitude": 6.7289,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5496,6.7289",
    "IsOpen24Hours": false,
    "id": "51.5496,6.7289"
  },
  {
    "ID": 683888751,
    "Tankstellennummer": 610465,
    "Name": "WESTFALEN, WERL",
    "Address": "Soester Str. 43",
    "Address__1": "",
    "City": "Werl",
    "Postcode": 59457,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02922/7810",
    "24 hour?": "FALSE",
    "Latitude": 51.5496,
    "Longitude": 7.92261,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5496,7.92261",
    "IsOpen24Hours": false,
    "id": "51.5496,7.92261"
  },
  {
    "ID": 1360,
    "Tankstellennummer": "0F445",
    "Name": "Aral Autohof Soest, Overweg 33/ B 475",
    "Address": "Overweg 33",
    "Address__1": "",
    "City": "Soest",
    "Postcode": 59494,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2921944064,
    "24 hour?": "TRUE",
    "Latitude": 51.553549,
    "Longitude": 8.158549,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.553549,8.158549",
    "IsOpen24Hours": true,
    "id": "51.553549,8.158549"
  },
  {
    "ID": 2680,
    "Tankstellennummer": "0FE02",
    "Name": "Aral Tankstelle Brehna, B 100",
    "Address": "An der B 100 32",
    "Address__1": "",
    "City": "Brehna",
    "Postcode": 6796,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3495448837,
    "24 hour?": "FALSE",
    "Latitude": 51.553736,
    "Longitude": 12.196659,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.553736,12.196659",
    "IsOpen24Hours": false,
    "id": "51.553736,12.196659"
  },
  {
    "ID": 1655,
    "Tankstellennummer": "0F668",
    "Name": "Aral Tankstelle Göttingen, Hannoversche Str. 43-47",
    "Address": "Hannoversche Str. 45",
    "Address__1": "",
    "City": "Göttingen",
    "Postcode": 37075,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 55132518,
    "24 hour?": "TRUE",
    "Latitude": 51.554009,
    "Longitude": 9.930994,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.554009,9.930994",
    "IsOpen24Hours": true,
    "id": "51.554009,9.930994"
  },
  {
    "ID": 2525,
    "Tankstellennummer": "0FC66",
    "Name": "Aral Tankstelle Torgau, Dahlener Str. 3",
    "Address": "Dahlener Straße 3",
    "Address__1": "",
    "City": "Torgau",
    "Postcode": 4860,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3421712851,
    "24 hour?": "TRUE",
    "Latitude": 51.555118,
    "Longitude": 13.000817,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.555118,13.000817",
    "IsOpen24Hours": true,
    "id": "51.555118,13.000817"
  },
  {
    "ID": 1013153422,
    "Tankstellennummer": 610344,
    "Name": "WESTFALEN, DINSLAKEN",
    "Address": "Kurt-Schumacher-Str. 236",
    "Address__1": "",
    "City": "Dinslaken",
    "Postcode": 46539,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02064/606483",
    "24 hour?": "TRUE",
    "Latitude": 51.5575,
    "Longitude": 6.79647,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.5575,6.79647",
    "IsOpen24Hours": true,
    "id": "51.5575,6.79647"
  },
  {
    "ID": 222237635,
    "Tankstellennummer": "TD041061",
    "Name": "DROCHOW BAB FREIENHUFENER ECK-",
    "Address": "FREIENHUFENER ECK-OST/ A13",
    "Address__1": "",
    "City": "DROCHOW",
    "Postcode": 1994,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 357543650,
    "24 hour?": "FALSE",
    "Latitude": 51.5586,
    "Longitude": 13.9203,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5586,13.9203",
    "IsOpen24Hours": false,
    "id": "51.5586,13.9203"
  },
  {
    "ID": 2893,
    "Tankstellennummer": "0FH60",
    "Name": "Aral Tankstelle Dortmund, Mengeder Str. 432",
    "Address": "Mengeder Straße 432",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44359,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 231350380,
    "24 hour?": "FALSE",
    "Latitude": 51.559425,
    "Longitude": 7.397122,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.559425,7.397122",
    "IsOpen24Hours": false,
    "id": "51.559425,7.397122"
  },
  {
    "ID": 19006850,
    "Tankstellennummer": "TD110910",
    "Name": "HERNE HORSTHAUSER STR.213",
    "Address": "HORSTHAUSER STR.213",
    "Address__1": "",
    "City": "HERNE",
    "Postcode": 44628,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02323-8603",
    "24 hour?": "FALSE",
    "Latitude": 51.5608,
    "Longitude": 7.2312,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5608,7.2312",
    "IsOpen24Hours": false,
    "id": "51.5608,7.2312"
  },
  {
    "ID": 2773,
    "Tankstellennummer": "0FE65",
    "Name": "Aral Tankstelle Torgau, Außenring 8",
    "Address": "Außenring 8",
    "Address__1": "",
    "City": "Torgau",
    "Postcode": 4860,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3421711855,
    "24 hour?": "TRUE",
    "Latitude": 51.561306,
    "Longitude": 12.973615,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.561306,12.973615",
    "IsOpen24Hours": true,
    "id": "51.561306,12.973615"
  },
  {
    "ID": 1123242862,
    "Tankstellennummer": 610349,
    "Name": "WESTFALEN, GELSENKIRCHEN",
    "Address": "Horster Str. 189",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45897,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0209/591634",
    "24 hour?": "FALSE",
    "Latitude": 51.5614,
    "Longitude": 7.04644,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5614,7.04644",
    "IsOpen24Hours": false,
    "id": "51.5614,7.04644"
  },
  {
    "ID": 3448,
    "Tankstellennummer": "0FL75",
    "Name": "Aral Tankstelle Gelsenkirchen, Cranger Str. 243",
    "Address": "Cranger Straße 243",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45891,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 20975561,
    "24 hour?": "TRUE",
    "Latitude": 51.562224,
    "Longitude": 7.087587,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.562224,7.087587",
    "IsOpen24Hours": true,
    "id": "51.562224,7.087587"
  },
  {
    "ID": 2285,
    "Tankstellennummer": "0FB09",
    "Name": "Aral Tankstelle Soest, Wisbyring 15",
    "Address": "Wisbyring 15",
    "Address__1": "",
    "City": "Soest",
    "Postcode": 59494,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 292173330,
    "24 hour?": "FALSE",
    "Latitude": 51.562734,
    "Longitude": 8.102926,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.562734,8.102926",
    "IsOpen24Hours": false,
    "id": "51.562734,8.102926"
  },
  {
    "ID": 752399770,
    "Tankstellennummer": 610350,
    "Name": "WESTFALEN, GELSENKIRCHEN",
    "Address": "Cranger Str. 215",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45891,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0209/97735077",
    "24 hour?": "FALSE",
    "Latitude": 51.564,
    "Longitude": 7.0848,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.564,7.0848",
    "IsOpen24Hours": false,
    "id": "51.564,7.0848"
  },
  {
    "ID": 966787749,
    "Tankstellennummer": "TD110830",
    "Name": "GLADBECK BOTTROPER STR. 248",
    "Address": "BOTTROPER STR. 248",
    "Address__1": "",
    "City": "GLADBECK",
    "Postcode": 45964,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02043-489074",
    "24 hour?": "FALSE",
    "Latitude": 51.5662,
    "Longitude": 6.9647,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5662,6.9647",
    "IsOpen24Hours": false,
    "id": "51.5662,6.9647"
  },
  {
    "ID": 1210,
    "Tankstellennummer": "0F321",
    "Name": "Aral Tankstelle Recklinghausen, Bochumer Str. 173",
    "Address": "Bochumer Straße 173",
    "Address__1": "",
    "City": "Recklinghausen",
    "Postcode": 45661,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 236163663,
    "24 hour?": "TRUE",
    "Latitude": 51.5676,
    "Longitude": 7.21187,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.5676,7.21187",
    "IsOpen24Hours": true,
    "id": "51.5676,7.21187"
  },
  {
    "ID": 282979435,
    "Tankstellennummer": "TD000946",
    "Name": "DORTMUND ALTENDERNER STR. 90",
    "Address": "ALTENDERNER STR.90",
    "Address__1": "",
    "City": "DORTMUND",
    "Postcode": 44329,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0231-7289051",
    "24 hour?": "FALSE",
    "Latitude": 51.5693,
    "Longitude": 7.5114,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5693,7.5114",
    "IsOpen24Hours": false,
    "id": "51.5693,7.5114"
  },
  {
    "ID": 1476329844,
    "Tankstellennummer": 900690,
    "Name": "WESTFALEN, BAT RESSER MARK NORD GELSENKIRCHEN",
    "Address": "BAT-Resser Mark/ Nordseite",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45892,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0209/ 177280",
    "24 hour?": "TRUE",
    "Latitude": 51.5706,
    "Longitude": 7.1026,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.5706,7.1026",
    "IsOpen24Hours": true,
    "id": "51.5706,7.1026"
  },
  {
    "ID": 33765999,
    "Tankstellennummer": "0FY30",
    "Name": "SCHIPKAU",
    "Address": "A13",
    "Address__1": "",
    "City": "Schipkau",
    "Postcode": 1994,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 35754737413,
    "24 hour?": "TRUE",
    "Latitude": 51.5707602,
    "Longitude": 13.9296646,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.5707602,13.9296646",
    "IsOpen24Hours": true,
    "id": "51.5707602,13.9296646"
  },
  {
    "ID": 1087291024,
    "Tankstellennummer": 610070,
    "Name": "WESTFALEN, BOTTROP",
    "Address": "Bottroper Str. 184",
    "Address__1": "",
    "City": "Bottrop",
    "Postcode": 46244,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02045/3301",
    "24 hour?": "TRUE",
    "Latitude": 51.5709,
    "Longitude": 6.91065,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.5709,6.91065",
    "IsOpen24Hours": true,
    "id": "51.5709,6.91065"
  },
  {
    "ID": 1372532858,
    "Tankstellennummer": "TD000960",
    "Name": "GELSENKIRCHEN HORSTER STR. 69",
    "Address": "HORSTER STR. 69",
    "Address__1": "",
    "City": "GELSENKIRCHEN",
    "Postcode": 45897,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0209-594033",
    "24 hour?": "FALSE",
    "Latitude": 51.5735,
    "Longitude": 7.048,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5735,7.048",
    "IsOpen24Hours": false,
    "id": "51.5735,7.048"
  },
  {
    "ID": 1206,
    "Tankstellennummer": "0F318",
    "Name": "Aral Tankstelle Dortmund, Schaphusstr. 9",
    "Address": "Schaphusstraße 9",
    "Address__1": "",
    "City": "Dortmund",
    "Postcode": 44359,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 231333191,
    "24 hour?": "TRUE",
    "Latitude": 51.573748,
    "Longitude": 7.387133,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.573748,7.387133",
    "IsOpen24Hours": true,
    "id": "51.573748,7.387133"
  },
  {
    "ID": 2876,
    "Tankstellennummer": "0FH44",
    "Name": "Aral Tankstelle Gelsenkirchen, Vom-Stein-Str. 40",
    "Address": "Vom-Stein-Straße 40",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45894,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 20932900,
    "24 hour?": "TRUE",
    "Latitude": 51.574377,
    "Longitude": 7.060192,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.574377,7.060192",
    "IsOpen24Hours": true,
    "id": "51.574377,7.060192"
  },
  {
    "ID": 1252,
    "Tankstellennummer": "0F356",
    "Name": "Aral Tankstelle Gladbeck, Hermannstr. 100",
    "Address": "Hermannstraße 100",
    "Address__1": "",
    "City": "Gladbeck",
    "Postcode": 45964,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 204325991,
    "24 hour?": "TRUE",
    "Latitude": 51.574673,
    "Longitude": 6.982006,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.574673,6.982006",
    "IsOpen24Hours": true,
    "id": "51.574673,6.982006"
  },
  {
    "ID": 1295,
    "Tankstellennummer": "0F395",
    "Name": "Aral Tankstelle Gelsenkirchen, Ewaldstr. 81",
    "Address": "Ewaldstraße 81",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45892,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 20975527,
    "24 hour?": "FALSE",
    "Latitude": 51.575117,
    "Longitude": 7.116441,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.575117,7.116441",
    "IsOpen24Hours": false,
    "id": "51.575117,7.116441"
  },
  {
    "ID": 517468342,
    "Tankstellennummer": "TD000336",
    "Name": "GLADBECK SANDSTR. 187",
    "Address": "SANDSTR. 187",
    "Address__1": "",
    "City": "GLADBECK",
    "Postcode": 45966,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02043-42772",
    "24 hour?": "FALSE",
    "Latitude": 51.5769,
    "Longitude": 6.968,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5769,6.968",
    "IsOpen24Hours": false,
    "id": "51.5769,6.968"
  },
  {
    "ID": 1620197303,
    "Tankstellennummer": "TD007278",
    "Name": "ELLRICH GOECKINGKSTR. 38",
    "Address": "GOECKINGKSTR.",
    "Address__1": "",
    "City": "ELLRICH",
    "Postcode": 99755,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "036332-20236",
    "24 hour?": "FALSE",
    "Latitude": 51.5799,
    "Longitude": 10.6774,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5799,10.6774",
    "IsOpen24Hours": false,
    "id": "51.5799,10.6774"
  },
  {
    "ID": 779412816,
    "Tankstellennummer": "TD110350",
    "Name": "SOEST OESTINGHAUSER STR.14",
    "Address": "OESTINGHAUSER STR.14",
    "Address__1": "",
    "City": "SOEST",
    "Postcode": 59494,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02921-80663",
    "24 hour?": "FALSE",
    "Latitude": 51.5805,
    "Longitude": 8.1117,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5805,8.1117",
    "IsOpen24Hours": false,
    "id": "51.5805,8.1117"
  },
  {
    "ID": 266448241,
    "Tankstellennummer": 610462,
    "Name": "WESTFALEN, RECKLINGHAUSEN",
    "Address": "Herner Str. 249",
    "Address__1": "",
    "City": "Recklinghausen",
    "Postcode": 45659,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02361/66246",
    "24 hour?": "FALSE",
    "Latitude": 51.5841,
    "Longitude": 7.2125,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5841,7.2125",
    "IsOpen24Hours": false,
    "id": "51.5841,7.2125"
  },
  {
    "ID": 1741425926,
    "Tankstellennummer": "TD040279",
    "Name": "CASTROP-RAUXEL RECKLINGHAUSER",
    "Address": "RECKLINGHAUSER STR. 50",
    "Address__1": "",
    "City": "CASTROP-RAUXEL",
    "Postcode": 44581,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 23059637500,
    "24 hour?": "FALSE",
    "Latitude": 51.5843,
    "Longitude": 7.345,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5843,7.345",
    "IsOpen24Hours": false,
    "id": "51.5843,7.345"
  },
  {
    "ID": 860895812,
    "Tankstellennummer": "TD030932",
    "Name": "BAD DUEBEN EILENBURGER STR.",
    "Address": "1 EILENBURGER STR.",
    "Address__1": "",
    "City": "BAD DUEBEN",
    "Postcode": 4849,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034243-23151",
    "24 hour?": "FALSE",
    "Latitude": 51.5845,
    "Longitude": 12.5923,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5845,12.5923",
    "IsOpen24Hours": false,
    "id": "51.5845,12.5923"
  },
  {
    "ID": 2516,
    "Tankstellennummer": "0FC57",
    "Name": "Aral Tankstelle Großräschen, Freienhufener Straße 2 /",
    "Address": "Freienhufener Straße 21",
    "Address__1": "",
    "City": "Großräschen",
    "Postcode": 1983,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 357533140,
    "24 hour?": "TRUE",
    "Latitude": 51.585775,
    "Longitude": 14.002684,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.585775,14.002684",
    "IsOpen24Hours": true,
    "id": "51.585775,14.002684"
  },
  {
    "ID": 745375125,
    "Tankstellennummer": "0FN77",
    "Name": "BOVENDEN",
    "Address": "Göttinger Straße 45",
    "Address__1": "",
    "City": "Bovenden",
    "Postcode": 37120,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5518768,
    "24 hour?": "FALSE",
    "Latitude": 51.586568,
    "Longitude": 9.92659,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.586568,9.92659",
    "IsOpen24Hours": false,
    "id": "51.586568,9.92659"
  },
  {
    "ID": 1903093245,
    "Tankstellennummer": "TD028951",
    "Name": "FALKENBERG ROTHSTEINSLACHE 2",
    "Address": "ROTHSTEINSLACHE 2",
    "Address__1": "",
    "City": "FALKENBERG",
    "Postcode": 4895,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03536-531275",
    "24 hour?": "FALSE",
    "Latitude": 51.587,
    "Longitude": 13.2486,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.587,13.2486",
    "IsOpen24Hours": false,
    "id": "51.587,13.2486"
  },
  {
    "ID": 107023707,
    "Tankstellennummer": "TD110030",
    "Name": "CASTROP-RAUXEL LANGE STR.159",
    "Address": "LANGE STR.159",
    "Address__1": "",
    "City": "CASTROP-RAUXEL",
    "Postcode": 44581,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02305-79420",
    "24 hour?": "FALSE",
    "Latitude": 51.5873,
    "Longitude": 7.326,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5873,7.326",
    "IsOpen24Hours": false,
    "id": "51.5873,7.326"
  },
  {
    "ID": 2106793662,
    "Tankstellennummer": "0FN59",
    "Name": "BAD DUBEN",
    "Address": "Görschlitzer Str. 7",
    "Address__1": "",
    "City": "Bad Düben",
    "Postcode": 4849,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3424325037,
    "24 hour?": "FALSE",
    "Latitude": 51.589969,
    "Longitude": 12.601952,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.589969,12.601952",
    "IsOpen24Hours": false,
    "id": "51.589969,12.601952"
  },
  {
    "ID": 120083103,
    "Tankstellennummer": 610351,
    "Name": "WESTFALEN, GELSENKIRCHEN",
    "Address": "Polsumer Str. 38-40",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45894,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "(0209/1776888)",
    "24 hour?": "TRUE",
    "Latitude": 51.5901,
    "Longitude": 7.05115,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.5901,7.05115",
    "IsOpen24Hours": true,
    "id": "51.5901,7.05115"
  },
  {
    "ID": 3466,
    "Tankstellennummer": "0FL86",
    "Name": "Aral Tankstelle Trendelburg, Bremer Str. 34",
    "Address": "Bremer Straße 34",
    "Address__1": "",
    "City": "Trendelburg",
    "Postcode": 34388,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5675725292,
    "24 hour?": "FALSE",
    "Latitude": 51.590721,
    "Longitude": 9.409088,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.590721,9.409088",
    "IsOpen24Hours": false,
    "id": "51.590721,9.409088"
  },
  {
    "ID": 935207235,
    "Tankstellennummer": "TD028944",
    "Name": "NEUPETERSHAIN SPREMBERGER STR.",
    "Address": "SPREMBERGER STR. 30",
    "Address__1": "",
    "City": "NEUPETERSHAIN",
    "Postcode": 3103,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035751/2100",
    "24 hour?": "FALSE",
    "Latitude": 51.5921,
    "Longitude": 14.1666,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5921,14.1666",
    "IsOpen24Hours": false,
    "id": "51.5921,14.1666"
  },
  {
    "ID": 1246,
    "Tankstellennummer": "0F351",
    "Name": "Aral Tankstelle Lünen, Preußenstr. 49",
    "Address": "Preußenstraße 49",
    "Address__1": "",
    "City": "Lünen",
    "Postcode": 44532,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 230641069,
    "24 hour?": "FALSE",
    "Latitude": 51.592756,
    "Longitude": 7.548283,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.592756,7.548283",
    "IsOpen24Hours": false,
    "id": "51.592756,7.548283"
  },
  {
    "ID": 1331,
    "Tankstellennummer": "0F003",
    "Name": "Aral Tankstelle Lünen, Brechtener Str. 26",
    "Address": "Brechtener Straße 26",
    "Address__1": "",
    "City": "Lünen",
    "Postcode": 44536,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 231871659,
    "24 hour?": "FALSE",
    "Latitude": 51.592867,
    "Longitude": 7.440023,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.592867,7.440023",
    "IsOpen24Hours": false,
    "id": "51.592867,7.440023"
  },
  {
    "ID": 1718214336,
    "Tankstellennummer": "TD040261",
    "Name": "LUENEN PREUSSENSTR. 106A",
    "Address": "PREUSSENSTR. 106a",
    "Address__1": "",
    "City": "LUENEN",
    "Postcode": 44532,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02306-48685",
    "24 hour?": "FALSE",
    "Latitude": 51.5942,
    "Longitude": 7.5529,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5942,7.5529",
    "IsOpen24Hours": false,
    "id": "51.5942,7.5529"
  },
  {
    "ID": 42843057,
    "Tankstellennummer": 610352,
    "Name": "WESTFALEN, HERTEN",
    "Address": "Resser Weg 20",
    "Address__1": "",
    "City": "Herten",
    "Postcode": 45699,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02366/184583",
    "24 hour?": "TRUE",
    "Latitude": 51.5947,
    "Longitude": 7.12981,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.5947,7.12981",
    "IsOpen24Hours": true,
    "id": "51.5947,7.12981"
  },
  {
    "ID": 1368,
    "Tankstellennummer": "0F453",
    "Name": "Aral Autohof Geseke, Bürener Str. 155",
    "Address": "Bürener Straße 155",
    "Address__1": "",
    "City": "Geseke",
    "Postcode": 59590,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2942574916,
    "24 hour?": "TRUE",
    "Latitude": 51.597761,
    "Longitude": 8.51572,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.597761,8.51572",
    "IsOpen24Hours": true,
    "id": "51.597761,8.51572"
  },
  {
    "ID": 1370481799,
    "Tankstellennummer": "TD038810",
    "Name": "RECKLINGHAUSEN SCHMALKALDER ST",
    "Address": "SCHMALKALDER STRASSE 13",
    "Address__1": "",
    "City": "RECKLINGHAUSEN",
    "Postcode": 45665,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02361-9387600",
    "24 hour?": "FALSE",
    "Latitude": 51.5978,
    "Longitude": 7.2451,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5978,7.2451",
    "IsOpen24Hours": false,
    "id": "51.5978,7.2451"
  },
  {
    "ID": 1275,
    "Tankstellennummer": "0F376",
    "Name": "Aral Tankstelle Gelsenkirchen, Polsumer Str. 152",
    "Address": "Polsumer Straße 152",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45896,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 209630444,
    "24 hour?": "TRUE",
    "Latitude": 51.597856,
    "Longitude": 7.05153,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.597856,7.05153",
    "IsOpen24Hours": true,
    "id": "51.597856,7.05153"
  },
  {
    "ID": 1313,
    "Tankstellennummer": "0F408",
    "Name": "Aral Tankstelle Voerde, Frankfurter Str.343",
    "Address": "Frankfurter Straße 343",
    "Address__1": "",
    "City": "Voerde",
    "Postcode": 46562,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 28556574,
    "24 hour?": "FALSE",
    "Latitude": 51.599175,
    "Longitude": 6.663943,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.599175,6.663943",
    "IsOpen24Hours": false,
    "id": "51.599175,6.663943"
  },
  {
    "ID": 3421,
    "Tankstellennummer": "0FL58",
    "Name": "Aral Tankstelle Herten, Kaiserstr. 143",
    "Address": "Kaiserstraße 145",
    "Address__1": "",
    "City": "Herten",
    "Postcode": 45699,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 236637188,
    "24 hour?": "FALSE",
    "Latitude": 51.599208,
    "Longitude": 7.148233,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.599208,7.148233",
    "IsOpen24Hours": false,
    "id": "51.599208,7.148233"
  },
  {
    "ID": 388825219,
    "Tankstellennummer": 610541,
    "Name": "WESTFALEN, HERTEN",
    "Address": "Hertener Str. 38",
    "Address__1": "",
    "City": "Herten",
    "Postcode": 45701,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0209/35546",
    "24 hour?": "FALSE",
    "Latitude": 51.5997,
    "Longitude": 7.09409,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.5997,7.09409",
    "IsOpen24Hours": false,
    "id": "51.5997,7.09409"
  },
  {
    "ID": 601698457,
    "Tankstellennummer": "TD000944",
    "Name": "BOTTROP BOTTROPER STR. 18-20",
    "Address": "BOTTROPER STR. 18-20",
    "Address__1": "",
    "City": "BOTTROP",
    "Postcode": 46244,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02045-2128",
    "24 hour?": "FALSE",
    "Latitude": 51.6006,
    "Longitude": 6.9144,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6006,6.9144",
    "IsOpen24Hours": false,
    "id": "51.6006,6.9144"
  },
  {
    "ID": 2881,
    "Tankstellennummer": "0FH49",
    "Name": "Aral Tankstelle Voerde, Bahnhofstr. 139",
    "Address": "Bahnhofstraße 139",
    "Address__1": "",
    "City": "Voerde",
    "Postcode": 46562,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 285593022,
    "24 hour?": "TRUE",
    "Latitude": 51.60073,
    "Longitude": 6.697079,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.60073,6.697079",
    "IsOpen24Hours": true,
    "id": "51.60073,6.697079"
  },
  {
    "ID": 561964679,
    "Tankstellennummer": "TD005371",
    "Name": "LUENEN BRAMBAUER STR.257",
    "Address": "BRAMBAUER STR.257",
    "Address__1": "",
    "City": "LUENEN",
    "Postcode": 44536,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0231-871861",
    "24 hour?": "FALSE",
    "Latitude": 51.6008,
    "Longitude": 7.4556,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6008,7.4556",
    "IsOpen24Hours": false,
    "id": "51.6008,7.4556"
  },
  {
    "ID": 1355,
    "Tankstellennummer": "0F440",
    "Name": "Aral Tankstelle Recklinghausen, Friedrich-Ebert-Str. 14",
    "Address": "Friedrich-Ebert-Straße 14",
    "Address__1": "",
    "City": "Recklinghausen",
    "Postcode": 45659,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 236126544,
    "24 hour?": "FALSE",
    "Latitude": 51.602707,
    "Longitude": 7.185095,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.602707,7.185095",
    "IsOpen24Hours": false,
    "id": "51.602707,7.185095"
  },
  {
    "ID": 662495255,
    "Tankstellennummer": "TD143060",
    "Name": "BERGKAMEN LUENENER STR. 11",
    "Address": "LUENENER STR. 11",
    "Address__1": "",
    "City": "BERGKAMEN",
    "Postcode": 59192,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02306-82190",
    "24 hour?": "FALSE",
    "Latitude": 51.603,
    "Longitude": 7.5943,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.603,7.5943",
    "IsOpen24Hours": false,
    "id": "51.603,7.5943"
  },
  {
    "ID": 260095945,
    "Tankstellennummer": 610536,
    "Name": "WESTFALEN, RECKLINGHAUSEN",
    "Address": "Sachsenstr. 149",
    "Address__1": "",
    "City": "Recklinghausen",
    "Postcode": 45665,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02361/81671",
    "24 hour?": "FALSE",
    "Latitude": 51.6043,
    "Longitude": 7.27329,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6043,7.27329",
    "IsOpen24Hours": false,
    "id": "51.6043,7.27329"
  },
  {
    "ID": 158647698,
    "Tankstellennummer": "TD005678",
    "Name": "RECKLINGHAUSEN CASTROPER STR.",
    "Address": "CASTROPER STR. 123",
    "Address__1": "",
    "City": "RECKLINGHAUSEN",
    "Postcode": 45665,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02361-484773",
    "24 hour?": "FALSE",
    "Latitude": 51.6046,
    "Longitude": 7.2147,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6046,7.2147",
    "IsOpen24Hours": false,
    "id": "51.6046,7.2147"
  },
  {
    "ID": 763526498,
    "Tankstellennummer": 610451,
    "Name": "WESTFALEN, BERGKAMEN",
    "Address": "Schulstr. 32",
    "Address__1": "",
    "City": "Bergkamen",
    "Postcode": 59192,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02307/963160",
    "24 hour?": "FALSE",
    "Latitude": 51.606,
    "Longitude": 7.61878,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.606,7.61878",
    "IsOpen24Hours": false,
    "id": "51.606,7.61878"
  },
  {
    "ID": 1114137723,
    "Tankstellennummer": 610632,
    "Name": "WESTFALEN, GELSENKIRCHEN",
    "Address": "Polsumer Str. 236",
    "Address__1": "",
    "City": "Gelsenkirchen",
    "Postcode": 45896,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0209/65231",
    "24 hour?": "FALSE",
    "Latitude": 51.6063,
    "Longitude": 7.04903,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6063,7.04903",
    "IsOpen24Hours": false,
    "id": "51.6063,7.04903"
  },
  {
    "ID": 563886758,
    "Tankstellennummer": "TD041046",
    "Name": "SANDERSDORF-BREHNA KOECKERN OS",
    "Address": "KOECKERN OST / BAB 9",
    "Address__1": "",
    "City": "SANDERSDORF-BREHNA",
    "Postcode": 6794,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034954-39237",
    "24 hour?": "FALSE",
    "Latitude": 51.6064,
    "Longitude": 12.1856,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6064,12.1856",
    "IsOpen24Hours": false,
    "id": "51.6064,12.1856"
  },
  {
    "ID": 153342877,
    "Tankstellennummer": "TD028597",
    "Name": "DOEBERN MUSKAUER STR. 32",
    "Address": "MUSKAUER STR. 32",
    "Address__1": "",
    "City": "DOEBERN",
    "Postcode": 3159,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035600-23083",
    "24 hour?": "FALSE",
    "Latitude": 51.6067,
    "Longitude": 14.6086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6067,14.6086",
    "IsOpen24Hours": false,
    "id": "51.6067,14.6086"
  },
  {
    "ID": 562229130,
    "Tankstellennummer": "TD035261",
    "Name": "HERTEN FELDSTR. 159-161",
    "Address": "FELDSTR. 159-161",
    "Address__1": "",
    "City": "HERTEN",
    "Postcode": 45699,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02366-937333",
    "24 hour?": "FALSE",
    "Latitude": 51.6072,
    "Longitude": 7.1242,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6072,7.1242",
    "IsOpen24Hours": false,
    "id": "51.6072,7.1242"
  },
  {
    "ID": 845070209,
    "Tankstellennummer": "TD041038",
    "Name": "SANDERSDORF-BREHNA KOECKERN WE",
    "Address": "KOECKERN WEST/BAB 9",
    "Address__1": "",
    "City": "SANDERSDORF-BREHNA",
    "Postcode": 6794,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034954-39317",
    "24 hour?": "FALSE",
    "Latitude": 51.6082,
    "Longitude": 12.1845,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6082,12.1845",
    "IsOpen24Hours": false,
    "id": "51.6082,12.1845"
  },
  {
    "ID": 2899,
    "Tankstellennummer": "0FH65",
    "Name": "Aral Tankstelle Lünen, Dortmunder Str. 51",
    "Address": "Dortmunder Straße 51",
    "Address__1": "",
    "City": "Lünen",
    "Postcode": 44536,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 230612366,
    "24 hour?": "TRUE",
    "Latitude": 51.609942,
    "Longitude": 7.508481,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.609942,7.508481",
    "IsOpen24Hours": true,
    "id": "51.609942,7.508481"
  },
  {
    "ID": 1250,
    "Tankstellennummer": "0F354",
    "Name": "Aral Tankstelle Recklinghausen, Dortmunder Str. 20",
    "Address": "Dortmunder Straße 20",
    "Address__1": "",
    "City": "Recklinghausen",
    "Postcode": 45665,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23619378150,
    "24 hour?": "TRUE",
    "Latitude": 51.612916,
    "Longitude": 7.204719,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.612916,7.204719",
    "IsOpen24Hours": true,
    "id": "51.612916,7.204719"
  },
  {
    "ID": 765307184,
    "Tankstellennummer": 5029,
    "Name": "Agip Doberlug-Kirchhain",
    "Address": "Bahnhofsallee",
    "Address__1": "",
    "City": "Doberlug-Kirchhain",
    "Postcode": 3253,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 35322451",
    "24 hour?": "TRUE",
    "Latitude": 51.61389,
    "Longitude": 13.55639,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.61389,13.55639",
    "IsOpen24Hours": true,
    "id": "51.61389,13.55639"
  },
  {
    "ID": 1664,
    "Tankstellennummer": "0F018",
    "Name": "Aral Tankstelle Gieboldehausen, Herzberger Landstrasse 3",
    "Address": "Herzberger Landstraße 3",
    "Address__1": "",
    "City": "Gieboldehausen",
    "Postcode": 37434,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5528200891,
    "24 hour?": "FALSE",
    "Latitude": 51.615622,
    "Longitude": 10.218676,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.615622,10.218676",
    "IsOpen24Hours": false,
    "id": "51.615622,10.218676"
  },
  {
    "ID": 2290,
    "Tankstellennummer": "0FB14",
    "Name": "Aral Autohof Hamm, Werler Str. 390",
    "Address": "Werler Straße 390",
    "Address__1": "",
    "City": "Hamm",
    "Postcode": 59069,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23852393,
    "24 hour?": "TRUE",
    "Latitude": 51.61679,
    "Longitude": 7.8489,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.61679,7.8489",
    "IsOpen24Hours": true,
    "id": "51.61679,7.8489"
  },
  {
    "ID": 1280,
    "Tankstellennummer": "0F381",
    "Name": "Aral Tankstelle Recklinghausen, Halterner Str. 17",
    "Address": "Halterner Straße 17",
    "Address__1": "",
    "City": "Recklinghausen",
    "Postcode": 45657,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 236126261,
    "24 hour?": "FALSE",
    "Latitude": 51.619394,
    "Longitude": 7.197811,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.619394,7.197811",
    "IsOpen24Hours": false,
    "id": "51.619394,7.197811"
  },
  {
    "ID": 3334,
    "Tankstellennummer": "0FK86",
    "Name": "Aral Tankstelle Weeze, Industriestrasse",
    "Address": "Industriestraße 43",
    "Address__1": "",
    "City": "Weeze",
    "Postcode": 47652,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2837962551,
    "24 hour?": "TRUE",
    "Latitude": 51.620177,
    "Longitude": 6.209968,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.620177,6.209968",
    "IsOpen24Hours": true,
    "id": "51.620177,6.209968"
  },
  {
    "ID": 739093888,
    "Tankstellennummer": 610358,
    "Name": "WESTFALEN, RECKLINGHAUSEN",
    "Address": "Dortmunder Str. 239",
    "Address__1": "",
    "City": "Recklinghausen",
    "Postcode": 45665,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02361/ 4855090",
    "24 hour?": "FALSE",
    "Latitude": 51.6225,
    "Longitude": 7.2291,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6225,7.2291",
    "IsOpen24Hours": false,
    "id": "51.6225,7.2291"
  },
  {
    "ID": 1901195616,
    "Tankstellennummer": "TD110310",
    "Name": "RECKLINGHAUSEN HALTERNER STR.1",
    "Address": "HALTERNER STR.100",
    "Address__1": "",
    "City": "RECKLINGHAUSEN",
    "Postcode": 45657,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02361-22485",
    "24 hour?": "FALSE",
    "Latitude": 51.6244,
    "Longitude": 7.193,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6244,7.193",
    "IsOpen24Hours": false,
    "id": "51.6244,7.193"
  },
  {
    "ID": 2668,
    "Tankstellennummer": "0FD87",
    "Name": "Aral Tankstelle Zörbig, Bitterfelder Str. 51",
    "Address": "Bitterfelder Straße 51",
    "Address__1": "",
    "City": "Zörbig",
    "Postcode": 6780,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 34956399202,
    "24 hour?": "FALSE",
    "Latitude": 51.625484,
    "Longitude": 12.133721,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.625484,12.133721",
    "IsOpen24Hours": false,
    "id": "51.625484,12.133721"
  },
  {
    "ID": 863024054,
    "Tankstellennummer": "TD038422",
    "Name": "NOERTEN-HARDENBERG LAUENFOERDE",
    "Address": "LAUENFOERDERSTR. 3",
    "Address__1": "",
    "City": "NOERTEN-HARDENBERG",
    "Postcode": 37176,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05503-9159377",
    "24 hour?": "FALSE",
    "Latitude": 51.6265,
    "Longitude": 9.9342,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6265,9.9342",
    "IsOpen24Hours": false,
    "id": "51.6265,9.9342"
  },
  {
    "ID": 2082319535,
    "Tankstellennummer": 610329,
    "Name": "WESTFALEN, USLAR",
    "Address": "Landstr. 10",
    "Address__1": "",
    "City": "USLAR",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.627,
    "Longitude": 9.66106,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.627,9.66106",
    "IsOpen24Hours": false,
    "id": "51.627,9.66106"
  },
  {
    "ID": 2626,
    "Tankstellennummer": "0FD51",
    "Name": "Aral Tankstelle Bitterfeld, Wittenberger Str.",
    "Address": "Wittenberger Straße",
    "Address__1": "",
    "City": "Bitterfeld",
    "Postcode": 6749,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 349324717,
    "24 hour?": "FALSE",
    "Latitude": 51.627109,
    "Longitude": 12.336667,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.627109,12.336667",
    "IsOpen24Hours": false,
    "id": "51.627109,12.336667"
  },
  {
    "ID": 1314,
    "Tankstellennummer": "0F409",
    "Name": "Aral Tankstelle Voerde, Spellener Str.13",
    "Address": "Spellenerstraße 13",
    "Address__1": "",
    "City": "Voerde",
    "Postcode": 46562,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 28141301,
    "24 hour?": "TRUE",
    "Latitude": 51.629196,
    "Longitude": 6.658793,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.629196,6.658793",
    "IsOpen24Hours": true,
    "id": "51.629196,6.658793"
  },
  {
    "ID": 2311,
    "Tankstellennummer": "0FB26",
    "Name": "Aral Tankstelle Lünen, Cappenberger Str. 101",
    "Address": "Cappenberger Straße 101",
    "Address__1": "",
    "City": "Lünen",
    "Postcode": 44534,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 230654268,
    "24 hour?": "FALSE",
    "Latitude": 51.629779,
    "Longitude": 7.526579,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.629779,7.526579",
    "IsOpen24Hours": false,
    "id": "51.629779,7.526579"
  },
  {
    "ID": 2307,
    "Tankstellennummer": "0FB24",
    "Name": "Aral Tankstelle Geseke, Bürener Str. 15",
    "Address": "Bürener Straße 15",
    "Address__1": "",
    "City": "Geseke",
    "Postcode": 59590,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 294297863160,
    "24 hour?": "FALSE",
    "Latitude": 51.631063,
    "Longitude": 8.512723,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.631063,8.512723",
    "IsOpen24Hours": false,
    "id": "51.631063,8.512723"
  },
  {
    "ID": 577506480,
    "Tankstellennummer": "0FM58",
    "Name": "HAMM",
    "Address": "A 2/Ostendorfstraße 60",
    "Address__1": "",
    "City": "Hamm",
    "Postcode": 59069,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23852577,
    "24 hour?": "TRUE",
    "Latitude": 51.632312,
    "Longitude": 7.873946,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.632312,7.873946",
    "IsOpen24Hours": true,
    "id": "51.632312,7.873946"
  },
  {
    "ID": 2621,
    "Tankstellennummer": "0FD46",
    "Name": "Aral Tankstelle Harzgerode, Augustenstr. 88",
    "Address": "Augustenstraße 88",
    "Address__1": "",
    "City": "Harzgerode",
    "Postcode": 6493,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 394843130,
    "24 hour?": "FALSE",
    "Latitude": 51.634599,
    "Longitude": 11.147129,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.634599,11.147129",
    "IsOpen24Hours": false,
    "id": "51.634599,11.147129"
  },
  {
    "ID": 2667,
    "Tankstellennummer": "0FD86",
    "Name": "Aral Tankstelle Hettstedt, Mansfelder Str.",
    "Address": "Mansfelder Straße 72a",
    "Address__1": "",
    "City": "Hettstedt",
    "Postcode": 6333,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3476810278,
    "24 hour?": "TRUE",
    "Latitude": 51.635302,
    "Longitude": 11.506106,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.635302,11.506106",
    "IsOpen24Hours": true,
    "id": "51.635302,11.506106"
  },
  {
    "ID": 500211178,
    "Tankstellennummer": "0FR41",
    "Name": "BITTERFELD",
    "Address": "Sonnenfeld 2",
    "Address__1": "",
    "City": "Bitterfeld",
    "Postcode": 6766,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 349397570,
    "24 hour?": "TRUE",
    "Latitude": 51.635574,
    "Longitude": 12.199639,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.635574,12.199639",
    "IsOpen24Hours": true,
    "id": "51.635574,12.199639"
  },
  {
    "ID": 2896,
    "Tankstellennummer": "0FH62",
    "Name": "Aral Tankstelle Geseke, Erwitterstr. 13",
    "Address": "Erwitterstraße 13",
    "Address__1": "",
    "City": "Geseke",
    "Postcode": 59590,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 29421371,
    "24 hour?": "FALSE",
    "Latitude": 51.635704,
    "Longitude": 8.505503,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.635704,8.505503",
    "IsOpen24Hours": false,
    "id": "51.635704,8.505503"
  },
  {
    "ID": 1289,
    "Tankstellennummer": "0F389",
    "Name": "Aral Tankstelle Oer-Erkenschwick, Schiller-/ Brechtstr.",
    "Address": "Brechtstraße 12",
    "Address__1": "",
    "City": "Oer-Erkenschwick",
    "Postcode": 45739,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23681536,
    "24 hour?": "FALSE",
    "Latitude": 51.640579,
    "Longitude": 7.254754,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.640579,7.254754",
    "IsOpen24Hours": false,
    "id": "51.640579,7.254754"
  },
  {
    "ID": 2902,
    "Tankstellennummer": "0FH68",
    "Name": "Aral Tankstelle Salzkotten, Kleiner Hellweg 2",
    "Address": "Kleiner Hellweg 2",
    "Address__1": "",
    "City": "Salzkotten",
    "Postcode": 33154,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5258940605,
    "24 hour?": "FALSE",
    "Latitude": 51.641287,
    "Longitude": 8.664817,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.641287,8.664817",
    "IsOpen24Hours": false,
    "id": "51.641287,8.664817"
  },
  {
    "ID": 2088975531,
    "Tankstellennummer": "TD028613",
    "Name": "FINSTERWALDE SONNEWALDER STR.",
    "Address": "SONNEWALDER STR.91 b",
    "Address__1": "",
    "City": "FINSTERWALDE",
    "Postcode": 3238,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03531-704610",
    "24 hour?": "FALSE",
    "Latitude": 51.6429,
    "Longitude": 13.6963,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6429,13.6963",
    "IsOpen24Hours": false,
    "id": "51.6429,13.6963"
  },
  {
    "ID": 210690138,
    "Tankstellennummer": "TD005322",
    "Name": "HAMM WERLER STR. 370",
    "Address": "WERLER STR. 370",
    "Address__1": "",
    "City": "HAMM",
    "Postcode": 59069,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02381-51543",
    "24 hour?": "FALSE",
    "Latitude": 51.6459,
    "Longitude": 7.8428,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6459,7.8428",
    "IsOpen24Hours": false,
    "id": "51.6459,7.8428"
  },
  {
    "ID": 267264841,
    "Tankstellennummer": 5221,
    "Name": "Agip Drebkau",
    "Address": "Spremberger Str.8/ B169 (Gewerbegeb",
    "Address__1": "",
    "City": "Drebkau",
    "Postcode": 3116,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 35602510",
    "24 hour?": "TRUE",
    "Latitude": 51.646239,
    "Longitude": 14.237595,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.646239,14.237595",
    "IsOpen24Hours": true,
    "id": "51.646239,14.237595"
  },
  {
    "ID": 1726,
    "Tankstellennummer": "0F718",
    "Name": "Aral Tankstelle Herzberg, Bundesstraße 27/243",
    "Address": "Scharzfelder Str. 6",
    "Address__1": "",
    "City": "Herzberg",
    "Postcode": 37412,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 55213071,
    "24 hour?": "TRUE",
    "Latitude": 51.649602,
    "Longitude": 10.346567,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.649602,10.346567",
    "IsOpen24Hours": true,
    "id": "51.649602,10.346567"
  },
  {
    "ID": 2904,
    "Tankstellennummer": "0FH70",
    "Name": "Aral Tankstelle Bergkamen, Hellweg 36",
    "Address": "Hellweg 34 A",
    "Address__1": "",
    "City": "Bergkamen",
    "Postcode": 59192,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23899260320,
    "24 hour?": "TRUE",
    "Latitude": 51.651335,
    "Longitude": 7.662784,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.651335,7.662784",
    "IsOpen24Hours": true,
    "id": "51.651335,7.662784"
  },
  {
    "ID": 851209593,
    "Tankstellennummer": "0FN95",
    "Name": "WEEZE",
    "Address": "A 57",
    "Address__1": "",
    "City": "Weeze",
    "Postcode": 47652,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2837664418,
    "24 hour?": "TRUE",
    "Latitude": 51.652277,
    "Longitude": 6.198259,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.652277,6.198259",
    "IsOpen24Hours": true,
    "id": "51.652277,6.198259"
  },
  {
    "ID": 589618607,
    "Tankstellennummer": "0FY19",
    "Name": "WEEZE",
    "Address": "A 57",
    "Address__1": "",
    "City": "Weeze",
    "Postcode": 47652,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2837664727,
    "24 hour?": "TRUE",
    "Latitude": 51.65326,
    "Longitude": 6.19841,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.65326,6.19841",
    "IsOpen24Hours": true,
    "id": "51.65326,6.19841"
  },
  {
    "ID": 513810863,
    "Tankstellennummer": "TD000658",
    "Name": "XANTEN RHEINBERGER STR. 15",
    "Address": "RHEINBERGER STR. 15",
    "Address__1": "",
    "City": "XANTEN",
    "Postcode": 46509,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02801-5360",
    "24 hour?": "FALSE",
    "Latitude": 51.654,
    "Longitude": 6.4707,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.654,6.4707",
    "IsOpen24Hours": false,
    "id": "51.654,6.4707"
  },
  {
    "ID": 1187,
    "Tankstellennummer": "0F304",
    "Name": "Aral Tankstelle Datteln, Südring 285",
    "Address": "Südring 285",
    "Address__1": "",
    "City": "Datteln",
    "Postcode": 45711,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23632297,
    "24 hour?": "FALSE",
    "Latitude": 51.655755,
    "Longitude": 7.351915,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.655755,7.351915",
    "IsOpen24Hours": false,
    "id": "51.655755,7.351915"
  },
  {
    "ID": 207794607,
    "Tankstellennummer": 610030,
    "Name": "WESTFALEN, DORSTEN",
    "Address": "Gahlener Str. 105",
    "Address__1": "",
    "City": "Dorsten",
    "Postcode": 46282,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02362/3214",
    "24 hour?": "TRUE",
    "Latitude": 51.6568,
    "Longitude": 6.94657,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.6568,6.94657",
    "IsOpen24Hours": true,
    "id": "51.6568,6.94657"
  },
  {
    "ID": 56819130,
    "Tankstellennummer": "TD030767",
    "Name": "HETTSTEDT ASCHERSLEBENER STR.",
    "Address": "ASCHERSLEBENER STR. 60",
    "Address__1": "",
    "City": "HETTSTEDT",
    "Postcode": 6333,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03476-851065",
    "24 hour?": "FALSE",
    "Latitude": 51.6571,
    "Longitude": 11.4951,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6571,11.4951",
    "IsOpen24Hours": false,
    "id": "51.6571,11.4951"
  },
  {
    "ID": 2302,
    "Tankstellennummer": "0FB21",
    "Name": "Aral Tankstelle Werne, Lünener Str.31",
    "Address": "Lünener Straße 31",
    "Address__1": "",
    "City": "Werne",
    "Postcode": 59368,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23894791,
    "24 hour?": "FALSE",
    "Latitude": 51.657573,
    "Longitude": 7.628648,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.657573,7.628648",
    "IsOpen24Hours": false,
    "id": "51.657573,7.628648"
  },
  {
    "ID": 1370734986,
    "Tankstellennummer": "0FO35",
    "Name": "UEDEM",
    "Address": "Molkerei-/Kervenheimer St",
    "Address__1": "",
    "City": "Uedem",
    "Postcode": 47589,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2825539955,
    "24 hour?": "FALSE",
    "Latitude": 51.658244,
    "Longitude": 6.275204,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.658244,6.275204",
    "IsOpen24Hours": false,
    "id": "51.658244,6.275204"
  },
  {
    "ID": 1351368857,
    "Tankstellennummer": "TD000755",
    "Name": "WESEL SCHERMBECKER LSTR.28",
    "Address": "SCHERMBECKER LSTR.28",
    "Address__1": "",
    "City": "WESEL",
    "Postcode": 46485,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0281-50167",
    "24 hour?": "FALSE",
    "Latitude": 51.6595,
    "Longitude": 6.6389,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6595,6.6389",
    "IsOpen24Hours": false,
    "id": "51.6595,6.6389"
  },
  {
    "ID": 328334682,
    "Tankstellennummer": "TD112820",
    "Name": "HAMM DORTMUNDER STR. 174",
    "Address": "DORTMUNDER STR. 174",
    "Address__1": "",
    "City": "HAMM",
    "Postcode": 59077,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02381-461785",
    "24 hour?": "FALSE",
    "Latitude": 51.6608,
    "Longitude": 7.7361,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6608,7.7361",
    "IsOpen24Hours": false,
    "id": "51.6608,7.7361"
  },
  {
    "ID": 639763063,
    "Tankstellennummer": 610639,
    "Name": "WESTFALEN, HAMM",
    "Address": "Werler Str. 238-240",
    "Address__1": "",
    "City": "Hamm",
    "Postcode": 59063,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02381/59146",
    "24 hour?": "TRUE",
    "Latitude": 51.6619,
    "Longitude": 7.83437,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.6619,7.83437",
    "IsOpen24Hours": true,
    "id": "51.6619,7.83437"
  },
  {
    "ID": 1294,
    "Tankstellennummer": "0F394",
    "Name": "Aral Tankstelle Marl, Brassertstr. 124",
    "Address": "Brassertstraße 124",
    "Address__1": "",
    "City": "Marl",
    "Postcode": 45768,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 236566398,
    "24 hour?": "FALSE",
    "Latitude": 51.66316,
    "Longitude": 7.08054,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.66316,7.08054",
    "IsOpen24Hours": false,
    "id": "51.66316,7.08054"
  },
  {
    "ID": 150066515,
    "Tankstellennummer": "TD110590",
    "Name": "WERNE STOCKUMER STR.25",
    "Address": "STOCKUMER STR.25",
    "Address__1": "",
    "City": "WERNE",
    "Postcode": 59368,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02389-2395",
    "24 hour?": "FALSE",
    "Latitude": 51.6635,
    "Longitude": 7.6409,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6635,7.6409",
    "IsOpen24Hours": false,
    "id": "51.6635,7.6409"
  },
  {
    "ID": 2884,
    "Tankstellennummer": "0FH52",
    "Name": "Aral Tankstelle Wesel, Reeser Landstr. 20",
    "Address": "Reeser Landstraße 20",
    "Address__1": "",
    "City": "Wesel",
    "Postcode": 46483,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 281331402,
    "24 hour?": "TRUE",
    "Latitude": 51.664252,
    "Longitude": 6.609866,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.664252,6.609866",
    "IsOpen24Hours": true,
    "id": "51.664252,6.609866"
  },
  {
    "ID": 3311,
    "Tankstellennummer": "0FK69",
    "Name": "Aral Tankstelle Marl, Victoriastrasse 174",
    "Address": "Victoriastraße 174",
    "Address__1": "",
    "City": "Marl",
    "Postcode": 45772,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2365415204,
    "24 hour?": "TRUE",
    "Latitude": 51.665641,
    "Longitude": 7.150652,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.665641,7.150652",
    "IsOpen24Hours": true,
    "id": "51.665641,7.150652"
  },
  {
    "ID": 301162911,
    "Tankstellennummer": 610057,
    "Name": "WESTFALEN, HAMM",
    "Address": "Wilhelmstr. 184",
    "Address__1": "",
    "City": "Hamm",
    "Postcode": 59067,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02381/44954",
    "24 hour?": "FALSE",
    "Latitude": 51.6664,
    "Longitude": 7.78781,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6664,7.78781",
    "IsOpen24Hours": false,
    "id": "51.6664,7.78781"
  },
  {
    "ID": 1312,
    "Tankstellennummer": "0F407",
    "Name": "Aral Tankstelle Hünxe, Schermbecker Landstr. 30",
    "Address": "Schermbecker Landstr. 34",
    "Address__1": "",
    "City": "Hünxe",
    "Postcode": 46569,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 28582468,
    "24 hour?": "FALSE",
    "Latitude": 51.668154,
    "Longitude": 6.741804,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.668154,6.741804",
    "IsOpen24Hours": false,
    "id": "51.668154,6.741804"
  },
  {
    "ID": 1724,
    "Tankstellennummer": "0F716",
    "Name": "Aral Tankstelle Salzkotten, Geseker Straße 21",
    "Address": "Geseker Straße 21",
    "Address__1": "",
    "City": "Salzkotten",
    "Postcode": 33154,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5258935377,
    "24 hour?": "FALSE",
    "Latitude": 51.668236,
    "Longitude": 8.59409,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.668236,8.59409",
    "IsOpen24Hours": false,
    "id": "51.668236,8.59409"
  },
  {
    "ID": 534528099,
    "Tankstellennummer": 610360,
    "Name": "WESTFALEN, WERNE",
    "Address": "Horster Str. 21",
    "Address__1": "",
    "City": "Werne",
    "Postcode": 59368,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02389/9238200",
    "24 hour?": "FALSE",
    "Latitude": 51.6684,
    "Longitude": 7.64131,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6684,7.64131",
    "IsOpen24Hours": false,
    "id": "51.6684,7.64131"
  },
  {
    "ID": 2287,
    "Tankstellennummer": "0FB11",
    "Name": "Aral Tankstelle Hamm, Alleestr. 92",
    "Address": "Alleestraße 92",
    "Address__1": "",
    "City": "Hamm",
    "Postcode": 59065,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 238151773,
    "24 hour?": "TRUE",
    "Latitude": 51.672103,
    "Longitude": 7.814706,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.672103,7.814706",
    "IsOpen24Hours": true,
    "id": "51.672103,7.814706"
  },
  {
    "ID": 1402811777,
    "Tankstellennummer": "TD006080",
    "Name": "GOCH OSTRING 140",
    "Address": "OSTRING 140",
    "Address__1": "",
    "City": "GOCH",
    "Postcode": 47574,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02823-7406",
    "24 hour?": "FALSE",
    "Latitude": 51.6725,
    "Longitude": 6.1721,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6725,6.1721",
    "IsOpen24Hours": false,
    "id": "51.6725,6.1721"
  },
  {
    "ID": 419384513,
    "Tankstellennummer": 610418,
    "Name": "WESTFALEN, LIPPSTADT",
    "Address": "Beckumer Str. 133",
    "Address__1": "",
    "City": "Lippstadt",
    "Postcode": 59555,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02941/3327",
    "24 hour?": "FALSE",
    "Latitude": 51.6805,
    "Longitude": 8.31155,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6805,8.31155",
    "IsOpen24Hours": false,
    "id": "51.6805,8.31155"
  },
  {
    "ID": 387571691,
    "Tankstellennummer": "TD000871",
    "Name": "DORSTEN BORKENER STR. 156",
    "Address": "BORKENER STR.156",
    "Address__1": "",
    "City": "DORSTEN",
    "Postcode": 46284,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02362-81600",
    "24 hour?": "FALSE",
    "Latitude": 51.6826,
    "Longitude": 6.9556,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6826,6.9556",
    "IsOpen24Hours": false,
    "id": "51.6826,6.9556"
  },
  {
    "ID": 1037913414,
    "Tankstellennummer": "0FE76",
    "Name": "Aral Autohof Paderborn, Borchener Str. 336",
    "Address": "Borchener Straße336",
    "Address__1": "",
    "City": "Paderborn",
    "Postcode": 33106,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5251892905,
    "24 hour?": "TRUE",
    "Latitude": 51.683275,
    "Longitude": 8.721109,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.683275,8.721109",
    "IsOpen24Hours": true,
    "id": "51.683275,8.721109"
  },
  {
    "ID": 2604,
    "Tankstellennummer": "0FD34",
    "Name": "Aral Tankstelle Wolfen, Steinfurther Str./B 184",
    "Address": "Steinfurther Straße/B 184",
    "Address__1": "",
    "City": "Wolfen",
    "Postcode": 6766,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 349422926,
    "24 hour?": "FALSE",
    "Latitude": 51.683379,
    "Longitude": 12.273757,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.683379,12.273757",
    "IsOpen24Hours": false,
    "id": "51.683379,12.273757"
  },
  {
    "ID": 1640525648,
    "Tankstellennummer": 610638,
    "Name": "WESTFALEN, HAMM",
    "Address": "Ostenallee 48",
    "Address__1": "",
    "City": "Hamm",
    "Postcode": 59063,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02381/24267",
    "24 hour?": "FALSE",
    "Latitude": 51.6858,
    "Longitude": 7.8345,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6858,7.8345",
    "IsOpen24Hours": false,
    "id": "51.6858,7.8345"
  },
  {
    "ID": 660492687,
    "Tankstellennummer": "TD027060",
    "Name": "OBERHARZ AM BROCKEN NORDHAEUSE",
    "Address": "NORDHAEUSER STR. 19",
    "Address__1": "",
    "City": "OBERHARZ AM BROCKEN",
    "Postcode": 38899,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039459-71276",
    "24 hour?": "FALSE",
    "Latitude": 51.6889,
    "Longitude": 10.8448,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6889,10.8448",
    "IsOpen24Hours": false,
    "id": "51.6889,10.8448"
  },
  {
    "ID": 2721,
    "Tankstellennummer": "0FE35",
    "Name": "Aral Autohof Könnern, B 71",
    "Address": "Nordstr. 1/B 71",
    "Address__1": "",
    "City": "Könnern",
    "Postcode": 6420,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3469151256,
    "24 hour?": "TRUE",
    "Latitude": 51.689058,
    "Longitude": 11.767214,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.689058,11.767214",
    "IsOpen24Hours": true,
    "id": "51.689058,11.767214"
  },
  {
    "ID": 1885930542,
    "Tankstellennummer": 610584,
    "Name": "WESTFALEN, LIPPSTADT",
    "Address": "Lippestr. 34",
    "Address__1": "",
    "City": "Lippstadt",
    "Postcode": 59558,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02941/63027",
    "24 hour?": "FALSE",
    "Latitude": 51.6899,
    "Longitude": 8.37223,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6899,8.37223",
    "IsOpen24Hours": false,
    "id": "51.6899,8.37223"
  },
  {
    "ID": 2292,
    "Tankstellennummer": "0FB15",
    "Name": "Aral Tankstelle Hamm, Hammer Str. 231",
    "Address": "Hammer Straße 231",
    "Address__1": "",
    "City": "Hamm",
    "Postcode": 59075,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2381788669,
    "24 hour?": "TRUE",
    "Latitude": 51.689995,
    "Longitude": 7.775106,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.689995,7.775106",
    "IsOpen24Hours": true,
    "id": "51.689995,7.775106"
  },
  {
    "ID": 2606,
    "Tankstellennummer": "0FD36",
    "Name": "Aral Tankstelle Herzberg, B 87 / Leipziger Str.",
    "Address": "Leipziger Straße 20",
    "Address__1": "",
    "City": "Herzberg",
    "Postcode": 4916,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 353521440,
    "24 hour?": "TRUE",
    "Latitude": 51.690245,
    "Longitude": 13.220948,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.690245,13.220948",
    "IsOpen24Hours": true,
    "id": "51.690245,13.220948"
  },
  {
    "ID": 298782868,
    "Tankstellennummer": "TD000239",
    "Name": "HAMM ALTER UENTROPER W.62",
    "Address": "ALTER UENTROPER W.62",
    "Address__1": "",
    "City": "HAMM",
    "Postcode": 59071,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02381-80632",
    "24 hour?": "FALSE",
    "Latitude": 51.691,
    "Longitude": 7.8695,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.691,7.8695",
    "IsOpen24Hours": false,
    "id": "51.691,7.8695"
  },
  {
    "ID": 774737235,
    "Tankstellennummer": 610055,
    "Name": "WESTFALEN, MARL",
    "Address": "Bachackerweg 1",
    "Address__1": "",
    "City": "Marl",
    "Postcode": 45772,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02365/21956",
    "24 hour?": "FALSE",
    "Latitude": 51.6922,
    "Longitude": 7.11865,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6922,7.11865",
    "IsOpen24Hours": false,
    "id": "51.6922,7.11865"
  },
  {
    "ID": 745296317,
    "Tankstellennummer": "TD035303",
    "Name": "BAD SCHMIEDEBERG KEMBERGER STR",
    "Address": "KEMBERGER STR. 9",
    "Address__1": "",
    "City": "BAD SCHMIEDEBERG",
    "Postcode": 6905,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034925-71176",
    "24 hour?": "FALSE",
    "Latitude": 51.6951,
    "Longitude": 12.7387,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6951,12.7387",
    "IsOpen24Hours": false,
    "id": "51.6951,12.7387"
  },
  {
    "ID": 696398220,
    "Tankstellennummer": "TD035659",
    "Name": "LIPPETAL AUTOHOF STRAENGENBACH",
    "Address": "AUTOHOF STRAENGENBACH 1",
    "Address__1": "",
    "City": "LIPPETAL",
    "Postcode": 59510,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02388-307400",
    "24 hour?": "FALSE",
    "Latitude": 51.6957,
    "Longitude": 7.9671,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6957,7.9671",
    "IsOpen24Hours": false,
    "id": "51.6957,7.9671"
  },
  {
    "ID": 58638712,
    "Tankstellennummer": "TD028662",
    "Name": "HERZBERG FRANKFURTER STR.14",
    "Address": "FRANKFURTER STR.14",
    "Address__1": "",
    "City": "HERZBERG",
    "Postcode": 4916,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03535-247650",
    "24 hour?": "FALSE",
    "Latitude": 51.6972,
    "Longitude": 13.2516,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6972,13.2516",
    "IsOpen24Hours": false,
    "id": "51.6972,13.2516"
  },
  {
    "ID": 1588481345,
    "Tankstellennummer": "TD035394",
    "Name": "WERNE-STOCKUM AUTOHOF NORD-LIP",
    "Address": "AUTOHOF NORD-LIPPE STR. 44",
    "Address__1": "",
    "City": "WERNE-STOCKUM",
    "Postcode": 59368,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02389-780256",
    "24 hour?": "FALSE",
    "Latitude": 51.6985,
    "Longitude": 7.6736,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.6985,7.6736",
    "IsOpen24Hours": false,
    "id": "51.6985,7.6736"
  },
  {
    "ID": 3010,
    "Tankstellennummer": "0FI56",
    "Name": "Aral Tankstelle Moringen, Northeimer Str. 31",
    "Address": "Northeimer Straße 31",
    "Address__1": "",
    "City": "Moringen",
    "Postcode": 37186,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5554400,
    "24 hour?": "FALSE",
    "Latitude": 51.703114,
    "Longitude": 9.875096,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.703114,9.875096",
    "IsOpen24Hours": false,
    "id": "51.703114,9.875096"
  },
  {
    "ID": 3426,
    "Tankstellennummer": "0FL61",
    "Name": "Aral Tankstelle Lippstadt, Ostlandstr. 65",
    "Address": "Ostlandstraße 65",
    "Address__1": "",
    "City": "Lippstadt",
    "Postcode": 59556,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 294180139,
    "24 hour?": "FALSE",
    "Latitude": 51.705443,
    "Longitude": 8.340492,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.705443,8.340492",
    "IsOpen24Hours": false,
    "id": "51.705443,8.340492"
  },
  {
    "ID": 227737193,
    "Tankstellennummer": 610128,
    "Name": "WESTFALEN, SELM",
    "Address": "Olfener Str. 11",
    "Address__1": "",
    "City": "Selm",
    "Postcode": 59379,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02592/983848",
    "24 hour?": "FALSE",
    "Latitude": 51.7057,
    "Longitude": 7.45588,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7057,7.45588",
    "IsOpen24Hours": false,
    "id": "51.7057,7.45588"
  },
  {
    "ID": 2903,
    "Tankstellennummer": "0FH69",
    "Name": "Aral Tankstelle Paderborn, Warburger Str. 120",
    "Address": "Warburger Straße 120",
    "Address__1": "",
    "City": "Paderborn",
    "Postcode": 33100,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 525162703,
    "24 hour?": "FALSE",
    "Latitude": 51.706264,
    "Longitude": 8.777121,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.706264,8.777121",
    "IsOpen24Hours": false,
    "id": "51.706264,8.777121"
  },
  {
    "ID": 1351431275,
    "Tankstellennummer": 610127,
    "Name": "WESTFALEN, OLFEN",
    "Address": "Schlosserstr. 2",
    "Address__1": "",
    "City": "Olfen",
    "Postcode": 59399,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02595/3869153",
    "24 hour?": "TRUE",
    "Latitude": 51.7068,
    "Longitude": 7.39241,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.7068,7.39241",
    "IsOpen24Hours": true,
    "id": "51.7068,7.39241"
  },
  {
    "ID": 921923647,
    "Tankstellennummer": 610544,
    "Name": "WESTFALEN, HAMM",
    "Address": "Ahlener Str. 118",
    "Address__1": "",
    "City": "Hamm",
    "Postcode": 59073,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02381/32480",
    "24 hour?": "FALSE",
    "Latitude": 51.7075,
    "Longitude": 7.83246,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7075,7.83246",
    "IsOpen24Hours": false,
    "id": "51.7075,7.83246"
  },
  {
    "ID": 2294,
    "Tankstellennummer": "0FB16",
    "Name": "Aral Tankstelle Wadersloh, Lippstädter Str. 23",
    "Address": "Lippstädter Straße 23",
    "Address__1": "",
    "City": "Wadersloh",
    "Postcode": 59329,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 25238474,
    "24 hour?": "FALSE",
    "Latitude": 51.70884,
    "Longitude": 8.260127,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.70884,8.260127",
    "IsOpen24Hours": false,
    "id": "51.70884,8.260127"
  },
  {
    "ID": 1707,
    "Tankstellennummer": "0F703",
    "Name": "Aral Tankstelle Paderborn, Borchener Str. 42",
    "Address": "Borchener Straße 42",
    "Address__1": "",
    "City": "Paderborn",
    "Postcode": 33098,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 525172335,
    "24 hour?": "TRUE",
    "Latitude": 51.71086,
    "Longitude": 8.746393,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.71086,8.746393",
    "IsOpen24Hours": true,
    "id": "51.71086,8.746393"
  },
  {
    "ID": 2080993888,
    "Tankstellennummer": 610460,
    "Name": "WESTFALEN, PADERBORN",
    "Address": "Bahnhofstr. 76",
    "Address__1": "",
    "City": "Paderborn",
    "Postcode": 33102,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05251/34367",
    "24 hour?": "TRUE",
    "Latitude": 51.7122,
    "Longitude": 8.73424,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.7122,8.73424",
    "IsOpen24Hours": true,
    "id": "51.7122,8.73424"
  },
  {
    "ID": 1699,
    "Tankstellennummer": "0F698",
    "Name": "Aral Tankstelle Brakel, Driburger Straße 2",
    "Address": "Driburger Straße 2",
    "Address__1": "",
    "City": "Brakel",
    "Postcode": 33034,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52723920240,
    "24 hour?": "FALSE",
    "Latitude": 51.712546,
    "Longitude": 9.177966,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.712546,9.177966",
    "IsOpen24Hours": false,
    "id": "51.712546,9.177966"
  },
  {
    "ID": 1598,
    "Tankstellennummer": "0F633",
    "Name": "Aral Tankstelle Paderborn, Bahnhofstraße 58",
    "Address": "Bahnhofstraße 58",
    "Address__1": "",
    "City": "Paderborn",
    "Postcode": 33102,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 525137111,
    "24 hour?": "TRUE",
    "Latitude": 51.712669,
    "Longitude": 8.73633,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.712669,8.73633",
    "IsOpen24Hours": true,
    "id": "51.712669,8.73633"
  },
  {
    "ID": 2877,
    "Tankstellennummer": "0FH45",
    "Name": "Aral Tankstelle Haltern, Dorstener Str. 666",
    "Address": "Dorstener Straße 666",
    "Address__1": "",
    "City": "Haltern am See",
    "Postcode": 45721,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2360901910,
    "24 hour?": "FALSE",
    "Latitude": 51.714005,
    "Longitude": 7.094333,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.714005,7.094333",
    "IsOpen24Hours": false,
    "id": "51.714005,7.094333"
  },
  {
    "ID": 494408336,
    "Tankstellennummer": 610629,
    "Name": "WESTFALEN, DORSTEN",
    "Address": "Hervester Str. 43-45",
    "Address__1": "",
    "City": "Dorsten",
    "Postcode": 46286,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02369/915030",
    "24 hour?": "FALSE",
    "Latitude": 51.715,
    "Longitude": 7.01242,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.715,7.01242",
    "IsOpen24Hours": false,
    "id": "51.715,7.01242"
  },
  {
    "ID": 1702,
    "Tankstellennummer": "0F701",
    "Name": "Aral Tankstelle Braunlage, Bahnhofstrasse 1",
    "Address": "Bahnhofstrasse 1",
    "Address__1": "",
    "City": "Braunlage",
    "Postcode": 38700,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5520923222,
    "24 hour?": "FALSE",
    "Latitude": 51.720189,
    "Longitude": 10.612492,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.720189,10.612492",
    "IsOpen24Hours": false,
    "id": "51.720189,10.612492"
  },
  {
    "ID": 523265015,
    "Tankstellennummer": 5032,
    "Name": "Agip Cottbus",
    "Address": "Haenchener Strasse 4",
    "Address__1": "",
    "City": "Cottbus",
    "Postcode": 3050,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 35553408",
    "24 hour?": "TRUE",
    "Latitude": 51.72071273,
    "Longitude": 14.34449376,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.72071273,14.34449376",
    "IsOpen24Hours": true,
    "id": "51.72071273,14.34449376"
  },
  {
    "ID": 2885,
    "Tankstellennummer": "0FH53",
    "Name": "Aral Tankstelle Dorsten, Duelmener Str. 8",
    "Address": "Duelmener Straße 8",
    "Address__1": "",
    "City": "Dorsten",
    "Postcode": 46286,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23694240,
    "24 hour?": "FALSE",
    "Latitude": 51.721643,
    "Longitude": 7.012792,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.721643,7.012792",
    "IsOpen24Hours": false,
    "id": "51.721643,7.012792"
  },
  {
    "ID": 1943924840,
    "Tankstellennummer": "TD141130",
    "Name": "OSTERODE WALDSTR. 20",
    "Address": "WALDSTR. 20",
    "Address__1": "",
    "City": "OSTERODE",
    "Postcode": 37520,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05522-2522",
    "24 hour?": "FALSE",
    "Latitude": 51.7259,
    "Longitude": 10.2613,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7259,10.2613",
    "IsOpen24Hours": false,
    "id": "51.7259,10.2613"
  },
  {
    "ID": 3364,
    "Tankstellennummer": "0FL08",
    "Name": "Aral Tankstelle Cottbus, Gelsenkirchener Allee",
    "Address": "Gelsenkirchener Allee 30",
    "Address__1": "",
    "City": "Cottbus",
    "Postcode": 3050,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 355524021,
    "24 hour?": "FALSE",
    "Latitude": 51.726583,
    "Longitude": 14.336133,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.726583,14.336133",
    "IsOpen24Hours": false,
    "id": "51.726583,14.336133"
  },
  {
    "ID": 257946188,
    "Tankstellennummer": "TD028910",
    "Name": "FORST SCHWERINSTR. 9-12",
    "Address": "SCHWERINSTR. 9-12",
    "Address__1": "",
    "City": "FORST",
    "Postcode": 3149,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03562-8334",
    "24 hour?": "FALSE",
    "Latitude": 51.729,
    "Longitude": 14.6286,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.729,14.6286",
    "IsOpen24Hours": false,
    "id": "51.729,14.6286"
  },
  {
    "ID": 1607,
    "Tankstellennummer": "0FE78",
    "Name": "Aral Autohof Northeim, Westerlange / B 3",
    "Address": "Westerlange 2",
    "Address__1": "",
    "City": "Northeim",
    "Postcode": 37154,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5551909730,
    "24 hour?": "TRUE",
    "Latitude": 51.730902,
    "Longitude": 9.972827,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.730902,9.972827",
    "IsOpen24Hours": true,
    "id": "51.730902,9.972827"
  },
  {
    "ID": 1143,
    "Tankstellennummer": "0F277",
    "Name": "Aral Tankstelle Paderborn, Detmolder Straße 75",
    "Address": "Detmolder Straße 75",
    "Address__1": "",
    "City": "Paderborn",
    "Postcode": 33100,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5251527584,
    "24 hour?": "TRUE",
    "Latitude": 51.732299,
    "Longitude": 8.764838,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.732299,8.764838",
    "IsOpen24Hours": true,
    "id": "51.732299,8.764838"
  },
  {
    "ID": 1619,
    "Tankstellennummer": "0F650",
    "Name": "Aral Tankstelle Osterode, Petershütter Allee 2",
    "Address": "Petershütter Allee 2",
    "Address__1": "",
    "City": "Osterode",
    "Postcode": 37520,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5522317986,
    "24 hour?": "TRUE",
    "Latitude": 51.732411,
    "Longitude": 10.238926,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.732411,10.238926",
    "IsOpen24Hours": true,
    "id": "51.732411,10.238926"
  },
  {
    "ID": 1556278187,
    "Tankstellennummer": "TD004283",
    "Name": "PADERBORN VON KETTELER STR. 13",
    "Address": "VON KETTELER STR. 13",
    "Address__1": "",
    "City": "PADERBORN",
    "Postcode": 33106,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05254-5150",
    "24 hour?": "FALSE",
    "Latitude": 51.7338,
    "Longitude": 8.6896,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7338,8.6896",
    "IsOpen24Hours": false,
    "id": "51.7338,8.6896"
  },
  {
    "ID": 315114367,
    "Tankstellennummer": 610187,
    "Name": "WESTFALEN, PADERBORN",
    "Address": "Detmolder Str. 112",
    "Address__1": "",
    "City": "Paderborn",
    "Postcode": 33100,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05251/56824",
    "24 hour?": "FALSE",
    "Latitude": 51.7347,
    "Longitude": 8.7669,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7347,8.7669",
    "IsOpen24Hours": false,
    "id": "51.7347,8.7669"
  },
  {
    "ID": 2288,
    "Tankstellennummer": "0FB12",
    "Name": "Aral Tankstelle Ascheberg, Werner Str.25",
    "Address": "Werner Straße 25",
    "Address__1": "",
    "City": "Ascheberg",
    "Postcode": 59387,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2599449,
    "24 hour?": "FALSE",
    "Latitude": 51.735252,
    "Longitude": 7.662861,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.735252,7.662861",
    "IsOpen24Hours": false,
    "id": "51.735252,7.662861"
  },
  {
    "ID": 1200,
    "Tankstellennummer": "0F314",
    "Name": "Aral Tankstelle Haltern, Weseler Str.102",
    "Address": "Weseler Straße 90",
    "Address__1": "",
    "City": "Haltern",
    "Postcode": 45721,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 23643624,
    "24 hour?": "FALSE",
    "Latitude": 51.74,
    "Longitude": 7.174035,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.74,7.174035",
    "IsOpen24Hours": false,
    "id": "51.74,7.174035"
  },
  {
    "ID": 1344,
    "Tankstellennummer": "0F429",
    "Name": "Aral Tankstelle Nordkirchen, Lüdinghauserstr 60",
    "Address": "Lüdinghauserstr 60",
    "Address__1": "",
    "City": "Nordkirchen",
    "Postcode": 59394,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 259698557,
    "24 hour?": "FALSE",
    "Latitude": 51.742007,
    "Longitude": 7.516944,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.742007,7.516944",
    "IsOpen24Hours": false,
    "id": "51.742007,7.516944"
  },
  {
    "ID": 2103599581,
    "Tankstellennummer": 5015,
    "Name": "Agip Calau",
    "Address": "Finsterwalder Str. 14",
    "Address__1": "",
    "City": "Calau",
    "Postcode": 3205,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 35418019",
    "24 hour?": "TRUE",
    "Latitude": 51.74351496,
    "Longitude": 13.94139301,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.74351496,13.94139301",
    "IsOpen24Hours": true,
    "id": "51.74351496,13.94139301"
  },
  {
    "ID": 3490,
    "Tankstellennummer": "0FM02",
    "Name": "Aral Tankstelle Paderborn, Dubelohstr. 143",
    "Address": "Dubelohstraße 143",
    "Address__1": "",
    "City": "Paderborn",
    "Postcode": 33104,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5254939178,
    "24 hour?": "TRUE",
    "Latitude": 51.745679,
    "Longitude": 8.731283,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.745679,8.731283",
    "IsOpen24Hours": true,
    "id": "51.745679,8.731283"
  },
  {
    "ID": 2901,
    "Tankstellennummer": "0FH67",
    "Name": "Aral Tankstelle Paderborn, Marienloher Str. 87",
    "Address": "Marienloher Straße 87",
    "Address__1": "",
    "City": "Paderborn",
    "Postcode": 33104,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52542430,
    "24 hour?": "FALSE",
    "Latitude": 51.745707,
    "Longitude": 8.741618,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.745707,8.741618",
    "IsOpen24Hours": false,
    "id": "51.745707,8.741618"
  },
  {
    "ID": 798962421,
    "Tankstellennummer": "TD000237",
    "Name": "HALTERN NORDWALL 1",
    "Address": "NORDWALL 50",
    "Address__1": "",
    "City": "HALTERN am See",
    "Postcode": 45721,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02364-3716",
    "24 hour?": "FALSE",
    "Latitude": 51.7458,
    "Longitude": 7.1834,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7458,7.1834",
    "IsOpen24Hours": false,
    "id": "51.7458,7.1834"
  },
  {
    "ID": 333467071,
    "Tankstellennummer": 610623,
    "Name": "WESTFALEN, AHLEN",
    "Address": "Dolberger Str. 136",
    "Address__1": "",
    "City": "Ahlen",
    "Postcode": 59229,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02382/65675",
    "24 hour?": "FALSE",
    "Latitude": 51.7503,
    "Longitude": 7.89772,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7503,7.89772",
    "IsOpen24Hours": false,
    "id": "51.7503,7.89772"
  },
  {
    "ID": 596817054,
    "Tankstellennummer": "TD028845",
    "Name": "COTTBUS KOLKWITZER STR. 66A",
    "Address": "KOLKWITZER STR. 66A",
    "Address__1": "",
    "City": "COTTBUS",
    "Postcode": 3046,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0355-31481",
    "24 hour?": "FALSE",
    "Latitude": 51.7531,
    "Longitude": 14.2975,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7531,14.2975",
    "IsOpen24Hours": false,
    "id": "51.7531,14.2975"
  },
  {
    "ID": 2620,
    "Tankstellennummer": "0FD45",
    "Name": "Aral Tankstelle Köthen, Geuzer Str. / B 185",
    "Address": "Geuzer Straße 32M",
    "Address__1": "",
    "City": "Köthen",
    "Postcode": 6366,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3496550124,
    "24 hour?": "TRUE",
    "Latitude": 51.7546,
    "Longitude": 11.94881,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.7546,11.94881",
    "IsOpen24Hours": true,
    "id": "51.7546,11.94881"
  },
  {
    "ID": 2501,
    "Tankstellennummer": "0FC45",
    "Name": "Aral Tankstelle Aschersleben, Zollberg 70",
    "Address": "Zollberg 70",
    "Address__1": "",
    "City": "Aschersleben",
    "Postcode": 6449,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3473226798,
    "24 hour?": "TRUE",
    "Latitude": 51.757477,
    "Longitude": 11.442081,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.757477,11.442081",
    "IsOpen24Hours": true,
    "id": "51.757477,11.442081"
  },
  {
    "ID": 64208730,
    "Tankstellennummer": 610419,
    "Name": "WESTFALEN, DORSTEN",
    "Address": "Lippramsdorfer Str. 6",
    "Address__1": "",
    "City": "Dorsten",
    "Postcode": 46286,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02369/7305",
    "24 hour?": "FALSE",
    "Latitude": 51.7604,
    "Longitude": 7.00015,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7604,7.00015",
    "IsOpen24Hours": false,
    "id": "51.7604,7.00015"
  },
  {
    "ID": 55559066,
    "Tankstellennummer": "TD035428",
    "Name": "COTTBUS BERLINER STR. 135",
    "Address": "BERLINER STR. 135",
    "Address__1": "",
    "City": "COTTBUS",
    "Postcode": 3046,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0355-4946930",
    "24 hour?": "FALSE",
    "Latitude": 51.7614,
    "Longitude": 14.3247,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7614,14.3247",
    "IsOpen24Hours": false,
    "id": "51.7614,14.3247"
  },
  {
    "ID": 2315,
    "Tankstellennummer": "0FB28",
    "Name": "Aral Tankstelle Ahlen, Beckumer Str. 109",
    "Address": "Beckumer Straße 109",
    "Address__1": "",
    "City": "Ahlen",
    "Postcode": 59229,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 238263484,
    "24 hour?": "TRUE",
    "Latitude": 51.764942,
    "Longitude": 7.912292,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.764942,7.912292",
    "IsOpen24Hours": true,
    "id": "51.764942,7.912292"
  },
  {
    "ID": 661651459,
    "Tankstellennummer": "TD033274",
    "Name": "KOETHEN DESSAUER STR.",
    "Address": "DESSAUER STR.",
    "Address__1": "",
    "City": "KOETHEN",
    "Postcode": 6366,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03496-216047",
    "24 hour?": "FALSE",
    "Latitude": 51.765,
    "Longitude": 11.9876,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.765,11.9876",
    "IsOpen24Hours": false,
    "id": "51.765,11.9876"
  },
  {
    "ID": 2308,
    "Tankstellennummer": "0FB25",
    "Name": "Aral Tankstelle Lüdinghausen, Wolfsberger Str. 39",
    "Address": "Wolfsberger Straße 39",
    "Address__1": "",
    "City": "Lüdinghausen",
    "Postcode": 59348,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 259122670,
    "24 hour?": "FALSE",
    "Latitude": 51.765258,
    "Longitude": 7.442895,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.765258,7.442895",
    "IsOpen24Hours": false,
    "id": "51.765258,7.442895"
  },
  {
    "ID": 2299,
    "Tankstellennummer": "0FB19",
    "Name": "Aral Tankstelle Beckum, Neubeckumer Str.72",
    "Address": "Neubeckumer Straße 72",
    "Address__1": "",
    "City": "Beckum",
    "Postcode": 59269,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 25213605,
    "24 hour?": "TRUE",
    "Latitude": 51.765658,
    "Longitude": 8.042467,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.765658,8.042467",
    "IsOpen24Hours": true,
    "id": "51.765658,8.042467"
  },
  {
    "ID": 2650,
    "Tankstellennummer": "0FD73",
    "Name": "Aral Tankstelle Cottbus, Merzdorfer Weg 1 B",
    "Address": "Merzdorfer Weg 1 B",
    "Address__1": "",
    "City": "Cottbus",
    "Postcode": 3042,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 355722208,
    "24 hour?": "TRUE",
    "Latitude": 51.767249,
    "Longitude": 14.35906,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.767249,14.35906",
    "IsOpen24Hours": true,
    "id": "51.767249,14.35906"
  },
  {
    "ID": 1300,
    "Tankstellennummer": "0F400",
    "Name": "Aral Tankstelle Kleve, Triftstr.118-120",
    "Address": "Triftstraße 118-120",
    "Address__1": "",
    "City": "Kleve",
    "Postcode": 47533,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 282140960,
    "24 hour?": "FALSE",
    "Latitude": 51.773785,
    "Longitude": 6.132118,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.773785,6.132118",
    "IsOpen24Hours": false,
    "id": "51.773785,6.132118"
  },
  {
    "ID": 914248095,
    "Tankstellennummer": 610029,
    "Name": "WESTFALEN, HAMMINKELN",
    "Address": "Bocholter Str. 30a",
    "Address__1": "",
    "City": "Hamminkeln",
    "Postcode": 46499,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02852/968822",
    "24 hour?": "FALSE",
    "Latitude": 51.7771,
    "Longitude": 6.61225,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7771,6.61225",
    "IsOpen24Hours": false,
    "id": "51.7771,6.61225"
  },
  {
    "ID": 1306394247,
    "Tankstellennummer": 610259,
    "Name": "WESTFALEN, DELBRUECK",
    "Address": "Bursdamm 1",
    "Address__1": "",
    "City": "Delbrück",
    "Postcode": 33129,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05250/98780",
    "24 hour?": "FALSE",
    "Latitude": 51.7822,
    "Longitude": 8.62402,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7822,8.62402",
    "IsOpen24Hours": false,
    "id": "51.7822,8.62402"
  },
  {
    "ID": 2636,
    "Tankstellennummer": "0FD60",
    "Name": "Aral Tankstelle Quedlinburg, Harzweg 19",
    "Address": "Harzweg 19",
    "Address__1": "",
    "City": "Quedlinburg",
    "Postcode": 6484,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3946701128,
    "24 hour?": "TRUE",
    "Latitude": 51.783042,
    "Longitude": 11.146766,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.783042,11.146766",
    "IsOpen24Hours": true,
    "id": "51.783042,11.146766"
  },
  {
    "ID": 1265,
    "Tankstellennummer": "0F367",
    "Name": "Aral Tankstelle Kranenburg, Klever Str.85",
    "Address": "Klever Straße 85",
    "Address__1": "",
    "City": "Kranenburg",
    "Postcode": 47559,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2826437,
    "24 hour?": "FALSE",
    "Latitude": 51.783402,
    "Longitude": 6.022668,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.783402,6.022668",
    "IsOpen24Hours": false,
    "id": "51.783402,6.022668"
  },
  {
    "ID": 2653,
    "Tankstellennummer": "0FD74",
    "Name": "Aral Tankstelle Vetschau, Berliner Str./Calauer Str",
    "Address": "Berliner Straße 25a",
    "Address__1": "",
    "City": "Vetschau",
    "Postcode": 3226,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3543371111,
    "24 hour?": "TRUE",
    "Latitude": 51.784779,
    "Longitude": 14.066966,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.784779,14.066966",
    "IsOpen24Hours": true,
    "id": "51.784779,14.066966"
  },
  {
    "ID": 1606,
    "Tankstellennummer": "0F640",
    "Name": "Aral Tankstelle Höxter, Albaxer Straße 63",
    "Address": "Albaxer Straße 63",
    "Address__1": "",
    "City": "Höxter",
    "Postcode": 37671,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5271920071,
    "24 hour?": "TRUE",
    "Latitude": 51.78602,
    "Longitude": 9.387016,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.78602,9.387016",
    "IsOpen24Hours": true,
    "id": "51.78602,9.387016"
  },
  {
    "ID": 140706225,
    "Tankstellennummer": "TD028795",
    "Name": "VETSCHAU BERLINER STR. 23",
    "Address": "BERLINER STR. 23",
    "Address__1": "",
    "City": "VETSCHAU",
    "Postcode": 3226,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035433-2350",
    "24 hour?": "FALSE",
    "Latitude": 51.7861,
    "Longitude": 14.066,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7861,14.066",
    "IsOpen24Hours": false,
    "id": "51.7861,14.066"
  },
  {
    "ID": 1154,
    "Tankstellennummer": "0F007",
    "Name": "Aral Tankstelle Echte, Hauptstrasse",
    "Address": "Hauptstraße 1",
    "Address__1": "",
    "City": "Echte",
    "Postcode": 37589,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5553919618,
    "24 hour?": "TRUE",
    "Latitude": 51.787755,
    "Longitude": 10.064217,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.787755,10.064217",
    "IsOpen24Hours": true,
    "id": "51.787755,10.064217"
  },
  {
    "ID": 646892691,
    "Tankstellennummer": 610189,
    "Name": "WESTFALEN, BAD LIPPSPRINGE",
    "Address": "Detmolder Str. 230",
    "Address__1": "",
    "City": "Bad Lippspringe",
    "Postcode": 33175,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05252/9336467",
    "24 hour?": "FALSE",
    "Latitude": 51.7895,
    "Longitude": 8.82677,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7895,8.82677",
    "IsOpen24Hours": false,
    "id": "51.7895,8.82677"
  },
  {
    "ID": 1923552211,
    "Tankstellennummer": "0FV94",
    "Name": "QUEDLINBURG",
    "Address": "Magdeburger Straße 16",
    "Address__1": "",
    "City": "Quedlinburg",
    "Postcode": 6484,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 39469072182,
    "24 hour?": "TRUE",
    "Latitude": 51.79006,
    "Longitude": 11.16914,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.79006,11.16914",
    "IsOpen24Hours": true,
    "id": "51.79006,11.16914"
  },
  {
    "ID": 863109036,
    "Tankstellennummer": "TD007500",
    "Name": "QUEDLINBURG WESTERHAEUSER STR.",
    "Address": "WESTERHAEUSER STR. 38",
    "Address__1": "",
    "City": "QUEDLINBURG",
    "Postcode": 6484,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03946-702014",
    "24 hour?": "FALSE",
    "Latitude": 51.7923,
    "Longitude": 11.1291,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7923,11.1291",
    "IsOpen24Hours": false,
    "id": "51.7923,11.1291"
  },
  {
    "ID": 1024439143,
    "Tankstellennummer": 610416,
    "Name": "WESTFALEN, ASCHEBERG",
    "Address": "Steinfurter Str. 24",
    "Address__1": "",
    "City": "Ascheberg",
    "Postcode": 59387,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02593/444",
    "24 hour?": "FALSE",
    "Latitude": 51.7926,
    "Longitude": 7.62922,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7926,7.62922",
    "IsOpen24Hours": false,
    "id": "51.7926,7.62922"
  },
  {
    "ID": 2629,
    "Tankstellennummer": "0FD54",
    "Name": "Aral Tankstelle Jessen, R.-Luxemburg-Str. 105",
    "Address": "R.-Luxemburg-Straße 105",
    "Address__1": "",
    "City": "Jessen",
    "Postcode": 6917,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3537213597,
    "24 hour?": "TRUE",
    "Latitude": 51.796673,
    "Longitude": 12.974008,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.796673,12.974008",
    "IsOpen24Hours": true,
    "id": "51.796673,12.974008"
  },
  {
    "ID": 1571942742,
    "Tankstellennummer": "TD028894",
    "Name": "JESSEN ROSA-LUXEMBURG-STR. 133",
    "Address": "ROSA-LUXEMBURG-STR. 133",
    "Address__1": "",
    "City": "JESSEN",
    "Postcode": 6917,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03537-212408",
    "24 hour?": "FALSE",
    "Latitude": 51.7972,
    "Longitude": 12.9874,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.7972,12.9874",
    "IsOpen24Hours": false,
    "id": "51.7972,12.9874"
  },
  {
    "ID": 2540,
    "Tankstellennummer": "0FC82",
    "Name": "Aral Tankstelle Blankenburg, Neue Halberstädter Str. 6",
    "Address": "Neue Halberstädter Str.67",
    "Address__1": "",
    "City": "Blankenburg",
    "Postcode": 38889,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3944365438,
    "24 hour?": "TRUE",
    "Latitude": 51.799814,
    "Longitude": 10.949071,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.799814,10.949071",
    "IsOpen24Hours": true,
    "id": "51.799814,10.949071"
  },
  {
    "ID": 557616176,
    "Tankstellennummer": 610002,
    "Name": "WESTFALEN, DRENSTEINFURT",
    "Address": "Bahnhofstr. 30",
    "Address__1": "",
    "City": "Drensteinfurt",
    "Postcode": 48317,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02508/1295",
    "24 hour?": "FALSE",
    "Latitude": 51.8,
    "Longitude": 7.73369,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8,7.73369",
    "IsOpen24Hours": false,
    "id": "51.8,7.73369"
  },
  {
    "ID": 2886,
    "Tankstellennummer": "0FH54",
    "Name": "Aral Tankstelle Ascheberg, Steinfurter Str. 72",
    "Address": "Steinfurter Straße 72",
    "Address__1": "",
    "City": "Ascheberg",
    "Postcode": 59387,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 25935536,
    "24 hour?": "FALSE",
    "Latitude": 51.800417,
    "Longitude": 7.649596,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.800417,7.649596",
    "IsOpen24Hours": false,
    "id": "51.800417,7.649596"
  },
  {
    "ID": 329331300,
    "Tankstellennummer": "TD000091",
    "Name": "KLEVE EMMERICHER STRASSE 99",
    "Address": "EMMERICHER STRASSE 99",
    "Address__1": "",
    "City": "KLEVE",
    "Postcode": 47533,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02821-980082",
    "24 hour?": "FALSE",
    "Latitude": 51.8008,
    "Longitude": 6.1626,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8008,6.1626",
    "IsOpen24Hours": false,
    "id": "51.8008,6.1626"
  },
  {
    "ID": 1061906255,
    "Tankstellennummer": "TD040428",
    "Name": "SCHLANGEN PADERBORNER STR. 78",
    "Address": "PADERBORNER STR. 78",
    "Address__1": "",
    "City": "SCHLANGEN",
    "Postcode": 33189,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05252-7109",
    "24 hour?": "FALSE",
    "Latitude": 51.8008,
    "Longitude": 8.8371,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8008,8.8371",
    "IsOpen24Hours": false,
    "id": "51.8008,8.8371"
  },
  {
    "ID": 817883639,
    "Tankstellennummer": "0FU96",
    "Name": "GUSTEN",
    "Address": "Bernburger Str. 4a",
    "Address__1": "",
    "City": "Güsten",
    "Postcode": 39439,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3926260005,
    "24 hour?": "TRUE",
    "Latitude": 51.801557,
    "Longitude": 11.620835,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.801557,11.620835",
    "IsOpen24Hours": true,
    "id": "51.801557,11.620835"
  },
  {
    "ID": 246334071,
    "Tankstellennummer": "TD030809",
    "Name": "BERNBURG AM PLATZ DER JUGEND 2",
    "Address": "AM PLATZ DER JUGEND 21",
    "Address__1": "",
    "City": "BERNBURG",
    "Postcode": 6406,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03471-350353",
    "24 hour?": "FALSE",
    "Latitude": 51.8041,
    "Longitude": 11.7385,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8041,11.7385",
    "IsOpen24Hours": false,
    "id": "51.8041,11.7385"
  },
  {
    "ID": 2303,
    "Tankstellennummer": "0FB22",
    "Name": "Aral Tankstelle Rietberg, Delbrücker Str.22",
    "Address": "Delbrücker Straße 22",
    "Address__1": "",
    "City": "Rietberg",
    "Postcode": 33397,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52448817,
    "24 hour?": "FALSE",
    "Latitude": 51.804138,
    "Longitude": 8.425995,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.804138,8.425995",
    "IsOpen24Hours": false,
    "id": "51.804138,8.425995"
  },
  {
    "ID": 123960406,
    "Tankstellennummer": 610144,
    "Name": "WESTFALEN, AHLEN",
    "Address": "Hauptstr. 19",
    "Address__1": "",
    "City": "Ahlen",
    "Postcode": 59227,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02528/3434",
    "24 hour?": "FALSE",
    "Latitude": 51.8042,
    "Longitude": 7.94689,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8042,7.94689",
    "IsOpen24Hours": false,
    "id": "51.8042,7.94689"
  },
  {
    "ID": 1025683558,
    "Tankstellennummer": 610503,
    "Name": "WESTFALEN, BECKUM",
    "Address": "Ennigerloher Str. 13",
    "Address__1": "",
    "City": "Beckum",
    "Postcode": 59269,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02525/3884",
    "24 hour?": "FALSE",
    "Latitude": 51.8047,
    "Longitude": 8.02537,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8047,8.02537",
    "IsOpen24Hours": false,
    "id": "51.8047,8.02537"
  },
  {
    "ID": 2103532389,
    "Tankstellennummer": "TD037978",
    "Name": "QUEDLINBURG HALBERSTAEDTER STR",
    "Address": "HALBERSTAEDTER STR.87",
    "Address__1": "",
    "City": "QUEDLINBURG",
    "Postcode": 6484,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03946-9019080",
    "24 hour?": "FALSE",
    "Latitude": 51.8091,
    "Longitude": 11.1287,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8091,11.1287",
    "IsOpen24Hours": false,
    "id": "51.8091,11.1287"
  },
  {
    "ID": 949174420,
    "Tankstellennummer": 610502,
    "Name": "WESTFALEN, OELDE",
    "Address": "In der Geist 110",
    "Address__1": "",
    "City": "Oelde",
    "Postcode": 59302,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02522/901555",
    "24 hour?": "TRUE",
    "Latitude": 51.8114,
    "Longitude": 8.13365,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.8114,8.13365",
    "IsOpen24Hours": true,
    "id": "51.8114,8.13365"
  },
  {
    "ID": 1139,
    "Tankstellennummer": "0F274",
    "Name": "Aral Tankstelle Einbeck, Altendorfer Tor",
    "Address": "Altendorfer Tor",
    "Address__1": "",
    "City": "Einbeck",
    "Postcode": 37574,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 556174228,
    "24 hour?": "TRUE",
    "Latitude": 51.811566,
    "Longitude": 9.890761,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.811566,9.890761",
    "IsOpen24Hours": true,
    "id": "51.811566,9.890761"
  },
  {
    "ID": 564313923,
    "Tankstellennummer": "TD029447",
    "Name": "SCHOENEWALDE BRANDISERSTR. 1",
    "Address": "BRANDISERSTR.1",
    "Address__1": "",
    "City": "SCHOENEWALDE",
    "Postcode": 4916,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 353626060,
    "24 hour?": "FALSE",
    "Latitude": 51.8134,
    "Longitude": 13.209,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8134,13.209",
    "IsOpen24Hours": false,
    "id": "51.8134,13.209"
  },
  {
    "ID": 2038856621,
    "Tankstellennummer": 610456,
    "Name": "WESTFALEN, HOEVELHOF",
    "Address": "Paderborner Str. 32",
    "Address__1": "",
    "City": "Hövelhof",
    "Postcode": 33161,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05257/97990",
    "24 hour?": "FALSE",
    "Latitude": 51.8176,
    "Longitude": 8.66084,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8176,8.66084",
    "IsOpen24Hours": false,
    "id": "51.8176,8.66084"
  },
  {
    "ID": 3307,
    "Tankstellennummer": "0FK65",
    "Name": "Aral Tankstelle Isselburg, Dekkers Waide 1",
    "Address": "Dekkers Waide 1",
    "Address__1": "",
    "City": "Isselburg",
    "Postcode": 46419,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 287495672,
    "24 hour?": "TRUE",
    "Latitude": 51.818341,
    "Longitude": 6.442688,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.818341,6.442688",
    "IsOpen24Hours": true,
    "id": "51.818341,6.442688"
  },
  {
    "ID": 1725,
    "Tankstellennummer": "0F717",
    "Name": "Aral Tankstelle Hövelhof, Paderborner Straße 22",
    "Address": "Paderborner Straße 22",
    "Address__1": "",
    "City": "Hövelhof",
    "Postcode": 33161,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52572631,
    "24 hour?": "FALSE",
    "Latitude": 51.818751,
    "Longitude": 8.659243,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.818751,8.659243",
    "IsOpen24Hours": false,
    "id": "51.818751,8.659243"
  },
  {
    "ID": 2858,
    "Tankstellennummer": "0FH33",
    "Name": "Aral Tankstelle Clausthal-Zellerfeld, Goslarsche Str. 64",
    "Address": "Goslarsche Straße 64",
    "Address__1": "",
    "City": "Clausthal-Zellerfeld",
    "Postcode": 38678,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5323982818,
    "24 hour?": "FALSE",
    "Latitude": 51.821126,
    "Longitude": 10.342064,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.821126,10.342064",
    "IsOpen24Hours": false,
    "id": "51.821126,10.342064"
  },
  {
    "ID": 1980568496,
    "Tankstellennummer": "TD005272",
    "Name": "DUELMEN HALTERNER STR. 93",
    "Address": "HALTERNER STR. 93",
    "Address__1": "",
    "City": "DUELMEN",
    "Postcode": 48249,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02594-4612",
    "24 hour?": "FALSE",
    "Latitude": 51.8235,
    "Longitude": 7.2692,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8235,7.2692",
    "IsOpen24Hours": false,
    "id": "51.8235,7.2692"
  },
  {
    "ID": 790806467,
    "Tankstellennummer": 610403,
    "Name": "WESTFALEN, DUELMEN",
    "Address": "Halterner Str. 61",
    "Address__1": "",
    "City": "Dülmen",
    "Postcode": 48249,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02594/98108",
    "24 hour?": "FALSE",
    "Latitude": 51.8255,
    "Longitude": 7.27279,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8255,7.27279",
    "IsOpen24Hours": false,
    "id": "51.8255,7.27279"
  },
  {
    "ID": 3395,
    "Tankstellennummer": "0FL35",
    "Name": "Aral Tankstelle Dessau, Junkersstr./Weststr. 28",
    "Address": "Junkersstraße 28",
    "Address__1": "",
    "City": "Dessau-Rosslau",
    "Postcode": 6847,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 340517101,
    "24 hour?": "TRUE",
    "Latitude": 51.826171,
    "Longitude": 12.214941,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.826171,12.214941",
    "IsOpen24Hours": true,
    "id": "51.826171,12.214941"
  },
  {
    "ID": 28468,
    "Tankstellennummer": "0FP45",
    "Name": "Aral Tankstelle Rheda-Wiedenbrück, Aurea 1+3",
    "Address": "Aurea 1-3",
    "Address__1": "",
    "City": "Rheda-Wiedenbrück",
    "Postcode": 33378,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 25228343732,
    "24 hour?": "TRUE",
    "Latitude": 51.831232,
    "Longitude": 8.218466,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.831232,8.218466",
    "IsOpen24Hours": true,
    "id": "51.831232,8.218466"
  },
  {
    "ID": 2306,
    "Tankstellennummer": "0FB23",
    "Name": "Aral Tankstelle Oelde, Berliner Ring 21",
    "Address": "Berliner Ring 21",
    "Address__1": "",
    "City": "Oelde",
    "Postcode": 59302,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 25223621,
    "24 hour?": "FALSE",
    "Latitude": 51.831344,
    "Longitude": 8.150928,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.831344,8.150928",
    "IsOpen24Hours": false,
    "id": "51.831344,8.150928"
  },
  {
    "ID": 590738673,
    "Tankstellennummer": "TD004416",
    "Name": "RIETBERG LANGE STR. 147",
    "Address": "LANGE STR. 147",
    "Address__1": "",
    "City": "RIETBERG",
    "Postcode": 33397,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05244-2318",
    "24 hour?": "FALSE",
    "Latitude": 51.8325,
    "Longitude": 8.4453,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8325,8.4453",
    "IsOpen24Hours": false,
    "id": "51.8325,8.4453"
  },
  {
    "ID": 1589,
    "Tankstellennummer": "0F626",
    "Name": "Aral Tankstelle Holzminden, Allersheimer Str.66",
    "Address": "Allersheimer Straße 66",
    "Address__1": "",
    "City": "Holzminden",
    "Postcode": 37603,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 553193510,
    "24 hour?": "FALSE",
    "Latitude": 51.836518,
    "Longitude": 9.46357,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.836518,9.46357",
    "IsOpen24Hours": false,
    "id": "51.836518,9.46357"
  },
  {
    "ID": 2868,
    "Tankstellennummer": "0FH39",
    "Name": "Aral Tankstelle Bocholt, Muensterstrasse 208",
    "Address": "Münsterstraße 208",
    "Address__1": "",
    "City": "Bocholt",
    "Postcode": 46397,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2871227206,
    "24 hour?": "FALSE",
    "Latitude": 51.837269,
    "Longitude": 6.642715,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.837269,6.642715",
    "IsOpen24Hours": false,
    "id": "51.837269,6.642715"
  },
  {
    "ID": 744107652,
    "Tankstellennummer": "TD027243",
    "Name": "WERNIGERODE AM ANGER",
    "Address": "AM ANGER",
    "Address__1": "",
    "City": "WERNIGERODE",
    "Postcode": 38855,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03943-23094",
    "24 hour?": "FALSE",
    "Latitude": 51.8379,
    "Longitude": 10.7947,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8379,10.7947",
    "IsOpen24Hours": false,
    "id": "51.8379,10.7947"
  },
  {
    "ID": 3271,
    "Tankstellennummer": "0FK40",
    "Name": "Aral Tankstelle Bocholt, Westend 50",
    "Address": "Westend 52",
    "Address__1": "",
    "City": "Bocholt",
    "Postcode": 46399,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2871233642,
    "24 hour?": "FALSE",
    "Latitude": 51.838627,
    "Longitude": 6.601342,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.838627,6.601342",
    "IsOpen24Hours": false,
    "id": "51.838627,6.601342"
  },
  {
    "ID": 243793269,
    "Tankstellennummer": 610713,
    "Name": "WESTFALEN, BORKEN",
    "Address": "Raesfelder Str. 24",
    "Address__1": "",
    "City": "Borken",
    "Postcode": 46325,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02861/98068-50",
    "24 hour?": "FALSE",
    "Latitude": 51.8387,
    "Longitude": 6.85795,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8387,6.85795",
    "IsOpen24Hours": false,
    "id": "51.8387,6.85795"
  },
  {
    "ID": 747667545,
    "Tankstellennummer": 610631,
    "Name": "WESTFALEN, ENNIGERLOH",
    "Address": "Westkirchener Str. 7-9",
    "Address__1": "",
    "City": "Ennigerloh",
    "Postcode": 59320,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02524/5640",
    "24 hour?": "FALSE",
    "Latitude": 51.8393,
    "Longitude": 8.02614,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8393,8.02614",
    "IsOpen24Hours": false,
    "id": "51.8393,8.02614"
  },
  {
    "ID": 48072853,
    "Tankstellennummer": "TD005280",
    "Name": "DUELMEN MUENSTERSTR. 144",
    "Address": "MUENSTERSTR. 144",
    "Address__1": "",
    "City": "DUELMEN",
    "Postcode": 48249,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02594-2577",
    "24 hour?": "FALSE",
    "Latitude": 51.8394,
    "Longitude": 7.2869,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8394,7.2869",
    "IsOpen24Hours": false,
    "id": "51.8394,7.2869"
  },
  {
    "ID": 16604266,
    "Tankstellennummer": 610512,
    "Name": "WESTFALEN, DUELMEN",
    "Address": "Coesfelder Str. 182",
    "Address__1": "",
    "City": "Dülmen",
    "Postcode": 48249,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02594/1507",
    "24 hour?": "FALSE",
    "Latitude": 51.8396,
    "Longitude": 7.26597,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8396,7.26597",
    "IsOpen24Hours": false,
    "id": "51.8396,7.26597"
  },
  {
    "ID": 392843523,
    "Tankstellennummer": "TD035295",
    "Name": "NIENBURG AN DER LANDSTR. 73",
    "Address": "AN DER LANDSTR. 73",
    "Address__1": "",
    "City": "NIENBURG",
    "Postcode": 6429,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "034721-22838",
    "24 hour?": "FALSE",
    "Latitude": 51.8401,
    "Longitude": 11.7536,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8401,11.7536",
    "IsOpen24Hours": false,
    "id": "51.8401,11.7536"
  },
  {
    "ID": 1333677721,
    "Tankstellennummer": 610085,
    "Name": "WESTFALEN, REKEN",
    "Address": "Zum Heubach 3",
    "Address__1": "",
    "City": "REKEN",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.8411,
    "Longitude": 7.09537,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8411,7.09537",
    "IsOpen24Hours": false,
    "id": "51.8411,7.09537"
  },
  {
    "ID": 1613119882,
    "Tankstellennummer": 610129,
    "Name": "WESTFALEN, SENDENHORST",
    "Address": "Osttor 63-65",
    "Address__1": "",
    "City": "Sendenhorst",
    "Postcode": 48324,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02526/1865",
    "24 hour?": "FALSE",
    "Latitude": 51.8418,
    "Longitude": 7.84124,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8418,7.84124",
    "IsOpen24Hours": false,
    "id": "51.8418,7.84124"
  },
  {
    "ID": 2128548345,
    "Tankstellennummer": "TD007856",
    "Name": "BORKEN NORDRING 210",
    "Address": "NORDRING 210",
    "Address__1": "",
    "City": "BORKEN",
    "Postcode": 46325,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02861-5113",
    "24 hour?": "FALSE",
    "Latitude": 51.8422,
    "Longitude": 6.8382,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8422,6.8382",
    "IsOpen24Hours": false,
    "id": "51.8422,6.8382"
  },
  {
    "ID": 241103690,
    "Tankstellennummer": "0FO64",
    "Name": "EMMERICH",
    "Address": "Marie-Curie-Straße 2",
    "Address__1": "",
    "City": "Emmerich",
    "Postcode": 46446,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 282297016,
    "24 hour?": "TRUE",
    "Latitude": 51.842421,
    "Longitude": 6.275239,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.842421,6.275239",
    "IsOpen24Hours": true,
    "id": "51.842421,6.275239"
  },
  {
    "ID": 1072541108,
    "Tankstellennummer": 610627,
    "Name": "WESTFALEN, BOCHOLT",
    "Address": "Dinxperloer Str. 55",
    "Address__1": "",
    "City": "Bocholt",
    "Postcode": 46399,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02871/42976",
    "24 hour?": "FALSE",
    "Latitude": 51.8431,
    "Longitude": 6.60602,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8431,6.60602",
    "IsOpen24Hours": false,
    "id": "51.8431,6.60602"
  },
  {
    "ID": 150967206,
    "Tankstellennummer": 610130,
    "Name": "WESTFALEN, SENDENHORST",
    "Address": "Westtor 40",
    "Address__1": "",
    "City": "Sendenhorst",
    "Postcode": 48324,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02526/1286",
    "24 hour?": "FALSE",
    "Latitude": 51.8432,
    "Longitude": 7.81461,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8432,7.81461",
    "IsOpen24Hours": false,
    "id": "51.8432,7.81461"
  },
  {
    "ID": 1132217386,
    "Tankstellennummer": 610184,
    "Name": "WESTFALEN, DUELMEN",
    "Address": "Auf dem Quellberg 18",
    "Address__1": "",
    "City": "Dülmen",
    "Postcode": 48249,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02594/6831",
    "24 hour?": "TRUE",
    "Latitude": 51.8435,
    "Longitude": 7.2946,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.8435,7.2946",
    "IsOpen24Hours": true,
    "id": "51.8435,7.2946"
  },
  {
    "ID": 1021746311,
    "Tankstellennummer": 610550,
    "Name": "WESTFALEN, RHEDE",
    "Address": "Vardingholter Str. 10",
    "Address__1": "",
    "City": "Rhede",
    "Postcode": 46414,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02872/3767",
    "24 hour?": "FALSE",
    "Latitude": 51.8449,
    "Longitude": 6.69437,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8449,6.69437",
    "IsOpen24Hours": false,
    "id": "51.8449,6.69437"
  },
  {
    "ID": 752008131,
    "Tankstellennummer": 610616,
    "Name": "WESTFALEN, RHEDA-WIEDENBRUECK",
    "Address": "Bielefelder Str. 107",
    "Address__1": "",
    "City": "Rheda-Wiedenbrück",
    "Postcode": 33378,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05242/55106",
    "24 hour?": "FALSE",
    "Latitude": 51.8459,
    "Longitude": 8.32075,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8459,8.32075",
    "IsOpen24Hours": false,
    "id": "51.8459,8.32075"
  },
  {
    "ID": 59698975,
    "Tankstellennummer": "TD028837",
    "Name": "LUEBBENAU/OT BOBLITZ BOBLITZER",
    "Address": "BOBLITZER CHAUSSEESTR. 2",
    "Address__1": "",
    "City": "LUEBBENAU/OT BOBLITZ",
    "Postcode": 3222,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03542-44138",
    "24 hour?": "FALSE",
    "Latitude": 51.8467,
    "Longitude": 13.9724,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8467,13.9724",
    "IsOpen24Hours": false,
    "id": "51.8467,13.9724"
  },
  {
    "ID": 868479841,
    "Tankstellennummer": "0FU60",
    "Name": "SENDEN",
    "Address": "Daimler Straße 2",
    "Address__1": "",
    "City": "Senden",
    "Postcode": 48308,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2597960750,
    "24 hour?": "FALSE",
    "Latitude": 51.846738,
    "Longitude": 7.484689,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.846738,7.484689",
    "IsOpen24Hours": false,
    "id": "51.846738,7.484689"
  },
  {
    "ID": 2671,
    "Tankstellennummer": "0FD90",
    "Name": "Aral Tankstelle Dessau, Albrechtstraße 35",
    "Address": "Albrechtstraße 35",
    "Address__1": "",
    "City": "Dessau-Rosslau",
    "Postcode": 6844,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3402210103,
    "24 hour?": "FALSE",
    "Latitude": 51.848343,
    "Longitude": 12.241853,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.848343,12.241853",
    "IsOpen24Hours": false,
    "id": "51.848343,12.241853"
  },
  {
    "ID": 813609861,
    "Tankstellennummer": 610088,
    "Name": "WESTFALEN, DUELMEN",
    "Address": "Rekener Str. 100",
    "Address__1": "",
    "City": "Dülmen",
    "Postcode": 48249,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02594/2501",
    "24 hour?": "FALSE",
    "Latitude": 51.8484,
    "Longitude": 7.19424,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8484,7.19424",
    "IsOpen24Hours": false,
    "id": "51.8484,7.19424"
  },
  {
    "ID": 1294914035,
    "Tankstellennummer": "TD030734",
    "Name": "DESSAU ALBRECHTSTR. 95",
    "Address": "ALBRECHTSTR. 95",
    "Address__1": "",
    "City": "DESSAU",
    "Postcode": 6844,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0340-214417",
    "24 hour?": "FALSE",
    "Latitude": 51.8488,
    "Longitude": 12.2404,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8488,12.2404",
    "IsOpen24Hours": false,
    "id": "51.8488,12.2404"
  },
  {
    "ID": 591773686,
    "Tankstellennummer": "TD028704",
    "Name": "LUCKAU BERLINER STR. 1",
    "Address": "BERLINER STR. 1",
    "Address__1": "",
    "City": "LUCKAU",
    "Postcode": 15926,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03544-2347",
    "24 hour?": "FALSE",
    "Latitude": 51.8517,
    "Longitude": 13.7032,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8517,13.7032",
    "IsOpen24Hours": false,
    "id": "51.8517,13.7032"
  },
  {
    "ID": 2494,
    "Tankstellennummer": "0FC40",
    "Name": "Aral Tankstelle Wernigerode, Dornbergsweg 49",
    "Address": "Dornbergsweg 49",
    "Address__1": "",
    "City": "Wernigerode",
    "Postcode": 38855,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3943533318,
    "24 hour?": "TRUE",
    "Latitude": 51.852873,
    "Longitude": 10.789973,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.852873,10.789973",
    "IsOpen24Hours": true,
    "id": "51.852873,10.789973"
  },
  {
    "ID": 210635649,
    "Tankstellennummer": "TD035345",
    "Name": "PEITZ COTTBUSER STRASSE 12 A",
    "Address": "COTTBUSER STRASSE 12 A",
    "Address__1": "",
    "City": "PEITZ",
    "Postcode": 3185,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035601-80645",
    "24 hour?": "FALSE",
    "Latitude": 51.8532,
    "Longitude": 14.4065,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8532,14.4065",
    "IsOpen24Hours": false,
    "id": "51.8532,14.4065"
  },
  {
    "ID": 2637,
    "Tankstellennummer": "0FD61",
    "Name": "Aral Tankstelle Lübbenau, Str. des Friedens 40",
    "Address": "Straße des Friedens 40",
    "Address__1": "",
    "City": "Lübbenau",
    "Postcode": 3222,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 354244848,
    "24 hour?": "TRUE",
    "Latitude": 51.853334,
    "Longitude": 13.949292,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.853334,13.949292",
    "IsOpen24Hours": true,
    "id": "51.853334,13.949292"
  },
  {
    "ID": 142669281,
    "Tankstellennummer": "TD005421",
    "Name": "SENDEN ANTON-AULKE-RING 10",
    "Address": "ANTON-AULKE-RING 10",
    "Address__1": "",
    "City": "SENDEN",
    "Postcode": 48308,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02597-5547",
    "24 hour?": "FALSE",
    "Latitude": 51.8554,
    "Longitude": 7.4921,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8554,7.4921",
    "IsOpen24Hours": false,
    "id": "51.8554,7.4921"
  },
  {
    "ID": 422796455,
    "Tankstellennummer": 610566,
    "Name": "WESTFALEN, SENDEN",
    "Address": "Münsterstr. 52",
    "Address__1": "",
    "City": "Senden",
    "Postcode": 48308,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02597/5518",
    "24 hour?": "FALSE",
    "Latitude": 51.8602,
    "Longitude": 7.49175,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8602,7.49175",
    "IsOpen24Hours": false,
    "id": "51.8602,7.49175"
  },
  {
    "ID": 1943053361,
    "Tankstellennummer": 610402,
    "Name": "WESTFALEN, DUELMEN",
    "Address": "Weseler Str. 3",
    "Address__1": "",
    "City": "DUELMEN",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.8648,
    "Longitude": 7.36028,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8648,7.36028",
    "IsOpen24Hours": false,
    "id": "51.8648,7.36028"
  },
  {
    "ID": 2496,
    "Tankstellennummer": "0FC42",
    "Name": "Aral Tankstelle Lutherstadt Wittenbe, Dessauer Str. 274 a",
    "Address": "Dessauer Straße 274 a",
    "Address__1": "",
    "City": "Lutherstadt Wittenbe",
    "Postcode": 6886,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3491667838,
    "24 hour?": "FALSE",
    "Latitude": 51.865645,
    "Longitude": 12.621698,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.865645,12.621698",
    "IsOpen24Hours": false,
    "id": "51.865645,12.621698"
  },
  {
    "ID": 405787734,
    "Tankstellennummer": "TD034686",
    "Name": "WITTENBERG DESSAUER STR. 13",
    "Address": "DESSAUER STR. 13",
    "Address__1": "",
    "City": "WITTENBERG",
    "Postcode": 6886,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03491-666986",
    "24 hour?": "FALSE",
    "Latitude": 51.8657,
    "Longitude": 12.6151,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8657,12.6151",
    "IsOpen24Hours": false,
    "id": "51.8657,12.6151"
  },
  {
    "ID": 1132326837,
    "Tankstellennummer": "0FQ07",
    "Name": "GUTERSLOH",
    "Address": "A2 / Brockweg 300",
    "Address__1": "",
    "City": "Gütersloh",
    "Postcode": 33334,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 524151100,
    "24 hour?": "TRUE",
    "Latitude": 51.866303,
    "Longitude": 8.369209,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.866303,8.369209",
    "IsOpen24Hours": true,
    "id": "51.866303,8.369209"
  },
  {
    "ID": 2561,
    "Tankstellennummer": "0FC96",
    "Name": "Aral Tankstelle Staßfurt, Löderburger Straße",
    "Address": "Löderburger Straße",
    "Address__1": "",
    "City": "Staßfurt",
    "Postcode": 39418,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3925621597,
    "24 hour?": "FALSE",
    "Latitude": 51.867405,
    "Longitude": 11.556805,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.867405,11.556805",
    "IsOpen24Hours": false,
    "id": "51.867405,11.556805"
  },
  {
    "ID": 306160038,
    "Tankstellennummer": "TD028589",
    "Name": "DAHME JUETERBOGER CH. 8",
    "Address": "JUETERBOGER CH. 8",
    "Address__1": "",
    "City": "DAHME",
    "Postcode": 15936,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035451-8308",
    "24 hour?": "FALSE",
    "Latitude": 51.8687,
    "Longitude": 13.4131,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8687,13.4131",
    "IsOpen24Hours": false,
    "id": "51.8687,13.4131"
  },
  {
    "ID": 1385996253,
    "Tankstellennummer": 610457,
    "Name": "WESTFALEN, HORN",
    "Address": "Mittelstr. 20",
    "Address__1": "",
    "City": "Horn-Bad Meinberg",
    "Postcode": 32805,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05234/2540",
    "24 hour?": "FALSE",
    "Latitude": 51.8713,
    "Longitude": 8.94023,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8713,8.94023",
    "IsOpen24Hours": false,
    "id": "51.8713,8.94023"
  },
  {
    "ID": 543084182,
    "Tankstellennummer": "TD000702",
    "Name": "ILSENBURG APFELWEG 2",
    "Address": "APFELWEG 2",
    "Address__1": "",
    "City": "ILSENBURG",
    "Postcode": 38871,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039452-49262",
    "24 hour?": "FALSE",
    "Latitude": 51.8731,
    "Longitude": 10.6922,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8731,10.6922",
    "IsOpen24Hours": false,
    "id": "51.8731,10.6922"
  },
  {
    "ID": 131011246,
    "Tankstellennummer": 610063,
    "Name": "WESTFALEN, WARENDORF",
    "Address": "Raiffeisenstr. 31 - 33",
    "Address__1": "",
    "City": "WARENDORF",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.8741,
    "Longitude": 7.91428,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8741,7.91428",
    "IsOpen24Hours": false,
    "id": "51.8741,7.91428"
  },
  {
    "ID": 1038225748,
    "Tankstellennummer": "0FX05",
    "Name": "GUTERSLOH",
    "Address": "A2",
    "Address__1": "",
    "City": "Gütersloh",
    "Postcode": 33334,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 524151600,
    "24 hour?": "TRUE",
    "Latitude": 51.8758618,
    "Longitude": 8.418065,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.8758618,8.418065",
    "IsOpen24Hours": true,
    "id": "51.8758618,8.418065"
  },
  {
    "ID": 2495,
    "Tankstellennummer": "0FC41",
    "Name": "Aral Tankstelle Lutherstadt Wittenbe, Berliner Str. 39",
    "Address": "Berliner Straße 39",
    "Address__1": "",
    "City": "Lutherstadt Wittenbe",
    "Postcode": 6886,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3491481100,
    "24 hour?": "TRUE",
    "Latitude": 51.878665,
    "Longitude": 12.651871,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.878665,12.651871",
    "IsOpen24Hours": true,
    "id": "51.878665,12.651871"
  },
  {
    "ID": 660761883,
    "Tankstellennummer": "TD004317",
    "Name": "HERZEBROCK CLARHOLZER STR.28",
    "Address": "CLARHOLZER STR.28",
    "Address__1": "",
    "City": "HERZEBROCK",
    "Postcode": 33442,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05245-2709",
    "24 hour?": "FALSE",
    "Latitude": 51.88,
    "Longitude": 8.2388,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.88,8.2388",
    "IsOpen24Hours": false,
    "id": "51.88,8.2388"
  },
  {
    "ID": 1639792027,
    "Tankstellennummer": "0FO50",
    "Name": "EMMERICH",
    "Address": "Kattegatweg 1",
    "Address__1": "",
    "City": "Emmerich",
    "Postcode": 46446,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2828903520,
    "24 hour?": "TRUE",
    "Latitude": 51.880266,
    "Longitude": 6.173678,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.880266,6.173678",
    "IsOpen24Hours": true,
    "id": "51.880266,6.173678"
  },
  {
    "ID": 852516513,
    "Tankstellennummer": 610171,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Davertstr. 53",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48163,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02501/4409170",
    "24 hour?": "FALSE",
    "Latitude": 51.8829,
    "Longitude": 7.60634,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8829,7.60634",
    "IsOpen24Hours": false,
    "id": "51.8829,7.60634"
  },
  {
    "ID": 2298,
    "Tankstellennummer": "0FB18",
    "Name": "Aral Tankstelle Herzebrock-Clarholz, Clarholzer Str.73",
    "Address": "Clarholzer Straße 73",
    "Address__1": "",
    "City": "Herzebrock-Clarholz",
    "Postcode": 33442,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52452066,
    "24 hour?": "FALSE",
    "Latitude": 51.883402,
    "Longitude": 8.232216,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.883402,8.232216",
    "IsOpen24Hours": false,
    "id": "51.883402,8.232216"
  },
  {
    "ID": 595594893,
    "Tankstellennummer": 5069,
    "Name": "Agip Halberstadt",
    "Address": "Blankenburger Heerstr. 1 A",
    "Address__1": "",
    "City": "Halberstadt",
    "Postcode": 38820,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 39412615",
    "24 hour?": "TRUE",
    "Latitude": 51.88519,
    "Longitude": 11.03109,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.88519,11.03109",
    "IsOpen24Hours": true,
    "id": "51.88519,11.03109"
  },
  {
    "ID": 2521,
    "Tankstellennummer": "0FC62",
    "Name": "Aral Autobahntankstelle Duben, Rüblingsheide West (A 13)",
    "Address": "Rüblingsheide/A 13",
    "Address__1": "",
    "City": "Luckau",
    "Postcode": 15926,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 354566830,
    "24 hour?": "TRUE",
    "Latitude": 51.888749,
    "Longitude": 13.837191,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.888749,13.837191",
    "IsOpen24Hours": true,
    "id": "51.888749,13.837191"
  },
  {
    "ID": 564326799,
    "Tankstellennummer": "TD038836",
    "Name": "COSWIG SANDBREITE 1",
    "Address": "SANDBREITE 1",
    "Address__1": "",
    "City": "COSWIG",
    "Postcode": 6869,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 34903472774,
    "24 hour?": "FALSE",
    "Latitude": 51.889,
    "Longitude": 12.4032,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.889,12.4032",
    "IsOpen24Hours": false,
    "id": "51.889,12.4032"
  },
  {
    "ID": 1582,
    "Tankstellennummer": "0F619",
    "Name": "Aral Tankstelle Bad Harzburg, Dr.-Heinr.-Jasper-Str.48",
    "Address": "Dr.-Heinrich-Jasper-Str.",
    "Address__1": "",
    "City": "Bad Harzburg",
    "Postcode": 38667,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 532286471,
    "24 hour?": "TRUE",
    "Latitude": 51.890441,
    "Longitude": 10.543603,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.890441,10.543603",
    "IsOpen24Hours": true,
    "id": "51.890441,10.543603"
  },
  {
    "ID": 1638117373,
    "Tankstellennummer": "TD007393",
    "Name": "WITTENBERG BERLINER CH. 70",
    "Address": "BERLINER CH. 70",
    "Address__1": "",
    "City": "WITTENBERG",
    "Postcode": 6886,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03491-443011",
    "24 hour?": "FALSE",
    "Latitude": 51.8907,
    "Longitude": 12.6655,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8907,12.6655",
    "IsOpen24Hours": false,
    "id": "51.8907,12.6655"
  },
  {
    "ID": 640729623,
    "Tankstellennummer": 610552,
    "Name": "WESTFALEN, MUNSTER",
    "Address": "Kopenhagener Str. 19",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48163,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02501/5942035",
    "24 hour?": "TRUE",
    "Latitude": 51.892621,
    "Longitude": 7.584127,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.892621,7.584127",
    "IsOpen24Hours": true,
    "id": "51.892621,7.584127"
  },
  {
    "ID": 762515217,
    "Tankstellennummer": "TD004325",
    "Name": "HORN-BAD MEINBERG HAMELNER STR",
    "Address": "HAMELNER STR.28",
    "Address__1": "",
    "City": "HORN-BAD MEINBERG",
    "Postcode": 32805,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05234-9512",
    "24 hour?": "FALSE",
    "Latitude": 51.8937,
    "Longitude": 8.9875,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8937,8.9875",
    "IsOpen24Hours": false,
    "id": "51.8937,8.9875"
  },
  {
    "ID": 2539,
    "Tankstellennummer": "0FC81",
    "Name": "Aral Tankstelle Halberstadt, Sternstr. 10",
    "Address": "Sternstraße 10",
    "Address__1": "",
    "City": "Halberstadt",
    "Postcode": 38820,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3941441180,
    "24 hour?": "TRUE",
    "Latitude": 51.894167,
    "Longitude": 11.034996,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.894167,11.034996",
    "IsOpen24Hours": true,
    "id": "51.894167,11.034996"
  },
  {
    "ID": 640483713,
    "Tankstellennummer": 610079,
    "Name": "WESTFALEN, COESFELD",
    "Address": "Coesfelder Str. 91",
    "Address__1": "",
    "City": "COESFELD",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.896,
    "Longitude": 7.19337,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.896,7.19337",
    "IsOpen24Hours": false,
    "id": "51.896,7.19337"
  },
  {
    "ID": 44937627,
    "Tankstellennummer": "0FT78",
    "Name": "STAßFURT / OT BRUMBY",
    "Address": "Am Heidfuchsberg 1",
    "Address__1": "",
    "City": "Staßfurt / OT Brumby",
    "Postcode": 39443,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 39291463920,
    "24 hour?": "TRUE",
    "Latitude": 51.896468,
    "Longitude": 11.691975,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.896468,11.691975",
    "IsOpen24Hours": true,
    "id": "51.896468,11.691975"
  },
  {
    "ID": 1921844686,
    "Tankstellennummer": "TD037374",
    "Name": "SEESEN BRAUNSCHWEIGER STR. 36",
    "Address": "BRAUNSCHWEIGER STR. 36 A",
    "Address__1": "",
    "City": "SEESEN",
    "Postcode": 38723,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05381-1220",
    "24 hour?": "FALSE",
    "Latitude": 51.8979,
    "Longitude": 10.1832,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.8979,10.1832",
    "IsOpen24Hours": false,
    "id": "51.8979,10.1832"
  },
  {
    "ID": 1016180608,
    "Tankstellennummer": "TD027086",
    "Name": "HALBERSTADT MAGDEBURGER STR. 3",
    "Address": "MAGDEBURGER STR. 39-45",
    "Address__1": "",
    "City": "HALBERSTADT",
    "Postcode": 38820,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03941-600139",
    "24 hour?": "FALSE",
    "Latitude": 51.898,
    "Longitude": 11.0634,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.898,11.0634",
    "IsOpen24Hours": false,
    "id": "51.898,11.0634"
  },
  {
    "ID": 1916594876,
    "Tankstellennummer": 610453,
    "Name": "WESTFALEN, GUTERSLOH",
    "Address": "Am Anger 22",
    "Address__1": "",
    "City": "Gütersloh",
    "Postcode": 33332,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05241/20318",
    "24 hour?": "FALSE",
    "Latitude": 51.9005,
    "Longitude": 8.40111,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9005,8.40111",
    "IsOpen24Hours": false,
    "id": "51.9005,8.40111"
  },
  {
    "ID": 3383,
    "Tankstellennummer": "0FL24",
    "Name": "Aral Tankstelle Halberstadt, Magdeburger Chaussee 8",
    "Address": "Magdeburger Chaussee 7 A",
    "Address__1": "",
    "City": "Halberstadt",
    "Postcode": 38820,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3941600245,
    "24 hour?": "FALSE",
    "Latitude": 51.901065,
    "Longitude": 11.073988,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.901065,11.073988",
    "IsOpen24Hours": false,
    "id": "51.901065,11.073988"
  },
  {
    "ID": 2843,
    "Tankstellennummer": "0FH21",
    "Name": "Aral Tankstelle Coesfeld, Coesfelder Str. 10",
    "Address": "Coesfelder Straße 10",
    "Address__1": "",
    "City": "Coesfeld",
    "Postcode": 48653,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2546340,
    "24 hour?": "FALSE",
    "Latitude": 51.903702,
    "Longitude": 7.18728,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.903702,7.18728",
    "IsOpen24Hours": false,
    "id": "51.903702,7.18728"
  },
  {
    "ID": 816762427,
    "Tankstellennummer": "TD037689",
    "Name": "SENDEN-BOESENSELL AM DORN",
    "Address": "AM DORN 5-7",
    "Address__1": "",
    "City": "SENDEN",
    "Postcode": 48308,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02536-345620",
    "24 hour?": "FALSE",
    "Latitude": 51.9041,
    "Longitude": 7.4884,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9041,7.4884",
    "IsOpen24Hours": false,
    "id": "51.9041,7.4884"
  },
  {
    "ID": 411052216,
    "Tankstellennummer": "TD034173",
    "Name": "CALBE/SAALE FRIEDENSPLATZ 1",
    "Address": "FRIEDENSPLATZ 1",
    "Address__1": "",
    "City": "CALBE/SAALE",
    "Postcode": 39240,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039291-72130",
    "24 hour?": "FALSE",
    "Latitude": 51.9045,
    "Longitude": 11.771,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9045,11.771",
    "IsOpen24Hours": false,
    "id": "51.9045,11.771"
  },
  {
    "ID": 818781843,
    "Tankstellennummer": 610404,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Westfalenstr. 124-126",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48165,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02501/928818",
    "24 hour?": "FALSE",
    "Latitude": 51.9074,
    "Longitude": 7.63338,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9074,7.63338",
    "IsOpen24Hours": false,
    "id": "51.9074,7.63338"
  },
  {
    "ID": 1573,
    "Tankstellennummer": "0F614",
    "Name": "Aral Tankstelle Goslar, Reiseckenweg 7",
    "Address": "Reiseckenweg 7",
    "Address__1": "",
    "City": "Goslar",
    "Postcode": 38640,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 532122292,
    "24 hour?": "FALSE",
    "Latitude": 51.907543,
    "Longitude": 10.438619,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.907543,10.438619",
    "IsOpen24Hours": false,
    "id": "51.907543,10.438619"
  },
  {
    "ID": 2520,
    "Tankstellennummer": "0FC61",
    "Name": "Aral Autobahntankstelle Duben, Berstetal (A 13)",
    "Address": "Berstetal/A 13",
    "Address__1": "",
    "City": "Luckau",
    "Postcode": 15926,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 354566820,
    "24 hour?": "TRUE",
    "Latitude": 51.9109,
    "Longitude": 13.7965,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.9109,13.7965",
    "IsOpen24Hours": true,
    "id": "51.9109,13.7965"
  },
  {
    "ID": 1863205066,
    "Tankstellennummer": "0FP81",
    "Name": "NOTTULN",
    "Address": "Beisenbusch 2",
    "Address__1": "",
    "City": "Nottuln",
    "Postcode": 48301,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2509990460,
    "24 hour?": "TRUE",
    "Latitude": 51.912118,
    "Longitude": 7.399278,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.912118,7.399278",
    "IsOpen24Hours": true,
    "id": "51.912118,7.399278"
  },
  {
    "ID": 1750865043,
    "Tankstellennummer": "0FU66",
    "Name": "CALBE",
    "Address": "Salzer Straße 18 d",
    "Address__1": "",
    "City": "Calbe",
    "Postcode": 39240,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3929177800,
    "24 hour?": "TRUE",
    "Latitude": 51.912402,
    "Longitude": 11.777568,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.912402,11.777568",
    "IsOpen24Hours": true,
    "id": "51.912402,11.777568"
  },
  {
    "ID": 1583,
    "Tankstellennummer": "0F620",
    "Name": "Aral Tankstelle Goslar, Hildesheimer Str. 18",
    "Address": "Hildesheimer Straße 18",
    "Address__1": "",
    "City": "Goslar",
    "Postcode": 38640,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 532122135,
    "24 hour?": "TRUE",
    "Latitude": 51.912696,
    "Longitude": 10.421956,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.912696,10.421956",
    "IsOpen24Hours": true,
    "id": "51.912696,10.421956"
  },
  {
    "ID": 2837,
    "Tankstellennummer": "0FH16",
    "Name": "Aral Tankstelle Gütersloh, Nordring 107",
    "Address": "Nordring 107",
    "Address__1": "",
    "City": "Gütersloh",
    "Postcode": 33330,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 524114110,
    "24 hour?": "TRUE",
    "Latitude": 51.91278,
    "Longitude": 8.366754,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.91278,8.366754",
    "IsOpen24Hours": true,
    "id": "51.91278,8.366754"
  },
  {
    "ID": 2602,
    "Tankstellennummer": "0FD32",
    "Name": "Aral Autohof Atzendorf, Magdeburg-Leipziger Chaus",
    "Address": "Magdeburg-Leipziger Chaus",
    "Address__1": "",
    "City": "Staßfurt",
    "Postcode": 39443,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3926650130,
    "24 hour?": "TRUE",
    "Latitude": 51.917186,
    "Longitude": 11.610609,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.917186,11.610609",
    "IsOpen24Hours": true,
    "id": "51.917186,11.610609"
  },
  {
    "ID": 929989269,
    "Tankstellennummer": 610405,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Albersloher Weg 580",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48167,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0251/615938",
    "24 hour?": "FALSE",
    "Latitude": 51.9217,
    "Longitude": 7.67464,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9217,7.67464",
    "IsOpen24Hours": false,
    "id": "51.9217,7.67464"
  },
  {
    "ID": 2861,
    "Tankstellennummer": "0FH36",
    "Name": "Aral Tankstelle Goslar, Robert-Koch-Str. 1",
    "Address": "Robert-Koch-Straße 1",
    "Address__1": "",
    "City": "Goslar",
    "Postcode": 38642,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 532181468,
    "24 hour?": "FALSE",
    "Latitude": 51.922003,
    "Longitude": 10.438066,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.922003,10.438066",
    "IsOpen24Hours": false,
    "id": "51.922003,10.438066"
  },
  {
    "ID": 846343663,
    "Tankstellennummer": "0FM63",
    "Name": "SEESEN",
    "Address": "A 7",
    "Address__1": "",
    "City": "Seesen",
    "Postcode": 38723,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5384969800,
    "24 hour?": "TRUE",
    "Latitude": 51.925588,
    "Longitude": 10.142902,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.925588,10.142902",
    "IsOpen24Hours": true,
    "id": "51.925588,10.142902"
  },
  {
    "ID": 1055370582,
    "Tankstellennummer": 610527,
    "Name": "WESTFALEN, NOTTULN",
    "Address": "Potthof 14",
    "Address__1": "",
    "City": "Nottuln",
    "Postcode": 48301,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02502/9808",
    "24 hour?": "FALSE",
    "Latitude": 51.9279,
    "Longitude": 7.35334,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9279,7.35334",
    "IsOpen24Hours": false,
    "id": "51.9279,7.35334"
  },
  {
    "ID": 547311185,
    "Tankstellennummer": 610106,
    "Name": "WESTFALEN, NOTTULN",
    "Address": "Appelhuelsener Str. 16",
    "Address__1": "",
    "City": "NOTTULN",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.9284,
    "Longitude": 7.36153,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9284,7.36153",
    "IsOpen24Hours": false,
    "id": "51.9284,7.36153"
  },
  {
    "ID": 847194703,
    "Tankstellennummer": "0FP93",
    "Name": "SEESEN",
    "Address": "A7",
    "Address__1": "",
    "City": "Seesen",
    "Postcode": 38723,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5384969502,
    "24 hour?": "TRUE",
    "Latitude": 51.928528,
    "Longitude": 10.142306,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.928528,10.142306",
    "IsOpen24Hours": true,
    "id": "51.928528,10.142306"
  },
  {
    "ID": 745317008,
    "Tankstellennummer": 610505,
    "Name": "WESTFALEN, BEELEN",
    "Address": "Warendorfer Str. 12",
    "Address__1": "",
    "City": "Beelen",
    "Postcode": 48361,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02586/216",
    "24 hour?": "FALSE",
    "Latitude": 51.9297,
    "Longitude": 8.12068,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9297,8.12068",
    "IsOpen24Hours": false,
    "id": "51.9297,8.12068"
  },
  {
    "ID": 2848,
    "Tankstellennummer": "0FH26",
    "Name": "Aral Tankstelle Münster, Hammer Str. 311-313",
    "Address": "Hammer Straße 311-315",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48153,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 25178109,
    "24 hour?": "TRUE",
    "Latitude": 51.931736,
    "Longitude": 7.626874,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.931736,7.626874",
    "IsOpen24Hours": true,
    "id": "51.931736,7.626874"
  },
  {
    "ID": 751012991,
    "Tankstellennummer": 610413,
    "Name": "WESTFALEN, BIELEFELD",
    "Address": "Paderborner Str. 319",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33689,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05205/71560",
    "24 hour?": "TRUE",
    "Latitude": 51.9321,
    "Longitude": 8.60747,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.9321,8.60747",
    "IsOpen24Hours": true,
    "id": "51.9321,8.60747"
  },
  {
    "ID": 2247,
    "Tankstellennummer": "0FA81",
    "Name": "Aral Autohof Bielefeld, Paderborner Str. 324",
    "Address": "Paderborner Straße 324",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33689,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52057292118,
    "24 hour?": "TRUE",
    "Latitude": 51.932147,
    "Longitude": 8.606917,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.932147,8.606917",
    "IsOpen24Hours": true,
    "id": "51.932147,8.606917"
  },
  {
    "ID": 1562,
    "Tankstellennummer": "0F600",
    "Name": "Aral Tankstelle Langelsheim, Wolfshagener Str. 2",
    "Address": "Wolfshagener Straße 2a",
    "Address__1": "",
    "City": "Langelsheim",
    "Postcode": 38685,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 532686086,
    "24 hour?": "FALSE",
    "Latitude": 51.933447,
    "Longitude": 10.345752,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.933447,10.345752",
    "IsOpen24Hours": false,
    "id": "51.933447,10.345752"
  },
  {
    "ID": 1138522784,
    "Tankstellennummer": 610523,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Weseler Str. 383",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48163,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0251/7180086",
    "24 hour?": "TRUE",
    "Latitude": 51.934,
    "Longitude": 7.59939,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.934,7.59939",
    "IsOpen24Hours": true,
    "id": "51.934,7.59939"
  },
  {
    "ID": 814500753,
    "Tankstellennummer": 610134,
    "Name": "WESTFALEN, SUEDLOHN",
    "Address": "Industriestr. 1",
    "Address__1": "",
    "City": "Südlohn",
    "Postcode": 46354,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02862/6381",
    "24 hour?": "FALSE",
    "Latitude": 51.9354,
    "Longitude": 6.82016,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9354,6.82016",
    "IsOpen24Hours": false,
    "id": "51.9354,6.82016"
  },
  {
    "ID": 949132962,
    "Tankstellennummer": 610613,
    "Name": "WESTFALEN, DETMOLD",
    "Address": "Augustdorfer Str. 67",
    "Address__1": "",
    "City": "Detmold",
    "Postcode": 32758,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05232/88902",
    "24 hour?": "FALSE",
    "Latitude": 51.937,
    "Longitude": 8.77853,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.937,8.77853",
    "IsOpen24Hours": false,
    "id": "51.937,8.77853"
  },
  {
    "ID": 589545149,
    "Tankstellennummer": 610619,
    "Name": "WESTFALEN, COESFELD",
    "Address": "Dülmener Str. 24-26",
    "Address__1": "",
    "City": "Coesfeld",
    "Postcode": 48653,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02541/5650",
    "24 hour?": "FALSE",
    "Latitude": 51.9375,
    "Longitude": 7.16795,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9375,7.16795",
    "IsOpen24Hours": false,
    "id": "51.9375,7.16795"
  },
  {
    "ID": 2259,
    "Tankstellennummer": "0FA90",
    "Name": "Aral Tankstelle Blomberg, Hellweg 2 b",
    "Address": "Hellweg 2 b",
    "Address__1": "",
    "City": "Blomberg",
    "Postcode": 32825,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52358089,
    "24 hour?": "FALSE",
    "Latitude": 51.939535,
    "Longitude": 9.090921,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.939535,9.090921",
    "IsOpen24Hours": false,
    "id": "51.939535,9.090921"
  },
  {
    "ID": 2076334,
    "Tankstellennummer": 610583,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Weseler Str. 269-271",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48151,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0251/72338",
    "24 hour?": "FALSE",
    "Latitude": 51.939615,
    "Longitude": 7.608723,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.939615,7.608723",
    "IsOpen24Hours": false,
    "id": "51.939615,7.608723"
  },
  {
    "ID": 826220428,
    "Tankstellennummer": "TD111280",
    "Name": "GUETERSLOH SENNER STR. 8",
    "Address": "SENNER STR. 8",
    "Address__1": "",
    "City": "GUETERSLOH",
    "Postcode": 33335,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05209-980818",
    "24 hour?": "FALSE",
    "Latitude": 51.9401,
    "Longitude": 8.487,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9401,8.487",
    "IsOpen24Hours": false,
    "id": "51.9401,8.487"
  },
  {
    "ID": 570957737,
    "Tankstellennummer": 900688,
    "Name": "WESTFALEN, BAT MUENSTERLAND OST MÜNSTER",
    "Address": "BAT-Münsterland/ Ostseite",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48161,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02534/492",
    "24 hour?": "TRUE",
    "Latitude": 51.9403,
    "Longitude": 7.55087,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.9403,7.55087",
    "IsOpen24Hours": true,
    "id": "51.9403,7.55087"
  },
  {
    "ID": 764492652,
    "Tankstellennummer": "TD026989",
    "Name": "EGELN HALBERSTAEDTER STR. 21 B",
    "Address": "HALBERSTAEDTER STR.21 B",
    "Address__1": "",
    "City": "EGELN",
    "Postcode": 39435,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039268-2235",
    "24 hour?": "FALSE",
    "Latitude": 51.9406,
    "Longitude": 11.4181,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9406,11.4181",
    "IsOpen24Hours": false,
    "id": "51.9406,11.4181"
  },
  {
    "ID": 1395670404,
    "Tankstellennummer": "TD038083",
    "Name": "OERLINGHAUSEN STUKENBROKER WEG",
    "Address": "STUKENBROKER WEG 18",
    "Address__1": "",
    "City": "OERLINGHAUSEN",
    "Postcode": 33813,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05202-72211",
    "24 hour?": "FALSE",
    "Latitude": 51.9409,
    "Longitude": 8.6548,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9409,8.6548",
    "IsOpen24Hours": false,
    "id": "51.9409,8.6548"
  },
  {
    "ID": 2266,
    "Tankstellennummer": "0FA96",
    "Name": "Aral Tankstelle Detmold, Heidenoldendorfer Str. 16",
    "Address": "Heidenoldendorfer Str.16",
    "Address__1": "",
    "City": "Detmold",
    "Postcode": 32758,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 523168884,
    "24 hour?": "FALSE",
    "Latitude": 51.941367,
    "Longitude": 8.859417,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.941367,8.859417",
    "IsOpen24Hours": false,
    "id": "51.941367,8.859417"
  },
  {
    "ID": 2670,
    "Tankstellennummer": "0FD89",
    "Name": "Aral Tankstelle Lübben, Frankfurter Str. 101",
    "Address": "Frankfurter Straße 101",
    "Address__1": "",
    "City": "Lübben",
    "Postcode": 15907,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 35468456,
    "24 hour?": "TRUE",
    "Latitude": 51.942419,
    "Longitude": 13.91087,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.942419,13.91087",
    "IsOpen24Hours": true,
    "id": "51.942419,13.91087"
  },
  {
    "ID": 1091706013,
    "Tankstellennummer": 610525,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Weseler Str. 212-216",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48151,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0251/791111",
    "24 hour?": "TRUE",
    "Latitude": 51.9431,
    "Longitude": 7.61105,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.9431,7.61105",
    "IsOpen24Hours": true,
    "id": "51.9431,7.61105"
  },
  {
    "ID": 527543976,
    "Tankstellennummer": "TD028878",
    "Name": "LUEBBEN FRANKFURTER STR.37",
    "Address": "FRANKFURTER STR. 37",
    "Address__1": "",
    "City": "LUEBBEN",
    "Postcode": 15907,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03546-3106",
    "24 hour?": "FALSE",
    "Latitude": 51.9431,
    "Longitude": 13.9135,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9431,13.9135",
    "IsOpen24Hours": false,
    "id": "51.9431,13.9135"
  },
  {
    "ID": 1070092125,
    "Tankstellennummer": 610628,
    "Name": "WESTFALEN, DETMOLD",
    "Address": "Lemgoer Str. 15",
    "Address__1": "",
    "City": "DETMOLD",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.9436,
    "Longitude": 8.87837,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9436,8.87837",
    "IsOpen24Hours": false,
    "id": "51.9436,8.87837"
  },
  {
    "ID": 539393664,
    "Tankstellennummer": 610425,
    "Name": "WESTFALEN, COESFELD",
    "Address": "Holtwicker Str. 31",
    "Address__1": "",
    "City": "Coesfeld",
    "Postcode": 48653,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02541/85677",
    "24 hour?": "FALSE",
    "Latitude": 51.9528,
    "Longitude": 7.16396,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9528,7.16396",
    "IsOpen24Hours": false,
    "id": "51.9528,7.16396"
  },
  {
    "ID": 1227289035,
    "Tankstellennummer": 610409,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Von-Steuben-Str. 13",
    "Address__1": "",
    "City": "MUENSTER",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 51.9549,
    "Longitude": 7.63226,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9549,7.63226",
    "IsOpen24Hours": false,
    "id": "51.9549,7.63226"
  },
  {
    "ID": 853774021,
    "Tankstellennummer": 610554,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Roxeler Str. 556",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48161,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02534/375",
    "24 hour?": "FALSE",
    "Latitude": 51.9553,
    "Longitude": 7.53847,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9553,7.53847",
    "IsOpen24Hours": false,
    "id": "51.9553,7.53847"
  },
  {
    "ID": 2029886405,
    "Tankstellennummer": 610415,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Mondstr. 160-162",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48155,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0251/314397",
    "24 hour?": "FALSE",
    "Latitude": 51.956,
    "Longitude": 7.67699,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.956,7.67699",
    "IsOpen24Hours": false,
    "id": "51.956,7.67699"
  },
  {
    "ID": 1108910976,
    "Tankstellennummer": 610044,
    "Name": "WESTFALEN, GESCHER",
    "Address": "Armlandstr. 22",
    "Address__1": "",
    "City": "Gescher",
    "Postcode": 48712,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02542/1258",
    "24 hour?": "FALSE",
    "Latitude": 51.957,
    "Longitude": 7.00132,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.957,7.00132",
    "IsOpen24Hours": false,
    "id": "51.957,7.00132"
  },
  {
    "ID": 2852,
    "Tankstellennummer": "0FH29",
    "Name": "Aral Tankstelle Warendorf, Sassenberger Str. 71",
    "Address": "Sassenberger Straße 71",
    "Address__1": "",
    "City": "Warendorf",
    "Postcode": 48231,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 25818091,
    "24 hour?": "FALSE",
    "Latitude": 51.957284,
    "Longitude": 8.007975,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.957284,8.007975",
    "IsOpen24Hours": false,
    "id": "51.957284,8.007975"
  },
  {
    "ID": 2286,
    "Tankstellennummer": "0FB10",
    "Name": "Aral Tankstelle Münster, Steinfurter Str. 1-3",
    "Address": "Steinfurter Straße 1-3",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48149,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 25122822,
    "24 hour?": "TRUE",
    "Latitude": 51.967265,
    "Longitude": 7.614308,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.967265,7.614308",
    "IsOpen24Hours": true,
    "id": "51.967265,7.614308"
  },
  {
    "ID": 1025987037,
    "Tankstellennummer": 610430,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Bohlweg 70-72",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48147,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0251/55043",
    "24 hour?": "FALSE",
    "Latitude": 51.9674,
    "Longitude": 7.64325,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9674,7.64325",
    "IsOpen24Hours": false,
    "id": "51.9674,7.64325"
  },
  {
    "ID": 2672,
    "Tankstellennummer": "0FD91",
    "Name": "Aral Tankstelle Osterwieck, Lüttgenröder Str. 1",
    "Address": "Lüttgenröder Straße 1",
    "Address__1": "",
    "City": "Osterwieck",
    "Postcode": 38835,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 394216140,
    "24 hour?": "FALSE",
    "Latitude": 51.968684,
    "Longitude": 10.699707,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.968684,10.699707",
    "IsOpen24Hours": false,
    "id": "51.968684,10.699707"
  },
  {
    "ID": 536722352,
    "Tankstellennummer": 610426,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Schiffahrter Damm 29",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48145,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0251/232904",
    "24 hour?": "FALSE",
    "Latitude": 51.9689,
    "Longitude": 7.65589,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9689,7.65589",
    "IsOpen24Hours": false,
    "id": "51.9689,7.65589"
  },
  {
    "ID": 2840,
    "Tankstellennummer": "0FH18",
    "Name": "Aral Tankstelle Oerlinghausen, Bielefelder Str. 23",
    "Address": "Bielefelder Straße 23",
    "Address__1": "",
    "City": "Oerlinghausen",
    "Postcode": 33813,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52024079,
    "24 hour?": "FALSE",
    "Latitude": 51.968923,
    "Longitude": 8.691772,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.968923,8.691772",
    "IsOpen24Hours": false,
    "id": "51.968923,8.691772"
  },
  {
    "ID": 415650766,
    "Tankstellennummer": 610015,
    "Name": "WESTFALEN, BILLERBECK",
    "Address": "Von-Galen-Str. 2",
    "Address__1": "",
    "City": "Billerbeck",
    "Postcode": 48727,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02543/9668",
    "24 hour?": "FALSE",
    "Latitude": 51.9704,
    "Longitude": 7.2807,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9704,7.2807",
    "IsOpen24Hours": false,
    "id": "51.9704,7.2807"
  },
  {
    "ID": 2297,
    "Tankstellennummer": "0FB17",
    "Name": "Aral Tankstelle Billerbeck, Münsterstr.60",
    "Address": "Münsterstraße 60",
    "Address__1": "",
    "City": "Billerbeck",
    "Postcode": 48727,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2543232393,
    "24 hour?": "FALSE",
    "Latitude": 51.974226,
    "Longitude": 7.298036,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.974226,7.298036",
    "IsOpen24Hours": false,
    "id": "51.974226,7.298036"
  },
  {
    "ID": 1014116846,
    "Tankstellennummer": "TD032813",
    "Name": "ZERBST MAGDEBURGER STR. 84",
    "Address": "MAGDEBURGER STR. 84",
    "Address__1": "",
    "City": "ZERBST",
    "Postcode": 39261,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03923-780437",
    "24 hour?": "FALSE",
    "Latitude": 51.975,
    "Longitude": 12.066,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.975,12.066",
    "IsOpen24Hours": false,
    "id": "51.975,12.066"
  },
  {
    "ID": 66936518,
    "Tankstellennummer": 610268,
    "Name": "WESTFALEN, LEOPOLDSHOEHE",
    "Address": "Hauptstr. 372-376",
    "Address__1": "",
    "City": "Leopoldshöhe",
    "Postcode": 33818,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05202/956134",
    "24 hour?": "FALSE",
    "Latitude": 51.9756,
    "Longitude": 8.66539,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9756,8.66539",
    "IsOpen24Hours": false,
    "id": "51.9756,8.66539"
  },
  {
    "ID": 321416333,
    "Tankstellennummer": 610603,
    "Name": "WESTFALEN, BIELEFELD",
    "Address": "Windelsbleicher Str. 101",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33647,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0521/410338",
    "24 hour?": "FALSE",
    "Latitude": 51.9768,
    "Longitude": 8.51836,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9768,8.51836",
    "IsOpen24Hours": false,
    "id": "51.9768,8.51836"
  },
  {
    "ID": 288996950,
    "Tankstellennummer": 610429,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Steinfurter Str. 166",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48159,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0251/2075227",
    "24 hour?": "TRUE",
    "Latitude": 51.9774,
    "Longitude": 7.60019,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.9774,7.60019",
    "IsOpen24Hours": true,
    "id": "51.9774,7.60019"
  },
  {
    "ID": 155500179,
    "Tankstellennummer": "TD028811",
    "Name": "GOLSSEN AN DER B 96",
    "Address": "AN DER B 96",
    "Address__1": "",
    "City": "GOLSSEN",
    "Postcode": 15938,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "035452-432",
    "24 hour?": "FALSE",
    "Latitude": 51.9776,
    "Longitude": 13.5896,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9776,13.5896",
    "IsOpen24Hours": false,
    "id": "51.9776,13.5896"
  },
  {
    "ID": 1926058391,
    "Tankstellennummer": 610258,
    "Name": "WESTFALEN, OERLINGHAUSEN",
    "Address": "Bahnhofstr. 90",
    "Address__1": "",
    "City": "Oerlinghausen",
    "Postcode": 33813,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05202/91500",
    "24 hour?": "FALSE",
    "Latitude": 51.9799,
    "Longitude": 8.71415,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9799,8.71415",
    "IsOpen24Hours": false,
    "id": "51.9799,8.71415"
  },
  {
    "ID": 2835,
    "Tankstellennummer": "0FH14",
    "Name": "Aral Tankstelle Bielefeld, Guetersloher Str. 240",
    "Address": "Guetersloher Straße 240",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33649,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 521412962,
    "24 hour?": "TRUE",
    "Latitude": 51.980169,
    "Longitude": 8.477516,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.980169,8.477516",
    "IsOpen24Hours": true,
    "id": "51.980169,8.477516"
  },
  {
    "ID": 19690370,
    "Tankstellennummer": 610401,
    "Name": "WESTFALEN, BILLERBECK",
    "Address": "Darfelder Str. 14",
    "Address__1": "",
    "City": "Billerbeck",
    "Postcode": 48727,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02543/8610",
    "24 hour?": "FALSE",
    "Latitude": 51.9821,
    "Longitude": 7.29366,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9821,7.29366",
    "IsOpen24Hours": false,
    "id": "51.9821,7.29366"
  },
  {
    "ID": 854143609,
    "Tankstellennummer": 610185,
    "Name": "WESTFALEN, BARNTRUP",
    "Address": "Frettholz 5",
    "Address__1": "",
    "City": "Barntrup",
    "Postcode": 32683,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05263/9548889",
    "24 hour?": "FALSE",
    "Latitude": 51.9887,
    "Longitude": 9.13577,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9887,9.13577",
    "IsOpen24Hours": false,
    "id": "51.9887,9.13577"
  },
  {
    "ID": 2245,
    "Tankstellennummer": "0FA80",
    "Name": "Aral Tankstelle Bielefeld, Gütersloher Str. 123",
    "Address": "Gütersloher Straße 123",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33649,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 521442240,
    "24 hour?": "FALSE",
    "Latitude": 51.990402,
    "Longitude": 8.492146,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "51.990402,8.492146",
    "IsOpen24Hours": false,
    "id": "51.990402,8.492146"
  },
  {
    "ID": 555529617,
    "Tankstellennummer": 610132,
    "Name": "WESTFALEN, STADTLOHN",
    "Address": "Dufkampstr. 89",
    "Address__1": "",
    "City": "Stadtlohn",
    "Postcode": 48703,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02563/3133",
    "24 hour?": "FALSE",
    "Latitude": 51.9923,
    "Longitude": 6.92837,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9923,6.92837",
    "IsOpen24Hours": false,
    "id": "51.9923,6.92837"
  },
  {
    "ID": 545451365,
    "Tankstellennummer": 610412,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Schneidemühler Str. 9",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48157,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0251/247109",
    "24 hour?": "FALSE",
    "Latitude": 51.994,
    "Longitude": 7.64971,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.994,7.64971",
    "IsOpen24Hours": false,
    "id": "51.994,7.64971"
  },
  {
    "ID": 2833,
    "Tankstellennummer": "0FH13",
    "Name": "Aral Tankstelle Bielefeld, Detmolder Str. 490",
    "Address": "Detmolder Straße 490",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33605,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 521203090,
    "24 hour?": "TRUE",
    "Latitude": 51.995687,
    "Longitude": 8.594726,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "51.995687,8.594726",
    "IsOpen24Hours": true,
    "id": "51.995687,8.594726"
  },
  {
    "ID": 1162,
    "Tankstellennummer": "0F290",
    "Name": "Aral Tankstelle Alfeld, Alte Heerstr. 4",
    "Address": "Alte Heerstraße 4",
    "Address__1": "",
    "City": "Alfeld",
    "Postcode": 31061,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5181828326,
    "24 hour?": "TRUE",
    "Latitude": 51.995752,
    "Longitude": 9.79987,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "51.995752,9.79987",
    "IsOpen24Hours": true,
    "id": "51.995752,9.79987"
  },
  {
    "ID": 683922712,
    "Tankstellennummer": "TD037507",
    "Name": "LAGE LEMGOER STRASSE 80",
    "Address": "LEMGOER STRASSE 80",
    "Address__1": "",
    "City": "LAGE",
    "Postcode": 32791,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05232-62009",
    "24 hour?": "FALSE",
    "Latitude": 51.9958,
    "Longitude": 8.8075,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.9958,8.8075",
    "IsOpen24Hours": false,
    "id": "51.9958,8.8075"
  },
  {
    "ID": 2284,
    "Tankstellennummer": "0FB08",
    "Name": "Aral Tankstelle Münster, Altenberger Str. 34",
    "Address": "Altenberger Straße 34",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48161,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 25339355140,
    "24 hour?": "FALSE",
    "Latitude": 51.99971,
    "Longitude": 7.558383,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "51.99971,7.558383",
    "IsOpen24Hours": false,
    "id": "51.99971,7.558383"
  },
  {
    "ID": 250474069,
    "Tankstellennummer": 610522,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Grevener Str. 395",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48159,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0251/211393",
    "24 hour?": "FALSE",
    "Latitude": 52.0007,
    "Longitude": 7.61394,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0007,7.61394",
    "IsOpen24Hours": false,
    "id": "52.0007,7.61394"
  },
  {
    "ID": 1930637179,
    "Tankstellennummer": "TD038109",
    "Name": "BIELEFELD ARTUR-LADEBECK-STR.",
    "Address": "ARTUR-LADEBECK-STR. 179",
    "Address__1": "",
    "City": "BIELEFELD",
    "Postcode": 33647,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0521-4174173",
    "24 hour?": "FALSE",
    "Latitude": 52.0012,
    "Longitude": 8.5059,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0012,8.5059",
    "IsOpen24Hours": false,
    "id": "52.0012,8.5059"
  },
  {
    "ID": 2102317679,
    "Tankstellennummer": "TD039396",
    "Name": "BOCKENEM WALTER-ALTHOFFSTR. 2",
    "Address": "WALTER-ALTHOFFSTR. 2",
    "Address__1": "",
    "City": "BOCKENEM",
    "Postcode": 31167,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05067-8959975",
    "24 hour?": "FALSE",
    "Latitude": 52.0015,
    "Longitude": 10.1447,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0015,10.1447",
    "IsOpen24Hours": false,
    "id": "52.0015,10.1447"
  },
  {
    "ID": 2263,
    "Tankstellennummer": "0FA94",
    "Name": "Aral Tankstelle Bielefeld, Stieghorster Str. 42",
    "Address": "Stieghorster Straße 42",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33605,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 521200809,
    "24 hour?": "FALSE",
    "Latitude": 52.001865,
    "Longitude": 8.589285,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.001865,8.589285",
    "IsOpen24Hours": false,
    "id": "52.001865,8.589285"
  },
  {
    "ID": 1108,
    "Tankstellennummer": "0F268",
    "Name": "Aral Autohof Bockenem, Oppelner Straße 1",
    "Address": "Oppelner Straße 1",
    "Address__1": "",
    "City": "Bockenem",
    "Postcode": 31167,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5067697875,
    "24 hour?": "TRUE",
    "Latitude": 52.001907,
    "Longitude": 10.135185,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.001907,10.135185",
    "IsOpen24Hours": true,
    "id": "52.001907,10.135185"
  },
  {
    "ID": 2103554186,
    "Tankstellennummer": "TD028118",
    "Name": "JUETERBOG LUCKENWALDER STR. 19",
    "Address": "LUCKENWALDER STR. 19",
    "Address__1": "",
    "City": "JUETERBOG",
    "Postcode": 14913,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03372-432352",
    "24 hour?": "FALSE",
    "Latitude": 52.0031,
    "Longitude": 13.0867,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0031,13.0867",
    "IsOpen24Hours": false,
    "id": "52.0031,13.0867"
  },
  {
    "ID": 1826070206,
    "Tankstellennummer": 610711,
    "Name": "WESTFALEN, BIELEFELD",
    "Address": "Osningstr. 15",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33605,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0521/24333",
    "24 hour?": "FALSE",
    "Latitude": 52.0033,
    "Longitude": 8.55888,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0033,8.55888",
    "IsOpen24Hours": false,
    "id": "52.0033,8.55888"
  },
  {
    "ID": 740926767,
    "Tankstellennummer": 610020,
    "Name": "WESTFALEN, BIELEFELD",
    "Address": "Stieghorster Str. 108",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33605,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0521/200755",
    "24 hour?": "FALSE",
    "Latitude": 52.0073,
    "Longitude": 8.58954,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0073,8.58954",
    "IsOpen24Hours": false,
    "id": "52.0073,8.58954"
  },
  {
    "ID": 2728,
    "Tankstellennummer": "0FE40",
    "Name": "Aral Tankstelle Jüterbog, Treuenbrietzener Str. 64",
    "Address": "Treuenbrietzener Straße 64",
    "Address__1": "",
    "City": "Niedergörsdorf",
    "Postcode": 14913,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 33724429633,
    "24 hour?": "FALSE",
    "Latitude": 52.008994,
    "Longitude": 13.012,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.008994,13.012",
    "IsOpen24Hours": false,
    "id": "52.008994,13.012"
  },
  {
    "ID": 2253,
    "Tankstellennummer": "0FA86",
    "Name": "Aral Tankstelle Steinhagen, Bahnhofstr.44",
    "Address": "Bahnhofstraße 44",
    "Address__1": "",
    "City": "Steinhagen",
    "Postcode": 33803,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5204921937,
    "24 hour?": "FALSE",
    "Latitude": 52.009148,
    "Longitude": 8.413793,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.009148,8.413793",
    "IsOpen24Hours": false,
    "id": "52.009148,8.413793"
  },
  {
    "ID": 15673487,
    "Tankstellennummer": "0FQ68",
    "Name": "BIELEFELD",
    "Address": "A 2",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33719,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52083448210,
    "24 hour?": "TRUE",
    "Latitude": 52.009959,
    "Longitude": 8.629997,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.009959,8.629997",
    "IsOpen24Hours": true,
    "id": "52.009959,8.629997"
  },
  {
    "ID": 1090696562,
    "Tankstellennummer": 610569,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Schiffahrter Damm 506",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48157,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0251/324308",
    "24 hour?": "FALSE",
    "Latitude": 52.0112,
    "Longitude": 7.6796,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0112,7.6796",
    "IsOpen24Hours": false,
    "id": "52.0112,7.6796"
  },
  {
    "ID": 2083340482,
    "Tankstellennummer": 610580,
    "Name": "WESTFALEN, MUENSTER",
    "Address": "Schiffahrter Damm 511",
    "Address__1": "",
    "City": "Münster",
    "Postcode": 48157,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0251/329058",
    "24 hour?": "FALSE",
    "Latitude": 52.0124,
    "Longitude": 7.67952,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0124,7.67952",
    "IsOpen24Hours": false,
    "id": "52.0124,7.67952"
  },
  {
    "ID": 1181893471,
    "Tankstellennummer": "TD004432",
    "Name": "STEINHAGEN BIELEFELDER STR.81",
    "Address": "BIELEFELDER STR.81",
    "Address__1": "",
    "City": "STEINHAGEN",
    "Postcode": 33803,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05204-4634",
    "24 hour?": "FALSE",
    "Latitude": 52.0133,
    "Longitude": 8.441,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0133,8.441",
    "IsOpen24Hours": false,
    "id": "52.0133,8.441"
  },
  {
    "ID": 1024536975,
    "Tankstellennummer": "TD040303",
    "Name": "LEMGO LEMGOER STR. 4",
    "Address": "LEMGOER STR. 4",
    "Address__1": "",
    "City": "LEMGO",
    "Postcode": 32657,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05261-870003",
    "24 hour?": "FALSE",
    "Latitude": 52.0145,
    "Longitude": 8.9197,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0145,8.9197",
    "IsOpen24Hours": false,
    "id": "52.0145,8.9197"
  },
  {
    "ID": 2254,
    "Tankstellennummer": "0FA87",
    "Name": "Aral Tankstelle Bielefeld, Heeper Str. 77",
    "Address": "Heeper Straße 77",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33607,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52169507,
    "24 hour?": "TRUE",
    "Latitude": 52.021724,
    "Longitude": 8.548129,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.021724,8.548129",
    "IsOpen24Hours": true,
    "id": "52.021724,8.548129"
  },
  {
    "ID": 3267,
    "Tankstellennummer": "0FK37",
    "Name": "Aral Tankstelle Lemgo, Lagesche Str. 60-64",
    "Address": "Lagesche Straße 60-64",
    "Address__1": "",
    "City": "Lemgo",
    "Postcode": 32657,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 526117044,
    "24 hour?": "FALSE",
    "Latitude": 52.022535,
    "Longitude": 8.885877,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.022535,8.885877",
    "IsOpen24Hours": false,
    "id": "52.022535,8.885877"
  },
  {
    "ID": 221363916,
    "Tankstellennummer": 610615,
    "Name": "WESTFALEN, LAGE",
    "Address": "Schötmarsche Str. 356",
    "Address__1": "",
    "City": "Lage",
    "Postcode": 32791,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05232/5167",
    "24 hour?": "FALSE",
    "Latitude": 52.0234,
    "Longitude": 8.76142,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0234,8.76142",
    "IsOpen24Hours": false,
    "id": "52.0234,8.76142"
  },
  {
    "ID": 275743128,
    "Tankstellennummer": 610645,
    "Name": "WESTFALEN, LEMGO",
    "Address": "Hamelner Str. 26",
    "Address__1": "",
    "City": "Lemgo",
    "Postcode": 32657,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05232/5167",
    "24 hour?": "FALSE",
    "Latitude": 52.0261,
    "Longitude": 8.91594,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0261,8.91594",
    "IsOpen24Hours": false,
    "id": "52.0261,8.91594"
  },
  {
    "ID": 2261,
    "Tankstellennummer": "0FA92",
    "Name": "Aral Tankstelle Bielefeld, Potsdamer Straße 7",
    "Address": "Potsdamer Straße 7",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33719,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 521331355,
    "24 hour?": "FALSE",
    "Latitude": 52.027828,
    "Longitude": 8.603513,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.027828,8.603513",
    "IsOpen24Hours": false,
    "id": "52.027828,8.603513"
  },
  {
    "ID": 50335635,
    "Tankstellennummer": 610205,
    "Name": "WESTFALEN, STEINHAGEN",
    "Address": "Haller Str. 69",
    "Address__1": "",
    "City": "Steinhagen",
    "Postcode": 33803,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05204/4292",
    "24 hour?": "FALSE",
    "Latitude": 52.0279,
    "Longitude": 8.40911,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0279,8.40911",
    "IsOpen24Hours": false,
    "id": "52.0279,8.40911"
  },
  {
    "ID": 866189457,
    "Tankstellennummer": "TD027334",
    "Name": "OSCHERSLEBEN ANDERLEBENER STR.",
    "Address": "ANDERLEBENER STR. 53",
    "Address__1": "",
    "City": "OSCHERSLEBEN",
    "Postcode": 39387,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03949-81693",
    "24 hour?": "FALSE",
    "Latitude": 52.0285,
    "Longitude": 11.2461,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0285,11.2461",
    "IsOpen24Hours": false,
    "id": "52.0285,11.2461"
  },
  {
    "ID": 2580,
    "Tankstellennummer": "0FD15",
    "Name": "Aral Tankstelle Oschersleben, Anderslebener Str. 42 a",
    "Address": "Anderslebener Straße 42 a",
    "Address__1": "",
    "City": "Oschersleben",
    "Postcode": 39387,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 394995959,
    "24 hour?": "TRUE",
    "Latitude": 52.028823,
    "Longitude": 11.24598,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.028823,11.24598",
    "IsOpen24Hours": true,
    "id": "52.028823,11.24598"
  },
  {
    "ID": 470249889,
    "Tankstellennummer": 610042,
    "Name": "WESTFALEN, VREDEN",
    "Address": "Bahnhofstr. 37/39",
    "Address__1": "",
    "City": "Vreden",
    "Postcode": 48691,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02564/390093",
    "24 hour?": "FALSE",
    "Latitude": 52.0296,
    "Longitude": 6.82345,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0296,6.82345",
    "IsOpen24Hours": false,
    "id": "52.0296,6.82345"
  },
  {
    "ID": 314596749,
    "Tankstellennummer": 610341,
    "Name": "WESTFALEN, BIELEFELD",
    "Address": "Heeper Str. 413",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33719,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0521/333000",
    "24 hour?": "FALSE",
    "Latitude": 52.0319,
    "Longitude": 8.59885,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0319,8.59885",
    "IsOpen24Hours": false,
    "id": "52.0319,8.59885"
  },
  {
    "ID": 1998520386,
    "Tankstellennummer": 610452,
    "Name": "WESTFALEN, BIELEFELD",
    "Address": "Herforder Str. 110-114",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33602,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0521/138895",
    "24 hour?": "TRUE",
    "Latitude": 52.0328,
    "Longitude": 8.54145,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.0328,8.54145",
    "IsOpen24Hours": true,
    "id": "52.0328,8.54145"
  },
  {
    "ID": 639520899,
    "Tankstellennummer": 610601,
    "Name": "WESTFALEN, BIELEFELD",
    "Address": "Eckendorfer Str. 32",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33609,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0521/31249",
    "24 hour?": "TRUE",
    "Latitude": 52.0334,
    "Longitude": 8.55817,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.0334,8.55817",
    "IsOpen24Hours": true,
    "id": "52.0334,8.55817"
  },
  {
    "ID": 704509163,
    "Tankstellennummer": 5002,
    "Name": "Agip Schoenebeck",
    "Address": "Magdeburger Str. 262",
    "Address__1": "",
    "City": "Schoenebeck",
    "Postcode": 39218,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 39288435",
    "24 hour?": "TRUE",
    "Latitude": 52.0342,
    "Longitude": 11.70533,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.0342,11.70533",
    "IsOpen24Hours": true,
    "id": "52.0342,11.70533"
  },
  {
    "ID": 1550435108,
    "Tankstellennummer": "TD038075",
    "Name": "LEMGO ENTRUPER WEG",
    "Address": "ENTRUPER WEG 48",
    "Address__1": "",
    "City": "LEMGO",
    "Postcode": 32657,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05261-3497",
    "24 hour?": "FALSE",
    "Latitude": 52.0351,
    "Longitude": 8.8936,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0351,8.8936",
    "IsOpen24Hours": false,
    "id": "52.0351,8.8936"
  },
  {
    "ID": 2117317535,
    "Tankstellennummer": 610176,
    "Name": "WESTFALEN, OSTBEVERN",
    "Address": "Loburg 58",
    "Address__1": "",
    "City": "Ostbevern",
    "Postcode": 48346,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02532/5834",
    "24 hour?": "FALSE",
    "Latitude": 52.0367,
    "Longitude": 7.85874,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0367,7.85874",
    "IsOpen24Hours": false,
    "id": "52.0367,7.85874"
  },
  {
    "ID": 2249,
    "Tankstellennummer": "0FA83",
    "Name": "Aral Tankstelle Versmold, Münsterstr.63",
    "Address": "Münsterstraße63",
    "Address__1": "",
    "City": "Versmold",
    "Postcode": 33775,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 542394330,
    "24 hour?": "FALSE",
    "Latitude": 52.038646,
    "Longitude": 8.142515,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.038646,8.142515",
    "IsOpen24Hours": false,
    "id": "52.038646,8.142515"
  },
  {
    "ID": 314700978,
    "Tankstellennummer": "TD040188",
    "Name": "BIELEFELD HERFORDER STR. 301",
    "Address": "HERFORDER STR. 301",
    "Address__1": "",
    "City": "BIELEFELD",
    "Postcode": 33609,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0521-74617",
    "24 hour?": "FALSE",
    "Latitude": 52.0408,
    "Longitude": 8.5723,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0408,8.5723",
    "IsOpen24Hours": false,
    "id": "52.0408,8.5723"
  },
  {
    "ID": 806486053,
    "Tankstellennummer": 610591,
    "Name": "WESTFALEN, AERZEN",
    "Address": "Reher Weg 36a",
    "Address__1": "",
    "City": "Aerzen",
    "Postcode": 31855,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05154/2250",
    "24 hour?": "FALSE",
    "Latitude": 52.0422,
    "Longitude": 9.2567,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0422,9.2567",
    "IsOpen24Hours": false,
    "id": "52.0422,9.2567"
  },
  {
    "ID": 1367253934,
    "Tankstellennummer": 610241,
    "Name": "WESTFALEN, VERSMOLD",
    "Address": "Westheider Weg 46-48",
    "Address__1": "",
    "City": "Versmold",
    "Postcode": 33775,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05423/8626",
    "24 hour?": "FALSE",
    "Latitude": 52.0439,
    "Longitude": 8.1492,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0439,8.1492",
    "IsOpen24Hours": false,
    "id": "52.0439,8.1492"
  },
  {
    "ID": 1569789400,
    "Tankstellennummer": "TD008037",
    "Name": "BARUTH LUCKENWALDER STR. 29A",
    "Address": "LUCKENWALDER STR. 29a",
    "Address__1": "",
    "City": "BARUTH",
    "Postcode": 15837,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033704-66346",
    "24 hour?": "FALSE",
    "Latitude": 52.0472,
    "Longitude": 13.4931,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0472,13.4931",
    "IsOpen24Hours": false,
    "id": "52.0472,13.4931"
  },
  {
    "ID": 244248984,
    "Tankstellennummer": "TD039768",
    "Name": "EMMERTHAL HAUPTSTR. 56",
    "Address": "HAUPTSTR. 56",
    "Address__1": "",
    "City": "EMMERTHAL",
    "Postcode": 31860,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05155-9199900",
    "24 hour?": "FALSE",
    "Latitude": 52.0483,
    "Longitude": 9.39,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0483,9.39",
    "IsOpen24Hours": false,
    "id": "52.0483,9.39"
  },
  {
    "ID": 2278,
    "Tankstellennummer": "0FB04",
    "Name": "Aral Tankstelle Bielefeld, Kirchdornberger Str. 85",
    "Address": "Kirchdornberger Straße 85",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33619,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 521101266,
    "24 hour?": "FALSE",
    "Latitude": 52.0489,
    "Longitude": 8.45241,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0489,8.45241",
    "IsOpen24Hours": false,
    "id": "52.0489,8.45241"
  },
  {
    "ID": 2831,
    "Tankstellennummer": "0FH11",
    "Name": "Aral Tankstelle Bielefeld, Herforder Str. 432",
    "Address": "Herforder Straße 432",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33609,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5219729855,
    "24 hour?": "TRUE",
    "Latitude": 52.049747,
    "Longitude": 8.587109,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.049747,8.587109",
    "IsOpen24Hours": true,
    "id": "52.049747,8.587109"
  },
  {
    "ID": 2258,
    "Tankstellennummer": "0FA89",
    "Name": "Aral Tankstelle Halle, Bielefelder Str.108",
    "Address": "Bielefelder Straße 108",
    "Address__1": "",
    "City": "Halle",
    "Postcode": 33790,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52019641,
    "24 hour?": "TRUE",
    "Latitude": 52.050865,
    "Longitude": 8.376857,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.050865,8.376857",
    "IsOpen24Hours": true,
    "id": "52.050865,8.376857"
  },
  {
    "ID": 2832,
    "Tankstellennummer": "0FH12",
    "Name": "Aral Tankstelle Bielefeld, Jöllenbecker Str.284",
    "Address": "Jöllenbecker Straße 284",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33613,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 521888330,
    "24 hour?": "FALSE",
    "Latitude": 52.052393,
    "Longitude": 8.520209,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.052393,8.520209",
    "IsOpen24Hours": false,
    "id": "52.052393,8.520209"
  },
  {
    "ID": 1561,
    "Tankstellennummer": "0F599",
    "Name": "Aral Tankstelle Salzgitter, Braunschweiger Str. 167",
    "Address": "Braunschweiger Straße 167",
    "Address__1": "",
    "City": "Salzgitter",
    "Postcode": 38259,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 534131260,
    "24 hour?": "TRUE",
    "Latitude": 52.053898,
    "Longitude": 10.380485,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.053898,10.380485",
    "IsOpen24Hours": true,
    "id": "52.053898,10.380485"
  },
  {
    "ID": 1522,
    "Tankstellennummer": "0F572",
    "Name": "Aral Tankstelle Sibbesse, Hauptstraße 21",
    "Address": "Hauptstraße 25",
    "Address__1": "",
    "City": "Sibbesse",
    "Postcode": 31079,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5065415,
    "24 hour?": "FALSE",
    "Latitude": 52.054875,
    "Longitude": 9.902859,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.054875,9.902859",
    "IsOpen24Hours": false,
    "id": "52.054875,9.902859"
  },
  {
    "ID": 1116142552,
    "Tankstellennummer": 610520,
    "Name": "WESTFALEN, LAER",
    "Address": "Münsterdamm 35",
    "Address__1": "",
    "City": "Laer",
    "Postcode": 48366,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02554/1081",
    "24 hour?": "TRUE",
    "Latitude": 52.0568,
    "Longitude": 7.36472,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.0568,7.36472",
    "IsOpen24Hours": true,
    "id": "52.0568,7.36472"
  },
  {
    "ID": 1832107914,
    "Tankstellennummer": 610006,
    "Name": "WESTFALEN, HERFORD",
    "Address": "Röntgenstr. 2/2a",
    "Address__1": "",
    "City": "Herford",
    "Postcode": 32052,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05221/179662",
    "24 hour?": "TRUE",
    "Latitude": 52.0608,
    "Longitude": 8.64421,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.0608,8.64421",
    "IsOpen24Hours": true,
    "id": "52.0608,8.64421"
  },
  {
    "ID": 2627,
    "Tankstellennummer": "0FD52",
    "Name": "Aral Tankstelle Wanzleben, Vor dem Rittertor",
    "Address": "Vor dem Rittertor",
    "Address__1": "",
    "City": "Wanzleben",
    "Postcode": 39164,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3920942078,
    "24 hour?": "TRUE",
    "Latitude": 52.06145,
    "Longitude": 11.432963,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.06145,11.432963",
    "IsOpen24Hours": true,
    "id": "52.06145,11.432963"
  },
  {
    "ID": 1536,
    "Tankstellennummer": "0F583",
    "Name": "Aral Tankstelle Salzhemmendorf, Calenberger Allee",
    "Address": "Calenberger Allee 1",
    "Address__1": "",
    "City": "Salzhemmendorf",
    "Postcode": 31020,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51535262,
    "24 hour?": "FALSE",
    "Latitude": 52.068024,
    "Longitude": 9.584918,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.068024,9.584918",
    "IsOpen24Hours": false,
    "id": "52.068024,9.584918"
  },
  {
    "ID": 1532,
    "Tankstellennummer": "0F580",
    "Name": "Aral Tankstelle Aerzen, Hamelner Str.23",
    "Address": "Hamelner Straße23",
    "Address__1": "",
    "City": "Aerzen",
    "Postcode": 31855,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51547098328,
    "24 hour?": "FALSE",
    "Latitude": 52.069667,
    "Longitude": 9.308468,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.069667,9.308468",
    "IsOpen24Hours": false,
    "id": "52.069667,9.308468"
  },
  {
    "ID": 2260,
    "Tankstellennummer": "0FA91",
    "Name": "Aral Tankstelle Werther, Bielefelder Str. 36",
    "Address": "Bielefelder Straße 36",
    "Address__1": "",
    "City": "Werther",
    "Postcode": 33824,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52033331,
    "24 hour?": "TRUE",
    "Latitude": 52.070366,
    "Longitude": 8.413767,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.070366,8.413767",
    "IsOpen24Hours": true,
    "id": "52.070366,8.413767"
  },
  {
    "ID": 819056351,
    "Tankstellennummer": "TD000228",
    "Name": "BIELEFELD-BRAKE BRAKER STR. 79",
    "Address": "BRAKER STR. 79",
    "Address__1": "",
    "City": "BIELEFELD-BRAKE",
    "Postcode": 33729,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0521-761823",
    "24 hour?": "FALSE",
    "Latitude": 52.0706,
    "Longitude": 8.6003,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0706,8.6003",
    "IsOpen24Hours": false,
    "id": "52.0706,8.6003"
  },
  {
    "ID": 2281,
    "Tankstellennummer": "0FB07",
    "Name": "Aral Tankstelle Ahaus, Wüllener Str.85",
    "Address": "Wüllener Straße 85",
    "Address__1": "",
    "City": "Ahaus",
    "Postcode": 48683,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2561971191,
    "24 hour?": "FALSE",
    "Latitude": 52.0729,
    "Longitude": 6.995821,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.0729,6.995821",
    "IsOpen24Hours": false,
    "id": "52.0729,6.995821"
  },
  {
    "ID": 1139727749,
    "Tankstellennummer": 900685,
    "Name": "WESTFALEN, BAT LIPPERLAND",
    "Address": "Am Speckenbach 28",
    "Address__1": "",
    "City": "Bad Salzuflen",
    "Postcode": 32107,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05221/7639635",
    "24 hour?": "TRUE",
    "Latitude": 52.0735,
    "Longitude": 8.66723,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.0735,8.66723",
    "IsOpen24Hours": true,
    "id": "52.0735,8.66723"
  },
  {
    "ID": 536346048,
    "Tankstellennummer": 610003,
    "Name": "WESTFALEN, AHAUS",
    "Address": "Wüllener Str. 64",
    "Address__1": "",
    "City": "Ahaus",
    "Postcode": 48683,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02561/3979",
    "24 hour?": "TRUE",
    "Latitude": 52.0738,
    "Longitude": 6.99724,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.0738,6.99724",
    "IsOpen24Hours": true,
    "id": "52.0738,6.99724"
  },
  {
    "ID": 1369030802,
    "Tankstellennummer": "0FP03",
    "Name": "FLÖTHE",
    "Address": "Lindenstraße 5",
    "Address__1": "",
    "City": "Flöthe",
    "Postcode": 38312,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5339928366,
    "24 hour?": "FALSE",
    "Latitude": 52.076215,
    "Longitude": 10.495459,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.076215,10.495459",
    "IsOpen24Hours": false,
    "id": "52.076215,10.495459"
  },
  {
    "ID": 1288736135,
    "Tankstellennummer": 610217,
    "Name": "WESTFALEN, BAD SALZUFLEN",
    "Address": "Am Zubringer 2",
    "Address__1": "",
    "City": "Bad Salzuflen",
    "Postcode": 32107,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05222/639144",
    "24 hour?": "FALSE",
    "Latitude": 52.0807,
    "Longitude": 8.72089,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0807,8.72089",
    "IsOpen24Hours": false,
    "id": "52.0807,8.72089"
  },
  {
    "ID": 251637333,
    "Tankstellennummer": 610650,
    "Name": "WESTFALEN, GLANDORF",
    "Address": "Münsterstr. 14",
    "Address__1": "",
    "City": "Glandorf",
    "Postcode": 49219,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05426/2271",
    "24 hour?": "TRUE",
    "Latitude": 52.0815,
    "Longitude": 7.99944,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.0815,7.99944",
    "IsOpen24Hours": true,
    "id": "52.0815,7.99944"
  },
  {
    "ID": 1371351823,
    "Tankstellennummer": "TD027029",
    "Name": "GOMMERN MAGDEBURGER STR. 28",
    "Address": "MAGDEBURGER STR. 28",
    "Address__1": "",
    "City": "GOMMERN",
    "Postcode": 39245,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039200-51317",
    "24 hour?": "FALSE",
    "Latitude": 52.0815,
    "Longitude": 11.8156,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0815,11.8156",
    "IsOpen24Hours": false,
    "id": "52.0815,11.8156"
  },
  {
    "ID": 1292786813,
    "Tankstellennummer": 610414,
    "Name": "WESTFALEN, AHAUS",
    "Address": "Wessumer Str. 63",
    "Address__1": "",
    "City": "Ahaus",
    "Postcode": 48683,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02561/5966",
    "24 hour?": "FALSE",
    "Latitude": 52.0823,
    "Longitude": 7.00263,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0823,7.00263",
    "IsOpen24Hours": false,
    "id": "52.0823,7.00263"
  },
  {
    "ID": 3305,
    "Tankstellennummer": "0FK63",
    "Name": "Aral Tankstelle Bad Salzuflen, Werler Str. 30",
    "Address": "Werler Straße 30",
    "Address__1": "",
    "City": "Bad Salzuflen",
    "Postcode": 32105,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52224410,
    "24 hour?": "FALSE",
    "Latitude": 52.084386,
    "Longitude": 8.736496,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.084386,8.736496",
    "IsOpen24Hours": false,
    "id": "52.084386,8.736496"
  },
  {
    "ID": 310518654,
    "Tankstellennummer": "TD032235",
    "Name": "TREUENBRIETZEN LEIPZIGER STR.",
    "Address": "LEIPZIGER STR. 152",
    "Address__1": "",
    "City": "TREUENBRIETZEN",
    "Postcode": 14929,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033748-15429",
    "24 hour?": "FALSE",
    "Latitude": 52.0874,
    "Longitude": 12.8495,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0874,12.8495",
    "IsOpen24Hours": false,
    "id": "52.0874,12.8495"
  },
  {
    "ID": 869199994,
    "Tankstellennummer": "TD033894",
    "Name": "LUCKENWALDE SALZUFLER ALLEE 40",
    "Address": "SALZUFLER ALLEE 40",
    "Address__1": "",
    "City": "LUCKENWALDE",
    "Postcode": 14943,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03371-622696",
    "24 hour?": "FALSE",
    "Latitude": 52.0885,
    "Longitude": 13.1792,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0885,13.1792",
    "IsOpen24Hours": false,
    "id": "52.0885,13.1792"
  },
  {
    "ID": 1026059628,
    "Tankstellennummer": 5066,
    "Name": "Agip Magdeburg",
    "Address": "Halberstaedter Chaussee 25",
    "Address__1": "",
    "City": "Magdeburg",
    "Postcode": 39116,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 39163133",
    "24 hour?": "TRUE",
    "Latitude": 52.0891,
    "Longitude": 11.5687,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.0891,11.5687",
    "IsOpen24Hours": true,
    "id": "52.0891,11.5687"
  },
  {
    "ID": 1205341780,
    "Tankstellennummer": "TD000290",
    "Name": "BIELEFELD VILSENDORFER STR. 87",
    "Address": "VILSENDORFER STR. 87",
    "Address__1": "",
    "City": "BIELEFELD",
    "Postcode": 33739,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05206-3301",
    "24 hour?": "FALSE",
    "Latitude": 52.0899,
    "Longitude": 8.5333,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0899,8.5333",
    "IsOpen24Hours": false,
    "id": "52.0899,8.5333"
  },
  {
    "ID": 872194974,
    "Tankstellennummer": 610021,
    "Name": "WESTFALEN, BIELEFELD",
    "Address": "Jöllenbecker Str. 526a",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33739,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05206/3108",
    "24 hour?": "TRUE",
    "Latitude": 52.0909,
    "Longitude": 8.51502,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.0909,8.51502",
    "IsOpen24Hours": true,
    "id": "52.0909,8.51502"
  },
  {
    "ID": 1814040219,
    "Tankstellennummer": 610477,
    "Name": "WESTFALEN, GREVEN",
    "Address": "Königstr. 69",
    "Address__1": "",
    "City": "Greven",
    "Postcode": 48268,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02571/3288",
    "24 hour?": "TRUE",
    "Latitude": 52.0931,
    "Longitude": 7.62367,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.0931,7.62367",
    "IsOpen24Hours": true,
    "id": "52.0931,7.62367"
  },
  {
    "ID": 160414832,
    "Tankstellennummer": "TD027391",
    "Name": "MAGDEBURG HERMANN-HESSE-STR. 1",
    "Address": "HERMANN-HESSE-STR. 1",
    "Address__1": "",
    "City": "MAGDEBURG",
    "Postcode": 39118,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0391-616397",
    "24 hour?": "FALSE",
    "Latitude": 52.0949,
    "Longitude": 11.6057,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0949,11.6057",
    "IsOpen24Hours": false,
    "id": "52.0949,11.6057"
  },
  {
    "ID": 2252,
    "Tankstellennummer": "0FA85",
    "Name": "Aral Tankstelle Bielefeld, Jöllenbecker Str. 565",
    "Address": "Jöllenbecker Straße 565",
    "Address__1": "",
    "City": "Bielefeld",
    "Postcode": 33739,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52063110,
    "24 hour?": "FALSE",
    "Latitude": 52.095025,
    "Longitude": 8.513664,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.095025,8.513664",
    "IsOpen24Hours": false,
    "id": "52.095025,8.513664"
  },
  {
    "ID": 1636945680,
    "Tankstellennummer": "TD027789",
    "Name": "WANZLEBEN-BOERDE WANZLEBENER",
    "Address": "12b WANZLEBENER Allee",
    "Address__1": "",
    "City": "WANZLEBEN-BOERDE",
    "Postcode": 39164,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039407-5080",
    "24 hour?": "FALSE",
    "Latitude": 52.0967,
    "Longitude": 11.3077,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.0967,11.3077",
    "IsOpen24Hours": false,
    "id": "52.0967,11.3077"
  },
  {
    "ID": 853368622,
    "Tankstellennummer": 610606,
    "Name": "WESTFALEN, HAMELN",
    "Address": "Pyrmonter Str. 35",
    "Address__1": "",
    "City": "Hameln",
    "Postcode": 31789,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05151/61250",
    "24 hour?": "TRUE",
    "Latitude": 52.0984,
    "Longitude": 9.35127,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.0984,9.35127",
    "IsOpen24Hours": true,
    "id": "52.0984,9.35127"
  },
  {
    "ID": 1544,
    "Tankstellennummer": "0F586",
    "Name": "Aral Tankstelle Hameln, 32 Pyrmonter Str.",
    "Address": "Pyrmonter Straße 32",
    "Address__1": "",
    "City": "Hameln",
    "Postcode": 31789,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5151980890,
    "24 hour?": "FALSE",
    "Latitude": 52.098497,
    "Longitude": 9.350625,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.098497,9.350625",
    "IsOpen24Hours": false,
    "id": "52.098497,9.350625"
  },
  {
    "ID": 3401,
    "Tankstellennummer": "0FL41",
    "Name": "Aral Tankstelle Magdeburg, Schoenebecker Str. 74",
    "Address": "Schoenebecker Straße 74",
    "Address__1": "",
    "City": "Magdeburg",
    "Postcode": 39104,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3914015407,
    "24 hour?": "TRUE",
    "Latitude": 52.099175,
    "Longitude": 11.647769,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.099175,11.647769",
    "IsOpen24Hours": true,
    "id": "52.099175,11.647769"
  },
  {
    "ID": 820227689,
    "Tankstellennummer": 900686,
    "Name": "WESTFALEN, BAT HERFORD-SUED",
    "Address": "Stadtholzstr. 252",
    "Address__1": "",
    "City": "BAT HERFORD-S?",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 52.1015,
    "Longitude": 8.72013,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1015,8.72013",
    "IsOpen24Hours": false,
    "id": "52.1015,8.72013"
  },
  {
    "ID": 245236614,
    "Tankstellennummer": "TD004309",
    "Name": "HERFORD AHMSER STR. 95",
    "Address": "AHMSER STR. 95",
    "Address__1": "",
    "City": "HERFORD",
    "Postcode": 32052,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05221-71087",
    "24 hour?": "FALSE",
    "Latitude": 52.1024,
    "Longitude": 8.6821,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1024,8.6821",
    "IsOpen24Hours": false,
    "id": "52.1024,8.6821"
  },
  {
    "ID": 43187342,
    "Tankstellennummer": 900550,
    "Name": "WESTFALEN, BAT HERFORD-NORD",
    "Address": "Stadtholzstr. 250",
    "Address__1": "",
    "City": "BAT HERFORD-NORD",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 52.1034,
    "Longitude": 8.72086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1034,8.72086",
    "IsOpen24Hours": false,
    "id": "52.1034,8.72086"
  },
  {
    "ID": 1525,
    "Tankstellennummer": "0F574",
    "Name": "Aral Tankstelle Hameln, Deisterstraße 13",
    "Address": "Deisterstraße 13",
    "Address__1": "",
    "City": "Hameln",
    "Postcode": 31785,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 515141626,
    "24 hour?": "TRUE",
    "Latitude": 52.10434,
    "Longitude": 9.365886,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.10434,9.365886",
    "IsOpen24Hours": true,
    "id": "52.10434,9.365886"
  },
  {
    "ID": 2567,
    "Tankstellennummer": "0FD02",
    "Name": "Aral Tankstelle Magdeburg, Halberstädter Chaussee196",
    "Address": "HalberstädterChaussee 196",
    "Address__1": "",
    "City": "Magdeburg",
    "Postcode": 39116,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 39161193822,
    "24 hour?": "TRUE",
    "Latitude": 52.105344,
    "Longitude": 11.590098,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.105344,11.590098",
    "IsOpen24Hours": true,
    "id": "52.105344,11.590098"
  },
  {
    "ID": 2097340309,
    "Tankstellennummer": 4638,
    "Name": "Agip Herford",
    "Address": "Elverdisser Str. 327",
    "Address__1": "",
    "City": "Herford",
    "Postcode": 32052,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 52217123",
    "24 hour?": "FALSE",
    "Latitude": 52.10577592,
    "Longitude": 8.66133834,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.10577592,8.66133834",
    "IsOpen24Hours": false,
    "id": "52.10577592,8.66133834"
  },
  {
    "ID": 2841,
    "Tankstellennummer": "0FH19",
    "Name": "Aral Tankstelle Herford, Salzufler Str. 111",
    "Address": "Salzufler Straße 111",
    "Address__1": "",
    "City": "Herford",
    "Postcode": 32052,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5221981593,
    "24 hour?": "FALSE",
    "Latitude": 52.107378,
    "Longitude": 8.690386,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.107378,8.690386",
    "IsOpen24Hours": false,
    "id": "52.107378,8.690386"
  },
  {
    "ID": 1979475292,
    "Tankstellennummer": 212055,
    "Name": "Grasdorf",
    "Address": "Lindener Berg",
    "Address__1": "",
    "City": "HOLLE/GRASDORF",
    "Postcode": 31188,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 52.10956,
    "Longitude": 10.167209,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.10956,10.167209",
    "IsOpen24Hours": false,
    "id": "52.10956,10.167209"
  },
  {
    "ID": 583435370,
    "Tankstellennummer": 212057,
    "Name": "Hamburg",
    "Address": "Maldfeldstra�e 23",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 21077,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 52.10956,
    "Longitude": 10.167209,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.10956,10.167209",
    "IsOpen24Hours": false,
    "id": "52.10956,10.167209"
  },
  {
    "ID": 2128748946,
    "Tankstellennummer": "TD038091",
    "Name": "HERFORD SALZUFLER STR.",
    "Address": "SALZUFLER STR. 65",
    "Address__1": "",
    "City": "HERFORD",
    "Postcode": 32052,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05221-6943542",
    "24 hour?": "FALSE",
    "Latitude": 52.1099,
    "Longitude": 8.6873,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1099,8.6873",
    "IsOpen24Hours": false,
    "id": "52.1099,8.6873"
  },
  {
    "ID": 1616,
    "Tankstellennummer": "0F647",
    "Name": "Aral Tankstelle Herford, Bielefelder Straße 56",
    "Address": "Bielefelder Straße 56",
    "Address__1": "",
    "City": "Herford",
    "Postcode": 32051,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5221169742,
    "24 hour?": "FALSE",
    "Latitude": 52.109947,
    "Longitude": 8.658077,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.109947,8.658077",
    "IsOpen24Hours": false,
    "id": "52.109947,8.658077"
  },
  {
    "ID": 537915841,
    "Tankstellennummer": 610420,
    "Name": "WESTFALEN, STEINFURT",
    "Address": "Altenberger Str. 128",
    "Address__1": "",
    "City": "Steinfurt",
    "Postcode": 48565,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02552/4616",
    "24 hour?": "FALSE",
    "Latitude": 52.1109,
    "Longitude": 7.40083,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1109,7.40083",
    "IsOpen24Hours": false,
    "id": "52.1109,7.40083"
  },
  {
    "ID": 2016915155,
    "Tankstellennummer": 610588,
    "Name": "WESTFALEN, BAD ROTHENFELDE",
    "Address": "Osnabrücker Str. 21",
    "Address__1": "",
    "City": "Bad Rothenfelde",
    "Postcode": 49214,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05424/4426",
    "24 hour?": "FALSE",
    "Latitude": 52.1131,
    "Longitude": 8.16225,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1131,8.16225",
    "IsOpen24Hours": false,
    "id": "52.1131,8.16225"
  },
  {
    "ID": 1814446209,
    "Tankstellennummer": 610510,
    "Name": "WESTFALEN, DISSEN",
    "Address": "Osnabrücker Str. 6",
    "Address__1": "",
    "City": "Dissen",
    "Postcode": 49201,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05421/4511",
    "24 hour?": "FALSE",
    "Latitude": 52.1154,
    "Longitude": 8.19836,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1154,8.19836",
    "IsOpen24Hours": false,
    "id": "52.1154,8.19836"
  },
  {
    "ID": 1517,
    "Tankstellennummer": "0F567",
    "Name": "Aral Tankstelle Coppenbrügge, Alte Heerstr. 18",
    "Address": "Alte Heerstraße 18",
    "Address__1": "",
    "City": "Coppenbrügge",
    "Postcode": 31863,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5156780080,
    "24 hour?": "FALSE",
    "Latitude": 52.115959,
    "Longitude": 9.555225,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.115959,9.555225",
    "IsOpen24Hours": false,
    "id": "52.115959,9.555225"
  },
  {
    "ID": 1872942717,
    "Tankstellennummer": "0FV71",
    "Name": "HAMELN",
    "Address": "Fischbecker Landstr. 91 (B83)",
    "Address__1": "",
    "City": "Hameln",
    "Postcode": 31787,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51511079824,
    "24 hour?": "TRUE",
    "Latitude": 52.121252,
    "Longitude": 9.334704,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.121252,9.334704",
    "IsOpen24Hours": true,
    "id": "52.121252,9.334704"
  },
  {
    "ID": 2279,
    "Tankstellennummer": "0FB05",
    "Name": "Aral Tankstelle Herford, Mindener Straße 115",
    "Address": "Mindener Straße 115",
    "Address__1": "",
    "City": "Herford",
    "Postcode": 32049,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 522124623,
    "24 hour?": "TRUE",
    "Latitude": 52.129069,
    "Longitude": 8.68675,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.129069,8.68675",
    "IsOpen24Hours": true,
    "id": "52.129069,8.68675"
  },
  {
    "ID": 486043483,
    "Tankstellennummer": 610455,
    "Name": "WESTFALEN, HERFORD",
    "Address": "Im Babenbecker Feld 56",
    "Address__1": "",
    "City": "Herford",
    "Postcode": 32051,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05221/33907",
    "24 hour?": "FALSE",
    "Latitude": 52.1295,
    "Longitude": 8.64347,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1295,8.64347",
    "IsOpen24Hours": false,
    "id": "52.1295,8.64347"
  },
  {
    "ID": 3367,
    "Tankstellennummer": "0FL11",
    "Name": "Aral Tankstelle Magdeburg, Jerichower Str. 24",
    "Address": "Jerichower Straße 24",
    "Address__1": "",
    "City": "Magdeburg",
    "Postcode": 39114,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 39183807333,
    "24 hour?": "TRUE",
    "Latitude": 52.131939,
    "Longitude": 11.665182,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.131939,11.665182",
    "IsOpen24Hours": true,
    "id": "52.131939,11.665182"
  },
  {
    "ID": 552726985,
    "Tankstellennummer": 610518,
    "Name": "WESTFALEN, LADBERGEN",
    "Address": "Grevener Str. 5",
    "Address__1": "",
    "City": "Ladbergen",
    "Postcode": 49549,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05485/1356",
    "24 hour?": "FALSE",
    "Latitude": 52.1358,
    "Longitude": 7.73578,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1358,7.73578",
    "IsOpen24Hours": false,
    "id": "52.1358,7.73578"
  },
  {
    "ID": 2863,
    "Tankstellennummer": "0FH38",
    "Name": "Aral Tankstelle Hildesheim, Marienburger Str. 85",
    "Address": "Marienburger Straße 85",
    "Address__1": "",
    "City": "Hildesheim",
    "Postcode": 31141,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 512184438,
    "24 hour?": "FALSE",
    "Latitude": 52.136567,
    "Longitude": 9.970387,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.136567,9.970387",
    "IsOpen24Hours": false,
    "id": "52.136567,9.970387"
  },
  {
    "ID": 686219000,
    "Tankstellennummer": "TD000278",
    "Name": "HILTER BIELEFELDER STR. 3",
    "Address": "BIELEFELDER STR. 3",
    "Address__1": "",
    "City": "HILTER",
    "Postcode": 49176,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05424-221733",
    "24 hour?": "FALSE",
    "Latitude": 52.1387,
    "Longitude": 8.1467,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1387,8.1467",
    "IsOpen24Hours": false,
    "id": "52.1387,8.1467"
  },
  {
    "ID": 1570,
    "Tankstellennummer": "0F611",
    "Name": "Aral Tankstelle Salzgitter, Vor dem Dorfe 30",
    "Address": "Vor dem Dorfe 30",
    "Address__1": "",
    "City": "Salzgitter",
    "Postcode": 38229,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 534141780,
    "24 hour?": "TRUE",
    "Latitude": 52.140024,
    "Longitude": 10.334923,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.140024,10.334923",
    "IsOpen24Hours": true,
    "id": "52.140024,10.334923"
  },
  {
    "ID": 2584,
    "Tankstellennummer": "0FD19",
    "Name": "Aral Tankstelle Eisenhüttenstadt, Karl-Marx-Str.",
    "Address": "Karl-Marx-Straße 67",
    "Address__1": "",
    "City": "Eisenhüttenstadt",
    "Postcode": 15890,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 336444953,
    "24 hour?": "TRUE",
    "Latitude": 52.140165,
    "Longitude": 14.634248,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.140165,14.634248",
    "IsOpen24Hours": true,
    "id": "52.140165,14.634248"
  },
  {
    "ID": 1884442396,
    "Tankstellennummer": "TD027847",
    "Name": "VOELPKE FRIEDENSSTR. 1",
    "Address": "FRIEDENSSTR.1",
    "Address__1": "",
    "City": "VOELPKE",
    "Postcode": 39393,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039402-9610",
    "24 hour?": "FALSE",
    "Latitude": 52.1406,
    "Longitude": 11.1057,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1406,11.1057",
    "IsOpen24Hours": false,
    "id": "52.1406,11.1057"
  },
  {
    "ID": 2251,
    "Tankstellennummer": "0FA84",
    "Name": "Aral Tankstelle Spenge, Enger Straße 14",
    "Address": "Enger Straße 14",
    "Address__1": "",
    "City": "Spenge",
    "Postcode": 32139,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5225859898,
    "24 hour?": "FALSE",
    "Latitude": 52.140856,
    "Longitude": 8.492113,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.140856,8.492113",
    "IsOpen24Hours": false,
    "id": "52.140856,8.492113"
  },
  {
    "ID": 1834225230,
    "Tankstellennummer": "TD027284",
    "Name": "MAGDEBURG ALBERT-VATER-STR. 30",
    "Address": "ALBERT-VATER-STR. 30",
    "Address__1": "",
    "City": "MAGDEBURG",
    "Postcode": 39108,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0391-2511279",
    "24 hour?": "FALSE",
    "Latitude": 52.1413,
    "Longitude": 11.6234,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1413,11.6234",
    "IsOpen24Hours": false,
    "id": "52.1413,11.6234"
  },
  {
    "ID": 1863544927,
    "Tankstellennummer": 610011,
    "Name": "WESTFALEN, MELLE",
    "Address": "Gerdener Str. 4",
    "Address__1": "",
    "City": "Melle",
    "Postcode": 49326,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05428/921095",
    "24 hour?": "FALSE",
    "Latitude": 52.1427,
    "Longitude": 8.38747,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1427,8.38747",
    "IsOpen24Hours": false,
    "id": "52.1427,8.38747"
  },
  {
    "ID": 1940913785,
    "Tankstellennummer": "TD112720",
    "Name": "METELEN HEEKER STR. 9",
    "Address": "HEEKER STR. 9",
    "Address__1": "",
    "City": "METELEN",
    "Postcode": 48629,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02556-98775",
    "24 hour?": "FALSE",
    "Latitude": 52.1434,
    "Longitude": 7.2078,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1434,7.2078",
    "IsOpen24Hours": false,
    "id": "52.1434,7.2078"
  },
  {
    "ID": 2743,
    "Tankstellennummer": "0FE49",
    "Name": "Aral Tankstelle Belzig, Brücker Landstr. 22 a",
    "Address": "Brücker Landstraße 22 a",
    "Address__1": "",
    "City": "Belzig",
    "Postcode": 14806,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3384130222,
    "24 hour?": "FALSE",
    "Latitude": 52.144699,
    "Longitude": 12.612856,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.144699,12.612856",
    "IsOpen24Hours": false,
    "id": "52.144699,12.612856"
  },
  {
    "ID": 1569,
    "Tankstellennummer": "0F610",
    "Name": "Aral Tankstelle Schöningen, Helmstedter Str. 33",
    "Address": "Helmstedter Straße 33",
    "Address__1": "",
    "City": "Schöningen",
    "Postcode": 38364,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 535259024,
    "24 hour?": "TRUE",
    "Latitude": 52.145306,
    "Longitude": 10.967413,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.145306,10.967413",
    "IsOpen24Hours": true,
    "id": "52.145306,10.967413"
  },
  {
    "ID": 1377,
    "Tankstellennummer": "0FE93",
    "Name": "Aral Autohof Ladbergen, Espenhof 1",
    "Address": "Espenhof 1",
    "Address__1": "",
    "City": "Ladbergen",
    "Postcode": 49549,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5485831620,
    "24 hour?": "TRUE",
    "Latitude": 52.146488,
    "Longitude": 7.736124,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.146488,7.736124",
    "IsOpen24Hours": true,
    "id": "52.146488,7.736124"
  },
  {
    "ID": 1588,
    "Tankstellennummer": "0F625",
    "Name": "Aral Tankstelle Hildesheim, Alfelder Straße 99",
    "Address": "Alfelder Straße 99",
    "Address__1": "",
    "City": "Hildesheim",
    "Postcode": 31139,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 512143480,
    "24 hour?": "TRUE",
    "Latitude": 52.147231,
    "Longitude": 9.938522,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.147231,9.938522",
    "IsOpen24Hours": true,
    "id": "52.147231,9.938522"
  },
  {
    "ID": 2256,
    "Tankstellennummer": "0FA88",
    "Name": "Aral Tankstelle Spenge, Lange Straße 100",
    "Address": "Lange Straße 100",
    "Address__1": "",
    "City": "Spenge",
    "Postcode": 32139,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 522587720,
    "24 hour?": "FALSE",
    "Latitude": 52.148073,
    "Longitude": 8.485003,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.148073,8.485003",
    "IsOpen24Hours": false,
    "id": "52.148073,8.485003"
  },
  {
    "ID": 476995466,
    "Tankstellennummer": "TD028407",
    "Name": "BAD BELZIG BRANDENBURGER STR.",
    "Address": "BRANDENBURGER STR.",
    "Address__1": "",
    "City": "BAD BELZIG",
    "Postcode": 14806,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033841-35772",
    "24 hour?": "FALSE",
    "Latitude": 52.1505,
    "Longitude": 12.6005,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1505,12.6005",
    "IsOpen24Hours": false,
    "id": "52.1505,12.6005"
  },
  {
    "ID": 1559,
    "Tankstellennummer": "0F598",
    "Name": "Aral Tankstelle Schöppenstedt, Schwarzer Weg 1",
    "Address": "Schwarzer Weg 1",
    "Address__1": "",
    "City": "Schöppenstedt",
    "Postcode": 38170,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 53329685327,
    "24 hour?": "TRUE",
    "Latitude": 52.151831,
    "Longitude": 10.768843,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.151831,10.768843",
    "IsOpen24Hours": true,
    "id": "52.151831,10.768843"
  },
  {
    "ID": 803099447,
    "Tankstellennummer": 5039,
    "Name": "Agip Magdeburg",
    "Address": "Olvenstedter Chaussee 22",
    "Address__1": "",
    "City": "Magdeburg",
    "Postcode": 39130,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 39172147",
    "24 hour?": "TRUE",
    "Latitude": 52.15276,
    "Longitude": 11.57365,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.15276,11.57365",
    "IsOpen24Hours": true,
    "id": "52.15276,11.57365"
  },
  {
    "ID": 573623430,
    "Tankstellennummer": "TD141020",
    "Name": "HILDESHEIM BUECKEBERGSTR. 1",
    "Address": "BUECKEBERGSTR. 1",
    "Address__1": "",
    "City": "HILDESHEIM",
    "Postcode": 31137,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05121-43777",
    "24 hour?": "FALSE",
    "Latitude": 52.1539,
    "Longitude": 9.9292,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1539,9.9292",
    "IsOpen24Hours": false,
    "id": "52.1539,9.9292"
  },
  {
    "ID": 1152,
    "Tankstellennummer": "0F284",
    "Name": "Aral Tankstelle Wolfenbüttel, Lange Straße 39",
    "Address": "Lange Straße 39",
    "Address__1": "",
    "City": "Wolfenbüttel",
    "Postcode": 38300,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 53311235,
    "24 hour?": "TRUE",
    "Latitude": 52.156558,
    "Longitude": 10.539676,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.156558,10.539676",
    "IsOpen24Hours": true,
    "id": "52.156558,10.539676"
  },
  {
    "ID": 2508,
    "Tankstellennummer": "0FC52",
    "Name": "Aral Tankstelle Magdeburg, Olvenstedter Graseweg",
    "Address": "Olvenstedter Graseweg",
    "Address__1": "",
    "City": "Magdeburg",
    "Postcode": 39128,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3917226129,
    "24 hour?": "TRUE",
    "Latitude": 52.156639,
    "Longitude": 11.588091,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.156639,11.588091",
    "IsOpen24Hours": true,
    "id": "52.156639,11.588091"
  },
  {
    "ID": 1999462255,
    "Tankstellennummer": 610012,
    "Name": "WESTFALEN, VLOTHO",
    "Address": "Herforder Str. 111",
    "Address__1": "",
    "City": "Vlotho",
    "Postcode": 32602,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05733/180447",
    "24 hour?": "FALSE",
    "Latitude": 52.1578,
    "Longitude": 8.84442,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1578,8.84442",
    "IsOpen24Hours": false,
    "id": "52.1578,8.84442"
  },
  {
    "ID": 333874139,
    "Tankstellennummer": "TD037267",
    "Name": "HILDESHEIM BERLINER STRASSE 2",
    "Address": "BERLINER STRASSE 2",
    "Address__1": "",
    "City": "HILDESHEIM",
    "Postcode": 31135,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05121-52584",
    "24 hour?": "FALSE",
    "Latitude": 52.1582,
    "Longitude": 9.9665,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1582,9.9665",
    "IsOpen24Hours": false,
    "id": "52.1582,9.9665"
  },
  {
    "ID": 698188685,
    "Tankstellennummer": 610037,
    "Name": "WESTFALEN, STEINFURT",
    "Address": "Carl-Benz-Str. 2",
    "Address__1": "",
    "City": "Steinfurt",
    "Postcode": 48565,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02551/703605",
    "24 hour?": "TRUE",
    "Latitude": 52.1589,
    "Longitude": 7.31906,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.1589,7.31906",
    "IsOpen24Hours": true,
    "id": "52.1589,7.31906"
  },
  {
    "ID": 942824487,
    "Tankstellennummer": "0FQ60",
    "Name": "HILDESHEIM",
    "Address": "Albert-Einstein-Straße",
    "Address__1": "",
    "City": "Hildesheim",
    "Postcode": 31135,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51216908322,
    "24 hour?": "TRUE",
    "Latitude": 52.160312,
    "Longitude": 9.997376,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.160312,9.997376",
    "IsOpen24Hours": true,
    "id": "52.160312,9.997376"
  },
  {
    "ID": 1568,
    "Tankstellennummer": "0F609",
    "Name": "Aral Tankstelle Wolfenbüttel, Adersheimer Str. 25",
    "Address": "Adersheimer Straße 25",
    "Address__1": "",
    "City": "Wolfenbüttel",
    "Postcode": 38304,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 53314738,
    "24 hour?": "FALSE",
    "Latitude": 52.161253,
    "Longitude": 10.517295,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.161253,10.517295",
    "IsOpen24Hours": false,
    "id": "52.161253,10.517295"
  },
  {
    "ID": 1136,
    "Tankstellennummer": "0F271",
    "Name": "Aral Tankstelle Wolfenbüttel, Leipziger Straße 8c",
    "Address": "Leipziger Straße 8c",
    "Address__1": "",
    "City": "Wolfenbüttel",
    "Postcode": 38302,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5331907575,
    "24 hour?": "TRUE",
    "Latitude": 52.163111,
    "Longitude": 10.552871,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.163111,10.552871",
    "IsOpen24Hours": true,
    "id": "52.163111,10.552871"
  },
  {
    "ID": 2226,
    "Tankstellennummer": "0FA66",
    "Name": "Aral Tankstelle Hiddenhausen, Bünder Straße 343",
    "Address": "Bünder Straße 343",
    "Address__1": "",
    "City": "Hiddenhausen",
    "Postcode": 32120,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 522161977,
    "24 hour?": "FALSE",
    "Latitude": 52.163329,
    "Longitude": 8.634844,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.163329,8.634844",
    "IsOpen24Hours": false,
    "id": "52.163329,8.634844"
  },
  {
    "ID": 778748338,
    "Tankstellennummer": "TD004689",
    "Name": "EMSDETTEN TAUBENSTR. 40",
    "Address": "TAUBENSTR. 40",
    "Address__1": "",
    "City": "EMSDETTEN",
    "Postcode": 48282,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "02572-8771733",
    "24 hour?": "FALSE",
    "Latitude": 52.1649,
    "Longitude": 7.5094,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1649,7.5094",
    "IsOpen24Hours": false,
    "id": "52.1649,7.5094"
  },
  {
    "ID": 214501199,
    "Tankstellennummer": "TD000286",
    "Name": "MELLE-WELLINGHOLZHAUSEN WELLIN",
    "Address": "WELLINGHOLZHAUSER STR.89",
    "Address__1": "",
    "City": "MELLE-WELLINGHOLZHAUSEN",
    "Postcode": 49326,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05429-929880",
    "24 hour?": "FALSE",
    "Latitude": 52.1662,
    "Longitude": 8.2677,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1662,8.2677",
    "IsOpen24Hours": false,
    "id": "52.1662,8.2677"
  },
  {
    "ID": 1228020900,
    "Tankstellennummer": "TD020107",
    "Name": "BEESKOW RUDOLF-BREITSCHEID-STR",
    "Address": "RUDOLF-BREITSCHEID-STR. 9b",
    "Address__1": "",
    "City": "BEESKOW",
    "Postcode": 15848,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03366-20497",
    "24 hour?": "FALSE",
    "Latitude": 52.1662,
    "Longitude": 14.2402,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1662,14.2402",
    "IsOpen24Hours": false,
    "id": "52.1662,14.2402"
  },
  {
    "ID": 2215,
    "Tankstellennummer": "0FA56",
    "Name": "Aral Tankstelle Emsdetten, Grevener Damm 78",
    "Address": "Grevener Damm 78",
    "Address__1": "",
    "City": "Emsdetten",
    "Postcode": 48282,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 25722540,
    "24 hour?": "FALSE",
    "Latitude": 52.167247,
    "Longitude": 7.542205,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.167247,7.542205",
    "IsOpen24Hours": false,
    "id": "52.167247,7.542205"
  },
  {
    "ID": 1529,
    "Tankstellennummer": "0F577",
    "Name": "Aral Tankstelle Hessisch Oldendorf, Welseder Strasse 20",
    "Address": "Welseder Straße 20",
    "Address__1": "",
    "City": "Hessisch Oldendorf",
    "Postcode": 31840,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51524715,
    "24 hour?": "TRUE",
    "Latitude": 52.171137,
    "Longitude": 9.238018,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.171137,9.238018",
    "IsOpen24Hours": true,
    "id": "52.171137,9.238018"
  },
  {
    "ID": 2733,
    "Tankstellennummer": "0FE43",
    "Name": "Aral Tankstelle Wünsdorf, Berliner Str. 41",
    "Address": "Berliner Allee 41",
    "Address__1": "",
    "City": "Zossen / OT Wünsdorf",
    "Postcode": 15806,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3370266125,
    "24 hour?": "TRUE",
    "Latitude": 52.171222,
    "Longitude": 13.471062,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.171222,13.471062",
    "IsOpen24Hours": true,
    "id": "52.171222,13.471062"
  },
  {
    "ID": 740524997,
    "Tankstellennummer": "TD038703",
    "Name": "HILDESHEIM LERCHENKAMP 36",
    "Address": "LERCHENKAMP 36",
    "Address__1": "",
    "City": "HILDESHEIM",
    "Postcode": 31137,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05121-9996442",
    "24 hour?": "FALSE",
    "Latitude": 52.172,
    "Longitude": 9.9383,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.172,9.9383",
    "IsOpen24Hours": false,
    "id": "52.172,9.9383"
  },
  {
    "ID": 1612,
    "Tankstellennummer": "0F645",
    "Name": "Aral Tankstelle Vlotho, Mindener Str. 17",
    "Address": "Mindener Straße 17",
    "Address__1": "",
    "City": "Vlotho",
    "Postcode": 32602,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 573380813,
    "24 hour?": "FALSE",
    "Latitude": 52.173933,
    "Longitude": 8.868517,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.173933,8.868517",
    "IsOpen24Hours": false,
    "id": "52.173933,8.868517"
  },
  {
    "ID": 3406,
    "Tankstellennummer": "0FL46",
    "Name": "Aral Tankstelle Magdeburg, Ziolkowskistr. 14",
    "Address": "Ziolkowskistraße 14",
    "Address__1": "",
    "City": "Magdeburg",
    "Postcode": 39126,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3912515281,
    "24 hour?": "TRUE",
    "Latitude": 52.175453,
    "Longitude": 11.629103,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.175453,11.629103",
    "IsOpen24Hours": true,
    "id": "52.175453,11.629103"
  },
  {
    "ID": 949136010,
    "Tankstellennummer": 610038,
    "Name": "WESTFALEN, GRONAU",
    "Address": "Nienborger Str. 28",
    "Address__1": "",
    "City": "Gronau",
    "Postcode": 48599,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "01577 - 4053482",
    "24 hour?": "FALSE",
    "Latitude": 52.1757,
    "Longitude": 7.04898,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1757,7.04898",
    "IsOpen24Hours": false,
    "id": "52.1757,7.04898"
  },
  {
    "ID": 1509492397,
    "Tankstellennummer": "0FU68",
    "Name": "HOHENWARSLEBEN",
    "Address": "Berliner Allee 7",
    "Address__1": "",
    "City": "Hohenwarsleben",
    "Postcode": 39326,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3920460849,
    "24 hour?": "TRUE",
    "Latitude": 52.177071,
    "Longitude": 11.490125,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.177071,11.490125",
    "IsOpen24Hours": true,
    "id": "52.177071,11.490125"
  },
  {
    "ID": 1384189471,
    "Tankstellennummer": "TD027433",
    "Name": "MAGDEBURG BARLEBER CH. 3",
    "Address": "BARLEBER CH. 3",
    "Address__1": "",
    "City": "MAGDEBURG",
    "Postcode": 39126,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0391-2530930",
    "24 hour?": "FALSE",
    "Latitude": 52.1786,
    "Longitude": 11.6291,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1786,11.6291",
    "IsOpen24Hours": false,
    "id": "52.1786,11.6291"
  },
  {
    "ID": 1575,
    "Tankstellennummer": "0F016",
    "Name": "Aral Tankstelle Hildesheim, Kennedydamm 200 Ost",
    "Address": "Kennedydamm 200 Ost",
    "Address__1": "",
    "City": "Hildesheim",
    "Postcode": 31135,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 512155951,
    "24 hour?": "TRUE",
    "Latitude": 52.17952,
    "Longitude": 9.964843,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.17952,9.964843",
    "IsOpen24Hours": true,
    "id": "52.17952,9.964843"
  },
  {
    "ID": 1295556704,
    "Tankstellennummer": 610581,
    "Name": "WESTFALEN, EMSDETTEN",
    "Address": "Rheiner Str. 121",
    "Address__1": "",
    "City": "Emsdetten",
    "Postcode": 48282,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02572/151194",
    "24 hour?": "FALSE",
    "Latitude": 52.1822,
    "Longitude": 7.52072,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1822,7.52072",
    "IsOpen24Hours": false,
    "id": "52.1822,7.52072"
  },
  {
    "ID": 1017302453,
    "Tankstellennummer": "TD034728",
    "Name": "GROSS SANTERSLEBEN BAB BOERDE",
    "Address": "BAB BOERDE SUED / A2",
    "Address__1": "",
    "City": "GROSS SANTERSLEBEN",
    "Postcode": 39343,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039206-68764",
    "24 hour?": "FALSE",
    "Latitude": 52.1841,
    "Longitude": 11.4502,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1841,11.4502",
    "IsOpen24Hours": false,
    "id": "52.1841,11.4502"
  },
  {
    "ID": 1836052414,
    "Tankstellennummer": "TD034710",
    "Name": "GROSS SANTERSLEBEN BAB BOERDE",
    "Address": "BAB BOERDE NORD / A2",
    "Address__1": "",
    "City": "GROSS SANTERSLEBEN",
    "Postcode": 39343,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039206-68351",
    "24 hour?": "FALSE",
    "Latitude": 52.1844,
    "Longitude": 11.4542,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1844,11.4542",
    "IsOpen24Hours": false,
    "id": "52.1844,11.4542"
  },
  {
    "ID": 1603,
    "Tankstellennummer": "0F637",
    "Name": "Aral Tankstelle Bünde, Herforder Str. 147 - 151",
    "Address": "Herforder Str. 147 - 151",
    "Address__1": "",
    "City": "Bünde",
    "Postcode": 32257,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5223878088,
    "24 hour?": "TRUE",
    "Latitude": 52.184992,
    "Longitude": 8.604273,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.184992,8.604273",
    "IsOpen24Hours": true,
    "id": "52.184992,8.604273"
  },
  {
    "ID": 2179,
    "Tankstellennummer": "0FA43",
    "Name": "Aral Tankstelle Lengerich, Osnabrücker Str.13",
    "Address": "Osnabrücker Straße 13",
    "Address__1": "",
    "City": "Lengerich",
    "Postcode": 49525,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 54814793,
    "24 hour?": "TRUE",
    "Latitude": 52.186098,
    "Longitude": 7.861557,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.186098,7.861557",
    "IsOpen24Hours": true,
    "id": "52.186098,7.861557"
  },
  {
    "ID": 801290421,
    "Tankstellennummer": "0FS88",
    "Name": "SALZGITTER",
    "Address": "A 39 Salzgitterhüttenblick Süd",
    "Address__1": "",
    "City": "Salzgitter",
    "Postcode": 38239,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 53009338093,
    "24 hour?": "TRUE",
    "Latitude": 52.188897,
    "Longitude": 10.396215,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.188897,10.396215",
    "IsOpen24Hours": true,
    "id": "52.188897,10.396215"
  },
  {
    "ID": 1087356804,
    "Tankstellennummer": 610585,
    "Name": "WESTFALEN, GEORGSMARIENHUETTE",
    "Address": "Teutoburger-Wald-Str. 45",
    "Address__1": "",
    "City": "Georgsmarienhütte",
    "Postcode": 49124,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05401/5206",
    "24 hour?": "FALSE",
    "Latitude": 52.1898,
    "Longitude": 8.06845,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1898,8.06845",
    "IsOpen24Hours": false,
    "id": "52.1898,8.06845"
  },
  {
    "ID": 2280,
    "Tankstellennummer": "0FB06",
    "Name": "Aral Tankstelle Löhne, Königstraße 57",
    "Address": "Königstraße 57",
    "Address__1": "",
    "City": "Löhne",
    "Postcode": 32584,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 57322225,
    "24 hour?": "FALSE",
    "Latitude": 52.191102,
    "Longitude": 8.714315,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.191102,8.714315",
    "IsOpen24Hours": false,
    "id": "52.191102,8.714315"
  },
  {
    "ID": 533005840,
    "Tankstellennummer": "0FS01",
    "Name": "SALZGITTER",
    "Address": "A 39 Salzgitterhüttenblick Nord",
    "Address__1": "",
    "City": "Salzgitter",
    "Postcode": 38239,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 53009304490,
    "24 hour?": "TRUE",
    "Latitude": 52.191301,
    "Longitude": 10.399207,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.191301,10.399207",
    "IsOpen24Hours": true,
    "id": "52.191301,10.399207"
  },
  {
    "ID": 495969236,
    "Tankstellennummer": 610703,
    "Name": "WESTFALEN, BUENDE",
    "Address": "Herforder Str. 54",
    "Address__1": "",
    "City": "Bünde",
    "Postcode": 32257,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05223/14290",
    "24 hour?": "FALSE",
    "Latitude": 52.1921,
    "Longitude": 8.59402,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1921,8.59402",
    "IsOpen24Hours": false,
    "id": "52.1921,8.59402"
  },
  {
    "ID": 1646141158,
    "Tankstellennummer": "0FR43",
    "Name": "RUDIGER THORMEIER",
    "Address": "Pflaumenallee 1",
    "Address__1": "",
    "City": "Uhrsleben",
    "Postcode": 39343,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 390529600,
    "24 hour?": "TRUE",
    "Latitude": 52.193805,
    "Longitude": 11.263491,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.193805,11.263491",
    "IsOpen24Hours": true,
    "id": "52.193805,11.263491"
  },
  {
    "ID": 1514,
    "Tankstellennummer": "0F564",
    "Name": "Aral Tankstelle Rinteln, Auf der Bünte 1",
    "Address": "Auf der Bünte 1",
    "Address__1": "",
    "City": "Rinteln",
    "Postcode": 31737,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 57515275,
    "24 hour?": "FALSE",
    "Latitude": 52.194334,
    "Longitude": 9.081367,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.194334,9.081367",
    "IsOpen24Hours": false,
    "id": "52.194334,9.081367"
  },
  {
    "ID": 1546569201,
    "Tankstellennummer": "0FM62",
    "Name": "MELLE",
    "Address": "A 30",
    "Address__1": "",
    "City": "Melle",
    "Postcode": 49328,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 542242010,
    "24 hour?": "TRUE",
    "Latitude": 52.196053,
    "Longitude": 8.384381,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.196053,8.384381",
    "IsOpen24Hours": true,
    "id": "52.196053,8.384381"
  },
  {
    "ID": 231858813,
    "Tankstellennummer": "0FG17",
    "Name": "MELLE",
    "Address": "A30",
    "Address__1": "",
    "City": "Melle",
    "Postcode": 49328,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 640191450,
    "24 hour?": "TRUE",
    "Latitude": 52.197285,
    "Longitude": 8.388175,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.197285,8.388175",
    "IsOpen24Hours": true,
    "id": "52.197285,8.388175"
  },
  {
    "ID": 2211,
    "Tankstellennummer": "0FA52",
    "Name": "Aral Tankstelle Bad Oeynhausen, Weserstraße 46",
    "Address": "Weserstraße 46",
    "Address__1": "",
    "City": "Bad Oeynhausen",
    "Postcode": 32547,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 57313848,
    "24 hour?": "FALSE",
    "Latitude": 52.197994,
    "Longitude": 8.809394,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.197994,8.809394",
    "IsOpen24Hours": false,
    "id": "52.197994,8.809394"
  },
  {
    "ID": 219315758,
    "Tankstellennummer": 610646,
    "Name": "WESTFALEN, MELLE",
    "Address": "Gesmolder Str. 61",
    "Address__1": "",
    "City": "Melle",
    "Postcode": 49324,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05422/3414",
    "24 hour?": "FALSE",
    "Latitude": 52.1996,
    "Longitude": 8.32607,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.1996,8.32607",
    "IsOpen24Hours": false,
    "id": "52.1996,8.32607"
  },
  {
    "ID": 551293564,
    "Tankstellennummer": 610016,
    "Name": "WESTFALEN, ROEDINGHAUSEN",
    "Address": "Osnabrücker Str. 4",
    "Address__1": "",
    "City": "Rödinghausen",
    "Postcode": 32289,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05226/591000",
    "24 hour?": "FALSE",
    "Latitude": 52.2001,
    "Longitude": 8.45704,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2001,8.45704",
    "IsOpen24Hours": false,
    "id": "52.2001,8.45704"
  },
  {
    "ID": 2836,
    "Tankstellennummer": "0FH15",
    "Name": "Aral Tankstelle Bünde, Wasserbreite 105",
    "Address": "Wasserbreite 105",
    "Address__1": "",
    "City": "Bünde",
    "Postcode": 32257,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 52235538,
    "24 hour?": "FALSE",
    "Latitude": 52.203856,
    "Longitude": 8.5887,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.203856,8.5887",
    "IsOpen24Hours": false,
    "id": "52.203856,8.5887"
  },
  {
    "ID": 1832877886,
    "Tankstellennummer": 610608,
    "Name": "WESTFALEN, KIRCHLENGERN",
    "Address": "Lübbecker Str. 115",
    "Address__1": "",
    "City": "Kirchlengern",
    "Postcode": 32278,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05223/760030",
    "24 hour?": "FALSE",
    "Latitude": 52.2042,
    "Longitude": 8.64026,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2042,8.64026",
    "IsOpen24Hours": false,
    "id": "52.2042,8.64026"
  },
  {
    "ID": 2724,
    "Tankstellennummer": "0FE38",
    "Name": "Aral Autohof Magdeburg, Grabower Straße 2",
    "Address": "Grabower Straße 2",
    "Address__1": "",
    "City": "Magdeburg",
    "Postcode": 39126,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 39125443057,
    "24 hour?": "TRUE",
    "Latitude": 52.204878,
    "Longitude": 11.672212,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.204878,11.672212",
    "IsOpen24Hours": true,
    "id": "52.204878,11.672212"
  },
  {
    "ID": 814760316,
    "Tankstellennummer": 610103,
    "Name": "WESTFALEN, OCHTRUP",
    "Address": "Laurenzstr. 108",
    "Address__1": "",
    "City": "Ochtrup",
    "Postcode": 48607,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "02553/4471",
    "24 hour?": "TRUE",
    "Latitude": 52.2055,
    "Longitude": 7.21122,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.2055,7.21122",
    "IsOpen24Hours": true,
    "id": "52.2055,7.21122"
  },
  {
    "ID": 419427155,
    "Tankstellennummer": 610170,
    "Name": "WESTFALEN, WETTRINGEN",
    "Address": "Metelener Str. 40",
    "Address__1": "",
    "City": "Wettringen",
    "Postcode": 48493,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "TRUE",
    "Latitude": 52.2061,
    "Longitude": 7.30972,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.2061,7.30972",
    "IsOpen24Hours": true,
    "id": "52.2061,7.30972"
  },
  {
    "ID": 1565,
    "Tankstellennummer": "0F606",
    "Name": "Aral Tankstelle Lengede, Lafferder Strasse 7",
    "Address": "Lafferder Straße 7",
    "Address__1": "",
    "City": "Lengede",
    "Postcode": 38268,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 53447666,
    "24 hour?": "FALSE",
    "Latitude": 52.20636,
    "Longitude": 10.303791,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.20636,10.303791",
    "IsOpen24Hours": false,
    "id": "52.20636,10.303791"
  },
  {
    "ID": 2738,
    "Tankstellennummer": "0FM13",
    "Name": "Aral Autohof Alleringersleben, Im Kleinen Morgen / BAB 2",
    "Address": "Zuckerfabrik 1",
    "Address__1": "",
    "City": "Alleringersleben",
    "Postcode": 39343,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3940092246,
    "24 hour?": "TRUE",
    "Latitude": 52.209417,
    "Longitude": 11.133019,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.209417,11.133019",
    "IsOpen24Hours": true,
    "id": "52.209417,11.133019"
  },
  {
    "ID": 494419853,
    "Tankstellennummer": "0FP01",
    "Name": "PORTA WESTFALICA",
    "Address": "Zum Autohof 4",
    "Address__1": "",
    "City": "Porta Westfalica",
    "Postcode": 32457,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5731156930,
    "24 hour?": "TRUE",
    "Latitude": 52.209562,
    "Longitude": 8.872277,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.209562,8.872277",
    "IsOpen24Hours": true,
    "id": "52.209562,8.872277"
  },
  {
    "ID": 2854,
    "Tankstellennummer": "0FH31",
    "Name": "Aral Tankstelle Wettringen, August-Kuempers-Str. 6",
    "Address": "August-Kuempers-Straße 6",
    "Address__1": "",
    "City": "Wettringen",
    "Postcode": 48493,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 2557927752,
    "24 hour?": "FALSE",
    "Latitude": 52.210966,
    "Longitude": 7.325445,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.210966,7.325445",
    "IsOpen24Hours": false,
    "id": "52.210966,7.325445"
  },
  {
    "ID": 307080831,
    "Tankstellennummer": "TD038141",
    "Name": "MAGDEBURG GLINDENBERGER WEG 3",
    "Address": "GLINDENBERGER WEG 3",
    "Address__1": "",
    "City": "MAGDEBURG",
    "Postcode": 39126,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0391-59818744",
    "24 hour?": "FALSE",
    "Latitude": 52.2113,
    "Longitude": 11.6705,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2113,11.6705",
    "IsOpen24Hours": false,
    "id": "52.2113,11.6705"
  },
  {
    "ID": 50362343,
    "Tankstellennummer": "TD000262",
    "Name": "SPRINGE BERNAUER STR. 2A",
    "Address": "BERNAUER STR. 2A",
    "Address__1": "",
    "City": "SPRINGE",
    "Postcode": 31832,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05041-4040",
    "24 hour?": "FALSE",
    "Latitude": 52.2124,
    "Longitude": 9.5473,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2124,9.5473",
    "IsOpen24Hours": false,
    "id": "52.2124,9.5473"
  },
  {
    "ID": 2233,
    "Tankstellennummer": "0FA71",
    "Name": "Aral Tankstelle Bad Oeynhausen, Mindener Straße 89",
    "Address": "Mindener Straße 89",
    "Address__1": "",
    "City": "Bad Oeynhausen",
    "Postcode": 32547,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 573129688,
    "24 hour?": "TRUE",
    "Latitude": 52.212491,
    "Longitude": 8.815242,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.212491,8.815242",
    "IsOpen24Hours": true,
    "id": "52.212491,8.815242"
  },
  {
    "ID": 2549,
    "Tankstellennummer": "0FC90",
    "Name": "Aral Tankstelle Zossen, Bahnhofstr. 45/47",
    "Address": "Bahnhofstraße 45/47",
    "Address__1": "",
    "City": "Zossen",
    "Postcode": 15806,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3377302403,
    "24 hour?": "TRUE",
    "Latitude": 52.217103,
    "Longitude": 13.441134,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.217103,13.441134",
    "IsOpen24Hours": true,
    "id": "52.217103,13.441134"
  },
  {
    "ID": 1745784356,
    "Tankstellennummer": "0FT84",
    "Name": "MELLE",
    "Address": "Im Gewerbepark 2",
    "Address__1": "",
    "City": "Melle",
    "Postcode": 49326,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5422942012,
    "24 hour?": "TRUE",
    "Latitude": 52.219127,
    "Longitude": 8.27261,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.219127,8.27261",
    "IsOpen24Hours": true,
    "id": "52.219127,8.27261"
  },
  {
    "ID": 2842,
    "Tankstellennummer": "0FH20",
    "Name": "Aral Tankstelle Porta- Westfalica, Hitzepohl-West 1",
    "Address": "Hitzepohl-West 1",
    "Address__1": "",
    "City": "Porta- Westfalica",
    "Postcode": 32457,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 57171208,
    "24 hour?": "FALSE",
    "Latitude": 52.219282,
    "Longitude": 8.88479,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.219282,8.88479",
    "IsOpen24Hours": false,
    "id": "52.219282,8.88479"
  },
  {
    "ID": 588632005,
    "Tankstellennummer": "0FQ19",
    "Name": "HELMSTEDT",
    "Address": "A 2",
    "Address__1": "",
    "City": "Helmstedt",
    "Postcode": 38350,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 53512077,
    "24 hour?": "TRUE",
    "Latitude": 52.220212,
    "Longitude": 11.058335,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.220212,11.058335",
    "IsOpen24Hours": true,
    "id": "52.220212,11.058335"
  },
  {
    "ID": 2838,
    "Tankstellennummer": "0FH17",
    "Name": "Aral Tankstelle Gronau, Gildehauser Str. 154",
    "Address": "Gildehauser Straße 154",
    "Address__1": "",
    "City": "Gronau",
    "Postcode": 48599,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 25629919331,
    "24 hour?": "FALSE",
    "Latitude": 52.221413,
    "Longitude": 7.042137,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.221413,7.042137",
    "IsOpen24Hours": false,
    "id": "52.221413,7.042137"
  },
  {
    "ID": 597773988,
    "Tankstellennummer": "TD028415",
    "Name": "TREBBIN BERLINER TOR 2",
    "Address": "BERLINER TOR 2",
    "Address__1": "",
    "City": "TREBBIN",
    "Postcode": 14959,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033731-15271",
    "24 hour?": "FALSE",
    "Latitude": 52.222,
    "Longitude": 13.2196,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.222,13.2196",
    "IsOpen24Hours": false,
    "id": "52.222,13.2196"
  },
  {
    "ID": 1384095920,
    "Tankstellennummer": "TD006833",
    "Name": "GEORGSMARIENHUETTE ALTE HEERST",
    "Address": "ALTE HEERSTR.2-4",
    "Address__1": "",
    "City": "GEORGSMARIENHUETTE",
    "Postcode": 49124,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05401-43174",
    "24 hour?": "FALSE",
    "Latitude": 52.2221,
    "Longitude": 8.0835,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2221,8.0835",
    "IsOpen24Hours": false,
    "id": "52.2221,8.0835"
  },
  {
    "ID": 1587,
    "Tankstellennummer": "0F624",
    "Name": "Aral Tankstelle Helmstedt, Poststrasse 5",
    "Address": "Poststraße 5",
    "Address__1": "",
    "City": "Helmstedt",
    "Postcode": 38350,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 53517442,
    "24 hour?": "TRUE",
    "Latitude": 52.224347,
    "Longitude": 11.01388,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.224347,11.01388",
    "IsOpen24Hours": true,
    "id": "52.224347,11.01388"
  },
  {
    "ID": 640602364,
    "Tankstellennummer": 4779,
    "Name": "Agip Helmstedt",
    "Address": "Helmstedt Sued / BAB A2",
    "Address__1": "",
    "City": "Helmstedt",
    "Postcode": 38350,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "",
    "24 hour?": "TRUE",
    "Latitude": 52.2244,
    "Longitude": 11.0111,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.2244,11.0111",
    "IsOpen24Hours": true,
    "id": "52.2244,11.0111"
  },
  {
    "ID": 3427,
    "Tankstellennummer": "0FL62",
    "Name": "Aral Tankstelle Löhne, Luebbecker Str. 166",
    "Address": "Luebbecker Straße 166",
    "Address__1": "",
    "City": "Löhne",
    "Postcode": 32584,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5732972802,
    "24 hour?": "TRUE",
    "Latitude": 52.224907,
    "Longitude": 8.706377,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.224907,8.706377",
    "IsOpen24Hours": true,
    "id": "52.224907,8.706377"
  },
  {
    "ID": 1576,
    "Tankstellennummer": "0FE81",
    "Name": "Aral Autobahntankstelle Auetal, Auetal Süd (A 2)",
    "Address": "A2",
    "Address__1": "",
    "City": "Auetal",
    "Postcode": 31749,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 57529296620,
    "24 hour?": "TRUE",
    "Latitude": 52.225178,
    "Longitude": 9.229529,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.225178,9.229529",
    "IsOpen24Hours": true,
    "id": "52.225178,9.229529"
  },
  {
    "ID": 583072469,
    "Tankstellennummer": 610275,
    "Name": "WESTFALEN, AUETAL",
    "Address": "Zur Obersburg 2",
    "Address__1": "",
    "City": "Auetal",
    "Postcode": 31749,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05752/1253",
    "24 hour?": "FALSE",
    "Latitude": 52.231,
    "Longitude": 9.23689,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.231,9.23689",
    "IsOpen24Hours": false,
    "id": "52.231,9.23689"
  },
  {
    "ID": 1132699234,
    "Tankstellennummer": 610317,
    "Name": "WESTFALEN, BISSENDORF",
    "Address": "Meller Str. 41",
    "Address__1": "",
    "City": "Bissendorf",
    "Postcode": 49143,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05402/643410",
    "24 hour?": "FALSE",
    "Latitude": 52.2325,
    "Longitude": 8.17424,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2325,8.17424",
    "IsOpen24Hours": false,
    "id": "52.2325,8.17424"
  },
  {
    "ID": 2262,
    "Tankstellennummer": "0FA93",
    "Name": "Aral Autobahntankstelle Tecklenburg, Tecklenburger Land West (A 1)",
    "Address": "A1",
    "Address__1": "",
    "City": "Tecklenburg",
    "Postcode": 49545,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5456935801,
    "24 hour?": "TRUE",
    "Latitude": 52.232534,
    "Longitude": 7.878547,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.232534,7.878547",
    "IsOpen24Hours": true,
    "id": "52.232534,7.878547"
  },
  {
    "ID": 560680011,
    "Tankstellennummer": "TD040386",
    "Name": "BAD OEYNHAUSEN DEHMER STR. 52",
    "Address": "DEHMER STR. 52",
    "Address__1": "",
    "City": "BAD OEYNHAUSEN",
    "Postcode": 32549,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05731-4989381",
    "24 hour?": "FALSE",
    "Latitude": 52.2337,
    "Longitude": 8.8334,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2337,8.8334",
    "IsOpen24Hours": false,
    "id": "52.2337,8.8334"
  },
  {
    "ID": 418367159,
    "Tankstellennummer": "TD000276",
    "Name": "RHEINE-MESUM RHEINER STR. 89",
    "Address": "RHEINER STR. 89",
    "Address__1": "",
    "City": "RHEINE-MESUM",
    "Postcode": 48432,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05975-8200",
    "24 hour?": "FALSE",
    "Latitude": 52.2342,
    "Longitude": 7.4833,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2342,7.4833",
    "IsOpen24Hours": false,
    "id": "52.2342,7.4833"
  },
  {
    "ID": 1557,
    "Tankstellennummer": "0F597",
    "Name": "Aral Tankstelle Braunschweig, Wolfenbütteler Str. 51",
    "Address": "Wolfenbütteler Straße 51",
    "Address__1": "",
    "City": "Braunschweig",
    "Postcode": 38124,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 531601278,
    "24 hour?": "FALSE",
    "Latitude": 52.234983,
    "Longitude": 10.52775,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.234983,10.52775",
    "IsOpen24Hours": false,
    "id": "52.234983,10.52775"
  },
  {
    "ID": 3327,
    "Tankstellennummer": "0FK81",
    "Name": "Aral Tankstelle Hasbergen, Osnabruecker Str. 36 A",
    "Address": "Osnabruecker Straße 36 A",
    "Address__1": "",
    "City": "Hasbergen",
    "Postcode": 49205,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 54053525,
    "24 hour?": "FALSE",
    "Latitude": 52.23685,
    "Longitude": 7.96284,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.23685,7.96284",
    "IsOpen24Hours": false,
    "id": "52.23685,7.96284"
  },
  {
    "ID": 949849441,
    "Tankstellennummer": 610223,
    "Name": "WESTFALEN, BISSENDORF",
    "Address": "Zum Eistruper Feld 2",
    "Address__1": "",
    "City": "Bissendorf",
    "Postcode": 49143,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05402/6414823",
    "24 hour?": "TRUE",
    "Latitude": 52.2371,
    "Longitude": 8.16274,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.2371,8.16274",
    "IsOpen24Hours": true,
    "id": "52.2371,8.16274"
  },
  {
    "ID": 1540,
    "Tankstellennummer": "0F604",
    "Name": "Aral Tankstelle Helmstedt, Marientaler Str. 52 b",
    "Address": "Marientaler Straße 52 b",
    "Address__1": "",
    "City": "Helmstedt",
    "Postcode": 38350,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 535134766,
    "24 hour?": "FALSE",
    "Latitude": 52.238707,
    "Longitude": 11.00015,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.238707,11.00015",
    "IsOpen24Hours": false,
    "id": "52.238707,11.00015"
  },
  {
    "ID": 536829104,
    "Tankstellennummer": 610228,
    "Name": "WESTFALEN, PTA WESTFALICA",
    "Address": "Kirchsiek 11",
    "Address__1": "",
    "City": "Porta Westfalica",
    "Postcode": 32457,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0571/7798899",
    "24 hour?": "FALSE",
    "Latitude": 52.2394,
    "Longitude": 8.92517,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2394,8.92517",
    "IsOpen24Hours": false,
    "id": "52.2394,8.92517"
  },
  {
    "ID": 1564,
    "Tankstellennummer": "0F605",
    "Name": "Aral Tankstelle Braunschweig, Salzdahlumer Str. 190",
    "Address": "Salzdahlumer Straße 188",
    "Address__1": "",
    "City": "Braunschweig",
    "Postcode": 38126,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 53166961,
    "24 hour?": "TRUE",
    "Latitude": 52.240507,
    "Longitude": 10.537416,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.240507,10.537416",
    "IsOpen24Hours": true,
    "id": "52.240507,10.537416"
  },
  {
    "ID": 209453184,
    "Tankstellennummer": "TD040824",
    "Name": "GENTHIN SCHOPSDORFER HEIDESTR.",
    "Address": "HEIDESTR. 5",
    "Address__1": "",
    "City": "GENTHIN OT SCHOPSDORF",
    "Postcode": 39291,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 39225395990,
    "24 hour?": "FALSE",
    "Latitude": 52.2478,
    "Longitude": 12.2491,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2478,12.2491",
    "IsOpen24Hours": false,
    "id": "52.2478,12.2491"
  },
  {
    "ID": 2202,
    "Tankstellennummer": "0FA45",
    "Name": "Aral Tankstelle Osnabrück, Meller Landstraße 66",
    "Address": "Meller Landstraße 66",
    "Address__1": "",
    "City": "Osnabrück",
    "Postcode": 49086,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 541389458,
    "24 hour?": "FALSE",
    "Latitude": 52.250728,
    "Longitude": 8.103604,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.250728,8.103604",
    "IsOpen24Hours": false,
    "id": "52.250728,8.103604"
  },
  {
    "ID": 835468158,
    "Tankstellennummer": "TD037135",
    "Name": "BRAUNSCHWEIG MUENCHENSTRASSE 1",
    "Address": "MUENCHENSTRASSE 15",
    "Address__1": "",
    "City": "BRAUNSCHWEIG",
    "Postcode": 38120,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0531-841598",
    "24 hour?": "FALSE",
    "Latitude": 52.2522,
    "Longitude": 10.4938,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2522,10.4938",
    "IsOpen24Hours": false,
    "id": "52.2522,10.4938"
  },
  {
    "ID": 1942454105,
    "Tankstellennummer": 212054,
    "Name": "Ziesar",
    "Address": "Am Seehagen",
    "Address__1": "",
    "City": "ZIESAR",
    "Postcode": 14793,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 52.252357,
    "Longitude": 12.263634,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.252357,12.263634",
    "IsOpen24Hours": false,
    "id": "52.252357,12.263634"
  },
  {
    "ID": 204190660,
    "Tankstellennummer": 212058,
    "Name": "Himmelkron",
    "Address": "Frankenring 4b",
    "Address__1": "",
    "City": "HIMMELKRON",
    "Postcode": 95502,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 52.252357,
    "Longitude": 12.263634,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.252357,12.263634",
    "IsOpen24Hours": false,
    "id": "52.252357,12.263634"
  },
  {
    "ID": 562905514,
    "Tankstellennummer": "TD028506",
    "Name": "BEELITZ BERLINER STR. 107",
    "Address": "BERLINER STR. 107",
    "Address__1": "",
    "City": "BEELITZ",
    "Postcode": 14547,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033204-35508",
    "24 hour?": "FALSE",
    "Latitude": 52.2524,
    "Longitude": 12.9861,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2524,12.9861",
    "IsOpen24Hours": false,
    "id": "52.2524,12.9861"
  },
  {
    "ID": 1011962189,
    "Tankstellennummer": "TD004390",
    "Name": "PORTA WESTFALICA PORTASTR. 44",
    "Address": "PORTASTR. 44",
    "Address__1": "",
    "City": "PORTA WESTFALICA",
    "Postcode": 32457,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0571-51142",
    "24 hour?": "FALSE",
    "Latitude": 52.2532,
    "Longitude": 8.9097,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2532,8.9097",
    "IsOpen24Hours": false,
    "id": "52.2532,8.9097"
  },
  {
    "ID": 1914764692,
    "Tankstellennummer": 610532,
    "Name": "WESTFALEN, OSNABRUECK",
    "Address": "Sutthauser Str. 166",
    "Address__1": "",
    "City": "Osnabrück",
    "Postcode": 49080,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0541/85490",
    "24 hour?": "FALSE",
    "Latitude": 52.2561,
    "Longitude": 8.04179,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2561,8.04179",
    "IsOpen24Hours": false,
    "id": "52.2561,8.04179"
  },
  {
    "ID": 547351898,
    "Tankstellennummer": "TD027235",
    "Name": "WOLMIRSTEDT COLBITZER STR.",
    "Address": "COLBITZER STR.",
    "Address__1": "",
    "City": "WOLMIRSTEDT",
    "Postcode": 39326,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039201-21376",
    "24 hour?": "FALSE",
    "Latitude": 52.2572,
    "Longitude": 11.6289,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2572,11.6289",
    "IsOpen24Hours": false,
    "id": "52.2572,11.6289"
  },
  {
    "ID": 2120197482,
    "Tankstellennummer": "TD000880",
    "Name": "IBBENBUEREN MUENSTERSTR.212",
    "Address": "MUENSTERSTR. 212 A",
    "Address__1": "",
    "City": "IBBENBUEREN",
    "Postcode": 49479,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05451-936673",
    "24 hour?": "FALSE",
    "Latitude": 52.2581,
    "Longitude": 7.7115,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2581,7.7115",
    "IsOpen24Hours": false,
    "id": "52.2581,7.7115"
  },
  {
    "ID": 2581,
    "Tankstellennummer": "0FD16",
    "Name": "Aral Tankstelle Müllrose, Frankfurter Straße",
    "Address": "Frankfurter Straße 125",
    "Address__1": "",
    "City": "Müllrose",
    "Postcode": 15299,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 336064662,
    "24 hour?": "FALSE",
    "Latitude": 52.258366,
    "Longitude": 14.426741,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.258366,14.426741",
    "IsOpen24Hours": false,
    "id": "52.258366,14.426741"
  },
  {
    "ID": 723172542,
    "Tankstellennummer": 900695,
    "Name": "WESTFALEN, WESTERKAPPELN S",
    "Address": "BAT-Brockbachtal/ Südseite",
    "Address__1": "",
    "City": "Westerkappeln",
    "Postcode": 49492,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05456/9358290",
    "24 hour?": "TRUE",
    "Latitude": 52.2606,
    "Longitude": 7.84928,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.2606,7.84928",
    "IsOpen24Hours": true,
    "id": "52.2606,7.84928"
  },
  {
    "ID": 1111420260,
    "Tankstellennummer": 900694,
    "Name": "WESTFALEN, WESTERKAPPELN N",
    "Address": "BAT-Brockbachtal/ Nordseite",
    "Address__1": "",
    "City": "Westerkappeln",
    "Postcode": 49492,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05456/9359361",
    "24 hour?": "TRUE",
    "Latitude": 52.2612,
    "Longitude": 7.8467,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.2612,7.8467",
    "IsOpen24Hours": true,
    "id": "52.2612,7.8467"
  },
  {
    "ID": 1915998390,
    "Tankstellennummer": "TD041368",
    "Name": "HOHENHAMELN MEIERKAMP 1",
    "Address": "MEIERKAMP 1",
    "Address__1": "",
    "City": "HOHENHAMELN",
    "Postcode": 31249,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05128-9489910",
    "24 hour?": "FALSE",
    "Latitude": 52.2626,
    "Longitude": 10.0751,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2626,10.0751",
    "IsOpen24Hours": false,
    "id": "52.2626,10.0751"
  },
  {
    "ID": 1644211898,
    "Tankstellennummer": 610702,
    "Name": "WESTFALEN, BUEKEBURG",
    "Address": "Petzer Str. 6c",
    "Address__1": "",
    "City": "Bückeburg",
    "Postcode": 31675,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05722/5262",
    "24 hour?": "FALSE",
    "Latitude": 52.2628,
    "Longitude": 9.03638,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2628,9.03638",
    "IsOpen24Hours": false,
    "id": "52.2628,9.03638"
  },
  {
    "ID": 1530,
    "Tankstellennummer": "0F578",
    "Name": "Aral Tankstelle Ilsede, Gerhardstr.54",
    "Address": "Gerhardstraße 54",
    "Address__1": "",
    "City": "Ilsede",
    "Postcode": 31241,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51728760,
    "24 hour?": "FALSE",
    "Latitude": 52.26319,
    "Longitude": 10.220637,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.26319,10.220637",
    "IsOpen24Hours": false,
    "id": "52.26319,10.220637"
  },
  {
    "ID": 2582,
    "Tankstellennummer": "0FD17",
    "Name": "Aral Tankstelle Storkow, Kummersdorfer Str. 13 d",
    "Address": "Kummersdorfer Straße 13 d",
    "Address__1": "",
    "City": "Storkow",
    "Postcode": 15859,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3367867991,
    "24 hour?": "TRUE",
    "Latitude": 52.263618,
    "Longitude": 13.935584,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.263618,13.935584",
    "IsOpen24Hours": true,
    "id": "52.263618,13.935584"
  },
  {
    "ID": 1566,
    "Tankstellennummer": "0F607",
    "Name": "Aral Tankstelle Braunschweig, Sackring 8",
    "Address": "Sackring 8",
    "Address__1": "",
    "City": "Braunschweig",
    "Postcode": 38118,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 531577440,
    "24 hour?": "FALSE",
    "Latitude": 52.263996,
    "Longitude": 10.500852,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.263996,10.500852",
    "IsOpen24Hours": false,
    "id": "52.263996,10.500852"
  },
  {
    "ID": 1013450121,
    "Tankstellennummer": 610109,
    "Name": "WESTFALEN, OSNABRUECK",
    "Address": "Hannoversche Str. 20",
    "Address__1": "",
    "City": "Osnabrück",
    "Postcode": 49084,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0541/572-934",
    "24 hour?": "TRUE",
    "Latitude": 52.2641,
    "Longitude": 8.06568,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.2641,8.06568",
    "IsOpen24Hours": true,
    "id": "52.2641,8.06568"
  },
  {
    "ID": 1468424250,
    "Tankstellennummer": 5064,
    "Name": "Agip Burg",
    "Address": "Magdeburger Chaussee 83",
    "Address__1": "",
    "City": "Burg",
    "Postcode": 39288,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 39219448",
    "24 hour?": "FALSE",
    "Latitude": 52.26464,
    "Longitude": 11.84645,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.26464,11.84645",
    "IsOpen24Hours": false,
    "id": "52.26464,11.84645"
  },
  {
    "ID": 1919786721,
    "Tankstellennummer": "TD040444",
    "Name": "HASBERGEN HAUPTSTR. 107",
    "Address": "HAUPTSTR. 107",
    "Address__1": "",
    "City": "HASBERGEN",
    "Postcode": 49205,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05405-8081564",
    "24 hour?": "FALSE",
    "Latitude": 52.2657,
    "Longitude": 7.9643,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2657,7.9643",
    "IsOpen24Hours": false,
    "id": "52.2657,7.9643"
  },
  {
    "ID": 1830008224,
    "Tankstellennummer": 610644,
    "Name": "WESTFALEN, IBBENBUEREN",
    "Address": "Mettinger Str. 33",
    "Address__1": "",
    "City": "Ibbenbüren",
    "Postcode": 49479,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05451/88864",
    "24 hour?": "FALSE",
    "Latitude": 52.2665,
    "Longitude": 7.78847,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2665,7.78847",
    "IsOpen24Hours": false,
    "id": "52.2665,7.78847"
  },
  {
    "ID": 1311588238,
    "Tankstellennummer": "TD004549",
    "Name": "IBBENBUEREN MUENSTERSTR. 51",
    "Address": "MUENSTERSTR. 51",
    "Address__1": "",
    "City": "IBBENBUEREN",
    "Postcode": 49477,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05451-894755",
    "24 hour?": "FALSE",
    "Latitude": 52.2691,
    "Longitude": 7.7175,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2691,7.7175",
    "IsOpen24Hours": false,
    "id": "52.2691,7.7175"
  },
  {
    "ID": 1815536083,
    "Tankstellennummer": 610230,
    "Name": "WESTFALEN, HUELLHORST",
    "Address": "Niedringhausener Str. 130",
    "Address__1": "",
    "City": "Hüllhorst",
    "Postcode": 32609,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "TRUE",
    "Latitude": 52.2693,
    "Longitude": 8.63558,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.2693,8.63558",
    "IsOpen24Hours": true,
    "id": "52.2693,8.63558"
  },
  {
    "ID": 307178097,
    "Tankstellennummer": "0FM67",
    "Name": "OSNABRUCK",
    "Address": "Kurt-Schumacher-Damm 12",
    "Address__1": "",
    "City": "Osnabrück",
    "Postcode": 49078,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5414095309,
    "24 hour?": "FALSE",
    "Latitude": 52.270623,
    "Longitude": 8.024833,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.270623,8.024833",
    "IsOpen24Hours": false,
    "id": "52.270623,8.024833"
  },
  {
    "ID": 307622986,
    "Tankstellennummer": "TD028357",
    "Name": "MITTENWALDE BERLINER CH.",
    "Address": "BERLINER CH.",
    "Address__1": "",
    "City": "MITTENWALDE",
    "Postcode": 15749,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033764-20389",
    "24 hour?": "FALSE",
    "Latitude": 52.2712,
    "Longitude": 13.5377,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2712,13.5377",
    "IsOpen24Hours": false,
    "id": "52.2712,13.5377"
  },
  {
    "ID": 1023941707,
    "Tankstellennummer": 610014,
    "Name": "WESTFALEN, HOERSTEL",
    "Address": "Riesenbecker Str. 27",
    "Address__1": "",
    "City": "Hörstel",
    "Postcode": 48477,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05459/1850",
    "24 hour?": "FALSE",
    "Latitude": 52.2715,
    "Longitude": 7.58977,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2715,7.58977",
    "IsOpen24Hours": false,
    "id": "52.2715,7.58977"
  },
  {
    "ID": 1555,
    "Tankstellennummer": "0F595",
    "Name": "Aral Tankstelle Braunschweig, Hildesheimer Str. 32",
    "Address": "Hildesheimer Straße 32",
    "Address__1": "",
    "City": "Braunschweig",
    "Postcode": 38114,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 53152826,
    "24 hour?": "TRUE",
    "Latitude": 52.271882,
    "Longitude": 10.496279,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.271882,10.496279",
    "IsOpen24Hours": true,
    "id": "52.271882,10.496279"
  },
  {
    "ID": 936316242,
    "Tankstellennummer": "TD027367",
    "Name": "BURG BERLINER CH. 103",
    "Address": "BERLINER CH. 103",
    "Address__1": "",
    "City": "BURG",
    "Postcode": 39288,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03921-944029",
    "24 hour?": "FALSE",
    "Latitude": 52.2734,
    "Longitude": 11.8722,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2734,11.8722",
    "IsOpen24Hours": false,
    "id": "52.2734,11.8722"
  },
  {
    "ID": 1211101107,
    "Tankstellennummer": 610612,
    "Name": "WESTFALEN, OSNABRUECK",
    "Address": "Rheiner Landstr. 69",
    "Address__1": "",
    "City": "Osnabrück",
    "Postcode": 49078,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0541/431375",
    "24 hour?": "FALSE",
    "Latitude": 52.2744,
    "Longitude": 8.01294,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2744,8.01294",
    "IsOpen24Hours": false,
    "id": "52.2744,8.01294"
  },
  {
    "ID": 1032993441,
    "Tankstellennummer": "TD111430",
    "Name": "IBBENBUEREN GRAVENHORSTER STR.",
    "Address": "GRAVENHORSTER STR.21",
    "Address__1": "",
    "City": "IBBENBUEREN",
    "Postcode": 49477,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05451-894751",
    "24 hour?": "FALSE",
    "Latitude": 52.2745,
    "Longitude": 7.7049,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2745,7.7049",
    "IsOpen24Hours": false,
    "id": "52.2745,7.7049"
  },
  {
    "ID": 1366274101,
    "Tankstellennummer": 610609,
    "Name": "WESTFALEN, MINDEN",
    "Address": "Lübbecker Str. 55",
    "Address__1": "",
    "City": "Minden",
    "Postcode": 32429,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0571/51250",
    "24 hour?": "FALSE",
    "Latitude": 52.2745,
    "Longitude": 8.89219,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2745,8.89219",
    "IsOpen24Hours": false,
    "id": "52.2745,8.89219"
  },
  {
    "ID": 2587,
    "Tankstellennummer": "0FD20",
    "Name": "Aral Tankstelle Haldensleben, Alvensleber Landstraße 5",
    "Address": "Alvensleber Landstraße 5",
    "Address__1": "",
    "City": "Haldensleben",
    "Postcode": 39340,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 390443232,
    "24 hour?": "TRUE",
    "Latitude": 52.276306,
    "Longitude": 11.389788,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.276306,11.389788",
    "IsOpen24Hours": true,
    "id": "52.276306,11.389788"
  },
  {
    "ID": 2271,
    "Tankstellennummer": "0FB02",
    "Name": "Aral Tankstelle Osnabrück, Alte Poststr. 2",
    "Address": "Alte Poststraße 2",
    "Address__1": "",
    "City": "Osnabrück",
    "Postcode": 49074,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 54121730,
    "24 hour?": "TRUE",
    "Latitude": 52.276945,
    "Longitude": 8.055264,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.276945,8.055264",
    "IsOpen24Hours": true,
    "id": "52.276945,8.055264"
  },
  {
    "ID": 1006781438,
    "Tankstellennummer": 5006,
    "Name": "Agip Haldensleben",
    "Address": "Dammmuehlenweg",
    "Address__1": "",
    "City": "Haldensleben",
    "Postcode": 39340,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 39044102",
    "24 hour?": "TRUE",
    "Latitude": 52.27768883,
    "Longitude": 11.44230902,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.27768883,11.44230902",
    "IsOpen24Hours": true,
    "id": "52.27768883,11.44230902"
  },
  {
    "ID": 797966334,
    "Tankstellennummer": "0FM91",
    "Name": "RHEINE",
    "Address": "Neuenkirchener Straße  44",
    "Address__1": "",
    "City": "Rheine",
    "Postcode": 48431,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 59713626,
    "24 hour?": "FALSE",
    "Latitude": 52.279282,
    "Longitude": 7.42933,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.279282,7.42933",
    "IsOpen24Hours": false,
    "id": "52.279282,7.42933"
  },
  {
    "ID": 677355918,
    "Tankstellennummer": "TD037127",
    "Name": "BRAUNSCHWEIG CELLER STRASSE 65",
    "Address": "CELLER STRASSE 65",
    "Address__1": "",
    "City": "BRAUNSCHWEIG",
    "Postcode": 38114,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 53121931707,
    "24 hour?": "FALSE",
    "Latitude": 52.2796,
    "Longitude": 10.5054,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2796,10.5054",
    "IsOpen24Hours": false,
    "id": "52.2796,10.5054"
  },
  {
    "ID": 240841045,
    "Tankstellennummer": "TD037119",
    "Name": "BRAUNSCHWEIG BERLINER STR. 54",
    "Address": "BERLINER STR. 54 A",
    "Address__1": "",
    "City": "BRAUNSCHWEIG",
    "Postcode": 38104,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0531-374141",
    "24 hour?": "FALSE",
    "Latitude": 52.2805,
    "Longitude": 10.5737,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2805,10.5737",
    "IsOpen24Hours": false,
    "id": "52.2805,10.5737"
  },
  {
    "ID": 662510860,
    "Tankstellennummer": "TD027078",
    "Name": "HALDENSLEBEN GERICKESTR. 108",
    "Address": "GERICKESTR. 108",
    "Address__1": "",
    "City": "HALDENSLEBEN",
    "Postcode": 39340,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03904-65040",
    "24 hour?": "FALSE",
    "Latitude": 52.2805,
    "Longitude": 11.4314,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2805,11.4314",
    "IsOpen24Hours": false,
    "id": "52.2805,11.4314"
  },
  {
    "ID": 1597,
    "Tankstellennummer": "0F632",
    "Name": "Aral Tankstelle Braunschweig, Bültenweg 40",
    "Address": "Bültenweg 40",
    "Address__1": "",
    "City": "Braunschweig",
    "Postcode": 38106,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 531347887,
    "24 hour?": "FALSE",
    "Latitude": 52.281498,
    "Longitude": 10.536907,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.281498,10.536907",
    "IsOpen24Hours": false,
    "id": "52.281498,10.536907"
  },
  {
    "ID": 641552740,
    "Tankstellennummer": 610072,
    "Name": "WESTFALEN, IBBENBUEREN",
    "Address": "Püsselbürener Damm 16",
    "Address__1": "",
    "City": "Ibbenbüren",
    "Postcode": 49477,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05451/16606",
    "24 hour?": "FALSE",
    "Latitude": 52.2817,
    "Longitude": 7.70657,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2817,7.70657",
    "IsOpen24Hours": false,
    "id": "52.2817,7.70657"
  },
  {
    "ID": 1105,
    "Tankstellennummer": "0F265",
    "Name": "Aral Tankstelle Minden, Ringstraße 26",
    "Address": "Ringstraße 26",
    "Address__1": "",
    "City": "Minden",
    "Postcode": 32427,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 57121415,
    "24 hour?": "TRUE",
    "Latitude": 52.283131,
    "Longitude": 8.902097,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.283131,8.902097",
    "IsOpen24Hours": true,
    "id": "52.283131,8.902097"
  },
  {
    "ID": 2593,
    "Tankstellennummer": "0FD23",
    "Name": "Aral Tankstelle Königs Wusterhausen, Luckenwalder Straße 111",
    "Address": "Luckenwalder Strasse 111",
    "Address__1": "",
    "City": "Königs Wusterhausen",
    "Postcode": 15711,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3375242010,
    "24 hour?": "TRUE",
    "Latitude": 52.28382,
    "Longitude": 13.614533,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.28382,13.614533",
    "IsOpen24Hours": true,
    "id": "52.28382,13.614533"
  },
  {
    "ID": 1608,
    "Tankstellennummer": "0F641",
    "Name": "Aral Tankstelle Obernkirchen, Hauptstr. 1 - B 65",
    "Address": "B65 / Hauptstraße 1",
    "Address__1": "",
    "City": "Obernkirchen",
    "Postcode": 31683,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5724902984,
    "24 hour?": "FALSE",
    "Latitude": 52.284253,
    "Longitude": 9.134329,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.284253,9.134329",
    "IsOpen24Hours": false,
    "id": "52.284253,9.134329"
  },
  {
    "ID": 1302479800,
    "Tankstellennummer": "TD028449",
    "Name": "ZEESEN KARL-LIEBKNECHT-STR. 15",
    "Address": "KARL-LIEBKNECHT-STR. 155",
    "Address__1": "",
    "City": "ZEESEN",
    "Postcode": 15711,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03375-900332",
    "24 hour?": "FALSE",
    "Latitude": 52.2844,
    "Longitude": 13.6303,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2844,13.6303",
    "IsOpen24Hours": false,
    "id": "52.2844,13.6303"
  },
  {
    "ID": 1904332150,
    "Tankstellennummer": "0FG68",
    "Name": "Aral TankStelle Braunschweig, Hamburger Straße 65",
    "Address": "Hamburger Straße 65",
    "Address__1": "",
    "City": "Braunschweig",
    "Postcode": 38114,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5312396801,
    "24 hour?": "TRUE",
    "Latitude": 52.28537,
    "Longitude": 10.51855,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.28537,10.51855",
    "IsOpen24Hours": true,
    "id": "52.28537,10.51855"
  },
  {
    "ID": 118322602,
    "Tankstellennummer": 610592,
    "Name": "WESTFALEN, OSNABRUECK",
    "Address": "Hansastr. 45a",
    "Address__1": "",
    "City": "Osnabrück",
    "Postcode": 49090,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0541/64386",
    "24 hour?": "FALSE",
    "Latitude": 52.2879,
    "Longitude": 8.0381,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2879,8.0381",
    "IsOpen24Hours": false,
    "id": "52.2879,8.0381"
  },
  {
    "ID": 24669231,
    "Tankstellennummer": 610648,
    "Name": "WESTFALEN, RHEINE",
    "Address": "Lingener Damm 26",
    "Address__1": "",
    "City": "Rheine",
    "Postcode": 48429,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05971/8070962",
    "24 hour?": "FALSE",
    "Latitude": 52.2888,
    "Longitude": 7.44091,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2888,7.44091",
    "IsOpen24Hours": false,
    "id": "52.2888,7.44091"
  },
  {
    "ID": 2730,
    "Tankstellennummer": "0FE42",
    "Name": "Aral Autohof Wollin b.Brandenburg, Im Gewerbegebiet 1",
    "Address": "Im Gewerbegebiet 1",
    "Address__1": "",
    "City": "Wollin b.Brandenburg",
    "Postcode": 14778,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3383371843,
    "24 hour?": "TRUE",
    "Latitude": 52.289088,
    "Longitude": 12.464309,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.289088,12.464309",
    "IsOpen24Hours": true,
    "id": "52.289088,12.464309"
  },
  {
    "ID": 1538,
    "Tankstellennummer": "0F602",
    "Name": "Aral Tankstelle Braunschweig, Bevenroder Str. 24",
    "Address": "Bevenroder Straße 24",
    "Address__1": "",
    "City": "Braunschweig",
    "Postcode": 38108,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 531371472,
    "24 hour?": "FALSE",
    "Latitude": 52.28966,
    "Longitude": 10.561611,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.28966,10.561611",
    "IsOpen24Hours": false,
    "id": "52.28966,10.561611"
  },
  {
    "ID": 1122,
    "Tankstellennummer": "0F258",
    "Name": "Aral Tankstelle Osnabrück, Pagenstecherstr. 58-60",
    "Address": "Pagenstecherstraße 58-60",
    "Address__1": "",
    "City": "Osnabrück",
    "Postcode": 49090,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 54161931,
    "24 hour?": "TRUE",
    "Latitude": 52.289738,
    "Longitude": 8.027446,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.289738,8.027446",
    "IsOpen24Hours": true,
    "id": "52.289738,8.027446"
  },
  {
    "ID": 2088787328,
    "Tankstellennummer": 610708,
    "Name": "WESTFALEN, OSNABRUECK",
    "Address": "Natruper Str. 166",
    "Address__1": "",
    "City": "Osnabrück",
    "Postcode": 49090,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0541/9619623",
    "24 hour?": "FALSE",
    "Latitude": 52.2905,
    "Longitude": 8.02025,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2905,8.02025",
    "IsOpen24Hours": false,
    "id": "52.2905,8.02025"
  },
  {
    "ID": 1624,
    "Tankstellennummer": "0FE73",
    "Name": "Aral Tankstelle Laatzen, Lüneburger Str. 14",
    "Address": "Lüneburger Straße 14a",
    "Address__1": "",
    "City": "Laatzen",
    "Postcode": 30880,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5102916693,
    "24 hour?": "TRUE",
    "Latitude": 52.29413,
    "Longitude": 9.825485,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.29413,9.825485",
    "IsOpen24Hours": true,
    "id": "52.29413,9.825485"
  },
  {
    "ID": 522774106,
    "Tankstellennummer": "TD004648",
    "Name": "OSNABRUECK WERSENER STR. 70",
    "Address": "WERSENER STR. 70",
    "Address__1": "",
    "City": "OSNABRUECK",
    "Postcode": 49090,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0541-123532",
    "24 hour?": "FALSE",
    "Latitude": 52.2967,
    "Longitude": 8.0005,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.2967,8.0005",
    "IsOpen24Hours": false,
    "id": "52.2967,8.0005"
  },
  {
    "ID": 2236,
    "Tankstellennummer": "0FA73",
    "Name": "Aral Tankstelle Osnabrück, Bramscher Str. 267",
    "Address": "Bramscher Straße 267",
    "Address__1": "",
    "City": "Osnabrück",
    "Postcode": 49090,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 54163204,
    "24 hour?": "FALSE",
    "Latitude": 52.301098,
    "Longitude": 8.034664,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.301098,8.034664",
    "IsOpen24Hours": false,
    "id": "52.301098,8.034664"
  },
  {
    "ID": 2844,
    "Tankstellennummer": "0FH22",
    "Name": "Aral Tankstelle Lübbecke, Osnabruecker Str. 82",
    "Address": "Osnabrücker Straße 82",
    "Address__1": "",
    "City": "Lübbecke",
    "Postcode": 32312,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 57418470,
    "24 hour?": "FALSE",
    "Latitude": 52.302068,
    "Longitude": 8.59658,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.302068,8.59658",
    "IsOpen24Hours": false,
    "id": "52.302068,8.59658"
  },
  {
    "ID": 676380846,
    "Tankstellennummer": "TD041053",
    "Name": "MICHENDORF BAB SUED A10",
    "Address": "BAB SUED / A10",
    "Address__1": "",
    "City": "MICHENDORF",
    "Postcode": 14552,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033205-46699",
    "24 hour?": "FALSE",
    "Latitude": 52.3026,
    "Longitude": 13.0148,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3026,13.0148",
    "IsOpen24Hours": false,
    "id": "52.3026,13.0148"
  },
  {
    "ID": 1093927862,
    "Tankstellennummer": "TD039107",
    "Name": "SCHWIELOWSEE /OT FERCH",
    "Address": "5 AM BAHNHOF LIENEWITZ",
    "Address__1": "",
    "City": "SCHWIELOWSEE / OT FERCH",
    "Postcode": 14548,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 33205757972,
    "24 hour?": "FALSE",
    "Latitude": 52.3036,
    "Longitude": 12.9706,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3036,12.9706",
    "IsOpen24Hours": false,
    "id": "52.3036,12.9706"
  },
  {
    "ID": 857775201,
    "Tankstellennummer": 610610,
    "Name": "WESTFALEN, MINDEN",
    "Address": "Stiftsallee 36",
    "Address__1": "",
    "City": "Minden",
    "Postcode": 32425,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0571/45404",
    "24 hour?": "FALSE",
    "Latitude": 52.3039,
    "Longitude": 8.90441,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3039,8.90441",
    "IsOpen24Hours": false,
    "id": "52.3039,8.90441"
  },
  {
    "ID": 1602191681,
    "Tankstellennummer": 610625,
    "Name": "WESTFALEN, BAD BENTHEIM",
    "Address": "Rheiner Str. 5",
    "Address__1": "",
    "City": "Bad Bentheim",
    "Postcode": 48455,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05922/2787",
    "24 hour?": "TRUE",
    "Latitude": 52.3047,
    "Longitude": 7.15061,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.3047,7.15061",
    "IsOpen24Hours": true,
    "id": "52.3047,7.15061"
  },
  {
    "ID": 2932336,
    "Tankstellennummer": 610589,
    "Name": "WESTFALEN, BARSINGHAUSEN",
    "Address": "Wilhelm-Hess-Str. 25",
    "Address__1": "",
    "City": "Barsinghausen",
    "Postcode": 30890,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05105/4934",
    "24 hour?": "FALSE",
    "Latitude": 52.3074,
    "Longitude": 9.45226,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3074,9.45226",
    "IsOpen24Hours": false,
    "id": "52.3074,9.45226"
  },
  {
    "ID": 1549,
    "Tankstellennummer": "0F591",
    "Name": "Aral Tankstelle Gehrden, 16A Lemmieer Str.",
    "Address": "Lemmier Straße 16 A",
    "Address__1": "",
    "City": "Gehrden",
    "Postcode": 30989,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51084884,
    "24 hour?": "FALSE",
    "Latitude": 52.30757,
    "Longitude": 9.607053,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.30757,9.607053",
    "IsOpen24Hours": false,
    "id": "52.30757,9.607053"
  },
  {
    "ID": 135390228,
    "Tankstellennummer": "TD006734",
    "Name": "PREUSSICH-OLDENDORF OSNABRUECK",
    "Address": "OSNABRUECKER STR. 1",
    "Address__1": "",
    "City": "PREUSSICH-OLDENDORF",
    "Postcode": 32361,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05742-700678",
    "24 hour?": "FALSE",
    "Latitude": 52.3076,
    "Longitude": 8.4871,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3076,8.4871",
    "IsOpen24Hours": false,
    "id": "52.3076,8.4871"
  },
  {
    "ID": 1935748515,
    "Tankstellennummer": 610833,
    "Name": "WESTFALEN, HOERSTEL",
    "Address": "Nobelstraße",
    "Address__1": "",
    "City": "HOERSTEL",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 52.30908,
    "Longitude": 7.61614,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.30908,7.61614",
    "IsOpen24Hours": false,
    "id": "52.30908,7.61614"
  },
  {
    "ID": 1623,
    "Tankstellennummer": "0FE85",
    "Name": "Aral Autohof Königslutter, Am Mühlenhop 1",
    "Address": "Am Mühlenhop  1",
    "Address__1": "",
    "City": "Königslutter",
    "Postcode": 38154,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5365941703,
    "24 hour?": "TRUE",
    "Latitude": 52.311557,
    "Longitude": 10.823885,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.311557,10.823885",
    "IsOpen24Hours": true,
    "id": "52.311557,10.823885"
  },
  {
    "ID": 1043522637,
    "Tankstellennummer": "TD028431",
    "Name": "LUDWIGSFELDE BRANDENBURGISCHE",
    "Address": "BRANDENBURGISCHE STR. 3",
    "Address__1": "",
    "City": "LUDWIGSFELDE",
    "Postcode": 14974,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03378-870107",
    "24 hour?": "FALSE",
    "Latitude": 52.3118,
    "Longitude": 13.2434,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3118,13.2434",
    "IsOpen24Hours": false,
    "id": "52.3118,13.2434"
  },
  {
    "ID": 842177906,
    "Tankstellennummer": 610180,
    "Name": "WESTFALEN, WESTERKAPPELN",
    "Address": "Osnabrücker Str. 18",
    "Address__1": "",
    "City": "Westerkappeln",
    "Postcode": 49492,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05404/4388",
    "24 hour?": "FALSE",
    "Latitude": 52.313,
    "Longitude": 7.88206,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.313,7.88206",
    "IsOpen24Hours": false,
    "id": "52.313,7.88206"
  },
  {
    "ID": 826084389,
    "Tankstellennummer": "TD007880",
    "Name": "WESTERKAPPELN AM DOELHOF 9",
    "Address": "AM DOELHOF 9",
    "Address__1": "",
    "City": "WESTERKAPPELN",
    "Postcode": 49492,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05404-4337",
    "24 hour?": "FALSE",
    "Latitude": 52.3134,
    "Longitude": 7.8744,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3134,7.8744",
    "IsOpen24Hours": false,
    "id": "52.3134,7.8744"
  },
  {
    "ID": 2731,
    "Tankstellennummer": "0FG28",
    "Name": "Aral Autohof Niederlehme, Robert-Guthmann-Straße 1",
    "Address": "Robert-Guthmann-Straße 1",
    "Address__1": "",
    "City": "Königs Wusterhausen",
    "Postcode": 15713,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3375507127,
    "24 hour?": "TRUE",
    "Latitude": 52.314496,
    "Longitude": 13.662701,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.314496,13.662701",
    "IsOpen24Hours": true,
    "id": "52.314496,13.662701"
  },
  {
    "ID": 1178,
    "Tankstellennummer": "0F300",
    "Name": "Aral Tankstelle Salzbergen, Dieselstr. 2",
    "Address": "Dieselstraße 2",
    "Address__1": "",
    "City": "Salzbergen",
    "Postcode": 48499,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5976940750,
    "24 hour?": "FALSE",
    "Latitude": 52.31478,
    "Longitude": 7.353656,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.31478,7.353656",
    "IsOpen24Hours": false,
    "id": "52.31478,7.353656"
  },
  {
    "ID": 3397,
    "Tankstellennummer": "0FL37",
    "Name": "Aral Autobahntankstelle Brusendorf, Am Fichtenplan Süd (A 10)",
    "Address": "BAB A10 Suedseite",
    "Address__1": "",
    "City": "Brusendorf",
    "Postcode": 15749,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 33764726022,
    "24 hour?": "TRUE",
    "Latitude": 52.315623,
    "Longitude": 13.493713,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.315623,13.493713",
    "IsOpen24Hours": true,
    "id": "52.315623,13.493713"
  },
  {
    "ID": 1516,
    "Tankstellennummer": "0F566",
    "Name": "Aral Tankstelle Sehnde, Iltener Strasse 8",
    "Address": "Iltener Straße 8",
    "Address__1": "",
    "City": "Sehnde",
    "Postcode": 31319,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51384746,
    "24 hour?": "TRUE",
    "Latitude": 52.317362,
    "Longitude": 9.959905,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.317362,9.959905",
    "IsOpen24Hours": true,
    "id": "52.317362,9.959905"
  },
  {
    "ID": 760193052,
    "Tankstellennummer": "TD041350",
    "Name": "SEHNDE LEHRTER STR. 20",
    "Address": "LEHRTER STR. 20",
    "Address__1": "",
    "City": "SEHNDE",
    "Postcode": 31319,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05138-7099202",
    "24 hour?": "FALSE",
    "Latitude": 52.3178,
    "Longitude": 9.9661,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3178,9.9661",
    "IsOpen24Hours": false,
    "id": "52.3178,9.9661"
  },
  {
    "ID": 3396,
    "Tankstellennummer": "0FL36",
    "Name": "Aral Autobahntankstelle Brusendorf, Am Fichtenplan Nord (A 10)",
    "Address": "BAB A10 Nordseite",
    "Address__1": "",
    "City": "Brusendorf",
    "Postcode": 15749,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 33764268105,
    "24 hour?": "TRUE",
    "Latitude": 52.318454,
    "Longitude": 13.498319,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.318454,13.498319",
    "IsOpen24Hours": true,
    "id": "52.318454,13.498319"
  },
  {
    "ID": 1382156628,
    "Tankstellennummer": "TD000618",
    "Name": "KLOSTER LEHNIN KURFUERSTENSTR.",
    "Address": "KURFUERSTENSTR. 5",
    "Address__1": "",
    "City": "KLOSTER LEHNIN",
    "Postcode": 14797,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03382-704261",
    "24 hour?": "FALSE",
    "Latitude": 52.3202,
    "Longitude": 12.7461,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3202,12.7461",
    "IsOpen24Hours": false,
    "id": "52.3202,12.7461"
  },
  {
    "ID": 421278731,
    "Tankstellennummer": "0FV17",
    "Name": "BAD BENTHEIM-GILDEHAUS",
    "Address": "Athener Strasse 1",
    "Address__1": "",
    "City": "Bad Bentheim-Gildehaus",
    "Postcode": 48455,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 59248087766,
    "24 hour?": "TRUE",
    "Latitude": 52.32028,
    "Longitude": 7.06769,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.32028,7.06769",
    "IsOpen24Hours": true,
    "id": "52.32028,7.06769"
  },
  {
    "ID": 1621,
    "Tankstellennummer": "0FG23",
    "Name": "Aral Autohof Braunschweig, Hansestraße 47 A",
    "Address": "Hansestraße 47 A",
    "Address__1": "",
    "City": "Braunschweig",
    "Postcode": 38112,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5313102198,
    "24 hour?": "TRUE",
    "Latitude": 52.322011,
    "Longitude": 10.478815,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.322011,10.478815",
    "IsOpen24Hours": true,
    "id": "52.322011,10.478815"
  },
  {
    "ID": 1531,
    "Tankstellennummer": "0F579",
    "Name": "Aral Tankstelle Hemmingen, Gutenbergstrasse 1",
    "Address": "Gutenbergstraße 1",
    "Address__1": "",
    "City": "Hemmingen",
    "Postcode": 30966,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 511423980,
    "24 hour?": "TRUE",
    "Latitude": 52.32275,
    "Longitude": 9.724928,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.32275,9.724928",
    "IsOpen24Hours": true,
    "id": "52.32275,9.724928"
  },
  {
    "ID": 1546,
    "Tankstellennummer": "0F588",
    "Name": "Aral Tankstelle Hannover, Messeschnellweg Ost",
    "Address": "Messeschnellweg Ost",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30539,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 511861335,
    "24 hour?": "TRUE",
    "Latitude": 52.32282,
    "Longitude": 9.814134,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.32282,9.814134",
    "IsOpen24Hours": true,
    "id": "52.32282,9.814134"
  },
  {
    "ID": 586330037,
    "Tankstellennummer": 610590,
    "Name": "WESTFALEN, GEHRDEN",
    "Address": "Gehrdener Str. 5",
    "Address__1": "",
    "City": "Gehrden",
    "Postcode": 30989,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05108/1423",
    "24 hour?": "FALSE",
    "Latitude": 52.3229,
    "Longitude": 9.55289,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3229,9.55289",
    "IsOpen24Hours": false,
    "id": "52.3229,9.55289"
  },
  {
    "ID": 488854661,
    "Tankstellennummer": 610593,
    "Name": "WESTFALEN, HANNOVER",
    "Address": "Hildesheimer Str. 407",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30519,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0511/862329",
    "24 hour?": "TRUE",
    "Latitude": 52.3261,
    "Longitude": 9.78182,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.3261,9.78182",
    "IsOpen24Hours": true,
    "id": "52.3261,9.78182"
  },
  {
    "ID": 516139942,
    "Tankstellennummer": "0FR80",
    "Name": "SALZBERGEN",
    "Address": "Holsterfeld 2",
    "Address__1": "",
    "City": "Salzbergen",
    "Postcode": 48499,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 597197260,
    "24 hour?": "TRUE",
    "Latitude": 52.32672,
    "Longitude": 7.42944,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.32672,7.42944",
    "IsOpen24Hours": true,
    "id": "52.32672,7.42944"
  },
  {
    "ID": 1567,
    "Tankstellennummer": "0F608",
    "Name": "Aral Tankstelle Lehre, Berliner Strasse 48",
    "Address": "Berliner Straße 48",
    "Address__1": "",
    "City": "Lehre",
    "Postcode": 38165,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 53081588,
    "24 hour?": "FALSE",
    "Latitude": 52.326917,
    "Longitude": 10.666069,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.326917,10.666069",
    "IsOpen24Hours": false,
    "id": "52.326917,10.666069"
  },
  {
    "ID": 1515,
    "Tankstellennummer": "0F565",
    "Name": "Aral Tankstelle Stadthagen, Vornhäger Strasse 35",
    "Address": "Vornhäger Straße 35",
    "Address__1": "",
    "City": "Stadthagen",
    "Postcode": 31655,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 57213360,
    "24 hour?": "TRUE",
    "Latitude": 52.327977,
    "Longitude": 9.21737,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.327977,9.21737",
    "IsOpen24Hours": true,
    "id": "52.327977,9.21737"
  },
  {
    "ID": 1513,
    "Tankstellennummer": "0F563",
    "Name": "Aral Tankstelle Hannover, Messeschnellweg-West",
    "Address": "Messeschnellweg-West",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30521,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 511871830,
    "24 hour?": "TRUE",
    "Latitude": 52.329613,
    "Longitude": 9.812187,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.329613,9.812187",
    "IsOpen24Hours": true,
    "id": "52.329613,9.812187"
  },
  {
    "ID": 1098579130,
    "Tankstellennummer": "TD028050",
    "Name": "BLANKENFELDE ZOSSENER DAMM 19",
    "Address": "ZOSSENER DAMM 19",
    "Address__1": "",
    "City": "BLANKENFELDE",
    "Postcode": 15827,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03379-379940",
    "24 hour?": "FALSE",
    "Latitude": 52.3301,
    "Longitude": 13.4041,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3301,13.4041",
    "IsOpen24Hours": false,
    "id": "52.3301,13.4041"
  },
  {
    "ID": 1511,
    "Tankstellennummer": "0F561",
    "Name": "Aral Tankstelle Peine, Schwarzer Weg 39",
    "Address": "Schwarzer Weg 39",
    "Address__1": "",
    "City": "Peine",
    "Postcode": 31224,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51716108,
    "24 hour?": "TRUE",
    "Latitude": 52.330412,
    "Longitude": 10.234947,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.330412,10.234947",
    "IsOpen24Hours": true,
    "id": "52.330412,10.234947"
  },
  {
    "ID": 2853,
    "Tankstellennummer": "0FH30",
    "Name": "Aral Tankstelle Bad Essen, Osnabruecker Str. 304",
    "Address": "Osnabruecker Straße 304",
    "Address__1": "",
    "City": "Bad Essen",
    "Postcode": 49152,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 54722150,
    "24 hour?": "FALSE",
    "Latitude": 52.332365,
    "Longitude": 8.314482,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.332365,8.314482",
    "IsOpen24Hours": false,
    "id": "52.332365,8.314482"
  },
  {
    "ID": 1094631344,
    "Tankstellennummer": "TD007211",
    "Name": "BARSINGHAUSEN AUTOHOF KRONSKAM",
    "Address": "AUTOHOF KRONSKAMP 3",
    "Address__1": "",
    "City": "BARSINGHAUSEN",
    "Postcode": 30890,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05105-514923",
    "24 hour?": "FALSE",
    "Latitude": 52.3332,
    "Longitude": 9.4146,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3332,9.4146",
    "IsOpen24Hours": false,
    "id": "52.3332,9.4146"
  },
  {
    "ID": 228743769,
    "Tankstellennummer": "TD039016",
    "Name": "HANNOVER BAT WUELFERODE OST",
    "Address": "WUELFERODE OST",
    "Address__1": "",
    "City": "HANNOVER",
    "Postcode": 30539,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0511-9545544",
    "24 hour?": "FALSE",
    "Latitude": 52.3333,
    "Longitude": 9.8641,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3333,9.8641",
    "IsOpen24Hours": false,
    "id": "52.3333,9.8641"
  },
  {
    "ID": 2573,
    "Tankstellennummer": "0FD09",
    "Name": "Aral Tankstelle Fürstenwalde, Friedenstr. 36",
    "Address": "Friedenstraße 36",
    "Address__1": "",
    "City": "Fürstenwalde",
    "Postcode": 15517,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 336157368,
    "24 hour?": "TRUE",
    "Latitude": 52.335668,
    "Longitude": 14.073532,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.335668,14.073532",
    "IsOpen24Hours": true,
    "id": "52.335668,14.073532"
  },
  {
    "ID": 517184806,
    "Tankstellennummer": 5083,
    "Name": "Agip Frankfurt/Oder",
    "Address": "Damaschkeweg 64",
    "Address__1": "",
    "City": "Frankfurt/Oder",
    "Postcode": 15234,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 33550000",
    "24 hour?": "FALSE",
    "Latitude": 52.33690809,
    "Longitude": 14.52094182,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.33690809,14.52094182",
    "IsOpen24Hours": false,
    "id": "52.33690809,14.52094182"
  },
  {
    "ID": 1831928779,
    "Tankstellennummer": "TD038182",
    "Name": "SCHWUELPER RUHMRISCHKAMP",
    "Address": "RUHMRISCHKAMP 3",
    "Address__1": "",
    "City": "SCHWUELPER",
    "Postcode": 38179,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05303-9709854",
    "24 hour?": "FALSE",
    "Latitude": 52.3376,
    "Longitude": 10.4178,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3376,10.4178",
    "IsOpen24Hours": false,
    "id": "52.3376,10.4178"
  },
  {
    "ID": 462111896,
    "Tankstellennummer": "0FY25",
    "Name": "BAT HANNOVER-WULFERODE WEST",
    "Address": "A7",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30539,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51151060204,
    "24 hour?": "TRUE",
    "Latitude": 52.340283,
    "Longitude": 9.865311,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.340283,9.865311",
    "IsOpen24Hours": true,
    "id": "52.340283,9.865311"
  },
  {
    "ID": 41668473,
    "Tankstellennummer": "TD020164",
    "Name": "FRANKFURT MARKENDORFER STR. 15",
    "Address": "MARKENDORFER STR. 15",
    "Address__1": "",
    "City": "FRANKFURT",
    "Postcode": 15234,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0335-4000187",
    "24 hour?": "FALSE",
    "Latitude": 52.341,
    "Longitude": 14.5291,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.341,14.5291",
    "IsOpen24Hours": false,
    "id": "52.341,14.5291"
  },
  {
    "ID": 1523,
    "Tankstellennummer": "0F573",
    "Name": "Aral Tankstelle Hannover, Göttinger Chaussee 80",
    "Address": "Göttinger Chaussee  80",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30459,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 511421873,
    "24 hour?": "TRUE",
    "Latitude": 52.345738,
    "Longitude": 9.716903,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.345738,9.716903",
    "IsOpen24Hours": true,
    "id": "52.345738,9.716903"
  },
  {
    "ID": 2200,
    "Tankstellennummer": "0FA41",
    "Name": "Aral Tankstelle Wallenhorst, Grosse Strasse 2",
    "Address": "Grosse Straße 2",
    "Address__1": "",
    "City": "Wallenhorst",
    "Postcode": 49134,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 540739522,
    "24 hour?": "FALSE",
    "Latitude": 52.347341,
    "Longitude": 8.018324,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.347341,8.018324",
    "IsOpen24Hours": false,
    "id": "52.347341,8.018324"
  },
  {
    "ID": 2654,
    "Tankstellennummer": "0FD75",
    "Name": "Aral Tankstelle Bergholz-Rehbrücke, Arthur-Scheunert-Allee 73",
    "Address": "Arthur-Scheunert-Allee 73",
    "Address__1": "",
    "City": "Nuthetal",
    "Postcode": 14558,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 33200508777,
    "24 hour?": "TRUE",
    "Latitude": 52.350942,
    "Longitude": 13.103456,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.350942,13.103456",
    "IsOpen24Hours": true,
    "id": "52.350942,13.103456"
  },
  {
    "ID": 241212789,
    "Tankstellennummer": 5080,
    "Name": "Agip Fuerstenwalde",
    "Address": "Langenwahler Str. 19",
    "Address__1": "",
    "City": "Fuerstenwalde",
    "Postcode": 15517,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 33615020",
    "24 hour?": "FALSE",
    "Latitude": 52.35128331,
    "Longitude": 14.07231842,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.35128331,14.07231842",
    "IsOpen24Hours": false,
    "id": "52.35128331,14.07231842"
  },
  {
    "ID": 863024421,
    "Tankstellennummer": 610251,
    "Name": "WESTFALEN, NIEDERNWOEHREN",
    "Address": "Hauptstr. 58",
    "Address__1": "",
    "City": "Niedernwöhren",
    "Postcode": 31712,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05721/81537",
    "24 hour?": "FALSE",
    "Latitude": 52.3559,
    "Longitude": 9.14196,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3559,9.14196",
    "IsOpen24Hours": false,
    "id": "52.3559,9.14196"
  },
  {
    "ID": 1574,
    "Tankstellennummer": "0F615",
    "Name": "Aral Tankstelle Hannover, Lehrter Str.  86 (B 65 Südseite)",
    "Address": "B 65 -Südseite-",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30559,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5119508955,
    "24 hour?": "TRUE",
    "Latitude": 52.357767,
    "Longitude": 9.862247,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.357767,9.862247",
    "IsOpen24Hours": true,
    "id": "52.357767,9.862247"
  },
  {
    "ID": 1553,
    "Tankstellennummer": "0F594",
    "Name": "Aral Tankstelle Hannover, Lehrter Str.  86 (B 65 Nordseite)",
    "Address": "Lehrter Str. 86 (B 65 Nor",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30559,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 511527428,
    "24 hour?": "TRUE",
    "Latitude": 52.358118,
    "Longitude": 9.861877,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.358118,9.861877",
    "IsOpen24Hours": true,
    "id": "52.358118,9.861877"
  },
  {
    "ID": 2729,
    "Tankstellennummer": "0FE41",
    "Name": "Aral Tankstelle Mahlow, Am Lückefeld",
    "Address": "Am Lückefeld 2",
    "Address__1": "",
    "City": "Mahlow",
    "Postcode": 15831,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3379379701,
    "24 hour?": "TRUE",
    "Latitude": 52.358172,
    "Longitude": 13.432388,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.358172,13.432388",
    "IsOpen24Hours": true,
    "id": "52.358172,13.432388"
  },
  {
    "ID": 2670129,
    "Tankstellennummer": 610640,
    "Name": "WESTFALEN, HANNOVER",
    "Address": "Badenstedter Str. 145",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30455,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0511/494987",
    "24 hour?": "FALSE",
    "Latitude": 52.3583,
    "Longitude": 9.67755,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3583,9.67755",
    "IsOpen24Hours": false,
    "id": "52.3583,9.67755"
  },
  {
    "ID": 784330811,
    "Tankstellennummer": "TD038620",
    "Name": "EMSBUEREN PAXTONSTR. 2",
    "Address": "PAXTONSTR. 2",
    "Address__1": "",
    "City": "EMSBUEREN",
    "Postcode": 48488,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05903-9321345",
    "24 hour?": "FALSE",
    "Latitude": 52.3588,
    "Longitude": 7.2611,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3588,7.2611",
    "IsOpen24Hours": false,
    "id": "52.3588,7.2611"
  },
  {
    "ID": 2242,
    "Tankstellennummer": "0FA77",
    "Name": "Aral Tankstelle Recke, Hauptstr.72",
    "Address": "Hauptstraße 72",
    "Address__1": "",
    "City": "Recke",
    "Postcode": 49509,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 54533410,
    "24 hour?": "FALSE",
    "Latitude": 52.362743,
    "Longitude": 7.718779,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.362743,7.718779",
    "IsOpen24Hours": false,
    "id": "52.362743,7.718779"
  },
  {
    "ID": 802987180,
    "Tankstellennummer": 610131,
    "Name": "WESTFALEN, SPELLE",
    "Address": "Bernard-Krone-Str. 1",
    "Address__1": "",
    "City": "Spelle",
    "Postcode": 48480,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05977/362",
    "24 hour?": "FALSE",
    "Latitude": 52.3633,
    "Longitude": 7.47391,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3633,7.47391",
    "IsOpen24Hours": false,
    "id": "52.3633,7.47391"
  },
  {
    "ID": 2558,
    "Tankstellennummer": "0FC94",
    "Name": "Aral Tankstelle Werder, Potsdamer Str. 82",
    "Address": "Potsdamer Straße 82",
    "Address__1": "",
    "City": "Werder",
    "Postcode": 14542,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 332745835,
    "24 hour?": "TRUE",
    "Latitude": 52.364491,
    "Longitude": 12.940611,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.364491,12.940611",
    "IsOpen24Hours": true,
    "id": "52.364491,12.940611"
  },
  {
    "ID": 2526,
    "Tankstellennummer": "0FC67",
    "Name": "Aral Tankstelle Frankfurt, Berliner Chaussee 22",
    "Address": "Berliner Chaussee 22",
    "Address__1": "",
    "City": "Frankfurt",
    "Postcode": 15234,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 33564190,
    "24 hour?": "FALSE",
    "Latitude": 52.365169,
    "Longitude": 14.522379,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.365169,14.522379",
    "IsOpen24Hours": false,
    "id": "52.365169,14.522379"
  },
  {
    "ID": 3402,
    "Tankstellennummer": "0FL42",
    "Name": "Aral Tankstelle Schönefeld, Zeppelinstr. 2",
    "Address": "Zeppelinstraße 2",
    "Address__1": "",
    "City": "Schönefeld",
    "Postcode": 12529,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3063311794,
    "24 hour?": "FALSE",
    "Latitude": 52.367812,
    "Longitude": 13.560766,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.367812,13.560766",
    "IsOpen24Hours": false,
    "id": "52.367812,13.560766"
  },
  {
    "ID": 3377,
    "Tankstellennummer": "0FL19",
    "Name": "Aral Tankstelle Potsdam, Gerlachstr. 8",
    "Address": "Gerlachstraße 8",
    "Address__1": "",
    "City": "Potsdam",
    "Postcode": 14480,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 331613452,
    "24 hour?": "TRUE",
    "Latitude": 52.367852,
    "Longitude": 13.12857,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.367852,13.12857",
    "IsOpen24Hours": true,
    "id": "52.367852,13.12857"
  },
  {
    "ID": 1064554902,
    "Tankstellennummer": 4661,
    "Name": "Agip Hannover",
    "Address": "Leinstrae 11",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30159,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 51136373",
    "24 hour?": "FALSE",
    "Latitude": 52.36960898,
    "Longitude": 9.73556302,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.36960898,9.73556302",
    "IsOpen24Hours": false,
    "id": "52.36960898,9.73556302"
  },
  {
    "ID": 1586,
    "Tankstellennummer": "0F623",
    "Name": "Aral Tankstelle Hannover, Marienstr. 108",
    "Address": "Marienstraße 108",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30171,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 511858508,
    "24 hour?": "TRUE",
    "Latitude": 52.370188,
    "Longitude": 9.758919,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.370188,9.758919",
    "IsOpen24Hours": true,
    "id": "52.370188,9.758919"
  },
  {
    "ID": 413336746,
    "Tankstellennummer": "TD038240",
    "Name": "SCHOENEFELD ELLY-BEINHORN-RING",
    "Address": "ELLY-BEINHORN-RING 2 A",
    "Address__1": "",
    "City": "SCHOENEFELD",
    "Postcode": 12529,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-609156230",
    "24 hour?": "FALSE",
    "Latitude": 52.3704,
    "Longitude": 13.5272,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3704,13.5272",
    "IsOpen24Hours": false,
    "id": "52.3704,13.5272"
  },
  {
    "ID": 1028164611,
    "Tankstellennummer": "0FG10",
    "Name": "Aral TankStelle Hannover, Hans-Böckler-Allee 33",
    "Address": "Hans-Böckler-Allee 33",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30173,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5118112116,
    "24 hour?": "TRUE",
    "Latitude": 52.371392,
    "Longitude": 9.773765,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.371392,9.773765",
    "IsOpen24Hours": true,
    "id": "52.371392,9.773765"
  },
  {
    "ID": 1131,
    "Tankstellennummer": "0F025",
    "Name": "Aral Tankstelle Espelkamp, Isenstedter Straße 139",
    "Address": "Isenstedter Straße 139",
    "Address__1": "",
    "City": "Espelkamp",
    "Postcode": 32339,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5772936176,
    "24 hour?": "TRUE",
    "Latitude": 52.37393,
    "Longitude": 8.634729,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.37393,8.634729",
    "IsOpen24Hours": true,
    "id": "52.37393,8.634729"
  },
  {
    "ID": 3380,
    "Tankstellennummer": "0FL21",
    "Name": "Aral Tankstelle Potsdam, Drewitzer Str. 20 A",
    "Address": "Drewitzer Straße 20 A",
    "Address__1": "",
    "City": "Potsdam",
    "Postcode": 14478,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 331864635,
    "24 hour?": "TRUE",
    "Latitude": 52.37411,
    "Longitude": 13.088152,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.37411,13.088152",
    "IsOpen24Hours": true,
    "id": "52.37411,13.088152"
  },
  {
    "ID": 2086411099,
    "Tankstellennummer": "TD028472",
    "Name": "POTSDAM PIRSCHHEIDE 1",
    "Address": "PIRSCHHEIDE 1",
    "Address__1": "",
    "City": "POTSDAM",
    "Postcode": 14471,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0331-972922",
    "24 hour?": "FALSE",
    "Latitude": 52.3753,
    "Longitude": 13.0082,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3753,13.0082",
    "IsOpen24Hours": false,
    "id": "52.3753,13.0082"
  },
  {
    "ID": 1333933485,
    "Tankstellennummer": "TD037366",
    "Name": "SEELZE HARENBERGER MEILE 39",
    "Address": "HARENBERGER MEILE 39",
    "Address__1": "",
    "City": "SEELZE",
    "Postcode": 30926,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05137-877106",
    "24 hour?": "FALSE",
    "Latitude": 52.3778,
    "Longitude": 9.6187,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3778,9.6187",
    "IsOpen24Hours": false,
    "id": "52.3778,9.6187"
  },
  {
    "ID": 2712,
    "Tankstellennummer": "0FE26",
    "Name": "Aral Autohof Großbeeren, Osdorfer Ring 33-35",
    "Address": "Osdorfer Ring 33-35",
    "Address__1": "",
    "City": "Großbeeren",
    "Postcode": 14979,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3370190587,
    "24 hour?": "TRUE",
    "Latitude": 52.3779696,
    "Longitude": 13.312169,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.3779696,13.312169",
    "IsOpen24Hours": true,
    "id": "52.3779696,13.312169"
  },
  {
    "ID": 2524,
    "Tankstellennummer": "0FC65",
    "Name": "Aral Tankstelle Fürstenwalde, Triftstr./Karl-Liebknecht",
    "Address": "Triftstraße 36",
    "Address__1": "",
    "City": "Fürstenwalde",
    "Postcode": 15517,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3361349966,
    "24 hour?": "TRUE",
    "Latitude": 52.378354,
    "Longitude": 14.073492,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.378354,14.073492",
    "IsOpen24Hours": true,
    "id": "52.378354,14.073492"
  },
  {
    "ID": 2703,
    "Tankstellennummer": "0FE18",
    "Name": "Aral Tankstelle Berlin, Adlergestell 748",
    "Address": "Adlergestell 748",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12527,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3067549184,
    "24 hour?": "TRUE",
    "Latitude": 52.37881,
    "Longitude": 13.644191,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.37881,13.644191",
    "IsOpen24Hours": true,
    "id": "52.37881,13.644191"
  },
  {
    "ID": 1868943044,
    "Tankstellennummer": "TD039198",
    "Name": "POTSDAM HORSTWEG 53C",
    "Address": "HORSTWEG 53c",
    "Address__1": "",
    "City": "POTSDAM",
    "Postcode": 14478,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0331-87003713",
    "24 hour?": "FALSE",
    "Latitude": 52.3824,
    "Longitude": 13.0885,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3824,13.0885",
    "IsOpen24Hours": false,
    "id": "52.3824,13.0885"
  },
  {
    "ID": 17826652,
    "Tankstellennummer": 610643,
    "Name": "WESTFALEN, HASTE",
    "Address": "Hauptstr. 63",
    "Address__1": "",
    "City": "Haste",
    "Postcode": 31559,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05723/981048",
    "24 hour?": "FALSE",
    "Latitude": 52.3826,
    "Longitude": 9.39411,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3826,9.39411",
    "IsOpen24Hours": false,
    "id": "52.3826,9.39411"
  },
  {
    "ID": 1585,
    "Tankstellennummer": "0F622",
    "Name": "Aral Tankstelle Hannover, Am Welfenplatz 19",
    "Address": "Am Welfenplatz 19",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30161,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 511314425,
    "24 hour?": "FALSE",
    "Latitude": 52.383431,
    "Longitude": 9.739919,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.383431,9.739919",
    "IsOpen24Hours": false,
    "id": "52.383431,9.739919"
  },
  {
    "ID": 137454232,
    "Tankstellennummer": "TD041632",
    "Name": "LEHRTE AUTOHOF STRASSBURGER ST",
    "Address": "AUTOHOF STRASSBURGER STR. 4",
    "Address__1": "",
    "City": "LEHRTE",
    "Postcode": 31275,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 52.3854,
    "Longitude": 9.9528,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3854,9.9528",
    "IsOpen24Hours": false,
    "id": "52.3854,9.9528"
  },
  {
    "ID": 1049316373,
    "Tankstellennummer": "TD028530",
    "Name": "POTSDAM GROSSBEERENSTR. 137",
    "Address": "GROSSBEERENSTR. 137",
    "Address__1": "",
    "City": "POTSDAM",
    "Postcode": 14482,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0331-7482098",
    "24 hour?": "FALSE",
    "Latitude": 52.3856,
    "Longitude": 13.1096,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3856,13.1096",
    "IsOpen24Hours": false,
    "id": "52.3856,13.1096"
  },
  {
    "ID": 3398,
    "Tankstellennummer": "0FL38",
    "Name": "Aral Tankstelle Potsdam, Grossbeerenstr. 121",
    "Address": "Grossbeerenstraße 121",
    "Address__1": "",
    "City": "Potsdam",
    "Postcode": 14482,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3317482335,
    "24 hour?": "TRUE",
    "Latitude": 52.386085,
    "Longitude": 13.106655,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.386085,13.106655",
    "IsOpen24Hours": true,
    "id": "52.386085,13.106655"
  },
  {
    "ID": 1864422477,
    "Tankstellennummer": 5089,
    "Name": "Agip Teltow",
    "Address": "Mahlower Str. 241",
    "Address__1": "",
    "City": "Teltow",
    "Postcode": 14513,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 33284718",
    "24 hour?": "TRUE",
    "Latitude": 52.38695825,
    "Longitude": 13.30493905,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.38695825,13.30493905",
    "IsOpen24Hours": true,
    "id": "52.38695825,13.30493905"
  },
  {
    "ID": 1590,
    "Tankstellennummer": "0F627",
    "Name": "Aral Tankstelle Lehrte, Burgdorfer Str. 115",
    "Address": "Burgdorfer Straße 115",
    "Address__1": "",
    "City": "Lehrte",
    "Postcode": 31275,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 513282960,
    "24 hour?": "FALSE",
    "Latitude": 52.387399,
    "Longitude": 9.970479,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.387399,9.970479",
    "IsOpen24Hours": false,
    "id": "52.387399,9.970479"
  },
  {
    "ID": 1815389341,
    "Tankstellennummer": "TD020362",
    "Name": "BERLIN ADLERGESTELL 623",
    "Address": "ADLERGESTELL 623",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12527,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-6758108",
    "24 hour?": "FALSE",
    "Latitude": 52.3878,
    "Longitude": 13.6307,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3878,13.6307",
    "IsOpen24Hours": false,
    "id": "52.3878,13.6307"
  },
  {
    "ID": 3266,
    "Tankstellennummer": "0FK36",
    "Name": "Aral Autobahntankstelle Lehrte, Lehrter See Nord (A 2)",
    "Address": "A 2",
    "Address__1": "",
    "City": "Lehrte",
    "Postcode": 31275,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51324888,
    "24 hour?": "TRUE",
    "Latitude": 52.388297,
    "Longitude": 9.997808,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.388297,9.997808",
    "IsOpen24Hours": true,
    "id": "52.388297,9.997808"
  },
  {
    "ID": 849363113,
    "Tankstellennummer": "TD037945",
    "Name": "GROSSBEEREN FRIEDERIKENHOFER S",
    "Address": "FRIEDERIKENHOFER STR. 1",
    "Address__1": "",
    "City": "GROSSBEEREN",
    "Postcode": 14979,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033701-365783",
    "24 hour?": "FALSE",
    "Latitude": 52.3903,
    "Longitude": 13.3407,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3903,13.3407",
    "IsOpen24Hours": false,
    "id": "52.3903,13.3407"
  },
  {
    "ID": 764414808,
    "Tankstellennummer": "TD150480",
    "Name": "PLOETZIN AUTOHOF BAB 10 /B1",
    "Address": "Am Magna Park 4",
    "Address__1": "tzin",
    "City": "PLOETZIN (WERDER)",
    "Postcode": 14542,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03327-730313",
    "24 hour?": "FALSE",
    "Latitude": 52.3909,
    "Longitude": 12.845,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3909,12.845",
    "IsOpen24Hours": false,
    "id": "52.3909,12.845"
  },
  {
    "ID": 2726,
    "Tankstellennummer": "0FE82",
    "Name": "Aral Tankstelle Schönefeld, Schützenstr. 11",
    "Address": "Schützenstraße 11",
    "Address__1": "",
    "City": "Schönefeld",
    "Postcode": 12529,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3063497445,
    "24 hour?": "TRUE",
    "Latitude": 52.394826,
    "Longitude": 13.533624,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.394826,13.533624",
    "IsOpen24Hours": true,
    "id": "52.394826,13.533624"
  },
  {
    "ID": 590206069,
    "Tankstellennummer": "TD034868",
    "Name": "BRANDENBURG WILHELMSDORFER LAN",
    "Address": "WILHELMSDORFER LANDSTR. 36",
    "Address__1": "",
    "City": "BRANDENBURG",
    "Postcode": 14776,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03381-795506",
    "24 hour?": "FALSE",
    "Latitude": 52.3952,
    "Longitude": 12.5405,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.3952,12.5405",
    "IsOpen24Hours": false,
    "id": "52.3952,12.5405"
  },
  {
    "ID": 654074799,
    "Tankstellennummer": 610641,
    "Name": "WESTFALEN, HANNOVER",
    "Address": "Melanchthonstr. 40",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30165,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0511/3501366",
    "24 hour?": "TRUE",
    "Latitude": 52.3982,
    "Longitude": 9.73002,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.3982,9.73002",
    "IsOpen24Hours": true,
    "id": "52.3982,9.73002"
  },
  {
    "ID": 2865,
    "Tankstellennummer": "0FH37",
    "Name": "Aral Tankstelle Hannover, Ferdinand-Wallbrecht-Str.",
    "Address": "Ferdinand-Wallbrecht-Stra",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30163,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5113946245,
    "24 hour?": "TRUE",
    "Latitude": 52.398249,
    "Longitude": 9.741357,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.398249,9.741357",
    "IsOpen24Hours": true,
    "id": "52.398249,9.741357"
  },
  {
    "ID": 754846998,
    "Tankstellennummer": "TD041673",
    "Name": "HANNOVER",
    "Address": "23 VINNHORSTER WEG",
    "Address__1": "",
    "City": "HANNOVER",
    "Postcode": 30419,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0511-753165",
    "24 hour?": "FALSE",
    "Latitude": 52.4008,
    "Longitude": 9.6936,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4008,9.6936",
    "IsOpen24Hours": false,
    "id": "52.4008,9.6936"
  },
  {
    "ID": 2209,
    "Tankstellennummer": "0FA50",
    "Name": "Aral Tankstelle Bramsche, Malgartenerstrasse 2",
    "Address": "Malgartenerstraße 2",
    "Address__1": "",
    "City": "Bramsche",
    "Postcode": 49565,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 54619951818,
    "24 hour?": "FALSE",
    "Latitude": 52.405021,
    "Longitude": 7.988431,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.405021,7.988431",
    "IsOpen24Hours": false,
    "id": "52.405021,7.988431"
  },
  {
    "ID": 3374,
    "Tankstellennummer": "0FL16",
    "Name": "Aral Tankstelle Brandenburg, Potsdamer Str. 22",
    "Address": "Potsdamer Straße 22",
    "Address__1": "",
    "City": "Brandenburg",
    "Postcode": 14776,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3381524079,
    "24 hour?": "TRUE",
    "Latitude": 52.406107,
    "Longitude": 12.575959,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.406107,12.575959",
    "IsOpen24Hours": true,
    "id": "52.406107,12.575959"
  },
  {
    "ID": 17814840,
    "Tankstellennummer": "0FV48",
    "Name": "HANNOVER",
    "Address": "Schulenburger Landstraße 128",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30165,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51167662626,
    "24 hour?": "TRUE",
    "Latitude": 52.40655,
    "Longitude": 9.71118,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.40655,9.71118",
    "IsOpen24Hours": true,
    "id": "52.40655,9.71118"
  },
  {
    "ID": 1633943441,
    "Tankstellennummer": "TD027011",
    "Name": "GENTHIN BERLINER CH.",
    "Address": "BERLINER CH.",
    "Address__1": "",
    "City": "GENTHIN",
    "Postcode": 39307,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03933-803515",
    "24 hour?": "FALSE",
    "Latitude": 52.4082,
    "Longitude": 12.1755,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4082,12.1755",
    "IsOpen24Hours": false,
    "id": "52.4082,12.1755"
  },
  {
    "ID": 1337163627,
    "Tankstellennummer": "0FV03",
    "Name": "GENTHIN",
    "Address": "Geschwister-Scholl-Straße 32",
    "Address__1": "",
    "City": "Genthin",
    "Postcode": 39307,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 39339482699,
    "24 hour?": "FALSE",
    "Latitude": 52.408785,
    "Longitude": 12.162745,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.408785,12.162745",
    "IsOpen24Hours": false,
    "id": "52.408785,12.162745"
  },
  {
    "ID": 751889945,
    "Tankstellennummer": "TD034835",
    "Name": "BRANDENBURG FRIEDRICH-FRANZ-ST",
    "Address": "FRIEDRICH-FRANZ-STR. 2",
    "Address__1": "",
    "City": "BRANDENBURG",
    "Postcode": 14770,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03381-301295",
    "24 hour?": "FALSE",
    "Latitude": 52.4137,
    "Longitude": 12.5241,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4137,12.5241",
    "IsOpen24Hours": false,
    "id": "52.4137,12.5241"
  },
  {
    "ID": 2039419267,
    "Tankstellennummer": "TD003681",
    "Name": "HANNOVER SUTELSTR. 23 D",
    "Address": "SUTELSTR. 23 D",
    "Address__1": "",
    "City": "HANNOVER",
    "Postcode": 30659,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0511-6497636",
    "24 hour?": "FALSE",
    "Latitude": 52.4143,
    "Longitude": 9.7974,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4143,9.7974",
    "IsOpen24Hours": false,
    "id": "52.4143,9.7974"
  },
  {
    "ID": 333242738,
    "Tankstellennummer": "TD035014",
    "Name": "BERLIN ADLERGESTELL 543",
    "Address": "ADLERGESTELL 543",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12527,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-67489152",
    "24 hour?": "FALSE",
    "Latitude": 52.4143,
    "Longitude": 13.573,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4143,13.573",
    "IsOpen24Hours": false,
    "id": "52.4143,13.573"
  },
  {
    "ID": 1609,
    "Tankstellennummer": "0F642",
    "Name": "Aral Tankstelle Hannover, Kirchhorster Str. 24",
    "Address": "Kirchhorster Straße 24",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30659,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5116139075,
    "24 hour?": "TRUE",
    "Latitude": 52.414372,
    "Longitude": 9.826168,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.414372,9.826168",
    "IsOpen24Hours": true,
    "id": "52.414372,9.826168"
  },
  {
    "ID": 1109265312,
    "Tankstellennummer": "TD038281",
    "Name": "KLEINMACHNOW KARL-MARX-STR. 46",
    "Address": "KARL-MARX-STR. 46",
    "Address__1": "",
    "City": "KLEINMACHNOW",
    "Postcode": 14532,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033203-22262",
    "24 hour?": "FALSE",
    "Latitude": 52.4148,
    "Longitude": 13.2235,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4148,13.2235",
    "IsOpen24Hours": false,
    "id": "52.4148,13.2235"
  },
  {
    "ID": 1395770529,
    "Tankstellennummer": 7017,
    "Name": "Agip Berlin",
    "Address": "Ostpreussendamm 89",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12207,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 30755197",
    "24 hour?": "TRUE",
    "Latitude": 52.41503211,
    "Longitude": 13.30259409,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.41503211,13.30259409",
    "IsOpen24Hours": true,
    "id": "52.41503211,13.30259409"
  },
  {
    "ID": 1903149450,
    "Tankstellennummer": "TD028290",
    "Name": "BRANDENBURG PLAUER LANDSTR.",
    "Address": "PLAUER LANDSTR. 3",
    "Address__1": "",
    "City": "BRANDENBURG",
    "Postcode": 14772,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03381-700066",
    "24 hour?": "FALSE",
    "Latitude": 52.4166,
    "Longitude": 12.4867,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4166,12.4867",
    "IsOpen24Hours": false,
    "id": "52.4166,12.4867"
  },
  {
    "ID": 785634883,
    "Tankstellennummer": "TD142930",
    "Name": "HANNOVER VAHRENWALDER STR. 284",
    "Address": "VAHRENWALDER STR. 284",
    "Address__1": "",
    "City": "HANNOVER",
    "Postcode": 30179,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0511-632957",
    "24 hour?": "FALSE",
    "Latitude": 52.4171,
    "Longitude": 9.7342,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4171,9.7342",
    "IsOpen24Hours": false,
    "id": "52.4171,9.7342"
  },
  {
    "ID": 1509,
    "Tankstellennummer": "0F559",
    "Name": "Aral Autohof Hannover, Am Leineufer 52",
    "Address": "Am Leineufer 52",
    "Address__1": "",
    "City": "Hannover",
    "Postcode": 30419,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 511794933,
    "24 hour?": "TRUE",
    "Latitude": 52.418532,
    "Longitude": 9.635381,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.418532,9.635381",
    "IsOpen24Hours": true,
    "id": "52.418532,9.635381"
  },
  {
    "ID": 2860,
    "Tankstellennummer": "0FH35",
    "Name": "Aral Tankstelle Wolfsburg, Hinterm Hagen",
    "Address": "Hinterm Hagen 21",
    "Address__1": "",
    "City": "Wolfsburg",
    "Postcode": 38442,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 536262204,
    "24 hour?": "FALSE",
    "Latitude": 52.419117,
    "Longitude": 10.721299,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.419117,10.721299",
    "IsOpen24Hours": false,
    "id": "52.419117,10.721299"
  },
  {
    "ID": 1047584663,
    "Tankstellennummer": "TD035469",
    "Name": "BERLIN HILDBURGHAUSER STR. 222",
    "Address": "HILDBURGHAUSER STR. 222",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12209,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-76687462",
    "24 hour?": "FALSE",
    "Latitude": 52.4192,
    "Longitude": 13.3194,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4192,13.3194",
    "IsOpen24Hours": false,
    "id": "52.4192,13.3194"
  },
  {
    "ID": 1010436404,
    "Tankstellennummer": "TD041616",
    "Name": "WOLFSBURG BRESLAUER 45",
    "Address": "BRESLAUER STR.45",
    "Address__1": "",
    "City": "WOLFSBURG",
    "Postcode": 38440,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05361-39000",
    "24 hour?": "FALSE",
    "Latitude": 52.4192,
    "Longitude": 10.7542,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4192,10.7542",
    "IsOpen24Hours": false,
    "id": "52.4192,10.7542"
  },
  {
    "ID": 3240,
    "Tankstellennummer": "0FK18",
    "Name": "Aral Tankstelle Berlin, Koenigstr. 58",
    "Address": "Königstraße 58",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 14109,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 308051018,
    "24 hour?": "TRUE",
    "Latitude": 52.41921,
    "Longitude": 13.158542,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.41921,13.158542",
    "IsOpen24Hours": true,
    "id": "52.41921,13.158542"
  },
  {
    "ID": 2690,
    "Tankstellennummer": "0FE09",
    "Name": "Aral Tankstelle Potsdam, Potsdamer Str. 54",
    "Address": "Potsdamer Straße 55",
    "Address__1": "",
    "City": "Potsdam",
    "Postcode": 14469,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 33155079450,
    "24 hour?": "TRUE",
    "Latitude": 52.419578,
    "Longitude": 13.012795,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.419578,13.012795",
    "IsOpen24Hours": true,
    "id": "52.419578,13.012795"
  },
  {
    "ID": 1818161475,
    "Tankstellennummer": "TD004614",
    "Name": "NORDHORN BENTHEIMER STR. 233",
    "Address": "BENTHEIMER STR. 233",
    "Address__1": "",
    "City": "NORDHORN",
    "Postcode": 48529,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05921-8274720",
    "24 hour?": "FALSE",
    "Latitude": 52.4196,
    "Longitude": 7.0836,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4196,7.0836",
    "IsOpen24Hours": false,
    "id": "52.4196,7.0836"
  },
  {
    "ID": 1383664705,
    "Tankstellennummer": 610096,
    "Name": "WESTFALEN, WESTFALEN, NEUENKIRCHEN",
    "Address": "Lindenstr. 35",
    "Address__1": "",
    "City": "Neuenkirchen",
    "Postcode": 49586,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05465/203930",
    "24 hour?": "FALSE",
    "Latitude": 52.4197,
    "Longitude": 7.83831,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4197,7.83831",
    "IsOpen24Hours": false,
    "id": "52.4197,7.83831"
  },
  {
    "ID": 1547,
    "Tankstellennummer": "0F589",
    "Name": "Aral Tankstelle Garbsen, 64 Auf DerHorst",
    "Address": "Auf der Horst 64",
    "Address__1": "",
    "City": "Garbsen",
    "Postcode": 30823,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 513771234,
    "24 hour?": "FALSE",
    "Latitude": 52.421219,
    "Longitude": 9.595494,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.421219,9.595494",
    "IsOpen24Hours": false,
    "id": "52.421219,9.595494"
  },
  {
    "ID": 492744369,
    "Tankstellennummer": "TD004150",
    "Name": "BERLIN NEUKOELLNER STR. 294",
    "Address": "NEUKOELLNER STR. 294",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12357,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-6613037",
    "24 hour?": "FALSE",
    "Latitude": 52.4222,
    "Longitude": 13.4871,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4222,13.4871",
    "IsOpen24Hours": false,
    "id": "52.4222,13.4871"
  },
  {
    "ID": 3232,
    "Tankstellennummer": "0FK11",
    "Name": "Aral Tankstelle Berlin, Mariendorfer Damm 341",
    "Address": "Mariendorfer Damm 341",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12107,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 307411920,
    "24 hour?": "FALSE",
    "Latitude": 52.423045,
    "Longitude": 13.396742,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.423045,13.396742",
    "IsOpen24Hours": false,
    "id": "52.423045,13.396742"
  },
  {
    "ID": 1605,
    "Tankstellennummer": "0F639",
    "Name": "Aral Tankstelle Langenhagen, Vahrenwalder Str. 323-325",
    "Address": "Vahrenwalder Str. 323",
    "Address__1": "",
    "City": "Langenhagen",
    "Postcode": 30851,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 511632212,
    "24 hour?": "TRUE",
    "Latitude": 52.423851,
    "Longitude": 9.732302,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.423851,9.732302",
    "IsOpen24Hours": true,
    "id": "52.423851,9.732302"
  },
  {
    "ID": 3226,
    "Tankstellennummer": "0FK05",
    "Name": "Aral Tankstelle Berlin, Neukoellner Str. 250-256",
    "Address": "Neukoellner Str. 250-256",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12357,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 306613947,
    "24 hour?": "TRUE",
    "Latitude": 52.425789,
    "Longitude": 13.482354,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.425789,13.482354",
    "IsOpen24Hours": true,
    "id": "52.425789,13.482354"
  },
  {
    "ID": 851972044,
    "Tankstellennummer": "TD020016",
    "Name": "ERKNER AM FRIEDENSPLATZ",
    "Address": "AM FRIEDENSPLATZ",
    "Address__1": "",
    "City": "ERKNER",
    "Postcode": 15537,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03362-3261",
    "24 hour?": "FALSE",
    "Latitude": 52.4266,
    "Longitude": 13.7511,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4266,13.7511",
    "IsOpen24Hours": false,
    "id": "52.4266,13.7511"
  },
  {
    "ID": 1556,
    "Tankstellennummer": "0F596",
    "Name": "Aral Tankstelle Wolfsburg, Heinr.-Nordhoff-Str.117",
    "Address": "Heinr.-Nordhoff-Str. 117",
    "Address__1": "",
    "City": "Wolfsburg",
    "Postcode": 38440,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 536113248,
    "24 hour?": "TRUE",
    "Latitude": 52.427196,
    "Longitude": 10.77158,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.427196,10.77158",
    "IsOpen24Hours": true,
    "id": "52.427196,10.77158"
  },
  {
    "ID": 2679,
    "Tankstellennummer": "0FE01",
    "Name": "Aral Tankstelle Erkner, Berliner Str. 11",
    "Address": "Berliner Straße 11",
    "Address__1": "",
    "City": "Erkner",
    "Postcode": 15537,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 336224701,
    "24 hour?": "FALSE",
    "Latitude": 52.428055,
    "Longitude": 13.744191,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.428055,13.744191",
    "IsOpen24Hours": false,
    "id": "52.428055,13.744191"
  },
  {
    "ID": 808,
    "Tankstellennummer": "0F040",
    "Name": "Aral Tankstelle Berlin, Neukoellner Str. 219",
    "Address": "Neuköllner Straße 219",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12357,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 306614212,
    "24 hour?": "FALSE",
    "Latitude": 52.428631,
    "Longitude": 13.479837,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.428631,13.479837",
    "IsOpen24Hours": false,
    "id": "52.428631,13.479837"
  },
  {
    "ID": 2270,
    "Tankstellennummer": "0FB01",
    "Name": "Aral Tankstelle Nordhorn, Denekamper Str. 15",
    "Address": "Denekamper Straße 15",
    "Address__1": "",
    "City": "Nordhorn",
    "Postcode": 48529,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 59215341,
    "24 hour?": "TRUE",
    "Latitude": 52.428975,
    "Longitude": 7.062336,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.428975,7.062336",
    "IsOpen24Hours": true,
    "id": "52.428975,7.062336"
  },
  {
    "ID": 3303,
    "Tankstellennummer": "0FK61",
    "Name": "Aral Tankstelle Wunstorf, Hagenburger Str. 8",
    "Address": "Hagenburger Straße 8",
    "Address__1": "",
    "City": "Wunstorf",
    "Postcode": 31515,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5031913719,
    "24 hour?": "TRUE",
    "Latitude": 52.42959,
    "Longitude": 9.423001,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.42959,9.423001",
    "IsOpen24Hours": true,
    "id": "52.42959,9.423001"
  },
  {
    "ID": 2625,
    "Tankstellennummer": "0FD50",
    "Name": "Aral Tankstelle Brandenburg, Brielower Landstraße",
    "Address": "Brielower Landstraße 17",
    "Address__1": "",
    "City": "Brandenburg",
    "Postcode": 14772,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3381700164,
    "24 hour?": "TRUE",
    "Latitude": 52.430302,
    "Longitude": 12.553348,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.430302,12.553348",
    "IsOpen24Hours": true,
    "id": "52.430302,12.553348"
  },
  {
    "ID": 420450401,
    "Tankstellennummer": 5086,
    "Name": "Agip Berlin",
    "Address": "Adlergestell 289 A",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12489,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 30671329",
    "24 hour?": "TRUE",
    "Latitude": 52.43197379,
    "Longitude": 13.54651899,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.43197379,13.54651899",
    "IsOpen24Hours": true,
    "id": "52.43197379,13.54651899"
  },
  {
    "ID": 2849,
    "Tankstellennummer": "0FH27",
    "Name": "Aral Tankstelle Rahden, Lemfoerder Str. 4",
    "Address": "Lemfoerder Straße 4",
    "Address__1": "",
    "City": "Rahden",
    "Postcode": 32369,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5771968092,
    "24 hour?": "FALSE",
    "Latitude": 52.432853,
    "Longitude": 8.609895,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.432853,8.609895",
    "IsOpen24Hours": false,
    "id": "52.432853,8.609895"
  },
  {
    "ID": 792,
    "Tankstellennummer": "0F028",
    "Name": "Aral Tankstelle Berlin, Potsdamer Chaussee 6",
    "Address": "Potsdamer Chaussee 6",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 14163,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 308027017,
    "24 hour?": "TRUE",
    "Latitude": 52.43311,
    "Longitude": 13.238092,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.43311,13.238092",
    "IsOpen24Hours": true,
    "id": "52.43311,13.238092"
  },
  {
    "ID": 802,
    "Tankstellennummer": "0F036",
    "Name": "Aral Tankstelle Berlin, Mariendorfer Damm 195",
    "Address": "Mariendorfer Damm 195",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12107,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 307418509,
    "24 hour?": "TRUE",
    "Latitude": 52.434036,
    "Longitude": 13.390571,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.434036,13.390571",
    "IsOpen24Hours": true,
    "id": "52.434036,13.390571"
  },
  {
    "ID": 778955861,
    "Tankstellennummer": "TD141470",
    "Name": "ISERNHAGEN HANNOVERSCHE STR. 2",
    "Address": "HANNOVERSCHE STR. 29",
    "Address__1": "",
    "City": "ISERNHAGEN",
    "Postcode": 30916,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0511-612900",
    "24 hour?": "FALSE",
    "Latitude": 52.4341,
    "Longitude": 9.8636,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4341,9.8636",
    "IsOpen24Hours": false,
    "id": "52.4341,9.8636"
  },
  {
    "ID": 142729464,
    "Tankstellennummer": "TD004077",
    "Name": "BERLIN BERLINER STR 15A",
    "Address": "BERLINER STR 15A",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 14169,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-81059385",
    "24 hour?": "FALSE",
    "Latitude": 52.4361,
    "Longitude": 13.2658,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4361,13.2658",
    "IsOpen24Hours": false,
    "id": "52.4361,13.2658"
  },
  {
    "ID": 1541,
    "Tankstellennummer": "0F584",
    "Name": "Aral Tankstelle Langenhagen, Hannoversche Str. 52",
    "Address": "Hannoversche Straße 52",
    "Address__1": "",
    "City": "Langenhagen",
    "Postcode": 30855,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 511784433,
    "24 hour?": "FALSE",
    "Latitude": 52.436544,
    "Longitude": 9.677426,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.436544,9.677426",
    "IsOpen24Hours": false,
    "id": "52.436544,9.677426"
  },
  {
    "ID": 1539,
    "Tankstellennummer": "0F603",
    "Name": "Aral Tankstelle Wolfsburg, Wolfsburger Str. 41",
    "Address": "Wolfsburger Straße 41",
    "Address__1": "",
    "City": "Wolfsburg",
    "Postcode": 38448,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 536373136,
    "24 hour?": "FALSE",
    "Latitude": 52.438275,
    "Longitude": 10.830318,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.438275,10.830318",
    "IsOpen24Hours": false,
    "id": "52.438275,10.830318"
  },
  {
    "ID": 3229,
    "Tankstellennummer": "0FK08",
    "Name": "Aral Tankstelle Berlin, Kaiser-Wilhelm-Str. 117",
    "Address": "Kaiser-Wilhelm-Straße 117",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12247,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3076680128,
    "24 hour?": "TRUE",
    "Latitude": 52.439832,
    "Longitude": 13.351252,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.439832,13.351252",
    "IsOpen24Hours": true,
    "id": "52.439832,13.351252"
  },
  {
    "ID": 738233207,
    "Tankstellennummer": 610538,
    "Name": "WESTFALEN, NORDHORN",
    "Address": "Lingener Str. 106",
    "Address__1": "",
    "City": "Nordhorn",
    "Postcode": 48531,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05921/36361",
    "24 hour?": "TRUE",
    "Latitude": 52.4408,
    "Longitude": 7.08702,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.4408,7.08702",
    "IsOpen24Hours": true,
    "id": "52.4408,7.08702"
  },
  {
    "ID": 3230,
    "Tankstellennummer": "0FK09",
    "Name": "Aral Tankstelle Berlin, Drakestrasse 26a",
    "Address": "Drakestraße 26a",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12205,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3084309770,
    "24 hour?": "TRUE",
    "Latitude": 52.440849,
    "Longitude": 13.299431,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.440849,13.299431",
    "IsOpen24Hours": true,
    "id": "52.440849,13.299431"
  },
  {
    "ID": 59977544,
    "Tankstellennummer": "TD020396",
    "Name": "BERLIN ADLERGESTELL 179",
    "Address": "ADLERGESTELL 179",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12489,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-6718211",
    "24 hour?": "FALSE",
    "Latitude": 52.4415,
    "Longitude": 13.5331,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4415,13.5331",
    "IsOpen24Hours": false,
    "id": "52.4415,13.5331"
  },
  {
    "ID": 53587133,
    "Tankstellennummer": "TD003657",
    "Name": "GARBSEN OSTERWALDER STR. 63",
    "Address": "OSTERWALDER STR. 63",
    "Address__1": "",
    "City": "GARBSEN",
    "Postcode": 30827,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05131-91637",
    "24 hour?": "FALSE",
    "Latitude": 52.4437,
    "Longitude": 9.6086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4437,9.6086",
    "IsOpen24Hours": false,
    "id": "52.4437,9.6086"
  },
  {
    "ID": 2029035079,
    "Tankstellennummer": "TD000142",
    "Name": "BERLIN ARGENTINISCHE ALLEE 47",
    "Address": "ARGENTINISCHE ALLEE 47.",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 14163,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-8134736",
    "24 hour?": "FALSE",
    "Latitude": 52.444,
    "Longitude": 13.2419,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.444,13.2419",
    "IsOpen24Hours": false,
    "id": "52.444,13.2419"
  },
  {
    "ID": 1545,
    "Tankstellennummer": "0F587",
    "Name": "Aral Tankstelle Burgdorf, 7 Dorfstr.",
    "Address": "Dorfstraße 7",
    "Address__1": "",
    "City": "Burgdorf",
    "Postcode": 31303,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51363920,
    "24 hour?": "FALSE",
    "Latitude": 52.444409,
    "Longitude": 9.981965,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.444409,9.981965",
    "IsOpen24Hours": false,
    "id": "52.444409,9.981965"
  },
  {
    "ID": 799,
    "Tankstellennummer": "0F034",
    "Name": "Aral Tankstelle Berlin, Argentinische Allee 49",
    "Address": "Argentinische Allee 49",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 14163,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3081496169,
    "24 hour?": "FALSE",
    "Latitude": 52.444544,
    "Longitude": 13.242588,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.444544,13.242588",
    "IsOpen24Hours": false,
    "id": "52.444544,13.242588"
  },
  {
    "ID": 790,
    "Tankstellennummer": "0F027",
    "Name": "Aral Tankstelle Berlin, Britzer Damm 202-204",
    "Address": "Britzer Damm 202-204",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12347,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 306076210,
    "24 hour?": "TRUE",
    "Latitude": 52.445368,
    "Longitude": 13.433414,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.445368,13.433414",
    "IsOpen24Hours": true,
    "id": "52.445368,13.433414"
  },
  {
    "ID": 1938157567,
    "Tankstellennummer": "TD020370",
    "Name": "BERLIN OBERSPREESTR. 138",
    "Address": "OBERSPREESTR. 138",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12555,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-6519761",
    "24 hour?": "FALSE",
    "Latitude": 52.4458,
    "Longitude": 13.5618,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4458,13.5618",
    "IsOpen24Hours": false,
    "id": "52.4458,13.5618"
  },
  {
    "ID": 3260,
    "Tankstellennummer": "0FK32",
    "Name": "Aral Tankstelle Berlin, Schlossstr. 63-64",
    "Address": "Schlossstraße 63-64",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12165,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 308344870,
    "24 hour?": "TRUE",
    "Latitude": 52.453079,
    "Longitude": 13.315076,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.453079,13.315076",
    "IsOpen24Hours": true,
    "id": "52.453079,13.315076"
  },
  {
    "ID": 1471877040,
    "Tankstellennummer": "TD039818",
    "Name": "BERLIN SPAETHSTRASSE 158",
    "Address": "SPAETHSTR. 158",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12359,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030/43208977",
    "24 hour?": "FALSE",
    "Latitude": 52.4532,
    "Longitude": 13.4568,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4532,13.4568",
    "IsOpen24Hours": false,
    "id": "52.4532,13.4568"
  },
  {
    "ID": 230855509,
    "Tankstellennummer": "TD020495",
    "Name": "BERLIN MICHAEL-BRUECKNER-STR.",
    "Address": "MICHAEL-BRUECKNER-STR.26-31",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12439,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-6310813",
    "24 hour?": "FALSE",
    "Latitude": 52.4533,
    "Longitude": 13.5135,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4533,13.5135",
    "IsOpen24Hours": false,
    "id": "52.4533,13.5135"
  },
  {
    "ID": 142855719,
    "Tankstellennummer": "TD020412",
    "Name": "BERLIN FUERSTENWALDER DAMM 418",
    "Address": "FUERSTENWALDER DAMM 418",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12587,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-64090400",
    "24 hour?": "FALSE",
    "Latitude": 52.4548,
    "Longitude": 13.6169,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4548,13.6169",
    "IsOpen24Hours": false,
    "id": "52.4548,13.6169"
  },
  {
    "ID": 3408,
    "Tankstellennummer": "0FL48",
    "Name": "Aral Tankstelle Berlin, An der Wuhlheide 240-242",
    "Address": "An der Wuhlheide 240-242",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12459,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3053790502,
    "24 hour?": "FALSE",
    "Latitude": 52.455211,
    "Longitude": 13.555774,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.455211,13.555774",
    "IsOpen24Hours": false,
    "id": "52.455211,13.555774"
  },
  {
    "ID": 1725026108,
    "Tankstellennummer": "TD036376",
    "Name": "BERLIN ARNULFSTR. 98-99",
    "Address": "ARNULFSTR. 98-99",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12105,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-75489500",
    "24 hour?": "FALSE",
    "Latitude": 52.4556,
    "Longitude": 13.3673,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4556,13.3673",
    "IsOpen24Hours": false,
    "id": "52.4556,13.3673"
  },
  {
    "ID": 3256,
    "Tankstellennummer": "0FK29",
    "Name": "Aral Tankstelle Berlin, Buschkrugallee 84-92",
    "Address": "Buschkrugallee 84-92",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12359,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 306061011,
    "24 hour?": "FALSE",
    "Latitude": 52.455891,
    "Longitude": 13.449641,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.455891,13.449641",
    "IsOpen24Hours": false,
    "id": "52.455891,13.449641"
  },
  {
    "ID": 1060217760,
    "Tankstellennummer": "TD038315",
    "Name": "BERLIN BRITZER DAMM 60-62",
    "Address": "BRITZER DAMM 60-62",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12347,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030/62981575",
    "24 hour?": "FALSE",
    "Latitude": 52.457,
    "Longitude": 13.4364,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.457,13.4364",
    "IsOpen24Hours": false,
    "id": "52.457,13.4364"
  },
  {
    "ID": 3381,
    "Tankstellennummer": "0FL22",
    "Name": "Aral Tankstelle Berlin, Schnellerstr. 20",
    "Address": "Schnellerstraße 20",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12439,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 306316974,
    "24 hour?": "TRUE",
    "Latitude": 52.459819,
    "Longitude": 13.504415,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.459819,13.504415",
    "IsOpen24Hours": true,
    "id": "52.459819,13.504415"
  },
  {
    "ID": 1533,
    "Tankstellennummer": "0F581",
    "Name": "Aral Tankstelle Garbsen, Otternhägener Str. 4-6",
    "Address": "Otternhägener Straße 4-6",
    "Address__1": "",
    "City": "Garbsen",
    "Postcode": 30826,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 513152737,
    "24 hour?": "FALSE",
    "Latitude": 52.461799,
    "Longitude": 9.534244,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.461799,9.534244",
    "IsOpen24Hours": false,
    "id": "52.461799,9.534244"
  },
  {
    "ID": 1591,
    "Tankstellennummer": "0F628",
    "Name": "Aral Tankstelle Weyhausen, Wolfsburger Str. 24",
    "Address": "Wolfsburger Straße 24",
    "Address__1": "",
    "City": "Weyhausen",
    "Postcode": 38554,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 53627479,
    "24 hour?": "TRUE",
    "Latitude": 52.462612,
    "Longitude": 10.718878,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.462612,10.718878",
    "IsOpen24Hours": true,
    "id": "52.462612,10.718878"
  },
  {
    "ID": 2698,
    "Tankstellennummer": "0FE14",
    "Name": "Aral Tankstelle Berlin, Köpenicker Landstr. 294,",
    "Address": "Köpenicker Landstr. 294,",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12437,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3063979990,
    "24 hour?": "TRUE",
    "Latitude": 52.464704,
    "Longitude": 13.496992,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.464704,13.496992",
    "IsOpen24Hours": true,
    "id": "52.464704,13.496992"
  },
  {
    "ID": 1112785792,
    "Tankstellennummer": "TD003665",
    "Name": "GIFHORN BRAUNSCHWEIGER STR. 13",
    "Address": "BRAUNSCHWEIGER STR.137",
    "Address__1": "",
    "City": "GIFHORN",
    "Postcode": 38518,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05371-3455",
    "24 hour?": "FALSE",
    "Latitude": 52.4653,
    "Longitude": 10.5426,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4653,10.5426",
    "IsOpen24Hours": false,
    "id": "52.4653,10.5426"
  },
  {
    "ID": 3235,
    "Tankstellennummer": "0FK13",
    "Name": "Aral Tankstelle Berlin, Suedwestkorso 36 A",
    "Address": "Suedwestkorso 36 A",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 14197,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 308211291,
    "24 hour?": "TRUE",
    "Latitude": 52.468439,
    "Longitude": 13.311289,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.468439,13.311289",
    "IsOpen24Hours": true,
    "id": "52.468439,13.311289"
  },
  {
    "ID": 945783735,
    "Tankstellennummer": "0FM54",
    "Name": "Aral TankStelle Berlin, Grenzallee 6",
    "Address": "Grenzallee 6",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12057,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3063227487,
    "24 hour?": "TRUE",
    "Latitude": 52.469042,
    "Longitude": 13.461875,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.469042,13.461875",
    "IsOpen24Hours": true,
    "id": "52.469042,13.461875"
  },
  {
    "ID": 3343,
    "Tankstellennummer": "0FK93",
    "Name": "Aral Tankstelle Berlin, Schoeneb.Str.Ecke Alboins",
    "Address": "Schöneberger Straße 19-20",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12103,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3075446987,
    "24 hour?": "TRUE",
    "Latitude": 52.470026,
    "Longitude": 13.371043,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.470026,13.371043",
    "IsOpen24Hours": true,
    "id": "52.470026,13.371043"
  },
  {
    "ID": 378231676,
    "Tankstellennummer": "TD039149",
    "Name": "BERLIN SCHOENEBERGER STR.",
    "Address": "SCHOENEBERGER STR. 17",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12103,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-43208844",
    "24 hour?": "FALSE",
    "Latitude": 52.4701,
    "Longitude": 13.372,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4701,13.372",
    "IsOpen24Hours": false,
    "id": "52.4701,13.372"
  },
  {
    "ID": 804,
    "Tankstellennummer": "0F004",
    "Name": "Aral Tankstelle Berlin, Hauptstr. 86",
    "Address": "Hauptstraße 86",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12159,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 308515286,
    "24 hour?": "TRUE",
    "Latitude": 52.474113,
    "Longitude": 13.338869,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.474113,13.338869",
    "IsOpen24Hours": true,
    "id": "52.474113,13.338869"
  },
  {
    "ID": 1526,
    "Tankstellennummer": "0F575",
    "Name": "Aral Tankstelle Langenhagen, Wagenzeller Str. 14",
    "Address": "Wagenzeller Straße 14",
    "Address__1": "",
    "City": "Langenhagen",
    "Postcode": 30855,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 511778059,
    "24 hour?": "FALSE",
    "Latitude": 52.474811,
    "Longitude": 9.734629,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.474811,9.734629",
    "IsOpen24Hours": false,
    "id": "52.474811,9.734629"
  },
  {
    "ID": 1136263343,
    "Tankstellennummer": "TD037226",
    "Name": "GIFHORN CALBERLAHER DAMM 54",
    "Address": "CALBERLAHER DAMM 54",
    "Address__1": "",
    "City": "GIFHORN",
    "Postcode": 38518,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05371-12646",
    "24 hour?": "FALSE",
    "Latitude": 52.4754,
    "Longitude": 10.5564,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4754,10.5564",
    "IsOpen24Hours": false,
    "id": "52.4754,10.5564"
  },
  {
    "ID": 3237,
    "Tankstellennummer": "0FK15",
    "Name": "Aral Tankstelle Berlin, Hohenzollerndamm 97",
    "Address": "Hohenzollerndamm 97",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 14199,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 308256179,
    "24 hour?": "TRUE",
    "Latitude": 52.475887,
    "Longitude": 13.279162,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.475887,13.279162",
    "IsOpen24Hours": true,
    "id": "52.475887,13.279162"
  },
  {
    "ID": 832495474,
    "Tankstellennummer": "TD037218",
    "Name": "GIFHORN BRAUNSCHWEIGER STR. 45",
    "Address": "BRAUNSCHWEIGER STR. 45 - 47",
    "Address__1": "",
    "City": "GIFHORN",
    "Postcode": 38518,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05371-13613",
    "24 hour?": "FALSE",
    "Latitude": 52.4767,
    "Longitude": 10.5442,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4767,10.5442",
    "IsOpen24Hours": false,
    "id": "52.4767,10.5442"
  },
  {
    "ID": 661769343,
    "Tankstellennummer": "0F038",
    "Name": "Aral TankStelle Berlin, Sonnenallee 113",
    "Address": "Sonnenallee 113",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12045,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 306811313,
    "24 hour?": "TRUE",
    "Latitude": 52.481479,
    "Longitude": 13.44137,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.481479,13.44137",
    "IsOpen24Hours": true,
    "id": "52.481479,13.44137"
  },
  {
    "ID": 1818277053,
    "Tankstellennummer": "TD020297",
    "Name": "BERLIN BULGARISCHE STR. 9",
    "Address": "BULGARISCHE STR. 9",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12435,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-5337955",
    "24 hour?": "FALSE",
    "Latitude": 52.4838,
    "Longitude": 13.4787,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4838,13.4787",
    "IsOpen24Hours": false,
    "id": "52.4838,13.4787"
  },
  {
    "ID": 3236,
    "Tankstellennummer": "0FK14",
    "Name": "Aral Tankstelle Berlin, Blissestr. 21",
    "Address": "Blissestraße 21",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10713,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 308738323,
    "24 hour?": "TRUE",
    "Latitude": 52.484325,
    "Longitude": 13.320671,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.484325,13.320671",
    "IsOpen24Hours": true,
    "id": "52.484325,13.320671"
  },
  {
    "ID": 3231,
    "Tankstellennummer": "0FK10",
    "Name": "Aral Tankstelle Berlin, Dudenstr. 19",
    "Address": "Dudenstraße 19",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10965,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3078913831,
    "24 hour?": "TRUE",
    "Latitude": 52.484893,
    "Longitude": 13.381366,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.484893,13.381366",
    "IsOpen24Hours": true,
    "id": "52.484893,13.381366"
  },
  {
    "ID": 541036056,
    "Tankstellennummer": "0FY47",
    "Name": "GARDELEGEN-MIESTE",
    "Address": "Wilhelmstraße 65",
    "Address__1": "",
    "City": "Gardelegen-Mieste",
    "Postcode": 39649,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 39082737,
    "24 hour?": "FALSE",
    "Latitude": 52.4854527,
    "Longitude": 11.216424,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.4854527,11.216424",
    "IsOpen24Hours": false,
    "id": "52.4854527,11.216424"
  },
  {
    "ID": 1168,
    "Tankstellennummer": "0FF01",
    "Name": "Aral Autohof Neuenkirchen, Hörster Heide 2",
    "Address": "Hörster Heide 2",
    "Address__1": "",
    "City": "Neuenkirchen",
    "Postcode": 49434,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5495952150,
    "24 hour?": "TRUE",
    "Latitude": 52.491489,
    "Longitude": 8.078994,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.491489,8.078994",
    "IsOpen24Hours": true,
    "id": "52.491489,8.078994"
  },
  {
    "ID": 3238,
    "Tankstellennummer": "0FK16",
    "Name": "Aral Tankstelle Berlin, Westfaelische Str. 9-10",
    "Address": "Westfaelische Straße 9-10",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10709,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3086409379,
    "24 hour?": "TRUE",
    "Latitude": 52.492513,
    "Longitude": 13.309285,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.492513,13.309285",
    "IsOpen24Hours": true,
    "id": "52.492513,13.309285"
  },
  {
    "ID": 3239,
    "Tankstellennummer": "0FK17",
    "Name": "Aral Tankstelle Berlin, Kurfuerstendamm 128",
    "Address": "Kurfuerstendamm 128",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10711,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3089096972,
    "24 hour?": "TRUE",
    "Latitude": 52.495804,
    "Longitude": 13.287605,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.495804,13.287605",
    "IsOpen24Hours": true,
    "id": "52.495804,13.287605"
  },
  {
    "ID": 811,
    "Tankstellennummer": "0F043",
    "Name": "Aral Tankstelle Berlin, Vor dem Schlesischen Tor",
    "Address": "Vor dem Schlesischen Tor",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10997,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3061286590,
    "24 hour?": "TRUE",
    "Latitude": 52.496857,
    "Longitude": 13.449614,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.496857,13.449614",
    "IsOpen24Hours": true,
    "id": "52.496857,13.449614"
  },
  {
    "ID": 1575783288,
    "Tankstellennummer": "0FU28",
    "Name": "LINGEN",
    "Address": "Rheiner Str. 176 A",
    "Address__1": "",
    "City": "Lingen",
    "Postcode": 49809,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 59161047865,
    "24 hour?": "TRUE",
    "Latitude": 52.497175,
    "Longitude": 7.32897,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.497175,7.32897",
    "IsOpen24Hours": true,
    "id": "52.497175,7.32897"
  },
  {
    "ID": 3225,
    "Tankstellennummer": "0FK04",
    "Name": "Aral Tankstelle Berlin, Skalitzer Str. 26",
    "Address": "Skalitzer Straße 26",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10999,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3061702190,
    "24 hour?": "TRUE",
    "Latitude": 52.498703,
    "Longitude": 13.422538,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.498703,13.422538",
    "IsOpen24Hours": true,
    "id": "52.498703,13.422538"
  },
  {
    "ID": 2716,
    "Tankstellennummer": "0FE30",
    "Name": "Aral Autohof Vogelsdorf, Frankfurter Chaussee 68 /",
    "Address": "Frankfurter Chaussee 68 /",
    "Address__1": "",
    "City": "Vogelsdorf",
    "Postcode": 15370,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3343965601,
    "24 hour?": "TRUE",
    "Latitude": 52.499556,
    "Longitude": 13.743801,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.499556,13.743801",
    "IsOpen24Hours": true,
    "id": "52.499556,13.743801"
  },
  {
    "ID": 2120358528,
    "Tankstellennummer": "TD038133",
    "Name": "BERLIN TEMPELHOFER UFER 33-35",
    "Address": "TEMPELHOFER UFER 33-35",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 10963,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-2621259",
    "24 hour?": "FALSE",
    "Latitude": 52.5005,
    "Longitude": 13.3767,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5005,13.3767",
    "IsOpen24Hours": false,
    "id": "52.5005,13.3767"
  },
  {
    "ID": 517885192,
    "Tankstellennummer": "0FX57",
    "Name": "WIETMARSCHEN",
    "Address": "A31",
    "Address__1": "",
    "City": "Wietmarschen",
    "Postcode": 49835,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5925905968,
    "24 hour?": "TRUE",
    "Latitude": 52.501797,
    "Longitude": 7.2012551,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.501797,7.2012551",
    "IsOpen24Hours": true,
    "id": "52.501797,7.2012551"
  },
  {
    "ID": 933437340,
    "Tankstellennummer": "0FU78",
    "Name": "BERLIN, BAT AVUS",
    "Address": "A115",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 14055,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 303024929,
    "24 hour?": "FALSE",
    "Latitude": 52.501839,
    "Longitude": 13.278117,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.501839,13.278117",
    "IsOpen24Hours": false,
    "id": "52.501839,13.278117"
  },
  {
    "ID": 794,
    "Tankstellennummer": "0F030",
    "Name": "Aral Tankstelle Berlin, Prinzenstraße 29",
    "Address": "Prinzenstraße 29",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10969,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 306149860,
    "24 hour?": "TRUE",
    "Latitude": 52.501894,
    "Longitude": 13.409839,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.501894,13.409839",
    "IsOpen24Hours": true,
    "id": "52.501894,13.409839"
  },
  {
    "ID": 916310315,
    "Tankstellennummer": 5079,
    "Name": "Agip Berlin-Hellersdorf",
    "Address": "Alt Mahlsdorf 94",
    "Address__1": "",
    "City": "Berlin-Hellersdorf",
    "Postcode": 12623,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 30566781",
    "24 hour?": "TRUE",
    "Latitude": 52.50414791,
    "Longitude": 13.61981328,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.50414791,13.61981328",
    "IsOpen24Hours": true,
    "id": "52.50414791,13.61981328"
  },
  {
    "ID": 1833054960,
    "Tankstellennummer": "TD020321",
    "Name": "BERLIN ALT MAHLSDORF 60",
    "Address": "ALT MAHLSDORF 60",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12623,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-5677297",
    "24 hour?": "FALSE",
    "Latitude": 52.5042,
    "Longitude": 13.6243,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5042,13.6243",
    "IsOpen24Hours": false,
    "id": "52.5042,13.6243"
  },
  {
    "ID": 130625990,
    "Tankstellennummer": "TD007864",
    "Name": "NEUSTADT RBG MECKLENHORSTER ST",
    "Address": "MECKLENHORSTER STR. 1",
    "Address__1": "",
    "City": "NEUSTADT RBG",
    "Postcode": 31535,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05032-2417",
    "24 hour?": "FALSE",
    "Latitude": 52.5046,
    "Longitude": 9.4692,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5046,9.4692",
    "IsOpen24Hours": false,
    "id": "52.5046,9.4692"
  },
  {
    "ID": 3224,
    "Tankstellennummer": "0FK03",
    "Name": "Aral Tankstelle Berlin, Heerstr. 136",
    "Address": "Heerstraße 136",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 14055,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3030810202,
    "24 hour?": "TRUE",
    "Latitude": 52.507079,
    "Longitude": 13.227781,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.507079,13.227781",
    "IsOpen24Hours": true,
    "id": "52.507079,13.227781"
  },
  {
    "ID": 3222,
    "Tankstellennummer": "0FK01",
    "Name": "Aral Tankstelle Berlin, Kaiser-Friedrich-Str.45 B",
    "Address": "Kaiser-Friedrich-Str. 45 B",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10627,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3032701495,
    "24 hour?": "FALSE",
    "Latitude": 52.50778,
    "Longitude": 13.301586,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.50778,13.301586",
    "IsOpen24Hours": false,
    "id": "52.50778,13.301586"
  },
  {
    "ID": 1182794635,
    "Tankstellennummer": "TD039487",
    "Name": "BERLIN HEERSTR.",
    "Address": "HEERSTR. 35-37",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 14055,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-50569275",
    "24 hour?": "FALSE",
    "Latitude": 52.5084,
    "Longitude": 13.2606,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5084,13.2606",
    "IsOpen24Hours": false,
    "id": "52.5084,13.2606"
  },
  {
    "ID": 485596764,
    "Tankstellennummer": 610090,
    "Name": "WESTFALEN, NEUENKIRCHEN",
    "Address": "Grosse Str. 22",
    "Address__1": "",
    "City": "Neuenkirchen",
    "Postcode": 49434,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05493/333",
    "24 hour?": "FALSE",
    "Latitude": 52.5096,
    "Longitude": 8.06854,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5096,8.06854",
    "IsOpen24Hours": false,
    "id": "52.5096,8.06854"
  },
  {
    "ID": 3223,
    "Tankstellennummer": "0FK02",
    "Name": "Aral Tankstelle Berlin, Messedamm 8-10",
    "Address": "Messedamm 6",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 14057,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 303025484,
    "24 hour?": "TRUE",
    "Latitude": 52.509603,
    "Longitude": 13.281325,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.509603,13.281325",
    "IsOpen24Hours": true,
    "id": "52.509603,13.281325"
  },
  {
    "ID": 3404,
    "Tankstellennummer": "0FL45",
    "Name": "Aral Tankstelle Berlin, Alt-Friedrichsfelde 64",
    "Address": "Alt-Friedrichsfelde 64",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12683,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 305133078,
    "24 hour?": "TRUE",
    "Latitude": 52.510314,
    "Longitude": 13.538487,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.510314,13.538487",
    "IsOpen24Hours": true,
    "id": "52.510314,13.538487"
  },
  {
    "ID": 3399,
    "Tankstellennummer": "0FL39",
    "Name": "Aral Tankstelle Berlin, Frankfurter Allee 214",
    "Address": "Frankfurter Allee 214",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10365,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 305578472,
    "24 hour?": "TRUE",
    "Latitude": 52.511439,
    "Longitude": 13.493861,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.511439,13.493861",
    "IsOpen24Hours": true,
    "id": "52.511439,13.493861"
  },
  {
    "ID": 3221,
    "Tankstellennummer": "0FJ99",
    "Name": "Aral Tankstelle Berlin, Bismarckstr. 1-2",
    "Address": "Bismarckstraße 2",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10625,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3034707002,
    "24 hour?": "TRUE",
    "Latitude": 52.512811,
    "Longitude": 13.319742,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.512811,13.319742",
    "IsOpen24Hours": true,
    "id": "52.512811,13.319742"
  },
  {
    "ID": 1107,
    "Tankstellennummer": "0F267",
    "Name": "Aral Tankstelle Fürstenau, Fröhlkingstraße 6",
    "Address": "Fröhlkingstraße 6",
    "Address__1": "",
    "City": "Fürstenau",
    "Postcode": 49584,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 59012771,
    "24 hour?": "FALSE",
    "Latitude": 52.513603,
    "Longitude": 7.677745,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.513603,7.677745",
    "IsOpen24Hours": false,
    "id": "52.513603,7.677745"
  },
  {
    "ID": 793,
    "Tankstellennummer": "0F029",
    "Name": "Aral Tankstelle Berlin, Reichsstr. 13/14",
    "Address": "Reichsstraße 13/14",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 14052,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 303042708,
    "24 hour?": "TRUE",
    "Latitude": 52.513646,
    "Longitude": 13.266036,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.513646,13.266036",
    "IsOpen24Hours": true,
    "id": "52.513646,13.266036"
  },
  {
    "ID": 2758,
    "Tankstellennummer": "0FE51",
    "Name": "Aral Tankstelle Berlin, Holzmarktstr. 12/14",
    "Address": "Holzmarktstraße 12/14",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10179,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3024720748,
    "24 hour?": "TRUE",
    "Latitude": 52.514151,
    "Longitude": 13.421487,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.514151,13.421487",
    "IsOpen24Hours": true,
    "id": "52.514151,13.421487"
  },
  {
    "ID": 201353580,
    "Tankstellennummer": 5088,
    "Name": "Agip Berlin",
    "Address": "Guelzower Str. 15A",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12619,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 30562931",
    "24 hour?": "TRUE",
    "Latitude": 52.51444203,
    "Longitude": 13.58190072,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.51444203,13.58190072",
    "IsOpen24Hours": true,
    "id": "52.51444203,13.58190072"
  },
  {
    "ID": 60780563,
    "Tankstellennummer": "TD040600",
    "Name": "BERLIN REICHSSTR.",
    "Address": "REICHSSTR. 86",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 14052,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-3046042",
    "24 hour?": "FALSE",
    "Latitude": 52.515,
    "Longitude": 13.2623,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.515,13.2623",
    "IsOpen24Hours": false,
    "id": "52.515,13.2623"
  },
  {
    "ID": 786,
    "Tankstellennummer": "0F021",
    "Name": "Aral Tankstelle Berlin, Heerstr. 325",
    "Address": "Heerstraße  325",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13593,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 303616676,
    "24 hour?": "TRUE",
    "Latitude": 52.516531,
    "Longitude": 13.177826,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.516531,13.177826",
    "IsOpen24Hours": true,
    "id": "52.516531,13.177826"
  },
  {
    "ID": 785133606,
    "Tankstellennummer": "TD037713",
    "Name": "BERLIN HEERSTR. 324",
    "Address": "HEERSTR. 324",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 13593,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-30124300",
    "24 hour?": "FALSE",
    "Latitude": 52.5168,
    "Longitude": 13.1801,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5168,13.1801",
    "IsOpen24Hours": false,
    "id": "52.5168,13.1801"
  },
  {
    "ID": 864529012,
    "Tankstellennummer": 610093,
    "Name": "WESTFALEN, NEUENKIRCHEN",
    "Address": "Bieste 2",
    "Address__1": "",
    "City": "Neuenkirchen",
    "Postcode": 49434,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05493/99250",
    "24 hour?": "FALSE",
    "Latitude": 52.5169,
    "Longitude": 8.05103,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5169,8.05103",
    "IsOpen24Hours": false,
    "id": "52.5169,8.05103"
  },
  {
    "ID": 1561643438,
    "Tankstellennummer": "0FU22",
    "Name": "GARDELEGEN",
    "Address": "Magdeburger Landstraße 14 a",
    "Address__1": "",
    "City": "Gardelegen",
    "Postcode": 39638,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 39077799377,
    "24 hour?": "TRUE",
    "Latitude": 52.517836,
    "Longitude": 11.412474,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.517836,11.412474",
    "IsOpen24Hours": true,
    "id": "52.517836,11.412474"
  },
  {
    "ID": 928848513,
    "Tankstellennummer": "TD033969",
    "Name": "BERLIN RHINSTRASSE 52",
    "Address": "RHINSTRASSE 52",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12681,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-54376256",
    "24 hour?": "FALSE",
    "Latitude": 52.5184,
    "Longitude": 13.52,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5184,13.52",
    "IsOpen24Hours": false,
    "id": "52.5184,13.52"
  },
  {
    "ID": 1341990414,
    "Tankstellennummer": "TD038976",
    "Name": "DAMME LINDENSTR. 26",
    "Address": "LINDENSTR. 26",
    "Address__1": "",
    "City": "DAMME",
    "Postcode": 49401,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05491-9994544",
    "24 hour?": "FALSE",
    "Latitude": 52.5186,
    "Longitude": 8.1925,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5186,8.1925",
    "IsOpen24Hours": false,
    "id": "52.5186,8.1925"
  },
  {
    "ID": 568068695,
    "Tankstellennummer": "TD000288",
    "Name": "LINGEN FRERENER STR. 11",
    "Address": "FRERENER STR. 11",
    "Address__1": "",
    "City": "LINGEN",
    "Postcode": 49809,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0591-53415",
    "24 hour?": "FALSE",
    "Latitude": 52.5206,
    "Longitude": 7.3379,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5206,7.3379",
    "IsOpen24Hours": false,
    "id": "52.5206,7.3379"
  },
  {
    "ID": 798,
    "Tankstellennummer": "0F033",
    "Name": "Aral Tankstelle Berlin, Levetzowstr. 9",
    "Address": "Levetzowstraße 9",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10555,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 303914575,
    "24 hour?": "TRUE",
    "Latitude": 52.521082,
    "Longitude": 13.334004,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.521082,13.334004",
    "IsOpen24Hours": true,
    "id": "52.521082,13.334004"
  },
  {
    "ID": 1106,
    "Tankstellennummer": "0F266",
    "Name": "Aral Tankstelle Lingen, Georgstr. 48",
    "Address": "Georgstraße 48",
    "Address__1": "",
    "City": "Lingen",
    "Postcode": 49809,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5913800,
    "24 hour?": "FALSE",
    "Latitude": 52.522326,
    "Longitude": 7.334073,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.522326,7.334073",
    "IsOpen24Hours": false,
    "id": "52.522326,7.334073"
  },
  {
    "ID": 576979445,
    "Tankstellennummer": "TD028423",
    "Name": "PREMNITZ AN DER B 102 NR. 2",
    "Address": "AN DER B 102 NR. 2",
    "Address__1": "",
    "City": "PREMNITZ",
    "Postcode": 14727,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03386-280770",
    "24 hour?": "FALSE",
    "Latitude": 52.5247,
    "Longitude": 12.3582,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5247,12.3582",
    "IsOpen24Hours": false,
    "id": "52.5247,12.3582"
  },
  {
    "ID": 796,
    "Tankstellennummer": "0F032",
    "Name": "Aral Tankstelle Berlin, Rathenower Str. 8",
    "Address": "Rathenower Straße 8",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10559,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 303944041,
    "24 hour?": "TRUE",
    "Latitude": 52.525458,
    "Longitude": 13.356891,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.525458,13.356891",
    "IsOpen24Hours": true,
    "id": "52.525458,13.356891"
  },
  {
    "ID": 317165897,
    "Tankstellennummer": "TD027581",
    "Name": "GARDELEGEN VOR DEM SALZWEDELER",
    "Address": "VOR DEM SALZWEDELER TOR 17",
    "Address__1": "",
    "City": "GARDELEGEN",
    "Postcode": 39638,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03907-41241",
    "24 hour?": "FALSE",
    "Latitude": 52.526,
    "Longitude": 11.3831,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.526,11.3831",
    "IsOpen24Hours": false,
    "id": "52.526,11.3831"
  },
  {
    "ID": 2117332095,
    "Tankstellennummer": "TD020388",
    "Name": "BERLIN STORKOWER STR. 174",
    "Address": "STORKOWER STR. 174",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 10369,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-9720385",
    "24 hour?": "FALSE",
    "Latitude": 52.5274,
    "Longitude": 13.4578,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5274,13.4578",
    "IsOpen24Hours": false,
    "id": "52.5274,13.4578"
  },
  {
    "ID": 1340040052,
    "Tankstellennummer": "0FP92",
    "Name": "WIETMARSCHEN",
    "Address": "A31",
    "Address__1": "",
    "City": "Wietmarschen",
    "Postcode": 49835,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5925998264,
    "24 hour?": "TRUE",
    "Latitude": 52.528611,
    "Longitude": 7.196889,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.528611,7.196889",
    "IsOpen24Hours": true,
    "id": "52.528611,7.196889"
  },
  {
    "ID": 866315892,
    "Tankstellennummer": "TD020511",
    "Name": "BERLIN PRENZLAUER ALLEE 1-4",
    "Address": "PRENZLAUER ALLEE 1-4",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 10405,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-24354310",
    "24 hour?": "FALSE",
    "Latitude": 52.5289,
    "Longitude": 13.4181,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5289,13.4181",
    "IsOpen24Hours": false,
    "id": "52.5289,13.4181"
  },
  {
    "ID": 1296936592,
    "Tankstellennummer": "TD020222",
    "Name": "SEELOW DIEDERSDORFER STR. 2",
    "Address": "DIEDERSDORFER STR. 2",
    "Address__1": "",
    "City": "SEELOW",
    "Postcode": 15306,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03346-316",
    "24 hour?": "FALSE",
    "Latitude": 52.5299,
    "Longitude": 14.3705,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5299,14.3705",
    "IsOpen24Hours": false,
    "id": "52.5299,14.3705"
  },
  {
    "ID": 1455230076,
    "Tankstellennummer": "TD020487",
    "Name": "BERLIN MARGARETE-SOMMER-STR. 2",
    "Address": "MARGARETE-SOMMER-STR. 2",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 10407,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-42852514",
    "24 hour?": "FALSE",
    "Latitude": 52.5308,
    "Longitude": 13.4409,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5308,13.4409",
    "IsOpen24Hours": false,
    "id": "52.5308,13.4409"
  },
  {
    "ID": 3233,
    "Tankstellennummer": "0FK12",
    "Name": "Aral Tankstelle Berlin, Beusselstr. 55",
    "Address": "Beusselstraße 55",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 10553,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 303914404,
    "24 hour?": "TRUE",
    "Latitude": 52.530855,
    "Longitude": 13.328394,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.530855,13.328394",
    "IsOpen24Hours": true,
    "id": "52.530855,13.328394"
  },
  {
    "ID": 2213,
    "Tankstellennummer": "0FA54",
    "Name": "Aral Tankstelle Lingen, Meppener Str. 69",
    "Address": "Meppener Straße 69",
    "Address__1": "",
    "City": "Lingen",
    "Postcode": 49808,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 59163082,
    "24 hour?": "TRUE",
    "Latitude": 52.531377,
    "Longitude": 7.310046,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.531377,7.310046",
    "IsOpen24Hours": true,
    "id": "52.531377,7.310046"
  },
  {
    "ID": 2717,
    "Tankstellennummer": "0FE31",
    "Name": "Aral Tankstelle Berlin, Märkische Allee 155",
    "Address": "Märkische Allee 155",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12681,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3054397011,
    "24 hour?": "FALSE",
    "Latitude": 52.532026,
    "Longitude": 13.536305,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.532026,13.536305",
    "IsOpen24Hours": false,
    "id": "52.532026,13.536305"
  },
  {
    "ID": 847248726,
    "Tankstellennummer": "TD037952",
    "Name": "DALLGOW ARTILLERIEPARK 5",
    "Address": "ARTILLERIEPARK 5",
    "Address__1": "",
    "City": "DALLGOW",
    "Postcode": 14624,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03322-2137842/43",
    "24 hour?": "FALSE",
    "Latitude": 52.5335,
    "Longitude": 13.0599,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5335,13.0599",
    "IsOpen24Hours": false,
    "id": "52.5335,13.0599"
  },
  {
    "ID": 801186524,
    "Tankstellennummer": "TD034157",
    "Name": "BERLIN STORKOWER STR. 116",
    "Address": "STORKOWER STR. 116",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 10407,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-4217562",
    "24 hour?": "FALSE",
    "Latitude": 52.5353,
    "Longitude": 13.4507,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5353,13.4507",
    "IsOpen24Hours": false,
    "id": "52.5353,13.4507"
  },
  {
    "ID": 474850674,
    "Tankstellennummer": "TD034165",
    "Name": "BERLIN LANDSBERGER ALLEE 376",
    "Address": "LANDSBERGER ALLEE 376",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12681,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-5437728",
    "24 hour?": "FALSE",
    "Latitude": 52.5354,
    "Longitude": 13.5228,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5354,13.5228",
    "IsOpen24Hours": false,
    "id": "52.5354,13.5228"
  },
  {
    "ID": 1027711104,
    "Tankstellennummer": "TD020271",
    "Name": "BERLIN RHINSTRASSE 175",
    "Address": "RHINSTRASSE 175",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 13053,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-9824536",
    "24 hour?": "FALSE",
    "Latitude": 52.5367,
    "Longitude": 13.5154,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5367,13.5154",
    "IsOpen24Hours": false,
    "id": "52.5367,13.5154"
  },
  {
    "ID": 1398719360,
    "Tankstellennummer": "TD037895",
    "Name": "BERLIN CHAUSSEESTR. 61",
    "Address": "CHAUSSEESTR. 61",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 10115,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-25762994",
    "24 hour?": "FALSE",
    "Latitude": 52.5372,
    "Longitude": 13.3754,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5372,13.3754",
    "IsOpen24Hours": false,
    "id": "52.5372,13.3754"
  },
  {
    "ID": 1086741965,
    "Tankstellennummer": 7013,
    "Name": "Agip Berlin",
    "Address": "Westhafenstrasse 1",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13353,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 30395361",
    "24 hour?": "FALSE",
    "Latitude": 52.53828412,
    "Longitude": 13.34408673,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.53828412,13.34408673",
    "IsOpen24Hours": false,
    "id": "52.53828412,13.34408673"
  },
  {
    "ID": 2714,
    "Tankstellennummer": "0FE27",
    "Name": "Aral Tankstelle Wustermark, Alter Spandauer Weg 5",
    "Address": "Alter Spandauer Weg 5",
    "Address__1": "",
    "City": "Wustermark",
    "Postcode": 14641,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3323488174,
    "24 hour?": "TRUE",
    "Latitude": 52.540085,
    "Longitude": 12.977601,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.540085,12.977601",
    "IsOpen24Hours": true,
    "id": "52.540085,12.977601"
  },
  {
    "ID": 801,
    "Tankstellennummer": "0F035",
    "Name": "Aral Tankstelle Berlin, Brunnenstr. 119",
    "Address": "Brunnenstraße 119",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13355,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3046404481,
    "24 hour?": "TRUE",
    "Latitude": 52.540785,
    "Longitude": 13.393457,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.540785,13.393457",
    "IsOpen24Hours": true,
    "id": "52.540785,13.393457"
  },
  {
    "ID": 1920729176,
    "Tankstellennummer": "0FT40",
    "Name": "WATHLINGEN",
    "Address": "Nienhagener Straße 13",
    "Address__1": "",
    "City": "Wathlingen",
    "Postcode": 29339,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5144681,
    "24 hour?": "FALSE",
    "Latitude": 52.540803,
    "Longitude": 10.141519,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.540803,10.141519",
    "IsOpen24Hours": false,
    "id": "52.540803,10.141519"
  },
  {
    "ID": 3445,
    "Tankstellennummer": "0FL74",
    "Name": "Aral Tankstelle Wedemark, Kaltenweider Straße 10",
    "Address": "Kaltenweider Straße 10",
    "Address__1": "",
    "City": "Wedemark",
    "Postcode": 30900,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51303554,
    "24 hour?": "FALSE",
    "Latitude": 52.542359,
    "Longitude": 9.727444,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.542359,9.727444",
    "IsOpen24Hours": false,
    "id": "52.542359,9.727444"
  },
  {
    "ID": 795,
    "Tankstellennummer": "0F031",
    "Name": "Aral Tankstelle Berlin, Falkenseer Damm 16",
    "Address": "Falkenseer Damm 16",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13585,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 303333811,
    "24 hour?": "TRUE",
    "Latitude": 52.542746,
    "Longitude": 13.199304,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.542746,13.199304",
    "IsOpen24Hours": true,
    "id": "52.542746,13.199304"
  },
  {
    "ID": 1278049686,
    "Tankstellennummer": "0FY34",
    "Name": "NEUENHAGEN, BAT SEEBERG WEST",
    "Address": "A10",
    "Address__1": "",
    "City": "Neuenhagen",
    "Postcode": 15366,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3342209225,
    "24 hour?": "TRUE",
    "Latitude": 52.542948,
    "Longitude": 13.689905,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.542948,13.689905",
    "IsOpen24Hours": true,
    "id": "52.542948,13.689905"
  },
  {
    "ID": 803,
    "Tankstellennummer": "0F037",
    "Name": "Aral Tankstelle Berlin, Gartenfelder Str. 71",
    "Address": "Gartenfelder Straße 71",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13599,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 303343935,
    "24 hour?": "TRUE",
    "Latitude": 52.543568,
    "Longitude": 13.243265,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.543568,13.243265",
    "IsOpen24Hours": true,
    "id": "52.543568,13.243265"
  },
  {
    "ID": 2737,
    "Tankstellennummer": "0FE84",
    "Name": "Aral Tankstelle Hönow, Altlandsberger Chaussee",
    "Address": "Altlandsberger Chaussee27",
    "Address__1": "",
    "City": "Hoppegarten",
    "Postcode": 15366,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3342423763,
    "24 hour?": "TRUE",
    "Latitude": 52.544051,
    "Longitude": 13.64699,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.544051,13.64699",
    "IsOpen24Hours": true,
    "id": "52.544051,13.64699"
  },
  {
    "ID": 811646997,
    "Tankstellennummer": "TD035196",
    "Name": "BERLIN LANDSBERGER ALLEE 526",
    "Address": "LANDSBERGER ALLEE 526",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12681,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-54376273/74",
    "24 hour?": "FALSE",
    "Latitude": 52.5449,
    "Longitude": 13.569,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5449,13.569",
    "IsOpen24Hours": false,
    "id": "52.5449,13.569"
  },
  {
    "ID": 789,
    "Tankstellennummer": "0F023",
    "Name": "Aral Tankstelle Berlin, Seestr. 18/19",
    "Address": "Seestraße 18/19",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13353,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 304536026,
    "24 hour?": "TRUE",
    "Latitude": 52.546403,
    "Longitude": 13.345187,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.546403,13.345187",
    "IsOpen24Hours": true,
    "id": "52.546403,13.345187"
  },
  {
    "ID": 1651122461,
    "Tankstellennummer": "TD020529",
    "Name": "BERLIN I.-GANDHI-STR. 106-109",
    "Address": "I.-GANDHI-STR. 106-109",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 13053,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-96064977",
    "24 hour?": "FALSE",
    "Latitude": 52.5475,
    "Longitude": 13.4681,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5475,13.4681",
    "IsOpen24Hours": false,
    "id": "52.5475,13.4681"
  },
  {
    "ID": 3386,
    "Tankstellennummer": "0FL27",
    "Name": "Aral Tankstelle Berlin, Märkische Allee 248",
    "Address": "Märkische Allee 248",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 12679,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 309312140,
    "24 hour?": "FALSE",
    "Latitude": 52.553732,
    "Longitude": 13.551587,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.553732,13.551587",
    "IsOpen24Hours": false,
    "id": "52.553732,13.551587"
  },
  {
    "ID": 565984933,
    "Tankstellennummer": "TD039313",
    "Name": "BERLIN STREITSTR. 84",
    "Address": "STREITSTR. 84",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 13587,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030/74782807",
    "24 hour?": "FALSE",
    "Latitude": 52.5542,
    "Longitude": 13.2074,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5542,13.2074",
    "IsOpen24Hours": false,
    "id": "52.5542,13.2074"
  },
  {
    "ID": 791270215,
    "Tankstellennummer": "TD007476",
    "Name": "BERLIN BITTERFELDER STR. 29",
    "Address": "BITTERFELDER STR. 29",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 12681,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-9309698",
    "24 hour?": "FALSE",
    "Latitude": 52.5564,
    "Longitude": 13.5283,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5564,13.5283",
    "IsOpen24Hours": false,
    "id": "52.5564,13.5283"
  },
  {
    "ID": 1383841874,
    "Tankstellennummer": "TD035287",
    "Name": "ALTLANDSBERG HOENOWER CHAUSSEE",
    "Address": "HOENOWER CHAUSSEE 1 A",
    "Address__1": "",
    "City": "ALTLANDSBERG",
    "Postcode": 15345,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033438-61811",
    "24 hour?": "FALSE",
    "Latitude": 52.5566,
    "Longitude": 13.7142,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5566,13.7142",
    "IsOpen24Hours": false,
    "id": "52.5566,13.7142"
  },
  {
    "ID": 1929325739,
    "Tankstellennummer": "TD020339",
    "Name": "BERLIN TINO-SCHWIERZINA-STR.",
    "Address": "TINO-SCHWIERZINA-STR.37",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 13089,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-4725049",
    "24 hour?": "FALSE",
    "Latitude": 52.5584,
    "Longitude": 13.4328,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5584,13.4328",
    "IsOpen24Hours": false,
    "id": "52.5584,13.4328"
  },
  {
    "ID": 303271079,
    "Tankstellennummer": "TD020081",
    "Name": "STRAUSBERG BERLINER STR. 69",
    "Address": "BERLINER STR. 69",
    "Address__1": "",
    "City": "STRAUSBERG",
    "Postcode": 15344,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03341-22475",
    "24 hour?": "FALSE",
    "Latitude": 52.5611,
    "Longitude": 13.8662,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5611,13.8662",
    "IsOpen24Hours": false,
    "id": "52.5611,13.8662"
  },
  {
    "ID": 2705,
    "Tankstellennummer": "0FE19",
    "Name": "Aral Tankstelle Falkensee, Finkenkruger Str. 57",
    "Address": "FINKENKRUGER STRAßE 57",
    "Address__1": "",
    "City": "FALKENSEE",
    "Postcode": 14612,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3322240134,
    "24 hour?": "FALSE",
    "Latitude": 52.562697,
    "Longitude": 13.074424,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.562697,13.074424",
    "IsOpen24Hours": false,
    "id": "52.562697,13.074424"
  },
  {
    "ID": 807,
    "Tankstellennummer": "0F039",
    "Name": "Aral Tankstelle Berlin, Scharnweber Str. 12-13",
    "Address": "Scharnweberstraße 12-13",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13405,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 304964005,
    "24 hour?": "TRUE",
    "Latitude": 52.563375,
    "Longitude": 13.329887,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.563375,13.329887",
    "IsOpen24Hours": true,
    "id": "52.563375,13.329887"
  },
  {
    "ID": 2696,
    "Tankstellennummer": "0FE13",
    "Name": "Aral Tankstelle Berlin, Mühlenstraße 26",
    "Address": "Mühlenstraße 26",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13187,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3047302020,
    "24 hour?": "TRUE",
    "Latitude": 52.563835,
    "Longitude": 13.408001,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.563835,13.408001",
    "IsOpen24Hours": true,
    "id": "52.563835,13.408001"
  },
  {
    "ID": 30225248,
    "Tankstellennummer": "TD020230",
    "Name": "BERLIN RIBNITZER STR. 42",
    "Address": "RIBNITZER STR. 42",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 13051,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-9294032",
    "24 hour?": "FALSE",
    "Latitude": 52.5656,
    "Longitude": 13.4918,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5656,13.4918",
    "IsOpen24Hours": false,
    "id": "52.5656,13.4918"
  },
  {
    "ID": 788,
    "Tankstellennummer": "0F022",
    "Name": "Aral Tankstelle Berlin, Residenzstr. 29",
    "Address": "Residenzstraße 29",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13409,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 304961035,
    "24 hour?": "FALSE",
    "Latitude": 52.568315,
    "Longitude": 13.361864,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.568315,13.361864",
    "IsOpen24Hours": false,
    "id": "52.568315,13.361864"
  },
  {
    "ID": 2513,
    "Tankstellennummer": "0FC54",
    "Name": "Aral Tankstelle Strausberg, Hohensteiner Chaussee",
    "Address": "Hohensteiner Chaussee",
    "Address__1": "",
    "City": "Strausberg",
    "Postcode": 15344,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 334122321,
    "24 hour?": "FALSE",
    "Latitude": 52.571617,
    "Longitude": 13.902983,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.571617,13.902983",
    "IsOpen24Hours": false,
    "id": "52.571617,13.902983"
  },
  {
    "ID": 226013553,
    "Tankstellennummer": 5058,
    "Name": "Agip Berlin-Marzahn",
    "Address": "Ahrensfelder Chaussee 152",
    "Address__1": "",
    "City": "Berlin-Marzahn",
    "Postcode": 12689,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 30937439",
    "24 hour?": "TRUE",
    "Latitude": 52.57228787,
    "Longitude": 13.56490688,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.57228787,13.56490688",
    "IsOpen24Hours": true,
    "id": "52.57228787,13.56490688"
  },
  {
    "ID": 743508891,
    "Tankstellennummer": "TD038364",
    "Name": "BERLIN EICHBORNDAMM 91",
    "Address": "EICHBORNDAMM 91",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 13403,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-41703023",
    "24 hour?": "FALSE",
    "Latitude": 52.5764,
    "Longitude": 13.3145,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5764,13.3145",
    "IsOpen24Hours": false,
    "id": "52.5764,13.3145"
  },
  {
    "ID": 1519,
    "Tankstellennummer": "0F570",
    "Name": "Aral Tankstelle Wedemark, Robert-Koch-Str. 2",
    "Address": "Robert-Koch-Straße 2",
    "Address__1": "",
    "City": "Wedemark",
    "Postcode": 30900,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51305845432,
    "24 hour?": "FALSE",
    "Latitude": 52.577071,
    "Longitude": 9.728123,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.577071,9.728123",
    "IsOpen24Hours": false,
    "id": "52.577071,9.728123"
  },
  {
    "ID": 859648896,
    "Tankstellennummer": "TD007302",
    "Name": "AHRENSFELDE DORFSTR. 37A",
    "Address": "DORFSTR.37a",
    "Address__1": "",
    "City": "AHRENSFELDE",
    "Postcode": 16356,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-93497825",
    "24 hour?": "FALSE",
    "Latitude": 52.5775,
    "Longitude": 13.5847,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5775,13.5847",
    "IsOpen24Hours": false,
    "id": "52.5775,13.5847"
  },
  {
    "ID": 1715605417,
    "Tankstellennummer": "0F041",
    "Name": "Aral TankStelle Berlin, Roedernallee 179",
    "Address": "Roedernallee 179",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13407,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3040910920,
    "24 hour?": "TRUE",
    "Latitude": 52.579129,
    "Longitude": 13.346392,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.579129,13.346392",
    "IsOpen24Hours": true,
    "id": "52.579129,13.346392"
  },
  {
    "ID": 3227,
    "Tankstellennummer": "0FK06",
    "Name": "Aral Tankstelle Berlin, Berliner Str. 67",
    "Address": "Berliner Straße 67",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13507,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3043551049,
    "24 hour?": "TRUE",
    "Latitude": 52.581386,
    "Longitude": 13.291377,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.581386,13.291377",
    "IsOpen24Hours": true,
    "id": "52.581386,13.291377"
  },
  {
    "ID": 1835004745,
    "Tankstellennummer": "TD041665",
    "Name": "LINSBURG MEINKINGSBURG 6A",
    "Address": "MEINKINGSBURG 6a",
    "Address__1": "",
    "City": "LINSBURG",
    "Postcode": 31636,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05027-776",
    "24 hour?": "FALSE",
    "Latitude": 52.5821,
    "Longitude": 9.2914,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5821,9.2914",
    "IsOpen24Hours": false,
    "id": "52.5821,9.2914"
  },
  {
    "ID": 2110591862,
    "Tankstellennummer": "TD020255",
    "Name": "BERLIN AM FEUCHTEN WINKEL 11",
    "Address": "AM FEUCHTEN WINKEL 11",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 13127,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-4742621",
    "24 hour?": "FALSE",
    "Latitude": 52.5821,
    "Longitude": 13.4324,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5821,13.4324",
    "IsOpen24Hours": false,
    "id": "52.5821,13.4324"
  },
  {
    "ID": 2633,
    "Tankstellennummer": "0FD57",
    "Name": "Aral Autohof Blumberg, McDonalds-Str. 1 / B158",
    "Address": "McDonalds-Straße 1",
    "Address__1": "",
    "City": "Blumberg",
    "Postcode": 16356,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 333945150,
    "24 hour?": "TRUE",
    "Latitude": 52.582424,
    "Longitude": 13.587225,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.582424,13.587225",
    "IsOpen24Hours": true,
    "id": "52.582424,13.587225"
  },
  {
    "ID": 1566818786,
    "Tankstellennummer": "TD020354",
    "Name": "BERLIN WACKENBERGSTR. 2-6",
    "Address": "WACKENBERGSTR. 2-6",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 13156,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-4765891",
    "24 hour?": "FALSE",
    "Latitude": 52.5829,
    "Longitude": 13.4043,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5829,13.4043",
    "IsOpen24Hours": false,
    "id": "52.5829,13.4043"
  },
  {
    "ID": 148998692,
    "Tankstellennummer": "TD000284",
    "Name": "HOLDORF GROSSE STR. 38",
    "Address": "GROSSE STR. 38",
    "Address__1": "",
    "City": "HOLDORF",
    "Postcode": 49451,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05494-8603",
    "24 hour?": "FALSE",
    "Latitude": 52.5832,
    "Longitude": 8.1253,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5832,8.1253",
    "IsOpen24Hours": false,
    "id": "52.5832,8.1253"
  },
  {
    "ID": 772695985,
    "Tankstellennummer": 5050,
    "Name": "Agip Ahrensfelde",
    "Address": "Blumberger Chaussee",
    "Address__1": "",
    "City": "Ahrensfelde",
    "Postcode": 16356,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 30937982",
    "24 hour?": "TRUE",
    "Latitude": 52.58415806,
    "Longitude": 13.58893345,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.58415806,13.58893345",
    "IsOpen24Hours": true,
    "id": "52.58415806,13.58893345"
  },
  {
    "ID": 810,
    "Tankstellennummer": "0F042",
    "Name": "Aral Tankstelle Berlin, Berliner Str. 24",
    "Address": "Berliner Straße 24",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13507,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3043400950,
    "24 hour?": "TRUE",
    "Latitude": 52.584372,
    "Longitude": 13.287744,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.584372,13.287744",
    "IsOpen24Hours": true,
    "id": "52.584372,13.287744"
  },
  {
    "ID": 2036534388,
    "Tankstellennummer": "TD036384",
    "Name": "BERLIN HABICHTSTR. 11-13",
    "Address": "HABICHTSTR. 11-13",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 13505,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": 3043673233,
    "24 hour?": "FALSE",
    "Latitude": 52.5859,
    "Longitude": 13.2319,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5859,13.2319",
    "IsOpen24Hours": false,
    "id": "52.5859,13.2319"
  },
  {
    "ID": 2666,
    "Tankstellennummer": "0FD85",
    "Name": "Aral Tankstelle Berlin, Pasewalker Str. 110",
    "Address": "Pasewalker Straße 110",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13127,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 304767080,
    "24 hour?": "TRUE",
    "Latitude": 52.586153,
    "Longitude": 13.429928,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.586153,13.429928",
    "IsOpen24Hours": true,
    "id": "52.586153,13.429928"
  },
  {
    "ID": 661778098,
    "Tankstellennummer": "TD027342",
    "Name": "STENDAL DAHLENER STR.",
    "Address": "DAHLENER STR. 16",
    "Address__1": "",
    "City": "STENDAL",
    "Postcode": 39576,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03931-414079",
    "24 hour?": "FALSE",
    "Latitude": 52.5863,
    "Longitude": 11.8387,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5863,11.8387",
    "IsOpen24Hours": false,
    "id": "52.5863,11.8387"
  },
  {
    "ID": 1865364050,
    "Tankstellennummer": "TD032557",
    "Name": "FALKENSEE NAUENER STR. 116",
    "Address": "NAUENER STR. 116",
    "Address__1": "",
    "City": "FALKENSEE",
    "Postcode": 14612,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03322-22701",
    "24 hour?": "FALSE",
    "Latitude": 52.5864,
    "Longitude": 13.065,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5864,13.065",
    "IsOpen24Hours": false,
    "id": "52.5864,13.065"
  },
  {
    "ID": 1617,
    "Tankstellennummer": "0F648",
    "Name": "Aral Tankstelle Steinfeld, Bahnhofstr. 48",
    "Address": "Bahnhofstraße 48",
    "Address__1": "",
    "City": "Steinfeld",
    "Postcode": 49439,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 54927608,
    "24 hour?": "FALSE",
    "Latitude": 52.590203,
    "Longitude": 8.203203,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.590203,8.203203",
    "IsOpen24Hours": false,
    "id": "52.590203,8.203203"
  },
  {
    "ID": 809269442,
    "Tankstellennummer": 7014,
    "Name": "Agip Berlin",
    "Address": "Dannenwalder Weg 69",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13439,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 30416520",
    "24 hour?": "FALSE",
    "Latitude": 52.5916907,
    "Longitude": 13.34644097,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5916907,13.34644097",
    "IsOpen24Hours": false,
    "id": "52.5916907,13.34644097"
  },
  {
    "ID": 329633532,
    "Tankstellennummer": "TD035311",
    "Name": "RATHENOW MILOWER LANDSTR.35B",
    "Address": "MILOWER LANDSTR.35b",
    "Address__1": "",
    "City": "RATHENOW",
    "Postcode": 14712,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03385-511431",
    "24 hour?": "FALSE",
    "Latitude": 52.594,
    "Longitude": 12.3384,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.594,12.3384",
    "IsOpen24Hours": false,
    "id": "52.594,12.3384"
  },
  {
    "ID": 2638,
    "Tankstellennummer": "0FD62",
    "Name": "Aral Tankstelle Stendal, Röxer Str. 16",
    "Address": "Röxer Straße 16",
    "Address__1": "",
    "City": "Stendal",
    "Postcode": 39576,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3931715620,
    "24 hour?": "FALSE",
    "Latitude": 52.594595,
    "Longitude": 11.842558,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.594595,11.842558",
    "IsOpen24Hours": false,
    "id": "52.594595,11.842558"
  },
  {
    "ID": 2005927262,
    "Tankstellennummer": 5116,
    "Name": "Agip Berlin",
    "Address": "Dietzgenstr. 127",
    "Address__1": "",
    "City": "Berlin",
    "Postcode": 13158,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 30477504",
    "24 hour?": "TRUE",
    "Latitude": 52.59468387,
    "Longitude": 13.40291895,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.59468387,13.40291895",
    "IsOpen24Hours": true,
    "id": "52.59468387,13.40291895"
  },
  {
    "ID": 941884081,
    "Tankstellennummer": "TD028167",
    "Name": "NAUEN BERLINER STR.",
    "Address": "BERLINER STR. 123",
    "Address__1": "",
    "City": "NAUEN",
    "Postcode": 14641,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03321-455369",
    "24 hour?": "FALSE",
    "Latitude": 52.5957,
    "Longitude": 12.8871,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5957,12.8871",
    "IsOpen24Hours": false,
    "id": "52.5957,12.8871"
  },
  {
    "ID": 1114524725,
    "Tankstellennummer": "TD038695",
    "Name": "HOLDORF ZUM HANSA-CENTER 2",
    "Address": "ZUM HANSA-CENTER 2",
    "Address__1": "",
    "City": "HOLDORF",
    "Postcode": 49451,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05494-9159243",
    "24 hour?": "FALSE",
    "Latitude": 52.5974,
    "Longitude": 8.118,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5974,8.118",
    "IsOpen24Hours": false,
    "id": "52.5974,8.118"
  },
  {
    "ID": 209642925,
    "Tankstellennummer": "TD029132",
    "Name": "RATHENOW GENTHINER STR. 59",
    "Address": "GENTHINER STR. 59",
    "Address__1": "",
    "City": "RATHENOW",
    "Postcode": 14712,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03385-512175",
    "24 hour?": "FALSE",
    "Latitude": 52.5994,
    "Longitude": 12.2971,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.5994,12.2971",
    "IsOpen24Hours": false,
    "id": "52.5994,12.2971"
  },
  {
    "ID": 2632,
    "Tankstellennummer": "0FD59",
    "Name": "Aral Tankstelle Rathenow, Genthiner Str. 65",
    "Address": "Genthiner Straße 65 a",
    "Address__1": "",
    "City": "Rathenow",
    "Postcode": 14712,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3385516278,
    "24 hour?": "FALSE",
    "Latitude": 52.599503,
    "Longitude": 12.29926,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.599503,12.29926",
    "IsOpen24Hours": false,
    "id": "52.599503,12.29926"
  },
  {
    "ID": 1144,
    "Tankstellennummer": "0F278",
    "Name": "Aral Tankstelle Celle, Braunschweig Heerstr. 79",
    "Address": "Braunschw. Heerstraße 79",
    "Address__1": "",
    "City": "Celle",
    "Postcode": 29227,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5141880723,
    "24 hour?": "TRUE",
    "Latitude": 52.600635,
    "Longitude": 10.107382,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.600635,10.107382",
    "IsOpen24Hours": true,
    "id": "52.600635,10.107382"
  },
  {
    "ID": 1028805538,
    "Tankstellennummer": "TD029124",
    "Name": "RATHENOW BERLINER STR. 23",
    "Address": "BERLINER STR. 23",
    "Address__1": "",
    "City": "RATHENOW",
    "Postcode": 14712,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03385-5200351",
    "24 hour?": "FALSE",
    "Latitude": 52.6041,
    "Longitude": 12.3479,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.6041,12.3479",
    "IsOpen24Hours": false,
    "id": "52.6041,12.3479"
  },
  {
    "ID": 1543,
    "Tankstellennummer": "0F585",
    "Name": "Aral Tankstelle Celle, 39 FuhrbergerLandstr.",
    "Address": "Fuhrberger Landstraße 39",
    "Address__1": "",
    "City": "Celle",
    "Postcode": 29225,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 514141463,
    "24 hour?": "TRUE",
    "Latitude": 52.604707,
    "Longitude": 10.018757,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.604707,10.018757",
    "IsOpen24Hours": true,
    "id": "52.604707,10.018757"
  },
  {
    "ID": 573746231,
    "Tankstellennummer": 610476,
    "Name": "WESTFALEN, EMLICHHEIM",
    "Address": "Ringer Str. 10",
    "Address__1": "",
    "City": "Emlichheim",
    "Postcode": 49824,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05943/653",
    "24 hour?": "FALSE",
    "Latitude": 52.6069,
    "Longitude": 6.85482,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.6069,6.85482",
    "IsOpen24Hours": false,
    "id": "52.6069,6.85482"
  },
  {
    "ID": 2220,
    "Tankstellennummer": "0FA62",
    "Name": "Aral Tankstelle Emlichheim, Hauptstr.78",
    "Address": "Hauptstraße 78",
    "Address__1": "",
    "City": "Emlichheim",
    "Postcode": 49824,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 59431946,
    "24 hour?": "FALSE",
    "Latitude": 52.607429,
    "Longitude": 6.839073,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.607429,6.839073",
    "IsOpen24Hours": false,
    "id": "52.607429,6.839073"
  },
  {
    "ID": 2706,
    "Tankstellennummer": "0FE20",
    "Name": "Aral Tankstelle Nauen, Hamburger Str. 34",
    "Address": "Hamburger Straße 34",
    "Address__1": "",
    "City": "Nauen",
    "Postcode": 14641,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3321450373,
    "24 hour?": "TRUE",
    "Latitude": 52.607682,
    "Longitude": 12.867791,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.607682,12.867791",
    "IsOpen24Hours": true,
    "id": "52.607682,12.867791"
  },
  {
    "ID": 2718,
    "Tankstellennummer": "0FE32",
    "Name": "Aral Tankstelle Ahrensfelde, Bernauer Str./B2",
    "Address": "Spenglerstr. 2",
    "Address__1": "",
    "City": "Ahrensfelde",
    "Postcode": 16356,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3094113252,
    "24 hour?": "TRUE",
    "Latitude": 52.608108,
    "Longitude": 13.52888,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.608108,13.52888",
    "IsOpen24Hours": true,
    "id": "52.608108,13.52888"
  },
  {
    "ID": 1520,
    "Tankstellennummer": "0F569",
    "Name": "Aral Tankstelle Celle, Hannoversche Heerstr. 44",
    "Address": "Hann. Heerstraße 44",
    "Address__1": "",
    "City": "Celle",
    "Postcode": 29227,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 514124511,
    "24 hour?": "TRUE",
    "Latitude": 52.609279,
    "Longitude": 10.075801,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.609279,10.075801",
    "IsOpen24Hours": true,
    "id": "52.609279,10.075801"
  },
  {
    "ID": 563874471,
    "Tankstellennummer": 5076,
    "Name": "Agip Stendal",
    "Address": "Arneburger Strasse",
    "Address__1": "",
    "City": "Stendal",
    "Postcode": 39576,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 39317110",
    "24 hour?": "TRUE",
    "Latitude": 52.61214,
    "Longitude": 11.86306,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.61214,11.86306",
    "IsOpen24Hours": true,
    "id": "52.61214,11.86306"
  },
  {
    "ID": 2623,
    "Tankstellennummer": "0FD48",
    "Name": "Aral Tankstelle Stendal, Arneburger Str. 47/B 189",
    "Address": "Arneburger Straße 47/B 18",
    "Address__1": "",
    "City": "Stendal",
    "Postcode": 39576,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3931796822,
    "24 hour?": "FALSE",
    "Latitude": 52.618061,
    "Longitude": 11.868661,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.618061,11.868661",
    "IsOpen24Hours": false,
    "id": "52.618061,11.868661"
  },
  {
    "ID": 18962,
    "Tankstellennummer": "0FP29",
    "Name": "Tankstelle Diepholz, Ovelgönne 20",
    "Address": "Ovelgönne 20",
    "Address__1": "",
    "City": "Diepholz",
    "Postcode": 49356,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5441929980,
    "24 hour?": "TRUE",
    "Latitude": 52.618299,
    "Longitude": 8.365318,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.618299,8.365318",
    "IsOpen24Hours": true,
    "id": "52.618299,8.365318"
  },
  {
    "ID": 843101170,
    "Tankstellennummer": "TD027466",
    "Name": "KLOETZE BAHNHOFSTR.60",
    "Address": "BAHNHOFSTR.60",
    "Address__1": "",
    "City": "KLOETZE",
    "Postcode": 38486,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03909-3890",
    "24 hour?": "FALSE",
    "Latitude": 52.63,
    "Longitude": 11.1534,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.63,11.1534",
    "IsOpen24Hours": false,
    "id": "52.63,11.1534"
  },
  {
    "ID": 867086175,
    "Tankstellennummer": "TD040733",
    "Name": "HAMBUEHREN CELLER STR. 27",
    "Address": "CELLER STR. 27",
    "Address__1": "",
    "City": "HAMBUEHREN",
    "Postcode": 29313,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05084-6260",
    "24 hour?": "FALSE",
    "Latitude": 52.634,
    "Longitude": 9.9405,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.634,9.9405",
    "IsOpen24Hours": false,
    "id": "52.634,9.9405"
  },
  {
    "ID": 2856,
    "Tankstellennummer": "0FH32",
    "Name": "Aral Tankstelle Celle, Harburger Str. 74",
    "Address": "Harburger Straße 74",
    "Address__1": "",
    "City": "Celle",
    "Postcode": 29223,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5141930909,
    "24 hour?": "TRUE",
    "Latitude": 52.634177,
    "Longitude": 10.080998,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.634177,10.080998",
    "IsOpen24Hours": true,
    "id": "52.634177,10.080998"
  },
  {
    "ID": 2576,
    "Tankstellennummer": "0FD12",
    "Name": "Aral Tankstelle Werneuchen, Freienwalder Straße",
    "Address": "Freienwalder Straße",
    "Address__1": "",
    "City": "Werneuchen",
    "Postcode": 16356,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 333987407,
    "24 hour?": "TRUE",
    "Latitude": 52.635677,
    "Longitude": 13.744871,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.635677,13.744871",
    "IsOpen24Hours": true,
    "id": "52.635677,13.744871"
  },
  {
    "ID": 2700,
    "Tankstellennummer": "0FE16",
    "Name": "Aral Tankstelle Glienicke, Oranienburger Chaussee 34",
    "Address": "Oranienburger Chaussee 34",
    "Address__1": "",
    "City": "Glienicke",
    "Postcode": 16548,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3305677500,
    "24 hour?": "TRUE",
    "Latitude": 52.636284,
    "Longitude": 13.306894,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.636284,13.306894",
    "IsOpen24Hours": true,
    "id": "52.636284,13.306894"
  },
  {
    "ID": 40759066,
    "Tankstellennummer": "TD020347",
    "Name": "BERLIN ZEPERNICKER STR. 9-10",
    "Address": "ZEPERNICKER STR. 9-10",
    "Address__1": "",
    "City": "BERLIN",
    "Postcode": 13125,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "030-9497581",
    "24 hour?": "FALSE",
    "Latitude": 52.641,
    "Longitude": 13.5143,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.641,13.5143",
    "IsOpen24Hours": false,
    "id": "52.641,13.5143"
  },
  {
    "ID": 557402580,
    "Tankstellennummer": 5017,
    "Name": "Agip Hohen-Neuendorf",
    "Address": "Bat A 111 Stolper Heide",
    "Address__1": "",
    "City": "Hohen-Neuendorf",
    "Postcode": 16540,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 33024938",
    "24 hour?": "TRUE",
    "Latitude": 52.65138313,
    "Longitude": 13.24402717,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.65138313,13.24402717",
    "IsOpen24Hours": true,
    "id": "52.65138313,13.24402717"
  },
  {
    "ID": 757952524,
    "Tankstellennummer": "TD037333",
    "Name": "NIENBURG VERDENER LANDSTRASSE",
    "Address": "VERDENER LANDSTRASSE 48",
    "Address__1": "",
    "City": "NIENBURG",
    "Postcode": 31582,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05021-62788",
    "24 hour?": "FALSE",
    "Latitude": 52.6523,
    "Longitude": 9.218,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.6523,9.218",
    "IsOpen24Hours": false,
    "id": "52.6523,9.218"
  },
  {
    "ID": 512903795,
    "Tankstellennummer": "TD032714",
    "Name": "HENNIGSDORF VELTENER STR. 22",
    "Address": "VELTENER STR. 22",
    "Address__1": "",
    "City": "HENNIGSDORF",
    "Postcode": 16761,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03302-810824",
    "24 hour?": "FALSE",
    "Latitude": 52.6539,
    "Longitude": 13.2043,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.6539,13.2043",
    "IsOpen24Hours": false,
    "id": "52.6539,13.2043"
  },
  {
    "ID": 26541703,
    "Tankstellennummer": "TD027102",
    "Name": "KALBE WERNSTEDTER STR. 2",
    "Address": "WERNSTEDTER STR 2",
    "Address__1": "",
    "City": "KALBE",
    "Postcode": 39624,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039080-40538",
    "24 hour?": "FALSE",
    "Latitude": 52.6573,
    "Longitude": 11.3833,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.6573,11.3833",
    "IsOpen24Hours": false,
    "id": "52.6573,11.3833"
  },
  {
    "ID": 2267,
    "Tankstellennummer": "0FA97",
    "Name": "Aral Tankstelle Dinklage, Burgstrasse 89",
    "Address": "Burgstraße 89",
    "Address__1": "",
    "City": "Dinklage",
    "Postcode": 49413,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4443736,
    "24 hour?": "FALSE",
    "Latitude": 52.6602,
    "Longitude": 8.136245,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.6602,8.136245",
    "IsOpen24Hours": false,
    "id": "52.6602,8.136245"
  },
  {
    "ID": 763482548,
    "Tankstellennummer": "TD034967",
    "Name": "BERNAU SCHWANEBECKER CH. 29",
    "Address": "SCHWANEBECKER CHAUSSEE 29",
    "Address__1": "",
    "City": "BERNAU",
    "Postcode": 16321,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03338-5500",
    "24 hour?": "FALSE",
    "Latitude": 52.6681,
    "Longitude": 13.5823,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.6681,13.5823",
    "IsOpen24Hours": false,
    "id": "52.6681,13.5823"
  },
  {
    "ID": 2748,
    "Tankstellennummer": "0FG66",
    "Name": "Aral Tankstelle Mühlenbeck, Liebenwalder Straße 10 a",
    "Address": "Liebenwalder Straße 10 a",
    "Address__1": "",
    "City": "Mühlenbeck",
    "Postcode": 16567,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3305682856,
    "24 hour?": "FALSE",
    "Latitude": 52.669296,
    "Longitude": 13.377538,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.669296,13.377538",
    "IsOpen24Hours": false,
    "id": "52.669296,13.377538"
  },
  {
    "ID": 157339151,
    "Tankstellennummer": "0FK72",
    "Name": "Aral TankStelle Buchholz/Aller, An der Autobahn 1",
    "Address": "An der Autobahn 1",
    "Address__1": "",
    "City": "Buchholz/Aller",
    "Postcode": 29690,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 50718060,
    "24 hour?": "TRUE",
    "Latitude": 52.673809,
    "Longitude": 9.689094,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.673809,9.689094",
    "IsOpen24Hours": true,
    "id": "52.673809,9.689094"
  },
  {
    "ID": 495068588,
    "Tankstellennummer": "TD000260",
    "Name": "CELLE-SCHEUEN AM STELLHORN 6",
    "Address": "AM STELLHORN 6",
    "Address__1": "",
    "City": "CELLE-SCHEUEN",
    "Postcode": 29229,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05086-535",
    "24 hour?": "FALSE",
    "Latitude": 52.6753,
    "Longitude": 10.0758,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.6753,10.0758",
    "IsOpen24Hours": false,
    "id": "52.6753,10.0758"
  },
  {
    "ID": 66764038,
    "Tankstellennummer": "TD041657",
    "Name": "BUCHHOLZ SCHWARMSTEDTER STR. 1",
    "Address": "SCHWARMSTEDTER STR. 14",
    "Address__1": "",
    "City": "BUCHHOLZ",
    "Postcode": 29690,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05071-4380",
    "24 hour?": "FALSE",
    "Latitude": 52.6762,
    "Longitude": 9.6811,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.6762,9.6811",
    "IsOpen24Hours": false,
    "id": "52.6762,9.6811"
  },
  {
    "ID": 1548,
    "Tankstellennummer": "0F590",
    "Name": "Aral Tankstelle Winsen, 57 CellerStrasse",
    "Address": "Celler Straße 57",
    "Address__1": "",
    "City": "Winsen",
    "Postcode": 29308,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51438187,
    "24 hour?": "FALSE",
    "Latitude": 52.67645,
    "Longitude": 9.920086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.67645,9.920086",
    "IsOpen24Hours": false,
    "id": "52.67645,9.920086"
  },
  {
    "ID": 1610,
    "Tankstellennummer": "0F643",
    "Name": "Aral Tankstelle Sulingen, Friedrich-Tietjen-Str. 15",
    "Address": "Friedrich-Tietjen-Str. 15",
    "Address__1": "",
    "City": "Sulingen",
    "Postcode": 27232,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 427193190,
    "24 hour?": "TRUE",
    "Latitude": 52.68576,
    "Longitude": 8.78304,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.68576,8.78304",
    "IsOpen24Hours": true,
    "id": "52.68576,8.78304"
  },
  {
    "ID": 2754,
    "Tankstellennummer": "0FG32",
    "Name": "Aral Tankstelle Bernau, Werner-von-Siemens-Straße",
    "Address": "Werner-von-Siemens-Straße",
    "Address__1": "",
    "City": "Bernau",
    "Postcode": 16321,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3338758845,
    "24 hour?": "TRUE",
    "Latitude": 52.686121,
    "Longitude": 13.570194,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.686121,13.570194",
    "IsOpen24Hours": true,
    "id": "52.686121,13.570194"
  },
  {
    "ID": 3451,
    "Tankstellennummer": "0FL78",
    "Name": "Aral Tankstelle Meppen, Schullendamm 51",
    "Address": "Schullendamm 51",
    "Address__1": "",
    "City": "Meppen",
    "Postcode": 49716,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 593112459,
    "24 hour?": "FALSE",
    "Latitude": 52.691659,
    "Longitude": 7.27538,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.691659,7.27538",
    "IsOpen24Hours": false,
    "id": "52.691659,7.27538"
  },
  {
    "ID": 248545695,
    "Tankstellennummer": 610596,
    "Name": "WESTFALEN, MEPPEN",
    "Address": "Schullendamm 63",
    "Address__1": "",
    "City": "Meppen",
    "Postcode": 49716,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05931/8469123",
    "24 hour?": "FALSE",
    "Latitude": 52.6917,
    "Longitude": 7.27239,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.6917,7.27239",
    "IsOpen24Hours": false,
    "id": "52.6917,7.27239"
  },
  {
    "ID": 1534,
    "Tankstellennummer": "0F582",
    "Name": "Aral Autobahntankstelle Essel, Allertal West (A 7)",
    "Address": "A7",
    "Address__1": "",
    "City": "Essel",
    "Postcode": 29690,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 507196260,
    "24 hour?": "TRUE",
    "Latitude": 52.695823,
    "Longitude": 9.685461,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.695823,9.685461",
    "IsOpen24Hours": true,
    "id": "52.695823,9.685461"
  },
  {
    "ID": 1366052015,
    "Tankstellennummer": "TD007237",
    "Name": "BIRKENWERDER HAUPTSTR. 200",
    "Address": "HAUPTSTR. 200",
    "Address__1": "",
    "City": "BIRKENWERDER",
    "Postcode": 16547,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03303-501693",
    "24 hour?": "FALSE",
    "Latitude": 52.7029,
    "Longitude": 13.2726,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.7029,13.2726",
    "IsOpen24Hours": false,
    "id": "52.7029,13.2726"
  },
  {
    "ID": 1586290988,
    "Tankstellennummer": "TD150470",
    "Name": "OBERKRAEMER AUTOHOF EICHSTAEDT",
    "Address": "EICHSTAEDTER CHAUSSEE 4",
    "Address__1": "",
    "City": "OBERKRAEMER OT VEHLEFANZ",
    "Postcode": 16727,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03304-502308",
    "24 hour?": "FALSE",
    "Latitude": 52.7087,
    "Longitude": 13.107,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.7087,13.107",
    "IsOpen24Hours": false,
    "id": "52.7087,13.107"
  },
  {
    "ID": 1198105686,
    "Tankstellennummer": 610314,
    "Name": "WESTFALEN, BARNSTORF",
    "Address": "Schlingstr. 26",
    "Address__1": "",
    "City": "Barnstorf",
    "Postcode": 49406,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05442/2737",
    "24 hour?": "FALSE",
    "Latitude": 52.7113,
    "Longitude": 8.50936,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.7113,8.50936",
    "IsOpen24Hours": false,
    "id": "52.7113,8.50936"
  },
  {
    "ID": 913183584,
    "Tankstellennummer": 610311,
    "Name": "WESTFALEN, ESSEN/OLDB.",
    "Address": "Lange Str. 100",
    "Address__1": "",
    "City": "Essen / Oldenburg",
    "Postcode": 49632,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "05434/943416",
    "24 hour?": "FALSE",
    "Latitude": 52.7159,
    "Longitude": 7.93839,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.7159,7.93839",
    "IsOpen24Hours": false,
    "id": "52.7159,7.93839"
  },
  {
    "ID": 1138,
    "Tankstellennummer": "0F273",
    "Name": "Aral Tankstelle Vechta, Münsterstraße 63-67",
    "Address": "Münsterstraße 63-67",
    "Address__1": "",
    "City": "Vechta",
    "Postcode": 49377,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4441921437,
    "24 hour?": "TRUE",
    "Latitude": 52.718923,
    "Longitude": 8.287141,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.718923,8.287141",
    "IsOpen24Hours": true,
    "id": "52.718923,8.287141"
  },
  {
    "ID": 768279302,
    "Tankstellennummer": "TD028381",
    "Name": "FRIESACK BERLINER ALLEE 2A",
    "Address": "BERLINER ALLEE 2a",
    "Address__1": "",
    "City": "FRIESACK",
    "Postcode": 14662,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033235-1592",
    "24 hour?": "FALSE",
    "Latitude": 52.7234,
    "Longitude": 12.588,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.7234,12.588",
    "IsOpen24Hours": false,
    "id": "52.7234,12.588"
  },
  {
    "ID": 29446585,
    "Tankstellennummer": "TD020099",
    "Name": "WRIEZEN FREIENWALDER STR. 26",
    "Address": "FREIENWALDER STR. 26",
    "Address__1": "",
    "City": "WRIEZEN",
    "Postcode": 16269,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033456-3113",
    "24 hour?": "FALSE",
    "Latitude": 52.7253,
    "Longitude": 14.1269,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.7253,14.1269",
    "IsOpen24Hours": false,
    "id": "52.7253,14.1269"
  },
  {
    "ID": 3356,
    "Tankstellennummer": "0FL01",
    "Name": "Aral Tankstelle Oranienburg, Berliner Str. 197",
    "Address": "Berliner Straße 197",
    "Address__1": "",
    "City": "Oranienburg",
    "Postcode": 16515,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3301530720,
    "24 hour?": "FALSE",
    "Latitude": 52.728338,
    "Longitude": 13.246281,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.728338,13.246281",
    "IsOpen24Hours": false,
    "id": "52.728338,13.246281"
  },
  {
    "ID": 2269,
    "Tankstellennummer": "0FA99",
    "Name": "Aral Tankstelle Löningen, Bremer Strasse 20",
    "Address": "Bremer Straße 20",
    "Address__1": "",
    "City": "Löningen",
    "Postcode": 49624,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 54323228,
    "24 hour?": "FALSE",
    "Latitude": 52.738201,
    "Longitude": 7.76049,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.738201,7.76049",
    "IsOpen24Hours": false,
    "id": "52.738201,7.76049"
  },
  {
    "ID": 779284410,
    "Tankstellennummer": "TD036350",
    "Name": "ORANIENBURG SAARLANDSTR.",
    "Address": "SAARLANDSTR. 40-42",
    "Address__1": "",
    "City": "ORANIENBURG",
    "Postcode": 16515,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03301-3243",
    "24 hour?": "FALSE",
    "Latitude": 52.7383,
    "Longitude": 13.2491,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.7383,13.2491",
    "IsOpen24Hours": false,
    "id": "52.7383,13.2491"
  },
  {
    "ID": 2634,
    "Tankstellennummer": "0FD58",
    "Name": "Aral Tankstelle Wandlitz, B 109/Prenzlauer Chaussee",
    "Address": "Prenzlauer Chaussee 76",
    "Address__1": "",
    "City": "Wandlitz",
    "Postcode": 16348,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3339781748,
    "24 hour?": "TRUE",
    "Latitude": 52.743148,
    "Longitude": 13.45845,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.743148,13.45845",
    "IsOpen24Hours": true,
    "id": "52.743148,13.45845"
  },
  {
    "ID": 3372,
    "Tankstellennummer": "0FL14",
    "Name": "Aral Autobahntankstelle Linum, Linumer Bruch Süd (A 24)",
    "Address": "BAB24 Südseite",
    "Address__1": "",
    "City": "Linum",
    "Postcode": 16833,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3392250546,
    "24 hour?": "TRUE",
    "Latitude": 52.753324,
    "Longitude": 12.852629,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.753324,12.852629",
    "IsOpen24Hours": true,
    "id": "52.753324,12.852629"
  },
  {
    "ID": 3355,
    "Tankstellennummer": "0FK99",
    "Name": "Aral Autobahntankstelle Linum, Linumer Bruch Nord (A 24)",
    "Address": "BAB24 Nordseite",
    "Address__1": "",
    "City": "Linum",
    "Postcode": 16833,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3392250538,
    "24 hour?": "TRUE",
    "Latitude": 52.754541,
    "Longitude": 12.85539,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.754541,12.85539",
    "IsOpen24Hours": true,
    "id": "52.754541,12.85539"
  },
  {
    "ID": 515298125,
    "Tankstellennummer": "0FV46",
    "Name": "HAREN",
    "Address": "An der Autobahn 8",
    "Address__1": "",
    "City": "Haren",
    "Postcode": 49733,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 59358099050,
    "24 hour?": "TRUE",
    "Latitude": 52.755852,
    "Longitude": 7.16432,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.755852,7.16432",
    "IsOpen24Hours": true,
    "id": "52.755852,7.16432"
  },
  {
    "ID": 1675812003,
    "Tankstellennummer": "0FT79",
    "Name": "VECHTA, VARDELER WEG 1/B69",
    "Address": "Vardeler Weg 1/B69",
    "Address__1": "",
    "City": "Vechta",
    "Postcode": 49377,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 44418545212,
    "24 hour?": "TRUE",
    "Latitude": 52.75603,
    "Longitude": 8.274148,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.75603,8.274148",
    "IsOpen24Hours": true,
    "id": "52.75603,8.274148"
  },
  {
    "ID": 861963375,
    "Tankstellennummer": "0FM33",
    "Name": "HODENHAGEN",
    "Address": "Heerstraße 41",
    "Address__1": "",
    "City": "Hodenhagen",
    "Postcode": 29693,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5164682,
    "24 hour?": "FALSE",
    "Latitude": 52.762715,
    "Longitude": 9.583025,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.762715,9.583025",
    "IsOpen24Hours": false,
    "id": "52.762715,9.583025"
  },
  {
    "ID": 2568,
    "Tankstellennummer": "0FD03",
    "Name": "Aral Tankstelle Osterburg, Schilddorf 10",
    "Address": "Schilddorf 10",
    "Address__1": "",
    "City": "Osterburg",
    "Postcode": 39606,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 393783756,
    "24 hour?": "TRUE",
    "Latitude": 52.768133,
    "Longitude": 11.754884,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.768133,11.754884",
    "IsOpen24Hours": true,
    "id": "52.768133,11.754884"
  },
  {
    "ID": 249322308,
    "Tankstellennummer": "TD027359",
    "Name": "OSTERBURG BISMARKER STR.",
    "Address": "BISMARKER STR.82",
    "Address__1": "",
    "City": "OSTERBURG",
    "Postcode": 39606,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03937-82928",
    "24 hour?": "FALSE",
    "Latitude": 52.7872,
    "Longitude": 11.7426,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.7872,11.7426",
    "IsOpen24Hours": false,
    "id": "52.7872,11.7426"
  },
  {
    "ID": 508743215,
    "Tankstellennummer": "TD037291",
    "Name": "LASTRUP VLAEMISCHE STR. 40",
    "Address": "VLAEMISCHE STR. 40",
    "Address__1": "",
    "City": "LASTRUP",
    "Postcode": 49688,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04472-1450",
    "24 hour?": "FALSE",
    "Latitude": 52.7973,
    "Longitude": 7.8656,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.7973,7.8656",
    "IsOpen24Hours": false,
    "id": "52.7973,7.8656"
  },
  {
    "ID": 429368238,
    "Tankstellennummer": 610481,
    "Name": "WESTFALEN, TWISTRINGEN",
    "Address": "Bremer Str. 46",
    "Address__1": "",
    "City": "Twistringen",
    "Postcode": 27239,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "04243/602825",
    "24 hour?": "TRUE",
    "Latitude": 52.8045,
    "Longitude": 8.64704,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.8045,8.64704",
    "IsOpen24Hours": true,
    "id": "52.8045,8.64704"
  },
  {
    "ID": 2210,
    "Tankstellennummer": "0FA51",
    "Name": "Aral Tankstelle Hoya, Lange Straße 65",
    "Address": "Lange Straße 65",
    "Address__1": "",
    "City": "Hoya",
    "Postcode": 27318,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 42512800,
    "24 hour?": "FALSE",
    "Latitude": 52.804958,
    "Longitude": 9.135822,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.804958,9.135822",
    "IsOpen24Hours": false,
    "id": "52.804958,9.135822"
  },
  {
    "ID": 335446290,
    "Tankstellennummer": 5003,
    "Name": "Agip Fehrbellin",
    "Address": "Berliner Allee 14",
    "Address__1": "",
    "City": "Fehrbellin",
    "Postcode": 16833,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 33932724",
    "24 hour?": "FALSE",
    "Latitude": 52.80589145,
    "Longitude": 12.78574759,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.80589145,12.78574759",
    "IsOpen24Hours": false,
    "id": "52.80589145,12.78574759"
  },
  {
    "ID": 909338699,
    "Tankstellennummer": "TD027052",
    "Name": "HAVELBERG GENTHINER STR.",
    "Address": "GENTHINER STR.19",
    "Address__1": "",
    "City": "HAVELBERG",
    "Postcode": 39539,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039387-21341",
    "24 hour?": "FALSE",
    "Latitude": 52.8188,
    "Longitude": 12.0708,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.8188,12.0708",
    "IsOpen24Hours": false,
    "id": "52.8188,12.0708"
  },
  {
    "ID": 847387754,
    "Tankstellennummer": "TD038166",
    "Name": "CLOPPENBURG VAHRENER DAMM",
    "Address": "VAHRENER DAMM 10",
    "Address__1": "",
    "City": "CLOPPENBURG",
    "Postcode": 49661,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04471-7012924",
    "24 hour?": "FALSE",
    "Latitude": 52.8272,
    "Longitude": 8.0003,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.8272,8.0003",
    "IsOpen24Hours": false,
    "id": "52.8272,8.0003"
  },
  {
    "ID": 3373,
    "Tankstellennummer": "0FL15",
    "Name": "Aral Tankstelle Eberswalde-Finow, Eberswalder Str.",
    "Address": "Eberswalder Straße 37",
    "Address__1": "",
    "City": "Eberswalde-Finow",
    "Postcode": 16227,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3334352814,
    "24 hour?": "TRUE",
    "Latitude": 52.839057,
    "Longitude": 13.7546,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.839057,13.7546",
    "IsOpen24Hours": true,
    "id": "52.839057,13.7546"
  },
  {
    "ID": 2203,
    "Tankstellennummer": "0FA46",
    "Name": "Aral Tankstelle Sögel, Clemens-August-Str.52",
    "Address": "Schlossallee 6",
    "Address__1": "",
    "City": "Sögel",
    "Postcode": 49751,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 59521433,
    "24 hour?": "FALSE",
    "Latitude": 52.839785,
    "Longitude": 7.525826,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.839785,7.525826",
    "IsOpen24Hours": false,
    "id": "52.839785,7.525826"
  },
  {
    "ID": 2681,
    "Tankstellennummer": "0FE03",
    "Name": "Aral Tankstelle Salzwedel, Ernst-Thälmann Str.",
    "Address": "Ernst-Thälmann-Straße 2a",
    "Address__1": "",
    "City": "Salzwedel",
    "Postcode": 29410,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 390134000,
    "24 hour?": "TRUE",
    "Latitude": 52.848653,
    "Longitude": 11.16287,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.848653,11.16287",
    "IsOpen24Hours": true,
    "id": "52.848653,11.16287"
  },
  {
    "ID": 2515,
    "Tankstellennummer": "0FC56",
    "Name": "Aral Tankstelle Finowfurt, B 167",
    "Address": "An der B 167 Nr. 4",
    "Address__1": "",
    "City": "Finowfurt",
    "Postcode": 16244,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 33354510819,
    "24 hour?": "TRUE",
    "Latitude": 52.849858,
    "Longitude": 13.684941,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.849858,13.684941",
    "IsOpen24Hours": true,
    "id": "52.849858,13.684941"
  },
  {
    "ID": 1510,
    "Tankstellennummer": "0F560",
    "Name": "Aral Tankstelle Walsrode, Hannoversche Straße 41-43",
    "Address": "Hannoversche Straße 41-43",
    "Address__1": "",
    "City": "Walsrode",
    "Postcode": 29664,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51615971,
    "24 hour?": "TRUE",
    "Latitude": 52.854727,
    "Longitude": 9.596444,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.854727,9.596444",
    "IsOpen24Hours": true,
    "id": "52.854727,9.596444"
  },
  {
    "ID": 3472,
    "Tankstellennummer": "0FL91",
    "Name": "Aral Tankstelle Lathen, Soegeler Str. 7",
    "Address": "Soegeler Straße 7",
    "Address__1": "",
    "City": "Lathen",
    "Postcode": 49762,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 59331341,
    "24 hour?": "FALSE",
    "Latitude": 52.860882,
    "Longitude": 7.328503,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.860882,7.328503",
    "IsOpen24Hours": false,
    "id": "52.860882,7.328503"
  },
  {
    "ID": 1512,
    "Tankstellennummer": "0F562",
    "Name": "Aral Tankstelle Fallingbostel, Düshorner Strasse 2 D",
    "Address": "Düshorner Straße 2 D",
    "Address__1": "",
    "City": "Fallingbostel",
    "Postcode": 29683,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 51622183,
    "24 hour?": "FALSE",
    "Latitude": 52.864689,
    "Longitude": 9.690716,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.864689,9.690716",
    "IsOpen24Hours": false,
    "id": "52.864689,9.690716"
  },
  {
    "ID": 58479460,
    "Tankstellennummer": "TD020214",
    "Name": "SCHORFHEIDE-BUCKOWSEE BAB WEST",
    "Address": "BAB WEST/A 11",
    "Address__1": "",
    "City": "SCHORFHEIDE-BUCKOWSEE",
    "Postcode": 16244,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033363-46210",
    "24 hour?": "FALSE",
    "Latitude": 52.883,
    "Longitude": 13.7019,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.883,13.7019",
    "IsOpen24Hours": false,
    "id": "52.883,13.7019"
  },
  {
    "ID": 514638254,
    "Tankstellennummer": "TD032326",
    "Name": "SCHORFHEIDE-BUCKOWSEE BAB OST/",
    "Address": "BAB OST/A 11",
    "Address__1": "",
    "City": "SCHORFHEIDE-BUCKOWSEE",
    "Postcode": 16244,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033363-46204",
    "24 hour?": "FALSE",
    "Latitude": 52.8833,
    "Longitude": 13.7047,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.8833,13.7047",
    "IsOpen24Hours": false,
    "id": "52.8833,13.7047"
  },
  {
    "ID": 1905406061,
    "Tankstellennummer": "TD029165",
    "Name": "WUSTERHAUSEN/DOSSE AN DER KLEM",
    "Address": "AN DER KLEMPNITZ 2",
    "Address__1": "",
    "City": "WUSTERHAUSEN/DOSSE OT WUSTERHAUSEN",
    "Postcode": 16868,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033979-14515",
    "24 hour?": "FALSE",
    "Latitude": 52.8936,
    "Longitude": 12.4551,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.8936,12.4551",
    "IsOpen24Hours": false,
    "id": "52.8936,12.4551"
  },
  {
    "ID": 1838109025,
    "Tankstellennummer": "TD020131",
    "Name": "SCHORFHEIDE BERLINER STR. 31",
    "Address": "BERLINER STR. 31",
    "Address__1": "",
    "City": "SCHORFHEIDE",
    "Postcode": 16244,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033393-516",
    "24 hour?": "FALSE",
    "Latitude": 52.8982,
    "Longitude": 13.5374,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.8982,13.5374",
    "IsOpen24Hours": false,
    "id": "52.8982,13.5374"
  },
  {
    "ID": 1965244074,
    "Tankstellennummer": "TD040147",
    "Name": "NEURUPPIN BECHLINER CHAUSSEE",
    "Address": "BECHLINER CHAUSSEE 18A",
    "Address__1": "",
    "City": "NEURUPPIN",
    "Postcode": 16816,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03391-3469813",
    "24 hour?": "FALSE",
    "Latitude": 52.9061,
    "Longitude": 12.7513,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.9061,12.7513",
    "IsOpen24Hours": false,
    "id": "52.9061,12.7513"
  },
  {
    "ID": 334112479,
    "Tankstellennummer": "0FQ02",
    "Name": "DÖTLINGEN",
    "Address": "A1",
    "Address__1": "",
    "City": "Dötlingen",
    "Postcode": 27801,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 443193920,
    "24 hour?": "TRUE",
    "Latitude": 52.914935,
    "Longitude": 8.426035,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.914935,8.426035",
    "IsOpen24Hours": true,
    "id": "52.914935,8.426035"
  },
  {
    "ID": 1084,
    "Tankstellennummer": "0F234",
    "Name": "Aral Tankstelle Verden, Nienburger Str.1",
    "Address": "Nienburger Straße 1",
    "Address__1": "",
    "City": "Verden",
    "Postcode": 27283,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 42312747,
    "24 hour?": "TRUE",
    "Latitude": 52.918079,
    "Longitude": 9.219863,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.918079,9.219863",
    "IsOpen24Hours": true,
    "id": "52.918079,9.219863"
  },
  {
    "ID": 44034699,
    "Tankstellennummer": "TD028373",
    "Name": "NEURUPPIN ALTRUPPINER ALLEE 25",
    "Address": "ALTRUPPINER ALLEE 25",
    "Address__1": "",
    "City": "NEURUPPIN",
    "Postcode": 16816,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03391-3291",
    "24 hour?": "FALSE",
    "Latitude": 52.939,
    "Longitude": 12.8187,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.939,12.8187",
    "IsOpen24Hours": false,
    "id": "52.939,12.8187"
  },
  {
    "ID": 1111,
    "Tankstellennummer": "0F251",
    "Name": "Aral Tankstelle Verden, Hamburger Str. 47",
    "Address": "Hamburger Straße 47",
    "Address__1": "",
    "City": "Verden",
    "Postcode": 27283,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 423173264,
    "24 hour?": "FALSE",
    "Latitude": 52.947981,
    "Longitude": 9.233142,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "52.947981,9.233142",
    "IsOpen24Hours": false,
    "id": "52.947981,9.233142"
  },
  {
    "ID": 1579,
    "Tankstellennummer": "0F617",
    "Name": "Aral Tankstelle Uelzen, Veersser Straße 98",
    "Address": "Veerßer Straße 98",
    "Address__1": "",
    "City": "Uelzen",
    "Postcode": 29525,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 58174212,
    "24 hour?": "TRUE",
    "Latitude": 52.957635,
    "Longitude": 10.551173,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.957635,10.551173",
    "IsOpen24Hours": true,
    "id": "52.957635,10.551173"
  },
  {
    "ID": 2503,
    "Tankstellennummer": "0FC47",
    "Name": "Aral Tankstelle Zehdenick, Castrop-Rauxel-Allee",
    "Address": "Castrop-Rauxel-Allee 2",
    "Address__1": "",
    "City": "Zehdenick",
    "Postcode": 16792,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 33072291,
    "24 hour?": "TRUE",
    "Latitude": 52.979558,
    "Longitude": 13.34459,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "52.979558,13.34459",
    "IsOpen24Hours": true,
    "id": "52.979558,13.34459"
  },
  {
    "ID": 1310709844,
    "Tankstellennummer": "TD038299",
    "Name": "ZEHDENICK BAHNHOFSTR.",
    "Address": "BAHNHOFSTR. 25",
    "Address__1": "",
    "City": "ZEHDENICK",
    "Postcode": 16792,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03307-311566",
    "24 hour?": "FALSE",
    "Latitude": 52.9807,
    "Longitude": 13.3205,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.9807,13.3205",
    "IsOpen24Hours": false,
    "id": "52.9807,13.3205"
  },
  {
    "ID": 2221,
    "Tankstellennummer": "0FA63",
    "Name": "Aral Tankstelle Weyhe, Sudweyher Straße 78",
    "Address": "Sudweyher Straße 78",
    "Address__1": "",
    "City": "Weyhe",
    "Postcode": 28844,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 42036162,
    "24 hour?": "FALSE",
    "Latitude": 52.983067,
    "Longitude": 8.877803,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.983067,8.877803",
    "IsOpen24Hours": false,
    "id": "52.983067,8.877803"
  },
  {
    "ID": 154393737,
    "Tankstellennummer": "TD037325",
    "Name": "MUNSTER DANZIGER STR. 69",
    "Address": "DANZIGER STR. 69",
    "Address__1": "",
    "City": "MUNSTER",
    "Postcode": 29633,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "05192-6267",
    "24 hour?": "FALSE",
    "Latitude": 52.9892,
    "Longitude": 10.0815,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "52.9892,10.0815",
    "IsOpen24Hours": false,
    "id": "52.9892,10.0815"
  },
  {
    "ID": 1156,
    "Tankstellennummer": "0F285",
    "Name": "Aral Autohof Uelzen, Ludwig-Erhard-Strasse",
    "Address": "Ludwig-Erhard-Straße 4",
    "Address__1": "",
    "City": "Uelzen",
    "Postcode": 29525,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5813897450,
    "24 hour?": "TRUE",
    "Latitude": 52.99548,
    "Longitude": 10.539605,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.99548,10.539605",
    "IsOpen24Hours": true,
    "id": "52.99548,10.539605"
  },
  {
    "ID": 522988666,
    "Tankstellennummer": "0FP97",
    "Name": "LANGWEDEL",
    "Address": "A 27",
    "Address__1": "",
    "City": "Langwedel",
    "Postcode": 27299,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4232306040,
    "24 hour?": "TRUE",
    "Latitude": 52.999475,
    "Longitude": 9.181822,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "52.999475,9.181822",
    "IsOpen24Hours": true,
    "id": "52.999475,9.181822"
  },
  {
    "ID": 743525742,
    "Tankstellennummer": "TD029678",
    "Name": "WITTENBERGE PARKSTR. 90 A",
    "Address": "PARKSTR. 90 A",
    "Address__1": "",
    "City": "WITTENBERGE",
    "Postcode": 19322,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03877-402317",
    "24 hour?": "FALSE",
    "Latitude": 53.0013,
    "Longitude": 11.7469,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0013,11.7469",
    "IsOpen24Hours": false,
    "id": "53.0013,11.7469"
  },
  {
    "ID": 1382268838,
    "Tankstellennummer": 610315,
    "Name": "WESTFALEN, BOESEL",
    "Address": "Friesoyther Str. 2",
    "Address__1": "",
    "City": "BOESEL",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.0061,
    "Longitude": 7.95418,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0061,7.95418",
    "IsOpen24Hours": false,
    "id": "53.0061,7.95418"
  },
  {
    "ID": 1327661652,
    "Tankstellennummer": 610470,
    "Name": "WESTFALEN, ACHIM",
    "Address": "Borsteler Landstr. 47",
    "Address__1": "",
    "City": "Achim",
    "Postcode": 28832,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "04202/888146",
    "24 hour?": "FALSE",
    "Latitude": 53.0175,
    "Longitude": 9.03867,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0175,9.03867",
    "IsOpen24Hours": false,
    "id": "53.0175,9.03867"
  },
  {
    "ID": 2824,
    "Tankstellennummer": "0FH06",
    "Name": "Aral Tankstelle Stuhr, Bremer Str. 53",
    "Address": "Bremer Straße 53",
    "Address__1": "",
    "City": "Stuhr",
    "Postcode": 28816,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 421891063,
    "24 hour?": "TRUE",
    "Latitude": 53.017893,
    "Longitude": 8.789342,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.017893,8.789342",
    "IsOpen24Hours": true,
    "id": "53.017893,8.789342"
  },
  {
    "ID": 1913897959,
    "Tankstellennummer": 610469,
    "Name": "WESTFALEN, ACHIM",
    "Address": "Embser Landstr. 22",
    "Address__1": "",
    "City": "Achim",
    "Postcode": 28832,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "04202/  3008",
    "24 hour?": "FALSE",
    "Latitude": 53.0193,
    "Longitude": 9.03119,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0193,9.03119",
    "IsOpen24Hours": false,
    "id": "53.0193,9.03119"
  },
  {
    "ID": 1172,
    "Tankstellennummer": "0F298",
    "Name": "Aral Autohof Stuhr, Delmenhorster Straße 305",
    "Address": "Weser-Ems-Str. 1",
    "Address__1": "",
    "City": "Stuhr",
    "Postcode": 28816,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 42213799,
    "24 hour?": "TRUE",
    "Latitude": 53.021027,
    "Longitude": 8.687874,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.021027,8.687874",
    "IsOpen24Hours": true,
    "id": "53.021027,8.687874"
  },
  {
    "ID": 1327284471,
    "Tankstellennummer": "TD037176",
    "Name": "DELMENHORST HASPORTER DAMM 200",
    "Address": "HASPORTER DAMM 200",
    "Address__1": "",
    "City": "DELMENHORST",
    "Postcode": 27755,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04221-24205",
    "24 hour?": "FALSE",
    "Latitude": 53.0298,
    "Longitude": 8.6557,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0298,8.6557",
    "IsOpen24Hours": false,
    "id": "53.0298,8.6557"
  },
  {
    "ID": 2235,
    "Tankstellennummer": "0FA72",
    "Name": "Aral Tankstelle Delmenhorst, Adelheider Str. 77",
    "Address": "Adelheider Straße 77",
    "Address__1": "",
    "City": "Delmenhorst",
    "Postcode": 27755,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 422123577,
    "24 hour?": "TRUE",
    "Latitude": 53.032145,
    "Longitude": 8.621628,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.032145,8.621628",
    "IsOpen24Hours": true,
    "id": "53.032145,8.621628"
  },
  {
    "ID": 1173,
    "Tankstellennummer": "0FE94",
    "Name": "Aral Autohof Bremen, Europaallee",
    "Address": "Europaallee",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28309,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4214581380,
    "24 hour?": "TRUE",
    "Latitude": 53.03474,
    "Longitude": 8.891315,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.03474,8.891315",
    "IsOpen24Hours": true,
    "id": "53.03474,8.891315"
  },
  {
    "ID": 209634148,
    "Tankstellennummer": "TD040154",
    "Name": "BREMEN EUROPAALLEE 5A",
    "Address": "EUROPAALLEE 5A",
    "Address__1": "",
    "City": "BREMEN",
    "Postcode": 28309,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0421-48536565",
    "24 hour?": "FALSE",
    "Latitude": 53.0349,
    "Longitude": 8.8868,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0349,8.8868",
    "IsOpen24Hours": false,
    "id": "53.0349,8.8868"
  },
  {
    "ID": 765249962,
    "Tankstellennummer": "0FP96",
    "Name": "WARDENBURG",
    "Address": "A29",
    "Address__1": "",
    "City": "Wardenburg",
    "Postcode": 26203,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 440791640,
    "24 hour?": "TRUE",
    "Latitude": 53.040952,
    "Longitude": 8.220862,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.040952,8.220862",
    "IsOpen24Hours": true,
    "id": "53.040952,8.220862"
  },
  {
    "ID": 2500,
    "Tankstellennummer": "0FC44",
    "Name": "Aral Tankstelle Schwedt, Berliner Allee / B 2",
    "Address": "Berliner Allee / B 2",
    "Address__1": "",
    "City": "Schwedt",
    "Postcode": 16303,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 333223206,
    "24 hour?": "FALSE",
    "Latitude": 53.046314,
    "Longitude": 14.265841,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.046314,14.265841",
    "IsOpen24Hours": false,
    "id": "53.046314,14.265841"
  },
  {
    "ID": 1600,
    "Tankstellennummer": "0F634",
    "Name": "Aral Tankstelle Bremen, Hannoversche Str. 142",
    "Address": "Hannoversche Straße 142",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28309,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 421452290,
    "24 hour?": "TRUE",
    "Latitude": 53.048427,
    "Longitude": 8.89153,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.048427,8.89153",
    "IsOpen24Hours": true,
    "id": "53.048427,8.89153"
  },
  {
    "ID": 2240,
    "Tankstellennummer": "0FA75",
    "Name": "Aral Tankstelle Bremen, Oldenburger Straße 99",
    "Address": "Oldenburger Straße 99",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28259,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 421583475,
    "24 hour?": "TRUE",
    "Latitude": 53.049093,
    "Longitude": 8.713773,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.049093,8.713773",
    "IsOpen24Hours": true,
    "id": "53.049093,8.713773"
  },
  {
    "ID": 221334141,
    "Tankstellennummer": 610475,
    "Name": "WESTFALEN, DELMENHORST",
    "Address": "Oldenburger Str. 87",
    "Address__1": "",
    "City": "Delmenhorst",
    "Postcode": 27753,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "04221/82560",
    "24 hour?": "FALSE",
    "Latitude": 53.0492,
    "Longitude": 8.60549,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0492,8.60549",
    "IsOpen24Hours": false,
    "id": "53.0492,8.60549"
  },
  {
    "ID": 1937632598,
    "Tankstellennummer": "TD037184",
    "Name": "DELMENHORST SYKERSTR. 74",
    "Address": "SYKERSTR. 74",
    "Address__1": "",
    "City": "DELMENHORST",
    "Postcode": 27751,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04221-71253",
    "24 hour?": "FALSE",
    "Latitude": 53.0503,
    "Longitude": 8.6545,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0503,8.6545",
    "IsOpen24Hours": false,
    "id": "53.0503,8.6545"
  },
  {
    "ID": 272368021,
    "Tankstellennummer": 900689,
    "Name": "WESTFALEN, BAT HUNTETAL WEST WARDENBURG",
    "Address": "Astruper Str. 168",
    "Address__1": "",
    "City": "BAT HUNTETAL WEST WARDENBURG",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.0525,
    "Longitude": 8.2061,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0525,8.2061",
    "IsOpen24Hours": false,
    "id": "53.0525,8.2061"
  },
  {
    "ID": 752944834,
    "Tankstellennummer": 5010,
    "Name": "Agip Schwedt/Oder",
    "Address": "Berliner Allee 67",
    "Address__1": "",
    "City": "Schwedt/Oder",
    "Postcode": 16303,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 33322348",
    "24 hour?": "TRUE",
    "Latitude": 53.05255601,
    "Longitude": 14.28044531,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.05255601,14.28044531",
    "IsOpen24Hours": true,
    "id": "53.05255601,14.28044531"
  },
  {
    "ID": 1725767991,
    "Tankstellennummer": "TD038430",
    "Name": "OYTEN AN DER AUTOBAHN",
    "Address": "AN DER AUTOBAHN 4",
    "Address__1": "",
    "City": "OYTEN",
    "Postcode": 28876,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04207-7081",
    "24 hour?": "FALSE",
    "Latitude": 53.0534,
    "Longitude": 9.0318,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0534,9.0318",
    "IsOpen24Hours": false,
    "id": "53.0534,9.0318"
  },
  {
    "ID": 2207,
    "Tankstellennummer": "0FA49",
    "Name": "Aral Tankstelle Bremen, Huchtinger Heerstr. 4",
    "Address": "Huchtinger Heerstraße 4",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28259,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 421581445,
    "24 hour?": "TRUE",
    "Latitude": 53.053781,
    "Longitude": 8.740779,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.053781,8.740779",
    "IsOpen24Hours": true,
    "id": "53.053781,8.740779"
  },
  {
    "ID": 1926758346,
    "Tankstellennummer": "0FT93",
    "Name": "WARDENBURG",
    "Address": "Oldenburger Str. 290",
    "Address__1": "",
    "City": "Wardenburg",
    "Postcode": 26203,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4407998070,
    "24 hour?": "FALSE",
    "Latitude": 53.056902,
    "Longitude": 8.199596,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.056902,8.199596",
    "IsOpen24Hours": false,
    "id": "53.056902,8.199596"
  },
  {
    "ID": 1171,
    "Tankstellennummer": "0F294",
    "Name": "Aral Tankstelle Bremen, Osterholzer Heerstr. 119",
    "Address": "Osterholzer Heerstraße 119-121",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28307,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4214173193,
    "24 hour?": "TRUE",
    "Latitude": 53.058102,
    "Longitude": 8.938544,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.058102,8.938544",
    "IsOpen24Hours": true,
    "id": "53.058102,8.938544"
  },
  {
    "ID": 1985994906,
    "Tankstellennummer": "TD040873",
    "Name": "BREMEN OSTERHOLZER HEERSTR. 83",
    "Address": "OSTERHOLZER HEERSTR. 83",
    "Address__1": "",
    "City": "BREMEN",
    "Postcode": 28307,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0421-451779",
    "24 hour?": "FALSE",
    "Latitude": 53.0588,
    "Longitude": 8.93,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0588,8.93",
    "IsOpen24Hours": false,
    "id": "53.0588,8.93"
  },
  {
    "ID": 2219,
    "Tankstellennummer": "0FA60",
    "Name": "Aral Tankstelle Delmenhorst, Stedinger Str. 174",
    "Address": "Stedinger Straße 174",
    "Address__1": "",
    "City": "Delmenhorst",
    "Postcode": 27753,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 422151566,
    "24 hour?": "TRUE",
    "Latitude": 53.065474,
    "Longitude": 8.633312,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.065474,8.633312",
    "IsOpen24Hours": true,
    "id": "53.065474,8.633312"
  },
  {
    "ID": 832561794,
    "Tankstellennummer": "TD036822",
    "Name": "BREMEN OSTERDEICH 124-125",
    "Address": "OSTERDEICH 124-125",
    "Address__1": "",
    "City": "BREMEN",
    "Postcode": 28205,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0421-440704",
    "24 hour?": "FALSE",
    "Latitude": 53.0669,
    "Longitude": 8.8443,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0669,8.8443",
    "IsOpen24Hours": false,
    "id": "53.0669,8.8443"
  },
  {
    "ID": 376397333,
    "Tankstellennummer": "TD029629",
    "Name": "PERLEBERG BERLINER STR. 31",
    "Address": "BERLINER STR. 31",
    "Address__1": "",
    "City": "PERLEBERG",
    "Postcode": 19348,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03876-612301",
    "24 hour?": "FALSE",
    "Latitude": 53.0703,
    "Longitude": 11.8765,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0703,11.8765",
    "IsOpen24Hours": false,
    "id": "53.0703,11.8765"
  },
  {
    "ID": 1364393565,
    "Tankstellennummer": "TD020156",
    "Name": "SCHWEDT STEINSTR.11",
    "Address": "STEINSTR.11",
    "Address__1": "",
    "City": "SCHWEDT",
    "Postcode": 16303,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03332-417992",
    "24 hour?": "FALSE",
    "Latitude": 53.0714,
    "Longitude": 14.2543,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0714,14.2543",
    "IsOpen24Hours": false,
    "id": "53.0714,14.2543"
  },
  {
    "ID": 2822,
    "Tankstellennummer": "0FH05",
    "Name": "Aral Tankstelle Bremen, Bismarckstr. 355",
    "Address": "Bismarckstraße 355",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28205,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 421447691,
    "24 hour?": "TRUE",
    "Latitude": 53.073599,
    "Longitude": 8.851597,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.073599,8.851597",
    "IsOpen24Hours": true,
    "id": "53.073599,8.851597"
  },
  {
    "ID": 321723313,
    "Tankstellennummer": "TD037341",
    "Name": "PAPENBURG FRIEDERIKENSTR. 27-2",
    "Address": "FRIEDERIKENSTR. 27-29",
    "Address__1": "",
    "City": "PAPENBURG",
    "Postcode": 26871,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04961-2436",
    "24 hour?": "FALSE",
    "Latitude": 53.0773,
    "Longitude": 7.3907,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0773,7.3907",
    "IsOpen24Hours": false,
    "id": "53.0773,7.3907"
  },
  {
    "ID": 2188,
    "Tankstellennummer": "0FA34",
    "Name": "Aral Tankstelle Bremen, Kurfürstenallee 49",
    "Address": "Kurfürstenallee 49",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28329,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 421444244,
    "24 hour?": "TRUE",
    "Latitude": 53.082046,
    "Longitude": 8.855155,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.082046,8.855155",
    "IsOpen24Hours": true,
    "id": "53.082046,8.855155"
  },
  {
    "ID": 861871414,
    "Tankstellennummer": "TD036368",
    "Name": "PERLEBERG HAMBURGER STR. 18",
    "Address": "HAMBURGER STR. 18",
    "Address__1": "",
    "City": "PERLEBERG",
    "Postcode": 19348,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03876-785150",
    "24 hour?": "FALSE",
    "Latitude": 53.0827,
    "Longitude": 11.8588,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.0827,11.8588",
    "IsOpen24Hours": false,
    "id": "53.0827,11.8588"
  },
  {
    "ID": 1083605116,
    "Tankstellennummer": 610325,
    "Name": "WESTFALEN, DELMENHORST",
    "Address": "Stedinger Landstr. 21",
    "Address__1": "",
    "City": "DELMENHORST",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.086,
    "Longitude": 8.65038,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.086,8.65038",
    "IsOpen24Hours": false,
    "id": "53.086,8.65038"
  },
  {
    "ID": 28127,
    "Tankstellennummer": "0FP38",
    "Name": "Aral Tankstelle Sottrum, A1",
    "Address": "A 1",
    "Address__1": "",
    "City": "Sottrum",
    "Postcode": 27367,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4205491,
    "24 hour?": "TRUE",
    "Latitude": 53.090109,
    "Longitude": 9.179623,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.090109,9.179623",
    "IsOpen24Hours": true,
    "id": "53.090109,9.179623"
  },
  {
    "ID": 1033009594,
    "Tankstellennummer": "0FU92",
    "Name": "SOTTRUM",
    "Address": "BAB A 1",
    "Address__1": "",
    "City": "Sottrum",
    "Postcode": 27367,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4205494,
    "24 hour?": "TRUE",
    "Latitude": 53.090887,
    "Longitude": 9.177942,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.090887,9.177942",
    "IsOpen24Hours": true,
    "id": "53.090887,9.177942"
  },
  {
    "ID": 1584571802,
    "Tankstellennummer": "TD036814",
    "Name": "BREMEN OBERNEULANDER HEERSTR.",
    "Address": "OBERNEULANDER HEERSTR. 38",
    "Address__1": "",
    "City": "BREMEN",
    "Postcode": 28355,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0421-251443",
    "24 hour?": "FALSE",
    "Latitude": 53.1004,
    "Longitude": 8.9028,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.1004,8.9028",
    "IsOpen24Hours": false,
    "id": "53.1004,8.9028"
  },
  {
    "ID": 1456427832,
    "Tankstellennummer": "TD036798",
    "Name": "BREMEN LEHER HEERSTR. 98",
    "Address": "LEHER HEERSTR. 98",
    "Address__1": "",
    "City": "BREMEN",
    "Postcode": 28359,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0421-234565",
    "24 hour?": "FALSE",
    "Latitude": 53.1016,
    "Longitude": 8.8783,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.1016,8.8783",
    "IsOpen24Hours": false,
    "id": "53.1016,8.8783"
  },
  {
    "ID": 2232,
    "Tankstellennummer": "0FA70",
    "Name": "Aral Tankstelle Bremen, Osterfeuerberger Ring 65",
    "Address": "Osterfeuerberger Ring 65",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28219,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 421385309,
    "24 hour?": "FALSE",
    "Latitude": 53.102758,
    "Longitude": 8.797215,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.102758,8.797215",
    "IsOpen24Hours": false,
    "id": "53.102758,8.797215"
  },
  {
    "ID": 2178,
    "Tankstellennummer": "0FA42",
    "Name": "Aral Tankstelle Hude, Nordheide 21",
    "Address": "Nordheide 21",
    "Address__1": "",
    "City": "Hude",
    "Postcode": 27798,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4408939918,
    "24 hour?": "FALSE",
    "Latitude": 53.109847,
    "Longitude": 8.466042,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.109847,8.466042",
    "IsOpen24Hours": false,
    "id": "53.109847,8.466042"
  },
  {
    "ID": 1602,
    "Tankstellennummer": "0F636",
    "Name": "Aral Autohof Bremen, Stapelfeldtstraße 4",
    "Address": "Stapelfeldtstraße 4",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28237,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4216163511,
    "24 hour?": "TRUE",
    "Latitude": 53.111003,
    "Longitude": 8.753271,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.111003,8.753271",
    "IsOpen24Hours": true,
    "id": "53.111003,8.753271"
  },
  {
    "ID": 737136815,
    "Tankstellennummer": "0FV73",
    "Name": "SOTTRUM",
    "Address": "Bremer Straße 32",
    "Address__1": "",
    "City": "Sottrum",
    "Postcode": 27367,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 42648366330,
    "24 hour?": "FALSE",
    "Latitude": 53.113645,
    "Longitude": 9.231325,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.113645,9.231325",
    "IsOpen24Hours": false,
    "id": "53.113645,9.231325"
  },
  {
    "ID": 2265,
    "Tankstellennummer": "0FA95",
    "Name": "Aral Tankstelle Bremen, Am Lehester Deich 62",
    "Address": "Am Lehester Deich 62",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28357,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 421270076,
    "24 hour?": "FALSE",
    "Latitude": 53.116367,
    "Longitude": 8.899966,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.116367,8.899966",
    "IsOpen24Hours": false,
    "id": "53.116367,8.899966"
  },
  {
    "ID": 1137,
    "Tankstellennummer": "0F272",
    "Name": "Aral Tankstelle Rotenburg, Rönnebrocksweg 15",
    "Address": "Rönnebrocksweg 15",
    "Address__1": "",
    "City": "Rotenburg",
    "Postcode": 27356,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4261971902,
    "24 hour?": "TRUE",
    "Latitude": 53.116688,
    "Longitude": 9.395152,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.116688,9.395152",
    "IsOpen24Hours": true,
    "id": "53.116688,9.395152"
  },
  {
    "ID": 594025619,
    "Tankstellennummer": 610479,
    "Name": "WESTFALEN, OLDENBURG",
    "Address": "Cloppenburger Str. 224",
    "Address__1": "",
    "City": "Oldenburg",
    "Postcode": 26133,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0441/41761",
    "24 hour?": "FALSE",
    "Latitude": 53.1176,
    "Longitude": 8.21453,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.1176,8.21453",
    "IsOpen24Hours": false,
    "id": "53.1176,8.21453"
  },
  {
    "ID": 3412,
    "Tankstellennummer": "0FL51",
    "Name": "Aral Tankstelle Bremen, Groepelinger Heerstr. 237",
    "Address": "Groepelinger Heerstr. 237",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28239,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 421612980,
    "24 hour?": "TRUE",
    "Latitude": 53.118219,
    "Longitude": 8.756886,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.118219,8.756886",
    "IsOpen24Hours": true,
    "id": "53.118219,8.756886"
  },
  {
    "ID": 2243,
    "Tankstellennummer": "0FA78",
    "Name": "Aral Tankstelle Oldenburg, Hundsmühler Str. 86",
    "Address": "Hundsmühler Straße 86",
    "Address__1": "",
    "City": "Oldenburg",
    "Postcode": 26131,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4419558927,
    "24 hour?": "TRUE",
    "Latitude": 53.125875,
    "Longitude": 8.190065,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.125875,8.190065",
    "IsOpen24Hours": true,
    "id": "53.125875,8.190065"
  },
  {
    "ID": 2514,
    "Tankstellennummer": "0FC55",
    "Name": "Aral Tankstelle Templin, Lychener Straße 15",
    "Address": "Lychener Straße 15",
    "Address__1": "",
    "City": "Templin",
    "Postcode": 17268,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 39876822,
    "24 hour?": "TRUE",
    "Latitude": 53.128937,
    "Longitude": 13.489475,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.128937,13.489475",
    "IsOpen24Hours": true,
    "id": "53.128937,13.489475"
  },
  {
    "ID": 2189,
    "Tankstellennummer": "0FA35",
    "Name": "Aral Tankstelle Oldenburg, Bremer Straße 72",
    "Address": "Bremer Straße 72",
    "Address__1": "",
    "City": "Oldenburg",
    "Postcode": 26135,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4419987199,
    "24 hour?": "TRUE",
    "Latitude": 53.129261,
    "Longitude": 8.227242,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.129261,8.227242",
    "IsOpen24Hours": true,
    "id": "53.129261,8.227242"
  },
  {
    "ID": 2216,
    "Tankstellennummer": "0FA57",
    "Name": "Aral Tankstelle Ostrhauderfehn, Hauptstraße 204",
    "Address": "Hauptstraße 204",
    "Address__1": "",
    "City": "Ostrhauderfehn",
    "Postcode": 26842,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 49525236,
    "24 hour?": "FALSE",
    "Latitude": 53.14011,
    "Longitude": 7.638033,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.14011,7.638033",
    "IsOpen24Hours": false,
    "id": "53.14011,7.638033"
  },
  {
    "ID": 2225,
    "Tankstellennummer": "0FA65",
    "Name": "Aral Tankstelle Oldenburg, Prinzessinweg 2",
    "Address": "Prinzessinweg  2",
    "Address__1": "",
    "City": "Oldenburg",
    "Postcode": 26122,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 441971971,
    "24 hour?": "TRUE",
    "Latitude": 53.143358,
    "Longitude": 8.191774,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.143358,8.191774",
    "IsOpen24Hours": true,
    "id": "53.143358,8.191774"
  },
  {
    "ID": 553170605,
    "Tankstellennummer": 5082,
    "Name": "Agip Liebenthal",
    "Address": "Wittstocker Kreuz, Dorfstrae 67",
    "Address__1": "",
    "City": "Liebenthal",
    "Postcode": 16909,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 33962509",
    "24 hour?": "TRUE",
    "Latitude": 53.14874161,
    "Longitude": 12.39723847,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.14874161,12.39723847",
    "IsOpen24Hours": true,
    "id": "53.14874161,12.39723847"
  },
  {
    "ID": 483231119,
    "Tankstellennummer": "TD000252",
    "Name": "WESTOVERLEDINGEN GROSSWOLDER S",
    "Address": "GROSSWOLDER STR. 118",
    "Address__1": "",
    "City": "WESTOVERLEDINGEN",
    "Postcode": 26810,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04955-1631",
    "24 hour?": "FALSE",
    "Latitude": 53.1512,
    "Longitude": 7.4501,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.1512,7.4501",
    "IsOpen24Hours": false,
    "id": "53.1512,7.4501"
  },
  {
    "ID": 103678772,
    "Tankstellennummer": 610432,
    "Name": "WESTFALEN, OLDENBURG",
    "Address": "Donnerschweer Str. 215",
    "Address__1": "",
    "City": "Oldenburg",
    "Postcode": 26123,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0441/384994",
    "24 hour?": "TRUE",
    "Latitude": 53.1516,
    "Longitude": 8.23736,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.1516,8.23736",
    "IsOpen24Hours": true,
    "id": "53.1516,8.23736"
  },
  {
    "ID": 505266452,
    "Tankstellennummer": "TD033803",
    "Name": "WITTSTOCK ROSA-LUXEMBURG-STR.",
    "Address": "ROSA-LUXEMBURG-STR. 7",
    "Address__1": "",
    "City": "WITTSTOCK",
    "Postcode": 16909,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03394-444154",
    "24 hour?": "FALSE",
    "Latitude": 53.1572,
    "Longitude": 12.4803,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.1572,12.4803",
    "IsOpen24Hours": false,
    "id": "53.1572,12.4803"
  },
  {
    "ID": 2530,
    "Tankstellennummer": "0FC70",
    "Name": "Aral Tankstelle Wittstock, Pritzwalker Str.",
    "Address": "Pritzwalker Straße",
    "Address__1": "",
    "City": "Wittstock",
    "Postcode": 16909,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3394433198,
    "24 hour?": "FALSE",
    "Latitude": 53.16036,
    "Longitude": 12.46742,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.16036,12.46742",
    "IsOpen24Hours": false,
    "id": "53.16036,12.46742"
  },
  {
    "ID": 2828,
    "Tankstellennummer": "0FH09",
    "Name": "Aral Tankstelle Oldenburg, Alexanderstr. 202",
    "Address": "Alexanderstraße 202",
    "Address__1": "",
    "City": "Oldenburg",
    "Postcode": 26121,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 441883268,
    "24 hour?": "TRUE",
    "Latitude": 53.161307,
    "Longitude": 8.207715,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.161307,8.207715",
    "IsOpen24Hours": true,
    "id": "53.161307,8.207715"
  },
  {
    "ID": 2034144604,
    "Tankstellennummer": "TD041905",
    "Name": "EGESTORF THANEBERG 1",
    "Address": "THANEBERG 1",
    "Address__1": "",
    "City": "EGESTORF",
    "Postcode": 21272,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04175-2299990",
    "24 hour?": "FALSE",
    "Latitude": 53.1617,
    "Longitude": 10.0621,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.1617,10.0621",
    "IsOpen24Hours": false,
    "id": "53.1617,10.0621"
  },
  {
    "ID": 317521025,
    "Tankstellennummer": 610480,
    "Name": "WESTFALEN, OLDENBURG",
    "Address": "Nadorster Str. 287",
    "Address__1": "",
    "City": "Oldenburg",
    "Postcode": 26125,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0441/31230",
    "24 hour?": "FALSE",
    "Latitude": 53.1663,
    "Longitude": 8.22672,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.1663,8.22672",
    "IsOpen24Hours": false,
    "id": "53.1663,8.22672"
  },
  {
    "ID": 2829,
    "Tankstellennummer": "0FH10",
    "Name": "Aral Tankstelle Oldenburg, Nadorster Str. 288",
    "Address": "Nadorster Straße 288",
    "Address__1": "",
    "City": "Oldenburg",
    "Postcode": 26125,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 44137770,
    "24 hour?": "FALSE",
    "Latitude": 53.166528,
    "Longitude": 8.227213,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.166528,8.227213",
    "IsOpen24Hours": false,
    "id": "53.166528,8.227213"
  },
  {
    "ID": 661144098,
    "Tankstellennummer": 610626,
    "Name": "WESTFALEN, BARSSEL",
    "Address": "Ammerländer Str. 8",
    "Address__1": "",
    "City": "Barssel",
    "Postcode": 26676,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "04499/9221057",
    "24 hour?": "FALSE",
    "Latitude": 53.1692,
    "Longitude": 7.74629,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.1692,7.74629",
    "IsOpen24Hours": false,
    "id": "53.1692,7.74629"
  },
  {
    "ID": 1025761530,
    "Tankstellennummer": "TD034561",
    "Name": "FUERSTENBERG HANS-GUENTER-BOCK",
    "Address": "HANS-GUENTER-BOCK-STR. 8",
    "Address__1": "",
    "City": "FUERSTENBERG",
    "Postcode": 16798,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033093-32106",
    "24 hour?": "FALSE",
    "Latitude": 53.1717,
    "Longitude": 13.1408,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.1717,13.1408",
    "IsOpen24Hours": false,
    "id": "53.1717,13.1408"
  },
  {
    "ID": 2231,
    "Tankstellennummer": "0FA69",
    "Name": "Aral Tankstelle Bremen, Bremerhavener Heerstr. 48",
    "Address": "Bremerhavener Heerstr. 48",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28717,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 421633622,
    "24 hour?": "TRUE",
    "Latitude": 53.173102,
    "Longitude": 8.703832,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.173102,8.703832",
    "IsOpen24Hours": true,
    "id": "53.173102,8.703832"
  },
  {
    "ID": 8912801,
    "Tankstellennummer": 610431,
    "Name": "WESTFALEN, OLDENBURG",
    "Address": "Alexanderstr. 420",
    "Address__1": "",
    "City": "Oldenburg",
    "Postcode": 26127,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0441/63148",
    "24 hour?": "TRUE",
    "Latitude": 53.1752,
    "Longitude": 8.19138,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.1752,8.19138",
    "IsOpen24Hours": true,
    "id": "53.1752,8.19138"
  },
  {
    "ID": 767781530,
    "Tankstellennummer": "0FV96",
    "Name": "SCHEEßEL",
    "Address": "Harburger Straße 39",
    "Address__1": "",
    "City": "Scheeßel",
    "Postcode": 27383,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.17561,
    "Longitude": 9.49334,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.17561,9.49334",
    "IsOpen24Hours": false,
    "id": "53.17561,9.49334"
  },
  {
    "ID": 335364170,
    "Tankstellennummer": 610321,
    "Name": "WESTFALEN, APEN",
    "Address": "Nordloher Str. 31",
    "Address__1": "",
    "City": "Apen",
    "Postcode": 26689,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "04409/387",
    "24 hour?": "FALSE",
    "Latitude": 53.1757,
    "Longitude": 7.82345,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.1757,7.82345",
    "IsOpen24Hours": false,
    "id": "53.1757,7.82345"
  },
  {
    "ID": 2081592791,
    "Tankstellennummer": "TD038638",
    "Name": "BREMEN LUESSUMER STR. 9",
    "Address": "LUESSUMER STR. 9",
    "Address__1": "",
    "City": "BREMEN",
    "Postcode": 28779,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0421-601164",
    "24 hour?": "FALSE",
    "Latitude": 53.1844,
    "Longitude": 8.5783,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.1844,8.5783",
    "IsOpen24Hours": false,
    "id": "53.1844,8.5783"
  },
  {
    "ID": 1141,
    "Tankstellennummer": "0F275",
    "Name": "Aral Autohof Gyhum, BAB 1/B 71",
    "Address": "An der Autobahn 1",
    "Address__1": "",
    "City": "Gyhum",
    "Postcode": 27404,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 428695120,
    "24 hour?": "TRUE",
    "Latitude": 53.193417,
    "Longitude": 9.288884,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.193417,9.288884",
    "IsOpen24Hours": true,
    "id": "53.193417,9.288884"
  },
  {
    "ID": 3461,
    "Tankstellennummer": "0FL83",
    "Name": "Aral Tankstelle Egestorf, Luebberstedter Str. 36",
    "Address": "Luebberstedter Straße 36",
    "Address__1": "",
    "City": "Egestorf",
    "Postcode": 21272,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4175587,
    "24 hour?": "FALSE",
    "Latitude": 53.194046,
    "Longitude": 10.072126,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.194046,10.072126",
    "IsOpen24Hours": false,
    "id": "53.194046,10.072126"
  },
  {
    "ID": 588058194,
    "Tankstellennummer": 610472,
    "Name": "WESTFALEN, BREMEN",
    "Address": "Farger Str. 16",
    "Address__1": "",
    "City": "Bremen",
    "Postcode": 28777,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0421/68786",
    "24 hour?": "TRUE",
    "Latitude": 53.194356,
    "Longitude": 8.532153,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.194356,8.532153",
    "IsOpen24Hours": true,
    "id": "53.194356,8.532153"
  },
  {
    "ID": 312629301,
    "Tankstellennummer": "TD041624",
    "Name": "LILIENTHAL WORPHAUSER LANDSTR.",
    "Address": "WORPHAUSER LANDSTR. 38",
    "Address__1": "",
    "City": "LILIENTHAL",
    "Postcode": 28865,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04792-688",
    "24 hour?": "FALSE",
    "Latitude": 53.1992,
    "Longitude": 8.9355,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.1992,8.9355",
    "IsOpen24Hours": false,
    "id": "53.1992,8.9355"
  },
  {
    "ID": 1119,
    "Tankstellennummer": "0F255",
    "Name": "Aral Tankstelle Osterholz-Scharmbeck, Ritterhuder Straße 55",
    "Address": "Ritterhuder Straße 55",
    "Address__1": "",
    "City": "Osterholz-Scharmbeck",
    "Postcode": 27711,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 47912772,
    "24 hour?": "FALSE",
    "Latitude": 53.214826,
    "Longitude": 8.778652,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.214826,8.778652",
    "IsOpen24Hours": false,
    "id": "53.214826,8.778652"
  },
  {
    "ID": 1595888793,
    "Tankstellennummer": 610330,
    "Name": "WESTFALEN, APEN",
    "Address": "Hauptstr. 455",
    "Address__1": "",
    "City": "APEN",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.2166,
    "Longitude": 7.76252,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.2166,7.76252",
    "IsOpen24Hours": false,
    "id": "53.2166,7.76252"
  },
  {
    "ID": 1083,
    "Tankstellennummer": "0F233",
    "Name": "Aral Tankstelle Tarmstedt, Bremer Landstr.4",
    "Address": "Bremer Landstraße4",
    "Address__1": "",
    "City": "Tarmstedt",
    "Postcode": 27412,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 428389110,
    "24 hour?": "FALSE",
    "Latitude": 53.223233,
    "Longitude": 9.076715,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.223233,9.076715",
    "IsOpen24Hours": false,
    "id": "53.223233,9.076715"
  },
  {
    "ID": 2815,
    "Tankstellennummer": "0FH03",
    "Name": "Aral Tankstelle Lüneburg, Uelzener Str. 118",
    "Address": "Universitätsallee 4",
    "Address__1": "",
    "City": "Lüneburg",
    "Postcode": 21335,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4131732501,
    "24 hour?": "FALSE",
    "Latitude": 53.22672,
    "Longitude": 10.405972,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.22672,10.405972",
    "IsOpen24Hours": false,
    "id": "53.22672,10.405972"
  },
  {
    "ID": 3347,
    "Tankstellennummer": "0FK95",
    "Name": "Aral Tankstelle Osterholz-Scharmbeck, Ruebhofstr. 27",
    "Address": "Rübhofstraße 27",
    "Address__1": "",
    "City": "Osterholz-Scharmbeck",
    "Postcode": 27711,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4791982119,
    "24 hour?": "FALSE",
    "Latitude": 53.228002,
    "Longitude": 8.811051,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.228002,8.811051",
    "IsOpen24Hours": false,
    "id": "53.228002,8.811051"
  },
  {
    "ID": 561970328,
    "Tankstellennummer": "TD040527",
    "Name": "ZEVEN-ELSDORF",
    "Address": "AUF DER BROOKE 2",
    "Address__1": "",
    "City": "ZEVEN-ELSDORF",
    "Postcode": 27404,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04286-9266416",
    "24 hour?": "FALSE",
    "Latitude": 53.2306,
    "Longitude": 9.3429,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.2306,9.3429",
    "IsOpen24Hours": false,
    "id": "53.2306,9.3429"
  },
  {
    "ID": 1626,
    "Tankstellennummer": "0F652",
    "Name": "Aral Tankstelle Elsfleth, Oberrege 6",
    "Address": "Oberrege 6",
    "Address__1": "",
    "City": "Elsfleth",
    "Postcode": 26931,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4404951488,
    "24 hour?": "TRUE",
    "Latitude": 53.235536,
    "Longitude": 8.455668,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.235536,8.455668",
    "IsOpen24Hours": true,
    "id": "53.235536,8.455668"
  },
  {
    "ID": 2110716418,
    "Tankstellennummer": "TD029330",
    "Name": "PUTLITZ PRITZWALKER STR. 11",
    "Address": "PRITZWALKER STR. 11",
    "Address__1": "",
    "City": "PUTLITZ",
    "Postcode": 16949,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "033981-80578",
    "24 hour?": "FALSE",
    "Latitude": 53.2377,
    "Longitude": 12.0556,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.2377,12.0556",
    "IsOpen24Hours": false,
    "id": "53.2377,12.0556"
  },
  {
    "ID": 2814,
    "Tankstellennummer": "0FH02",
    "Name": "Aral Tankstelle Lüneburg, Soltauer Str. 22",
    "Address": "Soltauer Straße 22",
    "Address__1": "",
    "City": "Lüneburg",
    "Postcode": 21335,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4131732511,
    "24 hour?": "TRUE",
    "Latitude": 53.24103,
    "Longitude": 10.400814,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.24103,10.400814",
    "IsOpen24Hours": true,
    "id": "53.24103,10.400814"
  },
  {
    "ID": 321855310,
    "Tankstellennummer": "TD000268",
    "Name": "WELLE HAUPTSTR. 6",
    "Address": "HAUPTSTR. 6",
    "Address__1": "",
    "City": "WELLE",
    "Postcode": 21261,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04188-880000",
    "24 hour?": "FALSE",
    "Latitude": 53.2416,
    "Longitude": 9.8022,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.2416,9.8022",
    "IsOpen24Hours": false,
    "id": "53.2416,9.8022"
  },
  {
    "ID": 1594,
    "Tankstellennummer": "0F631",
    "Name": "Aral Tankstelle Lüneburg, Dahlenburg. Landstr. 34",
    "Address": "Dahlenburg. Landstraße 34",
    "Address__1": "",
    "City": "Lüneburg",
    "Postcode": 21337,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 413151560,
    "24 hour?": "TRUE",
    "Latitude": 53.244234,
    "Longitude": 10.44459,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.244234,10.44459",
    "IsOpen24Hours": true,
    "id": "53.244234,10.44459"
  },
  {
    "ID": 2268,
    "Tankstellennummer": "0FA98",
    "Name": "Aral Tankstelle Leer, Heisfelder Straße 212",
    "Address": "Heisfelder Straße 212",
    "Address__1": "",
    "City": "Leer",
    "Postcode": 26789,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4913584,
    "24 hour?": "TRUE",
    "Latitude": 53.247346,
    "Longitude": 7.458482,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.247346,7.458482",
    "IsOpen24Hours": true,
    "id": "53.247346,7.458482"
  },
  {
    "ID": 1165,
    "Tankstellennummer": "0F293",
    "Name": "Aral Autohof Uplengen-Jübberde, Rudolf-Diesel-Str. 2",
    "Address": "Rudolf-Diesel-Straße 4",
    "Address__1": "",
    "City": "Uplengen-Jübberde",
    "Postcode": 26670,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4956990408,
    "24 hour?": "TRUE",
    "Latitude": 53.262841,
    "Longitude": 7.756089,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.262841,7.756089",
    "IsOpen24Hours": true,
    "id": "53.262841,7.756089"
  },
  {
    "ID": 1155,
    "Tankstellennummer": "0F013",
    "Name": "Aral Autohof Lüneburg, Hamburger Straße 35 b",
    "Address": "Hamburger Straße 35 b",
    "Address__1": "",
    "City": "Lüneburg",
    "Postcode": 21339,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4131855291,
    "24 hour?": "TRUE",
    "Latitude": 53.275159,
    "Longitude": 10.401078,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.275159,10.401078",
    "IsOpen24Hours": true,
    "id": "53.275159,10.401078"
  },
  {
    "ID": 282607210,
    "Tankstellennummer": "TD039271",
    "Name": "WESENBERG AM PUMP 4",
    "Address": "AM PUMP 4",
    "Address__1": "",
    "City": "WESENBERG",
    "Postcode": 17255,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039832-21027",
    "24 hour?": "FALSE",
    "Latitude": 53.2795,
    "Longitude": 12.9669,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.2795,12.9669",
    "IsOpen24Hours": false,
    "id": "53.2795,12.9669"
  },
  {
    "ID": 1042,
    "Tankstellennummer": "0F205",
    "Name": "Aral Tankstelle Tostedt, Kastanienallee",
    "Address": "Kastanienallee 17-19",
    "Address__1": "",
    "City": "Tostedt",
    "Postcode": 21255,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4182293396,
    "24 hour?": "TRUE",
    "Latitude": 53.282134,
    "Longitude": 9.714003,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.282134,9.714003",
    "IsOpen24Hours": true,
    "id": "53.282134,9.714003"
  },
  {
    "ID": 1572949842,
    "Tankstellennummer": "TD030023",
    "Name": "NEUHAUS BAHNHOFSTR.23",
    "Address": "BAHNHOFSTR.23",
    "Address__1": "",
    "City": "NEUHAUS",
    "Postcode": 19273,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038841-20733",
    "24 hour?": "FALSE",
    "Latitude": 53.2869,
    "Longitude": 10.9368,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.2869,10.9368",
    "IsOpen24Hours": false,
    "id": "53.2869,10.9368"
  },
  {
    "ID": 780292965,
    "Tankstellennummer": "TD037424",
    "Name": "ZEVEN BAHNHOFSTR. 36",
    "Address": "BAHNHOFSTR. 36",
    "Address__1": "",
    "City": "ZEVEN",
    "Postcode": 27404,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04281-80380",
    "24 hour?": "FALSE",
    "Latitude": 53.2872,
    "Longitude": 9.2769,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.2872,9.2769",
    "IsOpen24Hours": false,
    "id": "53.2872,9.2769"
  },
  {
    "ID": 597700489,
    "Tankstellennummer": "0FG25",
    "Name": "SITTENSEN",
    "Address": "Stader Straße 30",
    "Address__1": "",
    "City": "Sittensen",
    "Postcode": 27419,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4282592874,
    "24 hour?": "TRUE",
    "Latitude": 53.288559,
    "Longitude": 9.50774,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.288559,9.50774",
    "IsOpen24Hours": true,
    "id": "53.288559,9.50774"
  },
  {
    "ID": 2795,
    "Tankstellennummer": "0FG86",
    "Name": "Aral Tankstelle Bleckede, Lueneburger Str. 4",
    "Address": "Lueneburger Straße 4",
    "Address__1": "",
    "City": "Bleckede",
    "Postcode": 21354,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 5852453,
    "24 hour?": "FALSE",
    "Latitude": 53.289697,
    "Longitude": 10.730439,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.289697,10.730439",
    "IsOpen24Hours": false,
    "id": "53.289697,10.730439"
  },
  {
    "ID": 2190,
    "Tankstellennummer": "0FA36",
    "Name": "Aral Tankstelle Uplengen-Remels, Ostertorstrasse 45",
    "Address": "Ostertorstraße 45",
    "Address__1": "",
    "City": "Uplengen-Remels",
    "Postcode": 26670,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4956927799,
    "24 hour?": "FALSE",
    "Latitude": 53.305317,
    "Longitude": 7.746361,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.305317,7.746361",
    "IsOpen24Hours": false,
    "id": "53.305317,7.746361"
  },
  {
    "ID": 2214,
    "Tankstellennummer": "0FA55",
    "Name": "Aral Tankstelle Hesel, Auricher Straße 2",
    "Address": "Auricher Straße 2",
    "Address__1": "",
    "City": "Hesel",
    "Postcode": 26835,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 49502034,
    "24 hour?": "TRUE",
    "Latitude": 53.305665,
    "Longitude": 7.593839,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.305665,7.593839",
    "IsOpen24Hours": true,
    "id": "53.305665,7.593839"
  },
  {
    "ID": 2223,
    "Tankstellennummer": "0FA64",
    "Name": "Aral Tankstelle Moormerland, Dr.Warsing-Str.209",
    "Address": "Dr.Warsing-Straße 209",
    "Address__1": "",
    "City": "Moormerland",
    "Postcode": 26802,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 49544228,
    "24 hour?": "FALSE",
    "Latitude": 53.30865,
    "Longitude": 7.504725,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.30865,7.504725",
    "IsOpen24Hours": false,
    "id": "53.30865,7.504725"
  },
  {
    "ID": 1838268590,
    "Tankstellennummer": "TD029595",
    "Name": "LUDWIGSLUST GRABOWER ALLEE 31",
    "Address": "GRABOWER ALLEE 31",
    "Address__1": "",
    "City": "LUDWIGSLUST",
    "Postcode": 19288,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03874-61143/45",
    "24 hour?": "FALSE",
    "Latitude": 53.3161,
    "Longitude": 11.5065,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.3161,11.5065",
    "IsOpen24Hours": false,
    "id": "53.3161,11.5065"
  },
  {
    "ID": 1075713086,
    "Tankstellennummer": 610471,
    "Name": "WESTFALEN, BRAKE",
    "Address": "Breite Str. 173 a",
    "Address__1": "",
    "City": "BRAKE",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.325,
    "Longitude": 8.46438,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.325,8.46438",
    "IsOpen24Hours": false,
    "id": "53.325,8.46438"
  },
  {
    "ID": 66124389,
    "Tankstellennummer": 610318,
    "Name": "WESTFALEN, JADE",
    "Address": "Tiergartenstr. 84",
    "Address__1": "",
    "City": "JADE",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.3289,
    "Longitude": 8.18689,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.3289,8.18689",
    "IsOpen24Hours": false,
    "id": "53.3289,8.18689"
  },
  {
    "ID": 1386480009,
    "Tankstellennummer": "TD029793",
    "Name": "PRENZLAU STETTINER STR. 100",
    "Address": "STETTINER STR. 100",
    "Address__1": "",
    "City": "PRENZLAU",
    "Postcode": 17291,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03984-2888",
    "24 hour?": "FALSE",
    "Latitude": 53.3302,
    "Longitude": 13.8679,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.3302,13.8679",
    "IsOpen24Hours": false,
    "id": "53.3302,13.8679"
  },
  {
    "ID": 381758096,
    "Tankstellennummer": "TD032581",
    "Name": "FELDBERG ALTER LANDWEG 5",
    "Address": "ALTER LANDWEG 5",
    "Address__1": "",
    "City": "FELDBERG",
    "Postcode": 17258,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039831-20034",
    "24 hour?": "FALSE",
    "Latitude": 53.3317,
    "Longitude": 13.4311,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.3317,13.4311",
    "IsOpen24Hours": false,
    "id": "53.3317,13.4311"
  },
  {
    "ID": 1113726195,
    "Tankstellennummer": "TD037150",
    "Name": "BUCHHOLZ HAMBURGER STR. 20",
    "Address": "HAMBURGER STR. 20",
    "Address__1": "",
    "City": "BUCHHOLZ",
    "Postcode": 21244,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04181-35188",
    "24 hour?": "FALSE",
    "Latitude": 53.3319,
    "Longitude": 9.8823,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.3319,9.8823",
    "IsOpen24Hours": false,
    "id": "53.3319,9.8823"
  },
  {
    "ID": 1167,
    "Tankstellennummer": "0F295",
    "Name": "Aral Autohof Winsen, Max-Planck-Straße 18",
    "Address": "Max-Planck-Straße 18",
    "Address__1": "",
    "City": "Winsen",
    "Postcode": 21423,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4171690795,
    "24 hour?": "TRUE",
    "Latitude": 53.339323,
    "Longitude": 10.219539,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.339323,10.219539",
    "IsOpen24Hours": true,
    "id": "53.339323,10.219539"
  },
  {
    "ID": 1018302035,
    "Tankstellennummer": "TD041913",
    "Name": "EMDEN PETKUMER STR. 284",
    "Address": "PETKUMER STR. 284",
    "Address__1": "",
    "City": "EMDEN",
    "Postcode": 26725,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04921-6888855",
    "24 hour?": "FALSE",
    "Latitude": 53.3407,
    "Longitude": 7.2266,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.3407,7.2266",
    "IsOpen24Hours": false,
    "id": "53.3407,7.2266"
  },
  {
    "ID": 1278432008,
    "Tankstellennummer": "0FX26",
    "Name": "BUCHHOLZ",
    "Address": "Dibberser Strasse 30",
    "Address__1": "",
    "City": "Buchholz",
    "Postcode": 21244,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 41815110,
    "24 hour?": "TRUE",
    "Latitude": 53.362712,
    "Longitude": 9.873121,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.362712,9.873121",
    "IsOpen24Hours": true,
    "id": "53.362712,9.873121"
  },
  {
    "ID": 1294208882,
    "Tankstellennummer": "TD029744",
    "Name": "STOLPE RTK SUED 4",
    "Address": "RTK Sued 4",
    "Address__1": "",
    "City": "STOLPE",
    "Postcode": 19372,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038725-20216",
    "24 hour?": "FALSE",
    "Latitude": 53.367,
    "Longitude": 11.7271,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.367,11.7271",
    "IsOpen24Hours": false,
    "id": "53.367,11.7271"
  },
  {
    "ID": 1664335857,
    "Tankstellennummer": "TD029702",
    "Name": "STOLPE RTK NORD 1",
    "Address": "RTK Nord 1",
    "Address__1": "",
    "City": "STOLPE",
    "Postcode": 19372,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038725-169975",
    "24 hour?": "FALSE",
    "Latitude": 53.3677,
    "Longitude": 11.7319,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.3677,11.7319",
    "IsOpen24Hours": false,
    "id": "53.3677,11.7319"
  },
  {
    "ID": 2553,
    "Tankstellennummer": "0FC92",
    "Name": "Aral Tankstelle Neustrelitz, Hohenzieritzer Str. 34",
    "Address": "Hohenzieritzer Straße 34",
    "Address__1": "",
    "City": "Neustrelitz",
    "Postcode": 17235,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3981203295,
    "24 hour?": "TRUE",
    "Latitude": 53.372415,
    "Longitude": 13.074242,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.372415,13.074242",
    "IsOpen24Hours": true,
    "id": "53.372415,13.074242"
  },
  {
    "ID": 1091,
    "Tankstellennummer": "0F241",
    "Name": "Aral Tankstelle Lauenburg, Hamburger Straße 30",
    "Address": "Hamburger Straße 30",
    "Address__1": "",
    "City": "Lauenburg",
    "Postcode": 21481,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 41533120,
    "24 hour?": "FALSE",
    "Latitude": 53.374186,
    "Longitude": 10.550394,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.374186,10.550394",
    "IsOpen24Hours": false,
    "id": "53.374186,10.550394"
  },
  {
    "ID": 1104,
    "Tankstellennummer": "0F248",
    "Name": "Aral Tankstelle Gnarrenburg, Hindenburgstr. 10",
    "Address": "Hindenburgstraße 10",
    "Address__1": "",
    "City": "Gnarrenburg",
    "Postcode": 27442,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4763627155,
    "24 hour?": "FALSE",
    "Latitude": 53.376417,
    "Longitude": 9.006809,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.376417,9.006809",
    "IsOpen24Hours": false,
    "id": "53.376417,9.006809"
  },
  {
    "ID": 1055,
    "Tankstellennummer": "0F214",
    "Name": "Aral Tankstelle Seevetal, Jesteburger Straße 12",
    "Address": "Jesteburger Straße 12",
    "Address__1": "",
    "City": "Seevetal",
    "Postcode": 21218,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 41052804,
    "24 hour?": "TRUE",
    "Latitude": 53.38245,
    "Longitude": 9.979346,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.38245,9.979346",
    "IsOpen24Hours": true,
    "id": "53.38245,9.979346"
  },
  {
    "ID": 1389320904,
    "Tankstellennummer": 610327,
    "Name": "WESTFALEN, VAREL",
    "Address": "Bockhorner Str. 78",
    "Address__1": "",
    "City": "VAREL",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.3849,
    "Longitude": 8.05711,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.3849,8.05711",
    "IsOpen24Hours": false,
    "id": "53.3849,8.05711"
  },
  {
    "ID": 292945072,
    "Tankstellennummer": "TD034827",
    "Name": "BOIZENBURG AM KEESBOOM 2",
    "Address": "AM KEESBOOM 2",
    "Address__1": "",
    "City": "BOIZENBURG",
    "Postcode": 19258,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038847-38152",
    "24 hour?": "FALSE",
    "Latitude": 53.3858,
    "Longitude": 10.7352,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.3858,10.7352",
    "IsOpen24Hours": false,
    "id": "53.3858,10.7352"
  },
  {
    "ID": 1128,
    "Tankstellennummer": "0F263",
    "Name": "Aral Tankstelle Seevetal, Winsener Str. 25/Homsstr.",
    "Address": "Winsener Str. 25",
    "Address__1": "",
    "City": "Seevetal",
    "Postcode": 21220,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 410582801,
    "24 hour?": "FALSE",
    "Latitude": 53.390645,
    "Longitude": 10.045243,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.390645,10.045243",
    "IsOpen24Hours": false,
    "id": "53.390645,10.045243"
  },
  {
    "ID": 2241,
    "Tankstellennummer": "0FA76",
    "Name": "Aral Tankstelle Emden, Auricher Straße 216",
    "Address": "Auricher Straße 216",
    "Address__1": "",
    "City": "Emden",
    "Postcode": 26721,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 492142563,
    "24 hour?": "FALSE",
    "Latitude": 53.391055,
    "Longitude": 7.207846,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.391055,7.207846",
    "IsOpen24Hours": false,
    "id": "53.391055,7.207846"
  },
  {
    "ID": 2204,
    "Tankstellennummer": "0FA47",
    "Name": "Aral Tankstelle Großefehn, Auricher Landstrasse 16",
    "Address": "Auricher Landstraße 16",
    "Address__1": "",
    "City": "Großefehn",
    "Postcode": 26629,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4943990303,
    "24 hour?": "FALSE",
    "Latitude": 53.396711,
    "Longitude": 7.568814,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.396711,7.568814",
    "IsOpen24Hours": false,
    "id": "53.396711,7.568814"
  },
  {
    "ID": 3465,
    "Tankstellennummer": "0FL85",
    "Name": "Aral Tankstelle Stadland-Rodenkirchen, Lange Str. 32",
    "Address": "Lange Straße 32",
    "Address__1": "",
    "City": "Stadland-Rodenkirche",
    "Postcode": 26935,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 47322663,
    "24 hour?": "FALSE",
    "Latitude": 53.405734,
    "Longitude": 8.459758,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.405734,8.459758",
    "IsOpen24Hours": false,
    "id": "53.405734,8.459758"
  },
  {
    "ID": 1604,
    "Tankstellennummer": "0F638",
    "Name": "Aral Tankstelle Wiesmoor, Hauptstraße 205",
    "Address": "Hauptstraße 205",
    "Address__1": "",
    "City": "Wiesmoor",
    "Postcode": 26639,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 49445150,
    "24 hour?": "FALSE",
    "Latitude": 53.409412,
    "Longitude": 7.727903,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.409412,7.727903",
    "IsOpen24Hours": false,
    "id": "53.409412,7.727903"
  },
  {
    "ID": 1187058600,
    "Tankstellennummer": "TD041921",
    "Name": "WIESMOOR HAUPTSTR. 127",
    "Address": "HAUPTSTR. 127",
    "Address__1": "",
    "City": "WIESMOOR",
    "Postcode": 26639,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04944-9154010",
    "24 hour?": "FALSE",
    "Latitude": 53.4203,
    "Longitude": 7.7447,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.4203,7.7447",
    "IsOpen24Hours": false,
    "id": "53.4203,7.7447"
  },
  {
    "ID": 1832665233,
    "Tankstellennummer": 610328,
    "Name": "WESTFALEN, ZETEL",
    "Address": "Jacob-Borchers-Str. 49a",
    "Address__1": "",
    "City": "Zetel",
    "Postcode": 26340,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "04453/4102",
    "24 hour?": "FALSE",
    "Latitude": 53.4219,
    "Longitude": 7.98121,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.4219,7.98121",
    "IsOpen24Hours": false,
    "id": "53.4219,7.98121"
  },
  {
    "ID": 1486750845,
    "Tankstellennummer": "0FU93",
    "Name": "HAMBURG, BAT HARBURGER BERGE O",
    "Address": "A7",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 21077,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4076115317,
    "24 hour?": "TRUE",
    "Latitude": 53.423469,
    "Longitude": 9.967088,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.423469,9.967088",
    "IsOpen24Hours": true,
    "id": "53.423469,9.967088"
  },
  {
    "ID": 775248808,
    "Tankstellennummer": "0FY24",
    "Name": "HAMBURG",
    "Address": "A7",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 21077,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 407001010,
    "24 hour?": "TRUE",
    "Latitude": 53.424365,
    "Longitude": 9.96279,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.424365,9.96279",
    "IsOpen24Hours": true,
    "id": "53.424365,9.96279"
  },
  {
    "ID": 2594,
    "Tankstellennummer": "0FD24",
    "Name": "Aral Tankstelle Parchim, Ludwigsluster Chaussee 6",
    "Address": "Ludwigsluster Chaussee 24",
    "Address__1": "",
    "City": "Parchim",
    "Postcode": 19370,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3871443141,
    "24 hour?": "FALSE",
    "Latitude": 53.426446,
    "Longitude": 11.81927,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.426446,11.81927",
    "IsOpen24Hours": false,
    "id": "53.426446,11.81927"
  },
  {
    "ID": 659833624,
    "Tankstellennummer": "TD029611",
    "Name": "PARCHIM WESTRING 40",
    "Address": "WESTRING 40",
    "Address__1": "",
    "City": "PARCHIM",
    "Postcode": 19370,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03871-441021",
    "24 hour?": "FALSE",
    "Latitude": 53.4275,
    "Longitude": 11.8273,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.4275,11.8273",
    "IsOpen24Hours": false,
    "id": "53.4275,11.8273"
  },
  {
    "ID": 3463,
    "Tankstellennummer": "0FL84",
    "Name": "Aral Tankstelle Krummhörn, Burgstr. 2",
    "Address": "Burgstraße 2",
    "Address__1": "",
    "City": "Krummhörn",
    "Postcode": 26736,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4923990108,
    "24 hour?": "FALSE",
    "Latitude": 53.436115,
    "Longitude": 7.093333,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.436115,7.093333",
    "IsOpen24Hours": false,
    "id": "53.436115,7.093333"
  },
  {
    "ID": 567362402,
    "Tankstellennummer": "TD029686",
    "Name": "HAGENOW TODDINER CH. 5",
    "Address": "TODDINER CH. 5",
    "Address__1": "",
    "City": "HAGENOW",
    "Postcode": 19230,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03883-722207",
    "24 hour?": "FALSE",
    "Latitude": 53.4366,
    "Longitude": 11.1747,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.4366,11.1747",
    "IsOpen24Hours": false,
    "id": "53.4366,11.1747"
  },
  {
    "ID": 1074,
    "Tankstellennummer": "0F228",
    "Name": "Aral Tankstelle Hamburg, Bremer Straße 300",
    "Address": "Bremer Straße 300",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 21077,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 407604241,
    "24 hour?": "TRUE",
    "Latitude": 53.438596,
    "Longitude": 9.936119,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.438596,9.936119",
    "IsOpen24Hours": true,
    "id": "53.438596,9.936119"
  },
  {
    "ID": 2734,
    "Tankstellennummer": "0FE44",
    "Name": "Aral Autohof Fahrbinde, Ahornstraße 1",
    "Address": "Ahornstraße 1",
    "Address__1": "",
    "City": "Rastow",
    "Postcode": 19077,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3875388842,
    "24 hour?": "TRUE",
    "Latitude": 53.438969,
    "Longitude": 11.489584,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.438969,11.489584",
    "IsOpen24Hours": true,
    "id": "53.438969,11.489584"
  },
  {
    "ID": 329257258,
    "Tankstellennummer": "TD037077",
    "Name": "PARCHIM SCHWERINER CHAUSSEE",
    "Address": "SCHWERINER CHAUSSEE 45",
    "Address__1": "",
    "City": "PARCHIM",
    "Postcode": 19370,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03871-267297",
    "24 hour?": "FALSE",
    "Latitude": 53.4395,
    "Longitude": 11.8454,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.4395,11.8454",
    "IsOpen24Hours": false,
    "id": "53.4395,11.8454"
  },
  {
    "ID": 2782,
    "Tankstellennummer": "0FG75",
    "Name": "Aral Tankstelle Geesthacht, Hansastr. 44",
    "Address": "Hansastraße 44",
    "Address__1": "",
    "City": "Geesthacht",
    "Postcode": 21502,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4152847546,
    "24 hour?": "FALSE",
    "Latitude": 53.440435,
    "Longitude": 10.39303,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.440435,10.39303",
    "IsOpen24Hours": false,
    "id": "53.440435,10.39303"
  },
  {
    "ID": 2781,
    "Tankstellennummer": "0FG74",
    "Name": "Aral Tankstelle Geesthacht, Geesthachter Str. 24-28",
    "Address": "Geesthachter Straße 24-28",
    "Address__1": "",
    "City": "Geesthacht",
    "Postcode": 21502,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 41522591,
    "24 hour?": "TRUE",
    "Latitude": 53.4406,
    "Longitude": 10.365622,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.4406,10.365622",
    "IsOpen24Hours": true,
    "id": "53.4406,10.365622"
  },
  {
    "ID": 2536,
    "Tankstellennummer": "0FC76",
    "Name": "Aral Tankstelle Lübz, Plauer Chaussee / B 191",
    "Address": "Plauer Chaussee 18",
    "Address__1": "",
    "City": "Lübz",
    "Postcode": 19386,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3873122205,
    "24 hour?": "FALSE",
    "Latitude": 53.46326,
    "Longitude": 12.046955,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.46326,12.046955",
    "IsOpen24Hours": false,
    "id": "53.46326,12.046955"
  },
  {
    "ID": 2197,
    "Tankstellennummer": "0FA39",
    "Name": "Aral Tankstelle Aurich, Leerer Landstraße 27",
    "Address": "Leerer Landstraße 27",
    "Address__1": "",
    "City": "Aurich",
    "Postcode": 26603,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 49412385,
    "24 hour?": "FALSE",
    "Latitude": 53.464677,
    "Longitude": 7.487707,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.464677,7.487707",
    "IsOpen24Hours": false,
    "id": "53.464677,7.487707"
  },
  {
    "ID": 1062,
    "Tankstellennummer": "0F219",
    "Name": "Aral Tankstelle Buxtehude, Apensener Straße 20",
    "Address": "Apensener Straße 20",
    "Address__1": "",
    "City": "Buxtehude",
    "Postcode": 21614,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 416184850,
    "24 hour?": "FALSE",
    "Latitude": 53.46648,
    "Longitude": 9.67661,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.46648,9.67661",
    "IsOpen24Hours": false,
    "id": "53.46648,9.67661"
  },
  {
    "ID": 1150,
    "Tankstellennummer": "0F282",
    "Name": "Aral Tankstelle Buxtehude, Konrad-Adenauer-Allee 3 a",
    "Address": "Konrad-Adenauer-Allee 3 a",
    "Address__1": "",
    "City": "Buxtehude",
    "Postcode": 21614,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4161645637,
    "24 hour?": "FALSE",
    "Latitude": 53.470105,
    "Longitude": 9.713646,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.470105,9.713646",
    "IsOpen24Hours": false,
    "id": "53.470105,9.713646"
  },
  {
    "ID": 778714498,
    "Tankstellennummer": "TD034124",
    "Name": "PLAU AM SEE PLOENER STR. 2",
    "Address": "PLOENER STR. 2",
    "Address__1": "",
    "City": "PLAU AM SEE",
    "Postcode": 19395,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038735-40270",
    "24 hour?": "FALSE",
    "Latitude": 53.4704,
    "Longitude": 12.2643,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.4704,12.2643",
    "IsOpen24Hours": false,
    "id": "53.4704,12.2643"
  },
  {
    "ID": 3304,
    "Tankstellennummer": "0FK62",
    "Name": "Aral Tankstelle Hamburg, Stader Str. 294-296",
    "Address": "Stader Straße 294-296",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 21075,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 407928704,
    "24 hour?": "TRUE",
    "Latitude": 53.470872,
    "Longitude": 9.923733,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.470872,9.923733",
    "IsOpen24Hours": true,
    "id": "53.470872,9.923733"
  },
  {
    "ID": 243262343,
    "Tankstellennummer": "TD036988",
    "Name": "HAMBURG CUXHAVENER STR. 380",
    "Address": "CUXHAVENER STR. 380",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 21149,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-70380510",
    "24 hour?": "FALSE",
    "Latitude": 53.473,
    "Longitude": 9.8468,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.473,9.8468",
    "IsOpen24Hours": false,
    "id": "53.473,9.8468"
  },
  {
    "ID": 1379028819,
    "Tankstellennummer": 610313,
    "Name": "WESTFALEN, NORDENHAM",
    "Address": "Butjadinger Str. 6",
    "Address__1": "",
    "City": "Nordenham",
    "Postcode": 26954,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "04731/4358",
    "24 hour?": "FALSE",
    "Latitude": 53.4778,
    "Longitude": 8.45039,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.4778,8.45039",
    "IsOpen24Hours": false,
    "id": "53.4778,8.45039"
  },
  {
    "ID": 264688012,
    "Tankstellennummer": "TD003673",
    "Name": "HAMBURG NEUWIEDENTHALER STR. 1",
    "Address": "NEUWIEDENTHALER STR.122",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 21147,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-7965151",
    "24 hour?": "FALSE",
    "Latitude": 53.4783,
    "Longitude": 9.8846,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.4783,9.8846",
    "IsOpen24Hours": false,
    "id": "53.4783,9.8846"
  },
  {
    "ID": 155521116,
    "Tankstellennummer": "TD030411",
    "Name": "MALCHOW ROSTOCKER STR. 42",
    "Address": "ROSTOCKER STR. 42",
    "Address__1": "",
    "City": "MALCHOW",
    "Postcode": 17213,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039932-12695",
    "24 hour?": "FALSE",
    "Latitude": 53.481,
    "Longitude": 12.4211,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.481,12.4211",
    "IsOpen24Hours": false,
    "id": "53.481,12.4211"
  },
  {
    "ID": 2577,
    "Tankstellennummer": "0FD13",
    "Name": "Aral Tankstelle Malchow, Rostocker Straße 15",
    "Address": "Rostocker Straße 15",
    "Address__1": "",
    "City": "Malchow",
    "Postcode": 17213,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3993214757,
    "24 hour?": "TRUE",
    "Latitude": 53.481403,
    "Longitude": 12.421478,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.481403,12.421478",
    "IsOpen24Hours": true,
    "id": "53.481403,12.421478"
  },
  {
    "ID": 3275,
    "Tankstellennummer": "0FK44",
    "Name": "Aral Tankstelle Hamburg, Curslacker Neuer Deich 34",
    "Address": "Curslacker Neuer Deich 34",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 21029,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4072698676,
    "24 hour?": "TRUE",
    "Latitude": 53.481775,
    "Longitude": 10.208122,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.481775,10.208122",
    "IsOpen24Hours": true,
    "id": "53.481775,10.208122"
  },
  {
    "ID": 2212,
    "Tankstellennummer": "0FA53",
    "Name": "Aral Tankstelle Aurich, Esenser Str.109",
    "Address": "Esenser Straße 109-111",
    "Address__1": "",
    "City": "Aurich",
    "Postcode": 26607,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 494171435,
    "24 hour?": "TRUE",
    "Latitude": 53.4824,
    "Longitude": 7.490395,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.4824,7.490395",
    "IsOpen24Hours": true,
    "id": "53.4824,7.490395"
  },
  {
    "ID": 1158,
    "Tankstellennummer": "0F287",
    "Name": "Aral Tankstelle Hamburg, Nettelnburger Landweg 2",
    "Address": "Nettelnburger Landweg 2",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 21035,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4073596311,
    "24 hour?": "TRUE",
    "Latitude": 53.486699,
    "Longitude": 10.183866,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.486699,10.183866",
    "IsOpen24Hours": true,
    "id": "53.486699,10.183866"
  },
  {
    "ID": 1135,
    "Tankstellennummer": "0F270",
    "Name": "Aral Tankstelle Nordenham, Bahnhofstr. 62",
    "Address": "Bahnhofstraße 62",
    "Address__1": "",
    "City": "Nordenham",
    "Postcode": 26954,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 473122702,
    "24 hour?": "TRUE",
    "Latitude": 53.486812,
    "Longitude": 8.475246,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.486812,8.475246",
    "IsOpen24Hours": true,
    "id": "53.486812,8.475246"
  },
  {
    "ID": 1182,
    "Tankstellennummer": "0F301",
    "Name": "Aral Autohof Hamburg, Wilhelm-Iwan-Ring 1",
    "Address": "Wilhelm-Iwan-Ring 1",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 21035,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4073431926,
    "24 hour?": "TRUE",
    "Latitude": 53.489817,
    "Longitude": 10.115083,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.489817,10.115083",
    "IsOpen24Hours": true,
    "id": "53.489817,10.115083"
  },
  {
    "ID": 2804,
    "Tankstellennummer": "0FG91",
    "Name": "Aral Tankstelle Hamburg, Otto-Brenner-Str. 6",
    "Address": "Otto-Brenner-Straße 6",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 21109,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 407542478,
    "24 hour?": "TRUE",
    "Latitude": 53.490015,
    "Longitude": 10.019005,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.490015,10.019005",
    "IsOpen24Hours": true,
    "id": "53.490015,10.019005"
  },
  {
    "ID": 321751882,
    "Tankstellennummer": "0FQ71",
    "Name": "BANDENITZ",
    "Address": "Hauptstraße 41",
    "Address__1": "",
    "City": "Bandenitz",
    "Postcode": 19230,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 388505344,
    "24 hour?": "TRUE",
    "Latitude": 53.49134,
    "Longitude": 11.28225,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.49134,11.28225",
    "IsOpen24Hours": true,
    "id": "53.49134,11.28225"
  },
  {
    "ID": 35224532,
    "Tankstellennummer": "TD038927",
    "Name": "NORDENHAM FRIEDRICH-EBERT-STR.",
    "Address": "FRIEDRICH-EBERT-STR. 80",
    "Address__1": "",
    "City": "NORDENHAM",
    "Postcode": 26954,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04731-21313",
    "24 hour?": "FALSE",
    "Latitude": 53.4915,
    "Longitude": 8.4863,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.4915,8.4863",
    "IsOpen24Hours": false,
    "id": "53.4915,8.4863"
  },
  {
    "ID": 1480531886,
    "Tankstellennummer": "0FX58",
    "Name": "HAMBURG",
    "Address": "A1",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 21109,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 407544131,
    "24 hour?": "TRUE",
    "Latitude": 53.4938103,
    "Longitude": 10.0339526,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.4938103,10.0339526",
    "IsOpen24Hours": true,
    "id": "53.4938103,10.0339526"
  },
  {
    "ID": 1075,
    "Tankstellennummer": "0F229",
    "Name": "Aral Tankstelle Hamburg, Mengestraße 14",
    "Address": "Mengestraße 14",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 21107,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 407534767,
    "24 hour?": "TRUE",
    "Latitude": 53.500284,
    "Longitude": 9.994158,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.500284,9.994158",
    "IsOpen24Hours": true,
    "id": "53.500284,9.994158"
  },
  {
    "ID": 161547380,
    "Tankstellennummer": "TD030353",
    "Name": "STRASBURG AM WAETHERING NR. 2",
    "Address": "AM WAETHERING NR. 2",
    "Address__1": "",
    "City": "STRASBURG",
    "Postcode": 17335,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039753-22077",
    "24 hour?": "FALSE",
    "Latitude": 53.5021,
    "Longitude": 13.7339,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5021,13.7339",
    "IsOpen24Hours": false,
    "id": "53.5021,13.7339"
  },
  {
    "ID": 2719,
    "Tankstellennummer": "0FE33",
    "Name": "Aral Autohof Wittenburg, Rudolf-Diesel-Str. 02",
    "Address": "Rudolf-Diesel-Straße 02",
    "Address__1": "",
    "City": "Wittenburg",
    "Postcode": 19243,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3885252182,
    "24 hour?": "TRUE",
    "Latitude": 53.502251,
    "Longitude": 11.097956,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.502251,11.097956",
    "IsOpen24Hours": true,
    "id": "53.502251,11.097956"
  },
  {
    "ID": 808489857,
    "Tankstellennummer": 5016,
    "Name": "Agip Pasewalk",
    "Address": "Stettiner Chaussee",
    "Address__1": "",
    "City": "Pasewalk",
    "Postcode": 17309,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 39732101",
    "24 hour?": "TRUE",
    "Latitude": 53.50287725,
    "Longitude": 14.00323994,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.50287725,14.00323994",
    "IsOpen24Hours": true,
    "id": "53.50287725,14.00323994"
  },
  {
    "ID": 789359517,
    "Tankstellennummer": 610380,
    "Name": "WESTFALEN, NORDENHAM",
    "Address": "Atenser Allee 141",
    "Address__1": "",
    "City": "Nordenham",
    "Postcode": 26954,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "04731/ 249066",
    "24 hour?": "FALSE",
    "Latitude": 53.5032,
    "Longitude": 8.47753,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5032,8.47753",
    "IsOpen24Hours": false,
    "id": "53.5032,8.47753"
  },
  {
    "ID": 567095486,
    "Tankstellennummer": "TD030072",
    "Name": "WITTENBURG HAGENOWER CHAUSSEE",
    "Address": "HAGENOWER CHAUSSEE 1",
    "Address__1": "",
    "City": "WITTENBURG",
    "Postcode": 19243,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038852-50132",
    "24 hour?": "FALSE",
    "Latitude": 53.5057,
    "Longitude": 11.0799,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5057,11.0799",
    "IsOpen24Hours": false,
    "id": "53.5057,11.0799"
  },
  {
    "ID": 524048586,
    "Tankstellennummer": "0FQ58",
    "Name": "HAMBURG",
    "Address": "Amandus-Stubbe-Straße 6",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22113,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4087082555,
    "24 hour?": "TRUE",
    "Latitude": 53.505743,
    "Longitude": 10.089665,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.505743,10.089665",
    "IsOpen24Hours": true,
    "id": "53.505743,10.089665"
  },
  {
    "ID": 1022305419,
    "Tankstellennummer": "TD038992",
    "Name": "BREMERHAVEN AM LUNEDEICH 136",
    "Address": "AM LUNEDEICH 136",
    "Address__1": "",
    "City": "BREMERHAVEN",
    "Postcode": 27572,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0471-80994545",
    "24 hour?": "FALSE",
    "Latitude": 53.5061,
    "Longitude": 8.589,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5061,8.589",
    "IsOpen24Hours": false,
    "id": "53.5061,8.589"
  },
  {
    "ID": 1032787333,
    "Tankstellennummer": "TD003855",
    "Name": "HAMBURG NEUHOEFER STR. 28",
    "Address": "NEUHOEFER STR. 28",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 21107,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-7532295",
    "24 hour?": "FALSE",
    "Latitude": 53.5108,
    "Longitude": 9.9845,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5108,9.9845",
    "IsOpen24Hours": false,
    "id": "53.5108,9.9845"
  },
  {
    "ID": 1120,
    "Tankstellennummer": "0F256",
    "Name": "Aral Tankstelle Schwarzenbek, Möllner Straße 85",
    "Address": "Möllner Straße 85",
    "Address__1": "",
    "City": "Schwarzenbek",
    "Postcode": 21493,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4151895430,
    "24 hour?": "TRUE",
    "Latitude": 53.51153,
    "Longitude": 10.489115,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.51153,10.489115",
    "IsOpen24Hours": true,
    "id": "53.51153,10.489115"
  },
  {
    "ID": 242145087,
    "Tankstellennummer": "TD040030",
    "Name": "HAMBURG ANDREAS MEYER STRASSE",
    "Address": "ANDREAS MEYER STRASSE 63",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 22113,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-53022628",
    "24 hour?": "FALSE",
    "Latitude": 53.5124,
    "Longitude": 10.0889,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5124,10.0889",
    "IsOpen24Hours": false,
    "id": "53.5124,10.0889"
  },
  {
    "ID": 691221945,
    "Tankstellennummer": "TD038778",
    "Name": "HAMBURG ALUMINIUMSTR. 5",
    "Address": "ALUMINIUMSTR. 5",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 21129,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-30239835",
    "24 hour?": "FALSE",
    "Latitude": 53.5173,
    "Longitude": 9.8903,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5173,9.8903",
    "IsOpen24Hours": false,
    "id": "53.5173,9.8903"
  },
  {
    "ID": 1160,
    "Tankstellennummer": "0F288",
    "Name": "Aral Tankstelle Bremerhaven, Weserstraße 239-247",
    "Address": "Weserstraße 239",
    "Address__1": "",
    "City": "Bremerhaven",
    "Postcode": 27572,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4717004265,
    "24 hour?": "TRUE",
    "Latitude": 53.518363,
    "Longitude": 8.591478,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.518363,8.591478",
    "IsOpen24Hours": true,
    "id": "53.518363,8.591478"
  },
  {
    "ID": 2222,
    "Tankstellennummer": "0FA61",
    "Name": "Aral Tankstelle Wilhelmshaven, Gökerstraße/Ecke Peterstr",
    "Address": "Gökerstraße/Ecke Peterstr",
    "Address__1": "",
    "City": "Wilhelmshaven",
    "Postcode": 26384,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4421994353,
    "24 hour?": "TRUE",
    "Latitude": 53.520422,
    "Longitude": 8.12359,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.520422,8.12359",
    "IsOpen24Hours": true,
    "id": "53.520422,8.12359"
  },
  {
    "ID": 1032,
    "Tankstellennummer": "0F196",
    "Name": "Aral Tankstelle Reinbek, Hermann-Körner-Str. 51",
    "Address": "Hermann-Körner-Straße 51",
    "Address__1": "",
    "City": "Reinbek",
    "Postcode": 21465,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 407279460,
    "24 hour?": "FALSE",
    "Latitude": 53.522228,
    "Longitude": 10.244515,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.522228,10.244515",
    "IsOpen24Hours": false,
    "id": "53.522228,10.244515"
  },
  {
    "ID": 747453814,
    "Tankstellennummer": "TD040469",
    "Name": "WILHELMSHAVEN BANTER WEG 121",
    "Address": "BANTER WEG 121",
    "Address__1": "",
    "City": "WILHELMSHAVEN",
    "Postcode": 26389,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04421-9644884",
    "24 hour?": "FALSE",
    "Latitude": 53.5224,
    "Longitude": 8.0916,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5224,8.0916",
    "IsOpen24Hours": false,
    "id": "53.5224,8.0916"
  },
  {
    "ID": 1077,
    "Tankstellennummer": "0F009",
    "Name": "Aral Tankstelle Hamburg, Ostfrieslandstraße 80",
    "Address": "Ostfrieslandstraße 80",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 21129,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 407428605,
    "24 hour?": "TRUE",
    "Latitude": 53.52658,
    "Longitude": 9.884868,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.52658,9.884868",
    "IsOpen24Hours": true,
    "id": "53.52658,9.884868"
  },
  {
    "ID": 1176,
    "Tankstellennummer": "0FG22",
    "Name": "Aral Tankstelle Wilhelmshaven, Ernst-Barlach-Straße 1",
    "Address": "Ernst-Barlach-Straße 1",
    "Address__1": "",
    "City": "Wilhelmshaven",
    "Postcode": 26389,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 442171688,
    "24 hour?": "TRUE",
    "Latitude": 53.526613,
    "Longitude": 8.065767,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.526613,8.065767",
    "IsOpen24Hours": true,
    "id": "53.526613,8.065767"
  },
  {
    "ID": 1028,
    "Tankstellennummer": "0F193",
    "Name": "Aral Tankstelle Hamburg, Peutestraße 2",
    "Address": "Peutestraße 2",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 20539,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 407899840,
    "24 hour?": "TRUE",
    "Latitude": 53.527635,
    "Longitude": 10.024657,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.527635,10.024657",
    "IsOpen24Hours": true,
    "id": "53.527635,10.024657"
  },
  {
    "ID": 2275,
    "Tankstellennummer": "0FB03",
    "Name": "Aral Tankstelle Wilhelmshaven, Bismarckstr. 147",
    "Address": "Bismarckstraße 147",
    "Address__1": "",
    "City": "Wilhelmshaven",
    "Postcode": 26382,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 442133455,
    "24 hour?": "FALSE",
    "Latitude": 53.528244,
    "Longitude": 8.114908,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.528244,8.114908",
    "IsOpen24Hours": false,
    "id": "53.528244,8.114908"
  },
  {
    "ID": 1109,
    "Tankstellennummer": "0F249",
    "Name": "Aral Tankstelle Bremerhaven, Schiffdorfer Chaussee 120",
    "Address": "Schiffdorfer Chaussee 120",
    "Address__1": "",
    "City": "Bremerhaven",
    "Postcode": 27574,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 47135219,
    "24 hour?": "FALSE",
    "Latitude": 53.528709,
    "Longitude": 8.616031,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.528709,8.616031",
    "IsOpen24Hours": false,
    "id": "53.528709,8.616031"
  },
  {
    "ID": 2777,
    "Tankstellennummer": "0FG71",
    "Name": "Aral Tankstelle Aumühle, Schoenningstedter Str. 1",
    "Address": "Schoenningstedter Str. 1",
    "Address__1": "",
    "City": "Aumühle",
    "Postcode": 21521,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4104969968,
    "24 hour?": "FALSE",
    "Latitude": 53.530083,
    "Longitude": 10.311946,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.530083,10.311946",
    "IsOpen24Hours": false,
    "id": "53.530083,10.311946"
  },
  {
    "ID": 2588,
    "Tankstellennummer": "0FD21",
    "Name": "Aral Tankstelle Neubrandenburg, Neustrelitzer Str. 118",
    "Address": "Neustrelitzer Straße 118",
    "Address__1": "",
    "City": "Neubrandenburg",
    "Postcode": 17033,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3953687733,
    "24 hour?": "TRUE",
    "Latitude": 53.530213,
    "Longitude": 13.261818,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.530213,13.261818",
    "IsOpen24Hours": true,
    "id": "53.530213,13.261818"
  },
  {
    "ID": 282185140,
    "Tankstellennummer": "TD036848",
    "Name": "BREMERHAVEN SCHIFFDORFER CHAUS",
    "Address": "SCHIFFDORFER CHAUSSEE 32",
    "Address__1": "",
    "City": "BREMERHAVEN",
    "Postcode": 27574,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0471-35001",
    "24 hour?": "FALSE",
    "Latitude": 53.5304,
    "Longitude": 8.6059,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5304,8.6059",
    "IsOpen24Hours": false,
    "id": "53.5304,8.6059"
  },
  {
    "ID": 1146,
    "Tankstellennummer": "0F280",
    "Name": "Aral Autohof Hamburg, Großmannstraße 195-211",
    "Address": "Großmannstraße 195-211",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 20539,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4078079995,
    "24 hour?": "TRUE",
    "Latitude": 53.536753,
    "Longitude": 10.064204,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.536753,10.064204",
    "IsOpen24Hours": true,
    "id": "53.536753,10.064204"
  },
  {
    "ID": 869420376,
    "Tankstellennummer": 610316,
    "Name": "WESTFALEN, WILHELMSHAVEN",
    "Address": "Goekerstr. 125 a",
    "Address__1": "",
    "City": "WILHELMSHAVEN",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.5385,
    "Longitude": 8.12352,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5385,8.12352",
    "IsOpen24Hours": false,
    "id": "53.5385,8.12352"
  },
  {
    "ID": 59682696,
    "Tankstellennummer": "TD036962",
    "Name": "HAMBURG BILLSTEDTER HAUPTSTRAS",
    "Address": "BILLSTEDTER HAUPTSTRASSE 21",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 22111,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-7314835",
    "24 hour?": "FALSE",
    "Latitude": 53.5416,
    "Longitude": 10.0988,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5416,10.0988",
    "IsOpen24Hours": false,
    "id": "53.5416,10.0988"
  },
  {
    "ID": 2201,
    "Tankstellennummer": "0FA44",
    "Name": "Aral Tankstelle Schortens, An der B 210",
    "Address": "An der alten Bundessstraße 1a",
    "Address__1": "",
    "City": "Schortens",
    "Postcode": 26419,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 44618634,
    "24 hour?": "FALSE",
    "Latitude": 53.542262,
    "Longitude": 7.952522,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.542262,7.952522",
    "IsOpen24Hours": false,
    "id": "53.542262,7.952522"
  },
  {
    "ID": 2808,
    "Tankstellennummer": "0FG95",
    "Name": "Aral Tankstelle Hamburg, Amsinckstr. 48",
    "Address": "Amsinckstraße 48",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 20097,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40230646,
    "24 hour?": "TRUE",
    "Latitude": 53.545969,
    "Longitude": 10.015121,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.545969,10.015121",
    "IsOpen24Hours": true,
    "id": "53.545969,10.015121"
  },
  {
    "ID": 2807,
    "Tankstellennummer": "0FG94",
    "Name": "Aral Tankstelle Hamburg, Koenigstr. 9",
    "Address": "Königstraße 9",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22767,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40313481,
    "24 hour?": "TRUE",
    "Latitude": 53.549591,
    "Longitude": 9.952718,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.549591,9.952718",
    "IsOpen24Hours": true,
    "id": "53.549591,9.952718"
  },
  {
    "ID": 1054,
    "Tankstellennummer": "0F213",
    "Name": "Aral Tankstelle Hamburg, Spaldingstraße 107",
    "Address": "Spaldingstraße 107",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 20097,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40234524,
    "24 hour?": "TRUE",
    "Latitude": 53.550429,
    "Longitude": 10.019964,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.550429,10.019964",
    "IsOpen24Hours": true,
    "id": "53.550429,10.019964"
  },
  {
    "ID": 1036,
    "Tankstellennummer": "0F200",
    "Name": "Aral Tankstelle Hamburg, Eiffestraße 506",
    "Address": "Eiffestraße 506",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 20537,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40213820,
    "24 hour?": "TRUE",
    "Latitude": 53.551589,
    "Longitude": 10.05143,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.551589,10.05143",
    "IsOpen24Hours": true,
    "id": "53.551589,10.05143"
  },
  {
    "ID": 3331,
    "Tankstellennummer": "0FK84",
    "Name": "Aral Tankstelle Bremerhaven, Barkhausenstrasse 50",
    "Address": "Barkhausenstraße 50",
    "Address__1": "",
    "City": "Bremerhaven",
    "Postcode": 27568,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4719413835,
    "24 hour?": "TRUE",
    "Latitude": 53.551878,
    "Longitude": 8.568498,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.551878,8.568498",
    "IsOpen24Hours": true,
    "id": "53.551878,8.568498"
  },
  {
    "ID": 1298070919,
    "Tankstellennummer": "TD036996",
    "Name": "HAMBURG HAMMER LANDSTR. 204",
    "Address": "HAMMER LANDSTR. 204",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 20537,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-211533",
    "24 hour?": "FALSE",
    "Latitude": 53.554,
    "Longitude": 10.0632,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.554,10.0632",
    "IsOpen24Hours": false,
    "id": "53.554,10.0632"
  },
  {
    "ID": 1080,
    "Tankstellennummer": "0F232",
    "Name": "Aral Tankstelle Bremerhaven, Stresemannstraße 118",
    "Address": "Stresemannstraße 118",
    "Address__1": "",
    "City": "Bremerhaven",
    "Postcode": 27576,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 47153080,
    "24 hour?": "TRUE",
    "Latitude": 53.555202,
    "Longitude": 8.593656,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.555202,8.593656",
    "IsOpen24Hours": true,
    "id": "53.555202,8.593656"
  },
  {
    "ID": 499196742,
    "Tankstellennummer": "TD029876",
    "Name": "NEUBRANDENBURG WOLDEGKER STR.",
    "Address": "WOLDEGKER STR. 40",
    "Address__1": "",
    "City": "NEUBRANDENBURG",
    "Postcode": 17036,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0395-37937301",
    "24 hour?": "FALSE",
    "Latitude": 53.5562,
    "Longitude": 13.3128,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5562,13.3128",
    "IsOpen24Hours": false,
    "id": "53.5562,13.3128"
  },
  {
    "ID": 2605,
    "Tankstellennummer": "0FD35",
    "Name": "Aral Tankstelle Neubrandenburg, Woldegker Str. 39",
    "Address": "Woldegker Straße 39",
    "Address__1": "",
    "City": "Neubrandenburg",
    "Postcode": 17036,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3957792285,
    "24 hour?": "TRUE",
    "Latitude": 53.557102,
    "Longitude": 13.310935,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.557102,13.310935",
    "IsOpen24Hours": true,
    "id": "53.557102,13.310935"
  },
  {
    "ID": 703269275,
    "Tankstellennummer": "TD041707",
    "Name": "WILHELMSHAVEN DODOWEG 29",
    "Address": "DODOWEG 29",
    "Address__1": "",
    "City": "WILHELMSHAVEN",
    "Postcode": 26386,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.5585,
    "Longitude": 8.0993,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5585,8.0993",
    "IsOpen24Hours": false,
    "id": "53.5585,8.0993"
  },
  {
    "ID": 233616195,
    "Tankstellennummer": "0FY15",
    "Name": "PROJEKT",
    "Address": "An der alten Bundesstraße 3",
    "Address__1": "",
    "City": "Jever",
    "Postcode": 26441,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 44613002,
    "24 hour?": "FALSE",
    "Latitude": 53.560569,
    "Longitude": 7.91588,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.560569,7.91588",
    "IsOpen24Hours": false,
    "id": "53.560569,7.91588"
  },
  {
    "ID": 1050,
    "Tankstellennummer": "0F209",
    "Name": "Aral Tankstelle Hamburg, Sievekingsallee/Saling 30",
    "Address": "Sievekingsallee/Saling 30",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 20535,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 402502222,
    "24 hour?": "TRUE",
    "Latitude": 53.561393,
    "Longitude": 10.04849,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.561393,10.04849",
    "IsOpen24Hours": true,
    "id": "53.561393,10.04849"
  },
  {
    "ID": 3252,
    "Tankstellennummer": "0FK26",
    "Name": "Aral Tankstelle Hamburg, Schiffbeker Weg 228",
    "Address": "Schiffbeker Weg 228",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22119,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 406532765,
    "24 hour?": "TRUE",
    "Latitude": 53.56198,
    "Longitude": 10.119775,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.56198,10.119775",
    "IsOpen24Hours": true,
    "id": "53.56198,10.119775"
  },
  {
    "ID": 1020,
    "Tankstellennummer": "0F186",
    "Name": "Aral Tankstelle Hamburg, Stresemannstraße 329",
    "Address": "Stresemannstraße 329",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22761,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40857265,
    "24 hour?": "TRUE",
    "Latitude": 53.563814,
    "Longitude": 9.925584,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.563814,9.925584",
    "IsOpen24Hours": true,
    "id": "53.563814,9.925584"
  },
  {
    "ID": 1674400917,
    "Tankstellennummer": 610473,
    "Name": "WESTFALEN, BREMERHAVEN",
    "Address": "Stresemannstr. 253",
    "Address__1": "",
    "City": "Bremerhaven",
    "Postcode": 27576,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "0471/84290",
    "24 hour?": "TRUE",
    "Latitude": 53.5651,
    "Longitude": 8.59509,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.5651,8.59509",
    "IsOpen24Hours": true,
    "id": "53.5651,8.59509"
  },
  {
    "ID": 1283318488,
    "Tankstellennummer": "TD038372",
    "Name": "HAMBURG VON SAUERSTR.",
    "Address": "VON-SAUERSTR. 27",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 22761,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-89064795",
    "24 hour?": "FALSE",
    "Latitude": 53.5656,
    "Longitude": 9.9065,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5656,9.9065",
    "IsOpen24Hours": false,
    "id": "53.5656,9.9065"
  },
  {
    "ID": 2801,
    "Tankstellennummer": "0FG89",
    "Name": "Aral Tankstelle Hamburg, Bundesstr. 38",
    "Address": "Bundesstraße 38",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 20146,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40456460,
    "24 hour?": "TRUE",
    "Latitude": 53.56597,
    "Longitude": 9.979668,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.56597,9.979668",
    "IsOpen24Hours": true,
    "id": "53.56597,9.979668"
  },
  {
    "ID": 1812923226,
    "Tankstellennummer": 610310,
    "Name": "WESTFALEN, WILHELMSHAVEN",
    "Address": "Preussenstr. 2a",
    "Address__1": "",
    "City": "Wilhelmshaven",
    "Postcode": 26388,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "04421/5070792",
    "24 hour?": "TRUE",
    "Latitude": 53.5669,
    "Longitude": 8.09868,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.5669,8.09868",
    "IsOpen24Hours": true,
    "id": "53.5669,8.09868"
  },
  {
    "ID": 1385898030,
    "Tankstellennummer": "TD037036",
    "Name": "HAMBURG MUNDSBURGER DAMM 47",
    "Address": "MUNDSBURGER DAMM 47",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 22087,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-22739104",
    "24 hour?": "FALSE",
    "Latitude": 53.5687,
    "Longitude": 10.0241,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5687,10.0241",
    "IsOpen24Hours": false,
    "id": "53.5687,10.0241"
  },
  {
    "ID": 1069,
    "Tankstellennummer": "0F226",
    "Name": "Aral Tankstelle Barsbüttel, Hauptstraße 4",
    "Address": "Hauptstraße 4",
    "Address__1": "",
    "City": "Barsbüttel",
    "Postcode": 22885,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 406700104,
    "24 hour?": "FALSE",
    "Latitude": 53.569388,
    "Longitude": 10.152557,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.569388,10.152557",
    "IsOpen24Hours": false,
    "id": "53.569388,10.152557"
  },
  {
    "ID": 1864897419,
    "Tankstellennummer": 610478,
    "Name": "WESTFALEN, JEVER",
    "Address": "Bahnhofstr. 41",
    "Address__1": "",
    "City": "Jever",
    "Postcode": 26441,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "04461/2355",
    "24 hour?": "FALSE",
    "Latitude": 53.5704,
    "Longitude": 7.89227,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5704,7.89227",
    "IsOpen24Hours": false,
    "id": "53.5704,7.89227"
  },
  {
    "ID": 3263,
    "Tankstellennummer": "0FK34",
    "Name": "Aral Tankstelle Bremerhaven, Stresemannstr. 310",
    "Address": "Stresemannstraße 310",
    "Address__1": "",
    "City": "Bremerhaven",
    "Postcode": 27580,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 47185150,
    "24 hour?": "TRUE",
    "Latitude": 53.570573,
    "Longitude": 8.598823,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.570573,8.598823",
    "IsOpen24Hours": true,
    "id": "53.570573,8.598823"
  },
  {
    "ID": 1151,
    "Tankstellennummer": "0F283",
    "Name": "Aral Tankstelle Hamburg, Schimmelmannstraße 97",
    "Address": "Schimmelmannstraße 97",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22043,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4065389512,
    "24 hour?": "TRUE",
    "Latitude": 53.572234,
    "Longitude": 10.104974,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.572234,10.104974",
    "IsOpen24Hours": true,
    "id": "53.572234,10.104974"
  },
  {
    "ID": 1040,
    "Tankstellennummer": "0F204",
    "Name": "Aral Tankstelle Hamburg, Winterhuder Weg 25",
    "Address": "Winterhuder Weg 25",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22085,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 402207516,
    "24 hour?": "TRUE",
    "Latitude": 53.573575,
    "Longitude": 10.022581,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.573575,10.022581",
    "IsOpen24Hours": true,
    "id": "53.573575,10.022581"
  },
  {
    "ID": 2206,
    "Tankstellennummer": "0FA48",
    "Name": "Aral Tankstelle Wittmund, Jeverstr. 9",
    "Address": "Jeverstraße 9",
    "Address__1": "",
    "City": "Wittmund",
    "Postcode": 26409,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 44625511,
    "24 hour?": "FALSE",
    "Latitude": 53.576378,
    "Longitude": 7.792324,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.576378,7.792324",
    "IsOpen24Hours": false,
    "id": "53.576378,7.792324"
  },
  {
    "ID": 31229307,
    "Tankstellennummer": 610250,
    "Name": "WESTFALEN, HAMBURG",
    "Address": "Wandsbeker Zollstr. 117-123",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22041,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "+49 40 68949450",
    "24 hour?": "FALSE",
    "Latitude": 53.5771,
    "Longitude": 10.0838,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5771,10.0838",
    "IsOpen24Hours": false,
    "id": "53.5771,10.0838"
  },
  {
    "ID": 2806,
    "Tankstellennummer": "0FG93",
    "Name": "Aral Tankstelle Hamburg, Suelldorfer Landstr. 91",
    "Address": "Sülldorfer Landstraße 91",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22589,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40873813,
    "24 hour?": "TRUE",
    "Latitude": 53.577961,
    "Longitude": 9.809536,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.577961,9.809536",
    "IsOpen24Hours": true,
    "id": "53.577961,9.809536"
  },
  {
    "ID": 2809,
    "Tankstellennummer": "0FG96",
    "Name": "Aral Tankstelle Hamburg, Dehnhaide 49",
    "Address": "Dehnhaide 49",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22081,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40295490,
    "24 hour?": "TRUE",
    "Latitude": 53.579313,
    "Longitude": 10.045245,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.579313,10.045245",
    "IsOpen24Hours": true,
    "id": "53.579313,10.045245"
  },
  {
    "ID": 1039,
    "Tankstellennummer": "0F203",
    "Name": "Aral Tankstelle Hamburg, Nordschleswiger Str. 91",
    "Address": "Nordschleswiger Straße 91",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22049,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40687042,
    "24 hour?": "FALSE",
    "Latitude": 53.579851,
    "Longitude": 10.069837,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.579851,10.069837",
    "IsOpen24Hours": false,
    "id": "53.579851,10.069837"
  },
  {
    "ID": 2800,
    "Tankstellennummer": "0FG88",
    "Name": "Aral Tankstelle Hamburg, Hoheluftchaussee 78",
    "Address": "Hoheluftchaussee 78",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 20253,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40483601,
    "24 hour?": "TRUE",
    "Latitude": 53.582896,
    "Longitude": 9.971292,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.582896,9.971292",
    "IsOpen24Hours": true,
    "id": "53.582896,9.971292"
  },
  {
    "ID": 1022,
    "Tankstellennummer": "0F187",
    "Name": "Aral Tankstelle Wedel, Rissener Straße 89",
    "Address": "Rissener Straße 89",
    "Address__1": "",
    "City": "Wedel",
    "Postcode": 22880,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 41032519,
    "24 hour?": "TRUE",
    "Latitude": 53.583641,
    "Longitude": 9.720515,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.583641,9.720515",
    "IsOpen24Hours": true,
    "id": "53.583641,9.720515"
  },
  {
    "ID": 1018,
    "Tankstellennummer": "0F184",
    "Name": "Aral Tankstelle Hamburg, Kieler Straße 285",
    "Address": "Kieler Straße 285",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22525,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40545127,
    "24 hour?": "TRUE",
    "Latitude": 53.583811,
    "Longitude": 9.933157,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.583811,9.933157",
    "IsOpen24Hours": true,
    "id": "53.583811,9.933157"
  },
  {
    "ID": 2803,
    "Tankstellennummer": "0FG90",
    "Name": "Aral Tankstelle Hamburg, Kieler Str. 314",
    "Address": "Kieler Straße 314",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22525,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4054766700,
    "24 hour?": "TRUE",
    "Latitude": 53.58586,
    "Longitude": 9.933305,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.58586,9.933305",
    "IsOpen24Hours": true,
    "id": "53.58586,9.933305"
  },
  {
    "ID": 1832855644,
    "Tankstellennummer": "TD029710",
    "Name": "GOLDBERG WERDERSTR. 55",
    "Address": "WERDERSTR. 55",
    "Address__1": "",
    "City": "GOLDBERG",
    "Postcode": 19399,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038736-43424",
    "24 hour?": "FALSE",
    "Latitude": 53.586,
    "Longitude": 12.0988,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.586,12.0988",
    "IsOpen24Hours": false,
    "id": "53.586,12.0988"
  },
  {
    "ID": 1079,
    "Tankstellennummer": "0F231",
    "Name": "Aral Tankstelle Bremerhaven, Hans-Böckler-Str. 35",
    "Address": "Hans-Böckler-Straße 35",
    "Address__1": "",
    "City": "Bremerhaven",
    "Postcode": 27578,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 47160300,
    "24 hour?": "FALSE",
    "Latitude": 53.587704,
    "Longitude": 8.6126,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.587704,8.6126",
    "IsOpen24Hours": false,
    "id": "53.587704,8.6126"
  },
  {
    "ID": 2120089273,
    "Tankstellennummer": "TD036830",
    "Name": "BREMERHAVEN LANGENER LANDSTR.",
    "Address": "LANGENER LANDSTR. 245-247",
    "Address__1": "",
    "City": "BREMERHAVEN",
    "Postcode": 27578,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0471-804075",
    "24 hour?": "FALSE",
    "Latitude": 53.5878,
    "Longitude": 8.5972,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5878,8.5972",
    "IsOpen24Hours": false,
    "id": "53.5878,8.5972"
  },
  {
    "ID": 850284713,
    "Tankstellennummer": "TD040568",
    "Name": "HAMBURG BRAMFELDER STR. 86A",
    "Address": "BRAMFELDER STR. 86a",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 22305,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-22605444",
    "24 hour?": "FALSE",
    "Latitude": 53.5879,
    "Longitude": 10.0528,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5879,10.0528",
    "IsOpen24Hours": false,
    "id": "53.5879,10.0528"
  },
  {
    "ID": 1657541699,
    "Tankstellennummer": 610482,
    "Name": "WESTFALEN, WESTERHOLT",
    "Address": "Nordener Str. 5",
    "Address__1": "",
    "City": "Westerholt",
    "Postcode": 26556,
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "04975/282",
    "24 hour?": "FALSE",
    "Latitude": 53.5897,
    "Longitude": 7.45539,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5897,7.45539",
    "IsOpen24Hours": false,
    "id": "53.5897,7.45539"
  },
  {
    "ID": 2753,
    "Tankstellennummer": "0FG24",
    "Name": "Aral Tankstelle Schwerin, Graf-York-Straße 23",
    "Address": "Graf-York-Straße 23",
    "Address__1": "",
    "City": "Schwerin",
    "Postcode": 19061,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3853921301,
    "24 hour?": "TRUE",
    "Latitude": 53.590834,
    "Longitude": 11.430641,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.590834,11.430641",
    "IsOpen24Hours": true,
    "id": "53.590834,11.430641"
  },
  {
    "ID": 2813,
    "Tankstellennummer": "0FH01",
    "Name": "Aral Tankstelle Hamburg, Habichtstr. 39",
    "Address": "Habichtstraße 39",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22305,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40615874,
    "24 hour?": "FALSE",
    "Latitude": 53.590963,
    "Longitude": 10.056518,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.590963,10.056518",
    "IsOpen24Hours": false,
    "id": "53.590963,10.056518"
  },
  {
    "ID": 1601,
    "Tankstellennummer": "0F635",
    "Name": "Aral Tankstelle Hamburg, Habichtstraße 72-78",
    "Address": "Habichtstraße 72-78",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22305,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40611041,
    "24 hour?": "TRUE",
    "Latitude": 53.592307,
    "Longitude": 10.054916,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.592307,10.054916",
    "IsOpen24Hours": true,
    "id": "53.592307,10.054916"
  },
  {
    "ID": 751807452,
    "Tankstellennummer": 610335,
    "Name": "WESTFALEN, WILHELMSHAVEN",
    "Address": "Hauptstr. 58",
    "Address__1": "",
    "City": "WILHELMSHAVEN",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.5926,
    "Longitude": 8.04519,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.5926,8.04519",
    "IsOpen24Hours": false,
    "id": "53.5926,8.04519"
  },
  {
    "ID": 1157,
    "Tankstellennummer": "0F286",
    "Name": "Aral Tankstelle Hamburg, Bramfelder Chaussee 66",
    "Address": "Bramfelder Chaussee 66",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22177,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4069793273,
    "24 hour?": "TRUE",
    "Latitude": 53.597208,
    "Longitude": 10.064701,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.597208,10.064701",
    "IsOpen24Hours": true,
    "id": "53.597208,10.064701"
  },
  {
    "ID": 2811,
    "Tankstellennummer": "0FG98",
    "Name": "Aral Tankstelle Hamburg, Rahlstedter Str. 87",
    "Address": "Rahlstedter Straße 87",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22149,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4067561379,
    "24 hour?": "TRUE",
    "Latitude": 53.597735,
    "Longitude": 10.152924,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.597735,10.152924",
    "IsOpen24Hours": true,
    "id": "53.597735,10.152924"
  },
  {
    "ID": 2532,
    "Tankstellennummer": "0FC75",
    "Name": "Aral Tankstelle Schwerin, Am grünen Tal",
    "Address": "Am Grünen Tal 10",
    "Address__1": "",
    "City": "Schwerin",
    "Postcode": 19063,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3853941059,
    "24 hour?": "TRUE",
    "Latitude": 53.597979,
    "Longitude": 11.43837,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.597979,11.43837",
    "IsOpen24Hours": true,
    "id": "53.597979,11.43837"
  },
  {
    "ID": 1037,
    "Tankstellennummer": "0F201",
    "Name": "Aral Tankstelle Hamburg, Osterfeldstraße 30",
    "Address": "Osterfeldstraße 30",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22529,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 405531672,
    "24 hour?": "TRUE",
    "Latitude": 53.598394,
    "Longitude": 9.97447,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.598394,9.97447",
    "IsOpen24Hours": true,
    "id": "53.598394,9.97447"
  },
  {
    "ID": 2805,
    "Tankstellennummer": "0FG92",
    "Name": "Aral Tankstelle Hamburg, Kieler Str. 560",
    "Address": "Kieler Straße 560",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22525,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40545652,
    "24 hour?": "FALSE",
    "Latitude": 53.59994,
    "Longitude": 9.917373,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.59994,9.917373",
    "IsOpen24Hours": false,
    "id": "53.59994,9.917373"
  },
  {
    "ID": 2827,
    "Tankstellennummer": "0FH08",
    "Name": "Aral Tankstelle Norden, Norddeicher Str. 31-32",
    "Address": "Norddeicher Straße 31-32",
    "Address__1": "",
    "City": "Norden",
    "Postcode": 26506,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 49314256,
    "24 hour?": "FALSE",
    "Latitude": 53.60001,
    "Longitude": 7.192554,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.60001,7.192554",
    "IsOpen24Hours": false,
    "id": "53.60001,7.192554"
  },
  {
    "ID": 2617,
    "Tankstellennummer": "0FD43",
    "Name": "Aral Tankstelle Schwerin, Crivitzer Chaussee",
    "Address": "An der Crivitzer Chaussee",
    "Address__1": "",
    "City": "Schwerin",
    "Postcode": 19063,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3852072484,
    "24 hour?": "TRUE",
    "Latitude": 53.600318,
    "Longitude": 11.465076,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.600318,11.465076",
    "IsOpen24Hours": true,
    "id": "53.600318,11.465076"
  },
  {
    "ID": 474202965,
    "Tankstellennummer": "TD037093",
    "Name": "SCHWERIN GRABENSTR. 2",
    "Address": "GRABENSTR. 2",
    "Address__1": "",
    "City": "SCHWERIN",
    "Postcode": 19061,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0385-6666666",
    "24 hour?": "FALSE",
    "Latitude": 53.6012,
    "Longitude": 11.3911,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6012,11.3911",
    "IsOpen24Hours": false,
    "id": "53.6012,11.3911"
  },
  {
    "ID": 814101509,
    "Tankstellennummer": "TD037085",
    "Name": "SCHWERIN AN DER CRIVITZER CHAU",
    "Address": "AN DER CRIVITZER CHAUSSEE 12",
    "Address__1": "",
    "City": "SCHWERIN",
    "Postcode": 19061,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0385-3921148",
    "24 hour?": "FALSE",
    "Latitude": 53.6037,
    "Longitude": 11.4391,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6037,11.4391",
    "IsOpen24Hours": false,
    "id": "53.6037,11.4391"
  },
  {
    "ID": 1044,
    "Tankstellennummer": "0F206",
    "Name": "Aral Tankstelle Hamburg, Bargteheider Straße 100",
    "Address": "Bargteheider Straße 100",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22143,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 406773554,
    "24 hour?": "TRUE",
    "Latitude": 53.604469,
    "Longitude": 10.147826,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.604469,10.147826",
    "IsOpen24Hours": true,
    "id": "53.604469,10.147826"
  },
  {
    "ID": 270301591,
    "Tankstellennummer": "TD036970",
    "Name": "HAMBURG BORSTELER CHAUSSEE 95",
    "Address": "BORSTELER CHAUSSEE 95",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 22453,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-517235",
    "24 hour?": "FALSE",
    "Latitude": 53.606,
    "Longitude": 9.9804,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.606,9.9804",
    "IsOpen24Hours": false,
    "id": "53.606,9.9804"
  },
  {
    "ID": 1627,
    "Tankstellennummer": "0F653",
    "Name": "Aral Tankstelle Hamburg, Sieker Landstraße 128",
    "Address": "Sieker Landstraße 128",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22143,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4067563128,
    "24 hour?": "TRUE",
    "Latitude": 53.608549,
    "Longitude": 10.190714,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.608549,10.190714",
    "IsOpen24Hours": true,
    "id": "53.608549,10.190714"
  },
  {
    "ID": 1184,
    "Tankstellennummer": "0FG34",
    "Name": "Aral Tankstelle Himmelpforten, Hauptstraße 1",
    "Address": "Hauptstraße 1",
    "Address__1": "",
    "City": "Himmelpforten",
    "Postcode": 21709,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4144210521,
    "24 hour?": "FALSE",
    "Latitude": 53.610039,
    "Longitude": 9.313823,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.610039,9.313823",
    "IsOpen24Hours": false,
    "id": "53.610039,9.313823"
  },
  {
    "ID": 1060,
    "Tankstellennummer": "0F217",
    "Name": "Aral Tankstelle Hamburg, Holsteiner Chaussee 55",
    "Address": "Holsteiner Chaussee 55",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22523,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 405706132,
    "24 hour?": "TRUE",
    "Latitude": 53.614712,
    "Longitude": 9.906061,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.614712,9.906061",
    "IsOpen24Hours": true,
    "id": "53.614712,9.906061"
  },
  {
    "ID": 1093,
    "Tankstellennummer": "0F243",
    "Name": "Aral Tankstelle Mölln, Wasserkrüger Weg 131",
    "Address": "Wasserkrüger Weg 131",
    "Address__1": "",
    "City": "Mölln",
    "Postcode": 23879,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 45423656,
    "24 hour?": "TRUE",
    "Latitude": 53.614967,
    "Longitude": 10.691139,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.614967,10.691139",
    "IsOpen24Hours": true,
    "id": "53.614967,10.691139"
  },
  {
    "ID": 1072,
    "Tankstellennummer": "0F227",
    "Name": "Aral Tankstelle Hamburg, Kollaustraße 200",
    "Address": "Kollaustraße 200",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22453,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 40585456,
    "24 hour?": "TRUE",
    "Latitude": 53.615552,
    "Longitude": 9.951808,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.615552,9.951808",
    "IsOpen24Hours": true,
    "id": "53.615552,9.951808"
  },
  {
    "ID": 54452607,
    "Tankstellennummer": "TD037580",
    "Name": "MOELLN EICHHOLZBERG 2",
    "Address": "EICHHOLZBERG 2",
    "Address__1": "",
    "City": "MOELLN",
    "Postcode": 23879,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0454-24280",
    "24 hour?": "FALSE",
    "Latitude": 53.6195,
    "Longitude": 10.6789,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6195,10.6789",
    "IsOpen24Hours": false,
    "id": "53.6195,10.6789"
  },
  {
    "ID": 1634790516,
    "Tankstellennummer": "TD004036",
    "Name": "RELLINGEN ALTONAER STR. 377",
    "Address": "ALTONAER STR. 377",
    "Address__1": "",
    "City": "RELLINGEN",
    "Postcode": 25462,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04101-32439",
    "24 hour?": "FALSE",
    "Latitude": 53.6222,
    "Longitude": 9.885,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6222,9.885",
    "IsOpen24Hours": false,
    "id": "53.6222,9.885"
  },
  {
    "ID": 1113,
    "Tankstellennummer": "0F252",
    "Name": "Aral Tankstelle Bad Bederkesa, Drangstedter Straße 42",
    "Address": "Drangstedter Straße 46",
    "Address__1": "",
    "City": "GEESTLAND",
    "Postcode": 27624,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 47455085,
    "24 hour?": "FALSE",
    "Latitude": 53.626414,
    "Longitude": 8.824384,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.626414,8.824384",
    "IsOpen24Hours": false,
    "id": "53.626414,8.824384"
  },
  {
    "ID": 2692,
    "Tankstellennummer": "0FE11",
    "Name": "Aral Tankstelle Schwerin, Vor dem Wittenburger Tor",
    "Address": "Wittenburger Straße 121",
    "Address__1": "",
    "City": "Schwerin",
    "Postcode": 19059,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3857851496,
    "24 hour?": "TRUE",
    "Latitude": 53.628492,
    "Longitude": 11.38979,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.628492,11.38979",
    "IsOpen24Hours": true,
    "id": "53.628492,11.38979"
  },
  {
    "ID": 936115036,
    "Tankstellennummer": "TD029835",
    "Name": "TORGELOW LINDENSTR. 16",
    "Address": "LINDENSTR. 16",
    "Address__1": "",
    "City": "TORGELOW",
    "Postcode": 17358,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03976-202336",
    "24 hour?": "FALSE",
    "Latitude": 53.6299,
    "Longitude": 13.9997,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6299,13.9997",
    "IsOpen24Hours": false,
    "id": "53.6299,13.9997"
  },
  {
    "ID": 1025,
    "Tankstellennummer": "0F188",
    "Name": "Aral Tankstelle Hamburg, Pinneberger Straße 1-3",
    "Address": "Pinneberger Straße 1-3",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22457,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 405502787,
    "24 hour?": "TRUE",
    "Latitude": 53.632537,
    "Longitude": 9.910657,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.632537,9.910657",
    "IsOpen24Hours": true,
    "id": "53.632537,9.910657"
  },
  {
    "ID": 2812,
    "Tankstellennummer": "0FG99",
    "Name": "Aral Tankstelle Hamburg, Hummelsbuettler Hauptstr.",
    "Address": "Hummelsbuettler Hauptstraße 14",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22339,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4053897550,
    "24 hour?": "TRUE",
    "Latitude": 53.637209,
    "Longitude": 10.034799,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.637209,10.034799",
    "IsOpen24Hours": true,
    "id": "53.637209,10.034799"
  },
  {
    "ID": 740693408,
    "Tankstellennummer": "TD040162",
    "Name": "ESENS BAHNHOFSTR. 32-36",
    "Address": "BAHNHOFSTR. 32-36",
    "Address__1": "",
    "City": "ESENS",
    "Postcode": 26427,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04971-3349",
    "24 hour?": "FALSE",
    "Latitude": 53.6385,
    "Longitude": 7.6126,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6385,7.6126",
    "IsOpen24Hours": false,
    "id": "53.6385,7.6126"
  },
  {
    "ID": 207293845,
    "Tankstellennummer": "TD037614",
    "Name": "RELLINGEN HERMANN-LOENS-WEG 1",
    "Address": "HERMANN-LOENS-WEG 1",
    "Address__1": "",
    "City": "RELLINGEN",
    "Postcode": 25462,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04101-2163907",
    "24 hour?": "FALSE",
    "Latitude": 53.6387,
    "Longitude": 9.8935,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6387,9.8935",
    "IsOpen24Hours": false,
    "id": "53.6387,9.8935"
  },
  {
    "ID": 1554968091,
    "Tankstellennummer": "TD034470",
    "Name": "SCHWERIN OBOTRITENRING 19 A",
    "Address": "OBOTRITENRING 19 A",
    "Address__1": "",
    "City": "SCHWERIN",
    "Postcode": 19053,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0385-77313",
    "24 hour?": "FALSE",
    "Latitude": 53.6387,
    "Longitude": 11.4041,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6387,11.4041",
    "IsOpen24Hours": false,
    "id": "53.6387,11.4041"
  },
  {
    "ID": 1088,
    "Tankstellennummer": "0F237",
    "Name": "Aral Tankstelle Hamburg, Langenhorner Chaussee 53-",
    "Address": "Langenhorner Chaussee 53-",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22335,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 405315155,
    "24 hour?": "TRUE",
    "Latitude": 53.638753,
    "Longitude": 10.013372,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.638753,10.013372",
    "IsOpen24Hours": true,
    "id": "53.638753,10.013372"
  },
  {
    "ID": 1076,
    "Tankstellennummer": "0F230",
    "Name": "Aral Tankstelle Hamburg, Schleswiger Damm 173",
    "Address": "Schleswiger Damm 173",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22457,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 405509471,
    "24 hour?": "TRUE",
    "Latitude": 53.641563,
    "Longitude": 9.916875,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.641563,9.916875",
    "IsOpen24Hours": true,
    "id": "53.641563,9.916875"
  },
  {
    "ID": 2720,
    "Tankstellennummer": "0FE34",
    "Name": "Aral Tankstelle Schwerin, Güstrower Straße 32",
    "Address": "Güstrower Straße 32",
    "Address__1": "",
    "City": "Schwerin",
    "Postcode": 19055,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3855815854,
    "24 hour?": "TRUE",
    "Latitude": 53.643448,
    "Longitude": 11.424785,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.643448,11.424785",
    "IsOpen24Hours": true,
    "id": "53.643448,11.424785"
  },
  {
    "ID": 2784,
    "Tankstellennummer": "0FG76",
    "Name": "Aral Tankstelle Grosshansdorf, Sieker Landstr. 235",
    "Address": "Sieker Landstraße 235",
    "Address__1": "",
    "City": "Großhansdorf",
    "Postcode": 22927,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4102697052,
    "24 hour?": "TRUE",
    "Latitude": 53.644259,
    "Longitude": 10.278794,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.644259,10.278794",
    "IsOpen24Hours": true,
    "id": "53.644259,10.278794"
  },
  {
    "ID": 2810,
    "Tankstellennummer": "0FG97",
    "Name": "Aral Tankstelle Hamburg, Meiendorfer Muehlenweg 6A",
    "Address": "Meiendorfer Mühlenweg 6A",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22393,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 406018712,
    "24 hour?": "TRUE",
    "Latitude": 53.644497,
    "Longitude": 10.122445,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.644497,10.122445",
    "IsOpen24Hours": true,
    "id": "53.644497,10.122445"
  },
  {
    "ID": 546510955,
    "Tankstellennummer": "TD007955",
    "Name": "PINNEBERG THESDORFER WEG 194",
    "Address": "THESDORFER WEG 194",
    "Address__1": "",
    "City": "PINNEBERG",
    "Postcode": 25421,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04101-693669",
    "24 hour?": "FALSE",
    "Latitude": 53.6446,
    "Longitude": 9.8058,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6446,9.8058",
    "IsOpen24Hours": false,
    "id": "53.6446,9.8058"
  },
  {
    "ID": 600460897,
    "Tankstellennummer": "TD029728",
    "Name": "SCHWERIN LUEBECKER STR. 283",
    "Address": "LUEBECKER STR. 283",
    "Address__1": "",
    "City": "SCHWERIN",
    "Postcode": 19059,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0385-4864112",
    "24 hour?": "FALSE",
    "Latitude": 53.6457,
    "Longitude": 11.3821,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6457,11.3821",
    "IsOpen24Hours": false,
    "id": "53.6457,11.3821"
  },
  {
    "ID": 12192776,
    "Tankstellennummer": "TD032664",
    "Name": "KRAKOW AM SEE PLAUER CHAUSSEE",
    "Address": "PLAUER CHAUSSEE 26",
    "Address__1": "",
    "City": "KRAKOW AM SEE",
    "Postcode": 18292,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038457-24645",
    "24 hour?": "FALSE",
    "Latitude": 53.6479,
    "Longitude": 12.2655,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6479,12.2655",
    "IsOpen24Hours": false,
    "id": "53.6479,12.2655"
  },
  {
    "ID": 1030926221,
    "Tankstellennummer": "TD037028",
    "Name": "HAMBURG KROHNSTIEG 71-75",
    "Address": "KROHNSTIEG 71-75",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 22415,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-5319690",
    "24 hour?": "FALSE",
    "Latitude": 53.6483,
    "Longitude": 10.006,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6483,10.006",
    "IsOpen24Hours": false,
    "id": "53.6483,10.006"
  },
  {
    "ID": 1051,
    "Tankstellennummer": "0F210",
    "Name": "Aral Tankstelle Hamburg, Poppenbütteler Weg 126",
    "Address": "PoppenbüttelerWeg 126",
    "Address__1": "",
    "City": "Hamburg",
    "Postcode": 22399,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 405385227,
    "24 hour?": "TRUE",
    "Latitude": 53.649732,
    "Longitude": 10.064419,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.649732,10.064419",
    "IsOpen24Hours": true,
    "id": "53.649732,10.064419"
  },
  {
    "ID": 1116,
    "Tankstellennummer": "0F253",
    "Name": "Aral Tankstelle Rellingen, Hauptstraße 110",
    "Address": "Hauptstraße 110",
    "Address__1": "",
    "City": "Rellingen",
    "Postcode": 25462,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4101204018,
    "24 hour?": "TRUE",
    "Latitude": 53.654786,
    "Longitude": 9.823491,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.654786,9.823491",
    "IsOpen24Hours": true,
    "id": "53.654786,9.823491"
  },
  {
    "ID": 305162786,
    "Tankstellennummer": "TD037606",
    "Name": "PINNEBERG DAMM 80",
    "Address": "DAMM 80",
    "Address__1": "",
    "City": "PINNEBERG",
    "Postcode": 25421,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04101-26837",
    "24 hour?": "FALSE",
    "Latitude": 53.6568,
    "Longitude": 9.8131,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6568,9.8131",
    "IsOpen24Hours": false,
    "id": "53.6568,9.8131"
  },
  {
    "ID": 2505,
    "Tankstellennummer": "0FC49",
    "Name": "Aral Tankstelle Friedland, Neubrandenburger Str. 10",
    "Address": "Neubrandenburger Str. 10",
    "Address__1": "",
    "City": "Friedland",
    "Postcode": 17098,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3960120313,
    "24 hour?": "FALSE",
    "Latitude": 53.660491,
    "Longitude": 13.541973,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.660491,13.541973",
    "IsOpen24Hours": false,
    "id": "53.660491,13.541973"
  },
  {
    "ID": 1854398035,
    "Tankstellennummer": "TD006759",
    "Name": "HAMBURG BERGSTEDTER CH. 56",
    "Address": "BERGSTEDTER CH. 56",
    "Address__1": "",
    "City": "HAMBURG",
    "Postcode": 22395,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-6048674",
    "24 hour?": "FALSE",
    "Latitude": 53.6643,
    "Longitude": 10.12,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6643,10.12",
    "IsOpen24Hours": false,
    "id": "53.6643,10.12"
  },
  {
    "ID": 1030,
    "Tankstellennummer": "0F195",
    "Name": "Aral Tankstelle Ahrensburg, Manhagener Allee 47-49",
    "Address": "Manhagener Allee 47-49",
    "Address__1": "",
    "City": "Ahrensburg",
    "Postcode": 22926,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 410252343,
    "24 hour?": "FALSE",
    "Latitude": 53.669536,
    "Longitude": 10.243955,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.669536,10.243955",
    "IsOpen24Hours": false,
    "id": "53.669536,10.243955"
  },
  {
    "ID": 1063,
    "Tankstellennummer": "0F220",
    "Name": "Aral Tankstelle Norderstedt, Ohechaussee 146",
    "Address": "Ohechaussee 146",
    "Address__1": "",
    "City": "Norderstedt",
    "Postcode": 22848,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 405234266,
    "24 hour?": "TRUE",
    "Latitude": 53.671994,
    "Longitude": 9.98336,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.671994,9.98336",
    "IsOpen24Hours": true,
    "id": "53.671994,9.98336"
  },
  {
    "ID": 3431,
    "Tankstellennummer": "0FL66",
    "Name": "Aral Tankstelle Norderstedt, Niendorfer Str. 50",
    "Address": "Niendorfer Straße 50",
    "Address__1": "",
    "City": "Norderstedt",
    "Postcode": 22848,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4052876152,
    "24 hour?": "FALSE",
    "Latitude": 53.6725,
    "Longitude": 9.968369,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.6725,9.968369",
    "IsOpen24Hours": false,
    "id": "53.6725,9.968369"
  },
  {
    "ID": 567438337,
    "Tankstellennummer": "TD003996",
    "Name": "PINNEBERG ELMSHORNER STR. 143-",
    "Address": "ELMSHORNER STR. 143-147",
    "Address__1": "",
    "City": "PINNEBERG",
    "Postcode": 25421,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04101-74305",
    "24 hour?": "FALSE",
    "Latitude": 53.6748,
    "Longitude": 9.7935,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6748,9.7935",
    "IsOpen24Hours": false,
    "id": "53.6748,9.7935"
  },
  {
    "ID": 1406971777,
    "Tankstellennummer": 610322,
    "Name": "WESTFALEN, NEUHARLINGERSIEL",
    "Address": "Dorfstr. 4",
    "Address__1": "",
    "City": "NEUHARLINGERSIEL",
    "Postcode": "",
    "Country": "Germany",
    "Brand": "Westfalen",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.6841,
    "Longitude": 7.68646,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6841,7.68646",
    "IsOpen24Hours": false,
    "id": "53.6841,7.68646"
  },
  {
    "ID": 785419403,
    "Tankstellennummer": "TD003962",
    "Name": "NORDERSTEDT SEGEBERGER CHAUSSE",
    "Address": "SEGEBERGER CHAUSSEE 240",
    "Address__1": "",
    "City": "NORDERSTEDT",
    "Postcode": 22851,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "040-5242645",
    "24 hour?": "FALSE",
    "Latitude": 53.6904,
    "Longitude": 10.0416,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.6904,10.0416",
    "IsOpen24Hours": false,
    "id": "53.6904,10.0416"
  },
  {
    "ID": 1098,
    "Tankstellennummer": "0F245",
    "Name": "Aral Tankstelle Ratzeburg, Schweriner Straße 75",
    "Address": "Schweriner Straße 75",
    "Address__1": "",
    "City": "Ratzeburg",
    "Postcode": 23909,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 454183929,
    "24 hour?": "FALSE",
    "Latitude": 53.69291,
    "Longitude": 10.78774,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.69291,10.78774",
    "IsOpen24Hours": false,
    "id": "53.69291,10.78774"
  },
  {
    "ID": 2569,
    "Tankstellennummer": "0FD04",
    "Name": "Aral Tankstelle Gadebusch, Ratzeburger Chaussee 3",
    "Address": "Ratzeburger Chaussee 3",
    "Address__1": "",
    "City": "Gadebusch",
    "Postcode": 19205,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3886712717,
    "24 hour?": "TRUE",
    "Latitude": 53.697076,
    "Longitude": 11.102248,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.697076,11.102248",
    "IsOpen24Hours": true,
    "id": "53.697076,11.102248"
  },
  {
    "ID": 1085326145,
    "Tankstellennummer": "TD029801",
    "Name": "STAVENHAGEN MALCHINER STR. 81",
    "Address": "MALCHINER STR. 81",
    "Address__1": "",
    "City": "STAVENHAGEN",
    "Postcode": 17153,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039954-22175",
    "24 hour?": "FALSE",
    "Latitude": 53.7002,
    "Longitude": 12.8977,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.7002,12.8977",
    "IsOpen24Hours": false,
    "id": "53.7002,12.8977"
  },
  {
    "ID": 763038820,
    "Tankstellennummer": "TD029660",
    "Name": "STERNBERG BRUEELER CH. 12",
    "Address": "BRUEELER CH. 12",
    "Address__1": "",
    "City": "STERNBERG",
    "Postcode": 19406,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03847-2348",
    "24 hour?": "FALSE",
    "Latitude": 53.7089,
    "Longitude": 11.8067,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.7089,11.8067",
    "IsOpen24Hours": false,
    "id": "53.7089,11.8067"
  },
  {
    "ID": 1086,
    "Tankstellennummer": "0F236",
    "Name": "Aral Tankstelle Drochtersen, Theisbrügger Straße 6",
    "Address": "Theisbrügger Straße 1",
    "Address__1": "",
    "City": "Drochtersen",
    "Postcode": 21706,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 41437463,
    "24 hour?": "FALSE",
    "Latitude": 53.715352,
    "Longitude": 9.372777,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.715352,9.372777",
    "IsOpen24Hours": false,
    "id": "53.715352,9.372777"
  },
  {
    "ID": 1027539188,
    "Tankstellennummer": "TD004069",
    "Name": "QUICKBORN BAB HOLMMOORWEG WEST",
    "Address": "BAB HOLMMOORWEG WEST/A 7",
    "Address__1": "",
    "City": "QUICKBORN",
    "Postcode": 25451,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04106-9769520",
    "24 hour?": "FALSE",
    "Latitude": 53.7177,
    "Longitude": 9.938,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.7177,9.938",
    "IsOpen24Hours": false,
    "id": "53.7177,9.938"
  },
  {
    "ID": 1163,
    "Tankstellennummer": "0F291",
    "Name": "Aral Autohof Tornesch, Lise-Meitner-Allee 3",
    "Address": "Lise-Meitner-Allee 3",
    "Address__1": "",
    "City": "Tornesch",
    "Postcode": 25436,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4120909760,
    "24 hour?": "TRUE",
    "Latitude": 53.71812,
    "Longitude": 9.759808,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.71812,9.759808",
    "IsOpen24Hours": true,
    "id": "53.71812,9.759808"
  },
  {
    "ID": 2776,
    "Tankstellennummer": "0FG70",
    "Name": "Aral Tankstelle Bargteheide, Hamburger Str. 35",
    "Address": "Hamburger Straße 35",
    "Address__1": "",
    "City": "Bargteheide",
    "Postcode": 22941,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4532501590,
    "24 hour?": "TRUE",
    "Latitude": 53.724256,
    "Longitude": 10.255379,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.724256,10.255379",
    "IsOpen24Hours": true,
    "id": "53.724256,10.255379"
  },
  {
    "ID": 1177297095,
    "Tankstellennummer": "TD004010",
    "Name": "QUICKBORN KIELER STR. 32-34",
    "Address": "KIELER STR. 32-34",
    "Address__1": "",
    "City": "QUICKBORN",
    "Postcode": 25451,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04106-4768",
    "24 hour?": "FALSE",
    "Latitude": 53.7245,
    "Longitude": 9.9004,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.7245,9.9004",
    "IsOpen24Hours": false,
    "id": "53.7245,9.9004"
  },
  {
    "ID": 1089,
    "Tankstellennummer": "0F238",
    "Name": "Aral Tankstelle Norderstedt, Ulzburger Straße 440",
    "Address": "Ulzburger Straße 440",
    "Address__1": "",
    "City": "Norderstedt",
    "Postcode": 22844,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 405222155,
    "24 hour?": "TRUE",
    "Latitude": 53.727633,
    "Longitude": 9.997779,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.727633,9.997779",
    "IsOpen24Hours": true,
    "id": "53.727633,9.997779"
  },
  {
    "ID": 223736733,
    "Tankstellennummer": "TD032607",
    "Name": "BRUEEL AM KREUZSEE 1",
    "Address": "AM KREUZSEE 1",
    "Address__1": "",
    "City": "BRUEEL",
    "Postcode": 19412,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038483-22231",
    "24 hour?": "FALSE",
    "Latitude": 53.7294,
    "Longitude": 11.6909,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.7294,11.6909",
    "IsOpen24Hours": false,
    "id": "53.7294,11.6909"
  },
  {
    "ID": 2519,
    "Tankstellennummer": "0FC60",
    "Name": "Aral Tankstelle Malchin, Goethestraße 8",
    "Address": "Poststrasse 21",
    "Address__1": "",
    "City": "Malchin",
    "Postcode": 17139,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3994222308,
    "24 hour?": "TRUE",
    "Latitude": 53.74163,
    "Longitude": 12.766596,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.74163,12.766596",
    "IsOpen24Hours": true,
    "id": "53.74163,12.766596"
  },
  {
    "ID": 10008,
    "Tankstellennummer": "0FM44",
    "Name": "Aral Tankstelle Elmshorn, Werner-von-Siemens-Str. 1",
    "Address": "Werner-von-Siemens-Straße",
    "Address__1": "",
    "City": "Elmshorn",
    "Postcode": 25337,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 41215796687,
    "24 hour?": "TRUE",
    "Latitude": 53.745524,
    "Longitude": 9.705794,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.745524,9.705794",
    "IsOpen24Hours": true,
    "id": "53.745524,9.705794"
  },
  {
    "ID": 1033653016,
    "Tankstellennummer": "TD003822",
    "Name": "ELMSHORN TURNSTR. 3",
    "Address": "TURNSTR. 3",
    "Address__1": "",
    "City": "ELMSHORN",
    "Postcode": 25335,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04121-3232",
    "24 hour?": "FALSE",
    "Latitude": 53.7546,
    "Longitude": 9.6418,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.7546,9.6418",
    "IsOpen24Hours": false,
    "id": "53.7546,9.6418"
  },
  {
    "ID": 1061,
    "Tankstellennummer": "0F218",
    "Name": "Aral Tankstelle Elmshorn, Friedensallee 92",
    "Address": "Friedensallee 92",
    "Address__1": "",
    "City": "Elmshorn",
    "Postcode": 25335,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 412182611,
    "24 hour?": "FALSE",
    "Latitude": 53.763849,
    "Longitude": 9.662711,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.763849,9.662711",
    "IsOpen24Hours": false,
    "id": "53.763849,9.662711"
  },
  {
    "ID": 1116021122,
    "Tankstellennummer": "0FV81",
    "Name": "GUSTROW",
    "Address": "Friedrich-Pogge-Weg 60",
    "Address__1": "",
    "City": "Güstrow",
    "Postcode": 18273,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3843334848,
    "24 hour?": "FALSE",
    "Latitude": 53.772872,
    "Longitude": 12.166508,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.772872,12.166508",
    "IsOpen24Hours": false,
    "id": "53.772872,12.166508"
  },
  {
    "ID": 2550,
    "Tankstellennummer": "0FC91",
    "Name": "Aral Tankstelle Teterow, Poggestr. 17",
    "Address": "Poggestraße 17",
    "Address__1": "",
    "City": "Teterow",
    "Postcode": 17166,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3996187931,
    "24 hour?": "TRUE",
    "Latitude": 53.776953,
    "Longitude": 12.563496,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.776953,12.563496",
    "IsOpen24Hours": true,
    "id": "53.776953,12.563496"
  },
  {
    "ID": 1068,
    "Tankstellennummer": "0F225",
    "Name": "Aral Tankstelle Glückstadt, Stadtstraße 31",
    "Address": "Stadtstraße 31",
    "Address__1": "",
    "City": "Glückstadt",
    "Postcode": 25348,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 41242725,
    "24 hour?": "TRUE",
    "Latitude": 53.780917,
    "Longitude": 9.430447,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.780917,9.430447",
    "IsOpen24Hours": true,
    "id": "53.780917,9.430447"
  },
  {
    "ID": 1183,
    "Tankstellennummer": "0FG42",
    "Name": "Aral Tankstelle Barmstedt, Hamburger Straße 65",
    "Address": "Hamburger Straße 65",
    "Address__1": "",
    "City": "Barmstedt",
    "Postcode": 25355,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 412385138,
    "24 hour?": "FALSE",
    "Latitude": 53.784349,
    "Longitude": 9.788203,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.784349,9.788203",
    "IsOpen24Hours": false,
    "id": "53.784349,9.788203"
  },
  {
    "ID": 595669363,
    "Tankstellennummer": "TD029587",
    "Name": "GUESTROW PLAUER CHAUSSEE 2",
    "Address": "PLAUER CHAUSSEE 2",
    "Address__1": "",
    "City": "GUESTROW",
    "Postcode": 18273,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03843-82149",
    "24 hour?": "FALSE",
    "Latitude": 53.785,
    "Longitude": 12.1965,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.785,12.1965",
    "IsOpen24Hours": false,
    "id": "53.785,12.1965"
  },
  {
    "ID": 745812110,
    "Tankstellennummer": "TD030049",
    "Name": "REHNA GLETZOWER STR. 62 B",
    "Address": "GLETZOWER STR. 62 B",
    "Address__1": "",
    "City": "REHNA",
    "Postcode": 19217,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038872-53341",
    "24 hour?": "FALSE",
    "Latitude": 53.7854,
    "Longitude": 11.0441,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.7854,11.0441",
    "IsOpen24Hours": false,
    "id": "53.7854,11.0441"
  },
  {
    "ID": 1939128230,
    "Tankstellennummer": "TD029827",
    "Name": "TETEROW BRIKETTWEG 1",
    "Address": "ROSTOCKER STR. 154",
    "Address__1": "",
    "City": "TETEROW",
    "Postcode": 17166,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03996-172306",
    "24 hour?": "FALSE",
    "Latitude": 53.7859,
    "Longitude": 12.5705,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.7859,12.5705",
    "IsOpen24Hours": false,
    "id": "53.7859,12.5705"
  },
  {
    "ID": 1130,
    "Tankstellennummer": "0F261",
    "Name": "Aral Tankstelle Henstedt-Ulzburg, Hamburger Straße 123",
    "Address": "Hamburger Straße 123",
    "Address__1": "",
    "City": "Henstedt-Ulzburg",
    "Postcode": 24558,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 419393626,
    "24 hour?": "TRUE",
    "Latitude": 53.786987,
    "Longitude": 9.979266,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.786987,9.979266",
    "IsOpen24Hours": true,
    "id": "53.786987,9.979266"
  },
  {
    "ID": 1052,
    "Tankstellennummer": "0F211",
    "Name": "Aral Tankstelle Nahe, Segeberger Straße  42",
    "Address": "Segeberger Straße 42",
    "Address__1": "",
    "City": "Nahe",
    "Postcode": 23866,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4535407,
    "24 hour?": "FALSE",
    "Latitude": 53.795733,
    "Longitude": 10.14146,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.795733,10.14146",
    "IsOpen24Hours": false,
    "id": "53.795733,10.14146"
  },
  {
    "ID": 1633552633,
    "Tankstellennummer": "TD000258",
    "Name": "BAD OLDESLOE INDUSTRIESTR. 4",
    "Address": "INDUSTRIESTR. 4",
    "Address__1": "",
    "City": "BAD OLDESLOE",
    "Postcode": 23843,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04531-4199834",
    "24 hour?": "FALSE",
    "Latitude": 53.8019,
    "Longitude": 10.3858,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.8019,10.3858",
    "IsOpen24Hours": false,
    "id": "53.8019,10.3858"
  },
  {
    "ID": 1048,
    "Tankstellennummer": "0F207",
    "Name": "Aral Tankstelle Bad Oldesloe, Hamburger Straße 110",
    "Address": "Hamburger Straße 110",
    "Address__1": "",
    "City": "Bad Oldesloe",
    "Postcode": 23843,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 453182243,
    "24 hour?": "FALSE",
    "Latitude": 53.803071,
    "Longitude": 10.359054,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.803071,10.359054",
    "IsOpen24Hours": false,
    "id": "53.803071,10.359054"
  },
  {
    "ID": 1174,
    "Tankstellennummer": "0FG14",
    "Name": "Aral Autohof Henstedt-Ulzburg, Rudolf-Diesel-Straße 2",
    "Address": "Rudolf-Diesel-Straße 2",
    "Address__1": "",
    "City": "Henstedt-Ulzburg",
    "Postcode": 24558,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4193888252,
    "24 hour?": "TRUE",
    "Latitude": 53.80396,
    "Longitude": 9.939107,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.80396,9.939107",
    "IsOpen24Hours": true,
    "id": "53.80396,9.939107"
  },
  {
    "ID": 2609,
    "Tankstellennummer": "0FD38",
    "Name": "Aral Tankstelle Güstrow, Am Eicheneck 7",
    "Address": "Am Eicheneck 7",
    "Address__1": "",
    "City": "Güstrow",
    "Postcode": 18273,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 384322122,
    "24 hour?": "TRUE",
    "Latitude": 53.809594,
    "Longitude": 12.189835,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.809594,12.189835",
    "IsOpen24Hours": true,
    "id": "53.809594,12.189835"
  },
  {
    "ID": 1181,
    "Tankstellennummer": "0FE74",
    "Name": "Aral Tankstelle Reinfeld, An der Autobahn Nr. 2",
    "Address": "An der Autobahn Nr. 2",
    "Address__1": "",
    "City": "Reinfeld",
    "Postcode": 23858,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4533791203,
    "24 hour?": "TRUE",
    "Latitude": 53.823576,
    "Longitude": 10.504098,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.823576,10.504098",
    "IsOpen24Hours": true,
    "id": "53.823576,10.504098"
  },
  {
    "ID": 598743684,
    "Tankstellennummer": "TD029884",
    "Name": "ANKLAM PASEWALKER ALLEE 95 A",
    "Address": "PASEWALKER ALLEE 95 A",
    "Address__1": "",
    "City": "ANKLAM",
    "Postcode": 17389,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03971-210189",
    "24 hour?": "FALSE",
    "Latitude": 53.8387,
    "Longitude": 13.7148,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.8387,13.7148",
    "IsOpen24Hours": false,
    "id": "53.8387,13.7148"
  },
  {
    "ID": 959024567,
    "Tankstellennummer": "0FG65",
    "Name": "RECKNITZ",
    "Address": "BAB A 19 - Ost",
    "Address__1": "",
    "City": "Recknitz",
    "Postcode": 18276,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3845520952,
    "24 hour?": "TRUE",
    "Latitude": 53.839943,
    "Longitude": 12.287809,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.839943,12.287809",
    "IsOpen24Hours": true,
    "id": "53.839943,12.287809"
  },
  {
    "ID": 298263355,
    "Tankstellennummer": "0FG64",
    "Name": "RECKNITZ",
    "Address": "BAB A 19 - West",
    "Address__1": "",
    "City": "Recknitz",
    "Postcode": 18276,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3845520962,
    "24 hour?": "TRUE",
    "Latitude": 53.840037,
    "Longitude": 12.291249,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.840037,12.291249",
    "IsOpen24Hours": true,
    "id": "53.840037,12.291249"
  },
  {
    "ID": 1010905961,
    "Tankstellennummer": "0FP15",
    "Name": "KALTENKIRCHEN",
    "Address": "Kieler Straße 51-53",
    "Address__1": "",
    "City": "Kaltenkirchen",
    "Postcode": 24568,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 41919528830,
    "24 hour?": "FALSE",
    "Latitude": 53.840726,
    "Longitude": 9.947619,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.840726,9.947619",
    "IsOpen24Hours": false,
    "id": "53.840726,9.947619"
  },
  {
    "ID": 1092,
    "Tankstellennummer": "0F242",
    "Name": "Aral Tankstelle Lübeck, Ratzeburger Allee 116",
    "Address": "Ratzeburger Allee 116",
    "Address__1": "",
    "City": "Lübeck",
    "Postcode": 23562,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 451503133,
    "24 hour?": "FALSE",
    "Latitude": 53.841219,
    "Longitude": 10.707904,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.841219,10.707904",
    "IsOpen24Hours": false,
    "id": "53.841219,10.707904"
  },
  {
    "ID": 2518,
    "Tankstellennummer": "0FC59",
    "Name": "Aral Tankstelle Anklam, Friedländer Landstr. 21 a",
    "Address": "Friedländer Landstr. 21 a",
    "Address__1": "",
    "City": "Anklam",
    "Postcode": 17389,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3971214444,
    "24 hour?": "FALSE",
    "Latitude": 53.843132,
    "Longitude": 13.684718,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.843132,13.684718",
    "IsOpen24Hours": false,
    "id": "53.843132,13.684718"
  },
  {
    "ID": 1650435194,
    "Tankstellennummer": "TD029645",
    "Name": "BUETZOW WISMARSCHE STR.",
    "Address": "WISMARSCHE STR. 13B",
    "Address__1": "",
    "City": "BUETZOW",
    "Postcode": 18246,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038461-67157",
    "24 hour?": "FALSE",
    "Latitude": 53.8468,
    "Longitude": 11.9585,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.8468,11.9585",
    "IsOpen24Hours": false,
    "id": "53.8468,11.9585"
  },
  {
    "ID": 1066,
    "Tankstellennummer": "0F223",
    "Name": "Aral Tankstelle Cuxhaven, Grodener Chaussee 52",
    "Address": "Grodener Chaussee 52",
    "Address__1": "",
    "City": "Cuxhaven",
    "Postcode": 27472,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 472123442,
    "24 hour?": "TRUE",
    "Latitude": 53.853061,
    "Longitude": 8.711241,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.853061,8.711241",
    "IsOpen24Hours": true,
    "id": "53.853061,8.711241"
  },
  {
    "ID": 718446868,
    "Tankstellennummer": "TD042119",
    "Name": "VOELSCHOW DEMMINER LAND",
    "Address": "AM GROSSEN TORFMOOR 1/DEMMINER LAND",
    "Address__1": "",
    "City": "VOELSCHOW",
    "Postcode": 17129,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039991-367822",
    "24 hour?": "FALSE",
    "Latitude": 53.8566,
    "Longitude": 13.3347,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.8566,13.3347",
    "IsOpen24Hours": false,
    "id": "53.8566,13.3347"
  },
  {
    "ID": 717268852,
    "Tankstellennummer": 212056,
    "Name": "Lubeck",
    "Address": "Reepschlagerstrasse 27-31",
    "Address__1": "",
    "City": "LUBECK",
    "Postcode": 23556,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 53.86112,
    "Longitude": 10.61342,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.86112,10.61342",
    "IsOpen24Hours": false,
    "id": "53.86112,10.61342"
  },
  {
    "ID": 478940956,
    "Tankstellennummer": "TD035188",
    "Name": "GREVESMUEHLEN WISMARSCHE STR.1",
    "Address": "WISMARSCHE STR.153 A",
    "Address__1": "",
    "City": "GREVESMUEHLEN",
    "Postcode": 23936,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03881-2729",
    "24 hour?": "FALSE",
    "Latitude": 53.8654,
    "Longitude": 11.2095,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.8654,11.2095",
    "IsOpen24Hours": false,
    "id": "53.8654,11.2095"
  },
  {
    "ID": 1004,
    "Tankstellennummer": "0F191",
    "Name": "Aral Tankstelle Lübeck, Fackenburger Allee 20",
    "Address": "Fackenburger Allee 20",
    "Address__1": "",
    "City": "Lübeck",
    "Postcode": 23554,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 45142183,
    "24 hour?": "TRUE",
    "Latitude": 53.87046,
    "Longitude": 10.670016,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.87046,10.670016",
    "IsOpen24Hours": true,
    "id": "53.87046,10.670016"
  },
  {
    "ID": 3295,
    "Tankstellennummer": "0FK55",
    "Name": "Aral Tankstelle Lübeck, Krempelsdorfer Allee 63",
    "Address": "Krempelsdorfer Allee 63",
    "Address__1": "",
    "City": "Lübeck",
    "Postcode": 23556,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 451492396,
    "24 hour?": "TRUE",
    "Latitude": 53.884475,
    "Longitude": 10.653804,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.884475,10.653804",
    "IsOpen24Hours": true,
    "id": "53.884475,10.653804"
  },
  {
    "ID": 36556931,
    "Tankstellennummer": "TD003921",
    "Name": "STOCKELSDORF SEGEBERGER STR. 5",
    "Address": "SEGEBERGER STR. 55 A",
    "Address__1": "",
    "City": "STOCKELSDORF",
    "Postcode": 23617,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0451-495676",
    "24 hour?": "FALSE",
    "Latitude": 53.8878,
    "Longitude": 10.6446,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.8878,10.6446",
    "IsOpen24Hours": false,
    "id": "53.8878,10.6446"
  },
  {
    "ID": 949957725,
    "Tankstellennummer": "TD034892",
    "Name": "WISMAR DR.-LEBER-STR. 8",
    "Address": "DR.-LEBER-STR. 8",
    "Address__1": "",
    "City": "WISMAR",
    "Postcode": 23966,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03841-40523",
    "24 hour?": "FALSE",
    "Latitude": 53.8898,
    "Longitude": 11.4714,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.8898,11.4714",
    "IsOpen24Hours": false,
    "id": "53.8898,11.4714"
  },
  {
    "ID": 2673,
    "Tankstellennummer": "0FD94",
    "Name": "Aral Tankstelle Wismar, Lübsche Str. 161",
    "Address": "Lübsche Straße 161",
    "Address__1": "",
    "City": "Wismar",
    "Postcode": 23966,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3841704718,
    "24 hour?": "TRUE",
    "Latitude": 53.894485,
    "Longitude": 11.440239,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.894485,11.440239",
    "IsOpen24Hours": true,
    "id": "53.894485,11.440239"
  },
  {
    "ID": 1127,
    "Tankstellennummer": "0F262",
    "Name": "Aral Tankstelle Brunsbüttel, Olof-Palme-Allee 2",
    "Address": "Olof-Palme-Allee 2",
    "Address__1": "",
    "City": "Brunsbüttel",
    "Postcode": 25541,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 48527272,
    "24 hour?": "FALSE",
    "Latitude": 53.900422,
    "Longitude": 9.121024,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.900422,9.121024",
    "IsOpen24Hours": false,
    "id": "53.900422,9.121024"
  },
  {
    "ID": 52813773,
    "Tankstellennummer": "TD039834",
    "Name": "BRUNSBUETTEL OLOF-PALME-ALLEE",
    "Address": "OLOF-PALME-ALLEE 5",
    "Address__1": "",
    "City": "BRUNSBUETTEL",
    "Postcode": 25541,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04852-9405010",
    "24 hour?": "FALSE",
    "Latitude": 53.9021,
    "Longitude": 9.1222,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.9021,9.1222",
    "IsOpen24Hours": false,
    "id": "53.9021,9.1222"
  },
  {
    "ID": 661324100,
    "Tankstellennummer": "TD031716",
    "Name": "WISMAR ZIEROWER LANDSTRASSE 1",
    "Address": "ZIEROWER LANDSTRASSE 1",
    "Address__1": "",
    "City": "WISMAR",
    "Postcode": 23968,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03841-643704",
    "24 hour?": "FALSE",
    "Latitude": 53.9027,
    "Longitude": 11.4047,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.9027,11.4047",
    "IsOpen24Hours": false,
    "id": "53.9027,11.4047"
  },
  {
    "ID": 1546636822,
    "Tankstellennummer": "TD029751",
    "Name": "DEMMIN JARMENER CHAUSSEE 1A",
    "Address": "JARMENER CHAUSSEE 1A",
    "Address__1": "",
    "City": "DEMMIN",
    "Postcode": 17109,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03998-222571",
    "24 hour?": "FALSE",
    "Latitude": 53.9042,
    "Longitude": 13.0671,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.9042,13.0671",
    "IsOpen24Hours": false,
    "id": "53.9042,13.0671"
  },
  {
    "ID": 1087,
    "Tankstellennummer": "0F239",
    "Name": "Aral Tankstelle Itzehoe, Elmshorner Straße 36",
    "Address": "Elmshorner Straße 36",
    "Address__1": "",
    "City": "Itzehoe",
    "Postcode": 25524,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 482182469,
    "24 hour?": "TRUE",
    "Latitude": 53.905809,
    "Longitude": 9.507933,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.905809,9.507933",
    "IsOpen24Hours": true,
    "id": "53.905809,9.507933"
  },
  {
    "ID": 2757,
    "Tankstellennummer": "0FM04",
    "Name": "Aral Autobahntankstelle Glasin, Fuchsberg Süd (A 20)",
    "Address": "BAB A 20 - Fuchsberg Süd",
    "Address__1": "",
    "City": "Glasin",
    "Postcode": 23992,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 38429446620,
    "24 hour?": "TRUE",
    "Latitude": 53.908977,
    "Longitude": 11.756738,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.908977,11.756738",
    "IsOpen24Hours": true,
    "id": "53.908977,11.756738"
  },
  {
    "ID": 2756,
    "Tankstellennummer": "0FM05",
    "Name": "Aral Autobahntankstelle Glasin, Fuchsberg Nord (A 20)",
    "Address": "BAB  A 20 - Fuchsberg Nor",
    "Address__1": "",
    "City": "Glasin",
    "Postcode": 23992,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 38429446611,
    "24 hour?": "TRUE",
    "Latitude": 53.910784,
    "Longitude": 11.758571,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "53.910784,11.758571",
    "IsOpen24Hours": true,
    "id": "53.910784,11.758571"
  },
  {
    "ID": 26414244,
    "Tankstellennummer": "TD034918",
    "Name": "DASSOW FRIEDENSSTR. 88",
    "Address": "FRIEDENSSTR. 88",
    "Address__1": "",
    "City": "DASSOW",
    "Postcode": 23942,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038826-80219",
    "24 hour?": "FALSE",
    "Latitude": 53.9131,
    "Longitude": 10.9775,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.9131,10.9775",
    "IsOpen24Hours": false,
    "id": "53.9131,10.9775"
  },
  {
    "ID": 2003983742,
    "Tankstellennummer": "TD037572",
    "Name": "LUEBECK SOLMITZSTRASSE 23A",
    "Address": "SOLMITZSTRASSE 23a",
    "Address__1": "",
    "City": "LUEBECK",
    "Postcode": 23569,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0451-301413",
    "24 hour?": "FALSE",
    "Latitude": 53.9153,
    "Longitude": 10.8053,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.9153,10.8053",
    "IsOpen24Hours": false,
    "id": "53.9153,10.8053"
  },
  {
    "ID": 1179,
    "Tankstellennummer": "0FE75",
    "Name": "Aral Autohof Bark O.T. Bockhorn, Bockhorner Landstr. 63",
    "Address": "Bockhorner Landstraße 63a",
    "Address__1": "",
    "City": "Bark O.T. Bockhorn",
    "Postcode": 23826,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 45586759924,
    "24 hour?": "TRUE",
    "Latitude": 53.918409,
    "Longitude": 10.127657,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.918409,10.127657",
    "IsOpen24Hours": true,
    "id": "53.918409,10.127657"
  },
  {
    "ID": 550182955,
    "Tankstellennummer": "TD037564",
    "Name": "ITZEHOE ADENAUER ALLEE 10",
    "Address": "ADENAUER ALLEE 10",
    "Address__1": "",
    "City": "ITZEHOE",
    "Postcode": 25524,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04821-3333",
    "24 hour?": "FALSE",
    "Latitude": 53.9217,
    "Longitude": 9.5124,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.9217,9.5124",
    "IsOpen24Hours": false,
    "id": "53.9217,9.5124"
  },
  {
    "ID": 23798033,
    "Tankstellennummer": "TD029868",
    "Name": "JARMEN KLINKENBERG",
    "Address": "KLINKENBERG 28",
    "Address__1": "",
    "City": "JARMEN",
    "Postcode": 17126,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "039997-10404",
    "24 hour?": "FALSE",
    "Latitude": 53.9218,
    "Longitude": 13.3475,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.9218,13.3475",
    "IsOpen24Hours": false,
    "id": "53.9218,13.3475"
  },
  {
    "ID": 1006,
    "Tankstellennummer": "0F192",
    "Name": "Aral Tankstelle Itzehoe, Lindenstraße 24",
    "Address": "Lindenstraße 24",
    "Address__1": "",
    "City": "Itzehoe",
    "Postcode": 25524,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 48213114,
    "24 hour?": "TRUE",
    "Latitude": 53.929192,
    "Longitude": 9.509999,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.929192,9.509999",
    "IsOpen24Hours": true,
    "id": "53.929192,9.509999"
  },
  {
    "ID": 424529062,
    "Tankstellennummer": "TD031948",
    "Name": "AHLBECK FERDINAND-EGELINSKI-ST",
    "Address": "FERDINAND-EGELINSKI-STR. 3",
    "Address__1": "",
    "City": "AHLBECK",
    "Postcode": 17419,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038378-28415",
    "24 hour?": "FALSE",
    "Latitude": 53.9341,
    "Longitude": 14.1945,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.9341,14.1945",
    "IsOpen24Hours": false,
    "id": "53.9341,14.1945"
  },
  {
    "ID": 1085,
    "Tankstellennummer": "0F235",
    "Name": "Aral Tankstelle Bad Segeberg, Ziegelstraße 46",
    "Address": "Ziegelstraße 46",
    "Address__1": "",
    "City": "Bad Segeberg",
    "Postcode": 23795,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 45512920,
    "24 hour?": "TRUE",
    "Latitude": 53.942005,
    "Longitude": 10.30004,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.942005,10.30004",
    "IsOpen24Hours": true,
    "id": "53.942005,10.30004"
  },
  {
    "ID": 1027,
    "Tankstellennummer": "0F190",
    "Name": "Aral Tankstelle Lübeck, Gneversdorfer Weg 12-14",
    "Address": "Gneversdorfer Weg 12-14",
    "Address__1": "",
    "City": "Lübeck",
    "Postcode": 23570,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 45025175,
    "24 hour?": "TRUE",
    "Latitude": 53.959333,
    "Longitude": 10.861492,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.959333,10.861492",
    "IsOpen24Hours": true,
    "id": "53.959333,10.861492"
  },
  {
    "ID": 1065,
    "Tankstellennummer": "0F222",
    "Name": "Aral Tankstelle Marne, Meldorfer Straße 24",
    "Address": "Meldorfer Straße 24",
    "Address__1": "",
    "City": "Marne",
    "Postcode": 25709,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4851606,
    "24 hour?": "FALSE",
    "Latitude": 53.959975,
    "Longitude": 9.007651,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "53.959975,9.007651",
    "IsOpen24Hours": false,
    "id": "53.959975,9.007651"
  },
  {
    "ID": 1132,
    "Tankstellennummer": "0F269",
    "Name": "Aral Autobahntankstelle Schackendorf West",
    "Address": "BAB 21",
    "Address__1": "",
    "City": "Schackendorf",
    "Postcode": 23795,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 45514698,
    "24 hour?": "TRUE",
    "Latitude": 53.960176,
    "Longitude": 10.260458,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "53.960176,10.260458",
    "IsOpen24Hours": true,
    "id": "53.960176,10.260458"
  },
  {
    "ID": 1014877799,
    "Tankstellennummer": "TD039131",
    "Name": "LUEBECK-TRAVEMUENDE MOORREDDER",
    "Address": "MOORREDDER 1",
    "Address__1": "",
    "City": "LUEBECK",
    "Postcode": 23570,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04502-7879631",
    "24 hour?": "FALSE",
    "Latitude": 53.9627,
    "Longitude": 10.8611,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.9627,10.8611",
    "IsOpen24Hours": false,
    "id": "53.9627,10.8611"
  },
  {
    "ID": 833679681,
    "Tankstellennummer": "TD031799",
    "Name": "KLUETZ BOLTENHAGENER STR. 11B",
    "Address": "BOLTENHAGENER STR. 11b",
    "Address__1": "",
    "City": "KLUETZ",
    "Postcode": 23948,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038825-22578",
    "24 hour?": "FALSE",
    "Latitude": 53.9706,
    "Longitude": 11.1741,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.9706,11.1741",
    "IsOpen24Hours": false,
    "id": "53.9706,11.1741"
  },
  {
    "ID": 959516013,
    "Tankstellennummer": "TD007948",
    "Name": "PANSDORF EUTINER STR. 82",
    "Address": "EUTINER STR. 82",
    "Address__1": "",
    "City": "PANSDORF",
    "Postcode": 23689,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04504-1786",
    "24 hour?": "FALSE",
    "Latitude": 53.9876,
    "Longitude": 10.7129,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "53.9876,10.7129",
    "IsOpen24Hours": false,
    "id": "53.9876,10.7129"
  },
  {
    "ID": 238604964,
    "Tankstellennummer": "0FU97",
    "Name": "DUMMERSTORF",
    "Address": "Manfred-Roth-Straße 3",
    "Address__1": "",
    "City": "Dummerstorf",
    "Postcode": 18196,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 38208828266,
    "24 hour?": "TRUE",
    "Latitude": 54.021701,
    "Longitude": 12.231856,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.021701,12.231856",
    "IsOpen24Hours": true,
    "id": "54.021701,12.231856"
  },
  {
    "ID": 547408212,
    "Tankstellennummer": "0FV16",
    "Name": "NEUBUKOW",
    "Address": "Gewerbestraße 1",
    "Address__1": "",
    "City": "Neubukow",
    "Postcode": 18233,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3829416840,
    "24 hour?": "FALSE",
    "Latitude": 54.039041,
    "Longitude": 11.691152,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.039041,11.691152",
    "IsOpen24Hours": false,
    "id": "54.039041,11.691152"
  },
  {
    "ID": 1169,
    "Tankstellennummer": "0F296",
    "Name": "Aral Tankstelle Schenefeld, Holstenstraße 112",
    "Address": "Holstenstraße 112",
    "Address__1": "",
    "City": "Schenefeld",
    "Postcode": 25560,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4892859750,
    "24 hour?": "TRUE",
    "Latitude": 54.039641,
    "Longitude": 9.480057,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.039641,9.480057",
    "IsOpen24Hours": true,
    "id": "54.039641,9.480057"
  },
  {
    "ID": 2120109401,
    "Tankstellennummer": "TD031740",
    "Name": "WOLGAST CHAUSSEESTR. 45",
    "Address": "CHAUSSEESTR. 45",
    "Address__1": "",
    "City": "WOLGAST",
    "Postcode": 17438,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03836-232995",
    "24 hour?": "FALSE",
    "Latitude": 54.0467,
    "Longitude": 13.7521,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.0467,13.7521",
    "IsOpen24Hours": false,
    "id": "54.0467,13.7521"
  },
  {
    "ID": 330113012,
    "Tankstellennummer": "TD003939",
    "Name": "NEUMUENSTER HAART 225",
    "Address": "HAART 225",
    "Address__1": "",
    "City": "NEUMUENSTER",
    "Postcode": 24539,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04321-71272",
    "24 hour?": "FALSE",
    "Latitude": 54.0609,
    "Longitude": 10.0109,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.0609,10.0109",
    "IsOpen24Hours": false,
    "id": "54.0609,10.0109"
  },
  {
    "ID": 3298,
    "Tankstellennummer": "0FK58",
    "Name": "Aral Tankstelle Neumünster, Holsatenring 38",
    "Address": "Holsatenring 38",
    "Address__1": "",
    "City": "Neumünster",
    "Postcode": 24539,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 432141297,
    "24 hour?": "TRUE",
    "Latitude": 54.063387,
    "Longitude": 9.983743,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.063387,9.983743",
    "IsOpen24Hours": true,
    "id": "54.063387,9.983743"
  },
  {
    "ID": 1315718016,
    "Tankstellennummer": "TD032128",
    "Name": "ZINNOWITZ AHLBECKER STR. 37",
    "Address": "AHLBECKER STR. 37",
    "Address__1": "",
    "City": "ZINNOWITZ",
    "Postcode": 17454,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038377-42728",
    "24 hour?": "FALSE",
    "Latitude": 54.0674,
    "Longitude": 13.9196,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.0674,13.9196",
    "IsOpen24Hours": false,
    "id": "54.0674,13.9196"
  },
  {
    "ID": 126732975,
    "Tankstellennummer": "TD003954",
    "Name": "NEUMUENSTER WASBEKER STR. 361",
    "Address": "WASBEKER STR. 361",
    "Address__1": "",
    "City": "NEUMUENSTER",
    "Postcode": 24537,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04321-61092",
    "24 hour?": "FALSE",
    "Latitude": 54.0685,
    "Longitude": 9.938,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.0685,9.938",
    "IsOpen24Hours": false,
    "id": "54.0685,9.938"
  },
  {
    "ID": 520036238,
    "Tankstellennummer": "0FY29",
    "Name": "SIERKSDORF",
    "Address": "A1",
    "Address__1": "",
    "City": "Sierksdorf",
    "Postcode": 23730,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 45638800,
    "24 hour?": "TRUE",
    "Latitude": 54.072042,
    "Longitude": 10.758031,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "54.072042,10.758031",
    "IsOpen24Hours": true,
    "id": "54.072042,10.758031"
  },
  {
    "ID": 2506,
    "Tankstellennummer": "0FC50",
    "Name": "Aral Tankstelle Greifswald, Schönwalder Landstr. 1",
    "Address": "Schönwalder Landstraße 1",
    "Address__1": "",
    "City": "Greifswald",
    "Postcode": 17489,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3834500709,
    "24 hour?": "FALSE",
    "Latitude": 54.072864,
    "Longitude": 13.383331,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.072864,13.383331",
    "IsOpen24Hours": false,
    "id": "54.072864,13.383331"
  },
  {
    "ID": 764427408,
    "Tankstellennummer": "TD031807",
    "Name": "ROSTOCK ERICH-SCHLESINGER-STR.",
    "Address": "ERICH-SCHLESINGER-STR.26",
    "Address__1": "",
    "City": "ROSTOCK",
    "Postcode": 18059,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0381-442084",
    "24 hour?": "FALSE",
    "Latitude": 54.0766,
    "Longitude": 12.1194,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.0766,12.1194",
    "IsOpen24Hours": false,
    "id": "54.0766,12.1194"
  },
  {
    "ID": 1008926429,
    "Tankstellennummer": "TD039636",
    "Name": "SANITZ ROSTOCKER STR. 72",
    "Address": "ROSTOCKER STR . 72",
    "Address__1": "",
    "City": "SANITZ",
    "Postcode": 18190,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038209-308",
    "24 hour?": "FALSE",
    "Latitude": 54.0777,
    "Longitude": 12.3673,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.0777,12.3673",
    "IsOpen24Hours": false,
    "id": "54.0777,12.3673"
  },
  {
    "ID": 1590451087,
    "Tankstellennummer": "TD031831",
    "Name": "ROSTOCK TESSINER STR. 98",
    "Address": "TESSINER STR. 98",
    "Address__1": "",
    "City": "ROSTOCK",
    "Postcode": 18055,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0381-683783",
    "24 hour?": "FALSE",
    "Latitude": 54.0807,
    "Longitude": 12.1891,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.0807,12.1891",
    "IsOpen24Hours": false,
    "id": "54.0807,12.1891"
  },
  {
    "ID": 3393,
    "Tankstellennummer": "0FL33",
    "Name": "Aral Tankstelle Rostock, Tessiner Str. 68",
    "Address": "Tessiner Straße 68",
    "Address__1": "",
    "City": "Rostock",
    "Postcode": 18055,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 381690324,
    "24 hour?": "FALSE",
    "Latitude": 54.081197,
    "Longitude": 12.194307,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "54.081197,12.194307",
    "IsOpen24Hours": false,
    "id": "54.081197,12.194307"
  },
  {
    "ID": 1019,
    "Tankstellennummer": "0F185",
    "Name": "Aral Tankstelle Neumünster, Rendsburger Straße 48-52",
    "Address": "Rendsburger Straße 48-52",
    "Address__1": "",
    "City": "Neumünster",
    "Postcode": 24534,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 432112622,
    "24 hour?": "TRUE",
    "Latitude": 54.082141,
    "Longitude": 9.979484,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.082141,9.979484",
    "IsOpen24Hours": true,
    "id": "54.082141,9.979484"
  },
  {
    "ID": 1126,
    "Tankstellennummer": "0F259",
    "Name": "Aral Tankstelle Meldorf, Oesterstraße 92",
    "Address": "Oesterstraße 92",
    "Address__1": "",
    "City": "Meldorf",
    "Postcode": 25704,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 48323211,
    "24 hour?": "FALSE",
    "Latitude": 54.088684,
    "Longitude": 9.096075,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.088684,9.096075",
    "IsOpen24Hours": false,
    "id": "54.088684,9.096075"
  },
  {
    "ID": 1013645744,
    "Tankstellennummer": "TD034843",
    "Name": "ROSTOCK WARNOWUFER 53",
    "Address": "WARNOWUFER 53",
    "Address__1": "",
    "City": "ROSTOCK",
    "Postcode": 18057,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0381-4903119",
    "24 hour?": "FALSE",
    "Latitude": 54.0935,
    "Longitude": 12.1155,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.0935,12.1155",
    "IsOpen24Hours": false,
    "id": "54.0935,12.1155"
  },
  {
    "ID": 509654526,
    "Tankstellennummer": "TD031864",
    "Name": "ROSTOCK U.-VON-HUTTEN-STR. 7A",
    "Address": "U.-VON-HUTTEN-STR. 7a",
    "Address__1": "",
    "City": "ROSTOCK",
    "Postcode": 18069,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0381-8083459",
    "24 hour?": "FALSE",
    "Latitude": 54.0954,
    "Longitude": 12.0831,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.0954,12.0831",
    "IsOpen24Hours": false,
    "id": "54.0954,12.0831"
  },
  {
    "ID": 2537,
    "Tankstellennummer": "0FC77",
    "Name": "Aral Tankstelle Rostock, Rövershäger Chaussee 1",
    "Address": "Rövershäger Chaussee 11",
    "Address__1": "",
    "City": "Rostock",
    "Postcode": 18146,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 381690210,
    "24 hour?": "TRUE",
    "Latitude": 54.099553,
    "Longitude": 12.176054,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.099553,12.176054",
    "IsOpen24Hours": true,
    "id": "54.099553,12.176054"
  },
  {
    "ID": 1915789711,
    "Tankstellennummer": "TD031682",
    "Name": "ROSTOCK ROEVERSHAEGER CH. 3",
    "Address": "ROEVERSHAEGER CH. 3",
    "Address__1": "",
    "City": "ROSTOCK",
    "Postcode": 18146,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0381-681630",
    "24 hour?": "FALSE",
    "Latitude": 54.0996,
    "Longitude": 12.1773,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.0996,12.1773",
    "IsOpen24Hours": false,
    "id": "54.0996,12.1773"
  },
  {
    "ID": 933969318,
    "Tankstellennummer": "TD003947",
    "Name": "NEUMUENSTER KIELER STR. 387-39",
    "Address": "KIELER STR. 387-391",
    "Address__1": "",
    "City": "NEUMUENSTER",
    "Postcode": 24536,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04321-31282",
    "24 hour?": "FALSE",
    "Latitude": 54.1028,
    "Longitude": 9.9903,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.1028,9.9903",
    "IsOpen24Hours": false,
    "id": "54.1028,9.9903"
  },
  {
    "ID": 1084955225,
    "Tankstellennummer": "TD031674",
    "Name": "GRIMMEN GREIFSWALDER CHAUSSEE",
    "Address": "GREIFSWALDER CHAUSSEE 4",
    "Address__1": "",
    "City": "GRIMMEN",
    "Postcode": 18507,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038326-2134",
    "24 hour?": "FALSE",
    "Latitude": 54.1093,
    "Longitude": 13.0562,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.1093,13.0562",
    "IsOpen24Hours": false,
    "id": "54.1093,13.0562"
  },
  {
    "ID": 2688,
    "Tankstellennummer": "0FE07",
    "Name": "Aral Tankstelle Rostock, Toitenwinkler Allee 1",
    "Address": "Toitenwinkler Allee 1",
    "Address__1": "",
    "City": "Rostock",
    "Postcode": 18147,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 381690347,
    "24 hour?": "TRUE",
    "Latitude": 54.113535,
    "Longitude": 12.161486,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.113535,12.161486",
    "IsOpen24Hours": true,
    "id": "54.113535,12.161486"
  },
  {
    "ID": 491890051,
    "Tankstellennummer": "TD031856",
    "Name": "NEUENKIRCHEN MARKTFLECKEN 17",
    "Address": "MARKTFLECKEN 17",
    "Address__1": "",
    "City": "NEUENKIRCHEN",
    "Postcode": 17498,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03834-2340",
    "24 hour?": "FALSE",
    "Latitude": 54.1172,
    "Longitude": 13.3637,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.1172,13.3637",
    "IsOpen24Hours": false,
    "id": "54.1172,13.3637"
  },
  {
    "ID": 2563,
    "Tankstellennummer": "0FC98",
    "Name": "Aral Tankstelle Grimmen, Stralsunder Str./B 194",
    "Address": "Stralsunder Straße 46",
    "Address__1": "",
    "City": "Grimmen",
    "Postcode": 18507,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 383262443,
    "24 hour?": "FALSE",
    "Latitude": 54.117768,
    "Longitude": 13.043636,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.117768,13.043636",
    "IsOpen24Hours": false,
    "id": "54.117768,13.043636"
  },
  {
    "ID": 1035,
    "Tankstellennummer": "0F199",
    "Name": "Aral Tankstelle Eutin, Bürgerm.-Steenbock-Str. 3",
    "Address": "Bgm.-Steenbock-Str. 39",
    "Address__1": "",
    "City": "Eutin",
    "Postcode": 23701,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 45217908656,
    "24 hour?": "FALSE",
    "Latitude": 54.128861,
    "Longitude": 10.624868,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.128861,10.624868",
    "IsOpen24Hours": false,
    "id": "54.128861,10.624868"
  },
  {
    "ID": 3310,
    "Tankstellennummer": "0FK68",
    "Name": "Aral Tankstelle Eutin, Ploener Landstr. 7",
    "Address": "Ploener Landstraße 7",
    "Address__1": "",
    "City": "Eutin",
    "Postcode": 23701,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 45214016302,
    "24 hour?": "FALSE",
    "Latitude": 54.12896,
    "Longitude": 10.58002,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.12896,10.58002",
    "IsOpen24Hours": false,
    "id": "54.12896,10.58002"
  },
  {
    "ID": 2096128212,
    "Tankstellennummer": 5034,
    "Name": "Agip Rostock",
    "Address": "Schmarler Damm 9",
    "Address__1": "",
    "City": "Rostock",
    "Postcode": 18069,
    "Country": "Germany",
    "Brand": "AP",
    "Telephone": "+49 (0) 38112096",
    "24 hour?": "TRUE",
    "Latitude": 54.12927218,
    "Longitude": 12.07547295,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "54.12927218,12.07547295",
    "IsOpen24Hours": true,
    "id": "54.12927218,12.07547295"
  },
  {
    "ID": 125759045,
    "Tankstellennummer": "TD031757",
    "Name": "ROSTOCK-LUETTENKLEIN AN DER ST",
    "Address": "AN DER STADTAUTOBAHN 70",
    "Address__1": "",
    "City": "ROSTOCK-LUETTENKLEIN",
    "Postcode": 18107,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0381-712092",
    "24 hour?": "FALSE",
    "Latitude": 54.1315,
    "Longitude": 12.063,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.1315,12.063",
    "IsOpen24Hours": false,
    "id": "54.1315,12.063"
  },
  {
    "ID": 2528,
    "Tankstellennummer": "0FC68",
    "Name": "Aral Tankstelle Kühlungsborn, Doberaner Straße",
    "Address": "Doberaner Straße/Gewerbegebiet",
    "Address__1": "",
    "City": "Kühlungsborn",
    "Postcode": 18225,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 382936111,
    "24 hour?": "FALSE",
    "Latitude": 54.144393,
    "Longitude": 11.768801,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.144393,11.768801",
    "IsOpen24Hours": false,
    "id": "54.144393,11.768801"
  },
  {
    "ID": 846573962,
    "Tankstellennummer": "TD031971",
    "Name": "KUEHLUNGSBORN NEUE REIHE 94C",
    "Address": "NEUE REIHE 94C",
    "Address__1": "",
    "City": "KUEHLUNGSBORN",
    "Postcode": 18225,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038293-7608",
    "24 hour?": "FALSE",
    "Latitude": 54.1449,
    "Longitude": 11.7356,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.1449,11.7356",
    "IsOpen24Hours": false,
    "id": "54.1449,11.7356"
  },
  {
    "ID": 2791,
    "Tankstellennummer": "0FG82",
    "Name": "Aral Tankstelle Plön, Rautenbergstr. 37A",
    "Address": "Rautenbergstraße 37A",
    "Address__1": "",
    "City": "Plön",
    "Postcode": 24306,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4522760176,
    "24 hour?": "TRUE",
    "Latitude": 54.157166,
    "Longitude": 10.440925,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.157166,10.440925",
    "IsOpen24Hours": true,
    "id": "54.157166,10.440925"
  },
  {
    "ID": 1944477443,
    "Tankstellennummer": "TD038232",
    "Name": "BAD MALENTE EUTINER STR.",
    "Address": "EUTINER STR. 54",
    "Address__1": "",
    "City": "BAD MALENTE",
    "Postcode": 23714,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04523-6050",
    "24 hour?": "FALSE",
    "Latitude": 54.1634,
    "Longitude": 10.5664,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.1634,10.5664",
    "IsOpen24Hours": false,
    "id": "54.1634,10.5664"
  },
  {
    "ID": 2644,
    "Tankstellennummer": "0FD68",
    "Name": "Aral Tankstelle Rostock, An der Stadtautobahn 60",
    "Address": "An der Stadtautobahn 60",
    "Address__1": "",
    "City": "Rostock",
    "Postcode": 18119,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3815016,
    "24 hour?": "FALSE",
    "Latitude": 54.168422,
    "Longitude": 12.079958,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "54.168422,12.079958",
    "IsOpen24Hours": false,
    "id": "54.168422,12.079958"
  },
  {
    "ID": 481160950,
    "Tankstellennummer": "TD033779",
    "Name": "PLOEN LUETJENBURGER STR. 61",
    "Address": "LUETJENBURGER STR. 61",
    "Address__1": "",
    "City": "PLOEN",
    "Postcode": 24306,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04522-749000",
    "24 hour?": "FALSE",
    "Latitude": 54.1701,
    "Longitude": 10.4356,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.1701,10.4356",
    "IsOpen24Hours": false,
    "id": "54.1701,10.4356"
  },
  {
    "ID": 1312734034,
    "Tankstellennummer": "0FQ57",
    "Name": "DÄTGEN",
    "Address": "Grotwisch 2",
    "Address__1": "",
    "City": "Dätgen",
    "Postcode": 24589,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 43299136038,
    "24 hour?": "TRUE",
    "Latitude": 54.180057,
    "Longitude": 9.94417,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.180057,9.94417",
    "IsOpen24Hours": true,
    "id": "54.180057,9.94417"
  },
  {
    "ID": 159498558,
    "Tankstellennummer": "TD040089",
    "Name": "HEIDE MELDORFER STR. 143",
    "Address": "MELDORFER STR. 143",
    "Address__1": "",
    "City": "HEIDE",
    "Postcode": 25746,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0481-69881555",
    "24 hour?": "FALSE",
    "Latitude": 54.1844,
    "Longitude": 9.0904,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.1844,9.0904",
    "IsOpen24Hours": false,
    "id": "54.1844,9.0904"
  },
  {
    "ID": 3467,
    "Tankstellennummer": "0FL87",
    "Name": "Aral Tankstelle Wesselburen, Schuelperstr. 27",
    "Address": "Schuelperstraße 27",
    "Address__1": "",
    "City": "Wesselburen",
    "Postcode": 25764,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 48332373,
    "24 hour?": "FALSE",
    "Latitude": 54.214361,
    "Longitude": 8.923417,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "54.214361,8.923417",
    "IsOpen24Hours": false,
    "id": "54.214361,8.923417"
  },
  {
    "ID": 1145,
    "Tankstellennummer": "0F279",
    "Name": "Aral Tankstelle Lensahn, Dieselstraße 1",
    "Address": "Dieselstraße 1",
    "Address__1": "",
    "City": "Lensahn",
    "Postcode": 23738,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4363901841,
    "24 hour?": "TRUE",
    "Latitude": 54.21759,
    "Longitude": 10.896773,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.21759,10.896773",
    "IsOpen24Hours": true,
    "id": "54.21759,10.896773"
  },
  {
    "ID": 2510,
    "Tankstellennummer": "0FC78",
    "Name": "Aral Tankstelle Ribnitz-Damgarten, B 105",
    "Address": "Alte Klockenhäger Landstraße 1",
    "Address__1": "",
    "City": "Ribnitz-Damgarten",
    "Postcode": 18311,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3821811922,
    "24 hour?": "TRUE",
    "Latitude": 54.239377,
    "Longitude": 12.407908,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "54.239377,12.407908",
    "IsOpen24Hours": true,
    "id": "54.239377,12.407908"
  },
  {
    "ID": 551540372,
    "Tankstellennummer": "TD031849",
    "Name": "RIBNITZ-DAMGARTEN ALTE KLOCKEN",
    "Address": "ALTE KLOCKENHAEGER LANDSTR. 2",
    "Address__1": "",
    "City": "RIBNITZ-DAMGARTEN",
    "Postcode": 18311,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03821-2659",
    "24 hour?": "FALSE",
    "Latitude": 54.2399,
    "Longitude": 12.4053,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.2399,12.4053",
    "IsOpen24Hours": false,
    "id": "54.2399,12.4053"
  },
  {
    "ID": 1094,
    "Tankstellennummer": "0F244",
    "Name": "Aral Tankstelle Molfsee, Hamburger Landstraße 103",
    "Address": "Hamburger Landstraße 103",
    "Address__1": "",
    "City": "Molfsee",
    "Postcode": 24113,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 431651536,
    "24 hour?": "TRUE",
    "Latitude": 54.272592,
    "Longitude": 10.074924,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.272592,10.074924",
    "IsOpen24Hours": true,
    "id": "54.272592,10.074924"
  },
  {
    "ID": 3400,
    "Tankstellennummer": "0FL40",
    "Name": "Aral Tankstelle Stralsund, Greifswalder Chaussee 62",
    "Address": "Greifswalder Chaussee 62",
    "Address__1": "",
    "City": "Stralsund",
    "Postcode": 18439,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3831270126,
    "24 hour?": "FALSE",
    "Latitude": 54.276688,
    "Longitude": 13.10687,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.276688,13.10687",
    "IsOpen24Hours": false,
    "id": "54.276688,13.10687"
  },
  {
    "ID": 863953023,
    "Tankstellennummer": "TD039891",
    "Name": "KIEL EDISONSTR. 44",
    "Address": "EDISONSTR. 44",
    "Address__1": "",
    "City": "KIEL",
    "Postcode": 24145,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "0431-717570",
    "24 hour?": "FALSE",
    "Latitude": 54.2774,
    "Longitude": 10.161,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.2774,10.161",
    "IsOpen24Hours": false,
    "id": "54.2774,10.161"
  },
  {
    "ID": 3243,
    "Tankstellennummer": "0FK20",
    "Name": "Aral Tankstelle Kiel, Konrad-Adenauer-Damm 100",
    "Address": "Konrad-Adenauer-Damm 100",
    "Address__1": "",
    "City": "Kiel",
    "Postcode": 24143,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 431782778,
    "24 hour?": "TRUE",
    "Latitude": 54.296963,
    "Longitude": 10.164891,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.296963,10.164891",
    "IsOpen24Hours": true,
    "id": "54.296963,10.164891"
  },
  {
    "ID": 1142,
    "Tankstellennummer": "0F276",
    "Name": "Aral Tankstelle Kiel, Rendsburger Landstr. 205",
    "Address": "Rendsburger Landstr. 205",
    "Address__1": "",
    "City": "Kiel",
    "Postcode": 24113,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4316409660,
    "24 hour?": "TRUE",
    "Latitude": 54.300466,
    "Longitude": 10.092195,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.300466,10.092195",
    "IsOpen24Hours": true,
    "id": "54.300466,10.092195"
  },
  {
    "ID": 493890138,
    "Tankstellennummer": "TD040006",
    "Name": "STRALSUND GREIFSWALDER CHAUSSE",
    "Address": "GREIFSWALDER CHAUSSEE 122",
    "Address__1": "",
    "City": "STRALSUND",
    "Postcode": 18439,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03831-2031038",
    "24 hour?": "FALSE",
    "Latitude": 54.3009,
    "Longitude": 13.0947,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.3009,13.0947",
    "IsOpen24Hours": false,
    "id": "54.3009,13.0947"
  },
  {
    "ID": 577031393,
    "Tankstellennummer": "TD031765",
    "Name": "STRALSUND WERFTSTR. 13",
    "Address": "WERFTSTR. 13",
    "Address__1": "",
    "City": "STRALSUND",
    "Postcode": 18439,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03831-292749",
    "24 hour?": "FALSE",
    "Latitude": 54.3022,
    "Longitude": 13.1006,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.3022,13.1006",
    "IsOpen24Hours": false,
    "id": "54.3022,13.1006"
  },
  {
    "ID": 3272,
    "Tankstellennummer": "0FK41",
    "Name": "Aral Tankstelle St Peter-Ording, Wittenduener Allee 11",
    "Address": "Wittenduener Allee 11",
    "Address__1": "",
    "City": "St Peter-Ording",
    "Postcode": 25826,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 48633007,
    "24 hour?": "FALSE",
    "Latitude": 54.302244,
    "Longitude": 8.651125,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.302244,8.651125",
    "IsOpen24Hours": false,
    "id": "54.302244,8.651125"
  },
  {
    "ID": 1129,
    "Tankstellennummer": "0F264",
    "Name": "Aral Tankstelle Kiel, Schwedendamm 21",
    "Address": "Schwedendamm 21",
    "Address__1": "",
    "City": "Kiel",
    "Postcode": 24143,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 43174400,
    "24 hour?": "TRUE",
    "Latitude": 54.307356,
    "Longitude": 10.136729,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.307356,10.136729",
    "IsOpen24Hours": true,
    "id": "54.307356,10.136729"
  },
  {
    "ID": 1026,
    "Tankstellennummer": "0F189",
    "Name": "Aral Tankstelle Rendsburg, Fockbeker Chaussee 2",
    "Address": "Fockbeker Chaussee 4-6",
    "Address__1": "",
    "City": "Rendsburg",
    "Postcode": 24768,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 433171246,
    "24 hour?": "TRUE",
    "Latitude": 54.307929,
    "Longitude": 9.648026,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.307929,9.648026",
    "IsOpen24Hours": true,
    "id": "54.307929,9.648026"
  },
  {
    "ID": 2775,
    "Tankstellennummer": "0FG69",
    "Name": "Aral Tankstelle Kiel, Ostring 250-254",
    "Address": "Ostring 250-254",
    "Address__1": "",
    "City": "Kiel",
    "Postcode": 24148,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 431724260,
    "24 hour?": "TRUE",
    "Latitude": 54.313558,
    "Longitude": 10.162886,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.313558,10.162886",
    "IsOpen24Hours": true,
    "id": "54.313558,10.162886"
  },
  {
    "ID": 1117,
    "Tankstellennummer": "0F254",
    "Name": "Aral Tankstelle Kiel, Stockholmstraße 2",
    "Address": "Stockholmstraße 2",
    "Address__1": "",
    "City": "Kiel",
    "Postcode": 24109,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 431527585,
    "24 hour?": "TRUE",
    "Latitude": 54.326109,
    "Longitude": 10.058283,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.326109,10.058283",
    "IsOpen24Hours": true,
    "id": "54.326109,10.058283"
  },
  {
    "ID": 1049,
    "Tankstellennummer": "0F208",
    "Name": "Aral Tankstelle Schönkirchen, Schönberger Landstr.135",
    "Address": "Schönberger Landstraße135",
    "Address__1": "",
    "City": "Schönkirchen",
    "Postcode": 24232,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4348446,
    "24 hour?": "FALSE",
    "Latitude": 54.338912,
    "Longitude": 10.221949,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "54.338912,10.221949",
    "IsOpen24Hours": false,
    "id": "54.338912,10.221949"
  },
  {
    "ID": 1572987225,
    "Tankstellennummer": "TD031666",
    "Name": "BARTH CHAUSSEESTRASSE 75",
    "Address": "CHAUSSEESTRASSE 75",
    "Address__1": "",
    "City": "BARTH",
    "Postcode": 18356,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038231-2023",
    "24 hour?": "FALSE",
    "Latitude": 54.3535,
    "Longitude": 12.7245,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.3535,12.7245",
    "IsOpen24Hours": false,
    "id": "54.3535,12.7245"
  },
  {
    "ID": 1121,
    "Tankstellennummer": "0F257",
    "Name": "Aral Autobahntankstelle Alt Duvenstedt, Hüttener Berge West (A 7)",
    "Address": "A7",
    "Address__1": "",
    "City": "Alt Duvenstedt",
    "Postcode": 24791,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4338466,
    "24 hour?": "TRUE",
    "Latitude": 54.364191,
    "Longitude": 9.670657,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "54.364191,9.670657",
    "IsOpen24Hours": true,
    "id": "54.364191,9.670657"
  },
  {
    "ID": 1175,
    "Tankstellennummer": "0F299",
    "Name": "Aral Tankstelle Großenbrode, An der E 47 - Seeseite",
    "Address": "An der E 47 -Seeseite-",
    "Address__1": "",
    "City": "Großenbrode",
    "Postcode": 23775,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4367207,
    "24 hour?": "TRUE",
    "Latitude": 54.381326,
    "Longitude": 11.09432,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "54.381326,11.09432",
    "IsOpen24Hours": true,
    "id": "54.381326,11.09432"
  },
  {
    "ID": 1064,
    "Tankstellennummer": "0F221",
    "Name": "Aral Tankstelle Großenbrode, An der E 47 - Landseite",
    "Address": "An der E 47 -Landseite-",
    "Address__1": "",
    "City": "Großenbrode",
    "Postcode": 23775,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4367206,
    "24 hour?": "FALSE",
    "Latitude": 54.383294,
    "Longitude": 11.093269,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.383294,11.093269",
    "IsOpen24Hours": false,
    "id": "54.383294,11.093269"
  },
  {
    "ID": 3308,
    "Tankstellennummer": "0FK67",
    "Name": "Aral Tankstelle Schönberg, Bahnhofstr. 32",
    "Address": "Bahnhofstraße 32",
    "Address__1": "",
    "City": "Schönberg",
    "Postcode": 24217,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 43444107075,
    "24 hour?": "FALSE",
    "Latitude": 54.388788,
    "Longitude": 10.373199,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.388788,10.373199",
    "IsOpen24Hours": false,
    "id": "54.388788,10.373199"
  },
  {
    "ID": 1044768226,
    "Tankstellennummer": "TD034819",
    "Name": "BINZ PRORAER CHAUSSEE 8",
    "Address": "PRORAER CHAUSSEE 8",
    "Address__1": "",
    "City": "BINZ",
    "Postcode": 18609,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038393-144146",
    "24 hour?": "FALSE",
    "Latitude": 54.4024,
    "Longitude": 13.6029,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.4024,13.6029",
    "IsOpen24Hours": false,
    "id": "54.4024,13.6029"
  },
  {
    "ID": 1161,
    "Tankstellennummer": "0F289",
    "Name": "Aral Tankstelle Kiel-Friedrichsort, Koppelberg 1",
    "Address": "Koppelberg 1",
    "Address__1": "",
    "City": "Kiel-Friedrichsort",
    "Postcode": 24159,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4313970552,
    "24 hour?": "TRUE",
    "Latitude": 54.403287,
    "Longitude": 10.166145,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.403287,10.166145",
    "IsOpen24Hours": true,
    "id": "54.403287,10.166145"
  },
  {
    "ID": 542260301,
    "Tankstellennummer": "TD031815",
    "Name": "BERGEN STRALSUNDER CH. 18",
    "Address": "STRALSUNDER CH. 18",
    "Address__1": "",
    "City": "BERGEN",
    "Postcode": 18528,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "03838-22354",
    "24 hour?": "FALSE",
    "Latitude": 54.4075,
    "Longitude": 13.4181,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.4075,13.4181",
    "IsOpen24Hours": false,
    "id": "54.4075,13.4181"
  },
  {
    "ID": 2504,
    "Tankstellennummer": "0FC48",
    "Name": "Aral Tankstelle Bergen, Stralsunder Chaussee 23",
    "Address": "Stralsunder Chaussee 23",
    "Address__1": "",
    "City": "Bergen",
    "Postcode": 18528,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 3838251820,
    "24 hour?": "FALSE",
    "Latitude": 54.40752,
    "Longitude": 13.416135,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.40752,13.416135",
    "IsOpen24Hours": false,
    "id": "54.40752,13.416135"
  },
  {
    "ID": 2779,
    "Tankstellennummer": "0FG72",
    "Name": "Aral Tankstelle Eckernförde, Flensburger Str. 1",
    "Address": "Flensburger Straße 1",
    "Address__1": "",
    "City": "Eckernförde",
    "Postcode": 24340,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4351470645,
    "24 hour?": "TRUE",
    "Latitude": 54.465046,
    "Longitude": 9.834287,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.465046,9.834287",
    "IsOpen24Hours": true,
    "id": "54.465046,9.834287"
  },
  {
    "ID": 1067,
    "Tankstellennummer": "0F224",
    "Name": "Aral Tankstelle Husum, Flensburger Chaussee 50",
    "Address": "Flensburger Chaussee 44",
    "Address__1": "",
    "City": "Husum",
    "Postcode": 25813,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 484172690,
    "24 hour?": "TRUE",
    "Latitude": 54.487644,
    "Longitude": 9.077271,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.487644,9.077271",
    "IsOpen24Hours": true,
    "id": "54.487644,9.077271"
  },
  {
    "ID": 473034588,
    "Tankstellennummer": "TD033035",
    "Name": "SASSNITZ GEWERBEPARK 3",
    "Address": "GEWERBEPARK 3",
    "Address__1": "",
    "City": "SASSNITZ",
    "Postcode": 18546,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038392-32620",
    "24 hour?": "FALSE",
    "Latitude": 54.5133,
    "Longitude": 13.6031,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.5133,13.6031",
    "IsOpen24Hours": false,
    "id": "54.5133,13.6031"
  },
  {
    "ID": 13597,
    "Tankstellennummer": "0FN94",
    "Name": "Aral Tankstelle Schuby, Westring 19",
    "Address": "Westring 19",
    "Address__1": "",
    "City": "Schuby",
    "Postcode": 24850,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4621949406,
    "24 hour?": "TRUE",
    "Latitude": 54.518852,
    "Longitude": 9.464748,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.518852,9.464748",
    "IsOpen24Hours": true,
    "id": "54.518852,9.464748"
  },
  {
    "ID": 762462346,
    "Tankstellennummer": "TD031880",
    "Name": "ALTENKIRCHEN UMGEHUNGSSTRASSE",
    "Address": "UMGEHUNGSSTRASSE 1",
    "Address__1": "",
    "City": "ALTENKIRCHEN",
    "Postcode": 18556,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "038391-231",
    "24 hour?": "FALSE",
    "Latitude": 54.6318,
    "Longitude": 13.3476,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.6318,13.3476",
    "IsOpen24Hours": false,
    "id": "54.6318,13.3476"
  },
  {
    "ID": 1180,
    "Tankstellennummer": "0FE69",
    "Name": "Aral Tankstelle Kappeln, Eckernförder Straße 9 b",
    "Address": "Eckernförder Straße 9 b",
    "Address__1": "",
    "City": "Kappeln",
    "Postcode": 24376,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 464281008,
    "24 hour?": "TRUE",
    "Latitude": 54.657239,
    "Longitude": 9.946128,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "54.657239,9.946128",
    "IsOpen24Hours": true,
    "id": "54.657239,9.946128"
  },
  {
    "ID": 1029,
    "Tankstellennummer": "0F194",
    "Name": "Aral Tankstelle Kappeln, An der Bundesstr. 201",
    "Address": "Bundesstraße 1",
    "Address__1": "",
    "City": "Kappeln",
    "Postcode": 24376,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4642924788,
    "24 hour?": "FALSE",
    "Latitude": 54.65925,
    "Longitude": 9.925332,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.65925,9.925332",
    "IsOpen24Hours": false,
    "id": "54.65925,9.925332"
  },
  {
    "ID": 1561096171,
    "Tankstellennummer": "TD039701",
    "Name": "ENGE-SANDE BUNDESSTR. 14",
    "Address": "BUNDESSTR.14",
    "Address__1": "",
    "City": "ENGE-SANDE",
    "Postcode": 25917,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04662-87300",
    "24 hour?": "FALSE",
    "Latitude": 54.7362,
    "Longitude": 8.9453,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.7362,8.9453",
    "IsOpen24Hours": false,
    "id": "54.7362,8.9453"
  },
  {
    "ID": 1628,
    "Tankstellennummer": "0F654",
    "Name": "Aral Tankstelle Risum-Lindholm, An der B 5",
    "Address": "An der B5 3",
    "Address__1": "",
    "City": "Risum-Lindholm",
    "Postcode": 25920,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4661941159,
    "24 hour?": "FALSE",
    "Latitude": 54.760199,
    "Longitude": 8.878096,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "54.760199,8.878096",
    "IsOpen24Hours": false,
    "id": "54.760199,8.878096"
  },
  {
    "ID": 1099,
    "Tankstellennummer": "0F246",
    "Name": "Aral Tankstelle Schafflund, Hauptstraße 61",
    "Address": "Hauptstraße 61",
    "Address__1": "",
    "City": "Schafflund",
    "Postcode": 24980,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4639326,
    "24 hour?": "FALSE",
    "Latitude": 54.760746,
    "Longitude": 9.176394,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.760746,9.176394",
    "IsOpen24Hours": false,
    "id": "54.760746,9.176394"
  },
  {
    "ID": 149933757,
    "Tankstellennummer": "TD039693",
    "Name": "LECK FLENSBURGER STR. 37",
    "Address": "FLENSBURGER STR. 37",
    "Address__1": "",
    "City": "LECK",
    "Postcode": 25917,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04662-1500",
    "24 hour?": "FALSE",
    "Latitude": 54.7655,
    "Longitude": 8.979,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.7655,8.979",
    "IsOpen24Hours": false,
    "id": "54.7655,8.979"
  },
  {
    "ID": 2780,
    "Tankstellennummer": "0FG73",
    "Name": "Aral Tankstelle Flensburg, Husumer Str. 30",
    "Address": "Husumer Straße 30",
    "Address__1": "",
    "City": "Flensburg",
    "Postcode": 24941,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 46197312,
    "24 hour?": "TRUE",
    "Latitude": 54.775036,
    "Longitude": 9.426041,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.775036,9.426041",
    "IsOpen24Hours": true,
    "id": "54.775036,9.426041"
  },
  {
    "ID": 2039887641,
    "Tankstellennummer": "TD039909",
    "Name": "NIEBUELL BUSCH JOHANNSEN STR.",
    "Address": "BUSCH JOHANNSEN STR. 2",
    "Address__1": "",
    "City": "NIEBUELL",
    "Postcode": 25899,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04661-4042",
    "24 hour?": "FALSE",
    "Latitude": 54.7799,
    "Longitude": 8.8346,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.7799,8.8346",
    "IsOpen24Hours": false,
    "id": "54.7799,8.8346"
  },
  {
    "ID": 1033,
    "Tankstellennummer": "0F197",
    "Name": "Aral Tankstelle Flensburg, Nordstraße 20",
    "Address": "Nordstraße 20",
    "Address__1": "",
    "City": "Flensburg",
    "Postcode": 24943,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 4616740568,
    "24 hour?": "TRUE",
    "Latitude": 54.797087,
    "Longitude": 9.476214,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "TRUE",
    "location": "54.797087,9.476214",
    "IsOpen24Hours": true,
    "id": "54.797087,9.476214"
  },
  {
    "ID": 533143241,
    "Tankstellennummer": "TD039917",
    "Name": "SUEDERLUEGUM HAUPTSTR. 4",
    "Address": "HAUPTSTR. 4",
    "Address__1": "",
    "City": "SUEDERLUEGUM",
    "Postcode": 25923,
    "Country": "Germany",
    "Brand": "TOTAL",
    "Telephone": "04663-18360",
    "24 hour?": "FALSE",
    "Latitude": 54.8692,
    "Longitude": 8.9062,
    "Automated Station": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "54.8692,8.9062",
    "IsOpen24Hours": false,
    "id": "54.8692,8.9062"
  },
  {
    "ID": 1102,
    "Tankstellennummer": "0F247",
    "Name": "Aral Tankstelle Sylt-Ost, Keitumer Landstraße",
    "Address": "Mittelweg 3",
    "Address__1": "",
    "City": "Sylt",
    "Postcode": 25980,
    "Country": "Germany",
    "Brand": "ARAL",
    "Telephone": 46518367790,
    "24 hour?": "FALSE",
    "Latitude": 54.900401,
    "Longitude": 8.339205,
    "Automated Station": "FALSE",
    "Petit Bistro": "TRUE",
    "Open 24 hours": "FALSE",
    "location": "54.900401,8.339205",
    "IsOpen24Hours": false,
    "id": "54.900401,8.339205"
  }
];
