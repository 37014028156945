import React from 'react';
import {
  LocalizePropType,
  selectCurrentInstallation,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import InstalledView from './InstalledView';
import { navigateTo } from '../../../utils';
import { useSelector } from 'react-redux';

type Props = {
  localize: LocalizePropType;
};

const InstalledContainer = ({ localize }: Props) => {
  const history = useHistory();
  const currentInstallation = useSelector(selectCurrentInstallation);

  const onPressReady = () => {
    navigateTo(history, '/select');
  };

  return (
    <InstalledView
      localize={localize}
      installation={currentInstallation!}
      onPressReady={onPressReady}
    />
  );
};

export default withTextLocalizer(InstalledContainer);
