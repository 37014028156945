import React from 'react';
import './TextAutocomplete.less';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export type AutocompleteOption = {
  label: string;
  value: string;
};

type Props = {
  id: string;
  placeHolder?: string;
  data: Array<any>;
  value?: string | null;
  inputValue?: string;
  handleChange: (event: any, newValue: AutocompleteOption | null) => void;
  handleInputChange: (event: any, newValue: string) => void;
};

const TextAutocomplete = ({
  id,
  placeHolder,
  data,
  value,
  inputValue,
  handleChange,
  handleInputChange,
}: Props) => {
  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      id={id}
      options={data}
      sx={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label={placeHolder} />}
    />
  );
};

TextAutocomplete.defaultProps = {
  placeHolder: '',
  inputValue: '',
  value: '',
};

export default TextAutocomplete;
