import { FirstStepsState } from '../../../../@types';

const initialState: FirstStepsState = {
  address: {
    streetName: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    country: '',
  },
  referralCode: '',
  phone: {
    phoneNumber: '',
    phoneCode: {
      country: 'DE',
      code: '+49',
    },
  },
  phoneCodes: [],
  circleCode: '',
  dateOfBirth: '',
  iban: '',
  isVerifyingGDPREmail: false,
  isEditingPhone: false,
};
export default initialState;
