import React from 'react';
import { selectNotifications, dismissNotification } from 'getaway-data-layer';
import { useSelector, useDispatch } from 'react-redux';
import Message from './Message';

type NotificationType = Array<{
  id: string;
  type: string;
  message: string;
  title: string;
  actionText: string;
  actionCallback: () => void;
  dismissCallback: () => void;
}>;

const NotificationsView = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications) as NotificationType;
  const hasNotifications = notifications.length > 0;

  if (hasNotifications) {
    const notification = notifications[notifications.length - 1];
    const { id, message, type, dismissCallback } = notification;
    return (
      <Message
        key={id}
        message={message}
        type={type}
        onDismissNotification={() => {
          try {
            dispatch(dismissNotification(id));
            dismissCallback();
          } catch (e) {
            // console.log(e.toString());
          }
        }}
      />
    );
  }
  return null;
};

export default NotificationsView;
