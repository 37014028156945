import React from 'react';
import './DataTestView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import BrandButton from '../../components/BrandButton/BrandButton';
import {
  ConnectCarData,
  LocalizePropType,
  titleForDriverCar,
} from 'getaway-data-layer';
import ListItem from '../../components/ListItem/ListItem';
import DeviceStatus from '../../components/DeviceStatus/DeviceStatus';
import UnlockLockButton from '../../components/UnlockLockButton/UnlockLockButton';
import VerticalSpace from '../../components/VerticalSpace/VerticalSpace';

type props = {
  localize: LocalizePropType;
  car: ConnectCarData;
  onPressStart: () => void;
};

const DataTestView = ({ localize, car, onPressStart }: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('data.test.view.title')}
        description={localize('data.test.view.description')}
      />
      <div className="screen-body">
        <ListItem title={localize('connect.item.plate')} value={car.plate} />
        <ListItem
          title={localize('connect.item.model')}
          value={titleForDriverCar(car)}
        />
        <ListItem
          title={localize('connect.item.chassisNumber')}
          value={car.chassisNumber!}
        />
        <DeviceStatus />
        <UnlockLockButton />
        <VerticalSpace />
        <BrandButton
          title={localize('data.test.view.btn.txt')}
          onClick={onPressStart}
        />
      </div>
    </div>
  );
};

export default DataTestView;
