import React, { useEffect, useMemo } from 'react';
import {
  ConnectCarData,
  findCars,
  LocalizePropType,
  selectConnectCars,
  setInstallation,
  setSelectedConnectCar,
  setSelectedConnectDeviceStatus,
  withTextLocalizer,
  setVehicleRegistration,
} from 'getaway-data-layer';
import { useHistory, useLocation } from 'react-router-dom';
import SelectCarView from './SelectCarView';
import { navigateTo } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteOption } from '../../components/TextAutocomplete/TextAutocomplete';

type Props = {
  localize: LocalizePropType;
};

const SelectCarContainer = ({ localize }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const cars = useSelector(selectConnectCars);

  const [carChassisNumber, setCarChassisNumber] = React.useState<string | null>(
    null
  );

  const [inputValue, setInputValue] = React.useState('');
  const [selectedTab, setSelectedTab] = React.useState(
    (location?.state as any)?.from === 'remove' ? '1' : '0'
  );

  const installationCars = useMemo(
    () =>
      cars?.filter(
        (car: any) => car.status === 'EQUIPMENT_READY' && !car.installation
      ),
    [cars]
  );

  const installationOngoingCars = useMemo(
    () =>
      cars?.filter(
        (car: any) => car.status === 'EQUIPMENT_READY' && !!car.installation
      ),
    [cars]
  );

  const removalCars = useMemo(
    () => cars?.filter((car: any) => car.status === 'TERMINATED'),
    [cars]
  );

  const onPressInstallationCar = () => {
    const selectedCar = installationCars.find(
      (car) => car.chassisNumber === carChassisNumber
    );
    dispatch(setSelectedConnectCar(selectedCar!));
    navigateTo(history, '/car-data');
  };

  const onPressRemovalCar = () => {
    const selectedCar = removalCars.find(
      (car) => car.chassisNumber === carChassisNumber
    );
    dispatch(setSelectedConnectCar(selectedCar!));
    navigateTo(history, '/remove');
  };

  const continueInstallation = (car: ConnectCarData) => {
    dispatch(setSelectedConnectCar(car!));
    dispatch(setInstallation(car?.installation!));
    setTimeout(() => {
      navigateTo(history, '/car-data');
    }, 1500);
  };

  const handleChange = (event: any, newValue: AutocompleteOption | null) => {
    setCarChassisNumber(newValue?.value!);
  };

  const handleInputChange = (event: any, newValue: string) => {
    setInputValue(newValue);
  };

  const onChangeTab = (e: any, index: any) => {
    setSelectedTab(index);
    setCarChassisNumber(null);
    setInputValue('');
  };

  useEffect(() => {
    if (installationOngoingCars?.length > 0) {
      continueInstallation(installationOngoingCars[0]);
    }
  }, [installationOngoingCars]);

  useEffect(() => {
    dispatch(findCars());
    dispatch(setSelectedConnectCar(null));
    dispatch(setInstallation(null));
    dispatch(setVehicleRegistration(null));
    dispatch(setSelectedConnectDeviceStatus(null));
  }, []);

  return (
    <SelectCarView
      localize={localize}
      selectedTab={selectedTab}
      installationCars={installationCars}
      removalCars={removalCars}
      carChassisNumber={carChassisNumber}
      inputValue={inputValue}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
      onPressInstallationCar={onPressInstallationCar}
      onPressRemovalCar={onPressRemovalCar}
      onChangeTab={onChangeTab}
    />
  );
};

export default withTextLocalizer(SelectCarContainer);
