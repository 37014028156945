import getawayTexts from './initialData/InitialState.default.data';
import ubqTexts from './initialData/InitialState.ubq.data';
import robbenWientjesTexts from './initialData/InitialState.robbenWientjes.data';
import mietSmartTexts from './initialData/InitialState.mietSmart.data';
import connectTexts from './initialData/InitialState.connect.data';
import konigTexts from './initialData/InitialState.konig.data';
import mietbar from './initialData/InitialState.mietbar.data';
import bndTexts from './initialData/InitialState.bnd.data';
import tlaTexts from './initialData/InitialState.tla.data';
import frmTexts from './initialData/InitialState.frm.data';
import mymTexts from './initialData/InitialState.mym.data';
import { TextsState } from '../../../../@types';
import { isConnect } from '../../../../utils/isConnect';

const emptyLanguage = {
  de: {},
  deFormal: {},
  en: {},
};

const initialState: TextsState = {
  default: isConnect() ? emptyLanguage : getawayTexts,
  ubeeqo: isConnect() ? emptyLanguage : ubqTexts,
  'robben-wientjes': isConnect() ? emptyLanguage : robbenWientjesTexts,
  'miet-smart': isConnect() ? emptyLanguage : mietSmartTexts,
  'book-n-drive': isConnect() ? emptyLanguage : bndTexts,
  'franklin-mobil': isConnect() ? emptyLanguage : frmTexts,
  teilauto: isConnect() ? emptyLanguage : tlaTexts,
  konig: isConnect() ? emptyLanguage : konigTexts,
  mietbar: isConnect() ? emptyLanguage : mietbar,
  'my-motor': isConnect() ? emptyLanguage : mymTexts,
  connect: isConnect() ? connectTexts : emptyLanguage,
};

export default initialState;
