import { State } from '../@types';

export const selectAreas = (store: State) => store.appData.areas;
export const selectDialog = (store: State) => store.appData.common.dialog;
export const selectOpenedModal = (store: State) =>
  store.appData.common.openedModal;
export const selectIsVerifiedUser = (store: State) =>
  store.appData.common.firstSteps;
export const selectBrandSettings = (store: State) =>
  store.appData.common.brandSettings;

export const selectBicMandatory = (store: State) =>
  store.appData.common.brandSettings.bicMandatory;

export const selectCommon = (store: State) => store.appData.common;
export const selectFeatures = (store: State) => store.appData.features;

export const selectIsOBI = (store: State) =>
  store.appData.areas.find((area) =>
    [
      store.ui.map.selectedCar?.areaId,
      store.userData.rental?.rentalData?.carData?.areaId,
    ].includes(area?.id)
  )?.fleetStyle === 'obi';

export const selectIsExpert = (store: State) =>
  store.appData.areas.find((area) =>
    [
      store.ui.map.selectedCar?.areaId,
      store.userData.rental?.rentalData?.carData?.areaId,
    ].includes(area?.id)
  )?.fleetStyle === 'expert';

export const selectIsSPP = (store: State) =>
  store.appData.areas.find((area) =>
    [
      store.ui.map.selectedCar?.areaId,
      store.userData.rental?.rentalData?.carData?.areaId,
    ].includes(area?.id)
  )?.fleetStyle === 'spp';

export const selectFleetStyle = (store: State) =>
  store.appData.areas.find((area) =>
    [
      store.ui.map.selectedCar?.areaId,
      store.userData.rental?.rentalData?.carData?.areaId,
    ].includes(area?.id)
  )?.fleetStyle || 'default';
