import React from 'react';
import './SelectCarView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { ConnectCarData, LocalizePropType } from 'getaway-data-layer';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TextAutocomplete, {
  AutocompleteOption,
} from '../../components/TextAutocomplete/TextAutocomplete';
import BrandButton from '../../components/BrandButton/BrandButton';

type props = {
  localize: LocalizePropType;
  selectedTab: string;
  installationCars: Array<ConnectCarData>;
  removalCars: Array<ConnectCarData>;
  carChassisNumber?: string | null;
  inputValue?: string;
  handleChange: (event: any, newValue: AutocompleteOption | null) => void;
  handleInputChange: (event: any, newValue: string) => void;
  onPressInstallationCar: () => void;
  onPressRemovalCar: () => void;
  onChangeTab: (e: any, index: any) => void;
};

const SelectCarView = ({
  localize,
  selectedTab,
  installationCars,
  removalCars,
  carChassisNumber,
  inputValue,
  handleChange,
  handleInputChange,
  onPressInstallationCar,
  onPressRemovalCar,
  onChangeTab,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('car.select.view.title')}
        description={localize('car.select.view.description')}
        showCloseButton={false}
      />
      <div className="screen-body">
        <TabContext value={selectedTab}>
          <TabList
            centered
            value={selectedTab}
            onChange={onChangeTab}
            variant="fullWidth"
          >
            <Tab label={localize('car.select.tab.installation')} value="0" />
            <Tab label={localize('car.select.tab.removal')} value="1" />
          </TabList>
          <TabPanel value="0" style={{ padding: '16px 0' }}>
            <TextAutocomplete
              id="installationCars"
              placeHolder={localize('connect.item.car.install')}
              value={carChassisNumber}
              inputValue={inputValue}
              data={installationCars?.map((car) => ({
                label: car?.chassisNumber,
                value: car?.chassisNumber,
              }))}
              handleInputChange={handleInputChange}
              handleChange={handleChange}
            />
          </TabPanel>
          <TabPanel value="1" style={{ padding: '16px 0' }}>
            <TextAutocomplete
              id="removalCars"
              placeHolder={localize('connect.item.car.remove')}
              value={carChassisNumber}
              inputValue={inputValue}
              data={removalCars?.map((car) => ({
                label: car?.chassisNumber,
                value: car?.chassisNumber,
              }))}
              handleInputChange={handleInputChange}
              handleChange={handleChange}
            />
          </TabPanel>
        </TabContext>
        <BrandButton
          disabled={!carChassisNumber}
          title={localize(
            selectedTab === '0'
              ? 'car.select.tab.installation'
              : 'car.select.tab.removal'
          )}
          onClick={
            selectedTab === '0' ? onPressInstallationCar : onPressRemovalCar
          }
        />
      </div>
    </div>
  );
};

export default SelectCarView;
