import React from 'react';
import './FuelCardView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import BrandButton from '../../components/BrandButton/BrandButton';
import { ConnectFuelCardData, LocalizePropType } from 'getaway-data-layer';
import TextAutocomplete, {
  AutocompleteOption,
} from '../../components/TextAutocomplete/TextAutocomplete';

type props = {
  localize: LocalizePropType;
  fuelCards: Array<ConnectFuelCardData>;
  cardNo?: string | null;
  inputValue?: string;
  handleChange: (event: any, newValue: AutocompleteOption | null) => void;
  handleInputChange: (event: any, newValue: string) => void;
  onPressContinue: () => void;
};

const FuelCardView = ({
  localize,
  fuelCards,
  cardNo,
  inputValue,
  handleChange,
  handleInputChange,
  onPressContinue,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('fuel.card.view.title')}
        description={localize('fuel.card.view.description')}
      />
      <div className="screen-body">
        <TextAutocomplete
          id="fuelCards"
          placeHolder={localize('connect.item.fuel.card.number')}
          value={cardNo}
          inputValue={inputValue}
          data={fuelCards?.map((card) => ({
            label: card?.number,
            value: card?.number,
          }))}
          handleInputChange={handleInputChange}
          handleChange={handleChange}
        />
        <BrandButton
          title={localize('fuel.card.view.btn.txt')}
          onClick={onPressContinue}
          disabled={!cardNo}
        />
      </div>
    </div>
  );
};

export default FuelCardView;
