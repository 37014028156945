import React from 'react';
import { TextField, Box } from '@mui/material';
import { FormikValues } from 'formik';
import './ForgotPasswordView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import BrandButton from '../../components/BrandButton/BrandButton';
import { LocalizePropType } from 'getaway-data-layer';

type props = {
  localize: LocalizePropType;
  formik: FormikValues;
  onPressBack: () => void;
};

const ForgotPasswordView = ({ localize, formik, onPressBack }: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('login.forgot.title')}
        onPressBack={onPressBack}
        showCloseButton={false}
        showMenu={false}
      />
      <div className="screen-body">
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <TextField
            required
            fullWidth
            id="email"
            className="form-input"
            label={localize('login.email.placeholder')}
            helperText={(formik.touched.email && formik.errors.email) || ''}
            error={formik.touched.email && Boolean(formik.errors.email)}
            variant="standard"
            type="email"
            autoComplete="email"
            {...formik.getFieldProps('email')}
          />
          <BrandButton title={localize('login.forgot.text')} />
        </Box>
      </div>
    </div>
  );
};

export default ForgotPasswordView;
