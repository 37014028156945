import AsyncStorage from '@react-native-async-storage/async-storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const getReducerPersistBlacklist = (reducer: string): Array<string> => {
  switch (reducer) {
    case 'rental':
      return ['rentalViolations', 'finishRentalViolations'];

    case 'bookings':
      return ['bookingViolations'];

    default:
      return [];
  }
};

const getReducerPersistWhitelist = (reducer: string): Array<string> => {
  switch (reducer) {
    case 'map':
      return ['isFirstRental', 'showHomeWT', 'userAddress'];

    case 'damages':
      return ['newDamages'];

    case 'appData':
      return ['areas', 'serviceTypes', 'common', 'features'];

    case 'carsSearch':
      return ['circleId'];

    case 'networkStatus':
      return ['getHistory'];

    default:
      return [];
  }
};

const storage = AsyncStorage;

const LOCALSTORAGE_KEY = 'applicationData';
const storageWrapper = {
  getItem: async (key: string): Promise<string | null> => {
    const allItemsJson = await storage.getItem(LOCALSTORAGE_KEY);
    const allItems = allItemsJson ? JSON.parse(allItemsJson) : {};

    // Try to get the item from allObjects first
    if (Object.prototype.hasOwnProperty.call(allItems, key)) {
      return allItems[key];
    }
    // If not present in allObjects, read directly from storage
    return await storage.getItem(key);
  },

  setItem: async (key: string, item: string): Promise<void> => {
    const allItemsJson = await storage.getItem(LOCALSTORAGE_KEY);
    const allItems = allItemsJson ? JSON.parse(allItemsJson) : {};

    // Update the item in allObjects
    allItems[key] = item;
    await storage.setItem(LOCALSTORAGE_KEY, JSON.stringify(allItems));

    // Delete the item from top-level storage if it exists
    const topLevelItem = await storage.getItem(key);
    if (topLevelItem !== null) {
      await storage.removeItem(key);
    }
  },

  removeItem: async (key: string): Promise<void> => {
    const allItemsJson = await storage.getItem(LOCALSTORAGE_KEY);
    const allItems = allItemsJson ? JSON.parse(allItemsJson) : {};

    // Remove the item from allObjects
    delete allItems[key];
    await storage.setItem(LOCALSTORAGE_KEY, JSON.stringify(allItems));

    // Additionally, ensure the item is removed from top-level storage
    await storage.removeItem(key);
  },
};

export const getPersistConfig = (reducer: string): any => {
  const config: any = {
    key: reducer,
    storage: storageWrapper,
    stateReconciler: autoMergeLevel2,
  };
  const blacklist = getReducerPersistBlacklist(reducer);
  const whitelist = getReducerPersistWhitelist(reducer);

  if (blacklist.length > 0) {
    config.blacklist = blacklist;
  }

  if (whitelist.length > 0) {
    config.whitelist = whitelist;
  }

  return config;
};
