import { PhoneCode } from '../../Types';

export type SelectedAddress = {
  streetName: string;
  streetNumber: string;
  city: string;
  country: string;
  postalCode: string;
};

export type PhoneType = {
  phoneNumber: string;
  phoneCode: PhoneCode;
};

export type FirstStepsState = {
  readonly address: SelectedAddress;
  readonly phone: PhoneType;
  readonly referralCode: string;
  readonly phoneCodes: Array<PhoneCode>;
  readonly circleCode: string;
  readonly dateOfBirth: string;
  readonly iban: string;
  readonly isVerifyingGDPREmail: boolean;
  readonly isEditingPhone: boolean;
};

enum FirstStepsTypes {
  SET_ADDRESS = 'ui/firstSteps/SET_ADDRESS',
  SET_BDAY = 'ui/firstSteps/SET_BDAY',
  SET_IBAN = 'ui/firstSteps/SET_IBAN',
  CLEAR_ADDRESS = 'ui/firstSteps/CLEAR_ADDRESS',
  SET_PHONE_NUMBER = 'ui/firstSteps/SET_PHONE_NUMBER',
  SET_PHONE_CODE = 'ui/firstSteps/SET_PHONE_CODE',
  SET_PHONE_CODES = 'ui/firstSteps/SET_PHONE_CODES',
  CLEAR_PHONE_NUMBER = 'ui/firstSteps/CLEAR_PHONE_NUMBER',
  CLEAR_PHONE_CODE = 'ui/firstSteps/CLEAR_PHONE_CODE',
  SET_REFERRAL_CODE = 'ui/firstSteps/SET_REFERRAL_CODE',
  CLEAR_REFERRAL_CODE = 'ui/firstSteps/CLEAR_REFERRAL_CODE',
  SET_CIRCLE_CODE = 'ui/firstSteps/SET_CIRCLE_CODE',
  ClEAR_CIRCLE_CODE = 'ui/firstSteps/ClEAR_CIRCLE_CODE',
  SET_IS_VERIFYING_GDPR_EMAIL = 'ui/SET_IS_VERIFYING_GDPR_EMAIL',
  SET_IS_EDITING_PHONE = 'ui/SET_IS_EDITING_PHONE',
}

export default FirstStepsTypes;

export type SetAddress = {
  type: FirstStepsTypes.SET_ADDRESS;
  payload: {
    address: SelectedAddress;
  };
};

export type SetBDay = {
  type: FirstStepsTypes.SET_BDAY;
  payload: {
    dateOfBirth: string;
  };
};

export type SetIban = {
  type: FirstStepsTypes.SET_IBAN;
  payload: {
    iban: string;
  };
};

export type SetIsVerifyingGDPREmail = {
  type: FirstStepsTypes.SET_IS_VERIFYING_GDPR_EMAIL;
  payload: {
    isVerifyingGDPREmail: boolean;
  };
};

export type SetIsEditingPhone = {
  type: FirstStepsTypes.SET_IS_EDITING_PHONE;
  payload: {
    isEditingPhone: boolean;
  };
};

export type ClearAddress = {
  type: FirstStepsTypes.CLEAR_ADDRESS;
};

export type SetPhoneNumber = {
  type: FirstStepsTypes.SET_PHONE_NUMBER;
  payload: {
    phoneNumber: string;
  };
};

export type SetPhoneCode = {
  type: FirstStepsTypes.SET_PHONE_CODE;
  payload: {
    phoneCode: PhoneCode;
  };
};

export type SetPhoneCodes = {
  type: FirstStepsTypes.SET_PHONE_CODES;
  payload: {
    phoneCodes: Array<PhoneCode>;
  };
};

export type ClearPhoneNumber = {
  type: FirstStepsTypes.CLEAR_PHONE_NUMBER;
};

export type ClearPhoneCode = {
  type: FirstStepsTypes.CLEAR_PHONE_CODE;
};

export type SetReferralCode = {
  type: FirstStepsTypes.SET_REFERRAL_CODE;
  payload: {
    referralCode: string;
  };
};

export type ClearReferralCode = {
  type: FirstStepsTypes.CLEAR_REFERRAL_CODE;
};

export type SetCircleCode = {
  type: FirstStepsTypes.SET_CIRCLE_CODE;
  payload: {
    circleCode: string;
  };
};

export type ClearCircleCode = {
  type: FirstStepsTypes.ClEAR_CIRCLE_CODE;
};

export type FirstStepsAction =
  | SetAddress
  | SetPhoneNumber
  | SetPhoneCode
  | SetPhoneCodes
  | ClearPhoneNumber
  | ClearPhoneCode
  | SetReferralCode
  | ClearReferralCode
  | SetCircleCode
  | ClearCircleCode
  | SetBDay
  | SetIban
  | SetIsEditingPhone
  | SetIsVerifyingGDPREmail
  | ClearAddress;
