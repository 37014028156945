import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import './UnlockLockButton.less';
import {
  fetchDeviceStatus,
  LocalizePropType,
  lockConnectDevice,
  selectConnectDeviceStatus,
  selectCurrentInstallation,
  unlockConnectDevice,
  withTextLocalizer,
} from 'getaway-data-layer';

type Props = {
  localize: LocalizePropType;
};

const UnlockLockButton = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const currentInstallation = useSelector(selectCurrentInstallation);
  const conditions = useSelector(selectConnectDeviceStatus);
  const [isDeviceLocked, setIsDeviceLocked] = React.useState(
    conditions?.immobilizerLocked!
  );

  React.useEffect(() => {
    if (conditions) {
      setIsDeviceLocked(conditions?.immobilizerLocked!);
    }
  }, [conditions]);

  const handleLockCar = () => {
    dispatch(lockConnectDevice(currentInstallation?.device?.id!));
    setTimeout(
      () => dispatch(fetchDeviceStatus(currentInstallation?.device?.id!)),
      400
    );
  };

  const handleUnlockCar = () => {
    dispatch(unlockConnectDevice(currentInstallation?.device?.id!));
    setTimeout(
      () => dispatch(fetchDeviceStatus(currentInstallation?.device?.id!)),
      400
    );
  };

  return (
    <div className="buttons-wrapper">
      <Button
        className="lock button"
        onClick={handleLockCar}
        style={
          !isDeviceLocked ? { color: '#ee4e4e', borderColor: '#ee4e4e' } : {}
        }
        disabled={isDeviceLocked}
      >
        {localize('button.lock')}
      </Button>
      <Button
        className="unlock button"
        onClick={handleUnlockCar}
        style={
          isDeviceLocked ? { color: '#ee4e4e', borderColor: '#ee4e4e' } : {}
        }
        disabled={!isDeviceLocked}
      >
        {localize('button.unlock')}
      </Button>
    </div>
  );
};

export default withTextLocalizer(UnlockLockButton);
