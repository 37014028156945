import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTexts } from 'getaway-data-layer';

type props = {
  children: React.ReactElement;
};

const Data = ({ children }: props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTexts());
  }, []);

  return children;
};

export default Data;
