import React, { ReactNode } from 'react';
import './ListItem.less';
import { ListItemIcon, Divider } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import BrandIcon from '../BrandIcon/BrandIcon';
import message from '../Common/message';

type Props = {
  leftIcon?:
    | 'phone'
    | 'home'
    | 'license'
    | 'email'
    | 'key'
    | 'plus'
    | 'sepa'
    | 'visa'
    | 'paymentSepa'
    | 'paymentMastercard'
    | 'paymentVisa'
    | 'carFuel'
    | 'warning'
    | 'fuelKind'
    | 'fuelCard'
    | 'fuelCopy'
    | 'sandClock'
    | 'sandClockRed';
  title: string;
  value?: string | ReactNode;
  rightIcon?:
    | 'arrowRight'
    | 'arrowRightBrand'
    | 'iconIncomplete'
    | 'inProgress'
    | 'navigationArrow'
    | 'trash'
    | 'info'
    | 'arrowUp'
    | 'arrowDown';
  leftIconSize?: number;
  rightIconSize?: number;
  onClick?: any;
  copyOnClick?: boolean;
  showSeparator?: boolean;
  containerStyles?: any;
  leftIconStyles?: any;
  leftItem?: React.ReactElement;
};

const ListItem = ({
  leftIcon,
  title,
  value,
  rightIcon,
  leftIconSize,
  rightIconSize,
  onClick,
  copyOnClick,
  showSeparator,
  containerStyles,
  leftIconStyles,
  leftItem,
}: Props) => {
  const copyValue = () => {
    if (typeof value === 'string') {
      navigator.clipboard.writeText(value);
      message.success('Copied');
    }
  };

  return (
    <>
      <div
        style={{
          ...{ display: 'flex', padding: '10px 0', marginTop: '8px' },
          ...containerStyles,
        }}
        onClick={copyOnClick ? copyValue : onClick}
      >
        {leftIcon && (
          <ListItemIcon style={leftIconStyles}>
            <BrandIcon
              name={leftIcon}
              style={{ width: leftIconSize, height: leftIconSize }}
            />
          </ListItemIcon>
        )}
        {leftItem}
        <div className="list-item-text noselect">{title}</div>
        {value && <div className="list-item-value">{value}</div>}
        {copyOnClick && (
          <ContentCopy
            style={{ marginLeft: '10px' }}
            sx={{ color: '#383838', width: 18 }}
          />
        )}
        {rightIcon && (
          <ListItemIcon style={{ minWidth: 'unset' }}>
            <BrandIcon
              name={rightIcon}
              style={{
                width: rightIcon === 'inProgress' ? 18 : rightIconSize,
                height: rightIcon === 'inProgress' ? 18 : rightIconSize,
                marginLeft: '10px',
              }}
            />
          </ListItemIcon>
        )}
      </div>
      {showSeparator && <Divider />}
    </>
  );
};

ListItem.defaultProps = {
  leftIconSize: 20,
  rightIconSize: 20,
  leftIcon: null,
  rightIcon: null,
  value: null,
  leftItem: null,
  showSeparator: true,
  containerStyles: {},
  leftIconStyles: {},
  copyOnClick: false,
  onClick: null,
};

export default ListItem;
