import React from 'react';
import './InstalledView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import BrandButton from '../../components/BrandButton/BrandButton';
import {
  ConnectInstallationData,
  LocalizePropType,
  msToHMS,
} from 'getaway-data-layer';

type props = {
  localize: LocalizePropType;
  installation: ConnectInstallationData;
  onPressReady: () => void;
};
const InstalledView = ({ localize, installation, onPressReady }: props) => {
  const duration =
    installation?.finishedDate! && installation?.startDate!
      ? (new Date(installation?.finishedDate!) as any) -
        (new Date(installation?.startDate!) as any)
      : 0;
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('installed.view.title')}
        showCloseButton={false}
      />
      <div className="screen-body">
        <div className="duration-container">
          <img
            className="car-connected"
            src={`/svg/car-connected.svg`}
            alt="car-connected"
          />
          <div className="duration-label">
            {localize('installed.view.duration')}
          </div>
          <div className="duration-value">
            {msToHMS(duration, localize, true)}
          </div>
        </div>
        <BrandButton
          title={localize('installed.view.btn.txt')}
          onClick={onPressReady}
        />
      </div>
    </div>
  );
};

export default InstalledView;
