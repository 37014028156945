import * as React from 'react';
import * as Sentry from '@sentry/react';
import { Result } from 'antd';
import { withUser } from 'getaway-data-layer';
import BrandButton from '../BrandButton/BrandButton';

type Props = { clearAuth: () => void };
type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    Sentry.captureException(error);
  }

  onResetPressed = () => {
    const { clearAuth } = this.props;
    clearAuth();
    window?.location?.replace(window?.origin);
    this.setState({ hasError: false });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Result
          status="warning"
          title="Etwas hat nicht funktioniert."
          subTitle="Wir haben eine Fehlermeldung erhalten und arbeiten bereits an der Lösung. Bitte beende jetzt die App und starte sie neu. Sollte das Problem weiterhin auftreten, kontaktiere bitte unser ServiceTeam."
          extra={
            <BrandButton title="	Neu anmelden" onClick={this.onResetPressed} />
          }
        />
      );
    }

    return children;
  }
}

export default withUser(ErrorBoundary);
