import React, { useEffect, useState } from 'react';
import {
  getBase64,
  LocalizePropType,
  selectCurrentInstallation,
  selectSelectedConnectCar,
  selectVehicleRegistration,
  startCarInstallation,
  uploadInstallationDocument,
  withTextLocalizer,
  setVehicleRegistration,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import CarDataView, { REGISTRATION_IMAGE_ID } from './CarDataView';
import { navigateTo } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  localize: LocalizePropType;
};

const CarDataContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCar = useSelector(selectSelectedConnectCar);
  const currentInstallation = useSelector(selectCurrentInstallation);
  const vehicleRegistration = useSelector(selectVehicleRegistration);

  const [started, setStarted] = useState(false);

  const onPressUpload = () => {
    dispatch(
      uploadInstallationDocument(
        selectedCar?.id!,
        vehicleRegistration?.base64!,
        () => {
          dispatch(setVehicleRegistration(null));
          navigateTo(history, '/device');
        }
      )
    );
  };

  const onPressStart = () => {
    dispatch(
      startCarInstallation(selectedCar?.id!, () => {
        setStarted(true);
      })
    );
  };

  const onChangeImage = () => {
    // @ts-ignore
    const file = document.getElementById(REGISTRATION_IMAGE_ID).files;
    const imageFile = file[file.length - 1];
    getBase64(imageFile, (convertedBase64: string) =>
      dispatch(
        setVehicleRegistration({
          uri: URL.createObjectURL(imageFile),
          base64: convertedBase64,
        })
      )
    );
  };

  const onPressRemoveImage = () => {
    dispatch(setVehicleRegistration(null));
  };

  useEffect(() => {
    setStarted(!!currentInstallation);
  }, [currentInstallation]);

  useEffect(() => {
    dispatch(setVehicleRegistration(null));
    if (!selectedCar) {
      navigateTo(history, '/select');
    }

    document
      .getElementById(REGISTRATION_IMAGE_ID)
      ?.addEventListener('change', onChangeImage, true);
  }, []);

  useEffect(() => {
    if (!currentInstallation && started) {
      navigateTo(history, '/select');
    }
    setStarted(!!currentInstallation);
  }, [currentInstallation]);

  return selectedCar ? (
    <CarDataView
      localize={localize}
      car={selectedCar!}
      started={started}
      installationScan={vehicleRegistration?.uri}
      onPressUpload={onPressUpload}
      onPressStart={onPressStart}
      onPressRemoveImage={onPressRemoveImage}
    />
  ) : null;
};

export default withTextLocalizer(CarDataContainer);
