import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAdmin } from 'getaway-data-layer';

type Props = {
  component: React.ElementType;
  [x: string]: any;
};

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const user = useSelector(selectAdmin);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return <Component {...props} />;
        }
        return <Redirect to={{ pathname: '/login' }} />;
      }}
    />
  );
};

export default PrivateRoute;
