import React, { useState, useEffect } from 'react';
import { Image } from 'antd';
import cn from 'classnames';
import './ImageInput.less';
import { isCameraPermissionGranted, showWebAlert } from 'getaway-data-layer';

type Props = {
  show?: boolean;
  containerStyle?: any;
  imageId: string | undefined;
  image?: string | null;
  onPressRemoveImage: () => void;
};

const ImageInput = ({
  show,
  containerStyle,
  imageId,
  image,
  onPressRemoveImage,
}: Props) => {
  const [cameraEnabled, setCameraEnabled] = useState(true);

  const checkCameraPermission = () => {
    isCameraPermissionGranted().then((granted) => {
      setCameraEnabled(granted);
      if (!granted) {
        showWebAlert(
          'Camera permission denied',
          'Please enable camera access from browser settings',
          'ok',
          () => {}
        );
      }
    });
  };

  useEffect(() => {
    checkCameraPermission();
  }, []);

  return (
    <div className="input-image-wrapper" style={containerStyle}>
      <div
        className={cn('layout', { invisible: !show }, { transparent: !!image })}
        onClick={checkCameraPermission}
      >
        <input
          id={imageId}
          type="file"
          accept="image/*"
          capture="environment"
          className="addImage invisible"
          style={image ? { visibility: 'hidden' } : {}}
          disabled={!cameraEnabled}
        />
        {show && (
          <div className={cn('addImage', { full: !!image })}>
            {image ? (
              <Image
                src={image}
                wrapperStyle={{
                  objectFit: 'cover',
                }}
              />
            ) : (
              <img
                src={'/images/addImage.png'}
                className="input-image"
                style={{ objectFit: 'contain' }}
                alt="input-image"
              />
            )}
            {image && (
              <div onClick={onPressRemoveImage} className="trashTouchable">
                <img
                  src="/images/trash.png"
                  className="trash"
                  alt="trash-image"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ImageInput.defaultProps = {
  show: true,
  image: null,
  containerStyle: { height: window.innerHeight * 0.2 },
};

export default ImageInput;
