import React, { useEffect } from 'react';
import {
  finishCarInstallation,
  LocalizePropType,
  selectCurrentInstallation,
  selectSelectedConnectCar,
  selectSelectedConnectDevice,
  selectVehicleRegistration,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import DataConfirmView from './DataConfirmView';
import { navigateTo } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  localize: LocalizePropType;
};

const DataConfirmContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCar = useSelector(selectSelectedConnectCar);
  const selectedDevice = useSelector(selectSelectedConnectDevice);
  const currentInstallation = useSelector(selectCurrentInstallation);
  const vehicleRegistration = useSelector(selectVehicleRegistration);

  const finishInstallation = () => {
    dispatch(
      finishCarInstallation(selectedCar?.id!, () => {
        navigateTo(history, '/installed');
      })
    );
  };

  const onPressConfirm = () => {
    finishInstallation();
  };

  useEffect(() => {
    if (!currentInstallation) {
      navigateTo(history, '/select');
    }
  }, [currentInstallation]);

  return (
    <DataConfirmView
      localize={localize}
      selectedCar={selectedCar!}
      installationScan={vehicleRegistration?.uri}
      selectedDevice={currentInstallation?.device || selectedDevice!}
      selectedFuelCard={currentInstallation?.fuelCard!}
      onPressConfirm={onPressConfirm}
    />
  );
};

export default withTextLocalizer(DataConfirmContainer);
