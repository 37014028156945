type PlatformType = 'Android' | 'iOS' | 'Web' | 'Unknown' | undefined;
let platform: PlatformType;

const loadPlatform = () => {
  if (!platform || platform === 'Unknown') {
    platform = getPlatform();
  }
  return platform;
};
export const isIOS = () => {
  return loadPlatform() === 'iOS';
};

export const isWeb = () => {
  return loadPlatform() === 'Web';
};

export const isAndroid = () => {
  return loadPlatform() === 'Android';
};

const getPlatform = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    // We are in a browser environment, so it's the web
    return 'Web';
  } else if (
    typeof navigator !== 'undefined' &&
    navigator.product === 'ReactNative'
  ) {
    // @ts-ignore
    return global.platform;
  }
  return 'Unknown';
};

export default getPlatform;
