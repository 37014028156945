import * as React from 'react';
import './StatusIndicator.less';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
import { selectNetworkActivities, CLIENT_TYPE } from 'getaway-data-layer';

const StatusIndicator = () => {
  const networkActivities: any = useSelector(selectNetworkActivities);
  const show = networkActivities.some((activityType: any) =>
    [
      CLIENT_TYPE.CONNECT_CLIENT.LOGIN,
      CLIENT_TYPE.CONNECT_CLIENT.FORGET_PASSWORD,
      CLIENT_TYPE.CONNECT_CLIENT.FIND_CARS,
      CLIENT_TYPE.CONNECT_CLIENT.FIND_DEVICES,
      CLIENT_TYPE.CONNECT_CLIENT.FIND_FUEL_CARDS,
      CLIENT_TYPE.CONNECT_CLIENT.GET_DEVICE_STATUS,
      CLIENT_TYPE.CONNECT_CLIENT.START_INSTALLATION,
      CLIENT_TYPE.CONNECT_CLIENT.CANCEL_INSTALLATION,
      CLIENT_TYPE.CONNECT_CLIENT.UPDATE_INSTALLATION,
      CLIENT_TYPE.CONNECT_CLIENT.FINISH_INSTALLATION,
      CLIENT_TYPE.CONNECT_CLIENT.UPLOAD_DOCUMENT,
      CLIENT_TYPE.CONNECT_CLIENT.UNINSTALL_CAR,
    ].includes(activityType)
  );
  return show ? (
    <LoadingOverlay active spinner className="loading-overlay" />
  ) : null;
};

export default StatusIndicator;
