import React from 'react';
import './RemovalView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import BrandButton from '../../components/BrandButton/BrandButton';
import {
  ConnectCarData,
  LocalizePropType,
  titleForDriverCar,
} from 'getaway-data-layer';
import ListItem from '../../components/ListItem/ListItem';
import VerticalSpace from '../../components/VerticalSpace/VerticalSpace';

type props = {
  localize: LocalizePropType;
  car: ConnectCarData;
  onPressRemove: () => void;
};

const RemovalView = ({ localize, car, onPressRemove }: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('remove.view.title')}
        description={localize('remove.view.description')}
        showCloseButton={false}
      />
      <div className="screen-body">
        <ListItem title={localize('connect.item.plate')} value={car.plate} />
        <ListItem
          title={localize('connect.item.model')}
          value={titleForDriverCar(car)}
        />
        <ListItem
          title={localize('connect.item.chassisNumber')}
          value={car.chassisNumber!}
        />
        <ListItem
          title={localize('connect.item.brand.name')}
          value={car.fleetName!}
        />
        <ListItem
          title={localize('connect.item.cloudboxx.number')}
          value={car.deviceSNR!}
        />
        {car?.fuelCardRequired && (
          <ListItem
            title={localize('connect.item.fuel.card.number')}
            value={car.fuelCardNumber!}
          />
        )}
        <VerticalSpace />
        <BrandButton
          title={localize('remove.view.btn.txt')}
          onClick={onPressRemove}
        />
      </div>
    </div>
  );
};

export default RemovalView;
