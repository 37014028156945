import austriaGasStations from './gasStations.austria';
import germanyGasStations from './gasStations.germany';

// Script to assign location and id's to  gas stations
// console.log(
//   austriaGasStations
//     .filter((item) => item['Automated Station'] !== 'TRUE')
//     .map((station) => {
//       const location = `${Number(station.Latitude)},${Number(
//         station.Longitude
//       )}`;
//       delete station['Business Wash'];
//       delete station['HGV Suitable'];
//       delete station['Petit Bistrovq'];
//       delete station['Accepts Toll Payments'];
//       delete station['Motorway Site'];
//       delete station['Truck Diesel'];
//       delete station['Fax'];
//       return {
//         ...station,
//         location,
//         IsOpen24Hours: station['Open 24 hours'] === 'TRUE',
//         Brand:
//           station.Brand === 'WE'
//             ? 'Westfalen'
//             : station.Brand === 'AR' || station.Brand === 'RL'
//             ? 'ARAL'
//             : station.Brand === 'TO'
//             ? 'TOTAL'
//             : station.Brand,
//         id: `${Number(station.Latitude)},${Number(station.Longitude)}`,
//       };
//     })
// );

export const gasStations = [...austriaGasStations, ...germanyGasStations];
