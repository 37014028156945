import base64 from 'base64-js';

export const getBase64 = (file: any, callBack: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    // @ts-ignore
    callBack(reader.result.split(',')[1]);
  };
  reader.onerror = (error) => {
    console.log('Error: ', error);
  };
};

export const base64ToByteArray = (b64: string): Uint8Array =>
  base64.toByteArray(b64);
