import React, { useEffect } from 'react';
import CSS from 'csstype';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { isMobile } from 'react-device-detect';
import './BrandButton.less';
import { selectIsKeyboardOpened } from 'getaway-data-layer';
import { attachInputListeners, removeInputListeners } from '../../../utils';

type Props = {
  title: string;
  onClick?: () => void;
  containerStyles?: CSS.Properties;
  classStyle?: string;
  disabled?: boolean;
  isLoading?: boolean;
  attachListeners?: boolean;
  onClickDisabled?: () => void;
};

const BrandButton = ({
  title,
  onClick,
  onClickDisabled,
  containerStyles,
  classStyle,
  disabled,
  isLoading,
  attachListeners,
}: Props) => {
  const isKeyboardOpened = useSelector(selectIsKeyboardOpened) as any;

  useEffect(() => {
    if (attachListeners && isMobile) {
      attachInputListeners();
      return () => {
        removeInputListeners();
      };
    }
  }, []);

  return (
    <button
      type="submit"
      className={
        classStyle ||
        (disabled ? 'disabled-brand-btn noselect' : 'brand-btn noselect')
      }
      onClick={disabled ? onClickDisabled : onClick}
      style={
        isKeyboardOpened
          ? {
              ...containerStyles,
              position: 'unset',
              width: '100%',
              marginTop: 30,
            }
          : { ...containerStyles }
      }
      disabled={!onClickDisabled && disabled}
    >
      {isLoading && (
        <CircularProgress
          color="inherit"
          size={18}
          style={{ marginRight: 16 }}
        />
      )}
      {title}
    </button>
  );
};

BrandButton.defaultProps = {
  onClick: () => {},
  containerStyles: {},
  disabled: false,
  classStyle: '',
  onClickDisabled: null,
  isLoading: false,
  attachListeners: true,
};

export default BrandButton;
