import React from 'react';
import { Image } from 'antd';
import './DataConfirmView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import BrandButton from '../../components/BrandButton/BrandButton';
import {
  ConnectCarData,
  ConnectDeviceData,
  ConnectFuelCardData,
  LocalizePropType,
  titleForDriverCar,
} from 'getaway-data-layer';
import ListItem from '../../components/ListItem/ListItem';
import VerticalSpace from '../../components/VerticalSpace/VerticalSpace';

type props = {
  localize: LocalizePropType;
  selectedCar: ConnectCarData;
  installationScan: string | undefined;
  selectedDevice: ConnectDeviceData;
  selectedFuelCard: ConnectFuelCardData;
  onPressConfirm: () => void;
};

const DataConfirmView = ({
  localize,
  selectedCar,
  installationScan,
  selectedDevice,
  selectedFuelCard,
  onPressConfirm,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('data.confirm.view.title')}
        description={localize('data.confirm.view.description')}
      />
      <div className="screen-body">
        <ListItem
          title={localize('connect.item.plate')}
          value={selectedCar.plate}
        />
        <ListItem
          title={localize('connect.item.model')}
          value={titleForDriverCar(selectedCar)}
        />
        <ListItem
          title={localize('connect.item.chassisNumber')}
          value={selectedCar?.chassisNumber!}
        />
        <ListItem
          title={localize('connect.item.device.serialNo')}
          value={selectedDevice?.serialNumber!}
        />
        {selectedCar?.fuelCardRequired && (
          <ListItem
            title={localize('connect.item.fuel.card.number')}
            value={selectedFuelCard?.number!}
          />
        )}
        <div className="confirm-view-image-wrapper">
          <Image
            src={installationScan}
            wrapperStyle={{
              objectFit: 'cover',
            }}
          />
        </div>
        <VerticalSpace />
        <BrandButton
          title={localize('data.confirm.view.btn.txt')}
          onClick={onPressConfirm}
        />
      </div>
    </div>
  );
};

export default DataConfirmView;
