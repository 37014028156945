import React from 'react';
import Box from '@mui/material/Box';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormikValues } from 'formik';
import './LoginView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import BrandButton from '../../components/BrandButton/BrandButton';
import { LocalizePropType } from 'getaway-data-layer';

type props = {
  localize: LocalizePropType;
  formik: FormikValues;
  showPassword: boolean;
  onPressForgotPassword: () => void;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const LoginView = ({
  localize,
  formik,
  showPassword,
  onPressForgotPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('login.title')}
        showCloseButton={false}
        showMenu={false}
      />
      <div className="screen-body">
        <img
          className="connect-logo"
          src={`/images/${
            process.env.REACT_APP_STAGE === 'production'
              ? 'logo-production'
              : 'logo-staging'
          }.png`}
          alt="logo"
        />
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <TextField
            required
            fullWidth
            id="email"
            className="form-input"
            label={localize('login.email.placeholder')}
            helperText={(formik.touched.email && formik.errors.email) || ''}
            error={formik.touched.email && Boolean(formik.errors.email)}
            variant="standard"
            type="email"
            autoComplete="email"
            {...formik.getFieldProps('email')}
          />
          <TextField
            required
            fullWidth
            id="password"
            className="form-input"
            type={showPassword ? 'text' : 'password'}
            label={localize('login.password.placeholder')}
            helperText={
              (formik.touched.password && formik.errors.password) || ''
            }
            error={formik.touched.password && Boolean(formik.errors.password)}
            autoComplete="current-password"
            variant="standard"
            {...formik.getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div
            onClick={onPressForgotPassword}
            className="login-view-forget-container"
          >
            <div className="login-view-forget-text">
              {localize('login.forgot.title')}
            </div>
          </div>
          <BrandButton title={localize('login.button.title')} />
        </Box>
      </div>
    </div>
  );
};

export default LoginView;
