import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CachedIcon from '@mui/icons-material/Cached';
import cn from 'classnames';
import './DeviceStatus.less';
import {
  fetchDeviceStatus,
  dateAndTimeStringFromDate,
  LocalizePropType,
  selectConnectDeviceStatus,
  withTextLocalizer,
  selectCurrentInstallation,
  selectSelectedConnectCar,
} from 'getaway-data-layer';
import BrandIcon from '../BrandIcon/BrandIcon';

const ALLOWABLE_FUEL_AMOUNT = 25;
const ALLOWABLE_VOLTAGE_LEVEL = 11.5;
const ALLOWABLE_MILEAGE = 0;

const CarConditionOffline = ({ className, label, value, icon }: any) => {
  return (
    <>
      <div className={cn('condition-item-offline', className)}>
        <p className="condition-value">{value}</p>
        <BrandIcon name={icon} style={{ width: 30, height: 30 }} />
        <p className="condition-label">{label}</p>
      </div>
    </>
  );
};

const CarCondition = ({ className, label, value, icon }: any) => {
  return (
    <>
      <div className={cn('condition-item', className)}>
        <div className="condition-left-item">
          <BrandIcon name={icon} style={{ width: 20, height: 20 }} />
          <p className="condition-label">{label}</p>
        </div>
        <p className="condition-value">{value}</p>
      </div>
    </>
  );
};

type Props = {
  localize: LocalizePropType;
};

const DeviceStatus = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const currentInstallation = useSelector(selectCurrentInstallation);
  const conditions = useSelector(selectConnectDeviceStatus);
  const selectedCar = useSelector(selectSelectedConnectCar);

  const handleRefreshCarConditions = () => {
    if (currentInstallation?.device?.id) {
      dispatch(fetchDeviceStatus(currentInstallation?.device?.id!));
    }
  };

  // @ts-ignore
  const isValidFuelLevel = conditions?.fuelLevel > ALLOWABLE_FUEL_AMOUNT;
  // @ts-ignore
  const isValidVoltage = conditions?.batteryVoltage >= ALLOWABLE_VOLTAGE_LEVEL;
  // @ts-ignore
  const isValidMileage = conditions?.mileage >= ALLOWABLE_MILEAGE;

  const conditionProps =
    conditions === null
      ? [
          {
            className: 'no-data',
            label: localize('car.status.fuel.level'),
            value: '-',
            icon: 'fuel_no-data',
          },
          {
            className: 'no-data',
            label: localize('car.status.battery.voltage'),
            value: '-',
            icon: 'battery_no-data',
          },
          {
            className: 'no-data',
            label: localize('car.status.mileage'),
            value: '-',
            icon: 'mileage_no-data',
          },
          {
            className: 'no-data',
            label: localize('car.status.key'),
            value: '-',
            icon: 'key_no-data',
          },
          {
            className: 'no-data',
            label: localize('car.status.fuel.card'),
            value: '-',
            icon: 'fuel-card_no-data',
          },
        ]
      : [
          ...(selectedCar?.fuelCardRequired
            ? [
                {
                  className: !isValidFuelLevel && 'invalid',
                  label: localize('car.status.fuel.level'),
                  value: conditions.fuelLevel
                    ? `${conditions.fuelLevel}%`
                    : '-',
                  icon: isValidFuelLevel ? 'fuel' : 'fuel_invalid',
                },
              ]
            : []),
          {
            className: !isValidVoltage && 'invalid',
            label: localize('car.status.battery.voltage'),
            value: conditions.batteryVoltage
              ? `${conditions.batteryVoltage}V`
              : '-',
            icon: isValidVoltage ? 'battery' : 'battery_invalid',
          },
          {
            className: !isValidMileage && 'invalid',
            label: localize('car.status.mileage'),
            value: conditions.mileage ? `${conditions.mileage}km` : '-',
            icon: isValidMileage ? 'mileage' : 'mileage_invalid',
          },
          {
            className: !selectedCar?.keyFobRequired
              ? 'valid'
              : !conditions.keyFobIn && 'invalid',
            label: localize('car.status.key'),
            value: !selectedCar?.keyFobRequired ? (
              <div>{localize('car.status.not.required')}</div>
            ) : conditions.keyFobIn ? (
              <BrandIcon name="check" style={{ width: 20, height: 20 }} />
            ) : (
              <div>{localize('car.status.missing')}</div>
            ),
            icon: !selectedCar?.keyFobRequired
              ? 'key'
              : conditions.keyFobIn
              ? 'key'
              : 'key_invalid',
          },
          ...(selectedCar?.fuelCardRequired
            ? [
                {
                  className: !conditions.fuelCardIn && 'invalid',
                  label: localize('car.status.fuel.card'),
                  value: conditions.fuelCardIn ? (
                    <BrandIcon name="check" style={{ width: 20, height: 20 }} />
                  ) : (
                    <div>{localize('car.status.missing')}</div>
                  ),
                  icon: conditions.fuelCardIn
                    ? 'fuel-card'
                    : 'fuel-card_invalid',
                },
              ]
            : []),
        ];

  return (
    <>
      <div className="conditions-wrapper">
        {conditionProps.slice(0, 3).map((props, i) => (
          <CarConditionOffline key={i} {...props} />
        ))}
      </div>
      <div className="conditions-wrapper">
        {conditionProps.slice(3).map((props, i) => (
          <CarCondition key={i} {...props} />
        ))}
      </div>

      <div className="button-wrapper">
        <Button className="refresh-button" onClick={handleRefreshCarConditions}>
          <CachedIcon />
          <div className="refresh-button-txt">
            {localize('car.status.button.refresh')}
          </div>
        </Button>
        <span className="refresh-button-last-modified">
          {conditions?.lastModified
            ? dateAndTimeStringFromDate(
                new Date(conditions?.lastModified),
                localize
              )
            : ''}
        </span>
      </div>
    </>
  );
};

export default withTextLocalizer(DeviceStatus);
