import {
  FleetUserDataOutput,
  SetAdmin,
  SetAdminAuth,
} from '../../../../@types';
import TYPES from '../../../../@types/redux/store/AdminTypes';
import createAuthString from '../../../../helpers/createAuthString';

export const setAuth = (email: string, password: string): SetAdminAuth => ({
  type: TYPES.SET_AUTH,
  payload: {
    basicAuth: createAuthString(email, password),
  },
});

export const setAdmin = (admin: FleetUserDataOutput): SetAdmin => ({
  type: TYPES.SET_ADMIN,
  payload: {
    admin,
  },
});
