import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  LocalizePropType,
  loginAdmin,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import LoginView from './LoginView';
import { navigateTo } from '../../../utils';
import { LoginModal, LoginSchema } from '../../../validation';

interface Values {
  email: string;
  password: string;
}

type Props = {
  localize: LocalizePropType;
};

const LoginContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = ({ email, password }: Values) => {
    dispatch(
      loginAdmin(email.trim(), password.trim(), localize, () => {
        navigateTo(history, '/select');
      })
    );
  };

  const onPressForgotPassword = () => {
    navigateTo(history, '/forgot');
  };

  const loginFormik = useFormik({
    initialValues: LoginModal,
    validationSchema: LoginSchema(localize),
    onSubmit,
  });

  return (
    <LoginView
      localize={localize}
      formik={loginFormik}
      showPassword={showPassword}
      onPressForgotPassword={onPressForgotPassword}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
    />
  );
};

export default withTextLocalizer(LoginContainer);
