export default [
  {
    "ID": 49683653,
    "Tankstellennummer": 4118,
    "Name": "eni24 Bad Eisenkappel 196",
    "Address": "Bad Eisenkappel",
    "Address__1": "",
    "City": "Bad Eisenkappel 196",
    "Postcode": 9135,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4238/305",
    "24 hour?": "FALSE",
    "Latitude": 46.4898,
    "Longitude": 14.5898,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.4898,14.5898",
    "IsOpen24Hours": false,
    "id": "46.4898,14.5898"
  },
  {
    "ID": 404,
    "Tankstellennummer": "0G984",
    "Name": "Ferlach, Klagenfurterstr. 25",
    "Address": "Klagenfurter Straße 23",
    "Address__1": "KTN",
    "City": "Ferlach",
    "Postcode": 9170,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 422730849,
    "24 hour?": "FALSE",
    "Latitude": 46.528,
    "Longitude": 14.2916,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.528,14.2916",
    "IsOpen24Hours": false,
    "id": "46.528,14.2916"
  },
  {
    "ID": 479986432,
    "Tankstellennummer": 4046,
    "Name": "eni Ferlach",
    "Address": "Klagenfurter Strasse 42",
    "Address__1": "",
    "City": "Ferlach",
    "Postcode": 9170,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4227/30945",
    "24 hour?": "TRUE",
    "Latitude": 46.52908,
    "Longitude": 14.28975,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.52908,14.28975",
    "IsOpen24Hours": true,
    "id": "46.52908,14.28975"
  },
  {
    "ID": 1677479754,
    "Tankstellennummer": "0GH45",
    "Name": "Thoerl-Maglern, Thoerl-Maglern 35",
    "Address": "Thörl-Maglern 35",
    "Address__1": "",
    "City": "Thörl-Maglern",
    "Postcode": 9602,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 425582656,
    "24 hour?": "TRUE",
    "Latitude": 46.544373,
    "Longitude": 13.653962,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.544373,13.653962",
    "IsOpen24Hours": true,
    "id": "46.544373,13.653962"
  },
  {
    "ID": 1741542019,
    "Tankstellennummer": "A2504",
    "Name": "Sankt Jakob im Rosental 141",
    "Address": 141,
    "Address__1": "",
    "City": "Sankt Jakob im Rosental",
    "Postcode": 9184,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 46.54654,
    "Longitude": 14.06226,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.54654,14.06226",
    "IsOpen24Hours": false,
    "id": "46.54654,14.06226"
  },
  {
    "ID": 60285384,
    "Tankstellennummer": 4106,
    "Name": "eni Arnoldstein",
    "Address": "Südautobahn Ri Italien",
    "Address__1": "",
    "City": "Arnoldstein",
    "Postcode": 9601,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4255/2875",
    "24 hour?": "TRUE",
    "Latitude": 46.55072,
    "Longitude": 13.68218,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.55072,13.68218",
    "IsOpen24Hours": true,
    "id": "46.55072,13.68218"
  },
  {
    "ID": 1613577343,
    "Tankstellennummer": 4104,
    "Name": "eni Arnoldstein",
    "Address": "Südautobahn Ri österreich",
    "Address__1": "",
    "City": "Arnoldstein",
    "Postcode": 9601,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4255/2885",
    "24 hour?": "TRUE",
    "Latitude": 46.55109,
    "Longitude": 13.68326,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.55109,13.68326",
    "IsOpen24Hours": true,
    "id": "46.55109,13.68326"
  },
  {
    "ID": 49294131,
    "Tankstellennummer": 4022,
    "Name": "eni Hart bei Villach",
    "Address": "Bundesstrasse 83",
    "Address__1": "",
    "City": "Hart bei Villach",
    "Postcode": 9587,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4257/2779",
    "24 hour?": "TRUE",
    "Latitude": 46.55596,
    "Longitude": 13.79205,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.55596,13.79205",
    "IsOpen24Hours": true,
    "id": "46.55596,13.79205"
  },
  {
    "ID": 601376364,
    "Tankstellennummer": 4024,
    "Name": "eni Feistritz an der Gail",
    "Address": "Nr. 301",
    "Address__1": "",
    "City": "Feistritz an der Gail",
    "Postcode": 9613,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4256/20224",
    "24 hour?": "TRUE",
    "Latitude": 46.5738,
    "Longitude": 13.60441,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.5738,13.60441",
    "IsOpen24Hours": true,
    "id": "46.5738,13.60441"
  },
  {
    "ID": 19683,
    "Tankstellennummer": 2570,
    "Name": "Bleiburg Gonowetz 15",
    "Address": "Gonowetz 15",
    "Address__1": "",
    "City": "Bleiburg",
    "Postcode": 9150,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04235 200715",
    "24 hour?": "FALSE",
    "Latitude": 46.57613,
    "Longitude": 14.77537,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.57613,14.77537",
    "IsOpen24Hours": false,
    "id": "46.57613,14.77537"
  },
  {
    "ID": 316272516,
    "Tankstellennummer": 4043,
    "Name": "eni Bleiburg",
    "Address": "Tulpenweg 2",
    "Address__1": "",
    "City": "Bleiburg",
    "Postcode": 9150,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4235/2056",
    "24 hour?": "TRUE",
    "Latitude": 46.58571,
    "Longitude": 14.79439,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.58571,14.79439",
    "IsOpen24Hours": true,
    "id": "46.58571,14.79439"
  },
  {
    "ID": 19681,
    "Tankstellennummer": 2587,
    "Name": "Klagenfurt Keutschacher Straße 135",
    "Address": "Keutschacher Straße 135",
    "Address__1": "",
    "City": "Viktring",
    "Postcode": 9073,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0463 281222",
    "24 hour?": "TRUE",
    "Latitude": 46.59189,
    "Longitude": 14.27158,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.59189,14.27158",
    "IsOpen24Hours": true,
    "id": "46.59189,14.27158"
  },
  {
    "ID": 1664213121,
    "Tankstellennummer": 4116,
    "Name": "eni Klagenfurt",
    "Address": "Rosentalerstr. 196",
    "Address__1": "",
    "City": "Klagenfurt",
    "Postcode": 9020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)463/23330",
    "24 hour?": "TRUE",
    "Latitude": 46.6035,
    "Longitude": 14.29695,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.6035,14.29695",
    "IsOpen24Hours": true,
    "id": "46.6035,14.29695"
  },
  {
    "ID": 1815024925,
    "Tankstellennummer": 4121,
    "Name": "eni Velden",
    "Address": "Rosegger Strasse 15",
    "Address__1": "",
    "City": "Velden",
    "Postcode": 9220,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4274/34801",
    "24 hour?": "FALSE",
    "Latitude": 46.60427,
    "Longitude": 14.035324,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.60427,14.035324",
    "IsOpen24Hours": false,
    "id": "46.60427,14.035324"
  },
  {
    "ID": 19762,
    "Tankstellennummer": 2595,
    "Name": "Villach Maria-Gailer-Straße 53",
    "Address": "Maria-Gailer-Straße 53",
    "Address__1": "",
    "City": "Villach",
    "Postcode": 9500,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04242 311180",
    "24 hour?": "TRUE",
    "Latitude": 46.60434,
    "Longitude": 13.87092,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.60434,13.87092",
    "IsOpen24Hours": true,
    "id": "46.60434,13.87092"
  },
  {
    "ID": 677,
    "Tankstellennummer": "0G975",
    "Name": "Villach, Maria Gailer Str. 29",
    "Address": "Maria Gailer Straße 28B",
    "Address__1": "KTN",
    "City": "Villach",
    "Postcode": 9500,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 4242312706,
    "24 hour?": "TRUE",
    "Latitude": 46.604738,
    "Longitude": 13.8648643,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.604738,13.8648643",
    "IsOpen24Hours": true,
    "id": "46.604738,13.8648643"
  },
  {
    "ID": 316084127,
    "Tankstellennummer": 4117,
    "Name": "eni Villach",
    "Address": "Italienerstr. 57",
    "Address__1": "",
    "City": "Villach",
    "Postcode": 9500,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4242/37200",
    "24 hour?": "TRUE",
    "Latitude": 46.6048,
    "Longitude": 13.8409,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.6048,13.8409",
    "IsOpen24Hours": true,
    "id": "46.6048,13.8409"
  },
  {
    "ID": 1029505790,
    "Tankstellennummer": 4020,
    "Name": "eni Klagenfurt",
    "Address": "Südring 260",
    "Address__1": "",
    "City": "Klagenfurt",
    "Postcode": 9020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)463/310273",
    "24 hour?": "FALSE",
    "Latitude": 46.60699,
    "Longitude": 14.30492,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.60699,14.30492",
    "IsOpen24Hours": false,
    "id": "46.60699,14.30492"
  },
  {
    "ID": 679,
    "Tankstellennummer": "0G95G",
    "Name": "Villach, Ossiacherzeile 50A",
    "Address": "Ossiacherzeile 50 A",
    "Address__1": "KTN",
    "City": "Villach",
    "Postcode": 9500,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 424224589,
    "24 hour?": "TRUE",
    "Latitude": 46.611777,
    "Longitude": 13.8560069,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.611777,13.8560069",
    "IsOpen24Hours": true,
    "id": "46.611777,13.8560069"
  },
  {
    "ID": 1631412085,
    "Tankstellennummer": 4007,
    "Name": "eni Velden",
    "Address": "Villacher Strasse 21",
    "Address__1": "",
    "City": "Velden",
    "Postcode": 9220,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4274/2482",
    "24 hour?": "FALSE",
    "Latitude": 46.61364,
    "Longitude": 14.03563,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.61364,14.03563",
    "IsOpen24Hours": false,
    "id": "46.61364,14.03563"
  },
  {
    "ID": 813823608,
    "Tankstellennummer": "0G92T",
    "Name": "K-TROEPOLACH, NR. 83",
    "Address": "Nr. 83",
    "Address__1": "",
    "City": "Tröpolach",
    "Postcode": 9631,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 434285234,
    "24 hour?": "TRUE",
    "Latitude": 46.615,
    "Longitude": 13.2806,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.615,13.2806",
    "IsOpen24Hours": true,
    "id": "46.615,13.2806"
  },
  {
    "ID": 1379873615,
    "Tankstellennummer": 4018,
    "Name": "eni Villach",
    "Address": "Ossiacher Zeile 74",
    "Address__1": "",
    "City": "Villach",
    "Postcode": 9500,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4242/41978",
    "24 hour?": "TRUE",
    "Latitude": 46.61752,
    "Longitude": 13.86021,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.61752,13.86021",
    "IsOpen24Hours": true,
    "id": "46.61752,13.86021"
  },
  {
    "ID": 763395911,
    "Tankstellennummer": "A2711",
    "Name": "Wernberg Bundesstraße 23",
    "Address": "Bundesstraße 23",
    "Address__1": "",
    "City": "Wernberg",
    "Postcode": 9241,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 46.61782,
    "Longitude": 13.95629,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.61782,13.95629",
    "IsOpen24Hours": false,
    "id": "46.61782,13.95629"
  },
  {
    "ID": 486474642,
    "Tankstellennummer": 4110,
    "Name": "eni Klagenfurt",
    "Address": "Villacherstr. 143",
    "Address__1": "",
    "City": "Klagenfurt",
    "Postcode": 9020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)463/22281",
    "24 hour?": "TRUE",
    "Latitude": 46.6199,
    "Longitude": 14.2825,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.6199,14.2825",
    "IsOpen24Hours": true,
    "id": "46.6199,14.2825"
  },
  {
    "ID": 1852777369,
    "Tankstellennummer": "A2656",
    "Name": "Klagenfurt Kohldorferstraße 10",
    "Address": "Kohldorferstraße 101",
    "Address__1": "",
    "City": "Klagenfurt",
    "Postcode": 9020,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 46.62048,
    "Longitude": 14.26865,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.62048,14.26865",
    "IsOpen24Hours": false,
    "id": "46.62048,14.26865"
  },
  {
    "ID": 1391647654,
    "Tankstellennummer": 4111,
    "Name": "eni Jenig",
    "Address": "Rattendorf 7",
    "Address__1": "",
    "City": "Jenig",
    "Postcode": 9631,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4285/247",
    "24 hour?": "FALSE",
    "Latitude": 46.6283,
    "Longitude": 13.2522,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.6283,13.2522",
    "IsOpen24Hours": false,
    "id": "46.6283,13.2522"
  },
  {
    "ID": 415837991,
    "Tankstellennummer": 4032,
    "Name": "Agip Förolach",
    "Address": "Nr. 46",
    "Address__1": "",
    "City": "Förolach",
    "Postcode": 9615,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4283/2270",
    "24 hour?": "FALSE",
    "Latitude": 46.6286,
    "Longitude": 13.47207,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.6286,13.47207",
    "IsOpen24Hours": false,
    "id": "46.6286,13.47207"
  },
  {
    "ID": 19779,
    "Tankstellennummer": 2528,
    "Name": "Klagenfurt Völkermarkter Straße 272",
    "Address": "Völkermarkter Straße 272",
    "Address__1": "",
    "City": "Klagenfurt",
    "Postcode": 9020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0463 34302",
    "24 hour?": "TRUE",
    "Latitude": 46.62956,
    "Longitude": 14.35681,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.62956,14.35681",
    "IsOpen24Hours": true,
    "id": "46.62956,14.35681"
  },
  {
    "ID": 19718,
    "Tankstellennummer": 2559,
    "Name": "Hermagor Gailtalstraße 10",
    "Address": "Gailtalstraße 12",
    "Address__1": "",
    "City": "Hermagor",
    "Postcode": 9620,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04282 2142",
    "24 hour?": "FALSE",
    "Latitude": 46.62964,
    "Longitude": 13.37877,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.62964,13.37877",
    "IsOpen24Hours": false,
    "id": "46.62964,13.37877"
  },
  {
    "ID": 265197421,
    "Tankstellennummer": 4004,
    "Name": "eni Villach",
    "Address": "Tiroler Strasse 141",
    "Address__1": "",
    "City": "Villach",
    "Postcode": 9500,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4242/55901",
    "24 hour?": "FALSE",
    "Latitude": 46.63186,
    "Longitude": 13.82397,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.63186,13.82397",
    "IsOpen24Hours": false,
    "id": "46.63186,13.82397"
  },
  {
    "ID": 521747451,
    "Tankstellennummer": 4011,
    "Name": "eni Techelsberg am Wörthersee",
    "Address": "Tibitsch 84",
    "Address__1": "",
    "City": "Techelsberg am Wörthersee",
    "Postcode": 9212,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4272/20406",
    "24 hour?": "TRUE",
    "Latitude": 46.63335,
    "Longitude": 14.10509,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.63335,14.10509",
    "IsOpen24Hours": true,
    "id": "46.63335,14.10509"
  },
  {
    "ID": 1826741962,
    "Tankstellennummer": 4139,
    "Name": "eni Klagenfurt",
    "Address": "St. Veiterstr. 61",
    "Address__1": "",
    "City": "Klagenfurt",
    "Postcode": 9020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)463/440392",
    "24 hour?": "TRUE",
    "Latitude": 46.6359,
    "Longitude": 14.3118,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.6359,14.3118",
    "IsOpen24Hours": true,
    "id": "46.6359,14.3118"
  },
  {
    "ID": 19754,
    "Tankstellennummer": 2593,
    "Name": "Pörtschach am Wörther See Hauptstraße 232",
    "Address": "Hauptstraße 232",
    "Address__1": "",
    "City": "Pörtschach",
    "Postcode": 9210,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04272 2587",
    "24 hour?": "FALSE",
    "Latitude": 46.63668,
    "Longitude": 14.13709,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.63668,14.13709",
    "IsOpen24Hours": false,
    "id": "46.63668,14.13709"
  },
  {
    "ID": 1014919099,
    "Tankstellennummer": "A2005",
    "Name": "Klagenfurt Görtschitztal Straß",
    "Address": "Görtschitztal Straße 22",
    "Address__1": "",
    "City": "Klagenfurt",
    "Postcode": 9020,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 46.63785,
    "Longitude": 14.37077,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.63785,14.37077",
    "IsOpen24Hours": false,
    "id": "46.63785,14.37077"
  },
  {
    "ID": 19432,
    "Tankstellennummer": 2562,
    "Name": "Grafenstein Dolina",
    "Address": "Dolina 36",
    "Address__1": "",
    "City": "Grafenstein",
    "Postcode": 9131,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04225 29008",
    "24 hour?": "TRUE",
    "Latitude": 46.6396,
    "Longitude": 14.4509,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.6396,14.4509",
    "IsOpen24Hours": true,
    "id": "46.6396,14.4509"
  },
  {
    "ID": 18959,
    "Tankstellennummer": "0G983",
    "Name": "Grafenstein, Poggersdorf 50",
    "Address": "Landesstraße 1-3",
    "Address__1": "KTN",
    "City": "Poggersdorf",
    "Postcode": 9130,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 422481302,
    "24 hour?": "TRUE",
    "Latitude": 46.6414,
    "Longitude": 14.4495,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.6414,14.4495",
    "IsOpen24Hours": true,
    "id": "46.6414,14.4495"
  },
  {
    "ID": 1548701029,
    "Tankstellennummer": "A2539",
    "Name": "Klagenfurt St.Veiter Straße 25",
    "Address": "St.Veiter Straße 258",
    "Address__1": "",
    "City": "Klagenfurt",
    "Postcode": 9020,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 46.65337,
    "Longitude": 14.31387,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.65337,14.31387",
    "IsOpen24Hours": false,
    "id": "46.65337,14.31387"
  },
  {
    "ID": 558094423,
    "Tankstellennummer": "A2653",
    "Name": "Klagenfurt Feldkirchnerstraße",
    "Address": "Feldkirchnerstraße 275",
    "Address__1": "",
    "City": "Klagenfurt",
    "Postcode": 9020,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 46.65383,
    "Longitude": 14.28047,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.65383,14.28047",
    "IsOpen24Hours": false,
    "id": "46.65383,14.28047"
  },
  {
    "ID": 34154347,
    "Tankstellennummer": 4003,
    "Name": "eni Völkermarkt",
    "Address": "Klagenfurter Strasse 42 A",
    "Address__1": "",
    "City": "Völkermarkt",
    "Postcode": 9100,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4232/2568",
    "24 hour?": "TRUE",
    "Latitude": 46.66048,
    "Longitude": 14.61967,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.66048,14.61967",
    "IsOpen24Hours": true,
    "id": "46.66048,14.61967"
  },
  {
    "ID": 526552999,
    "Tankstellennummer": 4103,
    "Name": "eni Völkermarkt",
    "Address": "Umfahrungsstrasse 29",
    "Address__1": "",
    "City": "Völkermarkt",
    "Postcode": 9100,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4232/3171",
    "24 hour?": "FALSE",
    "Latitude": 46.66313,
    "Longitude": 14.63101,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.66313,14.63101",
    "IsOpen24Hours": false,
    "id": "46.66313,14.63101"
  },
  {
    "ID": 702421674,
    "Tankstellennummer": 4025,
    "Name": "eni Kötschach",
    "Address": "Mauthen 189",
    "Address__1": "",
    "City": "Kötschach",
    "Postcode": 9640,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4715/408",
    "24 hour?": "TRUE",
    "Latitude": 46.66567,
    "Longitude": 12.99836,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.66567,12.99836",
    "IsOpen24Hours": true,
    "id": "46.66567,12.99836"
  },
  {
    "ID": 838325216,
    "Tankstellennummer": 4039,
    "Name": "eni Klagenfurt",
    "Address": "St. Veiter-Strasse 168",
    "Address__1": "",
    "City": "Klagenfurt",
    "Postcode": 9020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)463/41709",
    "24 hour?": "FALSE",
    "Latitude": 46.66661,
    "Longitude": 14.31578,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.66661,14.31578",
    "IsOpen24Hours": false,
    "id": "46.66661,14.31578"
  },
  {
    "ID": 1312950519,
    "Tankstellennummer": "0GAGD",
    "Name": "ST-EIBISWALD, AIBL 72",
    "Address": "Aibl 72",
    "Address__1": "",
    "City": "Eibiswald",
    "Postcode": 8552,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 346642318,
    "24 hour?": "FALSE",
    "Latitude": 46.681945,
    "Longitude": 15.230276,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.681945,15.230276",
    "IsOpen24Hours": false,
    "id": "46.681945,15.230276"
  },
  {
    "ID": 1233846477,
    "Tankstellennummer": "0G955",
    "Name": "K-FEISTRITZ AN DER DRAU (A10)",
    "Address": "Tauernautobahn A-10",
    "Address__1": "",
    "City": "Feistritz an der Drau",
    "Postcode": 9710,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 42454402,
    "24 hour?": "TRUE",
    "Latitude": 46.683606,
    "Longitude": 13.671874,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.683606,13.671874",
    "IsOpen24Hours": true,
    "id": "46.683606,13.671874"
  },
  {
    "ID": 1482749900,
    "Tankstellennummer": 4047,
    "Name": "eni Neu Feffernitz",
    "Address": "Drautalstrasse 14",
    "Address__1": "",
    "City": "Neu Feffernitz",
    "Postcode": 9710,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4245/40001",
    "24 hour?": "FALSE",
    "Latitude": 46.68925,
    "Longitude": 13.68395,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.68925,13.68395",
    "IsOpen24Hours": false,
    "id": "46.68925,13.68395"
  },
  {
    "ID": 1399942248,
    "Tankstellennummer": "A2733",
    "Name": "Griffen",
    "Address": 33,
    "Address__1": "",
    "City": "Griffen",
    "Postcode": 9112,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 46.70256,
    "Longitude": 14.72846,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.70256,14.72846",
    "IsOpen24Hours": false,
    "id": "46.70256,14.72846"
  },
  {
    "ID": 1181908334,
    "Tankstellennummer": "A1115",
    "Name": "Spielfeld Oberst Perleß Straße",
    "Address": "Oberst Perleß Straße 9",
    "Address__1": "",
    "City": "Straß in der Steiermark",
    "Postcode": 8471,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": "+43-800-202055",
    "24 hour?": "FALSE",
    "Latitude": 46.70673,
    "Longitude": 15.63392,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.70673,15.63392",
    "IsOpen24Hours": false,
    "id": "46.70673,15.63392"
  },
  {
    "ID": 19680,
    "Tankstellennummer": 2568,
    "Name": "Feldkirchen 10. Oktober-Straße 22",
    "Address": "10. Oktober-Straße 23",
    "Address__1": "",
    "City": "Feldkirchen",
    "Postcode": 9560,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-4276-2230",
    "24 hour?": "FALSE",
    "Latitude": 46.72299,
    "Longitude": 14.09689,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.72299,14.09689",
    "IsOpen24Hours": false,
    "id": "46.72299,14.09689"
  },
  {
    "ID": 347,
    "Tankstellennummer": "0G99M",
    "Name": "Afritz, Millstaetterstr. 85",
    "Address": "Millstätterstraße 85",
    "Address__1": "KTN",
    "City": "Afritz am See",
    "Postcode": 9542,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 42472747,
    "24 hour?": "FALSE",
    "Latitude": 46.727661,
    "Longitude": 13.785455,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.727661,13.785455",
    "IsOpen24Hours": false,
    "id": "46.727661,13.785455"
  },
  {
    "ID": 691617091,
    "Tankstellennummer": 7110,
    "Name": "eni Strass",
    "Address": "Bundesstrasse 67",
    "Address__1": "",
    "City": "Strass",
    "Postcode": 8472,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3453/6160",
    "24 hour?": "FALSE",
    "Latitude": 46.7296,
    "Longitude": 15.6158,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.7296,15.6158",
    "IsOpen24Hours": false,
    "id": "46.7296,15.6158"
  },
  {
    "ID": 935780325,
    "Tankstellennummer": 4105,
    "Name": "eni Liebenfels",
    "Address": "Feldkirchnerstrasse 1",
    "Address__1": "",
    "City": "Liebenfels",
    "Postcode": 9556,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4215/2221",
    "24 hour?": "FALSE",
    "Latitude": 46.7375,
    "Longitude": 14.2858,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.7375,14.2858",
    "IsOpen24Hours": false,
    "id": "46.7375,14.2858"
  },
  {
    "ID": 483331886,
    "Tankstellennummer": 4031,
    "Name": "eni Dellach",
    "Address": "Dellach 7",
    "Address__1": "",
    "City": "Dellach im Drautal",
    "Postcode": 9772,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4714/20710",
    "24 hour?": "FALSE",
    "Latitude": 46.73843,
    "Longitude": 13.08396,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.73843,13.08396",
    "IsOpen24Hours": false,
    "id": "46.73843,13.08396"
  },
  {
    "ID": 19723,
    "Tankstellennummer": 2505,
    "Name": "Sillian Arnbach 75",
    "Address": "Arnbach 75b",
    "Address__1": "",
    "City": "Sillian",
    "Postcode": 9920,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04842 6343",
    "24 hour?": "FALSE",
    "Latitude": 46.74021,
    "Longitude": 12.37517,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.74021,12.37517",
    "IsOpen24Hours": false,
    "id": "46.74021,12.37517"
  },
  {
    "ID": 623,
    "Tankstellennummer": "0G985",
    "Name": "Sillian, Arnbach 74",
    "Address": "Arnbach 74",
    "Address__1": "T",
    "City": "Sillian",
    "Postcode": 9920,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 48426311,
    "24 hour?": "FALSE",
    "Latitude": 46.7404073,
    "Longitude": 12.3738622,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.7404073,12.3738622",
    "IsOpen24Hours": false,
    "id": "46.7404073,12.3738622"
  },
  {
    "ID": 19553,
    "Tankstellennummer": 2548,
    "Name": "Sankt Andrä Framrach 17",
    "Address": "Framrach 17",
    "Address__1": "",
    "City": "Sankt Andrä",
    "Postcode": 9433,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04358 2451",
    "24 hour?": "FALSE",
    "Latitude": 46.7449,
    "Longitude": 14.82285,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.7449,14.82285",
    "IsOpen24Hours": false,
    "id": "46.7449,14.82285"
  },
  {
    "ID": 383,
    "Tankstellennummer": "0G97W",
    "Name": "Brueckl, Klagenfurterstr. 10",
    "Address": "Klagenfurterstraße 10",
    "Address__1": "KTN",
    "City": "Brückl",
    "Postcode": 9371,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 42142508,
    "24 hour?": "FALSE",
    "Latitude": 46.7462676,
    "Longitude": 14.5309163,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.7462676,14.5309163",
    "IsOpen24Hours": false,
    "id": "46.7462676,14.5309163"
  },
  {
    "ID": 2116886950,
    "Tankstellennummer": 4034,
    "Name": "eni Oberdrauburg",
    "Address": "Tirolerstrasse 4",
    "Address__1": "",
    "City": "Oberdrauburg",
    "Postcode": 9781,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4710/29778",
    "24 hour?": "FALSE",
    "Latitude": 46.74921,
    "Longitude": 12.9638,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.74921,12.9638",
    "IsOpen24Hours": false,
    "id": "46.74921,12.9638"
  },
  {
    "ID": 465,
    "Tankstellennummer": "0G99O",
    "Name": "Himmelberg, Schulstr. 8",
    "Address": "Schulstraße 7",
    "Address__1": "KTN",
    "City": "Himmelberg",
    "Postcode": 9562,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 42762254,
    "24 hour?": "FALSE",
    "Latitude": 46.7514455,
    "Longitude": 14.0353928,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.7514455,14.0353928",
    "IsOpen24Hours": false,
    "id": "46.7514455,14.0353928"
  },
  {
    "ID": 600705073,
    "Tankstellennummer": 4134,
    "Name": "eni Gutmann Steinfeld",
    "Address": "Lindenweg 21",
    "Address__1": "",
    "City": "Steinfeld",
    "Postcode": 9754,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)664/816094",
    "24 hour?": "FALSE",
    "Latitude": 46.75275,
    "Longitude": 13.24998,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.75275,13.24998",
    "IsOpen24Hours": false,
    "id": "46.75275,13.24998"
  },
  {
    "ID": 1606373298,
    "Tankstellennummer": "A2580",
    "Name": "Sankt Veit an der Glan Klagenf",
    "Address": "Klagenfurter Straße 78",
    "Address__1": "",
    "City": "Sankt Veit an der Glan",
    "Postcode": 9300,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 46.76102,
    "Longitude": 14.36625,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.76102,14.36625",
    "IsOpen24Hours": false,
    "id": "46.76102,14.36625"
  },
  {
    "ID": 216915122,
    "Tankstellennummer": 7160,
    "Name": "eni Gleinstätten",
    "Address": "Maierhof 13",
    "Address__1": "",
    "City": "Gleinstätten",
    "Postcode": 8443,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3457/2247",
    "24 hour?": "FALSE",
    "Latitude": 46.76176,
    "Longitude": 15.40083,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.76176,15.40083",
    "IsOpen24Hours": false,
    "id": "46.76176,15.40083"
  },
  {
    "ID": 19714,
    "Tankstellennummer": 1537,
    "Name": "Wagna Landscha 50",
    "Address": 50,
    "Address__1": "",
    "City": "Landscha an der Mur",
    "Postcode": 8424,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03452 8204112",
    "24 hour?": "FALSE",
    "Latitude": 46.76379,
    "Longitude": 15.58034,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.76379,15.58034",
    "IsOpen24Hours": false,
    "id": "46.76379,15.58034"
  },
  {
    "ID": 19747,
    "Tankstellennummer": 2599,
    "Name": "Sankt Veit an der Glan Lastenstraße",
    "Address": "Lastenstraße 38",
    "Address__1": "",
    "City": "Sankt Veit an der Glan",
    "Postcode": 9300,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04212 30845",
    "24 hour?": "FALSE",
    "Latitude": 46.76591,
    "Longitude": 14.36587,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.76591,14.36587",
    "IsOpen24Hours": false,
    "id": "46.76591,14.36587"
  },
  {
    "ID": 656,
    "Tankstellennummer": "0G93Q",
    "Name": "St. Veit/Glan, Friesacherstr. 68",
    "Address": "Friesacher Straße 68",
    "Address__1": "KTN",
    "City": "Sankt Veit an der Glan",
    "Postcode": 9300,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 42122398,
    "24 hour?": "FALSE",
    "Latitude": 46.7724658,
    "Longitude": 14.3672964,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.7724658,14.3672964",
    "IsOpen24Hours": false,
    "id": "46.7724658,14.3672964"
  },
  {
    "ID": 1845146348,
    "Tankstellennummer": "0GH55",
    "Name": "Spittal an der Drau, Zgurner Str. 2",
    "Address": "Zgurner Strasse 2",
    "Address__1": "",
    "City": "Spittal an der Drau",
    "Postcode": 9800,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 476237470,
    "24 hour?": "FALSE",
    "Latitude": 46.782923,
    "Longitude": 13.534306,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.782923,13.534306",
    "IsOpen24Hours": false,
    "id": "46.782923,13.534306"
  },
  {
    "ID": 19552,
    "Tankstellennummer": 2522,
    "Name": "Spittal Villacher Straße 50",
    "Address": "Villacher Straße 50",
    "Address__1": "",
    "City": "Spittal an der Drau",
    "Postcode": 9800,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04762 2740",
    "24 hour?": "TRUE",
    "Latitude": 46.79163,
    "Longitude": 13.50961,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.79163,13.50961",
    "IsOpen24Hours": true,
    "id": "46.79163,13.50961"
  },
  {
    "ID": 1577639790,
    "Tankstellennummer": 4101,
    "Name": "eni24 Spittal a.d. Drau",
    "Address": "Villacherstr. 44",
    "Address__1": "",
    "City": "Spittal a.d. Drau",
    "Postcode": 9800,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4762/2717",
    "24 hour?": "FALSE",
    "Latitude": 46.7919,
    "Longitude": 13.5083,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.7919,13.5083",
    "IsOpen24Hours": false,
    "id": "46.7919,13.5083"
  },
  {
    "ID": 157301383,
    "Tankstellennummer": 4036,
    "Name": "eni Radenthein",
    "Address": "Millstätter Bundesstrasse 21",
    "Address__1": "",
    "City": "Radenthein",
    "Postcode": 9545,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4246/2747",
    "24 hour?": "TRUE",
    "Latitude": 46.79807,
    "Longitude": 13.71041,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.79807,13.71041",
    "IsOpen24Hours": true,
    "id": "46.79807,13.71041"
  },
  {
    "ID": 752076267,
    "Tankstellennummer": 4001,
    "Name": "eni Spittal/Drau",
    "Address": "Tiroler Strasse 9",
    "Address__1": "",
    "City": "Spittal/Drau",
    "Postcode": 9800,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4762/2986",
    "24 hour?": "FALSE",
    "Latitude": 46.80076,
    "Longitude": 13.49156,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.80076,13.49156",
    "IsOpen24Hours": false,
    "id": "46.80076,13.49156"
  },
  {
    "ID": 1876931075,
    "Tankstellennummer": "0GH59",
    "Name": "Gralla, Gewerbepark Sued 4",
    "Address": "Gewerbepark Süd 4",
    "Address__1": "",
    "City": "Gralla",
    "Postcode": 8431,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 345271416,
    "24 hour?": "FALSE",
    "Latitude": 46.804099,
    "Longitude": 15.557495,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.804099,15.557495",
    "IsOpen24Hours": false,
    "id": "46.804099,15.557495"
  },
  {
    "ID": 1120297398,
    "Tankstellennummer": 4114,
    "Name": "eni Bad Kleinkirchheim",
    "Address": "Dorfstraße 97",
    "Address__1": "",
    "City": "Bad Kleinkirchheim",
    "Postcode": 9546,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4240/3012",
    "24 hour?": "FALSE",
    "Latitude": 46.8145,
    "Longitude": 13.8009,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.8145,13.8009",
    "IsOpen24Hours": false,
    "id": "46.8145,13.8009"
  },
  {
    "ID": 19591,
    "Tankstellennummer": 1591,
    "Name": "Deutschlandsberg Grazer Straße 88",
    "Address": "Grazer Straße 88",
    "Address__1": "",
    "City": "Deutschlandsberg",
    "Postcode": 8530,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03462 2753",
    "24 hour?": "TRUE",
    "Latitude": 46.81593,
    "Longitude": 15.2346,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.81593,15.2346",
    "IsOpen24Hours": true,
    "id": "46.81593,15.2346"
  },
  {
    "ID": 745377994,
    "Tankstellennummer": "0GACX",
    "Name": "SEEBODEN,HAUPTSTRASSE 30",
    "Address": "Hauptstraße 30",
    "Address__1": "",
    "City": "Seeboden",
    "Postcode": 9871,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083007500,
    "24 hour?": "TRUE",
    "Latitude": 46.8166,
    "Longitude": 13.5101,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.8166,13.5101",
    "IsOpen24Hours": true,
    "id": "46.8166,13.5101"
  },
  {
    "ID": 19688,
    "Tankstellennummer": 2578,
    "Name": "Seeboden Hauptstraße 39",
    "Address": "Hauptstraße 39",
    "Address__1": "",
    "City": "Seeboden",
    "Postcode": 9871,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04762 81324",
    "24 hour?": "FALSE",
    "Latitude": 46.81713,
    "Longitude": 13.51108,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.81713,13.51108",
    "IsOpen24Hours": false,
    "id": "46.81713,13.51108"
  },
  {
    "ID": 1072938081,
    "Tankstellennummer": 7039,
    "Name": "eni Gralla",
    "Address": "Gewerbepark Nord Nr. 11",
    "Address__1": "",
    "City": "Gralla",
    "Postcode": 8430,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3452/76701",
    "24 hour?": "TRUE",
    "Latitude": 46.81861,
    "Longitude": 15.55269,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.81861,15.55269",
    "IsOpen24Hours": true,
    "id": "46.81861,15.55269"
  },
  {
    "ID": 1321214288,
    "Tankstellennummer": 4013,
    "Name": "eni Wolfsberg",
    "Address": "Klagenfurterstrasse 40",
    "Address__1": "",
    "City": "Wolfsberg",
    "Postcode": 9400,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4352/90946",
    "24 hour?": "FALSE",
    "Latitude": 46.82236,
    "Longitude": 14.83825,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.82236,14.83825",
    "IsOpen24Hours": false,
    "id": "46.82236,14.83825"
  },
  {
    "ID": 2111828043,
    "Tankstellennummer": 4135,
    "Name": "eni Gutmann Lienz",
    "Address": "Tirolerstrasse 17d",
    "Address__1": "",
    "City": "Lienz",
    "Postcode": 9900,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4852/63428",
    "24 hour?": "TRUE",
    "Latitude": 46.8277,
    "Longitude": 12.7664,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.8277,12.7664",
    "IsOpen24Hours": true,
    "id": "46.8277,12.7664"
  },
  {
    "ID": 19685,
    "Tankstellennummer": 2589,
    "Name": "Wolfsberg Klagenfurter Straße 16",
    "Address": "Klagenfurter Straße 16",
    "Address__1": "",
    "City": "Wolfsberg",
    "Postcode": 9400,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04352 2529",
    "24 hour?": "TRUE",
    "Latitude": 46.82775,
    "Longitude": 14.83911,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.82775,14.83911",
    "IsOpen24Hours": true,
    "id": "46.82775,14.83911"
  },
  {
    "ID": 9906,
    "Tankstellennummer": "0G969",
    "Name": "Lienz, Iseltalerstr. 25",
    "Address": "Iseltalerstraße 25",
    "Address__1": "T",
    "City": "Lienz",
    "Postcode": 9900,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 485262659,
    "24 hour?": "FALSE",
    "Latitude": 46.8298,
    "Longitude": 12.7547,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.8298,12.7547",
    "IsOpen24Hours": false,
    "id": "46.8298,12.7547"
  },
  {
    "ID": 19690,
    "Tankstellennummer": 2574,
    "Name": "Lienz Tiroler Straße",
    "Address": "Tiroler Straße 10",
    "Address__1": "",
    "City": "Lienz",
    "Postcode": 9900,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04852 63060",
    "24 hour?": "FALSE",
    "Latitude": 46.83095,
    "Longitude": 12.77553,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.83095,12.77553",
    "IsOpen24Hours": false,
    "id": "46.83095,12.77553"
  },
  {
    "ID": 2013217378,
    "Tankstellennummer": 4035,
    "Name": "eni Lienz",
    "Address": "Kärntner Strasse 84",
    "Address__1": "",
    "City": "Lienz",
    "Postcode": 9900,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4852/63020",
    "24 hour?": "TRUE",
    "Latitude": 46.83116,
    "Longitude": 12.78376,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.83116,12.78376",
    "IsOpen24Hours": true,
    "id": "46.83116,12.78376"
  },
  {
    "ID": 9869,
    "Tankstellennummer": "0G94C",
    "Name": "Moellbruecke, Moelltalstr. 33",
    "Address": "Mölltalstraße 33",
    "Address__1": "KTN",
    "City": "Möllbrücke",
    "Postcode": 9813,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 47692458,
    "24 hour?": "FALSE",
    "Latitude": 46.8367,
    "Longitude": 13.3737,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.8367,13.3737",
    "IsOpen24Hours": false,
    "id": "46.8367,13.3737"
  },
  {
    "ID": 19557,
    "Tankstellennummer": 2731,
    "Name": "Wolfsberg Grazer Straße 7",
    "Address": "Grazer Straße 7",
    "Address__1": "",
    "City": "Wolfsberg",
    "Postcode": 9400,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04352 3195",
    "24 hour?": "FALSE",
    "Latitude": 46.84742,
    "Longitude": 14.84422,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.84742,14.84422",
    "IsOpen24Hours": false,
    "id": "46.84742,14.84422"
  },
  {
    "ID": 562,
    "Tankstellennummer": "0G94T",
    "Name": "Nauders/Reschenpass, Bundesstr. 279",
    "Address": "Bundesstraße 279",
    "Address__1": "T",
    "City": "Nauders/Reschenpass",
    "Postcode": 6543,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 547387268,
    "24 hour?": "FALSE",
    "Latitude": 46.8512474,
    "Longitude": 10.5042976,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.8512474,10.5042976",
    "IsOpen24Hours": false,
    "id": "46.8512474,10.5042976"
  },
  {
    "ID": 678,
    "Tankstellennummer": "0G98W",
    "Name": "Ebene-Reichenau, Nr. 12 Turracher Bundesstr.",
    "Address": "Turracher Bundesstraße 12",
    "Address__1": "KTN",
    "City": "Ebene-Reichenau",
    "Postcode": 9565,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 46.857321,
    "Longitude": 13.8921,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.857321,13.8921",
    "IsOpen24Hours": false,
    "id": "46.857321,13.8921"
  },
  {
    "ID": 791707198,
    "Tankstellennummer": 7162,
    "Name": "eni Preding",
    "Address": "Gewerbepark Süd 1",
    "Address__1": "",
    "City": "Preding",
    "Postcode": 8504,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3185/2311",
    "24 hour?": "FALSE",
    "Latitude": 46.85877,
    "Longitude": 15.40688,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.85877,15.40688",
    "IsOpen24Hours": false,
    "id": "46.85877,15.40688"
  },
  {
    "ID": 841592205,
    "Tankstellennummer": 4002,
    "Name": "eni Winklern",
    "Address": "Reintal 21",
    "Address__1": "",
    "City": "Winklern",
    "Postcode": 9841,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4822/247",
    "24 hour?": "FALSE",
    "Latitude": 46.86212,
    "Longitude": 12.89067,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.86212,12.89067",
    "IsOpen24Hours": false,
    "id": "46.86212,12.89067"
  },
  {
    "ID": 19684,
    "Tankstellennummer": 2586,
    "Name": "Treibach 10. Oktober-Straße 11",
    "Address": "10. Oktober-Straße 11",
    "Address__1": "",
    "City": "Althofen",
    "Postcode": 9330,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04262 27207-30",
    "24 hour?": "FALSE",
    "Latitude": 46.87159,
    "Longitude": 14.46729,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.87159,14.46729",
    "IsOpen24Hours": false,
    "id": "46.87159,14.46729"
  },
  {
    "ID": 540925925,
    "Tankstellennummer": 7113,
    "Name": "eni Wildon",
    "Address": "Grazer Strasse 21",
    "Address__1": "",
    "City": "Wildon",
    "Postcode": 8410,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3182/2351",
    "24 hour?": "FALSE",
    "Latitude": 46.89038,
    "Longitude": 15.498,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.89038,15.498",
    "IsOpen24Hours": false,
    "id": "46.89038,15.498"
  },
  {
    "ID": 9859,
    "Tankstellennummer": "0G948",
    "Name": "Nauders, Bundesstr. 292",
    "Address": "Bundesstraße 292",
    "Address__1": "T",
    "City": "Nauders",
    "Postcode": 6543,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 547387336,
    "24 hour?": "FALSE",
    "Latitude": 46.894343,
    "Longitude": 10.498367,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.894343,10.498367",
    "IsOpen24Hours": false,
    "id": "46.894343,10.498367"
  },
  {
    "ID": 512583004,
    "Tankstellennummer": 7137,
    "Name": "eni St. Stefan im Rosental",
    "Address": "Mureckerstrasse 31",
    "Address__1": "",
    "City": "St.Stefan im Rosental",
    "Postcode": 8083,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)311/681254",
    "24 hour?": "FALSE",
    "Latitude": 46.901016,
    "Longitude": 15.71373,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.901016,15.71373",
    "IsOpen24Hours": false,
    "id": "46.901016,15.71373"
  },
  {
    "ID": 19428,
    "Tankstellennummer": 2734,
    "Name": "Eisentratten Tauernautobahn bei KM 125",
    "Address": "Laggen 18",
    "Address__1": "",
    "City": "Eisentratten",
    "Postcode": 9861,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04732 2884",
    "24 hour?": "TRUE",
    "Latitude": 46.91988,
    "Longitude": 13.57964,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.91988,13.57964",
    "IsOpen24Hours": true,
    "id": "46.91988,13.57964"
  },
  {
    "ID": 1401107927,
    "Tankstellennummer": 4102,
    "Name": "eni Mörtschach",
    "Address": "Mörtschach 72",
    "Address__1": "",
    "City": "Mörtschach",
    "Postcode": 9842,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4826/365",
    "24 hour?": "FALSE",
    "Latitude": 46.92286,
    "Longitude": 12.91034,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.92286,12.91034",
    "IsOpen24Hours": false,
    "id": "46.92286,12.91034"
  },
  {
    "ID": 897575300,
    "Tankstellennummer": "0GH62",
    "Name": "Werndorf, Gewerbepark 1",
    "Address": "Gewerbepark 1",
    "Address__1": "",
    "City": "Werndorf",
    "Postcode": 8402,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 313557199,
    "24 hour?": "TRUE",
    "Latitude": 46.933411,
    "Longitude": 15.467321,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.933411,15.467321",
    "IsOpen24Hours": true,
    "id": "46.933411,15.467321"
  },
  {
    "ID": 1572838532,
    "Tankstellennummer": 4119,
    "Name": "eni Matrei in Osttirol",
    "Address": "Huben 40",
    "Address__1": "",
    "City": "Matrei in Osttirol",
    "Postcode": 9971,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4872/5253",
    "24 hour?": "FALSE",
    "Latitude": 46.9346,
    "Longitude": 12.575,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.9346,12.575",
    "IsOpen24Hours": false,
    "id": "46.9346,12.575"
  },
  {
    "ID": 487509877,
    "Tankstellennummer": 9016,
    "Name": "eni Jennersdorf",
    "Address": "Eisenstädterstr. 7",
    "Address__1": "",
    "City": "Jennersdorf",
    "Postcode": 8380,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3329/45731",
    "24 hour?": "FALSE",
    "Latitude": 46.93705,
    "Longitude": 16.14296,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.93705,16.14296",
    "IsOpen24Hours": false,
    "id": "46.93705,16.14296"
  },
  {
    "ID": 19441,
    "Tankstellennummer": 4044,
    "Name": "Pack",
    "Address": "A2 Südautobahn/Pack Km 235",
    "Address__1": "",
    "City": "Preitenegg",
    "Postcode": 9451,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)4354/2200",
    "24 hour?": "TRUE",
    "Latitude": 46.93881,
    "Longitude": 14.92458,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.93881,14.92458",
    "IsOpen24Hours": true,
    "id": "46.93881,14.92458"
  },
  {
    "ID": 428916883,
    "Tankstellennummer": "0GH56",
    "Name": "Lannach, Radlpassstr. 26",
    "Address": "Radlpaßstraße 26",
    "Address__1": "",
    "City": "Lannach",
    "Postcode": 8502,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 313683140,
    "24 hour?": "FALSE",
    "Latitude": 46.948625,
    "Longitude": 15.337613,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.948625,15.337613",
    "IsOpen24Hours": false,
    "id": "46.948625,15.337613"
  },
  {
    "ID": 1671169771,
    "Tankstellennummer": 7136,
    "Name": "eni Feldbach",
    "Address": "Leitersdorf 177",
    "Address__1": "",
    "City": "Feldbach",
    "Postcode": 8330,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)315/239002",
    "24 hour?": "FALSE",
    "Latitude": 46.94936,
    "Longitude": 15.94368,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.94936,15.94368",
    "IsOpen24Hours": false,
    "id": "46.94936,15.94368"
  },
  {
    "ID": 1497227096,
    "Tankstellennummer": "0GH61",
    "Name": "Paldau, Saaz 92",
    "Address": "Saaz 92",
    "Address__1": "",
    "City": "Paldau",
    "Postcode": 8341,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 315267735,
    "24 hour?": "FALSE",
    "Latitude": 46.953953,
    "Longitude": 15.849611,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.953953,15.849611",
    "IsOpen24Hours": false,
    "id": "46.953953,15.849611"
  },
  {
    "ID": 1070832433,
    "Tankstellennummer": 7105,
    "Name": "eni Heiligenkreuz",
    "Address": "Marktplatz 24",
    "Address__1": "",
    "City": "Heiligenkreuz",
    "Postcode": 8081,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3134/2201",
    "24 hour?": "FALSE",
    "Latitude": 46.9566,
    "Longitude": 15.5849,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.9566,15.5849",
    "IsOpen24Hours": false,
    "id": "46.9566,15.5849"
  },
  {
    "ID": 19435,
    "Tankstellennummer": 1617,
    "Name": "Dobl (Kaiserwald)",
    "Address": "Autobahn A2 (Südautobahn) KM 190",
    "Address__1": "",
    "City": "Dobl (Kaiserwald)",
    "Postcode": 8143,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03136 52985",
    "24 hour?": "TRUE",
    "Latitude": 46.95939,
    "Longitude": 15.38155,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.95939,15.38155",
    "IsOpen24Hours": true,
    "id": "46.95939,15.38155"
  },
  {
    "ID": 9866,
    "Tankstellennummer": "0G998",
    "Name": "Friesach, Neumarkter Str. 32",
    "Address": "Neumarkter Straße 32",
    "Address__1": "KTN",
    "City": "Friesach",
    "Postcode": 9360,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 42682504,
    "24 hour?": "FALSE",
    "Latitude": 46.9600032,
    "Longitude": 14.4010809,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.9600032,14.4010809",
    "IsOpen24Hours": false,
    "id": "46.9600032,14.4010809"
  },
  {
    "ID": 1601977502,
    "Tankstellennummer": "A1171",
    "Name": "Lieboch Lutzstraße 1",
    "Address": "Lutzstraße 1",
    "Address__1": "",
    "City": "Lieboch",
    "Postcode": 8501,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 46.96186,
    "Longitude": 15.3529,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.96186,15.3529",
    "IsOpen24Hours": false,
    "id": "46.96186,15.3529"
  },
  {
    "ID": 19717,
    "Tankstellennummer": 2514,
    "Name": "Bad St. Leonhard im Lavanttal Klagenfurter Straße 230",
    "Address": "Klagenfurter Straße 356",
    "Address__1": "",
    "City": "Bad St. Leonhard im Lavanttal",
    "Postcode": 9462,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "04350 2361",
    "24 hour?": "FALSE",
    "Latitude": 46.96214,
    "Longitude": 14.79496,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.96214,14.79496",
    "IsOpen24Hours": false,
    "id": "46.96214,14.79496"
  },
  {
    "ID": 9867,
    "Tankstellennummer": "0G95U",
    "Name": "Soelden, Dorfstr. 148",
    "Address": "Dorfstraße 148",
    "Address__1": "T",
    "City": "Sölden",
    "Postcode": 6450,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 52542466,
    "24 hour?": "FALSE",
    "Latitude": 46.9631,
    "Longitude": 11.0077,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.9631,11.0077",
    "IsOpen24Hours": false,
    "id": "46.9631,11.0077"
  },
  {
    "ID": 861977885,
    "Tankstellennummer": "0GAGH",
    "Name": "ST-LIEBOCH, HANS THALH. STR. 1",
    "Address": "Hans Thalhammer Straße 1",
    "Address__1": "",
    "City": "Lieboch",
    "Postcode": 8501,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 313661133,
    "24 hour?": "TRUE",
    "Latitude": 46.9631909,
    "Longitude": 15.3508663,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.9631909,15.3508663",
    "IsOpen24Hours": true,
    "id": "46.9631909,15.3508663"
  },
  {
    "ID": 19757,
    "Tankstellennummer": 1787,
    "Name": "Kalsdorf bei Graz Bierbaumerstraße 3",
    "Address": "Bierbaumerstraße 3",
    "Address__1": "",
    "City": "Kalsdorf bei Graz",
    "Postcode": 8401,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03135 53343",
    "24 hour?": "TRUE",
    "Latitude": 46.97221,
    "Longitude": 15.47013,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.97221,15.47013",
    "IsOpen24Hours": true,
    "id": "46.97221,15.47013"
  },
  {
    "ID": 19728,
    "Tankstellennummer": 1767,
    "Name": "Lieboch-Waldhof Packer Straße 6",
    "Address": "Packer Straße 6",
    "Address__1": "",
    "City": "Lieboch",
    "Postcode": 8501,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03136 61808",
    "24 hour?": "FALSE",
    "Latitude": 46.9723,
    "Longitude": 15.36164,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.9723,15.36164",
    "IsOpen24Hours": false,
    "id": "46.9723,15.36164"
  },
  {
    "ID": 2102354338,
    "Tankstellennummer": "A2651",
    "Name": "Friesach Judendorf 9",
    "Address": "Judendorf 9",
    "Address__1": "",
    "City": "Friesach",
    "Postcode": 9360,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 46.97355,
    "Longitude": 14.39351,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.97355,14.39351",
    "IsOpen24Hours": false,
    "id": "46.97355,14.39351"
  },
  {
    "ID": 19771,
    "Tankstellennummer": 7055,
    "Name": "Unterpremstätten",
    "Address": "Thalerhofstrasse 70",
    "Address__1": "",
    "City": "Unterpremstätten",
    "Postcode": 8141,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3136/55474",
    "24 hour?": "TRUE",
    "Latitude": 46.97377,
    "Longitude": 15.42602,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.97377,15.42602",
    "IsOpen24Hours": true,
    "id": "46.97377,15.42602"
  },
  {
    "ID": 521,
    "Tankstellennummer": "0G99K",
    "Name": "Lieboch, Packerstr. 144",
    "Address": "Packerstraße",
    "Address__1": "STMK",
    "City": "Lieboch",
    "Postcode": 8501,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 313661187,
    "24 hour?": "FALSE",
    "Latitude": 46.9765,
    "Longitude": 15.3287,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.9765,15.3287",
    "IsOpen24Hours": false,
    "id": "46.9765,15.3287"
  },
  {
    "ID": 463,
    "Tankstellennummer": "0G97P",
    "Name": "Heiligenkreuz am Waasen, Liebensdorf 36",
    "Address": "Liebensdorf 36",
    "Address__1": "STMK",
    "City": "Heiligenkreuz am Waasen",
    "Postcode": 8081,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 31342313,
    "24 hour?": "FALSE",
    "Latitude": 46.9822,
    "Longitude": 15.5648,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "46.9822,15.5648",
    "IsOpen24Hours": false,
    "id": "46.9822,15.5648"
  },
  {
    "ID": 268836641,
    "Tankstellennummer": 7067,
    "Name": "eni Ligist",
    "Address": "Steinberg 209",
    "Address__1": "",
    "City": "Ligist",
    "Postcode": 8563,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3143/2180",
    "24 hour?": "TRUE",
    "Latitude": 46.99315,
    "Longitude": 15.2125,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "46.99315,15.2125",
    "IsOpen24Hours": true,
    "id": "46.99315,15.2125"
  },
  {
    "ID": 17881,
    "Tankstellennummer": "0G97F",
    "Name": "Virgen,Virgentalstr. 39",
    "Address": "Virgentalstraße 39",
    "Address__1": "KTN",
    "City": "Virgen",
    "Postcode": 9972,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 48745267,
    "24 hour?": "FALSE",
    "Latitude": 47.0017508,
    "Longitude": 12.4644121,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.0017508,12.4644121",
    "IsOpen24Hours": false,
    "id": "47.0017508,12.4644121"
  },
  {
    "ID": 852656770,
    "Tankstellennummer": 4122,
    "Name": "eni Gutmann Matrei in Osttirol",
    "Address": "Felbertauernstr.",
    "Address__1": "",
    "City": "Matrei in Osttirol",
    "Postcode": 9971,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5022/77997",
    "24 hour?": "FALSE",
    "Latitude": 47.00519,
    "Longitude": 12.54105,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.00519,12.54105",
    "IsOpen24Hours": false,
    "id": "47.00519,12.54105"
  },
  {
    "ID": 524163543,
    "Tankstellennummer": 5005,
    "Name": "Gries am Brenner Autobahn A13",
    "Address": "Autobahn A13 (Brenner Autobahn) 256",
    "Address__1": "",
    "City": "Gries am Brenner",
    "Postcode": 6156,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05274 87365",
    "24 hour?": "TRUE",
    "Latitude": 47.0076,
    "Longitude": 11.50913,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.0076,11.50913",
    "IsOpen24Hours": true,
    "id": "47.0076,11.50913"
  },
  {
    "ID": 19425,
    "Tankstellennummer": 5504,
    "Name": "Gries am Brenner Brenner Autobahn",
    "Address": "Autobahn A13 (Brenner Autobahn)",
    "Address__1": "",
    "City": "Gries am Brenner",
    "Postcode": 6156,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05274 87281",
    "24 hour?": "TRUE",
    "Latitude": 47.01424,
    "Longitude": 11.50557,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.01424,11.50557",
    "IsOpen24Hours": true,
    "id": "47.01424,11.50557"
  },
  {
    "ID": 949948623,
    "Tankstellennummer": 1145,
    "Name": "eni Gutmann Gries",
    "Address": "Brennerstr. 119",
    "Address__1": "",
    "City": "Gries am Brenner",
    "Postcode": 6156,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5274/86210",
    "24 hour?": "FALSE",
    "Latitude": 47.01436,
    "Longitude": 11.50802,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.01436,11.50802",
    "IsOpen24Hours": false,
    "id": "47.01436,11.50802"
  },
  {
    "ID": 9888,
    "Tankstellennummer": "0G964",
    "Name": "Ischgl, Nr. 315",
    "Address": "Silvrettastraße 17",
    "Address__1": "T",
    "City": "Ischgl",
    "Postcode": 6561,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 54445787,
    "24 hour?": "FALSE",
    "Latitude": 47.0157,
    "Longitude": 10.2969,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.0157,10.2969",
    "IsOpen24Hours": false,
    "id": "47.0157,10.2969"
  },
  {
    "ID": 19424,
    "Tankstellennummer": 5549,
    "Name": "Gries am Brenner Brennersee 231",
    "Address": "Brennersee 231",
    "Address__1": "",
    "City": "Gries am Brenner",
    "Postcode": 6156,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05274 87223",
    "24 hour?": "TRUE",
    "Latitude": 47.01769,
    "Longitude": 11.50733,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.01769,11.50733",
    "IsOpen24Hours": true,
    "id": "47.01769,11.50733"
  },
  {
    "ID": 1607221443,
    "Tankstellennummer": "0GH67",
    "Name": "Graz, Triester Str. 459",
    "Address": "Triester Straße 459",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8055,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 316293324,
    "24 hour?": "TRUE",
    "Latitude": 47.020192,
    "Longitude": 15.438608,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.020192,15.438608",
    "IsOpen24Hours": true,
    "id": "47.020192,15.438608"
  },
  {
    "ID": 3021,
    "Tankstellennummer": "0G92S",
    "Name": "St. Gallenkirch, Aussergant",
    "Address": "Außergant",
    "Address__1": "VBG",
    "City": "St. Gallenkirch",
    "Postcode": 6791,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083004500,
    "24 hour?": "FALSE",
    "Latitude": 47.0202617,
    "Longitude": 9.9771738,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.0202617,9.9771738",
    "IsOpen24Hours": false,
    "id": "47.0202617,9.9771738"
  },
  {
    "ID": 232705374,
    "Tankstellennummer": 1041,
    "Name": "eni Längenfeld",
    "Address": "Bruggen 352",
    "Address__1": "",
    "City": "Längenfeld",
    "Postcode": 6444,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5253/64881",
    "24 hour?": "FALSE",
    "Latitude": 47.02488,
    "Longitude": 10.99511,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.02488,10.99511",
    "IsOpen24Hours": false,
    "id": "47.02488,10.99511"
  },
  {
    "ID": 12874682,
    "Tankstellennummer": "A1102",
    "Name": "Graz Weblinger Straße 41",
    "Address": "Weblinger Straße 41",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8054,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.02883,
    "Longitude": 15.40058,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.02883,15.40058",
    "IsOpen24Hours": false,
    "id": "47.02883,15.40058"
  },
  {
    "ID": 19631,
    "Tankstellennummer": 1654,
    "Name": "Graz Straßganger Straße 436",
    "Address": "Straßganger Straße 436",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8054,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0316 283868",
    "24 hour?": "TRUE",
    "Latitude": 47.03326,
    "Longitude": 15.39896,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.03326,15.39896",
    "IsOpen24Hours": true,
    "id": "47.03326,15.39896"
  },
  {
    "ID": 9898,
    "Tankstellennummer": "0G94G",
    "Name": "Graz, Puntigamer Str. 130",
    "Address": "Puntigamer Straße 130",
    "Address__1": "STMK",
    "City": "Graz",
    "Postcode": 8055,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 316292492,
    "24 hour?": "TRUE",
    "Latitude": 47.0335,
    "Longitude": 15.4427,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.0335,15.4427",
    "IsOpen24Hours": true,
    "id": "47.0335,15.4427"
  },
  {
    "ID": 1940177258,
    "Tankstellennummer": "A1604",
    "Name": "Graz Liebenauer Hauptstraße 12",
    "Address": "Liebenauer Hauptstraße 123",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8041,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.03679,
    "Longitude": 15.46252,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.03679,15.46252",
    "IsOpen24Hours": false,
    "id": "47.03679,15.46252"
  },
  {
    "ID": 232628006,
    "Tankstellennummer": "0G951",
    "Name": "GRIES/BRENNER,WESTUFER",
    "Address": "Westufer",
    "Address__1": "",
    "City": "Gries am Brenner",
    "Postcode": 6156,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 527487288,
    "24 hour?": "TRUE",
    "Latitude": 47.0378548,
    "Longitude": 11.4732324,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.0378548,11.4732324",
    "IsOpen24Hours": true,
    "id": "47.0378548,11.4732324"
  },
  {
    "ID": 441,
    "Tankstellennummer": "0G954",
    "Name": "Gries am Brenner, Ost",
    "Address": "Ostufer",
    "Address__1": "T",
    "City": "Gries am Brenner",
    "Postcode": 6156,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 527487310,
    "24 hour?": "TRUE",
    "Latitude": 47.0392729,
    "Longitude": 11.474931,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.0392729,11.474931",
    "IsOpen24Hours": true,
    "id": "47.0392729,11.474931"
  },
  {
    "ID": 750838492,
    "Tankstellennummer": 1005,
    "Name": "Graz Ostbahnstraße 10",
    "Address": "Ostbahnstraße 10",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8041,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0316 4249800",
    "24 hour?": "FALSE",
    "Latitude": 47.04195,
    "Longitude": 15.4642,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.04195,15.4642",
    "IsOpen24Hours": false,
    "id": "47.04195,15.4642"
  },
  {
    "ID": 19753,
    "Tankstellennummer": 1783,
    "Name": "Graz St. Peter Hauptstraße 228",
    "Address": "St. Peter Hauptstraße 228",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8042,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0316   403829",
    "24 hour?": "TRUE",
    "Latitude": 47.04384,
    "Longitude": 15.48696,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.04384,15.48696",
    "IsOpen24Hours": true,
    "id": "47.04384,15.48696"
  },
  {
    "ID": 1602245339,
    "Tankstellennummer": "A1103",
    "Name": "Sankt Margarethen an der Raab",
    "Address": 190,
    "Address__1": "",
    "City": "Sankt Margarethen an der Raab",
    "Postcode": 8321,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.04704,
    "Longitude": 15.74609,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.04704,15.74609",
    "IsOpen24Hours": false,
    "id": "47.04704,15.74609"
  },
  {
    "ID": 3085752,
    "Tankstellennummer": 7111,
    "Name": "eni Voitsberg",
    "Address": "Grazer Vorstadt 11",
    "Address__1": "",
    "City": "Voitsberg",
    "Postcode": 8570,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3142/22287",
    "24 hour?": "FALSE",
    "Latitude": 47.0477,
    "Longitude": 15.1542,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.0477,15.1542",
    "IsOpen24Hours": false,
    "id": "47.0477,15.1542"
  },
  {
    "ID": 748506335,
    "Tankstellennummer": "A1509",
    "Name": "Fürstenfeld Körmender Straße 3",
    "Address": "Körmender Straße 30",
    "Address__1": "",
    "City": "Fürstenfeld",
    "Postcode": 8280,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.04909,
    "Longitude": 16.08948,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.04909,16.08948",
    "IsOpen24Hours": false,
    "id": "47.04909,16.08948"
  },
  {
    "ID": 163772807,
    "Tankstellennummer": 7034,
    "Name": "eni Fürstenfeld/Altenm.",
    "Address": "Fürstenf.Schnellstrasse 191",
    "Address__1": "",
    "City": "Fürstenfeld/Altenm.",
    "Postcode": 8280,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3382/53601",
    "24 hour?": "TRUE",
    "Latitude": 47.049796,
    "Longitude": 16.074044,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.049796,16.074044",
    "IsOpen24Hours": true,
    "id": "47.049796,16.074044"
  },
  {
    "ID": 1391267476,
    "Tankstellennummer": 7010,
    "Name": "eni Pachern",
    "Address": "Pachern Hauptstrasse 47",
    "Address__1": "",
    "City": "Pachern",
    "Postcode": 8075,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)316/491915",
    "24 hour?": "FALSE",
    "Latitude": 47.05305,
    "Longitude": 15.52888,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.05305,15.52888",
    "IsOpen24Hours": false,
    "id": "47.05305,15.52888"
  },
  {
    "ID": 687349097,
    "Tankstellennummer": 7104,
    "Name": "eni Graz - Wetzelsdorf",
    "Address": "Kärntnerstr. 86",
    "Address__1": "",
    "City": "Graz - Wetzelsdorf",
    "Postcode": 8053,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)316/272453",
    "24 hour?": "FALSE",
    "Latitude": 47.05484,
    "Longitude": 15.41667,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.05484,15.41667",
    "IsOpen24Hours": false,
    "id": "47.05484,15.41667"
  },
  {
    "ID": 433,
    "Tankstellennummer": "0G94Q",
    "Name": "Graz, Froehlichgasse 45",
    "Address": "Fröhlichgasse 45",
    "Address__1": "STMK",
    "City": "Graz",
    "Postcode": 8010,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 316472595,
    "24 hour?": "TRUE",
    "Latitude": 47.0559,
    "Longitude": 15.4483,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.0559,15.4483",
    "IsOpen24Hours": true,
    "id": "47.0559,15.4483"
  },
  {
    "ID": 538,
    "Tankstellennummer": "0G940",
    "Name": "Graz, Muenzgrabenstr. 121",
    "Address": "Münzgrabenstraße 121",
    "Address__1": "STMK",
    "City": "Graz",
    "Postcode": 8010,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 316465444,
    "24 hour?": "FALSE",
    "Latitude": 47.0568059,
    "Longitude": 15.4536062,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.0568059,15.4536062",
    "IsOpen24Hours": false,
    "id": "47.0568059,15.4536062"
  },
  {
    "ID": 1394376182,
    "Tankstellennummer": 7140,
    "Name": "eni Markt Hartmannsdorf",
    "Address": "Hauptstrasse 297",
    "Address__1": "",
    "City": "Markt Hartmannsdorf",
    "Postcode": 8311,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)311/45107",
    "24 hour?": "FALSE",
    "Latitude": 47.057356,
    "Longitude": 15.832504,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.057356,15.832504",
    "IsOpen24Hours": false,
    "id": "47.057356,15.832504"
  },
  {
    "ID": 19634,
    "Tankstellennummer": 5589,
    "Name": "Ried im Oberinntal",
    "Address": "Ried i. O. 299",
    "Address__1": "",
    "City": "Ried im Oberinntal",
    "Postcode": 6531,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05472 2338",
    "24 hour?": "FALSE",
    "Latitude": 47.05741,
    "Longitude": 10.65375,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.05741,10.65375",
    "IsOpen24Hours": false,
    "id": "47.05741,10.65375"
  },
  {
    "ID": 19635,
    "Tankstellennummer": 1650,
    "Name": "Graz Alte Poststraße 234",
    "Address": "Alte Poststraße 234",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-316-583900",
    "24 hour?": "FALSE",
    "Latitude": 47.05947,
    "Longitude": 15.41386,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.05947,15.41386",
    "IsOpen24Hours": false,
    "id": "47.05947,15.41386"
  },
  {
    "ID": 169653509,
    "Tankstellennummer": "0GH60",
    "Name": "Graz, Reininghausstr. 63",
    "Address": "Reininghausstraße 63",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8020,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 316583551,
    "24 hour?": "FALSE",
    "Latitude": 47.062958,
    "Longitude": 15.399311,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.062958,15.399311",
    "IsOpen24Hours": false,
    "id": "47.062958,15.399311"
  },
  {
    "ID": 1502636149,
    "Tankstellennummer": 9021,
    "Name": "eni Güssing",
    "Address": "Wiener Straße 2a",
    "Address__1": "",
    "City": "Güssing",
    "Postcode": 7540,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/664001",
    "24 hour?": "FALSE",
    "Latitude": 47.06475,
    "Longitude": 16.32512,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.06475,16.32512",
    "IsOpen24Hours": false,
    "id": "47.06475,16.32512"
  },
  {
    "ID": 19749,
    "Tankstellennummer": 1647,
    "Name": "Altenmarkt bei Fürstenfeld 218",
    "Address": "Altenmarkt 218",
    "Address__1": "",
    "City": "Fürstenfeld",
    "Postcode": 8280,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03382 51019",
    "24 hour?": "TRUE",
    "Latitude": 47.06559,
    "Longitude": 16.0368,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.06559,16.0368",
    "IsOpen24Hours": true,
    "id": "47.06559,16.0368"
  },
  {
    "ID": 19734,
    "Tankstellennummer": 1560,
    "Name": "Neumarkt Kärntner Straße 22",
    "Address": "Kärntner Straße 22",
    "Address__1": "",
    "City": "Neumarkt",
    "Postcode": 8820,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03584 2167",
    "24 hour?": "TRUE",
    "Latitude": 47.06752,
    "Longitude": 14.43023,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.06752,14.43023",
    "IsOpen24Hours": true,
    "id": "47.06752,14.43023"
  },
  {
    "ID": 657470405,
    "Tankstellennummer": 7043,
    "Name": "eni Graz",
    "Address": "Alte Poststrasse 87",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)316/586692",
    "24 hour?": "FALSE",
    "Latitude": 47.07687,
    "Longitude": 15.40696,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.07687,15.40696",
    "IsOpen24Hours": false,
    "id": "47.07687,15.40696"
  },
  {
    "ID": 19599,
    "Tankstellennummer": 1607,
    "Name": "Graz Seebacher Gasse",
    "Address": "Seebacher Gasse 8",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8010,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0316 325385",
    "24 hour?": "FALSE",
    "Latitude": 47.07722,
    "Longitude": 15.45904,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.07722,15.45904",
    "IsOpen24Hours": false,
    "id": "47.07722,15.45904"
  },
  {
    "ID": 19541,
    "Tankstellennummer": 5584,
    "Name": "Längenfeld Unterlängenfeld 117b",
    "Address": "Unterlängenfeld 117b",
    "Address__1": "",
    "City": "Längenfeld",
    "Postcode": 6444,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05253 6385",
    "24 hour?": "FALSE",
    "Latitude": 47.07863,
    "Longitude": 10.96764,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.07863,10.96764",
    "IsOpen24Hours": false,
    "id": "47.07863,10.96764"
  },
  {
    "ID": 864933004,
    "Tankstellennummer": 3109,
    "Name": "eni Schruns",
    "Address": "Bahnhofstr. 21",
    "Address__1": "",
    "City": "Schruns",
    "Postcode": 6780,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5556/72470",
    "24 hour?": "FALSE",
    "Latitude": 47.0801,
    "Longitude": 9.91687,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.0801,9.91687",
    "IsOpen24Hours": false,
    "id": "47.0801,9.91687"
  },
  {
    "ID": 1277019718,
    "Tankstellennummer": "0GH64",
    "Name": "Gleisdorf, Hofstaetten 113",
    "Address": "Hofstetten 113",
    "Address__1": "",
    "City": "Gleisdorf",
    "Postcode": 8200,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 3112590031,
    "24 hour?": "FALSE",
    "Latitude": 47.081403,
    "Longitude": 15.739456,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.081403,15.739456",
    "IsOpen24Hours": false,
    "id": "47.081403,15.739456"
  },
  {
    "ID": 19596,
    "Tankstellennummer": 1723,
    "Name": "Graz Heinrichstraße 91",
    "Address": "Heinrichstraße 91",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8010,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0316 323151",
    "24 hour?": "FALSE",
    "Latitude": 47.08178,
    "Longitude": 15.45404,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.08178,15.45404",
    "IsOpen24Hours": false,
    "id": "47.08178,15.45404"
  },
  {
    "ID": 645978238,
    "Tankstellennummer": 7036,
    "Name": "eni Hofstätten an der Raab",
    "Address": "Pirching 97",
    "Address__1": "",
    "City": "Hofstätten an der Raab",
    "Postcode": 8200,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3112/57414",
    "24 hour?": "TRUE",
    "Latitude": 47.08969,
    "Longitude": 15.73272,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.08969,15.73272",
    "IsOpen24Hours": true,
    "id": "47.08969,15.73272"
  },
  {
    "ID": 601347258,
    "Tankstellennummer": 1129,
    "Name": "eni Gutmann Prutz",
    "Address": "Reschen Bundesstrae",
    "Address__1": "",
    "City": "Prutz",
    "Postcode": 6522,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5472/20395",
    "24 hour?": "FALSE",
    "Latitude": 47.09181,
    "Longitude": 10.66737,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.09181,10.66737",
    "IsOpen24Hours": false,
    "id": "47.09181,10.66737"
  },
  {
    "ID": 931951477,
    "Tankstellennummer": 7040,
    "Name": "eni Nestelbach Im Ilztal",
    "Address": "Nr. 68",
    "Address__1": "",
    "City": "Nestelbach Im Ilztal",
    "Postcode": 8262,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3385/421",
    "24 hour?": "FALSE",
    "Latitude": 47.09185487,
    "Longitude": 15.87899702,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.09185487,15.87899702",
    "IsOpen24Hours": false,
    "id": "47.09185487,15.87899702"
  },
  {
    "ID": 19521,
    "Tankstellennummer": 4547,
    "Name": "Sankt Michael im Lungau Bundesstraße 112",
    "Address": "Bundesstraße 112",
    "Address__1": "",
    "City": "Sankt Michael im Lungau",
    "Postcode": 5582,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "06477 8908",
    "24 hour?": "FALSE",
    "Latitude": 47.09406,
    "Longitude": 13.67553,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.09406,13.67553",
    "IsOpen24Hours": false,
    "id": "47.09406,13.67553"
  },
  {
    "ID": 9890,
    "Tankstellennummer": "0G94F",
    "Name": "Steinach am Brenner, Erlach 138",
    "Address": "Erlach 138",
    "Address__1": "T",
    "City": "Steinach am Brenner",
    "Postcode": 6150,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 52722260,
    "24 hour?": "FALSE",
    "Latitude": 47.0953,
    "Longitude": 11.4655,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.0953,11.4655",
    "IsOpen24Hours": false,
    "id": "47.0953,11.4655"
  },
  {
    "ID": 1666260023,
    "Tankstellennummer": "0G91H",
    "Name": "St. Michael, Murtalerstrasse 579",
    "Address": "Murtal Straße 579",
    "Address__1": "",
    "City": "Sankt Michael im Lungau",
    "Postcode": 5582,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083003300,
    "24 hour?": "FALSE",
    "Latitude": 47.0954278,
    "Longitude": 13.6385084,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.0954278,13.6385084",
    "IsOpen24Hours": false,
    "id": "47.0954278,13.6385084"
  },
  {
    "ID": 423116907,
    "Tankstellennummer": 7020,
    "Name": "eni Graz-Andritz",
    "Address": "Weinözttlstrasse 2",
    "Address__1": "",
    "City": "Graz-Andritz",
    "Postcode": 8045,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)316/683425",
    "24 hour?": "TRUE",
    "Latitude": 47.09836,
    "Longitude": 15.41819,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.09836,15.41819",
    "IsOpen24Hours": true,
    "id": "47.09836,15.41819"
  },
  {
    "ID": 846076599,
    "Tankstellennummer": 7014,
    "Name": "eni Graz",
    "Address": "Wiener Strasse 304",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8051,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)316/683664",
    "24 hour?": "TRUE",
    "Latitude": 47.09837,
    "Longitude": 15.40378,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.09837,15.40378",
    "IsOpen24Hours": true,
    "id": "47.09837,15.40378"
  },
  {
    "ID": 1823084883,
    "Tankstellennummer": 1161,
    "Name": "Eni Gutmann Steinach",
    "Address": "Brennerstraße 3",
    "Address__1": "",
    "City": "Steinach am Brenner",
    "Postcode": 6150,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5272/21136",
    "24 hour?": "FALSE",
    "Latitude": 47.09838,
    "Longitude": 11.46405,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.09838,11.46405",
    "IsOpen24Hours": false,
    "id": "47.09838,11.46405"
  },
  {
    "ID": 1320901960,
    "Tankstellennummer": "A6553",
    "Name": "Sankt Anton Außerböden 1",
    "Address": "Außerböden 1",
    "Address__1": "",
    "City": "Sankt Anton",
    "Postcode": 6780,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.09864,
    "Longitude": 9.86944,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.09864,9.86944",
    "IsOpen24Hours": false,
    "id": "47.09864,9.86944"
  },
  {
    "ID": 35863748,
    "Tankstellennummer": 7135,
    "Name": "eni Gleisdorf",
    "Address": "Franz Josef Strasse 20",
    "Address__1": "",
    "City": "Gleisdorf",
    "Postcode": 8200,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)311/23225",
    "24 hour?": "FALSE",
    "Latitude": 47.102145,
    "Longitude": 15.71351,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.102145,15.71351",
    "IsOpen24Hours": false,
    "id": "47.102145,15.71351"
  },
  {
    "ID": 531587369,
    "Tankstellennummer": "A1722",
    "Name": "Graz Andritzer Reichsstraße 30",
    "Address": "Andritzer Reichsstraße 30",
    "Address__1": "",
    "City": "Graz",
    "Postcode": 8045,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.10248,
    "Longitude": 15.42493,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.10248,15.42493",
    "IsOpen24Hours": false,
    "id": "47.10248,15.42493"
  },
  {
    "ID": 360,
    "Tankstellennummer": "0G94W",
    "Name": "Arnwiesen",
    "Address": "A-2 Südautobahn",
    "Address__1": "STMK",
    "City": "Arnwiesen-Gleisdorf",
    "Postcode": 8200,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 31126744,
    "24 hour?": "TRUE",
    "Latitude": 47.105595,
    "Longitude": 15.768947,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.105595,15.768947",
    "IsOpen24Hours": true,
    "id": "47.105595,15.768947"
  },
  {
    "ID": 19687,
    "Tankstellennummer": 1661,
    "Name": "Gleisdorf Grazer Straße 42",
    "Address": "Grazer Straße 42",
    "Address__1": "",
    "City": "Gleisdorf",
    "Postcode": 8200,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-3112-2104",
    "24 hour?": "TRUE",
    "Latitude": 47.1057,
    "Longitude": 15.70097,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.1057,15.70097",
    "IsOpen24Hours": true,
    "id": "47.1057,15.70097"
  },
  {
    "ID": 754800304,
    "Tankstellennummer": 7130,
    "Name": "eni Wilfersdorf",
    "Address": "Wilfersdorf 104",
    "Address__1": "",
    "City": "Gleisdorf",
    "Postcode": 8200,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3112/32700",
    "24 hour?": "FALSE",
    "Latitude": 47.10687,
    "Longitude": 15.66413,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.10687,15.66413",
    "IsOpen24Hours": false,
    "id": "47.10687,15.66413"
  },
  {
    "ID": 7069,
    "Tankstellennummer": "0G994",
    "Name": "Neustift, Stubaitalstr. 81",
    "Address": "Stubaitalstraße 81",
    "Address__1": "T",
    "City": "Neustift",
    "Postcode": 6167,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 52263525,
    "24 hour?": "FALSE",
    "Latitude": 47.1115,
    "Longitude": 11.3096,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.1115,11.3096",
    "IsOpen24Hours": false,
    "id": "47.1115,11.3096"
  },
  {
    "ID": 19731,
    "Tankstellennummer": 1562,
    "Name": "Murau Keltensiedlung 95",
    "Address": "Keltensiedlung 95",
    "Address__1": "",
    "City": "Murau",
    "Postcode": 8850,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03532 3687",
    "24 hour?": "FALSE",
    "Latitude": 47.11225,
    "Longitude": 14.1823,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.11225,14.1823",
    "IsOpen24Hours": false,
    "id": "47.11225,14.1823"
  },
  {
    "ID": 1287851690,
    "Tankstellennummer": 7109,
    "Name": "eni Murau",
    "Address": "Märzenkeller 3",
    "Address__1": "",
    "City": "Murau",
    "Postcode": 8850,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3532/2193",
    "24 hour?": "FALSE",
    "Latitude": 47.11325,
    "Longitude": 14.16195,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.11325,14.16195",
    "IsOpen24Hours": false,
    "id": "47.11325,14.16195"
  },
  {
    "ID": 429,
    "Tankstellennummer": "0G93M",
    "Name": "Gratkorn, Harterstr. 8",
    "Address": "Harter Straße 8",
    "Address__1": "STMK",
    "City": "Gratkorn",
    "Postcode": 8101,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 3124290291,
    "24 hour?": "FALSE",
    "Latitude": 47.1221,
    "Longitude": 15.3604,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.1221,15.3604",
    "IsOpen24Hours": false,
    "id": "47.1221,15.3604"
  },
  {
    "ID": 19543,
    "Tankstellennummer": 5538,
    "Name": "Matrei Brenner Straße 114",
    "Address": "Brenner Straße 114",
    "Address__1": "",
    "City": "Matrei",
    "Postcode": 6143,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05273 6317",
    "24 hour?": "FALSE",
    "Latitude": 47.1286,
    "Longitude": 11.45258,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.1286,11.45258",
    "IsOpen24Hours": false,
    "id": "47.1286,11.45258"
  },
  {
    "ID": 19697,
    "Tankstellennummer": 6722,
    "Name": "Klösterle Danöfen 126",
    "Address": "Danöfen 126",
    "Address__1": "",
    "City": "Klösterle",
    "Postcode": 6754,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05582 592",
    "24 hour?": "TRUE",
    "Latitude": 47.12939,
    "Longitude": 10.06077,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.12939,10.06077",
    "IsOpen24Hours": true,
    "id": "47.12939,10.06077"
  },
  {
    "ID": 19537,
    "Tankstellennummer": 5700,
    "Name": "Kampl Gewerbezone 11",
    "Address": "Gewerbezone 11",
    "Address__1": "",
    "City": "Neustift",
    "Postcode": 6167,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05226 3188",
    "24 hour?": "FALSE",
    "Latitude": 47.12944,
    "Longitude": 11.33707,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.12944,11.33707",
    "IsOpen24Hours": false,
    "id": "47.12944,11.33707"
  },
  {
    "ID": 421322094,
    "Tankstellennummer": 2110,
    "Name": "eni24 Mauterndorf",
    "Address": "Katschb. Bundesstrasse",
    "Address__1": "",
    "City": "Mauterndorf",
    "Postcode": 5570,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6472/7349",
    "24 hour?": "FALSE",
    "Latitude": 47.1295,
    "Longitude": 13.684,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.1295,13.684",
    "IsOpen24Hours": false,
    "id": "47.1295,13.684"
  },
  {
    "ID": 2144425674,
    "Tankstellennummer": "0GH28",
    "Name": "Gratwein, Bahnhofstr. 27",
    "Address": "Bahnhofstraße 27",
    "Address__1": "",
    "City": "Gratwein",
    "Postcode": 8112,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 312452168,
    "24 hour?": "FALSE",
    "Latitude": 47.130132,
    "Longitude": 15.323842,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.130132,15.323842",
    "IsOpen24Hours": false,
    "id": "47.130132,15.323842"
  },
  {
    "ID": 19443,
    "Tankstellennummer": 2041,
    "Name": "Zederhaus",
    "Address": "Lamm 113",
    "Address__1": "",
    "City": "Zederhaus/Krottendorf",
    "Postcode": 5584,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6478/800",
    "24 hour?": "TRUE",
    "Latitude": 47.13408,
    "Longitude": 13.54854,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.13408,13.54854",
    "IsOpen24Hours": true,
    "id": "47.13408,13.54854"
  },
  {
    "ID": 557702902,
    "Tankstellennummer": 1150,
    "Name": "eni Gutmann St. Anton",
    "Address": "Bundesstrasse 197",
    "Address__1": "",
    "City": "St. Anton",
    "Postcode": 6580,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5446/42622",
    "24 hour?": "FALSE",
    "Latitude": 47.13976,
    "Longitude": 10.28971,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.13976,10.28971",
    "IsOpen24Hours": false,
    "id": "47.13976,10.28971"
  },
  {
    "ID": 19440,
    "Tankstellennummer": 1004,
    "Name": "Matrei Tal",
    "Address": "Brenner Autobahn Ost",
    "Address__1": "",
    "City": "Matrei Tal",
    "Postcode": 6143,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5273/6385",
    "24 hour?": "TRUE",
    "Latitude": 47.14072,
    "Longitude": 11.44653,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.14072,11.44653",
    "IsOpen24Hours": true,
    "id": "47.14072,11.44653"
  },
  {
    "ID": 19625,
    "Tankstellennummer": 6526,
    "Name": "Innerbraz Arlbergstraße 91",
    "Address": "Äuleweg 1",
    "Address__1": "",
    "City": "Innerbraz",
    "Postcode": 6751,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05552 28012",
    "24 hour?": "TRUE",
    "Latitude": 47.14077,
    "Longitude": 9.92512,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.14077,9.92512",
    "IsOpen24Hours": true,
    "id": "47.14077,9.92512"
  },
  {
    "ID": 19436,
    "Tankstellennummer": 1006,
    "Name": "Matrei Berg",
    "Address": "Brenner Autobahn West",
    "Address__1": "",
    "City": "Matrei Berg",
    "Postcode": 6143,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5273/6385",
    "24 hour?": "TRUE",
    "Latitude": 47.14126338,
    "Longitude": 11.44595606,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.14126338,11.44595606",
    "IsOpen24Hours": true,
    "id": "47.14126338,11.44595606"
  },
  {
    "ID": 2138346296,
    "Tankstellennummer": "A5655",
    "Name": "Landeck Innstrasse 38",
    "Address": "Innstrasse 38",
    "Address__1": "",
    "City": "Landeck",
    "Postcode": 6500,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.14163,
    "Longitude": 10.56352,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.14163,10.56352",
    "IsOpen24Hours": false,
    "id": "47.14163,10.56352"
  },
  {
    "ID": 9832,
    "Tankstellennummer": "0G92L",
    "Name": "Bludenz, Montafoner Str. 29",
    "Address": "Montafoner Strasse 29",
    "Address__1": "VBG",
    "City": "Bludenz",
    "Postcode": 6700,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083004550,
    "24 hour?": "FALSE",
    "Latitude": 47.141829,
    "Longitude": 9.833412,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.141829,9.833412",
    "IsOpen24Hours": false,
    "id": "47.141829,9.833412"
  },
  {
    "ID": 19760,
    "Tankstellennummer": 5596,
    "Name": "Pettneu am Arlberg Schnann-Gewerbezone 150",
    "Address": "Schnann-Gewerbezone 150",
    "Address__1": "",
    "City": "Pettneu am Arlberg",
    "Postcode": 6574,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05447 20111",
    "24 hour?": "TRUE",
    "Latitude": 47.1489,
    "Longitude": 10.3743,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.1489,10.3743",
    "IsOpen24Hours": true,
    "id": "47.1489,10.3743"
  },
  {
    "ID": 86150251,
    "Tankstellennummer": 3113,
    "Name": "eni Gutmann Bludenz",
    "Address": "Hermann-Sander-Straße 10",
    "Address__1": "",
    "City": "Bludenz",
    "Postcode": 6700,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5022/77670",
    "24 hour?": "FALSE",
    "Latitude": 47.15301,
    "Longitude": 9.81815,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.15301,9.81815",
    "IsOpen24Hours": false,
    "id": "47.15301,9.81815"
  },
  {
    "ID": 580767318,
    "Tankstellennummer": 1117,
    "Name": "eni Gutmann Innsbruck",
    "Address": "Fürstenweg 87",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)512/22777",
    "24 hour?": "FALSE",
    "Latitude": 47.1544,
    "Longitude": 11.222,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.1544,11.222",
    "IsOpen24Hours": false,
    "id": "47.1544,11.222"
  },
  {
    "ID": 670174358,
    "Tankstellennummer": 1139,
    "Name": "eni Zams",
    "Address": "Hauptstr. 96",
    "Address__1": "",
    "City": "Zams",
    "Postcode": 6511,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5442/67312",
    "24 hour?": "FALSE",
    "Latitude": 47.157,
    "Longitude": 10.5889,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.157,10.5889",
    "IsOpen24Hours": false,
    "id": "47.157,10.5889"
  },
  {
    "ID": 1825763686,
    "Tankstellennummer": 1123,
    "Name": "eni Mayrhofen",
    "Address": "Umfahrungsstr. 688",
    "Address__1": "",
    "City": "Mayrhofen",
    "Postcode": 6290,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5285/8266",
    "24 hour?": "FALSE",
    "Latitude": 47.16,
    "Longitude": 11.8493,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.16,11.8493",
    "IsOpen24Hours": false,
    "id": "47.16,11.8493"
  },
  {
    "ID": 1301461331,
    "Tankstellennummer": "A1521",
    "Name": "Stegersbach Wiener Straße 3",
    "Address": "Wiener Straße 3",
    "Address__1": "",
    "City": "Stegersbach",
    "Postcode": 7551,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.16113,
    "Longitude": 16.16488,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.16113,16.16488",
    "IsOpen24Hours": false,
    "id": "47.16113,16.16488"
  },
  {
    "ID": 19587,
    "Tankstellennummer": 1620,
    "Name": "Bad Waltersdorf 283",
    "Address": 283,
    "Address__1": "",
    "City": "Bad Waltersdorf",
    "Postcode": 8271,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03333 414110",
    "24 hour?": "FALSE",
    "Latitude": 47.16299,
    "Longitude": 16.01469,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.16299,16.01469",
    "IsOpen24Hours": false,
    "id": "47.16299,16.01469"
  },
  {
    "ID": 34378488,
    "Tankstellennummer": 1039,
    "Name": "eni Mieders",
    "Address": "Dorfstrasse 1",
    "Address__1": "",
    "City": "Mieders",
    "Postcode": 6142,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5225/62664",
    "24 hour?": "FALSE",
    "Latitude": 47.17122,
    "Longitude": 11.38392,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.17122,11.38392",
    "IsOpen24Hours": false,
    "id": "47.17122,11.38392"
  },
  {
    "ID": 272149871,
    "Tankstellennummer": 2007,
    "Name": "eni Bad Hofgastein",
    "Address": "Gasteiner Bundesstrasse 167",
    "Address__1": "",
    "City": "Bad Hofgastein",
    "Postcode": 5630,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6432/6123",
    "24 hour?": "TRUE",
    "Latitude": 47.17579,
    "Longitude": 13.10021,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.17579,13.10021",
    "IsOpen24Hours": true,
    "id": "47.17579,13.10021"
  },
  {
    "ID": 1021435558,
    "Tankstellennummer": 1134,
    "Name": "eni Gutmann Tumpen",
    "Address": "Hauptstrae 91",
    "Address__1": "",
    "City": "Tumpen",
    "Postcode": 6433,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5255/50148",
    "24 hour?": "FALSE",
    "Latitude": 47.1761,
    "Longitude": 10.9102,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.1761,10.9102",
    "IsOpen24Hours": false,
    "id": "47.1761,10.9102"
  },
  {
    "ID": 141775879,
    "Tankstellennummer": 1154,
    "Name": "eni Gutmann Mieders",
    "Address": "Stubaitalstraße 10",
    "Address__1": "",
    "City": "Mieders",
    "Postcode": 6142,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5225/62893",
    "24 hour?": "FALSE",
    "Latitude": 47.17688,
    "Longitude": 11.39159,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.17688,11.39159",
    "IsOpen24Hours": false,
    "id": "47.17688,11.39159"
  },
  {
    "ID": 19005,
    "Tankstellennummer": "0G97D",
    "Name": "Sebersdorf 304",
    "Address": "Sebersdorf 304",
    "Address__1": "STMK",
    "City": "Sebersdorf",
    "Postcode": 8272,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 333324899,
    "24 hour?": "FALSE",
    "Latitude": 47.182678,
    "Longitude": 15.992691,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.182678,15.992691",
    "IsOpen24Hours": false,
    "id": "47.182678,15.992691"
  },
  {
    "ID": 19702,
    "Tankstellennummer": 6723,
    "Name": "Nenzing Bundesstraße 77",
    "Address": "Bundesstraße 77",
    "Address__1": "",
    "City": "Nenzing",
    "Postcode": 6710,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05525 62383",
    "24 hour?": "FALSE",
    "Latitude": 47.18918,
    "Longitude": 9.70154,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.18918,9.70154",
    "IsOpen24Hours": false,
    "id": "47.18918,9.70154"
  },
  {
    "ID": 563078509,
    "Tankstellennummer": 7004,
    "Name": "eni Fohnsdorf",
    "Address": "Bundesstrasse 28",
    "Address__1": "",
    "City": "Fohnsdorf",
    "Postcode": 8753,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3572/47340",
    "24 hour?": "FALSE",
    "Latitude": 47.19201,
    "Longitude": 14.70616,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.19201,14.70616",
    "IsOpen24Hours": false,
    "id": "47.19201,14.70616"
  },
  {
    "ID": 2100411926,
    "Tankstellennummer": "0GH53",
    "Name": "Zeltweg, Hauptstr. 2",
    "Address": "Hauptstraße 2",
    "Address__1": "",
    "City": "Zeltweg",
    "Postcode": 8740,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 357724800,
    "24 hour?": "FALSE",
    "Latitude": 47.1973514,
    "Longitude": 14.7665027,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.1973514,14.7665027",
    "IsOpen24Hours": false,
    "id": "47.1973514,14.7665027"
  },
  {
    "ID": 19703,
    "Tankstellennummer": 6523,
    "Name": "Thüringen Walgaustraße 68",
    "Address": "Walgaustraße 499",
    "Address__1": "",
    "City": "Thüringen",
    "Postcode": 6712,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05550 3399",
    "24 hour?": "FALSE",
    "Latitude": 47.1993,
    "Longitude": 9.75582,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.1993,9.75582",
    "IsOpen24Hours": false,
    "id": "47.1993,9.75582"
  },
  {
    "ID": 19590,
    "Tankstellennummer": 1781,
    "Name": "Deutschfeistritz Pyhrnautobahn bei KM 198",
    "Address": "Autobahn A9 (Phyrnautobahn) KM 164",
    "Address__1": "",
    "City": "Deutschfeistritz",
    "Postcode": 8121,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03127 40920",
    "24 hour?": "TRUE",
    "Latitude": 47.20552,
    "Longitude": 15.31602,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.20552,15.31602",
    "IsOpen24Hours": true,
    "id": "47.20552,15.31602"
  },
  {
    "ID": 1651148660,
    "Tankstellennummer": "A1500",
    "Name": "Knittelfeld Kärntner Straße 75",
    "Address": "Kärntner Straße 75",
    "Address__1": "",
    "City": "Knittelfeld",
    "Postcode": 8720,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.21001,
    "Longitude": 14.80875,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.21001,14.80875",
    "IsOpen24Hours": false,
    "id": "47.21001,14.80875"
  },
  {
    "ID": 1663975090,
    "Tankstellennummer": "A5546",
    "Name": "Arzl im Pitztal Dorfstraße 16",
    "Address": "Dorfstraße 16",
    "Address__1": "",
    "City": "Arzl im Pitztal",
    "Postcode": 6471,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.21094,
    "Longitude": 10.75935,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.21094,10.75935",
    "IsOpen24Hours": false,
    "id": "47.21094,10.75935"
  },
  {
    "ID": 9872,
    "Tankstellennummer": "0G95V",
    "Name": "Weiz, Gleisdorfer Str. 60",
    "Address": "Gleisdorfer Straße 60",
    "Address__1": "STMK",
    "City": "Weiz",
    "Postcode": 8160,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 31722073,
    "24 hour?": "FALSE",
    "Latitude": 47.2123,
    "Longitude": 15.6293,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2123,15.6293",
    "IsOpen24Hours": false,
    "id": "47.2123,15.6293"
  },
  {
    "ID": 19445,
    "Tankstellennummer": 1055,
    "Name": "Mils - Trofana",
    "Address": "An der Au 1",
    "Address__1": "",
    "City": "Mils bei Imst",
    "Postcode": 6493,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5418/20137",
    "24 hour?": "TRUE",
    "Latitude": 47.21354,
    "Longitude": 10.69712,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.21354,10.69712",
    "IsOpen24Hours": true,
    "id": "47.21354,10.69712"
  },
  {
    "ID": 2030681162,
    "Tankstellennummer": 7028,
    "Name": "eni Knittelfeld",
    "Address": "Anton Regner Strasse 59",
    "Address__1": "",
    "City": "Knittelfeld",
    "Postcode": 8720,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3512/82180",
    "24 hour?": "FALSE",
    "Latitude": 47.21828,
    "Longitude": 14.81564,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.21828,14.81564",
    "IsOpen24Hours": false,
    "id": "47.21828,14.81564"
  },
  {
    "ID": 19602,
    "Tankstellennummer": 1579,
    "Name": "Kaindorf Hinterbüchl 224",
    "Address": "Hinterbüchl 224",
    "Address__1": "",
    "City": "Kaindorf",
    "Postcode": 8224,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03334 2272",
    "24 hour?": "FALSE",
    "Latitude": 47.21909,
    "Longitude": 15.90144,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.21909,15.90144",
    "IsOpen24Hours": false,
    "id": "47.21909,15.90144"
  },
  {
    "ID": 8946,
    "Tankstellennummer": "0G997",
    "Name": "Knittelfeld, Wiener Str. 51",
    "Address": "Wiener Straße 51",
    "Address__1": "STMK",
    "City": "Knittelfeld",
    "Postcode": 8720,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 351283710,
    "24 hour?": "FALSE",
    "Latitude": 47.2202,
    "Longitude": 14.829,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2202,14.829",
    "IsOpen24Hours": false,
    "id": "47.2202,14.829"
  },
  {
    "ID": 1870199927,
    "Tankstellennummer": 3003,
    "Name": "eni Feldkirch",
    "Address": "Liechtensteinerstrasse 115",
    "Address__1": "",
    "City": "Feldkirch",
    "Postcode": 6800,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5522/73119",
    "24 hour?": "TRUE",
    "Latitude": 47.22187,
    "Longitude": 9.57614,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.22187,9.57614",
    "IsOpen24Hours": true,
    "id": "47.22187,9.57614"
  },
  {
    "ID": 19695,
    "Tankstellennummer": 6510,
    "Name": "Frastanz Feldkircher Straße",
    "Address": "Feldkircher Straße 47",
    "Address__1": "",
    "City": "Frastanz",
    "Postcode": 6820,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05522 51109",
    "24 hour?": "FALSE",
    "Latitude": 47.22452,
    "Longitude": 9.61744,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.22452,9.61744",
    "IsOpen24Hours": false,
    "id": "47.22452,9.61744"
  },
  {
    "ID": 311278597,
    "Tankstellennummer": 1147,
    "Name": "eni Gutmann Imst",
    "Address": "Langgasse 95",
    "Address__1": "",
    "City": "Imst",
    "Postcode": 6460,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5412/62730",
    "24 hour?": "FALSE",
    "Latitude": 47.22655,
    "Longitude": 10.74782,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.22655,10.74782",
    "IsOpen24Hours": false,
    "id": "47.22655,10.74782"
  },
  {
    "ID": 1114389584,
    "Tankstellennummer": 5599,
    "Name": "Imst Langgasse 91a",
    "Address": "Langgasse 91a",
    "Address__1": "",
    "City": "Imst",
    "Postcode": 6460,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05412/6515142",
    "24 hour?": "FALSE",
    "Latitude": 47.22747,
    "Longitude": 10.74698,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.22747,10.74698",
    "IsOpen24Hours": false,
    "id": "47.22747,10.74698"
  },
  {
    "ID": 19709,
    "Tankstellennummer": 5737,
    "Name": "Zell am Ziller An der Umfahrungsstraße West",
    "Address": "An der Umfahrungsstraße West",
    "Address__1": "",
    "City": "Zell am Ziller",
    "Postcode": 6280,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05282 3115",
    "24 hour?": "FALSE",
    "Latitude": 47.22926,
    "Longitude": 11.89207,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.22926,11.89207",
    "IsOpen24Hours": false,
    "id": "47.22926,11.89207"
  },
  {
    "ID": 19766,
    "Tankstellennummer": 5597,
    "Name": "Ötztal-Bahnhof Ötztaler Bundesstraße 15",
    "Address": "Ötztaler Bundesstraße 15",
    "Address__1": "",
    "City": "Ötztal-Bahnhof",
    "Postcode": 6430,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05266 88158",
    "24 hour?": "TRUE",
    "Latitude": 47.22949,
    "Longitude": 10.85657,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.22949,10.85657",
    "IsOpen24Hours": true,
    "id": "47.22949,10.85657"
  },
  {
    "ID": 9868,
    "Tankstellennummer": "0G94B",
    "Name": "Mutters, Innsbrucker Str. 24",
    "Address": "Innsbrucker Straße 24",
    "Address__1": "T",
    "City": "Mutters",
    "Postcode": 6162,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 52249090062,
    "24 hour?": "FALSE",
    "Latitude": 47.2302,
    "Longitude": 11.3809,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2302,11.3809",
    "IsOpen24Hours": false,
    "id": "47.2302,11.3809"
  },
  {
    "ID": 208555629,
    "Tankstellennummer": "A5745",
    "Name": "Axams Innsbrucker Straße 37",
    "Address": "Innsbrucker Straße 37",
    "Address__1": "",
    "City": "Axams",
    "Postcode": 6094,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.23079,
    "Longitude": 11.28586,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.23079,11.28586",
    "IsOpen24Hours": false,
    "id": "47.23079,11.28586"
  },
  {
    "ID": 270013253,
    "Tankstellennummer": 1128,
    "Name": "eni Gutmann ötztaler Bahnhof",
    "Address": "Ötztaler Höhe",
    "Address__1": "",
    "City": "ötztaler - Bahnhof",
    "Postcode": 6430,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5266/88361",
    "24 hour?": "FALSE",
    "Latitude": 47.23157,
    "Longitude": 10.85312,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.23157,10.85312",
    "IsOpen24Hours": false,
    "id": "47.23157,10.85312"
  },
  {
    "ID": 155099721,
    "Tankstellennummer": 1040,
    "Name": "eni Imst",
    "Address": "Bundesstraße 4",
    "Address__1": "",
    "City": "Imst",
    "Postcode": 6460,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5412/66889",
    "24 hour?": "TRUE",
    "Latitude": 47.23248,
    "Longitude": 10.73582,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.23248,10.73582",
    "IsOpen24Hours": true,
    "id": "47.23248,10.73582"
  },
  {
    "ID": 1676725913,
    "Tankstellennummer": 1111,
    "Name": "eni Gutmann Götzens",
    "Address": "Burgstrasse 29",
    "Address__1": "",
    "City": "Götzens",
    "Postcode": 6091,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5234/33881",
    "24 hour?": "FALSE",
    "Latitude": 47.23915,
    "Longitude": 11.31189,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.23915,11.31189",
    "IsOpen24Hours": false,
    "id": "47.23915,11.31189"
  },
  {
    "ID": 600752721,
    "Tankstellennummer": 2030,
    "Name": "eni Großarl",
    "Address": "Markt 171",
    "Address__1": "",
    "City": "Großarl",
    "Postcode": 5611,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6414/8862",
    "24 hour?": "FALSE",
    "Latitude": 47.24036638,
    "Longitude": 13.20004642,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.24036638,13.20004642",
    "IsOpen24Hours": false,
    "id": "47.24036638,13.20004642"
  },
  {
    "ID": 9849,
    "Tankstellennummer": "0G95P",
    "Name": "Feldkirch-Gisingen, Haemmerlestr. 99",
    "Address": "Hämmerlestraße 99",
    "Address__1": "VBG",
    "City": "Feldkirch-Gisingen",
    "Postcode": 6805,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 552273455,
    "24 hour?": "FALSE",
    "Latitude": 47.2411,
    "Longitude": 9.59102,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2411,9.59102",
    "IsOpen24Hours": false,
    "id": "47.2411,9.59102"
  },
  {
    "ID": 1576808380,
    "Tankstellennummer": 1101,
    "Name": "eni Gutmann Aldrans",
    "Address": "Lanserstrasse 2A",
    "Address__1": "",
    "City": "Aldrans",
    "Postcode": 6071,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)512/341234",
    "24 hour?": "FALSE",
    "Latitude": 47.24823,
    "Longitude": 11.44406,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.24823,11.44406",
    "IsOpen24Hours": false,
    "id": "47.24823,11.44406"
  },
  {
    "ID": 9883,
    "Tankstellennummer": "0G961",
    "Name": "Flachau/Tauernalm",
    "Address": "Flachauwinklstraße 182",
    "Address__1": "SBG",
    "City": "Flachau/Tauernalm",
    "Postcode": 5542,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 645733737,
    "24 hour?": "TRUE",
    "Latitude": 47.2488361,
    "Longitude": 13.4247908,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.2488361,13.4247908",
    "IsOpen24Hours": true,
    "id": "47.2488361,13.4247908"
  },
  {
    "ID": 2026031433,
    "Tankstellennummer": 7048,
    "Name": "eni St.Marein/Knittelf.",
    "Address": "S 36 Murtal-Schnellstr. Nord",
    "Address__1": "",
    "City": "St.Marein/Knittelf.",
    "Postcode": 8733,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3515/4331",
    "24 hour?": "TRUE",
    "Latitude": 47.25337,
    "Longitude": 14.8789,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.25337,14.8789",
    "IsOpen24Hours": true,
    "id": "47.25337,14.8789"
  },
  {
    "ID": 759098288,
    "Tankstellennummer": 7049,
    "Name": "eni St.Marein/Knittelf.",
    "Address": "S 36 Murtal-Schnellstr. Sd",
    "Address__1": "",
    "City": "St.Marein/Knittelf.",
    "Postcode": 8733,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3515/4843",
    "24 hour?": "TRUE",
    "Latitude": 47.25337,
    "Longitude": 14.8789,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.25337,14.8789",
    "IsOpen24Hours": true,
    "id": "47.25337,14.8789"
  },
  {
    "ID": 1462728092,
    "Tankstellennummer": 1152,
    "Name": "eni Gutmann Innsbruck",
    "Address": "Leopoldstrae 67",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)512/585958",
    "24 hour?": "FALSE",
    "Latitude": 47.25394,
    "Longitude": 11.39918,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.25394,11.39918",
    "IsOpen24Hours": false,
    "id": "47.25394,11.39918"
  },
  {
    "ID": 325645215,
    "Tankstellennummer": 1052,
    "Name": "eni Innsbruck",
    "Address": "Leopoldstrasse 66",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)512/584177",
    "24 hour?": "TRUE",
    "Latitude": 47.25592,
    "Longitude": 11.39805,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.25592,11.39805",
    "IsOpen24Hours": true,
    "id": "47.25592,11.39805"
  },
  {
    "ID": 19551,
    "Tankstellennummer": 5711,
    "Name": "Völs Aflinger Straße 4",
    "Address": "Aflinger Straße 4",
    "Address__1": "",
    "City": "Völs",
    "Postcode": 6176,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0512 303578",
    "24 hour?": "FALSE",
    "Latitude": 47.25616,
    "Longitude": 11.31615,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.25616,11.31615",
    "IsOpen24Hours": false,
    "id": "47.25616,11.31615"
  },
  {
    "ID": 19535,
    "Tankstellennummer": 5717,
    "Name": "Innsbruck Egger-Lienz-Straße 3d",
    "Address": "Egger-Lienz-Straße 3d",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0512 582345",
    "24 hour?": "TRUE",
    "Latitude": 47.25633,
    "Longitude": 11.38869,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.25633,11.38869",
    "IsOpen24Hours": true,
    "id": "47.25633,11.38869"
  },
  {
    "ID": 19536,
    "Tankstellennummer": 5505,
    "Name": "Innsbruck Innrain 104",
    "Address": "Innrain 104",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0512 575303",
    "24 hour?": "TRUE",
    "Latitude": 47.25734,
    "Longitude": 11.37805,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.25734,11.37805",
    "IsOpen24Hours": true,
    "id": "47.25734,11.37805"
  },
  {
    "ID": 533765233,
    "Tankstellennummer": 1047,
    "Name": "eni Innsbruck",
    "Address": "Mitterweg/ Ecke Exelgasse",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)512/272062",
    "24 hour?": "FALSE",
    "Latitude": 47.258583,
    "Longitude": 11.372086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.258583,11.372086",
    "IsOpen24Hours": false,
    "id": "47.258583,11.372086"
  },
  {
    "ID": 416502820,
    "Tankstellennummer": 1044,
    "Name": "eni Bach im Lechtal",
    "Address": "Stockach 29A",
    "Address__1": "",
    "City": "Bach im Lechtal",
    "Postcode": 6653,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5634/6156",
    "24 hour?": "TRUE",
    "Latitude": 47.25996,
    "Longitude": 10.37553,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.25996,10.37553",
    "IsOpen24Hours": true,
    "id": "47.25996,10.37553"
  },
  {
    "ID": 9877,
    "Tankstellennummer": "0G92Z",
    "Name": "Innsbruck, Amraser Seestr. 10",
    "Address": "Amraser Seestraße 10",
    "Address__1": "T",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083004000,
    "24 hour?": "FALSE",
    "Latitude": 47.2609,
    "Longitude": 11.4213,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2609,11.4213",
    "IsOpen24Hours": false,
    "id": "47.2609,11.4213"
  },
  {
    "ID": 804470702,
    "Tankstellennummer": 1120,
    "Name": "eni Gutmann Kematen",
    "Address": "Sellraintalerstrasse",
    "Address__1": "",
    "City": "Kematen",
    "Postcode": 6175,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5232/3322",
    "24 hour?": "TRUE",
    "Latitude": 47.26168,
    "Longitude": 11.26511,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.26168,11.26511",
    "IsOpen24Hours": true,
    "id": "47.26168,11.26511"
  },
  {
    "ID": 1038605981,
    "Tankstellennummer": 1105,
    "Name": "eni Gutmann Innsbruck",
    "Address": "Amraserseestr. 29",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)512/343114",
    "24 hour?": "FALSE",
    "Latitude": 47.2619,
    "Longitude": 11.42309,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2619,11.42309",
    "IsOpen24Hours": false,
    "id": "47.2619,11.42309"
  },
  {
    "ID": 474,
    "Tankstellennummer": "0G976",
    "Name": "Innsbruck/Ampass",
    "Address": "A12",
    "Address__1": "T",
    "City": "Ampass",
    "Postcode": 6070,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 512346411,
    "24 hour?": "TRUE",
    "Latitude": 47.263278,
    "Longitude": 11.453458,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.263278,11.453458",
    "IsOpen24Hours": true,
    "id": "47.263278,11.453458"
  },
  {
    "ID": 19437,
    "Tankstellennummer": 1005,
    "Name": "Innsbruck-Amras",
    "Address": "Amraser Seestrasse 64",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)512/345498",
    "24 hour?": "TRUE",
    "Latitude": 47.2634138,
    "Longitude": 11.4333943,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.2634138,11.4333943",
    "IsOpen24Hours": true,
    "id": "47.2634138,11.4333943"
  },
  {
    "ID": 478,
    "Tankstellennummer": "0G977",
    "Name": "Innsbruck, Kranebitterallee 30",
    "Address": "Kranebitter Allee 30",
    "Address__1": "T",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 512274514,
    "24 hour?": "TRUE",
    "Latitude": 47.2645,
    "Longitude": 11.3658,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.2645,11.3658",
    "IsOpen24Hours": true,
    "id": "47.2645,11.3658"
  },
  {
    "ID": 19768,
    "Tankstellennummer": 5652,
    "Name": "Innsbruck Andechsstraße 83",
    "Address": "Andechsstraße 83",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0512 346219",
    "24 hour?": "TRUE",
    "Latitude": 47.26531,
    "Longitude": 11.42629,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.26531,11.42629",
    "IsOpen24Hours": true,
    "id": "47.26531,11.42629"
  },
  {
    "ID": 1720012476,
    "Tankstellennummer": "A5653",
    "Name": "Innsbruck Andechsstraße West 8",
    "Address": "Andechsstraße West 86",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.26533,
    "Longitude": 11.42537,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.26533,11.42537",
    "IsOpen24Hours": false,
    "id": "47.26533,11.42537"
  },
  {
    "ID": 300367062,
    "Tankstellennummer": 1135,
    "Name": "eni Gutmann Innsbruck",
    "Address": "Grabenweg 73",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5022776027",
    "24 hour?": "FALSE",
    "Latitude": 47.26587,
    "Longitude": 11.44258,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.26587,11.44258",
    "IsOpen24Hours": false,
    "id": "47.26587,11.44258"
  },
  {
    "ID": 836570533,
    "Tankstellennummer": 1028,
    "Name": "eni Innsbruck",
    "Address": "Andechsstrasse 69",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)512/344262",
    "24 hour?": "TRUE",
    "Latitude": 47.26672,
    "Longitude": 11.4236,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.26672,11.4236",
    "IsOpen24Hours": true,
    "id": "47.26672,11.4236"
  },
  {
    "ID": 677419611,
    "Tankstellennummer": 1141,
    "Name": "eni Gutmann Zirl",
    "Address": "Bahnhofstrae 37A",
    "Address__1": "",
    "City": "Zirl",
    "Postcode": 6170,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5238/52622",
    "24 hour?": "FALSE",
    "Latitude": 47.26753,
    "Longitude": 11.2344,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.26753,11.2344",
    "IsOpen24Hours": false,
    "id": "47.26753,11.2344"
  },
  {
    "ID": 19694,
    "Tankstellennummer": 6716,
    "Name": "Feldkirch-Altenstadt Königshofstraße 61",
    "Address": "Königshofstraße 61",
    "Address__1": "",
    "City": "Feldkirch",
    "Postcode": 6804,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05522 73589",
    "24 hour?": "TRUE",
    "Latitude": 47.26779,
    "Longitude": 9.61507,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.26779,9.61507",
    "IsOpen24Hours": true,
    "id": "47.26779,9.61507"
  },
  {
    "ID": 19503,
    "Tankstellennummer": 4522,
    "Name": "Bramberg Weichseldorf 243",
    "Address": "Weichseldorf 243",
    "Address__1": "",
    "City": "Bramberg",
    "Postcode": 5733,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "06566 7013",
    "24 hour?": "FALSE",
    "Latitude": 47.2678,
    "Longitude": 12.33757,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2678,12.33757",
    "IsOpen24Hours": false,
    "id": "47.2678,12.33757"
  },
  {
    "ID": 9876,
    "Tankstellennummer": "0G996",
    "Name": "Fladnitz, Schrems 3",
    "Address": "Schrems 3",
    "Address__1": "STMK",
    "City": "Fladnitz",
    "Postcode": 8163,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 31796132,
    "24 hour?": "FALSE",
    "Latitude": 47.2685733,
    "Longitude": 15.4807577,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2685733,15.4807577",
    "IsOpen24Hours": false,
    "id": "47.2685733,15.4807577"
  },
  {
    "ID": 285367945,
    "Tankstellennummer": 1014,
    "Name": "eni Silz",
    "Address": "Tiroler Strasse 2",
    "Address__1": "",
    "City": "Silz",
    "Postcode": 6424,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5263/6921",
    "24 hour?": "TRUE",
    "Latitude": 47.26906,
    "Longitude": 10.94032,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.26906,10.94032",
    "IsOpen24Hours": true,
    "id": "47.26906,10.94032"
  },
  {
    "ID": 1919696555,
    "Tankstellennummer": "A5511",
    "Name": "Zirl Meilstraße 49",
    "Address": "Meilstraße 49",
    "Address__1": "",
    "City": "Zirl",
    "Postcode": 6170,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.27135,
    "Longitude": 11.25196,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.27135,11.25196",
    "IsOpen24Hours": false,
    "id": "47.27135,11.25196"
  },
  {
    "ID": 653255346,
    "Tankstellennummer": 3004,
    "Name": "eni Rankweil",
    "Address": "Langgasse 118a",
    "Address__1": "",
    "City": "Rankweil",
    "Postcode": 6830,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5522/46805",
    "24 hour?": "TRUE",
    "Latitude": 47.27214,
    "Longitude": 9.61833,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.27214,9.61833",
    "IsOpen24Hours": true,
    "id": "47.27214,9.61833"
  },
  {
    "ID": 19765,
    "Tankstellennummer": 5594,
    "Name": "Hall in Tirol Schlöglstraße 77",
    "Address": "Schlöglstraße 77",
    "Address__1": "",
    "City": "Hall in Tirol",
    "Postcode": 6060,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05223 43573",
    "24 hour?": "TRUE",
    "Latitude": 47.27348,
    "Longitude": 11.47347,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.27348,11.47347",
    "IsOpen24Hours": true,
    "id": "47.27348,11.47347"
  },
  {
    "ID": 9880,
    "Tankstellennummer": "0G931",
    "Name": "Innsbruck, Reichenauer Str. 149",
    "Address": "Reichenauer Straße 149",
    "Address__1": "T",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 43508300,
    "24 hour?": "FALSE",
    "Latitude": 47.2738,
    "Longitude": 11.4282,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2738,11.4282",
    "IsOpen24Hours": false,
    "id": "47.2738,11.4282"
  },
  {
    "ID": 764536244,
    "Tankstellennummer": 9020,
    "Name": "eni Kemeten",
    "Address": "Steinbrückl 14",
    "Address__1": "",
    "City": "Kemeten",
    "Postcode": 7531,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3352/20168",
    "24 hour?": "TRUE",
    "Latitude": 47.27489,
    "Longitude": 16.15556,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.27489,16.15556",
    "IsOpen24Hours": true,
    "id": "47.27489,16.15556"
  },
  {
    "ID": 58467099,
    "Tankstellennummer": "0GAEU",
    "Name": "Innsbruck, Hallerstr. 164",
    "Address": "Haller Straße 164",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 512260492,
    "24 hour?": "FALSE",
    "Latitude": 47.2754196,
    "Longitude": 11.4340339,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2754196,11.4340339",
    "IsOpen24Hours": false,
    "id": "47.2754196,11.4340339"
  },
  {
    "ID": 1466143534,
    "Tankstellennummer": "A1641",
    "Name": "Kemeten Steinbrückl 12a",
    "Address": "Steinbrückl 12a",
    "Address__1": "",
    "City": "Kemeten",
    "Postcode": 7531,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.27573,
    "Longitude": 16.15609,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.27573,16.15609",
    "IsOpen24Hours": false,
    "id": "47.27573,16.15609"
  },
  {
    "ID": 1594825830,
    "Tankstellennummer": "0GH57",
    "Name": "Hartberg, Ressavarstr. 4",
    "Address": "Ressavarstraße 4",
    "Address__1": "",
    "City": "Hartberg",
    "Postcode": 8230,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 333262571,
    "24 hour?": "FALSE",
    "Latitude": 47.275829,
    "Longitude": 15.96666,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.275829,15.96666",
    "IsOpen24Hours": false,
    "id": "47.275829,15.96666"
  },
  {
    "ID": 584097715,
    "Tankstellennummer": 1116,
    "Name": "eni Innsbruck",
    "Address": "Rennweg 20",
    "Address__1": "",
    "City": "Innsbruck",
    "Postcode": 6020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)512/585400",
    "24 hour?": "FALSE",
    "Latitude": 47.2765,
    "Longitude": 11.3995,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2765,11.3995",
    "IsOpen24Hours": false,
    "id": "47.2765,11.3995"
  },
  {
    "ID": 1543647993,
    "Tankstellennummer": 1109,
    "Name": "eni Gutmann Hall",
    "Address": "Innsbruckerstrasse 90",
    "Address__1": "",
    "City": "Hall",
    "Postcode": 6060,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5223/57777",
    "24 hour?": "FALSE",
    "Latitude": 47.277,
    "Longitude": 11.4738,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.277,11.4738",
    "IsOpen24Hours": false,
    "id": "47.277,11.4738"
  },
  {
    "ID": 836062293,
    "Tankstellennummer": "A5006",
    "Name": "Rum Siemensstraße 1",
    "Address": "Siemensstraße 1",
    "Address__1": "",
    "City": "Rum",
    "Postcode": 6063,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.27753,
    "Longitude": 11.45756,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.27753,11.45756",
    "IsOpen24Hours": false,
    "id": "47.27753,11.45756"
  },
  {
    "ID": 141144201,
    "Tankstellennummer": 1025,
    "Name": "eni Stumm/Zillertal",
    "Address": "Zillertaler Schnellstrasse 80",
    "Address__1": "",
    "City": "Stumm",
    "Postcode": 6272,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5283/29242",
    "24 hour?": "TRUE",
    "Latitude": 47.27754,
    "Longitude": 11.88779,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.27754,11.88779",
    "IsOpen24Hours": true,
    "id": "47.27754,11.88779"
  },
  {
    "ID": 357,
    "Tankstellennummer": "0G97S",
    "Name": "Anger, Umfahrungsstr. 4",
    "Address": "Umfahrungstraße 4",
    "Address__1": "STMK",
    "City": "Anger",
    "Postcode": 8184,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 6646384247,
    "24 hour?": "FALSE",
    "Latitude": 47.2788429,
    "Longitude": 15.6936064,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2788429,15.6936064",
    "IsOpen24Hours": false,
    "id": "47.2788429,15.6936064"
  },
  {
    "ID": 459,
    "Tankstellennummer": "0G93T",
    "Name": "Hall in Tirol, Pfannhausstr. 14",
    "Address": "Pfannhausstraße 14",
    "Address__1": "T",
    "City": "Hall In Tirol",
    "Postcode": 6060,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 522345005,
    "24 hour?": "FALSE",
    "Latitude": 47.2793,
    "Longitude": 11.5014,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2793,11.5014",
    "IsOpen24Hours": false,
    "id": "47.2793,11.5014"
  },
  {
    "ID": 9834,
    "Tankstellennummer": "0G95N",
    "Name": "Rankweil, Stiegstr. 63",
    "Address": "Stiegstraße 63",
    "Address__1": "VBG",
    "City": "Rankweil",
    "Postcode": 6830,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 552244581,
    "24 hour?": "FALSE",
    "Latitude": 47.2798,
    "Longitude": 9.64971,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2798,9.64971",
    "IsOpen24Hours": false,
    "id": "47.2798,9.64971"
  },
  {
    "ID": 249404265,
    "Tankstellennummer": 1124,
    "Name": "eni Gutmann Mils",
    "Address": "Bundesstrasse 14",
    "Address__1": "",
    "City": "Mils",
    "Postcode": 6068,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5223/55746",
    "24 hour?": "FALSE",
    "Latitude": 47.28125,
    "Longitude": 11.53412,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.28125,11.53412",
    "IsOpen24Hours": false,
    "id": "47.28125,11.53412"
  },
  {
    "ID": 1345403387,
    "Tankstellennummer": 7068,
    "Name": "Eni Hartberg",
    "Address": "Ressarvarstraße 50",
    "Address__1": "",
    "City": "Hartberg",
    "Postcode": 8230,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3332/62522",
    "24 hour?": "FALSE",
    "Latitude": 47.28287131,
    "Longitude": 15.97567483,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.28287131,15.97567483",
    "IsOpen24Hours": false,
    "id": "47.28287131,15.97567483"
  },
  {
    "ID": 19736,
    "Tankstellennummer": 1640,
    "Name": "Oberwart Grazer Straße 123",
    "Address": "Grazer Straße 123",
    "Address__1": "",
    "City": "Oberwart",
    "Postcode": 7400,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03352 31380",
    "24 hour?": "TRUE",
    "Latitude": 47.28297,
    "Longitude": 16.18523,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.28297,16.18523",
    "IsOpen24Hours": true,
    "id": "47.28297,16.18523"
  },
  {
    "ID": 544214284,
    "Tankstellennummer": 2150,
    "Name": "eni Niedernsill",
    "Address": "Mittersillerstr. 9",
    "Address__1": "",
    "City": "Niedernsill",
    "Postcode": 5722,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)654/88517",
    "24 hour?": "FALSE",
    "Latitude": 47.28363,
    "Longitude": 12.63376,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.28363,12.63376",
    "IsOpen24Hours": false,
    "id": "47.28363,12.63376"
  },
  {
    "ID": 1644617948,
    "Tankstellennummer": 2112,
    "Name": "eni Mittersill",
    "Address": "Zellerstr. 38",
    "Address__1": "",
    "City": "Mittersill",
    "Postcode": 5730,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6562/4406",
    "24 hour?": "FALSE",
    "Latitude": 47.2852,
    "Longitude": 12.4945,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2852,12.4945",
    "IsOpen24Hours": false,
    "id": "47.2852,12.4945"
  },
  {
    "ID": 19444,
    "Tankstellennummer": 9015,
    "Name": "Loipersdorf",
    "Address": "Waldstraße Nr.4",
    "Address__1": "",
    "City": "Markt Allhau",
    "Postcode": 7411,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3359/2670",
    "24 hour?": "TRUE",
    "Latitude": 47.28726,
    "Longitude": 16.06778,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.28726,16.06778",
    "IsOpen24Hours": true,
    "id": "47.28726,16.06778"
  },
  {
    "ID": 1556447573,
    "Tankstellennummer": "A5741",
    "Name": "Rum Dörfer Straße 26b",
    "Address": "Dörfer Straße 26b",
    "Address__1": "",
    "City": "Rum",
    "Postcode": 6064,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.2873,
    "Longitude": 11.46305,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2873,11.46305",
    "IsOpen24Hours": false,
    "id": "47.2873,11.46305"
  },
  {
    "ID": 267363017,
    "Tankstellennummer": 1008,
    "Name": "eni Wattens",
    "Address": "Salzburgerstrasse 26",
    "Address__1": "",
    "City": "Wattens",
    "Postcode": 6112,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5224/52577",
    "24 hour?": "TRUE",
    "Latitude": 47.29203,
    "Longitude": 11.5993,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.29203,11.5993",
    "IsOpen24Hours": true,
    "id": "47.29203,11.5993"
  },
  {
    "ID": 1289846694,
    "Tankstellennummer": "0GH47",
    "Name": "Taxenbach, Bundesstr. 6",
    "Address": "Bundesstraße 6",
    "Address__1": "",
    "City": "Taxenbach",
    "Postcode": 5550,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 65436121,
    "24 hour?": "FALSE",
    "Latitude": 47.2932261,
    "Longitude": 12.9690655,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2932261,12.9690655",
    "IsOpen24Hours": false,
    "id": "47.2932261,12.9690655"
  },
  {
    "ID": 1289285885,
    "Tankstellennummer": 1160,
    "Name": "eni Gutmann Kaltenbach",
    "Address": "Gerlos Bundesstr. 169",
    "Address__1": "",
    "City": "Kaltenbach",
    "Postcode": 6272,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5283/2292",
    "24 hour?": "FALSE",
    "Latitude": 47.2936,
    "Longitude": 11.87525,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2936,11.87525",
    "IsOpen24Hours": false,
    "id": "47.2936,11.87525"
  },
  {
    "ID": 579957685,
    "Tankstellennummer": 2009,
    "Name": "eni Gries/Taxenbach",
    "Address": "Bundesstrasse 311",
    "Address__1": "",
    "City": "Gries/Taxenbach",
    "Postcode": 5662,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6543/8113",
    "24 hour?": "TRUE",
    "Latitude": 47.29578,
    "Longitude": 12.98227,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.29578,12.98227",
    "IsOpen24Hours": true,
    "id": "47.29578,12.98227"
  },
  {
    "ID": 234664623,
    "Tankstellennummer": "0GH48",
    "Name": "Zell am See, Brucker Bundesstr. 57",
    "Address": "Brucker Bundesstraße 57",
    "Address__1": "",
    "City": "Zell am See",
    "Postcode": 5700,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 654253858,
    "24 hour?": "FALSE",
    "Latitude": 47.2968228,
    "Longitude": 12.7960715,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.2968228,12.7960715",
    "IsOpen24Hours": false,
    "id": "47.2968228,12.7960715"
  },
  {
    "ID": 19524,
    "Tankstellennummer": 4502,
    "Name": "Zell am See Brucker Bundesstraße 108",
    "Address": "Brucker Bundesstraße 110",
    "Address__1": "",
    "City": "Zell am See",
    "Postcode": 5700,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "06542 53742",
    "24 hour?": "FALSE",
    "Latitude": 47.29793,
    "Longitude": 12.79454,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.29793,12.79454",
    "IsOpen24Hours": false,
    "id": "47.29793,12.79454"
  },
  {
    "ID": 19773,
    "Tankstellennummer": 1811,
    "Name": "Rechnitz Bahnhofstraße 11a",
    "Address": "Bahnhofstraße 11a",
    "Address__1": "",
    "City": "Rechnitz",
    "Postcode": 7471,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03363 7936918",
    "24 hour?": "FALSE",
    "Latitude": 47.30261,
    "Longitude": 16.44242,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.30261,16.44242",
    "IsOpen24Hours": false,
    "id": "47.30261,16.44242"
  },
  {
    "ID": 820127813,
    "Tankstellennummer": 5011,
    "Name": "Häselgehr Nr. 171",
    "Address": 171,
    "Address__1": "",
    "City": "Häselgehr",
    "Postcode": 6651,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0563/ 46322",
    "24 hour?": "FALSE",
    "Latitude": 47.30322,
    "Longitude": 10.48467,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.30322,10.48467",
    "IsOpen24Hours": false,
    "id": "47.30322,10.48467"
  },
  {
    "ID": 1185865317,
    "Tankstellennummer": "A5742",
    "Name": "Barwies 237",
    "Address": 237,
    "Address__1": "",
    "City": "Barwies",
    "Postcode": 6414,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.30453,
    "Longitude": 10.96999,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.30453,10.96999",
    "IsOpen24Hours": false,
    "id": "47.30453,10.96999"
  },
  {
    "ID": 152957978,
    "Tankstellennummer": 3023,
    "Name": "eni Klaus",
    "Address": "Treietstrasse 25",
    "Address__1": "",
    "City": "Klaus",
    "Postcode": 6833,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5523/62420",
    "24 hour?": "TRUE",
    "Latitude": 47.30529,
    "Longitude": 9.643,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.30529,9.643",
    "IsOpen24Hours": true,
    "id": "47.30529,9.643"
  },
  {
    "ID": 61816312,
    "Tankstellennummer": 9017,
    "Name": "eni Oberwart",
    "Address": "Industriestraße 49",
    "Address__1": "",
    "City": "Oberwart",
    "Postcode": 7400,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/664001",
    "24 hour?": "FALSE",
    "Latitude": 47.30549,
    "Longitude": 16.19296,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.30549,16.19296",
    "IsOpen24Hours": false,
    "id": "47.30549,16.19296"
  },
  {
    "ID": 9854,
    "Tankstellennummer": "0G945",
    "Name": "Telfs, Bundesstr. 1",
    "Address": "Untermarktstraße 61",
    "Address__1": "T",
    "City": "Telfs",
    "Postcode": 6410,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 526262162,
    "24 hour?": "FALSE",
    "Latitude": 47.307517,
    "Longitude": 11.079287,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.307517,11.079287",
    "IsOpen24Hours": false,
    "id": "47.307517,11.079287"
  },
  {
    "ID": 19742,
    "Tankstellennummer": 5601,
    "Name": "Weer Inntal",
    "Address": "Autobahn Schwerpunktparkplatz Weer",
    "Address__1": "",
    "City": "Weer",
    "Postcode": 6114,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05224 67477",
    "24 hour?": "TRUE",
    "Latitude": 47.31835,
    "Longitude": 11.65378,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.31835,11.65378",
    "IsOpen24Hours": true,
    "id": "47.31835,11.65378"
  },
  {
    "ID": 690,
    "Tankstellennummer": "0G959",
    "Name": "Weer, Inntalautobahn Nord",
    "Address": "Inntalautobahn",
    "Address__1": "T",
    "City": "Weer",
    "Postcode": 6114,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 522468388,
    "24 hour?": "TRUE",
    "Latitude": 47.3190793,
    "Longitude": 11.6556023,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.3190793,11.6556023",
    "IsOpen24Hours": true,
    "id": "47.3190793,11.6556023"
  },
  {
    "ID": 19518,
    "Tankstellennummer": 4504,
    "Name": "Schwarzach Salzburger Straße 58",
    "Address": "Salzburger Straße 52",
    "Address__1": "",
    "City": "Schwarzach",
    "Postcode": 5620,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "06415 7415",
    "24 hour?": "FALSE",
    "Latitude": 47.32019,
    "Longitude": 13.14384,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.32019,13.14384",
    "IsOpen24Hours": false,
    "id": "47.32019,13.14384"
  },
  {
    "ID": 19548,
    "Tankstellennummer": 5536,
    "Name": "Seefeld Umfahrung",
    "Address": "Krinz 27",
    "Address__1": "",
    "City": "Reith bei Seefeld",
    "Postcode": 6100,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-5212-2706",
    "24 hour?": "TRUE",
    "Latitude": 47.32402,
    "Longitude": 11.19909,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.32402,11.19909",
    "IsOpen24Hours": true,
    "id": "47.32402,11.19909"
  },
  {
    "ID": 19678,
    "Tankstellennummer": 5574,
    "Name": "Nassereith an der Fernpaßbundesstraße 1",
    "Address": "Fernpaß Bundesstraße 1",
    "Address__1": "",
    "City": "Nassereith",
    "Postcode": 6465,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05265 5898",
    "24 hour?": "TRUE",
    "Latitude": 47.3272,
    "Longitude": 10.8198,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.3272,10.8198",
    "IsOpen24Hours": true,
    "id": "47.3272,10.8198"
  },
  {
    "ID": 204660218,
    "Tankstellennummer": "0GAET",
    "Name": "Seefeld, Foehrenwald 498",
    "Address": "Föhrenwand 498",
    "Address__1": "",
    "City": "Seefeld in Tirol",
    "Postcode": 6100,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 521220567,
    "24 hour?": "FALSE",
    "Latitude": 47.3279656,
    "Longitude": 11.1986662,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3279656,11.1986662",
    "IsOpen24Hours": false,
    "id": "47.3279656,11.1986662"
  },
  {
    "ID": 825158955,
    "Tankstellennummer": "0G91G",
    "Name": "St. Johann, Bundesstrasse 2",
    "Address": "Bundesstraße 2",
    "Address__1": "",
    "City": "St. Johann im Pongau",
    "Postcode": 5600,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083003250,
    "24 hour?": "FALSE",
    "Latitude": 47.334402,
    "Longitude": 13.1893444,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.334402,13.1893444",
    "IsOpen24Hours": false,
    "id": "47.334402,13.1893444"
  },
  {
    "ID": 385077369,
    "Tankstellennummer": 1032,
    "Name": "eni Seefeld",
    "Address": "Münchner Str. 75",
    "Address__1": "",
    "City": "Seefeld",
    "Postcode": 6100,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5212/24070",
    "24 hour?": "FALSE",
    "Latitude": 47.33453,
    "Longitude": 11.19184,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.33453,11.19184",
    "IsOpen24Hours": false,
    "id": "47.33453,11.19184"
  },
  {
    "ID": 819646853,
    "Tankstellennummer": "0GH46",
    "Name": "Wagrain, Markt 130",
    "Address": "Markt 130",
    "Address__1": "",
    "City": "Wagrain",
    "Postcode": 5602,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 64138289,
    "24 hour?": "FALSE",
    "Latitude": 47.3365068,
    "Longitude": 13.3028488,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3365068,13.3028488",
    "IsOpen24Hours": false,
    "id": "47.3365068,13.3028488"
  },
  {
    "ID": 389430986,
    "Tankstellennummer": 1148,
    "Name": "eni Schwaz",
    "Address": "Innsbruckerstrasse 52A",
    "Address__1": "",
    "City": "Schwaz",
    "Postcode": 6130,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5242/62582",
    "24 hour?": "FALSE",
    "Latitude": 47.3387,
    "Longitude": 11.7057,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3387,11.7057",
    "IsOpen24Hours": false,
    "id": "47.3387,11.7057"
  },
  {
    "ID": 9871,
    "Tankstellennummer": "0G94E",
    "Name": "Goetzis, Im Buch 43",
    "Address": "Im Buch 43",
    "Address__1": "VBG",
    "City": "Götzis",
    "Postcode": 6840,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 552362719,
    "24 hour?": "FALSE",
    "Latitude": 47.3397706,
    "Longitude": 9.6521602,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3397706,9.6521602",
    "IsOpen24Hours": false,
    "id": "47.3397706,9.6521602"
  },
  {
    "ID": 635599954,
    "Tankstellennummer": 3112,
    "Name": "eni Gutmann Goetzis",
    "Address": "Im Buch 50",
    "Address__1": "",
    "City": "Goetzis",
    "Postcode": 6840,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5552/62222",
    "24 hour?": "FALSE",
    "Latitude": 47.341032,
    "Longitude": 9.65337,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.341032,9.65337",
    "IsOpen24Hours": false,
    "id": "47.341032,9.65337"
  },
  {
    "ID": 9855,
    "Tankstellennummer": "0G946",
    "Name": "Fuegen im Zillertal, Bundesstr. 271",
    "Address": "Zillertalstraße 35",
    "Address__1": "T",
    "City": "Fügen",
    "Postcode": 6263,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 528862413,
    "24 hour?": "FALSE",
    "Latitude": 47.3454,
    "Longitude": 11.8528,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3454,11.8528",
    "IsOpen24Hours": false,
    "id": "47.3454,11.8528"
  },
  {
    "ID": 19431,
    "Tankstellennummer": 5750,
    "Name": "Vomp Inntalautobahn bei KM 49",
    "Address": "Autobahn A12 (Inntalautobahn) KM 49",
    "Address__1": "",
    "City": "Vomp",
    "Postcode": 6134,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05242 64357",
    "24 hour?": "TRUE",
    "Latitude": 47.347,
    "Longitude": 11.69027,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.347,11.69027",
    "IsOpen24Hours": true,
    "id": "47.347,11.69027"
  },
  {
    "ID": 641,
    "Tankstellennummer": "0G97A",
    "Name": "St. Michael, Bundesstr. 59",
    "Address": "Bundesstraße 59",
    "Address__1": "STMK",
    "City": "St. Michael",
    "Postcode": 8770,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 38435116,
    "24 hour?": "FALSE",
    "Latitude": 47.3503359,
    "Longitude": 15.0085854,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3503359,15.0085854",
    "IsOpen24Hours": false,
    "id": "47.3503359,15.0085854"
  },
  {
    "ID": 19546,
    "Tankstellennummer": 5548,
    "Name": "Schwaz Münchner Straße",
    "Address": "Münchner Straße 24",
    "Address__1": "",
    "City": "Schwaz",
    "Postcode": 6130,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05242 62433",
    "24 hour?": "TRUE",
    "Latitude": 47.35044,
    "Longitude": 11.70603,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.35044,11.70603",
    "IsOpen24Hours": true,
    "id": "47.35044,11.70603"
  },
  {
    "ID": 8952,
    "Tankstellennummer": "0G967",
    "Name": "Maishofen, Zellerstr. 29",
    "Address": "Zellerstraße 29",
    "Address__1": "SBG",
    "City": "Maishofen",
    "Postcode": 5751,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 654268056,
    "24 hour?": "FALSE",
    "Latitude": 47.3507,
    "Longitude": 12.8005,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3507,12.8005",
    "IsOpen24Hours": false,
    "id": "47.3507,12.8005"
  },
  {
    "ID": 1116967749,
    "Tankstellennummer": "A5602",
    "Name": "Fügen im Zillertal Zillertalst",
    "Address": "Zillertalstraße 14",
    "Address__1": "",
    "City": "Fügen im Zillertal",
    "Postcode": 6263,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.35141,
    "Longitude": 11.85149,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.35141,11.85149",
    "IsOpen24Hours": false,
    "id": "47.35141,11.85149"
  },
  {
    "ID": 1868821241,
    "Tankstellennummer": 3024,
    "Name": "eni Altach",
    "Address": "Schweizer Strasse 45",
    "Address__1": "",
    "City": "Altach",
    "Postcode": 6844,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5523/62094",
    "24 hour?": "FALSE",
    "Latitude": 47.35316,
    "Longitude": 9.63469,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.35316,9.63469",
    "IsOpen24Hours": false,
    "id": "47.35316,9.63469"
  },
  {
    "ID": 1824596688,
    "Tankstellennummer": 1136,
    "Name": "eni Gutmann Vomp",
    "Address": "Industriestrasse 12",
    "Address__1": "",
    "City": "Vomp",
    "Postcode": 6134,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5242/62783",
    "24 hour?": "TRUE",
    "Latitude": 47.35317,
    "Longitude": 11.70026,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.35317,11.70026",
    "IsOpen24Hours": true,
    "id": "47.35317,11.70026"
  },
  {
    "ID": 8945,
    "Tankstellennummer": "0G95Z",
    "Name": "Leoben-Goess, Hirschgraben 7",
    "Address": "Hirschgraben",
    "Address__1": "STMK",
    "City": "Leoben-Goess",
    "Postcode": 8707,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 384222194,
    "24 hour?": "TRUE",
    "Latitude": 47.3640164,
    "Longitude": 15.0961791,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.3640164,15.0961791",
    "IsOpen24Hours": true,
    "id": "47.3640164,15.0961791"
  },
  {
    "ID": 1658810174,
    "Tankstellennummer": "A5738",
    "Name": "Leutasch Waidach 373",
    "Address": "Waidach 373",
    "Address__1": "",
    "City": "Leutasch",
    "Postcode": 6105,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.36978,
    "Longitude": 11.16653,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.36978,11.16653",
    "IsOpen24Hours": false,
    "id": "47.36978,11.16653"
  },
  {
    "ID": 9896,
    "Tankstellennummer": "0G96B",
    "Name": "Hohenems, Lustenauer Str. 112A",
    "Address": "Lustenauer Straße 112a",
    "Address__1": "VBG",
    "City": "Hohenems",
    "Postcode": 6845,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 557674262,
    "24 hour?": "TRUE",
    "Latitude": 47.3736,
    "Longitude": 9.67759,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.3736,9.67759",
    "IsOpen24Hours": true,
    "id": "47.3736,9.67759"
  },
  {
    "ID": 1200462954,
    "Tankstellennummer": "A1861",
    "Name": "Pinkafeld Wiener Straße 8",
    "Address": "Wiener Straße 8",
    "Address__1": "",
    "City": "Pinkafeld",
    "Postcode": 7423,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.3744,
    "Longitude": 16.12554,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3744,16.12554",
    "IsOpen24Hours": false,
    "id": "47.3744,16.12554"
  },
  {
    "ID": 3674,
    "Tankstellennummer": "0G98Z",
    "Name": "Jochberg, Nr. 440, Dorf 48",
    "Address": "Dorf 48",
    "Address__1": "T",
    "City": "Jochberg",
    "Postcode": 6373,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 53555297,
    "24 hour?": "FALSE",
    "Latitude": 47.3758,
    "Longitude": 12.419,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3758,12.419",
    "IsOpen24Hours": false,
    "id": "47.3758,12.419"
  },
  {
    "ID": 593,
    "Tankstellennummer": "0G98R",
    "Name": "Radstadt, Tauernstr. 27",
    "Address": "Tauernstraße 27",
    "Address__1": "SBG",
    "City": "Radstadt",
    "Postcode": 5550,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 645230921,
    "24 hour?": "FALSE",
    "Latitude": 47.3791,
    "Longitude": 13.4738,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3791,13.4738",
    "IsOpen24Hours": false,
    "id": "47.3791,13.4738"
  },
  {
    "ID": 802081659,
    "Tankstellennummer": 9007,
    "Name": "eni Pinkafeld",
    "Address": "Wiener Strasse 52",
    "Address__1": "",
    "City": "Pinkafeld",
    "Postcode": 7423,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3357/42581",
    "24 hour?": "TRUE",
    "Latitude": 47.37913,
    "Longitude": 16.12189,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.37913,16.12189",
    "IsOpen24Hours": true,
    "id": "47.37913,16.12189"
  },
  {
    "ID": 1818254620,
    "Tankstellennummer": "0GH52",
    "Name": "Leoben, Ostererweg 2a",
    "Address": "Ostererweg 2a",
    "Address__1": "",
    "City": "Leoben",
    "Postcode": 8700,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 384246179,
    "24 hour?": "FALSE",
    "Latitude": 47.3802672,
    "Longitude": 15.1070368,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3802672,15.1070368",
    "IsOpen24Hours": false,
    "id": "47.3802672,15.1070368"
  },
  {
    "ID": 350,
    "Tankstellennummer": "0G980",
    "Name": "Altenmarkt im Pongau, Hauptstr. 21",
    "Address": "Obere Marktstraße 45",
    "Address__1": "SBG",
    "City": "Altenmarkt im Pongau",
    "Postcode": 5541,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 64525660,
    "24 hour?": "FALSE",
    "Latitude": 47.381305,
    "Longitude": 13.4170064,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.381305,13.4170064",
    "IsOpen24Hours": false,
    "id": "47.381305,13.4170064"
  },
  {
    "ID": 30818273,
    "Tankstellennummer": "0GAEL",
    "Name": "Buch bei Jenbach, St. Margarethen 151b",
    "Address": "St. Margarethen 151B",
    "Address__1": "",
    "City": "Buch in Tirol",
    "Postcode": 6220,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 1522490900,
    "24 hour?": "TRUE",
    "Latitude": 47.383316,
    "Longitude": 11.771831,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.383316,11.771831",
    "IsOpen24Hours": true,
    "id": "47.383316,11.771831"
  },
  {
    "ID": 663123331,
    "Tankstellennummer": 2116,
    "Name": "eni Radstadt",
    "Address": "Salzburgerstrasse  45",
    "Address__1": "",
    "City": "Radstadt",
    "Postcode": 5550,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6452/5546",
    "24 hour?": "FALSE",
    "Latitude": 47.3865,
    "Longitude": 13.4506,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3865,13.4506",
    "IsOpen24Hours": false,
    "id": "47.3865,13.4506"
  },
  {
    "ID": 1268418690,
    "Tankstellennummer": "0GH30",
    "Name": "Radstadt, Salzburger Str. 43",
    "Address": "Salzburger Straße 43",
    "Address__1": "",
    "City": "Radstadt",
    "Postcode": 5550,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 64524384,
    "24 hour?": "FALSE",
    "Latitude": 47.386639,
    "Longitude": 13.450906,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.386639,13.450906",
    "IsOpen24Hours": false,
    "id": "47.386639,13.450906"
  },
  {
    "ID": 510165603,
    "Tankstellennummer": 2016,
    "Name": "eni Radstadt",
    "Address": "Katschberg-Bundesstrasse 34",
    "Address__1": "",
    "City": "Radstadt",
    "Postcode": 5550,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6452/4382",
    "24 hour?": "TRUE",
    "Latitude": 47.38671,
    "Longitude": 13.45209,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.38671,13.45209",
    "IsOpen24Hours": true,
    "id": "47.38671,13.45209"
  },
  {
    "ID": 703472472,
    "Tankstellennummer": 1143,
    "Name": "eni Gutmann Scharnitz",
    "Address": "Innsbruckerstr. 195",
    "Address__1": "",
    "City": "Scharnitz",
    "Postcode": 6108,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5213/20296",
    "24 hour?": "FALSE",
    "Latitude": 47.38696,
    "Longitude": 11.25912,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.38696,11.25912",
    "IsOpen24Hours": false,
    "id": "47.38696,11.25912"
  },
  {
    "ID": 1713889381,
    "Tankstellennummer": 7106,
    "Name": "eni Kammern",
    "Address": "Mühlgraben  22",
    "Address__1": "",
    "City": "Kammern",
    "Postcode": 8773,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3844/8747",
    "24 hour?": "TRUE",
    "Latitude": 47.38843,
    "Longitude": 14.9032,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.38843,14.9032",
    "IsOpen24Hours": true,
    "id": "47.38843,14.9032"
  },
  {
    "ID": 713,
    "Tankstellennummer": "0G943",
    "Name": "Saalbach, Glemmtaler Landesstr. 386",
    "Address": "Glemmtaler Landesstraße 386",
    "Address__1": "SBG",
    "City": "Saalbach",
    "Postcode": 5753,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083003350,
    "24 hour?": "FALSE",
    "Latitude": 47.3890774,
    "Longitude": 12.6423411,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3890774,12.6423411",
    "IsOpen24Hours": false,
    "id": "47.3890774,12.6423411"
  },
  {
    "ID": 531107385,
    "Tankstellennummer": 5013,
    "Name": "Jenbach Austrasse 26",
    "Address": "Austrasse 26",
    "Address__1": "",
    "City": "Jenbach",
    "Postcode": 6200,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 47.38927,
    "Longitude": 11.78557,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.38927,11.78557",
    "IsOpen24Hours": false,
    "id": "47.38927,11.78557"
  },
  {
    "ID": 870111241,
    "Tankstellennummer": 7018,
    "Name": "eni Schladming",
    "Address": "Ennstal-Bundestrasse 213",
    "Address__1": "",
    "City": "Schladming",
    "Postcode": 8970,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3687/24659",
    "24 hour?": "TRUE",
    "Latitude": 47.39032,
    "Longitude": 13.65833,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.39032,13.65833",
    "IsOpen24Hours": true,
    "id": "47.39032,13.65833"
  },
  {
    "ID": 19693,
    "Tankstellennummer": 6512,
    "Name": "Dornbirn Wallenmahd",
    "Address": "Wallenmahd 1",
    "Address__1": "",
    "City": "Dornbirn",
    "Postcode": 6850,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05572 23532",
    "24 hour?": "FALSE",
    "Latitude": 47.39618,
    "Longitude": 9.73126,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.39618,9.73126",
    "IsOpen24Hours": false,
    "id": "47.39618,9.73126"
  },
  {
    "ID": 840997757,
    "Tankstellennummer": 7118,
    "Name": "eni Schladming",
    "Address": "Lehen 20",
    "Address__1": "",
    "City": "Schladming",
    "Postcode": 8970,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3687/22201",
    "24 hour?": "TRUE",
    "Latitude": 47.3967,
    "Longitude": 13.7039,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.3967,13.7039",
    "IsOpen24Hours": true,
    "id": "47.3967,13.7039"
  },
  {
    "ID": 1006633846,
    "Tankstellennummer": 3101,
    "Name": "eni Andelsbuch",
    "Address": "Bersbuch 401",
    "Address__1": "",
    "City": "Andelsbuch",
    "Postcode": 6866,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5512/3214",
    "24 hour?": "FALSE",
    "Latitude": 47.3974,
    "Longitude": 9.85358,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.3974,9.85358",
    "IsOpen24Hours": false,
    "id": "47.3974,9.85358"
  },
  {
    "ID": 1449484840,
    "Tankstellennummer": "A5519",
    "Name": "Strass im Zillertal Bundesstra",
    "Address": "Bundesstraße 107",
    "Address__1": "",
    "City": "Strass im Zillertal",
    "Postcode": 6261,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.39793,
    "Longitude": 11.81625,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.39793,11.81625",
    "IsOpen24Hours": false,
    "id": "47.39793,11.81625"
  },
  {
    "ID": 289364108,
    "Tankstellennummer": 1010,
    "Name": "eni Wiesing",
    "Address": "Dorf 23d",
    "Address__1": "",
    "City": "Wiesing",
    "Postcode": 6200,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5244/61546",
    "24 hour?": "FALSE",
    "Latitude": 47.404,
    "Longitude": 11.80423,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.404,11.80423",
    "IsOpen24Hours": false,
    "id": "47.404,11.80423"
  },
  {
    "ID": 19713,
    "Tankstellennummer": 1776,
    "Name": "Vorau Bahnhofstraße 133",
    "Address": "Bahnhofstraße 133",
    "Address__1": "",
    "City": "Vorau",
    "Postcode": 8250,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03337 3246",
    "24 hour?": "FALSE",
    "Latitude": 47.40488,
    "Longitude": 15.88963,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.40488,15.88963",
    "IsOpen24Hours": false,
    "id": "47.40488,15.88963"
  },
  {
    "ID": 19589,
    "Tankstellennummer": 1606,
    "Name": "Bruck an der Mur Leobner Straße 59",
    "Address": "Leobner Straße 59",
    "Address__1": "",
    "City": "Bruck an der Mur",
    "Postcode": 8600,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03862 51022",
    "24 hour?": "FALSE",
    "Latitude": 47.40623,
    "Longitude": 15.26573,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.40623,15.26573",
    "IsOpen24Hours": false,
    "id": "47.40623,15.26573"
  },
  {
    "ID": 7062,
    "Tankstellennummer": "0G992",
    "Name": "Lermoos, Gries 16",
    "Address": "Gries 16",
    "Address__1": "T",
    "City": "Lermoos",
    "Postcode": 6631,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 56732197,
    "24 hour?": "FALSE",
    "Latitude": 47.4068591,
    "Longitude": 10.8691182,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4068591,10.8691182",
    "IsOpen24Hours": false,
    "id": "47.4068591,10.8691182"
  },
  {
    "ID": 802382645,
    "Tankstellennummer": "A6513",
    "Name": "Dornbirn Lustenauer Straße 24",
    "Address": "Lustenauer Straße 24",
    "Address__1": "",
    "City": "Dornbirn",
    "Postcode": 6850,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.40698,
    "Longitude": 9.73476,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.40698,9.73476",
    "IsOpen24Hours": false,
    "id": "47.40698,9.73476"
  },
  {
    "ID": 1172183815,
    "Tankstellennummer": "0GH49",
    "Name": "Maria Alm, Hochkönigstraße 9",
    "Address": "Hochkönigstraße 9",
    "Address__1": "",
    "City": "Maria Alm",
    "Postcode": 5761,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 65847759,
    "24 hour?": "FALSE",
    "Latitude": 47.4079407,
    "Longitude": 12.8942825,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4079407,12.8942825",
    "IsOpen24Hours": false,
    "id": "47.4079407,12.8942825"
  },
  {
    "ID": 674811238,
    "Tankstellennummer": 3106,
    "Name": "eni Dornbirn",
    "Address": "Lustenauerstrasse 52",
    "Address__1": "",
    "City": "Dornbirn",
    "Postcode": 6850,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5572/25837",
    "24 hour?": "FALSE",
    "Latitude": 47.41054,
    "Longitude": 9.72067,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.41054,9.72067",
    "IsOpen24Hours": false,
    "id": "47.41054,9.72067"
  },
  {
    "ID": 1657663140,
    "Tankstellennummer": 9102,
    "Name": "eni Lockenhaus",
    "Address": "Wienerstr. 28-30",
    "Address__1": "",
    "City": "Lockenhaus",
    "Postcode": 7442,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2616/2253",
    "24 hour?": "FALSE",
    "Latitude": 47.4109,
    "Longitude": 16.4147,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4109,16.4147",
    "IsOpen24Hours": false,
    "id": "47.4109,16.4147"
  },
  {
    "ID": 392,
    "Tankstellennummer": "0GACR",
    "Name": "Dornbirn, Lustenauerstr. 92",
    "Address": "Lustenauerstraße 92",
    "Address__1": "VBG",
    "City": "Dornbirn",
    "Postcode": 6850,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5572394782,
    "24 hour?": "FALSE",
    "Latitude": 47.412667,
    "Longitude": 9.71457,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.412667,9.71457",
    "IsOpen24Hours": false,
    "id": "47.412667,9.71457"
  },
  {
    "ID": 7060,
    "Tankstellennummer": "0G990",
    "Name": "Aurach, Pass Thurn Str. 17",
    "Address": "Pass Thurn Straße 17",
    "Address__1": "T",
    "City": "Aurach",
    "Postcode": 6371,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 53566402016,
    "24 hour?": "FALSE",
    "Latitude": 47.4185,
    "Longitude": 12.4216,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4185,12.4216",
    "IsOpen24Hours": false,
    "id": "47.4185,12.4216"
  },
  {
    "ID": 1027008857,
    "Tankstellennummer": 3020,
    "Name": "eni Lustenau",
    "Address": "Hohenemser Strasse 1",
    "Address__1": "",
    "City": "Lustenau",
    "Postcode": 6890,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5577/83209",
    "24 hour?": "TRUE",
    "Latitude": 47.41895,
    "Longitude": 9.66087,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.41895,9.66087",
    "IsOpen24Hours": true,
    "id": "47.41895,9.66087"
  },
  {
    "ID": 19700,
    "Tankstellennummer": 6717,
    "Name": "Lustenau Grindlstraße 14",
    "Address": "Grindlstraße 14",
    "Address__1": "",
    "City": "Lustenau",
    "Postcode": 6890,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05577 83390",
    "24 hour?": "FALSE",
    "Latitude": 47.42005,
    "Longitude": 9.65871,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.42005,9.65871",
    "IsOpen24Hours": false,
    "id": "47.42005,9.65871"
  },
  {
    "ID": 17890927,
    "Tankstellennummer": "0GH54",
    "Name": "Trofaiach, Hauptstr. 12",
    "Address": "Hauptstraße 12",
    "Address__1": "",
    "City": "Trofaiach",
    "Postcode": 8793,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 38472933,
    "24 hour?": "FALSE",
    "Latitude": 47.4204189,
    "Longitude": 15.0147581,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4204189,15.0147581",
    "IsOpen24Hours": false,
    "id": "47.4204189,15.0147581"
  },
  {
    "ID": 669,
    "Tankstellennummer": "0G988",
    "Name": "Trofaiach, Hauptstr. 9A",
    "Address": "Hauptstraße 9A",
    "Address__1": "STMK",
    "City": "Trofaiach",
    "Postcode": 8793,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 38472879,
    "24 hour?": "FALSE",
    "Latitude": 47.420513,
    "Longitude": 15.0140597,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.420513,15.0140597",
    "IsOpen24Hours": false,
    "id": "47.420513,15.0140597"
  },
  {
    "ID": 373,
    "Tankstellennummer": "0G98D",
    "Name": "Bischofshofen, Salzburgerstr. 70",
    "Address": "Salzburgerstraße 70",
    "Address__1": "SBG",
    "City": "Bischofshofen",
    "Postcode": 5500,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 64622501,
    "24 hour?": "FALSE",
    "Latitude": 47.4229426,
    "Longitude": 13.2163699,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4229426,13.2163699",
    "IsOpen24Hours": false,
    "id": "47.4229426,13.2163699"
  },
  {
    "ID": 1232289868,
    "Tankstellennummer": 2004,
    "Name": "eni Saalfelden",
    "Address": "Zeller Bundesstrasse 1a",
    "Address__1": "",
    "City": "Saalfelden",
    "Postcode": 5760,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6582/73258",
    "24 hour?": "TRUE",
    "Latitude": 47.42299,
    "Longitude": 12.83617,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.42299,12.83617",
    "IsOpen24Hours": true,
    "id": "47.42299,12.83617"
  },
  {
    "ID": 1338196659,
    "Tankstellennummer": "A4535",
    "Name": "Saalfelden Almerstraße 23",
    "Address": "Almerstraße 23",
    "Address__1": "",
    "City": "Saalfelden",
    "Postcode": 5760,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.42305,
    "Longitude": 12.85009,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.42305,12.85009",
    "IsOpen24Hours": false,
    "id": "47.42305,12.85009"
  },
  {
    "ID": 9839,
    "Tankstellennummer": "0G93Z",
    "Name": "Lustenau, Reichshofstr.",
    "Address": "Reichshofstraße",
    "Address__1": "VBG",
    "City": "Lustenau",
    "Postcode": 6890,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 557788184,
    "24 hour?": "FALSE",
    "Latitude": 47.4243142,
    "Longitude": 9.6517039,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4243142,9.6517039",
    "IsOpen24Hours": false,
    "id": "47.4243142,9.6517039"
  },
  {
    "ID": 1932120930,
    "Tankstellennummer": 2104,
    "Name": "eni Gutmann Saalfelden",
    "Address": "Färberstrasse 10",
    "Address__1": "",
    "City": "Saalfelden",
    "Postcode": 5760,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6582/72620",
    "24 hour?": "FALSE",
    "Latitude": 47.42614,
    "Longitude": 12.8403,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.42614,12.8403",
    "IsOpen24Hours": false,
    "id": "47.42614,12.8403"
  },
  {
    "ID": 19692,
    "Tankstellennummer": 6511,
    "Name": "Dornbirn Schwefel 53",
    "Address": "Schwefel 53",
    "Address__1": "",
    "City": "Dornbirn",
    "Postcode": 6850,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05572 25763",
    "24 hour?": "TRUE",
    "Latitude": 47.42804,
    "Longitude": 9.73995,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.42804,9.73995",
    "IsOpen24Hours": true,
    "id": "47.42804,9.73995"
  },
  {
    "ID": 1108483903,
    "Tankstellennummer": 3120,
    "Name": "eni Lustenau",
    "Address": "Maria Theresienstr. 14",
    "Address__1": "",
    "City": "Lustenau",
    "Postcode": 6890,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5577/83066",
    "24 hour?": "FALSE",
    "Latitude": 47.4282,
    "Longitude": 9.65945,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4282,9.65945",
    "IsOpen24Hours": false,
    "id": "47.4282,9.65945"
  },
  {
    "ID": 2134695594,
    "Tankstellennummer": 1107,
    "Name": "eni Gutmann Ehrwald",
    "Address": "Schanz 1A",
    "Address__1": "",
    "City": "Ehrwald",
    "Postcode": 6632,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5673/20073",
    "24 hour?": "FALSE",
    "Latitude": 47.4288,
    "Longitude": 10.9184,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4288,10.9184",
    "IsOpen24Hours": false,
    "id": "47.4288,10.9184"
  },
  {
    "ID": 19511,
    "Tankstellennummer": 4503,
    "Name": "Saalfelden Bundesstraße 2",
    "Address": "Bundesstraße 2",
    "Address__1": "",
    "City": "Saalfelden",
    "Postcode": 5760,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "06582 72272",
    "24 hour?": "FALSE",
    "Latitude": 47.42897,
    "Longitude": 12.84275,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.42897,12.84275",
    "IsOpen24Hours": false,
    "id": "47.42897,12.84275"
  },
  {
    "ID": 1031924103,
    "Tankstellennummer": 3006,
    "Name": "eni Dornbirn",
    "Address": "Schwefel 70a",
    "Address__1": "",
    "City": "Dornbirn",
    "Postcode": 6850,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5572/24326",
    "24 hour?": "FALSE",
    "Latitude": 47.4295,
    "Longitude": 9.73976,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4295,9.73976",
    "IsOpen24Hours": false,
    "id": "47.4295,9.73976"
  },
  {
    "ID": 9875,
    "Tankstellennummer": "0G95X",
    "Name": "Egg im Bregenzerwald, Bundesstr.",
    "Address": "Bundesstraße",
    "Address__1": "VBG",
    "City": "Egg im Bregenzerwald",
    "Postcode": 6863,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 55122530,
    "24 hour?": "FALSE",
    "Latitude": 47.432516,
    "Longitude": 9.895364,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.432516,9.895364",
    "IsOpen24Hours": false,
    "id": "47.432516,9.895364"
  },
  {
    "ID": 1931665547,
    "Tankstellennummer": 1030,
    "Name": "eni Brixlegg",
    "Address": "Innsbruckerstrasse 171",
    "Address__1": "",
    "City": "Brixlegg",
    "Postcode": 6230,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5337/62598",
    "24 hour?": "TRUE",
    "Latitude": 47.43262,
    "Longitude": 11.87866,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.43262,11.87866",
    "IsOpen24Hours": true,
    "id": "47.43262,11.87866"
  },
  {
    "ID": 872333733,
    "Tankstellennummer": 1122,
    "Name": "eni Gutmann Maurach",
    "Address": "Buchau Eden 5",
    "Address__1": "",
    "City": "Maurach",
    "Postcode": 6212,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5243/6113",
    "24 hour?": "FALSE",
    "Latitude": 47.4331,
    "Longitude": 11.7355,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4331,11.7355",
    "IsOpen24Hours": false,
    "id": "47.4331,11.7355"
  },
  {
    "ID": 19529,
    "Tankstellennummer": 5517,
    "Name": "Brixlegg Innsbrucker Straße 34",
    "Address": "Innsbrucker Straße 34",
    "Address__1": "",
    "City": "Brixlegg",
    "Postcode": 6230,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05337 62350",
    "24 hour?": "FALSE",
    "Latitude": 47.43323,
    "Longitude": 11.88036,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.43323,11.88036",
    "IsOpen24Hours": false,
    "id": "47.43323,11.88036"
  },
  {
    "ID": 1881041682,
    "Tankstellennummer": "0GAGG",
    "Name": "T-HEITERWANG, FERNPASSSTRASSE",
    "Address": "Gewerbegebiet 1",
    "Address__1": "",
    "City": "Heiterwang",
    "Postcode": 6611,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 6764135228,
    "24 hour?": "TRUE",
    "Latitude": 47.4366856,
    "Longitude": 10.7662369,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.4366856,10.7662369",
    "IsOpen24Hours": true,
    "id": "47.4366856,10.7662369"
  },
  {
    "ID": 1617703319,
    "Tankstellennummer": "A6551",
    "Name": "Lustenau Bahnhofstraße 8",
    "Address": "Bahnhofstraße 8",
    "Address__1": "",
    "City": "Lustenau",
    "Postcode": 6890,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.43888,
    "Longitude": 9.65664,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.43888,9.65664",
    "IsOpen24Hours": false,
    "id": "47.43888,9.65664"
  },
  {
    "ID": 526422121,
    "Tankstellennummer": "A4508",
    "Name": "Leogang 130",
    "Address": 130,
    "Address__1": "",
    "City": "Leogang",
    "Postcode": 5771,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.43954,
    "Longitude": 12.75824,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.43954,12.75824",
    "IsOpen24Hours": false,
    "id": "47.43954,12.75824"
  },
  {
    "ID": 740304924,
    "Tankstellennummer": 3111,
    "Name": "eni Gutmann Schwarzach",
    "Address": "Gutenbergstrasse 6",
    "Address__1": "",
    "City": "Schwarzach",
    "Postcode": 6858,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)522/776858",
    "24 hour?": "FALSE",
    "Latitude": 47.43971,
    "Longitude": 9.75557,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.43971,9.75557",
    "IsOpen24Hours": false,
    "id": "47.43971,9.75557"
  },
  {
    "ID": 1881828574,
    "Tankstellennummer": "A5751",
    "Name": "Weißenbach am Lech 46",
    "Address": 46,
    "Address__1": "",
    "City": "Weissenbach am Lech",
    "Postcode": 6671,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.44138,
    "Longitude": 10.64229,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.44138,10.64229",
    "IsOpen24Hours": false,
    "id": "47.44138,10.64229"
  },
  {
    "ID": 501411432,
    "Tankstellennummer": 1138,
    "Name": "eni Gutmann Wildschönau",
    "Address": "Oberau 27",
    "Address__1": "",
    "City": "Wildschönau",
    "Postcode": 6311,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5339/8176",
    "24 hour?": "FALSE",
    "Latitude": 47.44457,
    "Longitude": 12.03784,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.44457,12.03784",
    "IsOpen24Hours": false,
    "id": "47.44457,12.03784"
  },
  {
    "ID": 9899,
    "Tankstellennummer": "0G96F",
    "Name": "Brixen im Thale, Brixentalerstr. 13",
    "Address": "Brixentalerstraße 13",
    "Address__1": "T",
    "City": "Brixen im Thale",
    "Postcode": 6364,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 53342880,
    "24 hour?": "FALSE",
    "Latitude": 47.4449,
    "Longitude": 12.2335,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4449,12.2335",
    "IsOpen24Hours": false,
    "id": "47.4449,12.2335"
  },
  {
    "ID": 503139432,
    "Tankstellennummer": 7033,
    "Name": "eni Pinggau",
    "Address": "Gewerbegebiet Süd 2",
    "Address__1": "",
    "City": "Pinggau",
    "Postcode": 8243,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3339/24491",
    "24 hour?": "TRUE",
    "Latitude": 47.446831,
    "Longitude": 16.063269,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.446831,16.063269",
    "IsOpen24Hours": true,
    "id": "47.446831,16.063269"
  },
  {
    "ID": 19724,
    "Tankstellennummer": 1561,
    "Name": "Kapfenberg Wiener Straße 79",
    "Address": "Wiener Straße 79",
    "Address__1": "",
    "City": "Kapfenberg",
    "Postcode": 8605,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-3862-22614",
    "24 hour?": "TRUE",
    "Latitude": 47.44692,
    "Longitude": 15.30208,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.44692,15.30208",
    "IsOpen24Hours": true,
    "id": "47.44692,15.30208"
  },
  {
    "ID": 1183308026,
    "Tankstellennummer": 7051,
    "Name": "eni St.Marein/Mrz",
    "Address": "S 6 Semmering-Schnellstrasse",
    "Address__1": "",
    "City": "St.Marein/Mrz",
    "Postcode": 8641,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3864/3728",
    "24 hour?": "TRUE",
    "Latitude": 47.44806,
    "Longitude": 15.32654,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.44806,15.32654",
    "IsOpen24Hours": true,
    "id": "47.44806,15.32654"
  },
  {
    "ID": 1881590067,
    "Tankstellennummer": 7021,
    "Name": "eni Gröbming",
    "Address": "Hauptstrasse 456",
    "Address__1": "",
    "City": "Gröbming",
    "Postcode": 8962,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3685/22195",
    "24 hour?": "TRUE",
    "Latitude": 47.44907,
    "Longitude": 13.90387,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.44907,13.90387",
    "IsOpen24Hours": true,
    "id": "47.44907,13.90387"
  },
  {
    "ID": 19701,
    "Tankstellennummer": 6507,
    "Name": "Lustenau Hag 27",
    "Address": "Hagstraße 27",
    "Address__1": "",
    "City": "Lustenau",
    "Postcode": 6890,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05577 86782",
    "24 hour?": "FALSE",
    "Latitude": 47.44937,
    "Longitude": 9.66475,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.44937,9.66475",
    "IsOpen24Hours": false,
    "id": "47.44937,9.66475"
  },
  {
    "ID": 19725,
    "Tankstellennummer": 1502,
    "Name": "Kapfenberg-Süd Krottendorf 3b",
    "Address": "Krottendorf 3b",
    "Address__1": "",
    "City": "Kapfenberg",
    "Postcode": 8605,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03862 33825",
    "24 hour?": "TRUE",
    "Latitude": 47.45024,
    "Longitude": 15.33985,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.45024,15.33985",
    "IsOpen24Hours": true,
    "id": "47.45024,15.33985"
  },
  {
    "ID": 19769,
    "Tankstellennummer": 6528,
    "Name": "Alberschwende Hof 978",
    "Address": "Hof 978",
    "Address__1": "",
    "City": "Alberschwende",
    "Postcode": 6861,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05579 85796",
    "24 hour?": "FALSE",
    "Latitude": 47.45076,
    "Longitude": 9.82779,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.45076,9.82779",
    "IsOpen24Hours": false,
    "id": "47.45076,9.82779"
  },
  {
    "ID": 19533,
    "Tankstellennummer": 5545,
    "Name": "Hopfgarten Bahnhofstraße 11",
    "Address": "Bahnhofstraße 11",
    "Address__1": "",
    "City": "Hopfgarten",
    "Postcode": 6361,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05335 3624",
    "24 hour?": "FALSE",
    "Latitude": 47.45309,
    "Longitude": 12.15153,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.45309,12.15153",
    "IsOpen24Hours": false,
    "id": "47.45309,12.15153"
  },
  {
    "ID": 19704,
    "Tankstellennummer": 6509,
    "Name": "Wolfurt Dornbirner Straße 22",
    "Address": "Dornbirner Straße 22",
    "Address__1": "",
    "City": "Wolfurt",
    "Postcode": 6922,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05574 71123",
    "24 hour?": "FALSE",
    "Latitude": 47.4541,
    "Longitude": 9.75495,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4541,9.75495",
    "IsOpen24Hours": false,
    "id": "47.4541,9.75495"
  },
  {
    "ID": 1638664151,
    "Tankstellennummer": 7056,
    "Name": "eni Kapfenberg",
    "Address": "Wienerstraße 132",
    "Address__1": "",
    "City": "Kapfenberg",
    "Postcode": 8605,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/664001",
    "24 hour?": "FALSE",
    "Latitude": 47.45479,
    "Longitude": 15.31747,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.45479,15.31747",
    "IsOpen24Hours": false,
    "id": "47.45479,15.31747"
  },
  {
    "ID": 19676,
    "Tankstellennummer": 5588,
    "Name": "Kitzbühel St.Johanner Straße 15a",
    "Address": "St.Johanner Straße 15a",
    "Address__1": "",
    "City": "Kitzbühel",
    "Postcode": 6370,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05356 67059",
    "24 hour?": "TRUE",
    "Latitude": 47.45609,
    "Longitude": 12.38639,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.45609,12.38639",
    "IsOpen24Hours": true,
    "id": "47.45609,12.38639"
  },
  {
    "ID": 1121863745,
    "Tankstellennummer": "A6504",
    "Name": "Hittisau Windern 379",
    "Address": "Windern 379",
    "Address__1": "",
    "City": "Hittisau",
    "Postcode": 6952,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.45811,
    "Longitude": 9.95489,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.45811,9.95489",
    "IsOpen24Hours": false,
    "id": "47.45811,9.95489"
  },
  {
    "ID": 9911,
    "Tankstellennummer": "0G944",
    "Name": "Imlau 65",
    "Address": "Imlau  65",
    "Address__1": "SBG",
    "City": "Imlau",
    "Postcode": 5452,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 64685327,
    "24 hour?": "FALSE",
    "Latitude": 47.4603427,
    "Longitude": 13.1987387,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4603427,13.1987387",
    "IsOpen24Hours": false,
    "id": "47.4603427,13.1987387"
  },
  {
    "ID": 489,
    "Tankstellennummer": "0G93W",
    "Name": "Kitzbuehel, St. Johannerstr. 30",
    "Address": "St Johannerstraße 30",
    "Address__1": "T",
    "City": "Kitzbühel",
    "Postcode": 6370,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 535664377,
    "24 hour?": "FALSE",
    "Latitude": 47.465024,
    "Longitude": 12.386474,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.465024,12.386474",
    "IsOpen24Hours": false,
    "id": "47.465024,12.386474"
  },
  {
    "ID": 1286767202,
    "Tankstellennummer": "A5534",
    "Name": "Kundl Klammstraße 14",
    "Address": "Klammstraße 14",
    "Address__1": "",
    "City": "Kundl",
    "Postcode": 6250,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.46587,
    "Longitude": 11.98732,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.46587,11.98732",
    "IsOpen24Hours": false,
    "id": "47.46587,11.98732"
  },
  {
    "ID": 525656333,
    "Tankstellennummer": "0G97N",
    "Name": "Kundl, Schieferrollstrasse 17",
    "Address": "Schieferrollstraße 17",
    "Address__1": "",
    "City": "Kundl",
    "Postcode": 6250,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5338861136,
    "24 hour?": "FALSE",
    "Latitude": 47.4671099,
    "Longitude": 11.9900426,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4671099,11.9900426",
    "IsOpen24Hours": false,
    "id": "47.4671099,11.9900426"
  },
  {
    "ID": 9865,
    "Tankstellennummer": "0G95T",
    "Name": "Fussach, Harderstr. 84",
    "Address": "Harderstraße 84",
    "Address__1": "VBG",
    "City": "Fussach",
    "Postcode": 6972,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 557875718,
    "24 hour?": "FALSE",
    "Latitude": 47.4674,
    "Longitude": 9.65825,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4674,9.65825",
    "IsOpen24Hours": false,
    "id": "47.4674,9.65825"
  },
  {
    "ID": 2082460556,
    "Tankstellennummer": 1112,
    "Name": "eni Gutmann Höfen",
    "Address": "Hauptstrasse 20",
    "Address__1": "",
    "City": "Höfen",
    "Postcode": 6600,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5672/64750",
    "24 hour?": "FALSE",
    "Latitude": 47.47572,
    "Longitude": 10.69408,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.47572,10.69408",
    "IsOpen24Hours": false,
    "id": "47.47572,10.69408"
  },
  {
    "ID": 554759401,
    "Tankstellennummer": "A1301",
    "Name": "Unterer",
    "Address": "Luna 34",
    "Address__1": "",
    "City": "Kundl",
    "Postcode": 6250,
    "Country": "Austria",
    "Brand": "UN",
    "Telephone": "+43 5338 8426 25",
    "24 hour?": "FALSE",
    "Latitude": 47.47604,
    "Longitude": 12.018889,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.47604,12.018889",
    "IsOpen24Hours": false,
    "id": "47.47604,12.018889"
  },
  {
    "ID": 1312303954,
    "Tankstellennummer": 1133,
    "Name": "eni Gutmann Reutte",
    "Address": "Kreckelmoosstraße 5",
    "Address__1": "",
    "City": "Reutte",
    "Postcode": 6600,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5672/64365",
    "24 hour?": "FALSE",
    "Latitude": 47.47872,
    "Longitude": 10.73369,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.47872,10.73369",
    "IsOpen24Hours": false,
    "id": "47.47872,10.73369"
  },
  {
    "ID": 488640382,
    "Tankstellennummer": 1110,
    "Name": "eni Fieberbrunn",
    "Address": "Spielbergstrasse 4",
    "Address__1": "",
    "City": "Fieberbrunn",
    "Postcode": 6391,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5354/56310",
    "24 hour?": "FALSE",
    "Latitude": 47.4797,
    "Longitude": 12.5389,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4797,12.5389",
    "IsOpen24Hours": false,
    "id": "47.4797,12.5389"
  },
  {
    "ID": 1563497945,
    "Tankstellennummer": "0GH63",
    "Name": "Schaeffern, Gewerbepark 10",
    "Address": "Gewerbepark 10",
    "Address__1": "",
    "City": "Schäffern",
    "Postcode": 8244,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 333970365,
    "24 hour?": "TRUE",
    "Latitude": 47.480713,
    "Longitude": 16.091953,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.480713,16.091953",
    "IsOpen24Hours": true,
    "id": "47.480713,16.091953"
  },
  {
    "ID": 485605587,
    "Tankstellennummer": 3110,
    "Name": "eni Wolfurt",
    "Address": "Achstrasse 39",
    "Address__1": "",
    "City": "Wolfurt",
    "Postcode": 6922,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5574/72154",
    "24 hour?": "FALSE",
    "Latitude": 47.4816,
    "Longitude": 9.74579,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4816,9.74579",
    "IsOpen24Hours": false,
    "id": "47.4816,9.74579"
  },
  {
    "ID": 254140064,
    "Tankstellennummer": 1016,
    "Name": "eni Itter",
    "Address": "Mühltal 20",
    "Address__1": "",
    "City": "Itter",
    "Postcode": 6305,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5332/23398",
    "24 hour?": "FALSE",
    "Latitude": 47.48211,
    "Longitude": 12.14705,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.48211,12.14705",
    "IsOpen24Hours": false,
    "id": "47.48211,12.14705"
  },
  {
    "ID": 945998704,
    "Tankstellennummer": 3103,
    "Name": "eni Lauterach",
    "Address": "Bundesstr. 18",
    "Address__1": "",
    "City": "Lauterach",
    "Postcode": 6923,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5574/71829",
    "24 hour?": "FALSE",
    "Latitude": 47.4822,
    "Longitude": 9.73162,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4822,9.73162",
    "IsOpen24Hours": false,
    "id": "47.4822,9.73162"
  },
  {
    "ID": 1020681477,
    "Tankstellennummer": 1015,
    "Name": "eni Wörgl",
    "Address": "Innsbrucker Strae 36",
    "Address__1": "",
    "City": "Wrgl",
    "Postcode": 6300,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5332/73910",
    "24 hour?": "TRUE",
    "Latitude": 47.48262,
    "Longitude": 12.05798,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.48262,12.05798",
    "IsOpen24Hours": true,
    "id": "47.48262,12.05798"
  },
  {
    "ID": 403223131,
    "Tankstellennummer": 3007,
    "Name": "eni Bregenz-Hard",
    "Address": "Rheinstrasse",
    "Address__1": "",
    "City": "Bregenz-Hard",
    "Postcode": 6971,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5574/72424",
    "24 hour?": "TRUE",
    "Latitude": 47.4841,
    "Longitude": 9.69251,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.4841,9.69251",
    "IsOpen24Hours": true,
    "id": "47.4841,9.69251"
  },
  {
    "ID": 19746,
    "Tankstellennummer": 6529,
    "Name": "Hard Rheinstraße",
    "Address": "Rheinstraße 24",
    "Address__1": "",
    "City": "Hard",
    "Postcode": 6971,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-5574-61498",
    "24 hour?": "TRUE",
    "Latitude": 47.48752,
    "Longitude": 9.69962,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.48752,9.69962",
    "IsOpen24Hours": true,
    "id": "47.48752,9.69962"
  },
  {
    "ID": 19710,
    "Tankstellennummer": 6521,
    "Name": "Bregenz Arlbergstraße 135",
    "Address": "Arlbergstraße 135",
    "Address__1": "",
    "City": "Bregenz",
    "Postcode": 6900,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05574 71115",
    "24 hour?": "TRUE",
    "Latitude": 47.48778,
    "Longitude": 9.73324,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.48778,9.73324",
    "IsOpen24Hours": true,
    "id": "47.48778,9.73324"
  },
  {
    "ID": 1358890493,
    "Tankstellennummer": 1115,
    "Name": "eni Gutmann Wörgl",
    "Address": "Innsbruckerstrasse - Kreisverkehr 1",
    "Address__1": "",
    "City": "Wörgl",
    "Postcode": 6300,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5332/70696",
    "24 hour?": "FALSE",
    "Latitude": 47.48932,
    "Longitude": 12.06277,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.48932,12.06277",
    "IsOpen24Hours": false,
    "id": "47.48932,12.06277"
  },
  {
    "ID": 1331747943,
    "Tankstellennummer": "A5658",
    "Name": "Wörgl Salzburger Straße 48",
    "Address": "Salzburger Straße 48",
    "Address__1": "",
    "City": "Wörgl",
    "Postcode": 6300,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.49011,
    "Longitude": 12.0728,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.49011,12.0728",
    "IsOpen24Hours": false,
    "id": "47.49011,12.0728"
  },
  {
    "ID": 19707,
    "Tankstellennummer": 5571,
    "Name": "Wörgl Salzburger Straße 55",
    "Address": "Salzburger Straße 55",
    "Address__1": "",
    "City": "Wörgl",
    "Postcode": 6300,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05332 73609",
    "24 hour?": "TRUE",
    "Latitude": 47.49028,
    "Longitude": 12.07354,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.49028,12.07354",
    "IsOpen24Hours": true,
    "id": "47.49028,12.07354"
  },
  {
    "ID": 1119134251,
    "Tankstellennummer": "A6721",
    "Name": "Hard Hofsteigstraße 85",
    "Address": "Hofsteigstraße 85",
    "Address__1": "",
    "City": "Hard",
    "Postcode": 6971,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.49046,
    "Longitude": 9.70295,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.49046,9.70295",
    "IsOpen24Hours": false,
    "id": "47.49046,9.70295"
  },
  {
    "ID": 668,
    "Tankstellennummer": "0G973",
    "Name": "Trieben, Industriepark 2 an der A9/E57",
    "Address": "Industriepark 2",
    "Address__1": "STMK",
    "City": "Trieben",
    "Postcode": 8784,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 36155161,
    "24 hour?": "TRUE",
    "Latitude": 47.4938,
    "Longitude": 14.4829,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.4938,14.4829",
    "IsOpen24Hours": true,
    "id": "47.4938,14.4829"
  },
  {
    "ID": 871106733,
    "Tankstellennummer": 1146,
    "Name": "eni Gutmann Kirchbichl",
    "Address": "Loferer Strasse 28",
    "Address__1": "",
    "City": "Kirchbichl",
    "Postcode": 6322,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5332/73772",
    "24 hour?": "FALSE",
    "Latitude": 47.4945,
    "Longitude": 12.09157,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4945,12.09157",
    "IsOpen24Hours": false,
    "id": "47.4945,12.09157"
  },
  {
    "ID": 495799695,
    "Tankstellennummer": "A5518",
    "Name": "Haldensee Seestraße 5",
    "Address": "Seestraße 5",
    "Address__1": "",
    "City": "Haldensee",
    "Postcode": 6673,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.49479,
    "Longitude": 10.55756,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.49479,10.55756",
    "IsOpen24Hours": false,
    "id": "47.49479,10.55756"
  },
  {
    "ID": 637607831,
    "Tankstellennummer": 3005,
    "Name": "eni Bregenz-Rieden",
    "Address": "Arlbergstrasse 54",
    "Address__1": "",
    "City": "Bregenz-Rieden",
    "Postcode": 6900,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5574/44231",
    "24 hour?": "FALSE",
    "Latitude": 47.49596,
    "Longitude": 9.73402,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.49596,9.73402",
    "IsOpen24Hours": false,
    "id": "47.49596,9.73402"
  },
  {
    "ID": 787541541,
    "Tankstellennummer": 1127,
    "Name": "eni Gutmann Oberndorf",
    "Address": "Bahnhofstrae 11",
    "Address__1": "",
    "City": "Oberndorf",
    "Postcode": 6372,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5352/61515",
    "24 hour?": "TRUE",
    "Latitude": 47.4975,
    "Longitude": 12.3859,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.4975,12.3859",
    "IsOpen24Hours": true,
    "id": "47.4975,12.3859"
  },
  {
    "ID": 2144936271,
    "Tankstellennummer": 3107,
    "Name": "eni Bregenz",
    "Address": "Rheinstrasse 4",
    "Address__1": "",
    "City": "Bregenz",
    "Postcode": 6900,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5574/43760",
    "24 hour?": "FALSE",
    "Latitude": 47.4985,
    "Longitude": 9.73258,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.4985,9.73258",
    "IsOpen24Hours": false,
    "id": "47.4985,9.73258"
  },
  {
    "ID": 1769589842,
    "Tankstellennummer": 1007,
    "Name": "eni Reutte",
    "Address": "Allgäuer Strasse 66",
    "Address__1": "",
    "City": "Reutte",
    "Postcode": 6600,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5672/62846",
    "24 hour?": "TRUE",
    "Latitude": 47.49905,
    "Longitude": 10.72006,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.49905,10.72006",
    "IsOpen24Hours": true,
    "id": "47.49905,10.72006"
  },
  {
    "ID": 19550,
    "Tankstellennummer": 5583,
    "Name": "Tannheim 23",
    "Address": "Höf 14",
    "Address__1": "",
    "City": "Tannheim",
    "Postcode": 6675,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05675 6252",
    "24 hour?": "FALSE",
    "Latitude": 47.49917,
    "Longitude": 10.51679,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.49917,10.51679",
    "IsOpen24Hours": false,
    "id": "47.49917,10.51679"
  },
  {
    "ID": 19650,
    "Tankstellennummer": 8737,
    "Name": "Oberpullendorf Stoob Süd 6",
    "Address": "Stoob Süd 6",
    "Address__1": "",
    "City": "Oberpullendorf",
    "Postcode": 7350,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02612 42982",
    "24 hour?": "FALSE",
    "Latitude": 47.50957,
    "Longitude": 16.50023,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.50957,16.50023",
    "IsOpen24Hours": false,
    "id": "47.50957,16.50023"
  },
  {
    "ID": 379,
    "Tankstellennummer": "0G93X",
    "Name": "Bregenz, Bregenzerstr. 63",
    "Address": "Bregenzerstraße 63",
    "Address__1": "VBG",
    "City": "Bregenz/Lochau",
    "Postcode": 6900,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 557443445,
    "24 hour?": "TRUE",
    "Latitude": 47.5109,
    "Longitude": 9.75296,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.5109,9.75296",
    "IsOpen24Hours": true,
    "id": "47.5109,9.75296"
  },
  {
    "ID": 1828482315,
    "Tankstellennummer": 7071,
    "Name": "eni Kindberg",
    "Address": "Wienerstr. 12",
    "Address__1": "",
    "City": "Kindberg",
    "Postcode": 8650,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3865/2539",
    "24 hour?": "FALSE",
    "Latitude": 47.512083,
    "Longitude": 15.4567279,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.512083,15.4567279",
    "IsOpen24Hours": false,
    "id": "47.512083,15.4567279"
  },
  {
    "ID": 852980614,
    "Tankstellennummer": 1131,
    "Name": "eni Gutmann Schattwald",
    "Address": "Schattwald 29",
    "Address__1": "",
    "City": "Schattwald",
    "Postcode": 6677,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5675/20379",
    "24 hour?": "FALSE",
    "Latitude": 47.5121,
    "Longitude": 10.4628,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.5121,10.4628",
    "IsOpen24Hours": false,
    "id": "47.5121,10.4628"
  },
  {
    "ID": 1943201263,
    "Tankstellennummer": "0G94R",
    "Name": "GOING",
    "Address": "Innsbrucker Straße 5",
    "Address__1": "",
    "City": "Going am Wilden Kaiser",
    "Postcode": 6353,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 535843949,
    "24 hour?": "FALSE",
    "Latitude": 47.5158713,
    "Longitude": 12.3186388,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.5158713,12.3186388",
    "IsOpen24Hours": false,
    "id": "47.5158713,12.3186388"
  },
  {
    "ID": 355,
    "Tankstellennummer": "0G94X",
    "Name": "Angath",
    "Address": "Wirtschaftsweg 4",
    "Address__1": "T",
    "City": "Angath",
    "Postcode": 6321,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 533274371,
    "24 hour?": "TRUE",
    "Latitude": 47.517998,
    "Longitude": 12.067181,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.517998,12.067181",
    "IsOpen24Hours": true,
    "id": "47.517998,12.067181"
  },
  {
    "ID": 19430,
    "Tankstellennummer": 5550,
    "Name": "Angath Inntal-Autobahn",
    "Address": "A12 Inntalautobahn / Wirtschaftsweg",
    "Address__1": "",
    "City": "Angath",
    "Postcode": 6321,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05332 74373",
    "24 hour?": "TRUE",
    "Latitude": 47.5195,
    "Longitude": 12.066,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.5195,12.066",
    "IsOpen24Hours": true,
    "id": "47.5195,12.066"
  },
  {
    "ID": 305041306,
    "Tankstellennummer": 1132,
    "Name": "eni Scheffau-Blaiken",
    "Address": "Schwarzach 6",
    "Address__1": "",
    "City": "Scheffau-Blaiken",
    "Postcode": 6351,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5358/8464",
    "24 hour?": "FALSE",
    "Latitude": 47.5204,
    "Longitude": 12.25374,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.5204,12.25374",
    "IsOpen24Hours": false,
    "id": "47.5204,12.25374"
  },
  {
    "ID": 19530,
    "Tankstellennummer": 5575,
    "Name": "Ellmau Oberachen 13",
    "Address": "Oberachen 13",
    "Address__1": "",
    "City": "Ellmau",
    "Postcode": 6352,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05358 3661",
    "24 hour?": "TRUE",
    "Latitude": 47.52129,
    "Longitude": 12.26891,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.52129,12.26891",
    "IsOpen24Hours": true,
    "id": "47.52129,12.26891"
  },
  {
    "ID": 637,
    "Tankstellennummer": "0G98C",
    "Name": "St. Johann in Tirol, Salzburgerstr. 13",
    "Address": "Salzburgerstraße",
    "Address__1": "T",
    "City": "St. Johann in Tirol",
    "Postcode": 6380,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 535262439,
    "24 hour?": "FALSE",
    "Latitude": 47.5253219,
    "Longitude": 12.4231262,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.5253219,12.4231262",
    "IsOpen24Hours": false,
    "id": "47.5253219,12.4231262"
  },
  {
    "ID": 587396802,
    "Tankstellennummer": 1012,
    "Name": "eni St. Johann",
    "Address": "Salzburger Strasse 23",
    "Address__1": "",
    "City": "St. Johann",
    "Postcode": 6380,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5352/62656",
    "24 hour?": "FALSE",
    "Latitude": 47.52678,
    "Longitude": 12.42438,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.52678,12.42438",
    "IsOpen24Hours": false,
    "id": "47.52678,12.42438"
  },
  {
    "ID": 1812903066,
    "Tankstellennummer": 5509,
    "Name": "Achenkirch Achenseestraße B181",
    "Address": "Achenseestraße B181 B181",
    "Address__1": "",
    "City": "Achenkirch",
    "Postcode": 6215,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05246 6441",
    "24 hour?": "FALSE",
    "Latitude": 47.529,
    "Longitude": 11.708,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.529,11.708",
    "IsOpen24Hours": false,
    "id": "47.529,11.708"
  },
  {
    "ID": 629,
    "Tankstellennummer": "0G98L",
    "Name": "Stainach, Ennstal Bundesstr., Salzburgerstr. 401",
    "Address": "Salzburger Straße 401",
    "Address__1": "STMK",
    "City": "Stainach-Pürgg",
    "Postcode": 8950,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 368222650,
    "24 hour?": "FALSE",
    "Latitude": 47.5324,
    "Longitude": 14.106,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.5324,14.106",
    "IsOpen24Hours": false,
    "id": "47.5324,14.106"
  },
  {
    "ID": 19699,
    "Tankstellennummer": 6718,
    "Name": "Lochau Hofriedenstraße 17",
    "Address": "Hofriedenstraße 17",
    "Address__1": "",
    "City": "Lochau",
    "Postcode": 6911,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05574 43674",
    "24 hour?": "FALSE",
    "Latitude": 47.5347,
    "Longitude": 9.75204,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.5347,9.75204",
    "IsOpen24Hours": false,
    "id": "47.5347,9.75204"
  },
  {
    "ID": 1519270276,
    "Tankstellennummer": 5010,
    "Name": "Kirchdorf in Tirol Innsbrucker",
    "Address": "Innsbrucker Straße 68",
    "Address__1": "",
    "City": "Kirchdorf in Tirol",
    "Postcode": 6382,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "05352 61088",
    "24 hour?": "FALSE",
    "Latitude": 47.5383,
    "Longitude": 12.44099,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.5383,12.44099",
    "IsOpen24Hours": false,
    "id": "47.5383,12.44099"
  },
  {
    "ID": 1752188515,
    "Tankstellennummer": "A5595",
    "Name": "Kirchdorf in Tirol Innsbrucker",
    "Address": "Innsbrucker Straße 51",
    "Address__1": "",
    "City": "Kirchdorf in Tirol",
    "Postcode": 6383,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.54417,
    "Longitude": 12.44904,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.54417,12.44904",
    "IsOpen24Hours": false,
    "id": "47.54417,12.44904"
  },
  {
    "ID": 1397912502,
    "Tankstellennummer": "A5659",
    "Name": "Achenkirch 588",
    "Address": 588,
    "Address__1": "",
    "City": "Achenkirch",
    "Postcode": 6215,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.55139,
    "Longitude": 11.68864,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.55139,11.68864",
    "IsOpen24Hours": false,
    "id": "47.55139,11.68864"
  },
  {
    "ID": 19727,
    "Tankstellennummer": 1577,
    "Name": "Langenwang Schwöbing 70",
    "Address": "Schwöbing 70",
    "Address__1": "",
    "City": "Langenwang",
    "Postcode": 8665,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03854 6125",
    "24 hour?": "TRUE",
    "Latitude": 47.55876,
    "Longitude": 15.59092,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.55876,15.59092",
    "IsOpen24Hours": true,
    "id": "47.55876,15.59092"
  },
  {
    "ID": 1599981119,
    "Tankstellennummer": 7170,
    "Name": "eni Liezen",
    "Address": "Gesäusestrasse 18",
    "Address__1": "",
    "City": "Liezen",
    "Postcode": 8940,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3612/24561",
    "24 hour?": "TRUE",
    "Latitude": 47.55982,
    "Longitude": 14.25324,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.55982,14.25324",
    "IsOpen24Hours": true,
    "id": "47.55982,14.25324"
  },
  {
    "ID": 1543946209,
    "Tankstellennummer": 1013,
    "Name": "eni Kufstein",
    "Address": "Gewerbepark Süd 12",
    "Address__1": "",
    "City": "Kufstein",
    "Postcode": 6330,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5372/61622",
    "24 hour?": "FALSE",
    "Latitude": 47.56626,
    "Longitude": 12.15626,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.56626,12.15626",
    "IsOpen24Hours": false,
    "id": "47.56626,12.15626"
  },
  {
    "ID": 674815371,
    "Tankstellennummer": 2033,
    "Name": "eni Abtenau",
    "Address": "Markt 56",
    "Address__1": "",
    "City": "Abtenau",
    "Postcode": 5441,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6243/20121",
    "24 hour?": "FALSE",
    "Latitude": 47.56645,
    "Longitude": 13.34052,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.56645,13.34052",
    "IsOpen24Hours": false,
    "id": "47.56645,13.34052"
  },
  {
    "ID": 362,
    "Tankstellennummer": "0G97X",
    "Name": "Aspang, Wechselstr. 15",
    "Address": "Wechselstraße 15",
    "Address__1": "NOE",
    "City": "Aspang",
    "Postcode": 2870,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 264252577,
    "24 hour?": "TRUE",
    "Latitude": 47.5673,
    "Longitude": 16.0978,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.5673,16.0978",
    "IsOpen24Hours": true,
    "id": "47.5673,16.0978"
  },
  {
    "ID": 600511576,
    "Tankstellennummer": "0GH58",
    "Name": "Liezen, Salzburger Str. 35",
    "Address": "Salzburgerstraße 35",
    "Address__1": "",
    "City": "Liezen",
    "Postcode": 8940,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 3612237240,
    "24 hour?": "FALSE",
    "Latitude": 47.568336,
    "Longitude": 14.228307,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.568336,14.228307",
    "IsOpen24Hours": false,
    "id": "47.568336,14.228307"
  },
  {
    "ID": 871549777,
    "Tankstellennummer": 1113,
    "Name": "eni Kufstein",
    "Address": "Salurner Str. 28",
    "Address__1": "",
    "City": "Kufstein",
    "Postcode": 6330,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5372/62665",
    "24 hour?": "TRUE",
    "Latitude": 47.5764,
    "Longitude": 12.1604,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.5764,12.1604",
    "IsOpen24Hours": true,
    "id": "47.5764,12.1604"
  },
  {
    "ID": 264202195,
    "Tankstellennummer": "A3573",
    "Name": "Gosau 705",
    "Address": 705,
    "Address__1": "",
    "City": "Gosau",
    "Postcode": 4824,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.57958,
    "Longitude": 13.53156,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.57958,13.53156",
    "IsOpen24Hours": false,
    "id": "47.57958,13.53156"
  },
  {
    "ID": 19750,
    "Tankstellennummer": 1784,
    "Name": "Admont Hall 360",
    "Address": "Hall 360",
    "Address__1": "",
    "City": "Admont",
    "Postcode": 8911,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03613 2224",
    "24 hour?": "FALSE",
    "Latitude": 47.58208,
    "Longitude": 14.45614,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.58208,14.45614",
    "IsOpen24Hours": false,
    "id": "47.58208,14.45614"
  },
  {
    "ID": 671073734,
    "Tankstellennummer": 2102,
    "Name": "eni Golling",
    "Address": "TauernAutobahn, Torren 275",
    "Address__1": "",
    "City": "Golling",
    "Postcode": 5440,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6244/6084",
    "24 hour?": "TRUE",
    "Latitude": 47.5836,
    "Longitude": 13.1565,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.5836,13.1565",
    "IsOpen24Hours": true,
    "id": "47.5836,13.1565"
  },
  {
    "ID": 19433,
    "Tankstellennummer": 4565,
    "Name": "Golling an der Salzach Autobahn Golling Ost",
    "Address": "Autobahn Golling Ost",
    "Address__1": "",
    "City": "Golling an der Salzach",
    "Postcode": 5440,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "06244 6061",
    "24 hour?": "TRUE",
    "Latitude": 47.58396,
    "Longitude": 13.1575,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.58396,13.1575",
    "IsOpen24Hours": true,
    "id": "47.58396,13.1575"
  },
  {
    "ID": 509,
    "Tankstellennummer": "0G94S",
    "Name": "Kufstein, Schubertstr. 25",
    "Address": "Schubertstraße 25",
    "Address__1": "T",
    "City": "Kufstein",
    "Postcode": 6330,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 537262977,
    "24 hour?": "FALSE",
    "Latitude": 47.5853,
    "Longitude": 12.1617,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.5853,12.1617",
    "IsOpen24Hours": false,
    "id": "47.5853,12.1617"
  },
  {
    "ID": 1078685113,
    "Tankstellennummer": "A5048",
    "Name": "Kufstein Schubertstraße 20",
    "Address": "Schubertstraße 20",
    "Address__1": "",
    "City": "Kufstein",
    "Postcode": 6330,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.58636,
    "Longitude": 12.16222,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.58636,12.16222",
    "IsOpen24Hours": false,
    "id": "47.58636,12.16222"
  },
  {
    "ID": 1890806117,
    "Tankstellennummer": "A1210",
    "Name": "Mürzzuschlag Grazer Straße 79f",
    "Address": "Grazer Straße 79f",
    "Address__1": "",
    "City": "Mürzzuschlag",
    "Postcode": 8680,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.58709,
    "Longitude": 15.65981,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.58709,15.65981",
    "IsOpen24Hours": false,
    "id": "47.58709,15.65981"
  },
  {
    "ID": 19538,
    "Tankstellennummer": 5528,
    "Name": "Kufstein Oskar Pirlo-Straße 32",
    "Address": "Oskar Pirlo-Straße 32",
    "Address__1": "",
    "City": "Kufstein",
    "Postcode": 6330,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-5372-64549",
    "24 hour?": "FALSE",
    "Latitude": 47.58913,
    "Longitude": 12.17427,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.58913,12.17427",
    "IsOpen24Hours": false,
    "id": "47.58913,12.17427"
  },
  {
    "ID": 1631485556,
    "Tankstellennummer": 2130,
    "Name": "eni Hettegger Golling",
    "Address": "Obergäu 310",
    "Address__1": "",
    "City": "Golling",
    "Postcode": 5440,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6244/21150",
    "24 hour?": "TRUE",
    "Latitude": 47.59194,
    "Longitude": 13.1754,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.59194,13.1754",
    "IsOpen24Hours": true,
    "id": "47.59194,13.1754"
  },
  {
    "ID": 9893,
    "Tankstellennummer": "0G968",
    "Name": "Kufstein, Rosenheimer Str. 6",
    "Address": "Rosenheimer Straße 6",
    "Address__1": "T",
    "City": "Kufstein",
    "Postcode": 6330,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 537271021,
    "24 hour?": "TRUE",
    "Latitude": 47.5938,
    "Longitude": 12.1773,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.5938,12.1773",
    "IsOpen24Hours": true,
    "id": "47.5938,12.1773"
  },
  {
    "ID": 1343083275,
    "Tankstellennummer": 7072,
    "Name": "eni Mürzzuschlag",
    "Address": "Grazerstr. 44",
    "Address__1": "",
    "City": "Mürzzuschlag",
    "Postcode": 8680,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3852/2548",
    "24 hour?": "FALSE",
    "Latitude": 47.5991755,
    "Longitude": 15.6661072,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.5991755,15.6661072",
    "IsOpen24Hours": false,
    "id": "47.5991755,15.6661072"
  },
  {
    "ID": 945603348,
    "Tankstellennummer": 7066,
    "Name": "eni Spittal am Semmering",
    "Address": "Bundesstraße 38a",
    "Address__1": "",
    "City": "Spittal am Semmering",
    "Postcode": 8684,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/664001",
    "24 hour?": "FALSE",
    "Latitude": 47.61322,
    "Longitude": 15.74718,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.61322,15.74718",
    "IsOpen24Hours": false,
    "id": "47.61322,15.74718"
  },
  {
    "ID": 738211718,
    "Tankstellennummer": 8064,
    "Name": "eni Grimmenstein",
    "Address": "Wechsel-Bundesstrasse 45",
    "Address__1": "",
    "City": "Grimmenstein",
    "Postcode": 2840,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2644/6024",
    "24 hour?": "TRUE",
    "Latitude": 47.61994,
    "Longitude": 16.12818,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.61994,16.12818",
    "IsOpen24Hours": true,
    "id": "47.61994,16.12818"
  },
  {
    "ID": 1322282308,
    "Tankstellennummer": "0GH36",
    "Name": "Bad Goisern, Sankt Agatha 86",
    "Address": "Sankt Agatha 86",
    "Address__1": "",
    "City": "Bad Goisern",
    "Postcode": 4822,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 61358313,
    "24 hour?": "FALSE",
    "Latitude": 47.6238331,
    "Longitude": 13.6454513,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.6238331,13.6454513",
    "IsOpen24Hours": false,
    "id": "47.6238331,13.6454513"
  },
  {
    "ID": 231826345,
    "Tankstellennummer": 1037,
    "Name": "eni Ebbs",
    "Address": "Wildbachlerstrae 52",
    "Address__1": "",
    "City": "Ebbs",
    "Postcode": 6341,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)5373/42384",
    "24 hour?": "FALSE",
    "Latitude": 47.63349,
    "Longitude": 12.21413,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.63349,12.21413",
    "IsOpen24Hours": false,
    "id": "47.63349,12.21413"
  },
  {
    "ID": 28100,
    "Tankstellennummer": "0G97H",
    "Name": "Walchsee,Dorfstrasse 29",
    "Address": "Dorfstraße 29",
    "Address__1": "",
    "City": "Walchsee",
    "Postcode": 6344,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5374562031,
    "24 hour?": "FALSE",
    "Latitude": 47.6497977,
    "Longitude": 12.3132161,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.6497977,12.3132161",
    "IsOpen24Hours": false,
    "id": "47.6497977,12.3132161"
  },
  {
    "ID": 1575058896,
    "Tankstellennummer": 2021,
    "Name": "eni Unken",
    "Address": "Niederland 218",
    "Address__1": "",
    "City": "Unken",
    "Postcode": 5091,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6589/7291",
    "24 hour?": "TRUE",
    "Latitude": 47.65123,
    "Longitude": 12.73452,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.65123,12.73452",
    "IsOpen24Hours": true,
    "id": "47.65123,12.73452"
  },
  {
    "ID": 13423471,
    "Tankstellennummer": 8060,
    "Name": "eni Schottwien",
    "Address": "S 6 Semmering Schnellstr.",
    "Address__1": "",
    "City": "Schottwien",
    "Postcode": 2641,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2663/8872",
    "24 hour?": "TRUE",
    "Latitude": 47.65287,
    "Longitude": 15.87146,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.65287,15.87146",
    "IsOpen24Hours": true,
    "id": "47.65287,15.87146"
  },
  {
    "ID": 19733,
    "Tankstellennummer": 1361,
    "Name": "Neuberg Lahnsattel Bundesstraße",
    "Address": "Lahnsattel Bundesstraße",
    "Address__1": "",
    "City": "Neuberg",
    "Postcode": 8692,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "03857 8274",
    "24 hour?": "FALSE",
    "Latitude": 47.65921,
    "Longitude": 15.58941,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.65921,15.58941",
    "IsOpen24Hours": false,
    "id": "47.65921,15.58941"
  },
  {
    "ID": 28489,
    "Tankstellennummer": "0G97I",
    "Name": "Koessen, Huette 15",
    "Address": "Hütte 15",
    "Address__1": "",
    "City": "Kössen",
    "Postcode": 6345,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 53756394,
    "24 hour?": "FALSE",
    "Latitude": 47.6618416,
    "Longitude": 12.4131225,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.6618416,12.4131225",
    "IsOpen24Hours": false,
    "id": "47.6618416,12.4131225"
  },
  {
    "ID": 235894955,
    "Tankstellennummer": 8156,
    "Name": "eni Scheiblingkirchen",
    "Address": "Bundesstrasse 62",
    "Address__1": "",
    "City": "Scheiblingkirchen",
    "Postcode": 2831,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2629/2237",
    "24 hour?": "FALSE",
    "Latitude": 47.66212299,
    "Longitude": 16.12948449,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.66212299,16.12948449",
    "IsOpen24Hours": false,
    "id": "47.66212299,16.12948449"
  },
  {
    "ID": 783031554,
    "Tankstellennummer": "0G91F",
    "Name": "Unken, Niederland 112",
    "Address": "Niederland 112",
    "Address__1": "",
    "City": "Unken",
    "Postcode": 5091,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083003400,
    "24 hour?": "FALSE",
    "Latitude": 47.665194,
    "Longitude": 12.752882,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.665194,12.752882",
    "IsOpen24Hours": false,
    "id": "47.665194,12.752882"
  },
  {
    "ID": 23905002,
    "Tankstellennummer": 8071,
    "Name": "eni Gloggnitz",
    "Address": "Wienerstr. 87",
    "Address__1": "",
    "City": "Gloggnitz",
    "Postcode": 2640,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2662/42624",
    "24 hour?": "FALSE",
    "Latitude": 47.673024,
    "Longitude": 15.9559421,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.673024,15.9559421",
    "IsOpen24Hours": false,
    "id": "47.673024,15.9559421"
  },
  {
    "ID": 1056440354,
    "Tankstellennummer": 8072,
    "Name": "eni Gloggnitz",
    "Address": "Wienerstr. 35",
    "Address__1": "",
    "City": "Gloggnitz",
    "Postcode": 2640,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2262/42524",
    "24 hour?": "FALSE",
    "Latitude": 47.6734796,
    "Longitude": 15.9459849,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.6734796,15.9459849",
    "IsOpen24Hours": false,
    "id": "47.6734796,15.9459849"
  },
  {
    "ID": 943583569,
    "Tankstellennummer": 2105,
    "Name": "eni Hallein",
    "Address": "Salzachtalstrasse 23",
    "Address__1": "",
    "City": "Hallein",
    "Postcode": 5400,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6245/83029",
    "24 hour?": "FALSE",
    "Latitude": 47.6797,
    "Longitude": 13.1045,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.6797,13.1045",
    "IsOpen24Hours": false,
    "id": "47.6797,13.1045"
  },
  {
    "ID": 597,
    "Tankstellennummer": "0G97R",
    "Name": "Reichenau an der Rax, Hauptstr. 92",
    "Address": "Hauptstraße 92",
    "Address__1": "NOE",
    "City": "Reichenau an der Rax",
    "Postcode": 2651,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 266652424,
    "24 hour?": "FALSE",
    "Latitude": 47.6979763,
    "Longitude": 15.836456,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.6979763,15.836456",
    "IsOpen24Hours": false,
    "id": "47.6979763,15.836456"
  },
  {
    "ID": 47136205,
    "Tankstellennummer": 8074,
    "Name": "eni Wimpassing",
    "Address": "Bundesstr. 2",
    "Address__1": "",
    "City": "Wimpassing",
    "Postcode": 2632,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2630/38778",
    "24 hour?": "FALSE",
    "Latitude": 47.7043021,
    "Longitude": 16.0350379,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7043021,16.0350379",
    "IsOpen24Hours": false,
    "id": "47.7043021,16.0350379"
  },
  {
    "ID": 8955,
    "Tankstellennummer": "0G96C",
    "Name": "Natschbach, Schnellstr. S6",
    "Address": "Schnellstraße S 6",
    "Address__1": "NOE",
    "City": "Natschbach",
    "Postcode": 2620,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 263569714,
    "24 hour?": "TRUE",
    "Latitude": 47.7067365,
    "Longitude": 16.102159,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.7067365,16.102159",
    "IsOpen24Hours": true,
    "id": "47.7067365,16.102159"
  },
  {
    "ID": 19764,
    "Tankstellennummer": 3757,
    "Name": "Windischgarsten Gleinkerseestraße",
    "Address": "Gleinkerseestraße 42",
    "Address__1": "",
    "City": "Windischgarsten",
    "Postcode": 4580,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07562 20756",
    "24 hour?": "TRUE",
    "Latitude": 47.71611,
    "Longitude": 14.31597,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.71611,14.31597",
    "IsOpen24Hours": true,
    "id": "47.71611,14.31597"
  },
  {
    "ID": 1038187110,
    "Tankstellennummer": 2022,
    "Name": "eni Strobl",
    "Address": "Wolfgangseestraße 2",
    "Address__1": "",
    "City": "Strobl",
    "Postcode": 5350,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6137/75752",
    "24 hour?": "TRUE",
    "Latitude": 47.71695,
    "Longitude": 13.48334,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.71695,13.48334",
    "IsOpen24Hours": true,
    "id": "47.71695,13.48334"
  },
  {
    "ID": 47140128,
    "Tankstellennummer": 2023,
    "Name": "eni Puch-Hallein",
    "Address": "Halleiner Strasse 819",
    "Address__1": "",
    "City": "Puch-Hallein",
    "Postcode": 5412,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6245/83127",
    "24 hour?": "TRUE",
    "Latitude": 47.722,
    "Longitude": 13.09018,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.722,13.09018",
    "IsOpen24Hours": true,
    "id": "47.722,13.09018"
  },
  {
    "ID": 502014903,
    "Tankstellennummer": 7079,
    "Name": "eni Altenmarkt bei St. Gallen",
    "Address": "Altenmarkt bei St. Gallen Nr. 81",
    "Address__1": "",
    "City": "Altenmarkt bei St. Gallen",
    "Postcode": 8934,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3632/534",
    "24 hour?": "TRUE",
    "Latitude": 47.72275,
    "Longitude": 14.64959,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.72275,14.64959",
    "IsOpen24Hours": true,
    "id": "47.72275,14.64959"
  },
  {
    "ID": 589803347,
    "Tankstellennummer": 8012,
    "Name": "eni Neunkirchen",
    "Address": "Wienerstraße 94",
    "Address__1": "",
    "City": "Neunkirchen",
    "Postcode": 2620,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2635/62341",
    "24 hour?": "TRUE",
    "Latitude": 47.72502,
    "Longitude": 16.0716,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.72502,16.0716",
    "IsOpen24Hours": true,
    "id": "47.72502,16.0716"
  },
  {
    "ID": 523369894,
    "Tankstellennummer": 8080,
    "Name": "eni Neunkirchen",
    "Address": "Semmeringstr. 4",
    "Address__1": "",
    "City": "Neunkirchen",
    "Postcode": 2620,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2620/62811",
    "24 hour?": "FALSE",
    "Latitude": 47.7261326,
    "Longitude": 16.0731293,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7261326,16.0731293",
    "IsOpen24Hours": false,
    "id": "47.7261326,16.0731293"
  },
  {
    "ID": 1564464312,
    "Tankstellennummer": "0GH03",
    "Name": "Grossgmain, Salzburger Str. 285",
    "Address": "Salzburger Straße 285",
    "Address__1": "",
    "City": "Grossgmain",
    "Postcode": 5084,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 62478071,
    "24 hour?": "FALSE",
    "Latitude": 47.7279005,
    "Longitude": 12.9153066,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7279005,12.9153066",
    "IsOpen24Hours": false,
    "id": "47.7279005,12.9153066"
  },
  {
    "ID": 563503247,
    "Tankstellennummer": 2024,
    "Name": "eni Puch-Hallein",
    "Address": "Urstein Nord 15",
    "Address__1": "",
    "City": "Puch bei Hallein",
    "Postcode": 5412,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6245/21019",
    "24 hour?": "FALSE",
    "Latitude": 47.73315351,
    "Longitude": 13.08560603,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.73315351,13.08560603",
    "IsOpen24Hours": false,
    "id": "47.73315351,13.08560603"
  },
  {
    "ID": 1815100694,
    "Tankstellennummer": "0G91D",
    "Name": "Anif, Alpenstrasse 111",
    "Address": "Alpenstraße 111",
    "Address__1": "",
    "City": "Anif",
    "Postcode": 5081,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 6601349879,
    "24 hour?": "FALSE",
    "Latitude": 47.7362928,
    "Longitude": 13.0507707,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7362928,13.0507707",
    "IsOpen24Hours": false,
    "id": "47.7362928,13.0507707"
  },
  {
    "ID": 573662054,
    "Tankstellennummer": 2011,
    "Name": "eni Anif",
    "Address": "Salzachtal Bundesstrasse 105",
    "Address__1": "",
    "City": "Anif",
    "Postcode": 5081,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6246/72498",
    "24 hour?": "TRUE",
    "Latitude": 47.73769,
    "Longitude": 13.06754,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.73769,13.06754",
    "IsOpen24Hours": true,
    "id": "47.73769,13.06754"
  },
  {
    "ID": 19644,
    "Tankstellennummer": 9539,
    "Name": "Mattersburg Michael Koch-Straße 65",
    "Address": "Michael Koch-Straße 65",
    "Address__1": "",
    "City": "Mattersburg",
    "Postcode": 7210,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-2626-62184",
    "24 hour?": "FALSE",
    "Latitude": 47.74067,
    "Longitude": 16.40867,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.74067,16.40867",
    "IsOpen24Hours": false,
    "id": "47.74067,16.40867"
  },
  {
    "ID": 410,
    "Tankstellennummer": "0GAD4",
    "Name": "Frohsdorf, Wr.Neustaedterstr. 120",
    "Address": "Wiener Neustädter Straße 120",
    "Address__1": "NOE",
    "City": "Frohsdorf",
    "Postcode": 2821,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 800223350,
    "24 hour?": "TRUE",
    "Latitude": 47.7550212,
    "Longitude": 16.2528991,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.7550212,16.2528991",
    "IsOpen24Hours": true,
    "id": "47.7550212,16.2528991"
  },
  {
    "ID": 2123222074,
    "Tankstellennummer": 7115,
    "Name": "eni Mariazell",
    "Address": "Rasing 11",
    "Address__1": "",
    "City": "Mariazell",
    "Postcode": 8630,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)3882/2747",
    "24 hour?": "FALSE",
    "Latitude": 47.76414,
    "Longitude": 15.30968,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.76414,15.30968",
    "IsOpen24Hours": false,
    "id": "47.76414,15.30968"
  },
  {
    "ID": 7074,
    "Tankstellennummer": "0GF08",
    "Name": "Glasenbach-Elsbethen, Joh.Herbst-Str. 15",
    "Address": "Johann-Herbst-Straße 15",
    "Address__1": "",
    "City": "Glasenbach-Elsbethen",
    "Postcode": 5061,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083003100,
    "24 hour?": "FALSE",
    "Latitude": 47.7695606,
    "Longitude": 13.0854034,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7695606,13.0854034",
    "IsOpen24Hours": false,
    "id": "47.7695606,13.0854034"
  },
  {
    "ID": 168977531,
    "Tankstellennummer": "0GH16",
    "Name": "Sankt Pankraz, Sankt Pankraz 70",
    "Address": "Sankt Pankraz 70",
    "Address__1": "",
    "City": "Sankt Pankraz",
    "Postcode": 4572,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 75652340,
    "24 hour?": "TRUE",
    "Latitude": 47.7731262,
    "Longitude": 14.1779956,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.7731262,14.1779956",
    "IsOpen24Hours": true,
    "id": "47.7731262,14.1779956"
  },
  {
    "ID": 19512,
    "Tankstellennummer": 4510,
    "Name": "Salzburg Alpenstraße 106",
    "Address": "Alpenstraße 106",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0662 625985",
    "24 hour?": "TRUE",
    "Latitude": 47.77622,
    "Longitude": 13.06826,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.77622,13.06826",
    "IsOpen24Hours": true,
    "id": "47.77622,13.06826"
  },
  {
    "ID": 1922269733,
    "Tankstellennummer": "A8012",
    "Name": "Wiener Neustadt Neunkirchner S",
    "Address": "Neunkirchner Straße 118",
    "Address__1": "",
    "City": "Wiener Neustadt",
    "Postcode": 2700,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.78134,
    "Longitude": 16.19135,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.78134,16.19135",
    "IsOpen24Hours": false,
    "id": "47.78134,16.19135"
  },
  {
    "ID": 819958775,
    "Tankstellennummer": 2002,
    "Name": "eni Salzburg",
    "Address": "Alpenstrasse 60",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)662/624903",
    "24 hour?": "FALSE",
    "Latitude": 47.78372,
    "Longitude": 13.06697,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.78372,13.06697",
    "IsOpen24Hours": false,
    "id": "47.78372,13.06697"
  },
  {
    "ID": 4225,
    "Tankstellennummer": "0G95O",
    "Name": "Salzburg, Nonntaler Hauptstr. 65",
    "Address": "Nonntaler Hauptstraße 65",
    "Address__1": "SBG",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 662825644,
    "24 hour?": "FALSE",
    "Latitude": 47.7845,
    "Longitude": 13.0525,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7845,13.0525",
    "IsOpen24Hours": false,
    "id": "47.7845,13.0525"
  },
  {
    "ID": 556180922,
    "Tankstellennummer": "0G91E",
    "Name": "Wals, Bundesstrasse 49",
    "Address": "Bundesstraße 49",
    "Address__1": "",
    "City": "Wals",
    "Postcode": 5071,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083001800,
    "24 hour?": "FALSE",
    "Latitude": 47.7857414,
    "Longitude": 12.97552,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7857414,12.97552",
    "IsOpen24Hours": false,
    "id": "47.7857414,12.97552"
  },
  {
    "ID": 900961218,
    "Tankstellennummer": 4605,
    "Name": "Salzburg Nonntaler Hauptstraße",
    "Address": "Nonntaler Hauptstraße 53a",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0662 820939",
    "24 hour?": "FALSE",
    "Latitude": 47.78703,
    "Longitude": 13.05175,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.78703,13.05175",
    "IsOpen24Hours": false,
    "id": "47.78703,13.05175"
  },
  {
    "ID": 653001627,
    "Tankstellennummer": 2026,
    "Name": "eni Salzburg",
    "Address": "Moosstrasse 30",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)662/824825",
    "24 hour?": "FALSE",
    "Latitude": 47.79344,
    "Longitude": 13.0292,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.79344,13.0292",
    "IsOpen24Hours": false,
    "id": "47.79344,13.0292"
  },
  {
    "ID": 1502356015,
    "Tankstellennummer": 8073,
    "Name": "eni Grünbach/Schneeberg",
    "Address": "Wr. Neustädterstraße 18",
    "Address__1": "",
    "City": "Grünbach/Schneeberg",
    "Postcode": 2733,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2637/2758",
    "24 hour?": "FALSE",
    "Latitude": 47.7966409,
    "Longitude": 15.9937141,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.7966409,15.9937141",
    "IsOpen24Hours": false,
    "id": "47.7966409,15.9937141"
  },
  {
    "ID": 19513,
    "Tankstellennummer": 4534,
    "Name": "Salzburg Innsbrucker Bundesstraße 142",
    "Address": "Innsbrucker Bundesstraße 142",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0662 827420",
    "24 hour?": "TRUE",
    "Latitude": 47.79796,
    "Longitude": 13.00609,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.79796,13.00609",
    "IsOpen24Hours": true,
    "id": "47.79796,13.00609"
  },
  {
    "ID": 3567,
    "Tankstellennummer": "0G941",
    "Name": "Salzburg, Aiglhofstr. 20",
    "Address": "Aiglhofstraße 20",
    "Address__1": "SBG",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083003000,
    "24 hour?": "FALSE",
    "Latitude": 47.8034111,
    "Longitude": 13.0286574,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.8034111,13.0286574",
    "IsOpen24Hours": false,
    "id": "47.8034111,13.0286574"
  },
  {
    "ID": 19673,
    "Tankstellennummer": 9503,
    "Name": "Wiener Neustadt Neudörfler Straße 70",
    "Address": "Neudörfler Straße 70",
    "Address__1": "",
    "City": "Wiener Neustadt",
    "Postcode": 2700,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02622 29498",
    "24 hour?": "TRUE",
    "Latitude": 47.8047,
    "Longitude": 16.26798,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.8047,16.26798",
    "IsOpen24Hours": true,
    "id": "47.8047,16.26798"
  },
  {
    "ID": 939269367,
    "Tankstellennummer": 6101,
    "Name": "eni Ebensee",
    "Address": "Umfahrungsstr.",
    "Address__1": "",
    "City": "Ebensee",
    "Postcode": 4802,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6133/7565",
    "24 hour?": "FALSE",
    "Latitude": 47.8063,
    "Longitude": 13.7806,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.8063,13.7806",
    "IsOpen24Hours": false,
    "id": "47.8063,13.7806"
  },
  {
    "ID": 541233570,
    "Tankstellennummer": "A8772",
    "Name": "Wiener Neustadt Puchberger Str",
    "Address": "Puchberger Straße 44",
    "Address__1": "",
    "City": "Wiener Neustadt",
    "Postcode": 2700,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.8065,
    "Longitude": 16.20603,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.8065,16.20603",
    "IsOpen24Hours": false,
    "id": "47.8065,16.20603"
  },
  {
    "ID": 2100192094,
    "Tankstellennummer": 2012,
    "Name": "eni Salzburg",
    "Address": "Schallmooser Hauptstrasse 54",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)662/640034",
    "24 hour?": "TRUE",
    "Latitude": 47.80776,
    "Longitude": 13.06226,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.80776,13.06226",
    "IsOpen24Hours": true,
    "id": "47.80776,13.06226"
  },
  {
    "ID": 1390371728,
    "Tankstellennummer": 2118,
    "Name": "eni Salzburg",
    "Address": "Gabelsbergerstr. 1",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)662/874218",
    "24 hour?": "FALSE",
    "Latitude": 47.8099,
    "Longitude": 13.0469,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.8099,13.0469",
    "IsOpen24Hours": false,
    "id": "47.8099,13.0469"
  },
  {
    "ID": 1933520005,
    "Tankstellennummer": 2117,
    "Name": "eni Salzburg",
    "Address": "Ignaz Harrerstr. 78",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)662/433629",
    "24 hour?": "FALSE",
    "Latitude": 47.8121,
    "Longitude": 13.024,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.8121,13.024",
    "IsOpen24Hours": false,
    "id": "47.8121,13.024"
  },
  {
    "ID": 1933320370,
    "Tankstellennummer": "A3950",
    "Name": "Ebensee Hauptstraße 21",
    "Address": "Hauptstraße 21",
    "Address__1": "",
    "City": "Ebensee",
    "Postcode": 4802,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.81255,
    "Longitude": 13.77331,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.81255,13.77331",
    "IsOpen24Hours": false,
    "id": "47.81255,13.77331"
  },
  {
    "ID": 19748,
    "Tankstellennummer": 4570,
    "Name": "Salzburg Minnesheimerstraße 10",
    "Address": "Minnesheimstraße 10",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5023,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0662 645239",
    "24 hour?": "FALSE",
    "Latitude": 47.81309,
    "Longitude": 13.0695,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.81309,13.0695",
    "IsOpen24Hours": false,
    "id": "47.81309,13.0695"
  },
  {
    "ID": 19517,
    "Tankstellennummer": 4546,
    "Name": "Salzburg Vogelweider Straße 86",
    "Address": "Vogelweider Straße 86",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0662 877402",
    "24 hour?": "FALSE",
    "Latitude": 47.81696,
    "Longitude": 13.05364,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.81696,13.05364",
    "IsOpen24Hours": false,
    "id": "47.81696,13.05364"
  },
  {
    "ID": 18746207,
    "Tankstellennummer": "0G91B",
    "Name": "Hof b. Salzburg, Wolfgangseestr. 9",
    "Address": "Wolfgangseestraße 9",
    "Address__1": "",
    "City": "Hof bei Salzburg",
    "Postcode": 5322,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 43508300,
    "24 hour?": "FALSE",
    "Latitude": 47.8169976,
    "Longitude": 13.2281001,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.8169976,13.2281001",
    "IsOpen24Hours": false,
    "id": "47.8169976,13.2281001"
  },
  {
    "ID": 588313488,
    "Tankstellennummer": 2010,
    "Name": "eni Salzburg-Liefering",
    "Address": "Münchner Bundesstrasse 29",
    "Address__1": "",
    "City": "Salzburg-Liefering",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)662/432125",
    "24 hour?": "TRUE",
    "Latitude": 47.81961,
    "Longitude": 13.01756,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.81961,13.01756",
    "IsOpen24Hours": true,
    "id": "47.81961,13.01756"
  },
  {
    "ID": 19516,
    "Tankstellennummer": 4536,
    "Name": "Salzburg Schiller Straße 28",
    "Address": "Schiller Straße 28",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0662 450124",
    "24 hour?": "FALSE",
    "Latitude": 47.82232,
    "Longitude": 13.03982,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.82232,13.03982",
    "IsOpen24Hours": false,
    "id": "47.82232,13.03982"
  },
  {
    "ID": 622791075,
    "Tankstellennummer": "0GAG8",
    "Name": "Wr. Neustadt, Fischauergasse 124",
    "Address": "Fischauergasse 124",
    "Address__1": "",
    "City": "Wiener Neustadt",
    "Postcode": 2700,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 43262222724,
    "24 hour?": "FALSE",
    "Latitude": 47.822355,
    "Longitude": 16.223539,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.822355,16.223539",
    "IsOpen24Hours": false,
    "id": "47.822355,16.223539"
  },
  {
    "ID": 1916155341,
    "Tankstellennummer": 8146,
    "Name": "eni Wr. Neustadt",
    "Address": "Fischauergasse 34",
    "Address__1": "",
    "City": "Wr. Neustadt",
    "Postcode": 2700,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2622/22884",
    "24 hour?": "FALSE",
    "Latitude": 47.8225,
    "Longitude": 16.2421,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.8225,16.2421",
    "IsOpen24Hours": false,
    "id": "47.8225,16.2421"
  },
  {
    "ID": 647853411,
    "Tankstellennummer": 8046,
    "Name": "eni Wr. Neustadt",
    "Address": "Fischauer Gasse 217",
    "Address__1": "",
    "City": "Wr. Neustadt",
    "Postcode": 2700,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2622/26716",
    "24 hour?": "TRUE",
    "Latitude": 47.82324,
    "Longitude": 16.21538,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.82324,16.21538",
    "IsOpen24Hours": true,
    "id": "47.82324,16.21538"
  },
  {
    "ID": 19515,
    "Tankstellennummer": 4724,
    "Name": "Salzburg Münchner Bundesstraße 85",
    "Address": "Münchner Bundesstraße 85",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0662 435330",
    "24 hour?": "FALSE",
    "Latitude": 47.82773,
    "Longitude": 13.00872,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.82773,13.00872",
    "IsOpen24Hours": false,
    "id": "47.82773,13.00872"
  },
  {
    "ID": 19501,
    "Tankstellennummer": 4007,
    "Name": "Bergheim Berg-Sam 22a",
    "Address": "Berg-Sam 22a",
    "Address__1": "",
    "City": "Bergheim",
    "Postcode": 5101,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0662 664788",
    "24 hour?": "TRUE",
    "Latitude": 47.83065,
    "Longitude": 13.06571,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.83065,13.06571",
    "IsOpen24Hours": true,
    "id": "47.83065,13.06571"
  },
  {
    "ID": 1424187416,
    "Tankstellennummer": "0GH04",
    "Name": "Salzburg, Muenchner Bundesstr. 137",
    "Address": "Münchner Bundesstraße 137",
    "Address__1": "",
    "City": "Salzburg",
    "Postcode": 5020,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 662424285,
    "24 hour?": "TRUE",
    "Latitude": 47.8331674,
    "Longitude": 12.9980373,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.8331674,12.9980373",
    "IsOpen24Hours": true,
    "id": "47.8331674,12.9980373"
  },
  {
    "ID": 19471,
    "Tankstellennummer": 9531,
    "Name": "Eisenstadt Mattersburger Straße 28",
    "Address": "Mattersburger Straße 28",
    "Address__1": "",
    "City": "Eisenstadt",
    "Postcode": 7000,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02682 62034",
    "24 hour?": "FALSE",
    "Latitude": 47.83317,
    "Longitude": 16.52318,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.83317,16.52318",
    "IsOpen24Hours": false,
    "id": "47.83317,16.52318"
  },
  {
    "ID": 19429,
    "Tankstellennummer": 9542,
    "Name": "Bad Fischau Autobahn",
    "Address": "Autobahn A2 (Südautobahn) KM 36,7",
    "Address__1": "",
    "City": "Bad Fischau",
    "Postcode": 2721,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02639 2411",
    "24 hour?": "TRUE",
    "Latitude": 47.83396,
    "Longitude": 16.18183,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.83396,16.18183",
    "IsOpen24Hours": true,
    "id": "47.83396,16.18183"
  },
  {
    "ID": 552766104,
    "Tankstellennummer": 9001,
    "Name": "eni Eisenstadt",
    "Address": "Mattersburger Strasse 20",
    "Address__1": "",
    "City": "Eisenstadt",
    "Postcode": 7000,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2682/63142",
    "24 hour?": "TRUE",
    "Latitude": 47.83627,
    "Longitude": 16.52424,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.83627,16.52424",
    "IsOpen24Hours": true,
    "id": "47.83627,16.52424"
  },
  {
    "ID": 19578,
    "Tankstellennummer": 3749,
    "Name": "Innerschwand Warte am See",
    "Address": "Warte am See 28",
    "Address__1": "",
    "City": "Innerschwand am Mondsee",
    "Postcode": 5311,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "06232 2849",
    "24 hour?": "TRUE",
    "Latitude": 47.83687,
    "Longitude": 13.39455,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.83687,13.39455",
    "IsOpen24Hours": true,
    "id": "47.83687,13.39455"
  },
  {
    "ID": 201338458,
    "Tankstellennummer": 2108,
    "Name": "eni Hallwang",
    "Address": "Autobahnstation Soellheim",
    "Address__1": "",
    "City": "Hallwang",
    "Postcode": 5300,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)6626/61246",
    "24 hour?": "TRUE",
    "Latitude": 47.8385,
    "Longitude": 13.07592,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.8385,13.07592",
    "IsOpen24Hours": true,
    "id": "47.8385,13.07592"
  },
  {
    "ID": 1199558213,
    "Tankstellennummer": "A8654",
    "Name": "Eggendorf Pottendorferstraße 2",
    "Address": "Pottendorferstraße 280",
    "Address__1": "",
    "City": "Eggendorf",
    "Postcode": 2492,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.84106,
    "Longitude": 16.28269,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.84106,16.28269",
    "IsOpen24Hours": false,
    "id": "47.84106,16.28269"
  },
  {
    "ID": 1116659508,
    "Tankstellennummer": "A4518",
    "Name": "Thalgau Salzburger Straße 49",
    "Address": "Salzburger Straße 49",
    "Address__1": "",
    "City": "Thalgau",
    "Postcode": 5303,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.84126,
    "Longitude": 13.24865,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.84126,13.24865",
    "IsOpen24Hours": false,
    "id": "47.84126,13.24865"
  },
  {
    "ID": 271171972,
    "Tankstellennummer": 9014,
    "Name": "eni Frauenkirchen",
    "Address": "Mönchhofer Strasse 10",
    "Address__1": "",
    "City": "Frauenkirchen",
    "Postcode": 7132,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2172/7100",
    "24 hour?": "TRUE",
    "Latitude": 47.84322,
    "Longitude": 16.93024,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.84322,16.93024",
    "IsOpen24Hours": true,
    "id": "47.84322,16.93024"
  },
  {
    "ID": 19638,
    "Tankstellennummer": 8672,
    "Name": "Wiener Neustadt Wiener Straße 126",
    "Address": "Wiener Straße 126",
    "Address__1": "",
    "City": "Wiener Neustadt",
    "Postcode": 2700,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02622 89106",
    "24 hour?": "TRUE",
    "Latitude": 47.84322,
    "Longitude": 16.24631,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.84322,16.24631",
    "IsOpen24Hours": true,
    "id": "47.84322,16.24631"
  },
  {
    "ID": 8960,
    "Tankstellennummer": "0G96L",
    "Name": "Bergheim/Lengfelden, B156",
    "Address": "Lamprechtshausener B156",
    "Address__1": "SBG",
    "City": "Bergheim / Lengfelden",
    "Postcode": 5101,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 662453775,
    "24 hour?": "TRUE",
    "Latitude": 47.8441697,
    "Longitude": 13.0505776,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.8441697,13.0505776",
    "IsOpen24Hours": true,
    "id": "47.8441697,13.0505776"
  },
  {
    "ID": 19579,
    "Tankstellennummer": 3571,
    "Name": "Mondsee Salzburger Straße 8",
    "Address": "Salzburger Straße 8",
    "Address__1": "",
    "City": "Mondsee",
    "Postcode": 5310,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "06232  2583",
    "24 hour?": "FALSE",
    "Latitude": 47.85349,
    "Longitude": 13.34419,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.85349,13.34419",
    "IsOpen24Hours": false,
    "id": "47.85349,13.34419"
  },
  {
    "ID": 778,
    "Tankstellennummer": "0G95L",
    "Name": "Woellersdorf, Autobahnzubringer",
    "Address": "Bundesstraße 21, 406",
    "Address__1": "NOE",
    "City": "Wöllersdorf",
    "Postcode": 2752,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 263342822,
    "24 hour?": "FALSE",
    "Latitude": 47.859765,
    "Longitude": 16.180752,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.859765,16.180752",
    "IsOpen24Hours": false,
    "id": "47.859765,16.180752"
  },
  {
    "ID": 9905,
    "Tankstellennummer": "0G96E",
    "Name": "Eugendorf-Strass, Gewerbestr. 1",
    "Address": "Gewerbestraße 1",
    "Address__1": "SBG",
    "City": "Eugendorf-Strass",
    "Postcode": 5301,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 62252405,
    "24 hour?": "TRUE",
    "Latitude": 47.8616356,
    "Longitude": 13.1284904,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.8616356,13.1284904",
    "IsOpen24Hours": true,
    "id": "47.8616356,13.1284904"
  },
  {
    "ID": 555888312,
    "Tankstellennummer": 2015,
    "Name": "eni Elixhausen",
    "Address": "Mattseer Landstrasse 4",
    "Address__1": "",
    "City": "Elixhausen",
    "Postcode": 5161,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)662/480250",
    "24 hour?": "FALSE",
    "Latitude": 47.86832,
    "Longitude": 13.06688,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.86832,13.06688",
    "IsOpen24Hours": false,
    "id": "47.86832,13.06688"
  },
  {
    "ID": 271849397,
    "Tankstellennummer": 9006,
    "Name": "Hornstein Raststation Süd-Osta",
    "Address": "Raststation Süd-Ostautobahn A3 KM 2",
    "Address__1": "",
    "City": "Hornstein",
    "Postcode": 7053,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02689 208540",
    "24 hour?": "TRUE",
    "Latitude": 47.87799,
    "Longitude": 16.41503,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.87799,16.41503",
    "IsOpen24Hours": true,
    "id": "47.87799,16.41503"
  },
  {
    "ID": 919540839,
    "Tankstellennummer": 8029,
    "Name": "eni Sollenau",
    "Address": "Wr. Neustädter Strasse 101",
    "Address__1": "",
    "City": "Sollenau",
    "Postcode": 2601,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2628/62420",
    "24 hour?": "TRUE",
    "Latitude": 47.88093,
    "Longitude": 16.25044,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.88093,16.25044",
    "IsOpen24Hours": true,
    "id": "47.88093,16.25044"
  },
  {
    "ID": 1020307213,
    "Tankstellennummer": 8021,
    "Name": "eni Pernitz",
    "Address": "Hauptstrasse 11",
    "Address__1": "",
    "City": "Pernitz",
    "Postcode": 2763,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2632/72369",
    "24 hour?": "TRUE",
    "Latitude": 47.89638,
    "Longitude": 15.96825,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.89638,15.96825",
    "IsOpen24Hours": true,
    "id": "47.89638,15.96825"
  },
  {
    "ID": 1626535248,
    "Tankstellennummer": "0G91I",
    "Name": "Seekirchen, Obertrumer Landstrasse 11",
    "Address": "Obertrumer Landstraße 11",
    "Address__1": "",
    "City": "Seekirchen",
    "Postcode": 5201,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 43508300,
    "24 hour?": "FALSE",
    "Latitude": 47.9036543,
    "Longitude": 13.1205386,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9036543,13.1205386",
    "IsOpen24Hours": false,
    "id": "47.9036543,13.1205386"
  },
  {
    "ID": 2138391126,
    "Tankstellennummer": "A9506",
    "Name": "Pottendorf Wiener Straße 8",
    "Address": "Wiener Straße 8",
    "Address__1": "",
    "City": "Pottendorf",
    "Postcode": 2486,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.91283,
    "Longitude": 16.38632,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.91283,16.38632",
    "IsOpen24Hours": false,
    "id": "47.91283,16.38632"
  },
  {
    "ID": 19630,
    "Tankstellennummer": 4552,
    "Name": "Neumarkt am Wallersee Neufahrn 71",
    "Address": "Neufahrn 71",
    "Address__1": "",
    "City": "Neumarkt am Wallersee",
    "Postcode": 5202,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "06216 4748",
    "24 hour?": "TRUE",
    "Latitude": 47.9233,
    "Longitude": 13.2166,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.9233,13.2166",
    "IsOpen24Hours": true,
    "id": "47.9233,13.2166"
  },
  {
    "ID": 19567,
    "Tankstellennummer": 3525,
    "Name": "Gmunden Bahnhofstraße 42",
    "Address": "Bahnhofstraße 42",
    "Address__1": "",
    "City": "Gmunden",
    "Postcode": 4810,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07612 63990",
    "24 hour?": "TRUE",
    "Latitude": 47.92428,
    "Longitude": 13.78782,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.92428,13.78782",
    "IsOpen24Hours": true,
    "id": "47.92428,13.78782"
  },
  {
    "ID": 1649472247,
    "Tankstellennummer": 3045,
    "Name": "St. Georgen im Attergau Seerin",
    "Address": "Seeringstraße 2",
    "Address__1": "",
    "City": "St. Georgen im Attergau",
    "Postcode": 4880,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-7667-80068",
    "24 hour?": "TRUE",
    "Latitude": 47.92917,
    "Longitude": 13.50204,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.92917,13.50204",
    "IsOpen24Hours": true,
    "id": "47.92917,13.50204"
  },
  {
    "ID": 2120116142,
    "Tankstellennummer": "A3537",
    "Name": "Losenstein Meissenedt 3",
    "Address": "Meissenedt 3",
    "Address__1": "",
    "City": "Losenstein",
    "Postcode": 4460,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.92933,
    "Longitude": 14.41353,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.92933,14.41353",
    "IsOpen24Hours": false,
    "id": "47.92933,14.41353"
  },
  {
    "ID": 131572632,
    "Tankstellennummer": 8044,
    "Name": "eni Gaming",
    "Address": "ötscherlandstrasse 48",
    "Address__1": "",
    "City": "Gaming",
    "Postcode": 3292,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7485/97398",
    "24 hour?": "FALSE",
    "Latitude": 47.92944,
    "Longitude": 15.08818,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.92944,15.08818",
    "IsOpen24Hours": false,
    "id": "47.92944,15.08818"
  },
  {
    "ID": 19486,
    "Tankstellennummer": 9519,
    "Name": "Hirtenberg Hainfelder Bundesstraße",
    "Address": "Leobersdorfer Straße 96",
    "Address__1": "",
    "City": "Hirtenberg",
    "Postcode": 2552,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02256 81158",
    "24 hour?": "TRUE",
    "Latitude": 47.93151,
    "Longitude": 16.19569,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.93151,16.19569",
    "IsOpen24Hours": true,
    "id": "47.93151,16.19569"
  },
  {
    "ID": 1239192424,
    "Tankstellennummer": "0G965",
    "Name": "Leobersdorf, Hirtenberger Strasse 4E",
    "Address": "Hirtenberger Straße 4e",
    "Address__1": "",
    "City": "Leobersdorf",
    "Postcode": 2544,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 225664606,
    "24 hour?": "FALSE",
    "Latitude": 47.934682,
    "Longitude": 16.216976,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.934682,16.216976",
    "IsOpen24Hours": false,
    "id": "47.934682,16.216976"
  },
  {
    "ID": 1030243936,
    "Tankstellennummer": 6007,
    "Name": "eni Pinsdorf",
    "Address": "Leitenstrasse 30",
    "Address__1": "",
    "City": "Pinsdorf",
    "Postcode": 4812,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7612/64429",
    "24 hour?": "TRUE",
    "Latitude": 47.93485,
    "Longitude": 13.777,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.93485,13.777",
    "IsOpen24Hours": true,
    "id": "47.93485,13.777"
  },
  {
    "ID": 504421735,
    "Tankstellennummer": "0GH51",
    "Name": "Oberndorf bei Salzburg, Salzburger Str. 4",
    "Address": "Salzburger Straße 4",
    "Address__1": "",
    "City": "Oberndorf bei Salzburg",
    "Postcode": 5110,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 627273182,
    "24 hour?": "FALSE",
    "Latitude": 47.935288,
    "Longitude": 12.946558,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.935288,12.946558",
    "IsOpen24Hours": false,
    "id": "47.935288,12.946558"
  },
  {
    "ID": 19509,
    "Tankstellennummer": 4531,
    "Name": "Obertrum Salzburger Straße 2",
    "Address": "Salzburger Straße 2",
    "Address__1": "",
    "City": "Obertrum",
    "Postcode": 5162,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "06219 6213",
    "24 hour?": "FALSE",
    "Latitude": 47.93554,
    "Longitude": 13.08069,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.93554,13.08069",
    "IsOpen24Hours": false,
    "id": "47.93554,13.08069"
  },
  {
    "ID": 19649,
    "Tankstellennummer": 8537,
    "Name": "Neusiedl am See Weiden",
    "Address": "Parz. Nr. 2164 17-18",
    "Address__1": "",
    "City": "Weiden am See",
    "Postcode": 7100,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02167 2565",
    "24 hour?": "FALSE",
    "Latitude": 47.9375,
    "Longitude": 16.85532,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9375,16.85532",
    "IsOpen24Hours": false,
    "id": "47.9375,16.85532"
  },
  {
    "ID": 19467,
    "Tankstellennummer": 9705,
    "Name": "Breitenbrunn Eisenstädter Straße 69",
    "Address": "Eisenstädter Straße 69",
    "Address__1": "",
    "City": "Breitenbrunn",
    "Postcode": 7091,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02683  7060",
    "24 hour?": "FALSE",
    "Latitude": 47.93942,
    "Longitude": 16.72805,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.93942,16.72805",
    "IsOpen24Hours": false,
    "id": "47.93942,16.72805"
  },
  {
    "ID": 567090991,
    "Tankstellennummer": 9004,
    "Name": "eni Nickelsdorf/Bgl.",
    "Address": "Obere Hauptstrasse 44",
    "Address__1": "",
    "City": "Nickelsdorf",
    "Postcode": 2425,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2146/2246",
    "24 hour?": "TRUE",
    "Latitude": 47.94265,
    "Longitude": 17.06133,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.94265,17.06133",
    "IsOpen24Hours": true,
    "id": "47.94265,17.06133"
  },
  {
    "ID": 9850,
    "Tankstellennummer": "0G95Q",
    "Name": "Berndorf, Leobersdorfer Str. 12",
    "Address": "Leobersdorfer Straße 12",
    "Address__1": "NOE",
    "City": "Berndorf",
    "Postcode": 2560,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 267282579,
    "24 hour?": "FALSE",
    "Latitude": 47.9436,
    "Longitude": 16.1115,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9436,16.1115",
    "IsOpen24Hours": false,
    "id": "47.9436,16.1115"
  },
  {
    "ID": 1091505315,
    "Tankstellennummer": 8116,
    "Name": "eni Weigelsdorf",
    "Address": "Eisenstädter Strasse 19",
    "Address__1": "",
    "City": "Weigelsdorf",
    "Postcode": 2483,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2254/72366",
    "24 hour?": "FALSE",
    "Latitude": 47.94775,
    "Longitude": 16.40919,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.94775,16.40919",
    "IsOpen24Hours": false,
    "id": "47.94775,16.40919"
  },
  {
    "ID": 784,
    "Tankstellennummer": "0G99A",
    "Name": "Ybbsitz, Alte Poststr. 23",
    "Address": "Alte Poststraße 25",
    "Address__1": "NOE",
    "City": "Ybbsitz",
    "Postcode": 3341,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 744386514,
    "24 hour?": "TRUE",
    "Latitude": 47.9486638,
    "Longitude": 14.8964086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.9486638,14.8964086",
    "IsOpen24Hours": true,
    "id": "47.9486638,14.8964086"
  },
  {
    "ID": 19492,
    "Tankstellennummer": 9532,
    "Name": "Kottingbrunn Badener Straße 1",
    "Address": "Badner Straße 1",
    "Address__1": "",
    "City": "Kottingbrunn",
    "Postcode": 2542,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02252 76349",
    "24 hour?": "TRUE",
    "Latitude": 47.9493,
    "Longitude": 16.22221,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.9493,16.22221",
    "IsOpen24Hours": true,
    "id": "47.9493,16.22221"
  },
  {
    "ID": 938898939,
    "Tankstellennummer": 6106,
    "Name": "eni Seewalchen",
    "Address": "Atterseestrasse",
    "Address__1": "",
    "City": "Seewalchen",
    "Postcode": 4863,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7662/2463",
    "24 hour?": "FALSE",
    "Latitude": 47.952,
    "Longitude": 13.5863,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.952,13.5863",
    "IsOpen24Hours": false,
    "id": "47.952,13.5863"
  },
  {
    "ID": 1397760098,
    "Tankstellennummer": "A3956",
    "Name": "Rutzenmoos Autobahnzubringer G",
    "Address": "Autobahnzubringer Gmunden",
    "Address__1": "",
    "City": "Rutzenmoos",
    "Postcode": 4845,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.95502,
    "Longitude": 13.73619,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.95502,13.73619",
    "IsOpen24Hours": false,
    "id": "47.95502,13.73619"
  },
  {
    "ID": 1768523700,
    "Tankstellennummer": 8018,
    "Name": "eni Kottingbrunn",
    "Address": "August-Resch-Strasse 2",
    "Address__1": "",
    "City": "Kottingbrunn",
    "Postcode": 2542,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2252/25164",
    "24 hour?": "TRUE",
    "Latitude": 47.9551,
    "Longitude": 16.21933,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.9551,16.21933",
    "IsOpen24Hours": true,
    "id": "47.9551,16.21933"
  },
  {
    "ID": 2139128514,
    "Tankstellennummer": "A8765",
    "Name": "Pottenstein Bundesstraße 12",
    "Address": "Bundesstraße 12",
    "Address__1": "",
    "City": "Pottenstein",
    "Postcode": 2563,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.95514,
    "Longitude": 16.09516,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.95514,16.09516",
    "IsOpen24Hours": false,
    "id": "47.95514,16.09516"
  },
  {
    "ID": 572,
    "Tankstellennummer": "0G99F",
    "Name": "Neusiedl/See, Wienerstr. 69",
    "Address": "Wienerstraße 69",
    "Address__1": "BGLD",
    "City": "Neusiedl am See",
    "Postcode": 7100,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 2167314412,
    "24 hour?": "TRUE",
    "Latitude": 47.9580858,
    "Longitude": 16.8352404,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.9580858,16.8352404",
    "IsOpen24Hours": true,
    "id": "47.9580858,16.8352404"
  },
  {
    "ID": 19469,
    "Tankstellennummer": 9724,
    "Name": "Ebreichsdorf Wiener Neustädter Straße 2",
    "Address": "Wiener Neustädter Straße  2",
    "Address__1": "",
    "City": "Ebreichsdorf",
    "Postcode": 2483,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02254 72226",
    "24 hour?": "TRUE",
    "Latitude": 47.96042,
    "Longitude": 16.3977,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.96042,16.3977",
    "IsOpen24Hours": true,
    "id": "47.96042,16.3977"
  },
  {
    "ID": 19670,
    "Tankstellennummer": 9557,
    "Name": "Waidhofen an der Ybbs Wiener Straße 2a",
    "Address": "Wiener Straße 2a",
    "Address__1": "",
    "City": "Waidhofen an der Ybbs",
    "Postcode": 3340,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07442 52706",
    "24 hour?": "FALSE",
    "Latitude": 47.96667,
    "Longitude": 14.76931,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.96667,14.76931",
    "IsOpen24Hours": false,
    "id": "47.96667,14.76931"
  },
  {
    "ID": 802180721,
    "Tankstellennummer": "A8753",
    "Name": "Bad Vöslau Badner Straße 11",
    "Address": "Badner Straße 11",
    "Address__1": "",
    "City": "Bad Vöslau",
    "Postcode": 2540,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.96902,
    "Longitude": 16.21304,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.96902,16.21304",
    "IsOpen24Hours": false,
    "id": "47.96902,16.21304"
  },
  {
    "ID": 56937560,
    "Tankstellennummer": "A4006",
    "Name": "Strasswalchen Salzburger Straß",
    "Address": "Salzburger Straße 70",
    "Address__1": "",
    "City": "Strasswalchen",
    "Postcode": 5204,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.96971,
    "Longitude": 13.2461,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.96971,13.2461",
    "IsOpen24Hours": false,
    "id": "47.96971,13.2461"
  },
  {
    "ID": 788975836,
    "Tankstellennummer": 8078,
    "Name": "eni Waidhofen/Ybbs",
    "Address": "Wienerstr. 45a",
    "Address__1": "",
    "City": "Waidhofen/Ybbs",
    "Postcode": 3340,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7442/52562",
    "24 hour?": "FALSE",
    "Latitude": 47.9703474,
    "Longitude": 14.7634458,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9703474,14.7634458",
    "IsOpen24Hours": false,
    "id": "47.9703474,14.7634458"
  },
  {
    "ID": 2136044437,
    "Tankstellennummer": "A8063",
    "Name": "Mannersdorf/Leithagebirge Halt",
    "Address": "Halterzeile 3",
    "Address__1": "",
    "City": "Mannersdorf/Leithagebirge",
    "Postcode": 2452,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.97132,
    "Longitude": 16.59832,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.97132,16.59832",
    "IsOpen24Hours": false,
    "id": "47.97132,16.59832"
  },
  {
    "ID": 1026803470,
    "Tankstellennummer": 8086,
    "Name": "eni Oberwaltersdorf",
    "Address": "Ebreichsdorfer Str. 17b",
    "Address__1": "",
    "City": "Oberwaltersdorf",
    "Postcode": 2522,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2253/9201",
    "24 hour?": "FALSE",
    "Latitude": 47.973359,
    "Longitude": 16.327899,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.973359,16.327899",
    "IsOpen24Hours": false,
    "id": "47.973359,16.327899"
  },
  {
    "ID": 17727,
    "Tankstellennummer": "0G97E",
    "Name": "Regau, Salzkammergutstrasse 8",
    "Address": "Salzkammergut Str. 8",
    "Address__1": "OOE",
    "City": "Regau",
    "Postcode": 4844,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 767275371,
    "24 hour?": "FALSE",
    "Latitude": 47.9841675,
    "Longitude": 13.6975071,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9841675,13.6975071",
    "IsOpen24Hours": false,
    "id": "47.9841675,13.6975071"
  },
  {
    "ID": 1039779438,
    "Tankstellennummer": 6019,
    "Name": "eni Frankenmarkt",
    "Address": "Steinleiten 40",
    "Address__1": "",
    "City": "Frankenmarkt",
    "Postcode": 4890,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7684/6309",
    "24 hour?": "TRUE",
    "Latitude": 47.98556,
    "Longitude": 13.43298,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.98556,13.43298",
    "IsOpen24Hours": true,
    "id": "47.98556,13.43298"
  },
  {
    "ID": 1134590111,
    "Tankstellennummer": "A8024",
    "Name": "Oeynhausen Ebreichsdorfer Stra",
    "Address": "Ebreichsdorfer Straße 28",
    "Address__1": "",
    "City": "Oeynhausen",
    "Postcode": 2512,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 47.98609,
    "Longitude": 16.29075,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.98609,16.29075",
    "IsOpen24Hours": false,
    "id": "47.98609,16.29075"
  },
  {
    "ID": 19506,
    "Tankstellennummer": 4517,
    "Name": "Lamprechtshausen Salzburger Straße 12",
    "Address": "Salzburger Straße 12",
    "Address__1": "",
    "City": "Lamprechtshausen",
    "Postcode": 5112,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "06274 6354",
    "24 hour?": "FALSE",
    "Latitude": 47.98643,
    "Longitude": 12.95445,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.98643,12.95445",
    "IsOpen24Hours": false,
    "id": "47.98643,12.95445"
  },
  {
    "ID": 9886,
    "Tankstellennummer": "0G932",
    "Name": "Strasswalchen, Braunauer Str. 28",
    "Address": "Braunauer Straße 28",
    "Address__1": "SBG",
    "City": "Strasswalchen",
    "Postcode": 5204,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083003150,
    "24 hour?": "FALSE",
    "Latitude": 47.9881,
    "Longitude": 13.2495,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9881,13.2495",
    "IsOpen24Hours": false,
    "id": "47.9881,13.2495"
  },
  {
    "ID": 9861,
    "Tankstellennummer": "0G95S",
    "Name": "Steyrermuehl, Laakirchner Hauptstr. 43",
    "Address": "Laakirchner Hauptstraße 43",
    "Address__1": "OOE",
    "City": "Steyrermühl",
    "Postcode": 4662,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 76132217,
    "24 hour?": "FALSE",
    "Latitude": 47.9893129,
    "Longitude": 13.811732,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9893129,13.811732",
    "IsOpen24Hours": false,
    "id": "47.9893129,13.811732"
  },
  {
    "ID": 1426092169,
    "Tankstellennummer": "0GH19",
    "Name": "Voecklamarkt, Frankenburger Str. 1a",
    "Address": "Frankenburger Straße 1a",
    "Address__1": "",
    "City": "Vöcklamarkt",
    "Postcode": 4870,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 76823728,
    "24 hour?": "FALSE",
    "Latitude": 47.9953014,
    "Longitude": 13.4889876,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.9953014,13.4889876",
    "IsOpen24Hours": false,
    "id": "47.9953014,13.4889876"
  },
  {
    "ID": 1432732079,
    "Tankstellennummer": "0GH32",
    "Name": "Traiskirchen, Wiener Neustaedter Str. 134",
    "Address": "Wiener Neustädter Str. 134",
    "Address__1": "",
    "City": "Traiskirchen",
    "Postcode": 2512,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 2252259140,
    "24 hour?": "TRUE",
    "Latitude": 47.996178,
    "Longitude": 16.298568,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.996178,16.298568",
    "IsOpen24Hours": true,
    "id": "47.996178,16.298568"
  },
  {
    "ID": 1929520982,
    "Tankstellennummer": 8039,
    "Name": "eni Oeynhausen",
    "Address": "Wiener Neustadter Bundesstr., 135",
    "Address__1": "",
    "City": "Traiskirchen",
    "Postcode": 2512,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2252/84631",
    "24 hour?": "TRUE",
    "Latitude": 47.99723,
    "Longitude": 16.29984,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "47.99723,16.29984",
    "IsOpen24Hours": true,
    "id": "47.99723,16.29984"
  },
  {
    "ID": 19466,
    "Tankstellennummer": 9538,
    "Name": "Baden Haidhofstraße 4",
    "Address": "Haidhofstraße 4",
    "Address__1": "",
    "City": "Baden",
    "Postcode": 2500,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02252 88974",
    "24 hour?": "FALSE",
    "Latitude": 47.99769,
    "Longitude": 16.25102,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "47.99769,16.25102",
    "IsOpen24Hours": false,
    "id": "47.99769,16.25102"
  },
  {
    "ID": 1913045378,
    "Tankstellennummer": 8113,
    "Name": "eni Tribuswinkel",
    "Address": "Oeynhausner Strasse 55",
    "Address__1": "",
    "City": "Tribuswinkel",
    "Postcode": 2512,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2252/80317",
    "24 hour?": "FALSE",
    "Latitude": 48.00083,
    "Longitude": 16.26912,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.00083,16.26912",
    "IsOpen24Hours": false,
    "id": "48.00083,16.26912"
  },
  {
    "ID": 654,
    "Tankstellennummer": "0G99H",
    "Name": "St. Ulrich/Garsten, Eisenstr. 85",
    "Address": "Eisenstraße 85",
    "Address__1": "OOE",
    "City": "St. Ulrich/Garsten",
    "Postcode": 4451,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 725254161,
    "24 hour?": "FALSE",
    "Latitude": 48.0011,
    "Longitude": 14.4104,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0011,14.4104",
    "IsOpen24Hours": false,
    "id": "48.0011,14.4104"
  },
  {
    "ID": 738290012,
    "Tankstellennummer": 8014,
    "Name": "eni Baden",
    "Address": "Braitnerstrasse 62A",
    "Address__1": "",
    "City": "Baden",
    "Postcode": 2500,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2252/87949",
    "24 hour?": "FALSE",
    "Latitude": 48.00204,
    "Longitude": 16.24167,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.00204,16.24167",
    "IsOpen24Hours": false,
    "id": "48.00204,16.24167"
  },
  {
    "ID": 265249670,
    "Tankstellennummer": 6109,
    "Name": "eni Vöcklabruck",
    "Address": "Linzerstr. 12",
    "Address__1": "",
    "City": "Vöcklabruck",
    "Postcode": 4840,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7672/72350",
    "24 hour?": "FALSE",
    "Latitude": 48.00338,
    "Longitude": 13.65882,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.00338,13.65882",
    "IsOpen24Hours": false,
    "id": "48.00338,13.65882"
  },
  {
    "ID": 471898174,
    "Tankstellennummer": 6003,
    "Name": "eni Vöcklabruck",
    "Address": "Salzburger Strasse 42",
    "Address__1": "",
    "City": "Vöcklabruck",
    "Postcode": 4840,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7672/23278",
    "24 hour?": "TRUE",
    "Latitude": 48.00389,
    "Longitude": 13.63893,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.00389,13.63893",
    "IsOpen24Hours": true,
    "id": "48.00389,13.63893"
  },
  {
    "ID": 526,
    "Tankstellennummer": "0G972",
    "Name": "Lindach/Autobahn, Westautobahn A1",
    "Address": "Westautobahn A1",
    "Address__1": "OOE",
    "City": "Lindach",
    "Postcode": 4663,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 76138460,
    "24 hour?": "TRUE",
    "Latitude": 48.0040507,
    "Longitude": 13.8513135,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.0040507,13.8513135",
    "IsOpen24Hours": true,
    "id": "48.0040507,13.8513135"
  },
  {
    "ID": 19605,
    "Tankstellennummer": 3584,
    "Name": "Laakirchen Autobahn Lindach Nord",
    "Address": "Reiterholz 3",
    "Address__1": "",
    "City": "Laakirchen",
    "Postcode": 4663,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07613 3510",
    "24 hour?": "TRUE",
    "Latitude": 48.00466,
    "Longitude": 13.85405,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.00466,13.85405",
    "IsOpen24Hours": true,
    "id": "48.00466,13.85405"
  },
  {
    "ID": 19562,
    "Tankstellennummer": 3714,
    "Name": "Attnang-Puchheim Salzburger Straße 91",
    "Address": "Salzburger Straße 91",
    "Address__1": "",
    "City": "Attnang-Puchheim",
    "Postcode": 4800,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07674 64990",
    "24 hour?": "TRUE",
    "Latitude": 48.00541,
    "Longitude": 13.70937,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.00541,13.70937",
    "IsOpen24Hours": true,
    "id": "48.00541,13.70937"
  },
  {
    "ID": 647300267,
    "Tankstellennummer": 6072,
    "Name": "eni Laakirchen",
    "Address": "Johannes Humer Straße",
    "Address__1": "",
    "City": "Laakirchen",
    "Postcode": 4663,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/664001",
    "24 hour?": "TRUE",
    "Latitude": 48.00553,
    "Longitude": 13.84661,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.00553,13.84661",
    "IsOpen24Hours": true,
    "id": "48.00553,13.84661"
  },
  {
    "ID": 808355455,
    "Tankstellennummer": 8013,
    "Name": "eni Traiskirchen",
    "Address": "Wr. Neustädter Strasse 28",
    "Address__1": "",
    "City": "Traiskirchen",
    "Postcode": 2514,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2252/52454",
    "24 hour?": "TRUE",
    "Latitude": 48.00817,
    "Longitude": 16.26397,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.00817,16.26397",
    "IsOpen24Hours": true,
    "id": "48.00817,16.26397"
  },
  {
    "ID": 9903,
    "Tankstellennummer": "0G96K",
    "Name": "Vorchdorf, Neue Landstr. 68",
    "Address": "Neue Landstraße 68",
    "Address__1": "OOE",
    "City": "Vorchdorf",
    "Postcode": 4655,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 76145360,
    "24 hour?": "FALSE",
    "Latitude": 48.0083,
    "Longitude": 13.9233,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0083,13.9233",
    "IsOpen24Hours": false,
    "id": "48.0083,13.9233"
  },
  {
    "ID": 19772,
    "Tankstellennummer": 6024,
    "Name": "Vorchdorf",
    "Address": "Fachmarktstrasse/Gewerbepark",
    "Address__1": "",
    "City": "Vorchdorf",
    "Postcode": 4655,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7614/51845",
    "24 hour?": "TRUE",
    "Latitude": 48.01068,
    "Longitude": 13.92471,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.01068,13.92471",
    "IsOpen24Hours": true,
    "id": "48.01068,13.92471"
  },
  {
    "ID": 1944506011,
    "Tankstellennummer": 9154,
    "Name": "eni Bruckneudorf",
    "Address": "Parndorferstr. 103",
    "Address__1": "",
    "City": "Bruckneudorf",
    "Postcode": 2460,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2162/62434",
    "24 hour?": "FALSE",
    "Latitude": 48.021,
    "Longitude": 16.7839,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.021,16.7839",
    "IsOpen24Hours": false,
    "id": "48.021,16.7839"
  },
  {
    "ID": 9836,
    "Tankstellennummer": "0G93Y",
    "Name": "Traiskirchen, Wiener Str. 58",
    "Address": "Wiener Straße 58",
    "Address__1": "NOE",
    "City": "Traiskirchen",
    "Postcode": 2514,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 225252359,
    "24 hour?": "TRUE",
    "Latitude": 48.021232,
    "Longitude": 16.297963,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.021232,16.297963",
    "IsOpen24Hours": true,
    "id": "48.021232,16.297963"
  },
  {
    "ID": 848118727,
    "Tankstellennummer": "A8754",
    "Name": "Bruck / Leitha Alte Wiener Str",
    "Address": "Alte Wiener Straße 57",
    "Address__1": "",
    "City": "Bruck / Leitha",
    "Postcode": 2460,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.02141,
    "Longitude": 16.76646,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.02141,16.76646",
    "IsOpen24Hours": false,
    "id": "48.02141,16.76646"
  },
  {
    "ID": 19609,
    "Tankstellennummer": 8584,
    "Name": "Kematen Waidhofner Straße (Hilm) 23",
    "Address": "Waidhofner Straße 23",
    "Address__1": "",
    "City": "Hilm",
    "Postcode": 3331,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07448  2252",
    "24 hour?": "TRUE",
    "Latitude": 48.02154,
    "Longitude": 14.75676,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.02154,14.75676",
    "IsOpen24Hours": true,
    "id": "48.02154,14.75676"
  },
  {
    "ID": 405006304,
    "Tankstellennummer": "0GH34",
    "Name": "O-LOCHEN AM SEE, KERSCHHAM 104",
    "Address": "Kerschham 104",
    "Address__1": "",
    "City": "Lochen am See",
    "Postcode": 5221,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 774720338,
    "24 hour?": "FALSE",
    "Latitude": 48.027455,
    "Longitude": 13.14086,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.027455,13.14086",
    "IsOpen24Hours": false,
    "id": "48.027455,13.14086"
  },
  {
    "ID": 586285940,
    "Tankstellennummer": 8054,
    "Name": "eni Bruck/Leitha",
    "Address": "Bundesstrasse 10",
    "Address__1": "",
    "City": "Bruck/Leitha",
    "Postcode": 2460,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2162/62457",
    "24 hour?": "FALSE",
    "Latitude": 48.02955,
    "Longitude": 16.71775,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.02955,16.71775",
    "IsOpen24Hours": false,
    "id": "48.02955,16.71775"
  },
  {
    "ID": 1112573618,
    "Tankstellennummer": "A9505",
    "Name": "Hainfeld Wiener Straße 24",
    "Address": "Wiener Straße 24",
    "Address__1": "",
    "City": "Hainfeld",
    "Postcode": 3170,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.03366,
    "Longitude": 15.77878,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.03366,15.77878",
    "IsOpen24Hours": false,
    "id": "48.03366,15.77878"
  },
  {
    "ID": 453,
    "Tankstellennummer": "0G96Y",
    "Name": "Guntramsdorf, A2",
    "Address": "Autobahn A2",
    "Address__1": "NOE",
    "City": "Guntramsdorf West",
    "Postcode": 2353,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 223652003,
    "24 hour?": "TRUE",
    "Latitude": 48.0341287,
    "Longitude": 16.3379655,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.0341287,16.3379655",
    "IsOpen24Hours": true,
    "id": "48.0341287,16.3379655"
  },
  {
    "ID": 1823294068,
    "Tankstellennummer": "A8632",
    "Name": "Münchendorf Bundesstraße B16",
    "Address": "Bundesstraße B16 B16",
    "Address__1": "",
    "City": "Münchendorf",
    "Postcode": 2482,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.03444,
    "Longitude": 16.37948,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.03444,16.37948",
    "IsOpen24Hours": false,
    "id": "48.03444,16.37948"
  },
  {
    "ID": 935445297,
    "Tankstellennummer": 8019,
    "Name": "eni Hainfeld",
    "Address": "Hauptstrasse 77",
    "Address__1": "",
    "City": "Hainfeld",
    "Postcode": 3170,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2764/2504",
    "24 hour?": "FALSE",
    "Latitude": 48.03824,
    "Longitude": 15.7629,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.03824,15.7629",
    "IsOpen24Hours": false,
    "id": "48.03824,15.7629"
  },
  {
    "ID": 19647,
    "Tankstellennummer": 9734,
    "Name": "Möllersdorf Wiener Straße 197",
    "Address": "Wiener Straße 197",
    "Address__1": "",
    "City": "Möllersdorf",
    "Postcode": 2513,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02252 62163",
    "24 hour?": "TRUE",
    "Latitude": 48.03909,
    "Longitude": 16.30361,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.03909,16.30361",
    "IsOpen24Hours": true,
    "id": "48.03909,16.30361"
  },
  {
    "ID": 553,
    "Tankstellennummer": "0G999",
    "Name": "Merkenstetten, Stock 11",
    "Address": "Stock 11",
    "Address__1": "NOE",
    "City": "Merkenstetten",
    "Postcode": 3251,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 74892481,
    "24 hour?": "FALSE",
    "Latitude": 48.039127,
    "Longitude": 15.14372,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.039127,15.14372",
    "IsOpen24Hours": false,
    "id": "48.039127,15.14372"
  },
  {
    "ID": 2112758627,
    "Tankstellennummer": "0GH27",
    "Name": "Eberstalzell, Solarstr. 2",
    "Address": "Solarstraße 2",
    "Address__1": "",
    "City": "Eberstalzell",
    "Postcode": 4653,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 724121389,
    "24 hour?": "TRUE",
    "Latitude": 48.039795,
    "Longitude": 13.991393,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.039795,13.991393",
    "IsOpen24Hours": true,
    "id": "48.039795,13.991393"
  },
  {
    "ID": 1395449676,
    "Tankstellennummer": 8114,
    "Name": "eni St. Veit a. d. Gölsen",
    "Address": "Hauptstrasse 21",
    "Address__1": "",
    "City": "St. Veit a. d. Gölsen",
    "Postcode": 3161,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2763/3198",
    "24 hour?": "FALSE",
    "Latitude": 48.04289,
    "Longitude": 15.67186,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.04289,15.67186",
    "IsOpen24Hours": false,
    "id": "48.04289,15.67186"
  },
  {
    "ID": 1382329732,
    "Tankstellennummer": "A3110",
    "Name": "Steyr Sierninger Straße 178",
    "Address": "Sierninger Straße 178",
    "Address__1": "",
    "City": "Steyr",
    "Postcode": 4400,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.04345,
    "Longitude": 14.38747,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.04345,14.38747",
    "IsOpen24Hours": false,
    "id": "48.04345,14.38747"
  },
  {
    "ID": 1844260059,
    "Tankstellennummer": 8055,
    "Name": "eni Behamberg/Steyr",
    "Address": "Am Wachtberg 5",
    "Address__1": "",
    "City": "Behamberg/Steyr",
    "Postcode": 4441,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7252/80404",
    "24 hour?": "TRUE",
    "Latitude": 48.04618,
    "Longitude": 14.47219,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.04618,14.47219",
    "IsOpen24Hours": true,
    "id": "48.04618,14.47219"
  },
  {
    "ID": 622,
    "Tankstellennummer": "0G99G",
    "Name": "Sierning, Steyrerstr. 29",
    "Address": "Steyrerstraße 29",
    "Address__1": "OOE",
    "City": "Sierning",
    "Postcode": 4522,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 72592421,
    "24 hour?": "FALSE",
    "Latitude": 48.0462164,
    "Longitude": 14.3131072,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0462164,14.3131072",
    "IsOpen24Hours": false,
    "id": "48.0462164,14.3131072"
  },
  {
    "ID": 311547167,
    "Tankstellennummer": 6070,
    "Name": "eni Schwanenstadt",
    "Address": "Salzburgerstrasse 41",
    "Address__1": "",
    "City": "Schwanenstadt",
    "Postcode": 4690,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7673/30911",
    "24 hour?": "FALSE",
    "Latitude": 48.04835,
    "Longitude": 13.76219,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.04835,13.76219",
    "IsOpen24Hours": false,
    "id": "48.04835,13.76219"
  },
  {
    "ID": 1186970971,
    "Tankstellennummer": "A8593",
    "Name": "Traisen Mariazeller Straße 38a",
    "Address": "Mariazeller Straße 38a",
    "Address__1": "",
    "City": "Traisen",
    "Postcode": 3160,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.04857,
    "Longitude": 15.60983,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.04857,15.60983",
    "IsOpen24Hours": false,
    "id": "48.04857,15.60983"
  },
  {
    "ID": 9897,
    "Tankstellennummer": "0G96D",
    "Name": "Steyr, Ennser Str. 10A",
    "Address": "Ennser Straße 10 A",
    "Address__1": "OOE",
    "City": "Steyr",
    "Postcode": 4400,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 725282255,
    "24 hour?": "TRUE",
    "Latitude": 48.0543944,
    "Longitude": 14.421037,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.0543944,14.421037",
    "IsOpen24Hours": true,
    "id": "48.0543944,14.421037"
  },
  {
    "ID": 19637,
    "Tankstellennummer": 3577,
    "Name": "Steyr Haagerstraße 38A",
    "Address": "Haagerstraße 38A",
    "Address__1": "",
    "City": "Steyr",
    "Postcode": 4400,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-7252-73420",
    "24 hour?": "FALSE",
    "Latitude": 48.05521,
    "Longitude": 14.44928,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.05521,14.44928",
    "IsOpen24Hours": false,
    "id": "48.05521,14.44928"
  },
  {
    "ID": 19434,
    "Tankstellennummer": 8548,
    "Name": "Göttlesbrunn Ostautobahn A4 bei KM 27",
    "Address": "Autobahn A4 (Ostautobahn) KM 27,5",
    "Address__1": "",
    "City": "Göttlesbrunn",
    "Postcode": 2464,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02162 88130",
    "24 hour?": "TRUE",
    "Latitude": 48.05676,
    "Longitude": 16.71078,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.05676,16.71078",
    "IsOpen24Hours": true,
    "id": "48.05676,16.71078"
  },
  {
    "ID": 581164139,
    "Tankstellennummer": "A3959",
    "Name": "Steyr Ennser Straße 16",
    "Address": "Ennser Straße 16",
    "Address__1": "",
    "City": "Steyr",
    "Postcode": 4400,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.05739,
    "Longitude": 14.42064,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.05739,14.42064",
    "IsOpen24Hours": false,
    "id": "48.05739,14.42064"
  },
  {
    "ID": 866427464,
    "Tankstellennummer": "A9545",
    "Name": "Alland Bundesstraße 11",
    "Address": "Bundesstraße 11",
    "Address__1": "",
    "City": "Alland",
    "Postcode": 2534,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.05753,
    "Longitude": 16.08695,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.05753,16.08695",
    "IsOpen24Hours": false,
    "id": "48.05753,16.08695"
  },
  {
    "ID": 1748072135,
    "Tankstellennummer": "A3563",
    "Name": "Schwanenstadt Linzer Straße 22",
    "Address": "Linzer Straße 22",
    "Address__1": "",
    "City": "Schwanenstadt",
    "Postcode": 4690,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.05761,
    "Longitude": 13.7804,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.05761,13.7804",
    "IsOpen24Hours": false,
    "id": "48.05761,13.7804"
  },
  {
    "ID": 1619361392,
    "Tankstellennummer": "A9732",
    "Name": "Purgstall Erlauftalstraße 34",
    "Address": "Erlauftalstraße 34",
    "Address__1": "",
    "City": "Purgstall",
    "Postcode": 3251,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.06215,
    "Longitude": 15.13914,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.06215,15.13914",
    "IsOpen24Hours": false,
    "id": "48.06215,15.13914"
  },
  {
    "ID": 1333628355,
    "Tankstellennummer": "A3506",
    "Name": "Rohr im Kremstal Gewerbestraße",
    "Address": "Gewerbestraße 1",
    "Address__1": "",
    "City": "Rohr im Kremstal",
    "Postcode": 4532,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.0631,
    "Longitude": 14.19462,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0631,14.19462",
    "IsOpen24Hours": false,
    "id": "48.0631,14.19462"
  },
  {
    "ID": 17880,
    "Tankstellennummer": "0G99E",
    "Name": "Oberndorf, Wieselburger Strasse 2",
    "Address": "Wieselburger Straße 2",
    "Address__1": "NOE",
    "City": "Oberndorf an der Melk",
    "Postcode": 3281,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 74837000,
    "24 hour?": "FALSE",
    "Latitude": 48.0659052,
    "Longitude": 15.2158903,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0659052,15.2158903",
    "IsOpen24Hours": false,
    "id": "48.0659052,15.2158903"
  },
  {
    "ID": 8956,
    "Tankstellennummer": "0G96G",
    "Name": "Sattledt",
    "Address": "Voralpenkreuz A8",
    "Address__1": "OOE",
    "City": "Sattledt/Voralpenkreuz",
    "Postcode": 4642,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 724420189,
    "24 hour?": "TRUE",
    "Latitude": 48.0670337,
    "Longitude": 14.033948,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.0670337,14.033948",
    "IsOpen24Hours": true,
    "id": "48.0670337,14.033948"
  },
  {
    "ID": 19640,
    "Tankstellennummer": 8577,
    "Name": "Alland Gewerbestraße 550",
    "Address": "Gewerbestraße 550",
    "Address__1": "",
    "City": "Alland",
    "Postcode": 2534,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02258 8912",
    "24 hour?": "TRUE",
    "Latitude": 48.06751,
    "Longitude": 16.06339,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.06751,16.06339",
    "IsOpen24Hours": true,
    "id": "48.06751,16.06339"
  },
  {
    "ID": 349,
    "Tankstellennummer": "0G958",
    "Name": "Alland (A21)",
    "Address": "Außenringautobahn A21",
    "Address__1": "NOE",
    "City": "Alland",
    "Postcode": 2534,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 225876180,
    "24 hour?": "TRUE",
    "Latitude": 48.070115,
    "Longitude": 16.06662,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.070115,16.06662",
    "IsOpen24Hours": true,
    "id": "48.070115,16.06662"
  },
  {
    "ID": 1008563276,
    "Tankstellennummer": "A3040",
    "Name": "KREUZUNG B156/IBMER-MOOR-STR.",
    "Address": "Kreuzung B156 / Ibmer-Moor-Straße",
    "Address__1": "",
    "City": "Eggelsberg",
    "Postcode": 5142,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.07315,
    "Longitude": 12.99059,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.07315,12.99059",
    "IsOpen24Hours": false,
    "id": "48.07315,12.99059"
  },
  {
    "ID": 19672,
    "Tankstellennummer": 8704,
    "Name": "Wiener Neudorf NÖ Süd Strasse 4",
    "Address": "IZ NÖ Süd Straße 4 Objekt 9",
    "Address__1": "",
    "City": "Wiener Neudorf",
    "Postcode": 2351,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02236 62464",
    "24 hour?": "TRUE",
    "Latitude": 48.0776,
    "Longitude": 16.3233,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.0776,16.3233",
    "IsOpen24Hours": true,
    "id": "48.0776,16.3233"
  },
  {
    "ID": 781,
    "Tankstellennummer": "0G96W",
    "Name": "Wr. Neudorf, IZ Sued II",
    "Address": "Industriezentrum Süd II",
    "Address__1": "NOE",
    "City": "Wiener Neudorf",
    "Postcode": 2351,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 223662430,
    "24 hour?": "FALSE",
    "Latitude": 48.0788244,
    "Longitude": 16.323924,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0788244,16.323924",
    "IsOpen24Hours": false,
    "id": "48.0788244,16.323924"
  },
  {
    "ID": 19485,
    "Tankstellennummer": 8565,
    "Name": "Himberg Gutenhofer Straße 9",
    "Address": "Gutenhofer Straße 9",
    "Address__1": "",
    "City": "Himberg",
    "Postcode": 2325,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02235 84389",
    "24 hour?": "FALSE",
    "Latitude": 48.07949,
    "Longitude": 16.44134,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.07949,16.44134",
    "IsOpen24Hours": false,
    "id": "48.07949,16.44134"
  },
  {
    "ID": 1367697821,
    "Tankstellennummer": "A3738",
    "Name": "Ampflwang Siedlung 170",
    "Address": "Siedlung 170",
    "Address__1": "",
    "City": "Ampflwang",
    "Postcode": 4843,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.08075,
    "Longitude": 13.56378,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.08075,13.56378",
    "IsOpen24Hours": false,
    "id": "48.08075,13.56378"
  },
  {
    "ID": 686988713,
    "Tankstellennummer": 8043,
    "Name": "eni Mödling",
    "Address": "Neusiedler Strasse 15",
    "Address__1": "",
    "City": "Mödling",
    "Postcode": 2340,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2236/47109",
    "24 hour?": "FALSE",
    "Latitude": 48.08167,
    "Longitude": 16.28419,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.08167,16.28419",
    "IsOpen24Hours": false,
    "id": "48.08167,16.28419"
  },
  {
    "ID": 1924000454,
    "Tankstellennummer": 8092,
    "Name": "eni Achau",
    "Address": "Lanzendorfer Straße 471/1",
    "Address__1": "",
    "City": "Achau",
    "Postcode": 2481,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/664001",
    "24 hour?": "FALSE",
    "Latitude": 48.08196,
    "Longitude": 16.39019,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.08196,16.39019",
    "IsOpen24Hours": false,
    "id": "48.08196,16.39019"
  },
  {
    "ID": 1587363564,
    "Tankstellennummer": "0G91J",
    "Name": "Mauer bei Amstetten",
    "Address": "Hausmeninger Straße 74",
    "Address__1": "",
    "City": "Mauer bei Amstetten",
    "Postcode": 3362,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 747552619,
    "24 hour?": "FALSE",
    "Latitude": 48.0825413,
    "Longitude": 14.8017456,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0825413,14.8017456",
    "IsOpen24Hours": false,
    "id": "48.0825413,14.8017456"
  },
  {
    "ID": 2072749127,
    "Tankstellennummer": "0GH35",
    "Name": "Achau, Lanzendorfer Str. 9",
    "Address": "Lanzendorfer Straße 9",
    "Address__1": "",
    "City": "Achau",
    "Postcode": 2481,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 2236710698,
    "24 hour?": "FALSE",
    "Latitude": 48.0826508,
    "Longitude": 16.391651,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.0826508,16.391651",
    "IsOpen24Hours": false,
    "id": "48.0826508,16.391651"
  },
  {
    "ID": 1848876331,
    "Tankstellennummer": 8048,
    "Name": "eni Wr. Neudorf",
    "Address": "Hauptstrasse 38",
    "Address__1": "",
    "City": "Wr. Neudorf",
    "Postcode": 2351,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2236/62410",
    "24 hour?": "FALSE",
    "Latitude": 48.08638,
    "Longitude": 16.31786,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.08638,16.31786",
    "IsOpen24Hours": false,
    "id": "48.08638,16.31786"
  },
  {
    "ID": 207240866,
    "Tankstellennummer": "A8732",
    "Name": "Mödling Gabrieler Straße 11-13",
    "Address": "Gabrieler Straße 11-13",
    "Address__1": "",
    "City": "Mödling",
    "Postcode": 2340,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.08762,
    "Longitude": 16.29563,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.08762,16.29563",
    "IsOpen24Hours": false,
    "id": "48.08762,16.29563"
  },
  {
    "ID": 19583,
    "Tankstellennummer": 3516,
    "Name": "Ottnang a. H. Hauptstraße 32",
    "Address": "Hauptstraße 32",
    "Address__1": "",
    "City": "Ottnang a. H.",
    "Postcode": 4901,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07676 7240",
    "24 hour?": "FALSE",
    "Latitude": 48.09666,
    "Longitude": 13.66729,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.09666,13.66729",
    "IsOpen24Hours": false,
    "id": "48.09666,13.66729"
  },
  {
    "ID": 1459610490,
    "Tankstellennummer": 8107,
    "Name": "eni Kilb",
    "Address": "Mankerstr. 8",
    "Address__1": "",
    "City": "Kilb",
    "Postcode": 3233,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2748/7350",
    "24 hour?": "FALSE",
    "Latitude": 48.1008,
    "Longitude": 15.4052,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1008,15.4052",
    "IsOpen24Hours": false,
    "id": "48.1008,15.4052"
  },
  {
    "ID": 389158323,
    "Tankstellennummer": 8140,
    "Name": "eni Brunn am Gebirge",
    "Address": "Maria Enzersdorfer Str. 5-7",
    "Address__1": "",
    "City": "Brunn am Gebirge",
    "Postcode": 2345,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2236/31122",
    "24 hour?": "FALSE",
    "Latitude": 48.1044,
    "Longitude": 16.2853,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1044,16.2853",
    "IsOpen24Hours": false,
    "id": "48.1044,16.2853"
  },
  {
    "ID": 1043362646,
    "Tankstellennummer": 6060,
    "Name": "eni Dietach",
    "Address": "Ennser Strasse 152a",
    "Address__1": "",
    "City": "Dietach",
    "Postcode": 4407,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7252/39009",
    "24 hour?": "FALSE",
    "Latitude": 48.10747,
    "Longitude": 14.44654,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.10747,14.44654",
    "IsOpen24Hours": false,
    "id": "48.10747,14.44654"
  },
  {
    "ID": 392208016,
    "Tankstellennummer": "A8543",
    "Name": "Wildungsmauer Untere Carnuntum",
    "Address": "Untere Carnuntumer Straße 6",
    "Address__1": "",
    "City": "Wildungsmauer",
    "Postcode": 2403,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.10775,
    "Longitude": 16.80919,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.10775,16.80919",
    "IsOpen24Hours": false,
    "id": "48.10775,16.80919"
  },
  {
    "ID": 1032614487,
    "Tankstellennummer": 8040,
    "Name": "eni Brunn/Geb.",
    "Address": "Wiener Strasse 125",
    "Address__1": "",
    "City": "Brunn/Geb.",
    "Postcode": 2345,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2236/31790",
    "24 hour?": "TRUE",
    "Latitude": 48.11221,
    "Longitude": 16.29956,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.11221,16.29956",
    "IsOpen24Hours": true,
    "id": "48.11221,16.29956"
  },
  {
    "ID": 523317056,
    "Tankstellennummer": 8079,
    "Name": "eni Wilhelmsburg",
    "Address": "Bahnhofstr. 29",
    "Address__1": "",
    "City": "Wilhelmsburg",
    "Postcode": 3150,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2746/6053",
    "24 hour?": "FALSE",
    "Latitude": 48.112351,
    "Longitude": 15.6079363,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.112351,15.6079363",
    "IsOpen24Hours": false,
    "id": "48.112351,15.6079363"
  },
  {
    "ID": 1036828367,
    "Tankstellennummer": 6006,
    "Name": "eni Mattighofen",
    "Address": "Landstraße 37",
    "Address__1": "",
    "City": "Mattighofen",
    "Postcode": 5230,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7742/2388",
    "24 hour?": "TRUE",
    "Latitude": 48.11492,
    "Longitude": 13.15238,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.11492,13.15238",
    "IsOpen24Hours": true,
    "id": "48.11492,13.15238"
  },
  {
    "ID": 19481,
    "Tankstellennummer": 8556,
    "Name": "Haag Strasse zur Autobahn 5",
    "Address": "Straße zur Autobahn 5",
    "Address__1": "",
    "City": "Haag",
    "Postcode": 3350,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-7434-42294",
    "24 hour?": "TRUE",
    "Latitude": 48.11517,
    "Longitude": 14.57068,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.11517,14.57068",
    "IsOpen24Hours": true,
    "id": "48.11517,14.57068"
  },
  {
    "ID": 19652,
    "Tankstellennummer": 8536,
    "Name": "Perchtoldsdorf Brunner Gasse 48",
    "Address": "Brunner Gasse 48",
    "Address__1": "",
    "City": "Perchtoldsdorf",
    "Postcode": 2380,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 8691300",
    "24 hour?": "FALSE",
    "Latitude": 48.11554,
    "Longitude": 16.27169,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.11554,16.27169",
    "IsOpen24Hours": false,
    "id": "48.11554,16.27169"
  },
  {
    "ID": 1605476666,
    "Tankstellennummer": "A8775",
    "Name": "Zwölfaxing Schwechater Straße",
    "Address": "Schwechater Straße 53",
    "Address__1": "",
    "City": "Zwölfaxing",
    "Postcode": 2324,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.1162,
    "Longitude": 16.46643,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1162,16.46643",
    "IsOpen24Hours": false,
    "id": "48.1162,16.46643"
  },
  {
    "ID": 19465,
    "Tankstellennummer": 9546,
    "Name": "Amstetten Reichsstrasse 92",
    "Address": "Reichsstraße 92",
    "Address__1": "",
    "City": "Amstetten",
    "Postcode": 3300,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07472 68585",
    "24 hour?": "TRUE",
    "Latitude": 48.11657,
    "Longitude": 14.90712,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.11657,14.90712",
    "IsOpen24Hours": true,
    "id": "48.11657,14.90712"
  },
  {
    "ID": 354,
    "Tankstellennummer": "0G98Q",
    "Name": "Amstetten, Ybbsstr. 39",
    "Address": "Ybbsstraße 39",
    "Address__1": "NOE",
    "City": "Amstetten",
    "Postcode": 3300,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 747268222,
    "24 hour?": "FALSE",
    "Latitude": 48.1166141,
    "Longitude": 14.8716214,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1166141,14.8716214",
    "IsOpen24Hours": false,
    "id": "48.1166141,14.8716214"
  },
  {
    "ID": 1071893773,
    "Tankstellennummer": 8154,
    "Name": "eni Fischamend",
    "Address": "Wienerstrasse 38",
    "Address__1": "",
    "City": "Fischamend",
    "Postcode": 2401,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2232/76251",
    "24 hour?": "FALSE",
    "Latitude": 48.12098,
    "Longitude": 16.60183,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.12098,16.60183",
    "IsOpen24Hours": false,
    "id": "48.12098,16.60183"
  },
  {
    "ID": 478920262,
    "Tankstellennummer": "A8607",
    "Name": "Fischamend Reichsstraße 2",
    "Address": "Reichsstraße 2",
    "Address__1": "",
    "City": "Fischamend",
    "Postcode": 2401,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.12157,
    "Longitude": 16.59846,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.12157,16.59846",
    "IsOpen24Hours": false,
    "id": "48.12157,16.59846"
  },
  {
    "ID": 19776,
    "Tankstellennummer": 8703,
    "Name": "Schwechat S1 Wr. Aussenringschnellstraße",
    "Address": "S1",
    "Address__1": "",
    "City": "Schwechat",
    "Postcode": 2320,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 7062651",
    "24 hour?": "TRUE",
    "Latitude": 48.12249,
    "Longitude": 16.4387,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.12249,16.4387",
    "IsOpen24Hours": true,
    "id": "48.12249,16.4387"
  },
  {
    "ID": 1767663231,
    "Tankstellennummer": "A9003",
    "Name": "Amstetten Wiener Straße 60",
    "Address": "Wiener Straße 60",
    "Address__1": "",
    "City": "Amstetten",
    "Postcode": 3300,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.12263,
    "Longitude": 14.88932,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.12263,14.88932",
    "IsOpen24Hours": false,
    "id": "48.12263,14.88932"
  },
  {
    "ID": 1881023478,
    "Tankstellennummer": 8077,
    "Name": "eni Amstetten",
    "Address": "Wienerstr. 97",
    "Address__1": "",
    "City": "Amstetten",
    "Postcode": 3300,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7472/62558",
    "24 hour?": "FALSE",
    "Latitude": 48.12269,
    "Longitude": 14.8867194,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.12269,14.8867194",
    "IsOpen24Hours": false,
    "id": "48.12269,14.8867194"
  },
  {
    "ID": 746979954,
    "Tankstellennummer": 8067,
    "Name": "eni Brunn/Geb.",
    "Address": "Bundesstrasse 12",
    "Address__1": "",
    "City": "Brunn/Geb.",
    "Postcode": 2345,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2236/37887",
    "24 hour?": "TRUE",
    "Latitude": 48.12298226,
    "Longitude": 16.29372445,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.12298226,16.29372445",
    "IsOpen24Hours": true,
    "id": "48.12298226,16.29372445"
  },
  {
    "ID": 8758,
    "Tankstellennummer": "0G94V",
    "Name": "Schwechat-Flughafen, Ausfahrtsstr. 1",
    "Address": "Ausfahrtsstraße",
    "Address__1": "NOE",
    "City": "Schwechat-Flughafen",
    "Postcode": 1300,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 1700732618,
    "24 hour?": "TRUE",
    "Latitude": 48.124809,
    "Longitude": 16.561996,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.124809,16.561996",
    "IsOpen24Hours": true,
    "id": "48.124809,16.561996"
  },
  {
    "ID": 1014224928,
    "Tankstellennummer": "A8020",
    "Name": "Vösendorf Metro Platz 1",
    "Address": "Metro Platz 1",
    "Address__1": "",
    "City": "Vösendorf",
    "Postcode": 2331,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.12534,
    "Longitude": 16.32296,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.12534,16.32296",
    "IsOpen24Hours": false,
    "id": "48.12534,16.32296"
  },
  {
    "ID": 863928532,
    "Tankstellennummer": 5071,
    "Name": "eni Wien",
    "Address": "Ketzergasse 111",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1230,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/86924840",
    "24 hour?": "FALSE",
    "Latitude": 48.1277241,
    "Longitude": 16.3055531,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1277241,16.3055531",
    "IsOpen24Hours": false,
    "id": "48.1277241,16.3055531"
  },
  {
    "ID": 1841410934,
    "Tankstellennummer": "A8656",
    "Name": "Wolfsthal Hauptstraße (Wolfsth",
    "Address": "Hauptstraße (Wolfsthal) 63",
    "Address__1": "",
    "City": "Wolfsthal",
    "Postcode": 2412,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.13064,
    "Longitude": 17.02105,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.13064,17.02105",
    "IsOpen24Hours": false,
    "id": "48.13064,17.02105"
  },
  {
    "ID": 1353874614,
    "Tankstellennummer": "A8771",
    "Name": "Vösendorf Schönbrunner Allee 2",
    "Address": "Schönbrunner Allee 20",
    "Address__1": "",
    "City": "Vösendorf",
    "Postcode": 2331,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.13078,
    "Longitude": 16.32632,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.13078,16.32632",
    "IsOpen24Hours": false,
    "id": "48.13078,16.32632"
  },
  {
    "ID": 835514024,
    "Tankstellennummer": 8102,
    "Name": "eni Amstetten",
    "Address": "Oiden 110, Bundesstr.1",
    "Address__1": "",
    "City": "Amstetten",
    "Postcode": 3300,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7472/25197",
    "24 hour?": "FALSE",
    "Latitude": 48.1331,
    "Longitude": 14.8416,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1331,14.8416",
    "IsOpen24Hours": false,
    "id": "48.1331,14.8416"
  },
  {
    "ID": 19626,
    "Tankstellennummer": 7554,
    "Name": "Wien Brunner Straße 77-79",
    "Address": "Brunner Straße 77-79",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1230,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 8695856",
    "24 hour?": "TRUE",
    "Latitude": 48.13342,
    "Longitude": 16.29849,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.13342,16.29849",
    "IsOpen24Hours": true,
    "id": "48.13342,16.29849"
  },
  {
    "ID": 771,
    "Tankstellennummer": "0G96Z",
    "Name": "Wieselburg, Zur Autobahn 2",
    "Address": "Zur Autobahn 2",
    "Address__1": "NOE",
    "City": "Wieselburg",
    "Postcode": 3250,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 741658788,
    "24 hour?": "FALSE",
    "Latitude": 48.135225,
    "Longitude": 15.141678,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.135225,15.141678",
    "IsOpen24Hours": false,
    "id": "48.135225,15.141678"
  },
  {
    "ID": 456,
    "Tankstellennummer": "0G96X",
    "Name": "Haag/Autobahn, Westautobahn A1",
    "Address": "Westautobahn A1",
    "Address__1": "NOE",
    "City": "Haag",
    "Postcode": 3350,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 743442153,
    "24 hour?": "TRUE",
    "Latitude": 48.1394515,
    "Longitude": 14.5792436,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1394515,14.5792436",
    "IsOpen24Hours": true,
    "id": "48.1394515,14.5792436"
  },
  {
    "ID": 1384402805,
    "Tankstellennummer": "A8555",
    "Name": "Schwechat Bruck-Hainburger Str",
    "Address": "Bruck-Hainburger Straße 24a",
    "Address__1": "",
    "City": "Schwechat",
    "Postcode": 2320,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.1397,
    "Longitude": 16.4855,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1397,16.4855",
    "IsOpen24Hours": false,
    "id": "48.1397,16.4855"
  },
  {
    "ID": 1187950397,
    "Tankstellennummer": "A9514",
    "Name": "Ruprechtshofen Hauptstraße 24",
    "Address": "Hauptstraße 24",
    "Address__1": "",
    "City": "Ruprechtshofen",
    "Postcode": 3244,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.13974,
    "Longitude": 15.28193,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.13974,15.28193",
    "IsOpen24Hours": false,
    "id": "48.13974,15.28193"
  },
  {
    "ID": 740870672,
    "Tankstellennummer": 8104,
    "Name": "eni Schwechat",
    "Address": "Bruck Hainburgerstr. 19-21",
    "Address__1": "",
    "City": "Schwechat",
    "Postcode": 2320,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/7076159",
    "24 hour?": "FALSE",
    "Latitude": 48.1403,
    "Longitude": 16.4837,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1403,16.4837",
    "IsOpen24Hours": false,
    "id": "48.1403,16.4837"
  },
  {
    "ID": 494066800,
    "Tankstellennummer": 8106,
    "Name": "eni Hainburg",
    "Address": "Landstrasse 114",
    "Address__1": "",
    "City": "Hainburg",
    "Postcode": 2410,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2165/62562",
    "24 hour?": "FALSE",
    "Latitude": 48.14037,
    "Longitude": 16.92246,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.14037,16.92246",
    "IsOpen24Hours": false,
    "id": "48.14037,16.92246"
  },
  {
    "ID": 889516891,
    "Tankstellennummer": 6113,
    "Name": "eni Handenberg",
    "Address": "Sandtal 28",
    "Address__1": "",
    "City": "Handenberg",
    "Postcode": 5144,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7748/32129",
    "24 hour?": "FALSE",
    "Latitude": 48.1416,
    "Longitude": 13.0151,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1416,13.0151",
    "IsOpen24Hours": false,
    "id": "48.1416,13.0151"
  },
  {
    "ID": 1607417227,
    "Tankstellennummer": 5105,
    "Name": "eni Wien",
    "Address": "Triesterstr. 285-287",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1230,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/6673109",
    "24 hour?": "FALSE",
    "Latitude": 48.1417,
    "Longitude": 16.3296,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1417,16.3296",
    "IsOpen24Hours": false,
    "id": "48.1417,16.3296"
  },
  {
    "ID": 540550881,
    "Tankstellennummer": 5031,
    "Name": "eni Wien",
    "Address": "Breitenfurter Strasse 325",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1230,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/8690303",
    "24 hour?": "TRUE",
    "Latitude": 48.14304,
    "Longitude": 16.29244,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.14304,16.29244",
    "IsOpen24Hours": true,
    "id": "48.14304,16.29244"
  },
  {
    "ID": 1059253911,
    "Tankstellennummer": "0GH24",
    "Name": "Neuhofen an der Krems, Linzer Str. 41",
    "Address": "Linzer Straße 41",
    "Address__1": "",
    "City": "Neuhofen an der Krems",
    "Postcode": 4501,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 72275055,
    "24 hour?": "FALSE",
    "Latitude": 48.143874,
    "Longitude": 14.2282084,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.143874,14.2282084",
    "IsOpen24Hours": false,
    "id": "48.143874,14.2282084"
  },
  {
    "ID": 377458055,
    "Tankstellennummer": "A3952",
    "Name": "Kronstorf Hauptstraße 19",
    "Address": "Hauptstraße 19",
    "Address__1": "",
    "City": "Kronstorf",
    "Postcode": 4484,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.14532,
    "Longitude": 14.46511,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.14532,14.46511",
    "IsOpen24Hours": false,
    "id": "48.14532,14.46511"
  },
  {
    "ID": 506328838,
    "Tankstellennummer": 8155,
    "Name": "eni Strengberg",
    "Address": "Hauptstrasse 53",
    "Address__1": "",
    "City": "Strengberg",
    "Postcode": 3314,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7432/2242",
    "24 hour?": "FALSE",
    "Latitude": 48.14545,
    "Longitude": 14.64091,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.14545,14.64091",
    "IsOpen24Hours": false,
    "id": "48.14545,14.64091"
  },
  {
    "ID": 19460,
    "Tankstellennummer": 7513,
    "Name": "Wien Laxenburger Straße",
    "Address": "Laxenburger Straße 202",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1232,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 6161980",
    "24 hour?": "FALSE",
    "Latitude": 48.14651,
    "Longitude": 16.36108,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.14651,16.36108",
    "IsOpen24Hours": false,
    "id": "48.14651,16.36108"
  },
  {
    "ID": 19607,
    "Tankstellennummer": 7560,
    "Name": "Wien Laaer Berg-Straße 240",
    "Address": "Laaer Berg-Straße 240",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1100,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 6888065",
    "24 hour?": "FALSE",
    "Latitude": 48.14818,
    "Longitude": 16.39664,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.14818,16.39664",
    "IsOpen24Hours": false,
    "id": "48.14818,16.39664"
  },
  {
    "ID": 42020028,
    "Tankstellennummer": "A7573",
    "Name": "Wien Simmeringer Hauptstraße 4",
    "Address": "Simmeringer Hauptstraße 489",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1110,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.14844,
    "Longitude": 16.46228,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.14844,16.46228",
    "IsOpen24Hours": false,
    "id": "48.14844,16.46228"
  },
  {
    "ID": 19775,
    "Tankstellennummer": 7666,
    "Name": "Wien Sterngassse 13",
    "Address": "Sterngasse 13",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1230,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 6672130",
    "24 hour?": "TRUE",
    "Latitude": 48.14891,
    "Longitude": 16.33836,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.14891,16.33836",
    "IsOpen24Hours": true,
    "id": "48.14891,16.33836"
  },
  {
    "ID": 19004,
    "Tankstellennummer": "0G98T",
    "Name": "Obergrafendorf, Hauptstr. 34",
    "Address": "Hauptstraße 34",
    "Address__1": "NOE",
    "City": "Obergrafendorf",
    "Postcode": 3200,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 27472275,
    "24 hour?": "FALSE",
    "Latitude": 48.1498,
    "Longitude": 15.5495,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1498,15.5495",
    "IsOpen24Hours": false,
    "id": "48.1498,15.5495"
  },
  {
    "ID": 1874614156,
    "Tankstellennummer": "A8518",
    "Name": "Orth an der Donau Wiener Straß",
    "Address": "Wiener Straße 76",
    "Address__1": "",
    "City": "Orth an der Donau",
    "Postcode": 2304,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.14992,
    "Longitude": 16.68739,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.14992,16.68739",
    "IsOpen24Hours": false,
    "id": "48.14992,16.68739"
  },
  {
    "ID": 19632,
    "Tankstellennummer": 7557,
    "Name": "Wien Etrichstraße 42",
    "Address": "Etrichstraße 42",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1110,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 7684044",
    "24 hour?": "TRUE",
    "Latitude": 48.15133,
    "Longitude": 16.4632,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.15133,16.4632",
    "IsOpen24Hours": true,
    "id": "48.15133,16.4632"
  },
  {
    "ID": 19442,
    "Tankstellennummer": 8010,
    "Name": "Ybbs",
    "Address": "A1/Westautobahn",
    "Address__1": "",
    "City": "Ybbs-Kemmelbach",
    "Postcode": 3373,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7412/52746",
    "24 hour?": "TRUE",
    "Latitude": 48.1538462,
    "Longitude": 15.09798498,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1538462,15.09798498",
    "IsOpen24Hours": true,
    "id": "48.1538462,15.09798498"
  },
  {
    "ID": 19461,
    "Tankstellennummer": 7505,
    "Name": "Wien Speisinger Straße 228",
    "Address": "Speisinger Straße 228",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1232,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 8887229",
    "24 hour?": "FALSE",
    "Latitude": 48.15489,
    "Longitude": 16.2711,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.15489,16.2711",
    "IsOpen24Hours": false,
    "id": "48.15489,16.2711"
  },
  {
    "ID": 486,
    "Tankstellennummer": "0G95K",
    "Name": "Kemmelbach, Autobahn A1, Rasthausstr. 10",
    "Address": "Autobahn A1, Rasthausstraße 10",
    "Address__1": "NOE",
    "City": "Kemmelbach",
    "Postcode": 3373,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 741252748,
    "24 hour?": "TRUE",
    "Latitude": 48.1551024,
    "Longitude": 15.1001745,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1551024,15.1001745",
    "IsOpen24Hours": true,
    "id": "48.1551024,15.1001745"
  },
  {
    "ID": 766,
    "Tankstellennummer": "0G956",
    "Name": "Wien 10, Triesterstr. 153",
    "Address": "Triesterstraße 153",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1100,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 16167464,
    "24 hour?": "TRUE",
    "Latitude": 48.1565,
    "Longitude": 16.3416,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1565,16.3416",
    "IsOpen24Hours": true,
    "id": "48.1565,16.3416"
  },
  {
    "ID": 1024271739,
    "Tankstellennummer": 5112,
    "Name": "eni Wien",
    "Address": "Breitenfurterstr. 215",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1230,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/8042146",
    "24 hour?": "FALSE",
    "Latitude": 48.1568,
    "Longitude": 16.305,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1568,16.305",
    "IsOpen24Hours": false,
    "id": "48.1568,16.305"
  },
  {
    "ID": 615,
    "Tankstellennummer": "0G98V",
    "Name": "Hochburg-Ach, Braunauer Str. 1",
    "Address": "Braunauer Straße 1",
    "Address__1": "SBG",
    "City": "Ach",
    "Postcode": 5122,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 77274018,
    "24 hour?": "FALSE",
    "Latitude": 48.1570682,
    "Longitude": 12.8402924,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1570682,12.8402924",
    "IsOpen24Hours": false,
    "id": "48.1570682,12.8402924"
  },
  {
    "ID": 765,
    "Tankstellennummer": "0G95F",
    "Name": "Wien 10, Triesterstr. 104",
    "Address": "Triesterstraße 104",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1100,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 16674134,
    "24 hour?": "TRUE",
    "Latitude": 48.1573,
    "Longitude": 16.3411,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1573,16.3411",
    "IsOpen24Hours": true,
    "id": "48.1573,16.3411"
  },
  {
    "ID": 8957,
    "Tankstellennummer": "0G94H",
    "Name": "St. Poelten, Mariazellerstr. 264",
    "Address": "Mariazeller Straße 264",
    "Address__1": "NOE",
    "City": "St. Pölten",
    "Postcode": 3100,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 2742883738,
    "24 hour?": "FALSE",
    "Latitude": 48.1574248,
    "Longitude": 15.6142557,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1574248,15.6142557",
    "IsOpen24Hours": false,
    "id": "48.1574248,15.6142557"
  },
  {
    "ID": 820971784,
    "Tankstellennummer": 8011,
    "Name": "Altlengbach Raststation 1",
    "Address": "Raststation 1 1",
    "Address__1": "",
    "City": "Altlengbach",
    "Postcode": 3033,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02774 28846",
    "24 hour?": "TRUE",
    "Latitude": 48.15763,
    "Longitude": 15.94264,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.15763,15.94264",
    "IsOpen24Hours": true,
    "id": "48.15763,15.94264"
  },
  {
    "ID": 1865510795,
    "Tankstellennummer": "0GH37",
    "Name": "Wels, Salzburger Str. 57a",
    "Address": "Salzburger Straße 57a",
    "Address__1": "",
    "City": "Wels",
    "Postcode": 4600,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 724264105,
    "24 hour?": "FALSE",
    "Latitude": 48.157705,
    "Longitude": 14.010245,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.157705,14.010245",
    "IsOpen24Hours": false,
    "id": "48.157705,14.010245"
  },
  {
    "ID": 9902,
    "Tankstellennummer": "0G96J",
    "Name": "Wien 10, L.V.Hoehnelgasse 11",
    "Address": "L.V.Höhnelgasse",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1100,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 16881900,
    "24 hour?": "FALSE",
    "Latitude": 48.159663,
    "Longitude": 16.3866275,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.159663,16.3866275",
    "IsOpen24Hours": false,
    "id": "48.159663,16.3866275"
  },
  {
    "ID": 744,
    "Tankstellennummer": "0G94M",
    "Name": "Wien 11, Kaiser Ebersdorfer Str. 248-250",
    "Address": "Kaiser Ebersdorferstraße 248-250",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1110,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 6765606735,
    "24 hour?": "FALSE",
    "Latitude": 48.1596809,
    "Longitude": 16.4577638,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1596809,16.4577638",
    "IsOpen24Hours": false,
    "id": "48.1596809,16.4577638"
  },
  {
    "ID": 8962,
    "Tankstellennummer": "0G936",
    "Name": "Wien 12, Breitenfurterstr. 112",
    "Address": "Breitenfurter Straße 112",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1120,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 18023624,
    "24 hour?": "FALSE",
    "Latitude": 48.160533,
    "Longitude": 16.309534,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.160533,16.309534",
    "IsOpen24Hours": false,
    "id": "48.160533,16.309534"
  },
  {
    "ID": 703,
    "Tankstellennummer": "0G94Y",
    "Name": "Wien 12, Altmannsdorferstr. 117",
    "Address": "Altmannsdorferstraße 117",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1120,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 16678737,
    "24 hour?": "TRUE",
    "Latitude": 48.1609269,
    "Longitude": 16.3186078,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1609269,16.3186078",
    "IsOpen24Hours": true,
    "id": "48.1609269,16.3186078"
  },
  {
    "ID": 770,
    "Tankstellennummer": "0G94L",
    "Name": "Wien 11, Zinnergasse 4",
    "Address": "Zinnergasse 4",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1110,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 17676404,
    "24 hour?": "FALSE",
    "Latitude": 48.1612,
    "Longitude": 16.4715,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1612,16.4715",
    "IsOpen24Hours": false,
    "id": "48.1612,16.4715"
  },
  {
    "ID": 48176464,
    "Tankstellennummer": 5012,
    "Name": "eni Wien",
    "Address": "Grenzackerstrasse 6-8",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1100,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/6073149",
    "24 hour?": "TRUE",
    "Latitude": 48.16209,
    "Longitude": 16.3806,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.16209,16.3806",
    "IsOpen24Hours": true,
    "id": "48.16209,16.3806"
  },
  {
    "ID": 702,
    "Tankstellennummer": "0G95E",
    "Name": "Wien 12, Altmannsdorferstr. 94",
    "Address": "Altmannsdorferstraße 94",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1120,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 18043718,
    "24 hour?": "TRUE",
    "Latitude": 48.162186,
    "Longitude": 16.3172537,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.162186,16.3172537",
    "IsOpen24Hours": true,
    "id": "48.162186,16.3172537"
  },
  {
    "ID": 9851,
    "Tankstellennummer": "0G95R",
    "Name": "Wien 10, Triesterstr. 68",
    "Address": "Triesterstraße 68",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1100,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 16676173,
    "24 hour?": "TRUE",
    "Latitude": 48.1656,
    "Longitude": 16.3465,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1656,16.3465",
    "IsOpen24Hours": true,
    "id": "48.1656,16.3465"
  },
  {
    "ID": 19752,
    "Tankstellennummer": 3752,
    "Name": "Wels Eisenfeldstraße 2",
    "Address": "Eisenfeldstraße 2",
    "Address__1": "",
    "City": "Wels",
    "Postcode": 4600,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07242 77702",
    "24 hour?": "TRUE",
    "Latitude": 48.16575,
    "Longitude": 14.0391,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.16575,14.0391",
    "IsOpen24Hours": true,
    "id": "48.16575,14.0391"
  },
  {
    "ID": 1604113069,
    "Tankstellennummer": 8110,
    "Name": "eni Ybbs, Kemmelbach",
    "Address": "Bahnhofstrasse 37",
    "Address__1": "",
    "City": "Ybbs an der Donau",
    "Postcode": 3370,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7412/52431",
    "24 hour?": "FALSE",
    "Latitude": 48.16579,
    "Longitude": 15.09091,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.16579,15.09091",
    "IsOpen24Hours": false,
    "id": "48.16579,15.09091"
  },
  {
    "ID": 854028888,
    "Tankstellennummer": 8115,
    "Name": "eni St.Christophen",
    "Address": "Tullner Strasse 4",
    "Address__1": "",
    "City": "St.Christophen",
    "Postcode": 3051,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2772/52573",
    "24 hour?": "FALSE",
    "Latitude": 48.1664134,
    "Longitude": 15.8850938,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1664134,15.8850938",
    "IsOpen24Hours": false,
    "id": "48.1664134,15.8850938"
  },
  {
    "ID": 282513997,
    "Tankstellennummer": 5110,
    "Name": "eni Wien",
    "Address": "Raxstrasse 38",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1100,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/6041060",
    "24 hour?": "TRUE",
    "Latitude": 48.16659,
    "Longitude": 16.35804,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.16659,16.35804",
    "IsOpen24Hours": true,
    "id": "48.16659,16.35804"
  },
  {
    "ID": 856440753,
    "Tankstellennummer": 5107,
    "Name": "eni Wien",
    "Address": "Kaiserebersdorferstrasse 48",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1110,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/7676695",
    "24 hour?": "FALSE",
    "Latitude": 48.16746,
    "Longitude": 16.42691,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.16746,16.42691",
    "IsOpen24Hours": false,
    "id": "48.16746,16.42691"
  },
  {
    "ID": 19615,
    "Tankstellennummer": 7567,
    "Name": "Wien Leberstraße 112-114",
    "Address": "Leberstraße 112-114",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1110,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 7491753",
    "24 hour?": "TRUE",
    "Latitude": 48.17,
    "Longitude": 16.40953,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.17,16.40953",
    "IsOpen24Hours": true,
    "id": "48.17,16.40953"
  },
  {
    "ID": 155338359,
    "Tankstellennummer": "A8776",
    "Name": "Ybbs Bahnhofstraße 13",
    "Address": "Bahnhofstraße 13",
    "Address__1": "",
    "City": "Ybbs",
    "Postcode": 3370,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.17039,
    "Longitude": 15.08587,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.17039,15.08587",
    "IsOpen24Hours": false,
    "id": "48.17039,15.08587"
  },
  {
    "ID": 306119479,
    "Tankstellennummer": "A7010",
    "Name": "Wien Jedletzbergerstraße 7",
    "Address": "Jedletzbergerstraße 7",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1110,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": "+43-800-202055",
    "24 hour?": "FALSE",
    "Latitude": 48.17054,
    "Longitude": 16.45797,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.17054,16.45797",
    "IsOpen24Hours": false,
    "id": "48.17054,16.45797"
  },
  {
    "ID": 724,
    "Tankstellennummer": "0G93U",
    "Name": "Wien 12, Am Fasangarten 9",
    "Address": "Am Fasangarten 9",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1120,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 18135374,
    "24 hour?": "FALSE",
    "Latitude": 48.1708611,
    "Longitude": 16.305914,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1708611,16.305914",
    "IsOpen24Hours": false,
    "id": "48.1708611,16.305914"
  },
  {
    "ID": 768,
    "Tankstellennummer": "0G96Q",
    "Name": "Wien 12, Wienerbergstr. 29",
    "Address": "Wienerbergstraße 29",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1120,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 18156279,
    "24 hour?": "FALSE",
    "Latitude": 48.1709,
    "Longitude": 16.3368,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1709,16.3368",
    "IsOpen24Hours": false,
    "id": "48.1709,16.3368"
  },
  {
    "ID": 655,
    "Tankstellennummer": "0G99D",
    "Name": "St. Valentin, Werkstr. 4",
    "Address": "Werkstraße 4",
    "Address__1": "NOE",
    "City": "St. Valentin",
    "Postcode": 4300,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 743552224,
    "24 hour?": "FALSE",
    "Latitude": 48.171167,
    "Longitude": 14.5123446,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.171167,14.5123446",
    "IsOpen24Hours": false,
    "id": "48.171167,14.5123446"
  },
  {
    "ID": 19674,
    "Tankstellennummer": 9571,
    "Name": "Ybbs an der Donau Bahnhofstraße 18",
    "Address": "Bahnhofstraße 18",
    "Address__1": "",
    "City": "Ybbs an der Donau",
    "Postcode": 3370,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07412 52568",
    "24 hour?": "FALSE",
    "Latitude": 48.17122,
    "Longitude": 15.08459,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.17122,15.08459",
    "IsOpen24Hours": false,
    "id": "48.17122,15.08459"
  },
  {
    "ID": 1027007175,
    "Tankstellennummer": 6111,
    "Name": "eni Wels",
    "Address": "Grieskirchnerstr. 39",
    "Address__1": "",
    "City": "Wels",
    "Postcode": 4600,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7242/44205",
    "24 hour?": "FALSE",
    "Latitude": 48.17173,
    "Longitude": 14.02365,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.17173,14.02365",
    "IsOpen24Hours": false,
    "id": "48.17173,14.02365"
  },
  {
    "ID": 297848455,
    "Tankstellennummer": "A7579",
    "Name": "Wien Edelsinnstraße 16",
    "Address": "Edelsinnstraße 66",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1120,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.1724,
    "Longitude": 16.31949,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1724,16.31949",
    "IsOpen24Hours": false,
    "id": "48.1724,16.31949"
  },
  {
    "ID": 729,
    "Tankstellennummer": "0G93P",
    "Name": "Wien 11, Geiselbergstr. 47/Hauffg. 35",
    "Address": "Geiselbergstr.47/Hauffg.35",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1110,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 17495599,
    "24 hour?": "FALSE",
    "Latitude": 48.174907,
    "Longitude": 16.407979,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.174907,16.407979",
    "IsOpen24Hours": false,
    "id": "48.174907,16.407979"
  },
  {
    "ID": 8961,
    "Tankstellennummer": "0G96M",
    "Name": "Voellerndorf Truck-only, A1",
    "Address": "An Der Raststation-Lkw",
    "Address__1": "NOE",
    "City": "Völlerndorf/St.Pölten",
    "Postcode": 3385,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 800223350,
    "24 hour?": "TRUE",
    "Latitude": 48.176044,
    "Longitude": 15.556898,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.176044,15.556898",
    "IsOpen24Hours": true,
    "id": "48.176044,15.556898"
  },
  {
    "ID": 19751,
    "Tankstellennummer": 7583,
    "Name": "Wien Triesterstraße 38",
    "Address": "Triester Straße 38",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1100,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 6046279",
    "24 hour?": "TRUE",
    "Latitude": 48.1774,
    "Longitude": 16.35653,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1774,16.35653",
    "IsOpen24Hours": true,
    "id": "48.1774,16.35653"
  },
  {
    "ID": 9873,
    "Tankstellennummer": "0G95W",
    "Name": "Voellerndorf, Autobahn, PKW",
    "Address": "Autobahn St.Pölten-Pkw",
    "Address__1": "NOE",
    "City": "Völlerndorf/St.Pölten",
    "Postcode": 3385,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 27492511,
    "24 hour?": "TRUE",
    "Latitude": 48.1774881,
    "Longitude": 15.5527113,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1774881,15.5527113",
    "IsOpen24Hours": true,
    "id": "48.1774881,15.5527113"
  },
  {
    "ID": 706,
    "Tankstellennummer": "0G94I",
    "Name": "Wien 03, Arsenalstr. 5B",
    "Address": "Arsenalstraße 5b",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1030,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 17982552,
    "24 hour?": "TRUE",
    "Latitude": 48.1777,
    "Longitude": 16.3929,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1777,16.3929",
    "IsOpen24Hours": true,
    "id": "48.1777,16.3929"
  },
  {
    "ID": 1913111957,
    "Tankstellennummer": 5104,
    "Name": "eni Wien",
    "Address": "Gudrunstr. 116 - 118",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1100,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/6042372",
    "24 hour?": "FALSE",
    "Latitude": 48.17806,
    "Longitude": 16.37857,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.17806,16.37857",
    "IsOpen24Hours": false,
    "id": "48.17806,16.37857"
  },
  {
    "ID": 1117680571,
    "Tankstellennummer": "A7652",
    "Name": "Wien Eichenstraße 3a",
    "Address": "Eichenstraße 3a",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1120,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.17824,
    "Longitude": 16.34598,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.17824,16.34598",
    "IsOpen24Hours": false,
    "id": "48.17824,16.34598"
  },
  {
    "ID": 9884,
    "Tankstellennummer": "0G962",
    "Name": "Aistersheim (A8)",
    "Address": "Innkreisautobahn A8",
    "Address__1": "OOE",
    "City": "Aistersheim",
    "Postcode": 4676,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 77342232,
    "24 hour?": "TRUE",
    "Latitude": 48.1789656,
    "Longitude": 13.7470789,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1789656,13.7470789",
    "IsOpen24Hours": true,
    "id": "48.1789656,13.7470789"
  },
  {
    "ID": 2027886148,
    "Tankstellennummer": 5052,
    "Name": "eni Wien",
    "Address": "Bonygasse/Ruckergasse",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1120,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/8155136",
    "24 hour?": "FALSE",
    "Latitude": 48.179079,
    "Longitude": 16.326542,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.179079,16.326542",
    "IsOpen24Hours": false,
    "id": "48.179079,16.326542"
  },
  {
    "ID": 627,
    "Tankstellennummer": "0G96V",
    "Name": "Spratzern, Mariazellerstr. 182",
    "Address": "Mariazellerstraße 182",
    "Address__1": "NOE",
    "City": "Spratzern",
    "Postcode": 3106,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 274274960,
    "24 hour?": "TRUE",
    "Latitude": 48.1792415,
    "Longitude": 15.6155487,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1792415,15.6155487",
    "IsOpen24Hours": true,
    "id": "48.1792415,15.6155487"
  },
  {
    "ID": 754,
    "Tankstellennummer": "0G94U",
    "Name": "Wien 05, Margaretenguertel 43-45",
    "Address": "Margaretengürtel 43-45",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1050,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 15480292,
    "24 hour?": "TRUE",
    "Latitude": 48.1798,
    "Longitude": 16.3517,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1798,16.3517",
    "IsOpen24Hours": true,
    "id": "48.1798,16.3517"
  },
  {
    "ID": 9833,
    "Tankstellennummer": "0G95M",
    "Name": "Pressbaum, Hauptstr. 54",
    "Address": "Hauptstraße 54",
    "Address__1": "NOE",
    "City": "Pressbaum",
    "Postcode": 3021,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 223352543,
    "24 hour?": "FALSE",
    "Latitude": 48.1803,
    "Longitude": 16.078,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1803,16.078",
    "IsOpen24Hours": false,
    "id": "48.1803,16.078"
  },
  {
    "ID": 19639,
    "Tankstellennummer": 7555,
    "Name": "Wien 7. Haidequerstraße 2",
    "Address": "7. Haidequerstraße 2 KM 1,5",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1110,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 7685313",
    "24 hour?": "TRUE",
    "Latitude": 48.18187,
    "Longitude": 16.44451,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.18187,16.44451",
    "IsOpen24Hours": true,
    "id": "48.18187,16.44451"
  },
  {
    "ID": 748,
    "Tankstellennummer": "0G95D",
    "Name": "Wien 10, Laxenburgerstr. 2A",
    "Address": "Laxenburgerstraße",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1100,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 16044132,
    "24 hour?": "TRUE",
    "Latitude": 48.183289,
    "Longitude": 16.373414,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.183289,16.373414",
    "IsOpen24Hours": true,
    "id": "48.183289,16.373414"
  },
  {
    "ID": 1888259192,
    "Tankstellennummer": "A7665",
    "Name": "Wien Simmeringer Hauptstraße 5",
    "Address": "Simmeringer Hauptstraße 5",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1110,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.18388,
    "Longitude": 16.4059,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.18388,16.4059",
    "IsOpen24Hours": false,
    "id": "48.18388,16.4059"
  },
  {
    "ID": 34795571,
    "Tankstellennummer": 8085,
    "Name": "eni Wittau",
    "Address": "Bundesstr. 3",
    "Address__1": "",
    "City": "Wittau",
    "Postcode": 2301,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2215/3130",
    "24 hour?": "FALSE",
    "Latitude": 48.1839193,
    "Longitude": 16.5957023,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1839193,16.5957023",
    "IsOpen24Hours": false,
    "id": "48.1839193,16.5957023"
  },
  {
    "ID": 551040848,
    "Tankstellennummer": 8003,
    "Name": "eni Purkersdorf",
    "Address": "An der Stadlhütte 1E",
    "Address__1": "",
    "City": "Purkersdorf",
    "Postcode": 3002,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2231/64668",
    "24 hour?": "TRUE",
    "Latitude": 48.18495,
    "Longitude": 16.12428,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.18495,16.12428",
    "IsOpen24Hours": true,
    "id": "48.18495,16.12428"
  },
  {
    "ID": 1932671374,
    "Tankstellennummer": 5015,
    "Name": "eni Wien",
    "Address": "Erdbergstrasse 189",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1110,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/74020463",
    "24 hour?": "FALSE",
    "Latitude": 48.18596,
    "Longitude": 16.42439,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.18596,16.42439",
    "IsOpen24Hours": false,
    "id": "48.18596,16.42439"
  },
  {
    "ID": 725,
    "Tankstellennummer": "0G952",
    "Name": "Wien 03, Franzosengraben 18",
    "Address": "Franzosengraben",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1030,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 17988210,
    "24 hour?": "FALSE",
    "Latitude": 48.1868885,
    "Longitude": 16.4129331,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1868885,16.4129331",
    "IsOpen24Hours": false,
    "id": "48.1868885,16.4129331"
  },
  {
    "ID": 855466499,
    "Tankstellennummer": 6046,
    "Name": "eni Ritzlhof",
    "Address": "Kremstalstrasse 117",
    "Address__1": "",
    "City": "Ritzlhof",
    "Postcode": 4053,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7229/88351",
    "24 hour?": "FALSE",
    "Latitude": 48.18722,
    "Longitude": 14.24927,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.18722,14.24927",
    "IsOpen24Hours": false,
    "id": "48.18722,14.24927"
  },
  {
    "ID": 728,
    "Tankstellennummer": "0G95I",
    "Name": "Wien 12, Gaudenzdorfer Guertel 77",
    "Address": "Gaudenzdorfer Gürtel 75",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1120,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 18155177,
    "24 hour?": "TRUE",
    "Latitude": 48.187575,
    "Longitude": 16.339478,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.187575,16.339478",
    "IsOpen24Hours": true,
    "id": "48.187575,16.339478"
  },
  {
    "ID": 1847766626,
    "Tankstellennummer": "A9535",
    "Name": "Sankt Pölten Mariazeller Straß",
    "Address": "Mariazeller Straße 95",
    "Address__1": "",
    "City": "Sankt Pölten",
    "Postcode": 3100,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": "02742 76302",
    "24 hour?": "FALSE",
    "Latitude": 48.18769,
    "Longitude": 15.61753,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.18769,15.61753",
    "IsOpen24Hours": false,
    "id": "48.18769,15.61753"
  },
  {
    "ID": 1816236339,
    "Tankstellennummer": "A3954",
    "Name": "Marchtrenk Welser Straße 53",
    "Address": "Welser Straße 53",
    "Address__1": "",
    "City": "Marchtrenk",
    "Postcode": 4614,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.18954,
    "Longitude": 14.09827,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.18954,14.09827",
    "IsOpen24Hours": false,
    "id": "48.18954,14.09827"
  },
  {
    "ID": 2043813079,
    "Tankstellennummer": "0G97K",
    "Name": "Persenbeug, Nibelungenstrasse 68",
    "Address": "Nibelungenstraße 68",
    "Address__1": "",
    "City": "Persenbeug",
    "Postcode": 3680,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 741254840,
    "24 hour?": "FALSE",
    "Latitude": 48.1898373,
    "Longitude": 15.0960731,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1898373,15.0960731",
    "IsOpen24Hours": false,
    "id": "48.1898373,15.0960731"
  },
  {
    "ID": 773405352,
    "Tankstellennummer": 6112,
    "Name": "eni Wels - Pernau",
    "Address": "Terminalstr. 99",
    "Address__1": "",
    "City": "Wels - Pernau",
    "Postcode": 4600,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7242/78719",
    "24 hour?": "TRUE",
    "Latitude": 48.1899,
    "Longitude": 14.0733,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1899,14.0733",
    "IsOpen24Hours": true,
    "id": "48.1899,14.0733"
  },
  {
    "ID": 19426,
    "Tankstellennummer": 8676,
    "Name": "Loosdorf Gewerbestraße 8",
    "Address": "Gewerbestraße 8",
    "Address__1": "",
    "City": "Loosdorf",
    "Postcode": 3382,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02754 56773",
    "24 hour?": "TRUE",
    "Latitude": 48.19,
    "Longitude": 15.409,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.19,15.409",
    "IsOpen24Hours": true,
    "id": "48.19,15.409"
  },
  {
    "ID": 376,
    "Tankstellennummer": "0G98H",
    "Name": "Boeheimkirchen, Stockhofstr. 1",
    "Address": "Hainfelderstraße",
    "Address__1": "NOE",
    "City": "Böheimkirchen",
    "Postcode": 3071,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 27433265,
    "24 hour?": "FALSE",
    "Latitude": 48.1909328,
    "Longitude": 15.7660241,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1909328,15.7660241",
    "IsOpen24Hours": false,
    "id": "48.1909328,15.7660241"
  },
  {
    "ID": 9885,
    "Tankstellennummer": "0G963",
    "Name": "Marchtrenk, Linzerstr. 62",
    "Address": "Linzerstraße 62",
    "Address__1": "OOE",
    "City": "Marchtrenk",
    "Postcode": 4614,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 724354456,
    "24 hour?": "TRUE",
    "Latitude": 48.1914,
    "Longitude": 14.1214,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1914,14.1214",
    "IsOpen24Hours": true,
    "id": "48.1914,14.1214"
  },
  {
    "ID": 742,
    "Tankstellennummer": "0G96T",
    "Name": "Wien 13, Hietz. Kai 133-135",
    "Address": "Hietzinger Kai 133-135",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1130,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 18771451,
    "24 hour?": "TRUE",
    "Latitude": 48.1915,
    "Longitude": 16.2798,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1915,16.2798",
    "IsOpen24Hours": true,
    "id": "48.1915,16.2798"
  },
  {
    "ID": 1050523529,
    "Tankstellennummer": "A8010",
    "Name": "Neulengbach, Tullner Straße 1b",
    "Address": "Tullner Straße 1b",
    "Address__1": "",
    "City": "Neulengbach",
    "Postcode": 3040,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.19262,
    "Longitude": 15.89473,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.19262,15.89473",
    "IsOpen24Hours": false,
    "id": "48.19262,15.89473"
  },
  {
    "ID": 738,
    "Tankstellennummer": "0G96R",
    "Name": "Wien 14, Hadikgasse 252/Kefergasse",
    "Address": "Hadikgasse/Kefergasse",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1140,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 19141238,
    "24 hour?": "TRUE",
    "Latitude": 48.1927411,
    "Longitude": 16.2775969,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.1927411,16.2775969",
    "IsOpen24Hours": true,
    "id": "48.1927411,16.2775969"
  },
  {
    "ID": 722,
    "Tankstellennummer": "0GAD3",
    "Name": "Wien 03, Erdbergstr. 184",
    "Address": "Erdbergstraße 184",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1030,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 17963275,
    "24 hour?": "TRUE",
    "Latitude": 48.193174,
    "Longitude": 16.410318,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.193174,16.410318",
    "IsOpen24Hours": true,
    "id": "48.193174,16.410318"
  },
  {
    "ID": 752,
    "Tankstellennummer": "0G96P",
    "Name": "Wien 14, Linzerstr. 135-137",
    "Address": "Linzer Straße 135-137",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1140,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 19854327,
    "24 hour?": "FALSE",
    "Latitude": 48.1934,
    "Longitude": 16.3017,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1934,16.3017",
    "IsOpen24Hours": false,
    "id": "48.1934,16.3017"
  },
  {
    "ID": 1306532985,
    "Tankstellennummer": 5103,
    "Name": "eni Wien",
    "Address": "Oberzellerg. 2a",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1030,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/7136175",
    "24 hour?": "FALSE",
    "Latitude": 48.1936,
    "Longitude": 16.3968,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1936,16.3968",
    "IsOpen24Hours": false,
    "id": "48.1936,16.3968"
  },
  {
    "ID": 489222216,
    "Tankstellennummer": 5115,
    "Name": "eni24 Wien",
    "Address": "Felberst.Visavis 102",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1150,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/9824169",
    "24 hour?": "FALSE",
    "Latitude": 48.1937,
    "Longitude": 16.3214,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1937,16.3214",
    "IsOpen24Hours": false,
    "id": "48.1937,16.3214"
  },
  {
    "ID": 2091889509,
    "Tankstellennummer": "A8665",
    "Name": "Purkersdorf Tullnerbachstraße",
    "Address": "Tullnerbachstraße 77-79",
    "Address__1": "",
    "City": "Purkersdorf",
    "Postcode": 3002,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.19421,
    "Longitude": 16.14117,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.19421,16.14117",
    "IsOpen24Hours": false,
    "id": "48.19421,16.14117"
  },
  {
    "ID": 1345444305,
    "Tankstellennummer": 5123,
    "Name": "eni Wien",
    "Address": "Floragasse 7A",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1040,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(1)5051517",
    "24 hour?": "FALSE",
    "Latitude": 48.19456,
    "Longitude": 16.36808,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.19456,16.36808",
    "IsOpen24Hours": false,
    "id": "48.19456,16.36808"
  },
  {
    "ID": 751,
    "Tankstellennummer": "0G93N",
    "Name": "Wien 06, Linke Wienzeile 90-92",
    "Address": "Linke Wienzeile 90-92",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1060,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 15856962,
    "24 hour?": "FALSE",
    "Latitude": 48.1948633,
    "Longitude": 16.3537291,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1948633,16.3537291",
    "IsOpen24Hours": false,
    "id": "48.1948633,16.3537291"
  },
  {
    "ID": 1097043003,
    "Tankstellennummer": "A7656",
    "Name": "Wien Guldengasse 13",
    "Address": "Guldengasse 13",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1140,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.1953,
    "Longitude": 16.28135,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1953,16.28135",
    "IsOpen24Hours": false,
    "id": "48.1953,16.28135"
  },
  {
    "ID": 1395851903,
    "Tankstellennummer": "0GAGI",
    "Name": "Burgkirchen, Au 2",
    "Address": "Au 2",
    "Address__1": "",
    "City": "Burgkirchen",
    "Postcode": 5274,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 6766104999,
    "24 hour?": "FALSE",
    "Latitude": 48.19558,
    "Longitude": 13.10447,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.19558,13.10447",
    "IsOpen24Hours": false,
    "id": "48.19558,13.10447"
  },
  {
    "ID": 19648,
    "Tankstellennummer": 8560,
    "Name": "Neulengbach Tullner Straße 202",
    "Address": "Tullner Straße 64",
    "Address__1": "",
    "City": "Tausendblum / Neulengbach",
    "Postcode": 3040,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02772 53852",
    "24 hour?": "FALSE",
    "Latitude": 48.19653,
    "Longitude": 15.89983,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.19653,15.89983",
    "IsOpen24Hours": false,
    "id": "48.19653,15.89983"
  },
  {
    "ID": 19427,
    "Tankstellennummer": 8578,
    "Name": "Sankt Valentin Rasthausstraße 3",
    "Address": "Rasthausstraße 3",
    "Address__1": "",
    "City": "Sankt Valentin",
    "Postcode": 4300,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07435 58885",
    "24 hour?": "TRUE",
    "Latitude": 48.19676,
    "Longitude": 14.52693,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.19676,14.52693",
    "IsOpen24Hours": true,
    "id": "48.19676,14.52693"
  },
  {
    "ID": 667799384,
    "Tankstellennummer": "A9143",
    "Name": "Mitterau An der Bundesstraße 1",
    "Address": "An der Bundesstraße 1",
    "Address__1": "",
    "City": "Mitterau",
    "Postcode": 3385,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.1976,
    "Longitude": 15.48346,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1976,15.48346",
    "IsOpen24Hours": false,
    "id": "48.1976,15.48346"
  },
  {
    "ID": 19451,
    "Tankstellennummer": 7542,
    "Name": "Wien Hütteldorfer Straße 216b",
    "Address": "Hütteldorfer Straße 216b",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1140,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 9145580",
    "24 hour?": "FALSE",
    "Latitude": 48.19788,
    "Longitude": 16.27994,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.19788,16.27994",
    "IsOpen24Hours": false,
    "id": "48.19788,16.27994"
  },
  {
    "ID": 1597954519,
    "Tankstellennummer": "0GH06",
    "Name": "Poechlarn, Reichsstr. 14",
    "Address": "Reichsstraße 14",
    "Address__1": "",
    "City": "Pöchlarn",
    "Postcode": 3380,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 275720212,
    "24 hour?": "TRUE",
    "Latitude": 48.198069,
    "Longitude": 15.221268,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.198069,15.221268",
    "IsOpen24Hours": true,
    "id": "48.198069,15.221268"
  },
  {
    "ID": 9900,
    "Tankstellennummer": "0G96H",
    "Name": "St. Poelten, Noe-Ring 20",
    "Address": "Niederösterreichring 20",
    "Address__1": "NOE",
    "City": "St. Pölten",
    "Postcode": 3100,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 2742230033,
    "24 hour?": "FALSE",
    "Latitude": 48.198098,
    "Longitude": 15.639392,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.198098,15.639392",
    "IsOpen24Hours": false,
    "id": "48.198098,15.639392"
  },
  {
    "ID": 417,
    "Tankstellennummer": "0G90P",
    "Name": "Geiersberg, Pramerdorf 13",
    "Address": "Pramerdorf 13",
    "Address__1": "OOE",
    "City": "Geiersberg",
    "Postcode": 4922,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 77322288,
    "24 hour?": "FALSE",
    "Latitude": 48.1987,
    "Longitude": 13.6049,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.1987,13.6049",
    "IsOpen24Hours": false,
    "id": "48.1987,13.6049"
  },
  {
    "ID": 1623972015,
    "Tankstellennummer": "0GH14",
    "Name": "Haag, Geierau 4",
    "Address": "Geierau 4",
    "Address__1": "",
    "City": "Haag",
    "Postcode": 4680,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 77324540,
    "24 hour?": "TRUE",
    "Latitude": 48.2000533,
    "Longitude": 13.6457734,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2000533,13.6457734",
    "IsOpen24Hours": true,
    "id": "48.2000533,13.6457734"
  },
  {
    "ID": 721,
    "Tankstellennummer": "0G96U",
    "Name": "Wien 03, Erbergerlaende 30",
    "Address": "Erdbergerlände 30",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1030,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 17154826,
    "24 hour?": "TRUE",
    "Latitude": 48.2012,
    "Longitude": 16.4037,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2012,16.4037",
    "IsOpen24Hours": true,
    "id": "48.2012,16.4037"
  },
  {
    "ID": 19450,
    "Tankstellennummer": 7526,
    "Name": "Wien Wientalstraße/Auhofbrücke",
    "Address": "Wientalstraße/Auhofbrücke 40",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1140,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 9792586",
    "24 hour?": "TRUE",
    "Latitude": 48.20186,
    "Longitude": 16.23616,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.20186,16.23616",
    "IsOpen24Hours": true,
    "id": "48.20186,16.23616"
  },
  {
    "ID": 267933633,
    "Tankstellennummer": 5022,
    "Name": "eni Wien",
    "Address": "Biberhaufenweg 115",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1220,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/2823210",
    "24 hour?": "FALSE",
    "Latitude": 48.20206,
    "Longitude": 16.47318,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.20206,16.47318",
    "IsOpen24Hours": false,
    "id": "48.20206,16.47318"
  },
  {
    "ID": 751657124,
    "Tankstellennummer": 5101,
    "Name": "eni Wien",
    "Address": "Handelskai 388",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/7280259",
    "24 hour?": "FALSE",
    "Latitude": 48.2032,
    "Longitude": 16.4358,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2032,16.4358",
    "IsOpen24Hours": false,
    "id": "48.2032,16.4358"
  },
  {
    "ID": 755,
    "Tankstellennummer": "0G96O",
    "Name": "Wien 14, Maroltingergasse 5",
    "Address": "Maroltingergasse 5",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1140,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 19143237,
    "24 hour?": "TRUE",
    "Latitude": 48.2034159,
    "Longitude": 16.3028248,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2034159,16.3028248",
    "IsOpen24Hours": true,
    "id": "48.2034159,16.3028248"
  },
  {
    "ID": 1406135930,
    "Tankstellennummer": 8134,
    "Name": "eni St. Plten",
    "Address": "Wiener Strasse 65",
    "Address__1": "",
    "City": "St. Pölten",
    "Postcode": 3100,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2742/25863",
    "24 hour?": "FALSE",
    "Latitude": 48.20356,
    "Longitude": 15.63767,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.20356,15.63767",
    "IsOpen24Hours": false,
    "id": "48.20356,15.63767"
  },
  {
    "ID": 19571,
    "Tankstellennummer": 3557,
    "Name": "Haid Kremstalstraße 30",
    "Address": "Kremstalstraße 30",
    "Address__1": "",
    "City": "Haid",
    "Postcode": 4053,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07229 88344",
    "24 hour?": "FALSE",
    "Latitude": 48.20365,
    "Longitude": 14.24699,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.20365,14.24699",
    "IsOpen24Hours": false,
    "id": "48.20365,14.24699"
  },
  {
    "ID": 242078088,
    "Tankstellennummer": 8103,
    "Name": "eni24 Purkersdorf",
    "Address": "Wiener Strasse 48",
    "Address__1": "",
    "City": "Purkersdorf",
    "Postcode": 3002,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2231/62135",
    "24 hour?": "FALSE",
    "Latitude": 48.20566,
    "Longitude": 16.19258,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.20566,16.19258",
    "IsOpen24Hours": false,
    "id": "48.20566,16.19258"
  },
  {
    "ID": 727,
    "Tankstellennummer": "0G94J",
    "Name": "Wien 16, Gablenzgasse 72",
    "Address": "Gablenzgasse 72",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1160,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 14921006,
    "24 hour?": "FALSE",
    "Latitude": 48.2059,
    "Longitude": 16.3218,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2059,16.3218",
    "IsOpen24Hours": false,
    "id": "48.2059,16.3218"
  },
  {
    "ID": 869476918,
    "Tankstellennummer": 6018,
    "Name": "eni Enns",
    "Address": "Forstbergstrasse",
    "Address__1": "",
    "City": "Enns",
    "Postcode": 4470,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7223/82872",
    "24 hour?": "TRUE",
    "Latitude": 48.20671,
    "Longitude": 14.47358,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.20671,14.47358",
    "IsOpen24Hours": true,
    "id": "48.20671,14.47358"
  },
  {
    "ID": 259734902,
    "Tankstellennummer": "A8769",
    "Name": "Sankt Pölten Wiener Straße 92",
    "Address": "Wiener Straße 92",
    "Address__1": "",
    "City": "Sankt Pölten",
    "Postcode": 3100,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.20673,
    "Longitude": 15.64125,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.20673,15.64125",
    "IsOpen24Hours": false,
    "id": "48.20673,15.64125"
  },
  {
    "ID": 19677,
    "Tankstellennummer": 7556,
    "Name": "Wien Gablenzgasse/Minciostraße",
    "Address": "Gablenzgasse 109",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1150,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 9821210",
    "24 hour?": "FALSE",
    "Latitude": 48.20685,
    "Longitude": 16.31374,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.20685,16.31374",
    "IsOpen24Hours": false,
    "id": "48.20685,16.31374"
  },
  {
    "ID": 583482264,
    "Tankstellennummer": 5008,
    "Name": "eni Wien",
    "Address": "Huttengasse",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1160,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/4931672",
    "24 hour?": "FALSE",
    "Latitude": 48.20745,
    "Longitude": 16.30919,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.20745,16.30919",
    "IsOpen24Hours": false,
    "id": "48.20745,16.30919"
  },
  {
    "ID": 726,
    "Tankstellennummer": "0G95B",
    "Name": "Wien 01, Franz Josefskai 17/Morzinpl.",
    "Address": "Franz Josefskai/Morzinpl",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1010,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 15337398,
    "24 hour?": "TRUE",
    "Latitude": 48.212817,
    "Longitude": 16.375256,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.212817,16.375256",
    "IsOpen24Hours": true,
    "id": "48.212817,16.375256"
  },
  {
    "ID": 1453420946,
    "Tankstellennummer": "A3548",
    "Name": "Ried im Innkreis Schärdinger S",
    "Address": "Schärdinger Straße 16",
    "Address__1": "",
    "City": "Ried im Innkreis",
    "Postcode": 4910,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.21309,
    "Longitude": 13.48535,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.21309,13.48535",
    "IsOpen24Hours": false,
    "id": "48.21309,13.48535"
  },
  {
    "ID": 596444255,
    "Tankstellennummer": 6025,
    "Name": "eni St. Florian",
    "Address": "Am Bäckerberg 17",
    "Address__1": "",
    "City": "St. Florian",
    "Postcode": 4490,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7224/80446",
    "24 hour?": "TRUE",
    "Latitude": 48.21312,
    "Longitude": 14.39935,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.21312,14.39935",
    "IsOpen24Hours": true,
    "id": "48.21312,14.39935"
  },
  {
    "ID": 1116708469,
    "Tankstellennummer": "A7654",
    "Name": "Wien Esslinger Hauptstraße 32",
    "Address": "Esslinger Hauptstraße 32",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1220,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.2145,
    "Longitude": 16.51461,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2145,16.51461",
    "IsOpen24Hours": false,
    "id": "48.2145,16.51461"
  },
  {
    "ID": 358,
    "Tankstellennummer": "0G971",
    "Name": "Ansfelden/Autobahn, Nord",
    "Address": "Westautobahn A1",
    "Address__1": "OOE",
    "City": "Ansfelden",
    "Postcode": 4052,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 722987115,
    "24 hour?": "TRUE",
    "Latitude": 48.214988,
    "Longitude": 14.283562,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.214988,14.283562",
    "IsOpen24Hours": true,
    "id": "48.214988,14.283562"
  },
  {
    "ID": 19560,
    "Tankstellennummer": 3595,
    "Name": "Ansfelden Traunuferlandesstraße 108",
    "Address": "Traunuferstraße 108",
    "Address__1": "",
    "City": "Ansfelden",
    "Postcode": 4052,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07229 78989",
    "24 hour?": "TRUE",
    "Latitude": 48.21607,
    "Longitude": 14.27936,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.21607,14.27936",
    "IsOpen24Hours": true,
    "id": "48.21607,14.27936"
  },
  {
    "ID": 737,
    "Tankstellennummer": "0G96N",
    "Name": "Wien 02, Handelskai 276-280/Offbg.",
    "Address": "Handelskai 276-280",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1020,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 17293183,
    "24 hour?": "TRUE",
    "Latitude": 48.216659,
    "Longitude": 16.416943,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.216659,16.416943",
    "IsOpen24Hours": true,
    "id": "48.216659,16.416943"
  },
  {
    "ID": 19616,
    "Tankstellennummer": 7575,
    "Name": "Wien Groß-Enzersdorfer Straße 24-26",
    "Address": "Groß-Enzersdorfer Straße 24-26",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1220,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 2806469",
    "24 hour?": "FALSE",
    "Latitude": 48.21686,
    "Longitude": 16.49345,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.21686,16.49345",
    "IsOpen24Hours": false,
    "id": "48.21686,16.49345"
  },
  {
    "ID": 734,
    "Tankstellennummer": "0G95J",
    "Name": "Wien 17, Hernalser Hauptstr. 32-34",
    "Address": "Hernalser Hauptstraße 32-34",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1170,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 14064649,
    "24 hour?": "FALSE",
    "Latitude": 48.2171142,
    "Longitude": 16.3382813,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2171142,16.3382813",
    "IsOpen24Hours": false,
    "id": "48.2171142,16.3382813"
  },
  {
    "ID": 1105998879,
    "Tankstellennummer": "0G97J",
    "Name": "Klein Poechlarn, Wachauerstrasse 4",
    "Address": "Wachauerstraße 4",
    "Address__1": "",
    "City": "Klein Pöchlarn",
    "Postcode": 3660,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 74138008,
    "24 hour?": "FALSE",
    "Latitude": 48.21736,
    "Longitude": 15.2160691,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.21736,15.2160691",
    "IsOpen24Hours": false,
    "id": "48.21736,15.2160691"
  },
  {
    "ID": 1359100731,
    "Tankstellennummer": "0GH44",
    "Name": "Tumeltsham, Hannesgrub Sued 3",
    "Address": "Hannesgrub Süd 3",
    "Address__1": "",
    "City": "Tumeltsham",
    "Postcode": 4911,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 775288823,
    "24 hour?": "FALSE",
    "Latitude": 48.2180558,
    "Longitude": 13.4947438,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2180558,13.4947438",
    "IsOpen24Hours": false,
    "id": "48.2180558,13.4947438"
  },
  {
    "ID": 643910843,
    "Tankstellennummer": "A8666",
    "Name": "Gablitz Linzer Straße 1a",
    "Address": "Linzer Straße 1a",
    "Address__1": "",
    "City": "Gablitz",
    "Postcode": 3003,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.21888,
    "Longitude": 16.16551,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.21888,16.16551",
    "IsOpen24Hours": false,
    "id": "48.21888,16.16551"
  },
  {
    "ID": 19759,
    "Tankstellennummer": 8675,
    "Name": "Melk Wachbergstraße 1",
    "Address": "Wachbergstraße 3",
    "Address__1": "",
    "City": "Melk",
    "Postcode": 3390,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-2752-52933",
    "24 hour?": "TRUE",
    "Latitude": 48.21918,
    "Longitude": 15.34834,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.21918,15.34834",
    "IsOpen24Hours": true,
    "id": "48.21918,15.34834"
  },
  {
    "ID": 723,
    "Tankstellennummer": "0G94Z",
    "Name": "Wien 22, Erzherzog Karlstr. 253",
    "Address": "Erzherzog-Karl-Straße 253",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1220,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 12821480,
    "24 hour?": "FALSE",
    "Latitude": 48.2206,
    "Longitude": 16.4799,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2206,16.4799",
    "IsOpen24Hours": false,
    "id": "48.2206,16.4799"
  },
  {
    "ID": 1076933309,
    "Tankstellennummer": 5021,
    "Name": "eni Wien",
    "Address": "Donaustadtstrasse 43",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1220,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/2024043",
    "24 hour?": "TRUE",
    "Latitude": 48.22231,
    "Longitude": 16.44413,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.22231,16.44413",
    "IsOpen24Hours": true,
    "id": "48.22231,16.44413"
  },
  {
    "ID": 19561,
    "Tankstellennummer": 3538,
    "Name": "Asten An der Bundesstraße 1",
    "Address": "An der Bundesstraße 1 1",
    "Address__1": "",
    "City": "Asten",
    "Postcode": 4481,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07224 66172",
    "24 hour?": "TRUE",
    "Latitude": 48.22243,
    "Longitude": 14.40833,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.22243,14.40833",
    "IsOpen24Hours": true,
    "id": "48.22243,14.40833"
  },
  {
    "ID": 673,
    "Tankstellennummer": "0G942",
    "Name": "Grein, Ufer 4",
    "Address": "Ufer 4",
    "Address__1": "OOE",
    "City": "Grein",
    "Postcode": 4360,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 48.2229,
    "Longitude": 14.8503,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2229,14.8503",
    "IsOpen24Hours": false,
    "id": "48.2229,14.8503"
  },
  {
    "ID": 741574205,
    "Tankstellennummer": 8037,
    "Name": "eni St. Pölten",
    "Address": "S 33- Schnellstrasse",
    "Address__1": "",
    "City": "St. Pölten",
    "Postcode": 3100,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2742/23007",
    "24 hour?": "TRUE",
    "Latitude": 48.22339,
    "Longitude": 15.67863,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.22339,15.67863",
    "IsOpen24Hours": true,
    "id": "48.22339,15.67863"
  },
  {
    "ID": 19664,
    "Tankstellennummer": 8549,
    "Name": "Sankt Pölten Dr.Adolf Schärf-Straße 1",
    "Address": "Dr.Adolf Schärf-Straße 1",
    "Address__1": "",
    "City": "Sankt Pölten",
    "Postcode": 3100,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02742 348236",
    "24 hour?": "TRUE",
    "Latitude": 48.22376,
    "Longitude": 15.63452,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.22376,15.63452",
    "IsOpen24Hours": true,
    "id": "48.22376,15.63452"
  },
  {
    "ID": 763264043,
    "Tankstellennummer": "0GAB8",
    "Name": "Leopoldsdorf im Marchfeld, Hauptstr. 22",
    "Address": "Hauptstraße 22",
    "Address__1": "",
    "City": "Leopoldsdorf im Marchfeld",
    "Postcode": 2285,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 2216202363,
    "24 hour?": "FALSE",
    "Latitude": 48.2258029,
    "Longitude": 16.6952783,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2258029,16.6952783",
    "IsOpen24Hours": false,
    "id": "48.2258029,16.6952783"
  },
  {
    "ID": 1852666442,
    "Tankstellennummer": "A7511",
    "Name": "Wien Gaussplatz 1",
    "Address": "Gaussplatz 1",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1020,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.22588,
    "Longitude": 16.3708,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.22588,16.3708",
    "IsOpen24Hours": false,
    "id": "48.22588,16.3708"
  },
  {
    "ID": 7070,
    "Tankstellennummer": "0G94A",
    "Name": "Traun, Kremstal Bundesstr. 76",
    "Address": "Kremstal Bundestraße 76",
    "Address__1": "OOE",
    "City": "Traun",
    "Postcode": 4050,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 722961291,
    "24 hour?": "TRUE",
    "Latitude": 48.2271886,
    "Longitude": 14.2343832,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2271886,14.2343832",
    "IsOpen24Hours": true,
    "id": "48.2271886,14.2343832"
  },
  {
    "ID": 571342476,
    "Tankstellennummer": 5118,
    "Name": "eni Wien",
    "Address": "Gersthoferstr.geg. 13",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1180,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/4793107",
    "24 hour?": "FALSE",
    "Latitude": 48.2274,
    "Longitude": 16.3266,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2274,16.3266",
    "IsOpen24Hours": false,
    "id": "48.2274,16.3266"
  },
  {
    "ID": 60253311,
    "Tankstellennummer": 5054,
    "Name": "eni Wien",
    "Address": "Althanstrasse 14",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1090,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/3192664",
    "24 hour?": "FALSE",
    "Latitude": 48.2291,
    "Longitude": 16.3589,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2291,16.3589",
    "IsOpen24Hours": false,
    "id": "48.2291,16.3589"
  },
  {
    "ID": 359,
    "Tankstellennummer": "0G98J",
    "Name": "Ansfelden, Traunuferstr. 4/Frein",
    "Address": "Traunuferstraße 4",
    "Address__1": "OOE",
    "City": "Ansfelden-Freindorf",
    "Postcode": 4052,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 6649132069,
    "24 hour?": "FALSE",
    "Latitude": 48.2308289,
    "Longitude": 14.3050653,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2308289,14.3050653",
    "IsOpen24Hours": false,
    "id": "48.2308289,14.3050653"
  },
  {
    "ID": 19570,
    "Tankstellennummer": 3523,
    "Name": "Grieskirchen Wengerstraße 16",
    "Address": "Wengerstraße 16",
    "Address__1": "",
    "City": "Grieskirchen",
    "Postcode": 4710,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07248 68281",
    "24 hour?": "FALSE",
    "Latitude": 48.2327,
    "Longitude": 13.82238,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2327,13.82238",
    "IsOpen24Hours": false,
    "id": "48.2327,13.82238"
  },
  {
    "ID": 1854718375,
    "Tankstellennummer": "0GH17",
    "Name": "Traun, Wiener Bundesstr. 153",
    "Address": "Wiener Bundesstraße 153",
    "Address__1": "",
    "City": "Traun",
    "Postcode": 4050,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 722973209,
    "24 hour?": "TRUE",
    "Latitude": 48.2343935,
    "Longitude": 14.2330226,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2343935,14.2330226",
    "IsOpen24Hours": true,
    "id": "48.2343935,14.2330226"
  },
  {
    "ID": 202517708,
    "Tankstellennummer": 5065,
    "Name": "eni Wien",
    "Address": "Jägerstrasse 60",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1200,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/3322304",
    "24 hour?": "FALSE",
    "Latitude": 48.23488,
    "Longitude": 16.3702,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.23488,16.3702",
    "IsOpen24Hours": false,
    "id": "48.23488,16.3702"
  },
  {
    "ID": 1380216707,
    "Tankstellennummer": "A3744",
    "Name": "Traun/St.Martin Leondinger Str",
    "Address": "Leondinger Straße 47",
    "Address__1": "",
    "City": "Traun/St.Martin",
    "Postcode": 4050,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.23916,
    "Longitude": 14.2622,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.23916,14.2622",
    "IsOpen24Hours": false,
    "id": "48.23916,14.2622"
  },
  {
    "ID": 703835927,
    "Tankstellennummer": "0GH07",
    "Name": "Perg, Naarner Str. 73",
    "Address": "Naarner Straße 73",
    "Address__1": "",
    "City": "Perg",
    "Postcode": 4320,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 726253235,
    "24 hour?": "FALSE",
    "Latitude": 48.2394955,
    "Longitude": 14.6262131,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2394955,14.6262131",
    "IsOpen24Hours": false,
    "id": "48.2394955,14.6262131"
  },
  {
    "ID": 746,
    "Tankstellennummer": "0G95C",
    "Name": "Wien 19, Krottenbachstr. 17",
    "Address": "Krottenbachstraße 17",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1190,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 13681379,
    "24 hour?": "FALSE",
    "Latitude": 48.240355,
    "Longitude": 16.341965,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.240355,16.341965",
    "IsOpen24Hours": false,
    "id": "48.240355,16.341965"
  },
  {
    "ID": 1667268771,
    "Tankstellennummer": 5119,
    "Name": "eni Wien",
    "Address": "Krottenbachstr. 32-34",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1190,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/3696149",
    "24 hour?": "FALSE",
    "Latitude": 48.2407,
    "Longitude": 16.3393,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2407,16.3393",
    "IsOpen24Hours": false,
    "id": "48.2407,16.3393"
  },
  {
    "ID": 136428437,
    "Tankstellennummer": "0G950",
    "Name": "20,DRESDNERSTRASSE 8",
    "Address": "Dresdnerstraße 8",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1200,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 13304231,
    "24 hour?": "TRUE",
    "Latitude": 48.2408,
    "Longitude": 16.3746,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2408,16.3746",
    "IsOpen24Hours": true,
    "id": "48.2408,16.3746"
  },
  {
    "ID": 317549996,
    "Tankstellennummer": "0GH43",
    "Name": "Mauthausen, Dieselstr. 1",
    "Address": "Dieselstraße 1",
    "Address__1": "",
    "City": "Mauthausen",
    "Postcode": 4310,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 72383320,
    "24 hour?": "FALSE",
    "Latitude": 48.241435,
    "Longitude": 14.544579,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.241435,14.544579",
    "IsOpen24Hours": false,
    "id": "48.241435,14.544579"
  },
  {
    "ID": 813508130,
    "Tankstellennummer": 7509,
    "Name": "Wien Wagramer Straße 79 Donaup",
    "Address": "Wagramer Straße 79",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1220,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 2039669",
    "24 hour?": "FALSE",
    "Latitude": 48.2416,
    "Longitude": 16.43348,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2416,16.43348",
    "IsOpen24Hours": false,
    "id": "48.2416,16.43348"
  },
  {
    "ID": 533914143,
    "Tankstellennummer": 6002,
    "Name": "eni Linz",
    "Address": "Dallingerstrasse 11",
    "Address__1": "",
    "City": "Linz",
    "Postcode": 4030,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/370019",
    "24 hour?": "TRUE",
    "Latitude": 48.24226,
    "Longitude": 14.29119,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.24226,14.29119",
    "IsOpen24Hours": true,
    "id": "48.24226,14.29119"
  },
  {
    "ID": 19459,
    "Tankstellennummer": 7548,
    "Name": "Wien Hirschstettner Straße 34",
    "Address": "Hirschstettner Straße 34",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1220,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 2040012",
    "24 hour?": "FALSE",
    "Latitude": 48.24285,
    "Longitude": 16.45645,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.24285,16.45645",
    "IsOpen24Hours": false,
    "id": "48.24285,16.45645"
  },
  {
    "ID": 204551731,
    "Tankstellennummer": 7508,
    "Name": "Wien Wagramer Straße 94 Donauz",
    "Address": "Wagramer Straße 94",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1220,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 2021131",
    "24 hour?": "FALSE",
    "Latitude": 48.24288,
    "Longitude": 16.43842,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.24288,16.43842",
    "IsOpen24Hours": false,
    "id": "48.24288,16.43842"
  },
  {
    "ID": 19454,
    "Tankstellennummer": 7501,
    "Name": "Wien Adalbert Stifter-Straße 67",
    "Address": "Adalbert Stifter-Straße 67",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1200,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 3303242",
    "24 hour?": "FALSE",
    "Latitude": 48.24309,
    "Longitude": 16.37535,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.24309,16.37535",
    "IsOpen24Hours": false,
    "id": "48.24309,16.37535"
  },
  {
    "ID": 735,
    "Tankstellennummer": "0GAD1",
    "Name": "Wien 20, Handelskai 90",
    "Address": "Handelskai 90",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1200,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 800223350,
    "24 hour?": "FALSE",
    "Latitude": 48.2431,
    "Longitude": 16.3841,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2431,16.3841",
    "IsOpen24Hours": false,
    "id": "48.2431,16.3841"
  },
  {
    "ID": 1351704183,
    "Tankstellennummer": "A3606",
    "Name": "Mauthausen Machlandstraße 5",
    "Address": "Machlandstraße 5",
    "Address__1": "",
    "City": "Mauthausen",
    "Postcode": 4310,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.24347,
    "Longitude": 14.53427,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.24347,14.53427",
    "IsOpen24Hours": false,
    "id": "48.24347,14.53427"
  },
  {
    "ID": 739,
    "Tankstellennummer": "0G95A",
    "Name": "Wien 19, Heiligenstaedterstr. 77",
    "Address": "Heiligenstädterstraße 77",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1190,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 13681494,
    "24 hour?": "TRUE",
    "Latitude": 48.2440263,
    "Longitude": 16.3615186,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2440263,16.3615186",
    "IsOpen24Hours": true,
    "id": "48.2440263,16.3615186"
  },
  {
    "ID": 19453,
    "Tankstellennummer": 7529,
    "Name": "Wien Gunoldstraße 7/Muthgasse 1",
    "Address": "Gunoldstraße 7 / Muthgasse 1",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1190,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 3676565",
    "24 hour?": "TRUE",
    "Latitude": 48.24531,
    "Longitude": 16.36578,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.24531,16.36578",
    "IsOpen24Hours": true,
    "id": "48.24531,16.36578"
  },
  {
    "ID": 19604,
    "Tankstellennummer": 3583,
    "Name": "Braunau Salzburger Straße 99",
    "Address": "Salzburger Straße 99",
    "Address__1": "",
    "City": "Braunau",
    "Postcode": 5280,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07722 87227",
    "24 hour?": "FALSE",
    "Latitude": 48.24673,
    "Longitude": 13.0403,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.24673,13.0403",
    "IsOpen24Hours": false,
    "id": "48.24673,13.0403"
  },
  {
    "ID": 9901,
    "Tankstellennummer": "0G96I",
    "Name": "Linz, Salzburgerstr. 383",
    "Address": "Salzburgerstraße 383",
    "Address__1": "OOE",
    "City": "Linz",
    "Postcode": 4030,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 732372021,
    "24 hour?": "TRUE",
    "Latitude": 48.247276,
    "Longitude": 14.264803,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.247276,14.264803",
    "IsOpen24Hours": true,
    "id": "48.247276,14.264803"
  },
  {
    "ID": 769,
    "Tankstellennummer": "0G957",
    "Name": "Wien 22, Wr. Nordrandstr. B302 Gew. Park Stadlau",
    "Address": "Gewerbeparkstr.On1 Gew.Park Stadlau",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1220,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 17344935,
    "24 hour?": "TRUE",
    "Latitude": 48.2476153,
    "Longitude": 16.4658336,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2476153,16.4658336",
    "IsOpen24Hours": true,
    "id": "48.2476153,16.4658336"
  },
  {
    "ID": 1878557634,
    "Tankstellennummer": "A1201",
    "Name": "LHLM",
    "Address": "Neue Innbrückenstraße 2",
    "Address__1": "",
    "City": "Osternberg",
    "Postcode": 5282,
    "Country": "Austria",
    "Brand": "UB",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 48.24818,
    "Longitude": 13.014723,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.24818,13.014723",
    "IsOpen24Hours": false,
    "id": "48.24818,13.014723"
  },
  {
    "ID": 537428986,
    "Tankstellennummer": "0GAFM",
    "Name": "Braunau, Neue Innbrueckenstrasse 2",
    "Address": "Neue Innbrückenstraße 2",
    "Address__1": "",
    "City": "Braunau am Inn",
    "Postcode": 5280,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 5083007000,
    "24 hour?": "FALSE",
    "Latitude": 48.248524,
    "Longitude": 13.015532,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.248524,13.015532",
    "IsOpen24Hours": false,
    "id": "48.248524,13.015532"
  },
  {
    "ID": 377399651,
    "Tankstellennummer": "A3012",
    "Name": "Linz Franzosenhausweg 1",
    "Address": "Franzosenhausweg 1",
    "Address__1": "",
    "City": "Linz",
    "Postcode": 4020,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.24886,
    "Longitude": 14.29472,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.24886,14.29472",
    "IsOpen24Hours": false,
    "id": "48.24886,14.29472"
  },
  {
    "ID": 154621728,
    "Tankstellennummer": "0GH31",
    "Name": "Weng, Pirath 18",
    "Address": "Pirath 18",
    "Address__1": "",
    "City": "Weng",
    "Postcode": 4952,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 772344424,
    "24 hour?": "FALSE",
    "Latitude": 48.2494394,
    "Longitude": 13.2090305,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2494394,13.2090305",
    "IsOpen24Hours": false,
    "id": "48.2494394,13.2090305"
  },
  {
    "ID": 19624,
    "Tankstellennummer": 7546,
    "Name": "Wien Breitenleer Straße West B 302",
    "Address": "Breitenleer Straße West B302 108",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1220,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 7342707",
    "24 hour?": "TRUE",
    "Latitude": 48.25108,
    "Longitude": 16.47035,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.25108,16.47035",
    "IsOpen24Hours": true,
    "id": "48.25108,16.47035"
  },
  {
    "ID": 19458,
    "Tankstellennummer": 7547,
    "Name": "Wien Breitenleer Straße Ost B 302",
    "Address": "Breitenleer Straße Ost B302 112",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1220,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 7343236",
    "24 hour?": "TRUE",
    "Latitude": 48.25141,
    "Longitude": 16.47211,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.25141,16.47211",
    "IsOpen24Hours": true,
    "id": "48.25141,16.47211"
  },
  {
    "ID": 19603,
    "Tankstellennummer": 3582,
    "Name": "Altheim St. Ulrich 6",
    "Address": "St. Ulrich 6",
    "Address__1": "",
    "City": "Altheim",
    "Postcode": 4950,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07723 42552",
    "24 hour?": "FALSE",
    "Latitude": 48.25413,
    "Longitude": 13.23644,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.25413,13.23644",
    "IsOpen24Hours": false,
    "id": "48.25413,13.23644"
  },
  {
    "ID": 1385144971,
    "Tankstellennummer": "A8733",
    "Name": "Sieghartskirchen Wiener Straße",
    "Address": "Wiener Straße 26",
    "Address__1": "",
    "City": "Sieghartskirchen",
    "Postcode": 3443,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.25451,
    "Longitude": 16.01891,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.25451,16.01891",
    "IsOpen24Hours": false,
    "id": "48.25451,16.01891"
  },
  {
    "ID": 382089504,
    "Tankstellennummer": 6052,
    "Name": "eni Braunau",
    "Address": "Umfahrungsstrasse",
    "Address__1": "",
    "City": "Braunau",
    "Postcode": 5280,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7722/63280",
    "24 hour?": "TRUE",
    "Latitude": 48.254691,
    "Longitude": 13.045174,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.254691,13.045174",
    "IsOpen24Hours": true,
    "id": "48.254691,13.045174"
  },
  {
    "ID": 1315000448,
    "Tankstellennummer": "A7717",
    "Name": "Wien Wagramer Straße 179",
    "Address": "Wagramer Straße 179",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1210,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.25964,
    "Longitude": 16.45031,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.25964,16.45031",
    "IsOpen24Hours": false,
    "id": "48.25964,16.45031"
  },
  {
    "ID": 111068038,
    "Tankstellennummer": "A3574",
    "Name": "Braunau Laabstraße",
    "Address": "Laabstraße",
    "Address__1": "",
    "City": "Braunau",
    "Postcode": 5280,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.26028,
    "Longitude": 13.05145,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.26028,13.05145",
    "IsOpen24Hours": false,
    "id": "48.26028,13.05145"
  },
  {
    "ID": 201522957,
    "Tankstellennummer": 5108,
    "Name": "eni Wien",
    "Address": "Leopoldauerstr. 180",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1210,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/2582569",
    "24 hour?": "TRUE",
    "Latitude": 48.2624,
    "Longitude": 16.4359,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2624,16.4359",
    "IsOpen24Hours": true,
    "id": "48.2624,16.4359"
  },
  {
    "ID": 568291222,
    "Tankstellennummer": 5020,
    "Name": "eni Wien",
    "Address": "Rautenweg 13",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1222,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)1/2597962",
    "24 hour?": "TRUE",
    "Latitude": 48.26272,
    "Longitude": 16.46121,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.26272,16.46121",
    "IsOpen24Hours": true,
    "id": "48.26272,16.46121"
  },
  {
    "ID": 393582911,
    "Tankstellennummer": "A3753",
    "Name": "Linz Wahringerstraße",
    "Address": "Wahringerstraße",
    "Address__1": "",
    "City": "Linz",
    "Postcode": 4030,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.26326,
    "Longitude": 14.3232,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.26326,14.3232",
    "IsOpen24Hours": false,
    "id": "48.26326,14.3232"
  },
  {
    "ID": 19457,
    "Tankstellennummer": 7514,
    "Name": "Wien Prager Straße 32",
    "Address": "Prager Straße 32-34",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1210,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 2711319",
    "24 hour?": "TRUE",
    "Latitude": 48.26513,
    "Longitude": 16.39386,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.26513,16.39386",
    "IsOpen24Hours": true,
    "id": "48.26513,16.39386"
  },
  {
    "ID": 6339904,
    "Tankstellennummer": "A7713",
    "Name": "Wien Julius Ficker Straße 77",
    "Address": "Julius Ficker Straße 77",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1210,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.26672,
    "Longitude": 16.44859,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.26672,16.44859",
    "IsOpen24Hours": false,
    "id": "48.26672,16.44859"
  },
  {
    "ID": 707,
    "Tankstellennummer": "0G95H",
    "Name": "Wien 21, Bruennerstr. 57A",
    "Address": "Brünnerstraße 57c",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1210,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 12782239,
    "24 hour?": "TRUE",
    "Latitude": 48.267139,
    "Longitude": 16.402564,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.267139,16.402564",
    "IsOpen24Hours": true,
    "id": "48.267139,16.402564"
  },
  {
    "ID": 1324374001,
    "Tankstellennummer": "0GH02",
    "Name": "Wien, Siemensstr. 105 B",
    "Address": "Siemensstraße 105b",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1210,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 12580094,
    "24 hour?": "FALSE",
    "Latitude": 48.267149,
    "Longitude": 16.428972,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.267149,16.428972",
    "IsOpen24Hours": false,
    "id": "48.267149,16.428972"
  },
  {
    "ID": 87249014,
    "Tankstellennummer": "0GH33",
    "Name": "Steyr-Gleink, Ennser Str. 95",
    "Address": "Ennserstraße 95",
    "Address__1": "",
    "City": "Steyr-Gleink",
    "Postcode": 4407,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 732664001,
    "24 hour?": "FALSE",
    "Latitude": 48.2673928,
    "Longitude": 16.4293918,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2673928,16.4293918",
    "IsOpen24Hours": false,
    "id": "48.2673928,16.4293918"
  },
  {
    "ID": 19780,
    "Tankstellennummer": 8719,
    "Name": "Einsiedl Mühlfeldstrasse 2",
    "Address": "Mühlfeldstraße 2",
    "Address__1": "",
    "City": "Einsiedl",
    "Postcode": 3441,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02274 21017",
    "24 hour?": "TRUE",
    "Latitude": 48.26998,
    "Longitude": 15.97126,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.26998,15.97126",
    "IsOpen24Hours": true,
    "id": "48.26998,15.97126"
  },
  {
    "ID": 1461717596,
    "Tankstellennummer": 7005,
    "Name": "Wien Shuttleworthstraße 10",
    "Address": "Shuttleworthstraße 10",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1210,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 2903740",
    "24 hour?": "TRUE",
    "Latitude": 48.2705,
    "Longitude": 16.41214,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2705,16.41214",
    "IsOpen24Hours": true,
    "id": "48.2705,16.41214"
  },
  {
    "ID": 1500678028,
    "Tankstellennummer": "A3505",
    "Name": "Leonding Paschinger Straße 54",
    "Address": "Paschinger Straße 54",
    "Address__1": "",
    "City": "Leonding",
    "Postcode": 4060,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.27299,
    "Longitude": 14.25294,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.27299,14.25294",
    "IsOpen24Hours": false,
    "id": "48.27299,14.25294"
  },
  {
    "ID": 2046662982,
    "Tankstellennummer": 6014,
    "Name": "eni Leonding-Gaumbg.",
    "Address": "Welser Strasse 8",
    "Address__1": "",
    "City": "Leonding-Gaumbg.",
    "Postcode": 4060,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/671776",
    "24 hour?": "TRUE",
    "Latitude": 48.2747,
    "Longitude": 14.27285,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2747,14.27285",
    "IsOpen24Hours": true,
    "id": "48.2747,14.27285"
  },
  {
    "ID": 19573,
    "Tankstellennummer": 3746,
    "Name": "Leonding Hainzenbachstraße 18",
    "Address": "Hainzenbachstraße 18",
    "Address__1": "",
    "City": "Leonding",
    "Postcode": 4060,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0732 672547",
    "24 hour?": "FALSE",
    "Latitude": 48.27868,
    "Longitude": 14.2457,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.27868,14.2457",
    "IsOpen24Hours": false,
    "id": "48.27868,14.2457"
  },
  {
    "ID": 19608,
    "Tankstellennummer": 8583,
    "Name": "Herzogenburg St. Pöltner Straße 54",
    "Address": "St. Pöltner Straße 54",
    "Address__1": "",
    "City": "Herzogenburg",
    "Postcode": 3130,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02782 84981",
    "24 hour?": "TRUE",
    "Latitude": 48.27876,
    "Longitude": 15.69564,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.27876,15.69564",
    "IsOpen24Hours": true,
    "id": "48.27876,15.69564"
  },
  {
    "ID": 19455,
    "Tankstellennummer": 7550,
    "Name": "Wien Brünner Straße 172",
    "Address": "Brünner Straße 172",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1210,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "01 2921258",
    "24 hour?": "TRUE",
    "Latitude": 48.28114,
    "Longitude": 16.41181,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.28114,16.41181",
    "IsOpen24Hours": true,
    "id": "48.28114,16.41181"
  },
  {
    "ID": 720,
    "Tankstellennummer": "0G953",
    "Name": "Wien 21, Einzingerstr. 2",
    "Address": "Einzingergasse 2",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1210,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 12704191,
    "24 hour?": "TRUE",
    "Latitude": 48.2825,
    "Longitude": 16.3799,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2825,16.3799",
    "IsOpen24Hours": true,
    "id": "48.2825,16.3799"
  },
  {
    "ID": 380874344,
    "Tankstellennummer": "A3540",
    "Name": "Linz Unionstraße 71a",
    "Address": "Unionstraße 71a",
    "Address__1": "",
    "City": "Linz",
    "Postcode": 4020,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.28312,
    "Longitude": 14.28828,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.28312,14.28828",
    "IsOpen24Hours": false,
    "id": "48.28312,14.28828"
  },
  {
    "ID": 9895,
    "Tankstellennummer": "0G96A",
    "Name": "Wien 21, Bruennerstr. 186",
    "Address": "Brünnerstraße 186",
    "Address__1": "W",
    "City": "Wien",
    "Postcode": 1210,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 12926869,
    "24 hour?": "TRUE",
    "Latitude": 48.2837235,
    "Longitude": 16.4133932,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.2837235,16.4133932",
    "IsOpen24Hours": true,
    "id": "48.2837235,16.4133932"
  },
  {
    "ID": 1172480427,
    "Tankstellennummer": "0GH41",
    "Name": "Linz, Stahlstr. 5",
    "Address": "Stahlstraße 5",
    "Address__1": "",
    "City": "Linz",
    "Postcode": 4020,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 732653135,
    "24 hour?": "FALSE",
    "Latitude": 48.2845255,
    "Longitude": 14.3201387,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2845255,14.3201387",
    "IsOpen24Hours": false,
    "id": "48.2845255,14.3201387"
  },
  {
    "ID": 2005028027,
    "Tankstellennummer": "A7657",
    "Name": "Wien Heiligenstädter Straße 19",
    "Address": "Heiligenstädter Straße 196",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1190,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.28511,
    "Longitude": 16.34549,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.28511,16.34549",
    "IsOpen24Hours": false,
    "id": "48.28511,16.34549"
  },
  {
    "ID": 1335873904,
    "Tankstellennummer": 6016,
    "Name": "eni Steyregg",
    "Address": "Windegg 4",
    "Address__1": "",
    "City": "Steyregg",
    "Postcode": 4221,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/641175",
    "24 hour?": "FALSE",
    "Latitude": 48.2859,
    "Longitude": 14.3516,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2859,14.3516",
    "IsOpen24Hours": false,
    "id": "48.2859,14.3516"
  },
  {
    "ID": 19575,
    "Tankstellennummer": 3717,
    "Name": "Linz Bulgariplatz/Makartstraße 29",
    "Address": "Makartstraße 29",
    "Address__1": "",
    "City": "Linz",
    "Postcode": 4020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0732 653180",
    "24 hour?": "FALSE",
    "Latitude": 48.28592,
    "Longitude": 14.30255,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.28592,14.30255",
    "IsOpen24Hours": false,
    "id": "48.28592,14.30255"
  },
  {
    "ID": 1601089938,
    "Tankstellennummer": "A7577",
    "Name": "Wien Prager Straße 270-272",
    "Address": "Prager Straße 270-272",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1210,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.28664,
    "Longitude": 16.38404,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.28664,16.38404",
    "IsOpen24Hours": false,
    "id": "48.28664,16.38404"
  },
  {
    "ID": 19628,
    "Tankstellennummer": 8575,
    "Name": "Klosterneuburg Wiener Straße 49-53",
    "Address": "Wiener Straße 49-53",
    "Address__1": "",
    "City": "Klosterneuburg",
    "Postcode": 3400,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02243 38882",
    "24 hour?": "TRUE",
    "Latitude": 48.28666,
    "Longitude": 16.34386,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.28666,16.34386",
    "IsOpen24Hours": true,
    "id": "48.28666,16.34386"
  },
  {
    "ID": 28554019,
    "Tankstellennummer": "A3587",
    "Name": "Alkoven Strass 16",
    "Address": "Strass 16",
    "Address__1": "",
    "City": "Alkoven",
    "Postcode": 4072,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.28792,
    "Longitude": 14.07613,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.28792,14.07613",
    "IsOpen24Hours": false,
    "id": "48.28792,14.07613"
  },
  {
    "ID": 1609824291,
    "Tankstellennummer": 6008,
    "Name": "eni Alkoven",
    "Address": "Linzer Strasse 15",
    "Address__1": "",
    "City": "Alkoven",
    "Postcode": 4072,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7274/6360",
    "24 hour?": "TRUE",
    "Latitude": 48.28863,
    "Longitude": 14.11763,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.28863,14.11763",
    "IsOpen24Hours": true,
    "id": "48.28863,14.11763"
  },
  {
    "ID": 555,
    "Tankstellennummer": "0G92E",
    "Name": "Michaelnbach, Stauffstr. 6",
    "Address": "Stauffstraße 6",
    "Address__1": "OOE",
    "City": "Michaelnbach",
    "Postcode": 4712,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 72772520,
    "24 hour?": "FALSE",
    "Latitude": 48.2891,
    "Longitude": 13.8334,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2891,13.8334",
    "IsOpen24Hours": false,
    "id": "48.2891,13.8334"
  },
  {
    "ID": 395433041,
    "Tankstellennummer": 8131,
    "Name": "eni Klosterneuburg",
    "Address": "Wienerstr. 182",
    "Address__1": "",
    "City": "Klosterneuburg",
    "Postcode": 3400,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2243/37491",
    "24 hour?": "FALSE",
    "Latitude": 48.2907,
    "Longitude": 16.3385,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2907,16.3385",
    "IsOpen24Hours": false,
    "id": "48.2907,16.3385"
  },
  {
    "ID": 418,
    "Tankstellennummer": "0G93R",
    "Name": "Gerasdorf, Leopoldauerstr. 7",
    "Address": "Leopoldauerstraße 1",
    "Address__1": "NOE",
    "City": "Gerasdorf",
    "Postcode": 2201,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 22462334,
    "24 hour?": "FALSE",
    "Latitude": 48.2942543,
    "Longitude": 16.4675981,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2942543,16.4675981",
    "IsOpen24Hours": false,
    "id": "48.2942543,16.4675981"
  },
  {
    "ID": 184423823,
    "Tankstellennummer": "0GH23",
    "Name": "Linz, Franckstr. 17",
    "Address": "Franckstraße 17",
    "Address__1": "",
    "City": "Linz",
    "Postcode": 4020,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 732654562,
    "24 hour?": "FALSE",
    "Latitude": 48.296395,
    "Longitude": 14.308057,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.296395,14.308057",
    "IsOpen24Hours": false,
    "id": "48.296395,14.308057"
  },
  {
    "ID": 1773901690,
    "Tankstellennummer": "A8023",
    "Name": "Langenzersdorf Wiener Straße 1",
    "Address": "Wiener Straße 176-196",
    "Address__1": "",
    "City": "Langenzersdorf",
    "Postcode": 2103,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.2997,
    "Longitude": 16.37405,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.2997,16.37405",
    "IsOpen24Hours": false,
    "id": "48.2997,16.37405"
  },
  {
    "ID": 434034765,
    "Tankstellennummer": 8031,
    "Name": "eni Klosterneuburg",
    "Address": "Wiener Strasse 5",
    "Address__1": "",
    "City": "Klosterneuburg",
    "Postcode": 3400,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2243/32533",
    "24 hour?": "FALSE",
    "Latitude": 48.29999,
    "Longitude": 16.33266,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.29999,16.33266",
    "IsOpen24Hours": false,
    "id": "48.29999,16.33266"
  },
  {
    "ID": 1282371191,
    "Tankstellennummer": "A8602",
    "Name": "Deutsch-Wagram Gänserndorfer S",
    "Address": "Gänserndorfer Straße 40",
    "Address__1": "",
    "City": "Deutsch-Wagram",
    "Postcode": 2232,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.30043,
    "Longitude": 16.56957,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.30043,16.56957",
    "IsOpen24Hours": false,
    "id": "48.30043,16.56957"
  },
  {
    "ID": 1665868943,
    "Tankstellennummer": 6071,
    "Name": "eni Eferding",
    "Address": "Linzer Straße 24",
    "Address__1": "",
    "City": "Eferding",
    "Postcode": 4070,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/664001",
    "24 hour?": "FALSE",
    "Latitude": 48.30176,
    "Longitude": 14.02967,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.30176,14.02967",
    "IsOpen24Hours": false,
    "id": "48.30176,14.02967"
  },
  {
    "ID": 64282156,
    "Tankstellennummer": "A8559",
    "Name": "Klosterneuburg Wiener Straße 4",
    "Address": "Wiener Straße 42",
    "Address__1": "",
    "City": "Klosterneuburg",
    "Postcode": 3400,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.30458,
    "Longitude": 16.32988,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.30458,16.32988",
    "IsOpen24Hours": false,
    "id": "48.30458,16.32988"
  },
  {
    "ID": 596181321,
    "Tankstellennummer": 8126,
    "Name": "eni Heiligeneich",
    "Address": "Kremser Ladnstr. 33",
    "Address__1": "",
    "City": "Heiligeneich",
    "Postcode": 3452,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2275/5351",
    "24 hour?": "FALSE",
    "Latitude": 48.30459,
    "Longitude": 15.89132,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.30459,15.89132",
    "IsOpen24Hours": false,
    "id": "48.30459,15.89132"
  },
  {
    "ID": 1295948945,
    "Tankstellennummer": 6115,
    "Name": "eni24 Linz",
    "Address": "Industriezeile 58",
    "Address__1": "",
    "City": "Linz",
    "Postcode": 4020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7322/50443",
    "24 hour?": "TRUE",
    "Latitude": 48.3065,
    "Longitude": 14.31634,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.3065,14.31634",
    "IsOpen24Hours": true,
    "id": "48.3065,14.31634"
  },
  {
    "ID": 1315968422,
    "Tankstellennummer": 8022,
    "Name": "Deutsch-Wagram S1, Ost KM 38,5",
    "Address": "S1, Ost",
    "Address__1": "",
    "City": "Deutsch-Wagram",
    "Postcode": 2232,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02247 51947",
    "24 hour?": "TRUE",
    "Latitude": 48.31075,
    "Longitude": 16.51506,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.31075,16.51506",
    "IsOpen24Hours": true,
    "id": "48.31075,16.51506"
  },
  {
    "ID": 1383041318,
    "Tankstellennummer": "A7651",
    "Name": "Wien Brünner Straße 420",
    "Address": "Brünner Straße 316",
    "Address__1": "",
    "City": "Wien",
    "Postcode": 1210,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.31144,
    "Longitude": 16.43012,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.31144,16.43012",
    "IsOpen24Hours": false,
    "id": "48.31144,16.43012"
  },
  {
    "ID": 1288650406,
    "Tankstellennummer": 6105,
    "Name": "eni Linz",
    "Address": "Untere Donaulände 15",
    "Address__1": "",
    "City": "Linz",
    "Postcode": 4020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/773051",
    "24 hour?": "TRUE",
    "Latitude": 48.31378,
    "Longitude": 14.29957,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.31378,14.29957",
    "IsOpen24Hours": true,
    "id": "48.31378,14.29957"
  },
  {
    "ID": 19667,
    "Tankstellennummer": 8558,
    "Name": "Strasshof Hauptstraße 119",
    "Address": "Hauptstraße 119",
    "Address__1": "",
    "City": "Strasshof",
    "Postcode": 2231,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02287 2349",
    "24 hour?": "FALSE",
    "Latitude": 48.31458,
    "Longitude": 16.6256,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.31458,16.6256",
    "IsOpen24Hours": false,
    "id": "48.31458,16.6256"
  },
  {
    "ID": 541,
    "Tankstellennummer": "0G970",
    "Name": "Linz-Urfahr, Wildbergstr. 16",
    "Address": "Wildbergstraße 16",
    "Address__1": "OOE",
    "City": "Linz-Urfahr",
    "Postcode": 4040,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 732732586,
    "24 hour?": "TRUE",
    "Latitude": 48.3147745,
    "Longitude": 14.2859886,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.3147745,14.2859886",
    "IsOpen24Hours": true,
    "id": "48.3147745,14.2859886"
  },
  {
    "ID": 19577,
    "Tankstellennummer": 3566,
    "Name": "Linz Hafenstraße 8",
    "Address": "Hafenstraße 8",
    "Address__1": "",
    "City": "Linz",
    "Postcode": 4020,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "0732 778012",
    "24 hour?": "TRUE",
    "Latitude": 48.31554,
    "Longitude": 14.30166,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.31554,14.30166",
    "IsOpen24Hours": true,
    "id": "48.31554,14.30166"
  },
  {
    "ID": 738218395,
    "Tankstellennummer": 8030,
    "Name": "eni Straßhof",
    "Address": "Hauptstrasse 166",
    "Address__1": "",
    "City": "Straßhof",
    "Postcode": 2231,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2287/2306",
    "24 hour?": "TRUE",
    "Latitude": 48.31611,
    "Longitude": 16.63636,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.31611,16.63636",
    "IsOpen24Hours": true,
    "id": "48.31611,16.63636"
  },
  {
    "ID": 1909416852,
    "Tankstellennummer": "A3333",
    "Name": "Ort im Innkreis Kammer 19",
    "Address": "Kammer 19",
    "Address__1": "",
    "City": "Ort im Innkreis",
    "Postcode": 4974,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.31724,
    "Longitude": 13.42524,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.31724,13.42524",
    "IsOpen24Hours": false,
    "id": "48.31724,13.42524"
  },
  {
    "ID": 758534288,
    "Tankstellennummer": 8023,
    "Name": "eni Tulln - Nitzing",
    "Address": "Königstetterstrasse 154",
    "Address__1": "",
    "City": "Tulln - Nitzing",
    "Postcode": 3430,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2272/82685",
    "24 hour?": "TRUE",
    "Latitude": 48.31874,
    "Longitude": 16.08506,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.31874,16.08506",
    "IsOpen24Hours": true,
    "id": "48.31874,16.08506"
  },
  {
    "ID": 1284640477,
    "Tankstellennummer": "0GH15",
    "Name": "Obernberg am Inn, Brueckenstr. 4",
    "Address": "Brückenstraße 4",
    "Address__1": "",
    "City": "Obernberg am Inn",
    "Postcode": 4982,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 775840868,
    "24 hour?": "FALSE",
    "Latitude": 48.3193555,
    "Longitude": 13.3277399,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3193555,13.3277399",
    "IsOpen24Hours": false,
    "id": "48.3193555,13.3277399"
  },
  {
    "ID": 660980117,
    "Tankstellennummer": "A3742",
    "Name": "Linz-Urfahr Linke Brückenstraß",
    "Address": "Linke Brückenstraße 32",
    "Address__1": "",
    "City": "Linz-Urfahr",
    "Postcode": 4040,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.32105,
    "Longitude": 14.28817,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.32105,14.28817",
    "IsOpen24Hours": false,
    "id": "48.32105,14.28817"
  },
  {
    "ID": 19641,
    "Tankstellennummer": 8567,
    "Name": "Langenrohr/Asparn Teichstraße 1",
    "Address": "Teichstraße 1",
    "Address__1": "",
    "City": "Langenrohr/Asparn",
    "Postcode": 3442,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-2272-81123",
    "24 hour?": "TRUE",
    "Latitude": 48.32242,
    "Longitude": 16.02642,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.32242,16.02642",
    "IsOpen24Hours": true,
    "id": "48.32242,16.02642"
  },
  {
    "ID": 1926316586,
    "Tankstellennummer": "A8734",
    "Name": "Sankt Andrä-Wördern Tullner St",
    "Address": "Tullner Straße 20a",
    "Address__1": "",
    "City": "Sankt Andrä-Wördern",
    "Postcode": 3423,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.32248,
    "Longitude": 16.20326,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.32248,16.20326",
    "IsOpen24Hours": false,
    "id": "48.32248,16.20326"
  },
  {
    "ID": 1815862924,
    "Tankstellennummer": "0GH40",
    "Name": "Tulln an der Donau, Staasdorfer Str. 1",
    "Address": "Staasdorferstraße 1",
    "Address__1": "",
    "City": "Tulln",
    "Postcode": 3430,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 227262523,
    "24 hour?": "FALSE",
    "Latitude": 48.3261402,
    "Longitude": 16.059315,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3261402,16.059315",
    "IsOpen24Hours": false,
    "id": "48.3261402,16.059315"
  },
  {
    "ID": 772,
    "Tankstellennummer": "0G95Y",
    "Name": "Linz-Auhof, Altenberger Str. 7",
    "Address": "Altenberger Straße 7",
    "Address__1": "OOE",
    "City": "Linz-Auhof",
    "Postcode": 4040,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 732245847,
    "24 hour?": "TRUE",
    "Latitude": 48.3316879,
    "Longitude": 14.3173009,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.3316879,14.3173009",
    "IsOpen24Hours": true,
    "id": "48.3316879,14.3173009"
  },
  {
    "ID": 11467419,
    "Tankstellennummer": 8094,
    "Name": "eni Bisamberg",
    "Address": "Bundesstraße 130",
    "Address__1": "",
    "City": "Bisamberg",
    "Postcode": 2102,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/664001",
    "24 hour?": "FALSE",
    "Latitude": 48.33315,
    "Longitude": 16.34447,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.33315,16.34447",
    "IsOpen24Hours": false,
    "id": "48.33315,16.34447"
  },
  {
    "ID": 19617,
    "Tankstellennummer": 8615,
    "Name": "Hagenbrunn Brünner Straße 31",
    "Address": "Brünner Straße 31",
    "Address__1": "",
    "City": "Hagenbrunn",
    "Postcode": 2201,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02246 2576",
    "24 hour?": "TRUE",
    "Latitude": 48.33608,
    "Longitude": 16.46097,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.33608,16.46097",
    "IsOpen24Hours": true,
    "id": "48.33608,16.46097"
  },
  {
    "ID": 303090980,
    "Tankstellennummer": 8009,
    "Name": "eni Korneuburg",
    "Address": "Wiener Strasse 46",
    "Address__1": "",
    "City": "Korneuburg",
    "Postcode": 2100,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2262/72275",
    "24 hour?": "TRUE",
    "Latitude": 48.33762,
    "Longitude": 16.3397,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.33762,16.3397",
    "IsOpen24Hours": true,
    "id": "48.33762,16.3397"
  },
  {
    "ID": 19476,
    "Tankstellennummer": 8712,
    "Name": "Gänserndorf Wiener Straße 130",
    "Address": "Wiener Straße 130",
    "Address__1": "",
    "City": "Gänserndorf",
    "Postcode": 2230,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02282 2281",
    "24 hour?": "TRUE",
    "Latitude": 48.33812,
    "Longitude": 16.73144,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.33812,16.73144",
    "IsOpen24Hours": true,
    "id": "48.33812,16.73144"
  },
  {
    "ID": 1355907430,
    "Tankstellennummer": "A8620",
    "Name": "Korneuburg Wiener Straße 44",
    "Address": "Wiener Straße 44",
    "Address__1": "",
    "City": "Korneuburg",
    "Postcode": 2100,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.33832,
    "Longitude": 16.33873,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.33832,16.33873",
    "IsOpen24Hours": false,
    "id": "48.33832,16.33873"
  },
  {
    "ID": 1290957205,
    "Tankstellennummer": 8020,
    "Name": "eni Gerasdorf",
    "Address": "Alfred Fröhlich Strasse 1",
    "Address__1": "",
    "City": "Gerasdorf",
    "Postcode": 2201,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2246/20646",
    "24 hour?": "TRUE",
    "Latitude": 48.33873,
    "Longitude": 16.46407,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.33873,16.46407",
    "IsOpen24Hours": true,
    "id": "48.33873,16.46407"
  },
  {
    "ID": 19627,
    "Tankstellennummer": 3599,
    "Name": "Engerwitzdorf Freistädter Straße 50",
    "Address": "Freistädter Straße 50",
    "Address__1": "",
    "City": "Engerwitzdorf",
    "Postcode": 4209,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-7235-67890",
    "24 hour?": "FALSE",
    "Latitude": 48.3418,
    "Longitude": 14.41327,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3418,14.41327",
    "IsOpen24Hours": false,
    "id": "48.3418,14.41327"
  },
  {
    "ID": 52828377,
    "Tankstellennummer": "0GH18",
    "Name": "Linz, Leonfeldner Str. 268",
    "Address": "Leonfeldner Straße 268",
    "Address__1": "",
    "City": "Linz",
    "Postcode": 4040,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 732250346,
    "24 hour?": "FALSE",
    "Latitude": 48.3425901,
    "Longitude": 14.2937563,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3425901,14.2937563",
    "IsOpen24Hours": false,
    "id": "48.3425901,14.2937563"
  },
  {
    "ID": 19439,
    "Tankstellennummer": 8006,
    "Name": "Korneuburg Autobahn",
    "Address": "A22 Autobahn/Richtung Stockerau",
    "Address__1": "",
    "City": "Korneuburg",
    "Postcode": 2100,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2262/74589",
    "24 hour?": "TRUE",
    "Latitude": 48.34620469,
    "Longitude": 16.31994529,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.34620469,16.31994529",
    "IsOpen24Hours": true,
    "id": "48.34620469,16.31994529"
  },
  {
    "ID": 19438,
    "Tankstellennummer": 8005,
    "Name": "Korneuburg Autobahn",
    "Address": "A22 Autobahn/Richtung Wien",
    "Address__1": "",
    "City": "Korneuburg",
    "Postcode": 2100,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2262/75349",
    "24 hour?": "TRUE",
    "Latitude": 48.34728256,
    "Longitude": 16.32001716,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.34728256,16.32001716",
    "IsOpen24Hours": true,
    "id": "48.34728256,16.32001716"
  },
  {
    "ID": 19499,
    "Tankstellennummer": 3569,
    "Name": "Walding Mühlkreisbahnstraße 3",
    "Address": "Mühlkreisbahnstraße 3",
    "Address__1": "",
    "City": "Walding",
    "Postcode": 4111,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": 72348247329,
    "24 hour?": "FALSE",
    "Latitude": 48.34793,
    "Longitude": 14.15387,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.34793,14.15387",
    "IsOpen24Hours": false,
    "id": "48.34793,14.15387"
  },
  {
    "ID": 590,
    "Tankstellennummer": "0G98A",
    "Name": "Pregarten, Tragweinerstr. 31",
    "Address": "Tragweinerstraße 31",
    "Address__1": "OOE",
    "City": "Pregarten",
    "Postcode": 4230,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 72362230,
    "24 hour?": "TRUE",
    "Latitude": 48.3534,
    "Longitude": 14.5359,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.3534,14.5359",
    "IsOpen24Hours": true,
    "id": "48.3534,14.5359"
  },
  {
    "ID": 669069018,
    "Tankstellennummer": 6009,
    "Name": "eni Unterweitersdorf",
    "Address": "Betriebsstrasse 1",
    "Address__1": "",
    "City": "Unterweitersdorf",
    "Postcode": 4210,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7235/63850",
    "24 hour?": "TRUE",
    "Latitude": 48.3579,
    "Longitude": 14.4766,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.3579,14.4766",
    "IsOpen24Hours": true,
    "id": "48.3579,14.4766"
  },
  {
    "ID": 1936668538,
    "Tankstellennummer": "0GH26",
    "Name": "Unterweitersdorf, Radingdorf 9",
    "Address": "Radingdorf 9",
    "Address__1": "",
    "City": "Unterweitersdorf",
    "Postcode": 4213,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 723620895,
    "24 hour?": "FALSE",
    "Latitude": 48.358987,
    "Longitude": 14.477963,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.358987,14.477963",
    "IsOpen24Hours": false,
    "id": "48.358987,14.477963"
  },
  {
    "ID": 1384036452,
    "Tankstellennummer": "A3951",
    "Name": "Hartkirchen Schaunbergstraße 2",
    "Address": "Schaunbergstraße 20",
    "Address__1": "",
    "City": "Hartkirchen",
    "Postcode": 4081,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.35956,
    "Longitude": 13.99537,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.35956,13.99537",
    "IsOpen24Hours": false,
    "id": "48.35956,13.99537"
  },
  {
    "ID": 1597127755,
    "Tankstellennummer": 8112,
    "Name": "eni Spitz",
    "Address": "Wachauer Bundesstrasse",
    "Address__1": "",
    "City": "Spitz",
    "Postcode": 3620,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2713/2111",
    "24 hour?": "FALSE",
    "Latitude": 48.3625,
    "Longitude": 15.4168,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3625,15.4168",
    "IsOpen24Hours": false,
    "id": "48.3625,15.4168"
  },
  {
    "ID": 1033744587,
    "Tankstellennummer": 8026,
    "Name": "eni Wolkersdorf",
    "Address": "Wiener Strasse 31",
    "Address__1": "",
    "City": "Wolkersdorf",
    "Postcode": 2120,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2245/2424",
    "24 hour?": "FALSE",
    "Latitude": 48.3786,
    "Longitude": 16.51037,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3786,16.51037",
    "IsOpen24Hours": false,
    "id": "48.3786,16.51037"
  },
  {
    "ID": 19666,
    "Tankstellennummer": 8546,
    "Name": "Stockerau Autobahn A 22",
    "Address": "Autobahn A 22",
    "Address__1": "",
    "City": "Stockerau",
    "Postcode": 2000,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02266 63406",
    "24 hour?": "TRUE",
    "Latitude": 48.38086,
    "Longitude": 16.20408,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.38086,16.20408",
    "IsOpen24Hours": true,
    "id": "48.38086,16.20408"
  },
  {
    "ID": 642,
    "Tankstellennummer": "0G93S",
    "Name": "Stockerau, Pragerstr./Hornerstr.",
    "Address": "Pragerstraße/Hornerstraße",
    "Address__1": "NOE",
    "City": "Stockerau",
    "Postcode": 2000,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 226662438,
    "24 hour?": "FALSE",
    "Latitude": 48.3859492,
    "Longitude": 16.1987658,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.3859492,16.1987658",
    "IsOpen24Hours": false,
    "id": "48.3859492,16.1987658"
  },
  {
    "ID": 19612,
    "Tankstellennummer": 8591,
    "Name": "Stockerau Eduard Rösch-Straße 6-8",
    "Address": "Eduard Rösch-Straße 6-8",
    "Address__1": "",
    "City": "Stockerau",
    "Postcode": 2000,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02266 62926",
    "24 hour?": "FALSE",
    "Latitude": 48.38611,
    "Longitude": 16.21863,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.38611,16.21863",
    "IsOpen24Hours": false,
    "id": "48.38611,16.21863"
  },
  {
    "ID": 85864104,
    "Tankstellennummer": 6055,
    "Name": "eni St.Marienkirchen",
    "Address": "Andiesen 15",
    "Address__1": "",
    "City": "St.Marienkirchen/Schärding",
    "Postcode": 4774,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)7711/33045",
    "24 hour?": "TRUE",
    "Latitude": 48.39454,
    "Longitude": 13.42673,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.39454,13.42673",
    "IsOpen24Hours": true,
    "id": "48.39454,13.42673"
  },
  {
    "ID": 1352449477,
    "Tankstellennummer": "A8621",
    "Name": "Krems an der Donau Förthofer D",
    "Address": "Förthofer Donaulände 8",
    "Address__1": "",
    "City": "Krems an der Donau",
    "Postcode": 3500,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.39735,
    "Longitude": 15.56875,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.39735,15.56875",
    "IsOpen24Hours": false,
    "id": "48.39735,15.56875"
  },
  {
    "ID": 2109048969,
    "Tankstellennummer": "A8700",
    "Name": "Krems an der Donau Bertschinge",
    "Address": "Bertschingerstraße 6-8",
    "Address__1": "",
    "City": "Krems an der Donau",
    "Postcode": 3500,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.40624,
    "Longitude": 15.60365,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.40624,15.60365",
    "IsOpen24Hours": false,
    "id": "48.40624,15.60365"
  },
  {
    "ID": 1944528909,
    "Tankstellennummer": 8033,
    "Name": "eni Krems",
    "Address": "Wiener Strasse 63",
    "Address__1": "",
    "City": "Krems",
    "Postcode": 3500,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2732/84967",
    "24 hour?": "FALSE",
    "Latitude": 48.41439,
    "Longitude": 15.61417,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.41439,15.61417",
    "IsOpen24Hours": false,
    "id": "48.41439,15.61417"
  },
  {
    "ID": 9891,
    "Tankstellennummer": "0G966",
    "Name": "Krems, Wiener Str. 76",
    "Address": "Wiener Straße 76",
    "Address__1": "NOE",
    "City": "Krems",
    "Postcode": 3500,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 273277998,
    "24 hour?": "TRUE",
    "Latitude": 48.415,
    "Longitude": 15.6175,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.415,15.6175",
    "IsOpen24Hours": true,
    "id": "48.415,15.6175"
  },
  {
    "ID": 476981705,
    "Tankstellennummer": 8093,
    "Name": "eni Krems an der Donau",
    "Address": "An der Bundesstraße 37",
    "Address__1": "",
    "City": "Krems an der Donau",
    "Postcode": 3500,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)732/664001",
    "24 hour?": "FALSE",
    "Latitude": 48.42304804,
    "Longitude": 15.62184329,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.42304804,15.62184329",
    "IsOpen24Hours": false,
    "id": "48.42304804,15.62184329"
  },
  {
    "ID": 1814152771,
    "Tankstellennummer": 8693,
    "Name": "Wolfpassing Autobahn A5 / Brün",
    "Address": "Autobahn A5 / Brünner Straße 137",
    "Address__1": "",
    "City": "Wolfpassing",
    "Postcode": 2123,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02245 89003",
    "24 hour?": "TRUE",
    "Latitude": 48.4249,
    "Longitude": 16.54799,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.4249,16.54799",
    "IsOpen24Hours": true,
    "id": "48.4249,16.54799"
  },
  {
    "ID": 1126226571,
    "Tankstellennummer": "0GH42",
    "Name": "Hellmonsoedt, Linzer Str. 22",
    "Address": "Linzer Straße 22",
    "Address__1": "",
    "City": "Hellmonsödt",
    "Postcode": 4202,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 72152428,
    "24 hour?": "FALSE",
    "Latitude": 48.4291267,
    "Longitude": 14.2950845,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4291267,14.2950845",
    "IsOpen24Hours": false,
    "id": "48.4291267,14.2950845"
  },
  {
    "ID": 639,
    "Tankstellennummer": "0G98B",
    "Name": "St. Martin im Muehlkreis, Adsdorf 13",
    "Address": "Adsdorf 13",
    "Address__1": "OOE",
    "City": "St. Martin im Mühlkreis",
    "Postcode": 4113,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 723222350,
    "24 hour?": "TRUE",
    "Latitude": 48.4338,
    "Longitude": 14.0433,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.4338,14.0433",
    "IsOpen24Hours": true,
    "id": "48.4338,14.0433"
  },
  {
    "ID": 19498,
    "Tankstellennummer": 3542,
    "Name": "Unterweissenbach 51",
    "Address": 51,
    "Address__1": "",
    "City": "Unterweissenbach",
    "Postcode": 4273,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "07956 7258",
    "24 hour?": "FALSE",
    "Latitude": 48.43733,
    "Longitude": 14.78153,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.43733,14.78153",
    "IsOpen24Hours": false,
    "id": "48.43733,14.78153"
  },
  {
    "ID": 1597308149,
    "Tankstellennummer": "0GH65",
    "Name": "St. Martin im Muehlkreis, Windorf 10",
    "Address": "Windorf 10",
    "Address__1": "",
    "City": "St.Martin im Mühlkreis",
    "Postcode": 4113,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 72322230,
    "24 hour?": "FALSE",
    "Latitude": 48.438227,
    "Longitude": 14.037018,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.438227,14.037018",
    "IsOpen24Hours": false,
    "id": "48.438227,14.037018"
  },
  {
    "ID": 1184738622,
    "Tankstellennummer": "A3513",
    "Name": "Sankt Florian/Inn Bundesstraße",
    "Address": "Bundesstraße 129",
    "Address__1": "",
    "City": "Sankt Florian/Inn",
    "Postcode": 4780,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.43865,
    "Longitude": 13.44392,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.43865,13.44392",
    "IsOpen24Hours": false,
    "id": "48.43865,13.44392"
  },
  {
    "ID": 612,
    "Tankstellennummer": "0G97V",
    "Name": "Schaerding, Linzerstr",
    "Address": "Linzerstraße 52",
    "Address__1": "OOE",
    "City": "Schärding",
    "Postcode": 4780,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 77122194,
    "24 hour?": "FALSE",
    "Latitude": 48.4512,
    "Longitude": 13.436,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4512,13.436",
    "IsOpen24Hours": false,
    "id": "48.4512,13.436"
  },
  {
    "ID": 919852032,
    "Tankstellennummer": 8105,
    "Name": "eni Hadersdorf a. Kamp",
    "Address": "Bundesstr. 3",
    "Address__1": "",
    "City": "Hadersdorf a. Kamp",
    "Postcode": 3493,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2735/2410",
    "24 hour?": "FALSE",
    "Latitude": 48.4596,
    "Longitude": 15.7252,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.4596,15.7252",
    "IsOpen24Hours": false,
    "id": "48.4596,15.7252"
  },
  {
    "ID": 1824409981,
    "Tankstellennummer": "A3504",
    "Name": "Zwettl Linzer Straße 4",
    "Address": "Linzer Straße 4",
    "Address__1": "",
    "City": "Zwettl",
    "Postcode": 4180,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.46445,
    "Longitude": 14.27161,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.46445,14.27161",
    "IsOpen24Hours": false,
    "id": "48.46445,14.27161"
  },
  {
    "ID": 416,
    "Tankstellennummer": "0G94O",
    "Name": "Gaweinstal, Wienerstr. 35",
    "Address": "Wienerstraße 35",
    "Address__1": "NOE",
    "City": "Gaweinstal",
    "Postcode": 2191,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 25742183,
    "24 hour?": "FALSE",
    "Latitude": 48.472861,
    "Longitude": 16.588735,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.472861,16.588735",
    "IsOpen24Hours": false,
    "id": "48.472861,16.588735"
  },
  {
    "ID": 1556326517,
    "Tankstellennummer": "A8624",
    "Name": "Langenlois Wiener Straße 53",
    "Address": "Wiener Straße 53",
    "Address__1": "",
    "City": "Langenlois",
    "Postcode": 3550,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.47614,
    "Longitude": 15.69817,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.47614,15.69817",
    "IsOpen24Hours": false,
    "id": "48.47614,15.69817"
  },
  {
    "ID": 19480,
    "Tankstellennummer": 8562,
    "Name": "Großweikersdorf Bundesstraße 4",
    "Address": "An der Bundesstraße 4",
    "Address__1": "",
    "City": "Großweikersdorf",
    "Postcode": 3701,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02955 70277",
    "24 hour?": "FALSE",
    "Latitude": 48.47877,
    "Longitude": 15.97709,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.47877,15.97709",
    "IsOpen24Hours": false,
    "id": "48.47877,15.97709"
  },
  {
    "ID": 1135968660,
    "Tankstellennummer": "A8756",
    "Name": "Gaweinstal Brünner Straße 35",
    "Address": "Brünner Straße 35",
    "Address__1": "",
    "City": "Gaweinstal",
    "Postcode": 2191,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.48222,
    "Longitude": 16.59312,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.48222,16.59312",
    "IsOpen24Hours": false,
    "id": "48.48222,16.59312"
  },
  {
    "ID": 1323348482,
    "Tankstellennummer": 6073,
    "Name": "Eni Altenfelden",
    "Address": "Freileben 13",
    "Address__1": "",
    "City": "Altenfelden",
    "Postcode": 4121,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "",
    "24 hour?": "FALSE",
    "Latitude": 48.49181077,
    "Longitude": 13.97079066,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.49181077,13.97079066",
    "IsOpen24Hours": false,
    "id": "48.49181077,13.97079066"
  },
  {
    "ID": 205415585,
    "Tankstellennummer": "0GH25",
    "Name": "Freistadt, Linzer Straße 44",
    "Address": "Linzer Straße 44",
    "Address__1": "",
    "City": "Freistadt",
    "Postcode": 4240,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 794275096,
    "24 hour?": "FALSE",
    "Latitude": 48.5023154,
    "Longitude": 14.5016524,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.5023154,14.5016524",
    "IsOpen24Hours": false,
    "id": "48.5023154,14.5016524"
  },
  {
    "ID": 397370100,
    "Tankstellennummer": "0GAFN",
    "Name": "Putzleinsdorf, Glotzing 20",
    "Address": "Glotzing 20",
    "Address__1": "",
    "City": "Putzleinsdorf",
    "Postcode": 4134,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 72888363,
    "24 hour?": "TRUE",
    "Latitude": 48.504476,
    "Longitude": 13.877298,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.504476,13.877298",
    "IsOpen24Hours": true,
    "id": "48.504476,13.877298"
  },
  {
    "ID": 19636,
    "Tankstellennummer": 3581,
    "Name": "Bad Leonfelden Oberstiftung 100",
    "Address": "Oberstiftung 100",
    "Address__1": "",
    "City": "Bad Leonfelden",
    "Postcode": 4190,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "+43-7213-6188",
    "24 hour?": "TRUE",
    "Latitude": 48.51185,
    "Longitude": 14.29526,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.51185,14.29526",
    "IsOpen24Hours": true,
    "id": "48.51185,14.29526"
  },
  {
    "ID": 19679,
    "Tankstellennummer": 8566,
    "Name": "Gföhl Langenloiser Straße 85",
    "Address": "Langenloiser Straße 85",
    "Address__1": "",
    "City": "Gföhl",
    "Postcode": 3542,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02716 6064",
    "24 hour?": "FALSE",
    "Latitude": 48.51208,
    "Longitude": 15.51044,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.51208,15.51044",
    "IsOpen24Hours": false,
    "id": "48.51208,15.51044"
  },
  {
    "ID": 515994268,
    "Tankstellennummer": "0GH05",
    "Name": "Schrick, Antensee 3",
    "Address": "Antensee 3",
    "Address__1": "",
    "City": "Schrick",
    "Postcode": 2291,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 257429364,
    "24 hour?": "TRUE",
    "Latitude": 48.519049,
    "Longitude": 16.617422,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.519049,16.617422",
    "IsOpen24Hours": true,
    "id": "48.519049,16.617422"
  },
  {
    "ID": 19675,
    "Tankstellennummer": 8318,
    "Name": "Zistersdorf Umfahrungsstraße 28A",
    "Address": "Umfahrungsstraße 28a",
    "Address__1": "",
    "City": "Zistersdorf",
    "Postcode": 2225,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02532 2240",
    "24 hour?": "TRUE",
    "Latitude": 48.54239,
    "Longitude": 16.76417,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.54239,16.76417",
    "IsOpen24Hours": true,
    "id": "48.54239,16.76417"
  },
  {
    "ID": 755837294,
    "Tankstellennummer": 8015,
    "Name": "eni Hollabrunn",
    "Address": "Wiener Strasse 136",
    "Address__1": "",
    "City": "Hollabrunn",
    "Postcode": 2020,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2952/4581",
    "24 hour?": "FALSE",
    "Latitude": 48.54932,
    "Longitude": 16.07915,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.54932,16.07915",
    "IsOpen24Hours": false,
    "id": "48.54932,16.07915"
  },
  {
    "ID": 603,
    "Tankstellennummer": "0GF03",
    "Name": "Rohrbach, Scheiblberg 44",
    "Address": "Scheiblberg 44",
    "Address__1": "OOE",
    "City": "Berg bei Rohrbach",
    "Postcode": 4150,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 72888363,
    "24 hour?": "TRUE",
    "Latitude": 48.5596,
    "Longitude": 13.9871,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.5596,13.9871",
    "IsOpen24Hours": true,
    "id": "48.5596,13.9871"
  },
  {
    "ID": 1648124578,
    "Tankstellennummer": 8032,
    "Name": "eni Mistelbach",
    "Address": "Mitschastrasse 38",
    "Address__1": "",
    "City": "Mistelbach",
    "Postcode": 2130,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2572/2389",
    "24 hour?": "TRUE",
    "Latitude": 48.56456,
    "Longitude": 16.57595,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.56456,16.57595",
    "IsOpen24Hours": true,
    "id": "48.56456,16.57595"
  },
  {
    "ID": 1576012949,
    "Tankstellennummer": "0GH20",
    "Name": "Rohrbach, Umfahrung-Sued 1",
    "Address": "Umfahrung Süd 1",
    "Address__1": "",
    "City": "Rohrbach",
    "Postcode": 4150,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 72898939,
    "24 hour?": "FALSE",
    "Latitude": 48.5650573,
    "Longitude": 13.9852452,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.5650573,13.9852452",
    "IsOpen24Hours": false,
    "id": "48.5650573,13.9852452"
  },
  {
    "ID": 19633,
    "Tankstellennummer": 8569,
    "Name": "Hobersdorf Wiener Straße 91",
    "Address": "Wiener Straße 91",
    "Address__1": "",
    "City": "Hobersdorf",
    "Postcode": 2193,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02573 6010",
    "24 hour?": "TRUE",
    "Latitude": 48.5728,
    "Longitude": 16.6394,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.5728,16.6394",
    "IsOpen24Hours": true,
    "id": "48.5728,16.6394"
  },
  {
    "ID": 19610,
    "Tankstellennummer": 8585,
    "Name": "Hohenau an der March Bernsteinstraße",
    "Address": "Bernsteinstraße 6",
    "Address__1": "",
    "City": "Hohenau an der March",
    "Postcode": 2273,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02535 2130",
    "24 hour?": "FALSE",
    "Latitude": 48.60217,
    "Longitude": 16.90004,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.60217,16.90004",
    "IsOpen24Hours": false,
    "id": "48.60217,16.90004"
  },
  {
    "ID": 18975,
    "Tankstellennummer": "0G919",
    "Name": "Kollerschlag, Falkensteinstr. 16",
    "Address": "Falkensteinstraße 16",
    "Address__1": "OOE",
    "City": "Kollerschlag",
    "Postcode": 4154,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 7287752848,
    "24 hour?": "TRUE",
    "Latitude": 48.6023,
    "Longitude": 13.8389,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.6023,13.8389",
    "IsOpen24Hours": true,
    "id": "48.6023,13.8389"
  },
  {
    "ID": 37,
    "Tankstellennummer": "0G914",
    "Name": "Peilstein, Hanriederstr. 43",
    "Address": "Hanriederstraße 43",
    "Address__1": "OOE",
    "City": "Peilstein im Mühlviertel",
    "Postcode": 4153,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 7287752848,
    "24 hour?": "FALSE",
    "Latitude": 48.6205,
    "Longitude": 13.8857,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6205,13.8857",
    "IsOpen24Hours": false,
    "id": "48.6205,13.8857"
  },
  {
    "ID": 19470,
    "Tankstellennummer": 8711,
    "Name": "Eggenburg Grafenberger Straße",
    "Address": "Stoitzendorferstraße 8",
    "Address__1": "",
    "City": "Eggenburg",
    "Postcode": 3730,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02984  3367",
    "24 hour?": "FALSE",
    "Latitude": 48.6437,
    "Longitude": 15.82231,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6437,15.82231",
    "IsOpen24Hours": false,
    "id": "48.6437,15.82231"
  },
  {
    "ID": 1115012719,
    "Tankstellennummer": "A8587",
    "Name": "Poysdorf Wiener Straße 63",
    "Address": "Wiener Straße 63",
    "Address__1": "",
    "City": "Poysdorf",
    "Postcode": 2170,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.65948,
    "Longitude": 16.62935,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.65948,16.62935",
    "IsOpen24Hours": false,
    "id": "48.65948,16.62935"
  },
  {
    "ID": 1132409218,
    "Tankstellennummer": "A8586",
    "Name": "Horn Riedenburgstraße 2",
    "Address": "Riedenburgstraße 2",
    "Address__1": "",
    "City": "Horn",
    "Postcode": 3580,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.66188,
    "Longitude": 15.65477,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.66188,15.65477",
    "IsOpen24Hours": false,
    "id": "48.66188,15.65477"
  },
  {
    "ID": 1545781903,
    "Tankstellennummer": 8066,
    "Name": "eni Horn",
    "Address": "Wiener Strasse 87",
    "Address__1": "",
    "City": "Horn",
    "Postcode": 3580,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2982/2369",
    "24 hour?": "TRUE",
    "Latitude": 48.66258,
    "Longitude": 15.67015,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.66258,15.67015",
    "IsOpen24Hours": true,
    "id": "48.66258,15.67015"
  },
  {
    "ID": 19629,
    "Tankstellennummer": 8570,
    "Name": "Frauenhofen Wiener Straße 6",
    "Address": "Wiener Straße 6",
    "Address__1": "",
    "City": "Frauenhofen",
    "Postcode": 3580,
    "Country": "Austria",
    "Brand": "OM",
    "Telephone": "02982 60162",
    "24 hour?": "TRUE",
    "Latitude": 48.66569,
    "Longitude": 15.63271,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.66569,15.63271",
    "IsOpen24Hours": true,
    "id": "48.66569,15.63271"
  },
  {
    "ID": 370,
    "Tankstellennummer": "0G91Q",
    "Name": "Ulrichsberg, Dreisesselbergstr. 18",
    "Address": "Dreisesselbergstraße 18",
    "Address__1": "OOE",
    "City": "Ulrichsberg",
    "Postcode": 4161,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 72888363,
    "24 hour?": "FALSE",
    "Latitude": 48.6793,
    "Longitude": 13.9083,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.6793,13.9083",
    "IsOpen24Hours": false,
    "id": "48.6793,13.9083"
  },
  {
    "ID": 479239639,
    "Tankstellennummer": "A8641",
    "Name": "Pulkau Bundesstraße 35",
    "Address": "Bundesstraße 35",
    "Address__1": "",
    "City": "Pulkau",
    "Postcode": 3741,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.70033,
    "Longitude": 15.86356,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.70033,15.86356",
    "IsOpen24Hours": false,
    "id": "48.70033,15.86356"
  },
  {
    "ID": 202301352,
    "Tankstellennummer": 8050,
    "Name": "Agip Laa an der Thaya",
    "Address": "Simon Scheiner Str. 6",
    "Address__1": "",
    "City": "Laa an der Thaya",
    "Postcode": 2136,
    "Country": "Austria",
    "Brand": "AP",
    "Telephone": "+43(0)2522/2406",
    "24 hour?": "FALSE",
    "Latitude": 48.71853,
    "Longitude": 16.39589,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.71853,16.39589",
    "IsOpen24Hours": false,
    "id": "48.71853,16.39589"
  },
  {
    "ID": 1455784815,
    "Tankstellennummer": "0G91C",
    "Name": "Schwarzenberg, Schwarzenberg 168",
    "Address": "Schwarzenberg 168",
    "Address__1": "",
    "City": "Schwarzenberg am Böhmerwald",
    "Postcode": 4164,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 72888363,
    "24 hour?": "TRUE",
    "Latitude": 48.7362668,
    "Longitude": 13.8195991,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.7362668,13.8195991",
    "IsOpen24Hours": true,
    "id": "48.7362668,13.8195991"
  },
  {
    "ID": 393,
    "Tankstellennummer": "0G98N",
    "Name": "Drasenhofen, Drasenhofen Nr. 237",
    "Address": "Brünnerstraße/Staatsgr.",
    "Address__1": "NOE",
    "City": "Drasenhofen",
    "Postcode": 2165,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 255485523,
    "24 hour?": "TRUE",
    "Latitude": 48.777404,
    "Longitude": 16.640757,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "TRUE",
    "location": "48.777404,16.640757",
    "IsOpen24Hours": true,
    "id": "48.777404,16.640757"
  },
  {
    "ID": 495888516,
    "Tankstellennummer": "A8589",
    "Name": "Schrems Josef Widy-Straße 2",
    "Address": "Josef Widy-Straße 2",
    "Address__1": "",
    "City": "Schrems",
    "Postcode": 3943,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.79281,
    "Longitude": 15.06908,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.79281,15.06908",
    "IsOpen24Hours": false,
    "id": "48.79281,15.06908"
  },
  {
    "ID": 462,
    "Tankstellennummer": "0G98O",
    "Name": "Heidenreichstein, Klein Pertholz 58",
    "Address": "Klein Pertholz 58",
    "Address__1": "NOE",
    "City": "Heidenreichstein",
    "Postcode": 3860,
    "Country": "Austria",
    "Brand": "BP",
    "Telephone": 286252127,
    "24 hour?": "FALSE",
    "Latitude": 48.850263,
    "Longitude": 15.096532,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.850263,15.096532",
    "IsOpen24Hours": false,
    "id": "48.850263,15.096532"
  },
  {
    "ID": 1467922612,
    "Tankstellennummer": "A8561",
    "Name": "Heidenreichstein Schremser Str",
    "Address": "Schremser Straße 52",
    "Address__1": "",
    "City": "Heidenreichstein",
    "Postcode": 3860,
    "Country": "Austria",
    "Brand": "AN",
    "Telephone": 800202055,
    "24 hour?": "FALSE",
    "Latitude": 48.86169,
    "Longitude": 15.12039,
    "Automated Station": "FALSE",
    "Petit Bistro": "FALSE",
    "Open 24 hours": "FALSE",
    "location": "48.86169,15.12039",
    "IsOpen24Hours": false,
    "id": "48.86169,15.12039"
  }
];
