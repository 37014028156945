import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  getRentalData,
  reserveCar,
  cancelRental,
  clearRental,
  finishServiceType,
  updateRental,
  getDriverCarStatus,
  canFinishRental,
  clearFinishRentalViolations,
  addDamage,
  addDamageImage,
  addDamageImageBlob,
  setIsServiceReportSubmitted,
  unlockCar,
  turnIgnitionOn,
  turnIgnitionOff,
  getFuelInfo,
  setIsCarChecked,
  finishRental,
  getCarDamages,
  setCarDamages,
  forceRentalDriveMode,
  clearForceRentalDriveMode,
  getRentalS3Credential,
} from '../redux/store/userData/rental';
import { getServices } from '../redux/store/service';
import { RentalState, State } from '../@types';
import { CLIENT_TYPE } from '../@types';
import { getServiceTypes } from '../redux/store/appData/serviceTypes';
import { setOpened } from '../redux/store/ui/map';

export type Props = RentalState & {
  getRentalData: typeof getRentalData;
  getServices: typeof getServices;
  reserveCar: typeof reserveCar;
  cancelRental: typeof cancelRental;
  clearRental: typeof clearRental;
  serviceTypes: Array<string>;
  finishServiceType: typeof finishServiceType;
  loadingRentalData: boolean;
  updateRental: typeof updateRental;
  addDamage: typeof addDamage;
  addDamageImage: typeof addDamageImage;
  addDamageImageBlob: typeof addDamageImageBlob;
  getDriverCarStatus: typeof getDriverCarStatus;
  canFinishRental: typeof canFinishRental;
  clearFinishRentalViolations: typeof clearFinishRentalViolations;
  setIsServiceReportSubmitted: typeof setIsServiceReportSubmitted;
  isSubmittingDamage: boolean;
  unlockCar: typeof unlockCar;
  turnIgnitionOn: typeof turnIgnitionOn;
  turnIgnitionOff: typeof turnIgnitionOff;
  getFuelInfo: typeof getFuelInfo;
  setIsCarChecked: typeof setIsCarChecked;
  getRentalS3Credential: typeof getRentalS3Credential;
  getServiceTypes: typeof getServiceTypes;
  getCarDamages: typeof getCarDamages;
  setCarDamages: typeof setCarDamages;
  forceRentalDriveMode: typeof forceRentalDriveMode;
  clearForceRentalDriveMode: typeof clearForceRentalDriveMode;
  setOpened: typeof setOpened;
};

const withRental: any = compose(
  connect(
    ({
      userData: { rental },
      networkStatus: { activities },
      appData: { serviceTypes },
    }: State) => ({
      ...rental,
      loadingRentalData: activities.some((activityType: string) =>
        [CLIENT_TYPE.RENTAL_CLIENT.ADD_DAMAGE].includes(activityType)
      ),
      isSubmittingDamage: activities.some((activityType: string) =>
        [CLIENT_TYPE.RENTAL_CLIENT.ADD_DAMAGE].includes(activityType)
      ),
      serviceTypes,
    }),
    {
      getRentalData,
      reserveCar,
      cancelRental,
      clearRental,
      finishServiceType,
      updateRental,
      addDamage,
      addDamageImage,
      addDamageImageBlob,
      getDriverCarStatus,
      canFinishRental,
      clearFinishRentalViolations,
      setIsServiceReportSubmitted,
      unlockCar,
      turnIgnitionOn,
      turnIgnitionOff,
      getFuelInfo,
      setIsCarChecked,
      finishRental,
      getServices,
      getRentalS3Credential,
      getServiceTypes,
      getCarDamages,
      setCarDamages,
      forceRentalDriveMode,
      clearForceRentalDriveMode,
      setOpened,
    }
  )
);
export default withRental;
