import React, { useEffect } from 'react';
import {
  LocalizePropType,
  fetchDeviceStatus,
  selectSelectedConnectCar,
  withTextLocalizer,
  selectCurrentInstallation,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import DataTestView from './DataTestView';
import { navigateTo } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  localize: LocalizePropType;
};

const DataTestContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCar = useSelector(selectSelectedConnectCar);
  const currentInstallation = useSelector(selectCurrentInstallation);

  const onPressContinue = () => {
    navigateTo(
      history,
      selectedCar?.fuelCardRequired ? '/fuel-card' : '/confirm'
    );
  };

  useEffect(() => {
    if (currentInstallation?.device?.id) {
      dispatch(fetchDeviceStatus(currentInstallation?.device?.id!));
    }
  }, []);

  useEffect(() => {
    if (!currentInstallation) {
      navigateTo(history, '/select');
    }
  }, [currentInstallation]);

  return selectedCar ? (
    <DataTestView
      localize={localize}
      car={selectedCar!}
      onPressStart={onPressContinue}
    />
  ) : null;
};

export default withTextLocalizer(DataTestContainer);
