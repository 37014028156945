import * as React from 'react';
import './VerticalSpace.less';

type Props = {
  height?: number;
  backgroundColor?: string;
};

const VerticalSpace = ({ height, backgroundColor }: Props) => (
  <div
    className="vertical-space"
    style={{
      height,
      backgroundColor,
    }}
  />
);

VerticalSpace.defaultProps = {
  height: 100,
  color: 'white',
};
export default VerticalSpace;
