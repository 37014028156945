import { History } from 'history';

export const navigateTo = (
  history: History,
  path: string,
  state: Object = {}
) => {
  history.push({
    pathname: path,
    state: state,
  });
};
