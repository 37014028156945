export const isCameraPermissionGranted = async () => {
  // @ts-ignore
  const isFirefox = typeof InstallTrigger !== 'undefined';
  if (isFirefox) {
    return true;
  } else {
    // @ts-ignore
    const result = await navigator?.permissions?.query({ name: 'camera' });
    return result?.state ? result?.state === 'granted' : true;
  }
};
